import React from "react";

import { Field } from "redux-form";

import { ROEItemContainer, ROEItemTitle } from "../../RemoteObjectDetails/Components/ROInfo/styles";
import EllipsisText from "../../Ellipsis";
import { renderField } from "../../../utils/fieldValidator";
import { appDefaults } from "../../../constants/globalConfiguration";
import { __ } from '../../../utils/translationUtils'

export default (props) => {

  const {
    attribute: attr,
    disabled,
    enumValues,
    formName,
  } = props

  const getItems = (optionItems, readOnly) => {
    const Option = ({ key, label, value }) => (
      <option key={key} value={value}>
        {label}
      </option>
    )
    if (!optionItems?.length) return null
    return [<Option key="deselect-option-key" value="" label={__("Select one")} />, ...optionItems.map((item) => (
      <Option
        key={`date-format-${item.translationKey}`}
        value={item.value}
        label={item.translationKey ? __(item.translationKey) : item.value}
      />
    ))]
  }

  return (
    <ROEItemContainer>
      <ROEItemTitle>
        <EllipsisText>
          {__(attr.propertyLabel)}
        </EllipsisText>
      </ROEItemTitle>
      <Field
        key={`remoteobject-property-${attr.id}`}
        formName={formName}
        name={attr.id}
        component={renderField}
        disabled={disabled}
        type={attr.enumerationId ? "select" : attr.type || "text"}
        placeholder={"-"}
        dateFormat={appDefaults.dateFormat}
        items={attr.enumerationId && getItems(enumValues?.[attr.enumerationId])}
      />
    </ROEItemContainer>
  );
}
