import React from "react";
import moment from "moment";
import * as config from "../../../../../../../constants/globalConfiguration";
import EllipsisText from "../../../../../../Ellipsis";
import { Field } from "redux-form";
import { renderField } from "../../../../../../../utils/fieldValidator";
import { VALUE_TYPES } from "../../../../../../../constants";
import { ROEItemContainer, ROEItemTitle } from "../../../styles";
import { __ } from '../../../../../../../utils/translationUtils'

export default ({ label, type, value, key, mainKey }) => {

  if (value || value === false) {
    if (type === VALUE_TYPES.YESNO.value && (value === true || value.toLowerCase() === "yes" || value.toLowerCase() === "true")) {
      value = __('val_yes');
    }
    if (type === VALUE_TYPES.YESNO.value && (value === false || value.toLowerCase() === "no" || value.toLowerCase() === "false")) {
      value = __('val_no');
    }
    // Check if date
    if (type === VALUE_TYPES.DATE.value) {
      value = moment(value).format(config.appDefaults.dateFormat);
    }
    // Check if ListBox
    if (type === "PopupListBox") {
      value = __(value);
    }
  }

  return (
    <ROEItemContainer key={`StepObj-${mainKey}-${key}`}>
      <ROEItemTitle>
        <EllipsisText trigger='hover'>
          {label}
        </EllipsisText>
      </ROEItemTitle>
      <Field
        input={{
          value,
          name: key,
          container: 'ObjectListing',
        }}
        component={renderField}
        type={type}
        name={`StepObj-${mainKey}-${key}`}
        disabled={true}
      />
    </ROEItemContainer>);
}
