import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Collapse } from "antd";

import ChatContent from './ChatContent'
import { ChatContainer, ChatHeader } from "./styles";
import { fetchEntities, fetchMessages } from "./utils";
import CreateMessage from "./Message/CreateMessage";
import { api } from "../../../providers/ApiProvider";

const { Panel } = Collapse

export default (props) => {
  const { panelTitle, objectCaller, modelCaller } = props
  
  const [isLoading, setIsLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [entities, setEntities] = useState([])
  const callerURL = useMemo(() => `/${modelCaller}/${objectCaller.id}/comments`, [modelCaller, objectCaller])
  
  const getEntities = useCallback(() => (
    fetchEntities().then((res) => {
      setEntities(res.data)
    })), [])
  
  const getMessages = useCallback(() => (
    fetchMessages({ objectCaller, modelCaller }).then((res) => {
      setMessages(res.data)
    })
  ), [objectCaller, modelCaller])
  
  const getData = () => {
    setIsLoading(true)
    Promise.all([getEntities(), getMessages()])
    .then(() => { setIsLoading(false) })
  }
  
  useEffect(() => { getData() }, [])
  
  const postMessage = useCallback((payload) => {
    return api.post(callerURL, payload)
  }, [callerURL])
  
  if (isLoading) {
    return (<div />)
  }

  return (
    <ChatContainer
      onChange={() => {
        setTimeout(() => {
          props.containerRef?.current.scrollTo({
            top: props.containerRef?.current?.scrollTopMax,
            behavior: 'smooth',
          });
        }, 500);
      }}
      expandIconPosition="right"
      destroyInactivePanel={false}
      bordered={false}
      ghost
    >
      <Panel
        key={'chat-panel'}
        header={ <ChatHeader title={panelTitle} total={messages.length} /> }
      >
        <ChatContent
          {...props}
          messages={messages}
          entities={entities}
          refreshMessage={getData}
        />
        <CreateMessage
          {...props}
          refresh={getMessages}
          entities={entities}
          postMessage={postMessage}
        />
      </Panel>
    </ChatContainer>
  )
}
