import React, { useEffect } from 'react'
import { ResponsiveModal } from "../providers/StyleProvider/styles";
import { wrapperType } from "./DooOrderPage/Step/customRender/ObjectSelector/constants";
import { Sidebar } from "components";

export default (props) => {
  const {
    type = wrapperType.DEFAULT,
    children,
    containerRef,
    onSubmitCallback,
    ...wrapperProps
  } = props

  useEffect(() => {
    return () => wrapperProps?.onClose?.() || wrapperProps?.onCancel?.()
  }, [])

  switch (type) {
    case wrapperType.MODAL:
      return (
        <ResponsiveModal
          {...wrapperProps}
          onOk={onSubmitCallback}
          bodyStyle={{ padding: '0' }}
          fixedheight={true}
          columnContent
        >
          <div className={'ant-modal-body'} ref={containerRef}>
            {children}
          </div>
        </ResponsiveModal>
      )
    case wrapperType.DRAWER:
      return (
        <Sidebar
          closable
          {...wrapperProps}
        >
          {children}
        </Sidebar>
      )
    case wrapperType.CONTENT:
      return (
        <Sidebar
          zIndex={999}
          title={wrapperProps?.title}
          placement={wrapperProps?.placement}
          visible={wrapperProps?.visible}
          onEnter={onSubmitCallback}
          closable
          closeIcon="arrow-left"
          onClose={wrapperProps?.onClose || wrapperProps?.onCancel}
          getContainer={containerRef.current || document.body}
          width={'100%'}
          footer={wrapperProps?.footer}
          push={false}
          columnContent
        >
          {children}
        </Sidebar>
      )
    default:
      return (
        <>
          {wrapperProps.visible && children}
        </>
      )
  }
}
