import { Result } from "antd";
import React from "react";
import styled from "styled-components";

import { __ } from '../../../utils/translationUtils'

export default (props) => (
  <ErrorResult
    status="warning"
    extra={__('export_error')}
  />
)

const ErrorResult = styled(Result)`
  padding: 0;
  .ant-result-icon {
    margin: 0;

    .anticon {
      font-size: 48px;
    }
  }
  .ant-result-extra {
    margin: 12px 0 0 0;
  }
`
