import FileUploaderServiceAbstract from './fileUploaderServiceAbstract'
import * as CONST from '../constant'
import {emptyIfNull, removeLastPipeOrSlash, retrieveFileNamePath, toLowerAndRemoveSpace} from './utils'
import * as config from '../../../constants/globalConfiguration'

const apiUrl = config.getApiUrl()

class FileUploaderService extends FileUploaderServiceAbstract {
  constructor() {
    super()

    if (!FileUploaderService.instance ) FileUploaderService.instance = this
    return FileUploaderService.instance
  }

  getFilesTree(source, controllerOptions) {
    if (source == null || controllerOptions == null) return null

    return this.filesTreeFromControllerOptions(source, controllerOptions)
  }


  filesTreeFromControllerOptions(source, controllerOptions) {
    const res = {}
    this.retrieveRootDirectory((
      controllerOptions?.primaryKey &&
        controllerOptions?.xsdName &&
        controllerOptions?.fieldName)
      ? { ...controllerOptions, files: null } : source, res)
    res.allowFilePreview = controllerOptions.allowFilePreview
    res[CONST.RootDirectory].allowExtraFiles = controllerOptions[CONST.RootDirectory].allowExtraFiles
    res[CONST.RootDirectory].acceptedMimeTypes = controllerOptions[CONST.RootDirectory].acceptedMimeTypes
    res[CONST.RootDirectory].allowFilePreview = controllerOptions.allowFilePreview

    const [fileDefinitions, extraFiles] = this.retrieveFilesDefinitions(controllerOptions[CONST.RootDirectory], source)
    res[CONST.RootDirectory].fileDefinitions = fileDefinitions
    res[CONST.RootDirectory].files = extraFiles

    this.retrieveFieldDirectoriesFromControllerOptions(controllerOptions[CONST.RootDirectory], source, res[CONST.RootDirectory])
    return res
  }

  retrieveFieldDirectoriesFromControllerOptions(currentDirectory, source, res) {
    if (res.jsonPath == null) {
      console.error('Json Path is necessary to render file. Interrupt')
      return null
    }

    if (currentDirectory.directories != null)
      res.subDirectories = []

    for (const directory of emptyIfNull(currentDirectory.directories)) {
      const sourceSubDirectory = this.retrieveSourceSubDirectory(source, directory)
      const [fileDefinitions, extraFiles] = this.retrieveFilesDefinitions(directory, sourceSubDirectory)

      const subDirectory = {
        name: directory.name,
        fileDefinitions: fileDefinitions,
        allowExtraFiles: directory.allowExtraFiles,
        files: extraFiles,
        acceptedMimeTypes: directory.acceptedMimeTypes || res?.acceptedMimeTypes,
        allowFilePreview: directory.allowFilePreview || res?.allowFilePreview,
        jsonPath: `${res.jsonPath}${directory.name}|`,
        fieldName: source?.fieldName,
        primaryKey: source?.primaryKey,
        xsdName: source?.xsdName,
        group: directory.name,
      }

      res.subDirectories.push(subDirectory)

      this.retrieveFieldDirectoriesFromControllerOptions(directory, sourceSubDirectory, subDirectory)
    }

  }

  retrieveSourceSubDirectory(source, directory) {
    let sourceSubDirectory = emptyIfNull(source?.groups).find(group => group.name === directory.name)

    if (sourceSubDirectory == null)
      sourceSubDirectory = emptyIfNull(source?.groups).find(group =>
        toLowerAndRemoveSpace(group.name) === toLowerAndRemoveSpace(directory.name))

    return sourceSubDirectory
  }

  retrieveFilesDefinitions(directory, source) {

    if (source?.files == null) return [directory.files, null]

    let extraFiles = []

    // if directory has no file definitions so all the files are extra files
    if (directory?.files == null || (Array.isArray(directory?.files) && directory?.files.length === 0)) {
      extraFiles = extraFiles.concat(source?.files)
      return [null, extraFiles.length ? extraFiles : null]
    }

    const fileNamesPattern = emptyIfNull(directory.files).map(f => `^${retrieveFileNamePath(f)}`).join('|').replace(/\(/g, '\\(').replace(/\)/g, '\\)') || null
    const filesInDefinition = emptyIfNull(source?.files).filter(file => retrieveFileNamePath(file).match(fileNamesPattern))
    extraFiles = extraFiles.concat(source?.files.filter(file => !retrieveFileNamePath(file).match(fileNamesPattern)))

    const fileDefinitions = emptyIfNull(directory.files).map(fileDefinition => {
      const fileNamePath = retrieveFileNamePath(fileDefinition)
      const files = filesInDefinition.filter(file => retrieveFileNamePath(file).startsWith(fileNamePath))
      return {
        ...fileDefinition,
        files: files.length ? files : null
      }
    })

    return [fileDefinitions.length ? fileDefinitions : null, extraFiles.length ? extraFiles : null]

  }

  recomputeSource(filesTree) {
    if (filesTree == null) return null

    const source = {}
    const rootDirectory = filesTree[CONST.RootDirectory]

    source.fieldName = rootDirectory.fieldName || null
    source.primaryKey = rootDirectory.primaryKey || null
    source.xsdName = rootDirectory.xsdName || null

    this.recomputeSourceSubDirectory(rootDirectory, source)

    return source

  }

  recomputeSourceSubDirectory(directoryTree, source) {

    source.files = this.collectFilesInSubDirectory(directoryTree)
    if (directoryTree.subDirectories == null) {
      source.groups = null
      return
    }

    source.groups = []
    for (const directory of emptyIfNull(directoryTree.subDirectories)) {
      const group = {
        name: directory.name
      }
      source.groups.push(group)
      this.recomputeSourceSubDirectory(directory, group)
    }
  }

  collectFilesInSubDirectory(directory) {
    const files = []
    emptyIfNull(directory.files).forEach(f => files.push(f))
    emptyIfNull(directory.fileDefinitions).forEach(fileDefinition => emptyIfNull(fileDefinition.files).forEach(f => files.push(f)))
    return files.length ? files : []
  }

  async addFileToFilesTree(directoryTree, jsonPath, file) {

    if (directoryTree == null || file == null || jsonPath == null || jsonPath === '') {
      console.error('Cannot compute a source without all necessary components')
      return {}
    }

    const crc = await this.getFileCrc(`${apiUrl}/files${removeLastPipeOrSlash(jsonPath)}/${file.name}/crc`)
    if (crc == null) return

    const rootDirectoryJsonPath = this.formatInitialJsonPath(directoryTree[CONST.RootDirectory])
    if (jsonPath === rootDirectoryJsonPath) {
      const source = this.computeSourceOfFile(directoryTree[CONST.RootDirectory], file)
      this.addFile(source, file, crc)
    } else {
      this.computeFilesTreeOperation(directoryTree[CONST.RootDirectory], rootDirectoryJsonPath,
        jsonPath, file, CONST.PATCH_ADD, crc)
    }

  }

  computeFilesTreeOperation(directory, previousPath, finalPath, file, patchOperation, crc) {

    if (!Array.isArray(directory.subDirectories)) return null

    for (const currentDirectory of emptyIfNull(directory.subDirectories)) {
      const currentPath = `${previousPath}${currentDirectory.name}|`
      if (currentPath === finalPath) {
        switch (patchOperation) {
          case CONST.PATCH_ADD:

            const sourceToAdd = this.computeSourceOfFile(currentDirectory, file)
            return this.addFile(sourceToAdd, file, crc)
          case CONST.PATCH_REMOVE:
            const sourceToRemove = this.computeSourceOfFile(currentDirectory, file)
            return this.removeFile(sourceToRemove, file)
          default:
            console.error(`${patchOperation} Method NOT implemented`)
            return
        }
      }
      this.computeFilesTreeOperation(currentDirectory, currentPath, finalPath, file, patchOperation, crc)

    }

  }

  computeSourceOfFile(directory, file) {

    for (const fileDefinition of emptyIfNull(directory.fileDefinitions)) {
      const fileNamePath = retrieveFileNamePath(fileDefinition)
      if (file.name.startsWith(fileNamePath))
        return fileDefinition
    }

    return directory

  }

  removeFileFromFilesTree(directoryTree, jsonPath, file) {
    if (directoryTree == null || file == null || jsonPath == null || jsonPath === '') {
      console.error('Cannot compute a source without all necessary components')
      return
    }
    const rootDirectoryJsonPath = this.formatInitialJsonPath(directoryTree[CONST.RootDirectory])

    if (jsonPath === rootDirectoryJsonPath) {
      const source = this.computeSourceOfFile(directoryTree[CONST.RootDirectory], file)
      this.removeFile(source, file)
    } else {
      this.computeFilesTreeOperation(directoryTree[CONST.RootDirectory], rootDirectoryJsonPath,
        jsonPath, file, CONST.PATCH_REMOVE)
    }

  }

  filesDefinitionsRequirementInDirectory(directory) {
    let filesDefinitionsRequired = []

    if (Array.isArray(directory.subDirectories)) {
      filesDefinitionsRequired.push(...directory.subDirectories.map(this.filesDefinitionsRequirementInDirectory.bind(this)).flat())
    }

    if (Array.isArray(directory.fileDefinitions)) {
      directory.fileDefinitions.filter(fD => fD.minAmount > 0).forEach(fD => filesDefinitionsRequired.push({...fD, directoryPath: directory.jsonPath}))
    }

    return filesDefinitionsRequired
  }

  collectAllFiles(source, jsonPath) {

    if (jsonPath == null)
      throw new Error('Cannot compute a collect of file without jsonPath')

    let files = []
    if (Array.isArray(source.groups) && source.groups.length > 0) {
      files = [...source.groups.map((group) => this.collectAllFiles(group, `${jsonPath}${group.name}|`)).flat()]
    }

    if (Array.isArray(source.files) && source.files.length > 0) {
      source.files.forEach(f => files.push({...f, jsonPath: jsonPath}))
    }

    return files
  }

  retrieveFilesRequired(field, source) {
    if (field == null || source == null) {
      console.error('Cannot check files with null parameters')
      return null
    }

    const jsonPath = this.formatInitialJsonPath(source)

    return this.collectAllFiles(source, jsonPath)
  }

  validateField(field){

    if ( !field ) return

    let fieldSource = field.source || field.value
    try {
      fieldSource = JSON.parse(fieldSource)
    } catch (e) {
      console.error('Cannot validate File Field')
      return {[field.id]: {}}
    }
    const filesTree = this.getFilesTree(fieldSource, field.controllerOptions)
    if (filesTree == null) {
      return null
    }

    const fileList = this.retrieveFilesRequired(field, fieldSource);
    if (field.required && !fileList.length) {
      const root = {}
      this.retrieveRootDirectory(fieldSource, root)
      return { [field.id]: [root.rootDirectory.jsonPath] }
    }

    const filesDefinitionsRequired = this.filesDefinitionsRequirementInDirectory(filesTree[CONST.RootDirectory])
    const filesDefinitionsNotValid = filesDefinitionsRequired.filter(fD => ((fD.minAmount || 0) > emptyIfNull(fD.files).length) || ((fD.maxAmount || Number.MAX_VALUE) < emptyIfNull(fD.files).length))
    if (filesDefinitionsNotValid.length){
      const fieldError = {}
      fieldError[field.id] = filesDefinitionsNotValid.map( fD => fD.directoryPath)
      return fieldError

    }

    return null

  }


}

const instance = new FileUploaderService()
export default instance
