import React, { useCallback, useMemo, useState } from 'react'

import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getFormValues, reduxForm, reset } from "redux-form";

import { Button } from 'components'
import ResponsiveGrid from "../../RemoteObjectDetails/Components/ROInfo/ResponsiveGrid";
import Footer from './Footer'
import { createRemoteItem } from "../../../utils/appHelper";
import { ResponsiveModal, OverlayFullSpaceSpin } from '../../../providers/StyleProvider/styles'
import * as U from './utils'
import { __ } from '../../../utils/translationUtils'
import {UseAttributesMapQuery} from "../../../providers/QueryClientProvider/queries";

const formName = 'editRemoteObjectItemForm'

const CreateInstance = (props) => {

  const {
    configuration,
    displayDataIds,
    onSubmit: onSubmitCallback,
    dispatch
  } = props

  const { data: attributesMap } = UseAttributesMapQuery()

  const { enumValues, formValues } = useSelector((state) => ({
    enumValues: state?.enumValues,
    formValues: getFormValues('editRemoteObjectItemForm')(state),
  }))

  const [openModal, setOpenModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const gridDisplayAttributes = useMemo(() => {
    if (!configuration) return []
    if (configuration.content) return configuration.content?.displayAttributes?.filter((dAttr) => dAttr.gridPosition)
    return U.getDeprecatedContentDisplayAttributes(configuration.displayAttributes, attributesMap)
  }, [configuration, attributesMap])

  const gridItems = useMemo(() => (
    attributesMap && gridDisplayAttributes.reduce((prev, item) => {
      const attribute = item?.attributeId && attributesMap[item.attributeId]
      if (attribute) prev.push(U.getGridItem(item, attribute, enumValues))
      return prev
    }, [])
  ), [gridDisplayAttributes, attributesMap, enumValues])

  // TODO: subObjectAttributes is an array of sub objects attributes. Currently the sub objects attributes are not supported.
  // TODO: Use this array to display them
  const subObjectAttributes = useMemo(() => (
    attributesMap && displayDataIds?.reduce((prev, id) => {
      const attribute = attributesMap?.[id]
      if (attribute?.type === 'IDS' && attribute?.editable) prev.push(attribute)
      return prev
    }, []) || []
  ), [displayDataIds, attributesMap])

  const onClose = useCallback(() => {
    if (submitting) return
    dispatch?.(reset(formName))
    setOpenModal(false)
  }, [submitting, dispatch])

  const onSubmit = useCallback(() => {
    setSubmitting(true)
    createRemoteItem({
      displayData: formValues,
      accessRightsInfo: { read: true, edit: true }
    }).then(() => {
      onClose()
      onSubmitCallback?.()
    }).finally(() => {
      setSubmitting(false)
    })
  }, [onSubmitCallback, onClose, formValues])

  return (
    <>
      <Button
        onClick={() => setOpenModal(!openModal)}
        title={__('Create RemoteObject')}
        type='filled'
        icon={<PlusOutlined />}
      />
      {
        openModal && (
          <ResponsiveModal
            centered
            fixedheight
            footer={<Footer onSubmit={onSubmit} onClose={onClose} />}
            visible={openModal}
            title={__('Create RemoteObject')}
            onOk={onSubmit}
            onCancel={onClose}
          >
            {submitting && (
              <OverlayFullSpaceSpin />
            )}
            {!!gridItems?.length && (
              <ResponsiveGrid items={gridItems} />
            )}
          </ResponsiveModal>
        )
      }
    </>
  )
}

export default reduxForm({
  form: formName,
})(CreateInstance)
