import styled from 'styled-components'

export const ChartHandlerWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
`

export const StatisticWrapper = styled.div`
  * {
    color: ${(props) => props.theme.colors.thirdary};
    font-size: 6rem;
  }
`

export const TableWrapper = styled.div`
  white-space: pre;
  overflow: scroll;
  height: 100%;
  * {
    white-space: pre;
  }
`
