import {viewPropertiesFields} from "../constants";
import {isAValidTitle} from "../../../constants/regexes";
import * as C from '../constants'
import {visibleFor} from "../../../components/ConfigurationConfiguratorBeta/constants";

const required = (value) => {
  return value || typeof value === 'boolean'
}

const requiredArray = (array) => {
  return Array.isArray(array) && !!array.length
}

const requiredObject = (object) => {
  return !!Object.keys(object).length
}

const validateValue = (value, rules) => {
  if (!rules || (Array.isArray(rules) && !rules.length)) return true
  if (Array.isArray(rules)) {
    return rules.reduce((prev, rule) => (
      prev && rule(value)
    ), true)
  }
  return rules(value)
}

const validateField = (field) => {
  if (!field) return true
  return validateValue(field.value, field.rules)
}

const validateFields = (fields) => {
  if (!fields?.length) return true
  return fields.reduce((prev, field) => {
    return prev && validateField(field)
  }, true)
}

export const validateViewProperties = (viewProperties) => {

  const fields = [
    {
      value: viewProperties[viewPropertiesFields.VIEW_NAME],
      rules: [required, isAValidTitle]
    },
    {
      value: viewProperties[viewPropertiesFields.VISIBLE_FOR],
      rules: required
    },
    {
      value: viewProperties[viewPropertiesFields.SELECT_ENTITIES],
      rules: viewProperties[viewPropertiesFields.VISIBLE_FOR] === visibleFor.SPECIFIC_ENTITIES && requiredArray,
    },
    {
      value: viewProperties[viewPropertiesFields.LAYOUT],
      rules: requiredArray,
    },
  ]

  return validateFields(fields)
}

export const validateAllSteps = (configurations) => {
  return Object.keys(C.steps).reduce((prev, key) => {
    const step = C.steps[key]
    const validationMethod = stepValidation[step]
    prev[step] = validationMethod ? validationMethod(configurations[step]) : true
    return prev
  }, {})
}

export const stepValidation = {
  [C.steps.OUTPUT_FIELDS]: requiredObject,
  [C.steps.VIEW_PROPERTIES]: validateViewProperties,
}

