import moment from 'moment'
import theme from '../../../../providers/StyleProvider/theme'

function getDayProp({ view, workingEvents, timeSlot }) {
  if (view === 'month') {
    const slotDayOfTheWeekCode = moment(timeSlot).days() - 1
    const matchedEvents = workingEvents?.filter((w) => slotDayOfTheWeekCode === w.dayOfTheWeekCode)
    const isWorkingDay = matchedEvents?.length
    return isWorkingDay ? '#ffffff' : theme.colors.gray.thirdary
  }
  return null
}
export default getDayProp
