import _ from "lodash";
import FormItem from "./FormItem";
import React from "react";

export const getDeprecatedContentDisplayAttributes = (displayAttributes, attributesMap) => {
  if (!_.isArray(displayAttributes) || !attributesMap) return []
  return displayAttributes.reduce((prev, dAttr) => {
    const attribute = dAttr?.attributeId && attributesMap[dAttr.attributeId]
    if (!attribute || !attribute.editable || attribute.type === 'IDS' || attribute.type === 'BINARY') return prev
    const index = prev.length
    prev.push({
      ...dAttr,
      gridPosition: {
        w: "3",
        h: "1",
        x: ((index % 4) * 3).toString(),
        y: Math.floor(index / 4).toString(),
      },
    })
    return prev
  }, [])
}

export const getGridItem = (item, attribute, enumValues, ) => {
  if (!item || !attribute) return null
  const { w, h, x, y } = item.gridPosition || {}
  return {
    children: (
      <FormItem
        attribute={attribute}
        enumValues={enumValues}
        disabled={attribute.type === 'BINARY'}
      />
    ),
    cellProperties: {
      w: parseInt(w),
      h: parseInt(h),
      x: parseInt(x),
      y: parseInt(y),
      i: attribute.id
    }
  }
}
