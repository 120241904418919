export default (prevLayout, currentWidget) => {
  if (!prevLayout?.length) {
    return {x: 0, y: 0}
  }
  let i = prevLayout?.length - 1;
  let y = prevLayout[i]?.y + prevLayout[i]?.h;
  let x = prevLayout[i]?.x + prevLayout[i]?.w;
  if (x + currentWidget.minW > 8) {
    return {x: 0, y}
  }
  return {x, y}
}
