import React, {useMemo, useState} from 'react';
import {Link} from "react-router-dom";

import {
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined,
  EyeOutlined,
  FileZipOutlined,
  LoadingOutlined,
  EditOutlined
} from "@ant-design/icons";
import ActionsDropdown from "../../UI/Table/components/ActionsDropdown";
import {__} from "../../../utils/translationUtils";
import {cases} from "../../../utils/stringUtils";

import * as C from './constants'
import {getDetailedAccessRights} from "../../../providers/ApiProvider/order";
import {ERROR} from "components/dist/Utils/LoggerUtils";


type ActionMenuItem = 'overview' | OrderEvent | string

type ActionsMenuProps = {
  orderId: string
  actions?: ActionMenuItem[],
  triggerActionCallback: (orderId: string, status: JobStatus, action: OrderEvent) => void
  editOrder?: (orderId: string) => void
}

const ActionsMenu = ({
                  orderId,
                  actions = ['overview', 'edit', 'cancel', 'archive', 'delete', 'hardDelete'],
                  triggerActionCallback,
                  editOrder
                }: ActionsMenuProps) => {
  const [rights, setRights] = useState<Record<string, boolean> | undefined>(undefined)

  const availActions = useMemo(() => {
    return actions.reduce((acc: Record<ActionMenuItem, boolean>, action) => {
      acc[action] = true
      return acc
    }, {})

  }, [actions])

  const actionsItems = useMemo(() => [
    ...(
      availActions.overview ? [{
        value: 'overview',
        icon: <EyeOutlined/>,
        label: <Link
          to={`/orders/${orderId}/overview`}
        >
          {__('details', cases.CAPITALIZE_SENTENCE)}
        </Link>,
      }] : []
    ),
    ...(
      rights ?
        [
          ...(availActions.edit ? [{
            value: 'edit',
            label: __('edit', cases.CAPITALIZE_SENTENCE),
            disabled: !rights.edit,
            icon: <EditOutlined/>,
            onClick: () => editOrder?.(orderId)
          }] : []),
          ...(availActions.archive ? [{
            value: 'archive',
            label: __('archive', cases.CAPITALIZE_SENTENCE),
            disabled: !rights.archive,
            icon: <FileZipOutlined/>,
            onClick: () => triggerActionCallback?.(orderId, C.JobStatusEnum.toBeProcessed, 'archive')
          }] : []),
          ...(availActions.cancel ? [{
            value: 'cancel',
            disabled: !rights.cancel,
            icon: <CloseOutlined/>,
            label: __('cancel', cases.CAPITALIZE_SENTENCE),
            onClick: () => triggerActionCallback?.(orderId, C.JobStatusEnum.toBeProcessed, 'cancel')
          }] : []),
          ...(availActions.delete ? [{
            value: 'delete',
            disabled: !rights.delete,
            icon: <DeleteOutlined/>,
            label: __('delete', cases.CAPITALIZE_SENTENCE),
            onClick: () => triggerActionCallback?.(orderId, C.JobStatusEnum.toBeProcessed, 'delete')
          }] : []),
          ...(rights.hardDelete && availActions.hardDelete ? [
            {
              value: 'hardDelete',
              icon: <DeleteFilled/>,
              label: __('hard_delete', cases.CAPITALIZE_SENTENCE),
              onClick: () => triggerActionCallback?.(orderId, C.JobStatusEnum.toBeProcessed, 'hardDelete')
            }
          ] : [])
        ] :
        [{
          value: 'loading',
          label: <div style={{textAlign: 'center'}}><LoadingOutlined/></div>,
        }]
    ),

  ], [orderId, availActions, triggerActionCallback, rights])

  return (
    <ActionsDropdown
      actions={actionsItems}
      forceDropdown={true}
      onOpen={() => getDetailedAccessRights(orderId)
        .then((res) => setRights(res.data))
        .catch((err) => {
          ERROR(err)
          setRights(undefined)
        })
      }
    />
  )
}

export default ActionsMenu
