const auth = {
  // SIGN IN
  login: 'login',
  username: 'username or email address',
  password: 'password',
  badCredentials: 'err-wrong-credentials',
  userNotYetActive: 'user_not_yet_active',
  userNoLongerActive: 'user_no_longer_active',

  // SIGN UP - ENTITY SELECTION
  selectEntity: 'company or user group name',

  // SIGN UP
  signUp: 'sign up',
  registrationNotEnabled: 'self registration is not enabled, please contact the management',

  // SIGN UP - VALIDATION
  requiredField: 'the field is mandatory',
  minLength: 'minimum length not reached',
  maxLength: 'maximum length exceeded',
  illegalChars: 'the following characters are illegal',
  invalidInput: 'invalid input',
  invalidPhoneNumber: 'invalid phone number',
  invalidPrefix: 'add country prefix',
  invalidEmail: 'email not valid',
  invalidDomain: 'email domain not allowed',

  // successfulRegistration
  goBackToLogin: 'go back to login',
  passwordsMustMatch: 'PasswordDoesntMatch',

  // FORGOT PASSWORD
  forgotPassword: 'forgot password',
  requestPasswordReset: 'request password reset',

  // RESET PASSWORD
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
}

export default auth
