/* eslint react/no-did-update-set-state: off */
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {hashHistory} from '../providers/HistoryProvider';
import React from 'react';
import PropTypes from 'prop-types';
import {change, formValueSelector} from 'redux-form';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import objectAssign from 'object-assign';

import {
  addAsterisks,
  checkUserRights,
  getInitialPageSize,
  renderResultInfo,
  searchToObject,
  setInitialPageSize,
  showMessage,
} from '../utils/appHelper';

import {Button, DropdownButton, MenuItem, Modal} from 'react-bootstrap';
import Glyphicon from '@strongdm/glyphicon'
import * as config from '../constants/globalConfiguration';
import {deleteReport} from '../providers/ReduxProvider/actions/reportActions';
import {api} from '../providers/ApiProvider'
import {stringify} from 'query-string';
import {__} from '../utils/translationUtils'

const loadingBar = require('nprogress');
const BasicSearchForm = require('../components/BasicSearchForm').default;


class OrderTypeViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      objectId: props.match.params.id,
      deleteModal: {
        open: false,
        configuration: {
          name: '',
          id: 0
        }
      },
      name: '',
      data: [],
      isLoading: false,
      pagination: {
        'pageNumber': 1,
        'pageSize': getInitialPageSize('orderTypeViewPage'),
        'totalCount': 0
      },
      sort: 'name'
    };
  }

  componentDidMount() {
    // initial results
    this.setQueryToState(this.updateHash.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.objectId !== prevProps.match.params.id) {
      this.setState({objectId: prevProps.match.params.id}, () => {
        this.getData();
      });
    }
  }

  setQueryToState(cb) {
    let sq = searchToObject(this.props.location.search);
    this.props.change('basicSearchForm', 'q', sq.q);
    this.setState({
      sort: sq.sort || this.state.sort,
      pagination: objectAssign(this.state.pagination,
        {
          pageNumber: parseInt(sq.pageNumber) || this.state.pagination.pageNumber,
          pageSize: parseInt(sq.pageSize) || this.state.pagination.pageSize,
        }
      )
    }, () => {
      if (typeof cb !== 'undefined') {
        cb();
      }
    });
  }

  getParams() {
    let params = {...searchToObject(this.props.location.search)};
    return objectAssign({}, {
      sort: this.state.sort || params.sort,
      pageNumber: (parseInt(this.state.pagination.pageNumber) > -1) ? parseInt(this.state.pagination.pageNumber) : params.pageNumber,
      pageSize: this.state.pagination.pageSize || params.pageSize,
      q: this.props.q ? addAsterisks(this.props.q) : undefined,
    });
  }

  updateHash() {
    // update url params
    hashHistory.push({
      ...this.props.location,
      search: stringify({
        pageNumber: this.state.pagination.pageNumber,
        pageSize: this.state.pagination.pageSize,
        sort: this.state.sort,
        q: this.state.q,
      })
    }, this.getData());
  }

  search() {
    this.setState({
      q: this.props.q,
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: this.state.pagination.pageSize,
          pageNumber: 1
        }
      )
    }, () => {
      this.updateHash();
    });
  }

  getData() {
    const {type} = this.props;
    this.setState({isLoading: true});
    loadingBar.start();
    let params = {...this.getParams.bind(this)(), pageNumber: this.getParams().pageNumber};

    params.status = 'disabled,enabled,reserved';

    return api.get(`orderGroups/${this.state.objectId}`, {params: params})
      .then(
        response => {
          this.setState({
            data: type === 'view' ? response.data.associatedViews : response.data.associatedReports,
            isLoading: false,
            name: response.data.name,
            pagination: objectAssign(
              this.state.pagination, {
                totalCount: parseInt(response.headers['x-total-count']),
                pageSize: parseInt(response.headers['x-page-size']),
                pageNumber: parseInt(response.headers['x-page'])
              }
            )
          }, () => {
            loadingBar.done();
          });
        },
        error => {
          showMessage('error', error);
          this.setState({
            isLoading: false,
          }, () => {
            loadingBar.done();
          });
        }
      );
  }

  onSortChange(sortName, sortOrder) {
    this.setState({sort: ((sortOrder === 'asc') ? '' : '-') + sortName}, function () {
      this.updateHash();
    });
  }

  onPageChange(page) {
    this.setState({
        pagination: objectAssign(
          this.state.pagination, {
            pageNumber: page
          }
        )
      },
      () => {
        this.updateHash();
      });
  }

  confirmDelete(configuration) {
    this.setState({deleteModal: {open: true, configuration}});
  }

  handledelete() {
    this.props.deleteReport(this.state.deleteModal.configuration.id).then(() => {
      this.getData();
    });
    // Close modal:
    this.closeDeleteModal();
  }

  closeDeleteModal() {
    this.setState({deleteModal: {...this.state.deleteModal, open: false}});
  }

  setPageSize(val) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: val,
          pageNumber: 1
        }
      )
    }, () => {
      setInitialPageSize('orderTypeViewPage', val);
      this.updateHash();
    });
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.setPageSize.bind(this)}
        bsStyle="link"
        title={this.state.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config.pageSizeOptions.map((val, i) => {
          return <MenuItem key={i} eventKey={val}>{val}</MenuItem>;
        })}
      </DropdownButton>
    );
  }

  trClassFormat(row) {
    return row.status;  // return class name.
  }

  render() {
    const {type} = this.props;
    let userRights = [];
    if (this.props.user) {
      userRights = this.props.user.rights;
    }

    function linkFormater(cell, row) {
      return <Link className="stronger" to={`/${type}s/${row.configuration.id}`}>{row.configuration.name}</Link>;
    }

    function capitalizeFirstLetter(string) {
      if (!string) return
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function actionsFormater(cell, row) {
      return (
        <div className="actions text-right">
          <Button
            bsStyle="danger"
            className="btn btn-danger btn-sm btn-no-padding"
            onClick={() => this.confirmDelete.bind(this)(row.configuration)}
          >
            <Glyphicon glyph="remove"/>{__('Remove from Group')}
          </Button>
        </div>
      );
    }

    const {rightMappings} = global.constants

    return (
      <div id="main-content">

        <h1 className="sticky">
          <div className="container">
            {__('Order Type')} {this.state.name}aa
          </div>
        </h1>

        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-lg-offset-1 col-sm-12">
              <div className="col-xs-12 col-sm-6 col-lg-4">
                <BasicSearchForm
                  isLoading={this.state.isLoading}
                  itemType={type?.toLowerCase()}
                  initialValues={{
                    q: searchToObject(this.props.location.search).q,
                  }}
                  onSubmit={this.search.bind(this)}
                />
              </div>

              {checkUserRights(userRights, rightMappings.CAN_CREATE_REPORT) &&
                <Link className="btn btn-primary col-xs-12 col-sm-3 pull-right gutter-bottom"
                      to={`/orderGroups/${this.state.objectId}/edit`}><Glyphicon glyph="pencil"/>
                  {__(`Edit this Order Group`)}
                </Link>
              }
            </div>
          </div>

          {this.state.data &&
            <BootstrapTable
              data={this.state.data}
              remote={true}
              pagination={false}
              /*hover*/
              striped
              trClassName={this.trClassFormat}
              fetchInfo={{dataTotalSize: this.state.pagination.totalCount}}
              options={{
                onPageChange: this.onPageChange.bind(this),
                onSortChange: this.onSortChange.bind(this),
                noDataText: this.state.isLoading ? __('loading_data') : __('NoDataFound'),
                hideSizePerPage: false,
                firstPage: 'First',
                lastPage: 'Last',
                sizePerPageList: config.pageSizeOptions,
                paginationShowsTotal: renderResultInfo.bind(this),
                page: this.state.pagination.pageNumber,
                sizePerPage: this.state.pagination.pageSize,
              }}
            >
              <TableHeaderColumn
                dataSort
                isKey={true}
                hidden={true}
                dataField="id">
                {__('Id')}
              </TableHeaderColumn>

              <TableHeaderColumn
                dataSort
                dataField="name"
                dataFormat={linkFormater.bind(this)}
              >
                {__(`${capitalizeFirstLetter(type)} Name`)}
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={actionsFormater.bind(this)}
              />
            </BootstrapTable>}

          <Modal
            backdrop="static"
            show={this.state.deleteModal.open}
            onHide={this.closeDeleteModal.bind(this)}>
            <Modal.Header closeButton>
              <Modal.Title>{__('Confirm Deletion')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{__('Are you sure you want to delete')} "<strong>{this.state.deleteModal.configuration.name}</strong>"?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeDeleteModal.bind(this)}>
                {__('Back')}
              </Button>
              <Button onClick={this.handledelete.bind(this)} bsStyle="danger">
                {__('Delete')}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

      </div>
    );
  }
}

OrderTypeViewPage.propTypes = {
  type: PropTypes.string,
  deleteReport: PropTypes.func,
  change: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object,
  q: PropTypes.string,
  report: PropTypes.object,
};

const selector = formValueSelector('basicSearchForm');
export default connect(
  state => {
    return {
      user: state.user.data,
      q: selector(state, 'q'),
    };
  },
  {deleteReport, change}
)(OrderTypeViewPage);
