import React, {useCallback, useEffect, useMemo, useState} from "react"
import {
  Badge,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Result,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  Upload
} from "antd"
import { Button } from "components"
import { Icon } from "@ant-design/compatible"
import mime from 'mime-types'
import { api } from '../../providers/ApiProvider'
import {
  checkUserRights,
  getAuthData,
  getInstanceLogoUrl,
  showMessage,
  fetchEntitySettings,
  fetchEntity,
} from '../../utils/appHelper'
import { Panel } from "react-bootstrap"
import Glyphicon from '@strongdm/glyphicon'
import { hashHistory } from "../../providers/HistoryProvider"
import * as config from "../../constants/globalConfiguration"
import { CUSTOM_LOGO, CUSTOM_LOGO_NAME } from "../../constants/globalConfiguration"
import AttributesWidget, { MAX_N_OF_SELECTABLE_ATTRIBUTES } from "../AttributesWidget"
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useSelector } from "react-redux"
import Avatar from "antd/es/avatar"
import { UploadOutlined } from "@ant-design/icons"
import { getBase64 } from "../../components/EntityAvatar/utils"
import {DeleteOutlined, EditOutlined} from "@ant-design/icons"
import { getUserRegistrationPayload } from "../../components/EntitySetting/utils"
import * as S from './styles'
import * as C from '../../components/EntitySetting/constants'
import useInit from "../../hooks/useInit"
import { ERROR } from "components/dist/Utils/LoggerUtils"
import UserRegistrationSettings from "../../components/EntitySetting/UserRegistrationSettings"
import SettingsHeader from "../../components/EntitySetting/SettingsHeader"
import { __, T } from '../../utils/translationUtils'
import { updateEntitySettings, updateSystemConfigurations } from "../../providers/ApiProvider/settings"
import { getSettingsPanels } from "../../components/EntitySetting/config";
import { getFileBase64 } from "../../components/DooOrderPage/Step/customRender/FileUploader/utils/file";
import { cases } from '../../utils/stringUtils'
import {ResponsiveModal} from "../../providers/StyleProvider/styles";
import PinnedDashboardsSettings from "./PinnedDashboardsSettings";
import {useQueryClient} from "react-query";
import Loading from "../../components/Loading";

const { Title } = Typography

const EntitySettingsPage = (props) => {
  const queryClient = useQueryClient()
  const guiUser = useSelector((state) => state?.user?.data)
  const userRights = guiUser?.rights

  const [loading, setLoading] = useState(null)

  const [settingsForm] = Form.useForm()
  const [systemConfigurationForm] = Form.useForm()

  const [panelOpen, setPanelOpen] = useState([])
  const [entityInfo, setEntityInfo] = useState(null)
  const systemSettings = useSelector((state) => state?.ui?.systemSettings)
  const [logoSrc, setLogoSrc] = useState(null)

  const [entitySettings, setEntitySettings] = useState(null)

  const [availableDashboards, setAvailableDashboards] = useState(null)
  const [pinnedDashboards, setPinnedDashboards] = useState(null)
  const [pinnedDashboardModalOpen, setPinnedDashboardModalOpen] = useState(false)

  const entityId = props.match.params.entityId
  const { rightMappings } = global.constants

  const mounted = useInit()

  const settingsPanels = useMemo(() => getSettingsPanels(), [])

  const getAvailableDashboards = (pinnedDashboardIds = []) => {
    api.get(`${config.prefix()?.REPORT || ''}/dashboards?pageSize=${100}&pageNumber=1`)
      .then((response) => {
        setAvailableDashboards(response.data || [])
        setPinnedDashboards((response.data || []).filter(({ id }) => pinnedDashboardIds.includes(id)))
      })
      .catch((err) => ERROR(err))
      .finally(() => setLoading(false))
  }

  const getEntityData = useCallback((entityId) => {
    setLoading(true)
    fetchEntitySettings(entityId)
      .then((settings) => {
        if (mounted.current) {
          setEntitySettings(settings)
          getAvailableDashboards(settings.pinnedDashboardIds)
        }
      })
  }, [entityId])

  useEffect(() => {
    setLoading(true)
    fetchEntity(entityId).then((entity) => mounted.current && setEntityInfo(entity))
    getEntityData(entityId)
  }, [entityId])

  function handleSubmit(pinnedDashboardIds, defaultPinnedDashboardId) {
    const values = settingsForm.getFieldsValue()

    let payload = {
      ...entitySettings,
      entityId: entityId,
      userRegistration: getUserRegistrationPayload(values, entitySettings?.userRegistration),
      pinnedDashboardIds,
      defaultPinnedDashboardId,
    }

    updateEntitySettings(entityId, payload)
      .then(() => {
        getEntityData(entityId)
        queryClient.invalidateQueries({queryKey: ['fetchUsersDashboards']})
      })
  }

  useEffect(() => {
    if (!systemSettings) {
      return;
    }
    systemConfigurationForm.setFieldsValue(systemSettings);
    if (systemSettings?.[CUSTOM_LOGO]) {
      api.get(`staticFiles/logo/${systemSettings?.[CUSTOM_LOGO]}`, { responseType: 'arraybuffer' })
        .then(
          (result) => {
            getFileBase64(new Blob([result.data], { type: mime.lookup(systemSettings?.[CUSTOM_LOGO]) })).then(
              (data) => setLogoSrc(data)
            )
          }
        ).catch(() => {
        })
    }
  }, [systemSettings])

  const onFinishSystemConfiguration = () => {
    const systemConfigurationFormValues = systemConfigurationForm.getFieldsValue();
    const payload = Object.entries(systemConfigurationFormValues).map(([key, value]) => ({ key, value }));

    return updateSystemConfigurations(payload)
  }

  const uploadLogoUrlProps = {
    name: 'file',
    action: getInstanceLogoUrl(CUSTOM_LOGO_NAME),
    headers: { 'x-auth-token': getAuthData() },
    accept: "image/*",
    fileList: [],
    beforeUpload: async (file) => {
      if (!file) return false
      const maxFileSizeMB = 2
      const fileSizeMB = ((file.size / 1024) / 1024).toFixed(4)
      if (fileSizeMB > maxFileSizeMB) {
        ERROR('Cannot update image bigger then 2MB')
        return false
      }
    },
    onChange: async (info) => {

      if (info.file.status === 'error') {
        ERROR(`${info.file.name} file upload failed.`)
        return
      }

      updateSystemConfigurations([{ key: CUSTOM_LOGO, value: CUSTOM_LOGO_NAME }], __(T.logoAddedCorrectly))
        .then(async () => {
          setLogoSrc(await getBase64(new Blob([info.file.originFileObj || info.file], { type: "image/png;" })))
        })
    }
  }

  const removeLogo = () => {
    api.delete(getInstanceLogoUrl(CUSTOM_LOGO_NAME))
      .then(() => {
        updateSystemConfigurations([{ key: CUSTOM_LOGO, value: null }], __(T.logoRemovedCorrectly))
          .then(() => {
            setLogoSrc(null)
          })
      },
        (error) => showMessage('error', `${__(T.error)} ${error}`)
      )
  }

  return (
    <div id="main-content">
      <h1 className={`sticky`}>
        <div className="container">
          {
            entityInfo
            && (
              <><Icon type={entityInfo.type === 'organisation' ? 'cluster' : 'team'} /> {`${entityInfo.shortName}`}</>
            )
          }
        </div>
      </h1>
      <br />
      <div className="container">
        {loading && <Loading />}
        <div>
          <Panel>
            <Panel.Heading>
              {`${entityInfo?.type && __(config.entityTypes[entityInfo.type].name + " settings", cases.CAPITALIZE)}`}
            </Panel.Heading>
            <Panel.Body>
              <S.CollapseWrapper>
                <Collapse
                  bordered={false}
                  activeKey={panelOpen}
                  destroyInactivePanel={false}
                >
                  <Collapse.Panel
                    key="1"
                    header={
                      <SettingsHeader
                        panelOpen={panelOpen}
                        setPanelOpen={setPanelOpen}
                        {...settingsPanels.userSelfRegistration}
                      />
                    }
                    showArrow={false}
                    style={{ border: "none" }}
                  >
                    {
                      <Row>
                        <Col span={24}>
                          {
                            (checkUserRights(userRights, rightMappings.CAN_MANAGE_ENTITY_SETTINGS)) ?
                              (
                                <UserRegistrationSettings
                                  {...{
                                    entitySettings,
                                    settingsForm,
                                    handleSubmit: () => handleSubmit(entitySettings.pinnedDashboardIds, entitySettings.defaultPinnedDashboardId)
                                  }}
                                />
                              ) :
                              (
                                <Result
                                  status="403"
                                  title={__(T.unauthorized)}
                                  subTitle={__(T.unauthorizedExtended)}
                                />
                              )
                          }
                        </Col>
                      </Row>
                    }
                  </Collapse.Panel>
                  {
                    (entityInfo?.instanceOwner) && (
                      <Collapse.Panel
                        showArrow={false}
                        header={
                          <SettingsHeader
                            panelOpen={panelOpen}
                            setPanelOpen={setPanelOpen}
                            {...settingsPanels.widgetSettings}
                          />
                        }
                        key="2"
                      >
                        <Row>
                          <Col span={12}>
                            <AttributeSelectionModal />
                          </Col>
                        </Row>
                      </Collapse.Panel>
                    )
                  }
                  <Collapse.Panel
                    showArrow={false}
                    header={
                      <SettingsHeader
                        panelOpen={panelOpen}
                        setPanelOpen={setPanelOpen}
                        {...settingsPanels.dashboardsSettings}
                      />
                    }
                    key="3"
                  >
                    {!!pinnedDashboards && (
                      <Row>
                        <Col span={24}>
                          <Table
                            // pagination={pagination}
                            // onChange={handlePaginationChange}
                            dataSource={pinnedDashboards}
                            columns={[
                              {
                                title: __(T.dashboardName),
                                dataIndex: 'name',
                                key: 'name',
                                render: (e, r) => <Link to={`/dashboards/${r.id}/view`}>{r.name}</Link>,
                                sorter: () => 0
                              },
                              {
                                title: __(T.lastUpdateDateTime),
                                dataIndex: 'lastUpdateDateTime',
                                key: 'lastUpdateDateTime',
                                sorter: () => 0,
                                render: (e) => (e ? moment(e).format(config.appDefaults.dateTimeFormat) : '')
                              }
                            ]}
                            rowKey="id"
                          />
                          <button
                            className={'btn btn-primary '}
                            size="large"
                            style={{ float: "left", marginTop: '20px' }}
                            onClick={() => setPinnedDashboardModalOpen(true)}
                          >
                            {__(T.configPinnedDashboards, 'capitalize_sentence')}
                          </button>
                          {
                            (!!pinnedDashboardModalOpen && !!availableDashboards) && (
                              <PinnedDashboardsSettings
                                submit={({ pinnedDashboardIds, defaultPinnedDashboardId }) => {
                                  handleSubmit(pinnedDashboardIds, defaultPinnedDashboardId)
                                  setPinnedDashboardModalOpen(false)
                                }}
                                close={() => setPinnedDashboardModalOpen(false)}
                                availableDashboards={availableDashboards}
                                pinnedDashboardsIds={entitySettings.pinnedDashboardIds || []}
                                defaultPinnedDashboardId={entitySettings.defaultPinnedDashboardId}
                              />
                            )
                          }
                        </Col>
                      </Row>
                    )}
                  </Collapse.Panel>
                  {
                    (entityInfo?.instanceOwner) && (
                      <Collapse.Panel
                        showArrow={false}
                        header={
                          <SettingsHeader
                            panelOpen={panelOpen}
                            setPanelOpen={setPanelOpen}
                            {...settingsPanels.instanceSettings}
                          />
                        }
                        key="4"
                      >
                        <Row>
                          <Col span={24}>
                            <div style={{
                              display: 'flex',
                              alignContent: 'center',
                              justifyContent: 'center',
                              width: '100%',
                              padding: 8
                            }}>
                              <Space direction='vertical'>
                                {
                                  (logoSrc) ?
                                    (
                                      <>
                                        <Avatar size={128} src={logoSrc} />
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                          <Space direction='horizontal'>
                                            <Tooltip title={__(T.edit)}>
                                              <Upload {...uploadLogoUrlProps}>
                                                <Button icon={<EditOutlined />} />
                                              </Upload>
                                            </Tooltip>
                                            <Tooltip title={__(T.remove)}>
                                              <Button danger icon={<DeleteOutlined />}
                                                onClick={removeLogo}
                                              />
                                            </Tooltip>
                                          </Space>
                                        </div>
                                      </>
                                    )
                                    :
                                    (
                                      <>
                                        <Avatar size={128}>LOGO</Avatar>
                                        <Tooltip title={__(T.upload)}>
                                          <Upload {...uploadLogoUrlProps}>
                                            <Button icon={<UploadOutlined />}>{__(T.uploadLogo)}</Button>
                                          </Upload>
                                        </Tooltip>
                                      </>
                                    )

                                }
                              </Space>
                            </div>
                            <Form
                              name="systemConfiguration"
                              layout="vertical"
                              form={systemConfigurationForm}
                              onFinish={onFinishSystemConfiguration}
                            >
                              <Divider style={{ marginTop: 4, marginBottom: 0 }}>
                                <Title level={4}>
                                  {__(T.pageSize)}
                                </Title>
                              </Divider>
                              <Row gutter={[32, 2]}>
                                <Col span={12}>
                                  <Divider orientation="left">{__(T.views)}</Divider>
                                  <Row>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.max)}
                                        name={C.configurationFields.MAX_VIEW_PAGE_SIZE}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col offset={4} span={6}></Col><Col span={4}>{ }</Col>
                                  </Row>
                                </Col>
                                <Col span={12}>
                                  <Divider orientation="left">{__(T.reports)}</Divider>
                                  <Row>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.max)}
                                        name={C.configurationFields.MAX_REPORT_PAGE_SIZE}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col offset={4} span={6}></Col><Col span={4}>{ }</Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Divider />
                              <Divider style={{ marginTop: 4, marginBottom: 0 }}>
                                <Title level={4}>
                                  {__(T.pinnedViews)}
                                </Title>
                              </Divider>
                              <Row gutter={[32, 2]}>
                                <Col span={12}>
                                  <Divider orientation="left">{__(T.webApp)}</Divider>
                                  <Row>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.max)}
                                        name={C.configurationFields.WEBUI_MAX_PINNED_VIEWS}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.maxLength)}
                                        name={C.configurationFields.WEBUI_MAX_DEFAULT_PINNED_VIEWS}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>{ }</Col>
                                  </Row>
                                </Col>
                                <Col span={12}>
                                  <Divider orientation="left">{__(T.mobileApp)}</Divider>
                                  <Row>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.max)}
                                        name={C.configurationFields.MOBILE_MAX_PINNED_VIEWS}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.maxDef)}
                                        name={C.configurationFields.MOBILE_MAX_DEFAULT_PINNED_VIEWS}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>{ }</Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Divider />
                              <Divider style={{ marginTop: 4, marginBottom: 0 }}><Title
                                level={4}>{__(T.pinnedReports)}</Title></Divider>
                              <Row gutter={[32, 2]}>
                                <Col span={12}>
                                  <Divider orientation="left">{__(T.webApp)}</Divider>
                                  <Row>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.max)}
                                        name={C.configurationFields.WEBUI_MAX_PINNED_REPORTS}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.default)}
                                        name={C.configurationFields.WEBUI_MAX_DEFAULT_PINNED_REPORTS}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={12}>
                                  <Divider orientation="left">{__(T.mobileApp)}</Divider>
                                  <Row>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.max)}
                                        name={C.configurationFields.MOBILE_MAX_PINNED_REPORTS}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                    <Col offset={4} span={6}>
                                      <Form.Item
                                        label={__(T.maxDef)}
                                        name={C.configurationFields.MOBILE_MAX_DEFAULT_PINNED_REPORTS}
                                        rules={[{ required: true }]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Divider />
                              <button
                                className={'btn btn-primary'}
                                size="large"
                                htmlType="submit"
                                style={{ float: "right", marginRight: "-6px" }}
                              >
                                {__(T.save)}
                              </button>
                            </Form>
                          </Col>
                        </Row>
                      </Collapse.Panel>
                    )
                  }
                </Collapse>
              </S.CollapseWrapper>
            </Panel.Body>
            <Panel.Footer>
              <div className="group">
                <button className="btn btn-default pull-left" type="button" onClick={() => { hashHistory.goBack() }}>
                  <Glyphicon glyph="chevron-left" />{__('Back')}
                </button>
              </div>
            </Panel.Footer>
          </Panel>
        </div>
      </div>
    </div>
  );
};

const AttributeSelectionModal = () => {
  const [isModalOpen, openModal] = useState(null)
  const [id, setId] = useState(null)
  const [totalAttributesLength, setTotalAttributesLength] = useState(0)
  const [attributeLength, setAttributeLength] = useState(0)

  useEffect(() => {
    async function fetchData() {
      let currentId = await api.get(`${config.prefix()?.REPORT || ''}/cube/configurations`)
        .then((e) => e?.data?.[0]?.id)
        .catch((err) => ERROR(err))
      if (!currentId) {
        const response = await api.post(`${config.prefix()?.REPORT || ''}/cube/configurations`, {
          title: 'main',
          attributes: []
        })
        currentId = response.headers['x-location']
      }

      const currentConfiguration = await api.get(`${config.prefix()?.REPORT || ''}/cube/configurations/${currentId}`).then((d) => d.data)
        .catch((err) => ERROR(err))

      setAttributeLength((currentConfiguration?.attributes) ? currentConfiguration?.attributes.filter((e) => e.attributeId !== 'primary_key').length : 0)
      setTotalAttributesLength(MAX_N_OF_SELECTABLE_ATTRIBUTES)
      setId(currentId)
    }

    fetchData()
  }, [])

  return (
    <>
      <Button onClick={() => openModal(true)}>
        <div>
          <Space>
            {__(T.openAttributesSelection)}
            <Badge
              showZero count={`${attributeLength} of ${totalAttributesLength}`}
              className="site-badge-count-4"
              style={{ backgroundColor: '#0B4F7A' }}
            />
          </Space>
        </div>
      </Button>
      <ResponsiveModal
        title="Attribute selection"
        visible={isModalOpen}
        centered
        footer={
          <>
            <Button
              onClick={() => openModal(false)}
              title={__('Cancel')}
              type={'text'}
            />
            <Button
              type='filled'
              title={__('Save')}
              form='widget-attributes-form'
            />
          </>
        }
        onCancel={() => openModal(false)}
      >
        {
          id && (
            <AttributesWidget
              match={{ params: { id } }}
              onSubmit={setAttributeLength}
              openModal={openModal}
            />
          )
        }
      </ResponsiveModal>
    </>
  )
}

export default EntitySettingsPage
