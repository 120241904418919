const errors = {
  somethingWentWrong: 'Something_went_wrong',
  showError: 'show_error',
  errorLog: 'error_log',
  componentStackError: 'component_stack_error',
  copyToClipboard: 'copy_to_clipboard',
  copiedToClipboard: 'copied_to_clipboard',
  failedToCopy: 'failed_to_copy',
  close: 'close'
}

export default errors
