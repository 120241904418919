import React, {useCallback, useRef, useState} from 'react'

import ConfiguratorHelper from './ConfiguratorHelper';
import {useROEConfiguratorContext} from "../../context";
import {useTranslationList} from "../../../../hooks";
import { pagination } from "../../constants";
import { __ } from '../../../../utils/translationUtils'


export default () => {
  const { state } = useROEConfiguratorContext()
  const {
    activeStep,
    validationState,
    validationVisibility,
    attributesMap,
    valueTypesMap,
  } = state || {}

  const [formItems, setFormItems] = useState(null)
  const translationItems = useTranslationList()
  const formRef = useRef(null)

  const getItemFormInitialValue = useCallback(() => {
    return {
      pageSize: 5,
      listingLayout: 'CARD',
    }
  }, [valueTypesMap, attributesMap])

  const onFormOpen = useCallback(() => {
    setFormItems([
      {
        name: 'pageSize',
        label: __('default_page_size'),
        type: 'select',
        span: 12,
        items: pagination.map((item) => ({ value: item, label: item }))
      },
      {
        name: 'listingLayout',
        label: __('default_view'),
        span: 12,
        type: 'radio',
        items: [{ value: 'CARD', label: 'Card' }, { value: 'TABLE', label: 'Table' }],
      },
    ])
  }, [valueTypesMap, translationItems])

  return (
    <ConfiguratorHelper
      formRef={formRef}
      formItems={formItems}
      treeFilter={(items) => items?.filter(({ type }) => type === "IDS")}
      onFormOpen={onFormOpen}
      getItemFormInitialValue={getItemFormInitialValue}
      labels={{
        noSelectedItemsTitle: __('content_fields_no_data_title'),
        noSelectedItemsContent: __('content_fields_no_data_content'),
      }}
      left={{
        title: __("choose_fields"),
        required: true,
        error: validationVisibility[activeStep] && validationState[activeStep] != null && !validationState[activeStep]
      }}
      right={{title: __("set_and_order_selected_fields")}}
    />
  )
}
