import styled from "styled-components";
import { Collapse } from "antd";

export const MainObjectContainer = styled(Collapse)`
  max-height: 40vh;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
  > .ant-collapse-item {
    height: 100%;
    overflow: auto;
    margin-bottom: 0 !important;
    border: unset !important;
    background-color: #FCFCFF;
    padding: 15px;
    &:not(.ant-collapse-item-active) {
      padding: 0;
      box-shadow: 3px 3px 5px -4px #3330;
    }
    > .ant-collapse-header {
      display: none;
    }
    > .ant-collapse-content {
      padding-top: 20px;
      width: 100%;
      position: relative;
      min-height: 1px;
      > .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
  }
`

export const ROEItemContainer = styled.div`
  > .form-group {
    margin-bottom: 0;
    margin-left: 0;
    > * {
      padding: 0;
      width: 100%;
      > .form-control-static {
        line-height: 16px;
        font-size: 12px;
        font-weight: 400;
        min-height: 12px;
        padding: 0;
      }
    }
  }
`

export const ROEItemTitle = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #5D5E61;
`


