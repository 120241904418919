import _ from "lodash";
import React from "react";
import { getStepButtons } from "./utils";
import styled from "styled-components/macro";


const ButtonsWrapper = styled.div`
  & > span {
    margin-right: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
//  TODO: remove last child margin
`
const StepButtons = ({ parent, step }) => {
  if ( !step ) return null

  const leftButtons = _.sortBy(step.buttons.filter((b) => (!b.group.alignment) || b.group.alignment === 'LEFT'), (b) => b.group.position)
  const rightButtons = _.sortBy(step.buttons.filter((b) => b.group.alignment === 'RIGHT'), (b) => b.group.position)

  const ButtonObjects = [
    ...getStepButtons(leftButtons, parent),
    ...getStepButtons(rightButtons, parent)
  ]

  return (<ButtonsWrapper>{ ButtonObjects }</ButtonsWrapper>)
}

export default StepButtons
