import { removeLastPipeOrSlash } from "../../../../../fileUploader/services/utils";
import * as config from "../../../../../../constants/globalConfiguration";
import { isImage } from "../../../../../../utils/appHelper";

const apiUrl = config.getApiUrl()

const getFileUrl = (file, directory) => `${apiUrl}/files${removeLastPipeOrSlash(directory.jsonPath)}/${file.name}`
const getThumbUrl = (file, directory, size = "medium") => {
  const filename = file.name.replace(/(\.[\w_-]+)$/i, `-${size}$1`)
  return `${apiUrl}/staticFiles/thumbnails${removeLastPipeOrSlash(directory.jsonPath)}/${filename}`
}

export const getFileResourceInfo = (file, directory) => {
  const is_image = isImage(file.type)
  
  return {
    url: getFileUrl(file, directory),
    smallUrl: is_image && getThumbUrl(file, directory, "small"),
    mediumUrl: is_image && getThumbUrl(file, directory, "medium"),
  }
}

export const getExtensionFromFileName = (filename) => {
  const patternExtension = /\.[0-9a-z]+$/i
  return patternExtension.exec(filename)?.[0]
}
