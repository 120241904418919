import React from 'react'

import { Form, Select, DatePicker, Input, TimePicker, Space } from 'antd'
import Appointment from './Appointment'
import * as config from '../../../constants/globalConfiguration'
import { findActiveProcess } from '../../../utils/appHelper'
import { __ } from '../../../utils/translationUtils'

const { NON_WORKING_EVENT, WORKING_EVENT, GODOO_EVENT } = global.constants.eventTypes

const { RangePicker } = DatePicker

const CalendarEventForm = (props) => {
  const { user, initialValues, selectedCompanies, onSubmit, mode, closeModal, calendarControlProps, appointmentFormRef, eventTypeCodes, companiesTree, attachedUsersMap } = props

  const [form] = Form.useForm()

  if (calendarControlProps) {
    if (findActiveProcess([].concat(calendarControlProps.order.processes))) {
      return (
        <Appointment
          start={initialValues.dateRange[0]}
          end={initialValues.dateRange[1]}
          user={user}
          companiesTree={companiesTree}
          attachedUsersMap={attachedUsersMap}
          onSubmit={onSubmit}
          closeModal={closeModal}
          selectedCompanies={selectedCompanies}
          appointmentFormRef={appointmentFormRef}
          {...calendarControlProps}
        />
      )
    }
    return null
  }

  return (
    <Form
      layout="vertical"
      name="user-event-form"
      onFinish={onSubmit}
      form={form}
      initialValues={initialValues}
      id="calendar-event-form-id"
    >
      <Form.Item
        label={__('Select an event type')}
        name="eventType"
        hidden={initialValues.eventType} // fixed eventType: the user cannot change it
      >
        <Select disabled={mode === 'edit'}>
          <Select.Option value={WORKING_EVENT}>🕒 {__('Working hours')}</Select.Option>
          <Select.Option value={NON_WORKING_EVENT}>🏠 {__('Personal event')}</Select.Option>
          <Select.Option value={GODOO_EVENT}>🏠 {__('Appointment event')}</Select.Option>
        </Select>
      </Form.Item>

      {user && (
        <Form.Item
          label={__('Select an entity')}
          name="entitiesId"
          rules={[{ required: true, message: __('Please select the entity connected to the event') }]}
        >
          <Select disabled={mode === 'edit'} mode="multiple" placeholder={__('Select an entity')}>
            {user.belongsTo.map((entity) => (
              <Select.Option key={entity.id} value={entity.id}>{entity.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const eventType = getFieldValue('eventType')

          if (eventType === NON_WORKING_EVENT) {
            return (
              <>
                <Form.Item
                  label={__('Title')}
                  name="title"
                  rules={[{ required: true, message: __('Please enter the event title') }]}
                >
                  <Input placeholder={__('Event title')} />
                </Form.Item>

                <Form.Item
                  label={__('Event date')}
                  name="dateRange"
                  rules={[{ required: true, message: __('Please insert a valid date') }]}
                >
                  <RangePicker
                    showTime
                    format={config.appDefaults.dateTimeFormat}
                    minuteStep={5}
                  />
                </Form.Item>
                <Form.Item
                  label={__('Event type')}
                  name="nonWorkingTypeCode"
                  rules={[{ required: true, message: __('Please select the event type') }]}
                >
                  <Select placeholder={__('Select an event')}>
                    {
                      eventTypeCodes.map((e) => <Select.Option key={e.code} value={e.code}>{`${global.constants.eventTypeEmoji[e.code]} ${global.constants.eventTypeLabels[e.code]}`}</Select.Option>)
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label={__('Description')}
                  name="description"
                >
                  <Input.TextArea placeholder={__('Event description')} />
                </Form.Item>
              </>
            )
          }

          if (eventType === WORKING_EVENT) {
            return (
              <>
                <Form.Item
                  label={__('Day of the week')}
                  name="dayOfTheWeekCode"
                  rules={[{ required: true, message: __('Please select the day of the week') }]}
                >
                  <Select placeholder={__('Monday')}>
                    <Select.Option value={0}>{__('Monday')}</Select.Option>
                    <Select.Option value={1}>{__('Tuesday')}</Select.Option>
                    <Select.Option value={2}>{__('Wednesday')}</Select.Option>
                    <Select.Option value={3}>{__('Thursday')}</Select.Option>
                    <Select.Option value={4}>{__('Friday')}</Select.Option>
                    <Select.Option value={5}>{__('Saturday')}</Select.Option>
                    <Select.Option value={6}>{__('Sunday')}</Select.Option>
                  </Select>
                </Form.Item>
                <Space>
                  <Form.Item
                    label={__('Starting time')}
                    name="startingTime"
                    rules={[{ required: true, message: __('Please select the starting time') }]}
                  >
                    <TimePicker format="HH:mm:ss" minuteStep={15} />
                  </Form.Item>
                  <Form.Item
                    label={__('Ending time')}
                    name="endingTime"
                    rules={[{ required: true, message: __('Please select the ending time') }]}
                  >
                    <TimePicker format="HH:mm:ss" minuteStep={15} />
                  </Form.Item>
                </Space>
              </>
            )
          }
        }}
      </Form.Item>
    </Form>
  )
}

export default CalendarEventForm
