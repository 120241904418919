import React, {useMemo} from 'react'

import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {checkUserRights} from "../../utils/appHelper";
import NoData from "../UI/NoData";
import { __ } from '../../utils/translationUtils'

export default (props) => {

  const userRights = useSelector((state) => (state?.user?.data?.rights))
  const { rightMappings } = global.constants
  const canManageEntitySettings = useMemo(() => checkUserRights(userRights, rightMappings.CAN_MANAGE_ENTITY_SETTINGS), [userRights, rightMappings])

  return(
    <NoData
      size={'large'}
      space={200}
      title={__('Dashboards are currently not supported')}
      subTitle={canManageEntitySettings ?
        <Link to={`/organisations`}>{__('organisations')}</Link> : __('Ask your supervisor to configure them')}
      subTitleWrap={canManageEntitySettings && {before: __('Go to'), after: __('and start setting up dashboards')}}
    />
  )
}
