export function getFormattedDateTime(date = new Date()) {
  return `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${padLeadingZero(date.getMinutes())}:${padLeadingZero(date.getSeconds())}`;
}

export function padLeadingZero(value) {
  return value > 9 ? value : `0${value}`;
}

export const isValidDate = (dateString) => {
  // Regex to match ISO 8601 date with time and milliseconds like "2020-01-01T23:59:59.999"
  const iso8601WithTimeRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}/;

  // Regex to match ISO 8601 date without time like "2015-01-01"
  const iso8601DateOnlyRegex = /\d{4}-\d{2}-\d{2}/;

  // Check if the string matches either of the formats
  return iso8601WithTimeRegex.test(dateString) || iso8601DateOnlyRegex.test(dateString);
}
