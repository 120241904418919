import { Button } from "antd";
import React from "react";
import { hashHistory } from "../../../providers/HistoryProvider";

import { __ } from '../../../utils/translationUtils'

export default (props) => {

  let recentItemsButtons = renderButtons(props)
  return (
    recentItemsButtons && <div className="most-recent-items-container">{__("Most Recent Items")}:
      {recentItemsButtons}
    </div>
  )
}


const renderButtons = () => {
  let mostRecentItems = JSON.parse(localStorage.getItem('mostRecentItems'))
  let recentButtons = []
  const getUrlForExternalObject = (item) => {
    return `/remoteObjects/details/${item.dependencies}/${item.id}/${item.objectId}`
  }
  const handleOnClick = (item) => {
    if (mostRecentItems.indexOf(item) !== (mostRecentItems.length - 1)) {
      hashHistory.push(getUrlForExternalObject(item));
      mostRecentItems.splice((mostRecentItems.indexOf(item) + 1), mostRecentItems.length)
      localStorage.setItem('mostRecentItems', JSON.stringify(mostRecentItems))
    }
  }
  if (mostRecentItems !== null) {
    mostRecentItems.forEach((item) => {
      recentButtons.push(
        <Button key={item.name} className="btn-link recent-item-button" onClick={() => { handleOnClick(item) }}>
          {item.name}
        </Button>
      )
    }
    )
  }
  return recentButtons
}
