import {api} from "./index";
import {ERROR} from "components/dist/Utils/LoggerUtils";
import * as config from "../../constants/globalConfiguration";
import {showMessage} from "../../utils/appHelper";


// TODO: should be multi property
type filterParam = { name: string, value: string }

type QueryParams = {
  pageSize?: number,
  pageNumber?: number
  sort?: string // (-)propName
  q?: string
  filter?: filterParam
}

//  CUBE
export const fetchCubeConfigurations = async () => api.get(`${config.prefix()?.REPORT || ''}/cube/configurations`)
  .then((response) => response.data)
  .catch((error) => {
    ERROR(error)
  })

export const fetchCubeConfigurationById = async (id: string) =>
  api.get(`${config.prefix()?.REPORT || ''}/cube/configurations/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      ERROR(error)
    })

// WIDGET
export const fetchWidgetConfigurationById = async (id: string) =>
  api.get(`${config.prefix()?.REPORT || ''}/widgets/configuration/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      ERROR(error)
    })

export const fetchWidgets = async ({ pageSize = 25, pageNumber = 1, sort = 'name', q, filter }: QueryParams = {}) => {
  return api.get(`${config.prefix()?.REPORT || ''}/widgets`, { params: { pageSize, pageNumber, q, sort } })
    .then((res) => res)
    .catch(() => {
      showMessage('error', 'Error encountered while fetching widgets');
      return null;
    });
};

// DASHBOARDS
export const fetchDashboardById = async (id: string) =>
  api.get(`${config.prefix()?.REPORT || ''}/dashboards/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      ERROR(error)
    })

export const fetchDashboards = async ({ pageSize = 25, pageNumber = 1, sort = 'name', q, filter }: QueryParams = {}) => {
  const f = filter ? { [filter.name]: filter.value } : {}
  return api.get(`${config.prefix()?.REPORT || ''}/dashboards`,{ params: { pageSize, pageNumber, q, sort, ...f } })
    .then((res) => res)
    .catch(() => {
      showMessage('error', 'Error encountered while fetching dashboards');
      return null;
  });
};
