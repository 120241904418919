import React from 'react';
import PropTypes from 'prop-types';
import {MessageType, MessageText} from "./MessageComponents";
import {showMessage} from "../../../utils/appHelper";
import {Button, Modal} from "react-bootstrap";
import MessageAttachmentContainer from './MessageAttachmentContainer';
import { api } from '../../../providers/ApiProvider'
import { __ } from '../../../utils/translationUtils'


class MessageModal extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      visibility: props.defaultType || 'GLOBAL',
      entityId: props.defaultEntity || '',
      text: props.defaultMessageText || '',
      attachmentPaths: props.defaultAttachmentPath && [props.defaultAttachmentPath] || [],
      isLoading: false
    }

    this.handleChangeEntitySelected = this.handleChangeEntitySelected.bind(this);
    this.handleChangeTypeSelected = this.handleChangeTypeSelected.bind(this);
    this.handleChangeMessageText = this.handleChangeMessageText.bind(this);
    this.handleChangeAttachmentPaths = this.handleChangeAttachmentPaths.bind(this);
    this.closeModal = this.closeModal.bind(this)

    this.addMessage = this.addMessage.bind(this)
  }

  checkingValues(values){
    if ((!values.text || values.text === '') && (!values.attachmentPaths.length || values.attachmentPaths.every( attachment => attachment === '' ))) {
      showMessage('warning', 'Please provide a comment text or a file to upload.');
      return false;
    }

    if (values.visibility === 'ENTITY' && (!values.entityId)){
      showMessage('warning', 'Please provide a comment entity.');
      return false;
    }
    return true;
  }

  postMessage(url, createMessageValues){
    // Replace empty string with null
    Object.keys(createMessageValues).map( key => {
      createMessageValues[key] = createMessageValues[key] === '' ? null : createMessageValues[key];
    })

    return api.post(url, createMessageValues)
  }



  addMessage(){

    // I added this because until the promise is is complete you can repeatedly add comments
    const createMessageValues = (({visibility, entityId, text, attachmentPaths}) =>
      ({visibility, entityId, text, attachmentPaths}))(this.state);

    if (!this.checkingValues(createMessageValues)){return new Promise(() => {})}


    const remainingAttachment = createMessageValues.attachmentPaths.slice();
    const url = `/${this.props.modelCaller}/${this.props.objectCaller.id}/comments`;
    const requests = [];

    do {

      const attachment = remainingAttachment.length && remainingAttachment[0] || ''

      const payload = Object.assign({}, {
        visibility: createMessageValues.visibility,
        entityId: createMessageValues.entityId,
        text: createMessageValues.text,
        attachmentPath: attachment});

      remainingAttachment.length && remainingAttachment.splice(0,1)


      requests.push( Object.assign( {} , { url: url, payload: payload }) )

    }while (remainingAttachment.length);


    // Setting generator function

    this.setState({isLoading: true})

    /*
    * I need to run all the promise in a sequential way, because other way there is a problem in the server
    * because of the lock on the database
    * */
    requests.reduce( (promiseChain, currentTask ) => {

      return promiseChain.then( () => {

        return this.postMessage( currentTask.url, currentTask.payload )

      } )

    }, Promise.resolve() )
      .then( () => {
        this.setState({ isLoading: false }, () => {
          this.props.refreshMessage();
          this.props.closeModal();
        } );
        showMessage('success', 'Comments created successfully');
      })
      .catch(() => {
        this.setState({ isLoading: false } );
        showMessage('error', 'Comments cannot be created');
      })

  }



  closeModal(e){
    if(e?.stopPropagation) e.stopPropagation()
    // If you close the modal after updating a file, delete the file
    this.handleChangeAttachmentPaths([], true)
    this.props.closeModal()
  }

  handleChangeTypeSelected(value){
    this.setState({visibility: value});
  }

  handleChangeEntitySelected(value){
    this.setState({entityId: value});
  }

  handleChangeMessageText(value){
    this.setState({text: value});
  }

  handleChangeAttachmentPaths(paths, deleteAll = false){
    if (deleteAll){
      const { attachmentPaths } = this.state;
      for ( const attachment of attachmentPaths ) {
        api.delete(attachment);
      }

    }


    return this.setState({attachmentPaths: paths});
  }

  render() {
    const editModeOn = true;
    return(
      <Modal
        backdrop="static"
        show={this.props.modalOpen}
        onHide={this.closeModal}
        className="popup-wide">
        <Modal.Header closeButton>
          <Modal.Title className="capitalize">{__('Comments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MessageType typeSelected={this.state.visibility} entitySelected={this.state.entityId}
                       onChangeTypeSelected={this.handleChangeTypeSelected} onChangeEntitySelected={this.handleChangeEntitySelected}
                       editModeOn={editModeOn} {...this.props}
          />
          <MessageText messageText={this.state.text} onChangeMessageText={this.handleChangeMessageText}
                       editModeOn={editModeOn} {...this.props}
          />
          <MessageAttachmentContainer attachmentPaths={this.state.attachmentPaths} onChangeAttachmentPaths={this.handleChangeAttachmentPaths}
                             editModeOn={editModeOn} authToken={this.props.authToken} objectCaller={this.props.objectCaller}
                             {...this.props}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="pull-left" onClick={this.closeModal}>
            {__('Close')}
          </Button>
          <Button
            className="btn-blue"
            onClick={this.addMessage}
            disabled={this.state.isLoading}
          >
            {__('Add Comment')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

}


MessageModal.propTypes = {
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  defaultType: PropTypes.string,
  defaultEntity: PropTypes.object,
  defaultMessageText: PropTypes.string,
  defaultAttachmentPath: PropTypes.string,
  ObjectCaller: PropTypes.object,
  refreshMessage: PropTypes.func,
}

export default MessageModal;
