import React, { useMemo } from "react";

import ROSelector from "./ROSelector";
import { Card } from './styles'
import { Row } from "antd";
import { __ } from '../../../../../../utils/translationUtils'

export default (props) => {
  const {
    id,
    title,
    disabled,
    input,
    parentObjectId,
    parentObjectTypeId,
    attr,
    relation,
    attributes,
    overviewTabEditField,
    objectConfiguration,
    options,
    updateObject,
    highlighted,
  } = props

  const error = useMemo(() => {
    if (!attr) return __('Object Listing is using an unknown attribute')
    if (!attr.relatedObjects) return __('Object_Listing_Error_Missing_Related_Object_In_Attribute')
    return false
  }, [attr, attributes])

  const controlData = {
    objectConfiguration: objectConfiguration,
    source: input.value,
    input: input,
    readOnly: disabled,
    controllerType: 'ObjectListing',
    attribute: attr,
    relation,
    attributes,
  }

  return (
    <Card
      highlight={highlighted}
      {...(id && { id })}
    >
      {error ? (
        <Row justify="center" align="top">{error}</Row>
      ) : (
        <ROSelector
          title={title}
          readOnly={disabled}
          controlData={controlData}
          parentObjectId={parentObjectId}
          parentObjectTypeId={parentObjectTypeId}
          objectTypeId={attr.relatedObjects}
          additionalStyle={options?.additionalStyle || 'overviewTab'}
          permissions={options?.permissions}
          overviewTabEditField={overviewTabEditField}
          type={options && options.type !== 'ROE' ? 'ObjectListing' : 'ROE'}
          updateObject={updateObject}
          currentPage={options.currentPage}
          handlePageChange={options.handlePageChange}
          pageSize={options.pageSize}
          defaultView={options.defaultView}
        />
      )}
    </Card>
  )
}
