import React, { useState } from "react";
import { ViewDetailsButton, ViewDetailsList } from "./styles";

export default ({buttonText, messages}) => {
  if (!Array.isArray(messages)) messages = [messages]
  const [isMessageVisible, setIsMessageVisible] = useState(false)
  
  return (
    <div onClick={() => setIsMessageVisible(message => !message)}>
      <ViewDetailsButton>
        {buttonText ? buttonText : (messages.length === 1 ? "More detail" : "More details")}
      </ViewDetailsButton>
      <ViewDetailsList className={isMessageVisible ? "clicked" : ""} >
        {messages.map((text) => <div>{text}</div>)}
      </ViewDetailsList>
    </div>
  )
}
