import React, { Component } from 'react';
import PropTypes from 'prop-types';
import objectAssign from 'object-assign';
import _ from 'lodash';

import { __ } from '../utils/translationUtils'

class SelectField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
    this.renderList = this.renderList.bind(this);
  }

  renderList() {
    const { items, withFilter } = this.props;
    const hasGroups = _.filter(items, (item) => item && item.type === 'optgroup').length > 0;
    const { filter } = this.state;

    const filterOptions = (items) => {
      return _.filter(items, (item) => {
        return item && item.props && item.props.children && item.props.children.toLowerCase && item.props.children.toLowerCase().includes(filter.toLowerCase());
      });
    };

    const filterItems = (items) => {
      return _.filter(items, (item) => {
        return item && item.props && item.props.children && item.props.children.toLowerCase().includes(filter.toLowerCase());
      });
    };

    const filterGroup = (group) => {
      return objectAssign({}, group, {
        props: {
          label: group.props.label,
          children: _.filter(group.props.children, (option) => {
            return option.props.children.toLowerCase().includes(filter.toLowerCase());
          }),
        }
      } );
    };

    if (withFilter) {
      if (hasGroups) {
        return items.map((group) => {
          const hasItemsToShow = filterItems(group.props.children).length > 0;
          if (hasItemsToShow) {
            return filterGroup(group);
          }
          return null;
         });

      } else {
        return filterOptions(items);
      }
    } else {
      return items;
    }
  }

  render() {
    const { input, className, disabled, multiple, withFilter } = this.props;
    const { filter } = this.state;
    const disabledStyle = {border:'none', cursor:'default', opacity:'1',background:'none', appearance: 'none', boxShadow:'none', paddingLeft: '0'}
    return (
      <>
        {
          withFilter &&
          <input
            value={filter}
            placeholder={`${__('Search')}...`}
            className="pull-right options-filter form-control input-sm col-sm-6"
            onChange={(e) => this.setState({ filter: e.target.value })}
          />
        }
        <select
          style={disabled ? disabledStyle : {}}
          {...input}
          value={(typeof input?.value === 'object' && !Array.isArray(input?.value)) ? input?.value?.value : input?.value}
          className={`${className} scroll`}
          placeholder={`${__('Select One')}`}
          disabled={disabled}
          multiple={multiple}
        >
          {this.renderList()}
        </select>
      </>
    );
  }
}

SelectField.propTypes={
  disabled: PropTypes.bool,
  input: PropTypes.object,
  items: PropTypes.array,
  label: PropTypes.string,
  className: PropTypes.string,
  withFilter: PropTypes.bool,
  multiple: PropTypes.bool,
};
SelectField.defaultProps={
  withFilter: false,
  multiple: false,
  disabled: false,
  items: [],
};

export default SelectField;
