import { __ } from '../../../utils/translationUtils'

export const defaultPagination = {
  current: 1,
  pageSize: 25,
  total: 1,
  showSizeChanger: true,
  pageSizeOptions: [5, 10, 25, 50, 100],
  showTotal: (total) => `${total} ${__('total_items')}`
}

export const wrapperType = {
  DEFAULT: 'DEFAULT',
  MODAL: 'MODAL',
  DRAWER: 'DRAWER',
  CONTENT: 'CONTENT',
}

export const mechanismType = {
  RANGE: 'RANGE',
  SPECIFIC_VALUE: 'SPECIFIC_VALUE',
  ENUMERATION: 'ENUMERATION'
}
