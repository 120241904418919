import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import RemoteObjectForm from '../components/RemoteObjectForceCreatePage/RemoteObjectForm';
import objectAssign from 'object-assign';
import {generateRemoteObject, updateRemoteObject} from '../providers/ReduxProvider/actions/remoteObjectActions'
import {formValueSelector} from 'redux-form';
import {api} from '../providers/ApiProvider'
import {Spin} from "antd";
import { __ } from '../utils/translationUtils'

const loadingBar = require('nprogress');

// import _ from 'lodash';

class RemoteObjectForceCreatePage extends React.Component {
  static propTypes = {
    mode: PropTypes.string,
    remoteObject: PropTypes.object,
    match: PropTypes.object,
    modelValues: PropTypes.object,
    generateRemoteObject: PropTypes.func,
    updateRemoteObject: updateRemoteObject.func,
  };

  constructor(props){
    super(props);
    this.state = {
      objectId: this.props.match.params.id,
      mode: this.props.mode,
      remoteObject: null,
      entities: null,
      isLoading: false,
      errors: null
    };
  }

  componentDidMount() {
    this.init();
  }

  init(){
    if(this.props.mode === 'create'){
      return;
    }
    this.getData();
  }

  getData(){
    this.setState({isLoading: true});
    loadingBar.start();
    return api.get('/configurations/remoteObjects/' + this.state.objectId)
      .then(
        response => {
          this.setState({remoteObject: response.data, isLoading: false});
          loadingBar.done();
        }
      );
  }

  async handleSubmit() {
    this.setState({isLoading: true});
    if (this.props.mode === 'create') {
      try {
        await this.props.generateRemoteObject(this.props.modelValues);
      } catch (errorResponse) {

        try {
          this.setState({
            errors: JSON.parse(errorResponse?.response?.data?.details)
          })
        } catch (e) {
          this.setState({
            errors: {"General Error": errorResponse?.response?.data?.details}
          })
        }

      }
    } else if(this.props.mode === 'edit'){
      this.props.updateRemoteObject(this.props.modelValues);
    }
    this.setState({isLoading: false});
  }

  render() {
    const headerClass = (this.state.mode)?this.state.mode:'';
    return (

      <div id="main-content">
        <Spin spinning={this.state.isLoading}>
          <h1 className={'sticky ' + headerClass}>
            <div className="container">
              {
                (this.state.mode === 'create') ?
                  __("Create Remote Object") : __("Remote Object")
              }
            </div>
          </h1>

          <div className="container">
            <br/>
            {this.state.remoteObject || this.state.mode === 'create' ?
              <RemoteObjectForm
                mode={this.state.mode}
                id={(this.state.mode === 'create')?null:this.props.match.params.id}
                getData={this.getData.bind(this)}
                initialValues={(this.state.mode === 'create')?
                  {}
                  : objectAssign({},
                    {
                      id: this.state.remoteObject.id || null,
                    })}
                remoteObject={this.state.remoteObject}
                onSubmit={this.handleSubmit.bind(this)}
                errors={this.state.errors}
              />
              :
              <span>{__('loading')}</span>
            }
          </div>
        </Spin>
    </div>

    );
  }
}
const selector = formValueSelector('remoteObjectForm');

export default connect(
  state => {
    return {
      modelValues: {
        id: selector(state, 'id'),
      }
    };

  },
  { generateRemoteObject, updateRemoteObject }
)(RemoteObjectForceCreatePage);
