import React, {useEffect, useState} from 'react'
import {Alert, Form, Input, message, Select, Tooltip, TreeSelect} from 'antd'
import { Button } from 'components'
import {Link} from 'react-router-dom'
import {Modal, Panel} from 'react-bootstrap'
import {Icon} from '@ant-design/compatible'
import * as config from '../../constants/globalConfiguration'
import {api} from '../../providers/ApiProvider'
import Loading from '../../components/Loading'
import {convertArrayToObject} from '../../utils/appHelper'
import getAttributesTree from './utility/getAttributesTree'
import producePayload from './utility/producePayload'
import { __ } from '../../utils/translationUtils'
import {fetchCubeConfigurationById} from "../../providers/ApiProvider";
import * as S from './styles'

const {Option} = Select
const {SHOW_PARENT} = TreeSelect

export const MAX_N_OF_SELECTABLE_ATTRIBUTES = 20
const TreeSelectWithInfo = ({ attributesFlat, value, ...props }) => {

  const selectedAttribute = value && attributesFlat.find((e) => e.id === value)

  return (
    <div>
      <TreeSelect {...props} value={value} hidden={value} />
      {selectedAttribute && (
        <S.Info>
          <S.SimpleLabel><strong>Title: </strong>{__(selectedAttribute.propertyLabel)}</S.SimpleLabel>
          <S.SimpleLabel><strong>System: </strong>{selectedAttribute.system}</S.SimpleLabel>
          <S.SimpleLabel><strong>Object: </strong>{selectedAttribute.dependenciesLabel}</S.SimpleLabel>
          {selectedAttribute.system !== 'godoo' && (
            <S.SimpleLabel><strong>Path: </strong>
              <Tooltip title={selectedAttribute.property}>
                {selectedAttribute.property?.slice(0, 25)}
              </Tooltip>
            </S.SimpleLabel>
          )}
          <S.SimpleLabel><strong>Type: </strong>{selectedAttribute.type}</S.SimpleLabel>
        </S.Info>
      )}
    </div>
  )
}

const AttributesWidget = ({ fullscreen, onSubmit, openModal, match: { params: { id: editId } } }) => {
  const [initialAttributesTree, setInitialAttributesTree] = useState(null)
  const [attributesTree, setAttributesTree] = useState(null)
  const [error, setError] = useState(null)
  const [isErrorsDetailsVisible, setErrorDetailsVibile] = useState(null)
  const [attributesFlat, setAttributesFlat] = useState(null)
  const [attributesFlatObject, setAttributesFlatObject] = useState(null)
  const [initialValues, setInitialValues] = useState(null)
  const [loading, setLoading] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    async function fetchData() {
      setLoading(true)

      const responseAttributesTree = await api.get('configurations/attributes/tree').then((response) => response.data)
      const responseRemoteSystems = await api.get('remoteSystems').then((response) => response.data)
      const responseAttributesFlat = await api.get('configurations/attributes').then((response) => response.data)

      setInitialAttributesTree(getAttributesTree(responseAttributesTree, responseRemoteSystems))

      setAttributesFlatObject(convertArrayToObject(responseAttributesFlat, 'id'));
      setAttributesFlat(responseAttributesFlat)

      if (editId) {
        const initialData = await fetchCubeConfigurationById(editId).then((data) => data.attributes.filter((e) => e.attributeId !== 'primary_key'))
        if (initialData) {
          const initialAttributeIds = initialData.map((e) => ({ ...e, measures: e?.measures.map((measure) => measure?.measureName) }));
          setInitialValues({ attributes: initialAttributeIds});
        }
      }


      setLoading(false)
    }
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!initialValues || !initialAttributesTree) return;
    filterAttributeTreeSelect(initialValues?.attributes?.map(f => f.attributeId));
  }, [initialValues, initialAttributesTree]);

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  const filterAttributeTreeSelect = (attributeIds) => {
    setAttributesTree(initialAttributesTree.map(obj => ({
        ...obj,
        children: obj?.children.map(child => ({
            ...child,
            children: child?.children.filter(attribute => (!attributeIds.includes(attribute.value)))
          })
        )
      })
    ));
  };

  const handleChangeAttributeIdInAdd = (addedValue) => {
    let attributeIds = form.getFieldsValue()?.attributes.map(f => f.attributeId);
    attributeIds.filter(val => val !== addedValue);
    filterAttributeTreeSelect(attributeIds);
  };

  async function handleSubmit(formValues) {
    try {
      setLoading(true)

      const payload = producePayload({ ...formValues, attributesFlat })

      if (editId) {
        await api.put(`${config.prefix()?.REPORT || ''}/cube/configurations/${editId}`, payload)
          .then(() => {
            message.success('Attribute correctly edited')
            if (onSubmit) onSubmit(payload.attributes.length)
            openModal(false);
          })
          .catch((e) => {
            setError(e.response.data)
          })
      }
      if (!editId) {
        await api.post(`${config.prefix()?.REPORT || ''}/cube/configurations`, {title: formValues.title, ...payload})
          .then(() => {
            message.success('Attribute correctly created')
            if (onSubmit) onSubmit(payload.attributes.length)
            openModal(false);
          })
          .catch((e) => console.log(e))
      }
    } catch (e) {
      message.error('Something wrong. Fix the form errors')
    }
    setLoading(false)
  }

  const computeMeasureOptions = (field) => {
    let opts = [];
    const { VALUE_TYPES } = global.constants;

    const attrId = initialValues?.attributes[field.key]?.attributeId || form.getFieldsValue()?.attributes?.[field.key]?.attributeId;
    const attr = attributesFlatObject[attrId];

    const valueType = VALUE_TYPES[attr?.type]
    opts.push(<Option key="countDistinct" value="countDistinct">{__('count')}</Option>);

    if(valueType?.type === 'DATE' || valueType?.type === 'NUMERIC'){
      opts.push(<Option key="min" value="min">{__('minimum')}</Option>);
      opts.push(<Option key="max" value="max">{__('maximum')}</Option>);
    }
    if(valueType?.type === 'NUMERIC'){
      opts.push(<Option key="sum" value="sum">{__('sum')}</Option>);
      opts.push(<Option key="avg" value="avg">{__('average')}</Option>);
    }


    return opts;
  };

  return (
    <div id={fullscreen ? 'main-content' : ''}>
      {fullscreen && (
        <h1 className="sticky">
          <div className="container">
            { __('Attributes selection')}
          </div>
        </h1>
      )}
      <div className='container'>
        <Loading loading={loading} />
        {
          <Panel bsStyle=''>
            {fullscreen && <Panel.Heading>{__(editId ? 'Edit' : 'Create')}</Panel.Heading>}
            <Panel.Body>
              {attributesTree && attributesFlat && (
                <Form
                  {...{
                    labelCol: { span: 6 },
                    wrapperCol: { span: 16 }
                  }}
                  id='widget-attributes-form'
                  onFinish={handleSubmit}
                  form={form}
                  initialValues={initialValues}
                >
                  {!editId && (
                    <Form.Item required label="Attributes configuration title" name="title" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} rules={[{ required: true, message: 'Please input your title!' }]}>
                      <Input />
                    </Form.Item>
                  )}
                  <Form.List name="attributes">
                    {
                      (fields, { add, remove }) => (
                        <S.Cards>
                          {fields.map((field, index) => (
                            <S.Card key={field.key}>
                              <Form.Item label="Attribute" name={[index, 'attributeId']} style={{ marginBottom: '0px' }} rules={[{ required: true, message: 'Please select an attribute' }]}>
                                <TreeSelectWithInfo
                                  editId={editId}
                                  style={{ width: '100%', marginBottom: '1rem' }}
                                  dropdownStyle={{ maxHeight: 500, overflowY: 'scroll' }}
                                  treeData={attributesTree}
                                  allowClear
                                  showSearch
                                  onChange={handleChangeAttributeIdInAdd}
                                  treeNodeFilterProp="label"
                                  showCheckedStrategy={SHOW_PARENT}
                                  placeholder="Search attributes"
                                  attributesFlat={attributesFlat}
                                />
                              </Form.Item>
                              <Form.Item label="Measures" name={[index, 'measures']} style={{ marginBottom: '0px' }}>
                                <Select
                                  mode="multiple"
                                  style={{ width: '100%' }}
                                >
                                  {
                                    computeMeasureOptions(field)
                                  }
                                </Select>
                              </Form.Item>
                              {fields.length > 0 && (
                                <S.DeleteWrapper onClick={() => {
                                  remove(field.name);
                                  const attributeIds = form.getFieldsValue()?.attributes.map(f => f?.attributeId);
                                  filterAttributeTreeSelect(attributeIds);
                                }}>
                                  <Icon type="delete" />
                                </S.DeleteWrapper>
                              )}
                            </S.Card>
                          ))}
                          <S.AddWrapper>
                            <Button
                              type="text"
                              title='Add Attribute'
                              onClick={() => {
                                add();
                              }}
                            />
                          </S.AddWrapper>
                        </S.Cards>
                      )
                    }
                  </Form.List>
                </Form>
              )}
              {error && (
                <S.AlertWrapper>
                  <Alert
                    message="Error"
                    description={error.name}
                    type="error"
                    closable
                    showIcon
                    action={(
                      <Button
                        size="small"
                        title='Detail'
                        onClick={() => setErrorDetailsVibile(true)}
                      />
                    )}
                    onClose={() => {
                      setError(null)
                      setErrorDetailsVibile(null)
                    }}
                  />
                </S.AlertWrapper>
              )}
              {isErrorsDetailsVisible && (
                <Modal
                  backdrop="static"
                  show
                  onHide={() => setErrorDetailsVibile(null)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="capitalize">
                      Error details
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <S.ErrorsModalBody>
                      <p>You are trying to remove/edit attributes that are used in some widgets:</p>
                      {error.validationMessages.map(({ widgetName, attributeId, widgetId, description, className }) => {
                        if (className === 'ConfigurationWidgetRelatedValidationDto') {
                          return (
                            <S.ErrorCard>
                              <S.ErrorCardRow><strong>Description:</strong> <span>{description}</span></S.ErrorCardRow>
                              <S.ErrorCardRow><strong>AttributeId:</strong> <span>{__(attributeId)}</span></S.ErrorCardRow>
                            </S.ErrorCard>
                          )
                        }
                        if (className === 'ConfigurationUpdateValidationDto') {
                          return (
                            <Link to={`/widgets/${widgetId}`}>
                              <S.ErrorCard>
                                <S.ErrorCardRow><strong>Widget Name:</strong> <span>{widgetName}</span></S.ErrorCardRow>
                                <S.ErrorCardRow><strong>Widget Description:</strong> <span>{description}</span></S.ErrorCardRow>
                                <S.ErrorCardRow><strong>AttributeId:</strong> <span>{__(attributeId)}</span></S.ErrorCardRow>
                              </S.ErrorCard>
                            </Link>
                          )
                        }
                      })}
                    </S.ErrorsModalBody>
                  </Modal.Body>
                </Modal>
              )}
            </Panel.Body>
            {
              fullscreen && (
                <Panel.Footer>
                  <div className="group">
                    <Button
                      type='filled'
                      title={__('Save')}
                      form='widget-attributes-form'
                      className='pull-right'
                    >
                      {__('Save')}
                    </Button>
                  </div>
                </Panel.Footer>
              )
            }
          </Panel>
        }
      </div>
    </div>
  )
}

export default AttributesWidget
