import { api } from "../../providers/ApiProvider"
import { showMessage } from "../../utils/appHelper";
import {ERROR} from 'components/dist/Utils/LoggerUtils'
import {goToNextURL} from "../../providers/ReduxProvider/actions/userActions";

export const getRemoteObjectResults = (payload, primaryKeyId) => {
  if (!primaryKeyId) {
    showMessage("error", "primaryKeyId must be specified");
    return [];
  }
  if (!payload || !payload.displayAttributes) {
    showMessage("error", "invalid payload");
    return [];
  }
  return api.post(`configurations/remoteObjects/results?mainKey=${primaryKeyId}`, payload).then(({ data: response }) => {
    if (response.length > 0) {
      return response
    } else {
      showMessage('error', "No Results Found")
      return;
    }
  }
  );
}

export const getRemoteObjectConfiguration = (roConfigurationId) => {
  if (!roConfigurationId) {
    showMessage("error", "remoteObjectConfigurationId must be specified");
    return null;
  }
  return api.get('/configurations/remoteObjects/' + roConfigurationId).then(
    (remoteObjectConfiguration) => {
      return remoteObjectConfiguration.data
    }).catch((err) => {
      goToNextURL()
      ERROR(err)
  })
}

export const getRemoteObjectInstance = async (objectId, attributeConfiguration) => {
  return api.get(`/configurations/remoteObjects/${attributeConfiguration.dependencies}/${objectId}?skipCache=true`)
    .then((response) => {
      return response.data[0];
    });
}
