import styled, { css } from "styled-components";
import {Descriptions, Drawer, Menu, Table} from "antd";

const fitParent = css`
    &.ant-table-wrapper {
      height: 100%;
      overflow: hidden;
      > .ant-spin-nested-loading {
        height: 100%;
        > .ant-spin-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          > .ant-table {
            max-height: 100%;
          }
        }
      }
    }
  `

export const CustomTable = styled(Table)`
  ${(props) => props?.fitParent && fitParent}
  & .ant-table-content,
  & .ant-table-body {
    scrollbar-color: #C6C6C9AA transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      transition: .2s ease;
    }
    &::-webkit-scrollbar-track {
      border-top: unset;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 4px;
      height: 5px;
    }
  }
  & .ant-table-content:hover,
  & .ant-table-body:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #E2E2E6;
    }
  }
  .ant-table-cell .ant-empty {
    ${(props) => props?.width && `width: ${props.width}px`}
  }
  &.ant-table-wrapper .ant-table{
    border-radius: 5px;
    border: 1px solid #C6C6C9;
    overflow: hidden;

    .ant-table-container {
      height: 100%;
      display: grid;
    }

    thead.ant-table-thead > tr > th.ant-table-cell {
      border-right: unset;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      background-color: #E2E2E6;
      color: #1A1C1E;
      min-width: 200px;
      &.actions {
        width: 0;
        min-width: 0;
        position: sticky !important;
        z-index: 2;
      }
      &::before {
        background-color: #C6C6C9;
      }
      &.with-actions:nth-last-child(2)::before, &.actions::before {
        content: unset;
      }
    }
    tbody.ant-table-tbody > tr {
      ${props => props.onRow && 'cursor: pointer'};
      &:not(:last-child) {
        > td.ant-table-cell {
          border-bottom: 1px solid #E2E2E6;
        }
      }
      &:nth-of-type(even) > td {
        background-color: #FFF;
      }
      &:nth-of-type(odd):hover > td {
        background-color: #FFF;
      }
      > td.ant-table-cell {
        font-weight: 600;
        font-size: 14px !important;
        color: #1A1C1E;
        &.actions {
          position: sticky !important;
          right: 8px;
          width: 0;
          z-index: 2;
          background-color: transparent;
          direction: rtl;
          padding: 0;
          opacity: 0;
          transition: 0.15s ease-in 0s;
        }
      }
      &:hover {
        > td.ant-table-cell {
          background-color: #F0F0F3;
          &.actions {
            opacity: 1;
            right: 16px;
          }
        }
      }
      &:not(:hover) {
        > .ant-table-cell-fix-left, &.ant-table-cell-fix-right {
          background-color: #fff;
        }
      }
      &.ant-table-row-selected {
        > td.ant-table-cell {
          background: #EDF1F9;
        }
        &:hover {
          > td.ant-table-cell {
            background: #DEE3EB;
          }
        }
      }
    }
  }
`

export const MenuItem = styled(Menu.Item)`
  display: flex;
  gap: 4px;
`

export const DetailsDrawer = styled(Drawer)`
  &.ant-drawer {
    .ant-drawer-mask {
      background-color: rgba(0, 0, 0, 0.20);
    }
    .ant-drawer-body {
      ${props => props.noAutoSpacing && 'padding: 0'}
    }
  }
`

export const CustomDescriptions = styled(Descriptions)`
  &.ant-descriptions {
    > .ant-descriptions-view {
      border: unset;
    }
  }
`
