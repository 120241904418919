import { find } from "lodash";

export const getColumnSort = (colName, direction, previousSort) => {
  const found = find(previousSort, (sortAttribute) => sortAttribute.attributeId === colName)
  if (found) {
    return [{
      attributeId: colName,
      position: 1,
      value: found.value.toUpperCase() === 'DESC' ? 'ASC' : 'DESC'
    }]
  } else {
    return [{
      attributeId: colName,
      position: 1,
      value: direction.toUpperCase()
    }]
  }
  
}
