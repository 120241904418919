import i18next, { t } from 'i18next'

import { auth, misc, errors, process, settings, viewsReports } from './translations'

import _ from 'lodash'

export type TFunction = (translationKey: string, sentenceCase?: Cases) => string

export const __: TFunction = (translationKey: string, sentenceCase: Cases = 'none') => {
  if (!i18next.store || !i18next.store.options.resources?.language) {
    return translationKey
  }

  if (translationKey === undefined || translationKey === null) {
    return '.'
  }

  const translationValue = _.isString(translationKey) ? t(translationKey.trim().toLowerCase(), translationKey, {
    keySeparator: false,
    nsSeparator: ''
  }) : translationKey

  switch (sentenceCase) {
    case 'capitalize_sentence':
      let sentenceArray = translationValue.split('.')
      let capsArray = sentenceArray.map((word) => word?.replace(word[0], word[0]?.toUpperCase()))
      return capsArray.join('.')
    case 'capitalise':
      return translationValue.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    case 'lowercase':
      return translationValue.toLowerCase()
    case 'uppercase':
      return translationValue.toUpperCase()
    case 'none':
    default:
      // TODO maybe use CAPITALIZE_SENTENCE
      return translationValue
  }
}

export const T = {
  ...auth,
  ...settings,
  ...errors,
  ...misc,
  ...viewsReports,
  ...process,

  // ConfigurationConfiguratorBeta - HEADER/FOOTER
  chooseFieldsValidationError: 'choose_fields_validation_error',
  viewPropertiesValidationError: 'view_properties_validation_error',

  // GENERAL VALIDATIONS
  userEmailAlreadyExists: 'user_email_already_exists',
}
