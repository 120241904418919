import * as config from "../../constants/globalConfiguration";
import {api} from "../../providers/ApiProvider";
import {checkUserRights, getDashboardsUrl} from "../../utils/appHelper";
import {PlusOutlined, PushpinOutlined} from "@ant-design/icons";
import React from "react";
import {rightsCheckMethodType} from "./utils";
import {__} from '../../utils/translationUtils'

const {
  CAN_LIST_REPORTS,
  CAN_LIST_VIEWS,
  HAS_ROE_READ_ACCESS,
  CAN_CREATE_ORDERGROUP,
  CAN_CREATE_ORDERTYPES,
  CAN_MANAGE_OVERVIEW_OBJECTS,
  CAN_MANAGE_STEP_OBJECTS,
  CAN_MANAGE_FLOWS,
  CAN_CREATE_REPORT,
  CAN_CREATE_VIEW,
  CAN_CREATE_USER,
  CAN_CREATE_ENTITY,
  CAN_CREATE_ORDERS,
  CAN_USAGE_REPORT,
} = global.constants.rightMappings

const getCalendarMenuItem = () => ({
  path: '/calendar',
  text: __('Calendar'),
})

const getOrderMenuItem = (userOrderGroups = []) => (
  {
    text: __('Orders'),
    path: null,
    rightsCheck: {
      type: rightsCheckMethodType.PARENT,
      rights: CAN_LIST_VIEWS,
    },
    items: [
      ...userOrderGroups.map((g) => {
        const viewItemsToShow = g.defaultPinnedViews.concat(g.pinnedViews)
        const webPinnedViews = viewItemsToShow?.reduce((prev, v) => {
          // Check if pinned item is for the webui
          if (v.device === config.DEVICE_TYPES.WEB && !prev[v.configurationId]) {
            prev[v.configurationId] = ({
              path: `/to/views-${v.configurationId}-${g.id}`,
              text: v.configuration.name,
            })
          }
          return prev
        }, {})

        return {
          path: `/orderGroups/${g.id}/views`,
          text: g.name,
          items: Object.values(webPinnedViews || {}),
        }
      }),
      {
        path: '/orders/create',
        text: __('Create Order'),
        rightsCheck: {
          type: rightsCheckMethodType.PARENT,
          rights: [CAN_CREATE_ORDERS],
        },
      }
    ]
  }
)

const getReportMenuItem = (userOrderGroups = []) => (
  {
    text: __('Reports'),
    path: '/reports',
    position: 20,
    rightsCheck: {
      type: rightsCheckMethodType.PARENT,
      rights: CAN_LIST_REPORTS,
    },
    items: userOrderGroups.map((g) => {
      const reportItemsToShow = g.defaultPinnedReports.concat(g.pinnedReports)
      const pinnedReports = reportItemsToShow?.reduce((prev, r) => {
        // Check if pinned item is for the webui
        if (r.device === config.DEVICE_TYPES.WEB && !prev[r.configurationId]) {
          prev[r.configurationId] = ({
            path: `/to/reports-${r.configurationId}-${g.id}`,
            text: r.configuration.name,
          })
        }
        return prev
      }, {})
      return {
        path: `/orderGroups/${g.id}/reports`,
        text: g.name,
        items: Object.values(pinnedReports || {}),
      }
    })
  }
)

const getManageDataMenuItem = () => ({
  text: __('Manage Data'),
  path: '/remoteObjects',
  rightsCheck: {
    type: rightsCheckMethodType.PARENT,
    rights: HAS_ROE_READ_ACCESS,
  },
})

const getConfigMenuItem = () => ({
  text: 'Config',
  path: '/config',
  rightsCheck: {type: rightsCheckMethodType.CHILDREN,},
  items: [
    {
      text: __('People'),
      rightsCheck: {type: rightsCheckMethodType.CHILDREN,},
      expanded: true,
      items: [
        {
          path: '/to/users',
          text: __('Users'),
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_CREATE_USER,
          },
        },
        {
          path: '/userGroups',
          text: __('User Groups'),
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_CREATE_ENTITY,
          },
        },
        {
          path: '/organisations',
          text: __('Organisations'),
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_CREATE_ENTITY,
          },
        },
      ]
    },
    {
      text: __('Orders'),
      expanded: true,
      rightsCheck: {type: rightsCheckMethodType.CHILDREN,},
      items: [
        {
          path: '/customViews',
          text: __('Views'),
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_CREATE_VIEW,
          },
        },
        {
          path: '/customReports',
          text: __('Reports'),
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_CREATE_REPORT,
          },
        },
        {
          path: '/manageOrderTypes',
          text: __('Order Types'),
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_CREATE_ORDERTYPES,
          },
        },
        {
          path: '/manageOrderGroups',
          text: __('Order Groups'),
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_CREATE_ORDERGROUP,
          },
        },
        {
          path: window.__env__?.UI_FLOW_CONFIG_URL || '/config',
          external: true,
          target: '_blank',
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_MANAGE_FLOWS,
          },
          text: __('Flow Config')
        },
        {
          path: '/flows',
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_MANAGE_FLOWS,
          },
          text: __('Overview Tabs')
        },
        {
          path: '/overviewObjects',
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_MANAGE_OVERVIEW_OBJECTS,
          },
          text: __('Overview Objects')
        },
        {
          path: '/manageStepObjects',
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_MANAGE_STEP_OBJECTS,
          },
          text: __('Step Objects')
        },
        {
          path: '/config/#/debug-view',
          external: true,
          target: '_blank',
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_MANAGE_FLOWS,
          },
          text: __('Debug View')// todo✅: Translate manage calendars
        },
      ]
    },
    {
      text: __('Other'),
      expanded: true,
      rightsCheck: {type: rightsCheckMethodType.CHILDREN,},
      items: [
        {
          path: '/manageRemoteObjects',
          text: __('Data Management'),
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: HAS_ROE_READ_ACCESS,
          },
        },
        {
          path: window.__env__?.UI_TRANSLATION_URL || '/translation',
          external: true,
          target: '_blank',
          rightsCheck: {
            type: rightsCheckMethodType.PARENT,
            rights: CAN_MANAGE_FLOWS,
          },
          text: __('Translations')
        },
      ]
    }
  ]
})

export const mapPinnedDashboardsIntoMenuItems = (data, userPinnedIds, groupsPinnedId, organizationsPinnedId, quickRedirects) => {
  if (!data) return []
  const mappedData = [
    {groupLabel: __("Your:"), list: [], quickRedirect: quickRedirects?.["user"]},
    {groupLabel: __("Groups:"), list: [], quickRedirect: quickRedirects?.["group"]},
    {groupLabel: __("Organizations:"), list: [], quickRedirect: quickRedirects?.["organization"]}
  ]
  data.forEach((dashboard) => {
    if (userPinnedIds.includes(dashboard.id)) mappedData[0].list.push(
      {label: __(dashboard.name), link: `#/dashboards/${dashboard.id}/view`}
    )
    if (groupsPinnedId.includes(dashboard.id)) mappedData[1].list.push(
      {label: __(dashboard.name), link: `#/dashboards/${dashboard.id}/view`}
    )
    if (organizationsPinnedId.includes(dashboard.id)) mappedData[2].list.push(
      {label: __(dashboard.name), link: `#/dashboards/${dashboard.id}/view`}
    )
  })
  return mappedData.filter((group) => group.list.length > 0)
}

/**
 * @deprecated
 */
export const getPinnedDashboardsMenuItems = async (pinnedDashboards, guiUser) => {
  const {
    user = [],
    userGroups = [],
    organizations = [],
  } = pinnedDashboards || {}
  const pinnedIds = [...user, ...userGroups, ...organizations]
  const dashboardsResponse = (pinnedIds.length !== 0) ? await api.get(getDashboardsUrl({ids: pinnedIds})).catch((err) => console.log(err)) : {data: []}
  const firstFoundDashboard = (pinnedIds.length === 0) ? await api.get(getDashboardsUrl({
    pageSize: 1,
    pageNumber: 1
  })).catch((err) => console.log(err)) : {data: [...pinnedIds]}
  const noData = (firstFoundDashboard?.data && firstFoundDashboard.data.length === 0) ?
    {
      redirectText: __("Create the first dashboard"),
      redirectIcon: <PlusOutlined style={{fontSize: "25px", color: "#004570"}}/>,
      redirect: '#/dashboards/create',
      text: null
    } : {
      redirectText: __("Set a pinned dashboard"),
      redirectIcon: <PushpinOutlined style={{fontSize: "25px", color: "#004570"}}/>,
      redirect: `#/users/${guiUser.id}/settings`,
      text: __('No Pinned Dashboards')
    }
  const redirects = {
    user: {
      text: __("Set Your Pinned Dashboards"),
      redirect: `#/users/${guiUser.id}/settings`,
      icon: "pushpin"
    },
    ...(checkUserRights(guiUser.rights, global.constants.rightMappings.CAN_CREATE_ENTITY) && {
      group: {
        text: __("Groups Settings"),
        redirect: `#/userGroups/`,
        icon: "pushpin"
      },
      organization: {
        text: __("Organisations Settings"),
        redirect: `#organisations`,
        icon: "pushpin"
      }
    })
  }
  return {
    title: __("Pinned Dashboards"),
    quickRedirect: {
      text: __("Set Pinned Dashboards"),
      redirect: `#/users/${guiUser.id}/settings`,
      icon: "pushpin"
    },
    noData: noData,
    data: mapPinnedDashboardsIntoMenuItems(
      dashboardsResponse?.data,
      user,
      userGroups,
      organizations,
      redirects
    )
  }
}

const getDashboardMenuItem = (pinnedDashboards, user) => ({
  text: __('Dashboards'),
  path: '/dashboards',
  rightsCheck: {
    type: rightsCheckMethodType.PARENT,
    rights: CAN_USAGE_REPORT,
  },
  items: [
    {
      path: `/users/${user?.id}/settings`,
      icon: "user",
      text: __("personal_dashboards", 'capitalize_sentence'),
      items: pinnedDashboards?.user || []
    },
    ...(
      pinnedDashboards?.user_group ?
        [{
          path: '/userGroups',
          icon: "team",
          text: __('user_group_dashboards', 'capitalize_sentence'),
          items: pinnedDashboards?.user_group || []
        }] : []
    ),
    {
      path: '/organisations',
      icon: "bank",
      text: __('organization_dashboards', 'capitalize_sentence'),
      items: pinnedDashboards?.organisation || [],
      separator: true,
    },
    {
      path: '/dashboards',
      icon: "unorderedList",
      text: __('View Dashboards'),
      separator: true,
    },
    {
      path: '/widgets',
      icon: "unorderedList",
      text: __('View Widgets'),
    }
  ]
})

export const getMenu = (userOrderGroups, pinnedDashboards, user) => {

  const calendar = getCalendarMenuItem()

  const reports = getReportMenuItem(userOrderGroups)

  const orders = getOrderMenuItem(userOrderGroups)

  const manageData = getManageDataMenuItem()

  const config = getConfigMenuItem()

  const dashboard = getDashboardMenuItem(pinnedDashboards, user)

  return [
    calendar,
    orders,
    reports,
    manageData,
    config,
    dashboard,
  ]
}
