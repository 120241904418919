import React, {useEffect, useState} from 'react'

import PropTypes from 'prop-types'
import {TableHeaderColumn} from 'react-bootstrap-table'
import {Popconfirm, Spin, Tooltip} from 'antd'
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";
import {connect} from "react-redux";

import {checkUserRights, renderResultInfo} from '../../utils/appHelper'
import * as config from '../../constants/globalConfiguration'
import {StickyScrollableTable, TableFirstChildTdHiddenOverflow} from "../../providers/StyleProvider/styles";
import {hashHistory} from "../../providers/HistoryProvider";
import {deleteRemoteObjectItem} from "../../providers/ReduxProvider/actions/remoteObjectActions";
import {__} from '../../utils/translationUtils'
import {DropdownButton, MenuItem} from "react-bootstrap";

const RemoteObjectTable = (props) => {

  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    if (props.guiUser.rights && !permissions) {
      const {rightMappings} = global.constants;
      setPermissions({
        hasRoeReadAccess: checkUserRights(
          props.guiUser.rights,
          rightMappings.HAS_ROE_READ_ACCESS
        ),
        hasRoeEditAccess: checkUserRights(
          props.guiUser.rights,
          rightMappings.HAS_ROE_EDIT_ACCESS
        ),
        canUpdateRemoteObjectItem: checkUserRights(
          props.guiUser.rights,
          rightMappings.CAN_UPDATE_REMOTE_OBJECT_ITEM
        ),
        canDeleteRemoteObjectItem: checkUserRights(
          props.guiUser.rights,
          rightMappings.CAN_DELETE_REMOTE_OBJECT_ITEM
        )
      });
    }
  }, [props.guiUser.rights, permissions]);

  const renderPagination = () => {
    const { setPageSize } = props
    return (
      <DropdownButton
        onSelect={setPageSize}
        bsStyle="link"
        title={props.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {
          config?.pageSizeOptions?.map((val, i) => <MenuItem key={i} eventKey={val}>{val}</MenuItem>)
        }
      </DropdownButton>
    )
  }

  const {
    remoteObjectConfiguration,
    pagination,
    isLoading,
    onPageChange,
    onSortChange,
    selectedColumns,
    deleteRemoteObjectItem,
    data,
    attributesMap
  } = props

  if (selectedColumns.length === 0) {
    return (<center><h2>No summary attributes selected!</h2></center>)
  }

  return (
    <Spin spinning={false} tip={__("It may take a while")}>
      <div>
        {selectedColumns?.length > 0
          && (
            <TableFirstChildTdHiddenOverflow>
              <StickyScrollableTable
                keyField='id'
                inverse={true}
                data={data}
                remote
                pagination
                hover
                striped
                bordered={true}
                className="large-table"
                fetchInfo={{dataTotalSize: pagination.totalCount}}
                options={{
                  onPageChange: onPageChange,
                  onSortChange: onSortChange,
                  noDataText: isLoading ? __('loading_data') : __('NoDataFound'),
                  hideSizePerPage: false,
                  firstPage: 'First',
                  lastPage: 'Last',
                  paginationShowsTotal: (...props) => renderResultInfo(...props, renderPagination),
                  page: pagination.pageNumber,
                  sizePerPageList: config.pageSizeOptions,
                  sizePerPage: pagination.pageSize
                }}
              >
                {
                  selectedColumns.map((displayAttrId) => {
                    const attribute = attributesMap[displayAttrId]

                    return (
                      <TableHeaderColumn
                        dataSort
                        dataFormat={(cell, row) => cell}
                        key={attribute.id}
                        dataField={attribute.id}
                      >
                        {__(attribute.propertyLabel)}
                      </TableHeaderColumn>
                    )
                  })
                }
                <TableHeaderColumn
                  className="edit-remote-object"
                  width="100"
                  dataFormat={
                  (_, row) => {
                    return !row.accessRightsInfo?.edit ? null : (
                      <div style={{display: 'flex'}}>
                        {permissions?.hasRoeReadAccess && (
                          <Tooltip title={__('Details')}>
                            <div
                              className="btn-no-padding btn-std"
                              onClick={() => {
                                const {objectTypeId, id} = remoteObjectConfiguration

                                if (!objectTypeId || !id || !row.id) return null
                                localStorage.removeItem('mostRecentItems')
                                hashHistory.push(`/${config.remoteObjectDetailsUrl}/${objectTypeId}/${id}/${row.id}`)
                              }}
                            >
                              <SearchOutlined/>
                            </div>
                          </Tooltip>
                        )}
                        {permissions?.hasRoeEditAccess && permissions?.canDeleteRemoteObjectItem && (
                          <Popconfirm
                            placement="topRight"
                            title={__('Are you sure you want to delete')}
                            onConfirm={() => {
                              const {objectTypeId, systemId} = remoteObjectConfiguration
                              if (!objectTypeId || !systemId || !row.id) return null
                              deleteRemoteObjectItem({
                                primaryKey: row.id,
                                systemId,
                                objectTypeId
                              })
                            }}
                            okText={__('Yes')}
                            cancelText={__('No')}
                          >
                            <Tooltip title={__('Delete')}>
                              <div className="btn-no-padding btn-std icon-delete">
                                <DeleteOutlined/>
                              </div>
                            </Tooltip>
                          </Popconfirm>
                        )}
                      </div>
                    )
                  }}
                />
              </StickyScrollableTable>
            </TableFirstChildTdHiddenOverflow>
          )}
      </div>
    </Spin>
  )
}

RemoteObjectTable.propTypes = {
  data: PropTypes.array,
  remoteObjectConfiguration: PropTypes.object,
  selectedColumns: PropTypes.array,
  attributesMap: PropTypes.object,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  setPageSize: PropTypes.func.isRequired,
  viewConfiguration: PropTypes.object
}

RemoteObjectTable.defaultProps = {
  data: [],
  remoteObjectConfiguration: {},
  selectedColumns: [],
  pagination: {},
}

export default connect((state) => ({
  guiUser: state.user.data,
}), {deleteRemoteObjectItem})(RemoteObjectTable)
