import moment from 'moment'
import getFieldKey from './getFieldKey'

export default ({ name, description, shareWith, shareWithEntities, editableBy, editableByEntities, presentation, enumeration, configurationId, dimensions, filters, limit, timeDimensions, measures, granularity, filterOutLastGranularityBin, customDateRange, dateRange, order, percents, runningTotals, visualization, yLabel, xLabel }) => {

  const { pivotConfig, stackResults } = visualization || {}

  return {
    name,
    description,
    presentation,
    configurationId,
    visualization: {
      stackResults,
      pivotConfig: pivotConfig?.map(({ value, pivot }) => [getFieldKey(value, enumeration), pivot]),
      yLabel,
      xLabel
    },
    shareWith: {
      type: shareWith,
      entityIds: shareWithEntities?.filter(e => !e.includes(global.constants.WHITE_LABEL))
    },
    editableBy: {
      type: editableBy,
      entityIds: editableByEntities?.filter(e => !e.includes(global.constants.WHITE_LABEL))
    },
    query: {
      limit: isNaN(parseInt(limit))? 1000 : parseInt(limit),
      dimensions: dimensions?.map((e) => e && getFieldKey(e, enumeration)),
      filters: filters?.filter((e) => e && e.member).map(({ member, operator, values }) => {
        let updatedValues = values;
        const valueType = JSON.parse(member)?.valueType;

        if(valueType === 'time'){
        const isDateRange = Array.isArray(values) // if the values is an array, I assume that is a dateRange

        if (isDateRange) {
          updatedValues = updatedValues.map((e) => moment(e).format('YYYY-MM-DD'))
        }

        // everything beside numbers and string is a date
        const isSingleDate = !isDateRange && updatedValues && typeof updatedValues !== 'string' && !Number(`${updatedValues}`)

        if (isSingleDate) {
          updatedValues = moment(updatedValues).format('YYYY-MM-DD')
        }

        }

        return {
          member: getFieldKey(member, enumeration),
          ...(operator ? { operator } : {}),
          ...(values ? { values:  Array.isArray(updatedValues) ? updatedValues : [updatedValues] } : {})
        }

      }),
      measures: measures?.map((e) => JSON.parse(e)?.fieldKey),
      ...( timeDimensions && JSON.parse(timeDimensions).fieldKey ? {timeDimensions: [{
        dimension:  JSON.parse(timeDimensions).fieldKey,
        ...(granularity ? { granularity } : {}),
        ...((dateRange === 'Custom' && customDateRange) ? { dateRange: customDateRange.map((e) => moment(e).format('YYYY-MM-DD')) } : {}),
        ...((dateRange !== 'Custom') ? { dateRange } : {})
      }]} : {}),
      order: order?.map(({ dimension, order }) => [getFieldKey(dimension, enumeration), order]),
      options: {
        filterOutLastGranularityBin: false, // TODO: this has to be removed @BOMA @GRGU
        percents: percents?.filter((e) => e.percentage !== 'absolute')?.map(({ measures, percentage }) => ({ fieldKey: getFieldKey(measures, enumeration), keepBoth: percentage === 'keepBoth' })),
        runningTotals: runningTotals?.filter((e) => e.percentage !== 'absolute')?.map(({ measures, percentage }) => ({ fieldKey: getFieldKey(measures, enumeration), keepBoth: percentage === 'keepBoth' }))
      }
    }
  }
}
