import React from 'react';

import EgsBillingView from '../components/EgsBillingView';

import { showMessage } from '../utils/appHelper';
import moment from 'moment';
import { api } from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'


class EgsBilling extends React.Component{

  static propTypes = {
  };

 handleSubmit = (values) => {

    // Do something with the form values
    api.get(
        '/dashboard/reports/orders?before='+moment(values.to).toISOString()+'&after='+moment(values.from).toISOString(),{
         responseType: 'arraybuffer'
         
        }
      )
    .then(
      response => {       
        if(response.status === 200){        
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            let blob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = 'report.xlsx';
            a.click();
            window.URL.revokeObjectURL(url); 

          setTimeout(()=>{
            showMessage('success',  __('OrderReportDownloadSuccessMessage'));
            },
            200
          );
        }
      }
    );
  }


  render() {

    return (


      <div id="main-content">
        <h1 className="sticky">
          <div className="container">
             {__('EgsBillPageTitle')}
          </div>
        </h1>
        <div className="container-fluid">
          <EgsBillingView onSubmit={this.handleSubmit}/>
        </div>
      </div>
      
    );
  }

}

export default EgsBilling;
