import { T } from "../../utils/translationUtils"

export const PAGINATION_INITIAL_STATE = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  sorter: null
}

export const settingsFields = {
  SELF_REGISTRATION: 'selfRegistration',
  USER_GROUPS: 'userGroups',
  USER_ROLES: 'userRoles',
  ALLOWED_EMAIL_DOMAINS: 'allowedEmailDomains',
  EMAIL_VERIFICATION: 'emailVerification',
  ON_SUCCESSFUL_SIGNUP: 'onSuccessfulSignUp',
  SUCCESSFUL_SIGNUP_CONFIGURATION: 'successfulSignUpPageConfiguration',
  SIGN_UP_FIELDS: 'signUpFields'
}

export const configurationFields = {
  MAX_VIEW_PAGE_SIZE: 'maxViewPageSize',
  MAX_REPORT_PAGE_SIZE: 'maxReportPageSize',
  WEBUI_MAX_PINNED_VIEWS: 'webuiMaxPinnedViews',
  WEBUI_MAX_DEFAULT_PINNED_VIEWS: 'webuiMaxDefaultPinnedViews',
  MOBILE_MAX_PINNED_VIEWS: 'mobileMaxPinnedViews',
  MOBILE_MAX_DEFAULT_PINNED_VIEWS: 'mobileMaxDefaultPinnedViews',
  WEBUI_MAX_PINNED_REPORTS: 'webuiMaxPinnedReports',
  WEBUI_MAX_DEFAULT_PINNED_REPORTS: 'webuiMaxDefaultPinnedReports',
  MOBILE_MAX_PINNED_REPORTS: 'mobileMaxPinnedReports',
  MOBILE_MAX_DEFAULT_PINNED_REPORTS: 'mobileMaxDefaultPinnedReports',
}

export const availableBehaviours = {
  REDIRECT_TO_LOGIN_PAGE: 'REDIRECT_TO_LOGIN_PAGE',
  AUTOMATIC_LOGIN: 'AUTOMATIC_LOGIN',
  REDIRECT_TO_SUCCESSFUL_SIGN_UP_PAGE: 'REDIRECT_TO_SUCCESSFUL_SIGN_UP_PAGE'
}

export const registrationBehaviour = [
  {
    value: availableBehaviours.REDIRECT_TO_LOGIN_PAGE,
    label: T.loginPage
  },
  {
    value: availableBehaviours.AUTOMATIC_LOGIN,
    label: T.autoLogin,
    message: T.autoLoginDisabledInfo
  },
  {
    value: availableBehaviours.REDIRECT_TO_SUCCESSFUL_SIGN_UP_PAGE,
    label: T.messagePage
  }]

export const availableMessageTypes = {
  CHECK_YOUR_EMAIL: 'CHECK_YOUR_EMAIL',
  GREETINGS: 'GREETINGS'
}
