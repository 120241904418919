import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';

import {__} from '../utils/translationUtils'

class DeleteFileConfirmationModal extends React.Component {

  static propTypes = {
    file: PropTypes.object,
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    deleteFile: PropTypes.func,
  };

  constructor(props) {
    super(props);
  }

  render() {

    const {file} = this.props;

    return (

      <Modal
        backdrop="static"
        show={this.props.open}
        onHide={this.props.closeModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title className="capitalize">{__('DeleteFile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {__('DeleteThisFile?')}
        </Modal.Body>
        <Modal.Footer>
          <div className="group">
            <Button className="pull-left" onClick={this.props.closeModal.bind(this)}>
              {__('Back')}
            </Button>
            <Button className="btn-danger" onClick={this.props.deleteFile.bind(this, file)}>
              {__('Delete')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

    );
  }
}

export default DeleteFileConfirmationModal;
