export const mapOldFileVersionToNew = (oldField) => {
  
  const field = { ...oldField }
  
  if (!field.controllerOptions) field.controllerOptions = {type: "FileUploaderControllerOptions", allowFilePreview: true}
  if (!field.controllerOptions.rootDirectory)
    field.controllerOptions =
      { rootDirectory: {allowExtraFiles: typeof field.attributes?.extendedProperties?.rootFiles !== 'undefined' ? field.attributes.extendedProperties.rootFilestrue : true},
        ...field.controllerOptions
      }
  if (field.source && field.source !== "") {
    const sourceToMap = JSON.parse(field.source)
    let files = []
    if (field.attributes?.customOptions && field.attributes?.customOptions.length > 0){
      files = field.attributes?.customOptions.map((fileName) => {
        return {
          name: fileName,
          //maxAmount: 10,
          //minAmount: 1
        }
      })
    }
    field.controllerOptions.rootDirectory.directories = []
    if (sourceToMap.groups && sourceToMap.groups.length > 0) {
      field.controllerOptions.rootDirectory.directories = sourceToMap.groups.map((dir) => {
        return dir.name !== "StandardPhotos" ?
          {name: dir.name, allowExtraFiles: true, ...dir.allowExtraFiles}
          :
          {name: dir.name, allowExtraFiles: true, ...dir.allowExtraFiles, files: files}
      })
    }
    
  }
  const { fieldName, primaryKey, xsdName, files, groups } = field.controllerOptions;
  
  let fieldValue
  
  try {
    fieldValue = JSON.parse(field.value)
  } catch (ignored) {}
  
  const source = {
    fieldName: fieldName ? fieldName : fieldValue?.fieldName,
    primaryKey: primaryKey ? primaryKey : fieldValue?.primaryKey,
    xsdName: xsdName ? xsdName : fieldValue?.xsdName,
    files: files ? files : fieldValue?.files,
    groups: groups ? groups : fieldValue?.groups
  }
  const stringSource = JSON.stringify(source)
  if (!field.source || field.source === "") field.source = stringSource
  if (!field.value || field.value === "") field.value = field.source
  
  return field
}
