import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { AppstoreOutlined, TableOutlined } from "@ant-design/icons";

import { fetchDefaultRemoteObjectConfiguration } from '../../../../../../../utils/appHelper';
import { stepFieldRequired } from "../../../../../../../utils/fieldValidator";
import { api } from '../../../../../../../providers/ApiProvider'
import StepObjectNodeSkeleton from '../../../../../../StepObjectNodeSkeleton';
import { Link } from 'react-router-dom';
import NoData from '../../../../../../UI/NoData';
import ItemSelection from "./ItemSelection";
import ButtonRadio from "../../../../../../UI/ButtonRadio";
import CardListView from "./CardListView";
import TableView from "./TableView";

import * as S from '../styles'
import { removeRelationsROE } from '../../../../../../../providers/ApiProvider/remoteObjects';
import { __ } from '../../../../../../../utils/translationUtils'


class ROSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      values: {},
      error: null,
      selectedObjectIds: [],
      configuration: null,
      total: 0,
      currentPage: props.currentPage || 1,
      pageSize: props.pageSize || 5,
      isLoading: false,
      displayType: props.defaultView?.toLowerCase() || 'table',
      selectedObjectId: null
    }

    this.init = this.init.bind(this)
    this.initData = this.initData.bind(this)
    this.handleAddItems = this.handleAddItems.bind(this)
    this.updateReduxForm = this.updateReduxForm.bind(this)
    this.fetchDataHelper = this.fetchDataHelper.bind(this)
    this.bulkFetchData = this.bulkFetchData.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.stepFieldValidator = this.stepFieldValidator.bind(this)
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const { objectTypeId } = this.props
    this.setState({ isLoading: true })
    fetchDefaultRemoteObjectConfiguration(objectTypeId)
      .then((resp) => {
        if (resp.data?.displayAttributes?.length === 0) {
          this.setState({
            error: `Display attributes not found`,
            isLoading: false
          });
        } else {
          this.setState({
            configuration: resp.data,
            isLoading: false
          });

          this.initData()
        }
      }).catch((e) => {
        this.setState({
          error: `Error`,
          isLoading: false
        });
      })
  }

  async initData(ids) {
    const { controlData: { source } } = this.props

    this.fetchDataHelper(ids || source?.split(',').filter((item) => item))
      .then(({ data: selected = [], headers }) => {
        const values = { ...this.state.values }
        selected?.forEach((ro) => {
          values[ro.id] = ro
        })

        const initialSelectedObjectIds = [
          ...this.state.selectedObjectIds || [],
          ...(ids?.filter((id) => !this.state.selectedObjectIds?.includes(id)) || [])
        ]

        this.setState({
          values,
          selectedObjectIds: [
            ...initialSelectedObjectIds,
            ...selected?.map((item) => item.id).filter((mk) => !initialSelectedObjectIds?.includes(mk)) || []
          ],
          isLoading: false,
          ...(headers ? { total: headers['x-total-count'] } : {}),
        })
      })
  }

  getPrimaryKeyAttribute(objectTypeId, attributesMap) {
    const attributes = Object.values(attributesMap)
      .filter((attr) => attr.dependencies === objectTypeId?.toString())

    const attrPrimaryKey = attributes.find((attr) => attr.primaryKey)

    if (attrPrimaryKey) return attrPrimaryKey

    return attributes.find((attr) => {
      if (attr.property.toLowerCase().endsWith("/id")) {
        const slashCount = attr.property.split("/").length - 1;
        return slashCount === 1;
      }
      return false;
    })
  }

  async fetchDataHelper(ids) {
    const { attribute, attributes } = this.props.controlData
    const { relatedObjects } = attribute

    const primaryKeyAttribute = this.getPrimaryKeyAttribute(relatedObjects, attributes)

    const { currentPage, pageSize } = this.state;

    return (ids.length && !primaryKeyAttribute?.enumMechanismAllowed ? this.fetchData(ids) : this.bulkFetchData(ids, { currentPage, pageSize }))
  }

  fetchData(ids) {
    const { currentPage, pageSize } = this.state
    let idsToFetch = ids.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    const promises = [];
    const { configuration } = this.state;
    const { controlData } = this.props;
    const overviewConfiguration = configuration || controlData?.attributes?.objectBinderConfiguration

    if (!overviewConfiguration) return []
    if (idsToFetch.length === 0) {
      return []
    }
    idsToFetch.filter(id => id).forEach(primaryKey => {
      promises.push(
        new Promise(resolve => api.get(`configurations/remoteObjects/${overviewConfiguration.objectTypeId}/${primaryKey.trim()}`)
          .then(res => {
            if (res.status === 200) {
              resolve(res.data);
            } else {
              this.setState({
                error: 'Error in resolving promise response',
              });
            }
          }).catch(error => {
            this.setState({
              error: 'Sorry, at the moment we are unable to load this object.',
            });
          })
        ))
    });

    return Promise.all(promises)
      .then(results => ({ data: results.map((item) => item[0]), headers: { 'x-total-count': ids.length } }))
  }

  bulkFetchData(ids, pagination) {
    const { currentPage, pageSize } = pagination || {}
    const { configuration } = this.state;

    const { parentObjectId } = this.props
    const { attribute, attributes } = this.props.controlData
    const { relatedObjects, mappedBy } = attribute

    const primaryKeyAttribute = this.getPrimaryKeyAttribute(relatedObjects, attributes)

    const { enumMechanismAllowed } = primaryKeyAttribute || {}

    if (!(ids?.length && enumMechanismAllowed) && !mappedBy) {
      console.log('No ids to fetch')
      return []
    }

    const payload = { ...configuration, searchAttributes: [], sortAttributes: [], pageSize, pageNumber: currentPage }

    return api.post(`configurations/remoteObjects/results?${ids?.length ? `ids=${ids.join(',')}&` : ''}${mappedBy ? `parentObject=${mappedBy}&parentObjectIds=${parentObjectId}` : ''}`.replace(/&$/, ""), payload)
      .then((res) => res)
      .catch((e) => { console.log(e) })
  }

  updateReduxForm(_selectedObjectIds) {
    const { change, controlData } = this.props
    change('editRemoteObjectItemForm', controlData?.attribute?.id, _selectedObjectIds.join(','))
    return
  }

  handleAddItems(mainKeys) {
    let _selectedObjectIds;
    const { controlData, singleRow, updateObject } = this.props
    if (singleRow) {
      _selectedObjectIds = mainKeys
    } else {
      _selectedObjectIds = [...this.state.selectedObjectIds || [], ...mainKeys.filter((mk) => !this.state.selectedObjectIds?.includes(mk))]
    }
    const changes = { [controlData?.attribute?.id]: _selectedObjectIds.join(',') }
    this.updateReduxForm(_selectedObjectIds)
    updateObject(changes).then((res) => {
      if (res.status === 200)
        this.initData(_selectedObjectIds)
    })
  }

  stepFieldValidator(value) {
    const { controlData } = this.props;

    let patt = new RegExp(controlData.regex.js);

    // Validate value
    if (value && !patt.test(value)) { // Vaule is valid.
      return __('valueFormatError');
    } else { // Value is not valid.
      return undefined;
    }
  }

  stepValidation() {
    const { controlData } = this.props;
    let validation = [];

    if (controlData.required) {
      validation.push(stepFieldRequired);
    }

    // Check if field needs to be validated (check if regex exist)
    if (typeof controlData.regex !== 'undefined'
      && typeof controlData.regex.js !== 'undefined'
      && controlData.regex.js !== '') {
      validation.push(this.stepFieldValidator);
    }

    if (validation.length === 0) {
      return undefined;
    }
    return validation;
  }


  render() {
    const { controlData, type, singleRow, updateObject, title, attributes, permissions, parentObjectTypeId, parentObjectId } = this.props;
    const { values, error, selectedObjectIds, configuration, currentPage, pageSize, isLoading } = this.state;
    const { id: attributeId, mappedBy } = controlData?.attribute || {};

    const handlePageChange = (page, pageSize) => {
      this.setState({ currentPage: page, pageSize: pageSize }, () => this.initData());
    }

    const handleRemoveItems = (mainKey) => {
      if (mappedBy) {
        removeRelationsROE({ objectTypeId: parentObjectTypeId, mainKey: parentObjectId }, { childObject: attributeId, childObjectIds: [mainKey] }).then((res) => {
          this.setState({
            total: this.state.total - 1,
          })

          const _selectedObjectIds = this.state.selectedObjectIds?.filter((mk) => mk !== mainKey) || []
          // this.updateReduxForm(_selectedObjectIds) // TODO: investigate! this causes a bug in addRelation after removeRelation
          this.setState({
            isLoading: true,
            selectedObjectIds: _selectedObjectIds,
          }, () => {
            this.initData()
            this.setState({ isLoading: false });
          });
        })
      } else {
        const _selectedObjectIds = this.state.selectedObjectIds?.filter((mk) => mk !== mainKey) || []
        this.updateReduxForm(_selectedObjectIds)
        const changes = { [attributeId]: _selectedObjectIds.join(',') }
        this.setState({
          isLoading: true,
          selectedObjectIds: _selectedObjectIds,
          total: _selectedObjectIds.length,
        }, () => {
          updateObject(changes);
          this.initData(_selectedObjectIds)
          this.setState({ isLoading: false });
        });
      }
    }

    const handleDisplayTypeChange = (event) => {
      this.setState({ displayType: event.target.value })
    }
    const idsToRender = selectedObjectIds
    // In Object Binder the configuration is in the controlData
    const overviewConfiguration = {
      ...controlData?.attributes?.objectBinderConfiguration,
      id: attributeId, ...configuration
    }
    const summaryDisplayAttributes = overviewConfiguration?.displayAttributes?.filter(displayAttribute => displayAttribute.summary) || []
    const summaryAttributes = configuration?.displayAttributes?.filter(a => a.summary)?.map(({ attributeId: id }) => attributes[id]) || []
    const attributesUsedInDisplay = configuration?.displayAttributes?.map(({ attributeId: id }) => id && attributes[id]) || []
    if (!summaryAttributes.length && !isLoading) {
      return (<div>No summary values configured. Please check the configuration or ask your administrator.</div>)
    }

    const paginationProps = {
      defaultCurrent: currentPage || 1,
      size: "small",
      pageSize: pageSize,
      current: currentPage,
      total: this.state.total || selectedObjectIds.length,
      showSizeChanger: true,
      pageSizeOptions: [5, 10, 25, 50, 100],
      onChange: handlePageChange,
      showTotal: () => `${this.state.total || selectedObjectIds.length} ${__('total_items')}`,
    }

    const subObjCommonProps = {
      idsToRender,
      values,
      attributes: summaryAttributes,
      displayAttributes: summaryDisplayAttributes,
      permissions,
      onRemoveItem: handleRemoveItems,
      pagination: paginationProps,
      overviewConfiguration,
      type: 'ROE',
    }

    return (
      <>
        <S.Header>
          <S.CardTitle>
            {title}
          </S.CardTitle>
          {
            permissions.ADDABLE && (
              <ItemSelection
                configuration={overviewConfiguration}
                mappedBy={mappedBy}
                fetchMappedBy={(ids, pagination) => this.bulkFetchData(ids, pagination)?.then((res) => res?.data)}
                refreshData={() => this.initData()}
                parentObjectId={this.props.parentObjectId}
                parentObjectTypeId={this.props.parentObjectTypeId}
                attributeId={attributeId}
                handleAddItems={this.handleAddItems}
                selectedObjectIds={idsToRender}
                initialValues={{}}
                attributesConfiguration={attributes}
                attributes={attributesUsedInDisplay}
                values={[]}
                type={type}
                singleRow={singleRow}
              />
            )
          }
          <ButtonRadio
            options={[{ label: <TableOutlined />, value: "table" }, { label: <AppstoreOutlined />, value: "card" }]}
            size
            value={this.state.displayType}
            onChange={handleDisplayTypeChange}
          />
        </S.Header>
        <div>
          {!isLoading && !configuration && (
            <NoData
              title={__("You have not configured any views for this object")}
              subTitle={<Link to="/remoteObjects/create">{__('create')}</Link>}
              centered
              subTitleWrap={{
                before: __('To see results here'),
                after: __('a Remote Object Configuration or talk with your admin.')
              }}
              image={false}
            />
          )}
          {!(error || isLoading) ? (
            <>
              {idsToRender.length === 0 ?
                <div className="discreet">
                  <i>{__('objectListEmpty')}</i>
                </div> :
                <>
                  {this.state.displayType === 'table'
                    ? <TableView {...subObjCommonProps} />
                    : <CardListView{...subObjCommonProps} />
                  }
                </>
              }
            </>
          ) : (
            error ? (
              <div className="error-container">
                {error}
              </div>
            ) : (isLoading && (
              <StepObjectNodeSkeleton
                configuration={configuration}
                attributes={attributes} />
            ))
          )}
        </div>
      </>
    )
  }
}

ROSelector.propTypes = {
  controlData: PropTypes.object.isRequired,
  change: PropTypes.func,
  permissions: PropTypes.object,
  enumValues: PropTypes.object.isRequired,
  parentObjectId: PropTypes.string.isRequired,
  parentObjectTypeId: PropTypes.string.isRequired,
  objectTypeId: PropTypes.string.isRequired,
  updateObject: PropTypes.func
}

export default connect(
  state => {
    return {
      enumValues: state.enumValues,
      attributes: state.ui.attributes,
    };
  },
  { change }
)(ROSelector);
