import React, { useMemo, useRef, useState } from 'react'

import RGL, { WidthProvider } from "react-grid-layout";
import _ from 'lodash'

import Cell from "./Cell";

const ResponsiveReactGridLayout = WidthProvider(RGL);

export default (props) => {
  const {
    items,
    cols = 12,
    rowHeight = 2,
    margin = [25, 4],
  } = props

  const layout = useMemo(() => (
    items?.map((item) => item.cellProperties)?.sort((a, b) => {
      const aTop = a?.y + a?.h
      const bTop = b?.y + b?.h
      return aTop - bTop
    }) || []
  ),  [items])
  const [adjustedLayout, setAdjustedLayout] = useState(layout)
  const [gridItemsHeight, setGridItemsHeight] = useState({})
  const itemsRef = useRef({})

  const updateHeight = (key, width, height) => {
    if (height === gridItemsHeight[key]) return
    if (!Object.keys(itemsRef.current).length) return
    const newGridItemsHeight = {}
    Object.entries(itemsRef.current).forEach((item) => {
      newGridItemsHeight[item[0]] = item[1]?.offsetHeight
    })
    if (!_.isEqual(newGridItemsHeight, gridItemsHeight)) {
      setGridItemsHeight(newGridItemsHeight)
      updateGridItems(newGridItemsHeight)
    }
  }

  const updateGridItems = (gridItemsHeight) => {
    const heightChanges = {}
    layout.forEach((item) => {
      const { y, h, i: itemId } = item || {}
      const currentHeight = gridItemsHeight[itemId]
      const marginH = margin?.[1] || 0
      const minH = currentHeight && Math.ceil((currentHeight + marginH) / (rowHeight + marginH))
      let externalHeightChanges = 0
      for (let i = y; i < y + h - 1; i++) {
        externalHeightChanges += (heightChanges[i] || 0)
      }
      const itemHeightChanges = minH - h - externalHeightChanges
      if (!heightChanges[y + h - 1]) heightChanges[y + h - 1] = itemHeightChanges
      else heightChanges[y + h - 1] = Math.max(heightChanges[y + h - 1], itemHeightChanges)
    })
    const newLayout = layout.map((item) => {
      const { y, h } = item || {}
      let newH = h
      for (let i = y; i < y + h; i++) {
        newH += (heightChanges[i] || 0)
      }
      return {
        ...item,
        h: newH
      }
    })
    setAdjustedLayout(newLayout)
  }

  const generateDOM = () => {
    return items.map((item) => {
      return (
        <Cell
          itemsRef={itemsRef}
          updateHeight={updateHeight}
          key={item.cellProperties.i}
          {...item}
        />
      );
    });
  }

  return (
    <ResponsiveReactGridLayout
      layout={adjustedLayout}
      rowHeight={rowHeight}
      cols={cols}
      margin={margin}
      measureBeforeMount={false}
      className={"layout"}
      isDraggable={false}
      isResizable={false}
      resizeHandles={[]}
      useCSSTransforms
    >
      {generateDOM()}
    </ResponsiveReactGridLayout>
  )
}
