import React from 'react'
import {HashRouter, Link} from 'react-router-dom'
import {Icon} from '@ant-design/compatible'
import UserAvatar from '../UI/UserAvatar'
import * as S from './styles'
import EntityGravatar from "../EntityAvatar";
import { __ } from '../../utils/translationUtils'

export default ({
                  title,
                  deadline,
                  deadlineColor,
                  priorityLabel,
                  priorityIconProps,
                  users,
                  companies,
                  color,
                  id,
                  box
                }) => (

  <S.Card box={box}>
    <S.VerticalLine color={color}/>
    {title && (
      <HashRouter>
        <Link to={`/orders/${id}/overview`} href={`/orders/${id}/overview`}>
          <S.Title>{__(title)}</S.Title>
        </Link>
      </HashRouter>
    )}
    <S.Content>

      <S.ContentPrimary>
        {deadlineColor && <S.Circle color={deadlineColor} />}
        { deadline && (
          <div>
            <S.Subtitle>{__('Deadline')}</S.Subtitle>
            <S.Text>{deadline}</S.Text>
          </div>
        )}
      </S.ContentPrimary>
      <S.ContentPrimary>
        {priorityIconProps && <S.PriorityIconWrapper><Icon {...priorityIconProps} /></S.PriorityIconWrapper>}
        {priorityLabel && (
          <div>
            <S.Subtitle>{__('Priority')}</S.Subtitle>
            <S.Text>{priorityLabel}</S.Text>
          </div>
        )}
      </S.ContentPrimary>
      <S.ContentSecondary>
        {users && users.length > 0 && Array.isArray(users) && (
          <div>
            <S.Subtitle>{__('Users')}</S.Subtitle>
            <S.IconsWrapper>
              {users.map((user, index) => (
                  <div key={user.email} style={{ position: 'relative', marginLeft: `${index > 0 ? '-1.75' : 0}rem` }}>
                    <HashRouter>

                      <UserAvatar email={user?.email} avatarName={`${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`} userId={user?.id}
                                  avatarType={user?.avatar?.avatarType} title={user?.username}/>
                    </HashRouter>
                  </div>
                ))}
            </S.IconsWrapper>
          </div>
        )}

      </S.ContentSecondary>
      <S.ContentSecondary>
        {companies && companies.length > 0 && Array.isArray(companies) && (
          <div>
            <S.Subtitle>{__('Companies')}</S.Subtitle>
            <S.IconsWrapper>
              {companies.map((company, index) => (
                <div key={company.contactEmail} style={{ position: 'relative', marginLeft: `${index > 0 ? '-1.75' : 0}rem` }}>
                  <HashRouter>
                    <EntityGravatar title={company.name} entityId={company.id} avatarName={company.shortName && company.shortName.charAt(0)} avatarType={company.avatarType} contactEmail={company.contactEmail}/>
                  </HashRouter>
                </div>
              ))}
            </S.IconsWrapper>
          </div>
        )}
      </S.ContentSecondary>
    </S.Content>
  </S.Card>
)
