import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import _ from 'lodash'
import {Panel, PanelGroup} from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import {Draggable, Droppable} from 'react-drag-and-drop'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {hashHistory} from '../providers/HistoryProvider'
import {addAsterisks, checkUserRights} from '../utils/appHelper'
import {isRequired, renderField} from '../utils/fieldValidator'
import SearchAttributeCard from './SearchAttributeCard'
import DisplayFieldCard from './DisplayFieldCard'
import SortAttributeCard from './SortAttributeCard'
import {entryFeatures, listingLayouts} from '../constants'
import {pageSizeOptions} from '../constants/globalConfiguration'

import {api} from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'

const validate = (values) => {
  const errors = {}
  errors.name = isRequired(values.name)

  if (values.type === 'ENTITY') {
    errors.entityIds = isRequired(values.entityIds)
  }

  return errors
}

const SortableItem = SortableElement(({ item }) => <div>{item}</div>)

const SortableList = SortableContainer(({ items }) => (
  <div className="configuration-list">
    {items.map(({ item, key }, index) => (
      <SortableItem index={index} key={`item-${key}`} item={item} />
    ))}
  </div>
))

const stepTitles = {
  1: 'Define Output Fields',
  2: 'Define the Search Fields',
  3: 'Define Default Sorting',
  4: 'Define Visibility'
}

class CustomReportForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      entities: null,
      displayattributesClone: null,
      currentStep: 1,
      activeKey: 0,
      displayAttrsActiveKey: 0,
      publishedFlows: [],
      flowSelected: null,
      filters: {
        q: null,
        entities: null
      },
      items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
      selectedSystem: props.report.systemId || null,
      selectedObjectTypeId: props.report.objectTypeId || null
    }

    // Refs
    this.inoutFlowRef = null

    this.getAllEntities = this.getAllEntities.bind(this)
    this.getPublishFlow = this.getPublishFlow.bind(this)
    this.renderAttributeChildren = this.renderAttributeChildren.bind(this)
    this.renderAvailableAttributesList = this.renderAvailableAttributesList.bind(this)
    this.removeSearchAttribute = this.removeSearchAttribute.bind(this)
    this.addDisplayField = this.addDisplayField.bind(this)
    this.addSortAttribute = this.addSortAttribute.bind(this)
    this.addSearchAttribute = this.addSearchAttribute.bind(this)
    this.setSortAttributeValue = this.setSortAttributeValue.bind(this)
    this.removeSortAttribute = this.removeSortAttribute.bind(this)
    this.removeDisplayField = this.removeDisplayField.bind(this)
    this.displayFieldsList = this.displayFieldsList.bind(this)
    this.sortAttributesList = this.sortAttributesList.bind(this)
    this.searchAttributesList = this.searchAttributesList.bind(this)
    this.onSearchAttributesSortEnd = this.onSearchAttributesSortEnd.bind(this)
    this.onSortAttributesSortEnd = this.onSortAttributesSortEnd.bind(this)
    this.onDisplayAttributesSortEnd = this.onDisplayAttributesSortEnd.bind(this)
    this.resetVersion = this.resetVersion.bind(this)
  }

  componentDidMount() {
    this.getAllEntities()
    this.getPublishFlow()
  }

  handleSubmit(e) {
    e.preventDefault()
    const {
      selectedObjectTypeId,
      selectedSystem
    } = this.state

    this.props.onSubmit({
      selectedObjectTypeId,
      selectedSystem
    })
  }

  setHtmlAttributes() {
    this.inoutFlowRef && this.inoutFlowRef.setAttribute('cy-test', 'inputFlow')
  }

  onSearchAttributesSortEnd({ oldIndex, newIndex }) {
    const attributes = [].concat(this.props.report.searchAttributes)
    const item1 = _.find(attributes, (attr) => attr.position === oldIndex)

    if (newIndex > oldIndex) {
      // Update the position of all the previus items
      const prevItems = _.filter(attributes, (attr) => attr.position >= oldIndex && attr.position <= newIndex)
      prevItems.forEach((item) => {
        item.position -= 1
      })
    } else {
      // Update the position of all the previus items
      const prevItems = _.filter(attributes, (attr) => attr.position >= newIndex && attr.position <= oldIndex)
      prevItems.forEach((item) => {
        item.position += 1
      })
    }

    item1.position = newIndex
    this.props.setSearchAttrtibutes(attributes)
  }

  onDisplayAttributesSortEnd({ oldIndex, newIndex }) {
    const attributes = [].concat(this.props.report.displayAttributes)
    const item1 = _.find(attributes, (attr) => attr.position === oldIndex)

    if (newIndex > oldIndex) {
      // Update the position of all the previus items
      const prevItems = _.filter(attributes, (attr) => attr.position >= oldIndex && attr.position <= newIndex)
      prevItems.forEach((item) => {
        item.position -= 1
      })
    } else {
      // Update the position of all the previus items
      const prevItems = _.filter(attributes, (attr) => attr.position >= newIndex && attr.position <= oldIndex)
      prevItems.forEach((item) => {
        item.position += 1
      })
    }

    item1.position = newIndex
    this.props.setDisplayAttrtibutes(attributes)
  }

  onSortAttributesSortEnd({ oldIndex, newIndex }) {
    const attributes = [].concat(this.props.report.sortAttributes)
    const item1 = _.find(attributes, (attr) => attr.position === oldIndex)

    if (newIndex > oldIndex) {
      // Update the position of all the previus items
      const prevItems = _.filter(attributes, (attr) => attr.position >= oldIndex && attr.position <= newIndex)
      prevItems.forEach((item) => {
        item.position -= 1
      })
    } else {
      // Update the position of all the previus items
      const prevItems = _.filter(attributes, (attr) => attr.position >= newIndex && attr.position <= oldIndex)
      prevItems.forEach((item) => {
        item.position += 1
      })
    }

    item1.position = newIndex
    this.props.setSortAttributes(attributes)
  }

  renderAttributeData(system, node, onClick, listName) {
    const { currentStep } = this.state

    return (
      <ul className="attributes-list">
        {node.data && node.data.map((attr) => {
          if (currentStep === 1 && !attr.displayable) {
            return null
          }

          if (currentStep === 2 && !attr.searchable) {
            return null
          }

          if (currentStep === 3 && !attr.sortable) {
            return null
          }

          if (_.findIndex(this.props.report[listName], (i) => i.attributeId === attr.id) === -1) {
            return (
              <li
                className="attributes-list-item"
                key={`data-${system}-${attr.id}`}
              >
                <Draggable
                  type="item"
                  data={JSON.stringify(attr)}
                >
                  {attr && (__(attr?.propertyLabel) !== attr?.propertyLabel) ? __(attr?.propertyLabel) : attr.property.split('/').pop()}
                  <a
                    onClick={() => onClick({ item: JSON.stringify(attr) })}
                  >
                    <Glyphicon className="pull-right" glyph="plus-sign" />
                  </a>
                </Draggable>
              </li>
            )
          }
        })}
      </ul>
    )
  }

  renderAttributeChildren(system, node, level, onClick, listName) {
    return (node.children.map((child) => (
      <div
        className="gutter-top"
        style={{ paddingLeft: `${level * 8}px` }}
        key={`child-${system}-${node.label}-${child.label}`}
      >
        <h5>{__(child.label)}</h5>

        { child.data.length > 0
            && (
              <div>
                { this.renderAttributeData(system, child, onClick, listName) }
              </div>
            )}

        { child.children
            && (
              <div>
                {this.renderAttributeChildren(system, child, level + 1, onClick, listName)}
              </div>
            )}
      </div>
    ))
    )
  }

  renderAvailableAttributesList({ onClick, listName }) {
    const { remoteSystems, attributesConfigurationTree, report, type } = this.props
    const { selectedSystem, selectedObjectTypeId } = this.state
    const result = []

    if (!attributesConfigurationTree || !remoteSystems) {
      return
    }

    let systems = Object.keys(attributesConfigurationTree)

    // Make suse godoo system is allways on top.
    systems = _.orderBy(systems, (system, idx) => {
      if (system === 'godoo') {
        return 1
      }
      return 2 + idx
    })
    if (type.toLowerCase() === 'stepobject' || type.toLowerCase() === 'overview_object' || type.toLowerCase() === 'remoteobject') {
      if (selectedSystem) {
        systems = [selectedSystem]
      } else {
        systems = []
      }
    }
    if (type.toLowerCase() !== 'remoteobject' || (selectedSystem && selectedObjectTypeId)) {
      // Show all attributes per system.
      systems.forEach((system) => {
        // If has systemId then filter by it.
        if (!report.systemId || report.systemId === system) {
          // Add header title
          result.push(
            <h5 key={`system-${system}`}>{__(remoteSystems.find((e) => e.id === system?.toString())?.name)}</h5>
          )
          result.push(
            <PanelGroup
              accordion
              id={system}
            >{
              attributesConfigurationTree[system].map((item) => {
                // If has objectTypeId then filter by it.
                if (
                  (
                    report.objectTypeId
                    && report.objectTypeId.toString() === item.objectTypeId.toString()
                  )
                  || (
                    selectedObjectTypeId
                    && selectedObjectTypeId.toString() === item.objectTypeId.toString()
                  )
                  || (
                    !report.objectTypeId && !selectedObjectTypeId
                  )
                ) {
                  return (
                    <Panel
                      key={`panel-${system}-${item.label}`}
                      eventKey={`${system}-${item.label}`}
                    >
                      <Panel.Heading>
                        <Panel.Title toggle style={{ padding: '6px 0 6px 6px' }}>{__(item.label)}</Panel.Title>
                      </Panel.Heading>
                      <Panel.Body collapsible>
                        {item.data.length > 0 && this.renderAttributeData(system, item, onClick, listName)}
                        {item.children.length > 0 && this.renderAttributeChildren(system, item, 1, onClick, listName)}
                      </Panel.Body>
                    </Panel>
                  )
                }
              })
            }
            </PanelGroup>
          )
        }
      })
    }
    return result
  }

  addDisplayField(data) {
    this.props.handleAddDisplayField(JSON.parse(data.item))
  }

  removeDisplayField(data) {
    this.props.handleRemoveDisplayField(data)
  }

  handleResetAttributes(data) {
    this.props.handleResetAttributes(data)
  }

  addSearchAttribute(data) {
    this.props.handleAddSearchAttribute(JSON.parse(data.item))
  }

  removeSearchAttribute(data) {
    this.props.handleRemoveSearchAttribute(data)
  }

  addSortAttribute(data) {
    this.props.handleAddSortAttribute(JSON.parse(data.item))
  }

  removeSortAttribute(data) {
    this.props.handleRemoveSortAttribute(data)
  }

  setSortAttributeValue(attribute, value) {
    this.props.handleSetSortAttributeValue(attribute, value)
  }

  searchAttributesList() {
    const { type } = this.props
    const sorted = _.sortBy(this.props.report.searchAttributes, 'position')
    return sorted.map(
      (attr) => ({
        item: <SearchAttributeCard
          key={attr.attributeId}
          attribute={attr}
          type={type}
          attributesConfiguration={this.props.attributesConfiguration}
          extractAttributeConfig={this.props.extractAttributeConfig}
          removeSearchAttribute={this.removeSearchAttribute}
          remoteSystems={this.props.remoteSystems}
        />,
        key: attr.attributeId
      })
    )
  }

  displayFieldsList() {
    const { valueTypesMappings, remoteSystems, extractAttributeConfig, report } = this.props
    if (!remoteSystems) {
      return []
    }

    const sorted = _.sortBy(report.displayAttributes, 'position')
    return sorted.map(
      (attr) => ({
        item: <DisplayFieldCard
          key={attr.attributeId}
          attribute={attr}
          valueTypesMappings={valueTypesMappings}
          extractAttributeConfig={extractAttributeConfig}
          remove={this.removeDisplayField}
          remoteSystems={remoteSystems}
          reduxFormName={'customReportForm'}
        />,
        key: attr.attributeId
      })
    )
  }

  sortAttributesList() {
    const sorted = _.sortBy(this.props.report.sortAttributes, 'position')
    return sorted.map(
      (attr) => ({
        item: <SortAttributeCard
          key={attr.attributeId}
          attribute={attr}
          extractAttributeConfig={this.props.extractAttributeConfig}
          removeSortAttribute={this.removeSortAttribute}
          setSortAttributeValue={this.setSortAttributeValue}
        />,
        key: attr.attributeId
      })
    )
  }

  getAllEntities() {
    this.setState({ isLoading: true })
    return api.get('entities?pageSize=200')
      .then(
        (response) => {
          this.setState({ entities: response.data, isLoading: false })
        },
        (error) => {
          console.log(error)
        }
      )
  }

  getPublishFlow() {
    const { type } = this.props
    const { filters: { q } } = this.state
    if (type && type.toLowerCase() === 'overview_object') {
      const req = `/publishedFlows?pageSize=300&sort=-creationDatetime${q && `&q=${addAsterisks(q)}` || ''}`
      console.log(req)
      api.get(req)
        .then((res) => {
          this.setState({ publishedFlows: res.data })
        })
    }
  }

  resetVersion(element) {
    this.setState({ flowSelected: element.target.value })
  }

  searchFlowName() {
    this.getPublishFlow()
  }

  renderPublishFlow() {
    // Get data from props and state
    const { initialValues, type } = this.props
    const { publishedFlows } = this.state

    // Setting up default list values
    const flowIds = [<option key="000" value="">{__('Select one')}..</option>]
    const flowVersion = [<option key="000" value="">{__('Select one')}..</option>]

    this.setHtmlAttributes()

    // Checking if type is correct and publishedFlows exist
    if (type.toLowerCase() === 'overview_object' && publishedFlows.length) {
      // Implements flowIds list
      for (const flow of publishedFlows) {
        flowIds.push(
          <option key={`flow-${flow.flowId}-${flow.flowVersion}`} value={flow.flowId}>
            {`${flow.title} - ${flow.flowVersion} - (${flow.flowId})`}
          </option>
        )
      }

      if (this.state.publishedFlows.length && (this.state.flowSelected || initialValues.flowId)) {
        const flowSelected = this.state.flowSelected || initialValues.flowId
        const currentFlowsName = _.filter(this.state.publishedFlows, { flowId: flowSelected })
        for (const flow of currentFlowsName) {
          flowVersion.push(
            <option key={`flow-${flow.flowId}-${flow.flowVersion}`} value={flow.flowVersion}>
              {flow.flowVersion}
            </option>
          )
        }
      }
    }
    return (
      <div className="row fieldsflow_objectoverview">
        <div className="col-md-6">
          <Field
            name="flowId"
            component={renderField}
            label={__('Flow')}
            className="form-control"
            onChange={(el) => {
              this.resetVersion(el)
            }}
            items={flowIds}
            labelCol="col-md-4"
            inputCol="col-md-8"
            type="select"
          />
          <Field
            name="flowVersion"
            component={renderField}
            label={__('Flow Version')}
            className="form-control"
            items={flowVersion}
            labelCol="col-md-4"
            inputCol="col-md-8"
            type="select"
          />
        </div>
        <div className="col-md-6">
          <form onSubmit={this.searchFlowName.bind(this)}>
            <div className="form-group">
              <div className="col-xs-12">
                <input
                  className="form-control"
                  type="text"
                  placeholder={`${__('Search Flow and press Enter')}...`}
                  ref={(el) => this.inoutFlowRef = el}
                  onChange={
                    (e) => this.setState({ filters: { ...this.state.filters, q: e.target.value } })
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.searchFlowName()
                    }
                  }}
                />
              </div>

            </div>
          </form>
        </div>
      </div>
    )
  }

  render() {
    const {
      remoteSystems,
      isLoading,
      mode,
      type,
      reportType,
      hideCancel,
      attributesConfigurationTree
    } = this.props

    if (!remoteSystems) {
      return null
    }

    const { currentStep, selectedSystem, selectedObjectTypeId } = this.state
    const renderPanelFooter = () => (
      <div className="group">
        { !hideCancel
            && (
              <button
                className="btn btn-default"
                onClick={() => {
                  hashHistory.goBack()
                }}
              >
                {__('Cancel')}
              </button>
            )}

        { currentStep > 1
          && (
            <button
              className="btn btn-default"
              onClick={() => {
                this.setState({ currentStep: (currentStep - 1) })
              }}
            >
              <Glyphicon glyph="chevron-left" />
              {' '}{ __(stepTitles[currentStep - 1])}
            </button>
          )}

        { (mode === 'edit' || currentStep === 4)
          && (
            <button
              className={'btn pull-right '.concat(mode === 'create' ? 'btn-success' : 'btn-primary')}
              disabled={isLoading}
              onClick={this.handleSubmit.bind(this)}
            >
              <Glyphicon glyph={(mode === 'edit') ? 'save' : 'plus-sign'} />
              { __((mode === 'edit') ? __('Save Changes') : __('Create'))}
            </button>
          )}

        { currentStep < 4 && (type.toLowerCase() !== 'stepobject' || (selectedObjectTypeId && selectedSystem))
          && (
            <button
              className="btn pull-right btn-primary"
              onClick={() => {
                const displayattributesClone = { ...this.props.report.displayAttributes }
                this.setState({
                  currentStep: (currentStep + 1),
                  displayattributesClone
                })
              }}
            >
              { __(stepTitles[(currentStep + 1)])}
              {' '}<Glyphicon glyph="chevron-right" />
            </button>
          )}
      </div>
    )

    const listEntities = () => {
      const entityGroups = _.groupBy(this.state.entities, (e) => e.belongsTo.shortName)
      const optionGroups = []
      for (const entityGroup in entityGroups) {
        optionGroups.push(
          <optgroup key={entityGroup} label={entityGroup}>
            {entityGroups[entityGroup].map((entity) => <option key={entity.id} value={entity.id}>{entity.name}</option>)}
          </optgroup>
        )
      }
      return optionGroups
    }

    const typeOptions = () => {
      const opts = []
      const { rightMappings } = global.constants

      if (checkUserRights(this.props.user.rights, rightMappings.CAN_USE_SELF_TYPE)) {
        opts.push(<option key="option-self" value="SELF">{__('Only me')}</option>)
      }
      if (checkUserRights(this.props.user.rights, rightMappings.CAN_USE_GLOBAL_TYPE)) {
        opts.push(<option key="option-global" value="GLOBAL">{__('Global')}</option>)
      }
      if (checkUserRights(this.props.user.rights, rightMappings.CAN_USE_ENTITY_TYPE)) {
        opts.push(<option key="option-entity" value="ENTITY">{__('Specific Entities')}</option>)
      }
      return opts
    }

    let systems = attributesConfigurationTree && Object.keys(attributesConfigurationTree)

    // Make suse godoo system is allways on top.
    systems = _.filter(systems, (system) => system !== 'godoo')

    return (
      <div>
        <Panel>
          <Panel.Heading>{`${__('Step')} ${currentStep}: ${__(stepTitles[currentStep])}`}</Panel.Heading>
          <Panel.Body>
            <form className="form-horizontal report-form">
              { currentStep === 1
              && (
                <div className="gutter-top">

                  { (this.props.type.toLowerCase() === 'stepobject' || this.props.type.toLowerCase() === 'remoteobject' || this.props.type.toLowerCase() === 'overview_object')
                  && (
                    <div>
                      <h5>{__('System & Object Selection')}</h5>
                      <div className="form-horizontal user-form">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group clearfix">
                              <label className="col-sm-5 control-label">
                              {__('Select a System')}:
                            </label>
                              <div className="col-sm-7">
                              <select
                                  className="form-control"
                                  value={selectedSystem}
                                  onChange={(e) => {
                                    this.setState({
                                      selectedSystem: e.target.value,
                                      selectedObjectTypeId: null
                                    },
                                    // Reset selected Attributes
                                    this.props.handleResetAttributes)
                                  }}
                                >
                                  {
                                    [
                                      <option key="default-option" value="">{__('Select one')}</option>
                                    ].concat(systems.map((system) => <option key={system} value={system}>{__(remoteSystems.find((e) => e.id === system)?.name)}</option>))
                                  }
                                </select>
                            </div>
                            </div>
                          </div>
                        </div>

                        { selectedSystem && selectedSystem !== ''
                        && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group clearfix">
                                <label className="col-sm-5 control-label">
                                  {__('Select an Object')}:
                                </label>
                                <div className="col-sm-7">
                                  <select
                                  className="form-control"
                                  value={selectedObjectTypeId}
                                  onChange={(e) => {
                                      this.setState({ selectedObjectTypeId: e.target.value },
                                      // Reset selected Attributes
                                        this.props.handleResetAttributes)
                                    }}
                                >
                                  {
                                      !selectedSystem ? []
                                        : [
                                          <option key="default-option" value="">{__('Select one')}</option>
                                        ].concat(
                                          selectedSystem && attributesConfigurationTree && attributesConfigurationTree[selectedSystem].sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)).map(
                                            (obj) => <option key={obj.objectTypeId} value={obj.objectTypeId}>{__(obj.label)}</option>
                                          )
                                        )
                                  }
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <hr />
                    </div>
                  )}

                  { ((this.props.type.toLowerCase() !== 'stepobject' && this.props.type.toLowerCase() !== 'overview_object' && this.props.type.toLowerCase() !== 'remoteobject') || (this.props.type.toLowerCase() === 'stepobject' && selectedSystem && selectedObjectTypeId)
                  || (this.props.type.toLowerCase() === 'overview_object' && selectedSystem && selectedObjectTypeId) || (this.props.type.toLowerCase() === 'remoteobject' && selectedSystem && selectedObjectTypeId))
                  && (
                    <div className="row">
                      <div className="col-md-6 col-lg-5">
                        <PanelGroup
                          accordion
                          id="accordion-attributes"
                        >
                          {this.renderAvailableAttributesList({
                            onClick: this.addDisplayField,
                            listName: 'displayAttributes'
                          })}
                        </PanelGroup>
                      </div>

                      <div className="col-md-6 col-lg-7">
                        <Panel>
                          <Panel.Heading><span>{__('Selected Output Fields')}</span></Panel.Heading>
                          <Panel.Body>
                            <SortableList
                              lockAxis="y"
                              lockToContainerEdges
                              items={this.displayFieldsList()}
                              useDragHandle
                              onSortEnd={this.onDisplayAttributesSortEnd}
                            />
                            <div className="discreet text-center gutter-top gutter-bottom">
                              { this.props.report.searchAttributes.length === 0
                              && <span>{__('addFieldsToStartText')}</span>}
                            </div>
                          </Panel.Body>
                        </Panel>
                      </div>
                    </div>
                  )}
                </div>
              )}
              { currentStep === 2
              && (
                <div className="row">
                  <div className="col-md-4 attributes-groups">
                    <PanelGroup
                      accordion
                      id="accordion-attributes"
                    >
                      {this.renderAvailableAttributesList({
                        onClick: this.addSearchAttribute,
                        listName: 'searchAttributes'
                      })}
                    </PanelGroup>
                  </div>

                  <div className="col-md-8">
                    <div>
                      <Droppable
                        className="dnd-drop-area"
                        types={['item']} // <= allowed drop types
                        onDrop={this.addSearchAttribute}
                      >
                        <SortableList
                          lockAxis="y"
                          lockToContainerEdges
                          items={this.searchAttributesList()}
                          useDragHandle
                          onSortEnd={this.onSearchAttributesSortEnd}
                        />
                        <div className="discreet text-center gutter-top gutter-bottom">
                          { this.props.report.searchAttributes.length === 0
                          && <span>{__('dragAttributesToStartText')}</span>}
                          { this.props.report.searchAttributes.length > 0
                          && <span>{__('dragAttributesHereText')}</span>}
                        </div>
                      </Droppable>
                    </div>
                  </div>
                </div>
              )}

              { currentStep === 3
              && (
                <div className="row gutter-top">
                  <div className="col-md-6 col-lg-5">
                    <PanelGroup
                      accordion
                      id="accordion-attributes"
                    >
                      {this.renderAvailableAttributesList({
                        onClick: this.addSortAttribute,
                        listName: 'sortAttributes'
                      })}
                    </PanelGroup>
                  </div>

                  <div className="col-md-6 col-lg-7">
                    <Panel>
                      <Panel.Heading><span>{__('Selected Sorting Attributes')}</span></Panel.Heading>
                      <Panel.Body>
                        <SortableList
                          lockAxis="y"
                          lockToContainerEdges
                          items={this.sortAttributesList()}
                          useDragHandle
                          onSortEnd={this.onSortAttributesSortEnd}
                        />
                        <div className="discreet text-center gutter-top gutter-bottom">
                          { this.props.report.searchAttributes.length === 0
                          && <span>{__('addFieldsToStartText')}</span>}
                        </div>
                      </Panel.Body>
                    </Panel>
                  </div>
                </div>
              )}

              { currentStep === 4
              && (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        name="name"
                        component={renderField}
                        label={__(`${type} Name`)}
                        className="form-control"
                        type="text"
                        labelCol="col-md-5"
                        inputCol="col-md-7"
                      />
                    </div>
                  </div>

                  { (this.props.type.toLowerCase() !== 'stepobject' && this.props.type.toLowerCase() !== 'overview_object')
                  && (
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="onlySelfOrders"
                          component={renderField}
                          label={__('ShowOnlySelfOrders')}
                          type="checkbox"
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                        />
                      </div>
                    </div>
                  )}

                  { this.props.type.toLowerCase() === 'stepobject'
                  && (
                    <div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="addableEntries"
                            component={renderField}
                            label={__('Addable Entries')}
                            type="checkbox"
                            labelCol="col-md-5"
                            inputCol="col-md-7"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="editableEntries"
                            component={renderField}
                            label={__('Editable Entries')}
                            type="checkbox"
                            labelCol="col-md-5"
                            inputCol="col-md-7"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="removableEntries"
                            component={renderField}
                            label={__('Removable Entries')}
                            type="checkbox"
                            labelCol="col-md-5"
                            inputCol="col-md-7"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  { this.props.type.toLowerCase() === 'overview_object'
                && (
                  <div>
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="addableResults"
                          component={renderField}
                          label={__('Addable Entries')}
                          type="checkbox"
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="editableResults"
                          component={renderField}
                          label={__('Editable Entries')}
                          type="checkbox"
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="removableResults"
                          component={renderField}
                          label={__('Removable Entries')}
                          type="checkbox"
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                        />
                      </div>
                    </div>
                  </div>
                )}

                  { (this.props.type.toLowerCase() !== 'stepobject' && this.props.type.toLowerCase() !== 'overview_object')
                  && (
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="type"
                          component={renderField}
                          label={__('Visible to')}
                          className="form-control"
                          type="select"
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                          items={typeOptions()}
                        />
                        <Field
                          name="settings.pageLayout.listingLayout.available"
                          component={renderField}
                          label={__('Layouts Available')}
                          className="form-control"
                          type="select"
                          multiple
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                          items={Object.values(listingLayouts).map((layout) => (
                            <option
                              key={layout}
                              value={layout}
                            >{layout}
                            </option>
                          ))}
                        />
                        <Field
                          name="settings.pageLayout.listingLayout.defaultSelected"
                          component={renderField}
                          label={__('Default Layout')}
                          className="form-control"
                          type="select"
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                          items={Object.values(listingLayouts).map((layout) => (
                            <option
                              key={layout}
                              value={layout}
                            >{layout}
                            </option>
                          ))}
                        />
                        <Field
                          name="settings.pageLayout.pageSize"
                          component={renderField}
                          label={__('Default Page size')}
                          className="form-control"
                          type="select"
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                          items={pageSizeOptions.map((pageSize) => (
                            <option
                              key={pageSize}
                              value={pageSize}
                            >{pageSize}
                            </option>
                          ))}
                        />

                        <Field
                          name="settings.entryFeatures.available"
                          component={renderField}
                          label={__('Features Available')}
                          className="form-control"
                          type="select"
                          multiple
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                          items={Object.values(entryFeatures).map((layout) => (
                            <option
                              key={layout}
                              value={layout}
                            >{layout}
                            </option>
                          ))}
                        />

                      </div>
                    </div>
                  )}

                  {this.props.type.toLowerCase() === 'overview_object' && this.renderPublishFlow()}

                  { reportType === 'ENTITY'
                  && (
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="entityIds"
                          component={renderField}
                          label={__('Select entities')}
                          className="form-control"
                          type="select"
                          multiple
                          withFilter
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                          items={listEntities()}
                        />
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        name="settings.allowBatchOperations"
                        component={renderField}
                        label={__('Allow Batch Operations')}
                        className="form-control"
                        type="checkbox"
                        labelCol="col-md-5"
                        inputCol="col-md-7"
                      />
                    </div>
                  </div>

                  { (this.props.type.toLowerCase() !== 'stepobject' && this.props.type.toLowerCase() !== 'overview_object')
                  && (
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="settings.pageLayout.showSnapshot"
                          component={renderField}
                          label={__('ShowSnapshots')}
                          className="form-control"
                          type="checkbox"
                          labelCol="col-md-5"
                          inputCol="col-md-7"
                        />
                      </div>
                    </div>
                  )}

                </div>
              )}
            </form>
          </Panel.Body>
          <Panel.Footer>{renderPanelFooter()}</Panel.Footer>
        </Panel>
      </div>
    )
  }
}

CustomReportForm.propTypes = {
  handleAddSearchAttribute: PropTypes.func,
  handleRemoveSearchAttribute: PropTypes.func,
  handleAddSortAttribute: PropTypes.func,
  handleRemoveSortAttribute: PropTypes.func,
  handleSetSortAttributeValue: PropTypes.func,
  handleAddDisplayField: PropTypes.func,
  handleRemoveDisplayField: PropTypes.func,
  handleResetAttributes: PropTypes.func,
  setSearchAttrtibutes: PropTypes.func,
  setDisplayAttrtibutes: PropTypes.func,
  setSortAttributes: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  change: PropTypes.func,
  hideCancel: PropTypes.bool,
  getData: PropTypes.func,
  isLoading: PropTypes.bool,
  mode: PropTypes.string,
  type: PropTypes.string,
  report: PropTypes.object,
  reportId: PropTypes.string,
  reportType: PropTypes.string,
  valueTypesMappings: PropTypes.object,
  attributesConfiguration: PropTypes.array,
  attributesConfigurationTree: PropTypes.object,
  extractAttributeConfig: PropTypes.func,
  remoteSystems: PropTypes.object,
  user: PropTypes.object,
  initialValues: PropTypes.object
}

CustomReportForm.defaultProps = {
  remoteSystems: [],
  user: null,
  hideCancel: false
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const selector = formValueSelector('customReportForm')

const CustomReportReduxForm = reduxForm({
  form: 'customReportForm',
  validate
}, mapDispatchToProps)(CustomReportForm)

export default connect(
  (state) => ({
    user: state.user.data,
    remoteSystems: state.ui.remoteSystems,
    reportType: selector(state, 'type')
  })
  ,
  {}
)(CustomReportReduxForm)
