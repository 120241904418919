import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { detect } from 'detect-browser'
import './constants'
import { ToastContainer } from 'react-toastify'
import CloseOutlined from '@ant-design/icons/es/icons/CloseOutlined'
import { ApiProvider, ReduxProvider, StyleProvider } from './providers'
import NotSupportedBrowser from './pages/NotSupportedBrowser'
import Routes from './routes'
import 'react-toastify/dist/ReactToastify.css'
import '@ant-design/compatible/assets/index.css'
import UseQueryProvider from "./providers/QueryClientProvider";
import { registerLicense } from '@syncfusion/ej2-base';
import {getVersion} from "@syncfusion/ej2-base/src/validate-lic";
import {INFO} from "components/dist/Utils/LoggerUtils";

// Registering Syncfusion license key 27.x.x
registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9dcHRQRWRdUkd1Wks=');
INFO(`Syncfusion license key version: ${getVersion()}.x.x`)


const App = () => {
  const browser = detect()

  if (browser && browser.name === 'ie') {
    return <NotSupportedBrowser browser={browser} />
  }

  return (
    <>
      <ReduxProvider>
        <ApiProvider>
          <StyleProvider>
            <UseQueryProvider>
              <Routes/>
            </UseQueryProvider>
          </StyleProvider>
        </ApiProvider>
      </ReduxProvider>
      <ToastContainer
        closeOnClick={false}
        closeButton={({ closeToast }) => <CloseOutlined onClick={closeToast} />}
      />
    </>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
