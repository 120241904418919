import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import { __ } from '../utils/translationUtils'


class StepInfoModal extends React.Component {

  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    order: PropTypes.object,
    params: PropTypes.object
  };

  constructor(props){
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }

  render() {
    const { params, closeModal, open } = this.props;

    let text = params.info? params.info.split('\n').map((item, i) => {
      return <p key={i}>{item}</p>;
    }):"";
    return (
      <div>
        <Modal
          backdrop="static"
          bsSize="lg"
          show={open}
          onHide={closeModal}>

          <Modal.Header closeButton>
            <Modal.Title className="capitalize">
              {__('Step Info')}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {text}
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="pull-left"
              onClick={closeModal}>
              {__('Close')}
            </Button>
          </Modal.Footer>

        </Modal>
      </div>
    );
  }
}
export default StepInfoModal;
