import * as types from '../types';
import objectAssign from 'object-assign';
import { hashHistory } from '../../HistoryProvider';

import { showMessage } from '../../../utils/appHelper';
import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'


export function createOrderType(params) {
  return function () {
    return api.post('/orderTypes',{ ...params })
    .then(
      response => {
      if(response.status === 201){
          setTimeout(()=>{
            showMessage('success', __('Order Type created successfully'));
            },
            200
          );
          
          hashHistory.push('/manageOrderTypes');
        }
      }
    );
  };
}

export function updateOrderType(params) {

  return function (){

    let payload = objectAssign({}, {...params});

    return api.put('/orderTypes/'+params.id,{...payload})
      .then(response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Order Type updated successfully'));
          },
          200
          )
          hashHistory.push('/manageOrderTypes');
        }
      }
      )
  }
}

export function deleteOrderType(id) {
  return function (dispatch) {
    return api.delete('/orderTypes/'+id, {})
      .then(
        response => {       
          if(response.status === 200){
            setTimeout(()=>{
              showMessage('success', __('Order Type deleted successfully'));
              },
              200
            );
          } 
          dispatch({
            type: types.ORDERTYPE_DELETED,
            payload: response.data
          });
        }
      );
    };
}

export function getOrderType(key) {
  return function (dispatch) {
    return api.get('/orderTypes/'+key)
    .then(
      response => {
        dispatch({
          type: types.ORDERTYPE_GOT_DATA,
          payload: response.data
        });
      },
      error => {
        dispatch({
          type: types.ORDERTYPE_GET_DATA_ERROR,
          payload: error.response.data.message
        });
      },
    );
  };
}
