import React from 'react'
import PropTypes from "prop-types";
import {Field} from "redux-form";
import {renderField} from "../../utils/fieldValidator";
import {sortEnumItems} from "../../utils/enums";
import { __ } from '../../utils/translationUtils'
import { getSelectorControllerItems } from '../../utils/appHelper'

class MultipleChoiceControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      enumValues: []
    }
  }

  componentDidMount() {
    this.initializeEnumValues()
  }

  async initializeEnumValues() {
    const {controllerOptions, attributes} = this.props.controlData;
    const orderingType = controllerOptions?.sortOptions?.ordering
    const options = await getSelectorControllerItems(controllerOptions, attributes)
    const sortedItems = sortEnumItems(options, orderingType)
    this.setState({enumValues: sortedItems})
  }

  render() {
    const {id, title, required, description} = this.props.controlData;

    return (<Field
      name={id}
      description={description}
      component={renderField}
      label={__(title)}
      className="form-control"
      items={(this.state.enumValues || []).map(o => <option value={o.value}
                                                            key={o.value}>{o.translationKey ? __(o.translationKey) : o.value}</option>)}
      type="select"
      required={required}
      multiple={true}
      format={(value) => {
        if (typeof value === 'string' || value instanceof String) {
          return value.split(',')
        }
        if (typeof value === 'object' || value instanceof Object) {
          return value
        }
        if(!value){
          return []
        }
        return value
      }}
    />)
  }

}

export default MultipleChoiceControl
