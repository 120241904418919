import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"

import {EditOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons"

import {
  selectionMode as availableSelectionModes
} from 'components/dist/Form/Inputs/ObjectSelectorConfigurator/context/constant';

import RemoteObjectInception from '../RemoteObjectInception'
import {SmartTable} from "../../../../../../UI"
import {getSearchAttributesFromValues} from "../../../../../../UI/SmartTable/utils"

import {Button} from "components"

import {useObjectConfiguratorContext} from "../../context"

import _ from "lodash"
import * as C from "../../constants"
import {Tooltip} from "antd"
import {__} from '../../../../../../../utils/translationUtils'
import {getInceptionProps} from "../../utils";


export default (props) => {
  const {
    toggleSelectionModal,
    rowActions = [],
    attributeFields,
  } = props

  const {state, boundActions} = useObjectConfiguratorContext()
  const {getRemoteObjects, updateEnumerations} = boundActions

  const {
    value,
    onChange,
    writeTo,
    enumerations,
    attributesTree,
    attributesList,
    api,
    missingConfigurationMessage = 'Missing configuration',
  } = state

  const {
    selectedItems = [],
    objectConfiguration = {},
  } = value

  const {
    addableEntries: create,
    editableEntries: edit,
    manualSelection,
    autoSelectOnCreate,
  } = objectConfiguration

  const {
    editItem,
    createItem,
    fetchTableData,
    fetchRemoteTableData,
    fetchRemoteObject,
    fetchDefaultConfiguration,
    addRelations,
    removeRelations,
  } = api

  const [loading, setLoading] = useState(false)
  const [currentSelectedItems, setCurrentSelectedItems] = useState([])
  const [currentObjectConfiguration, setCurrentObjectConfiguration] = useState(null)
  const [formMode, setFormMode] = useState(null)
  const containerRef = useRef(null)
  const selectionRefreshRef = useRef(null)
  const objectName = useMemo(() => (
    objectConfiguration?.systemId &&
    attributesTree?.[objectConfiguration.systemId]?.find((item) => item.objectTypeId === objectConfiguration.objectTypeId)?.label
  ), [attributesTree, objectConfiguration])

  const onlyCreate = useMemo(() => create && !manualSelection, [create, manualSelection])

  const onlyCreatedDisabled = useMemo(() => {
    const {manualSelection, selectionMode} = objectConfiguration || {}
    return !manualSelection && selectionMode === availableSelectionModes.SINGLE && !!selectedItems.length
  }, [objectConfiguration, selectedItems])

  useEffect(() => {
    if (!_.isEqual(selectedItems, currentSelectedItems)) {
      selectionRefreshRef?.current?.()
      setCurrentSelectedItems(selectedItems)
    }
    if (!_.isEqual(objectConfiguration, currentObjectConfiguration)) setCurrentObjectConfiguration(objectConfiguration)
  }, [value])

  const fetchTableDataHelper = useCallback((payload, ids) => {
    if (!state.readFrom) return fetchTableData?.(payload, ids)

    return fetchRemoteTableData?.({
      objectTypeId: objectConfiguration?.objectTypeId,
      payload,
      optionalProps: objectConfiguration?.showOnlySelected ? {
        mappedBy: state.readFrom.attribute.mappedBy,
        parentObjectId: state.readFrom.primaryKey,
      } : {}
    })
      .then((res) => {
        if (objectConfiguration?.showOnlySelected){
          onChange?.({
            selectedItems: res?.data?.map((item) => item.id),
            objectConfiguration
          })
        }
        return res
      })
  }, [state.readFrom])

  const onSearchCallback = useCallback((searchValues) => {
    if (!searchValues) return
    const newSearchAttributes = getSearchAttributesFromValues(searchValues, currentObjectConfiguration.searchAttributes)
    if (!_.isEqual(newSearchAttributes, currentObjectConfiguration.searchAttributes)) {
      onChange?.({
        selectedItems: currentSelectedItems,
        objectConfiguration: {
          ...currentObjectConfiguration,
          searchAttributes: newSearchAttributes
        }
      })
    }
  }, [currentObjectConfiguration, currentSelectedItems, onChange])

  const onAttributeFormSubmitCallback = useCallback(async (itemId, mode) => {
    itemId && getRemoteObjects()
    selectionRefreshRef?.current?.()

    if (autoSelectOnCreate && mode === C.formMode.CREATE) {
      if (writeTo) {
        const parentObj = { objectTypeId: writeTo.attribute.dependencies, mainKey: writeTo.primaryKey }
        itemId && await addRelations(parentObj, { childObject: writeTo.attributeId, childObjectIds: [itemId] }, { sourceAccess: 'FIELD_VALUE' })
      }

      const selected = [...selectedItems, itemId]
      onChange?.({
        objectConfiguration: currentObjectConfiguration,
        selectedItems: selected
      })
    }

    setFormMode(null)
  }, [objectConfiguration?.objectTypeId, onChange, toggleSelectionModal, currentSelectedItems, currentObjectConfiguration, autoSelectOnCreate, onlyCreate])

  const handleRemove = useCallback((idToRemove) => {
    if (writeTo) {
      const parentObj = {objectTypeId: writeTo.attribute.dependencies, mainKey: writeTo.primaryKey}
      removeRelations(parentObj, {
        childObject: writeTo.attributeId,
        childObjectIds: [idToRemove]
      }, {sourceAccess: 'FIELD_VALUE'}).then(() => {
        onChange?.({
          objectConfiguration,
          selectedItems: selectedItems.filter((id) => id !== idToRemove),
        })
      })
    } else {
      onChange?.({
        objectConfiguration,
        selectedItems: selectedItems.filter((id) => id !== idToRemove),
      })
    }
  }, [objectConfiguration, onChange, selectedItems])

  const inceptionProps = useMemo(() => (
    formMode && getInceptionProps(formMode, {objectName})
  ), [formMode, objectName])

  return (
    !currentObjectConfiguration
      ?
      missingConfigurationMessage
      :
      <>
        <SmartTable
          loading={loading}
          value={value.selectedItems}
          attributesList={attributesList}
          enumerations={enumerations}
          methods={{
            fetchTableData: fetchTableDataHelper,
            updateEnumerations,
            setLoading,
            onSearchCallback,
          }}
          showAll={!!state.readFrom}
          refreshRef={selectionRefreshRef}
          containerRef={containerRef}
          objectConfiguration={currentObjectConfiguration}
          openViewDetails={(row) => {
            setFormMode({mode: C.formMode.VIEW, objectId: row.id})
          }}
          rowActions={[
            ...rowActions,
            ...(edit ? [{
              value: 'EDIT',
              icon: <EditOutlined/>,
              onClick: (row) => {
                setFormMode({mode: C.formMode.EDIT, objectId: row.id})
              },
              label: 'Edit',
            }] : []),
            {
              value: 'REMOVE',
              icon: <MinusCircleOutlined/>,
              onClick: (item) => handleRemove(item.id),
              danger: true,
              label: 'Remove',
            }
          ]}
          customHeader={
            (manualSelection || create) && (
              <Tooltip title={manualSelection ? __('Add Item') : onlyCreatedDisabled ?
                __('create_and_auto_select_blocked') : __('Create Item')}>
                <Button
                  type={'primary'}
                  icon={<PlusOutlined/>}
                  onClick={onlyCreate ?
                    () => setFormMode({mode: C.formMode.CREATE}) :
                    () => toggleSelectionModal(true)}
                  disabled={onlyCreatedDisabled}
                  backgroundColor={'#D5E4F7'}
                >
                  {manualSelection ? __('Add Item') : __('Create Item')}
                </Button>
              </Tooltip>
            )
          }
        />
        {
          formMode && (
            <RemoteObjectInception
              {...inceptionProps}
              wrapperProps={{
                title: inceptionProps?.title,
                onCancel: () => {
                  setFormMode(null)
                  if (onlyCreate) toggleSelectionModal?.(false)
                },
                placement: 'right',
                type: C.wrapperType.MODAL,
                containerRef,
              }}
              objectTypeId={objectConfiguration?.objectTypeId}
              attributeFields={attributeFields}
              objectConfiguration={objectConfiguration}
              attributesList={attributesList}
              attributesTree={attributesTree}
              enumerations={enumerations}
              missingConfigurationMessage={missingConfigurationMessage}
              methods={{
                updateEnumerations,
                updateItem: formMode?.mode === C.formMode.EDIT ? editItem : createItem,
                onSubmitCallback: onAttributeFormSubmitCallback,
                fetchRemoteObject,
                fetchRemoteTableData,
                fetchDefaultConfiguration,
                addRelations,
                removeRelations,
              }}
            />
          )
        }
      </>
  )
}
