import { hashHistory } from '../../HistoryProvider';

import { showMessage } from '../../../utils/appHelper';
import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function updateOverviewTabs({ payload, flowId, flowVersion }) {
  return function () {
    return api.put(`/publishedFlows/${flowId}/${flowVersion}/overviewTabs`, payload)
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Overview Tabs updated successfully'));
            },
            200
          );
          hashHistory.push(`/flows`);
        }
      }
    );
  };
}
