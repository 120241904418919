export const CAN_USE_SELF_TYPE = {
  method: "POST",
  requestParam: 25,
  uri: "/retrievableConfigurations",
}

export const CAN_USE_ENTITY_TYPE = {
  method: "POST",
  requestParam: 26,
  uri: "/retrievableConfigurations",
}

export const CAN_USE_GLOBAL_TYPE = {
  method: "POST",
  requestParam: 27,
  uri: "/retrievableConfigurations",
}
