import { showMessage } from '../../../utils/appHelper';
import { api } from '../../ApiProvider'

export function updateProcessOverviewObjects({ payload, flowId, flowVersion, processId }) {
  return function () {
    return api.put(`/publishedFlows/${flowId}/${flowVersion}/processOverviewObjects/${processId}`, payload)
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage(
              'success',
              `Overview Objects Visibility for ${processId} updated successfully`,
            );
          }, 200);
        }
      }
    );
  };
}
