import React, {useCallback, useMemo, useState} from 'react';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import _ from "lodash";

import { __ } from '../../../../utils/translationUtils'
import {Button} from "components";
import {SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import {Switch} from "antd";


const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

const SortingToggleButton = (props) => {
  const [sortValue, setSortValue] = useState(props.value);

  const toggleSorting = () => {
    const newValue = sortValue === 'ASC' ? 'DESC' : 'ASC';
    setSortValue(newValue);
    props.onChangeSelectSorting(newValue);
  };

  return (
    <Button type="outlined" shape="circle" onClick={toggleSorting}>
      {sortValue === 'ASC' ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
    </Button>
  );
};

const SortableItem = SortableElement(props => {
  const { attr, attributesList, activeIds } = props
  const itemFound = _.find(attributesList, config => config.id === attr.attributeId);
  if (!itemFound) { return null }

  const propertyLabel = attr.propertyLabel || itemFound.propertyLabel
  const onChangeCheckBox = () => {
    props.handleCheckBox(attr)
  }

  const onChangeSelectSorting = (e) => {
    props.handleSelectSorting(attr, e)
  }

  return (
    <div className="sorting-list-item">
      <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <DragHandle />
          <Switch
            size="small"
            checked={activeIds.includes(attr.attributeId)}
            onChange={onChangeCheckBox}
          />
          <strong>{__(propertyLabel, "capitalize_sentence")}</strong>
        </div>
        <SortingToggleButton value={attr.value} onChangeSelectSorting={onChangeSelectSorting}/>
      </div>
    </div>
  )
});

const SortableList = SortableContainer(props => {

  const {
    items,
    lockAxis,
    lockToContainerEdges,
    useDragHandle,
    onSortEnd,
    ...itemProps
  } = props

  const sorted = _.sortBy(items, 'position')
  return (
    <div className="sorting-list clearfix">
      {sorted.map((attr, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          attr={attr}
          {...itemProps}
        />
      ))}
    </div>
  )
})

export default (props) => {

  const {
    value = [],
    onChange = () => { },
    attributesList,
  } = props

  const activeIds = useMemo(() => (
    value.filter((attr) => attr.enabled).map((item) => item.attributeId)
  ), [value])

  const onSelect = useCallback((attr, a) => {
    if (!attr) return
    onChange((prev) => prev.map((item) => {
      if (item.attributeId !== attr.attributeId) return item
      return { ...item, enabled: !attr.enabled }
    }))
  }, [onChange])

  const onUpdate = useCallback((attr, value) => {
    if (!attr) return
    onChange((prev) => prev.map((item) => {
      if (item.attributeId !== attr.attributeId) return item
      return { ...item, value: value }
    }))
  }, [onChange])

  const onSort = useCallback((props) => {
    const { oldIndex, newIndex } = props || {}
    onChange((prev) => prev.map((item) => {
      if (oldIndex === item.position) return { ...item, position: newIndex }
      if (newIndex > oldIndex && item.position >= oldIndex && item.position <= newIndex) return { ...item, position: item.position - 1 }
      if (newIndex < oldIndex && item.position >= newIndex && item.position <= oldIndex) return { ...item, position: item.position + 1 }
      return item
    }))
  }, [onChange])

  return (
    <SortableList
      items={value}
      attributesList={attributesList}
      activeIds={activeIds}
      handleCheckBox={onSelect}
      handleSelectSorting={onUpdate}
      lockAxis="y"
      lockToContainerEdges
      useDragHandle={true}
      onSortEnd={onSort}
    />
  )
}
