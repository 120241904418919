import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"

import {EditOutlined, PlusOutlined} from "@ant-design/icons"

import {Button} from "components"
import ConditionalWrapper from "../../../../../../ConditionalWrapper"
import RemoteObjectInception from '../RemoteObjectInception'
import {SmartTable} from "../../../../../../UI"
import {getSearchAttributesFromValues} from "../../../../../../UI/SmartTable/utils"

import {useObjectConfiguratorContext} from "../../context"

import _ from "lodash"
import * as C from "../../constants"

import {__} from '../../../../../../../utils/translationUtils'
import {getInceptionProps} from "../../utils";


const TableSection =  (props) => {
  const {
    wrapperProps,
    selectionMode,
    rowActions = [],
    attributeFields,
    wrapperType,
  } = props

  const {
    type = C.wrapperType.DEFAULT,
    visible,
    onCancel,
    containerRef: externalContainerRef,
  } = wrapperProps || {}

  const {state, boundActions} = useObjectConfiguratorContext()
  const {getRemoteObjects, updateEnumerations} = boundActions

  const {
    value,
    writeTo,
    onChange,
    enumerations,
    attributesTree,
    attributesList,
    api,
    missingConfigurationMessage = 'Missing configuration',
  } = state

  const {
    selectedItems = [],
    objectConfiguration = {},
  } = value

  const {
    addableEntries: create,
    editableEntries: edit,
    manualSelection,
    autoSelectOnCreate,
  } = objectConfiguration

  const {
    editItem,
    createItem,
    fetchTableData,
    fetchRemoteTableData,
    fetchRemoteObject,
    fetchRemoteObjectConfiguration,
    fetchDefaultConfiguration,
    addRelations,
    removeRelations,
  } = api

  const [loading, setLoading] = useState(false)
  const [currentSelectedItems, setCurrentSelectedItems] = useState([])
  const [removed, setRemoved] = useState([])
  const [added, setAdded] = useState([])
  const [currentObjectConfiguration, setCurrentObjectConfiguration] = useState(null)
  const [formMode, setFormMode] = useState(null)
  const containerRef = useRef(null)
  const selectionRefreshRef = useRef(null)
  const objectName = useMemo(() => (
    objectConfiguration?.systemId &&
    attributesTree?.[objectConfiguration.systemId]?.find((item) => item.objectTypeId === objectConfiguration.objectTypeId)?.label
  ), [attributesTree, objectConfiguration])

  const onlyCreate = useMemo(() => create && !manualSelection, [create, manualSelection])

  useEffect(() => {
    if (visible && onlyCreate) {
      setFormMode({mode: C.formMode.CREATE})
    }
  }, [visible, onlyCreate])

  useEffect(() => {
    if (!_.isEqual(selectedItems, currentSelectedItems)) {
      setCurrentSelectedItems([...selectedItems, ...added])
    }
    if (!_.isEqual(objectConfiguration, currentObjectConfiguration)) setCurrentObjectConfiguration(objectConfiguration)
  }, [value])

  const onSearchCallback = useCallback((searchValues) => {
    if (!searchValues) return
    const newSearchAttributes = getSearchAttributesFromValues(searchValues, currentObjectConfiguration.searchAttributes)
    if (type === C.wrapperType.DEFAULT && !_.isEqual(newSearchAttributes, currentObjectConfiguration.searchAttributes)) {
      onChange?.({
        selectedItems: currentSelectedItems,
        objectConfiguration: {
          ...currentObjectConfiguration,
          searchAttributes: newSearchAttributes
        }
      })
    } else if (!_.isEqual(newSearchAttributes, currentObjectConfiguration.searchAttributes)) {
      setCurrentObjectConfiguration((prev) => ({
        ...prev,
        searchAttributes: newSearchAttributes
      }))
    }
  }, [currentObjectConfiguration, currentSelectedItems, onChange, type])

  const onSubmit = useCallback(async (props) => {
    setLoading(true)
    if (writeTo) {
      const parentObj = {objectTypeId: writeTo.attribute.dependencies, mainKey: writeTo.primaryKey}
      const newAdded = props.added || added

      newAdded?.length > 0 && await addRelations(parentObj, {
        childObject: writeTo.attributeId,
        childObjectIds: newAdded
      }, {sourceAccess: 'FIELD_VALUE'})
      removed?.length > 0 && await removeRelations(parentObj, {
        childObject: writeTo.attributeId,
        childObjectIds: removed
      }, {sourceAccess: 'FIELD_VALUE'})
      setAdded([])
      setRemoved([])
    }
    onChange?.({
      objectConfiguration: currentObjectConfiguration,
      selectedItems: currentSelectedItems
    })
    onCancel?.()
    setLoading(false)
  }, [onChange, onCancel, currentSelectedItems, added, removed, currentObjectConfiguration, writeTo])

  const onTableDataChange = useCallback((data, value) => {
    if (!state.readFrom) return
    fetchRemoteTableData?.({
      objectTypeId: objectConfiguration?.objectTypeId,
      payload: {
        ...currentObjectConfiguration,
        pageNumber: currentObjectConfiguration.pageNumber ? currentObjectConfiguration.pageNumber : 1,
        searchAttributes: [],
        sortAttributes: []
      },
      optionalProps: {
        mappedBy: state.readFrom.attribute.mappedBy,
        parentObjectId: state.readFrom.primaryKey,
      }
    }).then((res) => {
      const nextSelected = res.data?.map(({id}) => id.toString())
      onChange?.({
        selectedItems: nextSelected,
        objectConfiguration
      })
      if (_.isEmpty(value) && Object.keys(nextSelected).length > 0) selectionRefreshRef?.current?.(nextSelected)
    })
  }, [state.readFrom, currentObjectConfiguration])

  const onAttributeFormSubmitCallback = (itemId, mode) => {
    itemId && getRemoteObjects()
    selectionRefreshRef?.current?.()

    if (autoSelectOnCreate && mode === C.formMode.CREATE) {
      const selected = [...currentSelectedItems, itemId]
      const newAdded = [...added, itemId]
      setAdded(newAdded)
      setCurrentSelectedItems(selected)

      if (onlyCreate) {
        onSubmit({added: newAdded})
      }
    }
    setFormMode(null)
  }

  const onSelect = useCallback((rows, checked) => {
    setCurrentSelectedItems?.((prev) => {
      let newAdded
      let newRemoved
      switch (selectionMode) {
        case 'radio':
          if (checked) {
            newAdded = rows
            if (!removed.length && prev.length) {
              newRemoved = prev
            }
            if (removed.length && removed.includes(rows[0])) {
              newRemoved = []
              newAdded = []
            }

            newAdded && setAdded(newAdded)
            newRemoved && setRemoved(newRemoved)
          }
          return rows
        case 'checkbox':
          if (checked) {
            setAdded((prev) => [...prev, ...rows.filter((id) => !removed.includes(id))])
            setRemoved((prev) => prev.filter((id) => !rows.includes(id)))
            return [...prev].concat(rows.filter((item) => prev.indexOf(item) < 0))
          } else {

            setRemoved((prev) => [...prev, ...rows.filter((id) => !added.includes(id))])
            setAdded((prev) => prev.filter((id) => !rows.includes(id)))
            return prev.filter((row) => !rows.includes(row))
          }
        default:
          return []
      }
    })
  }, [selectionMode, added, removed])

  const footer = useMemo(() => {
    return (
      <>
        <Button
          onClick={onCancel}
          title={__('Cancel')}
          type={'text'}
        />
        <Button
          type='filled'
          disabled={loading}
          onClick={onSubmit}
          title={__('Save')}
        />
      </>
    )
  }, [onCancel, loading, onSubmit])

  const inceptionProps = useMemo(() => (
    formMode && getInceptionProps(formMode, {objectName})
  ), [formMode, objectName])

  return (
    <ConditionalWrapper
      type={type}
      title={__(`${objectName} selection`)}
      size={'large'}
      fixedheigth={true}
      centered
      visible={visible}
      onSubmitCallback={onSubmit}
      onCancel={() => {
        onCancel?.()
        setCurrentSelectedItems(value?.selectedItems || [])
      }}
      footer={footer}
      containerRef={externalContainerRef || containerRef}
      destroyOnClose
    >
      {!currentObjectConfiguration
        ?
        missingConfigurationMessage
        :
        <>
          <SmartTable
            wrapperType={wrapperType}
            loading={loading}
            value={value.selectedItems}
            attributesList={attributesList}
            enumerations={enumerations}
            methods={{
              fetchTableData,
              updateEnumerations,
              onTableDataChange,
              setLoading,
              onSearchCallback,
            }}
            refreshRef={selectionRefreshRef}
            containerRef={externalContainerRef || containerRef}
            objectConfiguration={currentObjectConfiguration}
            rowSelection={
              selectionMode && {
                type: selectionMode,
                selectedRows: currentSelectedItems,
                onSelect: onSelect,
              }
            }
            openViewDetails={(row) => {
              setFormMode({mode: C.formMode.VIEW, objectId: row.id})
            }}
            rowActions={[
              ...rowActions,
              ...(edit ? [{
                value: 'EDIT',
                icon: <EditOutlined/>,
                onClick: (row) => {
                  setFormMode({mode: C.formMode.EDIT, objectId: row.id})
                },
                label: 'Edit',
              }] : []),
            ]}
            customHeader={create && (
              <Button
                icon={<PlusOutlined/>}
                type={'primary'}
                onClick={() => setFormMode({mode: C.formMode.CREATE})}
                title={__('Create')}
                backgroundColor={'#D5E4F7'}
              />
            )}
          />
          {
            formMode && (
              <RemoteObjectInception
                {...inceptionProps}
                wrapperProps={{
                  title: inceptionProps?.title,
                  onCancel: () => {
                    setFormMode(null)
                    if (onlyCreate) {
                      onCancel?.()
                    }
                  },
                  placement: 'right',
                  type: type === C.wrapperType.DEFAULT ? C.wrapperType.MODAL : C.wrapperType.CONTENT,
                  containerRef: type !== C.wrapperType.DEFAULT && (externalContainerRef || containerRef),
                }}
                objectTypeId={objectConfiguration?.objectTypeId}
                attributeFields={attributeFields}
                objectConfiguration={objectConfiguration}
                attributesList={attributesList}
                attributesTree={attributesTree}
                enumerations={enumerations}
                missingConfigurationMessage={missingConfigurationMessage}
                methods={{
                  updateEnumerations,
                  updateItem: formMode?.mode === C.formMode.EDIT ? editItem : createItem,
                  onSubmitCallback: onAttributeFormSubmitCallback,
                  fetchRemoteObject,
                  fetchRemoteTableData,
                  fetchRemoteObjectConfiguration,
                  fetchDefaultConfiguration,
                  addRelations,
                  removeRelations,
                }}
              />
            )
          }

        </>
      }

    </ConditionalWrapper>
  )
}

export default TableSection
