import styled, { css } from "styled-components";
import RGL, { WidthProvider } from "react-grid-layout";
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import {Col, Image, Row, Skeleton, Steps, Form, Button} from "antd";

const ResponsiveReactGridLayout = WidthProvider(RGL);

export const Flex = styled.div`
  display: flex;
`

export const ColumnFlex = styled(Flex)`
  flex-direction: column;
`

export const SpaceBetween = styled(Flex)`
  justify-content: space-between;
`

export const JustifyCenter = styled(Flex)`
  justify-content: center;
`

const GridItemDynamicStyle = {
  default: css`
    position: relative;
    cursor: grab;
    & > .react-resizable-handle {
      transition: .15s;
      transform: scale(.5);
      opacity: 0;
    }
  `,
  dragging: css`
    cursor: grabbing;
    opacity: .5;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  `,
  hovering: css`
    & > .react-resizable-handle {
      transition: .15s;
      transform: scale(1);
      opacity: 1;
    }
  `,
}

export const GridItem = styled.div`
  ${GridItemDynamicStyle.default};
  overflow: hidden;
  background-color: white;
  border-radius: 4px;
  padding: 12px;
  transition-duration: .25s;
  font-size: 14px;
  ${props => props.dragging && GridItemDynamicStyle.dragging};
  ${props => props.hovering && GridItemDynamicStyle.hovering};
  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 1px;
    background-color: #0a4f7a;
  }
`

export const GridWrapper = styled(ResponsiveReactGridLayout)`
  width: 100%;
  min-height: 100%;
  overflow: scroll;
  .react-grid-placeholder {
    background-size: auto 100% !important;
    padding: 5px;
    background: #232323 !important;
    border-radius: 4px;
  }
`

const RemoveButtonDynamicStyle = {
  default: css`
    opacity: 0;
    transition-duration: .1s;
    font-size: 0;
  `,
  displaying: css`
    opacity: 1;
    transition-duration: .1s;
    font-size: 17px;
  `,
}

export const RemoveButton = styled(CloseCircleOutlined)`
  ${RemoveButtonDynamicStyle.default};
  color: #004671;
  background-color: white;
  z-index: 1;
  align-self: center;
  right: 3px;
  top: 3px;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0) 0 1px 1px;
  &:hover {
    color: #004671;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px;
  }
  ${props => props.displaying && RemoveButtonDynamicStyle.displaying};
`

export const GridItemHeader = styled(SpaceBetween)`
  position: relative;
  margin-bottom: 8px;
`

export const GridItemTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #004671;
  font-weight: 700;
`

export const GridItemBody = styled.div`
  color: #232323;
  padding: 8px 0;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CustomSkeletonDynamicStyle = {
  small: css`
    & > li {
      height: 10px;
      & + li {
        margin-top: 8px;
      }
    }
  `,
}

export const CustomSkeleton = styled(Skeleton)`
  & .ant-skeleton-content .ant-skeleton-paragraph {
    margin: unset;
    ${props => {
      switch(props.size) {
        case 'small':
          return CustomSkeletonDynamicStyle.small
        default:
          return null
      }
    }};
  }
`

export const DraggableItem = styled.div`
  margin: 8px 0;
  padding: 5px 10px;
  white-space: nowrap;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #004671;
  font-weight: 700;
  background: #dfdfdf;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border: 1px solid transparent;
  transition-duration: .25s;
  cursor: grab;
  font-size: 14px;
  &:hover {
    background-color: white;
    border-color: #004671;
  }
`

export const ItemInfoIcon = styled(InfoCircleOutlined)`
  font-size: 12px;
  margin: auto 0 auto 10px;
`

export const SearchSection = styled.div`
  padding-bottom: 8px;
`

export const ListSection = styled(ColumnFlex)`
  font-size: 12px;
`

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: scroll;
  padding: 15px 64px;
`

export const PreviewTitle = styled(ColumnFlex)`
  align-items: center;
  margin: 24px 10%;
  & > :not(:last-child) {
    padding-bottom: 12px;
  }
`

export const PreviewSteps = styled(Steps)`
  width: calc(40% + 64px);
  & .ant-steps-item-icon {
    cursor: default;
    color: white;
    font-family: inherit;
    width: 64px;
    height: 64px;
    margin: unset;
    font-size: 38px;
    font-weight: 600;
    line-height: 60px;
    background-color: transparent;
    border-width: 4px;
    border-color: white;
  }
  & .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: transparent;
  }
  & .ant-steps-item-wait .ant-steps-item-icon {
    line-height: 62px;
    border-width: 2px;
    & > .ant-steps-icon {
      color: inherit;
    }
  }
  & .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: white;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: white;
  }
  & .ant-steps-item-title::after {
    top: 30px;
    height: 4px;
    opacity: .5;
    left: auto;
  }
  &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: unset;
  }
  .ant-steps-item-finish .ant-steps-item-icon{
    background-color: white;
    & > .ant-steps-icon {
      color: #3d729c;
    }
  }
`

export const StepCol = styled(Col)`
  cursor: ${props => props.disabled ? 'not-allowed': 'pointer'};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 12px 20px 24px;
  & > :not(:last-child) {
    padding-bottom: 12px;
  }
`

export const StepRow = styled(Row)`
  &:not(:last-child) {
    margin: 0 10%;
  }
  &:last-child {
    margin: 0 10% auto;
    &, & > .ant-col, & > .ant-col > .ant-image {
      max-height: 100%;
      overflow: hidden;
    }
  }
`

export const StepItemContent = styled(Col)`
  font-size: ${props => {
    switch (props.size){
      case 'giga':
        return '26px'
      case 'large':
        return '20px'
      case 'small':
        return '14px'
      default:
        return '16px'
    }
  }};
  font-weight: ${props => {
    switch (props.weight){
      case 'large':
        return '800'
      case 'small':
        return '600'
      default:
        return '700'
    }
  }};
  max-width: ${props => props.expansion || '60%'};
  text-align: center;
  color: white;
`

const StepItemImageDynamicStyle = {
  disabled: css`
    max-width: 80%;
    opacity: .6;
  `,
  hovered: css`
    max-width: 100%;
    opacity: 1;
  `,
}

export const StepItemImage = styled(Image)`
  max-height: 100%;
  max-width: 90%;
  transition-duration: .25s;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto auto;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
  ${props => props.disabled && StepItemImageDynamicStyle.disabled};
  ${props => props.hovered && StepItemImageDynamicStyle.hovered};
`

export const SystemObjectSelectContainer = styled(ColumnFlex)`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: scroll;
  padding: 15px;
`

export const SystemObjectForm = styled(Form)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 64px 0;
  & :not(:last-child) {
    margin-right: 32px;
  }
  & .ant-form-item {
    width: 20%;
    min-width: 150px;
    max-width: 500px;
  }
  .ant-form-item-label > label {
    color: white;
    font-weight: 600;
  }
`

export const AttributesListingContainer = styled(Form)`
  overflow-y: scroll;
  height: 100%;
  margin: 16px 0 0;
`

export const ShortcutButton = styled(Button)`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  color: #004671 !important;
  &:hover {
    color: #3d729c !important;
  }
`
