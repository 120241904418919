import React from 'react'
import {checkUserRights} from "../../utils/appHelper";

export const rightsCheckMethodType = {
  PARENT: 'PARENT',
  CHILDREN: 'CHILDREN',
}

const isVisible = (item, guiUser) => {
  if (!item || !guiUser) return false
  const { type, rights } = item?.rightsCheck || {}
  if (!type) return true
  switch (type) {
    case rightsCheckMethodType.PARENT:
      if (Array.isArray(rights)) {
        return rights.reduce((prev, subRights) => (prev && checkUserRights(guiUser.rights, subRights)), true)
      }
      return checkUserRights(guiUser.rights, rights)
    case rightsCheckMethodType.CHILDREN:
      return !!item.items?.length
    default:
      return false
  }
}

const isActive = (item, location) => {
  if (item?.items?.length) {
    return item.items.some((subItem) => subItem.active)
  }
  else if (item?.path) {
    return item.path === location.pathname.substring(0, item.path?.length)
  }
  return false
}

export const getDisplayableItems = (items, guiUser) => (
  items?.reduce((prev, item) => {
    const newItem = {...item}
    if (newItem.items?.length) newItem.items = getDisplayableItems(newItem.items, guiUser)
    if (isVisible(newItem, guiUser)) prev.push(newItem)
    return prev
  }, [])
)

export const getItemsWithActiveFlag = (items, location) => (
  items?.map((item) => {
    const newItem = {...item}
    if (newItem.items?.length) newItem.items = getItemsWithActiveFlag(newItem.items, location)
    if (isActive(newItem, location)) newItem.active = true
    return newItem
  }, [])
)

