import React from "react";
import Lottie from "react-lottie";

const LottieFile = (props) => {
    const lottieOptions = {
        loop: props.loop,
        autoplay: true,
        animationData: props.file,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
    }

  return (
    <div style={{pointerEvents: 'none'}}>
      <Lottie options={lottieOptions} style={{width: props.width}}/>
    </div>
  )
}

export default LottieFile

LottieFile.defaultProps = {
  loop: true,
  width: "65%"
}
