import React from 'react';
import PropTypes from 'prop-types';
import {SortableHandle} from 'react-sortable-hoc';
import {change, Field, getFormValues} from 'redux-form';
import Glyphicon from '@strongdm/glyphicon'
import {connect} from 'react-redux';

import {renderField} from '../utils/fieldValidator';
import {getEnumValues} from '../providers/ReduxProvider/actions/reportActions';
import PropertyLabelTitle from "./PropertyLabelTitle";
import { __ } from '../utils/translationUtils'

const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

class SearchAttributeCard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      attribute,
      formValues,
      extractAttributeConfig,
      enumValues
    } = this.props;
    // Set default selected value to "SPECIFIC_VALUE".
    if (typeof formValues[`${attribute.attributeId}_mechanism`] === 'undefined'){
      this.props.dispatch(change('customReportForm', `${attribute.attributeId}_mechanism`, 'SPECIFIC_VALUE'));
    }

    const config = extractAttributeConfig(attribute.attributeId);
    if (config.enumerationId && !enumValues[config.enumerationId]){
      this.props.dispatch(getEnumValues(config.enumerationId))
    }
  }

  handleFormat(attr, value){
    const {formValues} = this.props;
    if (typeof value === 'string' && formValues[`${attr.attributeId}_multiSelect`] && attr.mechanism === 'ENUMERATION'){
      return  value.split(',')
    }

    return value
  }

  render() {
    const {
      attribute,
      extractAttributeConfig,
      removeSearchAttribute,
      getEnumValues,
      enumValues,
      remoteSystems,
      formValues,
      type,
    } = this.props;
    const config = extractAttributeConfig(attribute.attributeId);

    if (typeof config === 'undefined' || !remoteSystems) {
      return null;
    }

    let fieldType;
    if (config.type === 'Boolean') {
      fieldType = 'checkbox';
    } else if (config.type === 'YESNO' || config.type === 'Yesno') {
      fieldType = 'Yesno';
    } else if (config.type === 'DATE') {
      fieldType = 'datetime';
    } else if (config.type === 'DATETIME') {
      fieldType = 'datetime';
    } else {
      fieldType = 'text';
    }
    return (
      <div
          className="configuration-list-item"
          key={attribute.attributeId}
        >

        <div className="header">
          <DragHandle />{' '}

          <a
            className="pull-right"
            onClick={() => removeSearchAttribute(attribute)}
          >
            <Glyphicon glyph="remove" />
          </a>

          <strong>{__(config.propertyLabel)}</strong>
          {' '}<span style={{fontSize: '11px'}}>{__('Position')}: {attribute.position + 1}</span>

          <aside>{__('System')}: <strong>{remoteSystems.find((e) => e.id === config.system)?.name}</strong> | {__('Object')}: <strong>{__(config.dependenciesLabel)}</strong></aside>
        </div>

        <div className="content row clearfix">
          <div className="col-md-6">
            <Field
              required
              name={`${attribute.attributeId}_mechanism`}
              component={renderField}
              label={__('Mechanism')}
              className="form-control"
              onChange={(e) => {
                if (e.target.value === 'ENUMERATION') {
                  if (config && config.enumerationId) {
                    this.props.dispatch(getEnumValues(config.enumerationId));
                  }
                }
              }}
              type="select"
              labelCol="col-md-4"
              inputCol="col-md-8"
              items={
                (type.toLowerCase() === 'remoteobject') ?
                [
                  // <option key="0" value="">{__('Select ')}</option>,
                  (config && config.specificValueMechanismAllowed) ? <option key="1" value="SPECIFIC_VALUE">{__('SPECIFIC_VALUE')}</option> : null,
                  (config && config.rangeMechanismAllowed) ? <option key="2" value="RANGE">{__('RANGE')}</option> : null,
                  (config && config.enumMechanismAllowed && config.enumerationId) ? <option key="3" value="ENUMERATION">{__('ENUMERATION')}</option> : null,
                ] :
                [
                  // <option key="0" value="">{__('Select ')}</option>,
                  <option key="1" value="SPECIFIC_VALUE">{__('SPECIFIC_VALUE')}</option>,
                  <option key="2" value="RANGE">{__('RANGE')}</option>,
                  (config && config.enumerationId) ? <option key="3" value="ENUMERATION">{__('ENUMERATION')}</option> : null,
                ]
              }
            />
          </div>

          <div className="col-md-6">
            {formValues[`${attribute.attributeId}_mechanism`] !== 'ENUMERATION' &&
            formValues[`${attribute.attributeId}_mechanism`] !== 'RANGE' &&
              <Field
                name={`${attribute.attributeId}_value`}
                component={renderField}
                label={__('Default Value')}
                className="form-control"
                type="text"
                labelCol="col-md-4"
                inputCol="col-md-8"
              />
            }
            {formValues[`${attribute.attributeId}_mechanism`] === 'RANGE' &&
              <div>
                <div>
                  <Field
                    name={`${attribute.attributeId}_valueFrom`}
                    component={renderField}
                    label={__('Default From')}
                    className="form-control"
                    type={fieldType}
                    labelCol="col-md-4"
                    inputCol="col-md-8"
                  />
                </div>
                <div>
                  <Field
                    name={`${attribute.attributeId}_valueTo`}
                    component={renderField}
                    label={__('Default To')}
                    className="form-control"
                    type={fieldType}
                    labelCol="col-md-4"
                    inputCol="col-md-8"
                  />
                </div>
              </div>

            }
            {formValues[`${attribute.attributeId}_mechanism`] === 'ENUMERATION' &&
              <Field
                name={`${attribute.attributeId}_value`}
                component={renderField}
                label={__('Default Value')}
                className="form-control"
                type="select"
                labelCol="col-md-4"
                inputCol="col-md-8"
                multiple={formValues[`${attribute.attributeId}_multiSelect`]}
                format={(value) => this.handleFormat(attribute, value)}
                items={
                  enumValues[config.enumerationId] &&
                  [
                    <option key="option--0" value="">{__('Select one')}</option>
                  ].concat(enumValues[config.enumerationId].sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)).map((option, idx) => {
                    let value = option.value
                    if(option.toBeTranslated && option.translationKey){
                      value = option.translationKey
                    }
                    return <option key={`option-${option.id}-${idx}`}
                            value={option.value}>{__(value)}</option>
                  }
                ))}
              />
            }
          </div>

          <div className="col-md-3">
            <Field
              name={`${attribute.attributeId}_required`}
              component={renderField}
              label={__('Required')}
              type="checkbox"
              labelCol="col-md-9"
              inputCol="col-md-3"
            />
          </div>

          <div className="col-md-3">
            <Field
              name={`${attribute.attributeId}_hidden`}
              component={renderField}
              label={__('Hidden')}
              type="checkbox"
              labelCol="col-md-9"
              inputCol="col-md-3"
            />
          </div>


          {formValues[`${attribute.attributeId}_mechanism`] === 'ENUMERATION' &&
            <div className="col-md-3">
              <Field
                name={`${attribute.attributeId}_multiSelect`}
                component={renderField}
                label={__('Multi select')}
                type="checkbox"
                labelCol="col-md-9"
                inputCol="col-md-3"
              />
            </div>
          }
          <div className="col-xs-12 col-sm-12 col-md-9"><PropertyLabelTitle attribute={attribute} type={'search'}/></div>
        </div>


      </div>
    );
  }
}

SearchAttributeCard.propTypes = {
  attribute: PropTypes.object,
  enumValues: PropTypes.object,
  formValues: PropTypes.object,
  remoteSystems: PropTypes.arrayOf(Object),
  dispatch: PropTypes.func,
  getEnumValues: PropTypes.func,
  type: PropTypes.string,
  attributesConfiguration: PropTypes.array,
  extractAttributeConfig: PropTypes.func,
  removeSearchAttribute: PropTypes.func
};

SearchAttributeCard.defaultProps = {
  remoteSystems: []
}

export default connect(
  (state) => {
    return {
      enumValues: state.enumValues,
      formValues: getFormValues('customReportForm')(state),
    };
  },
  dispatch => {
    return {
      dispatch,
      getEnumValues,
    };
  },
)(SearchAttributeCard);

