import React, {useContext, useEffect, useState} from "react"
import {Collapse, Descriptions, Switch} from 'antd'
import * as CONTEXT from "./../../context"
import {DISPLAY_ATTRIBUTES} from "./../../utils/requiredFields"
import * as UT from "./../utils"
import * as S from "./../../style"
import AttributeTransfer from "../AttributeTransfer"
import FormContext from "../../factories/FormContext"
import {Form, Icon} from "@ant-design/compatible"

const {Panel} = Collapse

function View() {
  const {state} = useContext(CONTEXT.MainContext)
  const {form} = useContext(FormContext)

  const [attributeInformation, setAttributeInformation] = useState(null)

  useEffect(() => {
    if (state.editingForms.configurationFormFields) {
      setAttributeInformation(UT.retrieveAttributesInformation(state.editingForms.configurationFormFields, DISPLAY_ATTRIBUTES))
    }
  }, [state.editingForms.configurationFormFields])

  const renderCard = (key) => {
    const attributeProperty = (attributeInformation?.attributesFlat || []).find((attribute) => attribute.key === key)

    return (
      <S.SortableContainer>
        <S.FormDescription>
          <Descriptions title={attributeProperty?.property || ""}/>
        </S.FormDescription>

        <Collapse defaultActiveKey={[]}>
          <Panel
            header={
              <S.PanelHeader>
                <Icon type="more"/>
                Show More
              </S.PanelHeader>
            }
          >
            {/* DEFAULT VALUE */}
            <S.FormField>
              <Form.Item label={"Summary"}>
                {form.getFieldDecorator(`${DISPLAY_ATTRIBUTES},${key},summary`, {
                  initialValue: false,
                  valuePropName: "checked",
                })(<Switch/>)}
              </Form.Item>
            </S.FormField>
          </Panel>
        </Collapse>
      </S.SortableContainer>
    )
  }

  const createDisplayAttributes = (attributeKeys) => {
    const res = {}
    attributeKeys.forEach((attributeId, index) => {
      Object.assign(res, {
        [`${DISPLAY_ATTRIBUTES},${attributeId},attributeId`]: {
          name: `${DISPLAY_ATTRIBUTES},${attributeId},attributeId`,
          value: attributeId,
        },
        [`${DISPLAY_ATTRIBUTES},${attributeId},position`]: {
          name: `${DISPLAY_ATTRIBUTES},${attributeId},position`,
          value: index,
        },
        [`${DISPLAY_ATTRIBUTES},${attributeId},summary`]: {
          name: `${DISPLAY_ATTRIBUTES},${attributeId},summary`,
          value: form.getFieldValue(`${DISPLAY_ATTRIBUTES},${attributeId},summary`) || false,
        },
      })
    })
    return res
  }

  if (!attributeInformation) {
    return <S.Loading>...Loading</S.Loading>
  }

  return (
    <AttributeTransfer
      attributesInformation={attributeInformation}
      renderCard={renderCard}
      attributeType={DISPLAY_ATTRIBUTES}
      onAttributeTransfer={createDisplayAttributes}
    />
  )
}

export default View
