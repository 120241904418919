import {Form} from '@ant-design/compatible';
import {
  ADDABLE_ENTRIES,
  DISPLAY_ATTRIBUTES,
  EDITABLE_ENTRIES,
  REMOVABLE_ENTRIES,
  SEARCH_ATTRIBUTES,
  SORT_ATTRIBUTES
} from "../utils/requiredFields"
import * as SORT_TYPE from "./../templates/sortAttributes/sortingType"
import * as UT from "./../utils"


function mapAttributesToConfiguration(attributes) {
  const attributesResult = {}
  Object.entries(attributes).forEach(([attributeKey, attributeValue]) => {
    const currentPath = attributeKey
      .replace(/,/g, ".")
      .replace(/\[(\w+)\]/g, ".$1")
      .replace(/^\./, "")
    const currentValue = attributeValue.value
    const args = currentPath.split(".")
    UT.updatePayload(attributesResult, args, currentValue)
  })

  return attributesResult
}

export const mapFormFieldsToConfiguration = (formFields) => {
  if (!formFields) {
    throw Error("No changed Fields provided to update")
  }

  const res = {}
  const displayAttributes = UT.filerObjectByKey(formFields, (key) => key.startsWith(DISPLAY_ATTRIBUTES))
  const searchAttributes = UT.filerObjectByKey(formFields, (key) => key.startsWith(SEARCH_ATTRIBUTES))
  const sortAttributes = UT.filerObjectByKey(formFields, (key) => key.startsWith(SORT_ATTRIBUTES))

  const remainingFields = UT.filerObjectByKey(
    formFields,
    (key) => !(key.startsWith(DISPLAY_ATTRIBUTES) || key.startsWith(SEARCH_ATTRIBUTES) || key.startsWith(SORT_ATTRIBUTES))
  )

  res[DISPLAY_ATTRIBUTES] = Object.values(mapAttributesToConfiguration(displayAttributes)[DISPLAY_ATTRIBUTES] || {}).map((v) => v)
  res[SEARCH_ATTRIBUTES] = Object.values(mapAttributesToConfiguration(searchAttributes, res)[SEARCH_ATTRIBUTES] || {}).map((v) => v)
  res[SORT_ATTRIBUTES] = Object.values(mapAttributesToConfiguration(sortAttributes, res)[SORT_ATTRIBUTES] || {}).map((v) => v)


  Object.entries(remainingFields).forEach(([fieldKey, fieldValue]) => Object.assign(res, {[fieldKey]: fieldValue.value}))
  console.log(res)
  return res
}

function manageConfigurationFieldOnName(key, value, res) {
  switch (key) {
    case DISPLAY_ATTRIBUTES:
      if (!Array.isArray(value)) {
        throw Error("Display Attributes IS NOT iterable")
      }
      value.forEach((attribute) => {
        if (!attribute.attributeId) {
          throw Error("Every Attribute MUST have an attributeId" + JSON.stringify(attribute))
        }

        Object.assign(res, {
          [`${DISPLAY_ATTRIBUTES},${attribute.attributeId},controllerType`]: Form.createFormField({
            name: `${DISPLAY_ATTRIBUTES},${attribute.attributeId},controllerType`,
            value: null,
          }),
        })

        Object.entries(attribute).forEach(([attributeKey, attributeValue]) =>
          Object.assign(res, {
            [`${DISPLAY_ATTRIBUTES},${attribute.attributeId},${attributeKey}`]: Form.createFormField({
              name: `${DISPLAY_ATTRIBUTES},${attribute.attributeId},${attributeKey}`,
              value: attributeValue,
            }),
          })
        )
      })
      break
    case SEARCH_ATTRIBUTES:
      if (!Array.isArray(value)) {
        throw Error("Display Attributes IS NOT iterable")
      }
      value.forEach((attribute) => {
        if (!attribute.attributeId) {
          throw Error("Every Attribute MUST have an attributeId" + JSON.stringify(attribute))
        }

        // Assign now default value because in search field i have to manage range mechanism. If a value exist, the
        // value generated now will override below

        Object.assign(res, {
          [`${SEARCH_ATTRIBUTES},${attribute.attributeId},value`]: Form.createFormField({
            name: `${SEARCH_ATTRIBUTES},${attribute.attributeId},value`,
            value: "",
          }),
          [`${SEARCH_ATTRIBUTES},${attribute.attributeId},from`]: Form.createFormField({
            name: `${SEARCH_ATTRIBUTES},${attribute.attributeId},from`,
            value: "",
          }),
          [`${SEARCH_ATTRIBUTES},${attribute.attributeId},to`]: Form.createFormField({
            name: `${SEARCH_ATTRIBUTES},${attribute.attributeId},to`,
            value: "",
          }),
        })

        Object.entries(attribute).forEach(([attributeKey, attributeValue]) =>
          Object.assign(res, {
            [`${SEARCH_ATTRIBUTES},${attribute.attributeId},${attributeKey}`]: Form.createFormField({
              name: `${SEARCH_ATTRIBUTES},${attribute.attributeId},${attributeKey}`,
              value: attributeValue,
            }),
          })
        )
      })
      break
    case SORT_ATTRIBUTES:
      if (!Array.isArray(value)) {
        throw Error("Display Attributes IS NOT iterable")
      }
      value.forEach((attribute) => {
        if (!attribute.attributeId) {
          throw Error("Every Attribute MUST have an attributeId" + JSON.stringify(attribute))
        }

        Object.assign(res, {
          [`${SORT_ATTRIBUTES},${attribute.attributeId},value`]: Form.createFormField({
            name: `${SORT_ATTRIBUTES},${attribute.attributeId},value`,
            value: SORT_TYPE.ASC,
          }),
        })

        Object.entries(attribute).forEach(([attributeKey, attributeValue]) =>
          Object.assign(res, {
            [`${SORT_ATTRIBUTES},${attribute.attributeId},${attributeKey}`]: Form.createFormField({
              name: `${SORT_ATTRIBUTES},${attribute.attributeId},${attributeKey}`,
              value: attributeValue,
            }),
          })
        )
      })
      break
    case ADDABLE_ENTRIES:
    case EDITABLE_ENTRIES:
    case REMOVABLE_ENTRIES:
      Object.assign(res, {[key]: Form.createFormField({checked: value, name: key})})
      break
    default:
      Object.assign(res, {[key]: Form.createFormField({value: value, name: key})})
      break
  }
}

export const mapConfigurationToFormFields = (configurationToEdit) => {
  if (!configurationToEdit) {
    throw Error()
  }

  const res = {}

  Object.entries(configurationToEdit).forEach(([key, value]) => manageConfigurationFieldOnName(key, value, res))
  console.log(res)
  return res
}

export const updateFormFields = (changedFields) => {
  const res = {}
  Object.entries(changedFields).forEach(([key, value]) => Object.assign(res, {[key]: Form.createFormField(value)}))
  return res
}
