import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from 'styled-components'
import {Image, Modal as AntdModal, Modal, Upload} from "antd";
import {ENTITY_FORM_NAME, USER_FORM_NAME} from "./EntityFormNames";
import {useSelector} from "react-redux";
import md5 from "md5";

export const CUSTOM_PICTURE_AVATAR_TYPE = "CUSTOM_PICTURE";
export const GRAVATAR_AVATAR_TYPE = "GRAVATAR";
export const INITIAL_AVATAR_TYPE = "INITIALS";
export const AVATAR_TYPE_FIELD_NAME = "avatar.avatarType";
export const AVATAR_URL_FIELD_NAME = "avatar.avatarUrl";

EntityAvatarForm.propTypes = {
  avatarName: PropTypes.string.isRequired,
  formName: PropTypes.oneOf([ENTITY_FORM_NAME, USER_FORM_NAME]),
  gravatarMail: PropTypes.string.isRequired
}

const CompanyLogoViewStyled = styled(Image)`
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  position: absolute; 
  z-index: 1;
`

// I need to use Upload and not Image because I don't find the necessary customization for the preview Modal
// Maybe in future versions of Antd it will possible to use Image instead
const CompanyLogoImage = styled(Upload)`
  background-color: #fafafa;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9e9e9;
  overflow: hidden;

  .ant-upload-list-picture-card-container {
    margin: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 50%;
  }

  .ant-upload-list-item-info {
    border-radius: 50%;
  }

`
const CompanyLogoPreviewModal = styled(AntdModal)``

const gravatarUrl = (email) => `https://www.gravatar.com/avatar/${email && md5(email)}?s=1500&d=https://www.godoo.ch/en/icons/icon-512x512.png?v=d57b514fa910e3ff9d98c1ae52bfb7d7`
const avatarLettersUrl = (avatarName) => `https://eu.ui-avatars.com/api/?size=512&name=${avatarName}`

const antdFile = (name, url) => ({
  uid: '-1',
  name: name || '',
  status: 'done',
  url: url
})


function EntityAvatarForm({avatarName, formName, gravatarMail, customPictureSrcImage}) {

  const avatarType = useSelector(state => state?.form[formName] ? state?.form[formName].values?.avatar?.avatarType : null)

  const [previewVisible, setPreviewVisible] = useState(false)
  const [fileList, setFileList] = useState([])

  const closePreviewModal = () =>  {
    setPreviewVisible(false)
  }

  useEffect(() => {

    if (avatarType == null) return

    switch (avatarType) {

      case CUSTOM_PICTURE_AVATAR_TYPE:
        if (!customPictureSrcImage){
          setFileList([antdFile('INITIALS', avatarLettersUrl(avatarName))])
          break
        }
        setFileList([antdFile('CUSTOM_PICTURE', customPictureSrcImage)])
        break
      case GRAVATAR_AVATAR_TYPE:
        if (!gravatarMail){
          setFileList([antdFile('INITIALS', avatarLettersUrl(avatarName))])
          break
        }
        setFileList([antdFile('GRAVATAR', gravatarUrl(gravatarMail))])
        break
      default:
        setFileList([antdFile('INITIALS', avatarLettersUrl(avatarName))])
    }


  }, [avatarName, avatarType, customPictureSrcImage, gravatarMail])


  /*const fetchCompanyLogo = useCallback(async function () {

   if (!avatarName || !formValues) return

    switch (formValues.avatar.avatarType) {
      case CUSTOM_PICTURE_AVATAR_TYPE:

        if (!customPictureSrcImage) {
          setPreviewPicture(avatarLettersUrl(avatarName))
          break
        }

        let blobFile
        blobFile = await api.get(customPictureSrcImage, {responseType: 'arraybuffer'})
            .then(response => new Blob([response.data], {type: "image/jpeg;"}))
            .catch( () => null)

        if (blobFile){
          const preview = await getBase64( blobFile )
          setPreviewPicture(preview)
        } else {
          setPreviewPicture(avatarLettersUrl(avatarName))
        }
        break
      case GRAVATAR_AVATAR_TYPE:
        setPreviewPicture(gravatarUrl(gravatarMail))
        break
      default:
        setPreviewPicture(avatarLettersUrl(avatarName))
    }


  }, [avatarName, customPictureSrcImage, formValues, gravatarMail])

  useEffect(() => {
    if ( fetchLogoAtStart && formValues) {
      fetchCompanyLogo()
      setFetchLogoAtStart(false)
    }
  }, [fetchCompanyLogo, fetchLogoAtStart, formValues])*/

    return (
      <div title="View Picture">

        <CompanyLogoImage
          fileList={fileList}
          listType="picture-card"
          onPreview={() => setPreviewVisible(true)}
          showUploadList={{showRemoveIcon: false}}
          accept="image/*"
        />

        <Modal
          visible={previewVisible}
          title={'Preview'}
          footer={null}
          onCancel={closePreviewModal}
        >
          <img alt="example" style={{ width: '100%' }} src={fileList[0] && fileList[0].url} />
        </Modal>

      </div>
    )

}


export default EntityAvatarForm
