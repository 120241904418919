import { useCallback, useEffect, useState } from 'react'

export default (duration = 10, precision = .5) => {

  const [seconds, setSeconds] = useState(0);
  const [active, setActive] = useState(false)
  const [running, setRunning] = useState(false)
  const [error, setError] = useState(false)
  const [warning, setWarning] = useState(false)
  useEffect(() => {
    if (seconds >= duration || !running) {
      if (seconds >= duration && running) setRunning(false)
      return
    }
    let timer = setInterval(() => {
      setSeconds(seconds => seconds + precision);
    }, precision * 1000);
    return () => clearInterval(timer)
  }, [running])

  const onStart = useCallback((callback) => {
    setRunning(true)
    if (!active) setActive(true)
    if (error) setError(false)
    if (warning) setWarning(false)
    callback?.()
  }, [active, error, warning])

  const onStop = useCallback((callback) => {
    setRunning(false)
    callback?.()
  }, [])

  const onFinish = useCallback((callback) => {
    setSeconds(duration)
    onStop(callback)
  }, [duration, onStop])

  const onWarning = useCallback((callback) => {
    setWarning(true)
    onStop(callback)
  }, [onStop])

  const onError = useCallback((callback) => {
    setError(true)
    onStop(callback)
  }, [onStop])

  const onReset = useCallback((callback) => {
    setSeconds(0)
    setError(false)
    setWarning(false)
    setRunning(false)
    callback?.()
  }, [])

  const onClose = useCallback((delay, callback) => {
    const close = () => {
      onReset()
      setActive(false)
      callback?.()
    }
    if (!delay) close()
    else {
      const timer = setTimeout(() => {
        close()
        clearTimeout(timer)
      }, delay * 1000);
    }
  }, [onReset])

  return {
    state: {
      percent: seconds / duration * 100,
      active,
      running,
      error,
      warning,
      duration,
      precision,
    },
    actions: {
      start: onStart,
      stop: onStop,
      finish: onFinish,
      error: onError,
      warning: onWarning,
      reset: onReset,
      close: onClose,
    },
  }
}
