import React from 'react'
import PropTypes from 'prop-types'
import {Field, getFormValues, reduxForm} from 'redux-form'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import {Panel} from "react-bootstrap";
import {Col, Row, Switch} from 'antd';
import Amount from './Amount'
import BillingComment from './BillingComment'
import {isRequired, renderField, stepFieldRequired} from '../utils/fieldValidator'
import {DISCOUNT_TYPES} from './controls/BillingController'
import {__} from '../utils/translationUtils'

const validate = (values) => {
	const errors = {}
	errors.id = isRequired(values.id)

	return errors
}

class BillingStepForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func,
		onChange: PropTypes.func,
		summary: PropTypes.number,
		subSummaries: PropTypes.array,
		getData: PropTypes.func,
		formValues: PropTypes.object,
		isLoading: PropTypes.bool,
		readOnly: PropTypes.bool,
		mode: PropTypes.string,
		source: PropTypes.object,
		recap: PropTypes.object,
		extendedProperties: PropTypes.array
	}

	constructor(props) {
		super(props)
		this.state = {
			modal: {
				open: false,
				action: '',
				user: {
					name: '',
					id: 0
				}
			},
			discountEnabled: true
		}
		this.stepFieldQuantityValidator = this.stepFieldQuantityValidator.bind(this)
		this.stepFieldValidator = this.stepFieldValidator.bind(this)
		this.handleCheckedDiscount = this.handleCheckedDiscount.bind(this)
	}

	handleCheckedDiscount() {
		const {recap, source} = this.props
		let {formValues} = this.props
		const _source = {...source || {}}
		if (this.state?.discountEnabled === undefined) {
			this.setState({discountEnabled: recap.discount > 0})
		} else {
			_source.discount = this.state?.discountEnabled ? 0 : formValues["_discount"]
			this.setState({discountEnabled: !this.state.discountEnabled})
		}
		if (!this.state.discountEnabled) {
			formValues['_additionalDiscountType'] = "None"
		}
		this.setState({
			source: _source
		})
		this.props.change("_discount", parseFloat(source.discount).toFixed(2))
	}

	handleChangedDiscount(event) {
		const {source} = this.props
		if (event?.target) {
			this.setState({
				source: {...source, discount: event.target?.value}
			})
		}
	}

	stepFieldValidator(value) {
		const patt = new RegExp('^-?\\d+(\\.\\d+)*$')
		// Validate value
		if (value && !patt.test(value)) { // Vaule is not valid.
			return __('valueFormatError')
		} // Value is valid.
		return undefined
	}

	stepValidation() {
		const validation = []

		validation.push(stepFieldRequired)
		validation.push(this.stepFieldValidator)

		if (validation.length === 0) {
			return undefined
		}
		return validation
	}

	stepFieldQuantityValidator(value) {
		const patt = new RegExp('^\\d+(\\.\\d+)*$')
		// Validate value
		if (value && !patt.test(value)) { // Vaule is not valid.
			return __('valueFormatError')
		} // Value is valid.
		return undefined
	}

	stepQuantityValidation() {
		const validation = []

		validation.push(stepFieldRequired)
		validation.push(this.stepFieldQuantityValidator)

		if (validation.length === 0) {
			return undefined
		}
		return validation
	}

	handleSubmit(e) {
		e.preventDefault()
		this.props.handleSubmit()
	}

	calculateSubtotal(elementID) {
		let {formValues} = this.props
		switch (formValues[`${elementID}_discountType`]) {
			case DISCOUNT_TYPES.Percentage: {
				return formValues[`${elementID}_unitPrice`] * formValues[`${elementID}_quantity`] - (formValues[`${elementID}_unitPrice`] * formValues[`${elementID}_quantity`] * formValues[`${elementID}_discount`] / 100)
			}
			case DISCOUNT_TYPES.Price: {
				return formValues[`${elementID}_unitPrice`] * formValues[`${elementID}_quantity`] - formValues[`${elementID}_discount`]
			}
			case DISCOUNT_TYPES.Quantity: {
				return formValues[`${elementID}_unitPrice`] * (formValues[`${elementID}_quantity`] - formValues[`${elementID}_discount`])
			}
			default: {
				return formValues[`${elementID}_unitPrice`] * formValues[`${elementID}_quantity`]
			}
		}

	}

	renderDiscountTypes(discountType, hasQuantity, currency) {
		let discountTypes = []
		discountTypes.push(<option key={DISCOUNT_TYPES.Percentage} value={DISCOUNT_TYPES.Percentage}
		                           defaultValue={{discountType} === DISCOUNT_TYPES.Percentage}>
			{`%`}
		</option>)
		discountTypes.push(<option key={DISCOUNT_TYPES.Price} value={DISCOUNT_TYPES.Price}
		                           defaultValue={{discountType} === DISCOUNT_TYPES.Price}>
			{currency}
		</option>)
		if (hasQuantity) {
			discountTypes.push(<option key={DISCOUNT_TYPES.Quantity} value={DISCOUNT_TYPES.Quantity}
			                           defaultValue={{discountType} === DISCOUNT_TYPES.Quantity}>
				{__(DISCOUNT_TYPES.Quantity)}
			</option>)
		}
		return discountTypes
	}

	handleMaxDiscountValue(elementID) {
		let {formValues} = this.props
		switch (this.props.initialValues[`${elementID}_discountType`]) {
			case DISCOUNT_TYPES.Percentage: {
				return 100
			}
			case DISCOUNT_TYPES.Price: {
				if (formValues === undefined) {
					return this.props.initialValues[`${elementID}_quantity`] * this.props.initialValues[`${elementID}_unitPrice`]
				}
				return this.calculateSubtotal(elementID)
			}
			default: {
				return
			}
		}
	}

	render() {
		let {source, summary, subSummaries, formValues, onChange, readOnly, extendedProperties, recap} = this.props

		subSummaries = subSummaries || []
		formValues = formValues || []
		const showComment = false
		const renderVat = (item) => {
			return (
				<td>
					<div className="col-xs-8 col-sm-8 col-lg-8">
						<Field
							required={item.mandatory}
							name={`${item.priceElementID}_VAT`}
							validate={this.stepQuantityValidation.bind(this)()}
							component={renderField}
							label=""
							inputCol={((readOnly || item.editable === 'price' || item.editable === 'none') ? 'billing-disabled-quantity' : ' ')}
							className="form-control"
							type="percentageEntry"
							disabled={true}
						/>
					</div>

				</td>)
		}
		const renderDiscount = (item) => {
			return (<td>
				<tr>
					<td>
						<Field
							required={item.mandatory}
							name={`${item.priceElementID}_discount`}
							validate={this.stepQuantityValidation.bind(this)()}
							component={renderField}
							onClick={(event) => {
								if (event?.target?.value === '0') {
									event?.target?.select?.()
								}
							}}
							max={this.handleMaxDiscountValue(item.priceElementID)}
							label=""
							width={"40%"}
							inputCol={((readOnly || item.editable === 'price' || item.editable === 'none') ? 'billing-disabled-quantity' : ' ')}
							className="form-control"
							type="text"
							disabled={readOnly || item.editable === 'price' || item.editable === 'none'}
						/>
					</td>
					<td>
						<Field
							required={item.mandatory}
							name={`${item.priceElementID}_discountType`}
							value={`${item.priceElementID}_discountType`}
							component={renderField}
							items={this.renderDiscountTypes(item.discountType, true, item.priceElementCurrency)}
							label=""
							width={"50%"}
							inputCol={((readOnly || item.editable === 'price' || item.editable === 'none') ? 'billing-disabled-quantity' : ' ')}
							className="form-control"
							type="discountTypeEntry"
							disabled={readOnly || item.editable === 'price' || item.editable === 'none'}
						/>
					</td>
				</tr>


			</td>)
		}
		const renderItem = (item) => {
			if (!readOnly || readOnly && formValues[`${item.priceElementID}_quantity`]) {
				return (
					<tr>
						<td>
							<div className={`billing-item__name ${item.mandatory ? 'billing-item_bold' : ''}`}>
								{__(item.priceElementName) + (item.mandatory ? '*' : '')}
							</div>
							<BillingComment
								item={item}
								field={(
									<Field
										required={item.mandatory}
										placeholder={__('AddComment')}
										name={`${item.priceElementID}_comment`}
										component={renderField}
										label=""
										inputCol="col-xs-12"
										className="form-control"
										type="textarea"
										disabled={readOnly}
									/>
								)}
							/>

							<div style={{display: showComment ? 'block' : 'none'}}/>
						</td>

						{source && !source.isCompatibilityMode ? renderVat(item) : <td></td>}
						{source && !source.isCompatibilityMode ? renderDiscount(item) : <td></td>}

						<td>
							<div className="col-xs-12 col-sm-12 col-lg-12">
								<Field
									required={item.mandatory}
									name={`${item.priceElementID}_quantity`}
									validate={this.stepQuantityValidation.bind(this)()}
									component={renderField}
									label=""
									containerClassName={readOnly ? 'billing-disabled-quantity-container' : ' '}
									inputCol={((readOnly || item.editable === 'price' || item.editable === 'none') ? 'billing-disabled-quantity' : ' ')}
									className="form-control"
									type="text"
									disabled={readOnly || item.editable === 'price' || item.editable === 'none'}
								/>
							</div>
						</td>
						<td className="text-right">
							<div className="col-xs-12 col-sm-12 col-lg-12">
								<Field
									required={item.mandatory}
									name={`${item.priceElementID}_unitPrice`}
									validate={this.stepValidation.bind(this)()}
									component={renderField}
									label=""
									inputCol=" "
									className="form-control text-right"
									type="priceEntry"
									disabled={readOnly || item.editable === 'quantity' || item.editable === 'none'}
								/>
							</div>
						</td>
						<td className="text-right">
							<Amount
								decimalPrecision={2}
								value={
									formValues && formValues[`${item.priceElementID}_quantity`]
										? this.calculateSubtotal(item.priceElementID)
										: 0
								}
							/>
						</td>
					</tr>
				)
			}
		}

		const renderItems = (items) => items && items.map((item) => renderItem(item))
		const renderCategory = (category, i) => {
			const cats = category.subCategories && _.sortBy(category.subCategories, (c) => c.categorySortOrder).map((category) => renderCategory(category, i + 1))
			let categorySubtotal = ''
			if (subSummaries[category.categoryName]) {
				categorySubtotal = (
					<Amount
						className={(i > 0) ? 'categorySubTotal' : 'subTotal'}
						decimalPrecision={2}
						value={subSummaries[category.categoryName]}
					/>
				)
			}
			const rows = [
				<tr key="table-header">
					<td>
						<h5 className={(i > 0) ? 'subCategoryName' : 'categoryName'}>{__(category.categoryName)}</h5>
					</td>
					<td colSpan="4"/>
					<td className="text-right">
						{categorySubtotal}
					</td>
				</tr>,
				renderItems(_.sortBy(category.billingItems, (i) => i.priceElementSortOrder)),
				cats,
				<tr className="blank_row" key="table-row">
					<td className="blank_row" colSpan="4"/>
				</tr>
			]

			for (let i = rows.length - 1; i >= 0; i--) {
				if (rows[i]?.props?.className === 'blank_row') rows.pop()
			}

			return rows
		}
		const renderRecap = () => {
			return (<div className="fieldset style-1 recap" style={{marginTop: '1rem'}}>
        <span className="collapsible-header no-selection">
          {__("Recap")}
        </span>
					<Panel
						style={{'marginBottom': '40px', backgroundColor: '#F1F1F1', color: '#0a4f7a'}}
						defaultExpanded={true}
						bsStyle="info"
					>
						<Panel.Body>
							<div style={{
								maxHeight: '350px',
								overflowY: 'none',
								marginTop: '-10px',
								fontWeight: '600',
								fontSize: '13px'
							}}>

								<Row>
									<Col span={6} offset={12}>
										{__("Total Before Discount")}
									</Col>
									<Col span={6} style={{fontWeight: '700'}}>
										<Amount decimalPrecision={2} value={summary}/>
									</Col>
								</Row>
								<Row>
									<Col span={6} offset={12}>
										{__("Additional Discount")}
									</Col>
									<Col span={6}>
										<Switch
											name="_additionalDiscountEnabled"
											type="checkbox"
											defaultChecked={this.state.discountEnabled}
											checked={this.state.discountEnabled}
											component="input"
											onChange={() => {
												this.handleCheckedDiscount();
												onChange();
											}}
											disabled={readOnly}
										/>
									</Col>
								</Row>
								<Row style={{display: this.state.discountEnabled ? 'flex' : "none", marginRight: '2rem'}}
								     gutter={[8, 1]}>
									<Col span={2} offset={12} style={{paddingLeft: '2rem', paddingTop: readOnly ? '14px' : ''}}>
										<Field
											required={false}
											name={`_discount`}
											validate={this.stepQuantityValidation.bind(this)()}
											component={renderField}
											onChange={(event) => {
												onChange();
												this.handleChangedDiscount(event);
											}}
											label=""
											inputCol={' '}
											className="form-control"
											type="text"
											disabled={readOnly}
										/>
									</Col>

									<Col span={2} style={{paddingLeft: '1rem', marginleft: '2rem'}}>
										<Field
											required={false}
											name={`_additionalDiscountType`}
											component={renderField}
											items={this.renderDiscountTypes(this.state.additionalDiscountType, false, formValues["_currency"])}
											label=""
											inputCol={((readOnly || this.state.additionalDiscountType === 'price' || this.state.additionalDiscountType === 'none') ? 'billing-disabled-quantity' : ' ')}
											className="form-control"
											type="discountTypeEntry"
											disabled={readOnly || this.state.additionalDiscountType === 'price' || this.state.additionalDiscountType === 'none'}
										/>
									</Col>
								</Row>
								<Row>
									<Col span={6} offset={12}>
										{__("Total After Discount")}
									</Col>
									<Col span={6} style={{fontWeight: '700'}}>
										<Amount decimalPrecision={2} value={recap.totalAfterDiscount}/>
									</Col>
								</Row>
								<Row>
									<Col span={6} offset={12}>
										{__("VAT")}
									</Col>
									<Col span={6}>
										<Amount decimalPrecision={2} value={recap.vatTotal}/>
									</Col>
								</Row>
								<Row className="summary">
									<Col span={6} offset={12} className="total" style={{borderTop: '1px solid grey'}}>
										{__("Total Incl. Vat")}
									</Col>
									<Col span={6} className="total" style={{borderTop: '1px solid grey', fontWeight: '700'}}>
										<Amount decimalPrecision={2} value={recap.totalIncludingVat}/>
									</Col>
								</Row>
							</div>
						</Panel.Body>
					</Panel>
				</div>
			)
		}

		return (
			<form style={{border: '1px solid #0a4f7a', padding: '1rem 1rem 1rem 1rem'}}>
				<table className="pricelist">
					<tbody>
					<tr>
						<th width={!source.isCompatibilityMode ? 720 : 1}/>
						<th className="text-center" width={!source.isCompatibilityMode ? 100 : 1}>
							{!source.isCompatibilityMode ? __('VAT %') : ""}
						</th>
						<th width={!source.isCompatibilityMode ? (readOnly ? 125 : 150) : 1}>
							{!source.isCompatibilityMode ? __('Discount') : ""}
						</th>
						<th width={!source.isCompatibilityMode ? 125 : 300}>
							{__('Quantity')}
						</th>
						<th className="text-center" width={!source.isCompatibilityMode ? 150 : 200}>{__('Unit Price')}</th>
						<th width="100" className="text-right">{__('Price')}</th>
					</tr>

					{source && source.priceCategories instanceof Array && source.priceCategories.map((category) => renderCategory(category, 0))}

					<tr className="summary">
						<td colSpan="1"/>
						<td className="total text-right" colSpan="4">
							{__(extendedProperties && extendedProperties['Label Total'] ? extendedProperties['Label Total'] : 'Total')}:
						</td>
						<td className="total text-right">
							<Amount decimalPrecision={2} value={summary}/>
						</td>
					</tr>
					</tbody>
				</table>

				{!source.isCompatibilityMode ? renderRecap() : ""}
			</form>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch)
}

const Form = reduxForm({validate}, mapDispatchToProps)(BillingStepForm)

export default connect(
	(state, {form}) => ({formValues: getFormValues(form)(state)}),
	{}
)(Form)
