import {connect} from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import _, {find, sortBy} from 'lodash'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {Panel, Tab, Tabs} from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import Loader from 'react-loader'
import {Field, reduxForm} from 'redux-form'
import {bindActionCreators} from 'redux'
import {withLastLocation} from 'react-router-last-location'
import {parse} from 'query-string'
import {hashHistory} from '../providers/HistoryProvider'
import {triggerOrderEvent} from "../providers/ApiProvider/order";

import {
  checkUserRights,
  findActiveProcess,
  fixControllerType,
  formatData,
  isComplexObject,
  renderCAlgorithm
} from '../utils/appHelper'
import {renderField} from '../utils/fieldValidator'
import {getEnumValues} from '../providers/ReduxProvider/actions/reportActions'
import * as config from '../constants/globalConfiguration'
import Label from "../components/Label";
import TabFieldSet from '../components/TabFieldSet'
import EditOrderButton from '../components/EditOrderButton'
import OverviewTabEditButton from '../components/OverviewTabEditButton'
import OrderHistory from '../components/OrderHistory'
import ObjectSelector from "../components/DooOrderPage/Step/customRender/ObjectSelector";
import {objectSelectorCustomProps} from "../components/DooOrderPage/Step/StepBeta/NormalStep/initialization/customProps"
import ObjectListingControl from '../components/controls/ObjectListingControl'
import MessageRoom from '../components/UI/messagesDeprecated/MessageRoom'
import Loading from '../components/Loading'
import {CellCtaWrapper, CellDoo, CellEdit} from '../components/ReportDataTable'

import {api} from '../providers/ApiProvider'
import DooOrderButton from '../components/DooOrderButton'
import {renderColumns} from '../utils/formatTools'
import {
  OBJECT_BINDER_CONTROLLER_TYPE,
  OBJECT_PICKER_CONTROLLER_TYPE
} from "../components/DooOrderPage/Step/customRender/ObjectSelector/utils";
import {ActionPanel} from "../components/ReportDataTable/OrderActions/styles";
import {__, T} from '../utils/translationUtils'
import ActionsMenu from "../components/ReportDataTable/OrderActions/ActionsMenu";
import {cases} from "../utils/stringUtils";
import {eventStatusIcons} from "../components/ReportDataTable/OrderActions/constants";
import ProcessesTable from "../components/orderOverview/ProcessesTable";

const loadingBar = require('nprogress')

const bigControllers = ['Billing', 'BillingController', 'ObjectPicker']

class OrderOverview extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      objectId: this.props.match ? this.props.match.params.orderId : this.props.params.orderId,
      order: null,
      orderDetails: null,
      historyEntries: null,
      responsibleUser: null,
      orderOverview: null,
      orderHistory: null,
      processes: [],
      activeProcess: null,
      isLoading: false,
      loadingOverview: false,
      tabData: null,
      parentObjIds: null,
      slectedTab: null,
      panels: {details: true},
      appointmentModal: {open: false},
      filledOverviewObjects: {}
    }

    this.defaultDocumentTitle = document.title

    this.renderOrderDetails = this.renderOrderDetails.bind(this)
    // this.getOrderDetails = this.getOrderDetails.bind(this);
    this.extractAttributeConfig = this.extractAttributeConfig.bind(this)
    this.renderOrderDetailsLine = this.renderOrderDetailsLine.bind(this)
    this.renderTabContent = this.renderTabContent.bind(this)
    this.handleTabSelect = this.handleTabSelect.bind(this)
  }

  componentDidMount() {
    if (this.props.isSnapshot) {
      this.getSnapshot()
    } else {
      this.getOrder()
    }
  }

  // Make the title turn back to the default one
  componentWillUnmount() {
    document.title = this.defaultDocumentTitle
  }

  extractAttributeConfig(id) {
    return find(this.props.attributesList, (a) => a.id === id)
  }

  handleTabSelect(name) {
    const tab = find(this.state.orderOverview, (tab) => tab.name === name)

    const promises = []

    const filledOverviewObjects = {}
    tab.configurations.forEach((c) => {
      promises.push(
        new Promise((resolve) => api.get(`configurations/overviewObjects/${c.overviewObjectId}`)
          .then(
            (response) => {
              const payload = response.data
              filledOverviewObjects[payload.id] = payload

              let requestParams = '?'
              if (this.state.objectId) {
                requestParams += `orderIds=${this.state.objectId}&`
              }
              api.post(`configurations/overviewObjects/results${requestParams}`, payload)
                .then(
                  (res) => resolve({
                    filledOverviewObjects,
                    res: res.data,
                    overviewObjectId: c.overviewObjectId
                  })
                )
            }
          ))
      )
    })

    Promise.all(promises)
      .then((results) => {
        const _data = new Map()
        const _parentObjIds = {}
        const _fillOO = new Map()

        // Organizing data in order to display after
        for (const promise_res of results) {
          const objectId = promise_res.overviewObjectId

          const displayData = Object.assign({}, ...[].concat(promise_res.res.map((r) => r.displayData)))
          _data.set(objectId, displayData)
          _parentObjIds[objectId] = promise_res.res.map((r) => r.id)[0]
          _fillOO.set(objectId, promise_res.filledOverviewObjects)
        }

        this.setState({
          slectedTab: name,
          tabData: _data,
          parentObjIds: _parentObjIds,
          filledOverviewObjects: _fillOO
        })
      })
  }

  getAttributeTree(configuration) {
    const {attributesList} = this.props

    const attributeTree = {}
    configuration.displayAttributes.map((dA, index) => {
      const attributeBased = attributesList?.find((aC) => aC?.id === dA?.attributeId)
      const attribute = {...attributeBased, position: index}

      const branch = attribute?.property?.split('/').slice(0, -1).join('/')
      const depth = attribute?.property?.split('/').slice(0, -1).length

      const br = {}
      br[attribute.propertyLabel] = attribute

      const temporary = {}
      temporary[branch] = [attribute]
      if (attributeTree[depth] && attributeTree[depth][branch]) {
        attributeTree[depth][branch].push(attribute)
      } else if (attributeTree[depth] && !attributeTree[depth][branch]) {
        attributeTree[depth][branch] = [attribute]
      } else {
        attributeTree[depth] = temporary
      }
    })

    return attributeTree
  }

  renderTabContent() {
    const {
      tabData,
      order,
      filledOverviewObjects
    } = this.state

    const {attributesList, attributesTree} = this.props

    const {enumValues, overviewTabReadOnly} = this.props
    if (!attributesTree || !tabData) {
      return __('loading')
    }

    const getConfigController = (attr, configuration) => {
      if (!configuration) {
        return
      }
      const {objectConfiguration} = this.state

      if (configuration.configuration) configuration = configuration.configuration

      if (objectConfiguration && objectConfiguration[configuration.configuration.id] && objectConfiguration[configuration.configuration.id].displayAttributes) {
        configuration = objectConfiguration[configuration.configuration.id]
      }
      if (configuration && configuration.id) {
        const found = find(configuration.displayAttributes, (
          a
        ) => a.attributeId === attr.id)
        if (found) {
          return (found.controllerType || found.defaultControllerType)
        }
      }
      return attr.type
    }

    const getControllerType = (attr, configuration) => {
      return getConfigController(attr, ((configuration.configuration) ? configuration : {configuration}))
    }

    const renderLine = (attr, value, overviewConfiguration) => {
      const extractConfiguration = (id) => find(attributesList, (c) => c.id === id)
      const controllerType = getControllerType(attr, overviewConfiguration)
      const attrConfiguration = extractConfiguration(attr.id)
      if (!attrConfiguration) return null

      // Object Listing overview
      if (attrConfiguration.type === 'OBJECT_LIST_IDS') {
        let attributes
        attributes = [].concat.apply([], Object.values(attributesTree))
        attributes = [].concat.apply([], attributes.map((attribute) => attribute.data))
        const attribute = _.find(attributes, (a) => a.id === attr.id)
        const controlData = {}
        controlData.source = value
        controlData.readOnly = true
        controlData.controllerType = 'ObjectListing'
        controlData.attributes = {}
        if (!attribute.relatedObjects) {
          return <div>{__('Object_Listing_Error_Missing_Related_Object_In_Attribute')}</div>
        }
        return (
          <ObjectListingControl
            source={value}
            configuration={attr}
            readOnly
            controlData={controlData}
            objectTypeId={attribute.relatedObjects}
            additionalStyle="overviewTab"
          />
        )
      }

      if (attrConfiguration.enumerationId && attrConfiguration.enumerationId !== '' && !overviewTabReadOnly) {
        if (attrConfiguration) {
          // this.props.dispatch(getEnumValues(attrConfiguration.enumerationId));
        }
      }

      // TODO: get the title from the configuration!
      let labelField = attrConfiguration.propertyLabel
      if (['OBJECT_LIST_IDS', 'ObjectListing'].includes(controllerType)) {
        labelField = ''
      }

      if ([OBJECT_BINDER_CONTROLLER_TYPE, OBJECT_PICKER_CONTROLLER_TYPE].includes(controllerType)) {
        const displayAttrConfig = overviewConfiguration.displayAttributes.find((a) => a.attributeId === attr.id)

        if (!displayAttrConfig?.controllerOptions) return <p> {controllerType} {__(labelField)} is not properly
          configured.</p>

        const field = {
          readOnly: true,
          controllerOptions: {
            ...displayAttrConfig?.controllerOptions,
            ...(attrConfiguration.mappedBy ? {
              readFrom: {
                attributeId: displayAttrConfig.attributeId,
                primaryKey: this.state.parentObjIds[overviewConfiguration.id],
              }
            } : {}),
          },
          position: displayAttrConfig?.position,
          orderId: order.id,
          overviewObjectConfigurationId: overviewConfiguration.id,
        }

        const val = {
          selectedItems: value ? value.split(',') : [],
          objectConfiguration: {
            ...displayAttrConfig?.controllerOptions?.objectConfiguration || {},
            manualSelection: false
          },
        }

        const customProps = objectSelectorCustomProps(field)

        const objSelectorProps = {
          ...customProps,
          api: {
            ...customProps.api,
            fetchAttributesList: async () => (attributesList),
            fetchAttributesTree: async () => (attributesTree),
          }
        }

        return (
          <div style={{display: 'flex', flexDirection: 'column'}} className="form-group clearfix">
            <Label
              htmlFor="inputName"
              className={'col-sm-3 col-md-3 col-lg-2 control-label'}
              label={__(labelField)}
            />
            <div style={{marginTop: '5px'}}>
              <ObjectSelector
                key={attr.id}
                value={val}
                {...objSelectorProps}
              />
            </div>
          </div>
        )
      }

      const isBigController = ['OBJECT_LIST_IDS', 'ObjectListing', ...bigControllers].includes(controllerType)

      return attrConfiguration && (
        <Field
          key={`remoteobject-property-${attr.id}`}
          name={attr.id}
          component={renderField}
          disabled
          dateFormat={config.appDefaults.dateTimeFormat}
          label={__(labelField)}
          //className="form-control"
          // type={attrConfiguration.enumerationId ? 'select' : 'text'}
          inputCol={isBigController ? 'col-sm-12' : 'col-sm-9'}
          labelCol={isBigController ? '' : 'col-sm-3'}
          type={controllerType}
          attributesConfigurationTree={attributesTree}
          input={{name: attr.id, value: (value != undefined ? value : ''), attributesConfigurationTree: attributesTree}}
        />
      )
    }

    const keys = []
    for (const key of filledOverviewObjects.keys()) {
      keys.push(key)
    }

    const {rightMappings} = global.constants
    return (
      <div>
        {
          keys.map((key) => {
            const value = filledOverviewObjects.get(key)
            const c = value[key]
            const system = attributesTree[c.systemId]
            const node = find(system, (nodeItem) => nodeItem.objectTypeId === c.objectTypeId)
            const attributeTree = this.getAttributeTree(c)
            const starterDepth = Math.min(...Object.keys(attributeTree).map((k) => parseInt(k)))
            const root = attributeTree[starterDepth]
            return root && Object.entries(root).map(([branch, displayData], index) => {
              let readonly = true
              if (displayData.some((b) => {
                const {type} = find(attributesList, (attribute_configuration) => attribute_configuration.id === b.id)
                return type === 'OBJECT_LIST_IDS'
              }) && (c.editableResults || c.removableResults || c.addableResults)) {
                readonly = false
              } else {
                readonly = !c.editableResults
              }

              const multiCol = !displayData.some((d) => (bigControllers.includes(getControllerType(d, c))))

              return (
                <TabFieldSet
                  key={`${key}_${branch}_${index}`}
                  branch={branch}
                  displayData={displayData}
                  attributeTree={attributeTree}
                  renderLine={renderLine}
                  getControllerType={getControllerType}
                  configuration={c}
                  values={tabData.get(key)}
                  depth={starterDepth}
                  multiCol={multiCol}
                  editButton={(!readonly && checkUserRights(this.props.user.rights, rightMappings.CAN_PATCH_REMOTE_OBJECT))
                    ? (
                      <OverviewTabEditButton
                        configuration={c}
                        node={node}
                        overviewObjectId={key}
                        enumValues={enumValues}
                        initialValues={tabData.get(key)}
                        attributesConfiguration={attributesList}
                        system={c.systemId}
                        orderId={order.id}
                        dependencyId={c.objectTypeId}
                      />
                    ) : null}
                />
              )
            })

            /*            const system = attributesConfigurationTree[c.systemId];
            const node = find(system, (nodeItem) => {
              return nodeItem.objectTypeId === c.objectTypeId;
            });

            return (
              <div key={c.id}>
                <TabFieldSet
                  node={node}
                  values={tabData.get(key)}
                  configuration={c}
                  depth={1}
                  renderLine={renderLine}
                  attributesConfiguration={attributesConfiguration}
                  getTree={true}
                  editButton={(!overviewTabReadOnly && slectedTab === c.name) ?
                    <OverviewTabEditButton
                      configuration={c}
                      node={node}
                      overviewObjectId={this.state.objectId}
                      enumValues={enumValues}
                      initialValues={tabData}
                      attributesConfiguration={attributesConfiguration}
                      system={c.systemId}
                      dependencyId={c.objectTypeId}
                    /> : null
                  }
                />
              </div>
            ); */
          }, filledOverviewObjects)
        }
      </div>
    )
  }

  renderOrderDetails() {
    const {order} = this.state

    if (!order.orderDetails) {
      return null
    }
    const lastComment = order && order.lastComment
    const sorted = this.state.order.orderDetails.displayData
    const keys = Object.keys(sorted)
    const rows = []
    keys.forEach((attributeId) => {
      rows.push(this.renderOrderDetailsLine(attributeId)); //label, value, key.
    })
    return (
      <div>
        <span
          className="collapsible-header no-selection"
          onClick={() => this.setState({panels: {details: !this.state.panels.details}})}
        >
          {__('Main Order Details')}
        </span>
        <Panel
          style={{marginBottom: '40px'}}
          expanded={this.state.panels.details}
          onToggle={() => {
          }}
        >
          <Panel.Body collapsible>
            {!this.props.attributesList
              && <span>{__('loading')}</span>}

            <div className="row">
              {renderColumns(rows, 3)}

            </div>

            {lastComment
              && (
                <div className="comment-item gutter-top">
                  <h5>{__('Last Comment')}:</h5>
                  {lastComment.text}
                  <br/>
                  <span className="comment-item--time">
                    {__('Commented at')} {moment(lastComment.creationDatetime).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                    {' '} {__('by')} {lastComment.creator.username}
                  </span>
                </div>
              )}
          </Panel.Body>
        </Panel>
      </div>
    )
  }

  getSnapshot() {
    const {location} = this.props
    this.setState({isLoading: true})
    loadingBar.start()

    api.get(`snapshots/${this.state.objectId}?withChildren=true`)
      .then(
        (response) => {
          loadingBar.done()

          const {data} = response

          // Find active proccess
          const _process = findActiveProcess(data.content.processes)

          // Sort Tabs by position
          const overviewTabs = sortBy(data.content.overviewTabs, 'position')

          this.setState({
            order: data.content,
            orderOverview: overviewTabs,
            historyEntries: data.historyEntries,
            activeProcess: _process || null
          })
        }
      ).catch((error) => {
      if (error.request.status === 404) {
        const queryString = parse(location.search)
        hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
      }
    })

    api.get('users?pageSize=200&status=enabled,reserved')
      .then(
        (response) => {
          this.setState({users: response.data})
        }
      )
  }

  getOrder() {
    const {location} = this.props
    this.setState({isLoading: true})
    loadingBar.start()
    api.get(`orders/${this.state.objectId}?initialiseOrderDetails=true`)
      .then(
        (response) => {
          loadingBar.done()

          const orderData = response.data

          // Find active proccess
          const _process = findActiveProcess(orderData.processes)

          // Change title of the document if the request has gone well
          document.title = `${this.defaultDocumentTitle} - ${orderData.title ? orderData.title.toString() : ''}`

          this.setState({
            order: orderData,
            processes: response.data,
            activeProcess: _process || null,
            isLoading: false
          })
          api.get(`orders/${this.state.objectId}/overviewTabs`)
            .then(
              (response) => {
                let orderData = response.data
                // Sort by position
                orderData = sortBy(orderData, 'position')
                this.setState({orderOverview: orderData, loadingOverview: false}, () => {
                  const firstTab = sortBy(orderData, 'position')[0]
                  if (typeof firstTab !== 'undefined') {
                    this.handleTabSelect(firstTab.name)
                  }
                })
              }
            )
        }
      )
      .catch((error) => {
        if (error.request && error.request.status === 404) {
          const queryString = parse(location.search)
          hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
        }
      })

    api.get('users?pageSize=200&status=enabled,reserved')
      .then(
        (response) => {
          this.setState({users: response.data})
        }
      )
  }


  getActiveProcess() {
    return findActiveProcess(this.state.order.processes)
  }

  showActionButton() {
    const {order, users} = this.state
    const {location, isSnapshot, lastLocation, attributesList} = this.props
    const lastLocationRedirectTarget = {...location}
    if (lastLocation !== null) {
      lastLocationRedirectTarget.search = `?backUrl=${lastLocation.pathname}`
    }
    /* I comment this control because it seems that this props is never used, except in this place
    * The problem was that it was undefined so it doesn't return the action button in the Order Overview
    * */
    if (!order || isSnapshot /* || !this.props.guiUser */) {
      return
    }

    const process = this.getActiveProcess()

    if (!process || typeof process.ownership === 'undefined') {
      return null
    }

    return (
      <div style={{...CellCtaWrapper, gridTemplateColumns: 'repeat(3, 33%)'}}>
        <div style={{
          ...CellDoo,
          ...(order?.accessRightsInfo.doo ? {} : {
            pointerEvents: 'none',
            opacity: 0.4
          })
        }}
        >
          <DooOrderButton orderDetails={this.state.order.orderDetails} location={lastLocationRedirectTarget}/>
        </div>
        <div style={{
          ...CellEdit,
          ...(order?.accessRightsInfo.edit ? {} : {
            pointerEvents: 'none',
            opacity: 0.4
          })
        }}
        >
          <EditOrderButton
            order={order}
            orderId={this.state.order.id}
            users={users}
            icon={{
              type: 'edit',
              style: {fontSize: '1.3rem'}
            }}
            location={lastLocationRedirectTarget}
            updateData={() => {
            }}
            attributesConfiguration={attributesList}
            className="btn btn-action btn-sm btn-no-padding"
          />
        </div>
        <div>
          <ActionsMenu
            orderId={order.id}
            actions={['cancel', 'archive', 'delete', 'hardDelete']}
            triggerActionCallback={(orderId, status, action) => {
              this.setState({isLoading: true}, () => {
                triggerOrderEvent(order.id, action)
                  .then(() => {
                    if (action === 'hardDelete') {
                      hashHistory.push(config.ordersDefaultUrl)
                    } else {
                      window.location.reload();
                    }
                  }).finally(() => {
                  this.setState({isLoading: false})
                })
              })
            }}
          />
        </div>
      </div>
    )
  }

  renderOrderDetailsLine(attributeId) {
    const {order} = this.state
    const attributeConfig = this.extractAttributeConfig(attributeId)
    const attributeDisplayDataConfig = order?.orderDetails?.displayDataConfiguration?.[attributeId];

    const formatValue = (value) => {
      if (!attributeConfig) {
        return
      }
      if (attributeConfig.dependOnProcess || isComplexObject(attributeConfig.type)) {
        return renderCAlgorithm(value, order.orderDetails, attributeConfig, attributeConfig)

      }
      let controllerType = attributeDisplayDataConfig?.controllerType || attributeConfig?.controllerType || attributeConfig?.defaultControllerType;
      const fieldType = attributeConfig.type

      // Ugly fix for translating godoo objects: Whitelisting some properties.
      controllerType = fixControllerType(attributeConfig, fieldType, controllerType);
      return formatData(value, fieldType, controllerType)
    }

    return (
      <div key={attributeId} className="row">
        <label className="col-sm-3 control-label break-word">
          {attributeConfig && __(attributeConfig.propertyLabel)}
        </label>
        <div className="col-sm-9 break-word">
          <p className="form-control-static">
            {this.state.order && order.orderDetails && formatValue(order.orderDetails.displayData[attributeId], attributeConfig)}
          </p>
        </div>
      </div>
    )
  }

  render() {
    const {order, historyEntries, orderOverview, slectedTab} = this.state
    const {isSnapshot, isModal, location, user} = this.props

    return (
      <div id="main-content">
        {this.state.isLoading && <Loading loading/>}
        {!this.props.isModal
          && (
            <h1 className="sticky">
              <div className="container" style={{display: 'flex', alignItems: 'center', gap: "10px"}}>
                {this.props.isSnapshot ? __('snapshotDetailsTitle') : __('orderDetailsTitle')}
                {order?.archived &&
                  <ActionPanel>{eventStatusIcons.archive} {__(T.archived, cases.CAPITALIZE_SENTENCE)}</ActionPanel>}
                {order?.deleted &&
                  <ActionPanel>{eventStatusIcons.delete} {__(T.deleted, cases.CAPITALIZE_SENTENCE)}</ActionPanel>}
              </div>
            </h1>
          )}

        <div className={'order-details-page '.concat(!isModal ? 'container' : '')}>
          {order ?
            (
              <div className="fieldset style-1">
                {this.renderOrderDetails()}
                <OrderHistory data={isSnapshot ? historyEntries : null} orderId={order.id}/>
                <MessageRoom objectCaller={order} panelTitle="Order Comments" modelCaller="orders"/>
                <ProcessesTable currentUser={user} order={order}/>

                {(orderOverview == null && !isSnapshot) && (
                  <div style={{position: 'relative', height: '200px'}}>
                    <Loader loaded={this.state.orderOverview !== null} shadow={false} color="#0a4f7a"/>
                  </div>
                )}

                {orderOverview && (
                  <Tabs
                    animation={false}
                    className="gutter-bottom"
                    id="order-tabs"
                    onSelect={this.handleTabSelect}
                  >

                    {
                      orderOverview.map((tab) => (
                        <Tab
                          key={tab.name}
                          eventKey={tab.name}
                          title={__(tab.name)}
                        >
                          {
                            (tab && tab.name && slectedTab && slectedTab === tab.name) ?
                              this.renderTabContent() :
                              <Loading loading />
                          }
                        </Tab>
                      ))}
                  </Tabs>
                )}
              </div>
            ) :
            (
              <div style={{height: '150px'}}>
                {__('loading_data')}
              </div>
            )
          }

          {!this.props.isModal
            && (
              <div className="row gutter-bottom button-group" style={{marginTop: '40px'}}>
                <div className="col-xs-1">
                  <Link
                    onClick={() => {
                      const queryString = parse(location.search)
                      if (queryString.backUrl) {
                        hashHistory.push(queryString.backUrl)
                      } else {
                        hashHistory.goBack()
                      }
                    }}
                    to="#"
                    className="btn btn-default btn-block"
                  >
                    <Glyphicon glyph="chevron-left"/>{__('Back')}
                  </Link>
                </div>

                <div className="text-center col-xs-8"/>

                <div className="col-xs-3">
                  {this.showActionButton()}
                </div>

              </div>
            )}

        </div>

      </div>

    )
  }
}

OrderOverview.propTypes = {
  location: PropTypes.object,
  modelValues: PropTypes.object,
  match: PropTypes.object,
  enumValues: PropTypes.object,
  isModal: PropTypes.bool,
  isSnapshot: PropTypes.bool,
  overviewTabReadOnly: PropTypes.bool,
  objectConfiguration: PropTypes.object
}

OrderOverview.defaultProps = {
  isSnapshot: false,
  overviewTabReadOnly: false
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const OverviewTabConnected = connect(
  (state) => ({
    user: state.user.data,
    attributesList: state.ui.attributesList,
    attributesTree: state.ui.attributesTree,
  }),
  (dispatch) => ({
    dispatch,
    getEnumValues
  })
)(OrderOverview)

const OverviewTabReduxForm = reduxForm({form: 'overviewTabMarco'}, mapDispatchToProps)(OverviewTabConnected)

export default withLastLocation(OverviewTabReduxForm)
