import React, {useMemo, useState} from "react";
import {__, T} from "../../utils/translationUtils";
import {Button, Modal, TreeNodeListSelectorBeta} from "components";
import {TreeNodeSelectorWrapper} from "./styles";
import {PushpinOutlined} from "@ant-design/icons";

type PinnedDashboardsSettingsProps = {
  availableDashboards: { id: string, name: string }[]
  pinnedDashboardsIds: string[]
  defaultPinnedDashboardId: string
  submit: ({ pinnedDashboardIds, defaultPinnedDashboardId }: { pinnedDashboardIds: string[], defaultPinnedDashboardId?: string }) => void
  close: () => void
}

type treeValue = {
  [key: string]: { index: number }
}

const getInitValue = (pinnedDashboardsIds: string[]): treeValue => pinnedDashboardsIds.reduce((acc, dashboardId, index) => {
  acc[dashboardId] = { index }
  return acc
}, {} as treeValue)

const PinnedDashboardsSettings = ({
                                    submit,
                                    close,
                                    availableDashboards,
                                    pinnedDashboardsIds,
                                    defaultPinnedDashboardId
}: PinnedDashboardsSettingsProps) => {

  const [value, setValue] = useState(getInitValue(pinnedDashboardsIds))
  const [defaultPinned, setDefaultPinned] = useState<string | undefined>(defaultPinnedDashboardId)

  const itemsTree = useMemo(() => {
    return {
      key: 'root',
      label: 'root',
      data: availableDashboards.map(({ id, name }) => ({
        key: id,
        title: (
          <div>
            {name}
            <div style={{
              position: "absolute",
              right: "37px",
              top: "8px",
            }}>
              <Button
                shape="circle"
                type={defaultPinned === id ? "filled" : "text"}
                icon={<PushpinOutlined/>}
                onClick={() => setDefaultPinned((current) => id === current ? undefined : id)}
              />
            </div>
          </div>
        ),
        label: name,
      })),
      children: []
    }
  }, [availableDashboards, defaultPinned])

  return (
    <Modal
      title={__(T.pinnedDashboards, 'capitalize_sentence')}
      visible
      fixedheight
      centered
      footer={
        <>
          <Button
            onClick={() => close()}
            title={__(T.cancel)}
            type={'text'}
          />
          <Button
            type='filled'
            title={__(T.save)}
            onClick={() => submit({ pinnedDashboardIds: Object.keys(value || {}), defaultPinnedDashboardId: defaultPinned })}
          />
        </>
      }
      onCancel={() => close()}
    >
      <TreeNodeSelectorWrapper>
        <TreeNodeListSelectorBeta
          treeStyle="tertiary"
          value={value}
          onChange={(newValue: treeValue) => {
            if (defaultPinned && !Object.keys(newValue || {}).includes(defaultPinned))
              setDefaultPinned(undefined)

            setValue(newValue)
          }}
          treeItems={itemsTree}
          left={{
            title: __(T.selectDashboards, 'capitalize_sentence'),
          }}
          right={{
            title: __(T.pinnedDashboards, 'capitalize_sentence'),
          }}
          filters={{
            showSearch: false,
            showNodeSelector: false,
            showTotalResults: false,
          }}
          labels={{
            pinnedItems: __(T.showOnlyPinned)
          }}
        />
      </TreeNodeSelectorWrapper>
    </Modal>
  )
}

export default PinnedDashboardsSettings
