export const getAssignmentPayload = async ({button, formRef}) => {

  const {validationFormRequired = true} = button
  const {submit} = formRef?.current || {}
  const {validationStatus, values} = await submit?.({validation: validationFormRequired})

  if (validationFormRequired && (!validationStatus || validationStatus === 'failed')) {
    return {
      validationStatus
    }
  }

  return {
    validationStatus,
    assignees: values['assignment-step']?.map((assigneeString) => {
      const assignee = JSON.parse(assigneeString)

      return {
        userId: assignee.userId,
        entityId: assignee.entityId,
        type: assignee.type
      }
    })
  }
}
