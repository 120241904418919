
export const assigneeType = {
  USER: 'USER',
  ENTITY: 'ENTITY',
  COMPANY: 'COMPANY',
}

export default (assigneesStr, attachedUsersMap) => {
  const assignees = JSON.parse(assigneesStr)
  
  return assignees?.map((assignee) => {
    const { type } = assignee
    if (type !== assigneeType.USER) return getAttachedUsers(assignee, attachedUsersMap)
  
    return assignee
  }).flat()
}

const getAttachedUsers = (assignee, attachedUsersMap) => {
  const { id } = assignee
  return (attachedUsersMap[id] || []).map((user) => ({ user, userId: user.id, entityId: id }))
}
