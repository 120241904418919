import moment from "moment";
import * as config from "../../../../../constants/globalConfiguration";
import _ from "lodash";
import { valueTypeToInput } from "../../../../../utils/renderInputField";

import { __ } from '../../../../../utils/translationUtils'
import {WARN} from "components/dist/Utils/LoggerUtils";
import * as C from "./constants"

export const getAttributeFields = (objectConfiguration, attributesTree) => {
  if (!objectConfiguration || !attributesTree) return null
  const objectAttributes = attributesTree[objectConfiguration.systemId]?.find(({ objectTypeId }) => objectTypeId === objectConfiguration.objectTypeId)?.data
	if (!objectAttributes) WARN('Missing Object Type!')

  return objectAttributes?.filter(({ id }) => objectConfiguration?.displayAttributes?.some(({ attributeId }) => attributeId === id))
}

export const getInceptionProps = (formMode, { objectName }) => {
  const { mode, objectId } = formMode

  const common = { mode, objectId, objectName }

  switch (mode) {
    case C.formMode.EDIT:
      return {
        ...common,
        key: `remote-object-item-edit-${objectId}`,
        title: `Edit ${objectName} ${objectId}`,
        footerOkLabel: 'Save'
      }
    case C.formMode.CREATE:
      return {
        ...common,
        key: 'remote-object-item-create',
        title: `Create a ${objectName} item`,
        footerOkLabel: 'Create'
      }
    case C.formMode.VIEW:
      return {
        ...common,
        key: 'remote-object-item-view',
        title: `View ${objectName} ${objectId}`,
        footerOkLabel: 'Ok'
      }
    default:
      return {}
  }
}

export const prepareDateRangePayload_applyFelixAlgorithm = (searchAttribute, attributeValueMap, attributeConfiguration) => {

  if (searchAttribute == null || attributeConfiguration == null || attributeValueMap == null) return;

  if (searchAttribute.mechanism !== 'RANGE') return

  /* Apply Felix's Algorithm on range fields
   * 1) If both *from* and *to* are filled --> normal *range* search.
   * 2) If only *from* is filled --> *specific_value* search. (the from can contain wildcard)
   * 3) if only *to* is filled the webui fills the from value with the same value contained in the *to* --> *range* search (edited)
   */

  if (attributeConfiguration.type === 'DATE') {
    attributeValueMap[attributeConfiguration.id + 'From'] = attributeValueMap[attributeConfiguration.id + 'From'] ?
      attributeValueMap[attributeConfiguration.id + 'From'].utc().format() : undefined

    attributeValueMap[attributeConfiguration.id + 'To'] = attributeValueMap[attributeConfiguration.id + 'To'] ?
      attributeValueMap[attributeConfiguration.id + 'To'].utc().format() : undefined
  }

  if (attributeValueMap[attributeConfiguration.id + 'From']) {
    searchAttribute.from = attributeValueMap[attributeConfiguration.id + 'From']
  } else {
    delete searchAttribute.from
  }

  if (attributeValueMap[attributeConfiguration.id + 'To']) {
    searchAttribute.to = attributeValueMap[attributeConfiguration.id + 'To']
  } else {
    delete searchAttribute.to
  }

  const { to, from } = searchAttribute;

  if (from != null && to == null) {
    searchAttribute.mechanism = 'SPECIFIC_VALUE'
    searchAttribute.value = from
    delete searchAttribute.from
    delete searchAttribute.to
    delete searchAttribute.multiSelect
  } else if (!from && to) {
    searchAttribute.from = to
  }
}

const addAsterisks = (text) => {
  if (config.ADD_ASTERISKS_TO_SEARCH_VALUES) {
    if (text.indexOf('*') !== -1) return text
    return `*${text}*`
  }
  return text
}

export const prepareSpecificValue = (searchAttribute, attributeValueMap, attributeConfiguration) => {

  if (!searchAttribute?.mechanism === 'SPECIFIC_VALUE' || !attributeConfiguration || !attributeValueMap) return;
  if (attributeValueMap[attributeConfiguration.id] == null) {
    delete searchAttribute.value
    return;
  }
  switch (valueTypeToInput[attributeConfiguration.type]) {
    case 'datepicker':
      attributeValueMap[attributeConfiguration.id] = attributeValueMap[attributeConfiguration.id].utc().format();
      searchAttribute.value = attributeValueMap[attributeConfiguration.id]
      break
    case 'input':
      searchAttribute.value = addAsterisks(attributeValueMap[attributeConfiguration.id])
      break
    case 'switch':
      searchAttribute.value = attributeValueMap[attributeConfiguration.id] == 'true'
      break
    default:
      searchAttribute.value = attributeValueMap[attributeConfiguration.id]
      break
  }
}

export const prepareEnumeration = (searchAttribute, attributeValueMap, attributeConfiguration) => {
  if (searchAttribute == null || attributeConfiguration == null || attributeValueMap == null) return;
  if (searchAttribute.mechanism !== 'ENUMERATION') return

  if (attributeValueMap[attributeConfiguration.id] == null) {
    delete searchAttribute.value
    return;
  }
  searchAttribute.value = attributeValueMap[attributeConfiguration.id]
}


export const prepareRemoteObjectsResult = (
  remoteObjectsResult,
  attributeList,
  objectConfiguration,
) => {

  if (remoteObjectsResult == null || attributeList == null || objectConfiguration == null) return []
  const result = []
  remoteObjectsResult.forEach((remoteObject) => {
    const displayDataRes = {}
    result.push({ ...remoteObject, displayData: displayDataRes })
    if (!remoteObject.displayData) {
      return
    }

    Object.entries(remoteObject.displayData).forEach(([attributeId, displayData]) => {
      const attributeConfiguration = attributeList.find(a => a.id === attributeId);
      let displayAttribute = objectConfiguration.displayAttributes.find(a => a.attributeId === attributeId);

      displayDataRes[attributeId] = displayData
      if (displayData != null && attributeConfiguration.type === "YESNO") {
        displayDataRes[attributeId] = displayData.toLowerCase() === 'true' ? __('val_yes') : __('val_no');
      }
      if (displayAttribute.controllerType === "PopupListBox" || displayAttribute.defaultControllerType === "PopupListBox") {
        displayDataRes[attributeId] = displayData !== null ? __(displayData) : displayData;
      }
      if (displayAttribute.controllerType === "DateInput") {
        displayDataRes[attributeId] = displayData !== null ? moment(displayData).format(config.appDefaults.dateFormat) : displayData;
      }
      if (displayAttribute.controllerType === "DateTimeInput") {
        displayDataRes[attributeId] = displayData !== null ? moment(displayData).format(config.appDefaults.dateTimeFormat) : displayData;
      }

    })

  })

  return result;
}

export const OBJECT_BINDER_CONTROLLER_TYPE = 'ObjectBinder'
export const OBJECT_PICKER_CONTROLLER_TYPE = 'ObjectPicker'

export const getEnumValuesItems = (enumValues) => {
  if (!enumValues?.length) return []
  return enumValues.map((item) => ({
    value: item.value,
    label: item.toBeTranslated ? __(item.translationKey) : item.value
  }))
}

export const getEnumValuesMapItems = (enumValuesMap) => {
  if (!enumValuesMap || !Object.keys(enumValuesMap)?.length) return []
  return Object.keys(enumValuesMap).reduce((prev, enumId) => {
    prev[enumId] = getEnumValuesItems(enumValuesMap[enumId])
    return prev
  }, {})
}

export const getPayloadForDataFetch = (props) => {
  const {
    searchValues = {},
    sortValues = [],
    currentPagination = {},
    dataStructures = {},
  } = props || {}

  const {
    objectConfiguration = {},
    attributesList = [],
  } = dataStructures

  if (!objectConfiguration || !attributesList) {
    console.error('Not all props are settled correctly cannot execute the search');
    return;
  }

  const payload = {
    systemId: objectConfiguration.systemId,
    objectTypeId: objectConfiguration.objectTypeId,
    displayAttributes: objectConfiguration.displayAttributes,
    searchAttributes: objectConfiguration.searchAttributes ? _.cloneDeep(objectConfiguration.searchAttributes) : [],
    sortAttributes: sortValues.filter((item) => item.enabled),
    pageSize: currentPagination.pageSize,
    pageNumber: currentPagination.current
  };

  const payloadSearchAttribute = [];
  payload.searchAttributes.forEach(sA => {
    const attributeConfiguration = attributesList.find(a => a.id === sA.attributeId);
    switch (sA.mechanism) {
      case "SPECIFIC_VALUE":
        // TODO: to remove when works
        //if (attributeConfiguration.id === '100_6857c9117b0bd0fbe124db21169e') break
        //if (sA.attributeId === '100_dd4bf7e78a984fb1993007e4a005') sA.mechanism = 'ENUMERATION'
        prepareSpecificValue(sA, searchValues, attributeConfiguration);
        if (searchValues[sA.attributeId]) payloadSearchAttribute.push(sA);
        break;
      case "RANGE":
        prepareDateRangePayload_applyFelixAlgorithm(sA, searchValues, attributeConfiguration);
        if (searchValues[sA.attributeId + "From"] != null || searchValues[sA.attributeId + "To"] != null) payloadSearchAttribute.push(sA);
        break;
      case "ENUMERATION":
        prepareEnumeration(sA, searchValues, attributeConfiguration);
        if (searchValues[sA.attributeId]) payloadSearchAttribute.push(sA);
        break;
      default:
        console.error("Unsupported mechanism" + sA.mechanism);
        break;
    }
  })
  payload.searchAttributes = payloadSearchAttribute
  return payload
}

export const findArrayDelta = (array1, array2) => {
  const addedItems = array2.filter(item => !array1.includes(item));
  const removedItems = array1.filter(item => !array2.includes(item));

  return {
    added: addedItems,
    removed: removedItems
  };

}
