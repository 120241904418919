import React from 'react'
import PropTypes from 'prop-types'
import {MessageText, MessageType, MessageUserInfo} from "./MessageComponents";
import MessageAttachment from "./MessageAttachment";


class MessageHistorySingle extends React.Component {

  static propTypes = {
    historyMessage: PropTypes.object,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const {historyMessage, guiUser} = this.props;
    const editModeOn = false;
    const creator = guiUser.id === historyMessage.creator.id;
    return(
      <li className={`chat ${creator ? "right" : "left"}`}>
        <div className={'message'}>
          <MessageUserInfo user={historyMessage.modifier} dateToShow={historyMessage.lastUpdateDatetime} editModeOn={editModeOn}/>
          <MessageType editModeOn={editModeOn} entitySelected={historyMessage.entityId}
                       typeSelected={historyMessage.visibility}
                       onChangeTypeSelected={() => {}}
                       onChangeEntitySelected={() => {}}
                       {...this.props}
          />
          <MessageText editModeOn={editModeOn} messageText={historyMessage.text}
                       onChangeMessageText={() => {}} edited={false}
                       {...this.props}
          />
          <MessageAttachment editModeOn={editModeOn} attachmentPath={historyMessage.attachmentPath}
                             onChangeAttachmentPath={() => {}} {...this.props}
          />
        </div>
      </li>
    )
  }


}

export default MessageHistorySingle;
