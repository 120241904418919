import React, {useCallback, useEffect, useState} from "react";
import {Image} from 'antd';
import {api} from '../../providers/ApiProvider'
import {getBase64} from "../EntityAvatar/utils";
import {change, Field} from "redux-form";
import {renderField} from "../../utils/fieldValidator";
import {useDispatch} from "react-redux";
import { __ } from '../../utils/translationUtils'


export const ImageViewer = ({field, order, formName}) => {

  const [base64File, setBase64File] = useState(null)
  const dispatch = useDispatch()


  const retrieveImage = useCallback(async () => {

    if (order == null) {
      console.error('Cannot query image viewer if order is null');
      return
    }

    const value = field && (field.value || field.source)

    if (value == null) {
      console.error("Received value null for Image Viewer. Something goes wrong")
      return
    }

    if (typeof formName !== 'string' || field?.id == null) {
      console.error('No Form name specified, the file cannot be saved in redux form')
      return
    }

    dispatch(change(formName, field.id, value))


    const url = `staticFiles/${order.flowId}/${order.flowVersion}/${value}`

    const blobFile = await api.get(url, {responseType: 'arraybuffer'})
      .then(response => {
        if (response.status === 200) {
          return new Blob([response.data], {type: "image/jpeg;"});
        }
      }).catch(() => {
        return null
      });

    if (blobFile == null) {
      console.error("Cannot retrieve static file for value: " + value)
      return
    }

    setBase64File(await getBase64(blobFile));



  }, [dispatch, field, formName, order])

  useEffect(() => {

    if (base64File == null) {
      retrieveImage()
    }


  }, [retrieveImage, base64File])


  if (!base64File) return null

  return (
    <div className={`form-group label-control object-listing`}>
      <div className="col-sm-12 object-listing__list  top-bar">
        <div className="col-xs-4">
          <Field
            name={field.id}
            component={renderField}
            label={field.title ? __(field.title) : 'Image View'}
            description={field.description}
            className="form-control"
            labelCol={' '}
            inputCol={' '}
            type="hidden"
            items={[]}
          />
        </div>
        <div className="col-xs-8 pull-right text-right">
          <Image
            src={base64File}
          />
        </div>
      </div>
    </div>

  )


}




