export const selectionMode = {
  MULTI: 'checkbox',
  SINGLE: 'radio',
}

export const formMode = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  VIEW: 'VIEW',
}

export const wrapperType = {
  DEFAULT: 'DEFAULT',
  MODAL: 'MODAL',
  DRAWER: 'DRAWER',
  CONTENT: 'CONTENT',
}
