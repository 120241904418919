import React from 'react';
import {toast} from 'react-toastify';
import _, {filter, uniqBy} from 'lodash';
import moment from 'moment';
import {HashRouter, Link} from 'react-router-dom';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import axios from 'axios';
import {Icon} from '@ant-design/compatible';
import {Space} from 'antd';
import i18next from 'i18next';
import loadingBar from 'nprogress';
import {ERROR} from 'components/dist/Utils/LoggerUtils';
import {EllipsisText} from 'components/dist/Typography';
import Cookies from 'js-cookie';
import ViewDetails from '../components/UI/ViewDetails';

import * as config from '../constants/globalConfiguration';
import {CUSTOM_LOGO_NAME, getApiUrl} from '../constants/globalConfiguration';
import {api} from '../providers/ApiProvider';
import {hashHistory} from '../providers/HistoryProvider';
import {ENUMERATIONS} from '../constants';
import {sortEnumItems} from './enums';

import getErrorText from './getErrorText';
import {__} from './translationUtils';
import {geAuthCookieAttributes, getAuthCookieName} from '../providers/ReduxProvider/actions/userActions';
import {globalDispatch} from '../components/UI/Route/Private';
import * as types from '../providers/ReduxProvider/types';
import {cases} from "./stringUtils";

const IMAGE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/bmp',
  'image/x-windows-bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/x-jps',
  'image/tiff',
  'image/heic',
  'image/x-tiff',
  'image/svg+xml',
  'jpg',
  'jpeg',
  'tiff',
  'png',
  'gif',
  'svg',
  '.jpg',
  '.jpeg',
  '.tiff',
  '.png',
  '.heic',
  '.gif',
  '.svg',
];

const IMAGE_PNG = [
  'image/png',
  'png',
  '.png',
];

export const getOS = () => {
  const {userAgent} = window.navigator;
  const platform = navigator?.userAgentData?.platform || navigator?.platform
  const macOsPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  let os = '';

  if (macOsPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export function renderResultInfo(start, to, total, renderPagination) {
  return (
    <div className="pagination-info">
      {__('Total results')} : <strong>{total}</strong>.
      <span style={{display: 'inline-block', marginLeft: '10px'}}>{__('Page size')}</span> : {renderPagination?.() || this.renderPagination()}
    </div>
  );
}

export function isImage(type) {
  if (!type) return false;
  return !!type.match(/image.*/) || (IMAGE_TYPES.indexOf(type.toLowerCase()) > -1);
}

export function isPNG(type) {
  if (!type) return false;
  return (IMAGE_PNG.indexOf(type.toLowerCase()) > -1);
}

export function showMessage(type, message, props) {
  toast[type](<>{message}</>, {
    toastId: JSON.stringify(message),
    ...props,
  });
}

export function isComplexObject(type) {
  return [
    'RESPONSIBLE_USER',
    'RESPONSIBLE_ENTITY',
    'USER',
    'ENTITY',
    'ORDER_TYPE',
    'FLOW',
    'STEP',
    'DEADLINE',
  ].indexOf(type) > -1;
}

export function entityUrl(type, id) {
  if (!type || !id) {
    return null;
  }

  switch (type) {
    case 'RESPONSIBLE_USER':
    case 'USER':
      return `/users/${id}`;
    case 'RESPONSIBLE_ENTITY':
    case 'ENTITY':
      return `/redirectToEntity/${id}`;
    case 'ORDER_TYPE':
      return `/orderTypes/${id}/edit`;
    case 'FLOW':
      return '#';
    default:
      return '#';
  }
}

export function getFormEditedFields({submittedFormValues, initialValues, actualFormValues}) {
  const prevFormValues = submittedFormValues || initialValues || {};
  return Object.keys(prevFormValues).reduce((prev, key) => {
    if (!_.isEqual(prevFormValues[key], actualFormValues[key])) return {...prev, [key]: prevFormValues[key]};
    return prev;
  }, null);
}

export function getAttribute(attributes, dependenciesLabel, label) {
  return attributes.filter((a) => a.system === 'godoo' && a.dependenciesLabel === dependenciesLabel && a.property === label)[0];
}

export function fixControllerType(attributeConfig, type, controllerType) {
  // Ugly fix for translating godoo objects: Whitelisting some properties.
  if (attributeConfig.enumMechanismAllowed || (attributeConfig.system === 'godoo' && ['orderStatus', 'internalOrderStatus', 'name', 'title', 'ProcessStatus'].includes(attributeConfig.property))) {
    return 'select';
  }
  return controllerType;
}

export function cAlgorithm(processOverviews) {
  if (!processOverviews) return;

  // filter processes by active statuses (i.e. internalProcessStatus must be contained in (ASSIGNED,POSTPONED, STARTED, REJECTED)
  const result = processOverviews.filter((p) => global.constants.processActiveStatuses.includes(p.internalProcessStatus));
  // sort processes by lastUpdateDatetime desc
  result.sort((a, b) => b.lastUpdateDatetime.localeCompare(a.lastUpdateDatetime));
  // take the first one
  return result[0];
}

export function renderCAlgorithm(cell, row, attribute, fieldDisplayData, isSnapShot = false) {
  let data = cell;

  if (cell == null || typeof cell === 'undefined') {
    return '---';
  }

  let controllerType = fieldDisplayData.controllerType || fieldDisplayData.defaultControllerType;
  const fieldType = attribute.type;
  const isComplex = isComplexObject(fieldType);

  if (cell && cell.constructor === Array) {
    let items = cell;

    if (attribute.dependOnProcess) {
      const activeProcess = findActiveProcess(row.processOverviews ? row.processOverviews : [row.processOverview]);
      if (activeProcess) {
        items = filter(cell, (i) => activeProcess && i && i.parentObjectId === activeProcess.id);
      } else {
        // Depends on process but no active process found: getting data from the greatest process position
        items = filter(cell, (i) => i && i.parentObjectId === row.processOverviews?.reduce((prev, current) => ((prev.lastUpdateDatetime > current.lastUpdateDatetime) ? prev : current)).id);
      }
    }
    items = uniqBy(items, 'value');
    if (items.length === 0) {
      data = '---';
    } else if (items.length === 1) {
      // Ugly fix for translating godoo objects: Whitelisting some properties.
      controllerType = fixControllerType(attribute, fieldType, controllerType);
      data = <span
        key={`item-apopover-key-${items[0].value}`}>{formatData(items[0].value, fieldType, controllerType)}</span>;
      if (isComplex) {
        data = (
          <Link
            key={`item-bpopover-key-${items[0].id}`}
            to={entityUrl(fieldType, items[0].id)}
          >
            {formatData(items[0].value, fieldType, controllerType)}
          </Link>
        );
      }
    } else {
      const popoverItems = (isComplex)
        ? items.map((item) => {
          let {value} = item;
          if (attribute.id === '100_58be96d00e823f552aa1a014') {
            if (value && value.deadline) {
              value = value.deadline;
            }
          }
          return ([
            <Link
              key={`item-cpopover-key-${item.id}`}
              to={entityUrl(fieldType, item.id)}
            >
              {
                formatData(value, fieldType, controllerType)
              }
            </Link>,
            <br key={`item-cpopover-br-${item.id}`}/>,
          ]);
        })
        : items.map((item) => ([
          <span key={`item-dpopover-key-${item.value}`}>{formatData(item.value, fieldType, controllerType)}</span>,
          <br key={`item-dpopover-br-${item.value}`}/>,
        ]));

      const popoverHoverFocus = (
        <Popover id={`popover-aatrigger-${attribute.propertyLabel}`} title={__('')}>
          <HashRouter>
            {popoverItems}
          </HashRouter>
        </Popover>
      );
      data = (items.length > 1) ? (

        <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverHoverFocus}>
          <span className="user-nnpopover-trigger">
            {items.length}
            {' '}
            {__(attribute.propertyLabel)}
          </span>
        </OverlayTrigger>
      ) : (
        <span>{__(`no ${attribute.propertyLabel}`)}</span>
      );
    }
    return data;
  }
  if (isComplex) {
    let {value} = cell;
    if (attribute.id === '100_58be96d00e823f552aa1a014') {
      if (value && value.deadline) {
        value = moment(value.deadline).format(config.appDefaults.dateTimeFormat);

        const popoverDeadline = (
          <Popover className="capitalize" id={`popover-trigger-deadline-${attribute.id}`} title={__('deadline')}>
            {value}
          </Popover>
        );

        data = (
          <div>
            <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverDeadline}>
              <Icon
                className={cell.value.percentage && cell.value.percentage >= 90 ? 'blink' : ''}
                style={{marginRight: '4px', color: cell.value.color}}
                type="clock-circle"
                theme="filled"
              />
            </OverlayTrigger>
            <div style={{display: 'initial'}}>
              {value}
            </div>
          </div>
        );
      } else {
        value = null;
      }
    }

    if (cell.id) {
      data = <Link key={`item-rrpopover-key-${cell.id}`} to={entityUrl(fieldType, cell.id)}>{value}</Link>;
    }
  }

  data = formatData(data, fieldType, controllerType);
  if (controllerType === 'POINT') {
    if (attribute.dependOnProcess) {
      const activeProcess = findActiveProcess(row.processOverviews);
      if (activeProcess) {
        data = filter(cell, (i) => activeProcess && i.parentObjectId === activeProcess.id)[0].value;
      }
    }

    // Is array
    if (cell.constructor === Array) {
      let items = cell;
      items = uniqBy(items, 'value');
      if (attribute.dependOnProcess) {
        const activeProcess = findActiveProcess(row.processOverviews);
        if (activeProcess) {
          items = filter(cell, (i) => activeProcess && i.parentObjectId === activeProcess.id);
        }
      }
      const popoverItems = items.map((item, idx) => {
        const coordinates = typeof item.value !== 'undefined'
          ? item.value.coordinates
          : item.coordinates;
        return ([
          <a
            key={`item-point-popover-key-${idx}`}
            target="_blank"
            href={`https://www.google.com/maps/?q=${coordinates[0]},${coordinates[1]}`}
            rel="noreferrer"
          >
            {__('Show map')}
          </a>,
          <br key={`item-pop-over-br-${idx}`}/>,
        ]);
      });

      const popoverHoverFocus = (
        <Popover id={`popover-trigger-${attribute.propertyLabel}`} title={__('')}>
          {popoverItems}
        </Popover>
      );

      return (
        <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverHoverFocus}>
          <span className="user-popover-trigger">
            {items.length}
            {' '}
            {__(attribute.propertyLabel)}
          </span>
        </OverlayTrigger>
      );
    }
    // Single Item

    data = (
      <a
        target="_blank"
        href={`https://www.google.com/maps/?q=${data.coordinates[0]},${data.coordinates[1]}`}
        rel="noreferrer"
      >
        {__('Show map')}
      </a>
    );
  }

  // If attribute is "id" or "externalOrderId" make it a link to order overview
  if (attribute.id === '100_58be96d00e823f552aa1a000' || attribute.id === '100_58be96d00e823f552aa1a002') {
    return (
      <Link
        key={`item-overcviiew-key-${row.orderOverview.id}-${attribute.id}`}
        to={isSnapShot ? `/snapshots/${row.orderOverview.id}` : `/orders/${row.orderOverview.id}/overview`}
      >
        {cell}
      </Link>
    );
  }

  return (<EllipsisText>{data}</EllipsisText>);
}

export function renderGodooElement(resultsDisplayData, resultsId, attribute, defaultControllerType = null, processId = null, isSnapShot = false) {
  if (!isNaN(resultsDisplayData)) return <strong>{resultsDisplayData}</strong>;
  let data = resultsDisplayData;

  if (!resultsDisplayData) {
    return '---';
  }

  const attributeType = attribute.type;

  let controllerType = defaultControllerType;

  const isComplex = isComplexObject(attributeType);
  if (resultsDisplayData.constructor === Array) {
    let items = resultsDisplayData;

    if (attribute.dependOnProcess) {
      if (processId) {
        items = filter(resultsDisplayData, (i) => i && i.parentObjectId === processId);
      } else {
        // Depends on process but no active process found: getting data from the greatest process position
        // items = filter(resultsDisplayData, (i) => i && i.parentObjectId === results.processOverviews.reduce((prev, current) => (prev.position > current.position) ? prev : current).id);
        items = [];
      }
    }
    items = uniqBy(items, 'value');
    if (items.length === 0) {
      data = '---';
    } else if (items.length === 1) {
      // Ugly fix for translating godoo objects: Whitelisting some properties.
      controllerType = fixControllerType(attribute, attributeType, controllerType);

      data = (
        <span
          key={`item-apopover-key-${items[0].value}`}
        >
          {formatData(items[0].value, attributeType, controllerType)}
        </span>
      );
      if (isComplex) {
        data = (
          <Link
            key={`item-bpopover-key-${items[0].id}`}
            to={entityUrl(attributeType, items[0].id)}
          >
            {formatData(items[0].value, attributeType, controllerType)}
          </Link>
        );
      }
    } else {
      const popoverItems = (isComplex)
        ? items.map((item) => {
          let {value} = item;
          if (attribute.id === '100_58be96d00e823f552aa1a014') {
            if (value && value.deadline) {
              value = value.deadline;
            }
          }
          return ([
            <Link
              key={`item-cpopover-key-${item.id}`}
              to={entityUrl(attributeType, item.id)}
            >
              {
                formatData(value, attributeType, controllerType)
              }
            </Link>,
            <br key={`item-cpopover-br-${item.id}`}/>,
          ]);
        })
        : items.map((item) => ([
          <span key={`item-dpopover-key-${item.value}`}>{formatData(item.value, attributeType, controllerType)}</span>,
          <br key={`item-dpopover-br-${item.value}`}/>,
        ]));

      const popoverHoverFocus = (
        <Popover id={`popover-aatrigger-${attribute.propertyLabel}`} title={__('')}>
          {popoverItems}
        </Popover>
      );
      const lastS = (attribute.propertyLabel.slice(-1) !== 's' && items.length > 1) ? 's' : '';
      data = (items.length > 1) ? (

        <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverHoverFocus}>
          <span className="user-nnpopover-trigger">
            {items.length}
            {' '}
            {__(attribute.propertyLabel + lastS)}
          </span>
        </OverlayTrigger>
      ) : (
        <span>{__(`no ${attribute.propertyLabel + lastS}`)}</span>
      );
    }
    return data;
  }
  if (isComplex) {
    let {value} = resultsDisplayData;
    if (attribute.id === '100_58be96d00e823f552aa1a014') {
      if (value && value.deadline) {
        value = moment(value.deadline).format(config.appDefaults.dateTimeFormat);

        const popoverDeadline = (
          <Popover className="capitalize" id={`popover-trigger-deadline-${attribute.id}`} title={__('deadline')}>
            {value}
          </Popover>
        );

        data = (
          <div>
            <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverDeadline}>
              <Icon
                className={resultsDisplayData.value.percentage && resultsDisplayData.value.percentage >= 90 ? 'blink' : ''}
                style={{marginRight: '4px', color: resultsDisplayData.value.color}}
                type="clock-circle"
                theme="filled"
              />
            </OverlayTrigger>
            <div style={{display: 'initial'}}>
              {value}
            </div>
          </div>
        );
      } else {
        value = null;
      }
    }

    if (resultsDisplayData.id) {
      data = (
        <Link
          key={`item-rrpopover-key-${resultsDisplayData.id}`}
          to={entityUrl(attributeType, resultsDisplayData.id)}
        >
          {value}
        </Link>
      );
    }
  }

  data = formatData(data, attributeType, controllerType, attribute.translatable);

  if (controllerType === 'POINT') {
    if (attribute.dependOnProcess) {
      if (processId) {
        data = filter(resultsDisplayData, (i) => i.parentObjectId === processId)[0].value;
      }
    }

    // Is array
    if (resultsDisplayData.constructor === Array) {
      let items = resultsDisplayData;
      items = uniqBy(items, 'value');
      if (attribute.dependOnProcess) {
        if (processId) {
          items = filter(resultsDisplayData, (i) => i.parentObjectId === processId);
        }
      }
      const popoverItems = items.map((item, idx) => {
        const coordinates = typeof item.value !== 'undefined'
          ? item.value.coordinates
          : item.coordinates;
        return ([
          <a
            key={`item-point-popover-key-${idx}`}
            target="_blank"
            href={`https://www.google.com/maps/?q=${coordinates[0]},${coordinates[1]}`}
            rel="noreferrer"
          >
            {__('Show map')}
          </a>,
          <br key={`item-pop-over-br-${idx}`}/>,
        ]);
      });

      const popoverHoverFocus = (
        <Popover id={`popover-trigger-${attribute.propertyLabel}`} title={__('')}>
          {popoverItems}
        </Popover>
      );
      const lastS = (attribute.propertyLabel.slice(-1) !== 's' && items.length > 1) ? 's' : '';

      return (
        <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverHoverFocus}>
          <span className="user-popover-trigger">
            {items.length}
            {' '}
            {__(attribute.propertyLabel + lastS)}
          </span>
        </OverlayTrigger>
      );
    }
    // Single Item

    data = (
      <a
        target="_blank"
        href={`https://www.google.com/maps/?q=${data.coordinates[0]},${data.coordinates[1]}`}
        rel="noreferrer"
      >
        {__('Show map')}
      </a>
    );
  }

  // If attribute is "id" or "externalOrderId" make it a link to order overview
  if ((attribute.id === '100_58be96d00e823f552aa1a000' || attribute.id === '100_58be96d00e823f552aa1a002') && resultsId) {
    return (
      <Link
        key={`item-overcviiew-key-${resultsId}-${attribute.id}`}
        to={isSnapShot ? `/snapshots/${resultsId}` : `/orders/${resultsId}/overview`}
      >
        {resultsDisplayData}
      </Link>
    );
  }

  return data;
}

export function formatData(text, fieldType, controllerType, translatable) {
  if (['POINT'].includes(fieldType)) {
    return `${text.coordinates[0]} N, ${text.coordinates[1]} E`;
  }
  if (fieldType === "USER" && text === 'Anonymised') {
    return __('deleted_user', cases.CAPITALIZE)
  }
  if (['Email', 'Phone', 'Url'].includes(controllerType)) {
    let initial = '';
    let icon = '';
    switch (controllerType) {
      case 'Email':
        initial = 'mailto:';
        icon = 'mail';
        break;
      case 'Url':
        icon = 'global';
        break;
      case 'Phone':
        initial = 'tel:';
        icon = 'phone';
        break;
    }
    return (
      <div className="pad7px">
        <a href={initial + text}>
          <Icon type={icon}/>
          {' '}
          {text}
        </a>
      </div>
    );
  }
  if (['DateTime', 'DateTimeInput', 'DATETIME', 'DATE'].includes(fieldType)) {
    let format = config.appDefaults.dateTimeFormat;
    if (controllerType) format = (['DateInput'].includes(controllerType) ? config.appDefaults.dateFormat : config.appDefaults.dateTimeFormat);
    return moment(text).format(format);
  }
  if (['Date', 'DateInput'].includes(fieldType)) {
    return moment(text).format(config.appDefaults.dateFormat);
  }
  if (['yesno', 'YesNo', 'YESNO', 'BOOLEAN'].includes(fieldType)) {
    // TODO: This is for fixing an urgent Prod issue (https://mantis.e-globe-solutions.com/view.php?id=3859). Please investigate further.
    if (text === null || text === undefined) {
      text = 'false';
    }
    if (String.prototype.toLowerCase.apply(text) === 'true' || String.prototype.toLowerCase.apply(text) === 'yes') {
      text = (['BOOLEAN'].includes(fieldType)) ? __('val_true') : __('val_yes');
    } else if (String.prototype.toLowerCase.apply(text) === 'false' || String.prototype.toLowerCase.apply(text) === 'no') {
      text = (['BOOLEAN'].includes(fieldType)) ? __('val_false') : __('val_no');
    }
  }
  if (['TranslatedLabel', 'ComplexObject'].includes(fieldType)) {
    if (typeof data === 'object') {
      text[0].value = __(text[0].value);
    } else text = __(text);
  }
  if (translatable && (typeof text === 'string')) {
    text = __(text);
  }

  if (['select', 'SimpleCheckBox', 'PopupListBox'].includes(fieldType)) {
    return __(text);
  }
  if (['select', 'SimpleCheckBox', 'PopupListBox', 'TranslatedLabel'].includes(controllerType)) {
    text = __(text);
  }
  if (['STEP'].includes(fieldType)) {
    text = __(text);
  }
  if (['ENUM_VALUE'].includes(fieldType)) {
    if (typeof text === 'object') {
      if (text && text.value) {
        text = text.value;
      } else {
        text = '---';
      }
    }
    text = text ? __(text) : '---';
  }
  if (['BILLING'].includes(fieldType)) {
    try {
      const billingItems = JSON.parse(text);
      text = billingItems.length > 0 ? '' : '--';
      billingItems.forEach((item) => {
        text = `${text + item.categoryName}: ${item.categoryTotalPrice}\n`;
      });
    } catch (e) {
      // Ignored
      text = '--';
    }
  }
  return text;
}

export function addAsterisks(text) {
  if (config.ADD_ASTERISKS_TO_SEARCH_VALUES) {
    if (text.indexOf('*') !== -1) {
      return text;
    }
    return `*${text}*`;
  }

  return text;
}

export function getInitialPageSize(listName) {
  // Check if setting exist in general. If not create it.
  const paginationSettings = JSON.parse(localStorage.getItem('paginationSettings'));
  if (paginationSettings) {
    // Check if default page size has been set for this specific list
    if (typeof paginationSettings[listName] !== 'undefined') {
      return paginationSettings[listName];
    }
    if (listName === 'addStepObjectList') {
      return config.DEFAULT_PAGE_SIZE_OBJECTLIST;
    }
    return config.DEFAULT_PAGE_SIZE;

    // Or return default size
  }
  localStorage.setItem('paginationSettings', JSON.stringify({}));
  if (listName === 'addStepObjectList') {
    return config.DEFAULT_PAGE_SIZE_OBJECTLIST;
  }
  return config.DEFAULT_PAGE_SIZE;
}

export function setInitialPageSize(listName, value) {
  // Check if setting exist in general. If not create it.
  const paginationSettings = JSON.parse(localStorage.getItem('paginationSettings')) || {};
  paginationSettings[listName] = value;
  localStorage.setItem('paginationSettings', JSON.stringify(paginationSettings));
}

export function getAuthData() {
  return Cookies?.get?.(getAuthCookieName(), geAuthCookieAttributes()) || null;
}

export const setCustomEnv = (newAPI) => {
  window.__env__ = {
    ...window.__env__,
    ...{
      API_URL: newAPI,
      API_CALENDAR_PREFIX: '/calendar',
      API_TRANSLATION_PREFIX: newAPI?.includes('godoo.app') ? '' : '/translation',
      API_REPORT_PREFIX: '',
      UI_FLOW_CONFIG_URL: `${newAPI}/config`,
      UI_TRANSLATION_URL: `${newAPI}/translation`,
    },
  };
  localStorage.setItem('env', JSON.stringify(window.__env__));
};

export const addWatermarks = (text) => {
  const c = text.length * 2.5;

  const root = document.querySelector('#watermarks');
  const bg = `url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='${c * 3}px' width='${c * 3}px'><text transform='translate(${c / 2}, ${c * 2.6}) rotate(-45)' fill='rgb(245,45,45,0.5)' font-size='20' >${text}</text></svg>\")`;
  root.style.backgroundImage = bg;
};

export const removeWatermarks = () => {
  const root = document.querySelector('#watermarks');
  root.style.backgroundImage = '';
};

export const resetEnv = () => {
  localStorage.removeItem('env');
};

export function getApiHandler(e) {
  const api = axios.create({baseURL: config.getApiUrl()});

  const authInCookie = getAuthData();
  if (localStorage.getItem('entities')) {
    const entityFromLocalStorage = JSON.parse(localStorage.getItem('entities'));
    if (entityFromLocalStorage) {
      // TODO: get value from UI
      api.defaults.headers.common['x-entity-id'] = entityFromLocalStorage?.[0]?.id;
    }
  }

  if (authInCookie) {
    api.defaults.headers.common['x-auth-token'] = authInCookie;
  }

  // Set other needed Headers
  api.defaults.headers.common['Client-Type'] = config.DEVICE_TYPES.WEB;
  api.defaults.headers.common['Content-Type'] = 'application/json';
  api.defaults.headers.common['Client-Version'] = config.CLIENT_VERSION;
  api.defaults.headers.common.TimeZoneOffset = moment().utcOffset();
  api.defaults.headers.common.Language = window.localStorage.getItem('lang');

  api.interceptors.response.use((response) => {
    config.setApiRelease(response.headers);
    return response;
  }, async (error) => {
    if (error.message === 'Network Error') {
      return Promise.reject('logout');
    }

    if (error?.response?.status === 401) {
      const me = await fetch(`${getApiUrl()}/me`, {
        headers: {
          ...api.defaults.headers.common,
        },
      });

      if (me.status === 401) {
        globalDispatch?.({type: types.USER_LOGGED_OUT});
        Cookies.remove(getAuthCookieName(), geAuthCookieAttributes());
        localStorage.removeItem('entities');
        localStorage.removeItem('calendarDefaultSelection');
        localStorage.removeItem('mostRecentItems');

        if (hashHistory?.location?.pathname !== '/login')
          hashHistory.push('/login');
      }

      return Promise.reject(error)
    }

    loadingBar.done();
    if (error.message === 'canceled') {
      return;
    }
    // failed get api response from calendar, right now doesnt displays error messages

    const hideErrorMessages =
      ((error?.config?.url?.indexOf('calendar') > -1) && (error?.config?.method === 'get')) ||
      ((error?.config?.url?.indexOf('autoGenerated') > -1) && (error?.config?.method === 'post')) ||
      (error?.config?.url?.indexOf(COMPANY_LOGO_DIRECTORY) > -1) ||
      (error?.config?.url?.indexOf(USER_LOGO_DIRECTORY) > -1) ||
      (error?.config?.url?.indexOf(CUSTOM_LOGO_NAME) > -1) ||
      (error?.config?.url?.indexOf('login') > -1) ||
      (error?.config?.url?.indexOf('staticFiles/thumbnails') > -1) ||
      (error?.config?.url?.indexOf('auth/refresh') > -1) ||
      (error?.config?.url?.indexOf('requests/') > -1)

    // Do something with response error
    if (typeof error.response !== 'undefined' && !hideErrorMessages) {
      if (typeof error.response.data === 'undefined') {
        showMessage('error', <Space direction="vertical" size={2}>
          <strong>Error:</strong>
          error undefined
        </Space>); // previous code was not clear the error to show since it was printing error object
      } else {
        const err = error.response.data.toString() === '[object ArrayBuffer]'
          ? JSON.parse(Buffer.from(error.response.data).toString('utf8'))
          : error.response.data;

        // Not found => warning
        if (error.response.status === 404) {
          showLog('warning', err);
        } else {
          showLog('error', err);
        }
      }
    }

    return Promise.reject(error);
  });

  return api;
}

const showLog = (logType, err) => {
  const link = (
    <a
      target="_blank"
      href={`${window.location.origin}#logs/${err.logId}`}
      style={{color: '#DCDCDC'}}
      rel="noreferrer"
    >
      {__('Click here for more details')}
    </a>
  );
  const details = getErrorText(err.details);

  showMessage(logType, (
    <Space direction="vertical" size={2}>
      <strong>
        {capitalize(logType)}
        {' '}
        {err.code}
      </strong>
      <text>{err.message}</text>
      {/* TODO: can't show this ugly message, but how can we use it? */}
      {details && <text>{__(details)}</text>}
      {(err.validationMessages)
        && <ViewDetails messages={[...err.validationMessages.map((message) => message.description)]}/>}
      {(err.logId) && <text>{link}</text>}
    </Space>
  ));
};

export const refreshToken = () => api.post('auth/refresh');

export const logoutUser = () => api.post('auth/logout')
  .then(() => {
    ERROR('logout');
  }).catch((err) => {
    ERROR(err);
  });

export const checkUserRights = function (userRights, rightDesc, log = false) {
  const found = _.findIndex(userRights, (r) => {
    let isEqual = true;
    for (const prop in rightDesc) {
      if (r[prop] !== rightDesc[prop]) {
        isEqual = false;
      }
    }
    return isEqual;
  });

  if (log) {
    console.log({found});
  }

  return (found > -1);
};

export const checkUserRightsAll = (rights, rightsToCheck, log = false) => {
  const userRights = {};

  if (rightsToCheck) {
    Object.entries(rightsToCheck).forEach(([label, description]) => {
      userRights[label] = checkUserRights(rights, description, log);
    });
  }

  return userRights;
};

export const hasBatchAssignRights = (userRights) => {
  const {rightMappings} = global.constants;
  return userRights && checkUserRights(userRights, rightMappings.CAN_BATCH_ASSIGN_PROCESSES);
};

export const hasCancelRights = (userRights) => {
  const {rightMappings} = global.constants;
  return userRights
    && checkUserRights(userRights, rightMappings.CAN_EXECUTE_ORDER_ACTION)
    && checkUserRights(userRights, rightMappings.CAN_CANCEL_ORDER);
};

export const hasArchiveRights = (userRights) => {
  const {rightMappings} = global.constants;
  return userRights
    && checkUserRights(userRights, rightMappings.CAN_EXECUTE_ORDER_ACTION)
    && checkUserRights(userRights, rightMappings.CAN_ARCHIVE_ORDER);
};

export const hasDeleteRights = (userRights) => {
  const {rightMappings} = global.constants;
  return userRights
    && checkUserRights(userRights, rightMappings.CAN_EXECUTE_ORDER_ACTION)
    && checkUserRights(userRights, rightMappings.CAN_DELETE_ORDER);
};

export const hasHardDeleteRights = (userRights) => {
  const {rightMappings} = global.constants;
  return userRights
    && checkUserRights(userRights, rightMappings.CAN_EXECUTE_ORDER_ACTION)
    && checkUserRights(userRights, rightMappings.CAN_HARD_DELETE_ORDER);
};

export const canUserAssociateEntityOfTypeToOrderGroup = ({userRights, type}) => {
  const {rightMappings} = global.constants;
  if (type === 'SELF') {
    return checkUserRights(userRights, rightMappings.CAN_ASSOCIATE_SELFT_TYPES_TO_ORDERGROUP);
  }
  if (type === 'ENTITY') {
    return checkUserRights(userRights, rightMappings.CAN_ASSOCIATE_ENTITY_TYPES_TO_ORDERGROUP);
  }
  if (type === 'GLOBAL') {
    return checkUserRights(userRights, rightMappings.CAN_ASSOCIATE_GLOBAL_TYPES_TO_ORDERGROUP);
  }
};

export const fetchUserRoles = () => api.get('rights/templates')
  .then((response) => response.data)
  .catch((err) => ERROR(err));

export const findActiveProcess = function (processes, activeProcessId) {
  if (!processes) {
    return;
  }

  if (activeProcessId) {
    return processes.find((process) => process.id === activeProcessId);
  }

  return cAlgorithm(processes);
};

export const setClickedButton = function (step, buttonName) {
  if (!step?.buttons || !buttonName) {
    return;
  }

  const buttonIndex = _.findIndex(step.buttons, (b) => b.name === buttonName);

  unClickButtonsAndRemoveErrors(step);
  step.buttons[buttonIndex].clicked = true;
};
export const unClickButtonsAndRemoveErrors = function (step) {
  if (!step?.buttons) {
    return;
  }

  step.buttons.forEach((b) => b.clicked = false);
  step.fields.forEach((f) => f.error = undefined);
};

export const searchToObject = function (search) {
  if (!search) return {};
  const pairs = search.substring(1).split('&');
  const obj = {};
  let pair;
  let i;

  for (i in pairs) {
    if (pairs[i] === '') continue;

    pair = pairs[i].split('=');
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
};

export function addBeamerScript() {
  const script = document.createElement('script');
  script.innerHTML = 'var beamer_config = {\n'
    + '                product_id : \'XmpHMAhy19376\',\n'
    + '                selector : \'notificationTrigger\',\n'
    + '                right: -7,\n'
    + '                top: -8\n'
    + '              };';
  script.async = false;
  script.type = 'text/javascript';
  document.body.appendChild(script);

  const secondScript = document.createElement('script');
  secondScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  secondScript.async = false;

  document.body.appendChild(secondScript);
}

export function mapCommentType(type) {
  switch (type) {
    case 'GLOBAL':
      return 'global';
    case 'ENTITY':
      return 'specific entity';
    case 'SELF':
      return 'private';
  }
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return s;

  //  if (s.toUpperCase() === 'GODOO') {
  //    return 'godoo'
  //  }

  // This will capitalize all first letter of words
  return s.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
};

export function renameProp(obj, oldKey, newKey) {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
}

const INSTANCE_LOGO_DIRECTORY = 'logo';
const COMPANY_LOGO_DIRECTORY = 'companyLogos';
const USER_LOGO_DIRECTORY = 'userLogos';

export function getInstanceLogoUrl(fileName) {
  return `${config.getApiUrl()}/staticFiles/${INSTANCE_LOGO_DIRECTORY}/${fileName}`;
}

export function getCompanyLogoUrl(entityId) {
  if (!entityId) return '';
  return `${config.getApiUrl()}/staticFiles/${COMPANY_LOGO_DIRECTORY}/${entityId}`;
}

export function getUserLogoUrl(userId) {
  if (!userId) return '';
  return `${config.getApiUrl()}/staticFiles/${USER_LOGO_DIRECTORY}/${userId}`;
}

export function getUserHistory(userId) {
  if (!userId) return null;
  return `${config.getApiUrl()}/users/${userId}/history`;
}

export function getChartResultsUrl(props) {
  const {preview, firstConfigId, name} = props || {};
  if (preview) {
    return `${config.prefix()?.REPORT || ''}/cube/query?configurationId=${firstConfigId}&widgetName=${name}&speedUpDate=${preview}`;
  }
  return `${config.prefix()?.REPORT || ''}/cube/query?configurationId=${firstConfigId}&widgetName=${name}`;
}

export function getRemoteObjectViewsGivenRelatedObjectList(subObjects) {
  return `/configurations/remoteObjects?relatedObjectList=${subObjects}`;
}

export function getWidgetFastActionUrl(id, action) {
  switch (action) {
    case 'delete':
      return `${config.prefix()?.REPORT || ''}/widgets/${id}`;
    case 'clone':
      return `${config.prefix()?.REPORT || ''}/widgets/${id}/clone`;
    case 'export':
      return `${config.prefix()?.REPORT || ''}/widgets/${id}/export`;
    default:
      return '';
  }
}

export const getDashboardCloneUrl = (id) => `${config.prefix()?.REPORT || ''}/dashboards/${id}/clone`;

export const cloneDashboard = async (id) => {
  await api.post(getDashboardCloneUrl(id))
    .then((response) => {
      showMessage('success',
        <NotificationBodyRedirect
          message="The dashboard has been correctly cloned"
          redirectMessage="Go to the dashboard"
          path={`/dashboards/${response?.headers?.['x-location']}/edit`}
        />);
    }).catch((err) => console.log(err));
};

export const cloneWidget = async (id) => {
  await api.post(getWidgetFastActionUrl(id, 'clone'))
    .then((response) => {
      showMessage('success',
        <NotificationBodyRedirect
          message="The widget has been correctly cloned"
          redirectMessage="Go to the widget"
          path={`/widgets/${response?.headers?.['x-location']}/edit`}
        />);
    }).catch((err) => console.log(err));
};

export const NotificationBodyRedirect = (props) => {
  const {message} = props || {};
  const path = props?.path || '/';
  const redirectMessage = props?.redirectMessage || 'Check redirection';
  return (
    <Space direction="vertical">
      {message && __(message)}
      <a
        onClick={() => hashHistory.replace(path)}
        style={{color: '#DCDCDC'}}
      >
        <strong>{__(redirectMessage)}</strong>
      </a>
    </Space>
  );
};

export const getUserPinnedDashboardId = async () => api.get('userSettings').then((response) => response?.data?.pinnedDashboardId);

export const fetchUserEntities = async (userId) => {
  if (!userId) return null;
  return api.get(`users/${userId}/entities`);
};

export const getUserGroups = async (userId) => fetchUserEntities(userId).then((response) => response?.data?.filter((e) => e?.type === 'user_group'));

export const fetchEntity = (entityId) => api.get(`/entities/${entityId}`)
  .then((response) => response.data)
  .catch((error) => ERROR(error));

export const fetchAllEntities = () => api.get('entities?pageSize=200&status=enabled,reserved')
  .then((response) => response.data)
  .catch((error) => ERROR(error));

export const fetchEntitySettings = (entityId) => api.get(`entitySettings/${entityId}`)
  .then((response) => response.data)
  .catch((error) => ERROR(error));

export const getAllEntitiesSettings = async (userGroups) => Promise.all(userGroups.map((ug) => fetchEntitySettings(ug?.id)))
  .catch((err) => {
    showMessage('error', err?.message);
  });

export const fetchOrder = (orderId) => api.get(`orders/${orderId}`)
  .then((response) => response.data)
  .catch(() => {
    showMessage('error', `Error encountered while fetching order ${orderId}`);
    return null;
  });

/**
 * @deprecated
 */
export const getDashboardsUrl = (props) => {
  const {
    pageSize, sorter, pageNumber, ids,
  } = props || {};
  return `${config.prefix()?.REPORT || ''}/dashboards?${pageSize ? `&pageSize=${pageSize}` : ''}${sorter?.order ? `&sort=${sorter.order === 'ascend' ? '' : '-'}${sorter.field}` : ''}${pageNumber ? `&pageNumber=${pageNumber}` : ''}${ids ? `&ids=${ids}` : ''}`;
};

export const convertArrayToObject = (array, key) => {
  if (!Array.isArray(array) || !key) return null;
  const object = {};
  array.forEach((item) => {
    object[item[key]] = item;
  });
  return object;
};

export const fetchEnumById = (id, queryParams, forcedOrderingType) => {
  if (!id) {
    showMessage('warning', 'Trying to fetch enum with null id. CHECK ATTRIBUTE CONFIGURATION.');
    return null;
  }
  const enumQueryParams = (
    (queryParams?.parentPath ? `?parentPath=${queryParams.parentPath}` : '')
    + (queryParams?.valuePath ? `&valuePath=${queryParams.valuePath}` : '')
  );
  return api.get(`/configurations/enumerations/${id}${enumQueryParams}`)
    .then((response) => {
      const items = response.data?.enumerationValues;
      const orderingType = forcedOrderingType || response.data?.sortOptions?.ordering;
      if (items && orderingType) response.data.enumerationValues = sortEnumItems(items, orderingType);
      return response.data;
    })
    .catch(() => {
      showMessage('error', `MultipleChoice: Cannot fetch Enumeration values for enumeration id: ${id}`);
      return false;
    });
};

export const getSelectorControllerItems = (controllerOptions, attributes) => {
  if (Array.isArray(controllerOptions?.items) && controllerOptions.items.length !== 0) {
    return controllerOptions.items;
  }
  if (controllerOptions?.enumerationId != null) {
    return fetchEnumById(controllerOptions.enumerationId).then((resp) => (resp.enumerationValues));
  }
  // Legacy code (DEPRECATED)
  if (Array.isArray(attributes?.customOptions) && attributes.customOptions.length > 0) {
    return attributes.customOptions.map((a) => ({value: a}));
  }
  return fetchEnumById(ENUMERATIONS.TRUE_FALSE).then((resp) => (resp.enumerationValues));
};

export const fetchView = async (id) => {
  if (!id) return null;
  return await api.get(`/configurations/views/${id}`).then((res) => res?.data).catch((err) => {
    showMessage('error', err?.message);
    return null;
  });
};

export const fetchReport = async (id) => {
  if (!id) return null;
  return await api.get(`/configurations/reports/${id}`).then((res) => res?.data).catch((err) => {
    showMessage('error', err?.message);
    return null;
  });
};

export const fetchRemoteSystems = async () => await api.get('/remoteSystems').then((res) => {
  res?.data.forEach((r) => res[r.id] = r.name);
  return res;
}).catch((err) => {
  showMessage('error', err?.message);
  return null;
});

export const fetchValueTypesMappings = async () => await api.get('/configurations/valueTypes/mappings').then((res) => res?.data).catch((err) => {
  showMessage('error', err?.message);
  return null;
});

export const fetchEntities = async () => await api.get('/entities').then((res) => res?.data).catch((err) => {
  showMessage('error', err?.message);
  return null;
});

export const fetchPublishedFlowsLastVersion = async () => await api.get('/flows/', {
  data: null,
  params: {size: 1000},
}).then((res) => res?.data).catch((err) => {
  showMessage('error', err?.message);
  return null;
});

export const createView = async (payload) => {
  if (!payload) return null;
  return await api.post('/configurations/views/', {...payload});
};

export const createReport = async (payload) => {
  if (!payload) return null;
  return await api.post('/configurations/reports/', {...payload});
};

export const updateView = async (payload, id) => {
  if (!id || !payload) return null;
  return await api.put(`/configurations/views/${id}`, {...payload});
};

export const updateReport = async (payload, id) => {
  if (!id || !payload) return null;
  return await api.put(`/configurations/reports/${id}`, {...payload});
};

export const getAttributesByFlows = async (flows) => await Promise.all(
  flows.map((flow) => api.get(`${config.getApiUrl()}/flows/${flow}/last/attributes`, {data: null})
    .catch((err) => null)),
).then((arrayOfValuesOrErrors) => arrayOfValuesOrErrors?.reduce((prev, flowResp) => (
  flowResp ? [...prev, ...flowResp.data] : prev
), [])).catch((err) => {
  showMessage('error', err?.message);
});

export const cloneROEConfiguration = async (id) => {
  // TODO: to be replaced by the api call for the clone for when it is implemented
  if (!id) return;
  const configuration = await api.get(`/configurations/remoteObjects/${id}`).then((resp) => (resp?.data));
  if (!configuration) return;
  const {
    accessRightsInfo,
    creationDatetime,
    creator,
    id: roeId,
    modifier,
    name,
    ...payloadToCopy
  } = configuration;
  const clonePayload = {...payloadToCopy, name: `${name}_COPY`};
  await api.post('/configurations/remoteObjects/', clonePayload)
    .then((response) => {
      if (!response?.data?.id) return;
      showMessage('success',
        <NotificationBodyRedirect
          message="The ROE configuration has been correctly cloned"
          redirectMessage="Go to the configuration"
          path={`/remoteObjects/${response?.data?.id}/edit`}
        />);
    })
    .catch((err) => console.error(err));
};

export const cloneViewConfiguration = async (id) => {
  // TODO: to be replaced by the api call for the clone for when it is implemented
  if (!id) return;
  const configuration = await api.get(`/configurations/views/${id}`).then((resp) => (resp?.data));
  if (!configuration) return;
  const {
    accessRightsInfo,
    creationDatetime,
    creator,
    id: roeId,
    modifier,
    name,
    ...payloadToCopy
  } = configuration;
  const clonePayload = {...payloadToCopy, name: `${name}_COPY`};
  await api.post('/configurations/views/', clonePayload)
    .then((response) => {
      if (!response?.data?.id) return;
      showMessage('success',
        <NotificationBodyRedirect
          message="The view configuration has been correctly cloned"
          redirectMessage="Go to the configuration"
          path={`/customViews/${response?.data?.id}/edit`}
        />);
    })
    .catch((err) => console.error(err));
};

export const cloneReportConfiguration = async (id) => {
  // TODO: to be replaced by the api call for the clone for when it is implemented
  if (!id) return;
  const configuration = await api.get(`/configurations/reports/${id}`).then((resp) => (resp?.data));
  if (!configuration) return;
  const {
    accessRightsInfo,
    creationDatetime,
    creator,
    id: roeId,
    modifier,
    name,
    ...payloadToCopy
  } = configuration;
  const clonePayload = {...payloadToCopy, name: `${name}_COPY`};
  await api.post('/configurations/reports/', clonePayload)
    .then((response) => {
      if (!response?.data?.id) return;
      showMessage('success',
        <NotificationBodyRedirect
          message="The report configuration has been correctly cloned"
          redirectMessage="Go to the configuration"
          path={`/customReports/${response?.data?.id}/edit`}
        />);
    })
    .catch((err) => console.error(err));
};

export const getAllTranslations = () => i18next?.options?.resources?.language?.translation || i18next?.store?.data?.en?.translation;

export const fetchEnumValuesByIds = async (ids) => Promise.all(
  ids.map((enumId) => fetchEnumById(enumId)
    .catch((err) => err)),
).then((result) => result.reduce((prev, enumeration) => {
  prev[enumeration.id] = enumeration.enumerationValues;
  return prev;
}, {})).catch((err) => {
  showMessage('error', err?.message);
});

export const editRemoteItem = async (payload) => api.put('/configurations/remoteObjects/update', payload);

export const editStepRemoteItem = async (props) => {
  const {
    objectTypeId, fieldId, payload, mainKey,
  } = props || {};
  if (!payload || !objectTypeId || !fieldId || !mainKey) return;
  return api.put(`remoteObjects/${objectTypeId}/${mainKey}?fieldId=${fieldId}`, payload);
};

export const editFlowRemoteItem = async (props) => {
  const {
    objectTypeId, flowId, flowVersion, payload, mainKey,
  } = props || {};
  if (!payload || !objectTypeId || !flowId || !flowVersion || !mainKey) return;
  return api.put(`remoteObjects/${objectTypeId}/${mainKey}?flowId=${flowId}&flowVersion=${flowVersion}`, payload);
};

export const createRemoteItem = async (payload) => api.post('/configurations/remoteObjects/create', payload);

export const createStepRemoteItem = async (props) => {
  const {objectTypeId, fieldId, payload} = props || {};
  if (!payload || !objectTypeId || !fieldId) return;
  return api.post(`remoteObjects/${objectTypeId}?fieldId=${fieldId}`, payload)
    .then(
      (response) => {
        if (response.status === 200) {
          setTimeout(() => showMessage('success', __('Step Object updated successfully')), 200);
        }
        return response.headers['x-location'];
      },
    )
    .catch((error) => {
      showMessage('error', `${__('ServerSideError')}. ${error}`);
    });
};

export const createFlowRemoteItem = async (props) => {
  const {
    objectTypeId, flowId, flowVersion, payload,
  } = props || {};
  if (!payload || !objectTypeId || !flowId || !flowVersion) return;
  return api.post(`remoteObjects/${objectTypeId}?flowId=${flowId}&flowVersion=${flowVersion}`, payload);
};

export const fetchRemoteItemsFromIds = async (ids, parentId) => {
  const promises = ids.map((id) => (
    new Promise((resolve) => api.get(`configurations/remoteObjects/${parentId}/${id}`)
      .then((res) => {
        resolve(res.data);
      }))
  ));
  return Promise.all(promises);
};

export const fetchStepRemoteObject = async (props) => {
  const {
    mainKey,
    objectTypeId,
    fieldId,
    orderId,
    overviewObjectConfigurationId,
    position,
  } = props || {};

  if (!mainKey || !objectTypeId || (!fieldId && (!orderId || !overviewObjectConfigurationId || position == undefined))) return undefined;
  return api.get(`remoteObjects/${objectTypeId}/${mainKey}?${fieldId ? `fieldId=${fieldId}` : `orderId=${orderId}&overviewObjectConfigurationId=${overviewObjectConfigurationId}&overviewObjectConfigurationDisplayAttributePosition=${position}`}`)
    .then((res) => (res.data?.[0]))
    .catch((err) => ERROR(err));
};

export const fetchStepRemoteItemsFromIds = async (props) => {
  const {
    mainKeys,
    objectTypeId,
    fieldId,
    orderId,
    overviewObjectConfigurationId,
    position,
  } = props || {};

  if (!mainKeys?.length || !objectTypeId || (!fieldId && (!orderId || !overviewObjectConfigurationId || position == undefined))) return [];
  const promises = mainKeys.map((mainKey) => (
    new Promise((resolve) => api.get(`remoteObjects/${objectTypeId}/${mainKey}?${fieldId ? `fieldId=${fieldId}` : `orderId=${orderId}&overviewObjectConfigurationId=${overviewObjectConfigurationId}&overviewObjectConfigurationDisplayAttributePosition=${position}`}`)
      .then((res) => {
        resolve(res.data);
      }).catch((err) => ERROR(err)))
  ));
  return Promise.all(promises);
};

export const fetchFlowRemoteItemsFromIds = async (props) => {
  const {
    objectTypeId, flowId, flowVersion, mainKeys,
  } = props || {};
  if (!mainKeys?.length || !objectTypeId || !flowVersion || !flowId) return [];
  const promises = mainKeys.map((mainKey) => (
    new Promise((resolve) => api.get(`remoteObjects/${objectTypeId}/${mainKey}?flowId=${flowId}&flowVersion=${flowVersion}`)
      .then((res) => {
        resolve(res.data);
      }))
  ));
  return Promise.all(promises);
};

export const fetchFlowRemoteItem = async (props) => {
  const {
    mainKey, objectTypeId, flowId, flowVersion,
  } = props || {};
  if (!mainKey || !objectTypeId || !flowVersion || !flowId) return undefined;

  return api.get(`remoteObjects/${objectTypeId}/${mainKey}?flowId=${flowId}&flowVersion=${flowVersion}`)
    .then((res) => (res.data?.[0]))
    .catch((err) => ERROR(err));
};

export const fetchStepRemoteTableData = async (props) => {
  const {
    objectTypeId,
    payload,
    ids,
    mappedBy,
    parentObjectId,
    fieldId,
    orderId,
    overviewObjectConfigurationId,
    position,
  } = props || {};

  if (!objectTypeId || !payload || (!fieldId && (!orderId || !overviewObjectConfigurationId || position == undefined))) return;
  return api.post(`remoteObjects/${objectTypeId}/search`, payload, {
    params: {
      ...(ids ? {ids} : {}),
      ...(mappedBy && parentObjectId ? {parentObject: mappedBy, parentObjectIds: parentObjectId} : {}),
      ...(fieldId ? {fieldId} : {orderId, overviewObjectConfigurationId}),
      overviewObjectConfigurationDisplayAttributePosition: position
    }
  });
};


export const fetchFlowRemoteTableData = async (props) => {
  const {
    objectTypeId, flowId, flowVersion, payload, ids,
  } = props || {};
  if (!payload || !objectTypeId || !flowId || !flowVersion) return;
  return api.post(`remoteObjects/${objectTypeId}/search?${ids ? `ids=${ids}&` : ''}flowId=${flowId}&flowVersion=${flowVersion}`, payload);
};

export const fetchStepTableData = async (payload) => {
  if (!payload || !Object.keys(payload).length) return;
  return await api.post('configurations/stepObjects/results', payload);
};

export const fetchAvailableAssignees = (processId, filters) => {
  const {userId} = filters || {};

  return api.get(`/processes/${processId}/ownership/availableAssignees?${userId ? `userIds=${userId}` : ''}`)
    .then((response) => response.data)
    .catch(() => {
      showMessage('error', `Error encountered while fetching available assignees for process: ${processId}`);
      return false;
    });
};

export const fetchRemoteObjects = async (id) => {
  if (!id) return null;
  return api.get(`/configurations/remoteObjects?pageNumber=1&pageSize=200&status=disabled,enabled,reserved&relatedObjectList=${id}`);
};

export const fetchDefaultRemoteObjectConfiguration = async (objectTypeId) => {
  const getDefaultConfigurationId = (list) => (
    list?.find((config) => config?.defaultConfiguration)?.id
    || list?.find((config) => config?.autoGeneratedConfiguration)?.id
    || list?.[0]?.id
  );
  if (!objectTypeId) return null;
  const fetchRemoteObjectsResp = await fetchRemoteObjects(objectTypeId);
  const configId = getDefaultConfigurationId(fetchRemoteObjectsResp?.data);
  return configId && await fetchRemoteObjectConfiguration(configId);
};

export const putProcessMaster = (processId, data) => api.put(`processes/${processId}/ownership/${data.id}`, data)
  .then((response) => response)
  .catch(() => {
    showMessage('error', `Error encountered while the master of a process: ${processId}`);
    return false;
  });

export const fetchAttributeTree = async () => await api.get('configurations/attributes/tree').then((res) => res?.data).catch((err) => {
  showMessage('error', err?.message);
  return null;
});

export const createRemoteObjectConfiguration = (payload) => {
  if (!payload) return null;
  return api.post('/configurations/remoteObjects/', {...payload});
};

export const updateRemoteObjectConfiguration = (payload, configurationId) => {
  if (!configurationId) return null;
  return api.put(`/configurations/remoteObjects/${configurationId}`, {...payload});
};

export const fetchRemoteObjectConfiguration = async (id) => {
  if (!id) return null;
  return api.get(`/configurations/remoteObjects/${id}`);
};

export const fetchViewsResultsFilteredByROE = (ROId, ROEId, payload) => {
  if (!payload || !ROId || !ROEId) return;
  return api.post(`configurations/views/results?remoteObjects=${ROId}&remoteObjectMainKeys=${ROEId}`, payload);
};

export const fetchROEExportData = (payload) => {
  if (!payload) return;
  return api.post('/configurations/remoteObjects/results/export', payload, {responseType: 'arraybuffer'}).catch((err) => {
    ERROR(err);
  });
};

export const fetchROEExportProgress = (exportName) => {
  if (!exportName) return;
  return api.get(`/exports/${exportName}/progress`);
};

export const fetchAllROEExports = (props) => {
  const {createdFrom, createdTo} = props || {};
  return api.get('/exports', {params: {createdFrom, createdTo}});
};

export const fetchRemoteObjectEntries = (payload) => {
  if (!payload) return;
  return api.post('/configurations/remoteObjects/results?saveRequest', payload);
};

export const fetchStepDefaultConfiguration = async (props) => {
  const {
    objectTypeId,
    fieldId,
    orderId,
    overviewObjectConfigurationId,
    position,
  } = props || {};
  if (!objectTypeId || (!fieldId && (!orderId || !overviewObjectConfigurationId || position == undefined))) return;
  return api.get(`remoteObjects/${objectTypeId}/defaultConfiguration?${fieldId ? `fieldId=${fieldId}` : `orderId=${orderId}&overviewObjectConfigurationId=${overviewObjectConfigurationId}&overviewObjectConfigurationDisplayAttributePosition=${position}`}`);
};
