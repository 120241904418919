import * as yup from 'yup'
import V from '../../utils/validation'

import { __, T } from '../../utils/translationUtils'
import { cases } from '../../utils/stringUtils'

export const resetPasswordValidationSchema = () => yup.object().shape({
  password: yup.string().concat(V.isRequiredSchema()).concat(V.minSchema(8)).concat(V.maxSchema()).nullable(),
  passwordConfirmation: yup.string().concat(V.isRequiredSchema()).oneOf([yup.ref('password')], __(T.passwordsMustMatch, cases.CAPITALIZE_SENTENCE)).nullable()
})
