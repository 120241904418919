import React, {useMemo} from "react";

import {useConfigurationConfiguratorContext} from "../context";
import ViewProperties from "./ViewPropertiesConfigurator";
import * as C from '../constants'
import { Display, Filters, Sorting } from './AttributeConfigurator'

import { __ } from '../../../utils/translationUtils'

const Body = ({ viewPropertiesRef }) => {

  const { state } = useConfigurationConfiguratorContext()
  const { attributesTree } = state

  const treeItems = useMemo(() => {
    const getItemsTreeFromNode = (node, level, parentPathLabel) => {
      const pathLabel = level === 1 ? `${parentPathLabel} / ${node.label}` : parentPathLabel
      return {
        key: `${node.objectTypeId}-${node.label}`,
        label: node.label,
        type: level === 1 ? 'Object' : 'Sub object',
        data: node?.data?.map((item) => ({
          key: item.id,
          label: __(item.propertyLabel),
          tag: pathLabel,
          type: item.type,
        })) || [],
        children: node?.children?.length ? node.children.map((child) => getItemsTreeFromNode(child, level + 1, pathLabel)) : [],
      }
    }
    return attributesTree && {
      key: 'test',
      label: 'test',
      data: [],
      children: attributesTree.map(({name, label, data}) => ({
        key: name,
        label,
        type: 'System',
        data: [],
        children: data?.map?.((child) => getItemsTreeFromNode(child || [], 1, label)) || [],
      })),
    }
  }, [attributesTree])

  return (
    <>
      { state.activeStep === C.steps.OUTPUT_FIELDS && <Display key={`body-${C.steps.OUTPUT_FIELDS}`} treeItems={treeItems}/> }
      { state.activeStep === C.steps.FILTERS && <Filters key={`body-${C.steps.FILTERS}`} treeItems={treeItems}/> }
      { state.activeStep === C.steps.SORTING_PARAMETERS && <Sorting key={`body-${C.steps.SORTING_PARAMETERS}`} treeItems={treeItems}/> }
      { state.activeStep === C.steps.VIEW_PROPERTIES && <ViewProperties viewPropertiesRef={viewPropertiesRef} key={`body-${C.steps.VIEW_PROPERTIES}`}/> }
    </>
  )
}

export default Body
