import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import Glyphicon from '@strongdm/glyphicon'
import RightsWildCard from './RightsWildCard';
import RightsParam from './RightsParam';

class RightsResourceMethod extends React.Component {

  static propTypes = {
    resource: PropTypes.object,
    method: PropTypes.array,
    requestParams: PropTypes.array,
    methodName: PropTypes.string,
    uri: PropTypes.string,
    resourceName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false
    };
  }

  handleToggleEnabled(event){
    event.stopPropagation();
    this.setState({
      isEnabled: !this.state.isEnabled
    });
  }

  render() {
    const mapMethodToWording = function(methodName){
      let method = methodName;
      switch(methodName){
        case 'GET':
          method = 'Read';
          break;
        case 'POST':
          method = 'Create';
          break;
        case 'PUT':
          method = 'Update';
          break;
        case 'DELETE':
          method = 'Delete';
          break;
      }

      return (
        <div className="no-selection toggle-fix" >
          <span onClick={this.handleToggleEnabled.bind(this)}
            title={!this.state.isEnabled?'enable':'disable'}
            className="toggle-enable">
            <Glyphicon glyph={this.state.isEnabled?'check':'unchecked'} />
            <span>{method}</span>
          </span>
          <i className="pull-right">{this.state.isEnabled?'enabled':'disabled'}</i>
          
        </div>
      );
    };

    let data = {};

    data = this.props.method;

    return (
      <Panel
        expanded={this.state.isEnabled}
        //onClick={this.handleToggleEnabled.bind(this)}
        className={this.state.isEnabled?'enabled':'disabled'}
        bsStyle="info" collapsible
        /* defaultExpanded */
      >
        <Panel.Heading>{mapMethodToWording.bind(this)(this.props.methodName)}</Panel.Heading>
        <Panel.Body>
          {
            <div>
              {
              data[0].wildcard &&
              <h5>Wild Cards:</h5>
              }
              <RightsWildCard
                resource={this.props.resource}
                resourceName={this.props.resourceName}
                uri={this.props.uri}
                methodName={this.props.methodName}
                data={data} />

              {
              data[0].requestParams && !data[0].wildcard &&
              <div>
                <h5>Request Params:</h5>
                {
                  data[0].requestParams.length > 0 &&
                  data[0].requestParams.map((param, k)=>{
                    return <RightsParam key={k} data={param} />;
                  })
                }

                {
                  data[0].requestParams.length === 0 &&
                  <i>No Request param available for this method.</i>
                }
              </div>
              }

            </div>
          }
        </Panel.Body>
      </Panel>
    );
  }

}

export default RightsResourceMethod;
