import styled, {css} from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const customIconWrapper = {
  default: css`
    cursor: pointer;
  `,
  disabled: css`
    cursor: not-allowed;
    opacity: .75;
  `,
  readOnly: css`
    cursor: default;
  `
}

export const IconWrapper = styled.div`
  display: flex;
  ${props => {
    if (props.readOnly) return customIconWrapper.readOnly
    if (props.disabled) return customIconWrapper.disabled
    return customIconWrapper.default
  }}
`
