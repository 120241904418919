import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {hashHistory} from '../providers/HistoryProvider';
import React from 'react';
import PropTypes from 'prop-types';
import {change, formValueSelector} from 'redux-form';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import objectAssign from 'object-assign';
import moment from 'moment';


import {
  addAsterisks,
  checkUserRights,
  getInitialPageSize,
  renderResultInfo,
  searchToObject,
  setInitialPageSize,
  showMessage,
} from '../utils/appHelper';
import {Button, DropdownButton, MenuItem, Modal} from 'react-bootstrap';
import Glyphicon from '@strongdm/glyphicon'
import * as config from '../constants/globalConfiguration';
import {deleteOverviewObjects} from '../providers/ReduxProvider/actions/overviewObjectActions';
import {api} from '../providers/ApiProvider'
import {stringify} from 'query-string';
import {Tooltip} from 'antd'
import {Icon} from '@ant-design/compatible'
import {__} from '../utils/translationUtils'
import {ScrollableListView, StickyScrollableTable} from "../providers/StyleProvider/styles";

const loadingBar = require('nprogress');
const BasicSearchForm = require('../components/BasicSearchForm').default;

const initialState = {
  templates: [],
  deleteModal: {
    open: false,
    orderGroup: {
      name: '',
      id: 0
    }
  },
  data: [],
  isLoading: false,
  pagination: {
    'pageNumber': 1,
    'pageSize': getInitialPageSize('overviewObjectsPage'),
    'totalCount': 0
  },
  sort: 'name'
};

class OverviewObjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    // initial results
    this.setQueryToState(this.updateHash.bind(this));
  }

  setQueryToState(cb) {
    let sq = searchToObject(this.props.location.search);
    this.props.change('basicSearchForm', 'q', sq.q);
    this.setState({
      sort: sq.sort || this.state.sort,
      pagination: objectAssign(this.state.pagination,
        {
          pageNumber: parseInt(sq.pageNumber) || this.state.pagination.pageNumber,
          pageSize: parseInt(sq.pageSize) || this.state.pagination.pageSize,
        }
      )
    }, () => {
      if (typeof cb !== 'undefined') {
        cb();
      }
    });
  }

  getParams() {
    let params = {...searchToObject(this.props.location.search)};
    return objectAssign({}, {
      sort: this.state.sort || params.sort,
      pageNumber: (parseInt(this.state.pagination.pageNumber) > -1) ? parseInt(this.state.pagination.pageNumber) : params.pageNumber,
      pageSize: this.state.pagination.pageSize || params.pageSize,
      q: this.props.q ? addAsterisks(this.props.q) : undefined,
    });
  }

  updateHash() {
    // update url params
    hashHistory.push({
      ...this.props.location,
      search: stringify({
        pageNumber: this.state.pagination.pageNumber,
        pageSize: this.state.pagination.pageSize,
        sort: this.state.sort,
        q: this.state.q,
      })
    }, this.getData());
  }

  search() {
    this.setState({
      q: this.props.q,
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: this.state.pagination.pageSize,
          pageNumber: 1
        }
      )
    }, () => {
      this.updateHash();
    });
  }

  getData = () => {
    this.setState({isLoading: true});
    loadingBar.start();
    let params = {...this.getParams.bind(this)(), pageNumber: this.getParams().pageNumber};

    return api.get('/configurations/overviewObjects', {
      params: params
    })
      .then(
        response => {
          this.setState({
            data: response.data,
            isLoading: false,
            pagination: objectAssign(
              this.state.pagination, {
                totalCount: parseInt(response.headers['x-total-count']),
                pageSize: parseInt(response.headers['x-page-size']),
                pageNumber: parseInt(response.headers['x-page'])
              }
            )
          }, () => {
            loadingBar.done();
          });
        },
        error => {
          showMessage('error', error);
          this.setState({
            isLoading: false,
          }, () => {
            loadingBar.done();
          });
        }
      );
  }

  onSortChange(sortName, sortOrder) {
    this.setState({sort: ((sortOrder === 'asc') ? '' : '-') + sortName}, function () {
      this.updateHash();
    });
  }

  onPageChange(page) {
    this.setState({
        pagination: objectAssign(
          this.state.pagination, {
            pageNumber: page
          }
        )
      },
      () => {
        this.updateHash();
      });
  }

  confirmDelete(orderGroup) {
    this.setState({deleteModal: {open: true, orderGroup: orderGroup}});
  }

  handledelete() {
    this.props.deleteOverviewObjects(this.state.deleteModal.orderGroup.id).then(() => {
      this.getData();
    });
    // Close modal:
    this.closeDeleteModal();
  }

  closeDeleteModal() {
    this.setState({deleteModal: {...this.state.deleteModal, open: false}});
  }

  setPageSize(val) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: val,
          pageNumber: 1
        }
      )
    }, () => {
      setInitialPageSize('orderGroupsPage', val);
      this.updateHash();
    });
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.setPageSize.bind(this)}
        bsStyle="link"
        title={this.state.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config.pageSizeOptions.map((val, i) => {
          return <MenuItem key={i} eventKey={val}>{val}</MenuItem>;
        })}
      </DropdownButton>
    );
  }

  trClassFormat(row) {
    return row.status;  // return class name.
  }

  render() {

    const {user} = this.props;
    let userRights = [];
    if (user) {
      userRights = user.rights;
    }


    function linkFormater(cell, row) {
      if (typeof row.accessRightsInfo === 'undefined' || row.accessRightsInfo.read) {
        return <Link className="stronger" to={`/overviewObjects/${row.id}/edit`}>{__(row.name)}</Link>;
      } else {
        return __(row.name);
      }
    }

    function actionsFormater(cell, row) {

      return (
        <div style={{display: 'flex'}} className="actions text-right">
          {/*
          { row.accessRightsInfo.edit &&
            <Link
              to={`/overviewObjects/`+row.id+'/edit'}
              className="btn btn-primary btn-sm btn-no-padding"
            >
              <Glyphicon glyph="edit"/>{__('Edit')} {' '}
            </Link>
          }
          */}

          {row.accessRightsInfo.edit &&
            <Tooltip title={__('Clone')}><Link to={`/overviewObjects/clone/` + row.id}>
              <div className="btn-no-padding btn-std"><Icon className="icon-std" type="copy" theme="outlined"/></div>
            </Link></Tooltip>
          }

          {row.accessRightsInfo.delete &&
            <Tooltip title={__('delete')}>
              <div className="btn-no-padding btn-std" onClick={() => this.confirmDelete.bind(this)(row)}><Icon
                className="icon-delete" type="delete" theme="outlined"/></div>
            </Tooltip>
          }
        </div>
      );
    }

    const {rightMappings} = global.constants

    return (
      <ScrollableListView id="main-content">

        <h1 className="sticky">
          <div className="container">
            {__('Manage Overview Objects')}
          </div>
        </h1>

        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-lg-offset-1 col-sm-12">

              {checkUserRights(userRights, rightMappings.CAN_CREATE_OVERVIEW_OBJECT) &&
                <Link className="btn btn-success col-xs-12 col-sm-3 gutter-bottom"
                      to={`/overviewobjects/create`}><Glyphicon glyph="plus"/>
                  {__(`Create Overview Object`)}
                </Link>
              }

              <div className="col-xs-12 col-sm-6 col-lg-4">
                <BasicSearchForm
                  isLoading={this.state.isLoading}
                  itemType={'Overview Objects'}
                  initialValues={{
                    q: searchToObject(this.props.location.search).q,
                  }}
                  onSubmit={this.search.bind(this)}
                />
              </div>
            </div>
          </div>

          {this.state.data &&
            <StickyScrollableTable
              data={this.state.data}
              remote={true}
              pagination={true}
              /*hover*/
              striped
              trClassName={this.trClassFormat}
              fetchInfo={{dataTotalSize: this.state.pagination.totalCount}}
              options={{
                onPageChange: this.onPageChange.bind(this),
                onSortChange: this.onSortChange.bind(this),
                noDataText: this.state.isLoading ? __('loading_data') : __('NoDataFound'),
                hideSizePerPage: false,
                firstPage: 'First',
                lastPage: 'Last',
                sizePerPageList: config.pageSizeOptions,
                paginationShowsTotal: renderResultInfo.bind(this),
                page: this.state.pagination.pageNumber,
                sizePerPage: this.state.pagination.pageSize,
              }}
            >
              <TableHeaderColumn
                dataSort
                isKey={true}
                hidden={true}
                dataField="id">
                {__('Id')}
              </TableHeaderColumn>

              <TableHeaderColumn
                dataSort
                dataField="name"
                dataFormat={linkFormater.bind(this)}
              >
                {__('Overview Objects Name')}
              </TableHeaderColumn>

              <TableHeaderColumn
                dataSort
                dataField="creationDatetime"
                dataFormat={(val) => {
                  return moment(val).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat);
                }}
              >
                {__('creationDateTime')}
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={actionsFormater.bind(this)}
                width="110"
              />

            </StickyScrollableTable>}

          <Modal
            backdrop="static"
            show={this.state.deleteModal.open}
            onHide={this.closeDeleteModal.bind(this)}>
            <Modal.Header closeButton>
              <Modal.Title>{__('Confirm Deletion')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{__('Are you sure you want to delete')} "<strong>{this.state.deleteModal.orderGroup.name}</strong>"?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeDeleteModal.bind(this)}>
                {__('Back')}
              </Button>
              <Button onClick={this.handledelete.bind(this)} bsStyle="danger">
                {__('Delete')}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

      </ScrollableListView>
    );
  }
}

OverviewObjects.propTypes = {
  deleteOverviewObjects: PropTypes.func,
  change: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object,
  q: PropTypes.string,
  orderGroup: PropTypes.object,
};

const selector = formValueSelector('basicSearchForm');
export default connect(
  state => {
    return {
      user: state.user.data,
      q: selector(state, 'q'),
    };
  },
  {deleteOverviewObjects, change}
)(OverviewObjects);
