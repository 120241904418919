import React, {useEffect, useRef, useState} from 'react'

import {Button} from "antd";

import Sidebar from "components/dist/Sidebar";
import FiltersForm from "./FiltersForm";
import * as U from "../utils";
import * as S from "../styles"
import { __ } from '../../../utils/translationUtils'

export default (props) => {

  const {history, setFilteredHistory, open, setOpen} = props

  const [submittedFilters, setSubmittedFilters] = useState({dateRange: [null, null], actions: [], authors: []})
  const [formOptions, setFormOptions] = useState({})
  const formRef = useRef(undefined)

  useEffect(() => {
    if (history) setFormOptions(U.getFormOptions(history))
  }, [history])

  const handleFormSubmit = () => {
    formRef?.current?.submit();
    setSubmittedFilters(formRef?.current?.getFieldsValue())
    setOpen(false)
  }

  const handleFormReset = () => {
    formRef?.current?.resetFields()
  }

  const handleFormResetToLastSubmit = () => {
    setOpen(false)
    formRef?.current?.setFields(Object.keys(submittedFilters).map((key) => ({name: key, value: submittedFilters[key]})))
  }

  const DrawerFooter = () => ([
    <Button onClick={handleFormSubmit} type="primary" style={{float: "right"}} key='apply'>
      {__("Apply Filters")}
    </Button>,
    <S.ActionButton onClick={handleFormReset} key='clear'>
      {__('Clear All')}
    </S.ActionButton>
  ])

  return (
    <>
      <Sidebar
        title={__("User History Filters")}
        placement="left"
        onClose={handleFormResetToLastSubmit}
        visible={open}
        getContainer={false}
        width={600}
        footer={<DrawerFooter />}
      >
        <FiltersForm
          setHistory={setFilteredHistory}
          prevHistory={history}
          formRef={formRef}
          options={formOptions}
        />
      </Sidebar>
    </>
  )
}
