import React from "react";

import {EditOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";

import {actionType, actionName} from "./constants";
import {appDefaults} from "../../constants/globalConfiguration";
import { __ } from '../../utils/translationUtils'

export const getActionType = (action) => {
  switch (action.name) {
    case actionName.ORGANISATIONS:
    case actionName.USER_GROUPS:
    case actionName.ROLES:
      if (action.oldValue.length === 0) return actionType.ADDED
      if (action.newValue.length === 0) return actionType.REMOVED
      return actionType.EDITED
    default:
      if (action.oldValue && action.newValue) return actionType.EDITED
      if (!action.oldValue && action.newValue) return actionType.ADDED
      if (action.oldValue && !action.newValue) return actionType.REMOVED
      return ''
  }
}

export const getActionTypeIcon = (type) => {
  switch (type) {
    case actionType.ADDED:
      return <PlusOutlined style={{color: "green", opacity: .7}}/>;
    case actionType.REMOVED:
      return <MinusOutlined style={{color: "red", opacity: .7}}/>;
    case actionType.EDITED: default:
      return <EditOutlined style={{color: "goldenrod", opacity: .7}}/>;
  }
}

export const formatValue = (value, type) => {
  if (!value) return 'undefined'
  if (type === "DATE") return moment(value).format(appDefaults.dateTimeFormat)
  return value
}

const mapEntityAction = (history, actionsKey, actionKey, valueKey, entityName, mappedEntities) => {
  if (!mappedEntities[entityName]) mappedEntities[entityName] = {...history[actionsKey].updatedFields[actionKey], name: entityName}
  else mappedEntities[entityName][valueKey] = history[actionsKey].updatedFields[actionKey][valueKey]
  delete history[actionsKey].updatedFields[actionKey]
}

export const getMappedUserHistory = (history) => {
  const mappedHistory = _.cloneDeep(history)
  history.forEach((actions, actionsKey) => {
    const entities = {
      [actionName.ORGANISATIONS]: undefined,
      [actionName.USER_GROUPS]: undefined
    }
    actions.updatedFields.forEach((action, actionKey) => {
      if (action.name === actionName.MATCHING_TEMPLATES) {
        mappedHistory[actionsKey].updatedFields[actionKey].name = actionName.ROLES
        const oldList = mappedHistory[actionsKey].updatedFields[actionKey].oldValue?.split(",") || []
        const newList = mappedHistory[actionsKey].updatedFields[actionKey].newValue?.split(",") || []
        mappedHistory[actionsKey].updatedFields[actionKey].oldValue = oldList?.filter((val) => !newList.includes(val))
        mappedHistory[actionsKey].updatedFields[actionKey].newValue = newList?.filter((val) => !oldList.includes(val))
      }
      if (action.name === actionName.REMOVED_FROM_ORGANISATIONS) {
        mapEntityAction(mappedHistory, actionsKey, actionKey, "oldValue", actionName.ORGANISATIONS,  entities)
      }
      if (action.name === actionName.ADDED_TO_ORGANISATIONS) {
        mapEntityAction(mappedHistory, actionsKey, actionKey, "newValue", actionName.ORGANISATIONS,  entities)
      }
      if (action.name === actionName.REMOVED_FROM_USER_GROUPS) {
        mapEntityAction(mappedHistory, actionsKey, actionKey, "oldValue", actionName.USER_GROUPS,  entities)
      }
      if (action.name === actionName.ADDED_TO_USER_GROUPS) {
        mapEntityAction(mappedHistory, actionsKey, actionKey, "newValue", actionName.USER_GROUPS,  entities)
      }
      if (action.name === actionName.AVATAR) {
        mappedHistory[actionsKey].updatedFields[actionKey].oldValue = mappedHistory[actionsKey].updatedFields[actionKey].oldValue?.avatarType
        mappedHistory[actionsKey].updatedFields[actionKey].newValue = mappedHistory[actionsKey].updatedFields[actionKey].newValue?.avatarType
      }
    })
    if (entities[actionName.ORGANISATIONS]) mappedHistory[actionsKey].updatedFields.push(entities[actionName.ORGANISATIONS])
    if (entities[actionName.USER_GROUPS]) mappedHistory[actionsKey].updatedFields.push(entities[actionName.USER_GROUPS])
  })
  return mappedHistory
}

export const getFormOptions = (history) => {
  const foundAuthors = history.reduce((prev, actions) => {
    if (!prev.includes(actions?.creator?.username)) return [...prev, actions?.creator?.username]
    return prev
  }, [])
  const foundActions = history.reduce((prev, actions) => {
    actions.updatedFields.forEach((action) => {if (!prev.includes(action.name)) prev = [...prev, action.name]})
    return prev
  }, [])
  return {
    authors: foundAuthors.map((author) => {return {label: author, value: author}}),
    actions: foundActions.map((action) => {return {label: __(action), value: action}})
  }
}
