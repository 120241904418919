
export const emptyIfNull = object => {
  if (Array.isArray(object))
    return object
  else if (object == null)
    return []

  throw new Error('Cannot convert this Object into an Array')
}


export const retrieveFileNamePath = directory => {
  if (directory?.name == null || directory?.name === '')
    throw new Error('Cannot compute file name if the directory is null or has no name')

  return directory.name.replace(/\s/g, '')
}

export const toLowerAndRemoveSpace = object => {
  if (typeof object !== 'string') throw new Error('Cannot perform this function on a different object than a string')
  return object.toLowerCase().replace(/\s/g, '')
}

export const removeLastPipeOrSlash = object => {
  if (typeof object !== 'string') throw new Error('Cannot perform this function on a different object than a string')
  if (object.slice(-1) === '|' || object.slice(-1) === '/') {
    return object.slice(0, -1)
  }
  return object
}
