import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as CONST from './constant'
import { ILLEGAL_ARGUMENT_EXCEPTION, UPLOAD_FILE_TYPE } from './constant'
import { Collapse, message } from 'antd'
import FileUploaderService from './services/fileUploaderService'
import { MESSAGE_FILE_IS_MANDATORY } from "./style";
import UploadFile from "./components/UploadFile";
import { __ } from '../../../../../utils/translationUtils'

const { Panel } = Collapse


FileUploader.propTypes = {
  field: PropTypes
    .shape({
      id: PropTypes.string,
      source: PropTypes.string,
      controllerOptions: PropTypes.object.isRequired
    })
    .isRequired
}

let timer = null;
const debounce = (func) => {
  if (!timer) {
    timer = 1000
    func.call()
  }

  setTimeout(() => timer = null, 1000)
}

function FileUploader(props) {

  const {
    onChange,
    field
  } = props

  const [filesTree, setFilesTree] = useState(null)
  const [fieldSource, setFieldSource] = useState(null) // I have to initialize this otherwise change of redux form  does not make me update the files

  useEffect(() => {
    // Need to check even field source because I need to get the xsdName, the primaryKey, the fieldName

    if (field?.controllerOptions != null) {
      let source = field?.source || field?.value
      try {
        source = JSON.parse(source)
      } catch (e) {
        console.warn(`Cannot parse the source for the file:\n ${source} \n Error: ${e}`)
        // Use controller Options when ready
        source = {}
      }
      source = fieldSource || source

      const treeSource = FileUploaderService.getFilesTree(source, field.controllerOptions)
      setFilesTree(treeSource)

    }
  }, [field, fieldSource])

  const onUpdate = useCallback((treeSource) => {
    const fieldSource = FileUploaderService.recomputeSource(treeSource)
    setFilesTree(treeSource)
    setFieldSource(fieldSource)
    onChange?.(JSON.stringify(fieldSource))
  }, [onChange])

  if (filesTree == null || filesTree[CONST.RootDirectory] == null) {
    return null
  }

  const handleUpload = async (jsonPath, file) => {
    if (file == null || jsonPath == null || jsonPath === '') {
      console.error(ILLEGAL_ARGUMENT_EXCEPTION)
      return null
    }

    await FileUploaderService.addFileToFilesTree(filesTree, jsonPath, file)
      .then((updatedFilesTree) => updatedFilesTree && onUpdate(updatedFilesTree))
  }

  const handleRemove = async (jsonPath, file) => {
    if (file == null || jsonPath == null || jsonPath === '') {
      console.error(ILLEGAL_ARGUMENT_EXCEPTION)
      return null
    }
    await FileUploaderService.removeFileFromFilesTree(filesTree, jsonPath, file)
      .then((updatedFilesTree) => updatedFilesTree && onUpdate(updatedFilesTree))
  }

  function isDisabled(fileDefinition) {
    if (fileDefinition == null) {
      console.error(ILLEGAL_ARGUMENT_EXCEPTION)
      return true
    }

    if (field?.readOnly) return true

    const fileSizes = (fileDefinition.files || []).reduce((totalSize, file) => {
      totalSize += file.size
      return totalSize
    }, 0)

    if (fileDefinition?.validateFileFields < fileSizes)
      return true
  }


  const renderFilesDefinitions = (directory, depth, activeKeys) => {
    if (!Array.isArray(directory?.fileDefinitions)) return null

    return (
      <div>
        <div>
          <Collapse defaultActiveKey={activeKeys} ghost>
            {
              directory.fileDefinitions.map((fileDefinition, index) => (
                <Panel
                  className={(!fileDefinition || !fileDefinition.files || fileDefinition.files.length === 0) ? "emptyPanel" : null}
                  key={activeKeys[index]}
                  header={`${fileDefinition.name}`}
                >
                  <UploadFile
                    version={UPLOAD_FILE_TYPE.FILE_DEFINTION}
                    directory={directory}
                    field={field}
                    onUploadFile={handleUpload}
                    onRemoveFile={handleRemove}
                    fileDefinition={fileDefinition}
                    isReadOnly={isDisabled(fileDefinition)}
                    context={{
                      changeFileName: true
                    }}
                  />
                </Panel>
              )
              )
            }
          </Collapse>
        </div>
      </div>
    )
  }


  const renderPanelDirectories = (directoryPath, depth = 0) => {
    if (directoryPath == null || (Array.isArray(directoryPath) && directoryPath.length === 0)) return null
    if (!Array.isArray(directoryPath)) directoryPath = [directoryPath]

    const activeKeys = directoryPath.map((directory, index) =>
      `directory_${directory.name}_depth_${depth}_index_${index}`)

    const nextDepth = depth + 1

    const invalid = directoryPath.find(dP => (field.error || []).includes(dP.jsonPath))

    // Check if I have to skip the base folder
    // In case the rootFiles has to be false since there are cases like stepObject edit form where there is a single
    // directory and no specification so the component will render null without the possibility to add files
    if (invalid) {
      const titles = depth === 0 && field.title ? [__(field.title)] : directoryPath.map(directory => __(directory[CONST.DirectoryName]))
      const errorShow = title => message.error(title)
      titles.forEach(t => debounce(errorShow.bind(null, `${t} : ${MESSAGE_FILE_IS_MANDATORY}`)));

    }
    if (field?.controllerOptions?.rootDirectory?.allowExtraFiles === false && depth === 0) {
      return (
        directoryPath.map((directory, index) =>
          <React.Fragment>
            {renderFilesDefinitions(directory, nextDepth, activeKeys)}
            {renderPanelDirectories(directory.subDirectories, nextDepth)}
          </React.Fragment>
        )
      )
    }
    return (
      <div>
        <div style={{ padding: "5px" }}>
          <Collapse defaultActiveKey={activeKeys} ghost>
            {
              directoryPath.map((directory, index) =>
                <Panel key={`directory_${directory.name}_depth_${depth}_index_${index}`}
                  header={depth === 0 && field.title ?
                    <span>{__(field.title) || __(directory.name)} </span>
                    :
                    <span>{__(directory.name)}</span>
                  }
                >
                  {renderFilesDefinitions(directory, nextDepth, activeKeys)}
                  {directory.allowExtraFiles &&
                    <UploadFile
                      directory={directory}
                      field={{ ...directory, readOnly: field?.readOnly }}
                      isReadOnly={!!field?.readOnly}
                      onUploadFile={handleUpload}
                      onRemoveFile={handleRemove}
                      version={UPLOAD_FILE_TYPE.DIRECTORY_DEFINITION}
                    />}

                  {renderPanelDirectories(directory.subDirectories, nextDepth)}

                </Panel>
              )
            }
          </Collapse>
        </div>
      </div>
    )
  }


  return renderPanelDirectories(filesTree[CONST.RootDirectory], 0)

}


export default FileUploader
