import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {DropdownButton, MenuItem, Panel} from 'react-bootstrap';
import moment from 'moment';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import objectAssign from 'object-assign';

import {api} from '../providers/ApiProvider'
import {getInitialPageSize, setInitialPageSize,} from '../utils/appHelper';
import * as config from '../constants/globalConfiguration';
import {__} from '../utils/translationUtils'

const loadingBar = require('nprogress');

class OrderTypeHistoryButton extends React.Component {

  static propTypes = {
    publishedFlows: PropTypes.array,
    orderTypeId: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: true,
      data: [],
      pagination: {
        'pageNumber': 1,
        'pageSize': getInitialPageSize('usersList'),
        'totalCount': 0
      },
      sort: '-id.position'
    };
  }

  handleToggle() {
    // Get data only first time.
    if (!this.state.open && this.state.isLoading) {
      this.getData();
    }
    this.setState({"open": !this.state.open});
  }

  getData() {
    const {pagination, sort} = this.state;
    const {pageNumber, pageSize} = pagination;
    api.get(`orderTypes/${this.props.orderTypeId}/history?pageNumber=${pageNumber}&pageSize=${pageSize}&sort=${sort}`)
      .then(
        response => {
          this.setState({
            data: response.data,
            isLoading: false,
            pagination: objectAssign(
              this.state.pagination, {
                totalCount: parseInt(response.headers['x-total-count']),
                pageSize: parseInt(response.headers['x-page-size']),
                pageNumber: parseInt(response.headers['x-page'])
              }
            )
          });
          loadingBar.done();
        }
      );
  }

  onSortChange(sortName, sortOrder) {
    this.setState({sort: ((sortOrder === 'asc') ? '' : '-') + sortName}, this.getData);
  }

  onPageChange(page) {
    this.setState({
        pagination: objectAssign(
          this.state.pagination, {
            pageNumber: page
          }
        )
      },
      this.getData,
    );
  }

  setPageSize(val) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: val,
          pageNumber: 1
        }
      )
    }, () => {
      this.updateHash();
      setInitialPageSize('usersList', val);
    });
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.setPageSize.bind(this)}
        bsStyle="link"
        title={this.state.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config.pageSizeOptions.map((val, i) => {
          return <MenuItem key={i} eventKey={val}>{val}</MenuItem>;
        })}
      </DropdownButton>
    );
  }

  render() {
    const rows = [].concat(this.state.data);
    const {publishedFlows} = this.props;

    const getFlowName = (flowId, flowVersion) => {
      const found = _.find(publishedFlows, (f) => f.flowId === flowId && f.flowVersion === flowVersion);
      if (typeof found === 'undefined') {
        return '';
      }

      return found.title;
    };

    return (
      <div className="order-history fieldset style-1">
        <span className="collapsible-header no-selection"
              onClick={this.handleToggle.bind(this)}>
          {__('Order Type History')}
        </span>

        <Panel
          style={{'marginBottom': '40px'}}
          expanded={this.state.open}
          bsStyle="info"
        >
          <Panel.Body collapsible>
            <div style={{maxHeight: '350px', overflowY: 'auto'}}>
              {rows &&
                <BootstrapTable
                  data={rows}
                  remote={true}
                  pagination={true}
                  /*hover*/
                  striped
                  trClassName={this.trClassFormat}
                  fetchInfo={{dataTotalSize: this.state.pagination.totalCount}}
                  options={{
                    onPageChange: this.onPageChange.bind(this),
                    onSortChange: this.onSortChange.bind(this),
                    noDataText: this.state.isLoading ? __('loading_data') : __('NoDataFound'),
                    hideSizePerPage: false,
                    firstPage: 'First',
                    lastPage: 'Last',
                    sizePerPageList: config.pageSizeOptions,
                    page: this.state.pagination.pageNumber,
                    sizePerPage: this.state.pagination.pageSize,
                  }}
                >
                  <TableHeaderColumn
                    hidden={true}
                    isKey={true}
                    dataField="id"
                  >
                    {__('Id')}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="orderTypeId"
                    width="250"
                  >
                    {__('Order Type ID')}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataFormat={(cell, row) => {
                      return getFlowName(row.flowId, row.flowVersion);
                    }}
                  >
                    {__('Flow Name')}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="flowId"
                  >
                    {__('Flow Id')}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="flow.version"
                    dataFormat={(cell, row) => {
                      return row.flowVersion;
                    }}
                  >
                    {__('Flow Version')}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="from"
                    dataFormat={(cell, row) => {
                      return row.from && moment(row.from).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat + ':ss');
                    }}
                  >
                    {__('From Date/Time')}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="to"
                    dataFormat={(cell, row) => {
                      if (!row.to) {
                        return __('Currently active');
                      }

                      return row.to && moment(row.to).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat + ':ss');
                    }}
                  >
                    {__('To Date/Time')}
                  </TableHeaderColumn>
                </BootstrapTable>

              }


            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

export default OrderTypeHistoryButton;
