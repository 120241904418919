import {api} from "./index";
import {ERROR} from "components/dist/Utils/LoggerUtils";

export const fetchAttributes = async (): Promise<Attribute[]> => {
  return api.get('configurations/attributes')
    .then((response) => response.data)
}

export const fetchAttributesTree = async (): Promise<Record<string, Array<AttributeNode<Attribute>>>> => {
  return api.get("configurations/attributes/tree")
    .then((response) =>  response.data);
}

export const fetchAttributeById = async (attributeId: string): Promise<Attribute> =>
  api.get(`configurations/attributes/${attributeId}`)
    .then((response) => response.data)
    .catch((error) => {
      ERROR(error)
    })
