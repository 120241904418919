import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Glyphicon from '@strongdm/glyphicon'

import { __ } from '../../../utils/translationUtils'

class MessageOptions extends React.Component{

  static propTypes = {
    message: PropTypes.object,
    guiUser: PropTypes.object,
    editModeOn: PropTypes.bool,
    updateMessage: PropTypes.func,
    closeEditModeOn: PropTypes.func,
    openEditModeOn: PropTypes.func,
    getCommentHistory: PropTypes.func,
    deleteMessage: PropTypes.func
  }

  constructor(props) {
    super(props)
  }

  renderEditOptions(){
    const save_tip = (<Tooltip id="option-save-id">{__('Save Updates')}</Tooltip>);
    const close_edit_tip = (<Tooltip id="option-close-edit-id">{__('Close Edit')}</Tooltip>);
    return (
      <div className="chat-options-button-group">
        <OverlayTrigger placement="bottom" overlay={save_tip}>
          <Glyphicon glyph={'ok'} onClick={this.props.updateMessage}/>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={close_edit_tip}>
          <Glyphicon glyph={'remove'} onClick={this.props.closeEditModeOn}/>
        </OverlayTrigger>
      </div>
    );
  }

  renderOptions(){

    const {message, guiUser} = this.props;
    const edit_tip = (<Tooltip id="option-edit-id">{__('Edit Comment')}</Tooltip>);
    const history_tip = (<Tooltip id="option-history-id">{__('Comment History')}</Tooltip>);
    const delete_tip = (<Tooltip id="option-delete-id">{__('Delete Comment')}</Tooltip>);

    const creator = guiUser.id === message.creator.id;

    const { CAN_DELETE_COMMENT, CAN_EDIT_COMMENT } = this.props.permissions

    return(
      <div className="chat-options-button-group">
        {creator && CAN_EDIT_COMMENT && <OverlayTrigger placement="bottom" overlay={edit_tip}>
          <Glyphicon glyph={'pencil'} onClick={this.props.openEditModeOn}/>
        </OverlayTrigger>}
      {message && message.edited && <OverlayTrigger placement="bottom" overlay={history_tip}>
        <Glyphicon glyph={'hourglass'} onClick={this.props.getCommentHistory}/>
      </OverlayTrigger> || <div/>}
      {creator && CAN_DELETE_COMMENT && <OverlayTrigger placement="bottom" overlay={delete_tip}>
        <Glyphicon glyph={'trash'} onClick={this.props.deleteMessage}/>
      </OverlayTrigger>}
    </div>
    )
  }


  render() {
    const {editModeOn} = this.props;

    if (editModeOn){
      return this.renderEditOptions();
    } else {
      return this.renderOptions();
    }
  }
}

export default MessageOptions
