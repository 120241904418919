import React from 'react'
import * as S from '../styles'
import { Icon } from '@ant-design/compatible'
const { COMPANY, ENTITY, USER } = global.constants.entityTypes

export default (companies) => {
  return companies?.map(company => ({...company, totUsers: company.children.reduce((acc, entity) => {
      if (!entity?.users.length) return acc;
      return Number(acc + entity.users.length)
    }, 0)}))
    .filter(company=> company.totUsers !== 0)
    .sort((a,b) => {
      return a.name.localeCompare(b.name)
    })
    .map(company => ({
    title: (
      <S.TreeNode> <Icon type="cluster" /> {company.name} - {company.shortName} <small>{company.totUsers}</small>
      </S.TreeNode>
    ),
    searchValue: `${company.name} (${company.shortName})`,
    key: JSON.stringify({
      type: COMPANY,
      id: company.id,
      key: company.id
    }),
    isLeaf: company.totUsers === 0,
    children:  company.children?.map((entity) => ({
      title: <S.TreeNode><Icon type={entity.type === 'organisation' ? 'cluster' : 'team'} /> {entity.name} <small>({entity.users?.length})</small></S.TreeNode>,
      searchValue: entity.name,
      key: JSON.stringify({
        type: ENTITY,
        id: entity.id,
        key: `${company.id} ${entity.id}`
      }),
      children: entity.users?.map((user) => ({
        title: <S.TreeNode> <Icon type="user" /> {user.firstName} {user.lastName} - {user.username} ({entity.shortName}) </S.TreeNode>,
        searchValue: `${user.firstName} ${user.lastName} (${user.username})`,
        key: JSON.stringify({
          type: USER,
          id: user.id,
          key: `${company.id} ${entity.id} ${user.id}`,
          user,
          userId: user.id,
          entity,
          entityId: entity.id,
        })
      }))
    }))
  }))
}
