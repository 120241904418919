import React from 'react'

import { Col, Row } from "antd";

import * as C from './constants'
import * as S from './styles'

export default (props) => {

  const {
    columns = C.defaultColumns,
    margin = C.defaultMargin,
    items = [],
  } = props

  const span = !(C.maxColumns % columns) ? C.maxColumns / columns : C.defaultColumns

  return (
    <Row gutter={margin}>
      { items.map((item, index) => (
        <Col span={span} key={`grid-item-${index}`}>
          <S.CellContent>
            {item}
          </S.CellContent>
        </Col>
      ))}
    </Row>
  )
}
