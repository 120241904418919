import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ExtendedFileUploader from './fileVersions/FilePayload'
import ReducedFileUploader from './fileVersions/Reduced'
import FileUploaderService from './services/fileUploaderService'
import { FILE_VERSIONS_ENUM } from './constant'
import { mapOldFileVersionToNew } from "../DooOrderPage/Step/customRender/FileUploader/utils/payload";

const { OLD_FILE_VERSION, NEW_FILE_VERSION, REDUCED_VERSION } = FILE_VERSIONS_ENUM
function FileUploader({ controlData: field, reduced, attribute, ...props }) {
  const fileVersion = useMemo(() => FileUploaderService.computeFileVersion(field, reduced), [field, reduced])

  let newField = { ...field }

  if (fileVersion === OLD_FILE_VERSION) newField = mapOldFileVersionToNew(field)

  switch (fileVersion) {
    case OLD_FILE_VERSION:
    case NEW_FILE_VERSION:
      return (
        <div className='file-uploader-container'>
          <ExtendedFileUploader {...props} field={{title: attribute ? attribute.propertyLabel : 'No title', ...newField}} />
        </div>
      )
    case REDUCED_VERSION:
      return (
        <div className='file-uploader-container'>
          <ReducedFileUploader {...props} field={{title: attribute ? attribute.propertyLabel : 'No title', ...newField}} />
        </div>
      )
    default:
      return null
  }
}

FileUploader.propTypes = {
  controlData: PropTypes.shape({
    id: PropTypes.string,
    source: PropTypes.string,
    readOnly: PropTypes.bool,
    controllerOptions: PropTypes.object
  }).isRequired,
  reduced: PropTypes.bool
}

export default FileUploader
