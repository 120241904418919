import styled from 'styled-components'

export const Gravatar = styled.div`
  background-color: #fafafa;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  font-size: 1.0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9e9e9;
  overflow: hidden
`

export const Img = styled.img`
  width: 100%;
  display: block;
`
