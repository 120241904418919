// User
export const USER_SIGN_UP_START = 'USER_SIGN_UP_START';
export const USER_SIGN_UP_FINISHED = 'USER_SIGN_UP_FINISHED';
export const USER_SIGN_UP_ERROR = 'USER_SIGN_UP_ERROR';
export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const TOKEN_REFRESH_START = 'TOKEN_REFRESH_START';
export const TOKEN_REFRESH_END = 'TOKEN_REFRESH_END';
export const USER_GET_DATA_ERROR = 'USER_GET_DATA_ERROR';
export const USER_GOT_DATA = 'USER_GOT_DATA';
export const USER_GOT_REPORTS = 'USER_GOT_REPORTS';
export const USER_GOT_ORDERTYPES = 'USER_GOT_ORDERTYPES';
export const USER_GOT_VIEWS = 'USER_GOT_VIEWS';
export const USER_DELETED = 'USER_DELETED';
export const USER_GOT_RIGHTS = 'USER_GOT_RIGHTS';
export const USER_GOT_ORDERGROUPS = 'USER_GOT_ORDERGROUPS';
export const USER_FORGOT_PASSWORD_REQUEST_START = 'USER_FORGOT_PASSWORD_REQUEST_START';
export const USER_FORGOT_PASSWORD_REQUEST_FINISHED = 'USER_FORGOT_PASSWORD_REQUEST_FINISHED';
export const USER_FORGOT_PASSWORD_REQUEST_ERROR = 'USER_FORGOT_PASSWORD_REQUEST_ERROR';

// ui
export const LANGUAGE_SELECTED = 'LANGUAGE_SELECTED';
export const LANGUAGE_AVAILABLE = 'LANGUAGE_AVAILABLE';

// Main Menu
export const MENU_SELECTED = 'MENU_SELECTED';
export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';

// Entities
export const GOT_ORGANISATIONS_DATA = 'GOT_ORGANISATIONS_DATA';
export const GOT_ORGANISATIONS_ERROR = 'GOT_ORGANISATIONS_ERROR';
export const GETTING_ENTITIES_DATA = 'GETTING_ENTITIES_DATA';
export const ENTITY_DELETED = 'ENTITY_DELETED';
export const ENTITY_DELETION_ERROR = 'ENTITY_DELETION_ERROR';
export const ENTITY_LIST_UPDATED = 'ENTITY_LIST_UPDATED';

// ORDERS
export const ORDERS_GOT_DATA = 'ORDERS_GOT_DATA';
export const ORDERS_GET_DATA_ERROR = 'ORDERS_GET_DATA_ERROR';
export const SNAPSHOT_VIEW = 'SNAPSHOT_VIEW';

// REPORTS
export const REPORT_IMPORTED = 'REPORT_IMPORTED';
export const REPORT_RESET_IMPORT = 'REPORT_RESET_IMPORT';
export const REPORTS_GOT_DATA = 'REPORTS_GOT_DATA';
export const REPORTS_GET_DATA_ERROR = 'REPORTS_GET_DATA_ERROR';
export const ENUMVALUES_GOT_DATA = 'ENUMVALUES_GOT_DATA';

// VIEWS
export const VIEWS_GOT_DATA = 'VIEW_GOT_DATA';
export const VIEWS_GET_DATA_ERROR = 'VIEW_GET_DATA_ERROR';

// OrderGroups
export const ORDERGROUP_DELETED = 'ORDERGROUP_DELETED';
export const ORDERGROUP_GOT_DATA = 'ORDERGROUP_GOT_DATA';
export const ORDERGROUP_GET_DATA_ERROR = 'ORDERGROUP_GET_DATA_ERROR';

// OrderTypes
export const ORDERTYPE_DELETED = 'ORDERTYPE_DELETED';
export const ORDERTYPE_GOT_DATA = 'ORDERTYPE_GOT_DATA';
export const ORDERTYPE_GET_DATA_ERROR = 'ORDERTYPE_GET_DATA_ERROR';

// Remote systems
export const REMOTE_SYSTEMS_GOT = 'REMOTE_SYSTEMS_GOT';
export const GOT_SYSTEM_SETTINGS = 'GOT_SYSTEM_SETTINGS';
export const GOT_INSTANCE_SETTINGS = 'GOT_INSTANCE_SETTINGS';

export const GOT_ATTRIBUTES_TREE = 'GOT_ATTRIBUTES_TREE';
export const GOT_ATTRIBUTES = 'GOT_ATTRIBUTES';
export const GOT_ATTRIBUTES_LIST = 'GOT_ATTRIBUTES_LIST';

// Search History
export const SET_LAST_REMOTE_OBJ_SEARCH = 'SET_LAST_REMOTE_OBJ_SEARCH';
