import React from 'react';
import {Link} from 'react-router-dom';
import i18next from 'i18next';
import {Trans} from 'react-i18next';

const NotFoundPage = () => {
  return (
    <div style={{"textAlign": "center"}}>
			<br/><br/>
      <h4><Trans i18nKey="PageNotFound"/></h4>
      <Link to="/">&laquo; {i18next.t('back_to_homepage')}</Link>
    </div>
  );
};

export default NotFoundPage;
