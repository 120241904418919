
export const Heatmap = {
  padding: "1rem",
  height: "75vh",
  width: "100%"
};

export const Popup = {
  position: "relative"
};

export const PopupList = {
  "overflow-y": "scroll",
  "max-height": "35rem",
  padding: ".5rem",
  display: "grid",
  "gap": "1rem",
  "grid-template-columns": "1fr 1fr"
};

export const PopupFooter = {
  display: "flex",
  "justify-content": "flex-end",
  "align-items": "center",
  "background": "white",
  "margin-top": ".5rem",
  "cursor": "pointer"
};
