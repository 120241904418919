import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {__} from "../../../utils/translationUtils";
import Glyphicon from "@strongdm/glyphicon";
import React from "react";

const OrderOverviewTrigger = ({order, openOrderOverview}) => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id="order-tooltip">{__('Order Overview')}</Tooltip>}
  >
    <div onClick={openOrderOverview}>
      <Glyphicon glyph="list"/>
      {
        order && <span> {`${__(order.title)} / Order ID: (${__(order.externalOrderId)})`}</span>
      }
    </div>
  </OverlayTrigger>
)

export default OrderOverviewTrigger
