import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import objectAssign from 'object-assign'
import { formValueSelector, change } from 'redux-form'
import { stringify } from 'query-string'
import { Tooltip } from 'antd'
import { Icon } from '@ant-design/compatible'
import { api } from '../providers/ApiProvider'
import { showMessage, searchToObject } from '../utils/appHelper'
import { hashHistory } from '../providers/HistoryProvider'
import { deleteTemplate, restoreTemplate } from '../providers/ReduxProvider/actions/templateActions'
import { __ } from '../utils/translationUtils'

const BasicSearchForm = require('../components/BasicSearchForm').default
const loadingBar = require('nprogress')

class RightTemplatesPage extends React.Component {
  static propTypes = {
    change: PropTypes.func,
    restoreTemplate: PropTypes.func,
    deleteTemplate: PropTypes.func,
    location: PropTypes.object,
    q: PropTypes.string
  };

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isLoading: false,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0
      },
      sort: '',
      selectedOrders: [],
      assignModal: {
        open: false,
        orders: []
      },
      deleteModal: {
        open: false,
        model: {
          name: '',
          shortName: 0
        }
      },
      restoreModal: {
        open: false,
        model: {
          name: '',
          shortName: 0
        }
      }
    }
  }

  componentDidMount() {
    // initial results
    this.hashHistoryListener = hashHistory.listen((location) => {
      const searchParams = searchToObject(this.props.location.search)
      this.props.change('basicSearchForm', 'q', searchParams.q || '')
      if (location.action === 'POP') {
        this.getData()
      }
    })

    // get initial data
    this.getData()
  }

  componentWillUnmount() {
    this.hashHistoryListener()
  }

  getParams = () => objectAssign({}, {
    sort: this.state.sort || searchToObject(this.props.location.search).sort,
    pageNumber: (parseInt(this.state.pagination.pageNumber) > -1) ? parseInt(this.state.pagination.pageNumber) : searchToObject(this.props.location.search).pageNumber,
    pageSize: this.state.pagination.pageSize || searchToObject(this.props.location.search).pageSize,
    // withChildren: true,
    q: this.props.q || searchToObject(this.props.location.search).q
  })

  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({ assignModal: { ...this.state.assignModal, open: false } })
  }

  updateHash = () => {
    const params = this.getParams()
    // update url params
    hashHistory.push({
      ...this.props.location,
      search: stringify(params)
    })
  }

  search = () => {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: this.state.pagination.pageSize,
          pageNumber: 1
        }
      )
    })
    this.getData()
  }

  getData = () => {
    this.setState({ isLoading: true })
    loadingBar.start()
    return api.get('/rights/templates/', { params: { ...this.getParams.bind(this)(), pageNumber: this.getParams().pageNumber } })
      .then(
        (response) => {
          this.setState({
            data: response.data,
            isLoading: false,
            pagination: objectAssign(
              this.state.pagination, {
                totalCount: parseInt(response.headers['x-total-count']),
                pageSize: parseInt(response.headers['x-page-size']),
                pageNumber: parseInt(response.headers['x-page'])
              }
            )
          })
          loadingBar.done()
          this.updateHash()
        },
        (error) => {
          showMessage('error', error)
        }
      )
  }

  onSortChange(sortName, sortOrder) {
    this.setState({ sort: ((sortOrder === 'asc') ? '' : '-') + sortName }, function () {
      this.getData()
    })
  }

  confirmRestore(model) {
    this.setState({ restoreModal: { open: true, model } })
  }

  handleRestore() {
    this.props.restoreTemplate(this.state.restoreModal.model.shortName).then(() => {
      this.getData()
    })
    // Close modal:
    this.closeRestoreModal()
  }

  closeRestoreModal() {
    this.setState({ restoreModal: { ...this.state.restoreModal, open: false } })
  }

  confirmDelete(model) {
    this.setState({ deleteModal: { open: true, model } })
  }

  handledelete() {
    this.props.deleteTemplate(this.state.deleteModal.model.shortName).then(() => {
      this.getData()
    })
    // Close modal:
    this.closeDeleteModal()
  }

  closeDeleteModal() {
    this.setState({ deleteModal: { ...this.state.deleteModal, open: false } })
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      {
        pagination: objectAssign(
          this.state.pagination, { pageNumber: page, pageSize: sizePerPage }
        )
      },
      function () {
        this.updateHash()
      }
    )
  }

  onSizePerPageList(sizePerPage) {
    this.setState({
      pagination: objectAssign(this.state.pagination,
        { pageSize: sizePerPage })
    })
  }

  handleRowSelect() {
    this.setState({ selectedOrders: this.refs.ordersTable && this.refs.ordersTable.state.selectedRowKeys || [] })
  }

  render() {
    function linkFormater(cell, row) {
      return <Link className="stronger" to={`/right-templates/${row.shortName}/edit`}>{row.shortName}</Link>
    }

    function actionsFormater(cell, row) {
      return (
        <div>
          {row.status !== 'deleted'
          && (
            <div style={{ display: 'flex' }}>
              <Tooltip title={__('delete')}><div className="btn-no-padding btn-std" onClick={() => this.confirmDelete.bind(this)(row)}><Icon className="icon-delete" type="delete" theme="outlined" /></div></Tooltip>
            </div>
          )}
          {row.status === 'deleted'
          && (
            <Tooltip title={__('Restore')}><div className="btn-std btn-no-padding" onClick={() => this.confirmRestore.bind(this)(row)}><Icon className="icon-std" type="retweet" theme="outlined" /></div></Tooltip>
          )}
        </div>
      )
    }

    return (
      <div id="main-content">

        <h1 className="sticky">
          <div className="container">
            {__('rightTemplatesTitle')}
          </div>
        </h1>

        <div className="container">

          <div className="row">
            <div className="col-md-8 col-md-offset-2">

              <Link className="btn btn-success col-sm-4 col-xs-12 gutter-bottom" to="/right-templates/create">
                <Glyphicon glyph="plus" /> {__('Create Template')}
              </Link>

              <div className="col-sm-8 col-xs-12">
                <BasicSearchForm
                  isLoading={this.state.isLoading}
                  itemType="Templates"
                  initialValues={{ q: searchToObject(this.props.location.search).q }}
                  onSubmit={this.search.bind(this)}
                />
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              {this.state.data
              && (
                <BootstrapTable
                  data={this.state.data}
                  remote
                  pagination={false}
                  hover
                  striped
                  fetchInfo={{ dataTotalSize: this.state.pagination.totalCount }}
                  options={{
                    onSortChange: this.onSortChange.bind(this),
                    noDataText: this.state.isLoading ? 'Loading data...' : 'No data found',
                    // prePage: '&larr;',
                    // nextPage: '&rarr;',
                    hideSizePerPage: false,
                    firstPage: 'First',
                    lastPage: 'Last',
                    sizePerPageList: [10, 25, 50, 100],
                    paginationShowsTotal: true,
                    page: this.state.pagination.pageNumber,
                    sizePerPage: this.state.pagination.pageSize,
                    onPageChange: this.onPageChange
                  }}
                >

                  <TableHeaderColumn
                    isKey
                    dataField="shortName"
                    dataFormat={linkFormater.bind(this)}
                    dataSort
                  >
                    {__('Template Short Name')}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="name"
                    dataSort
                  >
                    {__('Template Name')}
                  </TableHeaderColumn>

                  <TableHeaderColumn dataFormat={actionsFormater.bind(this)} width="65px" />

                </BootstrapTable>
              )}

            </div>

          </div>
        </div>

        <Modal
          backdrop="static"
          show={this.state.deleteModal.open}
          onHide={this.closeDeleteModal.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{__('Confirm Deletion')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{__('Are you sure you want to delete')} "<strong>{this.state.deleteModal.model.name}</strong>"?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeDeleteModal.bind(this)}>
              {__('Back')}
            </Button>
            <Button onClick={this.handledelete.bind(this)} bsStyle="danger">
              {__('Delete')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          backdrop="static"
          show={this.state.restoreModal.open}
          onHide={this.closeRestoreModal.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{__('Confirm Restore')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{__('Are you sure you want to restore')} "<strong>{this.state.deleteModal.model.name}</strong>"?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeRestoreModal.bind(this)}>
              {__('Back')}
            </Button>
            <Button onClick={this.handleRestore.bind(this)} bsStyle="success">
              {__('Restore')}
            </Button>
          </Modal.Footer>
        </Modal>

      </div>

    )
  }
}

const selector = formValueSelector('basicSearchForm')
export default connect(
  (state) => ({ q: selector(state, 'q') }),
  { deleteTemplate, restoreTemplate, change }
)(RightTemplatesPage)
