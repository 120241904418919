import {connect} from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import {DropdownButton, MenuItem} from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import objectAssign from 'object-assign'
import _ from 'lodash'
import {change, formValueSelector} from 'redux-form'
import moment from 'moment'
import {stringify} from 'query-string'
import {Icon} from '@ant-design/compatible'
import {Tooltip} from 'antd'
import {api} from '../providers/ApiProvider'
import * as config from '../constants/globalConfiguration'
import {
  addAsterisks,
  checkUserRights,
  getInitialPageSize,
  renderResultInfo,
  searchToObject,
  setInitialPageSize,
  showMessage
} from '../utils/appHelper'
import {hashHistory} from '../providers/HistoryProvider'
import {__} from '../utils/translationUtils'
import {ScrollableListView, StickyScrollableTable} from "../providers/StyleProvider/styles";

const loadingBar = require('nprogress')
const BasicSearchForm = require('../components/BasicSearchForm').default

const initialState = {
  templates: [],
  data: [],
  isLoading: false,
  pagination: {
    pageNumber: 1,
    pageSize: getInitialPageSize('usersList'),
    totalCount: 0
  },
  showDeleted: false,
  sort: 'username'
}

class UsersPage extends React.Component {
  static propTypes = {
    change: PropTypes.func,
    location: PropTypes.object,
    q: PropTypes.string,
    user: PropTypes.object,
    showDeleted: PropTypes.bool
  };

  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    // initial results
    this.getTemplates()
    this.setQueryToState(this.updateHash.bind(this))
  }

  setQueryToState(cb) {
    const sq = searchToObject(this.props.location.search)
    this.props.change('basicSearchForm', 'q', sq.q)
    this.props.change('basicSearchForm', 'showDeleted', sq.showDeleted == 'true')
    this.setState({
      sort: sq.sort || this.state.sort,
      pagination: objectAssign(this.state.pagination,
        {
          pageNumber: parseInt(sq.pageNumber) || this.state.pagination.pageNumber,
          pageSize: parseInt(sq.pageSize) || this.state.pagination.pageSize
        })
    }, () => {
      if (typeof cb !== 'undefined') {
        cb()
      }
    })
  }

  getParams() {
    const params = {...searchToObject(this.props.location.search)}
    return objectAssign({}, {
      sort: this.state.sort || params.sort,
      pageNumber: (parseInt(this.state.pagination.pageNumber) > -1) ? parseInt(this.state.pagination.pageNumber) : params.pageNumber,
      pageSize: this.state.pagination.pageSize || params.pageSize,
      q: this.props.q ? addAsterisks(this.props.q) : undefined
    })
  }

  updateHash() {
    // update url params
    hashHistory.push({
      ...this.props.location,
      search: stringify({
        pageNumber: this.state.pagination.pageNumber,
        pageSize: this.state.pagination.pageSize,
        sort: this.state.sort,
        q: this.state.q,
        showDeleted: this.props.showDeleted
      })
    }, this.getData())
  }

  search() {
    this.setState({
      q: this.props.q,
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: this.state.pagination.pageSize,
          pageNumber: 1
        }
      )
    }, () => {
      this.updateHash()
    })
  }

  getTemplates = () => {
    this.setState({isLoading: true})
    loadingBar.start()

    return api.get('/rights/templates/available')
      .then(
        (response) => {
          this.setState({templates: response.data})
        },
        () => {
          loadingBar.done()
          this.updateHash()
        }
      )
  }

  getData = () => {
    this.setState({isLoading: true})
    loadingBar.start()
    const params = {...this.getParams.bind(this)(), pageNumber: this.getParams().pageNumber}

    // Add status depending on the "showDeleted" checkbox.
    params.status = this.props.showDeleted ? 'disabled,deleted,enabled,reserved' : 'disabled,enabled,reserved'

    return api.get('/users/', {params})
      .then(
        (response) => {
          this.setState({
            data: response.data,
            isLoading: false,
            pagination: objectAssign(
              this.state.pagination, {
                totalCount: parseInt(response.headers['x-total-count']),
                pageSize: parseInt(response.headers['x-page-size']),
                pageNumber: parseInt(response.headers['x-page'])
              }
            )
          }, () => {
            loadingBar.done()
          })
        },
        (error) => {
          this.setState({isLoading: false}, () => {
            loadingBar.done()
          })
        }
      )
  }

  onSortChange(sortName, sortOrder) {
    this.setState({sort: ((sortOrder === 'asc') ? '' : '-') + sortName}, function () {
      this.updateHash()
    })
  }

  onPageChange(page) {
    this.setState({
        pagination: objectAssign(
          this.state.pagination, {pageNumber: page}
        )
      },
      () => {
        this.updateHash()
      })
  }

  setPageSize(val) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: val,
          pageNumber: 1
        }
      )
    }, () => {
      this.updateHash()
      setInitialPageSize('usersList', val)
    })
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.setPageSize.bind(this)}
        bsStyle="link"
        title={this.state.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config.pageSizeOptions.map((val, i) => <MenuItem key={i} eventKey={val}>{val}</MenuItem>)}
      </DropdownButton>
    )
  }

  trClassFormat(row) {
    return row.status // return class name.
  }

  extractTemplate(shortName) {
    return _.find(this.state.templates, (t) => t.shortName == shortName)
  }

  render() {
    const {rightMappings} = global.constants

    // Check user rights
    if (this.props.user && this.props.user.rights) {
      if (!checkUserRights(this.props.user.rights, rightMappings.CAN_CREATE_USER)) {
        hashHistory.push('/')
        showMessage('error', __('NoAccessForThisPage'))
      }
    }

    let userRights = []
    if (this.props.user) {
      userRights = this.props.user.rights
    }

    function linkFormater(cell, row) {
      return <Link className="stronger" to={`/users/${row.id}`}>{row.username}</Link>
    }

    function templatesFormater(cell) {
      const templates = cell.split(', ')

      templates.map((t, i) => {
        if (typeof this.extractTemplate(t) !== 'undefined') {
          templates[i] = this.extractTemplate(t).name
        }
      })

      return (
        <div>
          {templates.join(', ')}
        </div>
      )
    }

    function actionsFormater(cell, row) {
      return (
        <div className="actions">
          {
            row.status !== 'deleted'
            && (
              <div style={{display: 'flex'}}>
                <Tooltip title={__('calendar')}><Link to={`/users/${row.id}/calendar`}>
                  <div className="btn-no-padding btn-std"><Icon className="icon-std" type="calendar" theme="outlined"/>
                  </div>
                </Link></Tooltip>
              </div>
            )
          }
        </div>
      )
    }

    return (
      <ScrollableListView id="main-content">

        <h1 className="sticky">
          <div className="container">
            {__('usersManagementTitle')}
          </div>
        </h1>

        <div className="container">

          <div className="row">

            <div className="col-lg-10 col-lg-offset-1 col-sm-12">
              {checkUserRights(userRights, rightMappings.CAN_CREATE_USER)
                && (
                  <Link className="btn btn-success col-xs-12 col-sm-3 gutter-bottom" to="/users/create"><Glyphicon
                    glyph="plus"/>
                    {__('Create User')}
                  </Link>
                )}

              <div className="col-xs-12 col-sm-6 col-lg-4">
                <BasicSearchForm
                  isLoading={this.state.isLoading}
                  itemType="user"
                  initialValues={{
                    q: searchToObject(this.props.location.search).q,
                    showDeleted: false
                  }}
                  onSubmit={this.search.bind(this)}
                />
              </div>

              <div className=" col-xs-12 col-sm-3 col-lg-4  pull-right text-right">
                {checkUserRights(userRights, rightMappings.CAN_DELETE_USER)
                  && (
                    <label>
                      <input
                        type="checkbox"
                        checked={this.props.showDeleted}
                        style={{marginRight: '8px'}}
                        onChange={(event) => {
                          this.props.change(
                            'basicSearchForm',
                            'showDeleted',
                            event.target.checked
                          )
                          setTimeout(() => {
                              this.updateHash()
                            },
                            500)
                        }}
                      />
                      {__('Show deleted users')}
                    </label>
                  )}
              </div>

            </div>

          </div>
          {this.state.data
            && (
              <StickyScrollableTable
                data={this.state.data}
                remote
                pagination
                /* hover */
                striped
                trClassName={this.trClassFormat}
                fetchInfo={{dataTotalSize: this.state.pagination.totalCount}}
                options={{
                  onPageChange: this.onPageChange.bind(this),
                  onSortChange: this.onSortChange.bind(this),
                  noDataText: this.state.isLoading ? __('loading_data') : __('NoDataFound'),
                  hideSizePerPage: false,
                  firstPage: 'First',
                  lastPage: 'Last',
                  sizePerPageList: config.pageSizeOptions,
                  paginationShowsTotal: renderResultInfo.bind(this),
                  page: this.state.pagination.pageNumber,
                  sizePerPage: this.state.pagination.pageSize
                }}
              >
                <TableHeaderColumn
                  hidden
                  isKey
                  dataField="id"
                >
                  {__('Id')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="username"
                  dataFormat={linkFormater.bind(this)}
                  dataSort
                >
                  {__('User Name')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="lastName"
                  dataFormat={(cell, row) => `${row.lastName} ${row.firstName}`}
                  dataSort
                >
                  {__('Full Name')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="matchingTemplates"
                  dataFormat={templatesFormater.bind(this)}
                  dataSort
                >
                  {__('Roles')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="rootOrganisations.name"
                  dataSort
                  dataFormat={(cell, row) => {
                    const list = row.rootOrganisations.map((org) => (
                      <span key={org.id}>
                      <Link to={`/redirectToEntity/${org.id}`}>
                        {org.name}
                      </Link> {' '}
                    </span>
                    ))
                    return <div>{list}</div>
                  }}
                >
                  {__('Company')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="lastUpdateDatetime"
                  dataSort
                  dataFormat={(val) => moment(val).format(`${config.appDefaults.dateTimeFormat}`)}
                >
                  {__('LastModifiedDatetime')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="id"
                  dataFormat={actionsFormater.bind(this)}
                  width="110"
                  hidden={!checkUserRights(userRights, rightMappings.CAN_DELETE_USER)}
                />

              </StickyScrollableTable>
            )}
        </div>

      </ScrollableListView>
    )
  }
}

const selector = formValueSelector('basicSearchForm')
export default connect(
  (state) => ({
    user: state.user.data,
    q: selector(state, 'q'),
    showDeleted: selector(state, 'showDeleted')
  }),
  {change}
)(UsersPage)
