import React from "react";
import {Button, FlexInlineGroup} from "components";
import {__} from '../utils/translationUtils'

export const SmartFooter = (props) => {
  const {onReset, resetLabel, onSubmit, submitLabel} = props

  return (
    <FlexInlineGroup
      fitParent
    >
      <div>
        {
          onReset &&
          <Button
            type='text'
            onClick={(e) => onReset?.(e)}
            title={resetLabel || __('Reset')}
          />
        }
      </div>
      <div>
        {
          onSubmit &&
          <Button
            type='filled'
            onClick={(e) => onSubmit?.(e)}
            title={submitLabel || __('Apply')}
          />
        }
      </div>
    </FlexInlineGroup>
  )
}
