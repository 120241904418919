import React, { forwardRef, LegacyRef, useEffect, useImperativeHandle, useRef } from "react";
import { ImageEditorComponent } from "@syncfusion/ej2-react-image-editor";
import { useResizeDetector } from "react-resize-detector";

import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-image-editor/styles/material.css";
import {ItemModel} from "@syncfusion/ej2-navigations/src/toolbar/toolbar-model";

type ImageEditorProps = {
  fileDefinition: {
    name: string,
    url: string,
    type: string
  },
  imageDataURL: string
  saveFile: ({ file }: { file: File }, replace?: boolean) => Promise<void>
  close?: () => void
  options: {
    open?: boolean
  }
};

const ImageEditor = forwardRef<ImageEditorComponent, ImageEditorProps>(({ imageDataURL, fileDefinition, saveFile, close, options = {}}, ref) => {
  const internalRef = useRef<ImageEditorComponent>(null);
  const { width, height, ref: resizeRef } = useResizeDetector();
  useImperativeHandle(ref, () => internalRef.current as ImageEditorComponent);

  const {
    open = true
  } = options

  const toolbar = [
    "Crop",
    "Transform",
    "Annotate",
    "ZoomIn",
    "ZoomOut",
    ...(open ? ["Open"] : []),
    "Reset",
    "Pan",
    "Undo",
    "Redo",
    "Finetune",
    "Filter",
    "Frame",
    "Resize",
    {
      align: "Right",
      cssClass: "top-icon e-save",
      id: "ej2-image-editor_0_save_custom",
      overflow: "None",
      prefixIcon : "e-icons e-btn-save",
      showAlwaysInPopup: false,
      showTextOn: "Both",
      suffixIcon: "",
      tabIndex: -1,
      click: async () => {
        if (internalRef.current) {
          const imgData = internalRef.current.getImageData();
          imageDataToFile(imgData, fileDefinition.name, fileDefinition.type).then((imgFile) => {
            saveFile({ file: imgFile }, true)
          })
        }
      },
      text: "",
      tooltipText: "Save",
      type: "Button",
      visible: true,
      width: "auto",
    } as ItemModel,
    ...(close ? [{
      align: "Right",
      cssClass: "top-icon e-save",
      id: "ej2-image-editor_0_close_custom",
      overflow: "None",
      prefixIcon : "e-icons e-close",
      showAlwaysInPopup: false,
      showTextOn: "Both",
      suffixIcon: "",
      tabIndex: -1,
      click: async () => { close?.() },
      text: "",
      tooltipText: "Close",
      type: "Button",
      visible: true,
      width: "auto",
    } as ItemModel,] : []),
  ];

  useEffect(() => {
     if(internalRef.current && width && height){
       internalRef.current.height = `${height}px`
       internalRef.current.width = `${width}px`
     }
  }, [width, height]);

  const imageEditorCreated = () => {
    fileDefinition && imageDataURL && internalRef.current?.open(imageDataURL)
  };

  return (
    <div ref={resizeRef} className="img-editor-wrapper">
      <ImageEditorComponent
        ref={internalRef as LegacyRef<ImageEditorComponent>}
        toolbar={toolbar}
        created={imageEditorCreated}
      />
    </div>
  );
});

/**
 * Converts a Blob to Data URL.
 * @param blob - The Blob to convert.
 * @returns A promise that resolves to the Data URL.
 */
export const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

/**
 * Converts ImageData to a Blob.
 * @param imageData - The ImageData to convert.
 * @param mimeType - The desired MIME type (e.g., 'image/png').
 * @returns A promise that resolves to a Blob.
 */
const imageDataToBlob = async (imageData: ImageData, mimeType: string = 'image/png'): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = imageData.width;
    canvas.height = imageData.height;

    // Get the 2D rendering context
    const context = canvas.getContext('2d');
    if (!context) {
      return reject(new Error('Failed to get canvas context'));
    }

    // Draw the ImageData onto the canvas
    context.putImageData(imageData, 0, 0);

    // Convert the canvas content to a Blob
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Failed to convert canvas to Blob'));
      }
    }, mimeType);
  });
}

/**
 * Converts ImageData to a File.
 * @param imageData - The ImageData to convert.
 * @param fileName - The desired file name.
 * @param mimeType - The desired MIME type (e.g., 'image/png').
 * @returns A promise that resolves to a File.
 */
const imageDataToFile = async (imageData: ImageData, fileName: string, mimeType: string = 'image/png'): Promise<File> => {
  return imageDataToBlob(imageData, mimeType).then((blob) => {
    return new File([blob], fileName, { type: mimeType, lastModified: Date.now() });
  });
}

export default ImageEditor;

