import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon } from '@ant-design/compatible'
import EditOrderModal from './EditOrderModal'
import { __ } from '../utils/translationUtils'

const Full = {
  width: '100%',
  paddingLeft: '1rem'
}

class EditOrderButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editOrderModal: { open: false } }
  }

  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({ editOrderModal: { open: false } })
  }

  render() {
    const { icon, className, orderId, location, filterProcesses, guiUser, users, updateData, attributesConfiguration } = this.props
    const { editOrderModal } = this.state
    
    if (icon) {
      return (
        <div
          className={className}
          style={Full}
          onClick={() => {
            this.setState({ editOrderModal: { open: true } })
          }}
        >
          <Icon {...icon} />
          {editOrderModal.open && (
            <EditOrderModal
              attributesConfiguration={attributesConfiguration}
              open={editOrderModal.open}
              orderId={orderId}
              location={location}
              filterProcesses={filterProcesses}
              guiUser={guiUser}
              users={users}
              closeModal={this.closeModal.bind(this)}
              updateData={updateData.bind(this)}
            />
          )}
        </div>
      )
    }

    return (
      <button
        type="button"
        onClick={() => {
          this.setState({ editOrderModal: { open: true } })
        }}
        className={className}
      >
        {__('Edit')}
        {editOrderModal.open
        && (
          <EditOrderModal
            attributesConfiguration={attributesConfiguration}
            open={editOrderModal.open}
            orderId={orderId}
            location={location}
            filterProcesses={filterProcesses}
            guiUser={guiUser}
            users={users}
            closeModal={this.closeModal.bind(this)}
            updateData={updateData.bind(this)}
          />
        )}
      </button>
    )
  }
}
EditOrderButton.defaultProps = {
  editEnabled: true,
  icon: {
    type: 'edit',
    style: { fontSize: '1.5rem' }
  },
  filterProcesses: false,
  location: undefined,
  className: undefined
}
EditOrderButton.propTypes = {
  guiUser: PropTypes.instanceOf(Object).isRequired,
  orderId: PropTypes.string.isRequired,
  users: PropTypes.instanceOf(Array).isRequired,
  attributesConfiguration: PropTypes.instanceOf(Array).isRequired,
  updateData: PropTypes.func.isRequired,
  className: PropTypes.string,
  editEnabled: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  filterProcesses: PropTypes.bool,
  icon: PropTypes.instanceOf(Object)
}
export default connect(
  (state) => ({ guiUser: state.user.data }),
  {}
)(EditOrderButton)
