import React, {useCallback, useContext, useEffect, useReducer} from 'react'

import * as A from "./actions";
import * as R from './reducer'
import * as C from '../../ConfigurationConfiguratorBeta/constants'

export const ROEConfiguratorContext = React.createContext(null)

export const useROEConfiguratorContext = () => useContext(ROEConfiguratorContext);

export default ( props) => {

  const initialState = R.getInitialState()
  const { children, mode, id, apis } = props
  const [state, dispatch] = useReducer(R.ROEConfiguratorReducer, initialState, R.initState)

  useEffect(() => {
    const initAttributes = async () => {
      return {
        apis: apis,
        mode: mode,
        configuration: id && await apis?.fetchConfiguration?.(id),
        tree: await apis?.fetchAttributeTree?.()?.then((attributesTree) => {
          const { godoo, ...attributesTreeRest } = attributesTree
          return attributesTreeRest
        }),
        systems: await apis?.fetchRemoteSystems?.(),
        valueTypesMap: await apis.fetchValueTypesMappings(),
        entities: await apis.fetchEntities(),
      }
    }
    if (state.mode !== mode) A.setMode(mode, dispatch)
    initAttributes().then((data) => A.initializeAction(data, dispatch))
  }, [])

  useEffect(() => {
    if (state.mode !== C.mode.CREATE || !state.system.id || !state.object.id) return
    const fetchDefaultConfiguration = async () => {
      const fetchRemoteObjectsResp = await apis?.fetchRemoteObjects?.(state.object.id)
      return fetchRemoteObjectsResp?.data
    }
    fetchDefaultConfiguration().then((resp) => {
      if (!resp?.length) A.updateDefaultConfigurationInfo({
        initialValue: false,
        exists: false,
        editable: true,
      }, dispatch)
      else {
        A.updateDefaultConfigurationInfo({
          initialValue: false,
          exists: resp[0].defaultConfigurationExists,
          editable: resp[0].defaultConfigurationIsEditable,
        }, dispatch)
      }
    })
  }, [state.system, state.object])

  const updateEnumerations = useCallback((ids) => {
    const idsToFetch = ids?.filter((id) => id && !state.enumerations?.[id])
    if (!idsToFetch?.length) return
    apis?.fetchEnumValuesByIds?.(ids).then((resp) => {
      A.updateEnumerations(resp, dispatch)
    })
  }, [apis, state])

  const editStepConfigurationAttributes = (attributesValues) => A.editStepConfigurationAttributesAction(attributesValues, dispatch)
  const editStepConfigurationAttribute = (attributeId, attributeValues, step) => A.editStepConfigurationAttributeAction({ attributeValues, attributeId, step }, dispatch)
  const editStepConfiguration = (stepValues, step) => A.editStepConfigurationAction({ stepValues, step }, dispatch)
  const connectValidationReference = (referenceObject) => A.connectValidationReference(referenceObject, dispatch)
  const setActiveStep = (step) => A.setActiveStepAction(step, dispatch)
  const shiftActiveStep = (jump) => A.shiftActiveStepAction(jump, dispatch)
  const setSystem = (system) => A.setSystem(system, dispatch)
  const setObject = (object) => A.setObject(object, dispatch)
  const setStepValidationVisibility = (visible) => A.setValidationVisibilityAction(visible, dispatch)

  return (
    <ROEConfiguratorContext.Provider
      value={{
        state,
        dispatch,
        setObject,
        setActiveStep,
        shiftActiveStep,
        setSystem,
        editStepConfiguration,
        connectValidationReference,
        editStepConfigurationAttribute,
        editStepConfigurationAttributes,
        setStepValidationVisibility,
        updateEnumerations,
      }}
    >
      {children}
    </ROEConfiguratorContext.Provider>

  )
}
