import _ from 'lodash'
import React from 'react'

export function renderColumns(rows, maxColumns, minColumns, multiCol = true) {
  if (!maxColumns) {
    // eslint-disable-next-line no-param-reassign
    maxColumns = 2
  }
  if (!minColumns) {
    // eslint-disable-next-line no-param-reassign
    minColumns = 1
  }
  let cols = []
  const i = 0
  
  const filteredItems = rows.filter((x) => x !== undefined)
  if (multiCol && (minColumns > 1 || filteredItems.length > 8)) {
    let chunkIndex = Math.max(minColumns, maxColumns > 2 && filteredItems.length > 15 ? 3 : 2)
    if (filteredItems.length < chunkIndex) {
      chunkIndex = filteredItems.length
    }

    let chunkSize = Math.ceil(filteredItems.length / chunkIndex)
    _.chunk(filteredItems, chunkSize)
      .forEach((element, idx) => {
        cols.push(
          <div
            className={`col-xs-12 col-sm-${(12 / chunkIndex).toString()} ${element.length === chunkSize ? 'no-border' : ''}`}
            key={`col-${idx}_${i}`}
          >
            {element}
          </div>
        )
      })
  } else {
    cols = (
      <div
        className="col-xs-12"
      >
        {filteredItems}
      </div>
    )
  }

  return cols
}
