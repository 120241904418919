import styled, {css} from "styled-components";
import {Button, Collapse} from 'antd'


export const CarouselOptions = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content:space-evenly;
`

export const UploadButton = styled(Button)`
  border:0;
  box-shadow: none;
  -webkit-box-shadow: none; 
`

export const CustomCollapse = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item {
    ${(props) => !props?.depth && 'margin-bottom: 0'}
  }
`

export const Directory = styled.div`
  padding: 5px;
  ${(props) => !props?.depth && 'margin-bottom: 12px'}
`

