import React from 'react';
import PropTypes from 'prop-types';
import { api } from '../providers/ApiProvider'
import { hashHistory } from '../providers/HistoryProvider';


class RedirectComponent extends React.Component {

  static propTypes = {
    type: PropTypes.string,
    match: PropTypes.object,
  };

  componentDidMount() {
    if (this.props.type === 'entity') {
      const id = this.props.match.params.id;
      api.get(`/entities/${id}`)
        .then(
          response => {
            if(response.status === 200) {
              hashHistory.push(`/${(response.data.type === 'user_group')?'userGroups':'organisations'}/${id}`);
            }
          }
        );

    } else {
      const url = this.props.match.params.url.replace('-', '/').replace('-', '/');
      hashHistory.push(`/${url}`);
    }
  }

  render() {
    return null;
  }
}
export default RedirectComponent;
