import React from "react";

import { Tooltip, Typography } from "antd";

import * as S from "./styles";

const CheckboxItem = (props) => {
  const {
    value,
    label,
    disabled,
    image,
    message,
    ...restCheckboxProps
  } = props
  return (
    <Tooltip
      title={message}
      {...(!message && { open: false })}
    >
      <S.FlexColumn>
        <S.CheckboxGroupItem
          value={value}
          disabled={disabled}
          {...restCheckboxProps}
        >
          <Typography.Text>
            {label}
          </Typography.Text>
        </S.CheckboxGroupItem>
        <S.CustomImage
          src={image}
          preview={false}
          disabled={disabled}
        />
      </S.FlexColumn>
    </Tooltip>
  )
}

export default ({items, style, ...restCheckboxGroupProps}) => (
  <S.CheckboxGroup
    style={{width: '100%', ...style}}
    {...restCheckboxGroupProps}
  >
    { items.map((item) => (
      <CheckboxItem {...item} key={item.value}/>
    ))}
  </S.CheckboxGroup>
)
