import {api} from "../../providers/ApiProvider";

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export async function getLogoBase64(entityId, promise) {

  if (entityId == null || typeof promise !== 'function') return null

  const blobFile = await api.get(promise(entityId), {responseType: 'arraybuffer'})
    .then(response => {
      if (response.status === 200) {
        return new Blob([response.data], {type: "image/jpeg;"});
      }
    }).catch(() => {
      return null
    });

  if (!blobFile) return null

  return await getBase64(blobFile);

}
