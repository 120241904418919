import React, {useCallback, useEffect, useImperativeHandle, useReducer, useState} from "react"
import * as CONTEXT from "./context"
import * as CONFIG_TYPE from "./context/configurationType"
import PAGES, * as P from "./factories"
import * as UT from "./utils"
import ApiManagement from "./api"
import * as ACTION_TYPE from "./context/actionTypes"
import * as VIEW_TYPE from "./context/viewType"
import WrapperForm from "./WrapperForm"
import "./style/dnd.css"
import * as MAPPER from "./mapper"
import {Icon} from '@ant-design/compatible';

import {Menu, PageHeader, Tag} from 'antd'

/*
 * 1 Fetch Data
 * 2 Handle Change Page
 * 3 Send back forms value and validate them
 * */

function Configurator({configurationType, configurationToEdit, pageHeaderProps, ...props}, ref) {
  const [state, dispatch] = useReducer(CONTEXT.reducer, CONTEXT.initialState, undefined)
  const [tagColour, setTagColour] = useState("default")
  const [currentPage, setCurrentPage] = useState(PAGES.MAIN_PAGE)

  const [wrapperFormRef, setWrapperFormRef] = useState(null)

  useEffect(() => {
    if (state.error?.exist) {
      setCurrentPage(PAGES.ERROR_PAGE)
    }
    setTagColour(UT.colourTagMatch(state.viewType, configurationType))
  }, [configurationType, state.error, state.viewType])

  useEffect(() => {
    const fetchData = async () => {
      const attributesTreeResponse = await ApiManagement.fetchAttributeTree()
      const valueTypesResponse = await ApiManagement.fetchValueTypesMappings()
      const remoteSystemResponse = await ApiManagement.fetchRemoteSystems()

      let entitiesResponse = true
      let userResponse = true
      if ([CONFIG_TYPE.VIEW, CONFIG_TYPE.REPORT].includes(configurationType)) {
        entitiesResponse = await ApiManagement.fetchEntities()
        userResponse = await ApiManagement.fetchUser()
      }

      if ([attributesTreeResponse, valueTypesResponse, remoteSystemResponse, entitiesResponse, userResponse].some((r) => !r)) {
        return {
          type: ACTION_TYPE.ERROR_FETCH,
        }
      }

      return {
        type: ACTION_TYPE.INITIALIZE_CONFIGURATOR,
        payload: {
          configurationToEdit: configurationToEdit,
          configurationType: configurationType,
        },
      }
    }

    if (state.needFetch) fetchData().then((resolve) => dispatch(resolve))
  }, [configurationToEdit, configurationType, state.needFetch])

  const handleChangePage = (targetPage) => {
    if (!targetPage.key) {
      throw Error("You must provide a key in order to change the page")
    }

    setCurrentPage(targetPage.key)
  }

  const saveWrapperFormRef = useCallback((node) => {
    if (node != null) {
      setWrapperFormRef(node)
    }
  }, [])

  useImperativeHandle(ref, () => ({
    getConfiguration() {
      let checked = false
      wrapperFormRef.validateFields((err) => {
        if (err || !state.stateConfiguration.ableToSubmitForm) {
          return null
        }
        checked = true
      })
      if (checked) {

        if (configurationType === CONFIG_TYPE.OBJECT_BINDER) {
          const response = MAPPER.mapFormFieldsToConfiguration(state.editingForms?.configurationFormFields)
          return {
            ...response,
            removableEntries: true,
            addableEntries: true
          }


        } else {
          return MAPPER.mapFormFieldsToConfiguration(state.editingForms?.configurationFormFields)
        }
      } else {
        return null
      }
    },
  }))

  return (
    <CONTEXT.MainContext.Provider value={{state, dispatch}}>
      <PageHeader
        style={{
          border: "1px solid rgb(235, 237, 240)",
          marginBottom: "30px",
        }}
        title={"Configuring: "}
        tags={
          <Tag
            color={tagColour}>{[VIEW_TYPE.LOADING, VIEW_TYPE.ERROR].includes(state.viewType) ? state.viewType : ''}</Tag>
        }
        {...pageHeaderProps}
      >
        <Menu onClick={handleChangePage} selectedKeys={currentPage} mode="horizontal">
          <Menu.Item key={PAGES.MAIN_PAGE}>
            <Icon type="eye"/>
            Main
          </Menu.Item>
          <Menu.Item key={PAGES.DISPLAY_PAGE} disabled={!state.stateConfiguration.ableToConfigureAttributes}>
            <Icon type="table"/>
            Display Attributes
          </Menu.Item>
          <Menu.Item key={PAGES.SEARCH_PAGE} disabled={!state.stateConfiguration.ableToConfigureAttributes}>
            <Icon type="search"/>
            Search Attributes
          </Menu.Item>
          <Menu.Item key={PAGES.SORT_PAGE} disabled={!state.stateConfiguration.ableToConfigureAttributes}>
            <Icon type="sort-ascending"/>
            Sort Attributes
          </Menu.Item>
        </Menu>
      </PageHeader>
      {currentPage === PAGES.ERROR_PAGE && <P.ErrorPage/>}
      <WrapperForm ref={saveWrapperFormRef} dispatch={dispatch} formFields={state.editingForms.configurationFormFields}>
        {currentPage === PAGES.MAIN_PAGE && <P.MainFactory configurationType={configurationType} {...props}/>}
        {currentPage === PAGES.DISPLAY_PAGE && <P.DisplayAttributeFactory configurationType={configurationType}/>}
        {currentPage === PAGES.SEARCH_PAGE && <P.SearchAttributeFactory configurationType={configurationType}/>}
        {currentPage === PAGES.SORT_PAGE && <P.SortAttributeFactory configurationType={configurationType}/>}
      </WrapperForm>
    </CONTEXT.MainContext.Provider>
  )
}

export default React.forwardRef(Configurator)
