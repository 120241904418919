import svgToImage from 'svg-to-image';
import { isPNG } from "../../../../../../utils/appHelper";
import FileUploaderService from "../services/fileUploaderService";
import { uploadFullFile } from "../../../../../../providers/ApiProvider";


export const getFileBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const resizeImage = (file, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = function () {
      let width = img.naturalWidth;
      let height = img.naturalHeight;

      const aspectRatio = width / height;

      if (width > maxWidth || height > maxHeight) {
        if (aspectRatio > 1) {
          width = maxWidth;
          height = maxWidth / aspectRatio;
        } else {
          height = maxHeight;
          width = maxHeight * aspectRatio;
        }

        if (width > maxWidth) {
          width = maxWidth;
          height = maxWidth / aspectRatio;
        }
        if (height > maxHeight) {
          height = maxHeight;
          width = maxHeight * aspectRatio;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        blob.name = file.name;
        resolve(blob);
      }, isPNG(file?.type) ? file?.type : 'image/jpeg', quality);
    };

    img.onerror = function () {
      reject(new Error("Error loading image."));
    };

    img.src = URL.createObjectURL(file);
  });
}

export const centerImage = (file, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = function () {
      let width = img.width;
      let height = img.height;

      canvas.width = maxWidth;
      canvas.height = maxHeight;

      ctx.fillStyle = 'white';  // Set the fill color to white
      ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the entire canvas

      const x = (maxWidth - img.naturalWidth) / 2;
      const y = (maxHeight - img.naturalHeight) / 2;

      // Resize the image
      ctx.drawImage(img, x, y, width, height);

      // Convert the canvas to a Blob
      canvas.toBlob((blob) => {
        blob.name = file.name;
        resolve(blob);
      }, isPNG(file?.type) ? file?.type : 'image/jpeg', 1);
    };

    img.onerror = function () {
      reject(new Error("Error loading image."));
    };

    img.src = URL.createObjectURL(file);
  });
}

export const emptyBase64 = (width, height) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.fillStyle = 'white'; // Set the fill color to white
    ctx.fillRect(0, 0, width, height); // Fill the rectangle
  }

  return canvas.toDataURL('image/jpeg');
};

export const getImageDimensions = async (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(event) {
      const img = new Image();
      img.onload = function() {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = function() {
        reject(new Error('Failed to load image'));
      };

      img.src = event.target.result; // Set the src to the result of the FileReader
    };

    reader.onerror = function() {
      reject(new Error('Failed to read blob'));
    };

    reader.readAsDataURL(blob); // Read the blob as a Data URL
  });
}

export const base64ToBlob = (base64String, contentType = '') => {
  // Remove the data URL prefix if present
  const byteCharacters = atob(base64String.split(',')[1]);
  // Create a typed array to hold the binary data
  const byteNumbers = new Array(byteCharacters.length);
  // Convert each character to its char code
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  // Create a Uint8Array from the byte numbers
  const byteArray = new Uint8Array(byteNumbers);
  // Return a new Blob object
  return new Blob([byteArray], { type: contentType });
}

export const convertSvgToPNG = (file, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (event) {
      const svgString = event.target.result
      const options = {
        width: maxWidth,
        height: maxHeight,
        encoderOptions: quality,
      }

      // Fix SVG document for Firefox
      const parser = new DOMParser()
      const svgDoc = parser.parseFromString(svgString, 'image/svg+xml')
      const fixedSvgDoc = fixSvgDocumentFF(svgDoc)

      // Convert SVG to image
      svgToImage(fixedSvgDoc.documentElement.outerHTML, function (err, image) {
        if (err) {
          reject(err)
        }
        else {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const context = canvas.getContext('2d')

          context.drawImage(image, 0, 0)
          canvas.toBlob(function (blob) {
            resolve(blob)
          }, 'image/png', quality)
        }
      }, options)
    }
    reader.readAsText(file)
  })
}

function fixSvgDocumentFF(svgDocument) {
  try {
    let widthInt = parseInt(svgDocument.documentElement.width.baseVal.value) || 500
    let heightInt = parseInt(svgDocument.documentElement.height.baseVal.value) || 500
    svgDocument.documentElement.width.baseVal.newValueSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX, widthInt)
    svgDocument.documentElement.height.baseVal.newValueSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX, heightInt)
    return svgDocument
  } catch (e) {
    return svgDocument
  }
}

export const getImageThumbnail = async (file, authToken, callback) => {
  if (file.smallUrl) {
    let smallBlob = await FileUploaderService.getUrlFile(file.smallUrl, authToken)
    smallBlob = smallBlob?.slice(0, smallBlob.size, 'image/png')
    if (smallBlob)
      getFileBase64(smallBlob).then((smallBase64) => callback(smallBase64))
    else {
      const fileBlob = await FileUploaderService.getUrlFile(file.url, authToken)
      resizeImage(fileBlob, 200, 200, 0.8).then(async (smallBlob) => {
        getFileBase64(smallBlob).then((smallBase64) => {
          callback(smallBase64)
          uploadFullFile(file.smallUrl, smallBlob)
        })
      })
      resizeImage(fileBlob, 720, 720, 0.8).then(async (medium) => {
        uploadFullFile(file.mediumUrl, medium)
      })
    }
  }
}
