import {useQuery} from "react-query";
import config from "../config";
import {fetchBlockedOrdersById} from "../../ApiProvider";

export const UseBlockedOrdersByUserIdQuery = (userId: string) => useQuery<number>({
  queryKey: ["fetchBlockedOrdersById", userId],
  queryFn: () => fetchBlockedOrdersById(userId),
  ...config,
  cacheTime: 0
})
