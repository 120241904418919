import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Glyphicon from '@strongdm/glyphicon';
import {capitalize} from '../../../utils/appHelper';
import {Icon} from "@ant-design/compatible";
import {ExportOutlined} from '@ant-design/icons';
import * as S from "./styles";
import {__} from '../../../utils/translationUtils';
import {Popover} from "antd";


const SubMenuItemContent = ({className, handleClick, item}) => {
  const {
    path,
    text,
    icon,
    items: children,
    external,
    target,
  } = item || {}

  return (
    <>
      {
        !external && (
          <Link onClick={handleClick} to={path}>
            {icon && <Icon type={icon} theme="outlined"/>}
            {text}
            {children?.length > 0 && (
              <S.Extra>
                <Glyphicon glyph="chevron-right"/>
              </S.Extra>
            )}
          </Link>
        )
      }
      {external && (
        <a onClick={handleClick} href={path} target={target}>
          {text}
          <S.Extra>
            {children?.length > 0 ? <Glyphicon glyph="chevron-right"/> : <ExportOutlined/>}
          </S.Extra>
        </a>
      )}
    </>
  )
}


const SubMenuItem = ({className, handleClick, item}) => {
  const {
    path,
    active,
    text,
    items: children,
    expanded,
    separator = ''
  } = item || {};

  if (expanded) {
    return (
      <div className={`expanded-group ${separator && 'separator'}`}>
        <div className={active ? 'active' : ''}>
          {text}
        </div>
        {children?.map((subItem, i) => (
          <SubMenuItem
            handleClick={handleClick}
            key={`${i}-${text}`}
            className={className}
            item={subItem}
          />
        ))}
      </div>
    );
  }

  if (children?.length > 0)
    return (
      <Popover
        placement="rightTop"
        overlayClassName="sub-menu-children"
        destroyTooltipOnHide
        content={
          <ul>
            {children.map((subItem, i) => (
              <li key={`menu-sub-item-${i}`}>
                <Link onClick={handleClick} to={subItem.path}>
                  {capitalize(subItem.text)}
                </Link>
              </li>
            ))}
            <li key="menu-sub-item-home">
              <Link onClick={handleClick} to={path}>
                {capitalize(__('View All'))}
              </Link>
            </li>
          </ul>
        }
        trigger="hover"
      >
        <li className={`${className} ${active ? 'active' : ''} ${separator && 'separator'}`}>
          <SubMenuItemContent
            handleClick={handleClick}
            className={className}
            item={item}
          />
        </li>

      </Popover>
    )

  return (
    <li className={`${className} ${active ? 'active' : ''} ${separator && 'separator'}`}>
      <SubMenuItemContent
        handleClick={handleClick}
        className={className}
        item={item}
      />
    </li>
  );
};

SubMenuItem.propTypes = {
  item: PropTypes.object,
  guiUser: PropTypes.object,
  handleClick: PropTypes.func,
  className: PropTypes.string
};

export default SubMenuItem;
