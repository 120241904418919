import React from 'react'

import moment from 'moment'
import { Table, Space } from 'antd'
import { Icon } from '@ant-design/compatible'
import { Link } from 'react-router-dom'
import * as S from './styles'

import { __ } from '../../../utils/translationUtils'

const { WORKING_EVENT } = global.constants.eventTypes

export default ({ events, onDelete, onEdit, onAdd, type }) => {
  const expandedRowRender = ({ dayOfTheWeekCode }) => {
    const innerDataSource = events
      .filter((e) => e.dayOfTheWeekCode === dayOfTheWeekCode)
      .map((e) => ({ ...e, hours: moment(e.end).hours() - moment(e.start).hours(), fromTo: `${moment(e.start).format('HH.mm')} - ${moment(e.end).format('HH.mm')}` }))

    const innerTableTitle = moment().day(dayOfTheWeekCode + 1).format('dddd')

    return (
      <S.InnerTable>
        <S.InnerTableTitle>{innerTableTitle}</S.InnerTableTitle>

        <Table
          columns={[
            { title: __('Working hours'), dataIndex: 'fromTo', key: 'fromTo' },
            { title: __('Working hours'), dataIndex: 'hours', key: 'hours' },
            type !== 'entity' ? { title: 'Entity', dataIndex: 'entity', key: 'entity', render: (entity) => <S.Entity key={entity.id}><Link to={`/${entity.type === 'organisation' ? 'organisations' : 'userGroups'}/${entity.id}`} key={entity.id}>{entity.shortName}</Link></S.Entity> } : null,
            {
              title: __('Actions'),
              key: 'actions',
              width: 100,
              render: (e) => !e.inherited && (
                <S.Actions>
                  <Space>
                    <S.Edit><Icon type="edit" onClick={() => onEdit(e)} /></S.Edit>
                    <S.Danger><Icon type="delete" onClick={() => onDelete(e)} /></S.Danger>
                  </Space>
                </S.Actions>
              )
            }
          ].filter((e) => e)}
          dataSource={innerDataSource}
          pagination={false}
          size="small"
        />
        <S.ButtonWrapper>
          <button type="button" className="btn btn-primary" onClick={() => onAdd({ eventType: WORKING_EVENT, dayOfTheWeekCode })}>
            {__('Create event')}
          </button>
        </S.ButtonWrapper>
      </S.InnerTable>
    )
  }

  const outerDataSource = events.reduce((acc, val, index) => {
    const alreadyExisting = acc.findIndex((e) => e.dayOfTheWeekCode === val.dayOfTheWeekCode)
    if (alreadyExisting > -1) {
      return [
        ...acc.slice(0, alreadyExisting),
        {
          ...acc[alreadyExisting],
          entities: [...acc[alreadyExisting].entities, val.entity],
          hours: acc[alreadyExisting].hours + moment(val.end).hours() - moment(val.start).hours()
        },
        ...acc.slice(alreadyExisting + 1)
      ]
    }
    return [...acc, {
      ...val,
      key: index,
      entities: [val.entity],
      hours: moment(val.end).hours() - moment(val.start).hours()
    }]
  }, [])

  return (
    <>
      <Table
        size="small"
        pagination={false}
        dataSource={outerDataSource}
        expandable={{ expandedRowRender }}
        columns={[
          {
            title: __('Day'),
            dataIndex: 'start',
            key: 'start',
            shouldCellUpdate: () => true,
            sorter: { compare: (a, b) => moment(a.start).isoWeekday() - moment(b.start).isoWeekday() },
            render: (e) => moment(e).format('dddd'),
            filters: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((text, i) => ({ text, value: i })),
            onFilter: (value, record) => record.dayOfTheWeekCode === value,
            defaultSortOrder: 'ascend'
          },
          {
            title: __('Hours'),
            dataIndex: 'hours',
            key: 'hours',
            shouldCellUpdate: () => true
          },
          type !== 'entity' ? {
            title: __('Working for'),
            dataIndex: 'entities',
            key: 'entities',
            render: (entities) => <div>{entities.map((entity) => <S.Entity key={entity.id}><Link to={`/${entity.type === 'organisation' ? 'organisations' : 'userGroups'}/${entity.id}`} key={entity.id}>{entity.shortName}</Link></S.Entity>)}</div>
          } : null
        ].filter((e) => e)}
      />
      <S.ButtonWrapper>
        <button type="button" className="btn btn-primary" onClick={() => onAdd({ eventType: WORKING_EVENT })}>
          {__('Create event')}
        </button>
      </S.ButtonWrapper>
    </>
  )
}
