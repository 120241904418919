import React, { useEffect, useMemo } from "react";

import { Col, Form, Row } from "antd";

import { FileUploaderAdapted } from "../../../../FileUploader";
import { ObjectSelectorAdapted } from "../../../../SimpleObjectSelector";
import { renderInputFieldFromValueType } from "../../../../../../../../utils/renderInputField";

import { appDefaults } from "../../../../../../../../constants/globalConfiguration";
import * as S from "../../../../../../../../providers/StyleProvider/styles";
import * as U from "../../../utils";
import * as C from "../../../constants";

import { __ } from '../../../../../../../../utils/translationUtils'


export default (props) => {
  const {
    initialValues,
    attributeFields,
    enumValues = {},
    formRef,
    methods,
    mode,
    objectId,
    objectTypeId,
    containerProps,
    attributesTree,
    attributesList,
    enumerations,
    objectConfiguration,
    missingConfigurationMessage
  } = props

  const {
    updateEnumerations,
    fetchEnumValuesByIds,
    fetchRemoteTableData,
    fetchDefaultConfiguration,
    addRelations,
    removeRelations,
  } = methods || {}

  const { displayAttributes } = objectConfiguration || {}

  const [form] = Form.useForm();
  const enumItems = useMemo(() => U.getEnumValuesMapItems(enumerations), [enumerations])

  useEffect(() => {
    const enumsToFetch = attributeFields.reduce((prev, attribute) => {
      const enumId = attribute.enumerationId
      if (enumId && !enumValues[enumId]) prev.push(enumId)
      return prev
    }, [])

    updateEnumerations(enumsToFetch)
    if (formRef) formRef.current = form
  }, [attributeFields])

  const objectSelectorsRights = useMemo(() => attributeFields.reduce((prev, attribute) => {
    if (attribute.type !== 'IDS') return prev
    const displayAttribute = displayAttributes?.find(({ attributeId }) => attributeId === attribute.id)
    prev[attribute.id] = {
      add: mode === C.formMode.CREATE ? displayAttribute?.canAddRelationDuringCreate : displayAttribute?.canAddRelationDuringEdit,
      remove: mode === C.formMode.CREATE ? true : displayAttribute?.canRemoveRelationDuringEdit,
    }
    return prev
  }, {}), [displayAttributes, attributeFields, mode])

  return (
    <>
      <S.ModalFormContainer>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ ...initialValues }}
          onFinish
        >
          <Row gutter={16}>
            {attributeFields.map((attribute) => (
              <Col span={12}>
                <Form.Item
                  key={attribute.id}
                  name={attribute.id}
                  label={(
                    <S.CustomLabel weight={600} size='16px'>
                      {__(attribute.propertyLabel)}
                    </S.CustomLabel>
                  )}
                >
                  {
                    attribute.type === 'IDS' ?
                      <ObjectSelectorAdapted
                        mode={mode}
                        readOnly={mode === C.formMode.VIEW}
                        selectionMode={attribute.multiple ? C.selectionMode.MULTI : C.selectionMode.SINGLE}
                        parentObjectId={objectId}
                        parentObjectTypeId={objectTypeId}
                        objectTypeId={attribute.relatedObjects}
                        attributeId={attribute.id}
                        mappedBy={attribute.mappedBy}
                        containerProps={containerProps}
                        enumerations={enumerations}
                        attributesTree={attributesTree}
                        attributesList={attributesList}
                        missingConfigurationMessage={missingConfigurationMessage}
                        disabled={!attribute.editable}
                        rights={objectSelectorsRights[attribute.id]}
                        methods={{
                          addRelations,
                          removeRelations,
                          fetchRemoteTableData,
                          fetchEnumValuesByIds,
                          fetchDefaultConfiguration,
                          updateEnumerations,
                        }}
                      />
                      : attribute.type === 'BINARY' ? (
                        <FileUploaderAdapted
                          readOnly={mode === C.formMode.VIEW}
                          attribute={attribute}
                        />
                      ) : (
                        <ControllerType
                          valueType={attribute.type}
                          items={attribute.enumerationId && enumItems[attribute.enumerationId]}
                          format={appDefaults.dateTimeFormat}
                          disabled={!attribute.editable}
                          readOnly={mode === C.formMode.VIEW}
                        />
                      )
                  }
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      </S.ModalFormContainer>
    </>
  );
}

const ControllerType = (props) => {
  const Controller = renderInputFieldFromValueType(props.valueType)
  return (
    <Controller
      {...props}
    />
  )
}
