import React from 'react'
import PropTypes from 'prop-types'
import { Button, Panel } from 'react-bootstrap'
import { connect } from 'react-redux'
import MessageModal from './MessageModal'
import MessageContainer from './MessageContainer'
import { api } from '../../../providers/ApiProvider'
import { __ } from '../../../utils/translationUtils'
import {checkUserRightsAll} from "../../../utils/appHelper";
const { CAN_ADD_ORDER_COMMENTS, CAN_ADD_STEP_COMMENTS, CAN_EDIT_COMMENT, CAN_DELETE_COMMENT } = global.constants.rightMappings

class MessageRoom extends React.Component {
  static propTypes = {
    panelTitle: PropTypes.string,
    objectCaller: PropTypes.object,
    modelCaller: PropTypes.string
  };

  constructor(props) {
    super(props)
    this.state = {
      addCommentModalOpen: false,
      overviewOpen: false,
      isLoading: true,
      permissions: null,
      messages: [],
      entities: []
    }

    this.handleToggle = this.handleToggle.bind(this)
    this.getData = this.getData.bind(this)
  }

  handleToggle() {
    this.setState({ overviewOpen: !this.state.overviewOpen, addCommentModalOpen: false })
  }

  componentDidMount() {
    this.getData()
    this.setState({ permissions: checkUserRightsAll(this.props.rights, { CAN_ADD_ORDER_COMMENTS, CAN_ADD_STEP_COMMENTS, CAN_EDIT_COMMENT, CAN_DELETE_COMMENT }) })
  }

  getData() {
    this.setState({ isLoading: true })
    Promise.all([this._getEntities(), this._getMessage()])
      .then((values) => {
        this.setState({ entities: values[0].data, messages: values[1].data, isLoading: false })
      })
  }

  _getMessage() {
    const { objectCaller, modelCaller } = this.props
    const req = `/${modelCaller}/${objectCaller.id}/comments`
    return api.get(req)
  }

  _getEntities() {
    const req = '/entities?pageSize=200&status=enabled,reserved'
    return api.get(req)
  }

  hasRight() {
    const { permissions } = this.state
    const { modelCaller } = this.props

    if (modelCaller === 'orders') return permissions.CAN_ADD_ORDER_COMMENTS
    return permissions.CAN_ADD_STEP_COMMENTS
  }

  renderButtons() {
    return (
      <Button
        disabled={!this.hasRight()}
        className="btn-blue pull-right btn-add-order-comment"
        onClick={() => { this.setState({ addCommentModalOpen: true }) }}
      >
        {__('AddComment')}
      </Button>
    )
  }

  renderAddCommentModal() {
    return (
      <MessageModal
        {...this.props}
        modalOpen={this.state.addCommentModalOpen}
        closeModal={() => { this.setState({ addCommentModalOpen: false }) }}
        refreshMessage={this.getData}
        entities={this.state.entities}
      />
    )
  }

  renderPanel() {
    const { overviewOpen, messages, entities, permissions } = this.state

    if (overviewOpen) {
      return (
        <Panel
          style={{ marginBottom: '40px' }}
          expanded={overviewOpen}
          onToggle={() => {}}
          bsStyle="info"
        >
          <Panel.Body collapsible>
            <div style={{ maxHeight: '350px', overflowY: 'auto', marginTop: '-10px' }}>
              <MessageContainer
                {...this.props}
                messages={messages}
                entities={entities}
                refreshMessage={this.getData}
                permissions={permissions}
              />
              {this.renderButtons()}
            </div>
          </Panel.Body>
        </Panel>
      )
    }
    const lastMessage = messages.length ? [messages[messages.length - 1]] : []
    return (
      <Panel
        style={{ marginBottom: '40px' }}
        expanded={!overviewOpen}
        onToggle={() => {}}
        bsStyle="info"
      >
        <Panel.Body collapsible>
          <div style={{ maxHeight: '350px', overflowY: 'auto', marginTop: '-10px' }}>
            <MessageContainer
              {...this.props}
              messages={lastMessage}
              entities={entities}
              refreshMessage={this.getData}
              permissions={permissions}
            />
            {this.renderButtons()}
          </div>
        </Panel.Body>
      </Panel>
    )
  }

  render() {
    const { panelTitle } = this.props
    const { isLoading, messages } = this.state
    if (isLoading) {
      return (<div />)
    }

    return (
      <div className="fieldset style-1">
        <span className="collapsible-header no-selection" onClick={this.handleToggle}>
          {`${__(panelTitle)} - (${messages.length})`}
        </span>
        {this.renderPanel()}
        {this.renderAddCommentModal()}
      </div>
    )
  }
}

export default connect(
  (state) => ({
    guiUser: state.user.data,
    rights: state?.user?.data.rights,
    authToken: state.user.authToken
  })
)(MessageRoom)
