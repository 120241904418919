import React, { useCallback, useEffect, useState } from "react";

import { Space } from "antd";
import { downloadFile } from "../../DooOrderPage/Step/customRender/FileUploader/utils/IO";
import downloadjs from "downloadjs";
import { FlexRowRight } from "components";
import { Button } from "components";

import { extensionToMimeType } from "./utils";
import { fetchROEExportProgress } from "../../../utils/appHelper";
import { getProgressColor } from "../../UI/Progress";
import * as S from "../../UI/Progress/styles.js";
import { __ } from '../../../utils/translationUtils'




export default (props) => {
  const { fileInfo, downloadButtonProps } = props
  const { path, name } = fileInfo || {}

  const [eProgress, setEProgress] = useState(0)
  const [showProgress, setShowProgress] = useState(true)

  const [dProgress, setDProgress] = useState(0)
  const [showDProgress, setShowDProgress] = useState(false)

  const updateProgress = async () => {
    return fetchROEExportProgress(name).then((res) => {
      const { data } = res

      setEProgress(data)
      return data
    }).catch((err) => {
      console.log('err', err)
    })
  }

  useEffect(() => {
    updateProgress()

    const interval = setInterval(() => {
      updateProgress().then((progress) => {
        if (progress >= 100) {
          clearInterval(interval)
          setShowProgress(false)
        }
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const onClick = useCallback((extension) => {
    const mimeType = extensionToMimeType[extension]

    setShowDProgress(true)
    downloadFile(`${path}.${extension}`, null, (status) => setDProgress(status.process))
      .then((blob) => {
        if (!blob) return
        setDProgress(100)
        const file = new File([blob], `${name}.${extension}`, { type: mimeType })
        downloadjs(file, `${name}.${extension}`, mimeType)

        setTimeout(() => {
          setShowDProgress(false)
        }, 1000)
      })
  }, [path, name])

  const { items } = downloadButtonProps || {}

  return (
    <Space direction="vertical">
      <h4>{name}</h4>
      <FlexRowRight>
        <Space size="middle" style={{ alignItems: 'baseline' }}>
          {
            eProgress != 100 && (
              <p style={{ fontStyle: "italic" }}>{__('generating_export')} ({eProgress}%)...</p>
            ) || (
              <p style={{ fontStyle: "italic" }}>{__("ready_to_download")}...</p>
            )
          }
          <div style={{ position: 'relative' }}>
            <Button
              shape='circle'
              icon={"DownloadOutlined"}
              disabled={eProgress != 100}
              direction="down"
              {...downloadButtonProps}
              {...(items ? { items: items.map((item) => ({ ...item, onClick: () => onClick(item.option) })) } : {})}
            />
            {
              showProgress && (
                <S.CustomProgress
                  type="circle"
                  isButton={true}
                  status="normal"
                  width="100%"
                  strokeWidth={10}
                  percent={eProgress}
                  showInfo={false}
                  strokeColor={getProgressColor({ completed: eProgress >= 100 })}
                />
              )
            }
          </div>
        </Space>
      </FlexRowRight>

      {
        showDProgress && (
          <S.CustomProgress
            status="normal"
            width="100%"
            strokeWidth={10}
            percent={dProgress}
            showInfo
            strokeColor={getProgressColor({ completed: dProgress >= 100 })}
          />
        )
      }
    </Space >
  )
}
