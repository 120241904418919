import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useROEConfiguratorContext} from "../../context";
import {Form, TreeSelect} from "antd";
import {debounce} from "lodash";
import ViewPropertiesForm from "../../../../components/ConfigurationConfiguratorBeta/ViewProperties";
import * as C from "../../../ConfigurationConfiguratorBeta/constants";
import {validationActivatedAfter} from "../../constants";
import {useSelector} from "react-redux";
import * as configuratorUtils from "../../../../components/ConfigurationConfiguratorBeta/utils";
import {visibleFor} from "../../../../components/ConfigurationConfiguratorBeta/constants";
import {CustomText} from "../../../../providers/StyleProvider/styles";
import Toggle from "../../../../components/Toggle";
import { __ } from '../../../../utils/translationUtils'
import {REGEX_TITLE} from "components/dist/Utils/regexUtils";

export default ({ viewPropertiesRef }) => {
  const {
    state,
    editStepConfiguration
  } = useROEConfiguratorContext()
  const {
    mode,
    creatorId,
    configurations,
    activeStep,
    entitiesTreeData,
    validationVisibility,
    defaultConfiguration,
  } = state
  const formValues = configurations[activeStep]

  const userId = useSelector((state) => state?.user?.data?.id)
  const [showEntitiesSelect, setShowEntitiesSelect] = useState(formValues?.visibleFor === visibleFor.SPECIFIC_ENTITIES)
  const visibleForDisabled = mode === C.mode.EDIT && creatorId !== userId
  const defaultConfigurationInfo = useMemo(() => {
    if (!defaultConfiguration.editable) {
      if (defaultConfiguration.exists) return __('default_configuration_update_negation')
      return __('default_configuration_missing_update_rights')
    }
    if (!defaultConfiguration.initialValue && defaultConfiguration.editable && defaultConfiguration.exists) return __('default_configuration_update_warning')
    return ''
  }, [defaultConfiguration])

  const [form] = Form.useForm();

  useEffect(() => {
    if (viewPropertiesRef) viewPropertiesRef.current = form
  }, [form, viewPropertiesRef])

  const debouncedSubmitValues = useCallback(
    debounce((step) => editStepConfiguration(form.getFieldsValue(), step), 300),
    [form],
  );

  useEffect(() => {
    if (validationVisibility[activeStep] === validationActivatedAfter.CLOSE) form.validateFields()
  }, [validationVisibility])

  const handleVisibleForChange = (change) => {
    if (change === visibleFor.SPECIFIC_ENTITIES) setShowEntitiesSelect(true)
    else if (showEntitiesSelect) setShowEntitiesSelect(false)
  }

  const onFormChange = (changes) => {
    if (changes.visibleFor) handleVisibleForChange(changes.visibleFor)
    debouncedSubmitValues(activeStep)
  }

  return (
    <ViewPropertiesForm
      form={form}
      initialValues={formValues}
      fields={[
        {
          name: C.viewPropertiesFields.VIEW_NAME,
          label: <CustomText required>{__('view_name')}</CustomText>,
          rules: [
            {required: true, message: __('This field is required')},
            {pattern: REGEX_TITLE, message: "Wrong format!"}
          ],
          required: false,
          colProps: { flex: '20%' },
          itemProps: {
            type: 'input',
            placeholder: __('view_name'),
          }
        },
        {
          name: C.viewPropertiesFields.VISIBLE_FOR,
          label: <CustomText required>{__('visible_for')}</CustomText>,
          rules: [{required: true, message: __('required_field')}],
          required: false,
          colProps: { flex: '20%' },
          // tooltipProps: visibleForDisabled && { title: __('only_creator_can_edit_visibility') },
          itemProps: {
            type: 'select',
            items: configuratorUtils.getVisibleForItems(),
            placeholder: __('select_one'),
            // disabled: visibleForDisabled,

          }
        },
        ...( !showEntitiesSelect ? [{ type: 'custom', colProps: {flex: '60%'}}] : [{
          name: C.viewPropertiesFields.SELECT_ENTITIES,
          label: <CustomText required>{__('select_entities')}</CustomText>,
          rules: [{required: true, message: __('required_field')}],
          required: false,
          colProps: { flex: '60%' },
          itemProps: {
            type: 'treeSelect',
            items: entitiesTreeData,
            treeCheckable: true,
            allowClear: true,
            showCheckedStrategy: TreeSelect.SHOW_PARENT,
            placeholder: __('please_select'),
            dropdownMatchSelectWidth: false,
            maxTagCount: 'responsive',
          }
        }]),
        {
          name: C.viewPropertiesFields.DEFAULT_CONFIGURATION,
          label: <CustomText>{__('default_view')}</CustomText>,
          colProps: { flex: '20%' },
          tooltipProps: defaultConfigurationInfo && {
            title: defaultConfigurationInfo,
            placement: 'topLeft',
          },
          itemProps: {
            CustomRender: Toggle,
            label: __('default_view_info')
          },
        },
        {
          name: C.viewPropertiesFields.DEFAULT_PAGE_SIZE,
          label: <CustomText>{__('default_page_size')}</CustomText>,
          colProps: { flex: '20%' },
          itemProps: {
            type: 'select',
            items: configuratorUtils.getDefaultPageSizeItems(),
          }
        },
      ]}
      onFormChange={onFormChange}
    />
  )
}
