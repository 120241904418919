export const actionType = {
  ADDED: 'added',
  REMOVED: 'removed',
  EDITED: 'edited'
}

export const actionName = {
  MATCHING_TEMPLATES: 'matchingTemplates',
  ROLES: 'roles',
  REMOVED_FROM_ORGANISATIONS: 'removed from organisations',
  ADDED_TO_ORGANISATIONS: 'added to organisations',
  ORGANISATIONS: 'organisations',
  REMOVED_FROM_USER_GROUPS: 'removed from user group',
  ADDED_TO_USER_GROUPS: 'added to user groups',
  USER_GROUPS: 'user groups',
  AVATAR: 'AVATAR'
}
