import * as I from 'components/dist/Form/Inputs'

export const valueTypeToInput = {
  DEFAULT: 'input',
  STRING: 'input',
  DOUBLE: 'number',
  DATE: 'datepicker',
  ENUM_VALUE: 'select',
  YESNO: 'switch',
  FLOAT: 'number',
  BOOLEAN: 'switch',
  INTEGER: 'number',
  IDS: 'number',
  LONG: 'number'
}

const oldToNewInput = (controllerType, valueType) => {
  if (controllerType === 'DateInput') return 'datepicker'
  if (controllerType === 'DateTimeInput') return 'timepicker'
  if (controllerType === 'SimpleLabel') return 'simpleLabel'
  if (controllerType === 'UrlLink') return 'input' // TODO: use url input type
  if (controllerType === 'SimpleCheckBox') return 'checkbox'
  if (controllerType === 'RadioButton') {
    if (valueType === 'YESNO' || valueType === 'BOOLEAN') return 'switch'
    return 'radio'
  }
  if (controllerType === 'PopupListBox') return 'select'

  if (controllerType === 'MultiLineTextEntry' || controllerType === 'DocumentEditor' || controllerType === 'HtmlEditor') return 'textArea'
  if (controllerType === 'TextEntry') return 'input'

  if (controllerType === 'CustomPhotoCollection' || controllerType === 'PhotoCollection') return 'imageViewerInput'
  if (controllerType === 'FileUpload') return 'input' // TODO: use file uploader controller type
  if (controllerType === 'SimpleSignature') return 'signature'
  if (controllerType === 'BillingController') return 'input' // TODO: use BillingController controller type
  if (controllerType === 'RemoteObjectBinder') return 'input' // TODO: use RemoteObjectBinder controller type
  if (controllerType === 'ObjectListing') return 'input' // TODO: use ObjectListing controller type

  return 'input'
}

// UNUSED => to delete?
export const renderInputFieldFromOldControllerType = (controllerType, valueType) => {
  const inputType = oldToNewInput(controllerType, valueType)
  return I[inputType]
}

export const renderInputFieldFromValueType = (valueType) => {
  const inputType = valueTypeToInput[valueType] || valueTypeToInput.DEFAULT
  return I[inputType] || I[valueTypeToInput.DEFAULT]
}

export default (inputType) => I[inputType]
