import React, {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import Timeline, {CustomMarker, DateHeader, TimelineHeaders, TimelineMarkers} from 'react-calendar-timeline'
import {Popover, Tag} from 'antd'
import 'react-calendar-timeline/lib/Timeline.css'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
import * as S from './styles'
import theme from '../../../providers/StyleProvider/theme'
import UserAvatar from '../../UI/UserAvatar'
import * as config from '../../../constants/globalConfiguration'
import CalendarEvent from '../CalendarEvent'
import CalendarMacbookStyleHandler from '../CalendarMacbookStyleHandler'

const {LINE_HEIGHT, LINE_HEIGHT_RATIO} = global.constants.calendarTimeline

// it is fully controlled
export default ({ items, groups, handleExpandCalendar, attributes, stackItems, view, visibleStart, visibleEnd, selectable, editable, onSelectSlot, onDelete, onEdit }) => {
  const [selectedEntityGroups, selectEntityGroup] = useState(groups.reduce((acc, group) => ([...acc, ...group.belongsTo.map((entity) => ({ group, entity }))]), []))
  const myOuterElement = useRef()
  const myTimelineHeaderElement = useRef()
  const [headerFixed, setHeaderFixed] = useState(null)
  const myGroupElements = useRef([])
  const [outerPosition, setOuterPosition] = useState()
  const [groupsPosition, setGroupsPosition] = useState([])

  useEffect(() => {
    if (!groups?.length || typeof document === 'undefined' || !myOuterElement?.current || !myGroupElements?.current || !myTimelineHeaderElement?.current) return

    if (myGroupElements.current?.length) {
      const groupsPosition = {}
      myGroupElements.current.filter((e) => e).forEach((group) => {
        groupsPosition[group.id] = group.getBoundingClientRect()
      })
      setGroupsPosition(groupsPosition)
    }

    setOuterPosition({ top: myOuterElement.current.getBoundingClientRect().top + myTimelineHeaderElement?.current.getBoundingClientRect().height + 2 })
  }, [groups, myOuterElement, myGroupElements, myTimelineHeaderElement])

  const innerItems = React.useMemo(() => items
    .map((item) => {
      let hidden = false

      if (item.eventType === global.constants.eventTypes.GODOO_EVENT) {
        // ✅ to do for the godoo event that right now cannot be hidden
        hidden = false
      } else {
        hidden = !selectedEntityGroups.find((e) => (e.entity.id === item.entityId) && (e.group.id === item.group))
      }
      return {
        ...item,
        hidden,
        // in the month view, we want all the day slot as working day if even a single hour is worked during the day
        start_time: (view === 'month') ? moment(item.start_time).startOf('day') : item.start_time,
        end_time: (view === 'month') ? moment(item.end_time).endOf('day') : item.end_time
      }
    }), [items, selectedEntityGroups, view])

  const innerGroups = React.useMemo(() => groups.map((group) => ({
    ...group,
    belongsTo: group.belongsTo.map((entity) => ({
      ...entity,
      color: selectedEntityGroups?.find((e) => (e.entity.id === entity.id) && (e.group.id === group.id)) ? theme.colors.thirdary : theme.colors.grey.primary
    }))
  })), [groups, selectedEntityGroups])

  const ItemRenderer = ({ item, getItemProps, itemContext }) => {
    const itemProps = getItemProps()

    return <CalendarEvent hoverable groupPosition={groupsPosition[item.group]} outerPosition={outerPosition} {...itemProps} {...item} {...itemContext.dimensions} attributes={attributes} onDelete={onDelete} onEdit={onEdit} editable={editable} />
  }

  function handleCanvasClick(groupId, time) {
    if (!selectable) return

    onSelectSlot({
      startDate: moment(time),
      endDate: moment(time).add(1, 'hour'),
      user: groups.find((group) => group.id === groupId)
    })
  }

  const handleToggleSelectedEntityGroups = ({ entity, group }) => {
    selectEntityGroup((prev) => {
      const selectedEntitiesIndex = prev?.findIndex((e) => (e.entity.id === entity.id) && (e.group.id === group.id))
      return selectedEntitiesIndex > -1
        ? [...prev.slice(0, selectedEntitiesIndex), ...prev.slice(selectedEntitiesIndex + 1)]
        : [...prev, { entity, group }]
    })
  }
  useEffect(() => {
    let animation = null
    function animate() {
      setHeaderFixed(myOuterElement?.current.getBoundingClientRect().top < 50)
      animation = window.requestAnimationFrame(animate)
    }
    animation = window.requestAnimationFrame(animate)
    return () => window.cancelAnimationFrame(animation)
  }, [])

  return (
    <S.TimelineWrapper ref={myOuterElement} headerFixed={headerFixed}>
      {headerFixed && <div style={{ height: myTimelineHeaderElement?.current?.getBoundingClientRect()?.height }} />}
      <Timeline
        groups={innerGroups}
        items={innerItems}
        stackItems={stackItems}
        canSelect={false}
        itemTouchSendsClick
        showCursorLine
        sidebarWidth={200}
        canMove={false}
        selectedEntityGroups={selectedEntityGroups}
        resizeDetector={containerResizeDetector}
        itemRenderer={ItemRenderer}
        canResize
        defaultTimeStart={visibleStart}
        defaultTimeEnd={visibleEnd}
        visibleTimeStart={visibleStart}
        visibleTimeEnd={visibleEnd}
        onCanvasClick={handleCanvasClick}
        lineHeight={LINE_HEIGHT}
        itemHeightRatio={LINE_HEIGHT_RATIO}
        groupRenderer={({ group }) => (
          <S.GroupRenderer id={group.id} key={group.id} ref={(e) => myGroupElements.current.push(e)}>
            <S.GroupInner>
              <UserAvatar title={group.username} avatarName={`${group.firstName.charAt(0)}${group.lastName.charAt(0)}`} alt={group.email} email={group.email} userId={group.id}
                          avatarType={group.avatar?.avatarType}/>
              <S.GroupTitle>
                <S.Title>{group.title}</S.Title>
                <S.GroupEntities>
                  {group.belongsTo.map((entity) => (
                    <S.GroupEntity>
                      <Popover key={entity.name} content={entity.name} title={false}>
                        <Tag color={entity.color} size="small"
                             onClick={() => handleToggleSelectedEntityGroups({entity, group})}>
                          {entity.shortName}
                        </Tag>
                      </Popover>
                    </S.GroupEntity>
                  ))}
                </S.GroupEntities>
              </S.GroupTitle>
            </S.GroupInner>
            <S.CalendarMacbookStyleHandlerWrapper>
              <CalendarMacbookStyleHandler
                onExpand={() => handleExpandCalendar({ item: group, events: items.filter((e) => e.group === group.id) })}
              />
            </S.CalendarMacbookStyleHandlerWrapper>
          </S.GroupRenderer>
        )}
      >
        <TimelineMarkers>
          <CustomMarker date={new Date()}>
            {({ styles }) => <div style={{ ...styles, zIndex: 81, backgroundColor: theme.colors.red.primary }} />}
          </CustomMarker>
        </TimelineMarkers>
        <TimelineHeaders>
          <div ref={myTimelineHeaderElement}>
            <DateHeader
              unit="day"
              labelFormat={`${view === 'month' ? 'DD' : 'ddd DD'}`}
              style={{ height: 50 }}
            />
            {view === 'day' && (
              <DateHeader
                unit="hour"
                labelFormat={config.appDefaults.hourFormat}
                style={{ height: 50 }}
              />
            )}
            {view === 'week' && (
              <DateHeader
                unit="hour"
                labelFormat="HH"
                style={{ height: 50 }}
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  const { interval, intervalText } = intervalContext

                  const { startTime } = interval
                  const isMidnight = moment(startTime).hour() === 0
                  if ([0, 6, 12, 18].findIndex((i) => moment(startTime).hour() === i) > -1) {
                    return <S.Hour {...getIntervalProps()} isMidnight={isMidnight}>{intervalText}</S.Hour>
                  }
                  return null
                }}
              />
            )}
          </div>
        </TimelineHeaders>
      </Timeline>
    </S.TimelineWrapper>
  )
}
