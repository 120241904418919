import React from 'react';
import { Table } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import styled from "styled-components";

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const columnFirst = {
  title: 'Sort',
  dataIndex: 'sort',
  width: 30,
  className: 'drag-visible',
  render: () => <DragHandle />,
}

const SortableItem = SortableElement((props) => {
  const { renderRows, ...itemProps } = props
  return renderRows?.(itemProps) || <tr {...itemProps}/>
});

const SortableBody = SortableContainer((props) => {
  return <tbody {...props} />
});

export default (props) => {
  
  const { columns, value, onChange, renderRows } = props
  
  if (!value) return null
  
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(value.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      ).map((field, index) => ({ ...field, index }))
      onChange(newData);
    }
  };
  
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )
  
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = value.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} renderRows={renderRows} onChange={onChange} fields={value} {...restProps} />;
  };

  return (
    <TableWrapper>
      <Table
        pagination={false}
        dataSource={value}
        columns={[ columnFirst, ...columns]}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  row-gap: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  .ant-table {
    border: 0;
  }
`
