import React, { useCallback, useMemo } from 'react'

import _ from 'lodash';
import TreeNodeListSelector from 'components/dist/TreeNodeListSelector'

import { useROEConfiguratorContext } from "../../context";

import { __ } from '../../../../utils/translationUtils'

export default (props) => {

  const {
    getItemFormInitialValue,
    formItems,
    validateFormItems,
    formRef,
    onFormOpen: onFormOpenCallback,
    onFormChange: onFormChangeCallback,
    pinnedKeys,
    left,
    right,
    labels,
    treeFilter = (arr) => arr
  } = props

  const {
    state,
    editStepConfiguration
  } = useROEConfiguratorContext()
  const {
    activeStep,
    configurations,
    attributesTree,
    system,
    object
  } = state || {}

  let value = validateFormItems?.(configurations?.[activeStep]) || configurations?.[activeStep]

  const treeNodeListItems = useMemo(() => {
    const getItemsTreeFromNode = (node) => ({
      key: node?.objectTypeId?.toString(),
      label: node?.label,
      data: treeFilter(node?.data)?.map((item) => {
        return {
          ...item,
          key: item.id,
          label: __(item.propertyLabel),
          type: item.type,
        }
      }) || [],
      children: node?.children?.length ? node?.children.map((child) => getItemsTreeFromNode(child)) : [],
    })

    const selectObjectTree = attributesTree.find((s) => s.name === system.id).data.find((o) => o.label === object.label)
    return getItemsTreeFromNode(selectObjectTree)
  }, [attributesTree, system, object, treeFilter])

  const onFormOpen = useCallback((item) => {
    formRef?.current?.setFieldsValue(value[item.key])
    onFormOpenCallback?.(item, value, formRef)
  }, [formRef, value, onFormOpenCallback])

  const debouncedSubmitValues = useCallback(
    _.debounce((newValue) => (newValue && editStepConfiguration(newValue)), 300), [],
  );

  const onFormChange = useCallback((item, changes) => {
    const newValue = onFormChangeCallback?.(item, changes, value, formRef) || { ...value[item.key], ...changes }
    debouncedSubmitValues({
      ...value,
      [item.key]: newValue,
    })
  }, [onFormChangeCallback, value, formRef, debouncedSubmitValues])

  return (
    <TreeNodeListSelector
      value={value}
      onChange={editStepConfiguration}
      treeItems={treeNodeListItems}
      filters={{
        showNodeSelector: false,
        showTotalResults: false,
        showSummaryTags: false,
      }}
      getItemInitialValue={getItemFormInitialValue}
      formProps={{
        formRef,
        onOpen: onFormOpen,
        onChange: onFormChange,
        items: formItems,
      }}
      treeStyle="tertiary"
      sortIndexName="index"
      left={left}
      right={right}
      pinnedKeys={pinnedKeys}
      labels={{
        checkAll: __('select_all_items'),
        pinnedItems: __('show_pinned_items'),
        noTreeDataTitle: __('attributes_no_data_title'),
        noTreeDataContent: __('attributes_no_data_content'),
        search: __('search_items'),
        ...labels
      }}
    />
  )
}
