import React, {useMemo} from 'react'
import { hashHistory } from '../providers/HistoryProvider';
import * as config from '../constants/globalConfiguration';
import CustomGrid from "./CustomGrid";
import { isSelectingText } from "../utils/misc";
import { __ } from '../utils/translationUtils'

const StepObjectNode = ({renderStepObject, buttons = null, attributes, displayAttributes, values, maxColumns = 2, minColumns = 2, mainKey, type, subObjectInfos}) => {

  const handleSelection = (subObjectInfos, type = '') => {
    if (type === 'ROE') {
      if (!isSelectingText()) {
        hashHistory.push(`/${config.remoteObjectDetailsUrl}/${subObjectInfos.configurationId}/${subObjectInfos.objectId}/${subObjectInfos.entryId}`);
      }
    }
  }


  const items = useMemo(() => {
    const newItems = []
    if (attributes?.length && values?.length && displayAttributes?.length) {
      for (let i = 0; i < displayAttributes.length; i++) {
        let controllerType = displayAttributes?.[i]?.controllerType || displayAttributes?.[i]?.defaultControllerType
        if (!(controllerType)) {
          console.error("It's impossible to render a field without a controller type. Defaulting to TextEntry. Please contact your administrator.")
          controllerType = 'TextEntry'
        }
        if (attributes.find(a => a.id === displayAttributes[i].attributeId)) {
          newItems.push(renderStepObject(__(attributes[i].propertyLabel), controllerType, values[i], attributes[i].id, mainKey))
        }
      }
    }
    return newItems.filter((i) => i)
  }, [attributes, displayAttributes, mainKey, renderStepObject, values])

  return (
      <div className="stepObject" onClick={() => handleSelection(subObjectInfos, type) }>
        <div className="text-right">
          {buttons}
        </div>
        <CustomGrid
          items={items}
          margin={[25, 4]}
        />
    </div>
  )
}

export default StepObjectNode
