import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Select, Space, Switch, Tooltip, Typography} from 'antd'
import localeCode from 'locale-code'
import { logout, setLanguage} from '../providers/ReduxProvider/actions/userActions'
import {resetEnv} from '../utils/appHelper'
import UserAvatar from "./UI/UserAvatar";
import _ from "lodash";

import loudspeaker from '../images/loudspeaker.svg'
import iconLogout from '../images/icon-logout-2.svg'
import { Button } from 'components'
import { hashHistory } from '../providers/HistoryProvider'
import { __ } from '../utils/translationUtils'

const { Option } = Select

class UserInfo extends Component {
  static propTypes = {
    user: PropTypes.object,
    authToken: PropTypes.string,
    handleLogout: PropTypes.func,
    setLanguage: PropTypes.func,
    updateLanguage: PropTypes.func,
    language: PropTypes.string,
    availableLanguages: PropTypes.instanceOf(Array)
  };

  constructor(props) {
    super(props)
    this.state = {
      languages: props.availableLanguages || [],
      currentLanguage: props.language
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    const { availableLanguages, language } = props
    const { languages, currentLanguage } = current_state
    const updateLanguages = availableLanguages && !_.isEqual(availableLanguages, languages)
    const updateCurrentLanguage = language !== currentLanguage
    if (updateLanguages || updateCurrentLanguage)
      return {
        ...current_state,
        languages: updateLanguages ? availableLanguages : languages,
        currentLanguage: updateCurrentLanguage ? language : currentLanguage
      }
    return current_state
  }

  handleLanguageChange(key) {
    this.props.setLanguage(key)
  }

  render() {
    const { currentLanguage, languages} = this.state
    const { user } = this.props
    const languageNames = languages.map((l) => l.language)
    const skipTranslations = localStorage.getItem('skipTranslations') === 'true'

    function getFlagEmoji(countryCode) {
      let cc = countryCode.toUpperCase()
      if(cc.length>2){
        cc = cc.substr(3,2)
      }
      const codePoints = cc
        .toUpperCase()
        .split('')
        .map(char =>  127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }

    return (
      <div className="user-info">
        {
          localStorage.getItem('env') && (
            <Button
              shape='circle'
              icon='api'
              direction="down"
              theme="error"
              items={[
                {
                  shape: "circle",
                  icon: 'edit',
                  theme: "error",
                  onClick: () => {
                    hashHistory.push('/custom-system-env')
                  }
                },
                {
                  shape: "circle",
                  icon: 'reload',
                  theme: "error",
                  onClick: () => {
                    this.props.logout(() => {
                      resetEnv()
                      window.location.reload()
                    })
                  }
                }
              ]}
            />
          )
        }
        {this.props.user
          && (
            <div>
              <Select
                value={currentLanguage}
                onChange={this.handleLanguageChange.bind(this)}
                className={'language-selector'}
                bordered={false}
                dropdownMatchSelectWidth={false}
                optionLabelProp='title'
                dropdownRender={menu => (
                  <>
                    {menu}
                    {this.props?.user?.matchingTemplates?.includes('ROOT') &&
                      <Space align='center' style={{padding: '7px 15px', borderTop: '1px solid #F5F7FA'}}>
                        <Switch size='small' checked={!skipTranslations} onClick={() => {
                          localStorage.setItem('skipTranslations', (!skipTranslations).toString())
                          window.location.reload()
                        }}/>
                        <Typography.Text type='secondary'>Translations</Typography.Text>
                      </Space>
                    }
                  </>
                )}
              >
                {languages.map((l) => {
                  let title = l.language
                  if (languageNames.filter((name) => name === l.language).length > 1) {
                    title = `${l.language}${l.languageCode ? ` (${localeCode.getCountryCode(l.languageCode.replace('_', '-'))})` : ''}`
                  }
                  return (
                    <Option
                      key={l.languageCode}
                      value={l.languageCode}
                      title={<Typography.Text
                        delete={skipTranslations}>{` ${title} ${getFlagEmoji(l.languageCode)}`}</Typography.Text>}
                    >
                      <Row justify='space-between'>
                        <Typography.Text>{`${title} `}</Typography.Text>
                        <div>
                          <Typography.Text type='secondary' style={{fontSize: '12px'}}>
                            {` ${__('country')}: `}
                          </Typography.Text>
                          {getFlagEmoji(l.languageCode)}
                        </div>
                      </Row>
                    </Option>
                  )
                })}
              </Select>

              <div>
                <UserAvatar userId={user.id} alt={user.email} email={user.email} title={user.username}
                            avatarName={`${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`}
                            avatarType={user?.avatar?.avatarType}/>
              </div>
              <a
                hidden={(!(this.props.user?.matchingTemplates?.includes('ROOT') || this.props.user?.matchingTemplates?.includes('PA') || (this.props.user?.matchingTemplates?.includes('OA') && (this.props.user?.belongsTo.filter((a) => (a.shortName === 'SIG')).length))))}
                id="notificationTrigger">
                <img className="beamerImage" width="24" height="24" src={loudspeaker}/>
                <span style={{position: 'absolute', top: '27px'}}>{__('What\'s new')}</span>
              </a>
            </div>
          )}

        {this.props.authToken && (
          <a onClick={this.props.handleLogout}>
            <img className="beamerImage" width="24" height="24" src={iconLogout}/>
          </a>
    )
  }
  </div>
  )
  }
}

export default connect(
  (state) => ({
    language: state.ui.language,
    authToken: state.user.authToken,
    availableLanguages: state.ui.availableLanguages
  }),
  { logout, setLanguage }
)(UserInfo)
