export const ERROR = 'ERROR'

export const NO_OP = 'NO_OP'

export const FETCHING_DATA = 'FETCHING_DATA'

export const INITIALIZED = 'INITIALIZED'

export const UPDATE_ENUMS = 'UPDATE_ENUMS'

export const UPDATE_REMOTE_OBJECT_ITEMS = 'UPDATE_REMOTE_OBJECT_ITEMS'

export const UPDATE_VALUE = 'UPDATE_VALUE'
