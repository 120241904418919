import { api } from "./index";
import { ERROR } from "components/dist/Utils/LoggerUtils";


export const authenticate = (credentials) => api.post('auth/login', credentials)

export const fetchAvailableEntities = () =>
  api.get('register/availableEntities')
    .then(
      (response) => response.data,
      (error) => {
        ERROR(error)
      }
    )

export const fetchEntityByName = (entityName) =>
  api.get(`register/availableEntities/${entityName}`)
    .then(
      (response) => response.data,
      (error) => {
        ERROR(error)
      }
    )
