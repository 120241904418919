import React from "react";
import {__, T} from '../../../utils/translationUtils'
import {Space} from "antd";
import {Button, Form, Modal} from "components";
import {FormWrapper} from "../../../pages/UserPage/StatusChange/styles";
import {exportColumnsInputs, ExportColumnsType} from "./config";

type ExportColumnsModalProps = {
  summaryDisplayAttributesItems: { value: string, label: string }[]
  submit: (formValues: { exportColumns: ExportColumnsType, selected_columns?: { value: string, label: string }[] }) => void
  onClose: () => void
}

const ExportColumnsModal = ({ summaryDisplayAttributesItems, submit, onClose }: ExportColumnsModalProps) => {
  return (
    <Modal
      title={__('export_columns')}
      visible
      size="small"
      onCancel={onClose}
      footer={
        <>
          <Button
            onClick={() => onClose()}
            title={__(T.cancel)}
            type={'text'}
          />
          <Button
            form="export-columns-form"
            type='filled'
            title={__(T.save)}
          />
        </>
      }
      destroyOnClose
    >
      <Space direction="vertical">
        <FormWrapper>
          <Form
            id="export-columns-form"
            key="export-columns-form"
            layout="vertical"
            showSubmitButton={false}
            initialValues={{ exportColumns: 'all' }}
            onSubmit={submit}
            inputs={exportColumnsInputs({data: { summaryDisplayAttributesItems }, __})}
          />
        </FormWrapper>
      </Space>
    </Modal>
  )
}

export default ExportColumnsModal
