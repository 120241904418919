import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { hashHistory } from '../providers/HistoryProvider';

import { api } from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'

class SelectPairModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedPair: null,
      userAssignmentPairs: [],
    };

    this.getAvailableAssignees = this.getAvailableAssignees.bind(this);
  }

  componentDidMount() {
    this.getAvailableAssignees();
  }

  getAvailableAssignees() {
    const { processId } = this.props;
    const { guiUserId } = this.props;

    if (!processId) {
      return;
    }

    api.get(`/processes/${processId}/ownership/availableAssignees?userIds=${guiUserId}`)
      .then(
        (response) => {
          this.setState({
            userAssignmentPairs: response.data,
          });
        });
  }

  /**
   * I change this in order to use the more linear code without closing the modal or setting the state twice
   * **/
  onSubmit() {
    const { componentActive } = this.props;
    if (componentActive && componentActive === 'AppointmentDetailsModal') {
      this.props.onSubmit(JSON.parse(this.state.selectedPair));
    } else {
      this.props.onSubmit(JSON.parse(this.state.selectedPair))?.then(() => this.props.closeModal());
    }
  }

  /*
   * Create this function to handle the hide pop function,
   * since this component is called in different situations now
   */
  handleHide() {
    const { componentActive } = this.props;
    if (componentActive && componentActive === 'AppointmentDetailsModal') {
      this.props.closeModal();
    } else {
      this.goToOrders();
    }

  }

  goToOrders() {
    hashHistory.goBack();
  }

  render() {
    const { componentActive, open } = this.props;
    return (
      <Modal
        backdrop="static"
        //bsSize="md"
        className="appointment-details"
        show={open}
        onHide={this.handleHide.bind(this)}>

        <Modal.Header closeButton>
          <Modal.Title className="capitalize">{__('Select Organisation')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="step-info">
            {componentActive && componentActive === 'AppointmentDetailsModal' ? __('SelectPairAppointDetailsModal') : __('SelectPairInfoText')}
          </p>

          <form className="form-horizontal">
            <div className="form-group">
              <label htmlFor="inputName"
                className="col-sm-4 control-label text-right">{__('Organisation') + '*'}:</label>
              <div className={'col-sm-6'}>
                <select
                  onChange={(e) => {
                    let options = e.target.options;
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        this.setState({
                          selectedPair: options[i].value
                        });
                      }
                    }
                  }}
                  name="assignees"
                  className="form-control"
                >
                  <option value={''} key={'pair-select'}>{__('Select one')}</option>
                  {
                    this.state.userAssignmentPairs.map((pair) => {
                      return <option value={JSON.stringify(pair)} key={`pair-${pair.userid}-${pair.entityId}`}>{`${pair.user.username} ${pair.entity.shortName}`}</option>;
                    })
                  }
                </select>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={!this.state.selectedPair}
            className="pull-right btn-doo"
            onClick={this.onSubmit.bind(this)}
          >
            DOO
          </Button>

        </Modal.Footer>

      </Modal>
    );
  }

}

SelectPairModal.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  componentActive: PropTypes.string,
  processId: PropTypes.string,
  guiUserId: PropTypes.string
};

export default SelectPairModal;
