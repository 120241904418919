import {Link} from "react-router-dom";
import {Icon} from "@ant-design/compatible";
import {Tooltip} from "antd";
import React from "react";
import { __ } from '../utils/translationUtils'

const ActionIconContent = ({action, disabled, containerClass, iconClass, theme, type}) => (
  <div className={containerClass} onClick={action}>
    <Icon className={iconClass} type={type} theme={theme} disabled={disabled} />
  </div>
)

const ActionIconContainer = ({title, link, children}) => (
  <Tooltip title={__(title)}>
    { link ?
      <Link to={link}>
        {children}
      </Link>
      :
      <div>
        {children}
      </div>
    }
  </Tooltip>
)

const ActionIcon = (props) => {
  const {
    title,
    type,
    link,
    action,
    containerClass = "btn-no-padding btn-std",
    iconClass = "icon-std",
    theme = "outlined",
    disabled
  } = props || {}
  return (
    <ActionIconContainer title={title} link={link}>
      <ActionIconContent
        action={action}
        disabled={disabled}
        containerClass={containerClass}
        iconClass={iconClass}
        theme={theme}
        type={type}
      />
    </ActionIconContainer>
  )
}

export default ActionIcon
