import React from 'react';
import PropTypes from 'prop-types';
import { renderField, stepFieldRequired } from '../../utils/fieldValidator';
import { Field} from 'redux-form';
import moment from 'moment';
import { __ } from '../../utils/translationUtils'

function stepDateTimeFieldValidator(value){
  if(!value){
    return undefined;
  }
  const date = moment(value);
  // Validate value
  if(!date.isValid() ){ // Vaule is valid.
    return __('dateTimevalueFormatError');
  }else{ // Value is not valid.
    return undefined;
  }
}

class DateTimeControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props)
  }


  stepValidation(){

    const { controlData } = this.props;
    let validation = [];

    if(controlData.required){
      validation.push(stepFieldRequired);
    }


    validation.push(stepDateTimeFieldValidator);

    if(validation.length === 0){
      return undefined;
    }


    return validation;
  }

  render() {

    const { controlData } = this.props;
    const req = (controlData.required)?'*':'';
    return (
      <Field
        minValue={controlData && controlData.attributes.extendedProperties && controlData.attributes.extendedProperties.minValue ? controlData.attributes.extendedProperties.minValue:null}
        maxValue={controlData && controlData.attributes.extendedProperties && controlData.attributes.extendedProperties.maxValue ? controlData.attributes.extendedProperties.maxValue:null}
        disabled={controlData.readOnly}
        name={controlData.id}
        description={controlData.description}
        component={renderField}
        label={__(controlData.title)+req}
        className="form-control"
        containerClassName="label-control"
        type="datetime"
        validate={this.stepValidation.bind(this)()}
      />
    );
  }

}


export default DateTimeControl;
