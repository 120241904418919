import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {renderField, stepFieldRequired} from '../../utils/fieldValidator';
import {sortEnumItems} from "../../utils/enums";
import { __ } from '../../utils/translationUtils'
import { getSelectorControllerItems } from '../../utils/appHelper'

class RadioButtonControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object,
    inputClass: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      enumValues: []
    }
  }

  stepValidation() {

    const {controlData} = this.props;
    let validation = [];

    if (controlData.required) {
      validation.push(stepFieldRequired);
    }
    if (validation.length == 0) {
      return undefined;
    }


    return validation;
  }

  componentDidMount() {
    this.initializeEnumValues()
  }

  async initializeEnumValues() {
    const {controllerOptions, attributes} = this.props.controlData;
    const orderingType = controllerOptions?.sortOptions?.ordering
    const options = await getSelectorControllerItems(controllerOptions, attributes)
    const sortedItems = sortEnumItems(options, orderingType)
    this.setState({enumValues: sortedItems})
  }


  render() {

    const {controlData} = this.props;

    return (
      <div className="form-group">
        <label className="col-sm-3 col-md-3 col-lg-2 control-label">{__(controlData.title)}{controlData.required ? '*' : ''}</label>
        <div className="col-sm-9 col-md-6 col-lg-6">
          <Field
            name={controlData.id}
            description={controlData.description}
            items={(this.state.enumValues || [])}
            component={renderField}
            inputCol={this.props.inputCol}
            type={controlData.valueType === "YESNO" ? "YESNO" : "RadioButton"}
            validate={this.stepValidation.bind(this)()}/>
        </div>
      </div>
    );

  }

}

export default RadioButtonControl;
