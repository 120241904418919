import React, { useCallback, useEffect, useState } from "react";

import { Glyphicon } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment/moment";

import { entityUrl, fetchViewsResultsFilteredByROE } from "../../../../../utils/appHelper";
import { appDefaults } from "../../../../../constants/globalConfiguration";
import { ATTRIBUTES } from '../../../../../constants'
import Table from "../../../../UI/Table";
import { Card, CardTitle, Header } from "./SubObject/styles";
import { __ } from '../../../../../utils/translationUtils'


const columns = [
  {
    title: "Overview",
    dataIndex: ATTRIBUTES.ORDER.ID,
    key: ATTRIBUTES.ORDER.ID,
    render: (value) => (
      <Link to={`/orders/${value}/overview`} target="_blank">
        <Glyphicon glyph="list" style={{ marginRight: "1rem" }} />
        {__("Order Overview")}
      </Link>
    ),
  },
  {
    title: "External Order ID",
    dataIndex: ATTRIBUTES.ORDER.EXTERNAL_ORDER_ID,
    key: ATTRIBUTES.ORDER.EXTERNAL_ORDER_ID,
  },
  {
    title: "Status",
    dataIndex: ATTRIBUTES.ORDER.ORDER_STATUS,
    key: ATTRIBUTES.ORDER.ORDER_STATUS,
    render: (value) => __(value),
  },
  {
    title: "Order Type",
    dataIndex: ATTRIBUTES.ORDER_TYPE.NAME,
    key: ATTRIBUTES.ORDER_TYPE.NAME,
    render: (value) => (
      <Link to={entityUrl("ORDER_TYPE", value?.id)}>
        {value?.value}
      </Link>
    ),
  },
  {
    title: "Last Update",
    dataIndex: ATTRIBUTES.ORDER.LAST_UPDATE_DATETIME,
    key: ATTRIBUTES.ORDER.LAST_UPDATE_DATETIME,
    render: (value) => value ? moment(value)?.format?.(appDefaults.dateFormat) : '-'
  },
  {
    title: "Creation Date",
    dataIndex: ATTRIBUTES.ORDER.CREATION_DATE_TIME,
    key: ATTRIBUTES.ORDER.CREATION_DATE_TIME,
    render: (value) => value ? moment(value)?.format?.(appDefaults.dateFormat) : '-'
  },
];

const OrdersTable = (props) => {
  const { id, ROId, ROEId, highlightedSubObjectId } = props
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)

  const handlePageChange = (page, pageSize) => {
    setPagination((pagination) => {
      const nextPagination = {
        ...pagination,
        current: page,
        pageSize: pageSize,
      }
      getData(ROId, ROEId, nextPagination)
      return nextPagination
    });
  }

  const PAGINATION_INITIAL_STATE = {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    sorter: null,
    onChange: handlePageChange,
  }

  const [pagination, setPagination] = useState(PAGINATION_INITIAL_STATE)

  const getOrdersPostPayload = useCallback((id, columns = [], pagination) => ({
    id: id,
    pageSize: pagination.pageSize,
    pageNumber: pagination.current,
    displayAttributes: columns.map((column) => ({
      attributeId: column.key,
      summary: true,
      position: 0
    })),
    sortAttributes: [],
    searchAttributes: []
  }), [])

  const getData = useCallback((ROId, ROEId, pagination) => {
    setLoading(true)
    fetchViewsResultsFilteredByROE(ROId, ROEId, getOrdersPostPayload(ROEId, columns, pagination))
      .then((resp) => {
        setPagination({
          ...pagination,
          total: resp?.headers?.["x-total-count"],
          showTotal: () => `${resp?.headers?.["x-total-count"] || 0} ${__('total_items')}`,
        })
        setOrders(resp?.data?.map((order) => order?.displayData))
      })
      .catch(() => {
        setOrders(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!ROId || !ROEId) return
    getData(ROId, ROEId, pagination)
  }, [ROId, ROEId])

  return (
    <Card
      id={id}
      highlight={highlightedSubObjectId === id}
    >
      <Header>
        <CardTitle>
          {__("godoo orders")}
        </CardTitle>
      </Header>
      <Table
        data={orders}
        columns={columns}
        loading={loading}
        locale={!orders && { emptyText: __('Error') }}
        pagination={pagination}
        tableLayout="fixed"
        key={"godooOrdersTable"}
      />
    </Card>
  )
}

export default OrdersTable
