import * as types from '../types';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function userUpdate(state = initialState.enumValues, { type, payload }) {
  const getNewState = (state, payload) => {
    const newState = objectAssign({}, state);
    newState[payload.id] = payload.enumerationValues;
    return newState;
  };

  switch (type) {
    case types.ENUMVALUES_GOT_DATA:
      return getNewState(state, payload);

    default:
      return state;
  }
  
}
