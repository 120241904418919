import * as types from '../types';
import { hashHistory } from '../../HistoryProvider';
import { showMessage } from '../../../utils/appHelper';

import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function getOrder(key) {
  return function (dispatch) {
    return api.get('/orders/'+key)
    .then(
      response => {
        dispatch({
          type: types.ORDERS_GOT_DATA,
          payload: response.data
        });
      },
      error => {
        dispatch({
          type: types.ORDERS_GET_DATA_ERROR,
          payload: error.response.data.message
        });
      },
    );
  };
}

export function createOrder(payload) {
  return async function () {
    return api.post('/orders', payload)
    .then(
      response => {

        if ([200, 201].includes(response.status)) {
            hashHistory.push("/");
          showMessage('success', __('Order created successfully'));
        }
      }
    )
    .catch( (error) => {
      showMessage('error', __('ServerSideError') + '. ' + error);
    });
  };
}

