import React, { useCallback, useEffect, useMemo } from "react";

import { hashHistory } from "../../../providers/HistoryProvider";
import { showMessage } from "../../../utils/appHelper";
import Footer from '../../../components/ConfigurationConfiguratorBeta/Footer'
import { useROEConfiguratorContext } from "../context";
import * as C from '../constants'
import * as U from '../utils'
import { useSelector } from "react-redux";
import { getGuiUser } from "../../../providers/ReduxProvider/actions/userActions";
import { validateViewProperties } from "../utils/validation";
import { message } from "antd";
import { __, T } from '../../../utils/translationUtils'

export default (props) => {

  const {
    theme,
    ROEId
  } = props

  const {
    state,
    setActiveStep,
    setStepValidationVisibility,
  } = useROEConfiguratorContext()

  const {
    system,
    object,
    configurations,
    mode,
    backendPayload,
    validationState,
    activeStep,
    apis,
  } = state

  const user = useSelector((state) => state?.user)

  const submitDisabled = useMemo(() => (
    !system.id || !object.id || Object.keys(validationState).some((key) => !validationState[key])
  ), [system, object, validationState])

  useEffect(() => {
    if (!user) getGuiUser()
  }, [user])

  const handleSubmit = () => {
    if (!validateViewProperties(configurations[C.steps.LISTING_VIEW_PROPERTIES])) return
    const payload = U.mapConfigurationPayload.FrontendToBackend(
      state.configurations,
      user.data.id,
      system.id,
      object.id,
      backendPayload
    )
    switch (mode) {
      case C.mode.CREATE:
        apis.createRemoteObjectConfiguration?.(payload).then((resp) => {
          if (resp.status === 200) {
            hashHistory.goBack()
            showMessage('success', __('configuration_create_success'))
          }
        })
        break
      case C.mode.EDIT:
        apis.updateRemoteObjectConfiguration?.(payload, ROEId).then((resp) => {
          if (resp.status === 200) {
            hashHistory.goBack()
            showMessage('success', __('configuration_update_success'))
          }
        })
        break
      default:
        return
    }
  }

  const handleNavigation = useCallback((steps) => {
    if (validationState[activeStep] != null && !validationState[activeStep]) {
      setStepValidationVisibility(C.validationActivatedAfter.CLOSE)
      message.error(
        __((
          [C.steps.LISTING_OUTPUT_FIELDS, C.steps.CONTENT_CHOOSE_FIELDS].includes(activeStep) && T.chooseFieldsValidationError)
          || (activeStep === C.steps.LISTING_VIEW_PROPERTIES && T.viewPropertiesValidationError)
        )
      )
    }
    else {
      setActiveStep(activeStep + steps)
    }
  }, [activeStep, setActiveStep, setStepValidationVisibility, validationState])

  const disabledNavigation = useMemo(() => {
    const canLeaveStep = system.id && object.id
    return {
      back: !activeStep || (activeStep === C.steps.LISTING_PREVIEW && mode === C.mode.EDIT) || !canLeaveStep,
      next: activeStep === C.steps.SELECT_RELATED_OBJECTS || !canLeaveStep,
    }
  }, [activeStep, mode, object, system])

  return (
    <Footer
      theme={theme}
      buttons={[
        {
          key: 'Back',
          disabled: disabledNavigation.back,
          title: __('Back'),
          onClick: () => handleNavigation(-1),
          backgroundColor: theme === 'dark' && '#3d729c',
          borderColor: theme === 'dark' ? '#FFFFFF' : '#72777F'
        },
        {
          key: 'Next',
          disabled: disabledNavigation.next,
          title: __('Next'),
          onClick: () => handleNavigation(1),
          backgroundColor: theme === 'dark' && '#3d729c',
          borderColor: theme === 'dark' ? '#FFFFFF' : '#72777F'
        },
        {
          key: 'Submit',
          type: 'primary',
          disabled: submitDisabled,
          title: __(mode === C.mode.CREATE ? 'Create' : 'Save'),
          color: theme === 'dark' && '#00629D',
          backgroundColor: theme === 'dark' && '#FFFFFF',
          onClick: handleSubmit
        }
      ]}
    />
  )

}
