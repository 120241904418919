import React, {useCallback, useEffect, useMemo, useState} from 'react'
import md5 from 'md5'
import {Link} from 'react-router-dom'
import {Tooltip} from 'antd'
import * as S from './styles'
import {getLogoBase64} from "../../EntityAvatar/utils";
import {getUserLogoUrl} from "../../../utils/appHelper";
import {GRAVATAR_AVATAR_TYPE, INITIAL_AVATAR_TYPE, CUSTOM_PICTURE_AVATAR_TYPE} from "../../EntityAvatarForm";


/**
 * @param name: {firstName: string, lastName: string}
 * @returns {JSX.Element|null}
 * @constructor
 */
export default ({email, alt, title, userId, avatarType, avatarName}) => {

  const href = useMemo(() => `/users/${userId}`, [userId])
  const [fileBase64, setFileBase64] = useState(null)
  const [fetchAtStart, setFetchAtStart] = useState(true)

  const retrieveFileBase64 = useCallback(async () => {
    const fileBase64 = await getLogoBase64(userId, getUserLogoUrl)
    setFileBase64(fileBase64)
  }, [userId])

  useEffect(() => {
    if (fetchAtStart && avatarType === CUSTOM_PICTURE_AVATAR_TYPE) {
      retrieveFileBase64()
      setFetchAtStart(false)
    } else if (fetchAtStart){
      setFetchAtStart(false)
    }
  }, [avatarType, fetchAtStart, retrieveFileBase64])

  // Still need to fetch logo
  if (fetchAtStart) return null

  return (<Tooltip title={title}>
    <Link to={href} href={href}>
      <S.Gravatar>

        {
          fileBase64 && avatarType === CUSTOM_PICTURE_AVATAR_TYPE ? <S.Img alt={alt || email} src={`${fileBase64}`}/> : null
        }

        {
          avatarType === GRAVATAR_AVATAR_TYPE ? <S.Img alt={alt || email} src={`https://www.gravatar.com/avatar/${email && md5(email)}?s=1500&d=https://www.godoo.ch/en/icons/icon-512x512.png?v=d57b514fa910e3ff9d98c1ae52bfb7d7`}/> : null
        }

        {
          avatarType === INITIAL_AVATAR_TYPE ? <S.Img alt={alt || email} src={`https://eu.ui-avatars.com/api/?size=512&name=${avatarName}`}/> : null
        }

      </S.Gravatar>
    </Link>
  </Tooltip>)
}
