import styled, { css } from "styled-components";

import { Button, Pagination } from 'antd'

const highlightCard = css`
  color: #00629d;
  border-left: 4px solid #004671 !important;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);

  border-left: 4px solid transparent;
  ${(props) => props.highlight && highlightCard}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  > *:not(:last-child) {
    margin-right: 15px;
  }
`

export const CardTitle = styled.div`
  color: #00629d;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  text-overflow: ellipsis;
`

export const CustomButton = styled(Button)`
  &.ant-btn {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #72777f;
    border-radius: 100px;
    background-color: #d5e4f7;

    span {
      margin-left: 0;
    }

    &:focus {
      color: #000;
    }

    &:hover {
      color: #00629d;
      background: rgba(0, 98, 157, 0.08);
    }

  }
`

export const CardListWrapper = styled.div`
  &>div:not(:last-child) {
    margin-bottom: 5px;
  }
`

export const CustomCard = styled.div`
  position: relative;
  padding: 10px;
  border: 1px solid #C6C6C9;
  border-radius: 4px;
  transition: .1s ease;
  background-color: rgb(0, 98, 157, 0.01);
  > .btn-unlink {

  > .btn-unlink {
    opacity: .5;
    transition: .25s ease;
  }

  &:hover {
    background-color: #1A1C1E08;
    cursor: pointer;
    transition: all 0.1s ease;
    border-radius: 4px;
    > .btn-unlink {

    > .btn-unlink {
      opacity: 1;
    }
  }
`

export const RemoveButton = styled.div`
  border-radius: 50%;
  padding: 8px;
  width: min-content;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 6px;
  top: 6px;
  transition: .25s ease;
  border: 1px solid #dcdcdc;

  background-color: white;
  box-shadow: unset;

  &:hover {
    background-color: #F5F5F5;
    box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  }
`

export const CardPagination = styled(Pagination)`
  margin-Top: 0
`
