export const mode = {
  CREATE: 'CREATE',
  EDIT: 'EDIT'
}

export const steps = {
  SYSTEM_OBJECT_SELECTION: 0,
  LISTING_PREVIEW: 1,
  LISTING_OUTPUT_FIELDS: 2,
  LISTING_FILTERS: 3,
  LISTING_SORTING_PARAMETERS: 4,
  LISTING_VIEW_PROPERTIES: 5,
  CONTENT_PREVIEW: 6,
  CONTENT_CHOOSE_FIELDS: 7,
  CONTENT_ORGANISE_FIELDS: 8,
  SELECT_RELATED_OBJECTS: 9,
}

export const validationInitialState = {
  [steps.LISTING_OUTPUT_FIELDS]: false,
  [steps.LISTING_VIEW_PROPERTIES]: false,
  [steps.CONTENT_CHOOSE_FIELDS]: false,
}

export const defaultCellSizeOptions = [
  { label: 'XS', value: 2 },
  { label: 'S', value: 3 },
  { label: 'M', value: 4 },
  { label: 'L', value: 6 },
  { label: 'XL', value: 12 },
]

export const defaultCellDimension = {
  w: 3,
  h: 1,
}

export const gridConfiguration = {
  cols: 12,
}

export const previewContent = {
  [mode.CREATE]: {
    [steps.LISTING_PREVIEW]: {
      title: "ro_configuration_listing_preview_title",
      message: 'ro_configuration_listing_preview_content_create',
      info: {
        title: 'ro_configuration_listing_preview_info_title',
        message: 'ro_configuration_listing_preview_info_content'
      }
    },
    [steps.CONTENT_PREVIEW]: {
      title: "ro_configuration_content_preview_title",
      message: 'ro_configuration_content_preview_content',
      info: {
        title: 'ro_configuration_content_preview_info_title',
        message: 'ro_configuration_content_preview_info_content'
      }
    }
  },
  [mode.EDIT]: {
    [steps.LISTING_PREVIEW]: {
      title: "ro_configuration_listing_preview_title",
      message: 'ro_configuration_listing_preview_content_edit',
      info: {
        title: 'ro_configuration_listing_preview_info_title',
        message: 'ro_configuration_listing_preview_info_content'
      }
    },
    [steps.CONTENT_PREVIEW]: {
      title: "ro_configuration_content_preview_title",
      message: 'ro_configuration_content_preview_content',
      info: {
        title: 'ro_configuration_content_preview_info_title',
        message: 'ro_configuration_content_preview_info_content'
      }
    }
  }
}

export const configuratorInitialState = {
  [steps.LISTING_OUTPUT_FIELDS]: {},
  [steps.LISTING_FILTERS]: {},
  [steps.LISTING_SORTING_PARAMETERS]: {},
  [steps.LISTING_VIEW_PROPERTIES]: {defaultPageSize: 25},
  [steps.CONTENT_CHOOSE_FIELDS]: {},
  [steps.CONTENT_ORGANISE_FIELDS]: {}
}

export const validationActivatedAfter = {
  EDIT: 'EDIT',
  CLOSE: 'CLOSE',
}

export const mechanism = {
  SPECIFIC_VALUE: 'SPECIFIC_VALUE',
  RANGE: 'RANGE',
  ENUMERATION: 'ENUMERATION'
}

export const sortMethod = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC'
}

export const pagination = [5, 10, 25, 50, 100]

export const availableListingLayouts = {
  CARD: 'CARD',
  TABLE: 'TABLE'
}
