import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import _ from 'lodash';
import { Field } from 'redux-form';
import RightsParam from './RightsParam';

class RightsWildCard extends React.Component {

  static propTypes = {
    data: PropTypes.array,
    wildcards: PropTypes.object,
    resourceName: PropTypes.string,
    methodName: PropTypes.string,
    uri: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  getWCObject() {
    let found = _.find(this.props.data, (o) => {
      if(typeof o.wildcard !== 'undefined' && typeof this.props.wildcards !== 'undefined'){
        return o.wildcard.name === this.props.wildcards[this.props.resourceName+'_'+this.props.uri+'_'+this.props.methodName + '_' +this.props.data[0].wildcard.position];
      }
    });
    return found;
  }

  render() {
    const wcoObj = this.getWCObject();
    return (

      <div>

        {this.props.data.constructor === Array && this.props.data[0].wildcard &&
        <FormGroup controlId="formHorizontalEmail">
          <Col componentClass={ControlLabel} sm={2}>
            {'{'+this.props.data[0].wildcard.position+'}'}:
          </Col>
          <Col sm={5}>

            <Field
              className="form-control"
              name={this.props.resourceName+'_'+this.props.uri+'_'+this.props.methodName + '_' +this.props.data[0].wildcard.position}
              component="select"
              type="text">
              <option value="">Select...</option>
              {
                this.props.data.map((wcoption, k)=>{
                  return (
                    <option key={'wc_opt_'+k} value={wcoption.wildcard.name}>{wcoption.wildcard.description}</option>
                  );
                })
              }
            </Field>

          </Col>
        </FormGroup>
        }
        {
          wcoObj && wcoObj.availableOperators &&
          <RightsWildCard data={wcoObj.availableOperators} />
        }
        
        {
        wcoObj && wcoObj.requestParams && wcoObj.requestParams.length > 0 &&
        <div>
          <h5>Request Params:</h5>
          {
            wcoObj.requestParams.map((param, k)=>{
              return <RightsParam key={k} data={param} />;
            })
          }
        </div>
        }

      </div>
    );
  }

}

export default connect(
  state => {
    return {
      wildcards: state.form.templateRightsForm && state.form.templateRightsForm.values
    };
  },
  {}
)(RightsWildCard);
