export default (type) => {
  switch (type) {
    case 'TABLE': return {defaultH: 4, defaultW: 4, minH: 2, minW: 2};
    case 'PIE': return {defaultH: 2, defaultW: 2, minH: 1, minW: 1};
    case 'LINE': return {defaultH: 2, defaultW: 4, minH: 1, minW: 2};
    case 'AREA': return {defaultH: 2, defaultW: 4, minH: 1, minW: 2};
    case 'BAR': return {defaultH: 2, defaultW: 2, minH: 1, minW: 2};
    case 'NUMBER': return {defaultH: 1, defaultW: 1, minH: 1, minW: 1};
    default: return {defaultH: 2, defaultW: 2, minH: 1, minW: 1};
  }
}
