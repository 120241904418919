import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { resetPassword } from '../../providers/ReduxProvider/actions/userActions';
import { Authentication as Auth } from 'components'
import { __, T } from '../../utils/translationUtils'
import getErrorText from "../../utils/getErrorText";
import { resetPasswordValidationSchema } from './validation';

const textCommons = { T, __, getErrorText }

const ResetPasswordPage = (props) => {
  const { resetPassword, match, location } = props

  const handleSubmit = values => {
    let payload = { code: match?.params?.token, password: values?.password || '' }
    resetPassword(payload)
  }

  return (
    <Auth.ResetPasswordForm
      handleSubmit={handleSubmit}
      textCommons={textCommons}
      validationSchema={resetPasswordValidationSchema()}
      queryString={location.search}
    />
  )
}

ResetPasswordPage.propTypes = {
  resetPassword: PropTypes.func,
  match: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default connect(
  null,
  { resetPassword }
)(ResetPasswordPage);


