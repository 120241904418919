import React from 'react';
import {Modal, Space, Typography} from 'antd';
import moment from 'moment';
import FieldTimeOutlined from "@ant-design/icons/es/icons/FieldTimeOutlined";
import UserSwitchOutlined from "@ant-design/icons/es/icons/UserSwitchOutlined";
import ExclamationCircleTwoTone from "@ant-design/icons/es/icons/ExclamationCircleTwoTone";
import {appDefaults} from "../../constants/globalConfiguration";
import { __ } from '../../utils/translationUtils'

const {Text} = Typography;
const DooTakeOverModal = ({open, closeModal, onConfirm, masterUserInfo, notBeforeInfo}) => {

  const handleCancel = () => {
    closeModal();
  };

  const handleOk = () => {
    onConfirm();
  };

  const ModalContent = () => (
    <Space direction='vertical'>

      <Space direction='horizontal'>
        <UserSwitchOutlined/>
        <Text>{`${__('This action will assign you as the master user of this order.')}
        ${(masterUserInfo) ? `${__('If required, you will need to reassign this order back to the previous user: ')} ${masterUserInfo}` : ''}`}</Text>
      </Space>
      {
        (notBeforeInfo) &&
        <Space direction='horizontal'>
          <FieldTimeOutlined/>
          <Text>
            {`${__('Please note, you are ignoring the Not Before Date of this order: ')} ${moment(notBeforeInfo).format(appDefaults.dateTimeFormat)}`}
          </Text>
        </Space>
      }

    </Space>
  );

  return (
    <Modal
      title={<Space><ExclamationCircleTwoTone twoToneColor='#ffa000'/> {__('Order Takeover')}</Space>}
      visible={open}
      width={600}
      cancelText={__('Cancel')}
      okText={__('Proceed')}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <ModalContent/>
    </Modal>
  );
};

export default DooTakeOverModal;
