import React, {useEffect} from "react";
import {Redirect, Route} from "react-router-dom";
import {HistoryProvider} from "../../../providers";
import LayoutPrivate from "../Layout/LayoutPrivate";
import {useDispatch, useSelector} from "react-redux";
import {jwtDecode} from "jwt-decode";
import Cookies from "js-cookie";
import {
  geAuthCookieAttributes,
  getAuthCookieName,
  handleRefreshToken,
  storeAttributesList,
  storeAttributesMap,
  storeAttributesTree,
} from "../../../providers/ReduxProvider/actions/userActions";
import * as types from "../../../providers/ReduxProvider/types";
import {millisToDays, millisToSeconds} from "../../../utils/timeUtils";
import {attributesDataStructuresSelector} from "../../../providers/ReduxProvider/reducers/uiReducer";
import {UseCombinedQueries} from "../../../providers/QueryClientProvider/queries";
import Loading from "../../Loading";
import {ErrorBoundary} from "components";

export let globalDispatch

const Private = (props) => {
  const {Component, location} = props
  const {pathname} = location
  const token = useSelector((state) => state?.user?.authToken)
  const refreshStartTime = useSelector((state) => state?.user?.refreshStartTime)
  const storedAttributes = useSelector(attributesDataStructuresSelector)

  const attributesDS = UseCombinedQueries(["attributes", "attributesMap", "attributesTree"])
  const dispatch = useDispatch()
  globalDispatch = dispatch

  useEffect(() => {
    const {attributes, attributesMap, attributesTree} = attributesDS
    if (attributes && attributesMap && attributesTree && !storedAttributes) {
      dispatch(storeAttributesList(attributes))
      dispatch(storeAttributesMap(attributesMap))
      dispatch(storeAttributesTree(attributesTree))
    }
  }, [storedAttributes, attributesDS])

  if (token) {
    const decoded = jwtDecode(token)
    const {exp, created} = decoded || {}
    const now = Date.now()

    if (exp && exp * 1000 > now) {
      const timeFromCreated = millisToDays(now - created)
      const timeoutRefresh = refreshStartTime && millisToSeconds(now - refreshStartTime)
      if (timeFromCreated >= 1 && (!refreshStartTime || timeoutRefresh > 30)) {
        handleRefreshToken(dispatch)
      }

      return (
        <Route
          {...props}
          render={(props) => (
            <HistoryProvider {...props}>
              {
                storedAttributes ? (
                  <LayoutPrivate {...props}>
                    <div style={{ height: '100%' }} key={pathname}>
                      <ErrorBoundary>
                        <Component {...props}/>
                      </ErrorBoundary>
                    </div>
                  </LayoutPrivate>
                ) :
                  <Loading fullHeight='full-page' loading/>
              }
            </HistoryProvider>
          )}
        />
      )
    } else {
      dispatch({type: types.USER_LOGGED_OUT})
      Cookies.remove(getAuthCookieName(), geAuthCookieAttributes())
    }
  }

  let search
  if (pathname.includes('?redirect=')) {
    search = pathname.substring(pathname.indexOf('?redirect='))
  } else {
    search = (pathname && pathname !== '/' && !pathname.startsWith('/login') ? `?redirect=${pathname}` : '')
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        search
      }}
    />
  )
}

export default Private
