import { createSelector } from 'reselect';
import * as types from '../types';
import initialState from './initialState';

export default function uiPrefsUpdate(state = initialState.ui, { type, payload }) {

  switch (type) {
    case types.LANGUAGE_SELECTED:
      return {
        ...state,
        language: payload.lang,
      }

    case types.REMOTE_SYSTEMS_GOT:
      return {
        ...state,
        remoteSystems: payload,
      }

    case types.GOT_ATTRIBUTES_TREE:
      return {
        ...state,
        attributesTree: payload
      }

    case types.GOT_ATTRIBUTES:
      return {
        ...state,
        attributes: payload,
      }
    case types.GOT_ATTRIBUTES_LIST:
      return {
        ...state,
        attributesList: payload,
      }

    case types.LANGUAGE_AVAILABLE:
      return {
        ...state,
        availableLanguages: payload,
      }

    case types.GOT_SYSTEM_SETTINGS:
      return {
        ...state,
        systemSettings: payload,
      }

    case types.GOT_INSTANCE_SETTINGS:
      return {
        ...state,
        instanceSettings: payload,
      }
    case types.SET_LAST_REMOTE_OBJ_SEARCH:
      return {
        ...state,
        lastSearches: {
          ...state.lastSearches || {},
          [payload.id]: payload
        }
      }
    default:
      return state;
  }
}

const getAttributesDataStructures = (state) => {
  const { attributes, attributesTree, attributesList } = state
  if (!attributes || !attributesTree || !attributesList) return null

  return { attributes, attributesTree, attributesList }
}
export const attributesDataStructuresSelector = createSelector((state) => state.ui, getAttributesDataStructures)
export const lastSearchSelector = createSelector((state) => state.ui, (ui) => ui?.lastSearches)
