import { DisconnectOutlined, MinusOutlined } from '@ant-design/icons';
import { Skeleton, Tooltip } from 'antd';
import React from 'react';

const StepObjectNodeSkeleton = () => {
  return <SkeletonTable/>;
};

const SkeletonTable = () => {
  return (
    <div className="stepObject stepobject-skeleton">
      {
        (
          <div className="row">
            <div className='col-md-11'></div>
            <div className='col-md-1' aria-disabled>
              <span style={{ position: 'absolute', left: '115rem', zIndex: '1000' }} disabled>
                <Tooltip>
                  <div className="btn-no-padding btn-std">
                    <MinusOutlined />
                  </div>
                </Tooltip>
              </span>
            </div>
          </div>
        )
      }
      <div className="form-horizontal">
        <div className="row">
          <div className="col-md-4">
            <Skeleton active={true}></Skeleton>
          </div>
          <div className="col-md-4">
          <Skeleton active={true}></Skeleton>
          </div>
          <div className="col-md-4">
          <Skeleton active={true}></Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepObjectNodeSkeleton;