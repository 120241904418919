import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalDialog} from 'react-bootstrap';
import Glyphicon from '@strongdm/glyphicon'
import Draggable from 'react-draggable'
import {__} from '../utils/translationUtils'

const DraggableModalDialog = props => {
  return (
    <Draggable><ModalDialog {...props}/></Draggable>
  )
}

class ImagePreviewPopup extends React.Component {

  static propTypes = {
    file: PropTypes.object,
    open: PropTypes.bool,
    closeModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
  }

  render() {

    const {file} = this.props;
    let url = file.path;
    if (typeof file.groupName !== 'undefined') {
      url = url + "/" + file.groupName;
    }
    url = url + '/' + file.data.name;

    return (

      <Modal
        backdrop="static"
        className="image-preview"
        show={this.props.open}
        onHide={this.props.closeModal.bind(this)}
        dialogComponentClass={DraggableModalDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title className="capitalize">{__('Photo Preview')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={url}/>
        </Modal.Body>
        <Modal.Footer>
          <div className="group">
            <Button className="pull-left" onClick={this.props.closeModal.bind(this)}>
              {__('Back')}
            </Button>
            <a href={url} target="_blank" className="btn pull-right btn-default" rel="noreferrer">
              <Glyphicon glyph="download-alt"/> {__('Download')}
            </a>
          </div>
        </Modal.Footer>
      </Modal>

    );
  }
}

export default ImagePreviewPopup;
