import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import OrderOverview from '../pages/OrderOverview';
import {ResponsiveModal} from "../providers/StyleProvider/styles";
import { Flex } from 'components';
import { __ } from '../utils/translationUtils'


class OrderOverviewModal extends React.Component {

  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    order: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    // Adding className in order to make pop-up bigger
    return (
      <div>
        <ResponsiveModal
          visible={this.props.open}
          onCancel={this.props.closeModal}
          centered
          title={<div className="capitalize">{__('Order Overview')}</div>}
          footer={
            <Flex>
              <Button
                className="pull-left"
                onClick={this.props.closeModal}>
                {__('Back')}
              </Button>
            </Flex>
          }
        >
          <OrderOverview isModal={true} params={{ orderId: this.props.order.id }} />
        </ResponsiveModal>
      </div>
    );
  }
}
export default OrderOverviewModal;
