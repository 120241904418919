import styled from 'styled-components'

export const Actions = styled.div`
  text-align: center;
  background-color: inherit;
`

export const Danger = styled.div`
  padding: .25rem;
  transition: .2s all;
  color: ${(props) => props.theme.colors.red.primary};
  cursor: pointer;

  &:hover{
    background-color: ${(props) => props.theme.colors.red.primary};
    color: white;
  }
`

export const InnerTableTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
`

export const InnerTable = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray.secondary};
  padding: 1rem;
  background-color: #f0f0f0;
`

export const Edit = styled.div`
  padding: .25rem;
  transition: .2s all;
  color: ${(props) => props.theme.colors.thirdary};
  cursor: pointer;

  &:hover{
    background-color: ${(props) => props.theme.colors.thirdary};
    color: white;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 1rem;
  text-align: right;
`

export const Entity = styled.div`
  margin-right: 1rem;
  display: inline-block;
  border: 1px solid ${(props) => props.theme.colors.gray.primary};
  padding: .5rem;
  border-radius: 7px;
  transition: background-color .2s;
`

