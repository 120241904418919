import moment from 'moment'
import theme from '../../../../providers/StyleProvider/theme'
const { AGGREGATED } = global.constants.calendarMode

function getSlotProp({viewMode, events, timeSlot, workingEvents}){
  const slotDayOfTheWeekCode = moment(timeSlot).days() - 1
  const slotHour = moment(timeSlot).hours()
  const slotMinute = moment(timeSlot).minutes()

  let backgroundColor = theme.colors.gray.thirdary

  const availability = (viewMode === AGGREGATED) && events
    ?.find(({ startingDateTime, endingDateTime }) => (moment(timeSlot).isAfter(startingDateTime) || moment(timeSlot).isSame(startingDateTime)) && (moment(timeSlot).isBefore(endingDateTime) || moment(timeSlot).isSame(endingDateTime)))
    ?.availability

  const isWorkingSlot = (viewMode !== AGGREGATED) && workingEvents
    ?.filter(({ startHour, startMinute, endHour, endMinute, dayOfTheWeekCode }) => {
      if (slotDayOfTheWeekCode === dayOfTheWeekCode) {
        const slotValue = Number(`${slotHour}.${slotMinute}`)
        const startValue = Number(`${startHour}.${startMinute}`)
        const endValue = Number(`${endHour}.${endMinute}`)
        return (slotValue >= startValue) && slotValue < endValue
      }
      return false
    })?.length

  if(availability || isWorkingSlot) backgroundColor = '#ffffff'

  if(availability === 'PARTIAL') backgroundColor = '#F0F4F7'
  if(availability === 'NONE') backgroundColor = '#E1EAEF'

  return {
    backgroundColor
  }
}
export default getSlotProp
