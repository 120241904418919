import React, {useEffect, useMemo, useState} from "react";
import {Alert, Modal} from "antd";
import {Button} from 'react-bootstrap'
import getCompaniesTree from "./Calendar/CalendarsDashboard/utility/getCompaniesTree";
import {api, fetchCompanies} from "../providers/ApiProvider";
import UsersTreeSelect from "./UsersTreeSelect";
import getSelectedUserEntityIds from "./Calendar/CalendarsDashboard/utility/getSelectedUserEntityIds";
import { __ } from '../utils/translationUtils'
import { cases } from '../utils/stringUtils'
import {ERROR} from "components/dist/Utils/LoggerUtils";
import {cAlgorithm} from "../utils/appHelper";


const EditOrderSelectAssigneeModal = (props) => {
  const {onSubmit, selectedItems, closeModal, open} = props;
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([])
  const [processIdAndOwnershipIdMap, setProcessIdAndOwnershipIdMap] = useState({})
  const [companiesTree, setCompaniesTree] = useState(null);

  const  getInitialData = () => {
    fetchCompanies()
      .then((companies) => setCompaniesTree(getCompaniesTree(companies)))

	  api.get('users?pageSize=200&status=enabled,reserved')
		  .then(data => data.data.map(user => ({
			  ...user,
			  title: `${user.firstName} ${user.lastName}`
		  })))
		  .catch((e) => ERROR(e))
  }

  const getProcessIdAndOwnershipIdMap = (selectedItems) => {
    let processIdAndOwnershipIdMap = {};
    selectedItems?.forEach((item) => {

      let processOverview = cAlgorithm(item.processOverviews)
      if (!processOverview) return
      processIdAndOwnershipIdMap[processOverview.id] = processOverview.ownershipId;
    });
    return processIdAndOwnershipIdMap;
  }

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if(open) {
      setProcessIdAndOwnershipIdMap(getProcessIdAndOwnershipIdMap(selectedItems));
    }
  }, [open])

  const selectedUserEntityCouple = useMemo(() => getSelectedUserEntityIds({
    selectedCompanies: selectedCompanyUsers,
    companiesTree
  }), [selectedCompanyUsers, companiesTree]);

  function handleSubmit() {
    closeModal();
    onSubmit(processIdAndOwnershipIdMap, selectedUserEntityCouple);
  }

  function handleCancel() {
    setSelectedCompanyUsers([]);
    closeModal(); //this.props.closeModal.bind(this)
  }

  return (
	  <Modal
		  title={__('Assign Process')}
		  className="popup-l" visible={open} onCancel={handleCancel}
		  zIndex={2000}
		  bodyStyle={{height: 500}}
		  footer={
			  <div style={{display: 'flex', justifyContent: "space-between"}}>
				  <Button
					  key="back"
					  onClick={handleCancel}
				  >
					  {__('Back')}
				  </Button>
				  <Button
					  key="submit" type="primary"
					  className="btn-green"
					  disabled={!selectedUserEntityCouple.length}
					  onClick={handleSubmit}
				  >
					  {__('Assign')}
				  </Button>
			  </div>}

	  >
      <div style={{marginBottom: '1vh'}}>
        {
          Object.values(processIdAndOwnershipIdMap)?.length !== selectedItems?.length && (
            <Alert
              message={`${__('only', cases.CAPITALIZE_SENTENCE)} ${Object.values(processIdAndOwnershipIdMap)?.length} of the ${selectedItems?.length} ${__("selected orders/processes can be re-assigned.", cases.CAPITALIZE_SENTENCE)}`}
              type="warning"
              showIcon
              style={{marginBottom: '0.5vh'}}
            />
          )
        }
        {
          (!selectedUserEntityCouple.length) && (
            <Alert
              message={__("Select the user(s) to assign to this group of orders", cases.CAPITALIZE_SENTENCE)}
              type="info"
              showIcon
            />
          )
        }
      </div>
      <div>
        <UsersTreeSelect
          companiesTree={companiesTree}
          selectedCompanyUsers={selectedCompanyUsers}
          setSelectedCompanyUsers={setSelectedCompanyUsers}
        />
      </div>
    </Modal>
  );
}

export default EditOrderSelectAssigneeModal;
