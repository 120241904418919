import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const TimelineWrapper = styled.div`  
  position: relative;
  z-index: 1;
  .rct-header-root {
    background: ${(props) => props.theme.colors.lightGray.secondary};
    border-top: 1px solid #bbbbbb;

    /* because z-index 90 was because of the current date vertical red line */
    ${(props) => props.headerFixed && `
      width: 100%;
      position: fixed;
      top: 50px;
      z-index: 89;
    `}
  }
  .rct-dateHeader {
    background: ${(props) => props.theme.colors.lightGray.secondary};
    font-weight: 400;
    color: black;
    border-left: 1px solid #bbb;
  }

  .rct-sidebar {
    border-right: 0;
  }

  .rct-sidebar-row {
    padding: 0 !important;
  }

  .rct-day-6,
  .rct-day-0 {
    background-color: inherit !important;
  }

  .rct-hl-odd,
  .rct-hl-even,
  .rct-sidebar-row-odd,
  .rct-sidebar-row-even {
    background: ${(props) => `${props.theme.colors.gray.thirdary} !important`};
  }
  .rct-scroll {
    overflow-x: hidden;
  }
  .rct-calendar-header {
    border: none;
  }
  .react-calendar-timeline .rct-dateHeader-primary {
    border-right: none;
  }
`

export const ButtonWrapper = styled.div`
  margin-bottom: .5rem;
  text-align: right;
`

export const GroupRenderer = styled.div`
  background: ${(props) => props.theme.colors.lightGrey.secondary};
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`

export const GroupInner = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
`

export const GroupTitle = styled.div`
  line-height: 1;
  margin-left: .5rem;
`
export const Title = styled.div`
  margin-bottom: .25rem;
`
export const CalendarMacbookStyleHandlerWrapper = styled.div`
  position: absolute;
  line-height: 0;
  top: .25rem;
  right: .25rem;
  font-size: 1.75rem;
`
export const GroupEntities = styled.div`
  line-height: 0;
  display: flex;
`
export const GroupEntity = styled.div`
  margin-right: .25rem;

  span {
    cursor: pointer;
    border-radius: 4px;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 1rem;
    height: 18px;
  }
`

export const Hour = styled.div`
  font-size: 12px;
  width: auto;
  height: 3rem;
  display: flex;
  align-items: center;
  border-left: ${(props) => (props.isMidnight ? `1px solid ${props.theme.colors.gray.primary}` : 0)};
`
