import * as CONFIG_TYPE from "./../context/configurationType"

export const SYSTEM_ID = "systemId"
export const OBJECT_TYPE_ID = "objectTypeId"
export const DISPLAY_ATTRIBUTES = "displayAttributes"
export const SEARCH_ATTRIBUTES = "searchAttributes"
export const SORT_ATTRIBUTES = "sortAttributes"
export const ADDABLE_ENTRIES = "addableEntries"
export const EDITABLE_ENTRIES = "editableEntries"
export const REMOVABLE_ENTRIES = "removableEntries"

export default {
  [CONFIG_TYPE.OBJECT_LIST]: [SYSTEM_ID, OBJECT_TYPE_ID],
  [CONFIG_TYPE.OBJECT_BINDER]: [SYSTEM_ID, OBJECT_TYPE_ID],
  [CONFIG_TYPE.OBJECT_PICKER]: [SYSTEM_ID, OBJECT_TYPE_ID]
}
