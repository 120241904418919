import React from 'react';
import MessageRoom from "../../UI/MessageRoom";
import { useSelector } from "react-redux";

export default ({ containerRef, step }) => {
  
  const user = useSelector((state) => ({
    guiUser: state.user.data,
    authToken: state.user.authToken
  }))
  
  if (step){
    return (
      <MessageRoom
        {...user}
        containerRef={containerRef}
        objectCaller={step}
        panelTitle={'Step Comments'}
        modelCaller={'steps'}
        defaultType={'GLOBAL'}/>)
  }
  return null
}
