
import {
  REGEX_EMAIL,
  REGEX_NAME,
  REGEX_PHONE,
  REGEX_PHONE_PREFIX, REGEX_TITLE,
  REGEX_USERNAME
} from "components/dist/Utils/regexUtils";

export const testRegex = (regex, input) => {
  regex.lastIndex = 0
  const res = regex.test(input)
  regex.lastIndex = 0
  return res
}

export const missingValues = (regex, input) => {
  let regexCleanedUpForReplace = regex.source

  if (regexCleanedUpForReplace && regexCleanedUpForReplace[0] === '^' && regexCleanedUpForReplace[regexCleanedUpForReplace.length - 1] === '$') {
    regexCleanedUpForReplace = regexCleanedUpForReplace.substr(1, regexCleanedUpForReplace.length - 2)
  }

  return Array.from(new Set(input.replace(new RegExp(regexCleanedUpForReplace, 'gui'), '') || ''))
}

export const isAValidEmail = (input) => testRegex(REGEX_EMAIL, input)
export const isAValidUsername = (input) => testRegex(REGEX_USERNAME, input)
export const isAValidName = (input) => testRegex(REGEX_NAME, input)
export const isAValidPhone = (input) => testRegex(REGEX_PHONE, input)
export const isAValidPhonePrefix = (input) => testRegex(REGEX_PHONE_PREFIX, input)
export const isAValidTitle = (input) => testRegex(REGEX_TITLE, input)

export const missingUsernameChars = (input) => missingValues(REGEX_USERNAME, input)
export const missingNameChars = (input) => missingValues(REGEX_NAME, input)

// URL REGEXES
export const urlAny = 'http[s]?:\\/\\/.*'
export const urlGodoo = 'http[s]?:\\/\\/.*.?godoo.\\/?.*'
export const urlLocalhost = 'http[s]?:\\/\\/localhost\\/?.*'
