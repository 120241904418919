import React, { useEffect, useState } from "react";
import { fetchStepRemoteTableData } from "../../utils/appHelper";
import ObjectListingControl from "./ObjectListingControl";
import {fetchAttributeById} from "../../providers/ApiProvider";

const consolidateField = async (field, order) => {
  const { objectBinderConfiguration, remoteObjectBinderConfiguration, objectConfiguration: listingConfig } = field.attributes
  const objectConfiguration = objectBinderConfiguration || remoteObjectBinderConfiguration || listingConfig

  const { controllerOptions } = field

  if (controllerOptions?.readFrom) {

    const { readFrom } = controllerOptions
    const { objectTypeId } = objectConfiguration

    const orderProps = {
      objectTypeId,
      fieldId: field.id,
      orderId: order.id,
    }

    const attribute = await fetchAttributeById(readFrom.attributeId)

    const optionalProps = {
      mappedBy: attribute.mappedBy,
      parentObjectId: readFrom.primaryKey,
    }

    const val = await fetchStepRemoteTableData({
      ...orderProps,
      ...optionalProps,
      objectTypeId,
      payload: {
        ...objectConfiguration,
        pageSize: 500,
        pageNumber: 1,
      },
    }).then((res) => {
      return res.data.map((item) => item.id).join(',')
    })
    field.value = val
    field.source = val
  }

  return field
}

export default (props) => {

  const [field, setField] = useState()
  useEffect(() => {
    consolidateField(props.controlData, props.order).then((res) => {
      setField(res)
    })
  }, [])

  return field ? (
    <ObjectListingControl
      key={field.id}
      controlData={field}
      apis={props.apis}
    />
  ) : null
}
