import styled from 'styled-components'

export const Card = styled.div`
  padding: 1rem;
  position: relative;
  width: 100%;
  ${(props) => props.box && `
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    padding: 1rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    width: 100%;
  `}
`

export const VerticalLine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: .5rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: ${(props) => props.color};

`

export const Title = styled.div`
  color: black;
  font-weight: 500;
  margin-bottom: .5rem;
  font-size: 2rem;
  cursor: pointer;
`

export const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.text.secondary};
  font-weight: 300;
  font-size: 1.125rem;
  text-transform: uppercase;
`

export const Text = styled.div`
  color: black;
  font-weight: 300;
  text-transform: uppercase;
`

export const Content = styled.div`
  display: grid;
  grid-auto-columns: 50%;
  gap: .5rem;
  grid-template-columns: 50%;
  grid-template-areas: 'item item';
  overflow: hidden;
`

export const ContentPrimary = styled.div`
  display: flex;
  align-items: center;
`

export const ContentSecondary = styled.div``

export const PriorityIconWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray.secondary};
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  font-size: 1.0rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IconsWrapper = styled.div`
  display: flex;
  padding-top: .5rem;
  width: 100%;
  overflow: auto;
`

export const Circle = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 1rem;
  flex-shrink: 0;
  background-color: ${(props) => props.color};
`
