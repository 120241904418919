import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import objectAssign from 'object-assign'
import moment from 'moment'
import { getFormValues, submit } from 'redux-form'
import { DropdownButton, MenuItem, OverlayTrigger, Panel, Tooltip } from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import { hashHistory } from '../../providers/HistoryProvider'

import {
  fetchAvailableAssignees,
  setClickedButton,
  showMessage,
  unClickButtonsAndRemoveErrors
} from '../../utils/appHelper'
import StepLegacy from '../../components/DooOrderPage/Step/StepLegacy'
import OrderOverviewModal from '../../components/OrderOverviewModal'
import StepInfoModal from '../../components/StepInfoModal'
import SelectPairModal from '../../components/SelectPairModal'
import * as config from '../../constants/globalConfiguration'
import Loading from '../../components/Loading'
import { api } from '../../providers/ApiProvider'
import { validateFileFields } from '../../components/fileUploader/services/utils'
import Label from '../../components/Label'
import { Strong } from '../../components/Label/styles'
import { Skeleton } from 'antd'
import DooTakeOverModal from "../../components/DooOrderPage/DooTakeOverModal";
import { filesController } from "../../components/DooOrderPage/utils";
import { countDownMessage } from '../../utils/timerUtils'
import { logout } from '../../providers/ReduxProvider/actions/userActions'
import { __ } from '../../utils/translationUtils'

const loadingBar = require('nprogress')

class DooOrderPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    submit: PropTypes.func,
    location: PropTypes.object,
    orderId: PropTypes.string,
    order: PropTypes.object,
    activeProcess: PropTypes.object,
    reduxState: PropTypes.object,
    guiUser: PropTypes.object,
    stepFormValues: PropTypes.object,
    appointmentFormValues: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      orderOverviewModal: { open: false },
      stepInfoModal: { open: false },
      selectPairModal: { open: false },
      steps: null,
      currentStep: null,
      activeStepObject: null,
      isLoading: true,
      userAssignmentPairs: [],
      stepLoading: false,
      selectedAppointmentDetails: {},
      dooTakeoverModal: { open: false }
    }

    this.queryString = parse(props.location.search)
    this.defaultDocumentTitle = document.title
  }



  componentDidMount() {
    this.initDooOrderPage()
  }

  initDooOrderPage() {
    const { guiUser, order, activeProcess } = this.props;
    // Change title of the document if the request has gone well
    document.title = `${this.defaultDocumentTitle} - ${order.title ? order.title.toString() : ''}`

    fetchAvailableAssignees(activeProcess.id, { userId: guiUser.id }).then(
      (response) => {
        this.setState({ userAssignmentPairs: response },
          () => {
            if (
              activeProcess?.ownership?.masterEntityId
              && activeProcess?.ownership?.masterUserId
              && activeProcess?.ownership?.masterUserId !== guiUser?.id
            ) this.setState({ dooTakeoverModal: { open: true } })
            else this.confirmSetMaster()
          })
      }
    )
  }

  // Make the title turn back to the default one
  componentWillUnmount() {
    document.title = this.defaultDocumentTitle
  }

  confirmSetMaster() {
    const { guiUser, activeProcess } = this.props;
    const { userAssignmentPairs } = this.state

    this.setState({ dooTakeoverModal: { open: false } });
    // Check if user is in the ownership's assignees list.
    const assigneeIndex = _.findIndex(activeProcess?.ownership?.assignees, (p) => p.userId == guiUser.id)

    if (assigneeIndex !== -1) { // In ownership Assignees list.
      this.setMaster(activeProcess?.ownership?.assignees[assigneeIndex]) // Use existing org
    } else { // Not in ownership Assignees list.
      // User has only one assignee pair available.
      if (userAssignmentPairs?.length === 1) {
        this.setMaster(userAssignmentPairs[0])
      }
      // User belongs to more than one organisation.
      else {
        // Force him to select which one to use.
        this.setState({ selectPairModal: { open: true } })
      }
    }
  }

  async setMaster(pair) {
    const { guiUser, activeProcess } = this.props
    const data = {
      ...activeProcess.ownership,
      masterUserId: guiUser.id,
      masterEntityId: pair.entityId
    }

    // If pair is missing from the assignees, add it
    const found = _.find(data.assignees, (p) => p.userId === pair.userId && p.entityId === pair.entityId)

    if (!found) {
      data.assignees.push({
        userId: pair.userId,
        entityId: pair.entityId,
        ownershipId: data.id
      })
    }

    // Try setting current user as MasterUser
    return api.put(`processes/${activeProcess.id}/ownership/${data.id}`, data)
      .then(
        () => {
          this.setState({ currentStep: activeProcess.currentStepLocation }, () => {
            this.getSteps()
              .then(() => {
                const step = this.extractStep()
                if (!step) {
                  hashHistory.goBack()
                  return true
                }
                this.setState({
                  isLoading: false,
                  activeStepObject: step
                })
                loadingBar.done()
                return true
              })
          })
        },
        () => { // error
          hashHistory.goBack()
        }
      )
  }

  handleButtonClick(button, step) {
    window.scrollTo(0, 0)
    switch (parseInt(step.discriminator)) {
      case config.NORMAL_STEP_DISCRIMINATOR:
        this.sendNormalStep(button, step)
        return

      case config.APPONINTMENT_STEP_DISCRIMINATOR:
        this.sendAppointmentStep(button, step)
        return

      case config.ASSIGNMENT_STEP_DISCRIMINATOR:
        this.sendAssignmentStep(button, step)
        return

      case config.REASSIGNMENT_STEP_DISCRIMINATOR:
        this.sendAssignmentStep(button, step)
    }
  }

  getSteps() {
    loadingBar.start()
    this.setState({ isLoading: true })
    return api.get(`steps?processId=${this.props.activeProcess.id}`)
      .then(
        (response) => {
          this.setState({
            isLoading: false,
            steps: _.sortBy(response.data, 'position')
          })
          loadingBar.done()
        }
      )
      .catch(() => { })
  }

  showStep(id) {
    this.setState({
      activeStepObject: null,
      stepLoading: true,
      isLoading: true
    })

    return api.get(`steps/${id}`)
      .then(
        (response) => {
          this.setState({ activeStepObject: response.data })
          loadingBar.done()
          this.setState({
            stepLoading: false,
            isLoading: false
          })
        }
      )
  }

  sendAssignmentStep(button, step) {
    // If button is not "Next" or "Complete", perform normal step submission.
    /* if (button.name != 'Next' && button.name !== 'Complete') {
      this.sendNormalStep(button, step);
      return;
    } */
    if (button && !(button.validationFormRequired) && _.findIndex(button.actions, (a) => (a.discriminator == 41 || a.discriminator == 18 || a.discriminator == 19)) > -1) {
      this.sendNormalStep(button, step)
      return
    }

    // Set button as clicked
    const _step = objectAssign({}, { ...step })
    setClickedButton(_step, button.name)

    // set attributes
    _step.attributes.assignees = []

    if (typeof this.props.stepFormValues.assignees === 'undefined'
      || !this.props.stepFormValues.assignees) {
      showMessage('error', __('NoAssigneesSelected'))
      this.setState({
        stepLoading: false,
        isLoading: false
      })
      return
    }
    const assignees = JSON.parse(this.props.stepFormValues.assignees)
    if (assignees.length === 0) {
      showMessage('error', __('NoAssigneesSelected'))
      this.setState({
        stepLoading: false,
        isLoading: false
      })
      return
    }
    assignees.map((pair) => {
      _step.attributes.assignees.push({
        userId: pair.userId,
        entityId: pair.entityId,
        type: 'USER'
      })
    })

    loadingBar.start()
    this.setState({
      stepLoading: true,
      isLoading: true
    })

    api.put(`/steps/${this.state.activeStepObject?.id}`, step)
      .then(
        (response) => {
          const { result } = response.data
          let hasToBeRedirected = false

          if (typeof result !== 'undefined' && result.length > 0) {
            hasToBeRedirected = this.doActions(result)
          }
          if (!hasToBeRedirected) {
            // I update the state only if the user remains in the same page
            this.setState({
              stepLoading: false,
              isLoading: false
            })
            loadingBar.done()
          }
        },
        () => {
          loadingBar.done()
          this.setState({
            stepLoading: false,
            isLoading: false
          })
        }
      )
  }

  setAppointmentDetails = () => {
    const { appointmentFormValues } = this.props
    const { selectedAppointmentDetails } = this.state

    const appointmentDetails = { ...selectedAppointmentDetails }

    if (typeof appointmentFormValues === 'undefined'
      || !appointmentFormValues
      || typeof appointmentFormValues.assignees === 'undefined'
      || appointmentFormValues.assignees === '[]'
      || appointmentFormValues.assignees.length === 0) {
      showMessage('error', __('NoAssigneesSelected'))

      return false
    }

    const _assignees = JSON.parse(appointmentFormValues.assignees)

    appointmentDetails.assignees = _assignees.map((assignee) => {

      const { type } = assignee
      return type === 'USER' ?
        {
          userId: assignee.userId,
          entityId: assignee.entityId,
          type: 'USER'
        } :
        {
          entityId: assignee.id,
          type: 'ENTITY_ATTACHED_USERS'
        }
    })

    appointmentDetails.startingDatetime = appointmentFormValues.start
    appointmentDetails.endingDatetime = appointmentFormValues.end

    this.setState({ selectedAppointmentDetails: appointmentDetails })

    return true
  }

  sendAppointmentStep(button, step) {
    const { activeStepObject } = this.props
    const { selectedAppointmentDetails } = this.state

    if (!step) {
      step = activeStepObject
    }

    if ((button) && (!button.validationFormRequired) && _.findIndex(button.actions, (a) => (a.discriminator == 41 || a.discriminator == 18 || a.discriminator == 19)) > -1) {
      this.sendNormalStep(button, step)
      return
    }

    // Set button as clicked
    const _step = objectAssign({}, { ...step })

    if (!button) {
      return this.setAppointmentDetails()
    }
    this.closeModal()

    loadingBar.start()

    this.setState({
      stepLoading: true,
      isLoading: true
    })

    setClickedButton(_step, button.name)

    // Fill in the data from selectedAppointmentDetails

    if (!selectedAppointmentDetails || !selectedAppointmentDetails.assignees
      || selectedAppointmentDetails.assignees === '[]'
      || selectedAppointmentDetails.assignees.length === 0
    ) {
      showMessage('error', __('NoAssigneesSelected'))
      loadingBar.done()
      this.setState({
        stepLoading: false,
        isLoading: false
      })
      return false
    }
    if (!selectedAppointmentDetails
      || !selectedAppointmentDetails.startingDatetime
      || !selectedAppointmentDetails.endingDatetime
    ) {
      showMessage('error', __('NoAppointmentSelected'))
      loadingBar.done()
      this.setState({
        stepLoading: false,
        isLoading: false
      })
      return false
    }

    _step.attributes.assignees = selectedAppointmentDetails.assignees
    _step.attributes.startingDatetime = selectedAppointmentDetails.startingDatetime
    _step.attributes.endingDatetime = selectedAppointmentDetails.endingDatetime
    api.put(`/steps/${step.id}`, step)
      .then(
        (response) => {
          const { result } = response.data
          let hasToBeRedirected = false

          if (typeof result !== 'undefined' && result.length > 0) {
            hasToBeRedirected = this.doActions(result)
          }
          if (!hasToBeRedirected) {
            // I update the state only if the user remains in the same page
            this.setState({
              stepLoading: false,
              isLoading: false
            })
            loadingBar.done()
          }
        },
        () => {
          loadingBar.done()
          this.setState({
            stepLoading: false,
            isLoading: false
          })
        }
      )
  }

  sendNormalStep(button, step) {
    const { activeStepObject } = this.state
    let stepFormErrors = this.props.reduxState.form.stepForm.syncErrors || null

    // -------------------- Validate File fields, since there are not inside a <Field of redux ---------------------//
    const fileFields = (step.fields || []).filter((f) => filesController.includes(f.controllerType))

    const errorFileFields = validateFileFields(fileFields)

    if (errorFileFields) {
      // this just to make a return in case of error
      stepFormErrors = { ...(stepFormErrors || {}), ...errorFileFields }
      const fileFieldsWithError = {}
      Object.entries(errorFileFields)
        .forEach(([fieldId, error]) => {
          const activeField = activeStepObject.fields.find((f) => f.id === fieldId)
          if (activeField == null) {
            return null
          }
          fileFieldsWithError[fieldId] = {
            ...activeField,
            error
          }
        })

      const fileFiledIds = Object.keys(fileFieldsWithError)
      this.setState({
        activeStepObject: {
          ...activeStepObject,
          fields: activeStepObject.fields.map((field) => (fileFiledIds.includes(field.id) ? fileFieldsWithError[field.id] : field))
        }
      })
    } else {
      step.fields.forEach((f) => delete f.error)
    }
    // ------------------------------------------------------------------------------------------------//

    this.props.dispatch(submit('stepForm'))
    if (button.validationFormRequired && stepFormErrors) {
      return
    }
    const _activeStepId = activeStepObject.id
    loadingBar.start()
    this.setState({
      stepLoading: true,
      isLoading: true
    })

    const _step = objectAssign({}, { ...step })
    setClickedButton(_step, button.name)

    for (const field in _step.fields) {
      let val = this.props.stepFormValues[_step.fields[field].id]
      // Todo: Need to get rid of this. We need to check where this is being used.
      if (val == 'true') {
        val = true
      }
      if (val == 'false') {
        val = false
      }
      if (Array.isArray(val)) {
        val = val.join()
      }
      if (typeof val === 'string') {
        try {
          //added for billing retrocompatibility
          let jsonVal = JSON.parse(val)
          if (jsonVal && jsonVal.isCompatibilityMode && jsonVal.priceCategories) {
            val = JSON.stringify(jsonVal.priceCategories)
          }
        } catch (error) {

        }
      }
      if (typeof val === 'object' && !(val instanceof Date) && !(val instanceof moment)) {
        if (val !== null) {
          val = JSON.stringify(val)
        }
      }

      if (_step.fields[field].controllerType === "ObjectBinder") {
        _step.fields[field].value = val
      } else {

        _step.fields[field].destination = val

      }
    }

    api.put(`/steps/${_activeStepId}`, _step)
      .then(
        (response) => {
          const { result } = response.data
          let hasToBeRedirected = false
          if (typeof result !== 'undefined' && result.length > 0) {
            hasToBeRedirected = this.doActions(result)
          }
          if (!hasToBeRedirected) {
            // I update the state only if the user remains in the same page
            this.setState({
              stepLoading: false,
              isLoading: false,
            })
            loadingBar.done()
          }
        },
        () => {
          loadingBar.done()
          unClickButtonsAndRemoveErrors(_step)
          this.setState({
            stepLoading: false,
            isLoading: false,
          })
        }
      )
  }

  doActions(actions) {
    const { orderId } = this.props
    let userHasBeenRedirected = false
    let moved = false
    actions.map((action) => {
      switch (action.discriminator) {
        case '2': // GoTo
          this.setState({ currentStep: parseInt(action.value) }, () => {
            this.showStep(this.extractStep().id)
          })
          break

        case '19': // Return to listing (Artiom's request)
          if (!moved) {
            hashHistory.push(this.queryString.backUrl || config.ordersDefaultUrl)
            userHasBeenRedirected = true
          }
          moved = true
          break

        case '20': // Complete
          if (!moved) {
            hashHistory.push(this.queryString.backUrl || config.ordersDefaultUrl)
            userHasBeenRedirected = true
          }
          moved = true
          break

        case '21': // Show Message
          showMessage('info', __(action.value))
          break

        case '22': // Show Error
          showMessage('error', __(action.value))
          break

        case '44': // Return to listing
          if (!moved) {
            hashHistory.push(
              this.queryString.backUrl || config.ordersDefaultUrl
            )
            userHasBeenRedirected = true
          }
          moved = true
          break

        case config.discriminators.GO_TO_NEXT_PROCESS: // Refresh doo page for current order(and show new active process)
          if (!moved) {
            hashHistory.push(`/orders/${orderId}/doo?backUrl=${this.queryString.backUrl}&reload=1`)
            window.location.reload()
          }
          moved = true
          break

        case config.discriminators.LOGOUT: // Refresh doo page for current order(and show new active process)
          if (!moved) {
            hashHistory.push(config.ordersDefaultUrl)
            if (action.message?.value) {
              countDownMessage(action.message?.toBeTranslated ? __(action.message?.value) : action.message?.value, action.delay)
            }
            setTimeout(() => {
              this.props.logoutAction()
            }, action.delay);
          }
          moved = true
          break
      }
    })
    return userHasBeenRedirected
  }

  extractStep() {
    const {
      steps,
      currentStep
    } = this.state
    return _.find(steps, (step) => step.position === currentStep)
  }

  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({
      orderOverviewModal: { open: false },
      stepInfoModal: { open: false },
      selectPairModal: { open: false }
    })
  }

  closeDooTakeoverModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({
      dooTakeoverModal: { open: false }
    });
    hashHistory.push(this.queryString.backUrl || config.ordersDefaultUrl)
  }

  initialValues() {
    const { activeStepObject } = this.state
    const obj = {}
    activeStepObject.fields.map((field) => {
      if (field.controllerType === 'DateInput' || field.controllerType === 'DateTimeInput') {
        // By default set current datetime or source if exists.
        obj[field.id] = (field.source) ? moment(field.source).toDate() : null
        // If source == "null" or missing leave input blank (Mantis 1387)
        if (field.source === 'null' || typeof field.source === 'undefined') {
          obj[field.id] = null
        }
      } else if (field.controllerType === 'SimpleCheckBox') {
        let val = ''
        if (typeof field.defaultValue !== 'undefined') {
          val = field.defaultValue.toLowerCase()
        }

        if (typeof field.source !== 'undefined') {
          val = field.source.toLowerCase()
        }

        obj[field.id] = val
      } else {
        obj[field.id] = (field.source) ? field.source : field.defaultValue
      }
    })
    if (activeStepObject.discriminator == 1
      || activeStepObject.discriminator == 2
      || activeStepObject.discriminator == 3) {
      obj.assignees = []
    }

    return obj
  }

  handleDocumentEditCompanySelect(value) {
    const _step = objectAssign({}, this.state.activeStepObject)
    const _field = _.find(_step.fields, (f) => f.controllerType === 'DocumentEditor')
    _field.attributes.documentEditorSettings.selectedCompany = value
    this.setState({ activeStepObject: _step })
  }

  render() {
    const {
      guiUser,
      order,
      activeProcess,
      stepFormValues
    } = this.props
    const {
      isLoading,
      stepLoading,
      activeStepObject: step,
      orderOverviewModal,
      stepInfoModal,
      selectPairModal,
      userAssignmentPairs,
      dooTakeoverModal,
    } = this.state

    if (step && stepFormValues) {
      step.fields = step.fields.map((f) => ((filesController.includes(f.controllerType) && stepFormValues[f.id]) ? {
        ...f,
        source: stepFormValues[f.id]
      } : f))
    }

    const renderStepButtons = () => {
      function fillButtons(buttons, parent) {
        const buttonGroups = _.groupBy(buttons, (b) => b.group.title)
        for (const buttonGroup in buttonGroups) {
          if (buttonGroups[buttonGroup].length === 1) {
            const button = buttonGroups[buttonGroup][0]
            let position = ''

            if ((!button.group.alignment) || button.group.alignment === 'LEFT') {
              position = ' pull-left '
            }
            if ((button.group.alignment) && button.group.alignment === 'RIGHT') {
              position = ' pull-right '
            }
            const style = (button.group.styleClass) ? button.group.styleClass : 'default'
            if (parseInt(step.discriminator) !== config.APPONINTMENT_STEP_DISCRIMINATOR
              || (parseInt(step.discriminator) === config.APPONINTMENT_STEP_DISCRIMINATOR
                && button.name !== 'Complete')) {
              ButtonObjects.push(
                <button
                  disabled={stepLoading}
                  key={button.id}
                  className={`btn${position}btn-${style}`}
                  size="large"
                  onClick={parent.handleButtonClick.bind(parent, button, step)}
                >
                  {__(button.name)}
                </button>
              )
            }
          } else { // Show dropdown
            let options = buttonGroups[buttonGroup]
            options = _.sortBy(options, (b) => -b.group.position)
            const buttons = []
            options.forEach((button, i) => {
              if (parseInt(step.discriminator) !== config.APPONINTMENT_STEP_DISCRIMINATOR
                || (parseInt(step.discriminator) === config.APPONINTMENT_STEP_DISCRIMINATOR
                  && button.name !== 'Complete')) {
                buttons.push(
                  <MenuItem
                    key={i}
                    onClick={parent.handleButtonClick.bind(parent, button, step)}
                    eventKey={button.name}
                  >
                    {__(button.name)}
                  </MenuItem>
                )
              }
            })

            const button = buttonGroups[buttonGroup][0]
            let position = ''

            if ((!button.group.alignment) || button.group.alignment === 'LEFT') {
              position = ' pull-left '
            }
            if ((button.group.alignment) && button.group.alignment === 'RIGHT') {
              position = ' pull-right '
            }
            const style = (button.group.styleClass) ? button.group.styleClass : 'default'

            ButtonObjects.push(
              <DropdownButton
                dropup
                disabled={parent.state.stepLoading}
                className={`btn${position}btn-${style}`}
                title={__(buttonGroup)}
                key={`step-btn-${buttonGroup}`}
                id={`dropdown-basic-${buttonGroup}`}
              >
                {buttons}
              </DropdownButton>
            )
          }
        }
      }

      const leftButtons = _.sortBy(step.buttons.filter((b) => (!b.group.alignment) || b.group.alignment === 'LEFT'), (b) => b.group.position)
      const rightButtons = _.sortBy(step.buttons.filter((b) => b.group.alignment === 'RIGHT'), (b) => b.group.position)

      let ButtonObjects = []

      fillButtons(leftButtons, this)
      fillButtons(rightButtons, this)

      return (
        <div className="group">
          {ButtonObjects}
        </div>
      )
    }

    return (
      <div id="main-content">
        <Loading loading={isLoading} />

        <h1 className="sticky">
          <div className="container">

            {this.state.steps && __(activeProcess.title)}

            {order
              && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="order-tooltip">{__('Order Overview')}</Tooltip>}
                >
                  <Link
                    to={`/orders/${order.id}/overview`}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      this.setState({ orderOverviewModal: { open: true } })
                    }}
                    style={{
                      fontSize: '16px',
                      lineHeight: '12px'
                    }}
                    className="godoo pull-right order-details-toggle"
                  >
                    <Glyphicon glyph="list" />
                    {
                      order && <span> WO ID: {order.externalOrderId}</span>
                    }
                  </Link>
                </OverlayTrigger>
              )}

          </div>
        </h1>

        {step && !isLoading && (
          <div
            className={(step && parseInt(step.discriminator) === config.APPONINTMENT_STEP_DISCRIMINATOR) ? 'container-fluid' : 'container'}
          >
            <Panel className="order-page">
              {step && (
                <Panel.Heading>
                  <Label
                    className="control-label"
                    label={<Strong>{__(step.title)}</Strong>}
                    description={step.description && __(step.description)}
                  />
                </Panel.Heading>
              )}
              {!stepLoading && (
                <Panel.Body>
                  <StepLegacy
                    step={step}
                    process={activeProcess}
                    order={order}
                    initialValues={this.initialValues()}
                    onSubmit={() => { }}
                    guiUserId={guiUser.id}
                    setAppointmentDetails={this.setAppointmentDetails.bind(this)}
                    handleDocumentEditCompanySelect={this.handleDocumentEditCompanySelect.bind(this)}
                  />
                </Panel.Body>
              )}
              <Panel.Footer>{step ? renderStepButtons() : null}</Panel.Footer>
            </Panel>
          </div>
        )}

        {isLoading && (
          <div className="container">
            <Panel className="order-page">
              <Panel.Heading>{__('loading')}</Panel.Heading>
              <Panel.Body>
                <Skeleton active title={false} paragraph={{ rows: 12, style: { marginBottom: '15vh', marginTop: '5vh' } }} />
              </Panel.Body>
            </Panel>
          </div>
        )}

        <DooTakeOverModal
          open={dooTakeoverModal.open}
          closeModal={this.closeDooTakeoverModal.bind(this)}
          onConfirm={this.confirmSetMaster.bind(this)}
          masterUserInfo={(activeProcess?.ownership?.masterUserId) ? activeProcess?.ownership?.master?.username : null}
          notBeforeInfo={activeProcess?.notBefore}
        />

        {order && (
          <OrderOverviewModal
            open={orderOverviewModal.open}
            params={{ orderId: order.id }}
            order={order}
            closeModal={this.closeModal.bind(this)}
          />
        )}

        {order && step && (
          <StepInfoModal
            open={stepInfoModal.open}
            params={{ info: step.description }}
            order={order}
            closeModal={this.closeModal.bind(this)}
          />
        )}

        {activeProcess && userAssignmentPairs && (
          <SelectPairModal
            open={selectPairModal.open}
            onSubmit={this.setMaster.bind(this)}
            closeModal={this.closeModal.bind(this)}
            processId={activeProcess && activeProcess.id}
            guiUserId={guiUser.id}
          />
        )}
      </div>

    )
  }
}

export default connect(
  (state) => ({
    reduxState: state,
    stepFormValues: getFormValues('stepForm')(state),
    appointmentFormValues: getFormValues('appointmentForm')(state)
  }),
  (dispatch) => ({
    dispatch,
    submit,
    logoutAction: () => logout()(dispatch)
  })
)(DooOrderPage)
