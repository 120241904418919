import styled from 'styled-components'

export const SubmitWrapper = styled.div`
  text-align: right;
`

export const FormGroup = styled.div`
`

export const ButtonWrapper = styled.div`
  text-align: right;
`

export const Button = styled.button`
  margin-bottom: 1rem;
`
export const AppointmentForm = styled.div`
  margin-bottom: 1rem;

  .noPaddingRight {
    margin-right: 0;
  }
`

export const BusyAssignee = styled.div`
  margin-bottom: 1rem;
`

export const TreeNode = styled.div`
  font-size:1.25rem;
  display: flex;
  align-items: center;
  white-space: pre;
  svg {
    color: ${(props) => props.theme.colors.thirdary};
    font-size:1.5rem;
    margin-right: .5rem;
  }
  small {
    margin-left: .25rem;
    color: ${(props) => props.theme.colors.grey.primary};
  }
`
