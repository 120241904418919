import React, {useContext} from "react"
import {Checkbox, Select} from "antd"
import FormContext from "../../factories/FormContext"
import ApiManagement from "./../../api"
import {Form} from '@ant-design/compatible';
import * as CONFIG_TYPE from "../../context/configurationType"

const {Option} = Select

function ObjectListing({configurationType, ...props}) {
  const {form} = useContext(FormContext)

  return (
    <React.Fragment>
      <Form.Item label={"System"}>
        {form.getFieldDecorator("systemId", {
          rules: [{required: true, message: "Please input the System of a Object Listing!"}],
        })(
          <Select disabled={props.disableSystemAndObjectType}>
            {ApiManagement.getRemoteSystemsFilteredBy("godoo").map(([system_id, system_label]) => (
              <Option value={system_id} key={system_id}>
                {system_label}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={"Object Type"}>
        {form.getFieldDecorator("objectTypeId", {
          rules: [{required: true, message: "Please input the Object of a Object Listing!"}],
        })(
          <Select disabled={props.disableSystemAndObjectType}>
            {ApiManagement.getRemoteObjectFromSystem(form.getFieldValue("systemId")).map((remoteObject) => (
              <Option value={remoteObject.objectTypeId} key={remoteObject.objectTypeId}>
                {remoteObject.label}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={"Addable Entries"}>
        {form.getFieldDecorator("addableEntries", {
          valuePropName: 'checked',
          initialValue: configurationType === CONFIG_TYPE.OBJECT_BINDER
        })(
          <Checkbox disabled={configurationType === CONFIG_TYPE.OBJECT_BINDER}/>
        )}
      </Form.Item>
      <Form.Item label={"Editable Entries"}>
        {form.getFieldDecorator("editableEntries", {
          valuePropName: 'checked',
        })(
          <Checkbox/>
        )}
      </Form.Item>
      <Form.Item label={"Removable Entries"}>
        {form.getFieldDecorator("removableEntries", {
          valuePropName: 'checked',
          initialValue: configurationType === CONFIG_TYPE.OBJECT_BINDER
        })(
          <Checkbox disabled={configurationType === CONFIG_TYPE.OBJECT_BINDER}/>
        )}
      </Form.Item>
    </React.Fragment>
  )
}

export default ObjectListing
