import { useEffect, useRef } from "react";

export default () => {
  const componentMounted = useRef(undefined)

  useEffect(() => {
    componentMounted.current = true
    return () => {
      componentMounted.current = false
    }
  }, []);

  return componentMounted
};
