import styled from 'styled-components'

export const EventWrapper = styled.div`
  font-size: 1.125rem;
  letter-spacing: .04rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-left: 0;
  filter: saturate(.8);
  padding: .5rem;
  border-radius: 5px;
  line-height: inherit !important;
  overflow: hidden;
  white-space: pre;
  color: white !important;
  border: 1px solid white !important;
  opacity: ${(props) => props.hidden ? 0 : 1};
  &:after {
    content:"";
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 2rem;
    height: 2rem;
    background-color: ${(props) => props.triangleColor};
    transform: rotate(45deg);
  }
  ${(props) => props.isNonWorkingEvent && `
    background: ${props.nonWorkingTypeCode > 2 ? 'gray' : 'darkgrey'} !important;
    z-index: 32 !important;
  `}

  ${(props) => props.hoverable && `
    &:hover {
      min-width: 100px !important;
      width: auto;
      z-index: 33 !important;
    }
  `}
  ${(props) => props.isGodooEvent && `
    background: ${props.theme.colors.thirdary} !important;
    border-left: 3px solid ${props.backgroundColor} !important;
    z-index: 32 !important;
  `}

  ${(props) => props.isWorkingEvent && `
    &:after {
      display: none;
    }
    height: calc(${global.constants.calendarTimeline.LINE_HEIGHT}px - 1px) !important;
    top: calc(${props.groupPosition?.top}px - ${props.outerPosition?.top}px) !important;
    height: ${props.groupPosition?.height}px !important;
    z-index: 29 !important;
    bottom: auto;
    border-radius: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    font-variant: all-small-caps;
    display: flex;
    align-items: flex-end;
    background: white !important;
    color: black !important;
  `}

  &:focus {
    outline: none;
  } 

`

export const Square = styled.div`
  background-color: ${(props) => props.backgroundColor};
  height: 20px;
  width: 20px;
  margin-right: .5rem;
  border: 1px solid white;
  border-radius: 4px;
`

export const PopoverTitle = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.justifyContent && `
    justify-content: space-between;
  `}
`

export const IconWrapper = styled.div`
  padding-left: .5rem;
  margin-left: 1rem;
  border-left: 1px solid ${(props) => props.theme.colors.gray.secondary};
  cursor: pointer;
  .edit {
    padding: .25rem;
    &:hover {
      background-color: ${(props) => props.theme.colors.gray.secondary};
      color: ${(props) => props.theme.colors.thirdary};
    }
  }

  .delete {
    padding: .25rem;
    color: ${(props) => props.theme.colors.red.primary};
    &:hover {
      background-color: ${(props) => props.theme.colors.gray.secondary};
    }
  }
`

// POPOVER
export const Popover = styled.div`
  min-width: 230px;
  max-width: 320px;
`
export const Section = styled.div`
  display: flex;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray.secondary};
`

export const Content = styled.div`
  width: 100%;
  ${(props) => props.center && 'text-align: center'};
`

export const Row = styled.div`
  display: flex;
`

export const Label = styled.div`
  width: 50%;
  color: ${(props) => props.theme.colors.text.secondary};
  vertical-align: middle;
  text-align: right;
  font-size: 1rem;

  button {
    padding: 0;
    width: 100%;
    height: initial;

    span {
      color: ${(props) => props.theme.colors.text.secondary};
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      width: 100%;
      font-size: 1rem;
      vertical-align: middle;
    }
    cursor: initial;
  }
`

export const Value = styled.div`
  width: 50%;
  text-align: left;
  padding-left: .5rem;
  font-weight: bold;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
`
