import React, {ReactNode, useEffect, useMemo} from 'react'
import * as S from "./styles";
import {ActionPanelText} from "./styles";
import {__} from "../../../utils/translationUtils";
import {Button} from "components";
import {SyncOutlined, WarningTwoTone} from "@ant-design/icons";
import {triggerOrderEvent} from "../../../providers/ApiProvider/order";

import {eventStatusIcons, eventStatusLabels, JobStatusEnum, MAX_CONCURRENT_JOBS} from './constants'
import {Dropdown, Menu} from "antd";
import {Link} from "react-router-dom";
import moment from "moment";

type ActionStatusPanelProps = {
	row: ConfigurationResult
	orders: Record<string, Job>
	setJobStatus: (orderId: string, status: JobStatus, action?: string) => void
}

const ActionStatusPanel = ({row, orders = {}, setJobStatus}: ActionStatusPanelProps) => {
	const {id} = row
	const orderUpdateAt = row.orderOverview?.lastUpdateDatetime;
	const job = useMemo(() => (orders[id]), [id, orders])

	const nextJobs = useMemo(() => {
		const allJobs = Object.entries(orders)
			.map(([id, job]) => ({...job, id}))
		const currentJobs = allJobs.filter((job) => job.status === JobStatusEnum.ongoing)

		if (currentJobs.length >= MAX_CONCURRENT_JOBS) return []

		return allJobs
			.filter((job) => job.status === JobStatusEnum.toBeProcessed)
			.sort((a, b) => a.position - b.position)
			.slice(0, MAX_CONCURRENT_JOBS - currentJobs.length)
	}, [orders])

	useEffect(() => {
		if (job.status === JobStatusEnum.toBeProcessed && nextJobs.find((job) => job.id === id)) {
			setJobStatus(id, JobStatusEnum.ongoing)

			triggerOrderEvent(id, job.action).then(() => {
				setJobStatus(id, JobStatusEnum.done)
			}).catch((err) => {
				setJobStatus(id, JobStatusEnum.failed)
			})
		}
	}, [id, job, nextJobs])

	return (
		<div style={{display: "flex", alignItems: 'center', justifyContent: 'center', height: "40px"}}>
			<Status job={job} orderId={id} orderUpdatedAt={orderUpdateAt}/>
			{
				job.status === JobStatusEnum.failed && (
					<div style={{position: 'relative', right: '-10px', zIndex: '1'}}>
						<Button
							type={"text"}
							shape={"circle"}
							color={"#e5e5e5"}
							backgroundColor={"#1a313f85"}
							icon={"reload"}
							onClick={() => setJobStatus(id, JobStatusEnum.toBeProcessed)}
						/>
					</div>
				)
			}
		</div>
	)
}

type StatusProps = {
	job: Job,
	orderId: string,
	orderUpdatedAt?: Date,
}

const getStatusContent = (job: Job) => {
	switch (job.status) {
		case JobStatusEnum.toBeProcessed:
			return <>
				{getStatusIcon(job)}
				{__(eventStatusLabels[job.action].toBeProcessed)}
			</>
		case JobStatusEnum.ongoing:
			return <>
				{getStatusIcon(job)}
				<ActionPanelText>{getStatusLabel(job)}</ActionPanelText>
				<SyncOutlined spin/>
			</>
		case JobStatusEnum.done:
			return <>
				{getStatusIcon(job)}
				<ActionPanelText>{getStatusLabel(job)}</ActionPanelText>
			</>
		case JobStatusEnum.alreadyDone:
			return <>
				{getStatusIcon(job)}
				<ActionPanelText>{getStatusLabel(job)}</ActionPanelText>
			</>
		case JobStatusEnum.failed:
			return <>
				{getStatusIcon(job)}
				<ActionPanelText>{getStatusLabel(job)}</ActionPanelText>
				<WarningTwoTone twoToneColor="red"/>
			</>
		default:
			return job.status
	}
}

const getStatusIcon = (job: Job) => {
	switch (job.status) {
		case JobStatusEnum.done:
			return eventStatusIcons[job.action]
		case JobStatusEnum.alreadyDone:
			return eventStatusIcons[job.action]
		default:
			return null
	}
}

const getStatusLabel = (job: Job) => {
	switch (job.status) {
		case JobStatusEnum.toBeProcessed:
			return __(eventStatusLabels[job.action].toBeProcessed)
		case JobStatusEnum.ongoing:
			return __(eventStatusLabels[job.action].ongoing)
		case JobStatusEnum.done:
			return __(eventStatusLabels[job.action].done)
		case JobStatusEnum.alreadyDone:
			return __(eventStatusLabels[job.action].alreadyDone)
		case JobStatusEnum.failed:
			return __(eventStatusLabels[job.action].failed)
		default:
			return job.status
	}
}

const Status = ({job, orderId, orderUpdatedAt}: StatusProps) => {
	const content: ReactNode = getStatusContent(job);

	const dropdownOverlay = <Menu style={{padding: "10px", display: "flex", flexDirection: "column", gap: "5px"}}>
		<b style={{color: "rgb(10, 79, 122)"}}>{getStatusIcon(job)}{getStatusLabel(job)}</b>
		<span style={{color: "black"}}>{__('Last modified date/time')}: {moment(orderUpdatedAt).fromNow(true)} </span>

		<Link
			to={`/orders/${orderId}/overview`}
		>
			{__('Order Overview')}
		</Link>
	</Menu>;
	return <Dropdown overlay={dropdownOverlay} trigger={["click"]} placement="topRight">
		<S.ActionPanel>
			{content}
		</S.ActionPanel>
	</Dropdown>;
}

export default ActionStatusPanel
