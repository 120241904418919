import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { parse } from 'query-string'

import { hashHistory } from '../../providers/HistoryProvider'

import { fetchOrder, findActiveProcess, showMessage } from "../../utils/appHelper"
import * as config from '../../constants/globalConfiguration'
import { createSelector } from "reselect";
import { selectUserStore, getUserData } from "../../providers/ReduxProvider/reducers/userReducer";
import DooOrderPageLegacy from "./DooOrderPageLegacy";
import DooOrderPageBeta from "./DooOrderPage";
import { LoadingSkeleton } from "../../components/DooOrderPage/utils";
import { __ } from '../../utils/translationUtils'


const guiUserSelector = createSelector(selectUserStore, getUserData)

const DooOrderPage = (props) => {
  const guiUser = useSelector(guiUserSelector, shallowEqual)

  const { location, match } = props
  const queryString = useMemo(() => parse(location.search), [location])

  const orderId = useMemo(() => match.params.orderId, [match.params.orderId])
  const [order, setOrder] = useState(null)
  const [activeProcess, setActiveProcess] = useState(null)


  useEffect(() => {
    if (orderId && guiUser && !order) {
      fetchOrder(orderId).then((orderData) => {
        if (orderData) setOrder((prev) =>
          (!prev || prev?.flowId !== orderData?.flowId) ?
            orderData : prev)
        else
          hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
      })
    }
  }, [queryString, orderId, guiUser, order])

  useEffect(() => {
    if (order && !activeProcess) {
      const _activeProcess = findActiveProcess(order.processes, queryString?.activeProcessId)
      if (_activeProcess) setActiveProcess(_activeProcess)
      else {
        showMessage('error', __('NoActiveProcessFound'))
        hashHistory.goBack()
      }
    }
  }, [queryString, order, activeProcess])

  if (!order || !activeProcess)
    return <LoadingSkeleton />

  if (order?.flowConfigVersion)
    return (
      <DooOrderPageBeta
        {...props}
        orderId={orderId}
        order={order}
        activeProcess={activeProcess}
        guiUser={guiUser}
        location={location}
      />
    )

  return (
    <DooOrderPageLegacy
      {...props}
      orderId={orderId}
      order={order}
      activeProcess={activeProcess}
      guiUser={guiUser}
      location={location}
    />
  )
}

export default DooOrderPage
