import React from 'react';
import PropTypes from 'prop-types';
import {
  findActiveProcess,
  fixControllerType, formatData,
  isComplexObject,
  renderCAlgorithm
} from "../utils/appHelper";
import { api } from '../providers/ApiProvider'
import {chunk, find, sortBy} from "lodash";
import OrderHistory from "../components/OrderHistory";
import {Panel, Tab, Tabs} from "react-bootstrap";
import * as config from "../constants/globalConfiguration";
import moment from 'moment';
import {connect} from "react-redux";
import {getEnumValues} from "../providers/ReduxProvider/actions/reportActions";
import SnapshotTabContent from "./SnapshotTabContent";
import {reduxForm} from "redux-form";
import { __ } from '../utils/translationUtils'



class SnapshotOverview extends React.Component {

  static propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    enumValues: PropTypes.object,
    overviewTabReadOnly: PropTypes.bool,
    objectConfiguration: PropTypes.object
  };

  static defaultProps = {
    isSnapshot: false,
    overviewTabReadOnly: true
  };

  constructor(props) {
    super(props);
    this.state = {
      objectId: this.props.match.params.orderId,
      order: null,
      orderDetails: null,
      historyEntries: null,
      responsibleUser: null,
      orderOverview: null,
      orderHistory: null,
      processes: [],
      activeProcess: null,
      isLoading: false,
      loadingOverview: false,
      attributesConfiguration: null,
      attributesConfigurationTree: null,
      tabData: null,
      selectedTab: null,
      panels: {
        details: true
      },
      appointmentModal: {
        open: false
      },
    };

    this.getAttributesConfiguration = this.getAttributesConfiguration.bind(this);
    this.getAttributesConfigurationTree = this.getAttributesConfigurationTree.bind(this);
    this.renderOrderDetails = this.renderOrderDetails.bind(this);
    this.extractAttributeConfig = this.extractAttributeConfig.bind(this);
    this.renderOrderDetailsLine = this.renderOrderDetailsLine.bind(this);
  }

  componentDidMount() {
    this.getSnapshot()
    this.getAttributesConfiguration();
    this.getAttributesConfigurationTree();
  }

  extractAttributeConfig(id) {
    return find(this.state.attributesConfiguration, (a) => a.id === id);
  }

  getAttributesConfigurationTree() {
    return api.get('configurations/attributes/tree')
      .then(
        response => {
          this.setState({
            attributesConfigurationTree: response.data,
          });
        }
      );
  }

  getSnapshot() {

    api.get(`/snapshots/${this.props.match.params.orderId}?withChildren=true`)
      .then( response => {

        let data = response.data;

        // Find active proccess
        let _process = findActiveProcess(data.content.processes);

        // Sort Tabs by position
        const overviewTabs = sortBy(data.content.overviewTabs, 'position');

        this.setState({
          order: data.content,
          orderOverview: overviewTabs,
          historyEntries: data.historyEntries,
          activeProcess: _process || null
        });

      })

  }

  renderOrderDetailsLine(attributeId) {
    const {order} = this.state;
    const attributeConfig = this.extractAttributeConfig(attributeId);
    //TODO wait J approval: const attributeDisplayDataConfig = order?.orderDetails?.displayDataConfiguration?.[attributeId];
    const formatValue = (value) => {

      if (attributeConfig.dependOnProcess || isComplexObject(attributeConfig.type)) {
        return renderCAlgorithm(value, order.orderDetails, attributeConfig,attributeConfig);
      }

      if (!attributeConfig) {
        return;
      }

      if (!value) {
        return '---';
      }
      if (typeof value === 'object')
        return JSON.stringify(value);
      let controllerType = attributeConfig.controllerType || attributeConfig.defaultControllerType; //TODO wait for J approval: let controllerType = attributeDisplayDataConfig?.controllerType || attributeConfig?.controllerType || attributeConfig?.defaultControllerType;
      let fieldType = attributeConfig.type;

      //Ugly fix for translating godoo objects: Whitelisting some properties.
      controllerType = fixControllerType(attributeConfig, fieldType, controllerType);
      return formatData(value,fieldType,controllerType);
    };

    return (
      <div key={attributeId} className="row">
        <label className="col-sm-5 control-label break-word">
          {attributeConfig && __(attributeConfig.propertyLabel)}:
        </label>
        <div className="col-sm-7 break-word">
          <p className="form-control-static">
            {this.state.order && order.orderDetails && formatValue(order.orderDetails.displayData[attributeId], attributeConfig)}
          </p>
        </div>
      </div>
    );
  }

  renderOrderDetails() {
    const {order} = this.state;
    console.log(order);
    if (!order.orderDetails) {
      return null;
    }
    const lastComment = order && order.lastComment;
    const sorted = order.orderDetails.displayData;
    const keys = Object.keys(sorted);
    const fieldGroups = chunk(keys, (keys.length + 1) / 2);

    return (
      <div>
        <span className="collapsible-header no-selection"
              onClick={() => this.setState({"panels": {'details': !this.state.panels.details}})}>
          {__('Main Order Details')}
        </span>
        <Panel
          style={{marginBottom: '40px'}}
          expanded={this.state.panels.details}
        >
          <Panel.Body collapsible>
            {!this.state.attributesConfiguration &&
            <span>{__('loading')}</span>
            }

            <div className="row">
              {this.state.attributesConfiguration && fieldGroups.map((group, k) => {
                return (
                  <div key={'fg-' + k} className="col-sm-6">
                    {group.map((attributeId) => {
                      return this.renderOrderDetailsLine(attributeId);
                    })}
                  </div>
                );
              })}
            </div>

            {lastComment &&
            <div className="comment-item gutter-top">
              <h5>{__('Last Comment')}:</h5>
              {lastComment.text}
              <br/>
              <span className="comment-item--time">
                  {__('Commented at')} {moment(lastComment.creationDatetime).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat)}
                {' '} {__('by')} {lastComment.creator.username}
                </span>
            </div>
            }
          </Panel.Body>
        </Panel>
      </div>
    );
  }

  getAttributesConfiguration() {
    return api.get('configurations/attributes')
      .then(
        response => {
          this.setState({
            attributesConfiguration: response.data,
          });
        }
      );
  }

  updateData() {
    this.getSnapshot();
  }

  renderOrderHistory(){
    const {order, historyEntries} = this.state;
    return(
      <OrderHistory data={historyEntries} orderId={order.id}/>
    );
  }

  renderOverview(){
    const { orderOverview } = this.state;
    if (!orderOverview) return (<div>{__('No Overview')}</div>);
    return (
      <Tabs
        animation={false}
        className="gutter-bottom"
        id="order-tabs"
      >
        {orderOverview.map((tab) => {
          return (
            <Tab key={tab.name} eventKey={tab.name} title={__(tab.name)}>
              <SnapshotTabContent {...this.state} enumValues={this.props.enumValues}
                                 overviewTabReadOnly={this.props.overviewTabReadOnly}
                                 tabData={tab}/>
            </Tab>
          );
        })}
      </Tabs>
    )
  }


  render() {
    const {order} = this.state;

    if (!order){return (<div style={{height: '150px'}}>{__('loading_data')}</div>)}

    return (
      <div id="main-content">
        <h1 className="sticky">
          <div className="container">
            {__('snapshotDetailsTitle')}
          </div>
        </h1>

        <div className={'order-details-page container'}>
          <div className="fieldset style-1">
            {this.renderOrderDetails()}
            {this.renderOrderHistory()}
            {this.renderOverview()}
          </div>
        </div>
      </div>
    );
  }
}

const snapshotOverviewConnected = connect(
  () => {
    return {};
  },
  dispatch => {
    return {
      dispatch,
      getEnumValues,
    };
  },
)(SnapshotOverview);

export default reduxForm({
  form: 'snapshotOverview',  // a unique identifier for this form
}, null)(snapshotOverviewConnected);
