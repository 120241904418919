import * as VIEW_TYPE from "./../context/viewType"
import * as CONFIG_TYPE from "./../context/configurationType"
import requiredFields, {
  DISPLAY_ATTRIBUTES,
  OBJECT_TYPE_ID,
  SEARCH_ATTRIBUTES,
  SORT_ATTRIBUTES,
  SYSTEM_ID
} from "./requiredFields"

export function manageConditionalFormFields(configurationFormFields, changedFields) {
  if (typeof changedFields[SYSTEM_ID] !== "undefined") {
    Object.keys(configurationFormFields).forEach((key) => {
      if (
        key === OBJECT_TYPE_ID ||
        key.startsWith(DISPLAY_ATTRIBUTES) ||
        key.startsWith(SEARCH_ATTRIBUTES) ||
        key.startsWith(SORT_ATTRIBUTES)
      ) {
        delete configurationFormFields[key]
      }
    })
  }

  if (typeof changedFields[OBJECT_TYPE_ID] !== "undefined") {
    Object.keys(configurationFormFields).forEach((key) => {
      if (key.startsWith(DISPLAY_ATTRIBUTES) || key.startsWith(SEARCH_ATTRIBUTES) || key.startsWith(SORT_ATTRIBUTES)) {
        delete configurationFormFields[key]
      }
    })
  }
}

/*
 * This will return the state configuration based on type and the configuration to edit itself
 * */
export function manageStateConfigurationOnType(configurationType, configurationToEdit) {
  return {
    ableToConfigureAttributes:
      (configurationType !== CONFIG_TYPE.OBJECT_LIST && configurationType !== CONFIG_TYPE.OBJECT_BINDER) ||
      (requiredFields[CONFIG_TYPE.OBJECT_LIST].every((f) => configurationToEdit[f]?.value) || requiredFields[CONFIG_TYPE.OBJECT_BINDER].every((f) => configurationToEdit[f]?.value)),
    ableToSubmitForm:
      (configurationType !== CONFIG_TYPE.OBJECT_LIST && configurationType !== CONFIG_TYPE.OBJECT_BINDER) ||
      (requiredFields[CONFIG_TYPE.OBJECT_LIST].every((f) => configurationToEdit[f]?.value) || requiredFields[CONFIG_TYPE.OBJECT_BINDER].every((f) => configurationToEdit[f]?.value)),
  }
}

export const colourTagMatch = (viewType, configType) => {
  if (viewType === VIEW_TYPE.ERROR) {
    return "volcano"
  } else if (viewType === VIEW_TYPE.LOADING) {
    return "geekblue"
  } else {
    switch (configType) {
      case CONFIG_TYPE.OBJECT_BINDER:
        return "cyan"
      case CONFIG_TYPE.OBJECT_LIST:
        return "magenta"
      case CONFIG_TYPE.REPORT:
        return "orange"
      case CONFIG_TYPE.VIEW:
        return "purple"
      default:
        return "default"
    }
  }
}

// This function return the field of the object based on the string path to passed
export const updatePayload = function (payload, args, valueToUpload) {
  const argsCloned = [...args]
  const finalKey = argsCloned.pop()

  // The value exist and it's am object
  if (argsCloned.length && typeof payload[argsCloned[0]] === "object" && payload[argsCloned[0]] !== null) {
    updatePayload(payload[args[0]], args.slice(1, args.length), valueToUpload)

    // The value does not exist and it should be updated
  } else {
    let objectToUpdate = payload

    for (let arg of argsCloned) {
      objectToUpdate[arg] = {}
      objectToUpdate = objectToUpdate[arg]
    }

    objectToUpdate[finalKey] = valueToUpload
  }

  // example Usage Object.byString(someObj, 'part3[0].name');
}

export const filerObjectByKey = (obj, predicate) => {
  let result = {}
  Object.keys(obj)
    .filter((key) => predicate(key))
    .forEach((key) => (result[key] = obj[key]))

  return result
}

export const debounce = (callback, delay = 500) => {
  let timeoutId
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      timeoutId = null
      callback(...args)
    }, delay)
  }
}
