import React from 'react';
import PropTypes from 'prop-types';
import RightsResourceTab from './RightsResourceTab';
import { Tabs, Tab } from 'react-bootstrap';
import { reduxForm} from 'redux-form';
import { bindActionCreators } from 'redux';
//import * as config from '../constants/globalConfiguration';


const validate = () => {
  const errors = {};
  return errors;
};

class TemplateRightsForm extends React.Component {

  static propTypes = {
    handleSubmit: PropTypes.func,
    rightsStructure: PropTypes.object
  };

  constructor(props){
    super(props);
    this.state={
    };

  }

  componentDidMount() {

  }

  handleSubmit(e){
    e.preventDefault();
    this.props.handleSubmit();
  }

  render() {
    
    const listTabs = () => {

      if(!this.props.rightsStructure){
        return null;
      }

      let c = 0;
      return Object.keys(this.props.rightsStructure).map((resource)=>{
        c++;
        return (
          <Tab eventKey={c} key={c} title={resource}>
            <RightsResourceTab resourceName={resource}
              resource={this.props.rightsStructure[resource]} />
          </Tab>
        );
      });
    };

    return (
      <form className="form-horizontal">

        <div className="col-md-12">
          <Tabs id="userRightsTabs" defaultActiveKey={1} animation={false}>
            {listTabs()}
          </Tabs>

        </div>

      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default reduxForm({
  form: 'templateRightsForm',  // a unique identifier for this form
  validate,
}, mapDispatchToProps)(TemplateRightsForm);
