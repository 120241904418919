import * as React from "react";
import {Panel} from "./styles";


// type CollapsiblePanelDeprecatedProps = {
// 	panelKey: string,
// 	eventKey: string,
// 	collapsible?: boolean,
// 	title: React.ReactNode,
// 	body: React.ReactNode,
// }

export default ({ panelKey, eventKey, isParent = false, collapsible = true, title, body }) => {
	const [open, setOpen] = React.useState(false);

	return (
		<Panel
			key={panelKey}
			eventKey={eventKey}
			collapsible={collapsible}
			isParent={isParent}
		>
			<Panel.Heading onClick={() => setOpen((open) => !open)}>{title}</Panel.Heading>
			<Panel.Body style={{ display: open ? 'block' : 'none' }}>
				{body}
			</Panel.Body>
		</Panel>
	)
}
