import {hashHistory} from '../../HistoryProvider';
import objectAssign from 'object-assign';

import {showMessage} from '../../../utils/appHelper';
import {api} from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function generateRemoteObject(params, templates = null, redirect = false) {
  return function () {
    return api.post('/configurations/remoteObjects/autoGenerated', {...params})
      .then(
        response => {
          if (response.status === 200 || response.status === 201) {
            if (redirect) {
              hashHistory.push('/manageRemoteObjects');
            }

            setTimeout(()=>{
                showMessage('success', __('Remote Object created successfully'));
              },
              200
            );
          }
        }
      )
  };
}
export function createRemoteObject(params, onSuccessfulCrate = null, redirect = false) {
  return function () {
    return api.post('/configurations/remoteObjects/',{ ...params })
      .then(
        response => {
          if(response.status === 201 || response.status === 200){
            if(redirect){
              hashHistory.push('/manageRemoteObjects');
            }

            setTimeout(()=>{
                showMessage('success', __('Remote Object created successfully'));
              },
              200
            );
          }
        }
      )
      .catch( (error) => {
        showMessage('error', __('ServerSideError') + '. ' + error);
      });
  };
}

export function updateRemoteObject(params) {
  let payload = objectAssign({}, {...params});

  return function(){
    return api.put('/configurations/remoteObjects/'+params.id,{...payload})
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Remote Object updated successfully'));
            },
            200
          );
          hashHistory.push('/manageRemoteObjects');
        }
      }
    )
    .catch( (error) => {
      showMessage('error', __('ServerSideError') + '. ' + error);
    });
  }

}

export function createRemoteObjectItem(params) {
  return function () {
    let payload = objectAssign({}, {...params});
    delete payload.sortAttributes;
    return api.post('/configurations/remoteObjects/create', {...payload})
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Remote Object updated successfully'));
            },
            200
          );
        }
      }
    )
    .catch( (error) => {
      showMessage('error', __('ServerSideError') + '. ' + error);
    });
  };
}

export function updateRemoteObjectItem(params) {
  
}

export function deleteRemoteObject(id) {
  return function () {
    return api.delete('/configurations/remoteObjects/'+id, {
      status: 'deleted'
    })
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Remote Object deleted successfully'));
            },
            200
          );
        }
      }
    );
  };
}
export function deleteRemoteObjectItem(data) {
  return function () {
    return api.delete(`/configurations/remoteObjects/${data.systemId}/${data.objectTypeId}/${data.primaryKey}`)
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Remote Object deleted successfully'));
            },
            200
          );
        }
      }
    );
  };
}
