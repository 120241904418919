import React from 'react';
import PropTypes from 'prop-types';
import {mapCommentType} from "../../../utils/appHelper";
import moment from "moment";
import _ from 'lodash';
import * as config from "../../../constants/globalConfiguration";
import {Link} from "react-router-dom";
import {OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import Glyphicon from '@strongdm/glyphicon'
import { __ } from '../../../utils/translationUtils'

export class MessageType extends React.Component {

  static propTypes = {
    entitySelected: PropTypes.string,
    typeSelected: PropTypes.string,
    onChangeTypeSelected: PropTypes.func,
    onChangeEntitySelected: PropTypes.func,
    editModeOn: PropTypes.bool,
    entities: PropTypes.array,
  }

  constructor(props){
    super(props);
    this.state = {
      showEntities: props.typeSelected === 'ENTITY'
    };

    this.handleEntityChange = this.handleEntityChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  handleTypeChange(e){
    const value = e.target.value
    this.setState({showEntities: value === 'ENTITY'});
    if (value !== 'ENTITY'){
      this.props.onChangeEntitySelected('')
    }
    this.props.onChangeTypeSelected(value);
  }

  handleEntityChange(e){
    this.props.onChangeEntitySelected(e.target.value);
  }

  renderOptions(){
    const {typeSelected} = this.props;
    return(
      <select value={typeSelected} onChange={this.handleTypeChange} className="form-control" style={{width:"40%",float:"left", marginLeft:"20px"}}>
          <option key="option-global" value="GLOBAL" className="capitalize">{__(mapCommentType('GLOBAL'))}</option>
          <option key="option-entity" value="ENTITY" className="capitalize">{__(mapCommentType('ENTITY'))}</option>
          <option key="option-self" value="SELF" className="capitalize">{__(mapCommentType('SELF'))}</option>
      </select>
    )
  }

  renderEntities(){
    const {entitySelected, entities} = this.props;
    return(
        <select value={entitySelected} onChange={this.handleEntityChange} className="form-control" style={{width:"40%",float:"left", marginLeft:"20px"}}>
          <option value="">{__('Select Organisation')}...</option>
          {entities && entities.map(entity => (entity.type !== 'user_group') && (
            <option key={`entity-option-${entity.id}`} value={entity.id}>
              {entity.name}
            </option>
          ))}
        </select>
    )
  }

  renderEditMessageType(){
    return(
      <div className="row" style={{padding: "5px 10px"}}>
        {this.renderOptions()}
        {this.state.showEntities && this.renderEntities()}
      </div>
    )
  }

  getCommentTypeIcon(visibility){
    switch (visibility) {
      case "GLOBAL":
        return "globe";
      case "ENTITY":
        return "briefcase";
      case "SELF":
        return "eye-close";

    }
  }

  getCommentTypeDescription(visibility, entity){
    switch (visibility) {
      case "GLOBAL":
        return "This comment is visible by everyone";
      case "ENTITY":
        if(entity && entity.type && entity.name && entity.shortName){
          return "This comment can be seen only by users that see the "+__(entity.type)+" "+entity.shortName+" ("+entity.name+")";
        }else{
          return "This comment can be seen only by users that see a specified company or user group";
        }
      case "SELF":
        return "This comment is only visible to you";

    }
  }

  renderMessageType(){
    const typeTooltipMap = [];
    typeTooltipMap[undefined] = null;
    typeTooltipMap["GLOBAL"] = <Tooltip id="Comment-Global">{__("Global")}</ Tooltip>;
    typeTooltipMap["ENTITY"] = <Tooltip id="Comment-Entity">{__("Entity")}</ Tooltip>;
    typeTooltipMap["SELF"] = <Tooltip id="Comment-Asd">{__("Private")}</ Tooltip>;

    let popoverHoverFocus = ( (visibility,entity) => {
        entity = entity || {};
        return <Popover id={`popover-id-${entity.id}`} className="capitalize"
                        title={__(mapCommentType(visibility)) + " " + __("Comment")}>
          {this.getCommentTypeDescription(visibility, entity)}
        </Popover>;
      }
    );

      return (
        <div className="comment-message--tob-bar">
        <span className="comment-item--type">
              <OverlayTrigger
                //overlay={a[comment.type.toUpperCase()]}
                placement="left"
                overlay={popoverHoverFocus(this.props.typeSelected, _.find(this.props.entities, entity => {
                  return entity.id === this.props.entitySelected
                }))}
              >
                <Glyphicon glyph={this.getCommentTypeIcon(this.props.typeSelected)}/>
              </OverlayTrigger>
            </span>
        </div>
      )
  }

  render() {
    const {editModeOn} = this.props;

    if (editModeOn){
      return this.renderEditMessageType();
    } else {
      return this.renderMessageType();
    }

  }

}

export class MessageText extends React.Component {

  static propTypes = {
    messageText: PropTypes.string,
    onChangeMessageText: PropTypes.func,
    editModeOn: PropTypes.bool,
    edited: PropTypes.bool
  }

  constructor(props){
    super(props)

    this.handleMessageTextChange = this.handleMessageTextChange.bind(this)
  }

  handleMessageTextChange(e){
    this.props.onChangeMessageText(e.target.value);
  }

  renderEditMessageText(){
    const {messageText} = this.props;
    return(
      <textarea onChange={this.handleMessageTextChange} value={messageText} className={'message-textarea'} autoFocus={true}/>
    )
  }

  renderMessageText(){
    const {messageText, edited} = this.props;
    return(
      <div className={'comment-item--chat-text comment-item'}>
        {messageText}
        {edited ? <span className="comment-item--edited">&nbsp;({__("edited")})</span> : ''}
      </div>
    )
  }

  render() {
    const {editModeOn} = this.props;

    if (editModeOn){
      return this.renderEditMessageText()
    } else {
      return this.renderMessageText()
    }
  }

}

export class MessageUserInfo extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    dateToShow: PropTypes.string,
    editModeOn: PropTypes.bool,
  }

  constructor(props){
    super(props)
  }

  renderEditMessageUserInfo(){
    const {user, dateToShow} = this.props;
    return(
      <div className="comment-message--tob-bar">
        <span className={'chat-creator'}>{user.username}</span>
        <span
          className="comment-item--time chat-comment-item--time">{moment(dateToShow).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat)}</span>
      </div>
    )
  }

  renderMessageUserInfo(){
    const {user, dateToShow} = this.props;
    return(
      <div className="comment-message--tob-bar">
            <span className={'chat-creator'}>
              <Link to={`/users/${user.id}/edit`}>{user.username}</Link>
              </span>
        <span className="comment-item--time">
              {moment(dateToShow).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat)}
              </span>
      </div>
    )
  }

  render() {
    const {editModeOn} = this.props;

    if (editModeOn){
      return this.renderEditMessageUserInfo()
    } else {
      return this.renderMessageUserInfo()
    }
  }


}

