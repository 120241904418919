/*eslint no-unused-vars: "off"*/
import React from 'react';
import PropTypes from 'prop-types';
import MessageRoom from "./UI/messagesDeprecated/MessageRoom";

class StepComments extends React.Component{

  static propTypes = {
    step: PropTypes.object,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const {step} = this.props;
    if (step){
      return (<MessageRoom objectCaller={step} panelTitle={'Step Comments'} modelCaller={'steps'} defaultType={'GLOBAL'}/>)
    } else {
      return (<div/>)
    }

  }


}

export default StepComments;

