import styled from 'styled-components'

export const SubContainer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  padding: 1.5rem 0;
  position: relative;
  margin-bottom: 1.5rem;
  form, button, .ant-picker {
    width: 100%;
  }
`

export const Container = styled.div`
  padding-bottom: ${props => {
    switch (props.bottom) {
      case 'small':
        return '16px'
      case 'medium':
        return '32px'
      case 'none':
        return 'unset'
      default:
        return '64px'
    }
  }};
  flex-wrap: wrap;
  width: 100%;

  form,
  button,
  .ant-picker {
    width: 100%;
  }


  @media (min-width: 992px) {
    display: flex;
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`

export const Cards = styled.div`
  @media (min-width: 992px) {
    display: grid;
    width: 100%;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Card = styled.div`
  margin-bottom: 1rem;
  box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  padding: 1rem;
  position: relative;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
  .ant-form-item {
    margin: 0;
    margin-bottom: 1rem;
  }
  .ant-form-item-label {
    padding: 0;
  }
`

export const AddWrapper = styled.div`
  box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  padding: 2rem;
  min-height: 75px;
  height: 100%;
  
  button {
    height: 100%;
    width: 100%;
  }

`
export const DeleteWrapper = styled.div`
  position: absolute;
  top: .5rem;
  right: 1rem;
  color: red;
  cursor: pointer;
`

export const Label = styled.div`
  margin-bottom: .75rem;
`
export const Dropdown = styled.div`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  padding: 1rem;
  position: absolute;
  height: 300px;
  overflow-y: scroll;
  background: white;
  z-index: 1;
`
export const Dropdowninner = styled.div`
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d8d8d8;

  .ant-radio-group {
    width: 100%;
    display: flex;
    text-align: center;

    label {
      width: 100%;
    }
  }
`

export const ChartWrapper = styled.div`
  height: 500px;
  background-color: #fafafa;
  position: relative;
  canvas {
    background: #fafafa;
  }

  .ant-table-wrapper {
    overflow-x: scroll;
  }
`
export const TabsWrapper = styled.div`
  width: 100%;
`

export const Icons = styled.div`
  position: absolute;
  top: .5rem;
  right: .5rem;

  svg {
    margin: 0 .5rem;
  }
`

export const SimpleLabel = styled.div`
  height: 3.5rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  background-color: #fafafa;
  border: 1px solid ${(props) => props.theme.colors.gray.primary};
  color: ${(props) => props.theme.colors.text.secondary};
`
