import { api } from "./index";
import { showMessage } from "../../utils/appHelper";

export const fetchSteps = (processId: string) => api.get(`steps?processId=${processId}&withDataInterfaces=true&withChildren=true`)
  .then((response) => response?.data)
  .catch((error) => { showMessage('error', error?.message) })

export const fetchStep = (stepId: string) => api.get(`steps/${stepId}?withDataInterfaces=true&withChildren=true`)
  .then((response) => response?.data)
  .catch((error) => { showMessage('error', error?.message) })

export const putStep = (stepId: string, step: any) => api.put(`/steps/${stepId}`, step)
  .then((response) => response?.data)

/* OrderActions */
export const triggerOrderEvent = (orderId: string, event: OrderEvent) => api.post(`/orders/${orderId}/${event}`)

export const getDetailedAccessRights = (orderId: string) => api.get(`orders/${orderId}/detailedAccessRights`)

export const getMultipleDetailedAccessRights = (orderIds: string, event?: OrderEvent) => api.get(`orders/detailedAccessRights`, { params: { orderIds, event } })
