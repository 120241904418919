/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Button, Dropdown, Form, Input, Menu, message, Popconfirm, Select, Space, Spin, Tag, TreeSelect,} from 'antd'
import {CopyOutlined, EditOutlined, PlusOutlined, SwapRightOutlined} from '@ant-design/icons';
import RGL, {WidthProvider} from 'react-grid-layout'
import {Icon} from '@ant-design/compatible'
import {Panel} from 'react-bootstrap'
import Label from '../../components/Label';
import * as config from '../../constants/globalConfiguration'

import Loading from '../../components/Loading'
import Chart from '../../components/Chart'
import {cloneDashboard} from '../../utils/appHelper'
import {hashHistory} from '../../providers/HistoryProvider'
import getCompaniesTree from './utility/getCompaniesTree'
import producePayload from './utility/producePayload'
import produceChartFormPayload from '../Widget/utility/producePayload'
import getInitialValues from './utility/getInitialValues'
import getChartInitialValues from '../Widget/utility/getInitialValues'
import produceChartPayload from '../Widget/utility/produceChartPayload'
import getIconType from './utility/getIconType'
import WidgetSelectModal from "../../components/Dashboard/WidgetSelectModal";
import computePosition from "./utility/computePosition";
import Result from "antd/es/result";
import NoDashboardSettings from "../../components/Dashboard/NoDashboardSettings";
import {Flex, ErrorBoundary} from 'components'
import * as GlobalS from '../../providers/StyleProvider/styles'
import * as S from './styles'
import {__} from '../../utils/translationUtils'
import {
  api,
  fetchAttributes,
  fetchCompanies,
  fetchCubeConfigurations,
  fetchDashboardById,
  fetchUsers,
  fetchWidgetConfigurationById
} from "../../providers/ApiProvider";

const ReactGridLayout = WidthProvider(RGL)
const {SHOW_ALL} = TreeSelect
const SimpleLabel = ({value}) => <S.SimpleLabel>{value}</S.SimpleLabel>

const WidgetWithChart = ({value, reportsWidgetConfiguration, remove, ...props}) => {

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => hashHistory.push(`/widgets/${value?.id}`)}>
        <Icon type="edit"/> {__('Edit')}
      </Menu.Item>
      <Menu.Item key="1">
        <Popconfirm placement="topLeft" title={__('Are you sure you want to remove this Widget?')} onConfirm={remove}
                    okText={__('Yes')} cancelText={__('No')}>
          <S.IconDeleteWrapper> <Icon type="delete"/> {__('Remove')}</S.IconDeleteWrapper>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      {
        value && (
          <>
            <S.ChartTitle>{value?.name}</S.ChartTitle>
            <S.ChartPlaceholder>
              <Icon type={getIconType(value?.presentation)}/>
            </S.ChartPlaceholder>
          </>
        )
      }
      {value && (
        <S.ChartHandlerWrapper>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className="btn-no-padding btn-std">
              <Icon type="more"/>
            </div>
          </Dropdown>
        </S.ChartHandlerWrapper>
      )}
    </>
  )
}

const Dashboard = (props) => {
  const {
    match: {params: {id: currentId}},
    mode,
    showHeader = true,
    showActions = true
  } = props
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(null)
  const [refreshCount, setRefreshCount] = useState(0)
  const [widgetsLayout, setLayout] = useState([])
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [formValues, setFormValues] = useState({shareWith: 'GLOBAL', editableBy: 'GLOBAL'})
  const [charts, setCharts] = useState([])
  const [form] = Form.useForm()

  const payload = producePayload({...formValues, widgetsLayout})

  useEffect(() => {
    form.resetFields()
  }, [form, refreshCount])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [formValues])

  useEffect(() => {
    const init = async () => {
      setLoading('DATA')

      const firstConfigurationId = await fetchCubeConfigurations().then((data) => data?.[0]?.id)

      if (!firstConfigurationId) {
        setLoading(false)
        return
      }

      const companies = await fetchCompanies()
      const reportsWidgetConfiguration = await fetchWidgetConfigurationById(firstConfigurationId)
      const attributes = await fetchAttributes()

      if (currentId) {
        const dashboard = await fetchDashboardById(currentId)
        const users = await fetchUsers({pageSize: 200, statuses: ["enabled", "disabled", "deleted", "reserved"]})
        const initialValues = (dashboard && users) ? getInitialValues({dashboard, users}) : null

        if (initialValues) {
          setTitle(dashboard.name);
          setLayout(dashboard.widgetsLayout)
          setFormValues(initialValues)

          if (mode === 'view') {
            let results = [];
            (initialValues) && initialValues.widgetBag.forEach((widgetConfiguration) => {
              const chartFormValues = getChartInitialValues({widgetConfiguration, reportsWidgetConfiguration})
              const chartFormPayload = produceChartFormPayload(chartFormValues)
              const chartPayload = produceChartPayload(chartFormPayload)

              let chart = {
                chartPayload,
                id: widgetConfiguration.id,
                results: [],
                ...chartFormValues
              };
              results = [...results, chart];

            });

            setCharts(results);

            initialValues?.widgetBag?.forEach((widgetConfiguration) => {
              // qui simulo QueryRenderer client
              api.get(`${config.prefix()?.REPORT || ''}/widgets/${widgetConfiguration.id}/query`)
                .then((response) => response.data)
                .catch((e) => ({ error: e?.response?.data?.name || e }))
                .then((chartData) => {
                  setCharts((prev) =>
                    prev.map((el) => {
                      if (el.id !== widgetConfiguration.id) {
                        return el;
                      }

                      return {...el, results: [chartData], error: chartData?.error };
                    }));
                })
            });
          }
        }
      }
      setData({
        companiesTree: getCompaniesTree(companies),
        attributes,
        reportsWidgetConfiguration,
        mappings: await api.get('/configurations/valueTypes/mappings').then((response) => response.data),
        widgets: await api.get(`${config.prefix()?.REPORT || ''}/widgets`).then((response) => response.data)
      })
    }
    init().then(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId])

  async function handleSubmit() {
    try {
      await form.validateFields()
      setLoading('SUBMIT')
      if (currentId) {
        await api.put(`${config.prefix()?.REPORT || ''}/dashboards/${currentId}`, payload)
          .then(() => {
            message.success(__('Dashboard correctly edited'))
            hashHistory.goBack()
          })
          .catch(() => message.error(__('Cube js: something wrong')))
      } else {
        await api.post(`${config.prefix()?.REPORT || ''}/dashboards/`, payload)
          .then(() => {
            message.success(__('Dashboard correctly created'))
            hashHistory.goBack()
          })
          .catch(() => message.error(__('Cube js: something wrong')))
      }
    } catch (e) {
      message.error(__('Something wrong. Fix the form errors'))
    }
    setLoading(false)
  }

  function onValuesChange() {
    const result = form.getFieldsValue()
    setFormValues(result)
  }

  function handleChangeGrid(e) {
    setLayout(e)
  }

  return (
    <div id="main-content">
      {
        (mode === 'view') ? (
          <>
            {
              (charts?.length && data && !loading) ?
                <>
                  {showHeader &&
                    <h1 className="sticky">
                      <Flex className="container">
                        <GlobalS.FullWidth>
                          {title}
                        </GlobalS.FullWidth>
                        {showActions &&
                          <S.TitleAction to={`/dashboards/${currentId}/edit`}>
                            <EditOutlined/> {__('edit')}
                          </S.TitleAction>
                        }
                      </Flex>
                    </h1>
                  }
                  <S.GridLayoutWrapper mode={mode}>
                    <ReactGridLayout
                      margin={[16, 16]}
                      layout={widgetsLayout}
                      cols={window?.innerWidth > 768 ? 8 : 1}
                      rowHeight={200}
                      isDraggable={mode === 'edit'}
                      isResizable={mode === 'edit'}>
                      {charts.map(({chartPayload, ...chart}, index) => (
                        <S.ChartWrapper key={index}>
                          <Space>
                            <S.ChartTitle>
                              <Label
                                label={chart.name}
                                description={chart.description}
                              />
                            </S.ChartTitle>
                            {
                              (chart?.results[0]?.slowQuery && !chart.results[0]?.data?.length) &&
                              <Tag color="orange" closable>{__(`Loading could take some time`)}</Tag>
                            }
                            {
                              (chart?.results[0]?.queryInBackGround && !chart?.results[0]?.slowQuery) &&
                              (
                                <Tag color="orange" closable>{__(`loading data`)}</Tag>
                              )
                            }
                          </Space>
                          <ErrorBoundary>
                            {
                              (chart?.results.length > 0) ?
                                <Chart {...data} {...chart} query={chartPayload.cubeQuery.query}/> :
                                <Spin/>
                            }
                          </ErrorBoundary>
                        </S.ChartWrapper>
                      ))}
                    </ReactGridLayout>
                  </S.GridLayoutWrapper>
                </>
                :
                (!data && (loading !== 'DATA') ? <NoDashboardSettings/> :
                    <>
                      {
                        (loading) ? <Loading loading={loading}/> :
                          <Result
                            status="404"
                            title={__('No widget is set for this dashboard')}
                            subTitle={__('Go to dashboard settings.')}
                            extra={<Button type='primary'><Link
                              to='dashboards'>{__('Dashboard settings')}</Link></Button>}
                          />
                      }
                    </>
                )
            }

          </>
        ) : (
          <div>
            {
              (mode === 'edit' || mode === 'create') &&
              <div>
                <h1 className={`sticky ${!currentId ? 'create' : ''}`}>
                  <Flex className="container">
                    <GlobalS.FullWidth>
                      {mode !== "view" && `${currentId ? __('Edit') : __('Create')} ${__('Dashboard')}`}
                    </GlobalS.FullWidth>
                    <Dropdown.Button
                      overlay={
                        <Menu>
                          <Menu.Item
                            icon={<CopyOutlined/>}
                            onClick={() => cloneDashboard(currentId)}
                          >
                            {__('Clone dashboard')}
                          </Menu.Item>
                          <Menu.Item
                            icon={<SwapRightOutlined/>}
                            onClick={() => hashHistory.push(`/dashboards/${currentId}/view`)}
                          >
                            {__('Go to dashboard')}
                          </Menu.Item>
                        </Menu>
                      }
                    />
                  </Flex>
                </h1>
                <div className="container">
                  <Loading loading={loading === 'DATA'}/>
                  {!data && (loading !== 'DATA') && <NoDashboardSettings/>}
                  {data && (loading !== 'DATA') && (
                    <GlobalS.CustomPanel>
                      <Panel.Body>
                        <S.MainContainer>
                          <Form
                            onFinish={handleSubmit}
                            form={form}
                            onValuesChange={onValuesChange}
                            initialValues={formValues}
                            layout="vertical"
                          >
                            <GlobalS.Title bottom={'small'}>
                              {__('Configuration')}
                            </GlobalS.Title>
                            <GlobalS.ItemListingContainer columns={3} margin={'large'}>
                              <GlobalS.CustomFormItem label="Title" name="name"
                                                      rules={[{required: true, message: __('Name required')}]}>
                                <Input/>
                              </GlobalS.CustomFormItem>
                              {currentId && (
                                <GlobalS.CustomFormItem label={__('Last Update DateTime')} name="lastUpdateDateTime">
                                  <SimpleLabel/>
                                </GlobalS.CustomFormItem>
                              )}
                              {currentId && (
                                <GlobalS.CustomFormItem label={__('Modified By')} name="modifiedBy">
                                  <SimpleLabel/>
                                </GlobalS.CustomFormItem>
                              )}
                              {!currentId && (
                                <>
                                  <div/>
                                  <div/>
                                </>
                              )}
                              <GlobalS.CustomFormItem label={__('Sharable with')} name="shareWith"
                                                      rules={[{required: true, message: __('Share with is required')}]}>
                                <Select allowClear>
                                  <Select.Option value="SELF">{__('Private')}</Select.Option>
                                  <Select.Option value="ENTITY">{__('Organisations')}</Select.Option>
                                  <Select.Option value="GLOBAL">{__('Public')}</Select.Option>
                                </Select>
                              </GlobalS.CustomFormItem>
                              {
                                formValues?.shareWith === 'ENTITY' && (
                                  <GlobalS.CustomFormItem label={__('Sharable with entities')} name="shareWithEntities"
                                                          rules={[{
                                                            required: true,
                                                            message: __('Share with is required')
                                                          }]}>
                                    <TreeSelect
                                      style={{width: '100%'}}
                                      dropdownStyle={{maxHeight: 500, overflowY: 'scroll'}}
                                      treeData={data.companiesTree}
                                      fieldNames={{label: 'title', value: 'key', children: 'children'}}
                                      treeCheckable
                                      multiple
                                      treeNodeFilterProp="searchValue"
                                      showCheckedStrategy={SHOW_ALL}
                                      placeholder={__('Search entity(s)')}
                                      maxTagCount={2}
                                    />
                                  </GlobalS.CustomFormItem>
                                )
                              }
                              {formValues?.shareWith === 'ENTITY' ? <>
                                <div/>
                              </> : <>
                                <div/>
                                <div/>
                              </>}
                              <GlobalS.CustomFormItem label={__('Editable by')} name="editableBy" rules={[{
                                required: true,
                                message: __('Editable by is required')
                              }]}>
                                <Select allowClear>
                                  <Select.Option value="SELF">{__('Private')}</Select.Option>
                                  <Select.Option value="ENTITY">{__('Organisations')}</Select.Option>
                                  <Select.Option value="GLOBAL">{__('Public')}</Select.Option>
                                </Select>
                              </GlobalS.CustomFormItem>
                              {
                                formValues?.editableBy === 'ENTITY' && (
                                  <GlobalS.CustomFormItem label={__('Editable by entities')} name="editableByEntities"
                                                          rules={[{
                                                            required: true,
                                                            message: __('Editable by is required')
                                                          }]}>
                                    <TreeSelect
                                      style={{width: '100%'}}
                                      dropdownStyle={{maxHeight: 500, overflowY: 'scroll'}}
                                      treeData={data.companiesTree}
                                      fieldNames={{label: 'title', value: 'key', children: 'children'}}
                                      treeCheckable
                                      multiple
                                      treeNodeFilterProp="searchValue"
                                      showCheckedStrategy={SHOW_ALL}
                                      placeholder={__('Search entity(s)')}
                                      maxTagCount={2}
                                    />
                                  </GlobalS.CustomFormItem>
                                )
                              }

                            </GlobalS.ItemListingContainer>
                            <GlobalS.TitleWithActions bottom={'small'}>
                              {__('Preview')}
                              <Space>
                                <Button
                                  type="text"
                                  onClick={() => {
                                    setLayout([])
                                    setFormValues((e) => ({...e, widgetBag: null}))
                                    setRefreshCount((e) => e + 1)
                                  }}
                                  icon={<Icon type="delete"/>}
                                >
                                  {__('Clear all')}
                                </Button>
                                <Button
                                  type="secondary"
                                  onClick={() => {
                                    setModalOpen(true);
                                  }}
                                  icon={<Icon type="plus"/>}
                                >
                                  {__('Add widget')}
                                </Button>
                              </Space>
                            </GlobalS.TitleWithActions>
                            <S.GridLayoutWrapper>
                              <Form.List name="widgetBag">
                                {
                                  (fields, {add, remove}) => (
                                    <>
                                      {!fields?.length &&
                                        <GlobalS.ChartNoDataAction onClick={() => setModalOpen(true)}>
                                          <GlobalS.NoDataActionContent size={'large'}>
                                            <PlusOutlined/>
                                          </GlobalS.NoDataActionContent>
                                          <GlobalS.NoDataActionContent size={'small'} bulky={'false'}>
                                            {__('Add first widget')}
                                          </GlobalS.NoDataActionContent>
                                        </GlobalS.ChartNoDataAction>
                                      }
                                      <ReactGridLayout
                                        margin={[16, 16]}
                                        layout={widgetsLayout}
                                        cols={window?.innerWidth > 768 ? 8 : 1}
                                        rowHeight={200}
                                        onDrag={handleChangeGrid}
                                        onResize={handleChangeGrid}
                                      >
                                        {fields.map((field, index) => (
                                          <S.ChartWrapper key={field.name} pointer>
                                            <Form.Item name={index} style={{marginBottom: '0px'}}
                                                       rules={[{
                                                         required: true,
                                                         message: __('Please select a widget')
                                                       }]}>
                                              <WidgetWithChart currentId={currentId} {...data} data={{}}
                                                               remove={() => {
                                                                 remove(field.name)
                                                                 //correctly reset indexes when remove a widget
                                                                 let tmpLayout = [...widgetsLayout.filter(w => w.i !== `${field.name}`).map(w => {
                                                                   return (parseInt(w?.i) > parseInt(field.name)) ?
                                                                     {...w, i: `${(parseInt(w?.i) - 1)}`} : w
                                                                 })];
                                                                 setLayout([...tmpLayout]);
                                                               }}/>
                                            </Form.Item>
                                          </S.ChartWrapper>
                                        ))}
                                      </ReactGridLayout>
                                      <WidgetSelectModal
                                        modalOpen={modalOpen}
                                        setModalOpen={setModalOpen}
                                        onSelect={(widget, dimensions) => {
                                          setLayout((prevLayout) => ([
                                            ...prevLayout,
                                            {
                                              i: `${(form.getFieldValue("widgetBag")?.length) ? form.getFieldValue("widgetBag")?.length : 0}`,
                                              h: dimensions.defaultH, w: dimensions.defaultW,
                                              minH: dimensions.minH, minW: dimensions.minW,
                                              ...computePosition(prevLayout, dimensions)
                                            }
                                          ]));
                                          add(widget);
                                        }}
                                      />
                                    </>
                                  )
                                }
                              </Form.List>
                            </S.GridLayoutWrapper>
                          </Form>
                        </S.MainContainer>
                      </Panel.Body>
                      <Panel.Footer>
                        <div className="group">
                          <Popconfirm
                            placement="rightBottom"
                            title={__("Unsaved changes will be lost!")}
                            onConfirm={() => {
                              hashHistory.goBack()
                            }}
                            okText="Exit"
                            showCancel={false}
                          >
                            <Button type="text">
                              {__('Exit')}
                            </Button>
                          </Popconfirm>
                          <button className="btn btn-primary pull-right" type="submit" onClick={handleSubmit}>
                            {__('Save')}
                          </button>
                        </div>
                      </Panel.Footer>
                    </GlobalS.CustomPanel>
                  )}
                </div>
              </div>
            }
          </div>
        )
      }
    </div>

  )
}

export default Dashboard
