import React from 'react';
import PropTypes from 'prop-types';
import { Field} from 'redux-form';
import { renderField } from '../../utils/fieldValidator';
import { __ } from '../../utils/translationUtils'

class PriceEntryControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    const { controlData } = this.props;
    const req = controlData.required?'*':'';

    return (
      <Field
        name={controlData.id}
        description={controlData.description}
        component={renderField}
        label={__(controlData.title)+req}
        className="form-control"
        type="priceEntry"
        />
    );

  }

}

export default PriceEntryControl;
