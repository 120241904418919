import React, {useCallback, useEffect, useMemo} from "react";

import _ from 'lodash'

import { useAnchor } from '../../../hooks/index'
import * as S from './styles'

const AnchorItem = (props) => {
  const {
    label,
    key,
    focused,
    onClick,
  } = props
  return (
    <S.AnchorItem
      key={key}
      focused={focused}
      onClick={onClick}
    >
      { label }
    </S.AnchorItem>
  )
}

export default (props) => {

  const {
    value = '',
    onChange,
    items = [],
    title = '',
    scrollableRef
  } = props

  const ids = useMemo(() => items.map((item) => item.targetId), [items])

  const [currentId, setCurrentId] = useAnchor(ids, scrollableRef, (id) => onChange?.(id))

  const onItemClick = useCallback((id) => {
    if (!id || !_.isString(id)) return null
    setCurrentId(id)
    onChange?.(id)
  }, [setCurrentId, onChange])

  useEffect(() => {
    if (!value || value === currentId) return null
    setCurrentId(value)
    onItemClick(value)
  }, [value])

  return (
    <S.Container>
      { title && (
        <S.Title>
          {title}
        </S.Title>
      )}
      <S.ItemsWrapper>
        { items.map((item) => (
          <AnchorItem
            key={`link-for-${item?.targetId}`}
            label={item?.label}
            focused={currentId === item?.targetId}
            onClick={(e) => {
              e.stopPropagation()
              onItemClick(item?.targetId)
            }}
          />
        ))}
      </S.ItemsWrapper>
    </S.Container>
  )
}

