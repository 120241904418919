import {T} from "../../../utils/translationUtils";
import {CloseOutlined, DeleteFilled, DeleteOutlined, FileZipOutlined} from "@ant-design/icons";
import React, {ReactNode} from "react";

export const MAX_CONCURRENT_JOBS = 1

export const JobStatusEnum: Record<string, JobStatus> = {
	toBeProcessed: 'toBeProcessed',
	ongoing: 'ongoing',
	done: 'done',
	failed: 'failed',
	alreadyDone: 'alreadyDone',
}

export const eventStatusLabels: Record<string, Record<string, string>> = {
	archive: {
		toBeProcessed: T.ready_to_archive,
		ongoing: T.archiving,
		done: T.archived,
		failed: T.archiving_failed,
		alreadyDone: T.archived,
	},
	cancel: {
		toBeProcessed: T.ready_to_cancel,
		ongoing: T.cancelling,
		done: T.cancelled,
		failed: T.cancelling_failed,
		alreadyDone: T.cancelled,
	},
	delete: {
		toBeProcessed: T.ready_to_delete,
		ongoing: T.deleting,
		done: T.deleted,
		failed: T.deleting_failed,
		alreadyDone: T.deleted,
	},
	hardDelete: {
		toBeProcessed: T.ready_to_hard_delete,
		ongoing: T.hard_deleting,
		done: T.hard_deleted,
		failed: T.hard_deleting_failed,
		alreadyDone: T.hard_deleted,
	},
}

export const eventStatusIcons: Record<string, ReactNode> = {
	archive: <FileZipOutlined/>,
	cancel: <CloseOutlined/>,
	delete: <DeleteOutlined/>,
	hardDelete: <DeleteFilled/>,
}

export const actionExecWarning: Record<OrderEvent, string> = {
	archive: T.orders_being_archived_warning,
	cancel: T.orders_being_cancelled_warning,
	delete: T.orders_being_deleted_warning,
	hardDelete: T.orders_being_hard_deleted_warning,
}
