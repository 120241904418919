export const langToMomentLocale = (lang = 'en_GB') => {
  switch (lang) {
    case 'no':
      return 'nb'
    default:
      return lang.replaceAll('_', '-').toLowerCase()
  }
}

export const langToAntdLocale = (lang = 'en_GB') => {
  switch (lang) {
    case 'de_CH':
      return 'de_DE'
    case 'fr_CH':
      return 'fr_FR'
    case 'no':
      return 'nb_NO'
    default:
      return lang
  }
}

