import {Collapse, Descriptions, Drawer, Spin, Tag, Timeline} from "antd";
import styled, {css} from "styled-components/macro";

export const CustomTimeline = styled(Timeline)`
  overflow: hidden;
  padding: 10px 40px 10px 20px;
  &.left-timeline.ant-timeline {
    & .ant-timeline-item-label {
      width: calc(25% - 12px);
    }
    & .ant-timeline-item-tail, & .ant-timeline-item-head, & .ant-timeline-item-head-custom {
      left: 25%;
    }
    & .ant-timeline-item-content {
      left: calc(25% - 4px);
      width: calc(75% - 14px);
      margin-left: 35px;
      & .ant-card {
        margin: 0 30px 20px 0;
        max-width: 800px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        transition-duration: .25s;
        &:hover {
          box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
        }
        & .ant-card-head {
          border-bottom: unset;
        }
        & .ant-card-body {
          padding: 0;
        }
      }
    }
  }
`

export const CustomActorDescription = styled(Descriptions)`
  & .ant-descriptions-view table {
    width: unset;
  }
`

export const ActionButton = styled.a`
  float: left;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: 100%;
`

export const GhostCollapse = styled(Collapse)`
  
  &.ant-collapse > .ant-collapse-item, .ant-collapse-item {
    border: unset;
    margin-bottom: unset;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: unset;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-content, .ant-collapse.ant-collapse-ghost > .ant-collapse-item .ant-collapse-content {
    border-top: unset;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header, .ant-collapse-header {
    transition-duration: .3s;
    border-bottom: solid 1px transparent;
    border-top: solid 1px transparent;
    background-color: transparent;
    padding: 0 22px 0 10px;
    color: rgba(0, 0, 0, 0.25);
    border-top-color: #f0f0f0;
    &:hover {
      padding-left: 16px;
      padding-right: 16px;
      color: rgba(0, 0, 0, 0.5);
    }
  } 
  &.ant-collapse.ant-collapse-ghost > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header, .ant-collapse-header {
    color: rgba(0, 0, 0, 0.85);
    border-bottom-color: #f0f0f0;
    background-color: #fafafa;
    border-radius: 0;
    height: 40px;
    padding: 0 16px;
  }
  & .ant-descriptions-view {
    border: unset;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  padding: 5px 0;
`

export const TagsContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  line-height: 2px;
`

export const TagType = styled.div`
  font-size: 8px;
  color: darkgray;
`

export const TagContent = styled.div`
  text-align: center;
  width: 100%;
  padding-left: 7px;
  font-size: 14px;
`

export const clickable = css`
  cursor: pointer;
  &:hover {
    background: white;
  }
`

export const notClickable = css`
  cursor: not-allowed;
`

export const SnapshotTag = styled(Tag)`
  &.ant-tag {
    position: relative;
    padding: 5px 7px;
    margin: 5px 6px;
    display: flex;
    flex: 1;
    border: 1px solid #d9d9d9;
    box-shadow: rgba(99, 99, 99, 0) 0 2px 4px 0;
    ${(props) => props.clickable ? clickable : notClickable}
  }
`

export const Relative = styled.div`
  position: relative;
`

export const ModalDrawer = styled(Drawer)`
  position: absolute;
  &.ant-drawer {
    & .ant-drawer-mask {
      background-color: rgba(0, 0, 0, 0.20);
    }
  }
`

export const SecondaryButton = styled.button`
  border: 1px solid #005c73;
  color: #00647d;
  background-color: white;
  border-radius: 2px;
  height: 32px;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  touch-action: manipulation;
  padding: 4px 15px;
  font-size: 14px;
  &:hover,
  &:active,
  &:focus {
    color: #008db0;
    border-color: #008db0;
    background-color: #fafafa;
  }
`

export const FullSpaceSpin = styled(Spin)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TimelineHeader = styled.div`
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  margin-bottom: 30px;
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  vertical-align: middle;
  align-items: center;
`
