import objectAssign from 'object-assign';

import { showMessage } from '../../../utils/appHelper';
import { hashHistory } from '../../HistoryProvider';
import * as types from '../types';
import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function updateOverviewObject({ id, params }) {
  let payload = objectAssign({}, {...params});

  return function(){
    return api.put(`/configurations/overviewObjects/${id}`, {...payload})
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Overview Object updated successfully'));
            },
            200
          );
        }
      }
    )
    .catch( (error) => {
      showMessage('error', __('ServerSideError') + '. ' + error);
    });
  }

}

export function createOverviewObject(params, onObjectCreate = undefined) {
  return function () {
    return api.post('/configurations/overviewObjects/',{ ...params })
      .then(
        response => {
          if(response.status === 200){
            setTimeout(()=>{
                showMessage('success', __('Overview Object created successfully'));
              },
              200
            );

            if (onObjectCreate) {
              onObjectCreate();
            } else {
              hashHistory.push('/overviewObjects');
            }
          }
        }
      )
      .catch( (error) => {
        showMessage('error', __('ServerSideError') + '. ' + error);
      });
  };
}

export function deleteOverviewObjects(id) {
  return function (dispatch) {
    return api.delete('/configurations/overviewObjects/'+id, {
    })
      .then(
        response => {
          if(response.status === 200){
            setTimeout(()=>{
                showMessage('success', __('Overview Object deleted successfully'));
              },
              200
            );
          }
          dispatch({
            type: types.ORDERGROUP_DELETED,
            payload: response.data
          });
        }
      );
  };
}
