export const SPECIFIC_VALUE = "SPECIFIC_VALUE"
export const RANGE = "RANGE"
export const ENUMERATION = "ENUMERATION"

export function retrieveMechanismInitialValue(attributeProperty) {
  if (attributeProperty?.specificValueMechanismAllowed) {
    return SPECIFIC_VALUE
  } else if (attributeProperty?.rangeMechanismAllowed) {
    return RANGE
  } else if (attributeProperty?.enumMechanismAllowed && attributeProperty?.enumerationId) {
    return ENUMERATION
  }

  return null
}
