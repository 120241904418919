import React, { useCallback, useEffect, useRef, useState } from 'react'

import ConfiguratorHelper from './ConfiguratorHelper';
import { useConfigurationConfiguratorContext } from "../../context";
import { useTranslationList } from "../../../../hooks";
import { valueTypeToInput } from "../../../../utils/renderInputField";
import { mechanism } from "../../../../components/ConfigurationConfiguratorBeta/constants";
import { getMechanismItems } from "../../../../components/ConfigurationConfiguratorBeta/utils";
import * as config from '../../../../constants/globalConfiguration'
import * as C from "../../constants";
import { __ } from '../../../../utils/translationUtils'

const Filters = ({ treeItems }) => {
  const { state, updateEnumerations } = useConfigurationConfiguratorContext()
  const {
    configurations,
    attributesMap,
    enumerations,
  } = state || {}

  const [formItems, setFormItems] = useState(null)
  const translationItems = useTranslationList()
  const formRef = useRef(null)

  useEffect(() => {
    const enumIds = Object.keys(configurations[C.steps.FILTERS]).reduce((prev, key) => {
      const enumId = attributesMap[key].enumerationId
      if (enumId) prev.push(enumId)
      return prev
    }, [])
    updateEnumerations(enumIds)
  }, [])

  const getItemFormInitialValue = useCallback((key) => {
    const attribute = attributesMap[key]
    updateEnumerations([attribute?.enumerationId])
    return {
      mechanism: mechanism.SPECIFIC_VALUE,
      defaultValue: null,
      exactMatch: false,
      defaultCustomTitle: attribute.propertyLabel,
      customTitle: null,
      required: false,
      hidden: false,
    }
  }, [attributesMap])

  const updateFormItems = useCallback((attribute, itemValue) => {
    const godooType = valueTypeToInput[attribute.type] || valueTypeToInput.DEFAULT
    const { mechanism: currentMechanism, multiSelect: currentMultiSelect } = itemValue

    const canExactMatch = currentMechanism === mechanism.SPECIFIC_VALUE && !config.TYPES_NO_ASTERISKS_ALLOWED.includes(attribute?.type?.toUpperCase())

    setFormItems([
      {
        name: 'mechanism',
        label: __('mechanism'),
        span: canExactMatch ? 10 : 12,
        type: 'select',
        showSearch: true,
        optionFilterProp: 'label',
        allowClear: true,
        placeholder: __('Default'),
        items: getMechanismItems(attribute),
      },
      {
        name: 'defaultValue',
        label: __('default_value'),
        span: canExactMatch ? 10 : 12,
        type: (
          (currentMechanism === mechanism.SPECIFIC_VALUE && (godooType !== 'select' ? godooType : 'input')) ||
          (currentMechanism === mechanism.RANGE && 'range') ||
          (currentMechanism === mechanism.ENUMERATION && 'select')
        ),
        ...(currentMechanism === mechanism.SPECIFIC_VALUE && {
          placeholder: __('DEFAULT_VALUE')
        }),
        ...(currentMechanism === mechanism.RANGE && {
          rangeType: godooType !== 'select' ? godooType : 'input',
          direction: 'vertical',
        }),
        ...(currentMechanism === mechanism.ENUMERATION && {
          placeholder: __('select_a_default_value'),
          items: attribute.enumerationId ? enumerations[attribute.enumerationId] : [],
          mechanism: currentMechanism,
          mode: currentMultiSelect && 'multiple',
          showSearch: true,
          optionFilterProp: 'label',
          allowClear: true,
        }),
      },
      ...(canExactMatch ? [{
        span: 4,
        name: 'exactMatch',
        label: __('exact_match', 'capitalize_sentence'),
        type: 'switch',
        size: 'small',
      }] : []),
      ...(currentMechanism === mechanism.ENUMERATION ? [{
        offset: 12,
        span: 12,
        name: 'multiSelect',
        label: __('multi_select'),
        type: 'switch',
      }] : []),
      {
        name: 'customTitle',
        label: __('custom_title'),
        type: 'select',
        showSearch: true,
        optionFilterProp: 'label',
        allowClear: true,
        placeholder: __(itemValue.defaultCustomTitle || 'use default'),
        items: translationItems,
        disabled: !translationItems?.length

      },
      {
        name: 'required',
        label: __('required'),
        type: 'switch',
      },
      {
        name: 'hidden',
        label: __('hidden'),
        type: 'switch',
      },
    ])
  }, [enumerations, translationItems])

  const onFormOpen = useCallback((item, values) => {
    const itemValue = values[item.key]
    const attribute = attributesMap[item.key]
    updateFormItems(attribute, itemValue)
  }, [updateFormItems, attributesMap])

  const onFormChange = useCallback((item, changes, values, formRef) => {
    if (!['mechanism', 'multiSelect'].some((r) => Object.keys(changes).includes(r))) return null
    const itemValue = values?.[item.key]
    const attribute = attributesMap?.[item.key]
    const currentValue = { ...itemValue, ...changes }
    const { mechanism: newMechanism, multiSelect: newMultiSelect } = changes
    if (newMechanism) {
      if (newMechanism === mechanism.ENUMERATION) currentValue.defaultValue = null
      if (newMechanism === mechanism.SPECIFIC_VALUE) currentValue.defaultValue = null
      if (newMechanism === mechanism.RANGE) currentValue.defaultValue = { from: null, to: null }
      formRef?.current?.setFieldsValue(currentValue)
    }
    if (newMultiSelect != null) {
      currentValue.defaultValue = newMultiSelect ? [] : null
      formRef?.current?.setFieldsValue({ defaultValue: currentValue.defaultValue })
    }
    updateFormItems(attribute, currentValue)
    return currentValue
  }, [attributesMap, updateFormItems])

  return (
    <ConfiguratorHelper
      treeItems={treeItems}
      formItems={formItems}
      onFormOpen={onFormOpen}
      onFormChange={onFormChange}
      formRef={formRef}
      getItemFormInitialValue={getItemFormInitialValue}
      labels={{
        noSelectedItemsTitle: __('filters_no_data_title'),
        noSelectedItemsContent: __('filters_no_data_content'),
      }}
      left={{ title: __("choose_filters") }}
      right={{ title: __("set_and_order_selected_fields") }}
      pinnedKeys={Object.keys(configurations[C.steps.OUTPUT_FIELDS] || [])}
    />
  )
}

export default Filters
