import styled from "styled-components/macro";


export const StepPageInfo = styled.div`
  display: flex;
  padding: 2px 24px;
  color: #e2e2e6;
  font-size: small;
  font-weight: lighter;
`

export const Tag = styled.div`
  border-radius: 8px;
  width: fit-content;
  padding: 4px 12px;
  margin: 8px 8px 8px 0;
  border: 1px solid #8c8c8c;
  &:hover {
    filter: brightness(1.2);
  }
`

export const StepWrapper = styled.div`
  display: flex;
  height: calc(100vh - 120px);
  overflow: hidden;
`

export const StepContainer = styled.div`
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #E6E6E6FF;
  
  .ant-layout {
    min-height: unset !important;
  }

  .modal-grid-layout {
    min-height: unset !important;
  }
`

export const StickyFooter = styled.div`
  box-shadow: 0 10px 20px 0 gray;
  position: absolute;
  bottom: 0;
  width: 100vw;
  padding: 12px 45px;
  background-color: #e6e6e6;
  z-index: 10;
`
