import moment from 'moment'

export default ({ widgetConfiguration, reportsWidgetConfiguration, users }) => {
  const { query, name, visualization, description, presentation, shareWith, editableBy, lastUpdateDateTime, lastModification } = widgetConfiguration
  const { stackResults, pivotConfig, yLabel, xLabel } = visualization || {}

  const { timeDimensions, dimensions, filters, measures, limit, order, options } = query

  const [firstSelectedTime] = timeDimensions || []

  let enumeration = null
  function getValue(id, items) {
    // if (!items || !id) return JSON.stringify({ fieldKey: '', fieldKeyEnum: '' })
    const matched = items.find((e) => e.fieldKey === id)

    if (matched && !matched?.isEnumeration) {
      return JSON.stringify({
        fieldKey: id,
        valueType: matched.valueType,
        fieldKeyEnum: items.find((e) => e.attributeId === matched.attributeId && e.isEnumeration)?.fieldKey
      }, 2)
    }

    if (matched?.isEnumeration) {
      enumeration = id
      return JSON.stringify({
        fieldKey: items.find((e) => e.attributeId === matched.attributeId && !e.isEnumeration)?.fieldKey,
        valueType: items.find((e) => e.attributeId === matched.attributeId && !e.isEnumeration)?.valueType,
        fieldKeyEnum: id
      }, 2)
    }

    return null

    // return JSON.stringify({ fieldKey: '', fieldKeyEnum: '' })
  }

  const result = {
    name,
    lastUpdateDateTime,
    modifiedBy: users?.find(user => user?.id === lastModification?.modifiedBy)?.username,
    description,
    presentation,
    enumeration,
    limit,
    shareWith: shareWith?.type,
    shareWithEntities: shareWith?.entityIds,
    editableBy: editableBy?.type,
    editableByEntities: editableBy?.entityIds,
    filters: filters?.map(({ member, values, operator }) => {
      let updatedValues = values;
      const memberValue = getValue(member, reportsWidgetConfiguration.filters);
      const valueType = JSON.parse(memberValue)?.valueType;

      if(valueType === 'time'){
        const isDateRange = Array.isArray(values) // if the values is an array, I assume that is a dateRange

        if (isDateRange) {
          updatedValues = updatedValues.map((e) => moment(e).format('YYYY-MM-DD'))
        }

        // everything beside numbers and string is a date
        const isSingleDate = !isDateRange && updatedValues && typeof updatedValues !== 'string' && !Number(`${updatedValues}`)

        if (isSingleDate) {
          updatedValues = moment(updatedValues).format('YYYY-MM-DD')
        }

      }

      return {
        member: memberValue,
        operator,
        values: updatedValues
      }
    }),
    chartLibrary: 'bizcharts',
    timeDimensions: getValue(firstSelectedTime?.dimension, reportsWidgetConfiguration.times),
    granularity: firstSelectedTime?.granularity,
    filterOutLastGranularityBin: options?.filterOutLastGranularityBin,
    dateRange: Array.isArray(firstSelectedTime?.dateRange) ? 'Custom' : firstSelectedTime?.dateRange,
    customDateRange: firstSelectedTime?.dateRange && Array.isArray(firstSelectedTime?.dateRange) && firstSelectedTime?.dateRange.map((e) => moment(e)),
    dimensions: dimensions?.map((dimension) => getValue(dimension, reportsWidgetConfiguration.dimensions)) || [],
    measures: measures?.map((measure) => getValue(measure, reportsWidgetConfiguration.measures)) || [],
    visualization: {
      pivotConfig: pivotConfig?.map(([value, pivot]) => ({
        value: getValue(value, [...reportsWidgetConfiguration.dimensions, ...reportsWidgetConfiguration.measures, reportsWidgetConfiguration.timeDimensions].filter(e => e)),
        pivot
      })) || [],
      stackResults: stackResults || 'false',
    },
    yLabel,
    xLabel,
    order: order?.map(([dimension, order]) => ({ dimension: getValue(dimension, reportsWidgetConfiguration.dimensions), order })) || [],
    percents: measures?.map((measure) => getValue(measure, reportsWidgetConfiguration.measures))?.filter((measure) => reportsWidgetConfiguration.measures.find(({ fieldKey }) => fieldKey === JSON.parse(measure).fieldKey)?.measureName === 'countDistinct')
      .map(measures => {
        const matchedPercentage = options?.percents?.find(({fieldKey}) => fieldKey === JSON.parse(measures)?.fieldKey)
        return {
          measures,
          percentage: matchedPercentage
            ? matchedPercentage.keepBoth ? "keepBoth" : "relative"
            : "absolute"
        }
      }) || [],
    runningTotals: measures?.map((measure) => getValue(measure, reportsWidgetConfiguration.measures))?.filter((measure) => reportsWidgetConfiguration.measures.find(({ fieldKey }) => fieldKey === JSON.parse(measure).fieldKey)?.measureName === 'countDistinct')
      .map(measures => {
        const matchedPercentage = options?.runningTotals?.find(({fieldKey}) => fieldKey === JSON.parse(measures)?.fieldKey)
        return {
          measures,
          percentage: matchedPercentage
            ? matchedPercentage.keepBoth ? "keepBoth" : "relative"
            : "absolute"
        }
      }) || [],
  }

  return {
    ...result,
    enumeration
  }
}
