import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {mapCommentType} from "../../../../utils/appHelper";
import _ from 'lodash';
import {Link} from "react-router-dom";
import {OverlayTrigger} from "react-bootstrap";
import Glyphicon from '@strongdm/glyphicon'
import {getCommentTypeDescription, getCommentTypeIcon} from "../utils";
import {Popover} from "antd";
import {Info, TextContent, UserInfoContainer} from "./styles";
import {Flex} from "components";
import UserAvatar from "../../UserAvatar";
import {getTimeElapsed} from "components/dist/Utils/TimeUtils";
import {__} from '../../../../utils/translationUtils'

export class MessageTypeDeprecated extends React.Component {

  static propTypes = {
    entitySelected: PropTypes.string,
    typeSelected: PropTypes.string,
    onChangeTypeSelected: PropTypes.func,
    onChangeEntitySelected: PropTypes.func,
    editModeOn: PropTypes.bool,
    entities: PropTypes.array,
  }

  constructor(props) {
    super(props);
    this.state = {
      showEntities: props.typeSelected === 'ENTITY'
    };

    this.handleEntityChange = this.handleEntityChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  handleTypeChange(e) {
    const value = e.target.value
    this.setState({showEntities: value === 'ENTITY'});
    if (value !== 'ENTITY') {
      this.props.onChangeEntitySelected('')
    }
    this.props.onChangeTypeSelected(value);
  }

  handleEntityChange(e) {
    this.props.onChangeEntitySelected(e.target.value);
  }

  renderOptions() {
    const {typeSelected} = this.props;
    return (
      <select value={typeSelected} onChange={this.handleTypeChange} className="form-control"
              style={{width: "40%", float: "left", marginLeft: "20px"}}>
        <option key="option-global" value="GLOBAL" className="capitalize">{__(mapCommentType('GLOBAL'))}</option>
        <option key="option-entity" value="ENTITY" className="capitalize">{__(mapCommentType('ENTITY'))}</option>
        <option key="option-self" value="SELF" className="capitalize">{__(mapCommentType('SELF'))}</option>
      </select>
    )
  }

  renderEntities() {
    const {entitySelected, entities} = this.props;
    return (
      <select value={entitySelected} onChange={this.handleEntityChange} className="form-control"
              style={{width: "40%", float: "left", marginLeft: "20px"}}>
        <option value="">{__('Select Organisation')}...</option>
        {entities && entities.map(entity => (entity.type !== 'user_group') && (
          <option key={`entity-option-${entity.id}`} value={entity.id}>
            {entity.name}
          </option>
        ))}
      </select>
    )
  }

  renderEditMessageType() {
    return (
      <div className="row" style={{padding: "5px 10px"}}>
        {this.renderOptions()}
        {this.state.showEntities && this.renderEntities()}
      </div>
    )
  }

  renderMessageType() {
    let popoverHoverFocus = ((visibility, entity) => {
        entity = entity || {};
        return <Popover id={`popover-id-${entity.id}`} className="capitalize"
                        title={__(mapCommentType(visibility)) + " " + __("Comment")}>
          {getCommentTypeDescription(visibility, entity)}
        </Popover>;
      }
    );

    return (
      <div className="comment-message--tob-bar">
        <span className="comment-item--type">
              <OverlayTrigger
                placement="left"
                overlay={popoverHoverFocus(this.props.typeSelected, _.find(this.props.entities, entity => {
                  return entity.id === this.props.entitySelected
                }))}
              >
                <Glyphicon glyph={getCommentTypeIcon(this.props.typeSelected)}/>
              </OverlayTrigger>
            </span>
      </div>
    )
  }

  render() {
    const {editModeOn} = this.props;

    if (editModeOn) {
      return this.renderEditMessageType();
    } else {
      return this.renderMessageType();
    }
  }
}

export class MessageTextDeprecated extends React.Component {

  static propTypes = {
    messageText: PropTypes.string,
    onChangeMessageText: PropTypes.func,
    editModeOn: PropTypes.bool,
    edited: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.handleMessageTextChange = this.handleMessageTextChange.bind(this)
  }

  handleMessageTextChange(e) {
    this.props.onChangeMessageText(e.target.value);
  }

  renderEditMessageText() {
    const {messageText} = this.props;
    return (
      <textarea onChange={this.handleMessageTextChange} value={messageText} className={'message-textarea'}
                autoFocus={true}/>
    )
  }

  renderMessageText() {
    const {messageText, edited} = this.props;
    return (
      <TextContent>
        {messageText}
        {edited ? <Info>({__("edited")})</Info> : ''}
      </TextContent>
    )
  }

  render() {
    const {editModeOn} = this.props;

    if (editModeOn) {
      return this.renderEditMessageText()
    } else {
      return this.renderMessageText()
    }
  }
}

export const MessageUserInfo = ({user, typeSelected, entity, dateToShow}) => {
  const timeElapsed = useMemo(() => {
    const elapsed = getTimeElapsed(dateToShow, new Date())
    return !_.isEmpty(elapsed) ? `${elapsed.truncatedElapsed} ${elapsed.unit} ago` : `1 second ago`
  }, [dateToShow])

  return (
    <UserInfoContainer>
      <span>
        <UserAvatar
          email={user?.email}
          avatarName={`${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`}
          userId={user?.id}
          avatarType={user?.avatar?.avatarType || "INITIALS"}
          title={user?.username}
        />
      </span>
      <Flex style={{alignItems: 'baseline'}}>
        <Info>
          <Link to={`/users/${user.id}/edit`}>{user.username}</Link>
        </Info>
        <Info>
          {timeElapsed}
        </Info>
        <Info>
          <Popover
            title={__(mapCommentType(typeSelected)) + " " + __("Comment")}
            content={getCommentTypeDescription(typeSelected, entity)}>
            <Glyphicon glyph={getCommentTypeIcon(typeSelected)}/>
          </Popover>
        </Info>
      </Flex>
    </UserInfoContainer>
  )
}

export const MessageText = ({text, edited}) => (
  <TextContent>
    {text}
    {edited ? <Info>({__("edited")})</Info> : ''}
  </TextContent>
)
