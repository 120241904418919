import React, {useCallback} from 'react';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortableHandle,
  SortEnd,
} from "react-sortable-hoc";
import {__} from '../../../utils/translationUtils'
import {Button, Form} from "components";
import {SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import {Switch} from "antd";
import {FormRef} from "../../../temporary/temporaryExports";


type State = SortAttribute[]

const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

type SortValue = 'DESC' | 'ASC'
type SortingToggleButtonProps = {
  value: SortValue
  onChange: (value: SortValue) => void
}

const SortingToggleButton = (props: SortingToggleButtonProps) => {
  const {value, onChange} = props

  const toggleSorting = () => {
    const newValue = value === 'ASC' ? 'DESC' : 'ASC';
    onChange(newValue);
  }

  return (
    <Button type="outlined" shape="circle" onClick={toggleSorting}>
      {value === 'ASC' ? <SortDescendingOutlined/> : <SortAscendingOutlined/>}
    </Button>
  );
};

interface SortableItemProps extends SortableElementProps {
  item: SortAttribute;
  onChange: (newItem: SortAttribute) => void;
  getAttribute: (attributeId: string) => Attribute | undefined; // Adjust the return type if needed
}

const SortableItem = SortableElement<SortableItemProps>((props: SortableItemProps) => {
  const {item, onChange, getAttribute} = props

  const attribute = getAttribute(item?.attributeId)
  if (!attribute) {
    return null
  }

  const {propertyLabel} = attribute

  return (
    <div className="sorting-list-item" key={`item-${item.attributeId}`}>
      <div className="header" style={{display: "flex", justifyContent: "space-between"}}>
        <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
          <DragHandle/>
          <Switch
            size="small"
            checked={item.enabled}
            onChange={(enabled) => onChange({...item, enabled})}
          />
          <strong>{__(propertyLabel, "capitalize_sentence")}</strong>
        </div>
        <SortingToggleButton
          value={item.value as SortValue}
          onChange={(value) => onChange({...item, value})}
        />
      </div>
    </div>
  )
});

interface SortableListProps extends SortableContainerProps {
  value: State
  onChange: ChangeHandler<State>;
  getAttribute: (attributeId: string) => Attribute | undefined; // Adjust the return type if needed
}

const SortableList = SortableContainer<SortableListProps>((props: SortableListProps) => {
  const {
    value,
    onChange,
    getAttribute
  } = props

  const handleItemChange = (newItem: SortAttribute) => {
    const index = value.findIndex((i) => i.attributeId === newItem.attributeId);

    if (index !== -1) {
      const newValue = [...value];
      newValue[index] = newItem;
      onChange(newValue);
    }
  };

  return (
    <div className="sorting-list clearfix">
      {
        value.map((item) => (
          <SortableItem
            key={`item-${item.attributeId}`}
            index={item.position}
            item={item}
            onChange={handleItemChange}
            getAttribute={getAttribute}
          />
        ))
      }
    </div>
  )
})

type SortFormProps = {
  value: State
  onSubmit: (value: State) => void
  getAttribute: (attributeId: string) => Attribute | undefined
  componentRef: React.MutableRefObject<FormRef | undefined>
}

const SortForm = (props: SortFormProps) => {
  const {
    value = [],
    onSubmit = () => {
    },
    getAttribute,
    componentRef
  } = props

  return (
    <Form
      id="sort-form-id"
      formRef={componentRef}
      initialValues={{sort: value}}
      onSubmit={(values: { sort: SortAttribute[] }) => onSubmit(values.sort)}
      inputs={[{
        type: "custom",
        name: "sort",
        CustomRender: (props: { value: SortAttribute[], onChange: ChangeHandler<State> }) => {
          const {value, onChange} = props
          const onSort = useCallback((props: SortEnd) => {
            const {oldIndex, newIndex} = props || {}
            onChange(value.map((item) => {
              if (oldIndex === item.position) return {...item, position: newIndex}
              if (newIndex > oldIndex && item.position >= oldIndex && item.position <= newIndex) return {
                ...item,
                position: item.position - 1
              }
              if (newIndex < oldIndex && item.position >= newIndex && item.position <= oldIndex) return {
                ...item,
                position: item.position + 1
              }
              return item
            }).sort((a, b) => a.position - b.position))
          }, [value, onChange])

          return (
            <SortableList
              value={value}
              onChange={onChange}
              useDragHandle
              onSortEnd={onSort}
              getAttribute={getAttribute}
              lockAxis="y"
              lockToContainerEdges
            />
          )
        }
      }]}
      showSubmitButton={false}
    />
  )
}

export default SortForm
