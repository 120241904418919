import styled from 'styled-components/macro'
import {Result} from "antd";


export const CustomResults = styled(Result)`
  .ant-result-icon > .anticon {
	  font-size: 5rem;
  }

  .ant-result-title {
	  font-size: 2rem;
  }
`

export const ActionsOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-left: -8px;
  padding: 0 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ActionPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 1.1rem;
  padding: 5px 2px;
  border-radius: 8px;
  z-index: 1;
  font-weight: bold;
  border: 1px solid rgba(10, 79, 122, 0.6);
  color: rgb(10, 79, 122);
  align-content: center;
  text-align: center;
  justify-content: space-around;
  max-width: 80px;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: initial;
  cursor: pointer;
`

export const ActionPanelText = styled.div`
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ActionProgress = styled.div`
  display: flex;
  position: fixed !important;
  height: fit-content !important;
  width: fit-content !important;
  top: 190px;
  right: 24px;
  z-index: 3;
`
