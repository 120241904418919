import * as React from "react";
import * as S from "./styles";
import {CustomProgress} from "../../UI/Progress/styles.js"
import {getProgressColor} from "../../UI/Progress";
import {JobStatusEnum} from "./constants";
import {__} from "../../../utils/translationUtils";


type ActionProgressStatusProps = {
	jobs: Job[]
}

export default ({jobs}: ActionProgressStatusProps) => {
	const progress = React.useMemo(() => Math.round(jobs.filter((job) => ![JobStatusEnum.toBeProcessed, JobStatusEnum.ongoing].includes(job.status)).length / jobs.length * 100), [jobs])
	const failed = React.useMemo(() => jobs.filter((job) => job.status === JobStatusEnum.failed).length, [jobs])

	return (
		<S.ActionProgress>
			<div>{__('order_actions_progress_message')} ({failed} {__('errors')})</div>

			<CustomProgress
				status="normal"
				strokeWidth={10}
				percent={progress}
				showInfo
				strokeColor={getProgressColor({completed: progress >= 100})}
			/>
		</S.ActionProgress>
	)
}
