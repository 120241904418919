import React from "react";

import {Descriptions, Popover, Tag, Typography} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";

import * as C from "../constants";
import * as U from "../utils";
import * as S from "../styles";
import { __ } from '../../../utils/translationUtils'

const { Text } = Typography;
const { ADDED, REMOVED, EDITED } = C.actionType
const { ORGANISATIONS, USER_GROUPS, ROLES } = C.actionName

const ActionTag = ({label, type }) => (
  <Tag color={type === ADDED ? 'green' : 'volcano'} style={{cursor: "default"}} >
    {type === ADDED ? '+ ' : '- '} {label}
  </Tag>
)

const ActionTagDetailed = ({ tag, actionType }) => (
  <Popover
    content={
      <S.CustomActorDescription column={1} size="small">
        <Descriptions.Item label={__("id")}>{tag.id}</Descriptions.Item>
        <Descriptions.Item label={__("short name")}>{tag.shortName}</Descriptions.Item>
        <Descriptions.Item label={__("name")}>{tag.name}</Descriptions.Item>
        <Descriptions.Item label={__("status")}>{tag.status}</Descriptions.Item>
      </S.CustomActorDescription>
    }
  >
    <span>
      <ActionTag label={tag.shortName} type={actionType}/>
    </span>
  </Popover>
)

const ActionText = ({before, after, beforeItalic, afterItalic}) => (
  <>
    <Text type="secondary" italic={beforeItalic} >{before}{"  "}<ArrowRightOutlined />{"  "}</Text>
    <Text type="primary" italic={afterItalic}>{after}</Text>
  </>
)

export const detailsToAnonymize = ["username", "firstName", "lastName", "phone", "email"]

const ActionDescription = ({action, status}) => {
  switch (action.name) {
    case ORGANISATIONS:
    case USER_GROUPS:
      return (
        <div>
          {action.oldValue.map((tag) => ( <ActionTagDetailed tag={tag} actionType={REMOVED} key={tag.shortName}/> ))}
          {action.newValue.map((tag) => ( <ActionTagDetailed tag={tag} actionType={ADDED} key={tag.shortName}/> ))}
        </div>
      )
    case ROLES:
      return (
        <div>
          {action.oldValue.map((tag) => (<ActionTag label={tag} type={REMOVED} key={tag}/> ))}
          {action.newValue.map((tag) => (<ActionTag label={tag} type={ADDED} key={tag}/> ))}
        </div>
      )
    default:
      if (status === 'deleted' && detailsToAnonymize.includes(action.name)) return "-"

      const actionType = U.getActionType(action)
      const oldValue = U.formatValue(action.oldValue, action.valueType)
      const newValue = U.formatValue(action.newValue, action.valueType)
      return (
        <div>
          { actionType === REMOVED && <ActionText before={oldValue} after={__('empty')} afterItalic={true} /> }
          { actionType === ADDED && <ActionText before={__('empty')} after={newValue} beforeItalic={true} /> }
          { actionType === EDITED && <ActionText before={oldValue} after={newValue} /> }
        </div>
      )
  }
}

export default ActionDescription
