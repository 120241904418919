import { __ } from '../../../utils/translationUtils'
/**
 * Filters all the isEnumeration items
 * Every not enum item contains the relative fieldKeyEnum (if it has one of them)
 * @param items array of elements coming from the api: ex, Measures, Dimensions, Filters, Times, ....
 *
 *
 * @returns Array of { label: String, value: String}
 */
export default (items, attributes) => (
  items
    ?.filter(({ isEnumeration }) => !isEnumeration)
    ?.map(({ attributeId, fieldKey, valueType, measureName}) => ({
      label: `${__(attributes.find(({ id }) => id === attributeId)?.propertyLabel || attributeId)} ${measureName ? `(${measureName})` : ''}`,
      value: JSON.stringify({
        fieldKey,
        valueType,
        fieldKeyEnum: items.find((e) => e.attributeId === attributeId && e.isEnumeration)?.fieldKey
      }, 2)
    }))
)
