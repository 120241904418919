import React, {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Collapse, message, Rate, Table } from 'antd'
import { Panel } from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import { api } from '../providers/ApiProvider'
import * as config from '../constants/globalConfiguration'
import {showMessage} from '../utils/appHelper'
import { hashHistory } from '../providers/HistoryProvider'
import Loading from '../components/Loading'
import { updateUserSettings } from "../providers/ApiProvider";
import {__, T} from '../utils/translationUtils'
import SettingsHeader from "../components/EntitySetting/SettingsHeader";
import {getSettingsPanels} from "../components/EntitySetting/config";
import {ERROR} from "components/dist/Utils/LoggerUtils";
import PinnedDashboardsSettings from "./EntitySettingsPage/PinnedDashboardsSettings";
import {useQueryClient} from "react-query";

const { Panel: CollapsePanel } = Collapse

const UserSettingsPage = ({ match: { params: { userId } } }) => {
  const queryClient = useQueryClient()
  const [panelOpen, setPanelOpen] = useState([])
  const settingsPanels = useMemo(() => getSettingsPanels(), [])

  const [loading, setLoading] = useState(null)
  const [userSettings, setUserSettings] = useState(null)

  const [availableDashboards, setAvailableDashboards] = useState(null)
  const [pinnedDashboards, setPinnedDashboards] = useState(null)
  const [pinnedDashboardModalOpen, setPinnedDashboardModalOpen] = useState(false)

  const getAvailableDashboards = (pinnedDashboardIds = []) => {
    api.get(`${config.prefix()?.REPORT || ''}/dashboards?pageSize=${100}&pageNumber=1`)
      .then((response) => {
        setAvailableDashboards(response.data || [])
        setPinnedDashboards((response.data || []).filter(({ id }) => pinnedDashboardIds.includes(id)))
      })
      .catch((err) => ERROR(err))
      .finally(() => setLoading(false))
  }

  const getEntityData = useCallback(() => {
    setLoading(true)
    api.get('userSettings')
      .then((response) => response.data)
      .then((userSetting) => {
        setUserSettings(userSetting)
        getAvailableDashboards(userSetting.pinnedDashboardIds)
      } )
  }, [])

  useEffect(() => {
    setLoading(true)
    getEntityData()
  }, [])

  async function handleSubmit(pinnedDashboardIds, defaultPinnedDashboardId) {
    const response = await updateUserSettings({ userId, pinnedDashboardIds, defaultPinnedDashboardId })
      .then(() => {
        showMessage('success', __('The dashboard has been pinned correctly'));
        getEntityData()
        queryClient.invalidateQueries({ queryKey: ['fetchUsersDashboards'] })
      })
      .catch((e) => { console.error(e) })

    if (response) message.success('user settings updated')
  }

  return (
    <div id="main-content">
      <h1 className="sticky">
        <div className="container">
          {__('Dashboards')}
        </div>
      </h1>
      <div className="container">
        {loading && <Loading />}
        <Panel>
          <Panel.Heading>
            User settings
          </Panel.Heading>
          <Panel.Body>
            <CollapseWrapper>
              <Collapse
                bordered={false}
                activeKey={panelOpen}
                destroyInactivePanel={false}
              >
                <CollapsePanel
                  key="1"
                  showArrow={false}
                  header={
                    <SettingsHeader
                      panelOpen={panelOpen}
                      setPanelOpen={setPanelOpen}
                      {...settingsPanels.dashboardsSettings}
                      panelKey='1'
                    />
                  }
                >
                  {!!pinnedDashboards && (
                    <>
                      <Table
                        dataSource={pinnedDashboards}
                        columns={[
                          {
                            title: 'Dashboard Name',
                            dataIndex: 'name',
                            key: 'name',
                            render: (e, r) => <Link to={`/dashboards/${r.id}/view`}>{r.name}</Link>,
                            sorter: () => 0
                          },
                          {
                            title: __('Last Update DateTime'),
                            dataIndex: 'lastUpdateDateTime',
                            key: 'lastUpdateDateTime',
                            sorter: () => 0,
                            render: (e) => (e ? moment(e).format(config.appDefaults.dateTimeFormat) : '')
                          }
                        ]}
                        rowKey="id"
                      />
                      <button
                        className={'btn btn-primary '}
                        size="large"
                        style={{float: "left", marginTop: '20px'}}
                        onClick={() => setPinnedDashboardModalOpen(true)}
                      >
                        {__(T.configPinnedDashboards, 'capitalize_sentence')}
                      </button>
                      {
                        (!!pinnedDashboardModalOpen && !!availableDashboards) && (
                          <PinnedDashboardsSettings
                            submit={({ pinnedDashboardIds, defaultPinnedDashboardId }) => {
                              handleSubmit(pinnedDashboardIds, defaultPinnedDashboardId)
                              setPinnedDashboardModalOpen(false)
                            }}
                            close={() => setPinnedDashboardModalOpen(false)}
                            availableDashboards={availableDashboards}
                            pinnedDashboardsIds={userSettings.pinnedDashboardIds || []}
                            defaultPinnedDashboardId={userSettings.defaultPinnedDashboardId}
                          />
                        )
                      }
                    </>
                  )}
                </CollapsePanel>
              </Collapse>
            </CollapseWrapper>
          </Panel.Body>
          <Panel.Footer>
            <div className="group">
              <button type="button" className="btn btn-default pull-left" onClick={() => {
                hashHistory.goBack()
              }}>
                <Glyphicon glyph="chevron-left"/> {__('Back')}
              </button>
            </div>
          </Panel.Footer>
        </Panel>
      </div>
    </div>
  )
}

export default UserSettingsPage

const CollapseWrapper = styled.div`
    .ant-collapse, .ant-collapse-header{
        background-color: initial;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding-left: 10px;
        padding-right: 10px;
        height: auto;
        flex-direction: column;
        cursor: default;

        .ant-row {
            width: 100%;
        }
    }

    .ant-collapse-item, .ant-collapse-item {
        margin-bottom: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }

    .ant-form-item-label {
        label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: none;
        }

        label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: "*";
        }
    }
`
