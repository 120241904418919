export default ({ dashboard, users }) => {
  const { shareWith, editableBy, widgetIds, lastUpdateDateTime, lastModification } = dashboard
  return {
    ...dashboard,
    shareWith: shareWith?.type,
    shareWithEntities: shareWith?.entityIds,
    editableBy: editableBy?.type,
    editableByEntities: editableBy?.entityIds,
    widgetIds: widgetIds?.map((id) => ({ id })),
    lastUpdateDateTime,
    modifiedBy: users?.find((user) => user?.id === lastModification?.modifiedBy)?.username
  }
}
