import * as C from '../constants'
import * as configurationPayloadMappingMethods from "./mapConfigurationPayload";
import _ from "lodash";
import {validateAllSteps} from "./validation";

export const mapConfigurationPayload = {
  FrontendToBackend: configurationPayloadMappingMethods.mapFrontendPayloadToBackend,
  BackendToFrontend: configurationPayloadMappingMethods.mapBackendPayloadToFrontend,
}

export const getObjectFlatAttributesId = (objectTree) => {
  return [
    ...(objectTree?.data?.map((attribute) => (attribute.id)) || []),
    ...(objectTree?.children?.reduce((prev, object) => (
      [...prev, ...getObjectFlatAttributesId(object)]
    ), []) || [])
  ]
}

export const getAdditionalPayload = (payload, attributesMap, attributesTree) => {
  if (!payload) return {}
  const mappedConfigurations = mapConfigurationPayload.BackendToFrontend(payload, attributesMap)
  const configurations = {
    ...C.configuratorInitialState,
    ...mappedConfigurations
  }
  const initialValidation = validateAllSteps(configurations)
  return {
    configurations,
    system: {
      id: payload.systemId.toString(),
      label: attributesTree.find((s) => s.name === payload.systemId.toString()).label
    },
    object: {
      id: payload.objectTypeId,
      label: attributesTree.find((s) => s.name === payload.systemId.toString())?.data?.find((o) => o.objectTypeId === payload.objectTypeId)?.label
    },
    backendPayload: payload,
    creatorId: payload.creatorId,
    validationState: initialValidation,
    defaultConfiguration: {
      initialValue: payload.defaultConfiguration,
      exists: payload.defaultConfigurationExists,
      editable: payload.defaultConfigurationIsEditable,
    }
  }
}

export const getAllowedGridItems = (gridItems, allowedItems) => (
  Object.keys(gridItems).reduce((prev, gridItem) => {
    if (allowedItems.includes(gridItem)) {
      prev[gridItem] = gridItems[gridItem]
    }
    return prev
  }, {})
)

export const getGridOccupiedCells = (gridItems) => (
  gridItems.reduce((prev, item) => {
    const cell = item.cellProperties
    for(let x = (cell.w - 1); x >= 0; x--){
      if (!prev[x + cell.x]) prev[x + cell.x] = {}
      for(let y = (cell.h - 1); y >= 0; y--){
        prev[x + cell.x][y + cell.y] = true
      }
    }
    return prev
  }, {})
)

const checkCoords = (occupiedCells, cols, x, y, w, h) => {
  for (let yy = 0; yy < h; yy++) {
    for (let xx = 0; xx < w; xx++) {
      if (occupiedCells[x + xx]?.[y + yy] || x+xx === cols) return false
    }
  }
  return true
}

const getGridFirstFreeCoords = (occupiedCells, configuration) => {
  const {
    cellDimension = C.defaultCellDimension,
    cols = 1,
    x = 0,
    y = 0,
  } = configuration || {}
  let currentX = x
  let currentY = y
  while (true) {
    if (checkCoords(occupiedCells, cols, currentX, currentY, cellDimension.w, cellDimension.h)) {
      return {
        x: currentX,
        y: currentY,
      }
    }
    if ((currentX + 1) === cols) {
      currentX = 0
      currentY += 1
    }
    else {
      currentX += 1
    }
  }
}

export const getGridItemsFromFilledGrid = (items=[], occupiedCells={}, cellDimension = C.defaultCellDimension, cols) => {
  const currentOccupiedCells = _.cloneDeep(occupiedCells)
  let coords = { x: 0, y: 0 }
  return items.map((item) => {
    coords = getGridFirstFreeCoords(currentOccupiedCells, {cellDimension, cols, ...coords})
    for (let yy = 0; yy < cellDimension.h; yy++) {
      for (let xx = 0; xx < cellDimension.w; xx++) {
        if (!currentOccupiedCells[coords.x+xx]) currentOccupiedCells[coords.x+xx] = {}
        currentOccupiedCells[coords.x+xx][coords.y+yy] = true
      }
    }
    return {
      ...item,
      cellProperties: {
        i: item.id.toString(),
        isDraggable: true,
        ...C.defaultCellDimension,
        ...cellDimension,
        ...coords
      }
    }
  })
}
