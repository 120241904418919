import React from 'react'
import PropTypes from 'prop-types';
import {MessageType, MessageUserInfo, MessageText} from "./MessageComponents";
import MessageAttachment from "./MessageAttachment";
import MessageOptions from "./MessageOptions";
import { showMessage} from "../../../utils/appHelper";
import MessageHistoryModal from "./MessageHistoryModal";
import { api } from '../../../providers/ApiProvider'
import { __ } from '../../../utils/translationUtils'

class MessageSingle extends React.Component {

  static propTypes = {
    message: PropTypes.object,
    refreshMessage: PropTypes.func,
    guiUser: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      editModeOn: false,
      openOptions: false,
      openHistoryModal: false,
      visibility: props.message.visibility || 'GLOBAL',
      entityId: props.message.entity && props.message.entity.id || '',
      text: props.message.text || '',
      attachmentPath: props.message.attachmentPath || '',
    }

    this.deleteFileWhenClose = false;

    this.handleChangeEntitySelected = this.handleChangeEntitySelected.bind(this);
    this.handleChangeTypeSelected = this.handleChangeTypeSelected.bind(this);
    this.handleChangeMessageText = this.handleChangeMessageText.bind(this);
    this.handleChangeAttachmentPath = this.handleChangeAttachmentPath.bind(this);

    this.closeEditModeOn = this.closeEditModeOn.bind(this);
    this.openEditModeOn = this.openEditModeOn.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);

    this.closeHistoryModal = this.closeHistoryModal.bind(this);
    this.getCommentHistory = this.getCommentHistory.bind(this);
  }

  _deleteFileWhenClose;


  get deleteFileWhenClose() {
    return this._deleteFileWhenClose;
  }

  set deleteFileWhenClose(value) {
    this._deleteFileWhenClose = value;
  }

  checkingValues(values){
    if ((!values.text || values.text === '') && (!values.attachmentPath || values.attachmentPath === '')) {
      showMessage('warning', 'Please provide a comment text or a file to upload.');
      return false;
    }

    if (values.visibility === 'ENTITY' && (!values.entityId)){
      showMessage('warning', 'Please provide a comment entity.');
      return false;
    }
    return true;
  }

  updateMessage(){
    const {message} = this.props;
    let updateMessageValues = (({visibility, entityId, text, attachmentPath}) =>
      ({visibility, entityId, text, attachmentPath}))(this.state);

    Object.assign(updateMessageValues, {id: message.id, edited: true});

    if (!this.checkingValues(updateMessageValues)){return new Promise(() => {})}

    // Replace empty string with null
    Object.keys(updateMessageValues).map( key => {
      updateMessageValues[key] = updateMessageValues[key] === '' ? null : updateMessageValues[key];
    })

    return api.put(`/comments/${message.id}`, updateMessageValues)
      .then(() => {

        // Close Edit Mode
        this._closeEditModeOn();

        // Refresh all Messages
        this.props.refreshMessage();

        // show update Message
        showMessage('success',__('commentUpdateSuccessMessage'));
      })
      .catch(() => {

        // Close Edit Mode and reset state
        this.closeEditModeOn();

        // Refresh all Messages
        this.props.refreshMessage();

        showMessage('error', __('commentUpdateFailureMessage'))
      })
  }

  deleteMessage(){
    const {message} = this.props;
    const res = window.confirm(__('commentDeleteConfirmationMessage'));
    if (!res){
      return new Promise(() => {})
    }

    return api.delete(`/comments/${message.id}`)
      .then( () => {

        // Close Edit Mode
        this.closeEditModeOn();

        // Refresh all Messages
        this.props.refreshMessage();

        showMessage('success', 'Comment deleted successfully')
      })
  }

  closeEditModeOn(){
    const {message} = this.props;

    // If the message is closed without saving, everything must return the same
    const oldState = {};
    Object.assign(oldState, {
      visibility: message.visibility || 'GLOBAL',
      entityId: message.entity && message.entity.id || '',
      text: message.text || '',
      attachmentPath: message.attachmentPath || '',
      editModeOn: false
    })
    if (this.deleteFileWhenClose && this.state.attachmentPath) {
      // If a file is added but then close edit is triggered the file should be deleted
      api.delete(this.state.attachmentPath);
    }
    this.setState(oldState, () => {this.deleteFileWhenClose = false});
  }

  openEditModeOn(){
    this.setState({editModeOn: true})
  }

  _closeEditModeOn(){
    this.setState({editModeOn: false})
  }

  handleChangeTypeSelected(value){
    this.setState({visibility: value});
  }

  handleChangeEntitySelected(value){
    this.setState({entityId: value});
  }

  handleChangeMessageText(value){
    this.setState({text: value});
  }

  getCommentHistory(){
    this.setState({openHistoryModal: true})
  }

  closeHistoryModal(){
    this.setState({openHistoryModal: false})
  }

  hoverOn() {
    this.setState({openOptions: true});
  }

  hoverOff() {
    if (!this.state.editModeOn) {
      this.setState({openOptions: false});
    }
  }

  handleChangeAttachmentPath(value){
    const {message} = this.props
    if (value){

      // If the edit mode is closed the file will be deleted
      this.deleteFileWhenClose = true;
      this.setState({attachmentPath: value});
    }else if (!value && this.state.attachmentPath && message.attachmentPath) {
      this.deleteFileWhenClose = false;
      this.setState({attachmentPath: ''});

      // This is the case i add an attachment and delete it immediately
    } else if (!value && this.state.attachmentPath && !message.attachmentPath) {
      api.delete(this.state.attachmentPath)
        .then(() => {
          this.setState({attachmentPath: ''})
        })
        .catch(() => showMessage('error', 'Could not delete file'))
    }else {
      this.deleteFileWhenClose = false;
    }
  }

  renderOptions(){
    return(
      <div onMouseEnter={() => this.hoverOn()} onMouseLeave={() => this.hoverOff()}>
        <MessageOptions {...this.props} editModeOn={this.state.editModeOn}
                        updateMessage={this.updateMessage} closeEditModeOn={this.closeEditModeOn}
                        openEditModeOn={this.openEditModeOn} deleteMessage={this.deleteMessage}
                        getCommentHistory={this.getCommentHistory}
        />
      </div>
    )
  }

  render() {
    const {message, guiUser} = this.props;
    const creator = guiUser.id === message.creator.id;
    return(
      <li className={`chat ${creator ? "right" : "left"}`}>
      <div className={'message'} onMouseEnter={() => this.hoverOn()}
          onMouseLeave={() => this.hoverOff()} onMouseOver={() => this.hoverOn()}>
        <MessageUserInfo user={message.creator} dateToShow={message.creationDatetime} editModeOn={this.state.editModeOn}/>
        <MessageType editModeOn={this.state.editModeOn} entitySelected={this.state.entityId}
                     typeSelected={this.state.visibility}
                     onChangeTypeSelected={this.handleChangeTypeSelected}
                     onChangeEntitySelected={this.handleChangeEntitySelected}
                     {...this.props}
        />
        <MessageText editModeOn={this.state.editModeOn} messageText={this.state.text}
                     onChangeMessageText={this.handleChangeMessageText} edited={message.edited}
                     {...this.props}
        />
        <MessageAttachment editModeOn={this.state.editModeOn} attachmentPath={this.state.attachmentPath}
                           onChangeAttachmentPath={this.handleChangeAttachmentPath} {...this.props}
        />
        <MessageHistoryModal message={message} openHistoryMessageModal={this.state.openHistoryModal}
                             closeHistoryMessageModal={this.closeHistoryModal} {...this.props}
        />
        {this.state.openOptions && this.renderOptions()}
      </div>
      </li>
    )

  }


}

export default MessageSingle;
