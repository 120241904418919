const constants = {
  WHITE_LABEL: 'WHITE_LABEL',
  calendar: {
    CALENDAR_VIEW: 'CALENDAR_VIEW',
    TEXTUAL_VIEW: 'TEXTUAL_VIEW',
    PUBLIC_CALENDAR: 'PUBLIC_CALENDAR',
    PRIVATE_CALENDAR: 'PRIVATE_CALENDAR',
    NONWORKING_PERIODS: 'Non-working periods',
    WORKING_DAYS: 'Working days',
    DRAWER_WIDTH: 500,
    FORM_GUTTER: 16,
    FORM_SPAN: 20,
    FORM_OFFSET: 2
  },
  calendarView: {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    AGENDA: 'agenda'
  },
  calendarMode: {
    CALENDAR: 'calendar',
    TIMELINE: 'timeline',
    AGGREGATED: 'aggregated'
  },
  calendarTimeline: {
    LINE_HEIGHT: 40,
    LINE_HEIGHT_RATIO: 1
  },
  eventTypes: {
    GODOO_EVENT: 'GODOO_EVENT',
    NON_WORKING_EVENT: 'NON_WORKING_EVENT',
    WORKING_EVENT: 'WORKING_EVENT'
  },
  entityTypes: {
    COMPANY: 'COMPANY',
    ENTITY: 'ENTITY',
    USER: 'USER'
  },
  entryFeatures: {
    ORDER_TYPE: 'ORDER_TYPE',
    DEADLINE: 'DEADLINE',
    PRIORITY: 'PRIORITY'
  },
  listingLayouts: {
    TABLE: 'TABLE',
    CARD: 'CARD',
    HEATMAP: 'HEATMAP'
  },
  processActiveStatuses: ['assigned', 'postponed', 'started', 'rejected'],
  eventTypeEmoji: ['', '⚽️', '📆', '✈️', '🤒', '🩺', '📝', '🪖', '👶', '📝'],
  eventTypeLabels: ['', 'Event', 'Public holiday', 'Private holiday', 'Illness', 'Medical consultation', 'Other', 'Military', 'Parental Leave', 'Other'],
  eventTypeCodes: {
    entity: [{
      code: 1,
      type: 'event',
      reservedFor: 'entity'
    },
      {
        code: 2,
        type: 'public holiday',
        reservedFor: 'entity'
      },
      {
        code: 9,
        type: 'other',
        reservedFor: 'entity'
      }],
    user: [
      {
        code: 3,
        type: 'private holiday',
        reservedFor: 'user'
      },
      {
        code: 4,
        type: 'illness',
        reservedFor: 'user'
      },
      {
        code: 5,
        type: 'medical consultation',
        reservedFor: 'user'
      },
      {
        code: 6,
        type: 'other',
        reservedFor: 'user'
      },
      {
        code: 7,
        type: 'military',
        reservedFor: 'user'
      },
      {
        code: 8,
        type: 'parental leave',
        reservedFor: 'user'
      }
    ]
  },
  orderingTypes: {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC',
    CUSTOM: 'CUSTOM'
  },
  priorityValues: {
    LOWEST: {name: 'LOWEST', icon: 'double-right', type: 'double-right', iconRotation: 90, rotate: 90, ordinal: 0},
    LOW: {name: 'LOW', icon: 'down', type: 'down', iconRotation: 0, rotate: 0, ordinal: 1},
    NORMAL: {name: 'NORMAL', icon: 'pause', type: 'pause', iconRotation: -90, rotate: -90, ordinal: 2},
    HIGH: {name: 'HIGH', icon: 'up', type: 'up', iconRotation: 0, rotate: 0, ordinal: 3},
    HIGHEST: {name: 'HIGHEST', icon: 'double-right', type: 'double-right', iconRotation: -90, rotate: -90, ordinal: 0}
  },
  rightMappings: {
    CAN_LIST_ORDERS: {uri: '/orders', method: 'GET'},
    CAN_LIST_REPORTS: {uri: '/configurations/reports', method: 'GET'},
    CAN_CREATE_REPORT: {uri: '/configurations/reports', method: 'POST'},
    CAN_MANAGE_ENTITY_SETTINGS: {uri: '/entitySettings/{id1}', method: 'PUT'},
    CAN_CREATE_VIEW: {uri: '/configurations/views', method: 'POST'},
    CAN_LIST_VIEWS: {uri: '/configurations/views', method: 'GET'},
    CAN_LIST_REMOTEOBJECTS: {uri: '/configurations/remoteObjects', method: 'GET'},
    HAS_ROE_READ_ACCESS: {uri: '/configurations/remoteObjects/{id1}', method: 'GET'},
    HAS_ROE_READ_ACCESS_DETAILS: {uri: '/configurations/remoteObjects/details/{id1}/{id2}/{id3}', method: 'GET'},
    HAS_ROE_EDIT_ACCESS: {uri: '/configurations/remoteObjects/{id1}', method: 'PUT'},
    HAS_ROE_EDIT_ACCESS_DETAILS: {uri: '/configurations/remoteObjects/details/{id1}', method: 'PUT'},
    CAN_USAGE_REPORT: {uri: '/configurations/remoteObjects/autoGenerated', method: 'POST'},
    CAN_MANAGE_OVERVIEW_OBJECTS: {uri: '/configurations/overviewObjects/{id1}', method: 'PUT'},
    CAN_MANAGE_STEP_OBJECTS: {uri: '/configurations/stepObjects/{id1}', method: 'PUT'},
    CAN_CREATE_STEP_OBJECT: {uri: '/configurations/stepObjects', method: 'POST'},
    CAN_MANAGE_FLOWS: {uri: '/publishedFlows/{id1}/{id2}/processOverviewObjects/{id3}', method: 'PUT'},
    CAN_REFRESH_FLOWS: {uri: '/system/discoverNewFlows', method: 'POST'},
    CAN_CREATE_REMOTE_OBJECT: {uri: '/configurations/remoteObjects', method: 'POST'},
    CAN_CREATE_REMOTEOBJECT_DETAILS: {uri: '/configurations/remoteObjects/details', method: 'POST'},
    CAN_CREATE_REMOTEOBJECT_ITEM: {uri: '/configurations/remoteObjects/create', method: 'POST'},
    CAN_CREATE_REMOTE_OBJECT_ITEM_DETAILS: {uri: '/configurations/remoteObjects/details/create', method: 'POST'},
    CAN_UPDATE_REMOTE_OBJECT_ITEM: {uri: '/configurations/remoteObjects/update', method: 'PUT'},
    CAN_UPDATE_REMOTE_OBJECT_ITEM_DETAILS: {uri: '/configurations/remoteObjects/details/update', method: 'PUT'},
    CAN_DELETE_REMOTE_OBJECT_ITEM: {uri: '/configurations/remoteObjects/{id1}/{id2}/{id3}', method: 'DELETE'},
    CAN_DELETE_REMOTE_OBJECT_ITEM_DETAILS: {
      uri: '/configurations/remoteObjects/details/{id1}/{id2}/{id3}',
      method: 'DELETE'
    },
    CAN_ADD_RO_RELATION: {uri: '/remoteObjects/relations/add', method: 'POST'},
    CAN_REMOVE_RO_RELATION: {uri: '/remoteObjects/relations/remove', method: 'POST'},
    CAN_LIST_FLOWS: {uri: '/configurations/overviewObjects', method: 'GET'}, // TODO Update when ready from api side method: 'GET',  },
    CAN_LIST_OVERVIEW_OBJECTS: {uri: '/configurations/overviewObjects', method: 'GET'},
    CAN_CREATE_OVERVIEW_OBJECT: {uri: '/configurations/overviewObjects', method: 'POST'},
    CAN_FORCE_AUTOMATIC_CREATION: {uri: '/configurations/remoteObjects/autoGenerated', method: 'POST'},
    CAN_EXPORT_REPORT: {uri: '/configurations/views/{id1}/export', method: 'GET'},
    CAN_IMPORT_REPORT: {uri: '/configurations/views', method: 'POST'},
    CAN_LIST_ORDERGROUPS: {uri: '/orderGroups', method: 'GET'},
    CAN_EDIT_ORDERGROUP: {uri: '/orderGroups/{id1}', method: 'PUT'},
    CAN_CREATE_ORDERGROUP: {uri: '/orderGroups', method: 'POST'},
    CAN_ASSOCIATE_VIEWS_TO_ORDERGROUP: {uri: '/orderGroups/{id1}/views', method: 'PATCH'},
    CAN_ASSOCIATE_REPORTS_TO_ORDERGROUP: {uri: '/orderGroups/{id1}/reports', method: 'PATCH'},
    CAN_MARK_AS_DEFAULT_PINNED: {uri: '/orderGroups/{id1}', method: 'PUT'},
    CAN_ASSOCIATE_ORDERTYPES_TO_ORDERGROUP: {uri: '/orderGroups/{id1}/orderTypes', method: 'PUT'},
    CAN_SET_PINNED_VIEWS_TO_ORDERGROUP: {uri: '/orderGroups/{id1}/views/pinned', method: 'PUT'},
    CAN_SET_PINNED_REPORTS_TO_ORDERGROUP: {uri: '/orderGroups/{id1}/reports/pinned', method: 'PUT'},
    CAN_LIST_ORDERTYPES: {uri: '/orderTypes', method: 'GET'},
    CAN_CREATE_ORDERTYPES: {uri: '/orderTypes', method: 'POST'},
    CAN_CREATE_ORDERS: {uri: '/orders', method: 'POST'},
    CAN_CREATE_USER: {uri: '/users', method: 'POST'},
    CAN_UPDATE_USER: {uri: '/users/{id1}', method: 'PUT'},
    CAN_DELETE_USER: {method: 'POST', requestParam: 2, uri: '/hierarchy'},
    CAN_CREATE_ENTITY: {uri: '/entities', method: 'POST'},
    CAN_UPDATE_ENTITY: {uri: '/entities/{id1}', method: 'PUT'},
    CAN_DELETE_ENTITY: {method: 'POST', requestParam: 6, uri: '/hierarchy'},
    CAN_SEE_ALL_ORDERS: {uri: '/visibleOrders', method: 'GET'},
    CAN_USE_SELF_TYPE: {method: 'POST', requestParam: 25, uri: '/retrievableConfigurations'},
    CAN_USE_ENTITY_TYPE: {method: 'POST', requestParam: 26, uri: '/retrievableConfigurations'},
    CAN_USE_GLOBAL_TYPE: {method: 'POST', requestParam: 27, uri: '/retrievableConfigurations'},
    CAN_ASSOCIATE_SELFT_TYPES_TO_ORDERGROUP: {method: 'PUT', requestParam: 25, uri: '/retrievableConfigurations'},
    CAN_ASSOCIATE_ENTITY_TYPES_TO_ORDERGROUP: {method: 'PUT', requestParam: 26, uri: '/retrievableConfigurations'},
    CAN_ASSOCIATE_GLOBAL_TYPES_TO_ORDERGROUP: {method: 'PUT', requestParam: 27, uri: '/retrievableConfigurations'},
    CAN_PATCH_REMOTE_OBJECT: {method: 'PATCH', uri: '/configurations/remoteObjects/{id1}/{id2}'},
    CAN_PATCH_REMOTE_OBJECT_DETAILS: {method: 'PATCH', uri: '/configurations/remoteObjects/details/{id1}/{id2}'},
    CAN_EXECUTE_ORDER_ACTION: {method: 'POST', uri: '/orders/{id1}/{id2}'},
    CAN_BATCH_ASSIGN_PROCESSES: {method: 'POST', uri: '/processes/ownerships/assign'},
    CAN_RESET_PROCESS: {method: 'DELETE', uri: '/processes/{id1}'},
    CAN_CANCEL_ORDER: {method: 'POST', uri: '/orders/{id1}/cancel'},
    CAN_ARCHIVE_ORDER: {method: 'POST', uri: '/orders/{id1}/archive'},
    CAN_DELETE_ORDER: {method: 'POST', uri: '/orders/{id1}/delete'},
    CAN_HARD_DELETE_ORDER: {method: 'POST', uri: '/orders/{id1}/hardDelete'},
    CAN_ADD_ORDER_COMMENTS: {method: 'POST', uri: '/orders/{id1}/comments'},
    CAN_ADD_STEP_COMMENTS: {method: 'POST', uri: '/steps/{id1}/comments'},
    CAN_EDIT_COMMENT: {method: 'PUT', uri: '/comments/{id1}'},
    CAN_DELETE_COMMENT: {method: 'DELETE', uri: '/comments/{id1}'},
  },

  VALUE_TYPES: {
    STRING: {value: 'STRING', type: 'STRING'},
    DATE: {value: 'DATE', type: 'DATE'},
    INTEGER: {value: 'INTEGER', type: 'NUMERIC'},
    FLOAT: {value: 'FLOAT', type: 'NUMERIC'},
    DOUBLE: {value: 'DOUBLE', type: 'NUMERIC'},
    LONG: {value: 'LONG', type: 'NUMERIC'},
    POINT: {value: 'POINT', type: 'OBJECT'},
    BOOLEAN: {value: 'BOOLEAN', type: 'BOOLEAN'},
    YESNO: {value: 'YESNO', type: 'BOOLEAN'},
    BINARY: {value: 'BINARY', type: 'STRING'},
    BILLING: {value: 'BILLING', type: 'STRING'},
    OBJECT_LIST_IDS: {value: 'OBJECT_LIST_IDS', type: 'STRING'},
    IDS: {value: 'IDS', type: 'STRING'},
    ENUM_VALUE: {value: 'ENUM_VALUE', type: 'OBJECT'},
    STEP: {value: 'STEP', type: 'OBJECT'},
    USER: {value: 'USER', type: 'OBJECT'},
    ENTITY: {value: 'ENTITY', type: 'OBJECT'},
    ORDER_TYPE: {value: 'ORDER_TYPE', type: 'OBJECT'},
    FLOW: {value: 'FLOW', type: 'OBJECT'},
    DEADLINE: {value: 'DEADLINE', type: 'OBJECT'},
  },

  ATTRIBUTES: {
    ORDER: {
      ID: "100_58be96d00e823f552aa1a000",
      TITLE: "100_58be96d00e823f552aa1a001",
      EXTERNAL_ORDER_ID: "100_58be96d00e823f552aa1a002",
      CREATION_DATE_TIME: "100_58be96d00e823f552aa1a003",
      LAST_UPDATE_DATETIME: "100_58be96d00e823f552aa1a004",
      ORDER_STATUS: "100_58be96d00e823f552aa1a005",
      INTERNAL_ORDER_STATUS: "100_58be96d00e823f552aa1a006",
      VERSION: "100_58be96d00e823f552aa1a012",
      PRIORITY: "100_58be96d00e823f552aa1a013",
      DEADLINE: "100_58be96d00e823f552aa1a014",
      DEADLINE_DATE: "100_58be96d00e823f552aa1a015",
      DEADLINE_PERCENTAGE: "100_58be96d00e823f552aa1a016",
      WEIGHT: "100_58be96d00e823f552aa1a072",

      PROCESS: {
        ID: "100_58be96d00e823f552aa1a035",
        TITLE: "100_58be96d00e823f552aa1a036",
        NEXT_ACTION_DATE_TIME: "100_58be96d00e823f552aa1a037",
        STARTING_DATE_TIME: "100_58be96d00e823f552aa1a038",
        LAST_UPDATE_DATE_TIME: "100_58be96d00e823f552aa1a039",
        ENDING_DATE_TIME: "100_58be96d00e823f552aa1a040",
        LOCATION: "100_58be96d00e823f552aa1a042",
        INTERNAL_PROCESS_STATUS: "100_58be96d00e823f552aa1a043",
        PROCESS_STATUS: "100_58be96d00e823f552aa1a044",
        MAIN_ORDER: "100_58be96d00e823f552aa1a045",
        POSITION: "100_58be96d00e823f552aa1a046",
        DEADLINE: "100_58be96d00e823f552aa1a047",
        NOT_BEFORE_DATE: "100_58be96d00e823f552aa1a048"
      },

      ASSIGNEE: {
        USER: {
          USERNAME: "100_58be96d00e823f552aa1a070"
        },
        ENTITY: {
          NAME: "100_58be96d00e823f552aa1a071",
        }
      },

      CURRENT_STEP: {
        TITLE: "100_58be96d00e823f552aa1a041",
      },
    },

    FLOW: {
      VERSION: "100_58be96d00e823f552aa1a007",
      TITLE: "100_58be96d00e823f552aa1a008",
    },

    ORDER_TYPE: {
      NAME: "100_58be96d00e823f552aa1a009",
      ID: "100_58be96d00e823f552aa1a011",
    },
    CREATOR: {
      USERNAME: "100_58be96d00e823f552aa1a010",
    }
  },

  ENUMERATIONS: {
    TRUE_FALSE: "100_58be96d00e823f552aa1a307"
  },

  mechanismType: {
    RANGE: 'RANGE',
    SPECIFIC_VALUE: 'SPECIFIC_VALUE',
    ENUMERATION: 'ENUMERATION'
  }
}

module.exports = global.constants = constants
