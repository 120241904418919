import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {getFormValues} from 'redux-form';
import Glyphicon from '@strongdm/glyphicon'
import {Modal} from 'react-bootstrap';

import StepObjectEditForm from '../components/StepObjectEditForm';
import {createStepObjectItem} from '../providers/ReduxProvider/actions/stepObjectActions';
//import AddStepObjectButton from "./AddStepObjectButton";
// import _ from 'lodash';
import { api } from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'


class CreateStepObjectButton extends React.Component {
  static propTypes = {
    guiUser: PropTypes.object,
    enumValues: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    initialValues: PropTypes.object,
    configuration: PropTypes.object,
    remoteObjectId: PropTypes.string,
    objectId: PropTypes.string,
    action: PropTypes.string,
    remoteObject: PropTypes.object,
    remoteObjectSpec: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    attributesConfiguration: PropTypes.array,
    className: PropTypes.string,
    createStepObjectItem: PropTypes.func,
    onCreate: PropTypes.func,
    attributes: PropTypes.array,
    values: PropTypes.array,
  };

  static defultProps = {
    action: 'edit',
    objectId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      attributesConfigurationTree: null,
      objectConfiguration: null,
      system: null,
      dependencyId: null,
      modal: {
        open: false,
      },
      isLoading: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.getDataAndOpenModal = this.getDataAndOpenModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAttributesConfigurationTree = this.getAttributesConfigurationTree.bind(this);
  }

  closeModal(e) {
    if(e?.stopPropagation) e.stopPropagation()
    this.setState({
      modal: {
        open: false,
      }
    });
  }

  getAttributesConfigurationTree() {
    return api.get('configurations/attributes/tree')
      .then(
        response => {
          this.setState({
            attributesConfigurationTree: response.data,
          });
        }
      );
  }

  getDataAndOpenModal() {
    // Fetch attributes tree
    this.getAttributesConfigurationTree();

    const {
      action,
      remoteObjectSpec,
    } = this.props;

    if (action === 'create') {
      this.setState({
        objectConfiguration: remoteObjectSpec,
        dependencyId: remoteObjectSpec.objectTypeId,
        system: remoteObjectSpec.systemId,
        modal: {
          open: true,
        },
      });
    }
  }

  handleSubmit() {
    const { configuration } = this.props

    const {createStepObjectItem: defaultCreateRemoteItem, formValues, onCreate, action} = this.props;
    this.setState({isLoading: true});
    const createRemoteItem = this.props.apis?.createRemoteItem || defaultCreateRemoteItem
    if (action === 'create') {
      createRemoteItem({
        id: 1,
        displayData: formValues,
        accessRightsInfo: {
          read: true,
          edit: true
        },
      }, { objectTypeId: configuration.objectTypeId }).then((id) => {
        this.setState({
          modal: {
            open: false,
          },
          isLoading: false,
        }, () => {
          onCreate(id, formValues);
        });
      }).catch(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  }

  render() {
    const {
      action,
      attributesConfiguration,
      enumValues,
      attributes,
      values,
    } = this.props;

    const {
      objectConfiguration,
      attributesConfigurationTree,
    } = this.state;
    return (
      <div>
        <button
          onClick={() => this.getDataAndOpenModal()}
          className={this.props.className}>
          {action === 'create' ? __('Create') : __('Edit')}
        </button>

        {this.state.modal.open &&
        <Modal
          backdrop="static"
          show={this.state.modal.open}
          onHide={this.closeModal}
          bsSize="large"
          className="popup-l"
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">{__('Create Step Object')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(objectConfiguration && attributesConfigurationTree)
              ? <StepObjectEditForm
                filterByDisplayAttributes
                enumValues={enumValues}
                configuration={this.props.configuration}
                attributesConfiguration={attributesConfiguration}
                objectConfiguration={objectConfiguration}
                attributesConfigurationTree={attributesConfigurationTree}
                initialValues={this.props.initialValues}
                attributes={attributes}
                values={values}
              />
              : __('loading')
            }
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <button className="btn btn-default pull-left" onClick={this.closeModal}>
                <Glyphicon glyph="chevron-left"/> {__('Back')}
              </button>

              <button
                className="btn pull-right btn-primary"
                disabled={this.state.isLoading}
                size="large"
                onClick={this.handleSubmit}
              >
                {action === 'create' ? __('Create') : __('Save Changes')}
              </button>

            </div>
          </Modal.Footer>
        </Modal>
        }
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      formValues: getFormValues('stepObjectEditForm')(state),
      guiUser: state.user.data,
      enumValues: state.enumValues,
    };
  },
  {createStepObjectItem}
)(CreateStepObjectButton);
