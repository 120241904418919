import React from 'react';
import PropTypes from 'prop-types';
import {SortableHandle} from 'react-sortable-hoc';
import Glyphicon from '@strongdm/glyphicon'
import {change, Field, getFormValues} from 'redux-form';
import PropertyLabelTitle from './PropertyLabelTitle';
import ConfigurationConfigurator from './../components/ConfigurationConfigurator'
import {renderField} from '../utils/fieldValidator';
import {OBJECT_PICKER} from "./ConfigurationConfigurator/context/configurationType";
import {Button, Space} from 'antd'
import {connect} from "react-redux";
import { __ } from '../utils/translationUtils'

const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

class DisplayFieldCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configurationConfiguratorVisibility: false
    }
    this.getControlOptions = this.getControlOptions.bind(this);
  }

  getControlOptions(type) {
    const {valueTypesMappings} = this.props;
    return valueTypesMappings && valueTypesMappings[type].availableControllerTypes.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0)).map((control, i) => (
      <option key={`${control}-${i}`} value={control}>{control}</option>
    ));
  }

  updateReduxForm(values) {
    if (this.props.reduxFormName) {

      this.props.change(this.props.reduxFormName, `${this.props.attribute?.attributeId}_controllerOptions`, values)

    }
  }

  renderFieldCardForm(config, attribute, valueTypesMappings) {
    return(
      <div className="row" style={{ margin: '0 -5px' }}>
        <div className="col-xs-12 col-sm-12 col-md-3">
          <label>
            <Field
              name={`${attribute.attributeId}_summary`}
              component="input"
              label={__('Required')}
              type="checkbox"
              labelCol="col-md-9"
              inputCol="col-md-12"
            /> { __('Summary') }
          </label>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-9">
          <Field
            required
            name={`${attribute.attributeId}_controllerType`}
            component={renderField}
            label={__('Control Type')}
            className="form-control"
            type="select"
            items={[
              <option key="0--" value="">
                {`${__('Use Default')} [${valueTypesMappings && valueTypesMappings[config.type].defaultControllerType}]`}
              </option>,
            ].concat(this.getControlOptions(config.type))}
          />
        </div>
        <div>
          <Field
            type="hidden"
            name={`${attribute.attributeId}_controllerOptions`}
            component={renderField}
            className="form-control"
          >
          </Field>
        </div>
        { ['ObjectPicker', 'ObjectBinder'].includes(attribute.controllerType || attribute.defaultControllerType) &&
        <>
          <Button size="small" onClick={() => this.setState({configurationConfiguratorVisibility: true})}>
            Configure
          </Button>
          <ConfigurationConfigurator
            {...{
              onSubmit: (e) => {
                this.updateReduxForm(e, attribute.attributeId)
                this.setState({configurationConfiguratorVisibility: false})
              },
              modalProps: {
                visible: this.state.configurationConfiguratorVisibility,
                onCancel: () => this.setState({configurationConfiguratorVisibility: false}),
              },
              configurationToEdit: attribute.controllerOptions?.objectConfiguration || {},
              configurationType: OBJECT_PICKER,
            }}
          />
        </>
        }
        <div className="col-xs-12 col-sm-12 col-md-3">
          <label>
            <Field
              name={`${attribute.attributeId}_editable`}
              component="input"
              label={__('Required')}
              type="checkbox"
              labelCol="col-md-9"
              inputCol="col-md-12"
            /> {__('Editable')}
          </label>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-9"><PropertyLabelTitle attribute={attribute} type={'display'}/></div>
      </div>
    )
  }

  render() {
    const {
      attribute,
      extractAttributeConfig,
      remove,
      remoteSystems,
      valueTypesMappings,
      showSystem,
      showPosition,
    } = this.props;

    const config = extractAttributeConfig(attribute.attributeId);

    if (showSystem && !remoteSystems) {
      return null;
    }

    if (!config) {
      return (
        <div className="gutter-top gutter-bottom">
          {`Configuration not found for attribute ${attribute.attributeId}`}
        </div>
      );
    }

    return (
      <div
        className="configuration-list-item"
        key={attribute.attributeId}
      >
        <div className="header">
          <Space>
            <DragHandle />
            <strong>{__(config.propertyLabel)}</strong>
            {
              showPosition && <span style={{fontSize: '11px'}}>{__('Position')}: {attribute.position + 1}</span>
            }
          </Space>
          <a
            className="pull-right"
            onClick={() => remove(attribute)}
          >
            <Glyphicon glyph="remove" />
          </a>
          {
            showSystem && <aside>{__('System')}: <strong>{remoteSystems.find((e) => e.id === config.system)?.name}</strong> | {__('Object')}: <strong>{__(config.dependenciesLabel)}</strong></aside>
          }
          {
            this.renderFieldCardForm(config, attribute, valueTypesMappings)
          }
        </div>
      </div>
    );
  }
}

DisplayFieldCard.propTypes = {
  attribute: PropTypes.object,
  remove: PropTypes.func,
  extractAttributeConfig: PropTypes.func,
  remoteSystems: PropTypes.arrayOf(Object),
  valueTypesMappings: PropTypes.object,
  reduxFormName: PropTypes.string,
  showSystem: PropTypes.bool,
  showPosition: PropTypes.bool,
};
DisplayFieldCard.defaultProps = {
  remoteSystems: [],
  showSystem: true,
  showPosition: false,
};

const DisplayFieldCardConnected = connect(
  (state, props) => ({
    formValues: getFormValues(props.reduxFormName)(state)
  }),
  {change}
)(DisplayFieldCard)

export default DisplayFieldCardConnected;
