import React, {useEffect} from 'react'
import {Modal} from 'antd'
import {T} from "../../../utils/translationUtils"

import {actionExecWarning, JobStatusEnum} from './constants'
import {__} from "../../../utils/translationUtils";
import {cases} from "../../../utils/stringUtils";
import {getMultipleDetailedAccessRights} from "../../../providers/ApiProvider/order";
import {ERROR} from 'components/dist/Utils/LoggerUtils'
import {LoadingOutlined} from "@ant-design/icons";
import * as S from "./styles";

type ConfirmationModalProps = {
	action: OrderEvent
	open: boolean
	selectedItems: ConfigurationResult[]
	onSubmit?: (orders: ConfigurationResult[], action: OrderEvent) => void
	onCancel: () => void
	jobsStatus: Record<string, Job>
}

const ConfirmationModal = ({action, open, selectedItems, jobsStatus, onSubmit, onCancel}: ConfirmationModalProps) => {

	const [eligibleOrders, setEligibleOrders] = React.useState<ConfigurationResult[] | undefined>(undefined)

	useEffect(() => {
		if (!open) {
			setEligibleOrders(undefined)
			return
		}

		getMultipleDetailedAccessRights(selectedItems?.map((item) => item.id).join(), action)
			.then((response) => {
				setEligibleOrders(selectedItems.filter((item) => {
					const currentStatus = jobsStatus[item.id]
					return (
						!currentStatus
						|| currentStatus.action !== action
						|| (currentStatus.action === action && currentStatus.status === JobStatusEnum.failed)
					) && response.data[item.id]?.[action]
				}))
			})
			.catch((error) => {
				ERROR(error)
			})
	}, [open])

	return (
		<Modal
			title={__(T.batch_action_confirmation_title)}
			visible={open}
			onOk={() => eligibleOrders && onSubmit?.(eligibleOrders, action)}
			onCancel={onCancel}
		>
			{
				eligibleOrders ? (
					<S.CustomResults
						status="warning"
						title={<>{(selectedItems?.length - eligibleOrders?.length) ?
							<p>{selectedItems?.length - eligibleOrders?.length} {__('orders_cannot_be_processed')}.</p> : ''}
							<p>{eligibleOrders?.length} {__('orders')} {__(actionExecWarning[action])}.</p></>}
						subTitle={<p>{__(T.non_reversible_action, cases.CAPITALIZE_SENTENCE)}</p>}
					/>
				) : (
					<S.CustomResults
						icon={<LoadingOutlined/>}
						title={__(T.checking_eligible_orders)}
					/>
				)
			}
		</Modal>
	)
}

export default ConfirmationModal
