import {useQuery, UseQueryResult} from "react-query";
import {
  fetchAttributes,
  fetchAttributesTree
} from "../../ApiProvider";
import config from "../config";
import {useSelector} from "react-redux";


enum DSQuery {
  attributes = "attributes",
  attributesMap = "attributesMap",
  attributesTree = "attributesTree",
}

export const transformAttributesToMap = (attributes: Attribute[]): Record<string, Attribute> => {
  const attributesMap: Record<string, Attribute> = {};
  for (const element of attributes) {
    attributesMap[element.id] = element;
  }
  return attributesMap;
};

export const UseAttributesQuery = () => {
  const token = useSelector((state: { user: any }) => state?.user?.authToken)

  return useQuery<Attribute[]>({
    queryKey: ["attributes", token],
    queryFn: token ? fetchAttributes : () => Promise.resolve([]),
    ...config,
  })
}

export const UseAttributesMapQuery = () => {
  const { data: attributes, isSuccess } = UseAttributesQuery();

  return useQuery<Record<string, Attribute>>({
    queryKey: ["attributesMap", attributes], // include attributes in the query key to cache the transformation
    queryFn: () => attributes ? Promise.resolve(transformAttributesToMap(attributes)) : Promise.resolve({}),
    ...({
      enabled: isSuccess, // only run this query if UseAttributesQuery was successful
      ...config,
    })
  });
};

export const UseAttributesTreeQuery = () => {
  const token = useSelector((state: { user: any }) => state?.user?.authToken)

  return useQuery<Record<string, Array<AttributeNode<Attribute>>>>({
    queryKey: ["fetchAttributesTree", token],
    queryFn: token ? fetchAttributesTree  : () => Promise.resolve({}),
    ...config
  })
}

const queriesMap: Record<DSQuery, () => UseQueryResult<any, any>> = {
  attributes: UseAttributesQuery,
  attributesMap: UseAttributesMapQuery,
  attributesTree: UseAttributesTreeQuery
}

type QueryKeys = keyof typeof queriesMap;
type QueryHook = () => UseQueryResult<any, any>

export const UseCombinedQueries = (queries: QueryKeys[]): Record<QueryKeys, unknown> | Object => {
  const query = queries.shift()
  if (!query) return {}

  const Query: QueryHook = queriesMap[query]

  const res = Query()

  return {
    [query]: res.data,
    ...UseCombinedQueries(queries)
  }
};
