import React from 'react';
import PropTypes from 'prop-types';

import { __ } from '../utils/translationUtils'


class BillingComment extends React.Component {

  static propTypes = {
    item: PropTypes.object,
    field: PropTypes.node,
    className: PropTypes.string,
    handleChange: PropTypes.func,
  };

  static defaultProps = { 
    field: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.item && props.item.comment && props.item.comment !== '' || false,
    };
  }

  render() {
    const { isOpen } = this.state;
    const { field, item } = this.props;

    let toggleLabel = __('Show Comment');

    if (!isOpen && !item.comment) {
      toggleLabel = __('Add Comment');
    }

    if (isOpen) {
      toggleLabel = __('Hide Comment');
    }

    return (
      <div>
        <div style={{ display: isOpen ? 'block' : 'none' }}>
          { field }
        </div>
        <span
          className="comment-toggle"
          onClick={() => {
            this.setState({
              isOpen: !this.state.isOpen,
            });
          }}
        >
          { toggleLabel }
        </span>
      </div>
    );
  }
  
}


export default BillingComment;
