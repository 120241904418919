import React from "react";

import {DatePicker, Form, Select} from "antd";
import moment from "moment";

import { __ } from '../../../utils/translationUtils'

const { RangePicker } = DatePicker;

export default ({setHistory, prevHistory, onSubmit, formRef, options}) => {

  const [filtersForm] = Form.useForm();
  if (formRef) formRef.current = filtersForm

  const applyFilters = (formValues) => {
    let filteredHistory = [...prevHistory];
    if (formValues.author?.length > 0) {
      filteredHistory = filteredHistory.filter((actions) => formValues.author.includes(actions.creator?.username))
    }
    if (formValues.actions?.length > 0) {
      filteredHistory = filteredHistory.filter((actions) => actions.updatedFields.some((action) => formValues.actions.includes(action.name)))
      filteredHistory = filteredHistory.map((actions) => ({...actions, updatedFields: actions.updatedFields.filter((action) => formValues.actions.includes(action.name))}))
    }
    if (formValues.dateRange?.[0]?._isValid && formValues.dateRange?.[1]?._isValid) {
      filteredHistory = filteredHistory.filter((actions) => moment(actions.creationDatetime).isBetween(formValues.dateRange[0], formValues.dateRange[1], 'milliseconds', '[]'))
    }
    setHistory([...filteredHistory])
    if (onSubmit?.()) onSubmit()
  }

  return (
    <Form
      layout={"vertical"}
      initialValues={{
        dateRange: [null, null],
        actions: [],
        authors: [],
      }}
      onFinish={applyFilters}
      form={filtersForm}
    >
      <Form.Item label={__("Date Range")} name="dateRange">
        <RangePicker
          ranges={{
            'Default': [moment("Thu Jan 01 2015 00:00:00 GMT+0100 (Central European Standard Time)"), moment()],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'Today': [moment().startOf('day'), moment().endOf('day')],
          }}
          showTime style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item label={__("Changes")} name="actions">
        <Select
          mode="tags"
          options={options?.actions}
          style={{ width: '100%' }}
          placeholder={__("select an action") + "..."}
        />
      </Form.Item>
      <Form.Item label={__("Authors")} name="authors">
        <Select
          mode="tags"
          options={options?.authors}
          style={{ width: '100%' }}
          placeholder={__("select an author") + "..."} />
      </Form.Item>
    </Form>
  )
}
