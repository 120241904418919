import {OBJECT_TYPE_ID, SYSTEM_ID} from "../utils/requiredFields"
import ApiManagement from "../api"

const addDataChildren = (parentRootObject, remoteObject) => {
  //sourceObject.data.forEach(d => (d.key = d.id))

  parentRootObject.push(...remoteObject.data.map((attribute) => ({...attribute, key: attribute.id})))

  remoteObject.children.forEach((child, index) => {
    const rootObject = {
      key: `${child.label}.${index}`,
      propertyLabel: child.label,
      childrenTreeNode: [],
    }
    parentRootObject.push(rootObject)
    addDataChildren(rootObject.childrenTreeNode, child)
  })
}

const createTreeFromObject = (remoteObject) => {
  if (!remoteObject) {
    return []
  }

  const attributes = []
  const rootObject = {
    key: `${remoteObject.label}.${remoteObject.objectTypeId}`,
    propertyLabel: remoteObject.label,
    childrenTreeNode: [],
  }

  attributes.push(rootObject)
  addDataChildren(rootObject.childrenTreeNode, remoteObject)

  return attributes
}

const createTreeFromAttributes = (attributesTree = {}) => {
  const attributes = []
  Object.entries(attributesTree).forEach(([systemId, systemRemoteObjects]) => {
    if (!Array.isArray(systemRemoteObjects)) {
      throw Error("Impossible to obtain value from the remote Object of this system " + systemId + ". Please, contact Administrator")
    }

    const systemName = ApiManagement.remoteSystems[systemId]
    const systemRootObject = {
      key: `${systemId}`,
      propertyLabel: systemName,
      childrenTreeNode: [],
    }
    systemRemoteObjects.forEach((remoteObject) => systemRootObject.childrenTreeNode.push(...createTreeFromObject(remoteObject)))
    attributes.push(systemRootObject)
  })

  return attributes
}

export const flattenAttributes = (list = [], result = []) => {
  list.forEach((item) => {
    result.push(item)
    flattenAttributes(item.childrenTreeNode, result)
  })
}

export const retrieveAttributesInformation = (configurationFormFields, ATTRIBUTE_TYPE) => {
  // Attributes Tree
  const systemId = configurationFormFields[SYSTEM_ID]?.value
  const objectTypeId = configurationFormFields[OBJECT_TYPE_ID]?.value
  let attributesTree = {}
  if (systemId && objectTypeId) {
    const remoteObject = ApiManagement.getRemoteObjectFromSystemIdAndObjectId(systemId, objectTypeId)
    attributesTree = createTreeFromObject(remoteObject)
  } else {
    attributesTree = createTreeFromAttributes(ApiManagement.attributesTree)
  }

  // Attributes Flat
  const attributesFlat = []
  flattenAttributes(attributesTree, attributesFlat)

  // Target Keys
  const targetKeys = Object.entries(configurationFormFields)
    .filter(([key]) => key.startsWith(ATTRIBUTE_TYPE) && key.endsWith(",attributeId"))
    .map(([_, value]) => value.value)

  return {
    attributesTree: attributesTree,
    attributesFlat: attributesFlat,
    targetKeys: targetKeys,
    systemId: systemId,
    objectTypeId: objectTypeId,
  }
}
