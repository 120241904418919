import React from 'react'

import {useROEConfiguratorContext} from "../context"
import ViewPropertiesConfigurator from './ViewPropertiesConfigurator'
import SystemObjectSelector from "./SystemObjectSelector"
import PreviewViewer from "./PreviewViewer"
import LayoutConfigurator from './LayoutConfigurator'
import * as C from '../constants'
import * as S from "../../ConfigurationConfiguratorBeta/styles"
import { Display, Filters, Sorting, Content, ContentRelationSelector } from './AttributeConfigurator'

export default () => {
  const {
    state,
  } = useROEConfiguratorContext()
  const {
    activeStep,
    loading,
  } = state || {}

  if (loading) {
    return (
      <S.BodyContainer>
        <S.ContentCentered>
          <S.CustomText size='extraLarge'>
            ...
          </S.CustomText>
        </S.ContentCentered>
      </S.BodyContainer>
    )
  }

  return (
    <>
      {activeStep === C.steps.SYSTEM_OBJECT_SELECTION && (
        <SystemObjectSelector key={`body-${C.steps.SYSTEM_OBJECT_SELECTION}`}/>
      )}
      {activeStep === C.steps.LISTING_PREVIEW && (
        <PreviewViewer key={`body-${C.steps.LISTING_PREVIEW}`}/>
      )}
      {activeStep === C.steps.LISTING_OUTPUT_FIELDS && (
        <Display key={`body-${C.steps.LISTING_OUTPUT_FIELDS}`}/>
      )}
      {activeStep === C.steps.LISTING_FILTERS && (
        <Filters key={`body-${C.steps.LISTING_FILTERS}`}/>
      )}
      {activeStep === C.steps.LISTING_SORTING_PARAMETERS && (
        <Sorting key={`body-${C.steps.LISTING_SORTING_PARAMETERS}`}/>
      )}
      {activeStep === C.steps.LISTING_VIEW_PROPERTIES && (
        <ViewPropertiesConfigurator key={`body-${C.steps.LISTING_VIEW_PROPERTIES}`}/>
      )}
      {activeStep === C.steps.CONTENT_PREVIEW && (
        <PreviewViewer key={`body-${C.steps.CONTENT_PREVIEW}`}/>
      )}
      {activeStep === C.steps.CONTENT_CHOOSE_FIELDS && (
        <Content key={`body-${C.steps.CONTENT_CHOOSE_FIELDS}`}/>
      )}
      {activeStep === C.steps.CONTENT_ORGANISE_FIELDS && (
        <LayoutConfigurator key={`body-${C.steps.CONTENT_ORGANISE_FIELDS}`}/>
      )}
      {activeStep === C.steps.SELECT_RELATED_OBJECTS && (
        <ContentRelationSelector key={`body-${C.steps.SELECT_RELATED_OBJECTS}`}/>
      )}
    </>
  )
}
