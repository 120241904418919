import React from 'react'
import PropTypes from 'prop-types';
import {showMessage} from "../../../utils/appHelper";
import {Button, Modal} from "react-bootstrap";
import MessageHistorySingle from "./MessageHistorySingle";
import { api } from '../../../providers/ApiProvider'
import { __ } from '../../../utils/translationUtils'

class MessageHistoryModal extends React.Component {

  static propTypes = {
    message: PropTypes.object,
    openHistoryMessageModal: PropTypes.bool,
    closeHistoryMessageModal: PropTypes.func
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      messageHistory: []
    };
  }

  componentDidMount() {
    this.getMessageHistory();
  }

  getMessageHistory(){
    this.setState({isLoading: true});
    const {message} = this.props;
    const req = `comments/${message.id}/history`;
    api.get(req)
      .then(response => {
        this.setState({messageHistory: response.data, isLoading: false})
      })
      .catch(() => {
        showMessage('error', 'Impossible to load comment history');
      })

  }

  renderModalBody(){
    if (this.state.isLoading){
      return(<Modal.Body><div/></Modal.Body>)
    } else {

      const {messageHistory} = this.state;
      const messageHistorySorted = messageHistory.slice().reverse()
      return (
        <div className={'chatroom history-chatroom'} id={'comment-list'}>
          <ul className={'chats'}>
            {[].concat(messageHistorySorted).map(message =>
              <MessageHistorySingle historyMessage={message} key={`${message.modifierId}_${message.lastUpdateDatetime}`}
                             {...this.props}
              />)}
          </ul>
        </div>
      )
    }
  }

  render(){
    return(

      <Modal
        backdrop="static"
        show={this.props.openHistoryMessageModal}
        onHide={this.props.closeHistoryMessageModal}
        className="history-message">
        <Modal.Header closeButton>
          <Modal.Title className="capitalize">{__('Comments History')}</Modal.Title>
        </Modal.Header>
        {this.renderModalBody()}
        <Modal.Footer>
          <Button className="pull-right" onClick={this.props.closeHistoryMessageModal}>
            {__('Close')}
          </Button>
        </Modal.Footer>
      </Modal>

    )

  }




}

export default MessageHistoryModal;
