import React, {useCallback, useEffect, useMemo, useState} from 'react';

import { TreeSelect } from 'antd';
import _ from "lodash";

import {useDispatch} from "react-redux";
import {change} from "redux-form";

import {fetchEnumById} from "../../utils/appHelper";

const { SHOW_PARENT } = TreeSelect;

export const GroupedTreeSelect = (props) => {

  const {
    enumId,
    value,
    controlData
  } = props || {}

  const dispatch = useDispatch()
  const [currentValue, setCurrentValue] = useState([])
  const [entities, setEntities] = useState([])
  const [loading, setLoading] = useState(false)

  const treeValue = useMemo(() => {
    if (!entities?.length || !currentValue?.length) return []
    return entities.reduce((prev, entityGroup) => {
      if (entityGroup.children.every(child => currentValue.includes(child.value.replace('entity-','')))){
        prev.push(entityGroup.value)
      }
      else {
        entityGroup.children.forEach(child => {
          if (currentValue.includes(child.value.replace('entity-',''))) prev.push(child.value)
        })
      }
      return prev
    }, [])
  }, [currentValue, entities])

  useEffect(() => {
    if (!enumId) return
    setLoading(true)
    fetchEnumById(enumId, {parentPath:'belongsTo.id', valuePath:'name'})
      .then(response => {
        const entityGroups = _.groupBy(response.enumerationValues, (e) => { return e.parent; });
        const entities = Object.entries(entityGroups).map(([key, value]) => {
          const entity = response.enumerationValues.find( e => e.id === key)
          return ({
            title: entity.value,
            value: `entityGroup-${value.map(o => o.value)}`,
            key: `entityGroup-${value.map(o => o.value)}`,
            isLeaf: false,
            children: value.map( o => ({
              title: o.value,
              value: `entity-${o.value}`,
              key: `entity-${o.value}`,
              isLeaf: true
            }))
          })
        })
        setEntities(entities)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.error(error)
      })
  }, [enumId])

  useEffect(() => {
    const arrayValue = value ? (Array.isArray(value) ? value : value.split(',')) : []
    if (!_.isEqual(arrayValue, currentValue)) {
      setCurrentValue(arrayValue)
    }
  }, [value])

  const onChange = useCallback((value) => {
    const simpleValue = value.reduce((prev, item) => {
      if (item.startsWith('entity-')) {
        prev.push(item.replace('entity-',''))
        return prev
      }
      else {
        return [...prev, ...entities.find(e => e.value === item).children.map(e => e.value.replace('entity-',''))]
      }
    }, [])
    setCurrentValue(simpleValue)
    dispatch(change('reportSearchFieldsForm', controlData.id, simpleValue.join(',')))
  }, [controlData, dispatch, entities])

  return (
    <TreeSelect
      treeCheckable
      treeData={entities}
      value={treeValue}
      onChange={onChange}
      loading={loading}
      showCheckedStrategy={SHOW_PARENT}
      searchPlaceholder={'Please select'}
      dropdownStyle={{ maxHeight: '40vh' }}
    />
  )
}

export default GroupedTreeSelect
