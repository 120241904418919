import _, {find} from "lodash";
import BillingOverview from "../components/BillingOverview";
import ObjectListingControl from "../components/controls/ObjectListingControl";
import {Field} from "redux-form";
import {renderField} from "../utils/fieldValidator";
import * as config from "../constants/globalConfiguration";
import React from "react";
import TabFieldSet from "../components/TabFieldSet";
import { __ } from '../utils/translationUtils'


const SnapshotTabContent = props => {

  const {tabData, attributesConfigurationTree, attributesConfiguration} = props;

  if (!attributesConfigurationTree || !tabData) {
    return (<div>{__('loading')}</div>);
  }

  const getConfigController = (attr, configuration) => {
    if (!configuration || !attr) {
      return;
    }
    return attr.type;
  };


  const getAttributeTree =  configuration  => {

    const attributeTree = {}
    configuration.displayAttributes.map( ( dA, index) => {

      const attributeBased = attributesConfiguration.find( aC => aC.id === dA.attributeId)
      const attribute = Object.assign({}, attributeBased, {position: index });

      const branch = attribute.property.split('/').slice(0, -1).join('/')
      const depth = attribute.property.split('/').slice(0, -1).length

      const br = {}
      br[attribute.propertyLabel] = attribute

      const temporary = {}
      temporary[branch] = [attribute]
      if (attributeTree[depth] && attributeTree[depth][branch]){
        attributeTree[depth][branch].push(attribute)
      } else if (attributeTree[depth] && !attributeTree[depth][branch]){
        attributeTree[depth][branch] = [attribute]
      } else{
        attributeTree[depth] = temporary;
      }
    })

    return attributeTree
  }

  const renderLine = (attr, value, configuration) => {

    const extractConfiguration = id => {
      return find(attributesConfiguration, (c) => c.id === id);
    };
    let controllerType = getConfigController(attr, ((configuration.configuration) ? configuration : {"configuration": configuration}));
    const attrConfiguration = extractConfiguration(attr.id);

    if (!attrConfiguration)
      return null;
    // Billing overview
    if (attrConfiguration.type === 'BILLING') {
      return (
        <BillingOverview
          source={value}
          configuration={attr}
          key={attrConfiguration.id}
        />
      );
    }

    // Object Listing overview
    if (attrConfiguration.type === 'OBJECT_LIST_IDS') {
      let attributes;
      attributes = [].concat.apply([], Object.values(attributesConfigurationTree));
      attributes = [].concat.apply([], attributes.map(attribute => {
        return attribute.data;
      }));
      let attribute = _.find(attributes, (a) => a.id === attr.id);
      let controlData = {};
      controlData.source = value;
      controlData.readOnly = true;
      controlData.controllerType = "ObjectListing";
      controlData.attributes = {};
      return (
        <ObjectListingControl
          source={value}
          configuration={attr}
          readOnly={true}
          controlData={controlData}
          objectTypeId={attribute.relatedObjects}
          additionalStyle={"overviewTab"}/>
      );
    }

    let labelField = attrConfiguration.propertyLabel;
    if (['OBJECT_LIST_IDS', 'ObjectListing'].includes(getConfigController(attr, configuration))) {
      labelField = '';
    }

    return attrConfiguration && (
      <Field
        key={`remoteobject-property-${attr.id}`}
        name={attr.id}
        component={renderField}
        disabled={true}
        dateFormat={config.appDefaults.dateTimeFormat}
        label={__(labelField)}
        //className="form-control"
        // type={attrConfiguration.enumerationId ? 'select' : 'text'}
        type={controllerType}
        input={{name: attr.id, value: (value ? value : ""), attributesConfigurationTree: attributesConfigurationTree}}
      />
    );
  };


  const localCacheEntries = props.order && props.order.localCacheEntries || undefined;
  if(!tabData || !tabData.configurations)
    return null;
  return (
    <div>
      {
        tabData.configurations.map(configuration => {

          const c = configuration.configuration;
          const attributeTree = getAttributeTree( c );
          const starterDepth = Math.min(...Object.keys(attributeTree).map( k => parseInt(k)))
          const root = attributeTree[ starterDepth ];

          return Object.entries(root).map( ([branch, displayData], index) => {
            return (<TabFieldSet
              key={`${branch}_${index}`}
              branch={branch} displayData={displayData} attributeTree={attributeTree} renderLine={renderLine}
              configuration={c}  values={localCacheEntries} depth={starterDepth}  />)})

        })
      }
    </div>
  );



}


export default SnapshotTabContent;
