import React from "react";

import {Popover, Typography} from "antd";

import * as U from "../utils";
import * as S from "../styles";
import ActionDescription, {detailsToAnonymize} from "./ActionDescription";
import { __ } from '../../../utils/translationUtils'

const { Text } = Typography;

const ActionsTags = ({actions, status}) => {
  const actionsType = actions.reduce((prev, action) => ({...prev, [action.name]: U.getActionType(action)}), {})
  return (
    <div>
      <Text type="secondary" style={{fontSize: "14px"}}>{__("Changes")}</Text>
      <S.TagsContainer>
        <S.TagsContainerWrap>

          {actions.map((action) => {
            if (status === 'deleted' && detailsToAnonymize.includes(action.name))
              return (
                <S.SnapshotTag key={action.name}>
                  <S.TagType> {U.getActionTypeIcon(actionsType[action.name])} </S.TagType>
                  <S.TagContent> {__(action.name)} </S.TagContent>
                </S.SnapshotTag>
              )

            return (
              <Popover content={<ActionDescription action={action} status={status} />} trigger="click" key={action.name}>
                <S.SnapshotTag key={action.name} clickable>
                  <S.TagType> {U.getActionTypeIcon(actionsType[action.name])} </S.TagType>
                  <S.TagContent> {__(action.name)} </S.TagContent>
                </S.SnapshotTag>
              </Popover>
            )
          })}
        </S.TagsContainerWrap>
      </S.TagsContainer>
    </div>
  )
}

export default ActionsTags
