import React, { useCallback, useMemo, useState } from 'react'

import ActionsDropdown from "./components/ActionsDropdown";
import DetailsDrawer from "./components/Details";
import Table from "./components/Table";
import * as C from './constants'

export default (props) => {

  const {
    data,
    columns = [],
    actions = [],
    openViewDetails,
    pagination,
    rowSelection,
    onTableChange,
    enableSorting,
    ...tableProps
  } = props

  const {
    type,
    onSelect,
    selectedRows = [],
    getCheckboxProps,
  } = rowSelection || {}

  const [preview, setPreview] = useState(null)

  const tableActions = useMemo(() => ({
    [C.actionType.VIEW_DETAILS]: (row) => {
      if (openViewDetails) openViewDetails(row)
      else setPreview(row)
    },
  }), [])

  const cols = useMemo(() => ([
    ...columns?.map((col) => ({
      ...col,
      className: `${col.className && col.className} ${!!actions?.length && 'with-actions'}`
    })),
    ...(!actions?.length ? [] : [{
      className: "actions",
      title: null,
      key: 'actions',
      width: '0px',
      render: (row) => (
        <ActionsDropdown
          actions={actions}
          actionsClick={tableActions}
          row={row}
        />
      ),
    }])
  ]), [columns, actions, tableActions])

  const handleRowSelection = useCallback((rows, checked) => {
    const rowsId = rows.map((row) => (row.id))
    if (onSelect) {
      onSelect(rowsId, checked)
    }
  }, [onSelect])

  return (
    <>
      <Table
        columns={cols}
        dataSource={data}
        rowSelection={rowSelection && {
          type: type,
          selectedRowKeys: selectedRows,
          onSelect: (row, checked) => handleRowSelection([row], checked),
          onSelectAll: (checked, allRows, rows) => handleRowSelection(rows, checked),
          onSelectMultiple: (checked, allRows, rows) => handleRowSelection(rows, checked),
          getCheckboxProps: getCheckboxProps,
        }}
        pagination={pagination}
        onChange={onTableChange}
        {...tableProps}
      />
      {actions.includes(C.actionType.VIEW_DETAILS) &&
        <DetailsDrawer
          onClose={() => setPreview(null)}
          visible={preview}
          data={preview}
          exceptions={['key', 'id']}
        />
      }
    </>
  )
}
