import React from "react";

import {Descriptions, Popover} from "antd";

import * as S from "../styles";

import {__} from '../../../utils/translationUtils'


const AuthorDetails = ({author, label}) => (
  author ? (
    <Popover
      content={
        <S.CustomActorDescription column={1} size="small">
          {
            Object.keys(author).map((field) => {
              return (
                <Descriptions.Item key={field} label={__(field)}>
                  {author[field]}
                </Descriptions.Item>
              )
            })
          }
        </S.CustomActorDescription>
      }
    >
      {label}
    </Popover>
  ) : <span style={{paddingLeft: '4px'}}>{__('unknown', 'capitalise')}</span>
)

export default AuthorDetails
