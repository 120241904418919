import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {getFormValues} from 'redux-form';
import Glyphicon from '@strongdm/glyphicon'
import {Modal} from 'react-bootstrap';
import _ from 'lodash';
import StepObjectEditForm from '../components/StepObjectEditForm';
import {updateRemoteObjectItem, createRemoteObjectItem} from '../providers/ReduxProvider/actions/remoteObjectActions';
import {showMessage} from '../utils/appHelper';
import { api } from '../providers/ApiProvider'
import { Icon } from '@ant-design/compatible'
import { Tooltip } from 'antd'
import { __ } from '../utils/translationUtils'
const loadingBar = require('nprogress');




class EditStepObjectButton extends React.Component {
  static propTypes = {
    guiUser: PropTypes.object,
    node: PropTypes.object,
    initialValues: PropTypes.object,
    configuration: PropTypes.object,
    enumValues: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
    remoteObjectId: PropTypes.string,
    dependencyId: PropTypes.number,
    system: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    resultId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    overviewObjectId: PropTypes.string,
    className: PropTypes.string,
    attributes: PropTypes.array,
    values: PropTypes.array
  };

  static defultProps = {
    action: 'edit',
    resultId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      attributesConfigurationTree: null,
      objectConfiguration: null,

      modal: {
        open: false,
      },
      isLoading: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.getDataAndOpenModal = this.getDataAndOpenModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAttributesConfigurationTree = this.getAttributesConfigurationTree.bind(this);
  }

  closeModal(e) {
    if(e?.stopPropagation) e.stopPropagation()
    this.setState({
      modal: {
        open: false,
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const {initialValues, formValues, dependencyId} = this.props;
    const {resultId} = this.props;

    const stepFormErrors = this.props.reduxState.form.stepObjectEditForm.syncErrors;
    if (stepFormErrors) {
      return;
    }
    const payload = {
      id: resultId,
      displayData: Object.entries(formValues).reduce((prev, entry) => {
        if (initialValues[entry[0]] === formValues[entry[0]]) return prev
        prev[entry[0]] = entry[1]
        return prev
      }, {})
    }
    // Check if patch is empty (Nothing to change.)
    if (!Object.keys(payload.displayData).length) {
      this.closeModal();
      showMessage('success', __('List Object updated successfully'));
      return;
    }
    const defaultEditRemoteItem = ({ payload, mainKey }) => {
      let patch = [];
      Object.keys(initialValues).forEach((key) => {
        if (initialValues[key] !== formValues[key]) {
          patch.push({
            op: 'test',
            path: `/displayData/${key}`,
            value: initialValues[key],
          });
          patch.push({
            op: 'replace',
            path: `/displayData/${key}`,
            value: formValues[key],
          });
        }
      });
      // Tests should always go first, then replace
      // Also make sure higher position items comes first / per operation.
      patch = _.sortBy(patch, (item) => {
        if (item.op === 'test') {
          return 'a_' + (100000 - parseInt(item.path.replace('/', '')));
        }
        else if (item.op === 'replace') {
          return 'b_' + (100000 - parseInt(item.path.replace('/', '')));
        }
        else if (item.op === 'remove') {
          return 'c_' + (100000 - parseInt(item.path.replace('/', '')));
        }
        else {
          return 'd_' + (100000 - parseInt(item.path.replace('/', '')));
        }
      });

      patch = _.orderBy(patch, 'op').reverse();
      return api.patch(`/configurations/stepObjects/${dependencyId}/${mainKey}`, payload)
    }
    const editRemoteItem = this.props.apis?.editRemoteItem || defaultEditRemoteItem
    editRemoteItem({ mainKey: resultId, payload }, { objectTypeId: dependencyId })
      .then(
        () => {
          // Update the state
          this.setState({
            isLoading: false,
          }, () => {
            loadingBar.done();
            this.closeModal();
            // trigger reload on the preview in order to get the latest changes.
            this.props.onUpdate(this.props.overviewObjectId);
            showMessage('success', __('List Object updated successfully'));
          });
        }
      ).catch((error) => {
      showMessage('error', __('ServerSideError') + '. ' + error);
    });
  }

  getAttributesConfigurationTree() {
    return api.get('configurations/attributes/tree')
      .then(
        response => {
          this.setState({
            attributesConfigurationTree: response.data,
          });
        }
      );
  }

  getDataAndOpenModal() {
    // Fetch attributes tree
    this.getAttributesConfigurationTree();
    this.setState({
      modal: {
        open: true,
      },
    });
  }

  render() {
    const {
      enumValues,
      node,
      configuration,
      initialValues,
      attributes,
      values
    } = this.props;
    const {
      objectConfiguration,
      attributesConfigurationTree,
    } = this.state;
    return (
      <span>
        <Tooltip title={__('edit')}><div className="btn-no-padding btn-std" onClick={(e) => {
          e.preventDefault();
          this.getDataAndOpenModal();
        }}><Icon className="icon-std" type="edit" theme="outlined" /></div></Tooltip>

        {this.state.modal.open}

        {this.state.modal.open &&
        <Modal
          backdrop="static"
          show={this.state.modal.open}
          onHide={this.closeModal}
          dialogClassName="modal-90W"
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">{__('Edit Object')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(node && attributesConfigurationTree)
              ? <StepObjectEditForm
                filterByDisplayAttributes
                enumValues={enumValues}
                node={node}
                configuration={configuration}
                objectConfiguration={objectConfiguration}
                attributesConfigurationTree={attributesConfigurationTree}
                initialValues={initialValues}
                attributes={attributes}
                values={values}
              />
              : __('loading')
            }
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <button className="btn btn-default pull-left" onClick={this.closeModal}>
                <Glyphicon glyph="chevron-left"/> {__('Back')}
              </button>

              <button
                className="btn pull-right btn-primary"
                disabled={this.state.isLoading}
                size="large"
                onClick={this.handleSubmit}
              >
                {__('Save Changes')}
              </button>

            </div>
          </Modal.Footer>
        </Modal>
        }
      </span>
    );
  }
}

export default connect(
  state => {
    return {
      formValues: getFormValues('stepObjectEditForm')(state),
      guiUser: state.user.data,
      enumValues: state.enumValues,
      reduxState: state,
    };
  },
  {updateRemoteObjectItem, createRemoteObjectItem}
)(EditStepObjectButton);
