import React from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'
import { renderColumns } from '../utils/formatTools'
import { __ } from '../utils/translationUtils'


class TabFieldSet extends React.Component {
  static propTypes = {
    editButton: PropTypes.node,
    node: PropTypes.object,
    values: PropTypes.object,
    renderLine: PropTypes.func,
    getControllerType: PropTypes.func.isRequired,
    configuration: PropTypes.object,
    // title: PropTypes.string,
    depth: PropTypes.number,
    maxColumns: PropTypes.number
  };

  static defaultProps = {
    editButton: null,
    maxColumns: 2
  };

  constructor(props) {
    super(props)
    this.state = { open: true }
  }

  render() {
    const { renderLine, getControllerType, values, depth, editButton, attributeTree, branch, displayData, configuration, multiCol } = this.props

    const dataRows = []
    const dataObjects = []
    const tabFieldChildren = []

    const branches = branch.split('/')

    // Create title
    const tabFieldTitle = branches.length === 1 ? configuration.name || branches[branches.length - 1] : branches[branches.length - 1] || configuration.name
    // Create rows
    if (!displayData.every((b) => Object.keys(values).includes(b.id) && values[b.id] === '')) {
      displayData.filter((b) => Object.keys(values).includes(b.id))
        .sort((a, b) => ((a.position > b.position) ? 1 : -1))
        .map((b) => {
          if (b.displayable) {
            if (getControllerType(b, configuration).toLowerCase() === 'objectlisting') {
              if (displayData.every((field) => getControllerType(field, configuration).toLowerCase() === 'objectlisting')) {
                dataRows.push(renderLine(b, values[b.id], configuration))
              } else { dataObjects.push(b) }
            } else {
              dataRows.push(renderLine(b, values[b.id], configuration))
            }
          }
        })
    }

    if (dataObjects.length > 0) {
      // TODO: get the title from the configuration!
      dataObjects.forEach((o) => {
        tabFieldChildren.push(<TabFieldSet
          key={Math.ceil(Math.random() * 1000)}
          depth={depth}
          getControllerType={getControllerType}
          branch={`${branch}/${o.propertyLabel}`}
          displayData={[o]}
          attributeTree={attributeTree}
          renderLine={renderLine}
          configuration={configuration}
          values={values}
        />)
      })
    }

    // Create tabFieldChildren
    const maxDepth = Math.max(...Object.keys(attributeTree).map((k) => parseInt(k)))
    let nextDepth = depth + 1

    if (attributeTree[nextDepth]) {
      Object.keys(attributeTree[nextDepth]).filter((a) => a.startsWith(branch))
        .map((tabFieldName, index) => {
          const clonedChildren = [...attributeTree[nextDepth][tabFieldName]].filter((b) => Object.keys(values).includes(b.id) && b.displayable && values[b.id] !== '')

          if (!clonedChildren.length) {
            // pass
          } else {
            tabFieldChildren.push(<TabFieldSet
              key={`${tabFieldName}_${index}`}
              depth={depth + 1}
              getControllerType={getControllerType}
              branch={tabFieldName}
              displayData={attributeTree[nextDepth][tabFieldName]}
              attributeTree={attributeTree}
              renderLine={renderLine}
              configuration={configuration}
              values={values}
            />)
          }
        })
    } else {
      while (nextDepth <= maxDepth) {
        nextDepth += 1

        if (attributeTree[nextDepth] && Object.keys(attributeTree[nextDepth]).filter((a) => a.startsWith(branch)).length) {
          const branchNames = new Set()
          // Create a Set of Branch Name which has children
          Object.keys(attributeTree[nextDepth]).filter((a) => a.startsWith(branch)).map((tabFieldName) => {
            // With this tabs with empty field are not displayed
            const clonedChildren = [...attributeTree[nextDepth][tabFieldName]].filter((b) => Object.keys(values).includes(b.id) && b.displayable && values[b.id] !== '')

            if (!clonedChildren.length) {
              // pass
            } else {
              const branchName = tabFieldName.split('/').slice(0, depth + 1).join('/')
              branchNames.add(branchName)
            }
          })
          Array.from(branchNames).map((branch, index) => {
            tabFieldChildren.push(<TabFieldSet
              key={`${branch}_${index}`}
              depth={depth + 1}
              branch={branch}
              getControllerType={getControllerType}
              displayData={[]}
              attributeTree={attributeTree}
              renderLine={renderLine}
              configuration={configuration}
              values={values}
            />)
          })

          break
        }
      }
    }

    /*
    root.map( tabField => {

      // It should only have one couple key-value
      Object.entries(tabField).map( ([branchName, branch]) => {

        const branches = branchName.split('/');

      });

    } )
*/

    /*    if (!values) {
      values = [];
    } else {
      const displayableNode = _.filter(node.data, nd => {
        return Object.keys(values).includes(nd.id);
      });

      displayableNodeSorted = Object.keys(values).map(val => {
        return _.find(displayableNode, dpnd => {
          return dpnd.id === val;
        });
      });
    }

    displayableNodeSorted.forEach((line) => {
      if (typeof line !== 'undefined' && line.displayable && configuration.displayAttributes.some(d => d.attributeId === line.id)) {
        rows.push(renderLine(line, values[line.id], configuration)); //label, value, key.
      }
    });
    */
    /*    //TODO: Controllare qui per gli oggetti vuoti nelle tab!
    let atLeastOneSubObject = false;
    //TODO: Fix this ugly thing here
    let objVal = values;
    if (!values || values.length < 1) {
      objVal = displayableNodeSorted;
    }
    Object.values(objVal).map((object) => {
      if (typeof object !== 'undefined' && object != null && object !== '') {
        atLeastOneSubObject = true;
      }
    });
    if (!atLeastOneSubObject) {
      return <div></div>;
    } */

    /*    node.children.forEach((child, i) => {
      childNodes.push(
        <TabFieldSet
          key={`fs-${child.label}-${child.objectTypeId}-${i}`}
          node={child}
          depth={depth + 1}
          renderLine={renderLine}
          values={values}
          configuration={configuration}
          getTree={false}
        />
      );

    }); */
    const { maxColumns } = this.props

    if (dataRows.length || dataObjects.length || tabFieldChildren.length) {
      return (
        <div
          className={`fieldset style-${depth}`}
        >
          <span
            className="collapsible-header no-selection"
            onClick={
              () => this.setState({ open: !this.state.open })
            }
          >
            {__(tabFieldTitle)}
          </span>

          <Panel
            expanded={this.state.open}
            onToggle={() => { }}
          >
            <Panel.Body collapsible>
              <div className="form-horizontal">
                {depth === 1
                  && (
                    <div className="text-right">
                      {editButton}
                    </div>
                  )}

                {dataRows.length > 0
                  && (
                    <div className="group">
                      {renderColumns(dataRows, maxColumns, null, multiCol)}
                    </div>
                  )}

                {tabFieldChildren.length > 0
                  && (
                    <div className={(depth == 2) ? 'group row' : 'group'}>
                      {tabFieldChildren}
                    </div>
                  )}

              </div>
            </Panel.Body>
          </Panel>

        </div>
      )
    }
    return null
  }
}

export default TabFieldSet
