import _ from 'lodash'
import {__} from "./translationUtils";


export function extractConfiguration (config, attributeId, idKey) {
  return _.find(config, (c) => c[idKey || 'id'] === attributeId)
}

export function extractDisplayConfiguration (config, attributeId) {
  return extractConfiguration(config, attributeId, 'attributeId')
}

export function extractDetailedDisplayableColumn (props) {
  const { field, selectedColumns, detailedFields } = props || {}
  const detailedField = extractConfiguration(detailedFields, field?.attributeId)
  if ((selectedColumns?.indexOf(detailedField?.propertyLabel) < 0) || !detailedField?.displayable) return null
  return detailedField
}

export const getDisplayableAttributes = (displayAttributes, attributeMap) => {
  const attributes = (displayAttributes?.sort((a, b) => a.position - b.position) || [])
    .map((da) => attributeMap[da.attributeId]).filter(x => x)

  return attributes?.filter((a) => (a.displayable))
}

export const getSummaryAttributesItems = (displayAttributes, attributeMap) => {
  const summaryDisplayAttributes = (displayAttributes || []).filter((da) => (da.summary))

  return getDisplayableAttributes(summaryDisplayAttributes, attributeMap).map((a) => ({
    value: a.id,
    label: __(a.propertyLabel)
  }))
}
