import * as types from '../types';
import initialState from './initialState';

export default function userUpdate(state = initialState.user, { type, payload }) {

  switch (type) {
    case types.USER_LOGGING_IN:
      return {
        ...state,
        isLoading: true
      }

    case types.USER_LOGGED_IN:
      return {
        ...state,
        authToken: payload.authToken,
        isLoading: false
      }
    case types.TOKEN_REFRESH_START:
      return {
        ...state,
        refreshStartTime: payload,
      }
    case types.TOKEN_REFRESH_END:
      return {
        ...state,
        refreshStartTime: undefined,
      }
    case types.USER_GOT_DATA:
      return {
        ...state,
        data: payload
      }

    case types.USER_GOT_REPORTS:
      return {
        ...state,
        reports: payload
      }

    case types.USER_GOT_ORDERGROUPS:
      return {
        ...state,
        orderGroups: payload
      }

    case types.USER_GOT_VIEWS:
      return {
        ...state,
        views: payload
      }

    case types.USER_GOT_ORDERTYPES:
      return {
        ...state,
        orderTypes: payload
      }

    case types.USER_GOT_RIGHTS:
      return {
        ...state,
        data: {
          ...state.data,
          rights: payload
        }
      }

    case types.USER_LOGIN_ERROR:
      return {
        ...state,
        data: null,
        isLoading: false,
        authToken: null
      }

    case types.USER_LOGGED_OUT:
      return {
        ...state,
        data: null,
        authToken: null
      }

    default:
      return state;
  }

}

/* Composite selectors for "reselect" */
export const selectUserStore = (state) => state.user

export const getUserData = (user, a, b) => user.data
