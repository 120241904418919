import React from 'react'
import { HashRouter, Switch } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'

import * as pages from "./pages"

import { addWatermarks } from './utils/appHelper'
import * as config from './constants/globalConfiguration'
import { PrivateRoute, PublicRoute } from "./components/UI/Route";
import { __ } from './utils/translationUtils'

const routes = () => {

  const env = localStorage.getItem('env') && config.getEnvConfig()
  env && addWatermarks(env.API_URL)

  return (
    <HashRouter>
      <LastLocationProvider>
        <Switch>
          <PrivateRoute path="/orders/:orderId/overview" Component={pages.OrderOverview} />
          <PrivateRoute path="/orders/:orderId/doo" Component={pages.DooOrderPage} />
          <PrivateRoute path="/orders/create" Component={pages.CreateOrderPage} />
          <PrivateRoute path="/orders/:orderId" Component={pages.OrderOverview} />
          <PrivateRoute path="/reports/:id/:orderGroupId" Component={(props) => <pages.ReportPage {...props} type="report" useRequestedOrderGroupId />} />
          <PrivateRoute path="/reports/:id" Component={(props) => <pages.ReportPage {...props} type="report" />} />

          <PrivateRoute path="/customReports/:id/deprecatedEdit" Component={(props) => <pages.CustomReportPage {...props} mode="edit" type="Report" />} />
          <PrivateRoute path="/customReports/:id/edit" Component={(props) => <pages.ConfigurationConfiguratorBeta {...props} mode={"EDIT"} type={"REPORT"} />} />
          <PrivateRoute path="/customReports/deprecatedCreate" Component={(props) => <pages.CustomReportPage {...props} mode="create" type="Report" />} />
          <PrivateRoute path="/customReports/create" Component={(props) => <pages.ConfigurationConfiguratorBeta {...props} mode={"CREATE"} type={"REPORT"} />} />
          {/*<PrivateRoute path="/customReports/clone/:id" Component={(props) => <pages.CustomReportPage {...props} mode="clone" type="Report" />} />*/}
          <PrivateRoute path="/customReports" Component={(props) => <pages.CustomReportsPage {...props} type="Report" />} />

          <PrivateRoute path="/views/:id/:orderGroupId" Component={(props) => <pages.ReportPage {...props} type="view" useRequestedOrderGroupId />} />
          <PrivateRoute path="/views/:id" Component={(props) => <pages.ReportPage {...props} type="view" />} />

          <PrivateRoute path="/customViews/:id/deprecatedEdit" Component={(props) => <pages.CustomReportPage {...props} mode="edit" type="View" />} />
          <PrivateRoute path="/customViews/:id/edit" Component={(props) => <pages.ConfigurationConfiguratorBeta {...props} mode={"EDIT"} type={"VIEW"} />} />
          <PrivateRoute path="/customViews/deprecatedCreate" Component={(props) => <pages.CustomReportPage {...props} mode="create" type="View" />} />
          <PrivateRoute path="/customViews/create" Component={(props) => <pages.ConfigurationConfiguratorBeta {...props} mode={"CREATE"} type={"VIEW"} />} />
          {/*<PrivateRoute path="/customViews/clone/:id" Component={(props) => <pages.CustomReportPage {...props} mode="clone" type="View" />} />*/}
          <PrivateRoute path="/customViews" Component={(props) => <pages.CustomReportsPage {...props} type="View" />} />

          <PrivateRoute path="/remoteObjects/:id/deprecatedEdit" Component={(props) => <pages.CustomReportPage {...props} mode="edit" type="RemoteObject" />} />
          <PrivateRoute path="/remoteObjects/:id/edit" Component={(props) => <pages.RemoteObjectConfiguratorBeta {...props} mode="EDIT" />} />
          <PrivateRoute path="/remoteObjects/force-create" Component={(props) => <pages.RemoteObjectForceCreatePage {...props} mode="create" type="RemoteObject" />} />
          <PrivateRoute path="/remoteObjects/deprecatedCreate" Component={(props) => <pages.CustomReportPage {...props} mode="create" type="RemoteObject" />} />
          <PrivateRoute path="/remoteObjects/create" Component={(props) => <pages.RemoteObjectConfiguratorBeta {...props} mode="CREATE" />} />
          {/*<PrivateRoute path="/remoteObjects/clone/:id" Component={(props) => <pages.CustomReportPage {...props} mode="clone" type="RemoteObject" />} />*/}

          <PrivateRoute path="/remoteObjects/details/:id/:configurationId/:entryId" Component={(props) => <pages.RemoteObjectDetailsPage key={props.match?.url} {...props} type="RemoteObject" />} />
          <PrivateRoute path="/remoteObjects/:id/:entryId?" Component={(props) => <pages.RemoteObjectInstances {...props} type="RemoteObject" />} />
          <PrivateRoute path="/remoteObjects" Component={(props) => <pages.RemoteObjectsPage mode="listing" {...props} title={__('Manage Data')} />} />
          <PrivateRoute path="/manageRemoteObjects" Component={(props) => <pages.RemoteObjectsPage {...props} title={__('Data_Mgmt_Configuration')} />} />
          <PrivateRoute path="/stepObjects/create" Component={(props) => <pages.CustomReportPage {...props} mode="create" type="StepObject" />} />
          <PrivateRoute path="/stepObjects/:id" Component={(props) => <pages.CustomReportPage {...props} mode="edit" type="StepObject" />} />
          <PrivateRoute path="/stepObjects" Component={(props) => <pages.CustomReportsPage {...props} mode="listing" type="StepObject" title={__('Manage Step Objects')} />} />
          <PrivateRoute path="/manageStepObjects" Component={(props) => <pages.CustomReportsPage {...props} type="StepObject" title={__('Manage Step Objects')} />} />

          <PrivateRoute path="/orderGroups/:id/edit" Component={(props) => <pages.OrderGroupPage {...props} mode="edit" />} />
          <PrivateRoute path="/orderGroups/:id/views" Component={(props) => <pages.OrderGroupViewPage {...props} type="view" />} />
          <PrivateRoute path="/orderGroups/:id/reports" Component={(props) => <pages.OrderGroupViewPage {...props} type="report" />} />
          <PrivateRoute path="/orderGroups/create" Component={(props) => <pages.OrderGroupPage {...props} mode="create" />} />
          <PrivateRoute path="/manageOrderGroups" Component={pages.OrderGroupsPage} />

          <PrivateRoute path="/orderTypes/:id/edit" Component={(props) => <pages.OrderTypePage {...props} mode="edit" />} />
          <PrivateRoute path="/orderTypes/create" Component={(props) => <pages.OrderTypePage {...props} mode="create" />} />
          <PrivateRoute path="/orderTypes/:id" Component={pages.OrderTypeViewPage} />
          <PrivateRoute path="/manageOrderTypes" Component={pages.OrderTypesPage} />

          <PrivateRoute path="/attributesWidgets/create" Component={(props) => <pages.AttributesWidget {...props} fullscreen />} />
          <PrivateRoute path="/attributesWidgets/:id" Component={(props) => <pages.AttributesWidget {...props} fullscreen />} />
          <PrivateRoute path="/attributesWidgets" Component={(props) => <pages.AttributesWidgets {...props} />} />

          <PrivateRoute path="/widgets/create" Component={(props) => <pages.Widget {...props} />} />
          <PrivateRoute path="/widgets/:id" Component={(props) => <pages.Widget {...props} />} />
          <PrivateRoute path="/widgets" Component={(props) => <pages.Widgets {...props} />} />

          <PrivateRoute path="/dashboards/create" Component={(props) => <pages.Dashboard {...props} mode="create" />} />
          <PrivateRoute path="/dashboards/:id/edit" Component={(props) => <pages.Dashboard {...props} mode="edit" />} />
          <PrivateRoute path="/dashboards/:id/view" Component={(props) => <pages.Dashboard {...props} mode="view" />} />
          <PrivateRoute path="/dashboards" Component={(props) => <pages.Dashboards {...props} />} />

          <PrivateRoute path="/organisations/:entityId/settings" Component={(props) => <pages.EntitySettingsPage entityType="organisation" {...props} />} />
          <PrivateRoute path="/organisations/:entityId/calendar" Component={(props) => <pages.EntityCalendarPage {...props} />} />
          <PrivateRoute path="/organisations/:entityId/edit" Component={(props) => <pages.EntityPage {...props} entityType="organisation" mode="edit" />} />
          <PrivateRoute path="/organisations/create" Component={(props) => <pages.EntityPage {...props} entityType="organisation" mode="create" />} />
          <PrivateRoute path="/organisations/:entityId" Component={(props) => <pages.EntityPage {...props} entityType="organisation" mode="view" />} />
          <PrivateRoute path="/organisations" Component={(props) => <pages.EntitiesPage {...props} entityType="organisation" />} />

          <PrivateRoute path="/userGroups/:entityId/settings" Component={(props) => <pages.EntitySettingsPage entityType="user_group" {...props} />} />
          <PrivateRoute path="/userGroups/:entityId/calendar" Component={(props) => <pages.EntityCalendarPage {...props} />} />
          <PrivateRoute path="/userGroups/:entityId/edit" Component={(props) => <pages.EntityPage {...props} entityType="user_group" mode="edit" />} />
          <PrivateRoute path="/userGroups/create" Component={(props) => <pages.EntityPage {...props} entityType="user_group" mode="create" />} />
          <PrivateRoute path="/userGroups/:entityId" Component={(props) => <pages.EntityPage {...props} entityType="user_group" mode="view" />} />
          <PrivateRoute path="/userGroups" Component={(props) => <pages.EntitiesPage {...props} entityType="user_group" />} />

          <PrivateRoute path="/overviewObjects/clone/:id" Component={(props) => <pages.CustomReportPage {...props} mode="clone" type="overview_object" />} />
          <PrivateRoute path="/overviewObjects/:id/edit" Component={(props) => <pages.CustomReportPage {...props} mode="edit" type="overview_object" />} />
          <PrivateRoute path="/overviewObjects/create" Component={(props) => <pages.CustomReportPage {...props} mode="create" type="overview_object" />} />
          <PrivateRoute path="/overviewObjects" Component={pages.OverviewObjects} />

          <PrivateRoute path="/flows/:id/:flowVersion/overviewTabs" Component={(props) => <pages.FlowOverviewTabsPage {...props} mode="edit" />} />
          <PrivateRoute path="/flows/:id/:flowVersion/processOverviewObjects" Component={(props) => <pages.ProcessOverviewObjectsPage {...props} mode="edit" />} />
          <PrivateRoute path="/flows" Component={pages.FlowsPage} />

          <PrivateRoute path="/users/:userId/calendar/edit" Component={(props) => <pages.UserCalendarPage {...props} mode="edit" />} />
          <PrivateRoute path="/users/:userId/calendar" Component={(props) => <pages.UserCalendarPage {...props} mode="view" />} />
          <PrivateRoute path="/users/:userId/settings" Component={(props) => <pages.UserSettingsPage {...props} mode="view" />} />
          <PrivateRoute path="/users/:userId/edit" Component={(props) => <pages.UserPage {...props} mode="edit" />} />
          <PrivateRoute path="/users/:userId/changeStatus" Component={(props) => <pages.UserPage {...props} mode="delete" />} />
          <PrivateRoute path="/users/:userId/my-account" Component={(props) => <pages.UserPage {...props} mode="view" />} />
          <PrivateRoute path="/users/create" Component={(props) => <pages.UserPage {...props} mode="create" />} />
          <PrivateRoute path="/users/:userId" Component={(props) => <pages.UserPage {...props} mode="view" />} />
          <PrivateRoute path="/users" Component={pages.UsersPage} />

          <PrivateRoute path="/right-templates/:shortName/edit" Component={(props) => <pages.RightTemplatePage {...props} mode="edit" />} />
          <PrivateRoute path="/right-templates/create" Component={(props) => <pages.RightTemplatePage {...props} mode="create" />} />
          <PrivateRoute path="/right-templates/:shortName" Component={(props) => <pages.RightTemplatePage {...props} mode="view" />} />
          <PrivateRoute path="/right-templates" Component={pages.RightTemplatesPage} />

          <PrivateRoute path="/egsBilling" Component={pages.EgsBilling} />
          <PrivateRoute path="/debug-view" Component={() => <pages.WindowOpen location={`${config.getApiUrl()}/config/#/debug-view`} />} />
          <PrivateRoute path="/logs/:logId" Component={pages.LogPage} />
          <PrivateRoute path="/logs" Component={pages.UsersPage} />
          <PrivateRoute path="/calendar" Component={pages.CalendarPage} />
          <PrivateRoute path="/snapshots/:orderId" Component={pages.SnapshotOverview} />

          <PrivateRoute path="/to/:url" Component={pages.RedirectComponent} />
          <PrivateRoute path="/redirectToEntity/:id" Component={(props) => <pages.RedirectComponent {...props} type="entity" />} />

          <PrivateRoute path="/myProfile/edit" Component={(props) => <pages.MyProfile {...props} mode="edit" />} />
          <PrivateRoute path="/myProfile/delete" Component={(props) => <pages.MyProfile {...props} mode="changeStatus" />} />
          <PrivateRoute path="/myProfile" Component={(props) => <pages.MyProfile {...props} />} />

          <PrivateRoute exact path="/" Component={pages.HomePage} />

          <PublicRoute path="/login" Component={pages.LoginPage} />
          <PublicRoute path="/forgot-password" Component={pages.ForgotPasswordPage} />
          <PublicRoute path="/set-password/:token" Component={pages.ResetPasswordPage} />
          <PublicRoute path="/register" Component={pages.SignUpSelectionPage} />
          <PublicRoute path="/sign-up/:entityName" Component={pages.SignUpPage} />
          <PublicRoute path="/successfulRegistration/:entityName" Component={pages.SuccessMessage} />
          <PublicRoute path="/custom-system-env" Component={pages.CustomSystemEnv} />
          <PublicRoute path="*" Component={pages.NotFoundPage} />
        </Switch>
      </LastLocationProvider>
    </HashRouter>
  )
}

export default routes
