import * as types from '../types';
import objectAssign from 'object-assign';
import { hashHistory } from '../../HistoryProvider';
import { showMessage } from '../../../utils/appHelper';
import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function createOrderGroup(params) {
  return function () {
    return api.post('/orderGroups',{ ...params })
    .then(
      response => {
      if(response.status === 201){
          setTimeout(()=>{
            showMessage('success', __('Order Group created successfully'));
            },
            200
          );
          
          hashHistory.push('/manageOrderGroups');
        }
      }
    );
  };
}

export function updateOrderGroup(params) {
  return function () {
    let payload = objectAssign({}, {...params});
    return api.put('/orderGroups/'+params.id,{...payload})
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Order Group updated successfully'));
            },
            200
          );
          hashHistory.push('/manageOrderGroups');
        }
      }
    );
  };
}

export function deleteOrderGroup(id) {
  return function (dispatch) {
    return api.delete('/orderGroups/'+id, {
    })
    .then(
      response => {       
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Order Group deleted successfully'));
            },
            200
          );
        } 
        dispatch({
          type: types.ORDERGROUP_DELETED,
          payload: response.data
        });
      }
    );
  };
}

export function getOrderGroup(key) {
  return function (dispatch) {
    return api.get('/orderGroups/'+key)
    .then(
      response => {
        dispatch({
          type: types.ORDERGROUP_GOT_DATA,
          payload: response.data
        });
      },
      error => {
        dispatch({
          type: types.ORDERGROUP_GET_DATA_ERROR,
          payload: error.response.data.message
        });
      },
    );
  };
}
