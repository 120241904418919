import appStoreImg from "../images/app-store.png";
import googlePlayImg from "../images/google-play.png";

export const appStore = {
  src: appStoreImg,
  href: "https://apps.apple.com/us/app/godoo/id1555212666/",
  target: "_blank"
}

export const googlePlay = {
  src: googlePlayImg,
  href: "https://play.google.com/store/apps/details?id=com.eglobe.godoo.droid",
  target: "_blank"
}
