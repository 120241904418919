import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import _ from "lodash";

import ConditionalWrapper from "../../../../../../ConditionalWrapper";
import * as C from "../../../ObjectSelector/constants";
import { SmartFooter } from "../../../../../../SmartFooter";
import { SmartTable } from "../../../../../../UI";
import { __ } from '../../../../../../../utils/translationUtils'


export const TableSelector = (props) => {
  const {
    wrapperProps,
    value = [],
    onChange,
    objectConfiguration = {},
    selectionMode,
    objectTypeConfiguration,
    attributesTree,
    missingConfigurationMessage = 'Missing configuration',
    getCheckboxProps,
    parentObjectTypeId,
    parentObjectId,
    objectTypeId,
    attributeId,
    mappedBy,
    ...tableSelectorProps
  } = props

  const {
    containerRef: externalContainerRef,
    type = C.wrapperType.DEFAULT,
    visible,
    onCancel,
    placement,
  } = wrapperProps || {}

  const { methods } = tableSelectorProps || {}

  const {
    addRelations,
    removeRelations,
    fetchRemoteTableData
  } = methods || {}

  const [loading, setLoading] = useState(false)
  const containerRef = useRef(null)
  const [currentSelectedItems, setCurrentSelectedItems] = useState([])
  const [removed, setRemoved] = useState([])
  const [added, setAdded] = useState([])
  const selectionRefreshRef = useRef(null)
  const objectName = useMemo(() => (
    objectConfiguration?.systemId &&
    attributesTree?.[objectConfiguration.systemId]?.find((item) => item.objectTypeId === objectConfiguration.objectTypeId)?.label
  ), [attributesTree, objectConfiguration])

  useEffect(() => {
    if (!_.isEqual(value, currentSelectedItems)) {
      const newSelectedItems = value.concat(added.filter((item) => !value.includes(item))).filter((item) => !removed.includes(item))
      setCurrentSelectedItems(newSelectedItems)
    }
  }, [value])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    if (mappedBy && parentObjectTypeId && parentObjectId && attributeId) {
      const parentObj = { objectTypeId: parentObjectTypeId, mainKey: parentObjectId }

      added?.length > 0 && await addRelations(parentObj, { childObject: attributeId, childObjectIds: added })
      removed?.length > 0 && await removeRelations(parentObj, { childObject: attributeId, childObjectIds: removed })
      setAdded([])
      setRemoved([])
    }
    onChange?.(currentSelectedItems)
    onCancel?.()
    setLoading(false)
  }, [value, onChange, onCancel, currentSelectedItems, added, removed])

  /* Loads the selected values for each current page (only for MAPPED_BY) */
  const onTableDataChange = useCallback((data, value) => {
    if (!mappedBy || !parentObjectId || !objectTypeId || !objectConfiguration || !data) return
    fetchRemoteTableData?.({
      objectTypeId,
      payload: { ...objectConfiguration, searchAttributes: [], sortAttributes: [] },
      optionalProps: {
        ids: data.map(({ id }) => id).join(','),
        mappedBy: mappedBy,
        parentObjectId: parentObjectId,
      }
    }).then((res) => {
      const nextSelected = res.data?.map(({ id }) => id.toString())
      onChange?.([...value].concat(nextSelected?.filter((item) => value.indexOf(item) < 0)))
    })
  }, [objectTypeId, mappedBy, parentObjectId, objectConfiguration])


  const onSelect = useCallback((rows, checked) => {
    setCurrentSelectedItems?.((prev) => {
      let newAdded
      let newRemoved
      switch (selectionMode) {
        case 'radio':
          if (checked) {
            newAdded = rows
            if (!removed.length && prev.length) {
              newRemoved = prev
            }
            if (removed.length && removed.includes(rows[0])) {
              newRemoved = []
              newAdded = []
            }

            newAdded && setAdded(newAdded)
            newRemoved && setRemoved(newRemoved)
          }
          return rows
        case 'checkbox':
          if (checked) {
            setAdded((prev) => [...prev, ...rows.filter((id) => !removed.includes(id))])
            setRemoved((prev) => prev.filter((id) => !rows.includes(id)))
            return [...prev].concat(rows.filter((item) => prev.indexOf(item) < 0))
          } else {

            setRemoved((prev) => [...prev, ...rows.filter((id) => !added.includes(id))])
            setAdded((prev) => prev.filter((id) => !rows.includes(id)))
            return prev.filter((row) => !rows.includes(row))
          }
        default:
          return []
      }
    })
  }, [selectionMode, added, removed])

  const footer = useMemo(() => {
    return (
      <SmartFooter
        onReset={onCancel}
        resetLabel={__('Cancel')}
        onSubmit={onSubmit}
        submitLabel={__('Save')}
      />
    )
  }, [onCancel, onSubmit])

  return (
    <ConditionalWrapper
      type={type}
      title={__(`${objectName} selection`)}
      size={'large'}
      fixedheigth={true}
      centered
      placement={placement}
      visible={visible}
      onSubmitCallback={onSubmit}
      onCancel={() => {
        onCancel?.()
        setCurrentSelectedItems(value || [])
      }}
      footer={footer}
      containerRef={externalContainerRef || containerRef}
    >
      {!objectConfiguration
        ?
        missingConfigurationMessage
        :
        <>
          <SmartTable
            {...tableSelectorProps}
            loading={loading}
            value={value}
            methods={{
              ...methods,
              onTableDataChange,
              setLoading,
            }}
            refreshRef={selectionRefreshRef}
            containerRef={externalContainerRef || containerRef}
            objectConfiguration={objectConfiguration}
            rowSelection={
              selectionMode && {
                type: selectionMode,
                selectedRows: currentSelectedItems,
                onSelect,
                getCheckboxProps
              }}
          />
        </>
      }

    </ConditionalWrapper>
  )
}

export default TableSelector
