import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {parse} from 'query-string'
import {Authentication as Auth} from 'components'
import {loginActionCreator, loginErrorActionCreator} from '../../providers/ReduxProvider/actions/userActions';
import {fetchAvailableEntities} from "../../providers/ApiProvider";
import useInit from "../../hooks/useInit";
import {appStore, googlePlay} from "../../utils/imgUtils";
import {__, T} from '../../utils/translationUtils'
import getErrorText from "../../utils/getErrorText";

const textCommons = {T, __, getErrorText}

const LoginPage = (props) => {
  const {login, loginError, editLoginError, location} = props

  const [showSignUp, setShowSignUp] = useState(null)
  const mounted = useInit()

  useEffect(() => {
    editLoginError(null)
    fetchAvailableEntities().then((entities) => mounted.current && setShowSignUp(entities?.length > 0))
  }, [])

  const handleSubmit = (credentials) => {
    const queryString = parse(location.search)
    login({
      credentials,
      nextUrl: queryString.redirect || '/'
    })
  }

  return <Auth.LoginForm
    {...
      {
        loginError,
        handleSubmit,
        showSignUp,
        apps: [appStore, googlePlay],
        textCommons,
        queryString: location.search
      }} />
}

export default connect(
  (state) => {
    return {
      loginError: state.errors.loginError
    }
  },
  {login: loginActionCreator, editLoginError: loginErrorActionCreator}
)(LoginPage)

LoginPage.propTypes = {
  login: PropTypes.func,
  loginError: PropTypes.string,
  location: PropTypes.object
}
