import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {Space} from "antd";
import {ControlOutlined, SortAscendingOutlined, TableOutlined} from "@ant-design/icons";
import {BatDrawer, BatModal, CheckboxList, Table, Tooltip} from "../index";
import {SearchForm, SortForm} from "./Header";

import {SmartFooter} from "../../SmartFooter"
import {getDisplayAttributesItems} from "../../../utils/displayAttributeHelper"
import {getFormEditedFields} from "../../../utils/appHelper"
import * as C from './constants'
import * as U from "./utils"
import * as S from '../../../providers/StyleProvider/styles'

import _ from "lodash"
import {ERROR} from 'components/dist/Utils/LoggerUtils';
import {__} from '../../../utils/translationUtils'

export default (props) => {
  const {
    value,
    showAll,
    methods = {},
    objectConfiguration = {},
    fixedPagination,
    rowSelection,
    loading,
    containerRef,
    refreshRef,
    attributesList,
    enumerations,
    wrapperType = C.wrapperType.MODAL,
    openViewDetails,
    rowActions = [],
    customHeader
  } = props

  const {
    fetchTableData,
    onTableDataChange,
    updateEnumerations,
    onSearchCallback,
    setLoading,
  } = methods

  const [init, setInit] = useState(false)

  const displayableAttributesItems = useMemo(() => getDisplayAttributesItems(objectConfiguration.displayAttributes, attributesList), [attributesList, objectConfiguration])
  const searchInitialValues = useMemo(() => U.getSearchInitialValues(objectConfiguration.searchAttributes), [objectConfiguration])
  const sortInitialValue = useMemo(() => objectConfiguration.sortAttributes, [objectConfiguration])
  const [data, setData] = useState(undefined)
  const [tableData, setTableData] = useState([])
  const [searchOpen, setSearchOpen] = useState(false)
  const [sortOpen, setSortOpen] = useState(false)
  const [submittedSearchValues, setSubmittedSearchValues] = useState(searchInitialValues)
  const totSearchedValues = useMemo(() => Object.entries(submittedSearchValues).filter((item) => item[1] && (!Array.isArray(item[1]) || item[1].length) && (!typeof item[1] === 'object' || Object.keys(item[1]).length)).length, [submittedSearchValues])
  const [currentSortValue, setCurrentSortValue] = useState(sortInitialValue)
  const [submittedSortValue, setSubmittedSortValue] = useState(sortInitialValue)
  const totSortedValues = useMemo(() => submittedSortValue.filter((item) => item.enabled).length, [submittedSortValue])
  const [selectedAttributesId, setSelectedAttributesId] = useState(displayableAttributesItems.map((a) => (a.value)))
  const [pagination, setPagination] = useState({
    ...C.defaultPagination,
    ...(fixedPagination ? {
      pageSize: fixedPagination,
      pageSizeOptions: [fixedPagination],
      showSizeChanger: false,
    } : {})
  })

  const searchFormRef = useRef(null)

  useEffect(() => {
    setSubmittedSearchValues(U.getSearchInitialValues(objectConfiguration?.searchAttributes))
    getDataHandler({
      value,
      submittedSearchValues: U.getSearchInitialValues(objectConfiguration?.searchAttributes),
      pagination: {...pagination, current: 1}
    })
  }, [objectConfiguration])

  useEffect(() => {
    if (!init) {
      if (searchFormRef?.current) {
        searchFormRef.current.resetFields()
        setSubmittedSearchValues(searchInitialValues)
      }
      getDataHandler({value, submittedSearchValues: searchInitialValues, pagination: {...pagination, current: 1}})
      setInit(true)
    }
  }, [])

  const tableColumns = useMemo(() => {
    const selectedAttributes = displayableAttributesItems.filter((a) => selectedAttributesId.includes(a.value))
    return U.colsItemsMappingMethod(selectedAttributes, {enableSelect: true})
  }, [displayableAttributesItems, selectedAttributesId])

  useEffect(() => {
    data && onTableDataChange?.(data, value)
  }, [data])

  const getDataHandler = useCallback(async (props = {}) => {
    setLoading(true)
    const currentPagination = props.pagination || pagination
    const displayAttributes = objectConfiguration.displayAttributes.map(({
                                                                           canAddRelationDuringCreate,
                                                                           canAddRelationDuringEdit,
                                                                           canRemoveRelationDuringEdit,
                                                                           ...propsForPayload
                                                                         }) => (propsForPayload))
    const payload = U.getPayloadForDataFetch({
      searchValues: props.submittedSearchValues || submittedSearchValues,
      sortValues: props.submittedSortValues || submittedSortValue,
      currentPagination,
      dataStructures: {objectConfiguration: {...objectConfiguration, displayAttributes}, attributesList},
    })

    if (!Object.keys(payload).length || !fetchTableData) {
      setLoading(false)
      return
    }

    let ids;
    if (!showAll) {
      const selectedItems = props?.value
      ids = !rowSelection && !showAll && objectConfiguration?.showOnlySelected && !!selectedItems?.length && selectedItems.join()
      if (!rowSelection && !showAll && objectConfiguration?.showOnlySelected && ids === false) {
        setData([])
        setTableData([])
        setLoading(false)
        return
      }
    }

    fetchTableData?.(payload, ids).then((resp) => {
      setPagination({
        ...C.defaultPagination,
        ...currentPagination,
        total: parseInt(resp?.headers['x-total-count']) || 0,
        pageSize: parseInt(resp?.headers['x-page-size']) || 25,
        current: parseInt(resp?.headers['x-page']) || 1,
      })
      const newData = U.prepareRemoteObjectsResult(resp?.data, attributesList, objectConfiguration)
      setData(newData);
      setTableData(U.dataMappingMethod(newData, attributesList));
    })
      .catch((err) => {
        ERROR(err)
      }).finally(() => {
      setLoading(false)
    })
  }, [submittedSearchValues, submittedSortValue, objectConfiguration, attributesList, showAll, rowSelection])

  const onTableChange = (pagination, filters, sorter) => {
    getDataHandler({value, pagination})
  }

  const onSearchSubmit = useCallback((e) => {
    if (searchFormRef?.current) {
      const values = searchFormRef.current.getFieldsValue()
      setSubmittedSearchValues(values)
      setSearchOpen(false)
      setPagination({...pagination, current: 1})
      onSearchCallback?.(values)
      getDataHandler({value, submittedSearchValues: values, pagination: {...pagination, current: 1}})
    }
  }, [getDataHandler, value, pagination])

  const onSearchReset = useCallback((e) => searchFormRef?.current?.resetFields(), [])

  const onSearchClose = useCallback(() => {
    const toReset = getFormEditedFields({
      submittedFormValues: submittedSearchValues,
      initialValues: searchInitialValues,
      actualFormValues: searchFormRef?.current?.getFieldsValue() || {},
    })
    if (searchFormRef?.current && toReset) {
      searchFormRef?.current?.setFieldsValue(toReset)
    }
  }, [searchInitialValues, submittedSearchValues])

  const onSortReset = useCallback(() => {
    if (!_.isEqual(currentSortValue, sortInitialValue)) setCurrentSortValue(sortInitialValue)
  }, [currentSortValue, sortInitialValue])

  const onSortClose = useCallback(() => {
    if (!_.isEqual(currentSortValue, submittedSortValue)) setCurrentSortValue(submittedSortValue)
  }, [currentSortValue, submittedSortValue])

  const onSortSubmit = useCallback(() => {
    setSubmittedSortValue(currentSortValue)
    setSortOpen(false)
    setPagination({...pagination, current: 1})
    getDataHandler({value, submittedSortValues: currentSortValue, pagination: {...pagination, current: 1}})
  }, [getDataHandler, value, pagination, currentSortValue])

  useEffect(() => {
    if (refreshRef) refreshRef.current = (v) => getDataHandler({value: v || value, pagination})
  }, [getDataHandler, refreshRef, value, pagination])

  const commonProps = {wrapperType, containerRef}

  return (
    <>
      {loading && <S.OverlayFullSpaceSpin/>}
      <S.HeaderSection>
        <Space>
          <ConditionalOverlay
            disabled={!objectConfiguration?.searchAttributes?.length}
            buttonBadgeCount={totSearchedValues}
            buttonProps={{icon: <ControlOutlined/>}}
            title={__('Filters')}
            width={700}
            setForceOpen={(state) => {
              setSearchOpen(state)
            }}
            forceOpen={searchOpen}
            onSubmitCallback={onSearchSubmit}
            onCloseCallback={onSearchClose}
            footer={<SmartFooter onSubmit={onSearchSubmit} onReset={onSearchReset}/>}
            {...commonProps}
          >
            <SearchForm
              initialValues={searchInitialValues}
              fields={objectConfiguration.searchAttributes}
              attributeList={attributesList}
              formRef={searchFormRef}
              enumerations={enumerations}
              updateEnumerations={updateEnumerations}
            />
          </ConditionalOverlay>
          <ConditionalOverlay
            disabled={!objectConfiguration?.sortAttributes?.length}
            buttonBadgeCount={totSortedValues}
            buttonProps={{icon: <SortAscendingOutlined/>}}
            title={__('Sorting')}
            width={700}
            footer={<SmartFooter onSubmit={onSortSubmit} onReset={onSortReset}/>}
            setForceOpen={(state) => {
              setSortOpen(state)
            }}
            onSubmitCallback={onSortSubmit}
            onCloseCallback={onSortClose}
            forceOpen={sortOpen}
            {...commonProps}
          >
            <SortForm
              value={currentSortValue}
              onChange={setCurrentSortValue}
              attributesList={attributesList}
            />
          </ConditionalOverlay>
          <Tooltip
            buttonProps={{
              title: __('Columns'),
              icon: <TableOutlined/>,
            }}
          >
            <CheckboxList
              items={displayableAttributesItems}
              value={selectedAttributesId}
              onChange={setSelectedAttributesId}
            />
          </Tooltip>
        </Space>
        {customHeader}
      </S.HeaderSection>
      <Table
        data={tableData}
        columns={tableColumns}
        openViewDetails={openViewDetails}
        actions={rowActions}
        pagination={pagination}
        onTableChange={onTableChange}
        rowSelection={rowSelection}
        fitParent
      />
    </>
  )
}

const ConditionalOverlay = (props) => {

  const {
    wrapperType,
    children,
    ...wrapperProps
  } = props

  switch (wrapperType) {
    case C.wrapperType.DRAWER:
      return (
        <BatDrawer {...wrapperProps}>
          {children}
        </BatDrawer>
      )
    case C.wrapperType.MODAL:
      return (
        <BatModal {...wrapperProps}>
          {children}
        </BatModal>
      )
    default:
      return (
        <>
          {children}
        </>
      )
  }
}
