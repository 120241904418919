export const mechanism = {
  SPECIFIC_VALUE: 'SPECIFIC_VALUE',
  RANGE: 'RANGE',
  ENUMERATION: 'ENUMERATION'
}
export const sortMethod = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC'
}
export const visibleFor = {
  MYSELF: 'SELF',
  GLOBAL: 'GLOBAL',
  SPECIFIC_ENTITIES: 'ENTITY'
}
export const layout = {
  TABLE: 'TABLE',
  CARD: 'CARD',
  MAP: 'HEATMAP'
}
export const features = {
  ORDER_TYPE: 'ORDER_TYPE',
  DEADLINE: 'DEADLINE',
  PRIORITY: 'PRIORITY',
  MULTIPLE_SELECTION: 'MULTIPLE_SELECTION',
}

