import React from 'react'
import {Icon} from '@ant-design/compatible'
import {TreeSelect} from "components";

const { Node } = TreeSelect
const {COMPANY, ENTITY, USER} = global.constants.entityTypes

export const getCompaniesTree = (companies, onlyLeafs = false) => {
  return companies?.map(company => ({
    ...company,
    totUsers: company.children.reduce((acc, entity) => {
      if (!entity?.users.length) return acc;
      return Number(acc + entity.users.length)
    }, 0)
  }))
    .filter(company => company.totUsers !== 0)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(company => ({
      title: (
        <Node>
          <Icon type="cluster"/> {company.name} - {company.shortName} ({company.totUsers})
        </Node>
      ),
      renderRootTitle: ({ name, shortName, selected, total }) => (
        <Node>
          <Icon type="cluster" />
          {`${name} - ${shortName} (${selected}/${total})`}
        </Node>
      ),
      name: company.name,
      shortName: company.shortName,
      totUsers: company.totUsers,
      searchValue: `${company.name} (${company.shortName})`,
      value: JSON.stringify({ type: COMPANY, entityId: company.id }),
      disableCheckbox: true,
      selectable: false,
      isLeaf: company.totUsers === 0,
      children: company.children?.map((entity) => ({
        title: (
          <Node>
            <Icon type={entity.type === 'organisation' ? 'cluster' : 'team'}/> {entity.name} ({entity.users?.length})
          </Node>
        ),
        searchValue: entity.name,
        value: JSON.stringify({ type: ENTITY, entityId: entity.id }),
        disabled: onlyLeafs,
        children: entity.users?.map((user) => ({
          title: (
            <Node>
              <Icon type="user"/> {user.firstName} {user.lastName} - {user.username} ({entity.shortName})
            </Node>
          ),
          searchValue: `${user.firstName} ${user.lastName} (${user.username})`,
          value: JSON.stringify({ type: USER, userId: user.id, entityId: entity.id })
        }))
      }))
    }))
}
