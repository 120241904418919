import React, {useEffect, useMemo, useState} from 'react'

import { useSelector } from 'react-redux'

import * as U from './utils'
import TopMenuItem from './components/TopMenuItem'
import {getMenu} from "./config";
import {UseUsersDashboardsQuery} from "../../providers/QueryClientProvider/queries";
import {fetchDashboards} from "../../providers/ApiProvider";

const Menu = (props) => {
  const { location } = props
  const {
    data: guiUser,
    orderGroups
  } = useSelector((state) => state?.user)

  const {data: userDashboards, isSuccess} = UseUsersDashboardsQuery(guiUser?.id)

  const [pinnedDashboards, setPinnedDashboards] = useState(undefined)

  useEffect(() => {
    if (isSuccess) {
      const allPinned = [...(new Set([
        ...userDashboards?.personalDashboards?.pinnedDashboardIds || [],
        ...userDashboards?.entityDashboards?.flatMap(entity => entity.pinnedDashboardIds) || []
      ]))]

      if (allPinned.length) {
        return fetchDashboards({ pageSize: 50, filter: { name: 'id', value: allPinned.join() } })
          .then((res) => res.data)
          .then((dashboards) => {
            const dashboardsMap = dashboards?.reduce((prev, dashboard) => ({ ...prev, [dashboard.id]: dashboard }), {})

            setPinnedDashboards({
              user: (userDashboards?.personalDashboards?.pinnedDashboardIds || []).reduce((acc, curr) => {
                const dashboard = dashboardsMap[curr]
                if(dashboard) acc.push({ text: dashboard.name, path: `/dashboards/${dashboard.id}/view` })
                return acc
              }, []),
              ...((userDashboards?.entityDashboards || []).reduce((acc, { entity, pinnedDashboardIds }) => {

                const dashboards = pinnedDashboardIds.reduce((acc, curr) => {
                  const dashboard = dashboardsMap[curr]
                  if(dashboard) acc.push({ text: dashboard.name, path: `/dashboards/${dashboard.id}/view` })
                  return acc
                }, [])

                acc[entity.type] = [...(acc[entity.type] || []), ...dashboards]

                return acc
              }, {}))
            })
          })
      }
    }
  }, [isSuccess, userDashboards])

  const menu = useMemo(() => {
    const menuConfig = getMenu(orderGroups, pinnedDashboards, guiUser)
    return U.getDisplayableItems(menuConfig, guiUser)
  }, [orderGroups, pinnedDashboards, guiUser])

  const displayableAndActiveMenu = useMemo(() => (
    U.getItemsWithActiveFlag(menu, location)
  ), [menu, location])

  return (
    <ul className="row">
      {
        guiUser &&
        displayableAndActiveMenu.map((item, i) => (
          <TopMenuItem
            key={`${i}-${item.text}`}
            item={item}
          />
        ))
      }
    </ul>
  )
}

export default Menu
