import React from "react";
import _ from "lodash";

import { Panel } from "react-bootstrap";
import { Skeleton } from "antd";
import { consolidateControllerValue } from "./Step/StepBeta/NormalStep/payload";
import { Button } from "components";
import { godooDefPalette } from "components/src/Form/Inputs/ColorPicker/utils";
import { __ } from '../../utils/translationUtils'
import * as config from "../../constants/globalConfiguration";


export const hasUnsupportedController = (step) => {
	const unsupportedControllers = ['Billing', 'BillingController']
	return step?.fields.some((f) => unsupportedControllers.includes(f.controllerType))
}

export const isBetaStep = (step) => {
	return [
    config.NORMAL_STEP_DISCRIMINATOR.toString(),
    config.ASSIGNMENT_STEP_DISCRIMINATOR.toString(),
    config.REASSIGNMENT_STEP_DISCRIMINATOR.toString()
  ].includes(step.discriminator) && !hasUnsupportedController(step)
}

export const isNormalStep = (step) => step.discriminator === config.NORMAL_STEP_DISCRIMINATOR.toString()
export const isAssignmentStep = (step) =>
  [
    config.ASSIGNMENT_STEP_DISCRIMINATOR.toString(),
    config.REASSIGNMENT_STEP_DISCRIMINATOR.toString()
  ].includes(step.discriminator)

export const filesController = ['PhotoCollection', 'CustomPhotoCollection', 'RepresentativeSignature', 'FileUpload', 'SimpleSignature', 'MediaViewer', 'Signature', 'FileUploader']

const getColorValue = (label, items = godooDefPalette) => {
  if (label && items) return items.find((i) => i.title.toLowerCase() === label)?.color || label
  return label
}

export const getStepButtons = (buttons, parent) => {
  const { activeStepObject: step, stepLoading } = parent.state || {}

  const buttonGroups = _.groupBy(buttons, (b) => b.group.title)

  return Object.entries(buttonGroups).map(([groupId, buttonGroup]) => {

    const button = buttonGroup[0]
    const position = ((!button.group.alignment) || button.group.alignment === 'LEFT') ? 'pull-left' : 'pull-right'
    const backgroundColor = getColorValue(button.group.styleClass || '#fff')

    if (buttonGroup.length === 1) {
      return (
        <Button
          title={__(button.name)}
          size={'large'}
          disabled={stepLoading}
          backgroundColor={backgroundColor}
          key={button.id}
          type='filled'
          className={position}
          onClick={parent.handleButtonClick.bind(parent, button, step)}
        />
      )
    }
    else {
      let options = buttonGroup
      options = _.sortBy(options, (b) => -b.group.position)
      const buttons = []
      options.forEach((button, i) => {
        buttons.push({
          title: __(button.name),
          eventKey: button.name,
          key: i,
          onClick: parent.handleButtonClick.bind(parent, button, step)
        })
      })

      return (
        <Button
          title={__(groupId)}
          size={'large'}
          type='filled'
          disabled={stepLoading}
          id={`dropdown-basic-${groupId}`}
          key={`step-btn-${groupId}`}
          backgroundColor={backgroundColor}
          items={buttons}
          className={position}
          onClick={parent.handleButtonClick.bind(parent, button, step)}
        />
      )
    }
  })
}

export const LoadingSkeleton = () => (
  <div className="container">
    <Panel className="order-page">
      <Panel.Heading>{__('loading')}</Panel.Heading>
      <Panel.Body>
        <Skeleton active title={false} paragraph={{ rows: 12, style: { marginBottom: '15vh', marginTop: '5vh' } }} />
      </Panel.Body>
    </Panel>
  </div>
)

const hasDataInterfaceInSameStep = (dataInterface, currentStepPosition) => (
  dataInterface?.discriminator === '3' &&
  dataInterface?.stepPosition === currentStepPosition &&
  dataInterface?.fieldId &&
  !dataInterface.value
)
const getDataInterfacesStepDependencies = (dataInterfaces, currentStepPosition) => {
  return dataInterfaces.reduce((prev, dataInterface) => {
    if (hasDataInterfaceInSameStep(dataInterface, currentStepPosition) && !prev.includes(dataInterface?.fieldId)) {
      prev.push(dataInterface?.fieldId)
    }
    return prev
  }, [])
}
export const getWatchedFieldsId = (field, step) => {
  if (!Object.keys(field)?.length || !Object.keys(step)?.length) return null
  const currentStepPosition = step.position
  const fieldValueDataInterface = field.sourceDataInterfaceForOfflineSupport?.attributes
  const searchAttributesDataInterfaces = field.controllerOptions?.objectConfiguration?.searchAttributes?.reduce((prev, item) => {
    if (item.defaultValue) prev.push(item.defaultValue)
    return prev
  }, []) || []
  const dataInterfaces = [fieldValueDataInterface, ...searchAttributesDataInterfaces]
  const dependencies = getDataInterfacesStepDependencies(dataInterfaces, currentStepPosition)
  return dependencies.length ? dependencies : null
}

const getFieldDIValue = (value, fieldsValue, dataInterface, step) => {
  if (!hasDataInterfaceInSameStep(dataInterface, step.position)) return value
  const pointedFieldId = dataInterface?.fieldId
  const pointedField = step.fields?.find((item) => item.id === pointedFieldId)
  const pointedValue = fieldsValue[pointedFieldId]
  if (pointedField?.controllerOptions?.objectConfiguration) {
    if (!dataInterface?.attributeProps?.attributeId) return pointedValue?.selectedItems
    else return pointedValue?.objectConfiguration?.[dataInterface.attribute + "s"]?.find((item) => item.attributeId === dataInterface?.attributeProps?.attributeId)?.value
  }
  else {
    return consolidateControllerValue(pointedValue, pointedField)
  }
}
const getObjectSelectorDIValue = (value, fieldsValue, field, step) => {
  const newValue = _.cloneDeep(value)
  newValue.selectedItems = getFieldDIValue(value.selectedItems, fieldsValue, field.sourceDataInterfaceForOfflineSupport?.attributes, step)
  if (newValue?.objectConfiguration?.searchAttributes?.length) {
    newValue.objectConfiguration.searchAttributes = newValue.objectConfiguration.searchAttributes.map((item) => {
      const newSearchAttributeValue = getFieldDIValue(item.value, fieldsValue, item.defaultValue, step)
      // TODO Fields pointing to fields with a different type is not managed
      if (Array.isArray(newSearchAttributeValue)) return { ...item, value: newSearchAttributeValue[0] }
      return { ...item, value: newSearchAttributeValue }
    })
  }
  return newValue
}
export const getUpdatedValue = (value, fieldsValue, field, step) => {
  if (!Object.keys(field)?.length || !Object.keys(step)?.length) return value
  if (field?.controllerOptions?.objectConfiguration) {
    return getObjectSelectorDIValue(value, fieldsValue, field, step)
  }
  else {
    return getFieldDIValue(value, fieldsValue, field.sourceDataInterfaceForOfflineSupport?.attributes, step)
  }
}
