import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

class Amount extends React.Component {
  static propTypes = {
    value: PropTypes.number,
    decimalPrecision: PropTypes.number,
    displayType: PropTypes.string,
    className: PropTypes.string,
    prefix: PropTypes.string,
    handleChange: PropTypes.func
  };

  static defaultProps = {
    displayType: 'text',
    prefix: 'CHF',
    decimalPrecision: 2
  };

  render() {
    const { handleChange, prefix, displayType, value, className, decimalPrecision } = this.props
    return (
      <div className={`${className} pull-right`}>
        <NumberFormat
          prefix={`${prefix} `}
          displayType={displayType}
          onChange={(e, val) => {
            handleChange(val)
          }}
          value={value}
          thousandSeparator="'"
          decimalScale={decimalPrecision}
          allowNegative
          fixedDecimalScale
        />
      </div>
    )
  }
}

export default Amount
