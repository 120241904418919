import React, { useCallback, useEffect, useRef, useState } from "react"

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"

import { SmartTable } from "../../../../../../UI"
import { getSearchAttributesFromValues } from "../../../../../../UI/SmartTable/utils"

import { Button } from "components"

import _ from "lodash"
import { Tooltip } from "antd"
import { __ } from '../../../../../../../utils/translationUtils'
import * as C from "../../../ObjectSelector/constants";


export default (props) => {
  const {
    toggleSelectionModal,
    mode,
    rowActions = [],
    onDeselect,
    value,
    onChange,
    objectConfiguration,
    enumerations,
    updateEnumerations,
    attributesList,
    parentObjectId,
    mappedBy,
    fetchRemoteTableData,
    missingConfigurationMessage = 'Missing configuration',
  } = props

  const {
    manualSelection,
  } = objectConfiguration || {}


  const [loading, setLoading] = useState(false)
  const containerRef = useRef(null)
  const selectionRefreshRef = useRef(null)

  useEffect(() => {
    selectionRefreshRef?.current?.()
  }, [value])

  const onSearchCallback = useCallback((searchValues) => {
    if (!searchValues) return
    const newSearchAttributes = getSearchAttributesFromValues(searchValues, objectConfiguration.searchAttributes)
    if (!_.isEqual(newSearchAttributes, objectConfiguration.searchAttributes)) {
      onChange?.({
        selectedItems: value,
        objectConfiguration: {
          ...objectConfiguration,
          searchAttributes: newSearchAttributes
        }
      })
    }
  }, [objectConfiguration, value, onChange])

  return (
    !objectConfiguration
      ?
      missingConfigurationMessage
      :
      <>
        <SmartTable
          loading={loading}
          value={value}
          attributesList={attributesList}
          enumerations={enumerations}
          showAll={mappedBy && parentObjectId}
          methods={{
            fetchTableData: (payload, ids) => fetchRemoteTableData({
              objectTypeId: payload?.objectTypeId,
              payload,
              optionalProps: {
                ...((mappedBy && parentObjectId) ? { mappedBy, parentObjectId } : { ids })
              }
            }),
            updateEnumerations,
            setLoading,
            onSearchCallback,
          }}
          refreshRef={selectionRefreshRef}
          containerRef={containerRef}
          objectConfiguration={objectConfiguration}
          fixedPagination={5}
          rowActions={[
            ...rowActions,
            ...(mode !== C.formMode.VIEW ?
              [{
                value: 'REMOVE',
                icon: <MinusCircleOutlined />,
                onClick: (item) => onDeselect?.(item.id),
                danger: true,
                label: 'Remove',
              }] : [])
          ]}
          customHeader={
            (manualSelection && mode !== C.formMode.VIEW) && (
              <Tooltip title={__('Add Item')}>
                <Button
                  type={'primary'}
                  icon={<PlusOutlined />}
                  onClick={() => toggleSelectionModal(true)}
                  backgroundColor={'#D5E4F7'}
                  disabled={mode === C.formMode.CREATE}
                >
                  {__('Add Item')}
                </Button>
              </Tooltip >
            )
          }
        />
      </>
  )
}
