import React from "react";
import StepComments from "../StepCommentsBeta";

import AssignmentStep from './AssignmentStep'
import NormalStep from './NormalStep'
import {isAssignmentStep, isNormalStep} from "../../utils";


const StepBeta = (props) => {
	const { step, stepRef } = props

	return (
		<>
			{
				isNormalStep(step) && <NormalStep {...props} />
			}
			{
				isAssignmentStep(step) && <AssignmentStep {...props} />
			}
			{
				!window.location.hostname?.toLowerCase()?.replace('-', '')?.includes('beadvanced') &&
				<StepComments containerRef={stepRef} step={step} />
			}
		</>
	)
}

export default StepBeta
