import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import objectAssign from 'object-assign';
import _ from 'lodash';
import { DropdownButton, Glyphicon, MenuItem, Modal, Panel, } from 'react-bootstrap';

import CreateStepObjectButton from '../components/CreateStepObjectButton';
import ReportSearchFieldsForm from '../components/ReportSearchFieldsForm';
import { createRemoteObjectItem, updateRemoteObjectItem } from '../providers/ReduxProvider/actions/remoteObjectActions';
import { addAsterisks, getInitialPageSize, renderResultInfo, setInitialPageSize, } from '../utils/appHelper';
import * as config from '../constants/globalConfiguration';
import moment from "moment";
import { api } from '../providers/ApiProvider'
import { Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import EllipsisText from './Ellipsis';
import { Button } from "components";
import { __ } from '../utils/translationUtils'


class AddStepObjectButton extends React.Component {
  static propTypes = {
    handleAddItems: PropTypes.func,
    guiUser: PropTypes.object,
    configuration: PropTypes.object,
    formValues: PropTypes.object,
    node: PropTypes.object,
    className: PropTypes.string,
    selectedObjectIds: PropTypes.array,
    attributes: PropTypes.array,
    values: PropTypes.array,
    apis: PropTypes.object,
  };

  static defultProps = {
    action: 'edit',
    resultId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRows: props.selectedObjectIds,
      attributesConfiguration: null,
      attributesConfigurationTree: null,
      objectConfiguration: null,
      results: null,
      searchOpen: false,
      showCreateForm: false,
      pagination: {
        'pageNumber': 1,
        'pageSize': getInitialPageSize('addStepObjectList'),
        'totalCount': 0
      },
      modal: {
        open: false,
      },
      isLoading: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.handleSingleSelectRow = this.handleSingleSelectRow.bind(this);
    this.getResult = this.getResult.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAttributesConfiguration = this.getAttributesConfiguration.bind(this);
    this.extractAttributeConfig = this.extractAttributeConfig.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.setPageSize = this.setPageSize.bind(this);
    this.updatedSelectedIds = this.updatedSelectedIds.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
  }

  componentDidMount() {
    this.getAttributesConfiguration();
  }

  getAttributesConfiguration() {
    return api.get('configurations/attributes')
      .then(
        response => {
          this.setState({
            attributesConfiguration: response.data,
          });
        }
      );
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.setPageSize.bind(this)}
        bsStyle="link"
        title={this.state.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config.pageSizeOptionsObjectList.map((val, i) => {
          return <MenuItem key={i} eventKey={val}>{val}</MenuItem>;
        })}
      </DropdownButton>
    );
  }

  onPageChange(page) {
    let spec = { ...this.state.reportSpec };
    spec.pageNumber = page;

    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
        pageNumber: page
      })
    }, () => {
      this.getResult();
    });
  }

  onCreateSubmit(id) {
    this.updatedSelectedIds(id, true);
    this.getResult();
  }

  onSortChange(sortName, sortOrder) {
    let spec = { ...this.state.reportSpec };
    spec.sortAttributes = [{
      attributeId: sortName,
      position: 1,
      value: sortOrder.toUpperCase(),
    }];
    this.getResult();
  }

  setPageSize(val) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
        // totalCount: this.state.results.length,
        pageSize: val,
        pageNumber: 1
      }
      )
    }, () => {
      this.getResult();
      setInitialPageSize('addStepObjectList', val);
    }
    );
  }

  openModal() {
    const { singleRow } = this.props
    this.getResult();
    this.setState({
      selectedRows: singleRow ? this.props.selectedObjectIds : [],
      showCreateForm: false,
      modal: {
        open: true,
      }
    });
  }

  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({
      modal: {
        open: false,
      }
    });
  }

  getResult() {
    const { configuration, formValues, type } = this.props;

    this.setState({
      results: null,
      isLoading: true,
    }, () => {
      let payload = { ...configuration };
      payload.pageSize = this.state.pagination.pageSize;
      payload.pageNumber = this.state.pagination.pageNumber;


      const _values = formValues;
      let values = { ..._values };
      const _attrs = [].concat(configuration.searchAttributes);
      let attrs = [];
      _attrs.forEach((_r) => {
        const r = { ..._r };
        const conf = this.extractAttributeConfig(_r.attributeId);

        // RANGE
        if (r.mechanism === 'RANGE') {
          if (conf.type === 'DATE') {
            if (values[r.attributeId + 'From']) {
              values[r.attributeId + 'From'] = values[r.attributeId + 'From'].utc().format();
            }
            if (values[r.attributeId + 'To']) {
              values[r.attributeId + 'To'] = values[r.attributeId + 'To'].utc().format();
            }
          }

          if (typeof values[r.attributeId + 'From'] !== 'undefined') {
            r.from = values[r.attributeId + 'From'];
          } else {
            delete values[r.attributeId + 'From'];
          }

          if (typeof values[r.attributeId + 'To'] !== 'undefined') {
            r.to = values[r.attributeId + 'To'];
          } else {
            delete values[r.attributeId + 'To'];
          }

          /*
           * Apply Felix's Algorithm on range fields
           * 1)If both *from* and *to* are filled --> normal *range* search.
           * 2) If only *from* is filled --> *specic_value* search. (the from can contain wildcard)
           * 3) if only *to* is filled the webui fills the from value with the same value contained in the *to* --> *range* search (edited)
           */

          // Applies only to RANGE.
          if (r.mechanism === 'RANGE') {
            const { to, from } = r;

            if (from && !to) {
              r.mechanism = 'SPECIFIC_VALUE';
              r.value = from;
              delete r.from;
              delete r.to;
              delete r.multiSelect;
            } else if (!from && to) {
              r.from = to;
            }
          }

          // SPECIFIC_VALUE
        } else if (r.mechanism === 'SPECIFIC_VALUE') {
          if (conf.type === 'DATE' && values[r.attributeId]) {
            values[r.attributeId] = values[r.attributeId].utc().format();
          }

          if (typeof values !== 'undefined' && typeof values[r.attributeId] !== 'undefined' && values[r.attributeId] !== '') {
            // Add asterisks by default
            if (conf.type === 'DATE') {
              r.value = values[r.attributeId];
            } else {
              r.value = addAsterisks(values[r.attributeId]);
            }

            if (r.value === 'true') {
              r.value = true;
            }
            if (r.value === 'false') {
              r.value = false;
            }
          } else {
            delete r.value;
          }
          // ENUMERATION
        } else if (r.mechanism === 'ENUMERATION') {
          const requiresobfuscation = (r.obfuscationMethod != null && r.obfuscationValue != null);
          let _value = [].concat(values[r.attributeId]);
          if (typeof values[r.attributeId] !== 'undefined' && values[r.attributeId].length > 0) {
            // If obfuscationMethod == byTypeEqualsUnionChildren, then select children entities too
            if (requiresobfuscation) {
              switch (r.obfuscationMethod) {
                case 'byTypeEqualsUnionChildren':
                  _value.forEach((parent) => {
                    const found = _.filter(r.values, (v) => v.parent === parent);
                    if (found) {
                      found.forEach((child) => {
                        _value.push(child.id);
                      });
                    }
                  });
                  break;
                default:
              }
            }

            r.value = _value;

            if (_value.constructor === Array) {
              r.value = _value.join(',');
            }
          } else {
            delete r.value;
          }

        }

        if ((values[r.attributeId] == undefined || values[r.attributeId] === '')
          && (values[`${r.attributeId}From`] == undefined || values[`${r.attributeId}From`] === '')
          && (values[`${r.attributeId}To`] == undefined || values[`${r.attributeId}To`] === '')
        ) {
          return
        } else {
          attrs.push(r);
        }

        // remove if empty value
        if (values[r.attributeId] != undefined && values[r.attributeId]?.length === 0) {
          //  if (typeof values[r.fieldId] !== 'undefined' && values[r.fieldId].length === 0) {
          //    const idx = findIndex(attrs, (f) => f.fieldId === r.fieldId);
          const idx = _.findIndex(attrs, (f) => f.attributeId === r.attributeId);
          if (idx > -1) {
            attrs.splice(idx, 1);
          }
        }
      });

      payload.searchAttributes = attrs;
      const defaultFetchTableData = (apiPayload) => api.post(`configurations/${(!['ROE'].includes(type)) ? 'stepObjects' : 'remoteObjects'}/results`, apiPayload)
      const fetchTableData = this.props.apis.fetchTableData || defaultFetchTableData
      let fetchPayload = { ...payload }

      if (type === 'StepObject') {
        const { id, name, type, pageSize, pageNumber, objectTypeId, searchAttributes, displayAttributes, sortAttributes, systemId } = fetchPayload
        fetchPayload = { id, name, type, pageSize, pageNumber, objectTypeId, searchAttributes, displayAttributes, sortAttributes, systemId }
      }

      fetchTableData(fetchPayload, { objectTypeId: configuration.objectTypeId })
        .then((res) => {
          this.setState({
            isLoading: false,
            results: this.fixResultValues(res.data),
            searchOpen: false,
            pagination: objectAssign(
              this.state.pagination, {
              totalCount: parseInt(res.headers['x-total-count']),
              pageSize: parseInt(res.headers['x-page-size']),
              pageNumber: parseInt(res.headers['x-page'])
            }
            )
          });
        })
        .catch(() => { });
    });
  }

  fixResultValues(data) {
    const {
      attributes, configuration
    } = this.props;
    data.forEach(function (part, elementIndex) {
      if (part.displayData) {
        Object.keys(part.displayData).forEach(function (attributeId) {

          let attribute = (attributes.filter(a => a.id === attributeId));
          if (attribute && attribute.length > 0)
            attribute = attribute[0];

          let displayAttribute = _.find(configuration.displayAttributes, (a) => a.attributeId === attributeId);

          if (this[attributeId] !== null && attribute.type === "YESNO") {
            this[attributeId] = this[attributeId].toLowerCase() === 'true' ? __('val_yes') : __('val_no');
          }
          if (displayAttribute.controllerType === "PopupListBox" || displayAttribute.defaultControllerType === "PopupListBox") {
            this[attributeId] = this[attributeId] !== null ? __(this[attributeId]) : this[attributeId];
          }
          if (displayAttribute.controllerType === "DateInput") {
            this[attributeId] = this[attributeId] !== null ? moment(this[attributeId]).format(config.appDefaults.dateFormat) : this[attributeId];
          }
          if (displayAttribute.controllerType === "DateTimeInput") {
            this[attributeId] = this[attributeId] !== null ? moment(this[attributeId]).format(config.appDefaults.dateTimeFormat) : this[attributeId];
          }
        }, part.displayData);
      }
      this[elementIndex] = part;
    }, data);
    return data;
  }

  handleSubmit() {
    const { selectedRows } = this.state;
    this.props.handleAddItems(selectedRows);
    this.closeModal();
  }

  handleRowSelect(row, isSelected) {
    this.updatedSelectedIds(row.id, isSelected);
  }

  updatedSelectedIds(id, isSelected) {
    let _selected = [].concat(this.state.selectedRows);

    if (isSelected) {
      if (!_selected.includes(id)) {
        _selected.push(id);

        this.setState({
          selectedRows: _selected,
        });
      }
    } else {
      this.setState({
        selectedRows: _.remove(_selected, (item) => item !== id),
      });
    }
  }


  extractAttributeConfig(id) {
    const { attributesConfiguration } = this.state;
    return _.find(attributesConfiguration, (a) => a.id === id);
  }

  handleSingleSelectRow(row, isSelected) {

    let _selected = [].concat(this.state.selectedRows);

    if (isSelected) {
      if (!_selected.includes(row.id)) {
        _selected = [row.id];

        this.setState({
          selectedRows: _selected,
        });
      }
    } else {
      this.setState({
        selectedRows: _.remove(_selected, (item) => item !== row.id),
      });
    }
  }

  render() {
    const {
      isLoading,
      results,
      showCreateForm,
      attributesConfiguration,
      pagination,
      searchOpen,
      selectedRows,
    } = this.state;


    const {
      configuration,
      selectedObjectIds,
      attributes,
      values,
      guiUser,
      singleRow
    } = this.props;

    const onSelectAll = (isSelected, selectedRows) => {
      if (isSelected) {
        this.setState({ selectedRows: selectedRows.map(i => i.id) });
      } else {
        this.setState({ selectedRows: [] });
      }

      return isSelected ? selectedRows.map(i => i.id) : [];
    };


    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      unselectable: selectedObjectIds,
      selected: _.union(selectedRows, selectedObjectIds),
      onSelectAll,
      onSelect: this.handleRowSelect,
    };

    const singleSelectRow = {
      mode: 'radio',
      onSelect: this.handleSingleSelectRow,
      clickToSelect: true,
      selected: selectedRows,
    }

    const shouldShowColumn = (attributeConfig) => {
      const found = _.find(configuration.displayAttributes, (a) => a.attributeId === attributeConfig.id);
      return (found && found.summary && attributeConfig.displayable);
    };

    const searchPanelHeader = (
      <div
        onClick={() => {
          this.setState({ searchOpen: !searchOpen });
        }}
      >
        <Glyphicon className="pull-right" glyph={searchOpen ? 'chevron-up' : 'chevron-down'} />
        {__('SetSearchParameters')}
      </div>
    );

    if (!configuration) return null

    return (
      <div>
        <div style={{ marginRight: '10px' }}>
          <Tooltip title={__('Add Item')}>
            <Button
              title={<><PlusOutlined />
                {__('Add Item')}</>}
              backgroundColor='#D5E4F7'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.openModal();
              }}
            >
            </Button>
          </Tooltip>
        </div>

        {this.state.modal.open}

        {this.state.modal.open &&
          <Modal
            backdrop="static"
            show={this.state.modal.open}
            onHide={this.closeModal}
            dialogClassName="modal-90W"
          >
            <Modal.Header closeButton>
              <Modal.Title className="capitalize">{__('Add Item')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'add-items-modal-body'}>
              <Panel
                expanded={searchOpen}
                className="sort-attribues-panel panel--simple"
              >
                <Panel.Heading>{searchPanelHeader}</Panel.Heading>
                <Panel.Body collapsible>
                  <ReportSearchFieldsForm
                    isLoading={isLoading}
                    guiUser={guiUser}
                    reportSpec={configuration}
                    fields={configuration.searchAttributes}
                    attributesConfiguration={attributesConfiguration}
                    sortAttributes={configuration.sortAttributes}
                    extractConfiguration={this.extractAttributeConfig}
                    initialValues={{}}
                    enumValues={{}}
                    onSubmit={this.getResult}
                    buttonSize="default"
                    setShowAllOrders={() => {
                    }}
                  />
                </Panel.Body>
              </Panel>

              {isLoading && __('loading')}

              {!isLoading &&
                <CreateStepObjectButton
                  action="create"
                  configuration={configuration}
                  onCreate={(id) => {
                    this.onCreateSubmit(id);
                  }}
                  attributesConfiguration={attributesConfiguration}
                  className="btn btn-primary gutter-bottom"
                  remoteObjectSpec={configuration}
                  attributes={attributes}
                  values={values}
                  apis={this.props.apis}
                />
              }

              {!isLoading && results && !showCreateForm &&
                <div className="table-wrapper">
                  <BootstrapTable
                    data={results}
                    selectRow={singleRow ? singleSelectRow : selectRow}
                    ref="stepObjectsTable"
                    remote
                    pagination
                    hover
                    fetchInfo={{ dataTotalSize: pagination.totalCount }}
                    striped
                    options={{
                      onPageChange: this.onPageChange,
                      onSortChange: this.onSortChange,
                      noDataText: isLoading ? __('loading_data') : __('NoDataFound'),
                      hideSizePerPage: false,
                      firstPage: 'First',
                      lastPage: 'Last',
                      sizePerPageList: config.pageSizeOptionsObjectList,
                      paginationShowsTotal: renderResultInfo.bind(this),
                      page: pagination.pageNumber,
                      sizePerPage: pagination.pageSize,
                    }}
                  >
                    <TableHeaderColumn
                      key={`table-column-id`}
                      isKey
                      dataField="id"
                      hidden={true}
                    >
                      {__("Id")}
                    </TableHeaderColumn>
                    {
                      results[0] && Object.keys(results[0].displayData).map((attrId) => {
                        const attributeConfig = this.extractAttributeConfig(attrId);

                        if (!shouldShowColumn(attributeConfig) || !attributeConfig || !attributeConfig.displayable) {
                          return;
                        }


                        return (
                          <TableHeaderColumn
                            key={`table-column-${attrId}`}
                            dataField={attrId}
                            dataSort
                            dataFormat={function (cell, row) {
                              let value = row.displayData[attrId]?.value || row.displayData[attrId]
                              return <div> <EllipsisText trigger={'hover'} text={value} ></EllipsisText></div>
                            }
                            }
                          >
                            {__(attributeConfig.propertyLabel)}
                          </TableHeaderColumn>

                        );
                      })
                    }
                  </BootstrapTable>
                </div>
              }
            </Modal.Body>
            <Modal.Footer>
              <div className="group">
                <button className="btn btn-default pull-left" onClick={this.closeModal}>
                  {__('Cancel')}
                </button>

                <button
                  className="btn pull-right btn-primary"
                  disabled={this.state.isLoading || selectedRows.length === 0}
                  size="large"
                  onClick={this.handleSubmit}
                >
                  {__('Add Selected')}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        }
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      guiUser: state.user.data,
      formValues: getFormValues('reportSearchFieldsForm')(state),
    };
  },
  { updateRemoteObjectItem, createRemoteObjectItem }
)(AddStepObjectButton);
