export default (payload) => {
  const { query } = payload || {}
  const { options, ...queryWithoutOptions } = query || {}

  return {
    cubeQuery: {
      ...payload,
      query: queryWithoutOptions
    },
    options
  }
}
