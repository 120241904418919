import * as types from '../types';
import objectAssign from 'object-assign';
import { hashHistory } from '../../HistoryProvider';

import { showMessage } from '../../../utils/appHelper';
import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function createView(params) {
  return function () {
    return api.post('/configurations/views/',{ ...params })
    .then(
      response => {
      if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('View created successfully'));
            },
            200
          );
          
          hashHistory.push('/customViews');
        }
      }
    );
  };
}

export function updateView(params) {
  let payload = objectAssign({}, {...params});
  return function () {
    return api.put('/configurations/views/'+params.id,{...payload})
      .then(
        response => {
          if(response.status === 200){
            setTimeout(()=>{
              showMessage('success', __('View updated successfully'));
              },
              200
            );
            hashHistory.push('/customViews');
          }
        }
      );
    }
}

export function deleteView(id) {
  return function (dispatch) {
    return api.delete('/configurations/views/'+id, {
      status: 'deleted'
    })
    .then(
      response => {       
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('View deleted successfully'));
            },
            200
          );
        } 
        dispatch({
          type: types.USER_DELETED,
          payload: response.data
        });
      }
    );
  };
}

export function getView(key) {
  return function (dispatch) {
    return api.get('/views/'+key)
    .then(
      response => {
        dispatch({
          type: types.VIEWS_GOT_DATA,
          payload: response.data
        });
      },
      error => {
        dispatch({
          type: types.VIEWS_GET_DATA_ERROR,
          payload: error.response.data.message
        });
      },
    );
  };
}
