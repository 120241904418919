import React, {useEffect, useState} from 'react'
import {Typography} from 'antd'
import PropTypes from 'prop-types'
import {Authentication as Auth, Gutter, Text} from 'components'
import {fetchEntityByName} from "../../providers/ApiProvider/authentication"
import useInit from "../../hooks/useInit"
import Loading from "../../components/Loading";
import {connect} from 'react-redux'
import {signUpActionCreator} from '../../providers/ReduxProvider/actions/userActions'
import {fetchEntitySettings} from "../../utils/appHelper"
import {__, T} from '../../utils/translationUtils'
import getErrorText from "../../utils/getErrorText";
import {signupValidationSchema} from './validation'
import {parse} from "query-string";

const textCommons = {T, __, getErrorText}

const {Title} = Typography;

const SignUpPage = (props) => {
  const {match, location, signUp, ...formProps} = props
  const [entitySettings, setEntitySettings] = useState(null)
  const [entity, setEntity] = useState(null)

  const {userRegistration} = entitySettings || {}
  const {fields, allowedEmailDomains} = userRegistration || {}

  const mounted = useInit()

  useEffect(() => {
    fetchEntityByName(match.params.entityName).then((res) => {
      if (mounted.current) {
        setEntity(res)
        res.id && fetchEntitySettings(res.id).then((settings) => setEntitySettings(settings))
      }
    })
  }, [match.params.entityName])

  const handleSubmit = (entity, data, onSuccessfulSignUp) => {
    const queryString = parse(location.search)
    signUp(entity, data, onSuccessfulSignUp, queryString?.redirect)
  }

  return (
    !userRegistration
      ? <Loading/>
      : (userRegistration?.selfRegistrationEnabled)
        ? (
          <>
            <Text center>
              <Gutter bottom>
                <h3>{entity?.name || ""}</h3>
              </Gutter>
            </Text>
            {
              <Auth.SignUpForm
                entity={entity}
                userRegistration={userRegistration}
                validationSchema={fields && signupValidationSchema(fields, allowedEmailDomains)}
                textCommons={textCommons}
                queryString={location.search}
                signUp={handleSubmit}
                {...formProps}
              />
            }
          </>
        )
        : (
          <>
            <Title level={3}>
              <Text center>
                {__(T.registrationNotEnabled)}
              </Text>
            </Title>
          </>
        )
  )
}

export default connect(
  (state) => ({
    availableLanguages: state.ui.availableLanguages,
    language: state.ui.language,
    signUpError: state.errors.signUpError,
  }),
  {
    signUp: signUpActionCreator,
  }
)(SignUpPage)

SignUpPage.propTypes = {
  availableLanguages: PropTypes.instanceOf(Array),
  getLanguagesWrapped: PropTypes.func
};

