import { hashHistory } from '../../HistoryProvider';
import objectAssign from 'object-assign';

import { showMessage } from '../../../utils/appHelper';
import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function createStepObject(params, onObjectCreate = undefined) {
    return function () {
      return api.post('/configurations/stepObjects/',{ ...params })
      .then(
        response => {
        if(response.status === 200){
            setTimeout(()=>{
              showMessage('success', __('Step Object created successfully'));
              },
              200
            );

            if (onObjectCreate) {
              onObjectCreate();
            } else {
              hashHistory.push('/manageStepObjects');
            }
          }
        }
      )
      .catch( (error) => {
        showMessage('error', __('ServerSideError') + '. ' + error);
      });
    };
  }

  export function createStepObjectItem(params) {
    return function () {
      let payload = objectAssign({}, {...params});
      delete payload.sortAttributes;
      return api.post('/configurations/stepObjects/create', {...payload})
      .then(
        response => {
          if(response.status === 200){
            setTimeout(()=>{
              showMessage('success', __('Step Object updated successfully'));
              },
              200
            );
          }
          return response.headers['x-location'];
        }
      )
    };
  }

  export function updateStepObject({ id, payload }) {
    return function() {
      return api.put(`/configurations/stepObjects/${id}`, payload )
      .then(
        response => {
          if(response.status === 200){
            setTimeout(()=>{
              showMessage('success', __('Step Object updated successfully'));
              },
              200
            );
            hashHistory.push('/manageStepObjects');
          }
        }
      )
      .catch( (error) => {
        showMessage('error', __('ServerSideError') + '. ' + error);
      });
    }

  }

  export function deleteStepObject(id) {
    return function () {
      return api.delete('/configurations/stepObjects/'+id, {
      status: 'deleted'
      })
      .then(
        response => {
          if(response.status === 200){
          setTimeout(()=>{
              showMessage('success', __('Step Object deleted successfully'));
              },
              200
          );
          }
      }
      );
    };
  }
