import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SubMenu from './Submenu';
import { __ } from '../../../utils/translationUtils';

const TopMenuItem = ({ item }) => {
  const [open, setOpen] = useState(false)
  const menuItemRef = useRef(null)

  const handleOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    const menuItem = menuItemRef.current
    if (menuItem.classList.contains('hovered')) {
      menuItem.classList.remove('hovered')
    }
    setOpen(false)
  }

  const { text, active, items: subItems = [] } = item || {}

  return (
    <div
      ref={menuItemRef}
      onMouseOver={handleOpen}
      onClick={handleOpen}
      onMouseLeave={handleClose}
      className={`${active ? 'active ' : ''}${open ? 'hovered' : ''}`}
    >
      <li className={`${active ? 'active ' : ''}${open ? 'hovered' : ''}`}>
        {
          !item.external ? (
            <Link to={!subItems.length ? item.path : ''}>{__(text)}</Link>
          ) :
            (
              <a href={!subItems.length ? item.path : ''} target={item.target}>
                {__(text)}
              </a>
            )
        }
        {
          subItems.length > 0 && (
            <SubMenu
              handleClick={handleClose}
              isOpen={open}
              items={subItems}
              customItemsProps={item.customItems}
            />
          )
        }
      </li>
    </div>
  );
};

TopMenuItem.propTypes = {
  item: PropTypes.object,
  guiUser: PropTypes.object,
  isOpen: PropTypes.bool,
  activeItem: PropTypes.object,
  path: PropTypes.string,
  location: PropTypes.object,
  text: PropTypes.string
};

export default TopMenuItem;
