import { addAsterisks } from "../../utils/appHelper";
import { filter, findIndex } from "lodash";
import moment from "moment";
import * as C from "../../constants";
import * as config from '../../constants/globalConfiguration'


export const getSearchAttributes = (values, remoteObjectConfiguration, attributesMap) => {

  const _attrs = [].concat(remoteObjectConfiguration.searchAttributes)
  const attrs = []

  _attrs.forEach((_r) => {
    const r = { ..._r }
    const attributeInfo = attributesMap[_r.attributeId]
    // RANGE
    if (r.mechanism === 'RANGE') {
      if (attributeInfo.type === 'DATE') {
        if (values[`${r.attributeId}From`]) {
          // Values From round down to the nearest minute
          const roundDown = moment(values[`${r.attributeId}From`]).startOf('minute')
          values[`${r.attributeId}From`] = roundDown || undefined
        }
        if (values[`${r.attributeId}To`]) {
          // Values To round up to the nearest minute
          const toMoment = moment(values[`${r.attributeId}To`])
          const roundUp = toMoment.second() || toMoment.millisecond() ? toMoment.add(1, 'minute').startOf('minute') : toMoment.startOf('minute')
          values[`${r.attributeId}To`] = roundUp || undefined
        }
      }

      if (typeof values[`${r.attributeId}From`] !== 'undefined' || values[`${r.attributeId}From`] !== '') {
        r.from = values[`${r.attributeId}From`]
      } else {
        delete values[`${r.attributeId}From`]
      }

      if (typeof values[`${r.attributeId}To`] !== 'undefined' || values[`${r.attributeId}To`] !== '') {
        r.to = values[`${r.attributeId}To`]
      } else {
        delete values[`${r.attributeId}To`]
      }

      /*
       * Apply Felix's Algorithm on range fields
       * 1) If both *from* and *to* are filled --> normal *range* search.
       * 2) If only *from* is filled --> *specic_value* search. (the from can contain wildcard)
       * 3) if only *to* is filled the webui fills the from value with the same value contained in the *to* --> *range* search (edited)
       */

      // Applies only to RANGE.
      if (r.mechanism === 'RANGE' && (r.to || r.from)) {
        const { to, from } = r
        if (!(attributeInfo.type === 'DATE')) {
          if (from && !to) {
            r.to = from
          } else if (!from && to) {
            r.from = to
          }
        } else if (from && !to) {
          r.from = from.utc().format()
          r.to = from.add(1, 'minute').utc().format()
        } else if (!from && to) {
          r.to = to.utc().format()
          r.from = to.subtract(1, 'minute').utc().format()
        } else {
          r.from = from.utc().format()
          r.to = to.utc().format()
        }
      }

      // SPECIFIC_VALUE
    } else if (r.mechanism === 'SPECIFIC_VALUE') {

      if (attributeInfo.type === 'DATE' && values[r.attributeId]) {
        values[r.attributeId] = values[r.attributeId]?.utc().format()
      }

     if (typeof values !== 'undefined' && typeof values[r.attributeId] !== 'undefined' && values[r.attributeId] !== '') {
        // Add asterisks by default
        if (config.TYPES_NO_ASTERISKS_ALLOWED.includes(attributeInfo?.type?.toUpperCase())) {
          r.value = values[r.attributeId]
        } else if (!r.hidden) {
          r.value = r.exactMatch ? values[r.attributeId] : addAsterisks(values[r.attributeId].toString())
        }

        if (r.value === 'true') {
          r.value = true
        }
        if (r.value === 'false') {
          r.value = false
        }
      } else {
        delete r.value
      }
      // ENUMERATION
    } else if (r.mechanism === 'ENUMERATION') {
      const requiresobfuscation = (r.obfuscationMethod != null && r.obfuscationValue != null)
      const _value = [].concat(values[r.attributeId])
      if (typeof values[r.attributeId] !== 'undefined' && values[r.attributeId].length > 0) {
        // If obfuscationMethod == byTypeEqualsUnionChildren, then select children entities too
        if (requiresobfuscation) {
          if (r.obfuscationMethod === 'byTypeEqualsUnionChildren') {
            _value.forEach((parent) => {
              const found = filter(r.values, (v) => v.parent === parent)
              if (found) {
                found.forEach((child) => {
                  _value.push(child.id)
                })
              }
            })
          }
        }

        r.value = _value

        if (_value.constructor === Array) {
          r.value = _value.join(',')
        }
      } else {
        delete r.value
      }
    }

    if ((values[r.attributeId] == undefined || values[r.attributeId] === '')
      && (values[`${r.attributeId}From`] == undefined || values[`${r.attributeId}From`] === '')
      && (values[`${r.attributeId}To`] == undefined || values[`${r.attributeId}To`] === '')
    ) {
      return
    }

    attrs.push(r)

    // remove if empty value
    if (values[r.attributeId] != undefined && values[r.attributeId]?.length === 0) {
      //  if (typeof values[r.fieldId] !== 'undefined' && values[r.fieldId].length === 0) {
      //    const idx = findIndex(attrs, (f) => f.fieldId === r.fieldId);
      const idx = findIndex(attrs, (f) => f.attributeId === r.attributeId)
      if (idx > -1) {
        attrs.splice(idx, 1)
      }
    }
  })
  return attrs
}

export const getSearchFields = (searchAttributes) => {
  const fields = {}
  searchAttributes?.forEach(attribute => {
    switch (attribute.mechanism) {
      case C.mechanismType.SPECIFIC_VALUE:
        fields[attribute.attributeId] = attribute.value
        break
      case C.mechanismType.RANGE:
        if (searchAttributes.type === 'DATE') {
          fields[`${attribute.attributeId}From`] = attribute.from && moment(attribute.from)
          fields[`${attribute.attributeId}To`] = attribute.to && moment(attribute.to)
        }
        else {
          fields[`${attribute.attributeId}From`] = attribute.from
          fields[`${attribute.attributeId}To`] = attribute.to
        }
        break
      case C.mechanismType.ENUMERATION:
        if (attribute.multiSelect) {
          fields[attribute.attributeId] = attribute.value ? attribute.value.split(",") : []
          break
        }
        fields[attribute.attributeId] = attribute.value
        break
      default:
        return null
    }
  })
  return fields
}

