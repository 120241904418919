import { defaultAvailableControllers } from "components/dist/FormBuilder/Config/defaults";
import _ from 'lodash'
import moment from "moment";

const {
  MultipleChoice: { controllerType: multipleChoiceCT },
  SingleChoice: { controllerType: singleChoiceCT },
  Dropdown: { controllerType: dropdownCT },
  Phone: { controllerType: phoneCT },
  Email: { controllerType: emailCT },
} = defaultAvailableControllers

export const consolidateControllerValue = (value, options) => {
  const { controllerOptions, controllerType } = options

  if (controllerType === phoneCT) {
    if (controllerOptions?.usePrefix) return `${value.prefix || ''} ${value.number || ''}`
    return value?.number
  }

  if (controllerType === emailCT) {
    if (controllerOptions?.useDomains) return `${value.address || ''}@${value.domain || ''}`
    return value?.address
  }

  if (controllerOptions?.objectConfiguration) {
    if (controllerOptions.writeTo) return
    return value?.selectedItems.join(',')
  }

  if ([multipleChoiceCT, singleChoiceCT, dropdownCT].includes(controllerType)) {
    if (_.isArray(value)) {
      return value?.map(({ value }) => getEnumControllerValue(value)).join(',')
    } else {
      return getEnumControllerValue(value?.value)
    }
  }

  if (value && typeof value === 'object' && !moment.isMoment(value)) {
    return JSON.stringify(value)
  }
  return value
}


const getEnumControllerValue = (value) => {
  try {
    const parsed = JSON.parse(value)

    return parsed?.value || parsed
  } catch (e) {
    return value
  }
}
