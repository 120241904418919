export default {
  ERROR_PAGE: "errorPage",
  MAIN_PAGE: "mainPage",
  DISPLAY_PAGE: "displayPage",
  SEARCH_PAGE: "searchPage",
  SORT_PAGE: "sortPage",
}

export {default as ErrorPage} from "./ErrorPage"
export {default as MainFactory} from "./MainFactory"
export {default as DisplayAttributeFactory} from "./DisplayAttributeFactory"
export {default as SearchAttributeFactory} from "./SearchAttributeFactory"
export {default as SortAttributeFactory} from "./SortAttributeFactory"
