import styled from 'styled-components/macro'
export const AppContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  // background: #EBEFF2;
  color: #000000FF;
  overflow-y: auto;

  //a,
  //a:hover,
  //a:active {
  //  color: #00629D;
  //}
`

export const ContentWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: 64px;
`

export const LogoWrapper = styled.div`
  padding: 5rem 0 4rem ;
   .logo {
     display: block;
     max-height: 200px;
     max-width: 200px;
     margin: 0 auto;
   }

   .logo img {
     display: block;
     margin: auto;
   }
`

export const Footer = styled.div`
  margin-bottom: 8px;
  width: 100%;
  color: #2D3945;
  text-align: center;
  
  a,
  a:hover,
  a:active {
    color: #2D3945;
  }
  
  a img {
    height: 2.2rem;
    padding: 0 7px
  }
`
