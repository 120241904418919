import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Table} from 'antd'
import Glyphicon from '@strongdm/glyphicon'
import {api} from '../../providers/ApiProvider'
import * as config from '../../constants/globalConfiguration'
import {hashHistory} from '../../providers/HistoryProvider'

import { __ } from '../../utils/translationUtils'

export default () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    api.get(`${config.prefix()?.REPORT || ''}/cube/configurations`)
      .then((e) => setData(e.data))
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (!data) return
    const [firstElement] = data
    hashHistory.push(`/attributesWidgets/${firstElement ? firstElement.id : 'create'}`)
  }, [data])

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (e) => <Link to={`/attributesWidgets/${e}`}>{e}</Link>
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => ((a > b) ? 1 : -1)
    }
  ]

  if (!data) return null
  return (
    <div id="main-content">
      <h1 className="sticky">
        <div className="container">
          { __('Attributes selection')}
        </div>
      </h1>

      <div className="container">
        <div className="row">
          <Link className="btn btn-success col-xs-12 col-sm-3 gutter-bottom" to="/attributesWidgets/create"><Glyphicon glyph="plus" />
            {__('Create attributes selection')}
          </Link>

          {data && (
            <div>
              <Table
                dataSource={data.map((e) => ({ id: e.id, title: e.title }))}
                columns={columns}
                rowKey="id"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
