import styled from "styled-components";
import {Radio} from "antd";

export const RadioGroup = styled(Radio.Group)`
  display: flex;
  
  & label:first-child {
    border-right-width: 0 !important;
    border-radius: 25px 0 0 25px;
  }

  & label:last-child {
    border-radius: 0 25px 25px 0;
  }
  
  & label {
    ::before {
      background-color: #72777F !important;
    }
    min-width: 50px;
    text-align: center;
    border: 1px solid #72777F !important;
  }
  
  & label.ant-radio-button-wrapper-checked {
    
    ::before {
      background-color: #72777F !important;
    }
    background: #d5e4f7 !important;
    color: #0F1D2A !important;
    border: 1px solid #72777F;
  }

  & label.ant-radio-button-wrapper {
    color: #0F1D2A !important;
  }
  
  & label span input:focus {
    outline: 0 !important;
  }
`
