import { __ } from '../../../utils/translationUtils'

export default ({ reportsWidgetConfiguration, formValues }) => [
  ...(reportsWidgetConfiguration?.filters || []),
  ...(reportsWidgetConfiguration?.dimensions || [])
]
  .filter(({ isEnumeration }) => isEnumeration)
  .map(({ attributeId, fieldKey }) => ({
    label: __(attributeId),
    value: fieldKey,
    disabled: !formValues?.dimensions?.find((e) => (e && JSON.parse(e)?.fieldKeyEnum) === fieldKey) && !formValues?.filters?.find((e) => (e?.member && JSON.parse(e.member)?.fieldKeyEnum) === fieldKey)
  }))
  .reduce((acc, val) => {
    if (acc.find((e) => e.value === val.value)) return acc
    return [...acc, val]
  }, [])
