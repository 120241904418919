import React from 'react'

import { useHistory } from "react-router-dom";

import { Popconfirm, Space } from "antd";

import { Button } from 'components'
import * as S from "../../pages/ConfigurationConfiguratorBeta/styles";
import { __ } from '../../utils/translationUtils'

const CloseMessage = () => (
  <>
    <S.CustomText> {__('This is an irreversible operation')} </S.CustomText>
    <S.CustomText> {__('Do you want to leave?')} </S.CustomText>
  </>
)

export default (props) => {

  const {
    buttons,
    close = true,
    closeLabel = __('Exit'),
    onClose = () => history.goBack(),
    theme,
  } = props || {}

  const history = useHistory();

  return (
    <S.FooterContainer>
      <Space size='middle'>
        {close &&
          <Popconfirm
            title={<CloseMessage />}
            okText={__('Yes')}
            cancelText={__('No')}
            placement="rightBottom"
            onConfirm={onClose}
          >
            <Button
              size={'large'}
              type="text"
              title={closeLabel}
              backgroundColor={'transparent'}
              color={theme === 'dark' ? '#FFFFFF' : '#00629D'}
            />
          </Popconfirm>
        }
      </Space>
      <Space size='middle'>
        {buttons?.map((button) => (
          <Button
            size={'large'}
            {...button}
          />
        ))}
      </Space>
    </S.FooterContainer>
  )
}
