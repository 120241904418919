import React from "react"
import FormContext from "./factories/FormContext"

import * as ACTION_TYPE from "./context/actionTypes"
import {debounce} from "./utils"

import {Form} from '@ant-design/compatible';

/*
 * Create Form Provider,
 * manage form props like name, propsToField, ecc...
 * */
function WrapperForm({children, form}) {
  return (
    <FormContext.Provider value={{form}}>
      <Form layout={"vertical"}>{children}</Form>
    </FormContext.Provider>
  )
}

const debounceCall = debounce(
  (dispatch, changedFields) =>
    dispatch({
      type: ACTION_TYPE.UPDATE_CONFIGURATION,
      payload: {
        changedFields: changedFields,
      },
    }),
  250
)

export default Form.create({
  name: "Wrapper Form",
  onFieldsChange: (props, changedFields) => {
    if (typeof props.dispatch !== "function") {
      throw Error("Dispatch is not a function in onFieldsChange. Please contact Administrator")
    }

    debounceCall(props.dispatch, changedFields)
  },
  mapPropsToFields: ({formFields}) => {
    return formFields
  },
})(WrapperForm)
