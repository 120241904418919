import styled from "styled-components";
import {Button} from 'antd'


export const CarouselOptions = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content:space-evenly;
`

export const MESSAGE_FILE_IS_MANDATORY = 'This file contains a mandatory field'


export const UploadButton = styled(Button)`
  border:0;
  boxShadow:"none";
  webkitBoxShadow:"none"; 
`
