import { features, layout } from "../../components/ConfigurationConfiguratorBeta/constants";

export const type = {
  REPORT: 'REPORT',
  VIEW: 'VIEW'
}

export const mode = {
  CREATE: 'CREATE',
  EDIT: 'EDIT'
}

export const steps = {
  OUTPUT_FIELDS: 0,
  FILTERS: 1,
  SORTING_PARAMETERS: 2,
  VIEW_PROPERTIES: 3
}

export const viewPropertiesFields = {
  VIEW_NAME: 'viewName',
  VISIBLE_FOR: 'visibleFor',
  SELECT_ENTITIES: 'selectEntities',
  ONLY_MY_ORDERS: 'onlyMyOrders',
  SHOW_PHASES: 'showPhases',
  LAYOUT: 'layout',
  DEFAULT_LAYOUT: 'defaultLayout',
  DEFAULT_PAGE_SIZE: 'defaultPageSize',
  FEATURES: 'features',
  DEFAULT_CONFIGURATION:'defaultConfiguration'
}

export const configuratorInitialState = {
  [steps.OUTPUT_FIELDS]: {},
  [steps.FILTERS]: {},
  [steps.SORTING_PARAMETERS]: {},
  [steps.VIEW_PROPERTIES]:
    {
      onlyMyOrders: true,
      layout: [layout.TABLE],
      defaultLayout: layout.TABLE,
      defaultPageSize: 25,
      features: [features.ORDER_TYPE, features.DEADLINE, features.PRIORITY],
      showPhases: ['ongoing', 'finalised'],
    },
}

export const validationInitialState = {
  [steps.OUTPUT_FIELDS]: false,
  [steps.VIEW_PROPERTIES]: false,
}

export const validationActivatedAfter = {
  EDIT: 'EDIT',
  CLOSE: 'CLOSE',
}
