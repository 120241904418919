import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';

import AssignmentField from './formFields/AssignmentField';
import { __ } from '../utils/translationUtils'

class EditOrderSelectUserModal extends React.Component {

  static propTypes = {
    open: PropTypes.bool,
    guiUser: PropTypes.object,
    process: PropTypes.object,
    pairs: PropTypes.array,
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      pairs: [],
    };

    // Setting Refs
    this.editOrderFooterButtons = null;

    this.onSubmit = this.onSubmit.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    this.settingCustomHTMLAttributes();
  }

  settingCustomHTMLAttributes(){
    this.editOrderFooterButtons
    && this.editOrderFooterButtons.setAttribute('cy-test', 'editOrderFooterButtons');
  }

  onSubmit(values){
    this.props.onSubmit(JSON.parse(values.assignees));
  }

  render() {
    const { handleSubmit, guiUser, process, closeModal, open } = this.props;

    return (
      <Modal
        className="popup-l"
        backdrop="static"
        show={open}
        onHide={closeModal.bind(this)}>

        <Modal.Header closeButton>
          <Modal.Title className="capitalize">{__('Assign Process')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="clearfix">
            <div className="col-xs-12">
              <form onSubmit={handleSubmit(this.onSubmit).bind(this)}
                className="form-horizontal"
              >
                <div className="form-group">
                  <Field
                    name="assignees"
                    component={AssignmentField}
                    label={__('Assign To')+'*'}
                    className="form-control"
                    multiple
                    withFilter
                    type="select"
                    guiUserId={guiUser.id}
                    selectedItems={process && process.ownership.assignees}
                    processId={process && process.id}
                  />
                </div>

                <hr />
                <div className="clearfix" ref={el => this.editOrderFooterButtons = el}>
                  <Button
                    className="pull-left"
                    onClick={this.props.closeModal.bind(this)}
                    >
                    {__('Back')}
                  </Button>

                  <Button
                    type="submit"
                    className="pull-right btn-green"
                    >
                    {__('Assign')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>

      </Modal>

    );
  }
}
export default reduxForm({
  form: 'editOrderSelectUserForm'  // a unique identifier for this form
})(EditOrderSelectUserModal);
