import React from 'react'
import { Col, Row, Typography } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import LottieFile from './LottieFile'

import overview from '../../../images/lottie-files/overview.json'
import deadline from '../../../images/lottie-files/deadline.json'
import initialization from '../../../images/lottie-files/initialization.json'
import create from '../../../images/lottie-files/create.json'

const { Text } = Typography

const MessageImg = ({type}) => {
  switch (type) {
  case 'success':
    return (<CheckCircleTwoTone twoToneColor="#52c41a" />)
  case 'error':
    return (<CloseCircleTwoTone twoToneColor="#eb2f96" />)
  case 'overview-lottie':
    return (<LottieFile file={overview} width="25%" loop />)
  case 'deadline-lottie':
    return (<LottieFile file={deadline} width="25%" loop />)
  case 'initialization-lottie':
    return (<LottieFile file={initialization} width="25%" loop />)
  case 'create-lottie':
    return (<LottieFile file={create} width="25%" loop />)
  default:
    return (null)
  }
}

const MessageView = (props) => (
  <div>
    <Row>
      <Col span={12} offset={6}>
        {
          (props.showImage) && <div className="ant-result-icon">
            <MessageImg type={props?.type} />
          </div>
        }

        <div className="ant-result-title">
          <Text strong> {props?.title} </Text>
        </div>
        <div className="ant-result-subtitle">
          <Text> {props?.subtitle} </Text>
        </div>
      </Col>
    </Row>
  </div>
)

export default MessageView

MessageView.defaultProps = {
  title: '',
  subtitle: '',
  type: null,
  showImage: true,
}

// MessageView.props = {
//   title: PropTypes.string,
//   subtitle: PropTypes.string,
//   type: PropTypes.string,
// };
