import React from 'react';
import PropTypes from 'prop-types';
import { SortableHandle } from 'react-sortable-hoc';

import PropertyLabelTitle from "./PropertyLabelTitle";
import { __ } from '../utils/translationUtils'

const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

class SortAttributeCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      attribute,
      extractAttributeConfig,
      removeSortAttribute,
      setSortAttributeValue,
    } = this.props;

    if (typeof extractAttributeConfig(attribute.attributeId) === 'undefined') {
      return null;
    }

    return (
      <div
        className="configuration-list-item"
        key={attribute.attributeId}
      >
        <div className="header">
          <DragHandle />{' '}

          <strong>{__(extractAttributeConfig(attribute.attributeId).propertyLabel)}</strong>
          &nbsp;
          <select
            style={{
              margin: '0 7px',
              fontSize: '11px',
              background: '#fff',
            }}
            value={attribute.value}
            onChange={(e) => {
              setSortAttributeValue(attribute, e.target.value);
            }}
          >
            <option value="ASC">{__('ASC')}</option>
            <option value="DESC">{__('DESC')}</option>
          </select>
          <PropertyLabelTitle attribute={attribute}  type={'sort'}/>

          {' '}<span style={{fontSize: '11px'}}>{__('Position')}: {attribute.position + 1}</span>

          <a
            className="pull-right"
            onClick={() => removeSortAttribute(attribute)}
          >
            {__('Remove')}
          </a>

        </div>

      </div>
    );
  }
}

SortAttributeCard.propTypes = {
  attribute: PropTypes.object,
  removeSortAttribute: PropTypes.func,
  setSortAttributeValue: PropTypes.func,
  extractAttributeConfig: PropTypes.func,
};

export default SortAttributeCard;
