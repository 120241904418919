import React from 'react';
import PropTypes from 'prop-types';
import {SortableHandle} from 'react-sortable-hoc';
import {Glyphicon} from "react-bootstrap";
import {Tag} from "antd";
import {Col, Row, Select, Space, Typography} from 'antd';

import { __ } from '../utils/translationUtils'
const {Text} = Typography;
const {Option} = Select;

const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

class OrderDetailsAttributeCard extends React.Component {
  constructor(props) {
    super(props);
    this.getControlOptions = this.getControlOptions.bind(this);
  }

  getControlOptions(type) {
    const {valueTypesMappings} = this.props;
    return valueTypesMappings && valueTypesMappings[type].availableControllerTypes.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0)).map((control, i) => (
      <Option key={`${control}-${i}`} value={control}>{control}</Option>
    ));
  }

  render() {
    const {
      attribute,
      extractAttributeConfig,
      remove,
      valueTypesMappings,
      setControllerType
    } = this.props;


    if (typeof extractAttributeConfig(attribute.attributeId) === 'undefined') {
      return null;
    }

    const config = extractAttributeConfig(attribute.attributeId);

    return (
      <div
        className="configuration-list-item"
        key={attribute.attributeId}
      >
          <Space>
            <DragHandle/>
            <strong>{__(config.propertyLabel)}</strong>{'  '}
            <Tag color="#DFE4E8"><span style={{color:"#003B66"}}>{__(config.dependenciesLabel)}</span></Tag>

          </Space>
          <a
            className="pull-right"
            onClick={() => remove(attribute)}
          >
            <Glyphicon glyph="remove"/>
          </a>
          <Row style={{marginTop: 6}}>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <Text>{__('Control Type')}</Text>
                </Col>
                <Col span={18}>
                  <Select
                    name={`${attribute.attributeId}_controllerType`}
                    defaultValue={attribute?.controllerType ? attribute?.controllerType : ""}
                    onChange={(value) => setControllerType(attribute.attributeId, value)}
                    style={{width: '100%'}}
                  >
                    <Option key="0--" value="">
                      {`${__('Use Default')} [${valueTypesMappings && valueTypesMappings[config.type].defaultControllerType}]`}
                    </Option>
                    {this.getControlOptions(config.type)}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
    );
  }
}

OrderDetailsAttributeCard.propTypes = {
  attribute: PropTypes.object,
  remove: PropTypes.func,
  extractAttributeConfig: PropTypes.func,
  valueTypesMappings: PropTypes.object,
  setControllerType: PropTypes.object,
};

export default OrderDetailsAttributeCard;
