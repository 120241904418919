import * as VIEW_TYPE from "./viewType"

export default {
  error: {
    exist: false,
    message: "",
  },
  viewType: VIEW_TYPE.LOADING,
  stateConfiguration: {
    configurationType: null,
    ableToConfigureAttributes: false,
    ableToSubmitForm: false,
  },
  editingForms: {
    configurationFormFields: null,
  },
  needFetch: true,
}
