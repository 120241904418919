import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import mime from 'mime-types'
import * as config from '../../constants/globalConfiguration'
import { api } from '../../providers/ApiProvider'
import FileUploaderService from '../fileUploader/services/fileUploaderService'
import {CUSTOM_LOGO} from "../../constants/globalConfiguration";

import logo_light_full from '../../images/logo-light-full.png';
import logo_full from '../../images/logo-full.png';
import { getFileBase64 } from "../DooOrderPage/Step/customRender/FileUploader/utils/file";

const Logo = (props) => {
  const { className, forceGodoo, light } = props
  const systemSettings = useSelector((state) => state?.ui?.systemSettings)
  const [logoSrc, setLogoSrc] = useState(null)
  const [noCustomLogo, setNoCustomLogo] = useState(false)

  useEffect(() => {
    if (systemSettings?.[CUSTOM_LOGO] && !forceGodoo && !noCustomLogo) {
      api.get(`staticFiles/logo/${systemSettings?.[CUSTOM_LOGO]}`, { responseType: 'arraybuffer' })
      .then(
        (result) => {
          getFileBase64(new Blob([result.data], { type: mime.lookup(systemSettings?.[CUSTOM_LOGO]) })).then(
            (data) => setLogoSrc(data)
          )
        }
      ).catch(() => {
        setLogoSrc(light ? logo_light_full : logo_full)
        setNoCustomLogo(true)
      })
    } else {
      setLogoSrc(light ? logo_light_full : logo_full)
    }
  }, [systemSettings, logoSrc, noCustomLogo])
  
  return (
    <Link className={`logo ${className}`} title="godoo" to={config.HOME_URL}>
      <div style={{ display: 'flex' }}>
        <img alt="" style={{objectFit: 'contain', width: '100%', height: '100%'}} src={logoSrc} onError={(e) => {
          console.error('Logo failed to load')
          setLogoSrc(light ? logo_light_full : logo_full)
        }}/>
      </div>
      </Link>
  )
}

Logo.propTypes = { className: PropTypes.string, forceGodoo: PropTypes.bool, light: PropTypes.bool }
Logo.defaultProps = {
  className: '',
  forceGodoo: false,
  light: false
}
export default Logo
