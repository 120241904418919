export const getMainStatus = (orderStatus: Record<any, any>) => {
	const {cancelled, archived, deleted} = orderStatus;

	return deleted || cancelled || archived
}


export const statusToAction = (orderStatus: Record<any, any>) => {
	const status = getMainStatus(orderStatus)

	switch (status) {
		case 'cancelled':
			return 'cancel';
		case 'archived':
			return 'archive';
		case 'deleted':
			return 'delete';
		default:
			return undefined;
	}
}
