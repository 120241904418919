import * as CONST from '../constant'
import { FILE_VERSIONS_ENUM } from '../constant'
import { api } from '../../../providers/ApiProvider'
import { downloadFile } from "../../DooOrderPage/Step/customRender/FileUploader/utils/IO";
import { getOS } from "../../../utils/appHelper";

class FileUploaderServiceAbstract {
  constructor() {
    if (this.constructor === FileUploaderServiceAbstract) {
      throw new Error('Abstract class cannot be instantiated')
    }
  }

  retrieveMimeTypes(field) {
    if (field == null) {
      return ''
    }

    let res = field?.acceptedMimeTypes || field?.accept || ''

    if (Array.isArray(res))
      res = res.join(', ')

    if (getOS() === 'Windows' && res && res.includes('text/csv'))
      res += ',.csv'
    return res
  }

  retrieveFormName(formName) {
    if (formName == null) {
      return 'stepForm'
    }

    return formName
  }

  getUrlFile(url, authToken, progress) {
    return downloadFile(url, authToken, progress)
  }


  computeFileVersion(field, reduced) {
    if (field == null) return null

    if (reduced) return FILE_VERSIONS_ENUM.REDUCED_VERSION
    return field?.controllerOptions?.rootDirectory ? FILE_VERSIONS_ENUM.NEW_FILE_VERSION : FILE_VERSIONS_ENUM.OLD_FILE_VERSION
  }

  retrieveRootDirectory(source, res) {
    Object.assign(res, {
      [CONST.RootDirectory]: {
        fieldName: source.fieldName?.split('/').join('|'),
        primaryKey: source.primaryKey,
        xsdName: source.xsdName,
        name: source.xsdName,
        jsonPath: this.formatInitialJsonPath(source),
        files: [...source?.files || []]
      }
    })
  }

  formatInitialJsonPath(source) {
    let res = '/'

    if (source.xsdName != null) res += `${source.xsdName}/`
    if (source.primaryKey != null) res += `${source.primaryKey}/`
    if (source.fieldName != null) res += `${source.fieldName.split('/').join('|')}/`

    return res
  }

  addFile(source, file, crc) {

    if (source.files == null) source.files = []
    source.files.push({
      name: file.name,
      crc: crc,
      length: file.size,
      type: file.type
    })


  }

  removeFile(source, file) {
    if (!Array.isArray(source.files)) {
      console.error('Cannot remove this file because source files does not exist')
      return
    }

    const indexToRemove = source.files.findIndex(f => f.name === file.name)
    if (indexToRemove === -1) {
      console.error('Cannot remove this file because is was not found')
      return
    }

    source.files.splice(indexToRemove, 1)

  }

  async getFileCrc(crcUrl) {

    const response = await api.get(crcUrl.replaceAll('|', '%7C'))
    if (response?.data == null) {
      console.error('Retrieving the crc returns undefined')
      return null
    }

    return response.data

  }

  getFileType(file) {
    if (file.name == null || !file.url) {
      return null
    }
    const pattern = /^data:(\S*);/
    return file.url.match(pattern) ? file.url.match(pattern)[1] : null
  }


}

export default FileUploaderServiceAbstract
