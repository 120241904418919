const misc = {
  // UI
  back: 'back',
  next: 'next',
  or: 'or',
  default: 'default',
  confirm: 'Confirm',
  webApp: 'web app',
  mobileApp: 'mobile app',
  getTheApp: 'get the app',
  edit: 'edit',
  cancel: 'cancel',
  save: 'save',
  remove: 'remove',
  upload: 'upload',
  uploadLogo: 'upload logo',
  pageSize: 'page size',
  views: 'views',
  unauthorized: 'unauthorized',
  unauthorizedExtended: 'sorry, you are not authorized to modify',
  unknownError: 'unknown_error',
  error: 'error',

  // MEASURES
  lastUpdateDateTime: 'last update dateTime',
  max: 'max',
  maxDef: 'max default',
  min: 'min',
}

export default misc
