import React, {useContext, useEffect, useState} from "react"
import FormContext from "../../factories/FormContext"
import * as CONTEXT from "./../../context"
import * as VIEW_TYPE from "./../../context/viewType"
import * as SHARING_TYPE from "./sharingType"
import * as USER_RIGHT from "./../../utils/userRights"
import ApiManagement from "./../../api"

import {Form} from '@ant-design/compatible';
import {Input, Select, Switch, TreeSelect} from 'antd'

const {Option} = Select

View.propTypes = {}

function View(props) {
  const {form} = useContext(FormContext)
  const {state} = useContext(CONTEXT.MainContext)

  const [entitiesTreeData, setEntitiesTreeData] = useState([])

  useEffect(() => {
    // When fetching data is finish
    if (state.viewType === VIEW_TYPE.DISPLAY) {
      setEntitiesTreeData(ApiManagement.getEntityTreeData())
    }
  }, [state.viewType])

  return (
    <React.Fragment>
      <Form.Item label={"View Name"}>
        {form.getFieldDecorator("name", {
          rules: [{required: true, message: "Please input the Name of the view!"}],
        })(<Input/>)}
      </Form.Item>
      <Form.Item label={"Visible For"}>
        {form.getFieldDecorator("type", {
          rules: [{required: true, message: "Please select the view's visibility!"}],
        })(
          <Select>
            {ApiManagement.checkUserRights(USER_RIGHT.CAN_USE_GLOBAL_TYPE) && (
              <Option value={SHARING_TYPE.GLOBAL}>{SHARING_TYPE.GLOBAL}</Option>
            )}
            {ApiManagement.checkUserRights(USER_RIGHT.CAN_USE_SELF_TYPE) &&
            <Option value={SHARING_TYPE.SELF}>{SHARING_TYPE.SELF}</Option>}
            {ApiManagement.checkUserRights(USER_RIGHT.CAN_USE_ENTITY_TYPE) && (
              <Option value={SHARING_TYPE.ENTITY}>{SHARING_TYPE.ENTITY}</Option>
            )}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={"Show Only Workflows assigned to me"}>
        {form.getFieldDecorator("onlySelfOrders", {valuePropName: "checked"})(<Switch/>)}
      </Form.Item>
      {/*
      TODO: Tree select may need "fieldNames={{ label: 'title', value: 'key', children: 'children' }}"
      */}
      {form.getFieldValue("type") === SHARING_TYPE.ENTITY ? (
        <Form.Item label={"Select Entities"}>
          {form.getFieldDecorator("entityIds", {
            rules: [
              {
                required: form.getFieldValue("type") === SHARING_TYPE.ENTITY,
                message: "Please select at least one entity of the view!",
              },
            ],
          })(<TreeSelect treeCheckable={true} treeData={entitiesTreeData}/>)}
        </Form.Item>
      ) : null}
    </React.Fragment>
  )
}

export default View
