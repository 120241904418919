import React, {useEffect, useState} from 'react'

import {Link} from 'react-router-dom'
import moment from 'moment'
import {Button, message, Table} from 'antd'
import {CopyOutlined, DeleteOutlined, EditOutlined, MoreOutlined} from "@ant-design/icons";
import Glyphicon from '@strongdm/glyphicon'
import {api, fetchDashboards} from '../../providers/ApiProvider'
import * as config from '../../constants/globalConfiguration'
import {cloneDashboard} from '../../utils/appHelper'
import {hashHistory} from '../../providers/HistoryProvider'
import Loading from '../../components/Loading'
import NoDashboardSettings from "../../components/Dashboard/NoDashboardSettings";
import ActionOptions from '../../components/UI/ActionOptions'
import * as S from '../../providers/StyleProvider/styles'
import {__} from '../../utils/translationUtils'

const PAGINATION_INITIAL_STATE = {
  search: null,
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  sorter: null
}

export default () => {
  const [pagination, setPagination] = useState(PAGINATION_INITIAL_STATE)
  const [data, setData] = useState()
  const [loading, setLoading] = useState(null)
  const [hasConfigurations, setHasConfigurations] = useState(true)

  async function fetchData(e) {
    const {current, pageSize, sorter, search} = e
    setLoading(true)

    const dashboardsResponse = await fetchDashboards({
      pageSize,
      pageNumber: current,
      q: search,
      sort: sorter ? `${sorter?.order === 'ascend' ? '' : '-'}${sorter.field}` : undefined,
    })

    setLoading(false)
    if (dashboardsResponse) {
      setData(dashboardsResponse.data)
      if (!dashboardsResponse?.data?.length) {
        const configurationsResponse = await api.get(`${config.prefix()?.REPORT || ''}/cube/configurations`).then((response) => response?.data?.[0]?.id)
        if (!configurationsResponse) setHasConfigurations(false)
      }

      setPagination((e) => ({
        ...e,
        current: Number(dashboardsResponse.headers?.['x-page']),
        pageSize: Number(dashboardsResponse.headers?.['x-page-size']),
        total: dashboardsResponse.headers?.['x-total-count']
      }))
    }
  }

  useEffect(() => {
    if (!pagination?.current || !pagination?.pageSize) return
    fetchData(pagination)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.pageSize, pagination?.current, pagination?.sorter, pagination?.search])

  const handlePaginationChange = ({current, pageSize}, _, sorter) => {
    setPagination((e) => ({...e, current, pageSize, sorter}))
  }

  const handleSearch = (value) => {
    setPagination((e) => ({...e, search: value}))
  }

  async function handleClone(id) {
    cloneDashboard(id).then(() => {
      fetchData(pagination)
    })
  }

  function handleDelete(id) {
    api.delete(`${config.prefix()?.REPORT || ''}/dashboards/${id}`)
      .then(() => {
        message.success(__('The dashboard has been correctly removed'))
        fetchData(pagination)
      })
      .catch((err) => console.log(err))
  }

  return (
    <div id="main-content">
      <h1 className="sticky">
        <div className="container">
          {__('Dashboards')}
        </div>
      </h1>
      <div className="container">
        {loading && <Loading/>}
        {data && (!hasConfigurations ? <NoDashboardSettings/> :
            <div>
              <S.FlexSpace>
                <S.FullWidthInputSearch
                  allowClear
                  placeholder={__('Dashboards search')}
                  onSearch={handleSearch}
                />
                <Link className="btn btn-primary col-xs-12 col-sm-3 gutter-bottom" to="/dashboards/create"><Glyphicon
                  glyph="plus"/>
                  {__('Create dashboard')}
                </Link>
              </S.FlexSpace>
              <div>
                <Table
                  pagination={pagination}
                  dataSource={data}
                  columns={[
                    {
                      title: __('Dashboard Name'),
                      dataIndex: 'name',
                      key: 'name',
                      render: (e, r) => <Link to={`/dashboards/${r.id}/view`}>{r.name}</Link>,
                      sorter: () => 0
                    },
                    {
                      title: __('Last Update DateTime'),
                      dataIndex: 'lastUpdateDateTime',
                      key: 'lastUpdateDateTime',
                      sorter: () => 0,
                      render: (e) => (e ? moment(e).format(config.appDefaults.dateTimeFormat) : '')
                    },
                    {
                      dataIndex: 'id',
                      key: 'id',
                      width: 50,
                      render: (e) => (
                        <ActionOptions
                          options={[
                            {
                              value: 'edit',
                              label: __('Edit'),
                              icon: <EditOutlined/>,
                              onClick: () => hashHistory.push(`/dashboards/${e}/edit`),
                            },
                            {
                              value: 'clone',
                              label: __('Clone'),
                              icon: <CopyOutlined/>,
                              onClick: () => handleClone(e),
                            },
                            {
                              value: 'delete',
                              label: __('Delete'),
                              icon: <DeleteOutlined/>,
                              onClick: () => handleDelete(e),
                              danger: true,
                            }
                          ]}
                        >
                          <Button
                            icon={<MoreOutlined/>}
                            size={'small'}
                            shape={'circle'}
                          />
                        </ActionOptions>
                      )
                    }
                  ]}
                  rowKey="id"
                  onChange={handlePaginationChange}
                />
              </div>
            </div>
        )}
      </div>
    </div>
  )
}
