import { api } from "./index";
import { ERROR } from "components/dist/Utils/LoggerUtils";
import { showMessage } from "../../utils/appHelper";
import { __ } from '../../utils/translationUtils'

export const updateUserSettings = async (payload) => {
  return api.put('userSettings', payload)
}

export const updateEntitySettings = async (id, payload) => {
  return id && api.put(`entitySettings/${id}`, payload)
    .then(() => {
      showMessage('success', __('Entity settings saved'))
    })
    .catch((error) => ERROR(error))
}

export const updateSystemConfigurations = async (payload, successMessage) => {
  return api.put(`systemConfigurations`, payload)
  .then(() => {
      showMessage('success', __(successMessage || 'System Configurations saved'))
    }
  )
  .catch((error) => ERROR(error))
}
