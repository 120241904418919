import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form} from 'components'

import {FormWrapper} from "./styles";
import {statusInputs} from "./config";
import {__} from "../../../utils/translationUtils";
import {cases} from "../../../utils/stringUtils";


type PickStatusModalProps = {
  user: any,
  submit: (status: Statuses) => void
  close: () => void
}
const PickStatusModal = ({user, submit, close}: PickStatusModalProps) => {
  const [status, setStatus] = useState<Statuses | undefined>(undefined)

  return (
    <>
      <Modal
        backdrop="static"
        show={!!user}
        onHide={close}
      >
        <Modal.Header closeButton>
          <Modal.Title className="capitalize">
            {__('disable_or_delete_user', cases.CAPITALIZE_SENTENCE) }
            <strong>{` ${user.firstName} ${user.lastName}`}</strong>?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormWrapper>
            <Form
              id="edit-user-status-form"
              key="edit-user-status-form"
              layout="vertical"
              showSubmitButton={false}
              initialValues={{ status: user.status }}
              inputs={statusInputs({ data: { status: user.status }, __ })}
              onValuesChange={(formValues: Record<string, any>) => setStatus(formValues.status)}
              onSubmit={(formValues: Record<string, any>) => submit(formValues.status)}
            />
          </FormWrapper>
        </Modal.Body>
        <Modal.Footer>
          <div className="group">
            <Button className="pull-left" onClick={close}>
              {__('Back')}
            </Button>
            <button
              form={'edit-user-status-form'}
              disabled={status === user.status}
              className={'capitalize btn pull-right btn-primary'}
            >
              {__('continue')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PickStatusModal
