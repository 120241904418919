import React, {useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Tooltip} from 'antd'
import {Icon} from '@ant-design/compatible'
import {Glyphicon, Modal, Panel} from 'react-bootstrap'
import * as UTILS from './utils'
import {OBJECT_BINDER_CONTROLLER_TYPE, OBJECT_PICKER_CONTROLLER_TYPE} from './utils'
import {api} from "../../../providers/ApiProvider";
import ReportSearchFieldsForm from "../../ReportSearchFieldsForm";
import {useSelector} from "react-redux";
import {getFormValues} from "redux-form";
import CreateStepObjectButton from "../../CreateStepObjectButton";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import * as config from "../../../constants/globalConfiguration";
import { __ } from '../../../utils/translationUtils'

const MainKeySelectModal = Modal

function AddMainKeyModal(props) {
  const {
    objectConfiguration,
    mainKeysSelected: mainKeysAlreadyAdded,
    onAddMainKey,
    attributeList,
    controllerType,
    apis,
  } = props
  const [mainKeySelectModalVisible, setMainKeySelectModalVisible] = useState(false);
  const [searchPanelIsOpen, setSearchPanelIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [mainKeyCollectionTablePagination, setMainKeyCollectionTablePagination] = useState({
    pageSize: 25,
    pageNumber: 1,
    totalCount: 0,
    sortAttributes: []
  })
  const [mainKeyCollection, setMainKeyCollection] = useState(null)
  const [selectedMainKeyIds, setSelectedMainKeyIds] = useState([])

  const guiUser = useSelector(state => state?.user?.data)
  const reportSearchFormValues = useSelector(state => getFormValues('reportSearchFieldsForm')(state))


  const openMainKeySelectModal = () => setMainKeySelectModalVisible(true)
  const closeMainKeySelectModal = () => setMainKeySelectModalVisible(false)

  useEffect(() => {
    if (Array.isArray(mainKeysAlreadyAdded))
      setSelectedMainKeyIds(mainKeysAlreadyAdded.map(mk => mk.id))
  }, [mainKeysAlreadyAdded])

  // Collect all MainKeys related to the searching
  const collectMainKeys = useCallback(async (tablePagination) => {

    if (reportSearchFormValues == null || objectConfiguration == null || attributeList == null) {
      console.error('Not all props are settled correctly cannot execute the search');
      return;
    }

    const pagination = tablePagination || mainKeyCollectionTablePagination

    setIsLoading(true)
    setMainKeyCollection([])

    const attributeValueMap = {...reportSearchFormValues};
    const payload = {
      systemId: objectConfiguration.systemId,
      objectTypeId: objectConfiguration.objectTypeId,
      displayAttributes: objectConfiguration.displayAttributes,
      searchAttributes: objectConfiguration.searchAttributes,
      sortAttributes: pagination.sortAttributes || objectConfiguration.sortAttributes,
      pageSize: pagination.pageSize || 25,
      pageNumber: pagination.pageNumber || 1
    };

    const payloadSearchAttribute = [];
    [...objectConfiguration.searchAttributes].forEach(sA => {
      const attributeConfiguration = attributeList.find(a => a.id === sA.attributeId);
      switch (sA.mechanism) {
        case "SPECIFIC_VALUE":
          UTILS.prepareSpecificValue(sA, attributeValueMap, attributeConfiguration);
          if (attributeValueMap[sA.attributeId]) payloadSearchAttribute.push(sA);
          break;
        case "RANGE":
          UTILS.prepareDateRangePayload_applyFelixAlgorithm(sA, attributeValueMap, attributeConfiguration);
          if (attributeValueMap[sA.attributeId + "From"] != null || attributeValueMap[sA.attributeId + "To"] != null) payloadSearchAttribute.push(sA);
          break;
        case "ENUMERATION":
          UTILS.prepareEnumeration(sA, attributeValueMap, attributeConfiguration);
          if (attributeValueMap[sA.attributeId]) payloadSearchAttribute.push(sA);
          break;
        default:
          console.error("Unsupported mechanism" + sA.mechanism);
          break;
      }
    })

    payload.searchAttributes = payloadSearchAttribute
    const fetchTableData = apis?.fetchTableData || ((payload) => api.post('configurations/remoteObjects/results', payload))
    const remoteObjectsResponse = await fetchTableData(payload, { objectTypeId: objectConfiguration.objectTypeId }).catch(() => setIsLoading(false))
    if (remoteObjectsResponse == null) {
      return;
    }

    setIsLoading(false)
    setSearchPanelIsOpen(false)
    setMainKeyCollectionTablePagination({
      totalCount: parseInt(remoteObjectsResponse.headers['x-total-count']) || 0,
      pageSize: parseInt(remoteObjectsResponse.headers['x-page-size']) || 25,
      pageNumber: parseInt(remoteObjectsResponse.headers['x-page']) || 1
    })

    setMainKeyCollection(UTILS.prepareRemoteObjectsResult(remoteObjectsResponse.data, attributeList, objectConfiguration))

  }, [reportSearchFormValues, objectConfiguration, mainKeyCollectionTablePagination, attributeList])

  useEffect(() => {
    if (mainKeyCollection == null)
      collectMainKeys()
  }, [collectMainKeys, mainKeyCollection])

  const handleAddMainKey = mainKeys => {
    if (typeof onAddMainKey === 'function') onAddMainKey(mainKeys)
  }

  const handleSubmit = () => {
    const selectedMainKeys = mainKeyCollection.filter(mk => selectedMainKeyIds.includes(mk.id));
    handleAddMainKey(selectedMainKeys);
    closeMainKeySelectModal()
  }

  const onPageChange = async (pageNumber) => {
    const newTablePagination = {
      ...mainKeyCollectionTablePagination,
      pageNumber: pageNumber
    }
    await collectMainKeys(newTablePagination)
  }

  const onSortChange = async (sortName, sortOrder) => {
    const newTablePagination = {
      ...mainKeyCollectionTablePagination,
      sortAttributes: [
        {
          attributeId: sortName,
          position: 1,
          value: sortOrder.toUpperCase(),
        }
      ]
    }
    await collectMainKeys(newTablePagination)

  }

  const attributesUsedInDisplay = useMemo(() =>
      ((objectConfiguration && objectConfiguration?.displayAttributes) || []).map(dAttribute =>
        (attributeList || []).find(a => a.id === dAttribute.attributeId)
      ).filter(a => a),
    [attributeList, objectConfiguration])

  const onSelectAll = (isSelected, mainKeys) => {
    if (isSelected) {
      setSelectedMainKeyIds((mainKeys || []).map(mk => mk.id))
    } else {
      setSelectedMainKeyIds([])
    }

  };

  const selectMultipleRow = {
    mode: 'checkbox',
    clickToSelect: true,
    unselectable: Array.from(new Set([...(mainKeysAlreadyAdded || []).map(mainKey => mainKey.id)])),
    selected: Array.from(new Set([...selectedMainKeyIds, ...(mainKeysAlreadyAdded || []).map(mainKey => mainKey.id)])),
    onSelectAll,
    onSelect: (mainKey, isSelected) => {
      let selectedBefore = [...selectedMainKeyIds];
      if (isSelected && !selectedBefore.includes(mainKey.id)) {
        setSelectedMainKeyIds([...selectedBefore, mainKey.id])
      } else if (!isSelected) {
        const mainKeyIndex = selectedBefore.indexOf(mainKey.id)
        if (mainKeyIndex > -1) {
          selectedBefore.splice(mainKeyIndex, 1);
          setSelectedMainKeyIds(selectedBefore)
        }
      }
    }
  }

  const selectSingleRow = {
    mode: 'radio',
    clickToSelect: true,
    selected: Array.from(new Set([...selectedMainKeyIds, ...(mainKeysAlreadyAdded || []).map(mainKey => mainKey.id)])),
    onSelect: (mainKey, isSelected) => {
      if (isSelected) {
        setSelectedMainKeyIds([mainKey.id])
      } else if (!isSelected) {
        if ([...selectedMainKeyIds].includes(mainKey.id)) {
          setSelectedMainKeyIds([])
        } else {
          console.error('Cannot found mainKey ' + mainKey.id + ' in selected rows')
        }
      }
    }
  }


  return (<span style={{float: 'right'}}>

    {/* Button open Modal AddMainKey */}
    <Tooltip title={__('Add Item')}>
      <div className="btn-no-padding btn-std" onClick={(e) => {
        e.preventDefault();
        openMainKeySelectModal()
      }}>
        <Icon className="icon-std" type="plus" theme="outlined"/>
      </div>
    </Tooltip>

    {/* Main Key Select Modal*/}
    <MainKeySelectModal backdrop="static"
                        show={mainKeySelectModalVisible}
                        onHide={closeMainKeySelectModal}
                        dialogClassName="modal-90W"
    >
      <Modal.Header closeButton>
        <Modal.Title className={"capitalize"}>{__('Select Item')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel expanded={searchPanelIsOpen} className="sort-attribues-panel panel--simple">
          <Panel.Heading>
            <div onClick={() => setSearchPanelIsOpen(!searchPanelIsOpen)}>
              <Glyphicon className="pull-right"
                         glyph={searchPanelIsOpen ? 'chevron-up' : 'chevron-down'}/>{__('SetSearchParameters')}
            </div>
          </Panel.Heading>
          <Panel.Body collapsible={true}>
            <ReportSearchFieldsForm
              isLoading={isLoading}
              guiUser={guiUser}
              reportSpec={objectConfiguration}
              fields={objectConfiguration.searchAttributes}
              attributesConfiguration={attributeList}
              sortAttributes={objectConfiguration.sortAttributes}
              extractConfiguration={id => attributeList.find(a => a.id === id)}
              initialValues={{}}
              enumValues={{}}
              onSubmit={collectMainKeys}
              buttonSize="default"
              setShowAllOrders={() => {
              }}
            />
          </Panel.Body>
        </Panel>

        {/* Create new Main Key Cannot do this with Create Step Object Button*/}
        {!isLoading &&
        <CreateStepObjectButton
          action="create"
          onCreate={() => collectMainKeys(mainKeyCollectionTablePagination)}
          configuration={objectConfiguration}
          attributesConfiguration={attributeList}
          className="btn btn-primary gutter-bottom"
          remoteObjectSpec={objectConfiguration}
          attributes={attributesUsedInDisplay}
          values={[]}
          apis={apis}
        />
        }

        {/* Show Remote Object Configuration Result */}
        {!isLoading && mainKeyCollection &&

        <div className="table-wrapper">
          <BootstrapTable
            data={mainKeyCollection}
            selectRow={controllerType === OBJECT_BINDER_CONTROLLER_TYPE ? selectSingleRow : selectMultipleRow}
            remote
            pagination
            hover
            fetchInfo={{dataTotalSize: mainKeyCollectionTablePagination.totalCount}}
            striped
            options={{
              onPageChange: onPageChange,
              onSortChange: onSortChange,
              noDataText: isLoading ? __('loading_data') : __('NoDataFound'),
              hideSizePerPage: false,
              firstPage: 'First',
              lastPage: 'Last',
              sizePerPageList: config.pageSizeOptionsObjectList,
              page: mainKeyCollectionTablePagination.pageNumber,
              sizePerPage: mainKeyCollectionTablePagination.pageSize,
            }}
          >

            {/* First column id hidden */}
            <TableHeaderColumn
              key={`table-column-id`}
              isKey
              dataField="id"
              hidden={true}
            >
              {__("Id")}
            </TableHeaderColumn>

            {/* Display Data shown */}
            {(attributesUsedInDisplay || []).filter(a => a).map(attribute => {

              if (!attribute.displayable) {
                return null;
              }

              return (
                <TableHeaderColumn
                  key={`table-column-${attribute.id}`}
                  dataField={attribute.id}
                  dataSort
                  dataFormat={function (cell, row) {
                    if(row.displayData[attribute.id]?.value){
                      return row.displayData[attribute.id].value
                    }
                    return row.displayData[attribute.id];
                  }}
                >
                  {__(attribute.propertyLabel)}
                </TableHeaderColumn>

              );


            })}


          </BootstrapTable>
        </div>
        }

      </Modal.Body>
      <Modal.Footer>
            <div className="group">
              <button className="btn btn-default pull-left" onClick={closeMainKeySelectModal}>
                {__('Cancel')}
              </button>

              <button
                className="btn pull-right btn-primary"
                disabled={isLoading || selectedMainKeyIds.length === 0}
                size="large"
                onClick={handleSubmit}
              >
                {__('Add Selected')}
              </button>
            </div>
          </Modal.Footer>
    </MainKeySelectModal>



  </span>)

}

AddMainKeyModal.propTypes = {
  field: PropTypes.object.isRequired,
  objectConfiguration: PropTypes.shape({
    searchAttributes: PropTypes.array,
    sortAttributes: PropTypes.array,
    displayAttributes: PropTypes.array,
    objectTypeId: PropTypes.number,
    systemId: PropTypes.number,
    addableEntries: PropTypes.bool
  }).isRequired,
  mainKeysSelected: PropTypes.array,
  onAddMainKey: PropTypes.func,
  controllerType: PropTypes.oneOf([OBJECT_PICKER_CONTROLLER_TYPE, OBJECT_BINDER_CONTROLLER_TYPE]).isRequired,
}

export default AddMainKeyModal
