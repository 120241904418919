//import * as actions from '../constants/actionTypes';
import initialState from './initialState';
//import objectAssign from 'object-assign';

export default function entityUpdate(state = initialState.entities, { type/*, payload*/ }) {

  switch (type) {

    default:
      return state;
  }
  
}
