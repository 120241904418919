import React, {useMemo} from "react";

import {Button, Dropdown, Menu, Popconfirm, Tooltip} from "antd";
import {BarsOutlined, EllipsisOutlined} from "@ant-design/icons";

import * as C from '../constants'
import * as S from "../styles";
import styled from "styled-components";

const getActionProps = (action, actionClick, row) => {
  switch (action) {
    case C.actionType.VIEW_DETAILS:
      return {
        value: action,
        label: 'View details',
        icon: <BarsOutlined/>,
        onClick: () => actionClick?.(row)
      }
    default:
      return {
        value: C.actionType.CLOSE,
        label: 'Close',
      }
  }
}

export const SingleAction = (props) => {
  const {
    action,
    actionsClick = {},
    row,
  } = props

  const actionProps = typeof action === 'string' ? getActionProps(action, actionsClick[action], row) : action

  if (actionProps.icon) {
    return (
      <Tooltip title={actionProps.label}>
        <Popconfirm
          {...actionProps.popconfirm}
          onConfirm={(e) => {
            e.stopPropagation();
            actionProps.popconfirm?.onConfirm?.(row)
          }}
          onCancel={(e) => {
            e.stopPropagation();
            actionProps.popconfirm?.onCancel?.(row)
          }}
          disabled={!actionProps.popconfirm}
        >
          <Button
            size={'small'}
            key={actionProps.value}
            icon={actionProps.icon}
            danger={actionProps.danger}
            disabled={actionProps.disabled}
            onClick={(e) => {
              e.stopPropagation();
              actionProps.onClick?.(row)
            }}
            shape={"circle"}
          />
        </Popconfirm>
      </Tooltip>

    )
  }
  return (
    <Button
      size={'small'}
      key={actionProps.value}
      icon={actionProps.icon}
      danger={actionProps.danger}
      disabled={actionProps.disabled}
      onClick={(e) => {
        e.stopPropagation();
        actionProps.onClick?.(row)
      }}
    >
      {actionProps.label}
    </Button>
  )
}

export const MultipleActions = (props) => {
  const {
    children,
    actions = [],
    actionsClick = {},
    row,
    trigger = 'click',
	  onOpen,
  } = props

  const ActionsMenu = useMemo(() => (
    <Menu>
      {
        actions.map((action) => {
          const actionProps = typeof action === 'string' ? getActionProps(action, actionsClick[action], row) : action
          return (
            <S.MenuItem
              key={actionProps.value}
              icon={actionProps.icon}
              danger={actionProps.danger}
              disabled={actionProps.disabled}
              onClick={({domEvent: e}) => {
                e.stopPropagation();
                actionProps.onClick?.(row)
              }}
            >
              {actionProps.label}
            </S.MenuItem>
          )
        })
      }
    </Menu>
  ), [actions, actionsClick, row]);

  return (
    <Dropdown
      overlay={ActionsMenu}
      placement={'bottomRight'}
      trigger={[trigger]}
      onVisibleChange={(open) => open && onOpen?.(row)}
    >
      {children ||
        <Button
          size={'small'}
          shape="circle"
          icon={<EllipsisOutlined/>}
        />
      }
    </Dropdown>
  )
}

export default (props) => {
  const {
    actions = [],
	  forceDropdown
  } = props

  if (!actions.length) return null
  if (actions.length === 1 && !forceDropdown)
    return (
      <ActionsWrapper>
        <SingleAction {...props} action={actions[0]}/>
      </ActionsWrapper>
    )
  return (
    <ActionsWrapper>
      <MultipleActions {...props} />
    </ActionsWrapper>)
}

const ActionsWrapper = styled.div`
  & > .ant-btn:hover, & > .ant-btn:focus {
    color: #0a4f7a;
    border-color: #0a4f7a;
  }
`
