import React from 'react'
import _ from "lodash"
import { successfulSignUpPageConfig } from "./config";
import { __ } from '../../utils/translationUtils'
import { cases } from '../../utils/stringUtils'

export const signUpFieldsColumns = [
  {
    title: 'Fields',
    dataIndex: 'label',
    className: 'drag-visible',
    render: (props) => __(props?.value, cases.CAPITALIZE_SENTENCE),
  },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    render: (props) => {
      const { onChange, value } = props || {}
      return <input type="checkbox" onChange={(e) => onChange?.(e.target.checked)} checked={value?.checked} disabled={!value?.editable}/>
    }
  },
  {
    title: 'Required',
    dataIndex: 'required',
    render: (props) => {
      const { onChange, value } = props || {}

      return <input type="checkbox" onChange={(e) => onChange?.(e.target.checked)} checked={value?.checked} disabled={!value?.editable}/>
    }
  },
]

export const signUpFieldsDefaultData = {
  email: {
    name: "email",
    enabled: { editable: false },
    required: { editable: false },
  },
  password: {
    name: "password",
    enabled: { editable: false },
    required: { editable: false },
  },
  language: {
    name: "language",
    required: { editable: false },
  },
}

export const getUserRegistrationPayload = (values, previous) => ({
  selfRegistrationEnabled: values.selfRegistration,
  defaultEntityIds: values.userGroups,
  defaultRoles: values.userRoles,
  allowedEmailDomains: (values.allowedEmailDomains && !_.isEmpty(values.allowedEmailDomains)) ? values.allowedEmailDomains.map((d) => d.value) : undefined,
  emailVerification: values.emailVerification,
  onSuccessfulSignUp: values.onSuccessfulSignUp,
  successfulSignUpPageConfiguration: successfulSignUpPageConfig[values.successfulSignUpPageConfiguration],
  fields: values.selfRegistration
    ? values.signUpFields
      .map(({ index, enabled, required, field }) => ({
        ...field,
        position: index,
        enabled: enabled.checked,
        required: required.checked
      }))
      : previous?.fields
})
