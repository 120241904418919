import {Form, Row, Steps} from "antd";
import styled, {css} from "styled-components";
import {Required} from "../../providers/StyleProvider/styles";

const FillScreenSpread = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const EllipsisCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Ellipsis = styled.div`
  ${EllipsisCss};
  width: 100%;
`

export const FullHeight = styled.div`
  height: 100%;
`

export const Flex = styled.div`
  display: flex;
`

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`

export const SpaceBetween = styled(Flex)`
  justify-content: space-between;
`

export const ContentCentered = styled(Flex)`
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
`

export const Background = styled(FlexColumn)`
  padding-top: 50px;
  height: calc(100vh);
  background-color: ${props => props.theme === 'dark' ? '#3d729c' : '#f5f5f5'};
`

export const HeaderContainer = styled(Flex)`
  position: sticky;
  top: 50px;
  min-height: 60px;
  padding: 0 39px;
  background-color: #004671;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 10px;
  z-index: 10;
`

export const FooterContainer = styled(SpaceBetween)`
  padding: 10px 39px 24px;
  background-color: transparent;
`

export const BodyContainer = styled(Row)`
  height: 100%;
  padding: 15px;
  margin: unset !important;
  overflow: hidden;
`

export const Container = styled.div`
  ${FillScreenSpread};
  position: relative;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  z-index: 0;
`

export const HeaderTitle = styled.div`;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.1;
  color: white;
  font-family: inherit;
  width: max-content;
  white-space: nowrap;
  margin-right: 40px;
  display: inline-flex;
  align-items: center;
`

export const HeaderSteps = styled(Steps)`
  height: 100%;
  max-width: max(1000px, 70%);
  max-height: max(100px, 5vh);
  padding-top: 12px;
  &.ant-steps {
    & > .ant-steps-item:not(.ant-steps-item-active) {
      opacity: .4;
    }
    & > .ant-steps-item, & .ant-steps-item-custom {
      & .ant-steps-item-icon {
        background-color: transparent;
        border-width: 0;
      }
      & > .ant-steps-item-container * {
        & .ant-steps-item-title, & .ant-steps-icon {
          color: white !important;
          font-weight: 600;
        }
      }
    }
  }
  &.ant-steps-navigation .ant-steps-item {
    &::after {
      border: 1px solid white;
      border-bottom: none;
      border-left: none;
    }
    &::before {
      background-color: white;
    }
  }
`

export const HeaderExtra = styled(ContentCentered)`;
  color: white;
  float: right;
  margin-left: auto;
  padding: 0 10px;
  width: auto;
  ${props => props.ghost && 'opacity: .5'}
`

export const CustomText = styled.div`
  ${props => props.display && `display: ${props.display}`};
  transition-duration: .25s;
  font-size: ${props => {
    switch (props.size){
      case 'extraLarge':
        return '18px'
      case 'large':
        return '16px'
      case 'small':
        return '12px'
      case 'extraSmall':
        return '10px'
      case 'inherit':
        return 'inherit'
      default:
        return '14px'
    }
  }};
  color: ${props => props?.color ? props.color : 'inherit'};
  font-weight: ${props => props.weight || 'inherit'};
  ${props => props.disabled && `opacity: .5`};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.required && Required};
`

// View Properties

export const ViewPropertiesBody = styled(FullHeight)`
  padding: 39px;
  overflow-y: scroll;
`

export const CustomViewPropertiesForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: max(50vw, 1400px);
  .ant-form-item {
    padding-bottom: 24px;
    margin-bottom: unset;
    .ant-form-item-explain {
      padding-top: 12px;
      .ant-form-item-explain-error{
        color: #BA1B1B;
      }
    }
  }
`
