import { hashHistory } from '../../HistoryProvider';
import { showMessage } from '../../../utils/appHelper';
import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function createTemplate(params) {
  return function () {
    return api.post('/rights/templates',{ ...params })
    .then(
      response => {
        if(response.status === 201){

          hashHistory.push('/right-templates');
          
          setTimeout(()=>{
            showMessage('success', __('Template created successfully'));
            },
            200
          );
        }
      }
    );
  };
}

export function updateTemplate(params) {
  return function () {
    return api.put('/rights/templates/'+params.shortName,{...params})
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Template updated successfully'));
            },
            200
          );
        }
      }
    );
  };
}

export function deleteTemplate(model) {
  return function () {
    return api.delete('/rights/templates/'+model.shortName)
    .then(
      response => {       
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Template deleted successfully'));
            },
            200
          );
        }
      }
    );
  };
}

export function restoreTemplate(shortName) {
  return function () {
    return api.put('/rights/templates/'+shortName, {
      status: 'enabled'
    })
    .then(
      response => {       
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Template enabled successfully') + '!');
            },
            200
          );
        }
      }
    );
  };
}
