import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import React from "react";

type MyProfileProps = {
  mode?: 'edit' | 'delete'
}

const MyProfile = (props: MyProfileProps) => {
  const { mode = 'view' } = props

  const userData = useSelector((state: { user: Record<string, any> }) => state.user.data)

  return <Redirect
    to={{
      pathname: `/users/${userData?.id}${mode ? `/${mode}` : ''}`
    }}
  />

}

export default MyProfile
