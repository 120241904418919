import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { message } from "antd";

import ConditionalWrapper from "../../../../../../ConditionalWrapper";
import RemoteObjectForm from "./RemoteObjectForm";
import { SmartFooter } from "../../../../../../SmartFooter";
import * as S from "../../../../../../../providers/StyleProvider/styles";
import * as C from "../../constants";
import _ from "lodash";
import { __ } from '../../../../../../../utils/translationUtils'

export default (props) => {
  const {
    wrapperProps,
    ...dataFormProps
  } = props

  const {
    type = C.wrapperType.DEFAULT,
    title,
    onCancel,
    containerRef: externalContainerRef,
    placement
  } = wrapperProps || {}

  const {
    mode,
    footerOkLabel,
    methods,
    objectId,
    objectTypeId,
    attributeFields,
  } = dataFormProps || {}

  const {
    fetchRemoteObject,
    updateItem,
    onSubmitCallback,
  } = methods || {}

  const [loading, setLoading] = useState(false)
  const [remoteObjectInstance, setRemoteObjectInstance] = useState(null)

  const formRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(async () => {
    if (!objectId) return
    fetchRemoteObject(objectId).then((res) => {
      setRemoteObjectInstance(res)
    })
  }, [objectId])

  const handleSubmit = useCallback(() => {
    if (!formRef?.current) return

    const attributeData = remoteObjectInstance?.displayData

    const formValues = formRef.current.getFieldsValue()
    const editedFields = Object.keys(formValues).reduce((prev, id) => {
      if (!_.isEqual(formValues[id], attributeData?.[id])) prev[id] = formValues[id]
      return prev
    }, {})
    if (!Object.keys(editedFields).length) {
      message.warning(__(`You have not ${mode === C.formMode.CREATE ? 'filled in' : 'edited'} any fields`));
      return
    }
    const payload = {
      id: objectId || 1,
      displayData: editedFields,
      accessRightsInfo: {
        read: true,
        edit: true
      },
    }
    setLoading(true)
    updateItem?.({ mainKey: objectId, payload })
      .then((newItemID) => {
        onSubmitCallback?.(objectId || newItemID, mode)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [onSubmitCallback, updateItem, objectId, remoteObjectInstance, mode])

  const footer = useMemo(() => {
    return (
      <SmartFooter
        onReset={onCancel}
        resetLabel={__('Cancel')}
        onSubmit={mode === C.formMode.VIEW ? onCancel : handleSubmit}
        submitLabel={__(footerOkLabel)}
      />
    )
  }, [mode, onCancel, handleSubmit])

  useEffect(() => {
    if (mode === C.formMode.CREATE) return setLoading(false)
    if (mode !== C.formMode.CREATE && remoteObjectInstance?.displayData) return setLoading(false)
  }, [mode, remoteObjectInstance])

  return (
    <ConditionalWrapper
      type={type}
      title={__(title)}
      size={'large'}
      fixedheigth={true}
      centered
      placement={placement}
      visible
      onSubmitCallback={mode === C.formMode.VIEW ? onCancel : handleSubmit}
      onCancel={onCancel}
      footer={footer}
      containerRef={externalContainerRef || containerRef}
    >
      {
        loading || (mode !== C.formMode.CREATE && !remoteObjectInstance?.displayData)
          ?
          <S.OverlayFullSpaceSpin />
          :
          <RemoteObjectForm
            key={objectId}
            {...dataFormProps}
            formRef={formRef}
            objectId={objectId}
            objectTypeId={objectTypeId}
            attributeFields={attributeFields}
            initialValues={remoteObjectInstance?.displayData}
            mode={mode}
            containerProps={{
              containerRef: externalContainerRef || containerRef,
              contentType: C.wrapperType.CONTENT,
            }}
          />
      }
    </ConditionalWrapper>
  )
}
