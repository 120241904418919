import React, {useCallback, useState} from 'react'

import _ from 'lodash';
import TreeNodeListSelector from 'components/dist/TreeNodeListSelector'

import {useConfigurationConfiguratorContext} from "../../context";
import {filterNodeByItemsKey, getFlowsSummaryTagProps} from "../../utils";
import { __ } from '../../../../utils/translationUtils'

const ConfiguratorHelper = (props) => {

  const {
    treeItems,
    getItemFormInitialValue,
    formItems,
    formRef,
    onFormOpen: onFormOpenCallback,
    onFormChange: onFormChangeCallback,
    pinnedKeys,
    left,
    right,
    labels,
  } = props

  const {
    state,
    editStepConfiguration
  } = useConfigurationConfiguratorContext()
  const {
    activeStep,
    configurations,
    loading,
    flowOptions,
    apis
  } = state || {}

  const value = configurations?.[activeStep]
  const [flowAttributes, setFlowAttributes] = useState({ flows: [], attributes: [] })

  const onFormOpen = useCallback((item) => {
    formRef?.current?.setFieldsValue(value[item.key])
    onFormOpenCallback?.(item, value, formRef)
  }, [formRef, value, onFormOpenCallback])

  const debouncedSubmitValues = useCallback(
    _.debounce((newValue) => (newValue && editStepConfiguration(newValue)), 300), [],
  );

  const onFormChange = useCallback((item, changes) => {
    const newValue = onFormChangeCallback?.(item, changes, value, formRef) || { ...value[item.key], ...changes }
    debouncedSubmitValues({
      ...value,
      [item.key]: newValue,
    })
  }, [onFormChangeCallback, value, formRef, debouncedSubmitValues])

  const filterTreeByFlows = useCallback(async (tree, flows) => {
    if (!flows?.length) return tree
    if (_.isEqual(flowAttributes.flows, flows)) return filterNodeByItemsKey(tree, flowAttributes.attributes)
    const attributes = await apis.getAttributesByFlows?.(flows) || []
    setFlowAttributes({ flows, attributes })
    return filterNodeByItemsKey(tree, attributes)
  }, [flowAttributes, apis])

  return (
    <TreeNodeListSelector
      value={value}
      onChange={editStepConfiguration}
      treeItems={treeItems}
      filters={{
        customItems: [
          {
            name: 'flows',
            label: __('flows'),
            summaryTag: getFlowsSummaryTagProps(flowOptions),
            initialValue: [],
            filterMethod: filterTreeByFlows,
            type: 'select',
            items: flowOptions,
            mode: "multiple",
            placeholder: __('flows_selection'),
          },
        ]
      }}
      getItemInitialValue={getItemFormInitialValue}
      loading={loading}
      formProps={{
        formRef,
        onOpen: onFormOpen,
        onChange: onFormChange,
        items: formItems,
      }}
      sortIndexName="index"
      left={left}
      right={right}
      pinnedKeys={pinnedKeys}
      labels={{
        checkAll: __('select_all_items'),
        pinnedItems: __('show_pinned_items'),
        noTreeDataTitle: __('attributes_no_data_title'),
        noTreeDataContent: __('attributes_no_data_content'),
        results: __('tot_found_attributes'),
        search: __('search_items'),
        filters: __('filters'),
        filtersReset: __('reset'),
        filtersApply: __('apply'),
        nodeSelector: __('system_object_selector_name'),
        nodeSelectorPlaceholder: __('system_object_selector_placeholder'),
        appliedFilters: __('applied_filters'),
        ...labels
      }}
    />
  )
}

export default ConfiguratorHelper
