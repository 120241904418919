import React from 'react'
import {Field} from "redux-form";
import i18next from "i18next";
import PropTypes from 'prop-types';

import {renderField} from "../utils/fieldValidator";
import DisplayFieldCard from "./DisplayFieldCard";
import { __ } from '../utils/translationUtils'

function createOption(value, label) {
  return {
    value,
    label
  }
}
class PropertyLabelTitle extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount() {
    this.getItems();
  }

  getItems() {
    let translations = i18next?.options.resources.language?.translation
    if (!translations) {
      return
    }

    const items = Object.entries(translations).map(e => createOption(e[0], e[1]));

    this.setState({
      items: items
    });
  }

  render() {
    const {attribute} = this.props;

    return (
      <Field
        name={`${attribute.attributeId}_${this.props.type}_propertyLabel`}
        component={renderField}
        label={__('Custom Title')}
        className="form-control"
        type="select"

        withFilter

        items={[
          <option key="option--0" value="">{__('Select one')}</option>
        ].concat(this.state.items.map((item, k) => {
          return <option key={'opt' + k} value={item.value}>{item.label}</option>;
        }))}
      />
    )

  }

}

DisplayFieldCard.propTypes = {
  type: PropTypes.string.isRequired,
  attribute: PropTypes.object.isRequired
};


export default PropertyLabelTitle
