import React from 'react';
import PropTypes from 'prop-types';
import {sortEnumItems} from "../../utils/enums";
import {Field} from 'redux-form';
import {renderField, stepListBoxFieldRequired} from "../../utils/fieldValidator";
import { __ } from '../../utils/translationUtils'
import { getSelectorControllerItems } from '../../utils/appHelper'

class PopupListBoxControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      enumValues: []
    }
  }

  stepValidation() {

    const {controlData} = this.props;
    let validation = [];

    if (controlData.required) {
      validation.push(stepListBoxFieldRequired);
    }

    if (validation.length == 0) {
      return undefined;
    }


    return validation;
  }

  componentDidMount() {
    this.initializeEnumValues()
  }

  async initializeEnumValues() {
    const {controllerOptions, attributes} = this.props.controlData;
    const orderingType = controllerOptions?.sortOptions?.ordering
    const options = await getSelectorControllerItems(controllerOptions, attributes)
    const sortedItems = sortEnumItems(options, orderingType)
    this.setState({enumValues: sortedItems})
  }

  render() {

    const {controlData} = this.props;
    const req = controlData.required ? '*' : '';

    return (
      <Field
        validate={this.stepValidation.bind(this)()}
        name={controlData.id}
        containerClassName="label-control"
        description={controlData.description}
        label={__(controlData.title) + req}
        component={renderField}
        type={"select"}
        className={controlData.readOnly ? "" :"form-control"}
        disabled={controlData.readOnly}
        items={[
          <option key="default" value={""}>{controlData.readOnly ? "" : __('Select one')}</option>,
          ...(this.state.enumValues || []).map(o => <option value={o.value} key={o.value}>{__(o.translationKey ? o.translationKey : o.value)}</option>)
        ]
        }
      />

    );
  }

}

export default PopupListBoxControl;
