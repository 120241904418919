import * as C from '../constants'
import * as T from './types'

export const setMode = (payload, dispatch) => {
  dispatch({
    type: T.SET_MODE,
    payload,
  })
}

export const initializeAction = (payload, dispatch) => {
  const {
    mode,
    tree,
    systems,
    valueTypesMap,
    configuration,
    entities,
  } = payload || {}
  if (
    !tree ||
    !systems ||
    !valueTypesMap ||
    !entities ||
    (mode !== C.mode.CREATE && !configuration)
  ) return { type: T.NOT_INITIALIZED }
  dispatch({
    type: T.INITIALIZED,
    payload,
  })
}

export const updateEnumerations = (payload, dispatch) => {
  dispatch({
    type: T.UPDATE_ENUMS,
    payload
  })
}

export const setConfigurations = (payload, dispatch) => {
  if (!payload) return null
  dispatch({
    type: T.SET_CONFIGURATIONS,
    payload,
  })
}

export const setActiveStepAction = (payload, dispatch) => {
  document.documentElement.scrollTop = 0;
  dispatch({
    type: T.SET_ACTIVE_STEP,
    payload,
  })
}

export const shiftActiveStepAction = (payload, dispatch) => {
  document.documentElement.scrollTop = 0;
  dispatch({
    type: T.SHIFT_ACTIVE_STEP,
    payload,
  })
}

export const setValidationVisibilityAction = (payload, dispatch) => {
  dispatch({
    type: T.SET_VALIDATION_VISIBILITY,
    payload
  })
}

export const editStepConfigurationAction = (payload, dispatch) => {
  dispatch({
    type: T.EDIT_STEP_CONFIGURATION,
    payload,
  })
}

export const editStepConfigurationAttributeAction = (payload, dispatch) => {
  dispatch({
    type: T.EDIT_STEP_CONFIGURATION_ATTRIBUTE,
    payload,
  })
}

export const editStepConfigurationAttributesAction = (payload, dispatch) => {
  dispatch({
    type: T.EDIT_STEP_CONFIGURATION_ATTRIBUTES,
    payload,
  })
}

export const setSystem = (payload, dispatch) => {
  dispatch({
    type: T.CHANGE_SYSTEM,
    payload,
  })
}

export const setObject = (payload, dispatch) => {
  dispatch({
    type: T.CHANGE_OBJECT,
    payload,
  })
}

export const connectValidationReference = (payload, dispatch) => {
  if (!Object.keys(payload)?.length) return
  dispatch({
    type: T.CONNECT_VALIDATION_REFERENCE,
    payload
  })
}

export const updateDefaultConfigurationInfo = (payload, dispatch) => {
  dispatch({
    type: T.UPDATE_DEFAULT_CONFIGURATION_INFO,
    payload
  })
}
