import React from 'react';
import {Link} from "react-router-dom";
import {Popover} from "antd";

import { __ } from '../../utils/translationUtils'

const OrderTypesROField = (props) => {
  const {orderTypes} = props;

  let popoverContent;
  if (orderTypes) {
    popoverContent = (
      <div>
        {
          orderTypes.map((orderType) => (
            <p>
              <Link to={`/orderTypes/${orderType?.id}/edit`}> {orderType?.name} </Link>
            </p>
          ))
        }
      </div>
    )
  }

  return (
    <div>
      {
        (orderTypes?.length > 1) ? (
          <Popover content={popoverContent} title={__("Order Types")} trigger="hover" placement="topLeft">
            <p className="form-control-static"> <a> {`${orderTypes.length} ${__("order types")}`} </a> </p>
          </Popover>
        ) : (
          <p className="form-control-static">
            {(orderTypes?.length === 1) ?
              <Link to={`/orderTypes/${orderTypes[0]?.id}/edit`}> {orderTypes[0]?.name} </Link> : "--"}
          </p>
        )
      }
    </div>
  )
};

export default OrderTypesROField;
