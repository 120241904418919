import {useQuery} from "react-query";
import {fetchUsers, FetchUsersProps, fetchUsersDashboards} from "../../ApiProvider";
import config from "../config";

export const UseUsersQuery = (props: FetchUsersProps = {}) => useQuery<User[]>({
  queryKey: ["fetchUsers"],
  queryFn: () => fetchUsers(props),
  ...config
})

export const UseUsersDashboardsQuery = (userId: string) => useQuery<unknown>({
  queryKey: ["fetchUsersDashboards", userId],
  queryFn: () => userId ? fetchUsersDashboards(userId) : Promise.resolve(undefined),
  ...config
})
