import React from 'react';
import PropTypes from 'prop-types';
import { sortBy} from 'lodash';

import Amount from './Amount';
import { __ } from '../utils/translationUtils'


class BillingOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      source: (props.source) ? JSON.parse(props.source) : [],
    };
    this.initialValues = this.generateInitialValues();
    this.calculateSummary = this.calculateSummary.bind(this);
  }

  generateInitialValues() {
    const { source } = this.state;
    const values = {};


    try {
      source.forEach((category) => {
        category.billingItems.forEach(item => {
          values[`${item.priceElementID}_quantity`] = item.quantity || 0;
          values[`${item.priceElementID}_unitPrice`] =  parseFloat(item.unitPrice || 0).toFixed(2);
          values[`${item.priceElementID}_comment`] =  item.comment || '';
        });

        category.subCategories.forEach((cat) => {
          cat.billingItems.forEach(item => {
            values[`${item.priceElementID}_quantity`] = item.quantity || 0;
            values[`${item.priceElementID}_unitPrice`] =  parseFloat(item.unitPrice || 0).toFixed(2);
            values[`${item.priceElementID}_comment`] =  item.comment || '';
          });

          cat.subCategories.forEach((cat) => {
            cat.billingItems.forEach(item => {
              values[`${item.priceElementID}_quantity`] = item.quantity || 0;
              values[`${item.priceElementID}_unitPrice`] =  parseFloat(item.unitPrice || 0).toFixed(2);
              values[`${item.priceElementID}_comment`] =  item.comment || '';
            });

            cat.subCategories.forEach((cat) => {
              cat.billingItems.forEach(item => {
                values[`${item.priceElementID}_quantity`] = item.quantity || 0;
                values[`${item.priceElementID}_unitPrice`] =  parseFloat(item.unitPrice || 0).toFixed(2);
                values[`${item.priceElementID}_comment`] =  item.comment || '';
              });
            });
          });
        });
      });

      return values;
    }catch (e) {
      return null;
    }

  }



  calculateSummary() {
    const { source } = this.state;
    let total = 0;
    let subSummaries = [];

    const calculateItemSummary = (item,category) => {
      if(!item.priceElementDetailSignum || item.priceElementDetailSignum === "DEBIT") {
        total = total + (item.quantity * item.unitPrice);
        subSummaries[category.categoryName] = (subSummaries[category.categoryName] || 0) + (item.quantity * item.unitPrice);
      }
      if(item.priceElementDetailSignum === "CREDIT") {
        total = total - (item.quantity * item.unitPrice);
        subSummaries[category.categoryName] = (subSummaries[category.categoryName] || 0) - (item.quantity * item.unitPrice);
      }

    };
    const calculateCategorySummary = (parent, child) => {
      subSummaries[parent.categoryName] = (subSummaries[parent.categoryName] || 0) + (subSummaries[child.categoryName] || 0);
    };

    try {
      source.forEach((category) => {
        category.billingItems.forEach(item => {
          calculateItemSummary(item,category);
        });
        category.subCategories.forEach((cat1) => {
          cat1.billingItems.forEach(item => {
            calculateItemSummary(item,cat1);
          });
          cat1.subCategories.forEach((cat2) => {
            cat2.billingItems.forEach(item => {
              calculateItemSummary(item,cat2);
            });
            cat2.subCategories.forEach((cat3) => {
              cat3.billingItems.forEach(item => {
                calculateItemSummary(item,cat3);
              });
              cat3.subCategories.forEach((cat4) => {
                cat4.billingItems.forEach(item => {
                  calculateItemSummary(item,cat4);
                });
                cat4.subCategories.forEach((cat5) => {
                  cat5.billingItems.forEach(item => {
                    calculateItemSummary(item,cat5);
                  });
                  calculateCategorySummary(cat4,cat5);
                });
                calculateCategorySummary(cat3,cat4);
              });
              calculateCategorySummary(cat2,cat3);
            });
            calculateCategorySummary(cat1,cat2);
          });
          calculateCategorySummary(category,cat1);
        });
      });
      return { total, subSummaries };
    }catch (e) {
      return {};
    }

  }

  render() {
    const { source } = this.state;
    let { configuration } = this.props;

    const renderItem = (item) => {
      if (item.quantity === 0) {
        return null;
      }

      return (
        <tr>
          <td>
            <div className="billing-item__name">
              {__(item.priceElementName)}
            </div>
            { item.comment && item.comment !== '' && (
              <div className="discreet">
                {item.comment}
              </div>
            )}
          </td>
          <td>
            <div className="col-xs-12 col-sm-8 col-lg-6 pull-right">
              {item.quantity}
            </div>

          </td>
          <td className="text-right">
            <Amount
              decimalPrecision={2}
              value={item.unitPrice}
            />
          </td>
          <td className="text-right">
            <Amount
              decimalPrecision={2}
              value={item.unitPrice * item.quantity}
            />
          </td>
        </tr>
      );
    };

    const renderItems = (items) => {
      return items && items.map((item) => {
        return renderItem(item);
      });
    };

    const renderCategory = (category, i) => {
      let cats =  category.subCategories && sortBy(category.subCategories, (c) => c.categorySortOrder).map((category) => {
        return renderCategory(category, i+1);
      });

      let categorySubtotal = null;

      if(subSummaries[category.categoryName]) {
        categorySubtotal = (
          <Amount
            className={(i > 0) ? "categorySubTotal" : "subTotal"}
            decimalPrecision={2}
            value={subSummaries[category.categoryName]}
          />
        );
      }
      const rows = [
        <tr key="table-header">
          <td>
            <h5 className={(i>0)?"subCategoryName":"categoryName"}>{__(category.categoryName)}</h5>
          </td>
          <td colSpan="2"/>
          <td className="text-right">
            {categorySubtotal}
          </td>
        </tr>,
        renderItems(sortBy(category.billingItems, (i) => i.priceElementSortOrder)),
        cats,
        <tr className="blank_row" key="table-row">
          <td className="blank_row" colSpan="4" />
        </tr>
      ];

      return rows;
    };

    const { total, subSummaries } = this.calculateSummary();
    if (!this.initialValues){
      return (
        <h3>{__('Billing Source not valid format')}</h3>
      )
    }

    return (
      <div className="form-group col-xs-12">
        <label className="control-label">
          {__(configuration.propertyLabel)}:
        </label>
        <form>
          <table className="pricelist">
            <tbody>
              <tr>
                <th />
                <th className="text-right" width={200}>
                  {__('Quantity')}
                </th>
                <th  className="text-right" width={160}>{__('Unit Price')}</th>
                <th  className="text-right" width={170}>{__('Price')}</th>
              </tr>

              {source && source.map((category) => {
                return renderCategory(category,0);
              })}

              <tr className="summary">
                <td colSpan="2"/>
                <td className="total text-right" colSpan="1">
                  {__('Total')}:
                </td>
                <td className="total text-right">
                  <Amount decimalPrecision={2} value={total} />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    );

  }
}
BillingOverview.propTypes = {
  source: PropTypes.string,
  values: PropTypes.object,
  configuration: PropTypes.object,
};

export default BillingOverview;
