import React, {useState} from "react";

import PickStatusModal from './PickStatusModal'
import ReassignModal from "./ReassignModal";
import PasswordConfirmation from "./PasswordConfirmation";


type StatusChangeProps = {
  user: User,
  isOwnUser: boolean
  submit: (user: User, status: Statuses, newUserId?: string) => void
  close: () => void
}

export const statusToAction = (status: Statuses): ChangeStatusAction => {
  switch (status) {
    case "disabled": return "disable"
    case "deleted": return "delete"
    case "enabled":
    default:
      return 'enable'
  }
}

const StatusChange = ({user, isOwnUser, submit, close}: StatusChangeProps) => {
  const [statusConfirmation, setStatusConfirmation] = useState<Statuses | undefined>(undefined)
  const [newAssignee, setNewAssignee] = useState<string | undefined>(undefined)
  const [needPasswordConfirmation, setNeedPasswordConfirmation] = useState<boolean | undefined>(false)

  return (
    <>
      {
        !statusConfirmation && (
          <PickStatusModal
            user={user}
            submit={(status) => {
              if (status === 'enabled') submit(user, status)
              else setStatusConfirmation(status)
            }}
            close={close}
          />
        )
      }
      {
        statusConfirmation && !needPasswordConfirmation && (
          <ReassignModal
            user={user}
            newStatus={statusConfirmation}
            currentAssignee={user.deputy}
            submit={(newUserId) => {
              setNewAssignee(newUserId)
              if(!isOwnUser) submit(user, statusConfirmation, newUserId)
              else setNeedPasswordConfirmation(true)
            }}
            close={() => setStatusConfirmation(undefined)}
          />
        )
      }
      {
        statusConfirmation && needPasswordConfirmation && (
          <PasswordConfirmation
            user={user}
            submit={() => {
              submit(user, statusConfirmation, newAssignee)
            }}
            close={() => setNeedPasswordConfirmation(undefined)}
            />
        )
      }
    </>
  )
}

export default StatusChange
