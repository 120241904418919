import React, { useState } from 'react'
import { Badge } from 'antd'
import { Button, Sidebar } from "components";


export default (props) => {
  const {
    forceRender,
    title,
    placement = 'left',
    children,
    buttonLabel,
    buttonProps,
    className,
    forceOpen,
    setForceOpen,
    footer,
    style,
    width = 400,
    buttonBadgeCount,
    onSubmitCallback,
    onCloseCallback,
    useButtonStyle = true,
    noSpacing,
    containerRef
  } = props || {}

  const [drawerOpen, setDrawerOpen] = useState(false)
  const open = forceOpen == null ? drawerOpen : forceOpen
  const setOpen = setForceOpen || setDrawerOpen

  return (
    <>
      <Badge count={buttonBadgeCount} offset={[-10, 0]} size="small">
        {
          useButtonStyle ?
            (<Button
              type={'default'}
              backgroundColor={'#FFFFFF'}
              borderColor={'#72777F'}
              color={'#00629D'}
              onClick={() => { setOpen(true) }}
              title={title}
              {...buttonProps}
            />) :
            (<div onClick={() => { setOpen(true) }}>
              {buttonLabel || title}
            </div>)
        }
      </Badge>
      <Sidebar
        title={title}
        className={className}
        style={style}
        placement={placement}
        visible={open}
        closable
        onEnter={onSubmitCallback}
        onClose={() => {
          setOpen(false)
          if (onCloseCallback?.()) onCloseCallback()
        }}
        getContainer={containerRef?.current || false}
        width={width}
        footer={footer || false}
        forceRender={forceRender}
        noSpacing={noSpacing}
      >
        {children}
      </Sidebar>
    </>
  )
}
