export default {
  ACTION: "0",
  ASSIGN_TO: "1",
  GO_TO: "2",
  LOCAL_FIELD_DATA: "3",
  LOCAL_BUTTON_ACTION_DATA: "5",
  LOCAL_ORDER_DATA: "7",
  LOCAL_PROCESS_DATA: "8",
  LOCAL_STEP_DATA: "9",
  LOCAL_USER_DATA: "10",
  SOUTH_BRIDGE_DATA: "11",
  STATIC_DATA: "12",
  GO_TO_IF_GREATER: "13",
  GO_TO_IF_GREATER_EQUALS: "14",
  GO_TO_IF_EQUALS: "15",
  GO_TO_IF_TRUE: "16",
  NEGATE: "17",
  CANCEL: "18",
  REJECT: "19",
  COMPLETE: "20",
  MESSAGE: "21",
  ALERT: "22",
  SET_BILLABLE: "23",
  SET_INTERNAL_ORDER_STATUS: "24",
  SET_INTERNAL_PROCESS_STATUS: "25",
  SET_ORDER_STATUS: "26",
  SET_PROCESS_STATUS: "27",
  SET_TOKENS: "28",
  SET_ON_HOLD: "29",
  SEND_EMAIL: "30",
  SETTINGS_DATA: "31",
  FORMAT_STRING: "32",
  AND: "33",
  EQUALS: "34",
  GREATER: "35",
  GREATER_EQUALS: "36",
  OR: "37",
  XOR: "38",
  SKIP_SAVE: "39",
  SKIP_SAVE_IF_TRUE: "40",
  BACK: "41",
  SET_PARENT_OWNERSHIP: "42",
  RESTART_PARENT_PROCESS: "43",
  RETURN: "44",
  SET_EXTERNAL_ORDER_STATUS: "45",
  CURRENT_DATETIME_DATA: "46",
  POP_PROCESS_STACK: "47",
  LOCAL_ENTITY_ORDER_DATA: "48",
  SET_PROCESS_NEXT_ACTION_DATE: "49",
  RESET_APPOINTMENT: "50",
  SET_EDITABLE: "51",
  REASSIGN_PROCESS: "52",
  GO_TO_NEXT_PROCESS: "53",
  BINARY_DATA: "54",
  DOCUMENT_GENERATOR_DATA: "55",
  SET_REMOTE_VALUE: "56",
  FIRE_BILLING_EVENT: "57",
  CREATE_REMOTE_OBJECT_FROM_WORKSHEET: "58",
  IF: "59",
  CREATE_WORKSHEET_FROM_REMOTE_OBJECT: "60",
  ASSIGN: "61",
  REMOTE_OBJECT_BINDING: "62",
  RESET: "63",
  SET_MASTER: "64",
  STRING_UTILS_JOIN: "80",
}
