const viewsReports = {
  // BATCH ACTIONS
  assign: 'assign',
  cancel: 'cancel',
  archive: 'archive',
  delete: 'delete',
  hard_delete: 'hard_delete',

	// PROGRESS BAR LABEL
	order_actions_progress_message: "order_actions_progress_message",

  // CONFIRMATION MODAL
  batch_action_confirmation_title: 'batch_action_confirmation_title',

	checking_eligible_orders: "checking_eligible_orders",

	orders_cannot_be_processed: "orders_cannot_be_processed",

	orders_being_cancelled_warning: 'orders_being_cancelled_warning',
  orders_being_archived_warning: 'orders_being_archived_warning',
	orders_being_deleted_warning: 'orders_being_deleted_warning',
	orders_being_hard_deleted_warning: 'orders_being_hard_deleted_warning',

  non_reversible_action: 'non_reversible_action',

  // ACTION STATUS PANEL

	// ARCHIVE
	ready_to_archive: "ready_to_archive",
	archiving: "archiving",
	archived: "archived",
	archiving_failed: "archived_failed",

	// CANCEL
	ready_to_cancel: "ready_to_cancel",
	cancelling: "cancelling",
	cancelled: "cancelled",
	cancelling_failed: "cancelled_failed",

	// DELETE
	ready_to_delete: "ready_to_delete",
	deleting: "deleting",
	deleted: "deleted",
	deleting_failed: "deleted_failed",

	// HARD DELETE
	ready_to_hard_delete: "ready_to_hard_delete",
	hard_deleting: "hard_deleting",
	hard_deleted: "hard_deleted",
	hard_deleting_failed: "hard_deleted_failed",
}

export default viewsReports
