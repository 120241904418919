import { mapCommentType } from "../../../utils/appHelper";
import { api } from "../../../providers/ApiProvider";

import { visibilityType } from "./constants";
import { __ } from '../../../utils/translationUtils'


export const getCommentTypeDescription = (visibility, entity) => {
  switch (visibility) {
    case visibilityType.GLOBAL:
      return "This comment is visible by everyone";
    case visibilityType.ENTITY:
      if(entity && entity.type && entity.name && entity.shortName){
        return "This comment can be seen only by users that see the "+__(entity.type)+" "+entity.shortName+" ("+entity.name+")";
      } else {
        return "This comment can be seen only by users that see a specified company or user group";
      }
    case visibilityType.SELF:
    default:
      return "This comment is only visible to you";
  }
}

export const getCommentTypeIcon = (visibility) => {
  switch (visibility) {
    case visibilityType.GLOBAL:
      return "globe";
    case visibilityType.ENTITY:
      return "briefcase";
    case visibilityType.SELF:
    default:
      return "eye-close";
  }
}

export const getVisibilityTypeTree = (entities) => ([
  {
    value: visibilityType.GLOBAL,
    label: __(mapCommentType(visibilityType.GLOBAL)),
  },
  {
    value: visibilityType.SELF,
    label: __(mapCommentType(visibilityType.SELF)),
  },
  {
    value: visibilityType.ENTITY,
    label: __(mapCommentType(visibilityType.ENTITY)),
    children: entities?.map(entity => (entity.type !== 'user_group') && ({
      value: entity.id,
      label: entity.name
    })).filter((e) => e) || []
  },
])

export const fetchMessages = ({ objectCaller, modelCaller }) => {
  const req = `/${modelCaller}/${objectCaller.id}/comments?sort=-lastUpdateDatetime`
  return api.get(req)
}

export const fetchEntities = () => {
  const req = '/entities?pageSize=200&status=enabled,reserved'
  return api.get(req)
}
