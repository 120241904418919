import React from "react";

import { Button } from "components";

import { __ } from '../../../utils/translationUtils'

export default ({ onSubmit, onClose }) => (
  <>
    <Button
      title={__('Close')}
      onClick={onClose}
    />
    <Button
      title={__('Create')}
      type='primary'
      onClick={onSubmit}
    />
  </>
)
