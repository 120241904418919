import styled from "styled-components";
import { Progress } from "antd";

export const CustomProgress = styled(Progress)`
  &.ant-progress {
    ${(props) => props.isButton ? 'position: absolute; top: 0; left: 0;' : ''};
    width: 100%;
    height: 100%;
    background-color: #FFFFFF${(props) => !props.showInfo ? '80' : ''};
    ${(props) => props.type === 'circle' && 'border-radius: 100%;'};
    cursor: default;
    .ant-progress-circle-path {
      ${(props) => props.gapDuration && `transition: ${props.gapDuration}s ease, opacity .25s ease 0s !important;`};
      ${(props) => props.percent >= 100 && 'transition-duration: .5s !important;'};
    }
  }
`
