import React, {useCallback, useRef, useState} from 'react'

import * as U from "../../../ConfigurationConfiguratorBeta/utils"
import ConfiguratorHelper from './ConfiguratorHelper';
import {useConfigurationConfiguratorContext} from "../../context";
import {useTranslationList} from "../../../../hooks";
import { __ } from '../../../../utils/translationUtils'

const Display = ({ treeItems }) => {
  const { state } = useConfigurationConfiguratorContext()
  const {
    activeStep,
    validationState,
    validationVisibility,
    attributesMap,
    valueTypesMap,
  } = state || {}

  const [formItems, setFormItems] = useState(null)
  const translationItems = useTranslationList()
  const formRef = useRef(null)

  const getItemFormInitialValue = useCallback((key) => {
    const attribute = attributesMap[key]
    return {
      defaultControllerType: attribute?.type && valueTypesMap[attribute.type].defaultControllerType,
      defaultCustomTitle: attribute?.propertyLabel,
      customTitle: null,
      summary: false,
    }
  }, [valueTypesMap, attributesMap])

  const onFormOpen = useCallback((item, values) => {
    const itemValue = values[item.key]
    setFormItems([
      {
        name: 'controllerType',
        label: __('controller_type'),
        type: 'select',
        span: 12,
        placeholder: itemValue.defaultControllerType || 'Select a controller type',
        items: U.getValuesListOptions(valueTypesMap?.[item.type]?.availableControllerTypes),
        disabled: !valueTypesMap?.[item.type]?.availableControllerTypes,
        showSearch: true,
        optionFilterProp: 'label',
        allowClear: true,
      },
      {
        name: 'customTitle',
        label: __('custom_title'),
        span: 12,
        type: 'select',
        placeholder: __(itemValue.defaultCustomTitle || 'use default'),
        items: translationItems,
        disabled: !translationItems?.length,
        showSearch: true,
        optionFilterProp: 'label',
        allowClear: true,
      },
      {
        name: 'summary',
        label: __('summary'),
        type: 'switch',
        size: 'small',
      },
    ])
  }, [valueTypesMap, translationItems])

  return (
    <ConfiguratorHelper
      treeItems={treeItems}
      formRef={formRef}
      formItems={formItems}
      onFormOpen={onFormOpen}
      getItemFormInitialValue={getItemFormInitialValue}
      labels={{
        noSelectedItemsTitle: __('output_fields_no_data_title'),
        noSelectedItemsContent: __('output_fields_no_data_content'),
      }}
      left={{
        title: __("choose_fields"),
        required: true,
        error: validationVisibility[activeStep] && validationState[activeStep] != null && !validationState[activeStep]
      }}
      right={{title: __("set_and_order_selected_fields")}}
    />
  )
}

export default Display
