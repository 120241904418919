import {addAsterisks} from "../../../utils/appHelper";
import moment from "moment";
import * as config from "../../../constants/globalConfiguration";
import { __ } from '../../../utils/translationUtils'

export const prepareDateRangePayload_applyFelixAlgorithm = (searchAttribute, attributeValueMap, attributeConfiguration) => {

  if (searchAttribute == null || attributeConfiguration == null || attributeValueMap == null) return;

  if (searchAttribute.mechanism !== 'RANGE') return

  /* Apply Felix's Algorithm on range fields
   * 1) If both *from* and *to* are filled --> normal *range* search.
   * 2) If only *from* is filled --> *specific_value* search. (the from can contain wildcard)
   * 3) if only *to* is filled the webui fills the from value with the same value contained in the *to* --> *range* search (edited)
   */

  if (attributeConfiguration.type === 'DATE') {

    attributeValueMap[attributeConfiguration.id + 'From'] = attributeValueMap[attributeConfiguration.id + 'From'] ?
      attributeValueMap[attributeConfiguration.id + 'From'].utc().format() : undefined

    attributeValueMap[attributeConfiguration.id + 'To'] = attributeValueMap[attributeConfiguration.id + 'To'] ?
      attributeValueMap[attributeConfiguration.id + 'To'].utc().format() : undefined

  }

  if (attributeValueMap[attributeConfiguration.id + 'From']) {
    searchAttribute.from = attributeValueMap[attributeConfiguration.id + 'From']
  } else {
    delete searchAttribute.from
  }

  if (attributeValueMap[attributeConfiguration.id + 'To']) {
    searchAttribute.to = attributeValueMap[attributeConfiguration.id + 'To']
  } else {
    delete searchAttribute.to
  }

  const {to, from} = searchAttribute;

  if (from != null && to == null) {
    searchAttribute.mechanism = 'SPECIFIC_VALUE'
    searchAttribute.value = from
    delete searchAttribute.from
    delete searchAttribute.to
    delete searchAttribute.multiSelect
  } else if (!from && to) {
    searchAttribute.from = to
  }
}

export const prepareSpecificValue = (searchAttribute, attributeValueMap, attributeConfiguration) => {

  if (searchAttribute == null || attributeConfiguration == null || attributeValueMap == null) return;
  if (searchAttribute.mechanism !== 'SPECIFIC_VALUE') return

  if (attributeValueMap[attributeConfiguration.id] == null) {
    delete searchAttribute.value
    return;
  }

  if (attributeConfiguration.type === 'DATE') {
    attributeValueMap[attributeConfiguration.id] = attributeValueMap[attributeConfiguration.id].utc().format();
    searchAttribute.value = attributeValueMap[attributeConfiguration.id]
  } else if (searchAttribute.value === 'true') searchAttribute.value = true
  else if (searchAttribute.value === 'false') searchAttribute.value = false
  else {
    searchAttribute.value = addAsterisks(attributeValueMap[attributeConfiguration.id])
  }

}

export const prepareEnumeration = (searchAttribute, attributeValueMap, attributeConfiguration) => {
  if (searchAttribute == null || attributeConfiguration == null || attributeValueMap == null) return;
  if (searchAttribute.mechanism !== 'ENUMERATION') return

  if (attributeValueMap[attributeConfiguration.id] == null) {
    delete searchAttribute.value
    return;
  }

  searchAttribute.value = attributeValueMap[attributeConfiguration.id]

}


export const prepareRemoteObjectsResult = (remoteObjectsResult, attributeList, objectConfiguration) => {

  if (remoteObjectsResult == null || attributeList == null || objectConfiguration == null) return [];
  const result = []
  remoteObjectsResult.forEach((rOE, rOE_Index) => {
    const displayDataRes = {}
    result[rOE_Index] = {...rOE, displayData: displayDataRes}
    if (!rOE.displayData) {
      return
    }

    Object.entries(rOE.displayData).forEach(([attributeId, displayData]) => {
      const attributeConfiguration = attributeList.find(a => a.id === attributeId);
      let displayAttribute = objectConfiguration.displayAttributes.find(a => a.attributeId === attributeId);

      displayDataRes[attributeId] = displayData
      if (displayData != null && attributeConfiguration.type === "YESNO") {
        displayDataRes[attributeId] = displayData.toLowerCase() === 'true' ? __('val_yes') : __('val_no');
      }
      if (displayAttribute.controllerType === "PopupListBox" || displayAttribute.defaultControllerType === "PopupListBox") {
        displayDataRes[attributeId] = displayData !== null ? __(displayData) : displayData;
      }
      if (displayAttribute.controllerType === "DateInput") {
        displayDataRes[attributeId] = displayData !== null ? moment(displayData).format(config.appDefaults.dateFormat) : displayData;
      }
      if (displayAttribute.controllerType === "DateTimeInput") {
        displayDataRes[attributeId] = displayData !== null ? moment(displayData).format(config.appDefaults.dateTimeFormat) : displayData;
      }

    })

  })

  return result;

}

export const OBJECT_BINDER_CONTROLLER_TYPE = 'ObjectBinder'
export const OBJECT_PICKER_CONTROLLER_TYPE = 'ObjectPicker'
