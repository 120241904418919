import React from 'react'
import {connect} from 'react-redux'
import {parse, stringify} from 'query-string'
import PropTypes from 'prop-types'
import {hashHistory} from '../../../providers/HistoryProvider'
import {getLanguagesWrapped, setLanguage} from '../../../providers/ReduxProvider/actions/userActions'
import * as config from '../../../constants/globalConfiguration'
import Loading from '../../Loading'
import i18next from 'i18next';
import {api} from "../../../providers/ApiProvider";
import moment from "moment";
import en from "antd/lib/locale-provider/en_GB";
import ConfigProvider from "antd/lib/config-provider";
import {langToAntdLocale, langToMomentLocale} from "../../../utils/langUtils";
import {ErrorBoundary, utils} from "components";

const { logger: { ERROR } } = utils


class Body extends React.Component {
  constructor(props) {
    super(props)
    const {location} = this.props
    this.queryString = parse(location.search)
    this.state = {
      isLoading: false,
      isTranslationLoaded: false,
      locale: undefined,
    }
  }

  componentWillMount() {
    const {getLanguagesWrapped, availableLanguages} = this.props

    if (!availableLanguages || availableLanguages?.length === 0) {
      getLanguagesWrapped();
    }
  }

  async loadLocales(language) {
    try {
      const momentLangStr = langToMomentLocale(language)
      const antdLangStr = langToAntdLocale(language)

      const momentLocale = await import(`moment/dist/locale/${momentLangStr}`)
      const antdLocale = await import(/* webpackInclude: /\.js$/ */`antd/lib/locale-provider/${antdLangStr}`)

      this.setLocale(momentLangStr, antdLocale.default, momentLocale.default)
    } catch (err) {
      const momentLocale = await import('moment/dist/locale/en-gb')
      this.setLocale('en-gb', en, momentLocale.default)
    }
  }

  setLocale(lang, antdLocale, momentLocale) {
    this.setState({locale: antdLocale})
    moment.updateLocale(lang, {
      week: {
        dow: 1,
      },
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {setLanguage, language, availableLanguages, location} = this.props;

    if (availableLanguages !== prevProps.availableLanguages) {
      // Check if language is in localstorage, if not get it form browser (navigator), if not set it to english
      // get languages codes and adding manually no translation language
      const languageCodes = availableLanguages?.map(l => l.languageCode) || []

      const storageLanguage = localStorage.getItem('lang')

      const navigatorLanguage = navigator.language && navigator.language.replace('-', '_');

      if (languageCodes.includes(storageLanguage)) {
        setLanguage(storageLanguage)
      } else if (languageCodes.includes(navigatorLanguage)) {
        setLanguage(navigatorLanguage)
      } else if (languageCodes.find(lc => (lc.substring(0, 2) === navigatorLanguage.substring(0, 2)))) {
        setLanguage(languageCodes.find(lc => (lc.substring(0, 2) === navigatorLanguage.substring(0, 2))))
      } else {
        setLanguage('en_GB')
      }
    }

    if (!i18next.store) {
      i18next.init({
        lng: "language",
        resources: {}
      })
    }

    if (language !== prevProps.language) {
      this.setState({isTranslationLoaded: false, isLoading: true})
      if (localStorage.getItem('skipTranslations') === 'true') {
        this.setState({isLoading: false})
        return
      }

      api.get(`${config.prefix()?.TRANSLATION || ''}/translations/${language}/last/export?dirty=true&sort=value&englishFallback=true`, {data: null}).then((response) => {
        const res = {}
        const responseJson = response.data
        const keyValues = Object.keys(responseJson).map((key) => {
          const newKey = key.trim().toLowerCase()
          return {[newKey]: responseJson[key]}
        })
        Object.assign(res, ...keyValues)

        i18next.addResourceBundle("language", 'translation', res, true, true);
        i18next.changeLanguage("language").then(() => {
          this.setState({isTranslationLoaded: true})
        })

      }).catch((e) => {
        ERROR(e)
      });

      this.loadLocales(language)
      api.defaults.headers.common.Language = language;

      // If language request param exists on the url, overwrite lang and remove param.
      if (this.queryString.language && this.queryString.language !== '') {
        if (typeof hashHistory.push === 'function') {
          hashHistory.push({
            ...location,
            search: stringify({
              ...this.queryString,
              language: ''
            })
          })
        }
        setLanguage(this.queryString.language)
      }
    }

    if (language === prevProps.language && !this.state.locale) {
      this.loadLocales(language)
    }

    if (this.state.isTranslationLoaded && this.state.isLoading) {
      this.setState((prevState) => ({...prevState, isLoading: false}))
    }
  }

  render() {
    const {isLoading, locale} = this.state
    const {children, layout} = this.props
    return (
      <ConfigProvider locale={locale}>
        <ErrorBoundary>
          {
            isLoading
              ? <Loading fullHeight={layout === 'full-page'} loading={isLoading}/>
              : children
          }
        </ErrorBoundary>
      </ConfigProvider>
    )
  }
}

export default connect(
  (state) => ({
    language: state.ui.language,
    availableLanguages: state.ui.availableLanguages
  }),
  {setLanguage, getLanguagesWrapped}
)(Body)

Body.propTypes = {
  layout: PropTypes.string,
  setLanguage: PropTypes.func.isRequired,
  getLanguagesWrapped: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  language: PropTypes.string,
  availableLanguages: PropTypes.instanceOf(Array),
  location: PropTypes.instanceOf(Object)
}

Body.defaultProps = {
  layout: '',
  language: null,
  location: {}
}
