import React from "react";

import {Row, Form, Col, Tooltip} from "antd";
import * as I from 'components/dist/Form/Inputs'

import * as S from "../../pages/ConfigurationConfiguratorBeta/styles";

/**
 *
 * fields: array of {
 *    name,
 *    label,
 *    tooltipProps, //props for ant tooltip component wrapping the form item
 *    itemProps: {
 *      type,
 *      ...restItemProps // props for the
 *    }
 *    ...restFormItemProps //additional props for ant form item component
 * } || {
 *   type = 'custom',
 *   children: ReactNode,
 * }
 */

const Controller = (props) => {
  const {
    type,
    CustomRender,
    ...controllerProps
  } = props
  const ControllerType = (type && I[type]) || CustomRender
  return (ControllerType && <ControllerType {...controllerProps} />)
}

const FormItem = ({ name, label, itemProps, ...restFormItemProps}) => (
  <Form.Item
    name={name}
    label={label}
    {...restFormItemProps}
  >
    <Controller {...itemProps}/>
  </Form.Item>
)

export default (props) => {
  const {
    form,
    fields = [],
    initialValues,
    onFormChange,
  } = props || {}
  return (
    <S.ViewPropertiesBody>
      <S.CustomViewPropertiesForm
        initialValues={initialValues}
        form={form}
        onValuesChange={onFormChange}
        layout='vertical'
      >
        <Row gutter={24}>
          {fields?.map((field, index) => {
            const { type, tooltipProps, colProps, children, ...rest } = field || {}
            const key = type === 'custom' ? `custom-${index}` : `form-item-${index}-${field.name}`
            if (tooltipProps) return (
              <Tooltip {...tooltipProps} key={key}>
                <Col {...colProps} key={key}>
                  { type === 'custom' ? children : <FormItem {...rest} /> }
                </Col>
              </Tooltip>
            )
            return (
              <Col {...colProps} key={key}>
                { type === 'custom' ? children : <FormItem {...rest} /> }
              </Col>
            )
          })}
        </Row>
      </S.CustomViewPropertiesForm>
    </S.ViewPropertiesBody>
  )
}
