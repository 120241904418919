import React, { useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import LayoutBody from './Body'
import logoMini from '../../../images/logo-mini.png'
import Logo from "../Logo";
import { getSystemSettingsWrapped, getInstanceSettings } from '../../../providers/ReduxProvider/actions/userActions'
import { E_GLOBE, GODOO } from "../../../constants/globalConfiguration";
import LinkImage from "components/dist/LinkImage";
import { Container, LogoWrapper, ContentWrapper, Footer } from "./styles"


const LayoutPublic = (props) => {
  const { children, getSystemSettingsWrapped, getInstanceSettings } = props

  useEffect(() => {
    getSystemSettingsWrapped()
    getInstanceSettings();
  }, [])

  return (
    <LayoutBody {...props}>
      <Container>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <ContentWrapper>
          {children}
        </ContentWrapper>
        <Footer>
          <LinkImage
            imgSrc={logoMini}
            linkHref={GODOO}
            linkTarget={"_blank"}
          />
          Powered by <a target="_blank" href={E_GLOBE} rel="noreferrer">e-globe solutions AG</a> © {moment().format('YYYY')}
        </Footer>
      </Container>
    </LayoutBody>
  )
}

export default connect(
  (state) => ({ instanceSettings: state.ui.instanceSettings }),
  { getSystemSettingsWrapped, getInstanceSettings }
)(LayoutPublic)
