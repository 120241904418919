import React, { useMemo } from 'react'

import { Popconfirm, Tooltip } from "antd";
import { MinusOutlined } from "@ant-design/icons";

import * as config from '../../../../../../../constants/globalConfiguration';
import { hashHistory } from '../../../../../../../providers/HistoryProvider';
import CustomGrid from "../../../../../../CustomGrid";
import { CardListWrapper, CardPagination, CustomCard, RemoveButton } from "../styles";
import { getItemValues } from "./utils";
import { isSelectingText } from "../../../../../../../utils/misc";
import InputSwitch from "./InputSwitch";
import { __ } from '../../../../../../../utils/translationUtils'


export default (props) => {
  const { idsToRender, values, attributes, displayAttributes, overviewConfiguration, pagination, permissions, onRemoveItem } = props

  return (<CardListWrapper>
    {idsToRender
      .filter(mk => mk)
      .map((mainKey) => {
        let summaryValues = getItemValues(values, mainKey, attributes).map((i) => i.value);
        let subObjectInfos = {
          entryId: mainKey, objectId: overviewConfiguration.id, configurationId: overviewConfiguration.objectTypeId
        }

        return (<SingleCard
          type={'ROE'}
          key={`object-${mainKey}`}
          mainKey={mainKey}
          depth={1}
          subObjectInfos={subObjectInfos}
          attributes={attributes}
          displayAttributes={displayAttributes}
          values={summaryValues}
          maxColumns={3}
          permissions={permissions}
          onRemove={(e) => {
            onRemoveItem(mainKey)
          }}
        />);
      })}
    <CardPagination {...pagination} />
  </CardListWrapper>)
}

export const SingleCard = (cardProps) => {

  const { onRemove, attributes, displayAttributes, values, mainKey, permissions, type, subObjectInfos } = cardProps

  const handleSelection = ({ configurationId, objectId, entryId }, type = '') => {
    if (!isSelectingText()) {
      hashHistory.push(`/${config.remoteObjectDetailsUrl}/${configurationId}/${objectId}/${entryId}`);
    }
  }

  const items = useMemo(() => {
    const newItems = []
    if (attributes?.length && values?.length && displayAttributes?.length) {
      for (let i = 0; i < displayAttributes.length; i++) {
        let controllerType = displayAttributes?.[i]?.controllerType || displayAttributes?.[i]?.defaultControllerType
        if (!(controllerType)) {
          console.error("It's impossible to render a field without a controller type. Defaulting to TextEntry. Please contact your administrator.")
          controllerType = 'TextEntry'
        }
        if (attributes.find(a => a.id === displayAttributes[i].attributeId)) {
          newItems.push(<InputSwitch
            label={__(attributes[i].propertyLabel)}
            type={controllerType}
            value={values[i]}
            key={attributes[i].id}
            mainKey={mainKey}
          />)
        }
      }
    }
    return newItems.filter((i) => i)
  }, [attributes, displayAttributes, mainKey, values])

  return (
    <CustomCard>
      {
        permissions.REMOVABLE && (
          <Tooltip title={__('remove')}>
            <Popconfirm
              title={__("Are you sure you want to remove this item relation?")}
              onConfirm={onRemove}
              okText={__("Yes")}
              cancelText={__("No")}
            >
              <RemoveButton className='btn-unlink'>
                <MinusOutlined />
              </RemoveButton>
            </Popconfirm>
          </Tooltip>
        )
      }
      <div onClick={() => {
        handleSelection(subObjectInfos, type)
      }}>
        <CustomGrid
          items={items}
          margin={[25, 4]}
        />
      </div>
    </CustomCard>)
}
