import styled from 'styled-components'

export const CalendarStandardVersion = styled.div`

 .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    position: relative;
  }

  .rbc-time-gutter * {
    background: white !important;
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid #f2f2f2;
    min-height: 46px;
  }
  .rbc-toolbar {
    flex-wrap: inherit;
  }

  .rbc-event-label {
    display: none;
  }
  .rbc-event {
    background-color: transparent;
  }
  .rbc-header {
    font-weight: 400;
  }

  .rbc-day-slot .rbc-event {
    min-height: 30px;
  }
  .rbc-month-row {
    height: auto;
  }

  .rbc-current-time-indicator {
    background-color: ${(props) => props.theme.colors.red.primary} !important;
    height: 2px !important;
    z-index: 33;

    &:before {
      background: ${(props) => props.theme.colors.red.primary};
      -webkit-border-radius: 50%;
      border-radius: 50%;
      content: "";
      position: absolute;
      z-index: 33;
      height: 12px;
      margin-left: -6.5px;
      margin-top: -5px;
      width: 12px;
    }
  }

  .rbc-row-segment {
    position: relative;
  }

  .rbc-month-view {
    display: block;

    .rbc-row-segment {
      min-height: 30px;
    }

    .rbc-row-content {
      min-height: 120px;
    }

  }
`

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media print {
    padding: 1rem;
  }
`

export const UserWrapper = styled.div`
  font-size: 2rem;
  letter-spacing: .04rem;
  display: flex;
  align-items: center;

  @media print {
    padding: 1rem;
  }
`

export const User = styled.div`
  margin-left: .5rem;
`

export const SubmitWrapper = styled.div`
  text-align: right;
`
