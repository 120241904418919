import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';
import {Modal} from 'react-bootstrap';
import Glyphicon from '@strongdm/glyphicon'

import OverviewTabEditForm from '../components/OverviewTabEditForm';
import {createRemoteObjectItem, updateRemoteObjectItem} from '../providers/ReduxProvider/actions/remoteObjectActions';
import {showMessage} from '../utils/appHelper';
import _ from 'lodash';
import {api} from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'

const loadingBar = require('nprogress');


class OverviewTabEditButton extends React.Component {
  static propTypes = {
    guiUser: PropTypes.object,
    node: PropTypes.object,
    initialValues: PropTypes.object,
    configuration: PropTypes.object,
    enumValues: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    remoteObjectId: PropTypes.string,
    dependencyId: PropTypes.number,
    system: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    attributesConfiguration: PropTypes.array,
    overviewObjectId: PropTypes.string,
    className: PropTypes.string,
  };

  static defultProps = {
    action: 'edit',
  };

  constructor(props){
    super(props);
    this.state = {
      attributesConfigurationTree: null,
      objectConfiguration: null,
      resultId: null,
      modal:{
        open: false,
      },
      isLoading: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.getDataAndOpenModal = this.getDataAndOpenModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAttributesConfigurationTree = this.getAttributesConfigurationTree.bind(this);
  }

  componentDidMount() {
    const { configuration, overviewObjectId, orderId} = this.props;
    api.get(`configurations/overviewObjects/${overviewObjectId}`)
      .then(
        response => {
          const payload = response.data;
          api.post('configurations/overviewObjects/results?orderIds='+[orderId], payload)
          .then(
            res => {
              let objectConfiguration = [];
              objectConfiguration[configuration.overviewObjectId]=response.data;
              this.setState({
                resultId: res.data[0].id,
                objectConfiguration:objectConfiguration
              });
            }
          );
        }
      );
  }

  closeModal(e){
    if(e?.stopPropagation) e.stopPropagation()
    this.setState({
      modal: {
        open: false,
      }
    });
  }

  handleSubmit() {
    const { initialValues, formValues, dependencyId } = this.props;
    const { resultId, objectConfiguration } = this.state;
    let patch = [];

    Object.keys(initialValues).forEach((key) => {
      if (initialValues[key] !== formValues[key] ){
        patch.push({
          op: 'test',
          path: `/displayData/${key}`,
          value: initialValues[key],
        });
        patch.push({
          op: 'replace',
          path: `/displayData/${key}`,
          value: formValues[key],
        });
      }
    });

    patch = _.orderBy(patch, 'op').reverse();

    // Check if patch is empty (Nothing to change.)
    if (patch.length === 0) {
      this.closeModal();
      showMessage('success', __('Overview Tab updated successfully'));
      return;
    }

    const overviewObjectId = objectConfiguration["undefined"] && objectConfiguration["undefined"].id

    api.patch(`/configurations/remoteObjects/${dependencyId}/${resultId}`, patch,
      {params: {overviewObjectId: overviewObjectId}})
      .then(
        () => {
          // Update the state
          this.setState({
            isLoading: false,
          }, () => {
            loadingBar.done();
            this.closeModal();
            showMessage('success', __('Overview Tab updated successfully'));
            typeof window !== 'undefined' && window.location.reload()
          });
        }
      ).catch( (error) => {
        showMessage('error', __('ServerSideError') + '. ' + error);
      });
  }

  getAttributesConfigurationTree() {
    return api.get('configurations/attributes/tree')
    .then(
      response => {
        this.setState({
          attributesConfigurationTree: response.data,
        });
      }
    );
  }

  getDataAndOpenModal() {
    // Fetch attributes tree
    this.getAttributesConfigurationTree();
    this.setState({
      modal: {
        open: true,
      },
    });
  }

  render() {
    const {
      attributesConfiguration,
      enumValues,
      node,
      configuration,
      initialValues,
    } = this.props;
    const {
      objectConfiguration,
      attributesConfigurationTree,
    } = this.state;

    return (
      <div>
        <button
          onClick={() => this.getDataAndOpenModal()}
            className="btn btn-default btn-sm"
        >
          Edit
        </button> { this.state.modal.open }

        {this.state.modal.open &&
          <Modal
            backdrop="static"
            show={this.state.modal.open}
            onHide={this.closeModal}
            bsSize="large"
          >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">{__('Edit Overview Tab')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { (node && attributesConfigurationTree)
              ? <OverviewTabEditForm
                enumValues={enumValues}
                node={node}
                configuration={configuration}
                attributesConfiguration={attributesConfiguration}
                objectConfiguration={objectConfiguration}
                attributesConfigurationTree={attributesConfigurationTree}
                initialValues={initialValues}
              />
              : __('loading')
            }
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <button className="btn btn-default pull-left" onClick={this.closeModal} >
                <Glyphicon glyph="chevron-left"/> {__('Back')}
              </button>

              <button
                className="btn pull-right btn-primary"
                disabled={this.state.isLoading}
                size="large"
                onClick={this.handleSubmit}
              >
                {__('Save Changes')}
              </button>
              </div>
          </Modal.Footer>
        </Modal>
        }
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      formValues: getFormValues('overviewTabEditForm')(state),
      guiUser: state.user.data,
      enumValues: state.enumValues,
    };
  },
  { updateRemoteObjectItem, createRemoteObjectItem }
)(OverviewTabEditButton);
