import React from 'react';
import PropTypes from 'prop-types';
//import StepObjectNode from "./StepObjectNode";

class StepAttributeNodeEdit extends React.Component {
  static propTypes = {
    renderLine: PropTypes.func,
    title: PropTypes.string,
    depth: PropTypes.number,
    checkVisibility: PropTypes.array,
    attributes: PropTypes.array,
    values: PropTypes.array,
  };

  static propTypes = {
    checkVisibility: PropTypes.bool,
  };

  static defaultProps = {
    depth: 1,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  render() {
    const {renderLine, depth, attributes, values} = this.props;

    let rows = [];
    for (let i = 0; i < attributes.length; i++) {
      let value = values?.[i];
      const formItem = renderLine(attributes[i], value)
      if (formItem) rows.push(formItem)
    }

    return (
      <div className={'form-horizontal style-' + depth}>
        {rows.length > 0 &&
        <div className="group">
          {rows}
        </div>
        }

      </div>
    );
  }
}

export default StepAttributeNodeEdit;
