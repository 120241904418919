import {Button, FlexColumn, FlexRow, Modal} from "components";
import {__} from "../../utils/translationUtils";
import React, {useEffect, useRef} from "react";
import {SignatureComponent} from "@syncfusion/ej2-react-inputs";
import {
  base64ToBlob,
  emptyBase64
} from "../../components/DooOrderPage/Step/customRender/FileUploader/utils/file";
import {initialize} from "./utils";
import {Input} from "antd";


type SignatureModalProps = {
  imgBase64?: string
  dimensions: { width: number, height: number }
  onCancel: () => void
  onSave: (file: Blob | undefined) => void
}
const SignatureModal = ({imgBase64, dimensions, onCancel, onSave}: SignatureModalProps) => {
  const internalRef = useRef<SignatureComponent>(null);

  useEffect(() => {
    imgBase64 && internalRef.current?.load(imgBase64, dimensions.width, dimensions.height)
  }, []);

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event)  => {
    const file = event.target?.files?.[0]
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const base64String = e.target?.result;

        base64String && initialize(base64ToBlob(base64String, 'image/jpeg'), dimensions.width, dimensions.height)
          .then((base64) => internalRef.current?.load(base64, dimensions.width, dimensions.height))
      };
      reader.readAsDataURL(file);
    }
  }

  const clearAndLoadWhiteBackground = () => {
    if (internalRef.current) {
      internalRef.current.clear();
      internalRef.current.load(emptyBase64(dimensions.width, dimensions.height), dimensions.width, dimensions.height);
    }
  };

  const submit = () => {
    const blob = internalRef.current?.saveAsBlob()
    onSave(blob)
  }

  return (
    <Modal
      title={imgBase64 ? __('edit_signature') : __('add_signature')}
      centered
      size="medium"
      footer={
        <>
          <Button
            title={__('cancel')}
            type='text'
            onClick={() => onCancel()}
          />
          <Button
            title={__('save')}
            type='filled'
            onClick={submit}
          />
        </>
      }
      visible={true}
      onOk={submit}
      onCancel={onCancel}
    >
      <FlexColumn gap={'12px'}>
        <SignatureComponent
          ref={internalRef}
          id='signature-add-edit'
          style={{width: '75%', borderRadius: '8px'}}
        />
        <FlexRow gap={'12px'}>
          <Button
            title={__('reset')}
            type='outlined'
            disabled={!imgBase64}
            onClick={() => {
              imgBase64 && internalRef.current?.load(imgBase64, dimensions.width, dimensions.height)
            }}
          />
          <Button
            title={__('clear')}
            type='outlined'
            onClick={clearAndLoadWhiteBackground}
          />
          <Input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
        </FlexRow>
      </FlexColumn>
    </Modal>
  )
}

export default SignatureModal
