import {__} from "../../../utils/translationUtils";
import _ from "lodash";


export function countFiltersDiff<T extends object | any[]>(current: T, initialValues: T): number {
  // If current is null or undefined or has no keys, return 0
  if (!current || Object.keys(current).length === 0) return 0;

  // Helper function to treat null and undefined as equal
  const isEqualWithNullish = (value1: any, value2: any): boolean => {
    if (value1 == null && value2 == null) {
      return true; // Treat null and undefined as equal
    }
    return _.isEqual(value1, value2);
  };

  // Reduce over the keys of the current object or array
  return Object.keys(current).reduce((totFilled, key) => {
    if (isEqualWithNullish((current as any)[key], (initialValues as any)[key])) {
      return totFilled;
    }
    return totFilled + 1;
  }, 0);
}

/*
* SEARCH ATTRIBUTES
*/

export const countMissingRequiredSearchAttributes = (search: FormValues, searchAttributes?: SearchAttribute[]): number => {
  const requiredAttributes = searchAttributes?.filter((f) => f.required)
  if (!requiredAttributes) return 0
  return requiredAttributes.reduce((prev, attr) => {
    if (!search[attr.attributeId]) {
      return prev + 1
    }
    return prev
  }, 0)
}

export const SearchAttributeInverseMapping = (searchAttribute: SearchAttribute) => {
  return {
    ...searchAttribute,
    ...(searchAttribute.mechanism === "SPECIFIC_VALUE" && !searchAttribute.exactMatch ? { value: searchAttribute.value.replace(/^\*|\*$/g, '') } : {})
  }
}

/*
* DISPLAY ATTRIBUTES
*/

export const getDisplayAttributesConfiguration = (displayAttributes: DisplayAttribute[], attributesMap: Record<string, Attribute>) => {
  return (
    displayAttributes?.sort((a, b) => a.position - b.position) || [])
    .map((dAttribute) => attributesMap[dAttribute.attributeId])
    .filter(x => x)
}

export const getDisplayAttributesItems = (displayAttributes: DisplayAttribute[], attributesMap: Record<string, Attribute>) => {
  const attributesUsedInDisplay = getDisplayAttributesConfiguration(displayAttributes, attributesMap)

  return attributesUsedInDisplay
    ?.filter((a) => (a.displayable))
    .map((a) => ({
      value: a.id,
      label: __(a.propertyLabel)
    }))
}
