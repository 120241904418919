import { useEffect, useState } from "react";

export default (searchValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(searchValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    }
  }, [searchValue]);

  return debouncedValue;
};
