import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { getFormValues, change } from 'redux-form';
import _ from 'lodash';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import TabConfigCard from './../components/TabConfigCard';
import { showMessage } from '../utils/appHelper';
import { updateOverviewTabs } from '../providers/ReduxProvider/actions/overviewTabsActions';
import { getGuiUser } from '../providers/ReduxProvider/actions/userActions';
import { api } from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'
const loadingBar = require('nprogress');

const SortableItem = SortableElement(({value}) =>
  <div>{value}</div>
);

const SortableList = SortableContainer(({items}) => {
    return (
      <div className="configuration-list">
        { items ? items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        )) : <div className={"data-test-no-items"}/> }
      </div>
    );

});

class FlowOverviewTabsPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      attributesConfiguration: null,
      objectId: this.props.match.params.id,
      flowVersion: this.props.match.params.flowVersion,
      mode: this.props.mode,
      newTabName: '',
      selectedObject: '',
      selectedTab: '',
      entities: null,
      dateFormats: [],
      overviewTabs: null,
      tabNames: {},
      overviewObjects: null,
      flow: {
        title: '',
      },
      initialOrderTypeValues: null,
      isLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAttributesConfiguration = this.getAttributesConfiguration.bind(this);
    this.extractAttributeConfig = this.extractAttributeConfig.bind(this);
    this.getPublishedFlow = this.getPublishedFlow.bind(this);
    this.getOverviewTabs = this.getOverviewTabs.bind(this);
    this.getOverviewObjects = this.getOverviewObjects.bind(this);
    this.addObjectToTab = this.addObjectToTab.bind(this);
    this.removeObjectFromTab = this.removeObjectFromTab.bind(this);
    this.resetTabsSelection = this.resetTabsSelection.bind(this);
    this.initTabNames = this.initTabNames.bind(this);
    this.onTabsSortEnd = this.onTabsSortEnd.bind(this);
    this.onConfigurationSortEnd = this.onConfigurationSortEnd.bind(this);
    this.tabsList = this.tabsList.bind(this);
    this.syncTabNames = this.syncTabNames.bind(this);
    this.clearConfigSorting = this.clearConfigSorting.bind(this);
    this.createTab = this.createTab.bind(this);
    this.removeTab = this.removeTab.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  extractAttributeConfig(id) {
    return _.find(this.state.attributesConfiguration, (a) => a.id === id);
  }

  resetTabsSelection() {
    this.setState({ selectedTab: '' });
  }

  syncTabNames() {
    const { tabNames, overviewTabs } = this.state;
    Object.keys(tabNames).forEach((key) => {
      const _overviewTabs = [].concat(overviewTabs);
      const tab = _.find(_overviewTabs, (tab) => tab.id.toString() === key );
      tab.name = tabNames[key];
      this.setState({ overviewTabs: _overviewTabs });
    });
  }

  createTab(e) {
    e.preventDefault();
    const { newTabName, overviewTabs, tabNames } = this.state;

    if (!newTabName || newTabName === '') {
      showMessage('error', 'Please provide a Name.');
      return;
    }

    const _overviewTabs = [].concat(overviewTabs);
    let _tabNames = {...tabNames};
    _tabNames[_overviewTabs.length] = newTabName;

    _overviewTabs.push({
      id: _overviewTabs.length,
      name: newTabName,
      configurations: [],
      position: _overviewTabs.length,
    });

    this.setState(
      {
        overviewTabs: _overviewTabs,
        tabNames: _tabNames,
        newTabName: '',
      },
      () => {

      }
    );
  }

  removeTab(tabId) {
    const { overviewTabs } = this.state;
    let _overviewTabs = [].concat(overviewTabs);
    _overviewTabs = _.filter(_overviewTabs, (tab) => tab.id !== tabId );
    this.setState(
      {
        overviewTabs: _overviewTabs,
      }
    );
  }

  removeObjectFromTab(objectId, TabId) {
    const { overviewTabs } = this.state;
    const _overviewTabs = [].concat(overviewTabs);

    const tab = _.find(_overviewTabs, (tab) => tab.id === TabId );
    tab.configurations = _.filter(tab.configurations, (conf) =>  conf.configuration.id !== objectId);
    this.setState(
      { overviewTabs: _overviewTabs },
      () => { this.clearConfigSorting(tab.id); }
    );
  }

  addObjectToTab(e) {
    e.preventDefault();
    const {
      selectedTab,
      selectedObject,
      overviewTabs,
      overviewObjects,
      flow,
    } = this.state;
    const _overviewTabs = [].concat(overviewTabs);
    if (!selectedObject || !selectedTab || selectedObject === '' || selectedTab === '') {
      showMessage('error', 'Make sure you have selected both an Overview Object and a destination Tab.');
      return;
    }

    // Find object.
    const config = _.find(overviewObjects, (conf) => conf.id === selectedObject );
    // Find the tab.
    const tab = _.find(_overviewTabs, (tab) => tab.id.toString() === selectedTab );
    // Add object to the Tab
    tab.configurations.push({
      configuration: config,
      overviewObjectPosition: tab.configurations.length,
      flowId: flow.id,
      flowVersion: flow.version,
      overviewTabId: tab.id,
      overviewObjectId: config.id,
    });
    this.setState(
      { overviewTabs: _overviewTabs },
      () => { this.clearConfigSorting(tab.id); }
    );
  }

  init(){
    this.getAttributesConfiguration();
    this.getPublishedFlow().then(() => {
      this.getOverviewTabs().then(() => {
        this.initTabNames();
      });
      this.getOverviewObjects();
    });
  }

  initTabNames() {
    const { overviewTabs } = this.state;
    let tabNames = {};
    overviewTabs.forEach((tab) => {
      tabNames[tab.id] = tab.name;
    });

    this.setState({ tabNames });
  }

  onTabsSortEnd({ oldIndex, newIndex }) {
    const tabs = [].concat(this.state.overviewTabs);
    const item1 = _.find(tabs, (tab) => tab.position === oldIndex);

    if (newIndex > oldIndex) {
      // Update the position of all the previus items
      const prevItems = _.filter(tabs, (tab) => tab.position >= oldIndex && tab.position <= newIndex);
      prevItems.forEach((item) => {
        item.position = item.position - 1;
      });

    } else{
      // Update the position of all the previus items
      const prevItems = _.filter(tabs, (tab) => tab.position >= newIndex && tab.position <= oldIndex);
      prevItems.forEach((item) => {
        item.position = item.position + 1;
      });
    }
    item1.position = newIndex;
    this.setState({
     overviewTabs: tabs,
    });
  }

  onConfigurationSortEnd({ oldIndex, newIndex, tab }) {
    const tabs = [].concat(this.state.overviewTabs);
    const _configurations = tab.configurations;

    const current = _.find( _configurations,
      (c) => c.overviewObjectPosition === oldIndex);

    if (newIndex > oldIndex) {
      // Update the position of all the previus items
      const toBeUpdated = _.filter(
        _configurations,
        (config) => config.overviewObjectPosition > oldIndex && config.overviewObjectPosition <= newIndex);
      toBeUpdated.forEach((config) => {
        config.overviewObjectPosition -= 1;
      });
    } else {
      // Update the position of all the previus items
      const toBeUpdated = _.filter(
        _configurations,
        (config) => config.overviewObjectPosition >= newIndex && config.overviewObjectPosition < oldIndex);
      toBeUpdated.forEach((config) => {
        config.overviewObjectPosition += 1;
      });
    }
    current.overviewObjectPosition = newIndex;

    this.setState({
     overviewTabs: tabs,
    });
  }

  clearConfigSorting(tabId) {
    const tabs = [].concat(this.state.overviewTabs);
    const _tab = _.find(tabs, (tab) => tab.id === tabId);

    const configs = _tab.configurations;
    configs.forEach((a, idx) => {
      a.overviewObjectPosition = idx;
    });

    this.setState({
      overviewTabs: tabs,
    });
  }

  getAttributesConfiguration() {
    return api.get('configurations/attributes')
    .then(
      response => {
        this.setState({
          attributesConfiguration: response.data,
          isLoading: false,
        });
        loadingBar.done();
      }
    );
  }

  getOverviewTabs() {
    const { flow, flowVersion } = this.state;
    return api.get(`publishedFlows/${flow.id}/${flowVersion}/overviewTabs`)
    .then(
      response => {
        this.setState({
          overviewTabs: response.data,
          isLoading: false,
        });
        loadingBar.done();
      }
    );
  }

  getOverviewObjects() {
    const { flow , flowVersion } = this.state;
    return api.get(`/publishedFlows/${flow.id}/${flowVersion}/configurations/overviewObjects`)
    .then(
      response => {
        this.setState({
          overviewObjects: response.data,
          isLoading: false,
        });
        loadingBar.done();
      }
    );
  }

  getPublishedFlow(){
    const { flowVersion } = this.state;
    return api.get(`/publishedFlows/${this.props.match.params.id}/${flowVersion}`)
    .then(
      response => {
        this.setState({
          flow: response.data,
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  tabsList() {
    const { overviewTabs, tabNames } = this.state;
    const updateTabName = (_tab, value) => {
      this.setState({ tabNames: { ...tabNames, [_tab.id ]: value }});
    };

    const sorted = _.sortBy(overviewTabs, 'position');
    return sorted && sorted.map(
      (tab) => (
        <TabConfigCard
          key={tab.id}
          tab={tab}
          updateTabName={updateTabName}
          tabNames={tabNames}
          syncTabNames={this.syncTabNames}
          removeObjectFromTab={this.removeObjectFromTab}
          removeTab={this.removeTab}
          onConfigurationSortEnd={
            ({ oldIndex, newIndex }) => this.onConfigurationSortEnd({ oldIndex, newIndex, tab })
          }
        />
      )
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    const { flow, overviewTabs, flowVersion } = this.state;
    const payload = [].concat(overviewTabs);

    // remove temporary ids from payload
    payload.forEach((tab) => {
      if (Number.isInteger(tab.id)) {
        delete tab.id;
      }
      tab.configurations.forEach((conf) => {
        if (Number.isInteger(conf.overviewTabId)) {
          delete conf.overviewTabId;
        }
      });
    });

    this.props.updateOverviewTabs({
      payload,
      flowId: flow.id,
      flowVersion: flowVersion,
    });
  }

  render() {
    const headerClass = (this.state.mode)?this.state.mode:'';
    const { flow, overviewObjects, overviewTabs } = this.state;

    if (!overviewObjects) {
      return null;
    }

    return (
      <div id="main-content" className="flow-overviewtabs-management-page">
        <h1 className={'sticky '+ headerClass}>
          <div className="container">
            {flow.title}: { __('Overview Tabs') }
          </div>
        </h1>

        <div className="container">
          <br/>
          { this.state.flow ?
            <div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="panel">
                    <div className="panel-body add-object-to-tab-panel" >
                      <form className="form-inline">
                        <div className="form-group">
                          <label>Create new Tab:&ensp;&ensp;</label>
                          <input
                            onChange={(e) => {
                              this.setState({ newTabName: e.target.value });
                            }}
                            value={this.state.newTabName}
                            className="form-control"
                          />
                        </div>
                        &ensp;&ensp;
                        <button
                          onClick={this.createTab}
                          type="submit"
                          className="btn btn-default"
                        >
                          Add Tab
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="panel">
                    <div className="panel-body add-object-to-tab-panel" >
                      <form className="form-inline">
                        <div className="form-group">
                          <label>Add&ensp;&ensp;</label>
                          <select
                            onChange={(e) => {
                              this.setState({ selectedObject: e.target.value });
                            }}
                            className="form-control"
                          >
                            <option value="">Select an Object...</option>
                            {
                              overviewObjects && overviewObjects.map((object) => {
                                return <option key={object.id} value={object.id}>{__(object.name)}</option>;
                              })
                            }
                          </select>
                        </div>
                        <div className="form-group">
                          <label>&ensp;&ensp;To&ensp;&ensp;</label>
                          <select
                            onChange={(e) => {
                              this.setState({ selectedTab: e.target.value });
                            }}
                            className="form-control"
                          >
                            <option value="">Select a Tab...</option>
                            {
                              overviewTabs && overviewTabs.map((tab) => {
                                return <option key={tab.id} value={tab.id}>{__(tab.name)}</option>;
                              })
                            }
                          </select>
                        </div>
                        &ensp;&ensp;
                        <button
                          onClick={this.addObjectToTab}
                          type="submit"
                          className="btn btn-default"
                        >
                          Add Object
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5">
                  <SortableList
                    lockAxis="y"
                    lockToContainerEdges
                    items={this.tabsList()}
                    useDragHandle={true}
                    onSortEnd={this.onTabsSortEnd}
                  />
                </div>
              </div>

              <div className="gutter-top text-center">
                <button
                  onClick={this.handleSubmit}
                  className="btn btn-primary"
                >
                  {__('save Configuration')}
                </button>
              </div>
            </div>
            : <span>{__('loading')}</span>
          }

        </div>
      </div>
    );
  }
}

FlowOverviewTabsPage.propTypes = {
  mode: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
  getGuiUser: PropTypes.func,
  updateOverviewTabs: PropTypes.func,
  availableViews: PropTypes.array,
  availableReports: PropTypes.array,
  change: PropTypes.func,
  formValues: PropTypes.object,
};

export default connect(
  state => {
    return {
      user: state.user.data,
      availableReports: state.user.reports,
      availableViews: state.user.views,
      formValues: getFormValues('orderTypeForm')(state),
    };

  },
  { getGuiUser, updateOverviewTabs, change }
)(FlowOverviewTabsPage);
