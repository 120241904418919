import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {change, getFormValues} from 'redux-form';
import _ from 'lodash';

import {createOrderType, updateOrderType} from '../providers/ReduxProvider/actions/orderTypeActions';
import {getGuiUser} from '../providers/ReduxProvider/actions/userActions';
import OrderTypeForm from '../components/OrderTypeForm';
import OrderTypeHistoryButton from '../components/OrderTypeHistoryButton';
import { api } from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'

const loadingBar = require('nprogress');

export const deadlineThresholdColor = [
  "#64D868",
  "#D1DB5B",
  "#FDE52C",
  "#FA9013",
  "#FF0000"];

class OrderTypePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributesConfiguration: null,
      attributesConfigurationTree: null,
      valueTypesMappings: null,
      objectId: this.props.match.params.id,
      mode: this.props.mode,
      entities: null,
      entitiesRelatedToUser: null,
      dateFormats: [],
      orderType: {
        name: '',
      },
      initialOrderTypeValues: null,
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.getAllEntities = this.getAllEntities.bind(this);
    this.getEntitiesRelatedToUser = this.getEntitiesRelatedToUser.bind(this);
    this.getPublishedFlows = this.getPublishedFlows.bind(this);
    this.resetVersion = this.resetVersion.bind(this);
    this.getAttributesConfigurationTree = this.getAttributesConfigurationTree.bind(this);
    this.getAttributesConfiguration = this.getAttributesConfiguration.bind(this);
    this.extractAttributeConfig = this.extractAttributeConfig.bind(this);
    this.getValueTypeMappings = this.getValueTypeMappings.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  resetVersion() {
    this.props.change('orderTypeForm', 'flowVersion', null);
  }

  extractAttributeConfig(id) {
    return _.find(this.state.attributesConfiguration, (a) => a.id === id);
  }

  init(){
    if(this.props.mode === 'create'){
      this.getAllEntities();
      this.getEntitiesRelatedToUser();
      this.getDateFormats();
      this.getPublishedFlows();
      this.getAttributesConfiguration();
      return;
    }
    this.getData().then(()=>{
      this.getAllEntities();
      this.getEntitiesRelatedToUser();
      this.getDateFormats();
      this.getPublishedFlows();
      this.getAttributesConfiguration();
      this.getAttributesConfigurationTree();
      this.getValueTypeMappings()
    });
  }

  getValueTypeMappings() {
    return api.get('/configurations/valueTypes/mappings')
      .then(
        (response) => {
          this.setState({
            valueTypesMappings: response.data,
            isLoading: false,
          });
          loadingBar.done();
        }
      )
  }

  getAttributesConfiguration() {
    return api.get('configurations/attributes')
    .then(
      response => {
        this.setState({
          attributesConfiguration: response.data,
          isLoading: false,
        });
        loadingBar.done();
      }
    );
  }

  getAttributesConfigurationTree() {
    return api.get('configurations/attributes/tree')
      .then(
        response => {
          this.setState({
            attributesConfigurationTree: response.data,
            isLoading: false,
          });
          loadingBar.done();
        }
      );
  }


  getDateFormats() {
    return api.get('dateFormats')
    .then(
      response => {
        this.setState({
          dateFormats: response.data,
        });
        loadingBar.done();
      }
    );
  }

  getData(){
    this.setState({isLoading: true});
    loadingBar.start();
    return api.get(`orderTypes/${this.state.objectId}`)
    .then(
      response => {
        let extraInitialValues = {};
        if (!response.data.deadlineThresholds) {
          extraInitialValues = {
            deadlineThresholds: [
              {valueEnd: 20}, {valueEnd: 40}, {valueEnd: 60}, {valueEnd: 80}, {valueEnd: 100}
            ]
          };
        }

        //convert orderType entities for populating form TreeSelect
        let data = {...response.data};
        data.entities = response.data?.entities.map((entity) =>
          JSON.stringify({
            type: 'entity',
            id: entity.id,
            key: entity.id
          })
        );

        data.defaultEntityIds = response.data?.defaultEntityIds?.map((entity) =>
          JSON.stringify({
            type: 'entity',
            id: entity,
            key: entity
          })
        );

        // Update the state
        this.setState({
          orderType: data,
          initialOrderTypeValues: data,
          initialValues: {
            ...data, ...extraInitialValues
          },
          isLoading: false,
        }, () => {
          loadingBar.done();
        });
      }
    );
  }

  getAllEntities(){
    return api.get('/entities?pageSize=200&status=enabled,reserved')
    .then(
      response => {
        this.setState({ entities: response.data });
      },
      error => {
        console.log(error);
      }
    );
  }

  getEntitiesRelatedToUser(){
    return api.get(`hierarchy/organisations?withUsers=false`)
      .then(
        response => {
          this.setState({ entitiesRelatedToUser: response.data });
        },
        error => {
          console.log(error);
        }
      );
  }

  getPublishedFlows(){
    return api.get(`/publishedFlows?pageSize=600&sort=-creationDatetime`)
    .then(
      response => {
        this.setState({ publishedFlows: response.data });
      },
      error => {
        console.log(error);
      }
    );
  }

  handleSubmit() {
    const {formValues} = this.props;
    const payload = {...formValues};

    // payload.modifierId = user.id;
    // const foundFlow = _.find(this.state.publishedFlows, (f) => f.flowId === formValues.flowId);
    // payload.flowVersion = foundFlow.flowVersion;
    delete payload.type;
    delete payload.lastUpdateDatetime;

    if (!payload.creatableByUser) {
      payload.dateFormat = payload.dateFormat || "ddmmYYYY"
      payload.size = payload.size || 2
      payload.offset = payload.offset || '0'
      payload.creatableByUser = false
    }

    if (payload.color === "transparent") {
      payload.color = null
    }

    if (payload.deadlineThresholds && Array.isArray(payload.deadlineThresholds)) {
      payload.deadlineThresholds = payload.deadlineThresholds.map((dT, index) => {
        if (index === 0) {
          return {...dT, valueStart: "0", color: deadlineThresholdColor[index]};
        } else {
          return {
            ...dT,
            valueStart: payload.deadlineThresholds[index - 1].valueEnd,
            color: deadlineThresholdColor[index]
          };
        }
      });
    }

    let filteredEntities = [];
    if(payload.entities){
      payload.entities.forEach((entity) => {
        entity = JSON.parse(entity);
        if(entity?.type === 'company'){
          let entitiesOfCompany = this.state.entitiesRelatedToUser.find((company) => company.id === entity.id)?.children;
          entitiesOfCompany.map((entityOfCompany) => {
            filteredEntities.push({id: entityOfCompany?.id})
          });
        } else {
          filteredEntities.push({id: entity?.id});
        }
      });
    }
    payload.entities = filteredEntities;

    let filteredDefaultEntities = [];
    if(payload.defaultEntityIds){
      payload.defaultEntityIds.forEach((entity) => {
        entity = JSON.parse(entity)
        filteredDefaultEntities.push(entity?.id);
      });
    }
    payload.defaultEntityIds = filteredDefaultEntities;

    if (this.props.mode === 'edit') {
      this.props.updateOrderType(payload).then(() => {
        this.props.getGuiUser();
      });
    } else {
      this.props.createOrderType(payload).then(() => {
        this.props.getGuiUser();
      });
    }
  }

  render() {
    const headerClass = (this.state.mode)?this.state.mode:'';
    const { orderType } = this.state;
    return (
      <div id="main-content" className="orderType-page">
        <h1 className={'sticky '+ headerClass}>
          <div className="container">
            {
              ( this.state.mode !== 'edit'
                && orderType
                && orderType.accessRightsInfo
                && orderType.accessRightsInfo.edit)
              ? __('Create Order Type')
              : __('Edit Order Type')
            }
          </div>
        </h1>

        <div className="container">
          <br/>
          { this.state.orderType || this.state.mode !== 'edit' ?
          <OrderTypeForm
            resetVersion={this.resetVersion}
            addItems={this.addItems}
            removeItems={this.removeItems}
            getData={this.getData}
            mode={this.state.mode}
            entities={this.state.entities}
            entitiesRelatedToUser={this.state.entitiesRelatedToUser}
            dateFormats={this.state.dateFormats}
            publishedFlows={this.state.publishedFlows}
            initialValues={(this.state.mode === 'create')?
              {
                type: 'SELF',
                color: 'transparent'
              } : this.state.initialValues
            }
            orderType={orderType}
            initialOrderTypeValues={this.state.initialOrderTypeValues}
            onSubmit={this.handleSubmit}
            attributesConfiguration={this.state.attributesConfiguration}
            attributesConfigurationTree={this.state.attributesConfigurationTree}
            extractAttributeConfig={this.extractAttributeConfig}
            getPublishedFlows={this.getPublishedFlows}
            valueTypesMappings={this.state.valueTypesMappings}
          />
          : <span>{__('loading')}</span>
          }

          <div>
            <OrderTypeHistoryButton
              orderTypeId={orderType.id}
              publishedFlows={this.state.publishedFlows}
            />
          </div>
        </div>
      </div>
    );
  }
}

OrderTypePage.propTypes = {
  mode: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
  getGuiUser: PropTypes.func,
  createOrderType: PropTypes.func,
  updateOrderType: PropTypes.func,
  change: PropTypes.func,
  formValues: PropTypes.object,
};

export default connect(
  state => {
    return {
      user: state.user.data,
      formValues: getFormValues('orderTypeForm')(state),
    };

  },
  { getGuiUser, createOrderType, updateOrderType, change }
)(OrderTypePage);
