import React from 'react'
import { getApiHandler } from '../../utils/appHelper'

const ApiContext = React.createContext()

export let api = getApiHandler()

export * from './authentication'
export * from './dashboards'
export * from './dataStructures'
export * from './entity'
export * from './filesIO'
export * from './misc'
export * from './order'
export * from './remoteObjects'
export * from './settings'

const ApiProvider = ({ children }) => (
  <ApiContext.Provider value={[]}>
    {children}
  </ApiContext.Provider>
)

export default ApiProvider
