import React from 'react'
import {QueryClient, QueryClientProvider} from 'react-query'

const queryClient = new QueryClient()

type UseQueryProviderProps = {
  children: React.ReactNode
}

const UseQueryProvider = ({ children }: UseQueryProviderProps) => (
  <QueryClientProvider client={queryClient}>
    {children}
  </QueryClientProvider>
)

export default UseQueryProvider
