import React from 'react'
import { useResizeDetector } from 'react-resize-detector'

import * as S from "../styles";

export default (props) => {

  const {
    dataSource,
    columns,
    pagination,
    rowSelection,
    onChange,
    fitParent,
    ...tableProps
  } = props

  const { width, ref } = useResizeDetector()

  return (
    <S.CustomTable
      ref={ref}
      columns={columns}
      dataSource={dataSource}
      size={'small'}
      scroll={{ y: fitParent ? '100%' : 550 }}
      fitParent={fitParent}
      rowSelection={rowSelection}
      pagination={pagination}
      onChange={onChange}
      width={width}
      {...tableProps}
    />
  )
}
