import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {isRequired, renderField} from '../utils/fieldValidator';
import {hashHistory} from '../providers/HistoryProvider';
import {Modal, Panel} from 'react-bootstrap';
import Glyphicon from '@strongdm/glyphicon'
import {deleteTemplate, restoreTemplate} from '../providers/ReduxProvider/actions/templateActions';
import TemplateRightsModal from './TemplateRightsModal';
import {__} from '../utils/translationUtils'

const validate = values => {
  const errors = {};

  errors.name = isRequired(values.name);
  errors.shortName = isRequired(values.shortName);

  return errors;
};

class RightsTemplateForm extends React.Component {

  static propTypes = {
    handleSubmit: PropTypes.func,
    getData: PropTypes.func,
    deleteTemplate: PropTypes.func,
    restoreTemplate: PropTypes.func,
    updateTemplate: PropTypes.func,
    model: PropTypes.object,
    isLoading: PropTypes.bool,
    mode: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: {
        open: false,
        action: '',
        model: {
          name: '',
          shortName: ''
        }
      },
      showTemplateRightsModal: {
        open: false
      }
    };
  }

  showTemplateRightsModal(e) {
    e.preventDefault();
    this.setState({showTemplateRightsModal: {...this.state.showTemplateRightsModal, open: true}});
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.handleSubmit();
  }

  confirmAction(action) {
    this.setState({modal: {action: action, open: true, model: this.props.model}});
  }

  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({showTemplateRightsModal: {...this.state.userRightsModal, open: false}});
    this.setState({modal: {...this.state.modal, open: false}});
  }

  handleConfirmation() {
    if (this.state.modal.action === 'reopen') {
      this.handlereopen();
    } else if (this.state.modal.action === 'delete') {
      this.handledelete();
    }
  }

  handleSetRights() {
    this.closeModal();
  }

  handledelete() {
    this.props.deleteTemplate(this.state.modal.model).then(() => {
      this.props.getData();
    });
    // Close modal:
    this.closeModal();
  }

  handlereopen() {
    this.props.restoreTemplate(this.state.modal.model).then(() => {
      hashHistory.goBack();
    });
    // Close modal:
    this.closeModal();
  }

  render() {

    const {isLoading} = this.props;

    const renderPanelFooter = () => {
      return (
        <div className="group">

          <button className="btn btn-default pull-left" onClick={() => {
            hashHistory.goBack();
          }}>
            <Glyphicon glyph="chevron-left"/> {__('Back')}
          </button>

          {this.props.mode !== 'create' &&
            <button className="btn btn-danger"
                    onClick={() => this.confirmAction.bind(this)('delete')}>
              {__('Delete')}
            </button>
          }

          {this.props.mode !== 'view' &&
            <button className={'btn pull-right '.concat(this.props.mode === 'create' ? 'btn-success' : 'btn-primary')}
                    disabled={isLoading}
                    size="large" onClick={this.handleSubmit.bind(this)}>
              <Glyphicon glyph={(this.props.mode === 'edit') ? 'save' : 'plus-sign'}/>
              {__((this.props.mode === 'edit') ? 'Save Changes' : 'Create')}
            </button>
          }

        </div>

      );
    };

    /*    const tooltip = (
          <Tooltip id="template-rights-tooltip">
            Please first create a Template in order to manage it's rights.
          </Tooltip>
        );*/

    return (
      <div>
        <Panel>
          <Panel.Heading>Template Details</Panel.Heading>
          <Panel.Body>
            <form className="form-horizontal">

              <div className="col-md-6">
                <Field
                  name="shortName"
                  disabled={this.props.mode === 'view' || this.props.mode === 'edit'}
                  component={renderField}
                  label={__('Short Name') + '*'}
                  className="form-control"
                  type="text"/>

                <Field
                  name="name"
                  disabled={this.props.mode === 'view'}
                  component={renderField}
                  label={__('Name') + '*'}
                  className="form-control"
                  type="text"/>

              </div>

            </form>

            <div className="col-sm-12">
              <div className="mandatory-text">
                <i>*{__('mandatory fields')}</i>
              </div>
            </div>
          </Panel.Body>
          <Panel.Footer>{renderPanelFooter()}</Panel.Footer>
        </Panel>

        <Modal
          backdrop="static"
          show={this.state.modal.open}
          onHide={this.closeModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">
              {__(this.state.modal.action)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{__('Are you sure you want to')} {__(this.state.modal.action)} "<strong>{this.state.modal.model.name}</strong>"?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <button onClick={this.handleConfirmation.bind(this)}
                      className={'capitalize pull-left btn '.concat((this.state.modal.action === 'delete') ? 'btn-danger' : 'btn-success dark')}
              >
                {this.state.modal.action}
              </button>
              <button className="btn pull-right btn-default" onClick={this.closeModal.bind(this)}>
                {__('Back')}
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        {this.state.showTemplateRightsModal.open &&
          <TemplateRightsModal
            open={this.state.showTemplateRightsModal.open}
            template={this.props.model}
            mode={this.props.mode}
            closeModal={this.closeModal.bind(this)}
            handleSetRights={this.handleSetRights.bind(this)}
          />
        }

      </div>
    );
  }
}

export default reduxForm({
  form: 'templateForm',  // a unique identifier for this form
  validate,
  deleteTemplate,
  restoreTemplate
})(RightsTemplateForm);
