export const SET_MODE = 'SET_MODE'

export const SET_VALIDATION_VISIBILITY = 'SET_VALIDATION_VISIBILITY'

export const INITIALIZED = 'INITIALIZED'

export const CHANGE_SYSTEM = 'CHANGE_SYSTEM'

export const CHANGE_OBJECT = 'CHANGE_OBJECT'

export const NOT_INITIALIZED = 'NOT_INITIALIZED'

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'

export const SHIFT_ACTIVE_STEP = 'SHIFT_ACTIVE_STEP'

export const SET_CONFIGURATIONS = 'SET_CONFIGURATIONS'

export const EDIT_STEP_CONFIGURATION = 'EDIT_STEP_CONFIGURATION'

export const CONNECT_VALIDATION_REFERENCE = 'CONNECT_VALIDATION_REFERENCE'

export const EDIT_STEP_CONFIGURATION_ATTRIBUTE = 'EDIT_STEP_CONFIGURATION_ATTRIBUTE'

export const EDIT_STEP_CONFIGURATION_ATTRIBUTES = 'EDIT_STEP_CONFIGURATION_ATTRIBUTES'

export const UPDATE_DEFAULT_CONFIGURATION_INFO = 'UPDATE_DEFAULT_CONFIGURATION_INFO'

export const UPDATE_ENUMS = 'UPDATE_ENUMS'
