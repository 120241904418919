/* eslint react/jsx-pascal-case: off */
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { change } from 'redux-form';

// moved from react-pdf-js
import PDF from '@mikecousins/react-pdf';
import * as config from '../../constants/globalConfiguration';
import Glyphicon from '@strongdm/glyphicon'
import { __ } from '../../utils/translationUtils'
const apiUrl = config.getApiUrl();

class DocumentViewerControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    let { controlData } = this.props;
    let fileUrl;
    if(typeof controlData.source ==='string')
      try {
        controlData.source = JSON.parse(controlData.source);
        const { xsdName, primaryKey, fieldName, files } = controlData.source;
        if(!files || !files.length)
          return;
        fileUrl = `${apiUrl}/files/${xsdName}/${primaryKey}/${fieldName}/${files[0].name}`;
      } catch (error) {
        fileUrl = apiUrl+"/files/LetterFileCollection/"+controlData.source;
      }

    if (!controlData.source) {
      return (
        <div className="form-group">
          <label className="col-xs-12">{__(controlData.title)}:</label>
          <div className="col-xs-12 letter-container">
            <i>No Document</i>
          </div>
        </div>
      );
    }


    return (
      <div>
        <div className="form-group">

          <label className="col-xs-12">{__(controlData.title)}</label>

          <div className="col-xs-12 letter-container">
            <PDF file={fileUrl} className="printable-area" />
            <br/>
            <a className="btn btn-default" target="_blank" href={fileUrl} rel="noreferrer" >
              {__('Download PDF')}
            </a>
            <a
              style={{marginLeft: '15px'}}
              onClick={()=>{
                window.print();
              }}
              className="btn btn-success"
              >
              <Glyphicon glyph="print" />{__('Print PDF')}
            </a>
          </div>

        </div>
      </div>
    );
  }

}

export default connect(
  null,
  { change }
)(DocumentViewerControl);
