import React from 'react'
import styled from 'styled-components'
import { Icon } from '@ant-design/compatible'
export default (companies ) => {
  return companies?.map((company) => ({
    
    title: <TreeNode> <Icon type="cluster" /> {company.name} - {company.shortName}</TreeNode>,
    searchValue: `${company.name} (${company.shortName})`,
    key: `${company.id}-${global.constants.WHITE_LABEL}`,
    children: company.children?.map((entity) => ({
      title: <TreeNode><Icon type={entity.type === 'organisation' ? 'cluster' : 'team'} /> {entity.name} </TreeNode>,
      searchValue: entity.name,
      key: entity.id,
    }))
  }))
}

const TreeNode = styled.div`
  font-size:1.25rem;
  display: flex;
  align-items: center;
  white-space: pre;
  svg {
    color: ${(props) => props.theme.colors.thirdary};
    font-size:1.5rem;
    margin-right: .5rem;
  }
  small {
    margin-left: .25rem;
    color: ${(props) => props.theme.colors.grey.primary};
  }
`
