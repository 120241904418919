import React, { useEffect, useRef, useState } from 'react';
import { fetchAvailableEntities } from "../../providers/ApiProvider/authentication";
import { Authentication as Auth } from 'components'
import { __, T } from '../../utils/translationUtils'
import getErrorText from "../../utils/getErrorText";

const textCommons = { T, __, getErrorText }

export default (props) => {
  const { location } = props
  const [entities, setEntities] = useState(null);
  const componentMounted = useRef(true)

  useEffect(() => {
    fetchAvailableEntities()
      .then((entities) => {
        componentMounted.current
          && setEntities(entities.map((entity) => (
            {
              ...entity,
              value: entity['name']
            }
          ))
          )
      })
    return () => {
      componentMounted.current = false
    }
  }, [])

  return (<Auth.EntitySelectionForm {...{ entities, textCommons, queryString: location.search }} />)
}
