import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import { getOS, showMessage } from '../utils/appHelper';
import { __ } from '../utils/translationUtils'

class UploadFileModal extends React.Component {

  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    onComplete: PropTypes.func,
    authToken: PropTypes.string,
    path: PropTypes.string,
    group: PropTypes.string,
    customGroup: PropTypes.object,
    multiple: PropTypes.bool,
    acceptedMimeTypes: PropTypes.string,
    onError: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      uploading: 0,
      fileList: []
    };
  }

  handleFileSelection(e) {

    // If no file is found here, maybe it will be a cypress native update

    const fileList = e.target.files;
    const fileNames = [];
    if (!fileList.length) {
      const currentFile = e.nativeEvent.data[0];
      fileNames[0] = {
        currentFile: currentFile,
        fileName: this.getSingleFileName(currentFile)
      }
    }

    for (const currentFile of fileList) {
      fileNames.push({
        currentFile: currentFile,
        fileName: this.getSingleFileName(currentFile)
      })
    }

    this.setState({
        uploading: fileList.length
      },
      () => {

        for (const val of fileNames) {
          this.uploadFile(val.currentFile, val.fileName)
          val.currentFile.crcUrl = this.props.path + '/' + val.fileName + '/crc';
        }

      });

  }

  getSingleFileName(file) {

    const customGroup = this.props.customGroup;

    let pattern = /\.[0-9a-z]+$/i;
    let fileExtension = pattern.exec(file.name)[0];
    let fileName = file.name;

    let sequence = 0;
    if (customGroup && customGroup.files && customGroup.files.length > 0) {
      sequence = customGroup.files[customGroup.files.length - 1].data.name.match(/\_(.*)\./).pop();
    }

    if (customGroup) {
      fileName = customGroup.name + '_' + (parseInt(sequence) + 1) + fileExtension;
    }

    return fileName;

  }


  uploadFile(file, filename) {
    const {path, group, authToken} = this.props;
    let form = new FormData(),
      xhr = new XMLHttpRequest();

    let url = path + "/";
    if (group)
      url = url + group + "/";
    url = url + filename;
    form.append('file', file);
    xhr.open('POST', url.replaceAll('|', '%7C'));//`${path}/${group}/${filename}`);

    xhr.setRequestHeader('x-auth-token', authToken);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (([200, 201].includes(xhr.status))) {
          this.setState({
              uploading: this.state.uploading - 1
            },
            () => {

              this.props.onComplete(file, group, filename);

              if (this.state.uploading == 0) {
                this.props.closeModal();
              }

            });
        } else {
          this.setState({
              uploading: this.state.uploading - 1
            },
            () => {

              showMessage('error', __('FileUploadFailed'));
              if (typeof this.props.onError !== 'undefined') {
                this.props.onError({file, group, filename});
              }

              if (this.state.uploading == 0) {
                this.props.closeModal();
              }
            });
        }
      }
    }
    xhr.send(form);
  }

  render() {
    let {multiple, acceptedMimeTypes} = this.props;
    if (getOS() === "Windows" && acceptedMimeTypes && acceptedMimeTypes.includes("text/csv")) {
      acceptedMimeTypes = acceptedMimeTypes + ",.csv";
    }

    return (

      <Modal
        backdrop="static"
        show={this.props.open}
        onHide={this.props.closeModal.bind(this)}>

        <Modal.Header closeButton>
          <Modal.Title className="capitalize">{__('UploadFile')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="drop-zone">

            <input type="file" onChange={this.handleFileSelection.bind(this)} name="file" id="file"
                   className="hide-input"
                   multiple={multiple} accept={acceptedMimeTypes || ''}/>
            <label htmlFor="file">{__('ChooseFile')}</label>

          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button className="pull-left" onClick={this.props.closeModal.bind(this)}>
            {__('Back')}
          </Button>
          {/*<Button className="btn-danger" onClick={this.props.onComplete.bind(this)}>
            {__('Delete')}
          </Button>*/}
        </Modal.Footer>

      </Modal>

    );
  }
}

export default UploadFileModal;
