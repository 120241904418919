import React, {useEffect, useState} from "react";
import {Form} from "components";
import {CheckboxList} from "../index";
import {FormRef} from "../../../temporary/temporaryExports";
import {Input} from "antd";
import { __ } from "src/utils/translationUtils";

type State = string[]

type ColumnFormProps = {
  items: { value: string; label: string }[]
  value: State
  onSubmit: (value: State) => void
  componentRef: React.MutableRefObject<FormRef | undefined>
}

const ColumnsForm = (props: ColumnFormProps) => {
  const {
    value,
    items,
    onSubmit,
    componentRef
  } = props

  const [filter, setFilters] = useState('')

  useEffect(() => {
      const formContent = document.querySelector('#form-content');
      if (formContent) {
        const checkboxWrappers: NodeListOf<HTMLLabelElement> = formContent.querySelectorAll('.ant-checkbox-group .ant-checkbox-wrapper');
        checkboxWrappers.forEach((wrapper) => {
          const match = wrapper.children?.[1]?.innerHTML?.toLowerCase().includes(filter?.toLowerCase())
          wrapper.style.display = match ? 'inline-flex' : 'none'
        });
      }
    }
  , [filter]);

  return (
   <>
     <div style={{ padding: '16px' }}>
       <Input placeholder={__('filter_column_by_name')} allowClear value={filter} onChange={(e) => setFilters(e.target.value) }
       />
     </div>
     <Form
       layout='horizontal'
       id="columns-form-id"
       formRef={componentRef}
       initialValues={{columns: value}}
       onSubmit={(values: { columns: State }) => {
         onSubmit(values.columns)
         setFilters('')
       }}
       inputs={[
         {
           type: "custom",
           name: "columns",
           CustomRender: (props: { value: State, onChange: ChangeHandler<State> }) => {
             const {value, onChange} = props

             return (
               <CheckboxList
                 items={items}
                 value={value}
                 onChange={(value: string[]) => { onChange(value) }}
               />
             )
           }
         }]}
       showSubmitButton={false}
     />
   </>
  )
}

export default ColumnsForm
