import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { actionType } from "../../../../UI/Table/constants";

import * as C from "../ObjectSelector/constants";
import _ from "lodash";
import SelectedItems from './components/SelectedItems';
import TableSelection from "./components/TableSelection";

export const SimpleObjectSelector = (props) => {

  const {
    value = [],
    onChange = () => { },
    parentObjectTypeId,
    parentObjectId,
    objectTypeId,
    attributeId,
    mappedBy,
    mode,
    selectionMode = C.selectionMode.MULTI,
    containerProps,
    enumerations,
    attributesTree,
    attributesList,
    missingConfigurationMessage,
    methods,
    rights,
  } = props

  const {
    containerRef,
    setExternalWrapperData,
    contentType = C.wrapperType.MODAL
  } = containerProps || {}

  const {
    updateEnumerations,
    fetchRemoteTableData,
    fetchDefaultConfiguration,
    addRelations,
    removeRelations,
  } = methods || {}

  const initialValue = useMemo(() => value, [])
  const [configuration, setConfiguration] = useState(null)
  const [viewSelection, setViewSelection] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (configuration) return
    const init = () => {
      setLoading(true)
      objectTypeId && fetchDefaultConfiguration?.(objectTypeId)
        .then(async (res) => {
          setConfiguration(res.data)

          /* Loads the selected values (only for MAPPED_BY) */
          if (!mappedBy || !parentObjectId || !objectTypeId) return

          const ids = (await fetchRemoteTableData?.({
            objectTypeId,
            payload: { ...res.data, searchAttributes: [], sortAttributes: [] },
            optionalProps: { mappedBy, parentObjectId }
          }))?.data?.map(({ id }) => id)
          onChange(ids)
        })
        .finally(() => setLoading(false))
    }
    init()
  }, [])

  const getCheckboxProps = useCallback(({ key }) => {
    const shouldDisableRow = (key) => {
      const checked = initialValue.includes(key)
      if (!rights?.add && !checked) return true
      if (!rights?.remove && checked) return true
      if (selectionMode === C.selectionMode.SINGLE && value.length && (!rights?.add || !rights?.remove)) return true
      return false
    }
    if (shouldDisableRow(key)) return { disabled: true }
  }, [initialValue, selectionMode, value, rights])

  return (
    <div>
      <SelectedItems
        toggleSelectionModal={() => setViewSelection(true)}
        rowActions={[actionType.VIEW_DETAILS]}
        mode={mode}
        onDeselect={(keyToRemove) => {
          mode === C.formMode.EDIT &&
            removeRelations({ objectTypeId: parentObjectTypeId, mainKey: parentObjectId }, { childObject: attributeId, childObjectIds: [keyToRemove] }).then(() => {
              onChange(value.filter((key) => (key !== keyToRemove)))
            })
        }}
        value={value}
        objectConfiguration={configuration}
        onChange={onChange}
        parentObjectId={parentObjectId}
        mappedBy={mappedBy}
        fetchRemoteTableData={fetchRemoteTableData}
        enumerations={enumerations}
        updateEnumerations={updateEnumerations}
        attributesList={attributesList}
      />
      <TableSelection
        wrapperProps={{
          visible: viewSelection,
          placement: 'right',
          onCancel: () => setViewSelection(false),
          type: contentType,
          setExternalWrapperData: setExternalWrapperData,
          containerRef: containerRef,
        }}
        wrapperType={C.wrapperType.DRAWER}
        parentObjectTypeId={parentObjectTypeId}
        parentObjectId={parentObjectId}
        objectTypeId={objectTypeId}
        attributeId={attributeId}
        mappedBy={mappedBy}
        objectConfiguration={configuration}
        value={value}
        onChange={onChange}
        enumerations={enumerations}
        attributesList={attributesList}
        missingConfigurationMessage={missingConfigurationMessage}
        attributesTree={attributesTree}
        methods={{
          fetchRemoteTableData,
          fetchDefaultConfiguration,
          fetchTableData: (payload) => fetchRemoteTableData({ objectTypeId: payload?.objectTypeId, payload }),
          updateEnumerations,
          addRelations,
          removeRelations,
        }}
        selectionMode={selectionMode}
        getCheckboxProps={getCheckboxProps}
      />
    </div>
  )
}

export const ObjectSelectorAdapted = (props) => {
  const { value, onChange } = props

  const consolidateValue = useMemo(() => {
    if (!value) return []
    if (_.isArray(value)) return value
    return value.split(',')
  }, [value])
  const consolidateOnChange = useCallback((newValue) => {
    if (_.isArray(value)) onChange(newValue)
    return onChange(newValue.length ? newValue.join(',') : null)
  }, [value, onChange])

  return (
    <SimpleObjectSelector
      {...props}
      value={consolidateValue}
      onChange={consolidateOnChange}
    />
  )
}

export default SimpleObjectSelector
