import React, {useCallback, useRef, useState} from 'react'

import ConfiguratorHelper from './ConfiguratorHelper';
import {useConfigurationConfiguratorContext} from "../../context";
import {useTranslationList} from "../../../../hooks";
import * as C from "../../constants";
import {sortMethod} from "../../../../components/ConfigurationConfiguratorBeta/constants";
import {getSortMethodItems} from "../../../../components/ConfigurationConfiguratorBeta/utils";
import { __ } from '../../../../utils/translationUtils'

const Sorting = ({ treeItems }) => {
  const { state } = useConfigurationConfiguratorContext()
  const {
    configurations,
    attributesMap,
  } = state || {}

  const [formItems, setFormItems] = useState(null)
  const translationItems = useTranslationList()
  const formRef = useRef(null)

  const getItemFormInitialValue = useCallback((key) => {
    const attribute = attributesMap[key]
    return {
      sortMethod: sortMethod.ASCENDING,
      defaultCustomTitle: attribute.propertyLabel,
      customTitle: null,
      defaultEnable: false,
    }
  }, [attributesMap])

  const onFormOpen = useCallback((item, values) => {
    const itemValue = values[item.key]
    setFormItems([
      {
        name: 'sortMethod',
        label: __('sort_method'),
        span: 12,
        type: 'select',
        placeholder: __('Default'),
        items: getSortMethodItems(),
        showSearch: true,
        optionFilterProp: 'label',
        allowClear: true,
      },
      {
        name: 'customTitle',
        label: __('custom_title'),
        span: 12,
        type: 'select',
        placeholder: __(itemValue.defaultCustomTitle || 'use default'),
        items: translationItems,
        disabled: !translationItems?.length,
        showSearch: true,
        optionFilterProp: 'label',
        allowClear: true,
      },
      {
        name: 'defaultEnable',
        label: __('default_enabled'),
        type: 'switch',
        size: 'small',
      },
    ])
  }, [translationItems])

  return (
    <ConfiguratorHelper
      treeItems={treeItems}
      formRef={formRef}
      formItems={formItems}
      onFormOpen={onFormOpen}
      getItemFormInitialValue={getItemFormInitialValue}
      labels={{
        noSelectedItemsTitle: __('sorting_no_data_title'),
        noSelectedItemsContent: __('sorting_no_data_content'),
      }}
      left={{title: __("choose_sorting_parameters")}}
      right={{title: __("set_and_order_selected_fields")}}
      pinnedKeys={Object.keys(configurations[C.steps.OUTPUT_FIELDS] || [])}
    />
  )
}

export default Sorting
