import styled from "styled-components";
import React from "react";
import {Button, Col, Row, Typography} from "antd";

import { __ } from '../utils/translationUtils'

const {Title, Paragraph} = Typography;

const PanelHeader = (props) => {
  const {
    panelOpen, setPanelOpen, panelKey,
    buttonType, collapseText = __("Collapse"), expandText = __("Expand"),
    titleComponent, subtitleComponent,
  } = props;
  const openOrExpand = () => {
    if (panelOpen.includes(panelKey)) {
      setPanelOpen(panelOpen.filter(e => e !== panelKey))
    } else {
      setPanelOpen(panelOpen.concat(panelKey))
    }
  };
  return (
    <React.Fragment>
      <Row
        onClick={event => event.stopPropagation()}
        justify="space-between"
        style={{cursor: "default"}}
      >
        <Col span={14}>
          {
            (titleComponent) ? titleComponent : <Title level={4}>{props.title}</Title>
          }

        </Col>
        <Col span={2} style={{}}>
          <Row justify="end" style={{marginTop: 10}}>
            <Button onClick={openOrExpand} type={(buttonType) ? buttonType : ''}>
              {panelOpen.includes(panelKey) ? collapseText : expandText}
            </Button>
          </Row>
        </Col>
      </Row>
      {
        (subtitleComponent) ?
          subtitleComponent :
          <div>
            {
              (props.subtitle) &&
              <Row>
                <Col span={14}>
                  <Paragraph ellipsis={{rows: 2}}>
                    {props.subtitle}
                  </Paragraph>
                </Col>
              </Row>
            }
          </div>
      }
    </React.Fragment>
  );
};


export const CollapseWrapper = styled.div`

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
   padding-left: 10px !important;
   padding-right: 10px !important;
   height: auto !important;
}

.ant-collapse-item, .ant-collapse-item {
  margin-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
}
`

export default PanelHeader;
