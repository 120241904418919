import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import React from 'react'
import PropTypes from 'prop-types'
import {change, formValueSelector} from 'redux-form'
import {Button, DropdownButton, Glyphicon, MenuItem, Modal} from 'react-bootstrap'
import {TableHeaderColumn} from 'react-bootstrap-table'
import objectAssign from 'object-assign'
import moment from 'moment'


import {stringify} from 'query-string'
import {Tooltip} from 'antd'
import {Icon} from '@ant-design/compatible'
import {
  addAsterisks,
  checkUserRights,
  cloneROEConfiguration,
  getInitialPageSize,
  fetchRemoteObjectConfiguration,
  renderResultInfo,
  searchToObject,
  setInitialPageSize,
  showMessage,
  updateRemoteObjectConfiguration,
} from '../utils/appHelper'
import * as config from '../constants/globalConfiguration'
import ActionIcon from '../components/ActionIcon'
import {deleteReport, importReport} from '../providers/ReduxProvider/actions/reportActions'
import {deleteView} from '../providers/ReduxProvider/actions/viewActions'
import {deleteRemoteObject} from '../providers/ReduxProvider/actions/remoteObjectActions'
import {deleteStepObject} from '../providers/ReduxProvider/actions/stepObjectActions'
import {WrappedBookmarkItem} from '../components/UI/BookmarkItem'
import {api} from '../providers/ApiProvider'
import {hashHistory} from '../providers/HistoryProvider'
import Loading from "../components/Loading";
import * as S from '../providers/StyleProvider/styles'
import EllipsisText from "../components/Ellipsis";
import {__} from '../utils/translationUtils'
import {ScrollableListView, StickyScrollableTable} from "../providers/StyleProvider/styles";

const loadingBar = require('nprogress')
const BasicSearchForm = require('../components/BasicSearchForm').default

const initialState = {
  templates: [],
  importModalOpen: false,
  deleteModal: {
    open: false,
    report: {
      name: '',
      id: 0
    }
  },
  data: [],
  isLoading: false,
  pagination: {
    pageNumber: 1,
    pageSize: getInitialPageSize('CustomReportsPage'),
    totalCount: 0
  },
  sort: 'name'
}

class CustomReportsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.export = this.export.bind(this)
    this.handleImportClick = this.handleImportClick.bind(this)
    this.readBlob = this.readBlob.bind(this)
    this.openImportModal = this.openImportModal.bind(this)
    this.closeImportModal = this.closeImportModal.bind(this)
    this.actionsFormater = this.actionsFormater.bind(this)
    this.handleClone = this.handleClone.bind(this)
    this.updateDefaultConfiguration = this.updateDefaultConfiguration.bind(this)
    this.renderDefaultConfiguration = this.renderDefaultConfiguration.bind(this)
  }

  componentDidMount() {
    // initial results
    this.setQueryToState(this.updateHash.bind(this))
  }

  export(row) {
    api.get(`/configurations/remoteObjects/${row.id}/export`, {responseType: 'arraybuffer'})
      .then((response) => {
        if (response.status === 200) {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
          const url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = `export_${moment().format('YYYYMMDD')}_${row.name}.json`
          a.click()
          window.URL.revokeObjectURL(url)

          this.setState({isLoading: false})
          loadingBar.done()
        }
      }).catch((ex) => {
      console.log(ex)
      this.setState({isLoading: false})
      loadingBar.done()
    })
  }

  readBlob() {
    const {files} = document.getElementById('files')
    if (!files.length) {
      alert(__('Please select a file'))
      return
    }

    const file = files[0]
    const start = 0
    const stop = file.size - 1

    const reader = new FileReader()

    const closeModal = () => {
      this.setState({importModalOpen: false})
    }

    const importToStore = (data) => {
      this.props.importReport(JSON.parse(data))
        .then(() => {
          hashHistory.push(`remoteObjects/create?fromImport`)
        })
    }

    // If we use onloadend, we need to check the readyState.
    reader.onloadend = function (evt) {
      if (evt.target.readyState == FileReader.DONE) {
        importToStore(evt.target.result)
        closeModal()
      }
    }
    const blob = file.slice(start, stop + 1)
    reader.readAsBinaryString(blob)
  }

  handleImportClick(e) {
    e.preventDefault()
    // Check for the various File API support.
    if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
      alert('Import functionality is not supported in this browser.')
    }
    this.readBlob()
  }

  openImportModal() {
    this.setState({importModalOpen: true})
  }

  closeImportModal() {
    this.setState({importModalOpen: false})
  }

  setQueryToState(cb) {
    const sq = searchToObject(this.props.location.search)
    this.props.change('basicSearchForm', 'q', sq.q)
    this.setState({
      sort: sq.sort || this.state.sort,
      pagination: objectAssign(this.state.pagination,
        {
          pageNumber: parseInt(sq.pageNumber) || this.state.pagination.pageNumber,
          pageSize: parseInt(sq.pageSize) || this.state.pagination.pageSize
        })
    }, () => {
      if (typeof cb !== 'undefined') {
        cb()
      }
    })
  }

  getParams() {
    const params = {...searchToObject(this.props.location.search)}
    return objectAssign({}, {
      sort: this.state.sort || params.sort,
      pageNumber: (parseInt(this.state.pagination.pageNumber) > -1) ? parseInt(this.state.pagination.pageNumber) : params.pageNumber,
      pageSize: this.state.pagination.pageSize || params.pageSize,
      q: this.props.q ? addAsterisks(this.props.q) : undefined
    })
  }

  updateHash() {
    // update url params
    hashHistory.push({
      ...this.props.location,
      search: stringify({
        pageNumber: this.state.pagination.pageNumber,
        pageSize: this.state.pagination.pageSize,
        sort: this.state.sort,
        q: this.state.q
      })
    }, this.getData())
  }

  search() {
    this.setState({
      q: this.props.q,
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: this.state.pagination.pageSize,
          pageNumber: 1
        }
      )
    }, () => {
      this.updateHash()
    })
  }

  getData = () => {
    this.setState({isLoading: true})
    loadingBar.start()
    const params = {...this.getParams.bind(this)(), pageNumber: this.getParams().pageNumber}
    params.status = 'disabled,enabled,reserved'
    const url = '/configurations/remoteObjects'

    return api.get(url, {params})
      .then(
        (response) => {
          this.setState({
            data: response.data,
            isLoading: false,
            pagination: objectAssign(
              this.state.pagination, {
                totalCount: parseInt(response.headers['x-total-count']),
                pageSize: parseInt(response.headers['x-page-size']),
                pageNumber: parseInt(response.headers['x-page'])
              }
            )
          }, () => {
            loadingBar.done()
          })
        },
        (error) => {
          this.setState({isLoading: false}, () => {
            loadingBar.done()
          })
        }
      )
  }

  onSortChange(sortName, sortOrder) {
    this.setState({sort: ((sortOrder === 'asc') ? '' : '-') + sortName}, function () {
      this.updateHash()
    })
  }

  onPageChange(page) {
    this.setState({
        pagination: objectAssign(
          this.state.pagination, {pageNumber: page}
        )
      },
      () => {
        this.updateHash()
      })
  }

  confirmDelete(report) {
    this.setState({deleteModal: {open: true, report}})
  }

  handledelete() {
    this.props.deleteRemoteObject(this.state.deleteModal.report.id).then(() => {
      this.getData()
    })
    this.closeDeleteModal()
  }

  handleClone(id) {
    if (!id) return
    this.setState({isLoading: true})
    cloneROEConfiguration(id)
      .then(() => {
        this.getData()
      })
      .catch(() => {
        this.setState({isLoading: false})
      })
  }

  async updateDefaultConfiguration(value, configurationId) {
    this.setState({isLoading: true}, async () => {
        const payload = await fetchRemoteObjectConfiguration(configurationId).then((resp) => resp?.data)
        updateRemoteObjectConfiguration({
          ...payload,
          defaultConfiguration: value
        }, configurationId)
          .then((resp) => {
            if (resp?.status === 200) {
              showMessage('success', __('Default configuration updated successfully'))
              this.getData()
            }
          })
          .catch((err) => {
            this.setState({isLoading: false})
          })
      }
    )
  }

  closeDeleteModal() {
    this.setState({deleteModal: {...this.state.deleteModal, open: false}})
  }

  setPageSize(val) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: val,
          pageNumber: 1
        }
      )
    }, () => {
      this.updateHash()
      setInitialPageSize('CustomReportsPage', val)
    })
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.setPageSize.bind(this)}
        bsStyle="link"
        title={this.state.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config.pageSizeOptions.map((val, i) => <MenuItem key={i} eventKey={val}>{val}</MenuItem>)}
      </DropdownButton>
    )
  }

  trClassFormat(row) {
    return row.status // return class name.
  }

  actionsFormater(cell, row) {
    const {user} = this.props
    const userRights = user ? user.rights : []
    const url = {view: 'remoteObjects', edit: 'remoteObjects'}
    return (
      <div style={{display: 'flex'}} className="actions text-right">
        {row.accessRightsInfo.read &&
          <ActionIcon
            title='Apply'
            type='play-circle'
            link={`/${url.view}/${row.id}`}
            disabled
          />
        }
        {row.accessRightsInfo.edit &&
          <ActionIcon
            title='Clone'
            type='copy'
            action={() => this.handleClone(row.id)}
          />
        }
        {row.accessRightsInfo.edit && checkUserRights(userRights, global.constants.rightMappings.CAN_EXPORT_REPORT) &&
          <ActionIcon
            title='Export'
            type='export'
            action={() => this.export(row)}
          />
        }
        {row.status !== 'deleted' && row.accessRightsInfo.delete &&
          <ActionIcon
            title='delete'
            type='delete'
            action={() => this.confirmDelete.bind(this)(row)}
            iconClass='icon-delete'
          />
        }
      </div>
    )
  }

  renderDefaultConfiguration(value, configuration) {
    const {mode} = this.props
    const {defaultConfigurationIsEditable: editable} = configuration

    const readOnly = mode === 'listing' || value
    const disabled = !editable && !readOnly

    return (
      <WrappedBookmarkItem
        value={value}
        onChange={(value) => this.updateDefaultConfiguration(value, configuration.id)}
        disabled={disabled}
        tooltip={disabled && {title: __('Sorry, it seems that currently there is already a default view for this object. Talk to your admin if you want to change it.')}}
        popconfirm={{
          title: <S.BoundedText maxWidth={350}>
            {__("Currently, you already have a default view for this object. Do you want to replace the current default view with this one?")}
          </S.BoundedText>,
          okText: __("Yes"),
          cancelText: __("No"),
        }}
        readOnly={readOnly}
      />
    )
  }

  render() {
    const {
      mode,
      user,
      title,
      remoteSystems,
      attributesTree
    } = this.props
    const {
      isLoading
    } = this.state
    const userRights = user ? user.rights : []
    const url = {view: 'remoteObjects', edit: 'remoteObjects'}

    function linkFormater(cell, row) {
      if (mode === 'listing') {
        if (row.accessRightsInfo.read || row.accessRightsInfo.edit) return <Link className="stronger"
                                                                                 to={`/remoteObjects/${row.id}`}>{row.name}</Link>
        return row.name
      }
      if (row.accessRightsInfo && !row.accessRightsInfo.edit) return row.name
      return <Link className="stronger" to={`/${url.edit}/${row.id}/edit`}>{row.name}</Link>
    }

    const {rightMappings} = global.constants
    return (
      <ScrollableListView id="main-content">
        <Loading loading={isLoading}/>
        <h1 className="sticky">
          <div className="container">
            <span>{__(title || `Manage RemoteObjects`)}</span>
          </div>
        </h1>

        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-lg-offset-1 col-sm-12">

              {mode !== 'listing' &&
                <>
                  {checkUserRights(userRights, rightMappings.CAN_FORCE_AUTOMATIC_CREATION) &&
                    <Link className="btn btn-success col-xs-12 col-sm-3 gutter-bottom" to="/remoteObjects/force-create">
                      <Glyphicon glyph="plus"/>
                      {__('Force automatic creation')}
                    </Link>
                  }
                  {checkUserRights(userRights, rightMappings.CAN_CREATE_REMOTE_OBJECT) &&
                    <Link style={{marginLeft: '20px'}} className="btn btn-success col-xs-12 col-sm-3 gutter-bottom"
                          to="/remoteObjects/create">
                      <Glyphicon glyph="plus"/>
                      {__('create new page')}
                    </Link>
                  }
                </>
              }

              <div className="col-xs-12 col-sm-6 col-lg-4">
                <BasicSearchForm
                  isLoading={this.state.isLoading}
                  itemType={'RemoteObject'}
                  initialValues={{q: searchToObject(this.props.location.search).q}}
                  onSubmit={this.search.bind(this)}
                />
              </div>

              {
                mode !== 'listing' && checkUserRights(userRights, rightMappings.CAN_IMPORT_REPORT) &&
                (
                  <Button onClick={this.openImportModal}>
                    <Glyphicon glyph="import"/>{__('Import')}
                  </Button>
                )
              }
            </div>
          </div>
        </div>

        <div className='container'>

          {this.state.data
            && (
              <StickyScrollableTable
                data={this.state.data}
                remote
                pagination
                striped
                trClassName={this.trClassFormat}
                fetchInfo={{dataTotalSize: this.state.pagination.totalCount}}
                options={{
                  onPageChange: this.onPageChange.bind(this),
                  onSortChange: this.onSortChange.bind(this),
                  noDataText: this.state.isLoading ? __('loading_data') : __('NoDataFound'),
                  hideSizePerPage: false,
                  firstPage: 'First',
                  lastPage: 'Last',
                  sizePerPageList: config.pageSizeOptions,
                  paginationShowsTotal: renderResultInfo.bind(this),
                  page: this.state.pagination.pageNumber,
                  sizePerPage: this.state.pagination.pageSize
                }}
              >
                <TableHeaderColumn
                  dataSort
                  isKey
                  hidden
                  dataField="id"
                >
                  {__('Id')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort
                  dataField="name"
                  width={'30%'}
                  dataFormat={linkFormater.bind(this)}
                >
                  {__(`RemoteObject Name`)}
                </TableHeaderColumn>

                <TableHeaderColumn
                  className="text-capitalize"
                  dataSort
                  dataField="systemId"
                  dataFormat={(systemId) => (
                    <EllipsisText>
                      {remoteSystems.some((e) => e.id.toString() === systemId.toString()) ? `${remoteSystems.find((e) => e.id.toString() === systemId.toString())?.name}` : systemId}
                    </EllipsisText>
                  )}
                >
                  {__('remote system')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  className="text-capitalize"
                  dataSort
                  dataField={"objectTypeId"}
                  dataFormat={(objectTypeId, {systemId}) => {
                    const objectTypeLabel = attributesTree?.[systemId]?.find((object) => object.objectTypeId === objectTypeId)?.label
                    return (
                      <EllipsisText>
                        {objectTypeLabel ? __(objectTypeLabel) : objectTypeId}
                      </EllipsisText>
                    )
                  }}
                >
                  {__('Object type')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  headerText={__('Default Object Type View')}
                  className="text-capitalize"
                  dataSort
                  dataField={"defaultConfiguration"}
                  dataFormat={this.renderDefaultConfiguration}
                >
                  {__('Default view')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort
                  dataField="creator.username"
                  dataFormat={(col, row) => {
                    const {creator} = row
                    return (
                      <Link to={`/users/${creator.id}`}>
                        {creator.username}
                      </Link>
                    )
                  }}
                >
                  {__('creator')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort
                  dataField="creationDatetime"
                  dataFormat={(val) => (
                    <EllipsisText>
                      {moment(val).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                    </EllipsisText>
                  )}
                >
                  {__('creationDateTime')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort
                  dataField="lastUpdateDatetime"
                  dataFormat={(val) => (
                    <EllipsisText>
                      {moment(val).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                    </EllipsisText>
                  )}
                >
                  {__('lastUpdateDatetime')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort
                  dataField="type"
                  width={'7%'}
                  dataFormat={(val) => (
                    <Tooltip title={__(val)}><Icon style={{display: 'block', margin: 'auto'}} className="icon-std"
                                                   type={val.toLowerCase() === 'global' ? 'global' : val.toLowerCase() === 'private' ? 'user' : 'bank'}
                                                   theme="outlined"/></Tooltip>)}
                >
                  {__('Type')}
                </TableHeaderColumn>

                {mode !== 'listing'
                  && (
                    <TableHeaderColumn
                      width='180px'
                      dataFormat={this.actionsFormater}
                    />
                  )}
              </StickyScrollableTable>
            )}

          <Modal
            backdrop="static"
            show={this.state.deleteModal.open}
            onHide={this.closeDeleteModal.bind(this)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{__('Confirm Deletion')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{__('Are you sure you want to delete')} "<strong>{this.state.deleteModal.report.name}</strong>"?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeDeleteModal.bind(this)}>
                {__('Back')}
              </Button>
              <Button onClick={this.handledelete.bind(this)} bsStyle="danger">
                {__('Delete')}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            backdrop="static"
            show={this.state.importModalOpen}
            onHide={this.closeImportModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>{__('Import')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input type="file" id="files" name="file"/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeImportModal.bind(this)}>
                {__('Cancel')}
              </Button>
              <Button onClick={this.handleImportClick.bind(this)} bsStyle="primary">
                <Glyphicon glyph="import"/>{__('Import')}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

      </ScrollableListView>
    )
  }
}

CustomReportsPage.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  deleteReport: PropTypes.func,
  deleteRemoteObject: PropTypes.func,
  deleteStepObject: PropTypes.func,
  deleteView: PropTypes.func,
  importReport: PropTypes.func,
  change: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object,
  q: PropTypes.string,
  report: PropTypes.object,
  remoteSystems: PropTypes.arrayOf(Object)
}

CustomReportsPage.defaultProps = {
  mode: '',
  title: null,
  remoteSystems: []
}

const selector = formValueSelector('basicSearchForm')
export default connect(
  (state) => ({
    user: state.user.data,
    q: selector(state, 'q'),
    remoteSystems: state.ui.remoteSystems,
    attributesTree: state.ui.attributesTree
  }),
  {deleteReport, deleteStepObject, deleteRemoteObject, deleteView, importReport, change}
)(CustomReportsPage)
