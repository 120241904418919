import React from "react"
import PropTypes from "prop-types"
import * as CONFIG_TYPE from "./../context/configurationType"
import * as TEMPLATES from "./../templates/main"

/*
 * 1 Manage the template to show using a factory
 * */
MainFactory.propTypes = {
  configurationType: PropTypes.oneOf([CONFIG_TYPE.VIEW, CONFIG_TYPE.REPORT, CONFIG_TYPE.OBJECT_LIST, CONFIG_TYPE.OBJECT_BINDER]).isRequired,
}

function MainFactory({configurationType, ...props}) {
  switch (configurationType) {
    case CONFIG_TYPE.OBJECT_LIST:
    case CONFIG_TYPE.OBJECT_BINDER:
    case CONFIG_TYPE.OBJECT_PICKER:
      return <TEMPLATES.ObjBinding {...props} configurationType={configurationType}/>
    case CONFIG_TYPE.VIEW:
      return <TEMPLATES.View/>
    case CONFIG_TYPE.REPORT:
      return <TEMPLATES.Report/>
    default:
      return <div>...loading</div>
  }
}

export default MainFactory
