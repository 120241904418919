import * as T from './types'
import * as C from "../constants";

export const getInitialState = () => ({
  value: {},
  onChange: undefined,
  readOnly: false,
  missingConfigurationMessage: undefined,
  api: undefined,
  readFrom: undefined,
  writeTo: undefined,
  enumerations: undefined,
  attributesList: undefined,
  attributesTree: undefined,
  remoteObjectItems: [],
  loading: false,
  initialized: false,
  error: false
})

export const initState = (state) => ({ ...state })

export const getAttributeFromId = (id, attributesList) => {
  return attributesList?.find((attribute) => attribute.id === id)
}

export const ObjectSelectorReducer = (state, action) => {

  const {
    type,
    payload
  } = action

  if (!type) return

  switch (type) {

    case T.ERROR:
      return {
        ...state,
        initialized: true,
        error: true
      }
    case T.FETCHING_DATA:
      return {
        ...state,
        loading: payload,
      }

    case T.INITIALIZED:
      const optionalPayload = {}

      if (payload?.readFrom?.attributeId) {
        optionalPayload.readFrom = {
          ...payload.readFrom,
          attribute: getAttributeFromId(payload.readFrom.attributeId, payload.attributesList)
        }
      }

      if (payload?.writeTo?.attributeId) {
        optionalPayload.writeTo = {
          ...payload.writeTo,
          attribute: getAttributeFromId(payload.writeTo.attributeId, payload.attributesList)
        }
      }

      return {
        ...state,
        ...payload,
        ...optionalPayload,
        initialized: true,
      }

    case T.UPDATE_ENUMS:
      return {
        ...state,
        enumerations: {
          ...state.enumerations,
          ...payload
        }
      }

    case T.UPDATE_REMOTE_OBJECT_ITEMS:
      return {
        ...state,
        remoteObjectItems: payload
      }

    case T.UPDATE_VALUE:
      const {
        selectedItems = [],
        objectConfiguration,
      } = payload || {}
      return {
        ...state,
        value: {
          selectedItems,
          objectConfiguration
        },
      }

    default:
      return state
  }
}
