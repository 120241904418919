import React, {useContext, useEffect, useState} from "react"
import * as CONTEXT from "./../../context"
import {SORT_ATTRIBUTES} from "./../../utils/requiredFields"
import * as UT from "./../utils"
import * as S from "./../../style"
import AttributeTransfer from "../AttributeTransfer"
import * as SORT_TYPES from "./sortingType"
import FormContext from "../../factories/FormContext"
import {Form, Icon} from '@ant-design/compatible';
import {Collapse, Descriptions, Select} from 'antd'

const {Panel} = Collapse
const {Option} = Select

function ObjectListing() {
  const {state} = useContext(CONTEXT.MainContext)
  const {form} = useContext(FormContext)

  const [attributeInformation, setAttributeInformation] = useState(null)

  useEffect(() => {
    if (state.editingForms.configurationFormFields) {
      setAttributeInformation(UT.retrieveAttributesInformation(state.editingForms.configurationFormFields, SORT_ATTRIBUTES))
    }
  }, [state.editingForms.configurationFormFields])

  const renderCard = (key) => {
    const attributeProperty = (attributeInformation?.attributesFlat || []).find((attribute) => attribute.key === key)
    //const formFields = state.editingForms.configurationFormFields || {}

    return (
      <S.SortableContainer>
        <S.FormDescription>
          <Descriptions title={attributeProperty?.property || ""}/>
        </S.FormDescription>

        <Collapse defaultActiveKey={[]}>
          <Panel
            header={
              <S.PanelHeader>
                <Icon type="more"/>
                Show More
              </S.PanelHeader>
            }
          >
            {/* DEFAULT VALUE */}
            <S.FormField>
              <Form.Item label={"Sorting"}>
                {form.getFieldDecorator(`${SORT_ATTRIBUTES},${key},value`, {initialValue: SORT_TYPES.ASC})(
                  <Select>
                    <Option key={SORT_TYPES.ASC} value={SORT_TYPES.ASC}>
                      {SORT_TYPES.ASC}
                    </Option>
                    <Option key={SORT_TYPES.DESC} value={SORT_TYPES.DESC}>
                      {SORT_TYPES.DESC}
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </S.FormField>
          </Panel>
        </Collapse>
      </S.SortableContainer>
    )
  }

  const createSortAttributes = (attributeKeys) => {
    const res = {}

    attributeKeys.forEach((attributeId, index) =>
      Object.assign(res, {
        [`${SORT_ATTRIBUTES},${attributeId},attributeId`]: {
          name: `${SORT_ATTRIBUTES},${attributeId},attributeId`,
          value: attributeId,
        },
        [`${SORT_ATTRIBUTES},${attributeId},position`]: {
          name: `${SORT_ATTRIBUTES},${attributeId},position`,
          value: index,
        },
        [`${SORT_ATTRIBUTES},${attributeId},value`]: {
          name: `${SORT_ATTRIBUTES},${attributeId},value`,
          value: form.getFieldValue(`${SORT_ATTRIBUTES},${attributeId},value`) || SORT_TYPES.ASC,
        },
      })
    )
    return res
  }

  if (!attributeInformation) {
    return <S.Loading>...Loading</S.Loading>
  }

  return (
    <AttributeTransfer
      attributesInformation={attributeInformation}
      renderCard={renderCard}
      attributeType={SORT_ATTRIBUTES}
      onAttributeTransfer={createSortAttributes}
    />
  )
}

export default ObjectListing
