import styled, {css} from "styled-components";
import {Empty} from "antd";

const NoDataStyles = {
  size: {
    default: css`
      font-size: 14px;
    `,
    large: css`
      font-size: 18px;
      & > .ant-empty-image {
        height: max(150px, 5vh)
      }
    `,
    small: css`
      font-size: 10px;
      & > .ant-empty-image {
        height: 50px
      }
    `
  },
  noImage: css`
    .ant-empty-image {
      display: none;
    }
  `,
  centered: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
}

export const NoData = styled(Empty)`
  font-family: inherit;
  color: inherit;
  height: 100%;
  padding: ${props => props.space ? `${props.space}px` : '0'};
  ${props => {
    switch (props.size) {
      case 'large':
        return NoDataStyles.size.large
      case 'small':
        return NoDataStyles.size.small
      default:
        return NoDataStyles.size.default
    }
  }}
  ${props => !props.image && NoDataStyles.noImage}
  ${props => props.centered && NoDataStyles.centered}
`

const TitleStyles = {
  size: {
    default: css`
      font-size: 14px;
    `,
    large: css`
      font-size: 18px;
    `,
    small: css`
      font-size: 10px;
    `
  }
}

export const Title = styled.div`
  margin-bottom: 5px;
  font-weight: 600;
  color: inherit;
  ${props => {
    switch (props.size) {
      case 'large':
        return TitleStyles.size.large
      case 'small':
        return TitleStyles.size.small
      default:
        return TitleStyles.size.default
    }
  }}
`

const SubTitleStyles = {
  size: {
    default: css`
      font-size: 10px;
    `,
    large: css`
      font-size: 14px;
    `,
    small: css`
      font-size: 8px;
    `
  }
}

export const SubTitle = styled.div`
  font-weight: 500;
  color: grey;
  ${props => {
  switch (props.size) {
    case 'large':
      return SubTitleStyles.size.large
    case 'small':
      return SubTitleStyles.size.small
    default:
      return SubTitleStyles.size.default
  }
}}
`

export const WrapText = styled.span`
  font-weight: 500;
  font-size: inherit;
  ${props => props.before && 'padding-left: 4px'};
  ${props => props.after && 'padding-right: 4px'};
`
