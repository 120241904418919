import React from 'react';
import PropTypes from 'prop-types';
import Glyphicon from '@strongdm/glyphicon'
import { SortableHandle, SortableContainer, SortableElement} from 'react-sortable-hoc';
import _ from 'lodash';

import TabConfigurationCard from './../components/TabConfigurationCard';

import { __ } from '../utils/translationUtils'

const SortableItem = SortableElement(({value}) =>
  <div>{value}</div>
);

const SortableList = SortableContainer(({ items, removeObjectFromTab }) => {
  return (
    <div className="configuration-list">
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          removeObjectFromTab={removeObjectFromTab}
        />
      ))}
    </div>
  );
});

const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

class TabConfigCard extends React.Component {
  constructor(props) {
    super(props);

    this.configurationList = this.configurationList.bind(this);
  }

  configurationList() {
    const sorted = _.sortBy(this.props.tab.configurations, 'overviewObjectPosition');
    return sorted.map(
      (configuration) => (
        <TabConfigurationCard
          key={configuration.id}
          configuration={configuration}
          tab={this.props.tab}
          removeObjectFromTab={this.props.removeObjectFromTab}
        />
      )
    );
  }

  render() {
    const {
      tab,
      updateTabName,
      tabNames,
      removeTab,
      syncTabNames,
    } = this.props;

    return (
      <div
        className="tabs-list-item"
        key={tab.id}
      >
        <div className="tabs-list-item__container">
          <div className="panel panel-default">
            <div className="panel-heading">
              <DragHandle />
              <Glyphicon onClick={
              () => removeTab(tab.id)}
              className="remove-icon" glyph="remove" />
              {' '}
              <input
                className="tab-name-input form-control"
                onChange={(e) => { updateTabName(tab, e.target.value); }}
                onBlur={() => { syncTabNames(); }}
                value={__(tabNames[tab.id])}
              />
            </div>
            <div className="panel-body" >
              <SortableList
                lockAxis="y"
                lockToContainerEdges
                items={this.configurationList()}
                useDragHandle={true}
                removeObjectFromTab={this.props.removeObjectFromTab}
                onSortEnd={this.props.onConfigurationSortEnd}
              />
              { this.props.tab.configurations.length === 0 &&
                <i className="discreet">
                  No configurations added yet.
                </i>
              }
            </div>
          </div>

        </div>
      </div>
    );
  }
}

TabConfigCard.propTypes = {
  tab: PropTypes.object,
  updateTabName: PropTypes.func,
  tabNames: PropTypes.object,
  syncTabNames: PropTypes.func,
  onConfigurationSortEnd: PropTypes.func,
  removeObjectFromTab: PropTypes.func,
  removeTab: PropTypes.func,
};

export default TabConfigCard;
