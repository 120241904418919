import React from "react";
import moment from "moment";
import {Tooltip, Typography} from "antd";
import * as config from "../../../constants/globalConfiguration";
import * as C from './constants'
import EllipsisText from "components/dist/Typography/EllipsisText";

import _ from "lodash";
import {valueTypeToInput} from "../../../utils/renderInputField";
import {appDefaults} from "../../../constants/globalConfiguration";

import {__} from '../../../utils/translationUtils'


const addAsterisks = (text) => {
  if (config.ADD_ASTERISKS_TO_SEARCH_VALUES) {
    if (text.indexOf('*') !== -1) return text
    return `*${text}*`
  }
  return text
}

export const prepareRemoteObjectsResult = (
  remoteObjectsResult,
  attributeList,
  objectConfiguration,
) => {

  if (remoteObjectsResult == null || attributeList == null || objectConfiguration == null) return []
  const result = []
  remoteObjectsResult.forEach((remoteObject) => {
    const displayDataRes = {}
    result.push({...remoteObject, displayData: displayDataRes})
    if (!remoteObject.displayData) {
      return
    }

    Object.entries(remoteObject.displayData).forEach(([attributeId, displayData]) => {
      const attribute = attributeList.find(a => a.id === attributeId);
      let displayAttribute = objectConfiguration.displayAttributes.find(a => a.attributeId === attributeId);

      displayDataRes[attributeId] = displayData
      if (displayData != null && attribute.type === "YESNO") {
        displayDataRes[attributeId] = displayData.toLowerCase() === 'true' ? __('val_yes') : __('val_no');
      }
      if (displayAttribute.controllerType === "PopupListBox" || displayAttribute.defaultControllerType === "PopupListBox") {
        displayDataRes[attributeId] = displayData !== null ? __(displayData) : displayData;
      }
      if (displayAttribute.controllerType === "DateInput") {
        displayDataRes[attributeId] = displayData !== null ? moment(displayData).format(config.appDefaults.dateFormat) : displayData;
      } else if (displayAttribute.controllerType === "DateTimeInput") {
        displayDataRes[attributeId] = displayData !== null ? moment(displayData).format(config.appDefaults.dateTimeFormat) : displayData;
      } else if (attribute.type === "DATE") {
        displayDataRes[attributeId] = displayData !== null && moment(displayData).isValid() ? moment(displayData).format(appDefaults.dateTimeFormat) : displayData
      }
    })

  })

  return result;

}

const formatField = (value, type) => {
  if (!value || (_.isArray(value) && !value.length) || (_.isObject(value) && !Object.keys(value).length)) {
    return <Typography.Text italic type="secondary">-</Typography.Text>
  }
  switch (type) {
    case 'DATE':
      return (
        <EllipsisText>
          {value}
        </EllipsisText>
      )
    case 'ENUM_VALUE':
    case 'IDS':
      const valueList = (value.value || value).split('/')
      if (valueList.length <= 1) return (
        <EllipsisText>
          {valueList?.[0]}
        </EllipsisText>
      )
      return (
        <Tooltip
          title={valueList.join(', ')}
        >
          {`${valueList.length} items`}
        </Tooltip>
      )
    default:
      return (
        <EllipsisText>
          {(_.isArray(value) || _.isObject(value)) ? JSON.stringify(value) : value}
        </EllipsisText>
      )
  }
}

export const dataMappingMethod = (data, attributes) => {
  return data?.map((row) => ({
    id: row.id,
    key: row.id,
    ...Object.keys(row.displayData).reduce((prev, el) => {
      prev[el] = formatField(row.displayData[el], attributes.find(({id}) => id === el)?.type)
      return prev
    }, {})
  }))
}

export const colsItemsMappingMethod = (cols, options) => {
  const {
    enableSorting,
    enableSelect,
  } = options || {}
  return cols.map((col, index) => ({
    title: col.label,
    key: `table-column-${col.value}`,
    width: 150,
    dataIndex: col.value,
    fixed: enableSelect && index === 0,
    sorter: enableSorting,
  }))
}

const getEnumValuesItems = (enumValues) => {
  if (!enumValues?.length) return []
  return enumValues.map((item) => ({
    value: item.value,
    label: item.toBeTranslated ? __(item.translationKey) : item.value
  }))
}

export const getEnumValuesMapItems = (enumValuesMap) => {
  if (!enumValuesMap || !Object.keys(enumValuesMap)?.length) return []
  return Object.keys(enumValuesMap).reduce((prev, enumId) => {
    prev[enumId] = getEnumValuesItems(enumValuesMap[enumId])
    return prev
  }, {})
}

export const getSearchInitialValues = (searchAttributes) => {
  if (!searchAttributes?.length) return {}
  return searchAttributes?.reduce((prev, attribute) => {
    switch (attribute.mechanism) {
      case C.mechanismType.SPECIFIC_VALUE:
        prev[attribute.attributeId] = attribute.value || attribute.defaultValue?.value
        break
      case C.mechanismType.ENUMERATION:
        prev[attribute.attributeId] = attribute.value
        break
      case C.mechanismType.RANGE:
        prev[`${attribute.attributeId}From`] = attribute.from
        prev[`${attribute.attributeId}To`] = attribute.to
        break
      default:
        break
    }
    return prev
  }, {})
}

export const getSearchAttributesFromValues = (searchValues, searchAttributes) => {
  if (!Object.keys(searchValues).length) return searchAttributes
  const newSearchAttributes = _.cloneDeep(searchAttributes)
  Object.keys(searchValues).forEach((attrId) => {
    const searchAttribute = newSearchAttributes.find((attr) => attr.attributeId === attrId)
    if (!searchAttribute) return
    switch (searchAttribute.mechanism) {
      case C.mechanismType.SPECIFIC_VALUE:
        if (!_.isEqual(searchValues[attrId], searchAttribute.value)) {
          searchAttribute.value = searchValues[attrId]
          if (searchAttribute.defaultValue)
            searchAttribute.defaultValue.value = searchValues[attrId]
        }
        break
      case C.mechanismType.ENUMERATION:
        searchAttribute.value = searchValues[attrId]
        break
      case C.mechanismType.RANGE:
        searchAttribute.from = searchValues[`${attrId}From`]
        searchAttribute.to = searchValues[`${attrId}To`]
        break
      default:
        break
    }
  })
  return newSearchAttributes
}

const prepareSpecificValue = (searchAttribute, attributeValueMap, attributeConfiguration) => {

  if (!searchAttribute?.mechanism === 'SPECIFIC_VALUE' || !attributeConfiguration || !attributeValueMap) return;
  if (attributeValueMap[attributeConfiguration.id] == null) {
    delete searchAttribute.value
    return;
  }
  switch (valueTypeToInput[attributeConfiguration.type]) {
    case 'datepicker':
      attributeValueMap[attributeConfiguration.id] = attributeValueMap[attributeConfiguration.id].utc().format();
      searchAttribute.value = attributeValueMap[attributeConfiguration.id]
      break
    case 'input':
      searchAttribute.value = searchAttribute.exactMatch ? attributeValueMap[attributeConfiguration.id] : addAsterisks(attributeValueMap[attributeConfiguration.id])
      break
    case 'switch':
      searchAttribute.value = attributeValueMap[attributeConfiguration.id] == 'true'
      break
    default:
      searchAttribute.value = attributeValueMap[attributeConfiguration.id]
      break
  }
}

const prepareDateRangePayload_applyFelixAlgorithm = (searchAttribute, attributeValueMap, attributeConfiguration) => {
  if (searchAttribute == null || attributeConfiguration == null || attributeValueMap == null) return;

  if (searchAttribute.mechanism !== 'RANGE') return

  /* Apply Felix's Algorithm on range fields
   * 1) If both *from* and *to* are filled --> normal *range* search.
   * 2) If only *from* is filled --> *specific_value* search. (the from can contain wildcard)
   * 3) if only *to* is filled the webui fills the from value with the same value contained in the *to* --> *range* search (edited)
   */

  if (attributeConfiguration.type === 'DATE') {
    attributeValueMap[attributeConfiguration.id + 'From'] = attributeValueMap[attributeConfiguration.id + 'From'] ?
      moment(attributeValueMap[attributeConfiguration.id + 'From']).utc().format() : undefined

    attributeValueMap[attributeConfiguration.id + 'To'] = attributeValueMap[attributeConfiguration.id + 'To'] ?
      moment(attributeValueMap[attributeConfiguration.id + 'To']).utc().format() : undefined
  }

  if (attributeValueMap[attributeConfiguration.id + 'From']) {
    searchAttribute.from = attributeValueMap[attributeConfiguration.id + 'From']
  } else {
    delete searchAttribute.from
  }

  if (attributeValueMap[attributeConfiguration.id + 'To']) {
    searchAttribute.to = attributeValueMap[attributeConfiguration.id + 'To']
  } else {
    delete searchAttribute.to
  }

  const {to, from} = searchAttribute;

  if (from != null && to == null) {
    searchAttribute.mechanism = 'SPECIFIC_VALUE'
    searchAttribute.value = from
    delete searchAttribute.from
    delete searchAttribute.to
    delete searchAttribute.multiSelect
  } else if (!from && to) {
    searchAttribute.from = to
  }
}

const prepareEnumeration = (searchAttribute, attributeValueMap, attributeConfiguration) => {
  if (searchAttribute == null || attributeConfiguration == null || attributeValueMap == null) return;
  if (searchAttribute.mechanism !== 'ENUMERATION') return

  if (attributeValueMap[attributeConfiguration.id] == null) {
    delete searchAttribute.value
    return;
  }
  searchAttribute.value = attributeValueMap[attributeConfiguration.id]
}

export const getPayloadForDataFetch = (props) => {

  const {
    searchValues = {},
    sortValues = [],
    currentPagination = {},
    dataStructures = {},
  } = props || {}

  const {
    objectConfiguration = {},
    attributesList = [],
  } = dataStructures

  if (!objectConfiguration || !attributesList) {
    console.error('Not all props are settled correctly cannot execute the search');
    return;
  }

  const payload = {
    systemId: objectConfiguration.systemId,
    objectTypeId: objectConfiguration.objectTypeId,
    displayAttributes: objectConfiguration.displayAttributes,
    searchAttributes: objectConfiguration.searchAttributes ? _.cloneDeep(objectConfiguration.searchAttributes) : [],
    sortAttributes: sortValues.filter((item) => item.enabled),
    pageSize: currentPagination.pageSize,
    pageNumber: currentPagination.current
  };

  const payloadSearchAttribute = [];
  payload.searchAttributes.forEach(sA => {
    const attributeConfiguration = attributesList.find(a => a.id === sA.attributeId);
    switch (sA.mechanism) {
      case "SPECIFIC_VALUE":
        // TODO: to remove when works
        //if (attributeConfiguration.id === '100_6857c9117b0bd0fbe124db21169e') break
        //if (sA.attributeId === '100_dd4bf7e78a984fb1993007e4a005') sA.mechanism = 'ENUMERATION'
        prepareSpecificValue(sA, searchValues, attributeConfiguration);
        if (searchValues[sA.attributeId]) payloadSearchAttribute.push(sA);
        break;
      case "RANGE":
        prepareDateRangePayload_applyFelixAlgorithm(sA, searchValues, attributeConfiguration);
        if (searchValues[sA.attributeId + "From"] != null || searchValues[sA.attributeId + "To"] != null) payloadSearchAttribute.push(sA);
        break;
      case "ENUMERATION":
        prepareEnumeration(sA, searchValues, attributeConfiguration);
        if (searchValues[sA.attributeId]) payloadSearchAttribute.push(sA);
        break;
      default:
        console.error("Unsupported mechanism" + sA.mechanism);
        break;
    }
  })
  payload.searchAttributes = payloadSearchAttribute
  return payload
}
