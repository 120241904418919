import React, {useCallback} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {message, Popconfirm, Tooltip} from "antd";
import {ControlOutlined, EyeOutlined, SortAscendingOutlined, TableOutlined, SwapOutlined} from "@ant-design/icons";

import { useConfigurationConfiguratorContext } from "../context";
import Header from '../../../components/ConfigurationConfiguratorBeta/Header'
import * as C from '../constants';
import * as S from "../styles";
import { __, T } from '../../../utils/translationUtils'

/* TODO: to delete on old configuration configurator deletion */
const GoToOldConfigurator = ({ mode }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <Popconfirm
      title={
        <>
          <S.CustomText weight={700} size={'large'}> {__('You are moving to the old configurator')} </S.CustomText>
          <S.CustomText> {__('Changes will be lost')} </S.CustomText>
          <S.CustomText> {__('Do you want to leave?')} </S.CustomText>
        </>
      }
      okText={__('Yes')}
      cancelText={__('No')}
      placement="bottomRight"
      onConfirm={() => {
        if (mode === C.mode.CREATE) history.push(location.pathname.split('/').slice(0,-1).join('/') + '/deprecatedCreate')
        if (mode === C.mode.EDIT) history.push(location.pathname.split('/').slice(0,-1).join('/') + '/deprecatedEdit')
      }}
    >
      <Tooltip title={__('Move to the old configurator')} placement='left'>
        <SwapOutlined />
      </Tooltip>
    </Popconfirm>
  )
}

export default () => {

  const { state, setActiveStep, setStepValidationVisibility} = useConfigurationConfiguratorContext()
  const { activeStep, mode, type, validationState } = state

  const onStepChange = useCallback((current) => {
    if (validationState[activeStep] != null && !validationState[activeStep]) {
      setStepValidationVisibility(C.validationActivatedAfter.CLOSE)
      message.error(
        __((
            activeStep === C.steps.OUTPUT_FIELDS && T.chooseFieldsValidationError)
          || (activeStep === C.steps.VIEW_PROPERTIES && T.viewPropertiesValidationError)
        )
      )
    }
    else {
      setActiveStep(current)
    }
  }, [activeStep, setActiveStep, setStepValidationVisibility, validationState])

  return (
    <Header
      title={__(`${mode} ${type}`)}
      steps={[
        {title: __("output_fields"), value: "OutputFields", icon: <TableOutlined />},
        {title: __("filters"), value: "Filters", icon: <ControlOutlined />},
        {title: __("sorting"), value: "Sorting", icon: <SortAscendingOutlined />},
        {title: __("view_properties"), value: "ViewProperties", icon: <EyeOutlined />},
      ]}
      activeStep={activeStep}
      onStepChange={onStepChange}
      extra={<GoToOldConfigurator mode={mode}/>}
      ghostExtra={true}
    />
  )
}
