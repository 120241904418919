import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../../Menu';
import Logo from '../Logo';
import UserInfo from '../../UserInfo';
import { addBeamerScript } from '../../../utils/appHelper';
import { MenuOutlined } from '@ant-design/icons';

import { logout, redirect, getGuiUser } from '../../../providers/ReduxProvider/actions/userActions';
import { connect } from 'react-redux';

class Header extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    location: PropTypes.object,
    logout: PropTypes.func,
    getGuiUser: PropTypes.func,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.user.authToken !== null && !this.props.user.data) {
      this.props.getGuiUser()
      addBeamerScript();
    }
  }

  handleLogout() {
    this.props.logout();
    return false;
  }

  render() {
    return (
      <header id="main-header" className="group dont-print">
        <input type="checkbox" name="menu-button" id="menu-button" />
        <div className="main-menu">
          <div className="container">
            <label htmlFor="menu-button" className="small-menu-icon"><MenuOutlined style={{ fontSize: "24px" }} /></label>
            <div className="logoHeader" ><Logo className="circle" /></div>
            <Menu selectedItem={{}} location={this.props.location} />
            <div className="right-panel">
              <UserInfo handleLogout={this.handleLogout.bind(this)} user={this.props.user.data} />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user
    };
  },
  { logout, redirect, getGuiUser }
)(Header);
