import React from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'
import { renderColumns } from '../utils/formatTools'
import { __ } from '../utils/translationUtils'

class AttributeNodeEdit extends React.Component {
  static propTypes = {
    node: PropTypes.object.isRequired,
    renderLine: PropTypes.func.isRequired,
    depth: PropTypes.number,
    maxColumns: PropTypes.number
  };

  static defaultProps = {
    depth: 1,
    maxColumns: 2
  };

  constructor(props) {
    super(props)
    this.state = { open: true }
  }

  render() {
    const { renderLine, node, depth } = this.props
    const rows = []
    const childNodes = []

    if (typeof node === 'undefined') {
      console.log('Node is undefined')
      return <div>Node is undefined</div>
    }
    node.data.forEach((data) => {
      rows.push(renderLine(data)) // label, value, key.
    })

    node.children.forEach((child) => {
      childNodes.push(
        <AttributeNodeEdit
          key={`fs-${child.label}-${child.objectTypeId}`}
          node={child}
          depth={depth + 1}
          renderLine={renderLine}
        />
      )
    })
    const { maxColumns } = this.props

    return (
      <div
        className={`fieldset style-${depth}`}
      >

        <span
          className="collapsible-header no-selection main-object-title-label"
          onClick={
            () => this.setState({ open: !this.state.open })
          }
        >
          {__(node.label)}
        </span>

        <Panel
          defaultExpanded={this.state.open}
        >
          <Panel.Body collapsible className='main-object-table-content'>
            <div className="form-horizontal">

              {rows.length > 0
              && (
                <div className="group">
                  {renderColumns(rows, maxColumns)}
                </div>
              )}

              {childNodes.length > 0
              && (
                <div className={(depth == 2) ? 'group row' : 'group'}>
                  {childNodes}
                </div>
              )}

            </div>
          </Panel.Body>
        </Panel>

      </div>
    )
  }
}

export default AttributeNodeEdit
