import { orderingTypes } from '../constants'
import { __ } from './translationUtils'

const getItemValue = (option, params) => {
  if (!option) return ''
  const {
    fieldToBeTranslated = 'translationKey',
    fieldToBeCompared = 'value'
  } = params || {}
  if (!option.toBeTranslated) {
    return (typeof option[fieldToBeCompared] === 'string') ? option[fieldToBeCompared] : ''
  }
  return __(option[fieldToBeTranslated])
}

export const sortEnumItems = (options, orderingType, params) => {
  if (!options?.length) return []
  const sortedOptions = [...options]
  switch (orderingType) {
  case orderingTypes.ASCENDING:
    return sortedOptions.sort((a, b) => (getItemValue(a, params).localeCompare(getItemValue(b, params))))
  case orderingTypes.DESCENDING:
    return sortedOptions.sort((a, b) => (getItemValue(b, params).localeCompare(getItemValue(a, params))))
  case orderingTypes.CUSTOM:
    return sortedOptions.sort((a, b) => (a?.position - b?.position))
  default:
    return options
  }
}

