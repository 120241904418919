import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {Tooltip} from 'antd'
import * as S from '../UI/UserAvatar/styles'
import {getLogoBase64} from "./utils";
import {getCompanyLogoUrl} from "../../utils/appHelper";
import {GRAVATAR_AVATAR_TYPE, INITIAL_AVATAR_TYPE, CUSTOM_PICTURE_AVATAR_TYPE} from "../EntityAvatarForm";
import md5 from "md5";

const EntityAvatar = ({alt, title, entityId, avatarType, avatarName, contactEmail}) => {

  const href = useMemo(() => `/organisations/${entityId}`, [entityId])
  const [fileBase64, setFileBase64] = useState(null)
  const [fetchAtStart, setFetchAtStart] = useState(true)

  const retrieveFileBase64 = useCallback(async () => {
    const fileBase64 = await getLogoBase64(entityId, getCompanyLogoUrl)
    setFileBase64(fileBase64)
  }, [entityId])

  useEffect(() => {

    if (fetchAtStart && avatarType === CUSTOM_PICTURE_AVATAR_TYPE) {
      retrieveFileBase64()
      setFetchAtStart(false)
    } else if (fetchAtStart){
      setFetchAtStart(false)
    }
  }, [avatarType, fetchAtStart, retrieveFileBase64])

  // Still need to fetch logo
  if (fetchAtStart) return null

  return (
    <Tooltip title={title || `Entity ${entityId}`}>
      <Link to={href} href={href}>
        <S.Gravatar>
          { (fileBase64 && avatarType === CUSTOM_PICTURE_AVATAR_TYPE) ? <S.Img alt={alt} src={`${fileBase64}`}/> : null}
          {
            avatarType === GRAVATAR_AVATAR_TYPE ? <S.Img alt={alt || contactEmail} src={`https://www.gravatar.com/avatar/${contactEmail && md5(contactEmail)}?s=1500&d=https://www.godoo.ch/en/icons/icon-512x512.png?v=d57b514fa910e3ff9d98c1ae52bfb7d7`}/> : null
          }
          { avatarType === INITIAL_AVATAR_TYPE ? <S.Img alt={alt} src={`https://eu.ui-avatars.com/api/?size=512&name=${avatarName || 'E'}`}/> : null}

        </S.Gravatar>
      </Link>
    </Tooltip>)
}

export default EntityAvatar
