import React, { useEffect, useMemo } from 'react'

import { Form } from "antd";

import { filter } from "lodash";
import { renderInputFieldFromValueType } from "../../../../utils/renderInputField";

import * as C from '../constants'
import * as U from "../utils";
import { __ } from '../../../../utils/translationUtils'

const ControllerType = (props) => {
  const Controller = renderInputFieldFromValueType(props.valueType)
  return <Controller {...props} />
}

export default (props) => {

  const {
    initialValues = {},
    fields = [],
    attributeList = [],
    formRef,
    enumerations = {},
    updateEnumerations,
  } = props

  const [form] = Form.useForm();
  const enumItems = useMemo(() => U.getEnumValuesMapItems(enumerations), [enumerations])
  const displayableFields = useMemo(() => (fields.filter((f) => !f.hidden).sort((a, b) => a.position - b.position)), [fields])

  useEffect(() => {
    const enumsToFetch = fields.reduce((prev, field) => {
      const enumId = attributeList.find((attr) => attr.id === field.attributeId).enumerationId
      if (enumId && !enumerations?.[enumId]) prev.push(enumId)
      return prev
    }, [])
    updateEnumerations?.(enumsToFetch)
  }, [fields, attributeList])

  useEffect(() => {
    if (formRef) formRef.current = form
  }, [])

  if (!filter(fields, (f) => !f.hidden).length) return (
    <div className="text-cetner">
      {__('There are no Search Fields available')}
    </div>
  )
  return (
    <Form
      initialValues={initialValues}
      form={form}
      layout={"vertical"}
    >
      {displayableFields.map((field) => {

        const fieldAttr = attributeList.find((attr) => attr.id === field.attributeId)
        return (
          <SearchField
            mechanism={field.mechanism}
            multiSelect={field.multiSelect}
            type={fieldAttr.type}
            enumItems={enumItems[field.attributeId]}
            label={__(field.propertyLabel || fieldAttr.propertyLabel)}
            dataInterface={field.defaultValue}
            name={field.attributeId}
          />
        )
      })}
    </Form>
  )
}

const defaultDiscriminator = '12'

export const SearchField = ({ name, label, mechanism, multiSelect, type, enumItems, dataInterface }) => {
  switch (mechanism) {
    case C.mechanismType.SPECIFIC_VALUE:
      const discriminator = dataInterface?.discriminator || defaultDiscriminator
      return (
        <Form.Item label={label} name={name}>
          <ControllerType
            valueType={discriminator === '12' && type}
            placeholder={__('None')}
            allowClear={discriminator === '12'}
            disabled={discriminator !== '12'}
          />
        </Form.Item>
      )
    case C.mechanismType.RANGE:
      return (
        <Form.Item label={label}>
          <Form.Item name={`${name}From`} label={`${__('From')}`}>
            <ControllerType
              valueType={type}
              placeholder={__('From')}
              allowClear
            />
          </Form.Item>
          <Form.Item name={`${name}To`} label={`${__('To')}`}>
            <ControllerType
              valueType={type}
              placeholder={__('To')}
              allowClear
            />
          </Form.Item>
        </Form.Item>
      )
    case C.mechanismType.ENUMERATION:
      return (
        <Form.Item label={label} name={name}>
          <ControllerType
            allowClear
            valueType={'ENUM_VALUE'}
            placeholder={__('None')}
            items={enumItems}
            maxTagCount={'responsive'}
            mode={multiSelect && 'multiple'}
          />
        </Form.Item>
      )
    default:
      return null
  }
}
