import styled from "styled-components";

export const ViewDetailsButton = styled.text`
  font-weight: bold;
  transition-duration: .15s;
  color: #DCDCDC;
  &:hover {
    text-decoration: underline #DCDCDC;
  }
  & + div {
    overflow-y: scroll;
    & > div {
      height: 0;
      overflow: hidden;
      color: white;
      transition-duration: .15s;
      font-size: 12px;
      opacity: 0;
      line-height: 0;
      border-left: solid 1px white;
      padding-left: 10px;
    }
  }
  &.clicked {
    & + div > div {
      margin-top: 10px;
      line-height: 1.5;
      opacity: 1;
      height: 100%;
      overflow: visible;
    }
    & + div {
      margin-bottom: 10px;
    }
  }
`

export const ViewDetailsList = styled.div`
    margin-bottom: 10px;
    & > div {
      height: 0;
      overflow: hidden;
      color: white;
      transition-duration: .15s;
      font-size: 12px;
      opacity: 0;
      line-height: 0;
      border-left: solid 1px white;
      padding-left: 10px;
    }
   {
    &.clicked > div {
      margin-top: 10px;
      line-height: 1.5;
      opacity: 1;
      height: 100%;
      overflow: visible;
    }
  }
`
