import {cases} from "../../../utils/stringUtils";
import React, {ReactNode} from "react";
import {TFunction} from "../../../utils/translationUtils";
import {Typography} from 'components'

const {Title, Text} = Typography

type ExportColumnsInputsProps = {
  data: {
    summaryDisplayAttributesItems: { value: string, label: string }[]
  }
  __: TFunction
}

export type ExportColumnsType = 'all' | 'selected' | 'custom'

export const exportColumnsInputs = ({data, __}: ExportColumnsInputsProps) => ([
  {
    name: 'exportColumns',
    type: 'radio',
    size: 'large',
    items: [
      {
        value: "all",
        label: (
          <div>
            <Title level={5}>{__('all_columns', 'capitalize_sentence')}</Title>
            <Text color={'#000'}>{__('all_columns_description', 'capitalize_sentence')}</Text>
          </div>
        )
      },
      {
        value: "selected",
        label: (
          <div>
            <Title level={5}>{__('selected_columns', 'capitalize_sentence')}</Title>
            <Text color={'#000'}>{__('selected_columns_description', 'capitalize_sentence')}</Text>
          </div>
        )
      },
      {
        value: "custom",
        label: (
          <div>
            <Title level={5}>{__('custom_selection', 'capitalize_sentence')}</Title>
            <Text color={'#000'}>{__('custom_selection_description', 'capitalize_sentence')}</Text>
          </div>
        )
      },
    ] as { value: ExportColumnsType, label: ReactNode }[],
  },
  {
    name: 'selected_columns',
    type: 'select',
    placeholder: __('select_columns', cases.CAPITALIZE_SENTENCE),
    showSearch: true,
    mode: 'multiple',
    allowClear: true,
    items: data.summaryDisplayAttributesItems,
    condition: ({watch}: { watch: (fieldName: string) => unknown }) => {
      return watch('exportColumns') as ExportColumnsType === "custom"
    }
  },
])
