import React, { useCallback, useMemo, useState } from 'react'
import { Input } from "antd";
import Glyphicon from "@strongdm/glyphicon";

import { showMessage } from '../../../../utils/appHelper';
import UserAvatar from "../../UserAvatar";

import { getVisibilityTypeTree } from "../utils";
import { IconClickable, SendIcon, UserInput, UserInputWrapper, VisibilitySelector } from "./styles";
import { visibilityType } from "../constants";
import { __ } from '../../../../utils/translationUtils'


export default (props) => {
  const { guiUser, entities, postMessage, refresh } = props
  const options = useMemo(() => getVisibilityTypeTree(entities), [entities])
  
  const [attachments, setAttachments] = useState([])
  const [visibility, setVisibility] = useState([ visibilityType.GLOBAL ])
  const [text, setText] = useState('')
  
  const sendMessage = useCallback(({ visibility, text, attachments }) => {
    
    postMessage?.({
      visibility: visibility[0],
      entityId: visibility[1] || null,
      text,
      attachmentPath: null
    }).then(() => {
      showMessage('success', 'Comments created successfully');
      setVisibility([ visibilityType.GLOBAL ])
      setText('')
      refresh?.()
    }).catch(() => {
      showMessage('error', 'Comments cannot be created');
    })
  }, [postMessage])
  
  
  return (
    <UserInputWrapper>
      <UserAvatar
        id='user-avatar'
        email={guiUser?.email}
        avatarName={`${guiUser?.firstName?.charAt(0)}${guiUser?.lastName?.charAt(0)}`}
        userId={guiUser?.id}
        avatarType={guiUser?.avatar?.avatarType || "INITIALS"}
        title={guiUser?.username} />
      <IconClickable onClick={(e) => console.log(e)}>
        <Glyphicon glyph="paperclip" />
      </IconClickable>
      <UserInput compact>
        <VisibilitySelector
          value={visibility}
          onChange={setVisibility}
          options={options} />
        <Input
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder={__('AddComment')} />
      </UserInput>
      <SendIcon disabled={text.length === 0} onClick={() => sendMessage({ visibility, text, attachments })} />
    </UserInputWrapper>
  )
}
