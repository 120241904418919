import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import RightsResourceMethod from './RightsResourceMethod';

class RightsResourceTab extends React.Component {

  static propTypes = {
    resource: PropTypes.object,
    resourceName: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    if(!this.props.resource){
      return null;
    }

    let c = 0;
    return (
      <div>
        {Object.keys(this.props.resource).map((uri)=>{
          c++;
          return (
            <Panel bsStyle="info" key={c} defaultExpanded={true}>
              <Panel.Heading>{uri}</Panel.Heading>
              <Panel.Body collapsible>
                { 
                  Object.keys(this.props.resource[uri]).map((method)=>{
                    c++;
                    return (
                      <RightsResourceMethod key={c} 
                        resource={this.props.resource} 
                        resourceName={this.props.resourceName}
                        uri={uri}
                        method={this.props.resource[uri][method]}
                        methodName={method} />
                    );
                  })
                }
              </Panel.Body>
            </Panel>
          
          );
        })}
      </div>);
  }

}

export default RightsResourceTab;