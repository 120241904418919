import React from 'react'

import CalendarsDashboard from '../components/Calendar/CalendarsDashboard'
import { __ } from '../utils/translationUtils'

const CalendarPage = () => (
  <div id="main-content">
    <h1 className="sticky">
      <div className="container">
        { __('Calendar')}
      </div>
    </h1>
    <CalendarsDashboard />
  </div>
)

export default CalendarPage
