import React, { useMemo, useRef } from "react";
import OrdersTable from "./OrdersTable";
import { RelatedContentContainer, RelatedItemsCol, Column, AnchorsContainer } from "./styles";
import { Field } from "redux-form";
import SubObject from "./SubObject";
import { appDefaults } from "../../../../../constants/globalConfiguration";
import AnchorsBar from '../../../../UI/AnchorsBar'
import { formName } from "../index";
import { __ } from '../../../../../utils/translationUtils'


const ExternalObject = ({ parentObjectId, parentObjectTypeId, attr, relation, attributes, updateObject, permissions, highlightedSubObjectId }) => (
  <Field
    key={`remote-object-property-${attr.id}`}
    name={attr.id}
    id={attr.elementId}
    title={__(attr.propertyLabel)}
    component={SubObject}
    highlighted={highlightedSubObjectId === attr.elementId}
    formName={formName}
    labelCol={"col-sm-12 col-md-12 col-lg-12"}
    inputCol={"col-sm-12 col-md-12 col-lg-12"}
    disabled={!attr.editable}
    type="Index"
    updateObject={updateObject}
    dateFormat={appDefaults.dateFormat}
    parentObjectId={parentObjectId}
    parentObjectTypeId={parentObjectTypeId}
    attr={attr}
    relation={relation}
    attributes={attributes}
    options={{
      type: "ROE",
      additionalStyle: "overviewTab",
      currentPage: attr.currentPage,
      pageSize: relation?.settings?.pageLayout?.pageSize || attr?.pageSize,
      defaultView: relation?.settings?.pageLayout?.listingLayout?.defaultSelected,
      handlePageChange: (page, pageSize) => {
        attr.currentPage = page;
        attr.pageSize = pageSize;
      },
      permissions
    }}
  />
)

const ordersTableID = 'related-element-godoo-orders-table'

const RelatedContent = ({ subObjectsData, targetData, remoteObjectDefinition, remoteObjectInstance, isCreatingObject, permissions, highlightedSubObjectId, onSubObjectHighlight, attributes, updateObject }) => {
  const scrollableRef = useRef(undefined)

  const bookmarks = useMemo(() => (
    [
      ...subObjectsData?.map(({ attr }) => ({
        targetId: attr.elementId,
        label: __(attr.propertyLabel)
      })),
      {
        label: __("godoo orders"),
        targetId: ordersTableID
      }
    ]
  ), [subObjectsData, remoteObjectDefinition, remoteObjectInstance, targetData])

  return (
    <RelatedContentContainer>
      <RelatedItemsCol ref={scrollableRef} span={20}>
        {
          subObjectsData?.map((props) =>
            <ExternalObject
              {...props}
              parentObjectId={remoteObjectInstance.id}
              parentObjectTypeId={remoteObjectDefinition?.objectTypeId}
              attributes={attributes}
              updateObject={updateObject}
              permissions={permissions}
              highlightedSubObjectId={highlightedSubObjectId}
            />
          )
        }
        {
          targetData && (
            <OrdersTable
              id={ordersTableID}
              ROId={remoteObjectDefinition.objectTypeId}
              ROEId={remoteObjectInstance?.id}
              highlightedSubObjectId={highlightedSubObjectId}
            />
          )
        }
      </RelatedItemsCol>
      <Column span={4}>
        {
          bookmarks.length > 0 && !isCreatingObject && (
            <AnchorsContainer>
              <AnchorsBar
                value={subObjectsData?.[0]?.attr.elementId}
                title={targetData?.label}
                scrollableRef={scrollableRef}
                items={bookmarks}
                onChange={onSubObjectHighlight}
              />
            </AnchorsContainer>
          )
        }
      </Column>
    </RelatedContentContainer>
  )
}

export default RelatedContent
