import { features, layout, mechanism, sortMethod, visibleFor } from "./constants";

import Table from "../../images/thumbnails/Table.svg";
import Cards from "../../images/thumbnails/Cards.svg";
import Map from "../../images/thumbnails/Map.svg";
import OrderTypeLabel from "../../images/thumbnails/OrderTypeLabel.svg";
import Deadline from "../../images/thumbnails/Deadline.svg";
import Priority from "../../images/thumbnails/Priority.svg";
import MultipleSelection from "../../images/thumbnails/MultipleSelection.svg";
import Snapshot from "../../images/thumbnails/ShowSnapshot.svg";
import { __ } from '../../utils/translationUtils'


export const getAvailableMechanisms = (attribute) => ([
  ...(attribute.specificValueMechanismAllowed ? [mechanism.SPECIFIC_VALUE] : []),
  ...(attribute.rangeMechanismAllowed ? [mechanism.RANGE] : []),
  ...(attribute.enumerationId && attribute.enumMechanismAllowed ? [mechanism.ENUMERATION] : []),
])

const getAvailableMechanismItem = (m) => {
  switch (m) {
    case mechanism.RANGE:
      return { label: __('range'), value: mechanism.RANGE }
    case mechanism.ENUMERATION:
      return { label: __('enumeration'), value: mechanism.ENUMERATION }
    case mechanism.SPECIFIC_VALUE:
    default:
      return { label: __('specific_value'), value: mechanism.SPECIFIC_VALUE }
  }
}

export const getMechanismItems = (attribute) => (getAvailableMechanisms(attribute).map((mechanism) => getAvailableMechanismItem(mechanism)))

export const getSortMethodItems = () => ([
  { label: __('ascending'), value: sortMethod.ASCENDING },
  { label: __('descending'), value: sortMethod.DESCENDING }
])

export const getLayoutItems = () => ([
  {
    label: __('table'),
    value: layout.TABLE,
    image: Table,
  },
  {
    label: __('card'),
    value: layout.CARD,
    disabled: true,
    message: __('layout_availability_info'),
    image: Cards,
  },
  {
    label: __('map'),
    value: layout.MAP,
    image: Map,
  },
])

export const getShowPhasesItems = () => ([
  {
    label: 'ongoing (initialised, assigned, started)',
    value: 'ongoing',
  },
  {
    label: 'finalised (completed, cancelled)',
    value: 'finalised',
  },
  {
    label: 'archived',
    value: 'archived',
  },
  {
    label: 'deleted',
    value: 'deleted',
  },
])

export const getInitialShowPhasesItems = (showOrderPhases = {}) => {
  const phasesConfig = Object.entries(showOrderPhases).reduce((acc, [key, value]) => {
    acc[key] = value !== false
    return acc
  }, { 'ongoing': true, 'finalised': true})

  return getShowPhasesItems().reduce((acc, currentValue) => {
    if (phasesConfig[currentValue.value]) acc.push(currentValue.value)
    return acc
  }, [])
}

export const getFeaturesItems = () => ([
  {
    label: __('order_type_label'),
    value: features.ORDER_TYPE,
    image: OrderTypeLabel,
  },
  {
    label: __('deadline'),
    value: features.DEADLINE,
    image: Deadline,
  },
  {
    label: __('priority'),
    value: features.PRIORITY,
    image: Priority,
  },
  {
    label: __('multiple_selection'),
    value: features.MULTIPLE_SELECTION,
    image: MultipleSelection,
  }
])

export const getDefaultPageSizeItems = () => ([
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
])

export const getVisibleForItems = () => ([
  { label: __('only me'), value: visibleFor.MYSELF },
  { label: __('global'), value: visibleFor.GLOBAL },
  { label: __('specific entities'), value: visibleFor.SPECIFIC_ENTITIES },
])
