import React from "react"
import {Tree} from 'antd'

const {TreeNode} = Tree

const AttributesTree = ({checkedKeys = [], attributes = [], dataSource, onItemSelect}) => {
  const generateTree = (treeNodes = [], checkedKeys = []) => {
    return treeNodes.map(({childrenTreeNode, ...props}) => (
      <TreeNode
        {...props}
        disabled={checkedKeys.includes(props.key) || !!(childrenTreeNode && childrenTreeNode.length)}
        key={props.key}
        title={props.property}
        checkable={!(childrenTreeNode && childrenTreeNode.length)}
      >
        {generateTree(childrenTreeNode, checkedKeys)}
      </TreeNode>
    ))
  }

  const isChecked = (selectedKeys, eventKey) => {
    return selectedKeys.indexOf(eventKey) !== -1
  }

  return (
    <Tree
      blockNode
      checkable
      checkStrictly
      defaultExpandedKeys={Array.isArray(dataSource) && dataSource.length === 1 ? [dataSource[0]?.key] : []}
      checkedKeys={checkedKeys}
      onCheck={(
        _,
        {
          node: {
            props: {eventKey},
          },
        }
      ) => {
        onItemSelect(eventKey, !isChecked(checkedKeys, eventKey))
      }}
      onSelect={(
        _,
        {
          node: {
            props: {eventKey},
          },
        }
      ) => {
        onItemSelect(eventKey, !isChecked(checkedKeys, eventKey))
      }}
    >
      {generateTree(dataSource, attributes)}
    </Tree>
  )
}

export default AttributesTree
