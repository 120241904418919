import React, {useMemo} from 'react'

import { Icon } from 'components/dist'

import * as S from './styles'
import {Popconfirm, Tooltip} from "antd";

export const BookmarkItem = (props) => {

  const {
    value,
    onChange,
    disabled,
    readOnly,
    trueColor = '#0a4f7a',
    falseColor = '#0a4f7a',
    disabledColor = '#aaaaaa',
    popconfirm,
    tooltip,
    iconProps,
    children
  } = props

  const color = useMemo(() => {
    if (disabled) return disabledColor
    return value ? trueColor : falseColor
  }, [value, disabled, disabledColor, falseColor, trueColor])

  return (
    <Popconfirm
      disabled={!popconfirm || disabled || readOnly}
      onConfirm={(!readOnly && (() => onChange?.(!value))) || null}
      {...popconfirm}
    >
      <Tooltip {...tooltip}>
        <S.IconWrapper
          disabled={disabled}
          readOnly={readOnly}
          onClick={(!popconfirm && !readOnly && (() => onChange?.(!value))) || null}
        >
          { children || (
            <Icon
              component={value ? 'Bookmark' : 'BookmarkBorder'}
              color={color}
              {...iconProps}
            />
          )}
        </S.IconWrapper>
      </Tooltip>
    </Popconfirm>
  )
}

export const WrappedBookmarkItem = (props) => (
  <S.Container>
    <BookmarkItem {...props} />
  </S.Container>
)

export default BookmarkItem
