import React, {useMemo} from 'react'

import {Descriptions, Typography} from "antd";
import * as S from '../styles'

import {__} from '../../../../utils/translationUtils'

const {Text} = Typography

const Details = (props) => {
  const {
    data = {},
    exceptions = [],
    noDataLabel,
  } = props

  const items = useMemo(() => (
    data && Object.keys(data).filter((item) => !exceptions.includes(item))
  ), [data, exceptions])
  if (!items?.length) return null

  return (
    <S.CustomDescriptions
      layout={'vertical'}
      column={1}
      size='small'
      bordered={true}
    >
      {items.map((item) => (
        <Descriptions.Item
          label={<Text strong>{__(item)}</Text>}
          key={item}
        >
          <div style={{width: '360px'}}>
            {data[item] ||
              <Text italic disabled>
                {noDataLabel}
              </Text>
            }
          </div>
        </Descriptions.Item>
      ))}
    </S.CustomDescriptions>
  )
}

export default (props) => {
  const {
    visible,
    onClose,
    data,
    exceptions,
    noDataLabel = 'none',
  } = props

  return (
    <S.DetailsDrawer
      title={__("Details")}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={400}
      footer={null}
      noAutoSpacing
    >
      <Details
        data={data}
        exceptions={exceptions}
        noDataLabel={noDataLabel}
      />
    </S.DetailsDrawer>
  )
}
