import React, { useCallback, useMemo, useState } from "react";

import * as S from '../providers/StyleProvider/styles'
import {Popover} from "antd";

const mouseEvents = {
  CLICK: 'click',
  HOVER: 'hover',
  FOCUS: 'focus',
  CONTEXT_MENU: 'contextMenu'
}


const isEllipsisActive = (e) => (e?.offsetWidth < e?.scrollWidth)

export default (props) => {
  const {
    trigger = 'click',
    hidden,
    text,
    children,
  } = props

  const [hasEllipsis, setHasEllipsis] = useState(false)

  const clickable = useMemo(() => {
    return trigger !== mouseEvents.HOVER && hasEllipsis
  }, [trigger, hasEllipsis])

  const checkEllipsis = useCallback((e) => {
    if (isEllipsisActive(e?.target)) {
      setHasEllipsis(true)
    }
    else {
      setHasEllipsis(false)
    }
  }, [setHasEllipsis])

  const content = children != null ? children : text

  return (
    <Popover
      content={content}
      trigger={trigger}
      overlayStyle={{maxWidth: 'min(90vw, 500px)', zIndex: '1050'}}
      {... !hidden && hasEllipsis ? {} : { visible: false }}
    >
      <S.Ellipsis onMouseEnter={checkEllipsis} clickable={clickable}>
        {content}
      </S.Ellipsis>
    </Popover>
  )
}
