import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {UseUsersDashboardsQuery} from "../../providers/QueryClientProvider/queries";
import Dashboard from "../Dashboard";
import { Select, Tooltip } from "antd";
import { SelectAsTitle } from "./styles";
import { __ } from '../../utils/translationUtils'
import {fetchDashboards} from "../../providers/ApiProvider";

const getDefaultDashboardId = (userDashboards, dashboards) => {
  const { defaultDashboardId, personalDashboards, entityDashboards } = userDashboards;

  const personalPinnedIds = personalDashboards?.pinnedDashboardIds;

  if (defaultDashboardId && dashboards.find(({ id }) => id === defaultDashboardId)) {
    return defaultDashboardId;
  }

  if (personalPinnedIds?.length > 0) {
    const sortedPersonal = sortDashboardsByName(personalPinnedIds, dashboards);
    return sortedPersonal?.length > 0 ? sortedPersonal[0].id : null;
  }

  const sortedDashboards = sortDashboardsByName(entityDashboards?.flatMap(entity => entity.pinnedDashboardIds || []) || [], dashboards);
  return sortedDashboards?.length ? sortedDashboards[0].id: null;
}

function sortDashboardsByName(pinnedIds, dashboardResponse) {
  const filteredDashboards = dashboardResponse?.filter(dashboard =>
    pinnedIds.includes(dashboard.id)
  ) || [];

  return filteredDashboards.sort((a, b) => a.name.localeCompare(b.name));
}


const Homepage = () => {
  const user = useSelector((state) => state?.user?.data)
  const [loading, setLoading] = useState(null)

  const [dashboardId, setDashboardId] = useState(null);
  const [pinnedDashboards, setPinnedDashboards] = useState(null)
  const [dashboardsDetails, setDashboardsDetails] = useState(null)

  const {data: userDashboards, isSuccess} = UseUsersDashboardsQuery(user?.id)

  useEffect(() => {
    if (isSuccess && userDashboards) {
      const allPinned = [...(new Set([
        ...userDashboards?.personalDashboards?.pinnedDashboardIds || [],
        ...userDashboards?.entityDashboards?.flatMap(entity => entity.pinnedDashboardIds || []) || []
      ]))]

      if (allPinned?.length) {
        setLoading(true)
        fetchDashboards({ pageSize: 50, filter: { name: 'id', value: allPinned.join() } })
          .then((res) => res.data)
          .then((dashboards) => {
            const dashboardsMap = dashboards?.reduce((prev, dashboard) => {
              return { ...prev, [dashboard.id]: dashboard }
            }, {})

            const onlyVisiblePinned = allPinned.filter((id) => dashboardsMap[id])
            setPinnedDashboards(onlyVisiblePinned)
            setDashboardsDetails(dashboardsMap)
            setDashboardId(getDefaultDashboardId(userDashboards, dashboards))
          })
      }
    }
  }, [isSuccess, userDashboards]);

  const PinnedDashboardHeader = () => {
    if (!pinnedDashboards || !dashboardId || !dashboardsDetails) return null
    return (
      <h1 className="sticky">
        <div className="container">
          {__('Dashboard')} -
          {Object.keys(dashboardsDetails).length === 1 ? <> {dashboardsDetails[dashboardId]?.name} </> :
            <Tooltip title={__("Select a Dashboard")}>
              <SelectAsTitle
                value={dashboardId}
                onChange={(e) => setDashboardId(e)}
                dropdownMatchSelectWidth={false}
                bordered={false}
              >
                {pinnedDashboards.map((dashboard) => {
                  return (
                    <Select.Option value={dashboard} key={dashboard}>
                      {dashboardsDetails[dashboard]?.name}
                    </Select.Option>
                  )
                })}
              </SelectAsTitle>
            </Tooltip>
          }
        </div>
      </h1>
    )
  }

  return (
    <div id="main-content">
      <PinnedDashboardHeader />
      {
        dashboardId &&
        <Dashboard
          match={{ params: { id: dashboardId } }}
          mode="view"
          showHeader={false}
          showActions={false}
        />
      }
    </div>

  )
}

export default Homepage
