import {useQuery} from "react-query";
import config from "../config";
import {fetchEntities, FetchEntitiesProps} from "../../ApiProvider";

export const UseEntitiesQuery = ({ type, pageSize }: FetchEntitiesProps) => useQuery<Entity[]>({
  queryKey: ["fetchEntities", type],
  queryFn: async () => fetchEntities({ pageSize, type }),
  ...config
})

