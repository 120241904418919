import { mapConfigurationPayload } from "../../ConfigurationConfiguratorBeta/utils";
import * as configConfiguratorConstants from "../../ConfigurationConfiguratorBeta/constants";
import * as C from '../constants'
import { mapBackendItemsToFrontendPayload } from "../../ConfigurationConfiguratorBeta/utils/mapConfigurationPayload";
import { visibleFor } from "../../../components/ConfigurationConfiguratorBeta/constants";
import { availableListingLayouts } from "../constants";

export const mapGridPositionFromFrontend = (cellProperties) => {
  if (!cellProperties) return null
  return {
    x: cellProperties.x,
    y: cellProperties.y,
    h: cellProperties.h,
    w: cellProperties.w
  }
}

export const mapViewPropertiesFromFrontend = (viewProperties) => {
  return {
    name: viewProperties.viewName,
    type: viewProperties.visibleFor,
    entityIds: viewProperties.visibleFor === visibleFor.SPECIFIC_ENTITIES ? viewProperties.selectEntities.map((entity) => ({ id: entity })) : [],
    pageSize: viewProperties.defaultPageSize,
    defaultConfiguration: viewProperties.defaultConfiguration,
  }
}

export const mapChooseFieldsFromFrontend = (attributesConfig) => {
  return Object.keys(attributesConfig).map((attribute) => ({
    position: attributesConfig[attribute].index,
    attributeId: attribute,
    defaultControllerType: attributesConfig[attribute].defaultControllerType,
    controllerType: attributesConfig[attribute].controllerType,
    propertyLabel: attributesConfig[attribute].customTitle,
    summary: attributesConfig[attribute].summary,
    editable: attributesConfig[attribute].editable,
  }))
}

export const mapFrontendPayloadToBackend = (configurations, userId, system, object, oldPayload) => {
  const relatedObjects = Object.entries(configurations[C.steps.SELECT_RELATED_OBJECTS] || {})

  const result = {
    ...(oldPayload || {}),
    modifierId: userId,
    creatorId: configurations.additionalInfo?.creatorId || null,
    id: configurations.additionalInfo?.id || null,
    systemId: parseInt(system),
    objectTypeId: object,
    displayAttributes: mapConfigurationPayload.FrontendItemsToBackend(configurations[C.steps.LISTING_OUTPUT_FIELDS], configConfiguratorConstants.steps.OUTPUT_FIELDS),
    searchAttributes: mapConfigurationPayload.FrontendItemsToBackend(configurations[C.steps.LISTING_FILTERS], configConfiguratorConstants.steps.FILTERS),
    sortAttributes: mapConfigurationPayload.FrontendItemsToBackend(configurations[C.steps.LISTING_SORTING_PARAMETERS], configConfiguratorConstants.steps.SORTING_PARAMETERS),
    ...mapViewPropertiesFromFrontend(configurations[C.steps.LISTING_VIEW_PROPERTIES]),
    content: {
      ...(oldPayload?.content || {}),
      displayAttributes: mapChooseFieldsFromFrontend(configurations[C.steps.CONTENT_CHOOSE_FIELDS]),
      relations: !relatedObjects.length ? undefined : relatedObjects.map(([attributeId, { index, pageSize, listingLayout }]) => ({
        attributeId,
        position: index,
        settings: {
          pageLayout: {
            listingLayout: {
              defaultSelected: listingLayout,
              available: Object.values(availableListingLayouts)
            },
            pageSize
          },
        }
      })),
    }
  }
  result.content.displayAttributes.forEach((contentAttribute) => {
    const attributeGridPosition = configurations[C.steps.CONTENT_ORGANISE_FIELDS][contentAttribute.attributeId]
    if (attributeGridPosition) {
      contentAttribute.gridPosition = mapGridPositionFromFrontend(attributeGridPosition)
    }
  })
  return result
}

export const mapViewPropertiesFromBackend = (viewProperties) => {
  return {
    viewName: viewProperties.name,
    visibleFor: viewProperties.type,
    selectEntities: viewProperties.entityIds?.map((entity) => (entity.id)),
    defaultPageSize: viewProperties.pageSize,
    defaultConfiguration: viewProperties.defaultConfiguration
  }
}

export const mapDeprecatedGridPositionFromBackend = (attributes) => (
  attributes.reduce((prev, attribute, index) => {
    prev[attribute.attributeId] = {
      i: attribute.attributeId,
      w: C.defaultCellDimension.w,
      h: 1,
      x: (index % (C.gridConfiguration.cols / C.defaultCellDimension.w)) * C.defaultCellDimension.w,
      y: parseInt(index / (C.gridConfiguration.cols / C.defaultCellDimension.w))
    }
    return prev
  }, {})
)

export const mapChooseFieldsFromBackend = (attributes, attributesMap) => {
  return attributes?.reduce((prev, item) => {
    return {
      ...prev, [item.attributeId]: {
        index: item.position,
        controllerType: item.controllerType,
        defaultControllerType: item.defaultControllerType,
        defaultCustomTitle: attributesMap[item.attributeId]?.propertyLabel,
        customTitle: item.propertyLabel,
        summary: item.summary,
        editable: item.editable,
      }
    }
  }, {})
}

export const mapGridPositionFromBackend = (attributes) => {
  return attributes.reduce((prev, attribute) => {
    const cellProperties = attribute.gridPosition
    if (cellProperties) {
      prev[attribute.attributeId] = {
        i: attribute.attributeId,
        w: parseInt(cellProperties.w),
        h: parseInt(cellProperties.h),
        x: parseInt(cellProperties.x),
        y: parseInt(cellProperties.y)
      }
    }
    return prev
  }, {})
}

export const mapBackendPayloadToFrontend = (payload, attributesMap) => {
  return {
    [C.steps.LISTING_OUTPUT_FIELDS]: mapConfigurationPayload.BackendItemsToFrontend(payload.displayAttributes, configConfiguratorConstants.steps.OUTPUT_FIELDS, attributesMap),
    [C.steps.LISTING_FILTERS]: mapConfigurationPayload.BackendItemsToFrontend(payload.searchAttributes, configConfiguratorConstants.steps.FILTERS, attributesMap),
    [C.steps.LISTING_SORTING_PARAMETERS]: mapConfigurationPayload.BackendItemsToFrontend(payload.sortAttributes, configConfiguratorConstants.steps.SORTING_PARAMETERS, attributesMap),
    [C.steps.LISTING_VIEW_PROPERTIES]: mapViewPropertiesFromBackend(payload),
    [C.steps.CONTENT_CHOOSE_FIELDS]: mapChooseFieldsFromBackend(payload.content?.displayAttributes || payload.displayAttributes?.filter(({ attributeId }) => attributesMap[attributeId].type !== "IDS"), attributesMap) || [],
    [C.steps.CONTENT_ORGANISE_FIELDS]: !payload.content?.displayAttributes?.some((item) => item.gridPosition) ?
      mapDeprecatedGridPositionFromBackend(payload.content?.displayAttributes || payload.displayAttributes) :
      mapGridPositionFromBackend(payload.content.displayAttributes),
    [C.steps.SELECT_RELATED_OBJECTS]: payload.content?.relations?.reduce((acc, item) => {
      acc[item.attributeId] = {
        index: item.position,
        pageSize: item.settings.pageLayout.pageSize,
        listingLayout: item?.settings?.pageLayout?.listingLayout?.defaultSelected || availableListingLayouts.TABLE
      }
      return acc
    }, {}),
    additionalInfo: {
      creatorId: payload.creatorId,
      id: payload.id
    }
  }
}
