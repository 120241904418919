import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import RightsTemplateForm from '../components/RightsTemplateForm';
//import * as config from '../constants/globalConfiguration';
import objectAssign from 'object-assign';
import { updateTemplate, createTemplate, deleteTemplate , restoreTemplate } from '../providers/ReduxProvider/actions/templateActions';
import { formValueSelector } from 'redux-form';
import { api } from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'
const loadingBar = require('nprogress');
//import _ from 'lodash';

class TemplatePage extends React.Component {

  static propTypes = {
    templateId: PropTypes.string,
    mode: PropTypes.string,
    match: PropTypes.object,
    modelValues: PropTypes.object,
    createTemplate: PropTypes.func,
    updateTemplate: PropTypes.func,
    restoreTemplate: PropTypes.func,
    deleteTemplate: PropTypes.func,
  };

  constructor(props){
    super(props);
    this.state = {
      objectId: this.props.match.params.shortName,
      mode: this.props.mode,
      template: null,
      isLoading: false
    };
  }

  componentDidMount() {
    if(this.props.mode !== 'create'){
      this.getData();
      return;
    }
  }

  getData(){
    this.setState({isLoading: true});
    loadingBar.start();
    return api.get('rights/templates/'+this.state.objectId)
    .then(
      response => {
        this.setState({template: response.data, isLoading: false});
        loadingBar.done();
      },
      error => {
        console.log(error);
        this.setState({isLoading: false});
        loadingBar.done();
      }
    );
  }

  getAllEntities(){
    this.setState({isLoading: true});
    return api.get('entities')
    .then(
      response => {
        this.setState({entities: response.data});
      },
      error => {
        console.log(error);
      }
    );
  }

  handleSubmit(){
    if(this.props.mode === 'edit')
      this.props.updateTemplate({...this.props.modelValues});
    else if(this.props.mode === 'create')
      this.props.createTemplate({...this.props.modelValues});
  }

  render() {
    const headerClass = (this.state.mode)?this.state.mode:'';

    return (

      <div id="main-content">
    
        <h1 className={'sticky '+ headerClass}>
          <div className="container">
            {
              (this.state.mode === 'create')?
                __("Create Template"):__("Edit Template")
            }             
          </div>
        </h1>

        <div className="container">
          
          <br/>
          {(this.state.template ||  this.state.mode === 'create')?
          <RightsTemplateForm 
            mode={this.state.mode} 
            templateId={(this.state.mode === 'create')?null:this.props.match.params.templateId} 
            deleteTemplate={this.props.deleteTemplate} 
            getData={this.getData.bind(this)} 
            initialValues={(this.state.mode === 'create')?
              {
              }
              :objectAssign({}, 
              {
                shortName: this.state.template.shortName || null,
                name: this.state.template.name,
              })} 
            model={this.state.template} 
            onSubmit={this.handleSubmit.bind(this)} 
            entities={this.state.entities} />

            :<span>{__('loading')}</span>

          }
        </div>
        
    </div>

    );
  }
}
const selector = formValueSelector('templateForm');
export default connect(
  state => {
    const values = selector(
      state, 
      'shortName', 
      'name',
    );
    return {
      modelValues: {...values}
    };

  },
  { updateTemplate, createTemplate, deleteTemplate , restoreTemplate }
)(TemplatePage);
