import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { hashHistory } from '../providers/HistoryProvider';
import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector, change } from 'redux-form';
import objectAssign from 'object-assign';
import moment from 'moment';
import  { Table, Button as AntButton, Modal as AntModal } from 'antd';
import { Icon } from '@ant-design/compatible';
import {
  addAsterisks,
  showMessage,
  getInitialPageSize,
  searchToObject, checkUserRights,
} from '../utils/appHelper';
import * as config from '../constants/globalConfiguration';
import { deleteOrderGroup } from '../providers/ReduxProvider/actions/orderGroupActions';
import { api } from '../providers/ApiProvider'
import { stringify } from 'query-string';

import { RedoOutlined  } from '@ant-design/icons';
import styled from "styled-components"
import { __ } from '../utils/translationUtils'
import {ScrollableListView} from "../providers/StyleProvider/styles";

const loadingBar = require('nprogress');
const BasicSearchForm = require('../components/BasicSearchForm').default;



class FlowsPage extends React.Component {

  initialState = {
    templates: [],
    flowVersionShowModal:{
      visible: false,
      flow: null,
      versionsData: [],
      loading: false,
      sort: '-id.flowVersion',
      pagination: {
        pageNumber: 1,
        pageSize: 25,
        totalCount: 0
      }
    },
    data: [],
    isLoading: false,
    pagination: {
      'pageNumber': 1,
      'pageSize': getInitialPageSize('flowsPage'),
      'totalCount': 0
    },
    sort: 'title'
  };

  async handleRefreshFlow() {
    try {
      await api.post('/system/discoverNewFlows')
      showMessage('success', 'Flows are now updated')
    } catch (e) {
      showMessage('error', 'Flows cannot be updated')
    }
  }

  constructor(props){
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    // initial results
    this.setQueryToState(this.updateHash.bind(this));
  }

  setQueryToState(cb){
    let sq = searchToObject(this.props.location.search);
    this.props.change('basicSearchForm', 'q', sq.q);
    this.setState({
      sort: sq.sort || this.state.sort,
      pagination: objectAssign( this.state.pagination,
        {
          pageNumber: parseInt(sq.pageNumber) || this.state.pagination.pageNumber,
          pageSize: parseInt(sq.pageSize) || this.state.pagination.pageSize,
        }
      )
    }, ()=>{
      if(typeof cb !== 'undefined'){
        cb();
      }
    });
  }

  getParams(){
    let params = {...searchToObject(this.props.location.search)};
    return objectAssign({}, {
      sort: this.state.sort || params.sort,
      pageNumber: (parseInt(this.state.pagination.pageNumber) > -1)?parseInt(this.state.pagination.pageNumber):params.pageNumber,
      pageSize: this.state.pagination.pageSize || params.pageSize,
      q: this.props.q ? addAsterisks(this.props.q) : undefined,
    });
  }

  updateHash(){
    // update url params
    hashHistory.push({
      ...this.props.location,
      search: stringify({
        pageNumber: this.state.pagination.pageNumber,
        pageSize: this.state.pagination.pageSize,
        sort: this.state.sort,
        q: this.state.q,
      })
    }, this.getData());
  }

  search(){
    this.setState({
      q: this.props.q,
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: this.state.pagination.pageSize,
          pageNumber: 1
        }
      )
    }, ()=>{
      this.updateHash();
    });
  }

  getData = () => {
    this.setState({isLoading: true});
    loadingBar.start();
    let params = {...this.getParams.bind(this)(), pageNumber: this.getParams().pageNumber};

    return api.get('/publishedFlows/onlyLastVersion', {
      params: params
    })
    .then(
      response => {
        this.setState({
          data: response.data,
          isLoading: false,
          pagination: objectAssign(
            this.state.pagination, {
              totalCount: parseInt(response.headers['x-total-count']) ,
              pageSize: parseInt(response.headers['x-page-size']),
              pageNumber: parseInt(response.headers['x-page']),
            }
          )
        },()=>{
          loadingBar.done();
        });
      },
      error => {
        showMessage('error', error);
        this.setState({
          isLoading: false,
        }, ()=>{
          loadingBar.done();
        });
      }
    );
  }


  showTotal = total => () =>  {
    return `Total ${total} items`;
  }


  handleTableChange(pagination, filters, sorter){
    if (sorter.columnKey){
      this.setState({
        sort: ((sorter.order === 'ascend')?'':'-')+sorter.columnKey,
        pagination:{
          pageNumber: pagination.current,
          pageSize: pagination.pageSize
        }
      }, () => this.updateHash());
    } else {
      this.setState({
        pagination:{
          pageNumber: pagination.current,
          pageSize: pagination.pageSize
        }
      }, () => this.updateHash());
    }

  }

  handleFlowVersionsTableChange(pagination, filters, sorter){
    if (sorter.columnKey){
      this.setState({
        flowVersionShowModal: {
          ...this.state.flowVersionShowModal,
          sort: ((sorter.order === 'ascend')?'':'-')+sorter.columnKey,
          loading: true,
          pagination:{
            pageNumber: pagination.current,
            pageSize: pagination.pageSize
          }
        }
      }, () => this.fetchFlowVersions());
    } else {
      this.setState({
        flowVersionShowModal: {
          ...this.state.flowVersionShowModal,
          loading: true,
          pagination:{
            pageNumber: pagination.current,
            pageSize: pagination.pageSize
          }
        }
      }, () => this.fetchFlowVersions());
    }
  }

  getLastVersionTableColumnsAndDataSource(){

    const columnsLastFlow = [
      {
        title: __('Flow Name'),
        dataIndex: 'flowName',
        key: 'title',
        sorter: true,
        render: flow => <AntButton onClick={() => this.handleOpenModalFlowVersionState(flow)} type="link" className={"data_test_flowName"}>{flow.title}</AntButton>
      },
      {
        title: __('Flow Version'),
        dataIndex: 'flowVersion',
        key: 'id.flowVersion',
        className: 'data_test_version'
      },
      {
        title: __('creationDateTime'),
        dataIndex: 'creationDatetime',
        key: 'creationDatetime',
        sorter: true,
        render: text => moment(text).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat)
      },
      {
        title: '',
        key: 'flowId',
        dataIndex: 'flowId',
        className: 'object_id data_test_flowId',
      }
    ];

    const dataSourceLastFlow = [];

    if (this.state.data){
      dataSourceLastFlow.push(...this.state.data.map( lastFlow => {
        return ({
          key: `${lastFlow.flowId}_${lastFlow.flowVersion}`,
          flowId: lastFlow.flowId,
          flowName: lastFlow,
          flowVersion: lastFlow.flowVersion,
          creationDatetime: lastFlow.creationDatetime,
        });
      }));
    }

    return {columnsLastFlow, dataSourceLastFlow};

  }

  getTableColumnsAndDataSourceByFlowIdSelected() {

    const columnsFlow = [
      {
        title: __('Flow Name'),
        dataIndex: 'flowName',
        key: 'title',
        sorter: true,
        'cy-data-test': 'flow_title'
      },
      {
        title: __('Flow Version'),
        dataIndex: 'flowVersion',
        key: 'id.flowVersion',
        sorter: true,
        defaultSortOrder: 'descend'
      },
      {
        title: __('creationDateTime'),
        dataIndex: 'creationDatetime',
        key: 'creationDatetime',
        sorter: true,
        render: text => moment(text).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat)
      },
      {
        title: '',
        key: 'flowId',
        dataIndex: 'flowId',
        className: 'object_id',
        'cy-data-test': 'flow_id'
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: ([ flowId, flowVersion ]) => (
          <div className={'flow_overview_tab_link'}>
            <Link
              to={`/flows/${flowId}/${flowVersion}/overviewTabs`}
            >
              <Icon type="form" /> {' '}
              {__('Edit Overview Tabs')} {' '}
            </Link>
            {' '}
            <Link
              to={`/flows/${flowId}/${flowVersion}/processOverviewObjects`}
            >
              <Icon type="form" /> {' '}
              {__('Overview Object visibility')} {' '}
            </Link>
          </div>
        )
      }
    ];

    const dataSourceFlow = [];

    if ( this.state.flowVersionShowModal && this.state.flowVersionShowModal.versionsData &&
      this.state.flowVersionShowModal.versionsData.length ){
      dataSourceFlow.push(...this.state.flowVersionShowModal.versionsData.map( flow => {
        return ({
          key: `${flow.flowId}_${flow.flowVersion}`,
          flowId: flow.flowId,
          flowName: flow.title,
          flowVersion: flow.flowVersion,
          creationDatetime: flow.creationDatetime,
          actions: [flow.flowId, flow.flowVersion]
        });
      }));
    }

    return {columnsFlow, dataSourceFlow};

  }


  handleOpenModalFlowVersionState(flow) {
    this.setState({
      flowVersionShowModal: {
        ...this.state.flowVersionShowModal,
        visible: true,
        flow: flow,
        loading: true
      }
    }, () => this.fetchFlowVersions());
  }

  handleCloseModalShowFlowVersions(){
    this.setState({
      flowVersionShowModal: {
        ...this.state.flowVersionShowModal,
        visible: false,
        flow: null,
        sort:'-id.flowVersion',
        versionsData: [],
        loading: false
      }
    });
  }

  fetchFlowVersions() {
    const showVersionsModal = {...this.state.flowVersionShowModal};
    const params = {
      sort: showVersionsModal.sort,
      pageNumber: parseInt(showVersionsModal.pagination.pageNumber),
      pageSize: showVersionsModal.pagination.pageSize,
    }

    if (!showVersionsModal.flow.flowId){
      this.setState({flowVersionShowModal: {...showVersionsModal, loading: false}});
    }

    return api.get(`/publishedFlows/${showVersionsModal.flow.flowId}`, {
      params: params
    })
      .then( res => {
        this.setState({
          flowVersionShowModal: {
            ...showVersionsModal,
            versionsData: res.data,
            loading: false,
            pagination: {
              ...showVersionsModal.pagination,
              totalCount: parseInt(res.headers['x-total-count'] || showVersionsModal.pagination.totalCount),
              pageSize: parseInt(res.headers['x-page-size'] || showVersionsModal.pagination.pageSize),
              pageNumber: parseInt(res.headers['x-page'] || showVersionsModal.pagination.pageNumber),
            }
          }
        })
      })
      .catch( error => {
        showMessage('error', error);
        this.setState({flowVersionShowModal:
            {...showVersionsModal,loading: false}});
      })

  }

  render() {

    const {columnsLastFlow, dataSourceLastFlow} = this.getLastVersionTableColumnsAndDataSource();
    const {columnsFlow, dataSourceFlow } = this.getTableColumnsAndDataSourceByFlowIdSelected();
    const { rightMappings } = global.constants
    const showVersionsModal = {...this.state.flowVersionShowModal};

    return (
      <ScrollableListView id="main-content">

        <h1 className="sticky edit">
          <div className="container">
            {__('Manage Flows')}
          </div>
        </h1>

        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-lg-offset-1 col-sm-12">

              <div className="col-xs-12 col-sm-6 col-lg-4">
                <BasicSearchForm
                  isLoading={this.state.isLoading}
                  itemType={'Flows'}
                  initialValues={{
                    q: searchToObject(this.props.location.search).q,
                  }}
                  onSubmit={this.search.bind(this)}
                  />
              </div>
              {this.props.user && checkUserRights(this.props.user.rights, rightMappings.CAN_REFRESH_FLOWS) ?
                <div className="col-xs-12 col-sm-6 col-lg-8">
                  <RefreshFlowButton type="primary" onClick={this.handleRefreshFlow.bind(this)}><RedoOutlined /></RefreshFlowButton>
                </div> : null}
            </div>
          </div>

          <Table
            columns={columnsLastFlow}
            dataSource={dataSourceLastFlow}
            onChange={this.handleTableChange.bind(this)}
            loading={this.state.isLoading}
            pagination={{
              showSizeChanger: true,
              showTotal: this.showTotal(this.state.pagination['totalCount']).bind(this),
              total: this.state.pagination.totalCount,
              pageSizeOptions: ['10', '25', '50', '100'],
              defaultPageSize: 25
            }}

          />

          <AntModal
            title={showVersionsModal.flow && showVersionsModal.flow.title}
            footer={[]}
            mask
            visible={showVersionsModal.visible}
            onCancel={this.handleCloseModalShowFlowVersions.bind(this)}
            afterClose={document.body.removeAttribute('style')} //for bug described here: https://github.com/ant-design/ant-design/issues/21539
            width={1200}
          >
            <Table
              columns={columnsFlow}
              dataSource={dataSourceFlow}
              loading={showVersionsModal.loading}
              onChange={this.handleFlowVersionsTableChange.bind(this)}
              pagination={{
                showSizeChanger: true,
                showTotal: this.showTotal(showVersionsModal.pagination && showVersionsModal.pagination.totalCount || 0).bind(this),
                total: showVersionsModal.pagination && showVersionsModal.pagination.totalCount || 0,
                pageSizeOptions: ['10', '25', '50', '100'],
                defaultPageSize: showVersionsModal.pagination && showVersionsModal.pagination.pageSize || 1
              }}
              />
          </AntModal>

        </div>

    </ScrollableListView>
    );
  }

}

FlowsPage.propTypes = {
  deleteOrderGroup: PropTypes.func,
  change: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object,
  q: PropTypes.string,
  orderGroup: PropTypes.object,
};

const selector = formValueSelector('basicSearchForm');
export default connect(
  state => {
    return {
      user: state.user.data,
      q: selector(state, 'q'),
    };
  },
  { deleteOrderGroup, change }
)(FlowsPage);


export const RefreshFlowButton = styled(AntButton)`
  background-color: rgb(0, 141, 176);
  border-color: rgb(0, 141, 176);
  border-radius: 3px;
  
  :hover {
   background-color : #086391;
  }
  
`
