import React, { useMemo, useState } from 'react'
import { MessageUserInfo, MessageText } from "./MessageViewer";
import { MessageContent } from "../styles";
import _ from "lodash";
import { visibilityType } from "../constants";

export default ({ message, entitySelected, guiUser, entities }) => {
  
  const [editMode, setEditMode] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  
  const visibility = useMemo(() => message.visibility || visibilityType.GLOBAL, [message])
  const entityId = useMemo(() => message?.entity?.id || '', [message])
  const text = useMemo(() => message.text || '', [message])
  
  const isCreator = useMemo(() => guiUser.id === message.creator.id, [guiUser.id, message.creator.id])
  const creator = useMemo(() => isCreator ? guiUser : message.creator, [isCreator])
  
  const entity = _.find(entities, entity => { return entity.id === entitySelected})
  
  const [attachmentPath, setAttachmentPath] = useState('')
  
  return (
    <MessageContent isCreator={isCreator}>
      <div className={'message'}>
        <MessageUserInfo
          user={creator}
          dateToShow={message.creationDatetime}
          typeSelected={visibility}
          entity={entity}
        />
        <MessageText
          text={text}
          edited={message.edited}
        />
      </div>
    </MessageContent>
  )
}

// TODO: attachments viewer. Multiple attachments?
// TODO: history
// TODO: user options in overlay
