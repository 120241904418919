import React from 'react';
import PropTypes from 'prop-types';
import Glyphicon from '@strongdm/glyphicon'
import Label from "../Label";
import { __ } from '../../utils/translationUtils'

class UrlLinkControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { controlData } = this.props;
    const req = controlData.required?'*':'';

    return (
      <div className="form-group">
        <Label
          className="col-sm-3 col-md-3 col-lg-2 control-label"
          label={__(controlData.title) + req}
          description={controlData.description && __(controlData.description)}
        />
        <div className="col-sm-8 control-label pull-left">
          <Glyphicon glyph="download" />  <a href={controlData.source || controlData.defaultValue}>{__('Download')}</a>
        </div>
      </div>
    );
  }
}

export default UrlLinkControl;
