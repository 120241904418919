import React, {useEffect, useMemo} from "react";
import { FlexColumn } from 'components';
import * as S from './styles';
import { __ } from '../../../utils/translationUtils';

type CheckboxListProps = {
  items: { value: string; label: string }[]
  value?: string[];
  onChange: (selectedItems: string[]) => void;
}

const CheckboxList: React.FC<CheckboxListProps> = (props) => {
  const {
    items,
    value = [],
    onChange,
  } = props;

  const isIndeterminate = useMemo(() => {
    return !!value.length && value.length < items.length;
  }, [value, items]);

  const isChecked = useMemo(() => {
    return value.length === items.length;
  }, [value, items]);

  return (
    <FlexColumn
      width="100%"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 6px #d0d0d0",
      }}>
      <S.CustomCheckbox
        indeterminate={isIndeterminate}
        onChange={(e) => onChange(e.target.checked ? items.map(item => item.value) : [])}
        checked={isChecked}
      >
        {__('Check all')}
      </S.CustomCheckbox>
      <S.CustomCheckboxGroup
        options={items}
        value={value}
        onChange={(items) => onChange(items as string[])}
      />
    </FlexColumn>
  );
}

export default CheckboxList;
