import { combineReducers } from 'redux';
import user from './userReducer';
import entities from './EntityReducer';
import enumValues from './EnumValuesReducer';
import errors from './errorReducer';
import ImportingReducer from './ImportingReducer';
import uiReducer from './uiReducer';
import { reducer as reduxFormReducer } from 'redux-form';
const rootReducer = combineReducers({
  user,
  entities,
  errors,
  enumValues,
  ui: uiReducer,
  form: reduxFormReducer,
  import: ImportingReducer,
});

export default rootReducer;
