import styled from "styled-components/macro";

export const SignatureWrapper = styled.div<{ width: string }>`
  position: relative;
  display: flex;
  max-width: 95%;
  width: ${(props) => props.width || '480px'};
  
  &:hover {
    .edit-signature-overlay {
      opacity: 1;
      transition: opacity 0.3s ease, visibility 0.2s ease;
    }
  }
`

export const EditSignatureOverlay = styled.div`
  position: absolute;
  display: flex;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.2s ease;
  
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #004a7999;
  border-radius: 8px
`
