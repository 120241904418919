import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as config from '../../constants/globalConfiguration';
import { __ } from '../../utils/translationUtils'

class SimpleLabelControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object,
    value: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    let {controlData, value} = this.props;
    value = (!value) ? (controlData.source) ? controlData.source : controlData.defaultValue : value;
    // Check if date

    let transKey
    if (controlData.valueType && controlData.valueType === 'datetime') {
      value = moment(value).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat);
    } else if (controlData.valueType && (controlData.valueType === 'Yesno' || controlData.valueType === 'YESNO')) {
      value = value?.toLowerCase() === 'true' ? __('val_yes') : __('val_no');
    } else if (Array.isArray(controlData?.controllerOptions?.items) && (transKey = controlData?.controllerOptions?.items.find(i => i.value === value)?.translationKey) != null){
      value = __(transKey)
    }

    if (this.props.controlData?.attributes?.extendedProperties?.valueToBeTranslated) {
      value = __(value);
    }

    const hasLabel = !!controlData.title.length;
    return (
      <div className="form-group label-control">
        {hasLabel &&
          <label className="col-sm-3 col-md-3 col-lg-2 control-label">{__(controlData.title)}</label>
        }
        <div className={hasLabel ? 'col-sm-9 col-md-6 col-lg-6' : 'col-sm-12 gutter-top'}>
          <p className="form-control-static">{value}</p>
        </div>
      </div>
    );
  }

}

export default SimpleLabelControl;
