import React from "react";

import {Dropdown, Menu} from "antd";
import {SettingOutlined} from "@ant-design/icons";

const MenuOption = (option) => {
  const {
    icon,
    label,
    danger = false,
    onClick,
  } = option
  return (
    <Menu.Item
      icon={icon}
      danger={danger}
      onClick={(e) => onClick?.(e)}
    >
      {label}
    </Menu.Item>
  )
}

export const DropdownMenu = (props) => {
  const {
    disabled,
    children,
    options = [],
    title,
    trigger = 'click',
    placement = 'bottomLeft'
  } = props

  if (!options.length) return null
  if (disabled) return children
  return (
    <Dropdown
      title={title}
      trigger={trigger}
      placement={placement}
      overlay={
        <Menu>
          {options.map((option) => (
            <MenuOption
              {...option}
              key={`menu-option-${option.value}`}
            />
          ))}
        </Menu>
      }
    >
      { children || <SettingOutlined />}
    </Dropdown>
  )
}

export default DropdownMenu
