import React from 'react'

import {Steps} from "antd";

import * as S from "../../pages/ConfigurationConfiguratorBeta/styles";

const { Step } = Steps;

export default (props) => {

  const {
    title,
    steps,
    activeStep,
    onStepChange,
    extra,
    ghostExtra=false,
  } = props || {}

  return (
    <S.HeaderContainer>
      { title &&
        <S.HeaderTitle>
          {title}
        </S.HeaderTitle>
      }
      { steps &&
        <S.HeaderSteps type="navigation" current={activeStep} onChange={onStepChange}>
          { steps.map((step) => (
            <Step
              icon={step.icon}
              title={step.title}
              key={step.value}
            />
          ))}
        </S.HeaderSteps>
      }
      { extra &&
        <S.HeaderExtra ghost={ghostExtra}>
          {extra}
        </S.HeaderExtra>
      }
    </S.HeaderContainer>
  )
}
