import React from 'react'
import Message from "./Message";
import { Chatroom, MessageList, NoComments } from "./styles";
import { __ } from '../../../utils/translationUtils'

export default (props) => {
  const { panelTitle, messages } = props;
  if (!messages) {
    return (
      <Chatroom id={'comment-list'}>
        {__(`No ${panelTitle}`)}
      </Chatroom>
    )
  }
  
  if (messages.length === 0)
    return (
      <Chatroom>
        <NoComments id={'comment-list'}>
          {__("There are no comments yet.")}
        </NoComments>
      </Chatroom>
    )
  
  return (
    <Chatroom id={'comment-list'}>
      <MessageList>
        {
          messages.map(message =>
            <Message
              message={message}
              key={message.id}
              {...props}
            />
          )
        }
      </MessageList>
    </Chatroom>
  )
}
