import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {find} from 'lodash';
import {bindActionCreators} from 'redux';
import {renderField} from '../utils/fieldValidator';
import StepAttributeNodeEdit from '../components/StepAttributeNodeEdit';
import {getEnumValues} from '../providers/ReduxProvider/actions/reportActions';
import {appDefaults} from "../constants/globalConfiguration";
import * as S from '../providers/StyleProvider/styles'
import EllipsisText from "./Ellipsis";
import { __ } from '../utils/translationUtils'

class StepObjectEditForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    getEnumValues: PropTypes.func,
    system: PropTypes.string,
    configuration: PropTypes.object,
    filterByDisplayAttributes: PropTypes.bool,
    initialValues: PropTypes.object,
    dependencyId: PropTypes.string,
    enumValues: PropTypes.object.isRequired,
    objectConfiguration: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    attributes: PropTypes.array,
    values: PropTypes.array
  };

  static defaultProps = {
    filterByDisplayAttributes: false,
  }

  constructor(props){
    super(props);
    this.passwordInput = null;
    this.selectedCompany = null;
    this.stepFieldValidator = this.stepFieldValidator.bind(this)
  }

  stepFieldValidator(value,a,b,attributeId){
    const{attributes} = this.props;
    const attribute = attributes.find(a=>a.id===attributeId);
    if( typeof attribute.regex !== 'undefined'
      && typeof attribute.regex.js !== 'undefined'
      && attribute.regex.js !== ''){

      let patt = new RegExp(attribute.regex.js);

      // Validate value
      if(value && !patt.test(value) ){ // Vaule is valid.
        return __('valueFormatError');
      }else{ // Value is not valid.
        return undefined;
      }

    }else{ // No validation needed.
      return undefined;
    }
  }

  stepValidation(){
    let validation = [];

    validation.push(this.stepFieldValidator);

    if(validation.length){
      return undefined;
    }

    return validation;
  }

  render() {
    const {
      configuration,
      filterByDisplayAttributes,
      enumValues,
      attributes,
      values
    } = this.props;

    const shouldShow = (attr) => {
      if (attr.type === 'IDS') return false
      if (filterByDisplayAttributes) {
        const found = find(configuration.displayAttributes, (a) => a.attributeId === attr.id);
        return (found && attr.displayable);
      }
      return attr.displayable;
    };

    const getConfigController = (attr) => {
      if (filterByDisplayAttributes) {
        const found = find(configuration.displayAttributes, (a) => a.attributeId === attr.id);
        return (found && (found.controllerType || found.defaultControllerType || attr.type) );
      }
      return attr.type;
    };

    const renderLine = (attribute) => {
      if(attribute.enumerationId && !enumValues[attribute.enumerationId])
      {
        this.props.dispatch(getEnumValues(attribute.enumerationId));
      }

      if (shouldShow(attribute)) {
        return attribute && (
          <div className="col-xs-12 col-md-6" key={`obj-property-${attribute.id}`}>
            <Field
              name={attribute.id}
              formName={'stepObjectEditForm'}
              component={renderField}
              disabled={attribute.propertyLabel === 'id' || !attribute.editable}
              labelCol={'col-sm-9 col-md-6 col-lg-6'}
              label={
                <EllipsisText
                  trigger={'hover'}
                  enterDelay={0.25}
                >
                  {__(attribute.propertyLabel)}
                </EllipsisText>
              }
              className="form-control"
              type={getConfigController(attribute)}
              dateFormat={appDefaults.dateFormat}
              items={enumValues && attribute.enumerationId
              ? renderSelectOptions(enumValues[attribute.enumerationId],attribute.propertyLabel === 'id' || !attribute.editable)
              : []}
              attribute={attribute}
              validate={this.stepValidation.bind(this)()}
              overviewTabEditField
              // this is not really correct but I need this information or the new file uploader will be rendered as the reduced version
            />
          </div>
        );
      }
      return null
    };


    const renderSelectOptions = (options,readOnly) => {
      const _opts = options && options.map((opt) => {
        return (
          <option key={`date-format-${opt.id}`} value={opt.value} >
            {opt.translationKey ? __(opt.translationKey) : __(opt.value)}
          </option>);
       });
      return [<option key="verion-000" value="">{readOnly?"":__('Select one')}</option>].concat(_opts)
    };

    return (
      <S.VerticalForm className="form-horizontal stepObject-form">
        <div className="row">
          <div className="col-xs-12">
            {
              <StepAttributeNodeEdit
                checkVisibility
                renderLine={renderLine}
                attributes={attributes}
                values={values}
              />
            }
          </div>
        </div>
      </S.VerticalForm>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const StepObjectEditFormConnected = connect(
  () => {
    return {
    };
  },
  dispatch => {
    return {
      dispatch,
      getEnumValues,
    };
  },
)(StepObjectEditForm);

export default reduxForm({
  form: 'stepObjectEditForm',  // a unique identifier for this form
}, mapDispatchToProps)(StepObjectEditFormConnected);
