import { useState, useCallback, useEffect, useMemo } from "react";

import useDebounce from './useDebounce'
import _ from 'lodash'


const firstVisible = (parent, startIndex, children, direction) => {
  const { scrollTop: scrollPos, offsetHeight: scrollHeight } = parent
  
  for (let i = startIndex+direction; children[i]; i+=direction) {
    const nextEl = children[i]
    const { offsetTop: top, offsetHeight: height } = nextEl.ref
    const bottom = top + height
  
    if (scrollPos + 50 > top && scrollPos < bottom)
      return nextEl.id
    if (
      (direction === -1 && scrollPos > bottom) ||
      (direction === 1 && (scrollPos+scrollHeight) < top)
    )
      return
  }
  
  return undefined
}

/**
 * @props {ids: string[]}
 * @props {offsetTop: number}
 */

export default (ids = [], scrollableRef, optCallback) => {

  const [currentId, setCurrentId] = useState('')
  const [scrollingTo, setScrollingTo] = useState(null)
  const debouncedScrollingTo = useDebounce(scrollingTo, 150);
  
  const anchors = useMemo(() => (ids.reduce((acc, curr) => {
    const el = document.querySelector(`#${curr}`)
    if (el) acc.push({ id: curr, ref: el })
    return acc
  }, [])), [ids])
  
  const updateCurrentId = useCallback((id) => {
    if (!id || !_.isString(id)) return null
    const el = document.querySelector(`#${id}`)
    
    setScrollingTo(id)
    setCurrentId(id)
    scrollableRef?.current?.scrollTo({
      top: el.offsetTop - 10,
      behavior: "smooth"
    })
    
  }, [scrollableRef])
  
  const handleScroll = useCallback((e) => {
    const { scrollTop, scrollTopMax} = scrollableRef?.current || {}
  
    const el = anchors.find(({ id }) => id === currentId)
    const offSet = scrollTop - el.ref.offsetTop
    
    if (debouncedScrollingTo || scrollingTo) {
      if ( debouncedScrollingTo === currentId && (Math.abs(offSet + 10) <= 1 || scrollTop  === scrollTopMax) ) {
        setScrollingTo(true)
        return
      } else if(scrollingTo === true && (offSet + 10 < -15 || el.ref.clientHeight - offSet < 0)) {
        setScrollingTo(false)
      } else return
    }

    if (!_.isArray(ids)) return null

    const newId = firstVisible(scrollableRef?.current, anchors.indexOf(el), anchors, offSet > 0 ? 1 : -1)

    if (newId) {
      optCallback?.(newId)
      setCurrentId(newId)
      setScrollingTo(true)
    }
  }, [currentId, scrollingTo, debouncedScrollingTo, anchors]);
  
  useEffect(() => {
    scrollableRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      scrollableRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollableRef, handleScroll]);
  
  
  return [currentId, updateCurrentId]
}
