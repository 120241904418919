import React from 'react'
import ReactDOM from 'react-dom'
import ModalMessage from "../components/UI/ModalMessage"

export const handleTimeout = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), timeout)
  })
}

// Helper function to wait for a specified time
export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms))


export const countDownMessage = (message, countDown, callback) => {
  ReactDOM.render(
    <ModalMessage
      id="modal-message-id"
      message={message}
      countDown={countDown}
      onFinish={callback}
    />,
    document.createElement('root'));
}
