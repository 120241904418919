import styled from 'styled-components'

export const CalendarMacbookStyleHandler = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SIZES = {
  small: '1rem',
  regular: '1.4rem'
}

export const IconWrapper = styled.div`
  color: black;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  width: ${(props) => SIZES[props.size] || SIZES.regular};
  height: ${(props) => SIZES[props.size] || SIZES.regular};
  font-size: .75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: .7rem;
  transition: all .2s;
  &:hover {
    box-shadow: 0px 1px 4px -2px black; 
  }
  svg {
    margin: 0;
    padding: 0;
  }
`