import * as types from '../types';
import * as config from '../../../constants/globalConfiguration';
import { showMessage } from '../../../utils/appHelper';
import { hashHistory } from '../../HistoryProvider';

import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function deleteEntity(entity) {
  return function (dispatch) {
    return api.patch('/entities/'+entity.id+'/status', {
      status: 'deleted'
    })
    .then(
      response => {
        if(response.status === 200){
          let typeUrl = (entity.type === 'user_group')?'userGroups':'organisations';
          hashHistory.push('/' + typeUrl);
          setTimeout(()=>{
            showMessage('success', __(config.entityTypes[entity.type].name) + ' ' + __('deleted successfully'));
            },
            200
          );
        }
      }
    );
  };
}

export function reopenEntity(entity) {
  return function (dispatch) {
    return api.patch('/entities/'+entity.id+'/status', {
      status: 'enabled'
    })
    .then(
      response => {
        if(response.status === 200){
          //hashHistory.push('/'+config.entityTypes[entity.type].alias+'s');
          setTimeout(()=>{
            showMessage('success', __(config.entityTypes[entity.type].name) + ' ' + __('enabled successfully'));
            },
            200
          );
        }
      }
    );
  };
}

export function updateEntity(params) {
  return function (dispatch) {
    return api.put('/entities/'+params.id,{...params})
    .then(
      response => {
        if(response.status === 200){

          hashHistory.push('/'+config.entityTypes[params.type].alias+'s');

          setTimeout(()=>{
            showMessage('success', __(config.entityTypes[params.type].name) + ' ' +  __('updated successfully'));
            },
            200
          );
        }

        dispatch({
          type: types.GOT_ORGANISATIONS_DATA,
          payload: response.data
        });

      }
    );
  };
}

export function createEntity(params) {
  return function (dispatch) {
    return api.post('/entities',{ ...params })
    .then(
      response => {
        if(response.status === 201){

          hashHistory.push('/'+config.entityTypes[params.type].alias+'s');

          setTimeout(()=>{
            showMessage('success', __(config.entityTypes[params.type].name) + ' ' + __('created successfully'));
            },
            200
          );

        }
        dispatch({
          type: types.GOT_ORGANISATIONS_DATA,
          payload: response.data
        });
      }
    );
  };
}
