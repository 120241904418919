import { __ } from '../../../utils/translationUtils'

/* eslint-disable eqeqeq */
function transformChildren({ label, children = [], data = [] }) {
  return {
    value: label,
    label,
    checkable: !data?.map,
    disabled: data?.map,
    children: [
      ...data.map((leaf) => ({
        value: leaf.id,
        label: __(leaf.propertyLabel)
      })),
      ...children.map(transformChildren)
    ]
  }
}

export default (data, remoteSystems) => {
  if (!data) return []

  const apiAttributes = Object.values(data)

  const systemKeys = Object.keys(data)

  return apiAttributes.map((remoteSystem, remoteSystemIndex) => {
    const currenSystemKey = systemKeys[remoteSystemIndex]
    const system = remoteSystems.find((e) => e.id == currenSystemKey) // == is not a bug
    if (!system) return null

    return {
      value: system?.id,
      checkable: false,
      disabled: true,
      label: system?.name,
      children: remoteSystem.map(transformChildren)
    }
  }).filter((e) => e)
}
