import * as config from "../constants/globalConfiguration";
const apiUrl = config.getApiUrl()

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const partialPathToFileInfo = (partialPath, extension) => {
  let path = `${apiUrl}/${partialPath}`
  path = extension ? path.split(extension).at(0) : path

  return {
    path,
    name: path.split('/').at(-1),
  }
}
