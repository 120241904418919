import {api} from '../../../providers/ApiProvider'
import {fetchEnumById} from "../../../utils/appHelper";
import groupBy from "lodash.groupby"

import { __ } from '../../../utils/translationUtils'

// @Singleton Instance
class ApiManagement {
  constructor() {
    if (!ApiManagement.instance) {
      ApiManagement.instance = this
    }
    return ApiManagement.instance
  }

  #_attributesTree

  get attributesTree() {
    return this.#_attributesTree
  }

  #_valueTypesMappings

  get valueTypesMappings() {
    return this.#_valueTypesMappings
  }

  #_remoteSystems

  get remoteSystems() {
    return this.#_remoteSystems
  }

  #_entities

  get entities() {
    return this.#_entities
  }

  #_enumerations

  get enumerations() {
    return this.#_enumerations
  }

  #_publishedFlowsLastVersion

  get publishedFlowsLastVersion() {
    return this.#_publishedFlowsLastVersion
  }

  #_userRights

  get userRights() {
    return this.#_userRights
  }

  fetchAttributeTree = async () => {
    const response = await api.get('configurations/attributes/tree')
    if (response) {
      this.#_attributesTree = response.data
      return true
    }
    return false
  }

  fetchPublishedFlowsLastVersion = async () => {
    const response = await api.get('/flows/', { data: null, params: { size: 1000 } })
    if (response) {
      this.#_publishedFlowsLastVersion = response.data
      return true
    }
    return false
  }

  fetchValueTypesMappings = async () => {
    const response = await api.get('/configurations/valueTypes/mappings')
    if (response) {
      this.#_valueTypesMappings = response.data
      return true
    }
    return false
  }

  fetchRemoteSystems = async () => {
    const response = await api.get('/remoteSystems')
    if (response) {
      const res = {}
      response.data.forEach((r) => res[r.id] = r.name)
      this.#_remoteSystems = res
      return true
    }
    return false
  }

  fetchEntities = async () => {
    const response = await api.get('/entities')
    if (response) {
      this.#_entities = response.data
      return true
    } else {
      return false
    }
  }

  fetchEnumeration = async (enumerationId) => {
    const response = await fetchEnumById(enumerationId)
    if (response) {
      this.#_enumerations = {...(this.#_enumerations ? this.#_enumerations : {}), [enumerationId]: response.data}
      return true
    } else {
      return false
    }
  }

  fetchUser = async () => {
    const responseMe = await api.get('/me')
    if (!responseMe || !responseMe.data?.id) {
      return false
    }

    const responseRights = await api.get(`/users/${responseMe.data.id}/rights`)
    if (responseRights) {
      this.#_userRights = responseRights.data
      return true
    } else {
      return false
    }
  }

  checkUserRights = (rightDescription = {}) => {
    if (!this.userRights) {
      return false
    }

    return this.userRights.findIndex((r) => Object.entries(rightDescription).every(([k, v]) => r[k] === v)) !== -1
  }

  getRemoteSystemsFilteredBy = (filteredBy) => {
    if (typeof filteredBy !== "string") {
      throw Error("Filtered by is required to filter remote systems")
    }

    if (typeof this.#_remoteSystems === "undefined") {
      return []
    }

    return Object.entries(this.#_remoteSystems)
      .filter((entry) => entry[0] !== filteredBy)
      .map(([systemId, systemLabel]) => [parseInt(systemId), systemLabel])
  }

  getRemoteObjectFromSystem = (systemId) => {
    if (
      typeof systemId === "undefined" ||
      (typeof systemId === "string" && systemId === "") ||
      typeof this.#_attributesTree === "undefined"
    ) {
      return []
    }

    return this.#_attributesTree[systemId]
  }

  getRemoteObjectFromSystemIdAndObjectId = (systemId, objectTypeId) => {
    if (typeof systemId === "undefined" || typeof objectTypeId === "undefined" || typeof this.#_attributesTree === "undefined") {
      return null
    }

    return this.#_attributesTree[systemId].find((oObject) => oObject.objectTypeId === objectTypeId)
  }

  getEntityTreeData = () => {
    if (!this.#_entities) {
      return []
    }
    const treeData = []
    Object.entries(groupBy(this.entities, (e) => e.belongsTo.id)).forEach(([entityId, entityChildren]) => {
      const entity = this.entities.find((e) => e.id === entityId)
      treeData.push({
        title: entity.name,
        value: `${entityId}_parent`,
        key: `${entityId}_parent`,
        checkable: false,
        children: entityChildren.map((child) => ({
          title: child.name,
          value: child.id,
          key: child.id,
        })),
      })
    })
    return treeData
  }
  
  getAvailableControllerType = (configurationType) => {
    if (!configurationType || this.valueTypesMappings == null) {
      return []
    }
    return this.valueTypesMappings[configurationType].availableControllerTypes.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
  }

  getDefaultControllerType = (configurationType) => {
    if (!configurationType || this.valueTypesMappings == null) {
      return null
    }
    return this.valueTypesMappings[configurationType].defaultControllerType
  }
}

const instance = new ApiManagement()

export default instance
