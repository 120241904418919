import React, {useMemo} from 'react'

import {Empty} from 'antd'

import * as S from './styles'

export default (props) => {

  const {
    children,
    image,
    type='default',
    size='default',
    space,
    title,
    subTitle,
    centered,
    titleWrap= { before: null, after: null },
    subTitleWrap= { before: null, after: null }
  } = props

  const getImage = useMemo(() => {
    if (image) return image
    if (!(image == null) && !image) return null
    if (type === 'noData') return Empty.PRESENTED_IMAGE_SIMPLE
    return Empty.PRESENTED_IMAGE_DEFAULT
  }, [image, type] )

  return (
    <S.NoData
      image={getImage}
      centered={centered}
      description={
        <>
          { title &&
            <S.Title size={size}>
              {titleWrap?.before &&
                <S.WrapText after={size}>
                  {titleWrap.before}
                </S.WrapText>
              }
              {title}
              {titleWrap?.after &&
                <S.WrapText before={size}>
                  {titleWrap.after}
                </S.WrapText>
              }
            </S.Title>
          }
          { subTitle &&
            <S.SubTitle size={size}>
              {subTitleWrap?.before &&
                <S.WrapText after={size}>
                  {subTitleWrap.before}
                </S.WrapText>
              }
              {subTitle}
              {subTitleWrap?.after &&
                <S.WrapText before={size}>
                  {subTitleWrap.after}
                </S.WrapText>
              }
            </S.SubTitle>
          }
        </>
      }
      size={size}
      space={space}
    >
      {children}
    </S.NoData>
  )
}
