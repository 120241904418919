import React, {useRef} from "react"
import {Modal} from 'antd'
import PropTypes from "prop-types"
import * as CONFIG_TYPE from "./context/configurationType"
import Configurator from "./Configurator"


ModalConfigurator.propTypes = {
  configurationType: PropTypes.oneOf([CONFIG_TYPE.VIEW, CONFIG_TYPE.REPORT, CONFIG_TYPE.OBJECT_LIST, CONFIG_TYPE.OBJECT_BINDER, CONFIG_TYPE.OBJECT_PICKER]).isRequired,
  configurationToEdit: PropTypes.object,
  modalProps: PropTypes.object,
  pageHeaderProps: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

// manage the submit

function ModalConfigurator({modalProps = {}, onSubmit, ...props}) {
  const configurator = useRef()

  const handleSubmit = () => {
    if (typeof onSubmit !== "function") {
      throw Error("You must provide a submit function to use this component. Please contact Administrator")
    }

    const dataSource = configurator.current.getConfiguration()

    if (dataSource) {
      onSubmit(dataSource)
    }
  }

  return (
    <Modal title={"Configuration Configurator"} width={1500} onOk={handleSubmit} {...modalProps}>
      <Configurator {...props} ref={configurator}/>
    </Modal>
  )
}

export default ModalConfigurator
