import React from 'react'

import {Row, Space, Typography} from "antd";
import _ from "lodash";

import * as configurationPayloadMappingMethods from './mapConfigurationPayload'
import groupBy from "lodash.groupby";
import {validateAllSteps} from "./validation";
import { __ } from '../../../utils/translationUtils'

const { Text } = Typography

export const mapConfigurationPayload = {
  FrontendToBackend: configurationPayloadMappingMethods.mapFrontendPayloadToBackend,
  BackendToFrontend: configurationPayloadMappingMethods.mapBackendPayloadToFrontend,
  FrontendItemsToBackend: configurationPayloadMappingMethods.mapFrontendItemsToBackend,
  FrontendItemToBackend: configurationPayloadMappingMethods.mapFrontendItemToBackend,
  BackendItemsToFrontend: configurationPayloadMappingMethods.mapBackendItemsToFrontendPayload,
  BackendItemToFrontend: configurationPayloadMappingMethods.mapBackendItemToFrontend,
}

export const mapAttributesTree = (attributesTree) => (
  attributesTree && Object.keys(attributesTree).map((system) => (
    {
      label: system,
      name: system,
      data: attributesTree[system]
    }
  ))
)

export const sortArrayOfObjects = (array, field, type) => {
  if (!array.length) return []
  switch (type) {
    case 'translation':
      return array.sort((a, b) => __(a?.[field])?.toLowerCase() > __(b?.[field])?.toLowerCase() ? 1 : -1)
    default:
      return array.sort((a, b) => a?.[field]?.toLowerCase() > b?.[field]?.toLowerCase() ? 1 : -1)
  }
}

const sortObjectsTree = (objectsTree) => {
  if (!objectsTree?.length) return []
  sortArrayOfObjects(objectsTree, 'label')
  objectsTree.forEach((objectTree) => {
    objectTree.children = sortObjectsTree(objectTree.children)
    sortArrayOfObjects(objectTree.data, 'propertyLabel', 'translation')
  })
  return objectsTree
}

export const sortAttributesTree = (attributesTree) => {
  const sortedAttributesTree = _.cloneDeep(attributesTree)
  sortArrayOfObjects(sortedAttributesTree, 'label')
  sortedAttributesTree.forEach((system) => {
    sortObjectsTree(system.data)
  })
  return sortedAttributesTree
}

export const translateAllSystems = (tree, systems) => {
  if (!tree) return []
  if (!systems) return tree
  const copy = _.cloneDeep(tree)
  return copy.map((system) => {
    system.label = systems[system.name]
    return system
  })
}

export const getAttributesListingFromObjectListing = (objectsTree) => {
  if (!objectsTree) return null
  return objectsTree.reduce((prevObjects, object) => (
    [
      ...prevObjects,
      ...(object.data || []),
      ...(getAttributesListingFromObjectListing(object.children) || [])
    ]
  ), [])
}

export const getAttributesListingFromTree = (attributesTree) => (
  attributesTree.reduce((prevSystems, system) => (
    [
      ...prevSystems,
      ...getAttributesListingFromObjectListing(system.data)
    ]
  ), [])
)

export const getAttributesMapFromTree = (attributesTree) => (
  attributesTree && getAttributesListingFromTree(attributesTree).reduce((prev, attribute) => {
    prev[attribute.id] = attribute
    return prev
  }, {})
)

export const getValuesListOptions = (list) => {
  return list.map((item) => (
    {value: item, label: __(item)}
  ))
}

export const getExtendedKey = (key, extension) => {
  if (!extension) return key
  if (Array.isArray(extension)) return extension.reduce((prev, ext) => (prev + ext + '_' ), '') +  key
  return extension + '_' + key
}


export const getAttributePathParents = (attribute, systems) => {
  const objectsPath = attribute.property?.split('/')
  objectsPath.pop()
  return [ systems[attribute?.system], ...objectsPath]
}

export const getFlowOptions = (flows) => {
  return flows.map((flow) => (
    {
      value: flow.id,
      title: flow.title,
      label: (
        <Row justify='space-between'>
          <Text>{flow.title}</Text>&nbsp;
          <Text type="secondary">v.{flow.version}</Text>
        </Row>
      )
    }
  ))
}

export const getSubObjectsStatistics = (treeData, selectedAttributes) => {
  if (!treeData.length) return {selected: 0, tot: 0}
  return treeData.reduce((prev, subObject) => {
    const subObjectStatistics = getSubObjectsStatistics(subObject.children, selectedAttributes)
    return {
      selected: prev.selected + subObjectStatistics?.selected + subObject.data.filter((attribute) => selectedAttributes.includes(attribute.id)).length,
      tot: prev.tot + subObjectStatistics?.tot + subObject.data.length
    }
  }, {
    selected: 0,
    tot: 0
  })
}

const countAttributesInObjects = (objectsTree) => {
  if (!objectsTree) return 0
  let count = 0
  objectsTree.forEach((subObject) => {
    count += subObject.data?.length || 0
    count += countAttributesInObjects(subObject.children)
  })
  return count
}

export const mapEntityTreeData = (entities) => {
  if (!entities) {
    return []
  }
  const treeData = []
  Object.entries(groupBy(entities, (e) => e.belongsTo.id)).forEach(([entityId, entityChildren]) => {
    const entity = entities.find((e) => e.id === entityId)
    treeData.push({
      title: entity.name,
      value: `${entityId}_parent`,
      key: `${entityId}_parent`,
      checkable: false,
      children: entityChildren.map((child) => ({
        title: child.name,
        value: child.id,
        key: child.id,
      })),
    })
  })
  return treeData
}

export const getAdditionalPayload = (payload, attributesMap) => {
  if (!payload) return null
  const mappedConfigurations = mapConfigurationPayload.BackendToFrontend(payload, attributesMap)
  return {
    configurations: mappedConfigurations,
    creatorId: payload.creatorId,
    validationState: validateAllSteps(mappedConfigurations)
  }
}


export const filterNodeByItemsKey = (node, keys) => {
  const filterNodeChildrenByItemsKey = (objectsToFilter) => {
    if (!objectsToFilter?.length || !keys?.length) return []
    return objectsToFilter.reduce((prevObjects, object) => {
      const newObject = filterNodeByItemsKey(object, keys)
      if (newObject?.data?.length || newObject?.children?.length) prevObjects.push(newObject)
      return prevObjects
    }, [])
  }
  if (!node || !keys?.length) return null
  return {
    ...node,
    data: node?.data?.filter((item) => keys.includes(item.key)) || [],
    children: node?.children?.length ? filterNodeChildrenByItemsKey(node.children) : [],
  }
}

export const getFlowsSummaryTagProps = (flowOptions) => (
  {
    title: __('flows'),
    content: (filterValue) => (
      <Space direction={'vertical'} size={'small'}>
        { filterValue.map((flowId) => flowOptions.find((flow) => flow.value === flowId)?.label)}
      </Space>
    ),
  }
)
