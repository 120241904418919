import styled from 'styled-components'

export const InfoBox = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: .25rem;
`
export const Strong = styled.strong`
  font-size: 16px;
  line-height: 12px;
`

export const Label = styled.label`
  word-break: break-word;
  text-align: right;
  &label.col-xs-12 {
    text-align: left;
  }
`
