import styled from 'styled-components'
import {Checkbox, Image} from 'antd'

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const CheckboxGroupItem = styled(Checkbox)`
  padding-bottom: 8px;
  ${props => props?.disabled && 'opacity: 0.5' };
`

export const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  > :not(:last-child) {
    margin-right: 12px;
  }
  > :not(:first-child) {
    margin-left: 12px;
  }
`

export const CustomImage = styled(Image)`
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
  ${props => props?.disabled && 'opacity: 0.5' };
`
