import React from 'react'
import PropTypes from 'prop-types'

const Loading = ({ loading, partial, fullHeight }) => (
  <div className={`loading-component ${partial ? 'partial' : ''} ${loading ? 'loading' : ''} ${fullHeight ? 'full' : ''}`}>
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1" />
      <div className="sk-cube sk-cube2" />
      <div className="sk-cube sk-cube3" />
      <div className="sk-cube sk-cube4" />
      <div className="sk-cube sk-cube5" />
      <div className="sk-cube sk-cube6" />
      <div className="sk-cube sk-cube7" />
      <div className="sk-cube sk-cube8" />
      <div className="sk-cube sk-cube9" />
      <div className="sk-cube sk-cube10" />
      <div className="sk-cube sk-cube11" />
      <div className="sk-cube sk-cube12" />
      <div className="sk-cube sk-cube13" />
      <div className="sk-cube sk-cube14" />
      <div className="sk-cube sk-cube15" />
    </div>
  </div>
)

Loading.propTypes = {
  loading: PropTypes.bool,
  partial: PropTypes.bool,
  fullHeight: PropTypes.bool
}

Loading.defaultProps = {
  loading: true,
  partial: false,
  fullHeight: false
}

export default Loading
