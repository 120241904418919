import React from 'react'
import { Button, Form} from "antd";
import styled from "styled-components/macro";

import { __ } from '../../utils/translationUtils'

export default (props) => {

  const {
    form,
    initialValues = {},
    onValuesChange,
    onFinish,
    layout = 'vertical',
    showSubmit = true,
    submitLabel,
    children,
  } = props



  return <Form
    initialValues={initialValues}
    form={form}
    layout={layout}
    scrollToFirstError
    onValuesChange={onValuesChange}
    onFinish={(values) => {
      onFinish?.(values)
    }}
  >
    {
      children
    }
    <SubmitWrapper visible={showSubmit}>
      <Button type="primary" htmlType="submit" >
        { submitLabel || __('Submit')}
      </Button>
    </SubmitWrapper>
  </Form>
}

const SubmitWrapper = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
`
