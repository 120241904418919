import { isImage } from '../../../../../../utils/appHelper'
import { ILLEGAL_ARGUMENT_EXCEPTION } from '../constant'

class UploadFileService {
  constructor() {
    if (UploadFileService.instance == null) {
      UploadFileService.instance = this
    }
    return UploadFileService.instance
  }

  filterImage(fileList = []) {
    const pattern = /^data:(\S*);/
    return fileList.filter((file) => {
      const match = file?.url?.match?.(pattern)
      const fileType = match ? match[1] : file.type || null
      if (fileType === 'application/octet-stream') {
        const patternExtension = /\.[0-9a-z]+$/i
        return isImage(patternExtension.exec(file.name)[0])
      }
      return isImage(fileType)
    })
  }

  filterImageBaseOnExtension(fileList = []) {
    const imageList = []

    const patternExtension = /\.[0-9a-z]+$/i
    for (const file of fileList) {
      if (isImage(patternExtension.exec(file.name)[0])) {
        imageList.push(file)
      }
    }
    return imageList
  }

  computeFileName( file, fileDefinition, fileList ) {
    if ( !file || !fileDefinition || !fileList ) {
      console.error(ILLEGAL_ARGUMENT_EXCEPTION)
      return 'impossible_compute_name.txt'
    }

    /* Change fileName */
    const patternExtension = /\.[0-9a-z]+$/i
    const fileExtension = patternExtension.exec(file.name)[0]
    
    let maxIndexFileList = 0
    const patternIndex = /[0-9a-zA-Z\s-]+(_|\.)(\d+)/i
    fileList.forEach((f) => {
      const maxIndex = f.name.match(patternIndex) ? f.name.match(patternIndex)[2] : '0'
      maxIndexFileList = maxIndex > maxIndexFileList ? maxIndex : maxIndexFileList
    })

    return `${fileDefinition.name}_${parseInt(maxIndexFileList) + 1}${fileExtension}`
  }

  computeFileNameNoDuplicates( file, fileList ) {
    if (!file || !fileList) {
      console.error(ILLEGAL_ARGUMENT_EXCEPTION)
      return 'impossible_compute_name.txt'
    }
    const patternExtension = /\.[0-9a-z]+$/i
    const fileExtension = patternExtension.exec(file.name)[0]
    const fileName = file.name.substring(0, file.name.length - fileExtension.length )

    let composedFileName = fileName + fileExtension
    let duplicatesCounter = 0
    while (fileList.filter((existingFile) => existingFile.name === composedFileName).length > 0) {
      duplicatesCounter += 1
      composedFileName = `${fileName}_copy_${duplicatesCounter}${fileExtension}`
    }
    return composedFileName
  }

  applyImageOptions(imageOptions) {
    let uri = '?'

    Object.entries(imageOptions).forEach(([key, value]) => {
      const param = `${key.toLowerCase()}=${value}`
      uri += uri.charAt(uri.length - 1) === '?' ? param : `&${param}`
    })

    return uri === '?' ? '' : uri
  }
}

const instance = new UploadFileService()

export default instance
