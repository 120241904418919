import React from 'react';
import PropTypes from 'prop-types';
import {HashRouter, Link} from 'react-router-dom';
import {OverlayTrigger, Panel, Popover} from 'react-bootstrap';
import Glyphicon from '@strongdm/glyphicon'
import moment from 'moment';
import * as config from '../constants/globalConfiguration';
import MessageAttachment from "./UI/messagesDeprecated/MessageAttachment";
import {MessageText, MessageUserInfo} from "./UI/messagesDeprecated/MessageComponents";
import {api} from '../providers/ApiProvider'
import {__} from '../utils/translationUtils'

const loadingBar = require('nprogress');

class OrderHistory extends React.Component {

  static propTypes = {
    orderId: PropTypes.string,
    data: PropTypes.array,
  };

  static defaultProps = {
    data: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: true,
      data: [],
    };
  }

  handleTottle() {
    // Get data only first time.
    if (!this.state.open && this.state.isLoading) {
      this.getData();
    }
    this.setState({"open": !this.state.open});
  }

  getData() {
    if (this.props.data) { // is snapshot
      this.setState({data: this.props.data, isLoading: false});
    } else { // is normal order
      api.get(`orders/${this.props.orderId}/history`)
        .then(
          response => {
            this.setState({data: response.data, isLoading: false});
            loadingBar.done();
          }
        );
    }
  }

  render() {
    const rows = [].concat(this.state.data);
    return (

      <div className="order-history fieldset style-1">

        <span className="collapsible-header no-selection"
              onClick={this.handleTottle.bind(this)}>
          {__('Order History')}
        </span>

        <Panel
          style={{marginBottom: '40px'}}
          expanded={this.state.open}
          onToggle={() => {
          }}
          bsStyle="info"
        >
          <Panel.Body collapsible>
            <div style={{maxHeight: '350px', overflowY: 'auto'}}>

              {
                (!this.state.isLoading) ?
                  <table className="table small">
                    <thead>
                    <tr>
                      <th>{__('mainStatus')}</th>
                      <th>{__('Order Sub-Status')}</th>
                      {/*<th>{__('NA Work Order Status')}</th>*/}
                      <th>{__('Responsible ORG')}</th>
                      <th>{__('Responsible User')}</th>
                      <th>{__('Last Modification Date/Time')}</th>
                      <th>{__('Comment')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      rows.map((row, i) => {
                          // Snapshots data encapsulated in "content" property.
                          if (typeof row.content !== 'undefined') {
                            row = row.content;
                          }

                          // const process = findActiveProcess(row.processes);
                          const getResponsibleUser = () => {

                            if (typeof row.responsibleUsers == 'undefined'
                              || row.responsibleUsers == null
                              || row.responsibleUsers.length == 0) {
                              return '---';
                            }

                            if (row.responsibleUsers.length == 1) {
                              return (
                                <Link style={{fontWeight: 'normal'}} to={`/users/${row.responsibleUsers[0].id}`}>
                                  {row.responsibleUsers[0].username}
                                </Link>
                              );
                            } else { // Multiple users
                              let users = [];
                              row.responsibleUsers.map((user, i) => {
                                let comma = (i + 1 < row.responsibleUsers.length) ? ', ' : '';
                                users.push(
                                  <span key={row.id + '_' + user.id}>
                                  <Link to={`/users/${user.id}/edit`}>{user.username}</Link>
                                    {comma}
                          </span>
                                );
                              });

                              const popoverHoverFocus = (
                                <Popover id={`popover-trigger-resUsers-${row.id}`} title={__('')}>
                                  <HashRouter>
                                    {users}.
                                  </HashRouter>
                                </Popover>
                              );

                              return (
                                <OverlayTrigger trigger={'click'} rootClose placement="top" overlay={popoverHoverFocus}>
                                  <span
                                    className="user-popover-trigger">{row.responsibleUsers.length} {__('Users')}</span>
                                </OverlayTrigger>
                              );
                            }
                          };

                          {/*const extractMediumSummaryData = (fieldName) => {
                      let found = _.find(row.summaryMedium, (r)=>{
                        return r.title.trim() == fieldName;
                      });
                      if (typeof found !== 'undefined'){
                        return found.value;
                      }else{
                        return '---';
                      }
                    };

                    const extractSmallSummaryData = (fieldName) => {
                      let found = _.find(row.summarySmall, (r)=>{
                        return r.title.trim() == fieldName;
                      });
                      if (typeof found !== 'undefined'){
                        return found.value;
                      }else{
                        return '---';
                      }
                    };*/
                          }

                          const getResponsibleOrg = () => {

                            if (!row
                              || typeof row.responsibleOrganisations == 'undefined'
                              || row.responsibleOrganisations == null
                              || row.responsibleOrganisations.length == 0) {
                              return '---';
                            }

                            if (row.responsibleOrganisations.length == 1) {
                              return row.responsibleOrganisations[0].shortName;
                            } else { // Multiple orgs
                              let orgs = '';
                              row.responsibleOrganisations.map((org) => {
                                orgs = orgs + org.shortName + ', ';
                              });

                              orgs = orgs.substring(0, orgs.length - 2); // remove last comma
                              return orgs;
                            }
                          };


                          const getComment = () => {
                            if (!row
                              || typeof row.lastComment == 'undefined'
                              || row.lastComment == null
                              || typeof row.lastComment.text == 'undefined'
                              || row.lastComment.text == null) {
                              return '---';
                            }

                            const lastComment = row.lastComment;

                            // I have to add maxWidth 1000px otherwise with an attachment to big it will be break the ui
                            const popoverHoverFocus = (
                              <Popover id={`popover-trigger-lastComment-${row.id}`} title={__('')}
                                       style={{maxWidth: '2000px'}}>
                                {lastComment &&
                                  <div className="comment-item gutter-top">
                                    <MessageUserInfo user={lastComment.creator} dateToShow={lastComment.creationDatetime}
                                                     editModeOn={false}/>
                                    <MessageText editModeOn={false} messageText={lastComment.text}
                                                 onChangeMessageText={() => {
                                                 }} edited={false}
                                    />
                                    {lastComment.attachmentPath &&
                                      <MessageAttachment
                                        attachmentPath={lastComment.attachmentPath}
                                        onChangeAttachmentPath={() => {
                                        }}
                                        editModeOn={false}
                                        authToken={''}
                                        objectCaller={{}}
                                      />}
                                  </div>
                                }
                              </Popover>
                            );


                            return (
                              <OverlayTrigger
                                trigger={'click'}
                                rootClose placement="top"
                                overlay={popoverHoverFocus}
                              >
                              <span className="user-popover-trigger" key={row.id + '_' + row.lastComment.id}>
                                {row.lastComment.text} {row.lastComment && row.lastComment.attachmentPath &&
                                <span>&nbsp;&nbsp;<Glyphicon glyph={'file'}/></span>}
                              </span>
                              </OverlayTrigger>
                            );
                          };


                          return (
                            <tr key={i}>
                              <td>{__(row.orderStatus)}</td>
                              <td>{__(row.processStatus) ? __(row.processStatus) : '---'}</td>
                              <td>{getResponsibleOrg()}</td>
                              <td>{getResponsibleUser()}</td>
                              <td>{moment(row.lastUpdateDatetime).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat)}</td>
                              <td>{getComment()}</td>
                            </tr>
                          );
                        }
                      )
                    }
                    </tbody>
                  </table>
                  : <i>{__('loading')}</i>
              }
            </div>

          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

export default OrderHistory;
