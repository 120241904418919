import styled from 'styled-components'
import Background from '../../images/logo-full-op.png'
import {Select} from "antd";

export const Section = styled.div`
  background-image: url(${Background});
  background-position: center 130px;
  background-size: 40%;
  background-repeat: no-repeat;
`
export const Containter = styled.div`
  box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  width: 100%;
  position: relative;


  form,
  button,
  .ant-picker {
    width: 100%;
  }

  .ant-row {
    height: 100%;
  }

  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    height: 100%;
  }

  @media (min-width: 992px) {
    display: flex;
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`

export const GridLayoutWrapper = styled.div`
  width: 100%;
  .react-grid-layout {
    width: 100%;
  }

  .react-resizable-handle {
    display: none;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    height: 2rem;
    width: 1re;
    width: 2rem;
    border-right: 2px solid ${(props) => props.theme.colors.thirdary};
    border-bottom: 2px solid ${(props) => props.theme.colors.thirdary};
    cursor: move;
  }
`

export const HandlerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`

export const IconDanger = styled.div`
  color: red;
  display: flex;
  align-items: center;
  svg {
    margin-right: .5rem;
  }
`

export const Label = styled.div`
  margin-bottom: .75rem;
`

export const Title = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: .5rem;
  color: ${(props) => props.theme.colors.gray.quintenary};
`

export const ChartWrapper = styled.div`
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(141, 149, 166, 0.1) 0px 2px 4px;
  display: flex;
  flex-direction: column;
  ${(props) => props.pointer && `
    cursor: pointer;
  `}
  padding: 2rem;
`

export const ChartTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  letter-spacing: .04rem;
  color: ${(props) => props.theme.colors.thirdary};
`

export const SelectWrapper = styled.div`
  padding: 2rem;
`

export const SelectAsTitle = styled(Select)`
  font-size: 26px;
  font-weight: 400;
  font-family: inherit;
  color: #2d394588;
  padding: 0 5px;
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
    padding: 0 5px;
  }
  & .ant-select-selection-item {
    overflow: unset;
  }
`

