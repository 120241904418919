export default ({ companiesTree, userId, selectedCompanies }) => {
  if (!companiesTree || !userId) return
  const defaultAssignees = []

  let companiesTreeToBeTraversed = companiesTree

  // companiesTreeToBeTraversed is filtered only if there are companies selected by the user on the left sidebar
  if (selectedCompanies.filter((e) => JSON.parse(e)?.type === 'COMPANY')?.length > 0) {
    companiesTreeToBeTraversed = companiesTreeToBeTraversed.filter(({ key }) => selectedCompanies.includes(key))
  }

  companiesTreeToBeTraversed.forEach((company) => {
    if (!company.children) return
    company.children?.forEach((entity) => {
      entity.children?.forEach((user) => {
        if (JSON.parse(user.key).id === userId) {
          defaultAssignees.push(user.key)
        }
      })
    })
  })
  
  return defaultAssignees
}
