import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import mapboxgl from 'mapbox-gl'
import { Button } from 'react-bootstrap'
import { MAPBOX_KEY as mapboxKey } from '../../../constants/server-variables'
import { ThemeProvider } from 'styled-components'

import * as S from './styles'
import Card from '../../Card'
import 'mapbox-gl/dist/mapbox-gl.css'
import theme from '../../../providers/StyleProvider/theme'

// return a numeric value that represents a latitude absolute value
function getFlyingFactor({ zoom }) {
  if (zoom >= 12) return ((12 / zoom) ** 9) * 0.02
  if (zoom >= 8) return ((8 / zoom) ** 3) * 0.1
  if (zoom >= 6) return ((6 / zoom) ** 2) * 0.4
  return 0.6
}

function getJsonParsedValue(value) {
  if (typeof value === 'string') return JSON.parse(`${value}`)
  return value
}

class Heatmap extends Component {
  static propTypes = {
    items: PropTypes.shape({
      type: PropTypes.string,
      features: PropTypes.array
    }),
    style: PropTypes.string,
    onClusterSelect: PropTypes.func,
    zoom: PropTypes.number,
    shouldFitBounds: PropTypes.bool,
    center: PropTypes.array,
    clusterThresholdsPropertyName: PropTypes.string,
    clusterDefaultColor: PropTypes.string,
    clusterThresholds: PropTypes.arrayOf(
      PropTypes.shape({ // Array of length 5 max
        color: PropTypes.string,
        limit: PropTypes.number
      })
    )
  };



  constructor(props) {
    super(props)
    this.init = this.init.bind(this)
    this.handleRenderPopup = this.handleRenderPopup.bind(this)
  }

  componentDidMount() {
    this.init()
  }

  handleRenderPopup({ items, center, map }) {
    const { onClusterSelect } = this.props
    const placeholder = document.createElement('div')

    function handleViewAllItems() {
      onClusterSelect({
        center,
        items: items.map((e) => e.properties.id),
        zoom: map.getZoom()
      })
    }
    ReactDOM.render(
      <div style={S.Popup}>
        <div style={{ ...S.PopupList, 'grid-template-columns': items.length > 1 ? '1fr 1fr' : '1fr' }}>
          {items.map(({ properties }) => {
            const { priorityIconProps, users, companies } = properties
            const props = {
              ...properties,
              priorityIconProps: getJsonParsedValue(priorityIconProps),
              users: getJsonParsedValue(users),
              companies: getJsonParsedValue(companies)
            }
            return <ThemeProvider theme={theme}><Card box {...props} /></ThemeProvider>
          })}
        </div>
        {items.length > 1 && (
          <div style={S.PopupFooter}>
            <Button onClick={handleViewAllItems}>View All</Button>
          </div>
        )}
      </div>,
      placeholder
    )

    new mapboxgl.Popup()
      .setDOMContent(placeholder)
      .setLngLat(center)
      .setMaxWidth('inherit')
      .addTo(map)
  }

  init() {
    const { handleRenderPopup } = this
    const { zoom, center, style, items, shouldFitBounds, clusterThresholds, clusterThresholdsPropertyName, clusterDefaultColor } = this.props

    if (!this.heatmap) return

    mapboxgl.accessToken = mapboxKey

    const map = new mapboxgl.Map({
      container: this.heatmap,
      style,
      ...(center ? { center } : {}),
      zoom,
      maxZoom: 17.75,
      clusterMaxZoom: 19
    })

    if (shouldFitBounds) {
      const bounds = new mapboxgl.LngLatBounds()

      items.features.forEach((feature) => {
        bounds.extend(feature.geometry.coordinates)
      })
      // eslint-disable-next-line no-underscore-dangle
      if (bounds._sw) {
        map.fitBounds(bounds, { padding: 40 })
      }
    }

    const [A, B, C, D, E] = clusterThresholds

    map.on('load', () => {
      map.resize()
      map.addSource('data', {
        type: 'geojson',
        data: items,
        cluster: true,
        clusterRadius: 80,
        clusterProperties: {
          limit1: ['+', ['case', ['<', ['get', clusterThresholdsPropertyName], A.limit], 1, 0]],
          limit2: ['+', ['case', ['all', ['>=', ['get', clusterThresholdsPropertyName], A.limit], ['<', ['get', clusterThresholdsPropertyName], B.limit]], 1, 0]],
          limit3: ['+', ['case', ['all', ['>=', ['get', clusterThresholdsPropertyName], B.limit], ['<', ['get', clusterThresholdsPropertyName], C.limit]], 1, 0]],
          limit4: ['+', ['case', ['all', ['>=', ['get', clusterThresholdsPropertyName], C.limit], ['<', ['get', clusterThresholdsPropertyName], D.limit]], 1, 0]],
          limit5: ['+', ['case', ['>=', ['get', clusterThresholdsPropertyName], D.limit], 1, 0]]
        }
      })

      map.addControl(new mapboxgl.NavigationControl())

      // CLUSTERS
      map.addLayer({
        id: 'cluster',
        type: 'circle',
        source: 'data',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'case',
            ['all', ['>=', ['get', 'limit1'], 0], ['==', ['get', 'limit2'], 0], ['==', ['get', 'limit3'], 0], ['==', ['get', 'limit4'], 0], ['==', ['get', 'limit5'], 0]],
            A.color,
            ['all', ['>', ['get', 'limit2'], 0], ['==', ['get', 'limit3'], 0], ['==', ['get', 'limit4'], 0], ['==', ['get', 'limit5'], 0]],
            B.color,
            ['all', ['>', ['get', 'limit3'], 0], ['==', ['get', 'limit4'], 0], ['==', ['get', 'limit5'], 0]],
            C.color,
            ['all', ['>', ['get', 'limit4'], 0], ['==', ['get', 'limit5'], 0]],
            D.color,
            ['all', ['>', ['get', 'limit5'], 0]],
            E.color,
            clusterDefaultColor
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20, 5, 30, 10, 40
          ],
          'circle-opacity': 0.8
        }
      })

      // CLUSTER TEXT
      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'data',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 18
        },
        paint: { 'text-color': '#ffffff' }
      })

      // POINT
      map.addLayer({
        id: 'point',
        type: 'circle',
        source: 'data',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'case',
            ['all', ['<', ['get', clusterThresholdsPropertyName], A.limit]],
            A.color,
            ['all', ['>=', ['get', clusterThresholdsPropertyName], A.limit], ['<', ['get', clusterThresholdsPropertyName], B.limit]],
            B.color,
            ['all', ['>=', ['get', clusterThresholdsPropertyName], B.limit], ['<', ['get', clusterThresholdsPropertyName], C.limit]],
            C.color,
            ['all', ['>=', ['get', clusterThresholdsPropertyName], C.limit], ['<', ['get', clusterThresholdsPropertyName], D.limit]],
            D.color,
            ['all', ['>=', ['get', clusterThresholdsPropertyName], D.limit]],
            E.color,
            clusterDefaultColor
          ],
          'circle-radius': 16
        }
      })

      // POINT TEXT
      map.addLayer({
        id: 'point-count',
        type: 'symbol',
        source: 'data',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field': '1',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 14
        },
        paint: { 'text-color': '#ffffff' }
      })

      map.on('click', 'cluster', (e) => {
        const features = map.queryRenderedFeatures(e.point, { layers: ['cluster'] })
        const clusterId = features[0].properties.cluster_id
        const { point_count: pointCount } = features[0].properties
        const clusterSource = map.getSource('data')

        const newCenter = e.features[0].geometry.coordinates.slice()

        clusterSource.getClusterLeaves(clusterId, pointCount, 0, (err, newItems) => {
          handleRenderPopup({ center: newCenter, items: newItems, map })
        })

        const flyingFactor = getFlyingFactor({ zoom: map.getZoom() })
        map.flyTo({ center: [newCenter[0], newCenter[1] - flyingFactor] })
      })

      map.on('click', 'point', (e) => {
        const newCenter = e.features[0].geometry.coordinates.slice()
        handleRenderPopup({ center: newCenter, items: [e.features[0]], map })
        const flyingFactor = getFlyingFactor({ zoom: map.getZoom() })
        map.flyTo({ center: [newCenter[0], newCenter[1] - flyingFactor] })
      })

      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: { enableHighAccuracy: true },
          trackUserLocation: true
        })
      )
    })
  }

  render() {
    return (
      <div style={S.Heatmap} ref={(e) => { this.heatmap = e }} />
    )
  }
}
Heatmap.defaultProps = {
  items: [],
  style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
  zoom: 8,
  center: [6, 52]
}
export default Heatmap
