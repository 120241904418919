import React from "react";

import { Space, Typography } from "antd";

import * as S from "./styles";

export default ({ value, onChange, label, ...restSwitchProps }) => (
  <Space align="center">
    <S.Toggle
      size={"small"}
      checked={value}
      onChange={onChange}
      {...restSwitchProps}
    />
    { label && (
      <Typography.Text>
        {label}
      </Typography.Text>
    )}
  </Space>
)
