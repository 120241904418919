import React, { useMemo, useRef, useState } from 'react'
import { FormCard } from 'components/dist/Authentication/styles'
import { TextCenter } from '../components/UI/styles/text'
import { Button, Form } from "components"
import { setCustomEnv, showMessage } from '../utils/appHelper'
import { FlexRow, Gutter } from 'components'
import { ping } from '../providers/ApiProvider/misc'
import { Space } from 'antd'
import { logout } from '../providers/ReduxProvider/actions/userActions';
import { connect } from 'react-redux'
import { getEnvConfig } from '../constants/globalConfiguration'
import { __ } from '../utils/translationUtils'


export const CustomSystemEnv = (props) => {

  const { logout } = props

  const formRef = useRef(undefined)
  const initialValues = useMemo(() => {
    const env = getEnvConfig()
    return { api: env.API_URL }
  }, [])

  return (
    <FormCard>
      <TextCenter><h3>Custom System Env</h3></TextCenter>
      <FlexRow>
        <div style={{ flex: 1 }}>
          <Form
            formRef={formRef}
            key={`key-sign-up-form`}
            layout="vertical"
            style={{ submitWrapper: { display: 'none' } }}
            inputs={[
              {
                name: 'api',
                type: 'input',
                placeholder: 'Enter a valid API URL...',
              }
            ]}
            initialValues={initialValues}
            onSubmit={(values) => {
              ping(`${values.api}/v1.0`).then((res) => {
                if (values.api !== window.__env__.API_URL) {
                  showMessage('success', <Space direction="vertical" size={2}>System switched successfully</Space>)

                  logout(() => {
                    setCustomEnv(values.api)
                    window.location.reload()
                  })
                } else {
                  showMessage('warning', <Space direction="vertical" size={2}>System already set</Space>)
                }


              }).catch((err) => {
                showMessage('error', <Space direction="vertical" size={2}><strong>Error:</strong>unknown system</Space>)
              })
            }}
          />
        </div>

        <Gutter left>
          <Button
            title={__('set')}
            type='filled'
            onClick={() => { formRef.current?.submit() }}
          />
        </Gutter>
      </FlexRow>
    </FormCard>
  )
}

export default connect(null, { logout })(CustomSystemEnv);
