const settings = {
  // SETTINGS
  logoAddedCorrectly: 'logo added correctly',
  logoRemovedCorrectly: 'logo removed correctly',
  reports: 'reports',
  pinnedViews: 'pinned views',
  pinnedReports: 'pinned reports',
  pinnedDashboards: 'pinned_dashboards',
  selectDashboards: "select_dashboards",
  configPinnedDashboards: 'configure_pinned_dashboards',
  showOnlyPinned: 'show_only_pinned',
  openAttributesSelection: 'open attributes selection',
  dashboardName: 'dashboard name',
  loginPage: 'login_page',
  autoLogin: 'auto_login',
  autoLoginDisabledInfo: 'auto_login_disabled_info',
  messagePage: 'message_page',

  // SETTINGS - PANELS
  userSelfRegistration: 'user self registration',
  userSelfRegistrationSub: 'Choose settings for the self sign-up of your users, including user-groups, roles and allowed email domains',
  widgetSettings: 'widget settings',
  widgetSettingsSub: 'select widget and dashboard settings',
  dashboardsConfiguration: 'dashboards configuration',
  dashboardsConfigurationSub: 'here you can pin your favourite dashboard',
  instanceConfigurations: 'instance system configurations',
  instanceConfigurationsSub: 'change instance system configurations such as the logo, page sizes, pinned views and pinned reports',

  // SETTINGS - SELF REGISTRATION
  selfRegistration: 'self registration',
  targetUserGroups: 'target user-groups',
  targetUserRoles: 'target user-roles',
  selectTargetGroups: 'select target entities / user groups',
  selectTargetGroupRoles: 'select target entities / user groups roles',
  allowedDomains: 'allowed domains',
  noEntryAddedYet: 'no entry added yet',
  emailVerification: 'email verification',
  registrationBehaviour: 'registration_behaviour',
  successMessage: 'success_message',
  checkYourEmailMessage: 'successful_sign_up_page_message_check_your_email',
  greetingsMessage: 'successful_sign_up_page_message_greetings',
  registrationFormFields: 'registration form fields',
}

export default settings
