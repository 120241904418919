import { onlyUnique } from "../../../../utils/misc";

const { COMPANY, ENTITY, USER } = global.constants.entityTypes

export default ({ selectedCompanies, companiesTree }) => selectedCompanies?.reduce((acc, val) => {
  const parsedVal = JSON.parse(val)
  const newUserIds = []

  if (companiesTree && parsedVal.type === COMPANY) {
    const selectedCompany = companiesTree.find((company) => JSON.parse(company.key).key === parsedVal.key)

    selectedCompany.children.forEach((entity) => {
      if (entity.children) {
        entity.children.forEach((user) => {
          newUserIds.push(JSON.parse(user.key).id)
        })
      }
    })
  }

  if (companiesTree && parsedVal.type === ENTITY) {
    const selectedCompany = companiesTree.find((e) => e.children.find((entity) => JSON.parse(entity.key).key === parsedVal.key))
    const selectedEntity = selectedCompany.children.find((entity) => JSON.parse(entity.key).key === parsedVal.key)
    selectedEntity.children.forEach((user) => {
      if (user) newUserIds.push(JSON.parse(user.key).id)
    })
  }
  if (parsedVal.type === USER) {
    newUserIds.push(parsedVal.id)
  }
  return [...acc, ...newUserIds]
}, []).filter(onlyUnique)
