import React from 'react';
import { Result, Modal } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';

import { InfoIcon } from './styles';

export default (props) => {
  const { title, status = "info", message, closable, countDown, onFinish } = props;

  const [open, setOpen] = React.useState(true);

  return (
    <Modal
      title={title}
      visible={open}
      footer={null}
      closable={!!closable}
      onCancel={() => { setOpen(false) }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <Result
        status={status}
        icon={status === "info" && <InfoIcon />}
        title={message}
        subTitle={countDown && <Countdown value={Date.now() + countDown} onFinish={() => {
          setOpen(false)
          onFinish?.()
        }} />}
      />
    </Modal>
  );
}
