const { WORKING_EVENT, NON_WORKING_EVENT, GODOO_EVENT } = global.constants.eventTypes

function getGroupedEvents({ events, selectedOrganisations, userEntities }) {
  return {
    workingEvents: events.filter((e) => e?.eventType === WORKING_EVENT && (userEntities ? selectedOrganisations.find((o) => o.id === e?.entity?.id) : true)),
    nonWorkingEvents: events.filter((e) => e?.eventType === NON_WORKING_EVENT && (userEntities ? selectedOrganisations.find((o) => o.id === e?.entity?.id) : true)),
    godooEvents: events.filter((e) => (e?.eventType === GODOO_EVENT)
    && (e?.orderData?.additionalDisplayData?.['100_58be96d00e823f552aa1a071']?.find((entity) => selectedOrganisations.some(o => o.entities.map(e => e.id).includes(entity.id)))
    || selectedOrganisations.some(o => o.entities.map(e => e.id).includes(e.entityId)) // for locally created appointments (in this case we have the entityId without difficult calculations)
    ))
  }
}

export default getGroupedEvents
