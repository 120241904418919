import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { showMessage } from '../utils/appHelper';
import TemplateRightsForm from './TemplateRightsForm';
import { Button, Modal } from 'react-bootstrap';
import { formValueSelector , change} from 'redux-form';
import { api } from '../providers/ApiProvider'
const loadingBar = require('nprogress');

class TemplateRightsModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rightsStructure: null,
      currentTemplateRights: null,
      templateRights: [],
      isLoading: false,
      availableTemplates: [],
      selectedTemplate: ''
    };
  }

  componentDidMount() {
    this.getRightsObject();
    this.getAvailableTemplates();
  }

  getRightsObject(){
    this.setState({isLoading: true});
    loadingBar.start();
    return api.get('rights/templates/'+ this.props.template.shortName + '/availableRights')
    .then(
      response => {
        this.setState({
          rightsStructure: response.data.addableRights, 
          currentTemplateRights: response.data.activeRights, 
          isLoading: false
        });
        loadingBar.done();
        this.setExistingRights();
      },
      () => {
        this.setState({isLoading: false});
        loadingBar.done();
      }
    );
  }

  getAvailableTemplates(){
    this.setState({isLoading: true});
    loadingBar.start();
    return api.get('rights/templates/')
    .then(
      response => {
        this.setState({availableTemplates: response.data, isLoading: false});
        loadingBar.done();
      },
      () => {
        this.setState({isLoading: false});
        loadingBar.done();
      }
    );
  }

  setExistingRights(){
    this.applyRights([]);
  }

  applyRights(rights){
    rights.forEach(param => {
      param.wildcards.forEach(wc => {
        // set value on wildcard selects
        this.props.change('templateRightsForm', param.resource+'_'+param.uri+'_'+param.method+'_'+wc.position, wc.name);
      });
    });
    showMessage('success', 'Template applied successfully.');
  }

  handleTemplateSelected(event){
    this.setState({
      selectedTemplate: event.target.value
    });
  }

  handleApplyTemplate(event){
    event.preventDefault();
    this.setState({isLoading: true});
    api.get('rights/templates/'+ this.state.selectedTemplate + '/rights')
    .then(response => {
        this.setState({ 
          templateRights: response.data, 
          isLoading: false});
        loadingBar.done();
        this.applyRights(this.state.templateRights);
      },
      () => {
        this.setState({isLoading: false});
        loadingBar.done();
      });
  }

  render() {  

    return (

      <Modal 
        backdrop="static"
        bsSize="lg"
        className="rights-list"
        show={this.props.open}
        onHide={this.props.closeModal}>

        <Modal.Header closeButton>
          <Modal.Title className="capitalize">Template Rights</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-xs-12">
              <form onSubmit={this.handleApplyTemplate.bind(this)} className="form-inline apply-template">
                <div className="form-group pull-right">
                  <label className="control-label">Apply  template:</label>
                  <select value={this.state.selectedTemplate} name="selectedTemplate" onChange={this.handleTemplateSelected.bind(this)} className="form-control">
                    <option value="" >Select a template...</option>
                    {
                     this.state.availableTemplates.map((template, k)=>{
                      if(template.shortName == this.props.template.shortName)
                        return null;
                      return <option key={'opt'+ k} value={template.shortName}>{template.name}</option>;
                      })
                    }
                  </select>
                  <button disabled={this.state.selectedTemplate == ''} className="btn btn-default btn-no-padding">Apply</button>
                </div>
              </form>
            </div>
          </div>
          
          {(this.state.rightsStructure)?
          <div className="row">
            <TemplateRightsForm  
            rightsStructure={this.state.rightsStructure}
            />
          </div>:<i>Loading rights structure...</i>
          }

        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.props.closeModal}>
            Ok
          </Button>
          {/*<button onClick={this.props.handleSetRights}
                  className={'btn btn-primary'}
                  >
            Set Template Rights
          </button>*/}
        </Modal.Footer>
      </Modal>
    );

  }

}

TemplateRightsModal.propTypes = {
  handleSetRights: PropTypes.func,
  modelValues: PropTypes.object,
  template: PropTypes.object,
  mode: PropTypes.string,
  closeModal: PropTypes.func,
  change: PropTypes.func,
  open: PropTypes.bool
};

const selector = formValueSelector('templateForm');
export default connect(
  state => {
    const values = selector(
      state, 
      'name', 
      'shortName', 
      'rights', 
    );
    return {
      modelValues: {...values}
    };

  },
  { change }
)(TemplateRightsModal);