export default ({ name, shareWith, widgetsLayout, shareWithEntities, editableBy, editableByEntities, widgetBag }) => ({
  name,
  shareWith: {
    type: shareWith,
    entityIds: shareWithEntities?.filter((e) => !e.includes(global.constants.WHITE_LABEL))
  },
  widgetsLayout,
  editableBy: {
    type: editableBy,
    entityIds: editableByEntities?.filter((e) => !e.includes(global.constants.WHITE_LABEL))
  },
  widgetIds: widgetBag?.map((e) => e?.id)
})
