import React, { useMemo } from 'react'
import {useROEConfiguratorContext} from "../../context";
import {Form, Select} from "antd";
import * as S from "../../styles";
import { __ } from '../../../../utils/translationUtils'

export default () => {

  const {
    state,
    setSystem,
    setObject
  } = useROEConfiguratorContext()

  const {
    attributesTree,
    system,
    object
  } = state || {}

  const [form] = Form.useForm();

  const systemOptions = useMemo(() => {
    return attributesTree.map((system) => ({
      value: system.name,
      label: system.label
    }))
  }, [attributesTree])

  const objectOptions = useMemo(() =>
    attributesTree.find((sys) => sys.name === system.id)?.data.map((obj) => ({
      value: obj.objectTypeId,
      label: obj.label
    })), [attributesTree, system])
  
  const handleValueChange = (value, values) => {
    if (value.system) {
      form.setFieldsValue({ object: null })
      setSystem(systemOptions.find((system) => system.value === value.system))
    }
    if (value.object) {
      setObject(objectOptions.find((object) => object.value === value.object))
    }
  }

  return (
    <S.SystemObjectSelectContainer>
      <S.StepItemContent size={'giga'} weight={'large'} expansion={'50%'}>
        {__('system_object_selection')}
      </S.StepItemContent>
      <S.SystemObjectForm
        layout='vertical'
        initialValues={{
          system: system.id,
          object: object.id
        }}
        onValuesChange={handleValueChange}
        form={form}
      >
        <Form.Item label={__('select_system')} name='system'>
          <Select
            showSearch
            options={systemOptions}
            placeholder={__('select_one')}
            filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
        <Form.Item label={__('select_object')} name='object'>
          <Select
            showSearch
            options={objectOptions}
            disabled={!system.id}
            placeholder={__('select_one')}
            filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
      </S.SystemObjectForm>
    </S.SystemObjectSelectContainer>
  )
}
