import React from 'react';

import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import _ from 'lodash';
import {DropdownButton, Glyphicon, MenuItem, Modal, Panel,} from 'react-bootstrap';
import {PlusOutlined} from '@ant-design/icons';
import moment from "moment";

import CreateStepObjectButton from '../../../../../../CreateStepObjectButton';
import ReportSearchFieldsForm from '../../../../../../ReportSearchFieldsForm';
import {
  createRemoteObjectItem,
  updateRemoteObjectItem
} from '../../../../../../../providers/ReduxProvider/actions/remoteObjectActions';
import {
  addAsterisks,
  getInitialPageSize,
  renderResultInfo,
  setInitialPageSize,
} from '../../../../../../../utils/appHelper';
import * as config from '../../../../../../../constants/globalConfiguration';
import {api} from '../../../../../../../providers/ApiProvider'
import {EllipsisText} from 'components/dist/Typography';
import * as S from "../styles";
import {addRelationsROE} from '../../../../../../../providers/ApiProvider/remoteObjects';
import {__} from '../../../../../../../utils/translationUtils'

class ItemSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      added: [],
      selected: props.selectedObjectIds,
      selectedRows: props.selectedObjectIds,
      createdObjectsMap: {},
      results: null,
      searchOpen: false,
      showCreateForm: false,
      sortAttributes: [],
      pagination: {
        'pageNumber': 1,
        'pageSize': getInitialPageSize('addStepObjectList'),
        'totalCount': 0
      },
      modal: {
        open: false,
      },
      isLoading: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.handleSingleSelectRow = this.handleSingleSelectRow.bind(this);
    this.getResult = this.getResult.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.extractAttributeConfig = this.extractAttributeConfig.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.setPageSize = this.setPageSize.bind(this);
    this.updatedSelectedIds = this.updatedSelectedIds.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      selected: props.selectedObjectIds,
    }
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.setPageSize.bind(this)}
        bsStyle="link"
        title={this.state.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config.pageSizeOptionsObjectList.map((val, i) => {
          return <MenuItem key={i} eventKey={val}>{val}</MenuItem>;
        })}
      </DropdownButton>
    );
  }

  onPageChange(page) {
    let spec = {...this.state.reportSpec};
    spec.pageNumber = page;

    this.setState({
      pagination: {
        ...this.state.pagination,
        pageNumber: page
      }
    }, () => {
      this.getResult();
    });
  }

  onCreateSubmit(id, values) {
    this.updatedSelectedIds(id, true);

    const newObj = {
      id: id,
      accessRightsInfo: {"read": true, "edit": true, "delete": true},
      displayData: values
    }

    this.setState({
      createdObjectsMap: {...this.state.createdObjectsMap, [id]: newObj},
      results: [newObj, ...this.state.results]
    })

  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortAttributes: [{
        attributeId: sortName,
        position: 1,
        value: sortOrder.toUpperCase(),
      }]
    }, () => {
      this.getResult()
    })
  }

  setPageSize(val) {
    this.setState({
        pagination: {
          ...this.state.pagination,
          pageSize: val,
          pageNumber: 1
        }
      }, () => {
        this.getResult();
        setInitialPageSize('addStepObjectList', val);
      }
    );
  }

  openModal() {
    const {singleRow} = this.props
    this.getResult();
    this.setState({
      selectedRows: singleRow ? this.state.selected : [],
      showCreateForm: false,
      modal: {
        open: true,
      }
    });
  }

  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({
      createdObjectsMap: {},
      modal: {
        open: false,
      }
    });
  }

  getResult() {
    const {configuration, formValues, type, mappedBy, fetchMappedBy} = this.props;
    const {sortAttributes} = this.state


    this.setState({
      results: null,
      isLoading: true,
    }, () => {
      let payload = {...configuration, ...(sortAttributes.length ? {sortAttributes} : {})};
      payload.pageSize = this.state.pagination.pageSize;
      payload.pageNumber = this.state.pagination.pageNumber;


      const _values = formValues;
      let values = {..._values};
      const _attrs = [].concat(configuration.searchAttributes);
      let attrs = [];
      _attrs.forEach((_r) => {
        const r = {..._r};
        const conf = this.extractAttributeConfig(_r.attributeId);

        // RANGE
        if (r.mechanism === 'RANGE') {
          if (conf.type === 'DATE') {
            if (values[r.attributeId + 'From']) {
              values[r.attributeId + 'From'] = values[r.attributeId + 'From'].utc().format();
            }
            if (values[r.attributeId + 'To']) {
              values[r.attributeId + 'To'] = values[r.attributeId + 'To'].utc().format();
            }
          }

          if (typeof values[r.attributeId + 'From'] !== 'undefined') {
            r.from = values[r.attributeId + 'From'];
          } else {
            delete values[r.attributeId + 'From'];
          }

          if (typeof values[r.attributeId + 'To'] !== 'undefined') {
            r.to = values[r.attributeId + 'To'];
          } else {
            delete values[r.attributeId + 'To'];
          }

          /*
           * Apply Felix's Algorithm on range fields
           * 1)If both *from* and *to* are filled --> normal *range* search.
           * 2) If only *from* is filled --> *specic_value* search. (the from can contain wildcard)
           * 3) if only *to* is filled the webui fills the from value with the same value contained in the *to* --> *range* search (edited)
           */

          // Applies only to RANGE.
          if (r.mechanism === 'RANGE') {
            const {to, from} = r;

            if (from && !to) {
              r.mechanism = 'SPECIFIC_VALUE';
              r.value = from;
              delete r.from;
              delete r.to;
              delete r.multiSelect;
            } else if (!from && to) {
              r.from = to;
            }
          }

          // SPECIFIC_VALUE
        } else if (r.mechanism === 'SPECIFIC_VALUE') {
          if (conf.type === 'DATE' && values[r.attributeId]) {
            values[r.attributeId] = values[r.attributeId].utc().format();
          }

          if (typeof values !== 'undefined' && typeof values[r.attributeId] !== 'undefined' && values[r.attributeId] !== '') {
            // Add asterisks by default
            if (conf.type === 'DATE') {
              r.value = values[r.attributeId];
            } else {
              r.value = addAsterisks(values[r.attributeId]);
            }

            if (r.value === 'true') {
              r.value = true;
            }
            if (r.value === 'false') {
              r.value = false;
            }
          } else {
            delete r.value;
          }
          // ENUMERATION
        } else if (r.mechanism === 'ENUMERATION') {
          const requiresobfuscation = (r.obfuscationMethod != null && r.obfuscationValue != null);
          let _value = [].concat(values[r.attributeId]);
          if (typeof values[r.attributeId] !== 'undefined' && values[r.attributeId].length > 0) {
            // If obfuscationMethod == byTypeEqualsUnionChildren, then select children entities too
            if (requiresobfuscation) {
              switch (r.obfuscationMethod) {
                case 'byTypeEqualsUnionChildren':
                  _value.forEach((parent) => {
                    const found = _.filter(r.values, (v) => v.parent === parent);
                    if (found) {
                      found.forEach((child) => {
                        _value.push(child.id);
                      });
                    }
                  });
                  break;
              }
            }

            r.value = _value;

            if (_value.constructor === Array) {
              r.value = _value.join(',');
            }
          } else {
            delete r.value;
          }

        }

        if ((values[r.attributeId] == undefined || values[r.attributeId] === '')
          && (values[`${r.attributeId}From`] == undefined || values[`${r.attributeId}From`] === '')
          && (values[`${r.attributeId}To`] == undefined || values[`${r.attributeId}To`] === '')
        ) {
          return
        } else {
          attrs.push(r);
        }

        // remove if empty value
        if (values[r.attributeId] != undefined && values[r.attributeId]?.length === 0) {
          //  if (typeof values[r.fieldId] !== 'undefined' && values[r.fieldId].length === 0) {
          //    const idx = findIndex(attrs, (f) => f.fieldId === r.fieldId);
          const idx = _.findIndex(attrs, (f) => f.attributeId === r.attributeId);
          if (idx > -1) {
            attrs.splice(idx, 1);
          }
        }
      });
      payload.searchAttributes = attrs;
      api.post(`configurations/${(!['ROE'].includes(type)) ? 'stepObjects' : 'remoteObjects'}/results`, payload)
        .then(async (res) => {
            const {pageSize, pageNumber: currentPage} = this.state.pagination
            const newSelected = mappedBy ? (await fetchMappedBy(res.data.map(({id}) => id), {
              pageSize,
              pageNumber: currentPage
            })).map(({id}) => id) : this.state.selected;
            this.setState({
              isLoading: false,
              selected: newSelected,
              selectedRows: [...newSelected, ...(this.state.selectedRows || [])],
              results: this.fixResultValues(res.data),
              searchOpen: false,
              pagination: {
                ...this.state.pagination,
                totalCount: parseInt(res.headers['x-total-count']),
                pageSize: parseInt(res.headers['x-page-size']),
                pageNumber: parseInt(res.headers['x-page'])
              }
            });
          }
        );
    });
  }

  fixResultValues(data) {
    const {attributes, configuration} = this.props;

    const createdObjects = [...Object.values(this.state.createdObjectsMap)];
    const dataWithoutCreatedObjects = [];

    data.forEach((part) => {
      if (part.displayData) {
        Object.keys(part.displayData).forEach(function (attributeId) {

          let attribute = (attributes.filter(a => a.id === attributeId));
          if (attribute && attribute.length > 0)
            attribute = attribute[0];

          let displayAttribute = _.find(configuration.displayAttributes, (a) => a.attributeId === attributeId);

          if (this[attributeId] !== null && attribute.type === "YESNO") {
            this[attributeId] = this[attributeId].toLowerCase() === 'true' ? __('val_yes') : __('val_no');
          }
          if (displayAttribute.controllerType === "PopupListBox" || displayAttribute.defaultControllerType === "PopupListBox") {
            this[attributeId] = this[attributeId] !== null ? __(this[attributeId]) : this[attributeId];
          }
          if (displayAttribute.controllerType === "DateInput") {
            this[attributeId] = this[attributeId] !== null ? moment(this[attributeId]).format(config.appDefaults.dateFormat) : this[attributeId];
          }
          if (displayAttribute.controllerType === "DateTimeInput") {
            this[attributeId] = this[attributeId] !== null ? moment(this[attributeId]).format(config.appDefaults.dateTimeFormat) : this[attributeId];
          }
        }, part.displayData);
      }

      if (!this.state.createdObjectsMap[part.id]) {
        dataWithoutCreatedObjects.push(part);
      }
    }, data);

    return createdObjects.concat(dataWithoutCreatedObjects)
  }

  handleSubmit() {
    const {mappedBy, parentObjectId, parentObjectTypeId, attributeId} = this.props
    const {selectedRows, added} = this.state
    if (mappedBy) {
      if (parentObjectId && parentObjectTypeId && attributeId && added.length > 0) {
        addRelationsROE({objectTypeId: parentObjectTypeId, mainKey: parentObjectId}, {
          childObject: attributeId,
          childObjectIds: added
        }).then(() => {
          this.props.refreshData()
          this.setState({added: []})
        })
      }
    } else {
      this.props.handleAddItems(selectedRows)
    }
    this.closeModal()
  }

  handleRowSelect(row, isSelected) {
    this.updatedSelectedIds(row.id, isSelected);
  }

  updatedSelectedIds(id, isSelected) {
    let _selected = [].concat(this.state.selectedRows);

    if (isSelected) {
      if (!_selected.includes(id)) {
        _selected.push(id);

        this.setState({
          added: [...this.state.added, id],
          selectedRows: _selected,
        });
      }
    } else {
      this.setState({
        added: _.remove(this.state.added, (item) => item !== id),
        selectedRows: _.remove(_selected, (item) => item !== id),
      });
    }
  }

  extractAttributeConfig(id) {
    const {attributesConfiguration} = this.props;
    return _.find(attributesConfiguration, (a) => a.id === id);
  }

  handleSingleSelectRow(row, isSelected) {

    let _selected = [].concat(this.state.selectedRows);

    if (isSelected) {
      if (!_selected.includes(row.id)) {
        _selected = [row.id];

        this.setState({
          added: _selected,
          selectedRows: _selected,
        });
      }
    } else {
      this.setState({
        added: _.remove(this.state.added, (item) => item !== row.Arrayid),
        selectedRows: _.remove(_selected, (item) => item !== row.id),
      });
    }
  }

  render() {
    const {
      isLoading,
      results,
      showCreateForm,
      pagination,
      searchOpen,
      selected,
      selectedRows,
    } = this.state;

    const {attributesConfiguration} = this.props

    const {
      configuration,
      attributes,
      values,
      guiUser,
      singleRow
    } = this.props;

    const onSelectAll = (isSelected, selectedRows) => {
      if (isSelected) {
        this.setState({selectedRows: [...this.state.selectedRows, ...selectedRows.map(i => i.id)]});
      } else {
        this.setState({selectedRows: this.state.selectedRows.filter(i => !selectedRows.map(i => i.id).includes(i))});
      }

      return isSelected ? selectedRows.map(i => i.id) : [];
    };

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      unselectable: selected,
      selected: _.union(selectedRows, selected),
      onSelectAll,
      onSelect: this.handleRowSelect,
    };

    const singleSelectRow = {
      mode: 'radio',
      onSelect: this.handleSingleSelectRow,
      clickToSelect: true,
      selected: selectedRows,
    }

    const shouldShowColumn = (attributeConfig) => {
      const found = _.find(configuration.displayAttributes, (a) => a.attributeId === attributeConfig.id);
      return (found && found.summary && attributeConfig.displayable);
    };

    const searchPanelHeader = (
      <div
        onClick={() => {
          this.setState({searchOpen: !searchOpen});
        }}
      >
        <Glyphicon className="pull-right" glyph={searchOpen ? 'chevron-up' : 'chevron-down'}/>
        {__('SetSearchParameters')}
      </div>
    );

    if (!configuration) return null

    return (
      <>
        <S.CustomButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.openModal();
          }}
        >
          <PlusOutlined/>
          {__('Add Item')}
        </S.CustomButton>
        {this.state.modal.open &&
          <Modal
            backdrop="static"
            show={this.state.modal.open}
            onHide={this.closeModal}
            dialogClassName="modal-90W"
          >
            <Modal.Header closeButton>
              <Modal.Title className="capitalize">{configuration.name} - {__('Add Item')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'add-items-modal-body'}>
              <Panel
                expanded={searchOpen}
                className="sort-attribues-panel panel--simple"
              >
                <Panel.Heading>{searchPanelHeader}</Panel.Heading>
                <Panel.Body collapsible>
                  <ReportSearchFieldsForm
                    isLoading={isLoading}
                    guiUser={guiUser}
                    reportSpec={configuration}
                    fields={configuration.searchAttributes}
                    attributesConfiguration={attributesConfiguration}
                    sortAttributes={configuration.sortAttributes}
                    extractConfiguration={this.extractAttributeConfig}
                    initialValues={{}}
                    enumValues={{}}
                    onSubmit={this.getResult}
                    buttonSize="default"
                    setShowAllOrders={() => {
                    }}
                  />
                </Panel.Body>
              </Panel>

              {isLoading && __('loading')}

              {!isLoading &&
                <CreateStepObjectButton
                  action="create"
                  configuration={configuration}
                  onCreate={(id, values) => {
                    this.onCreateSubmit(id, values);
                  }}
                  attributesConfiguration={attributesConfiguration}
                  className="btn btn-primary gutter-bottom"
                  remoteObjectSpec={configuration}
                  attributes={attributes}
                  values={values}
                />
              }

              {!showCreateForm &&
                <div className="table-wrapper">
                  <BootstrapTable
                    data={results || []}
                    selectRow={singleRow ? singleSelectRow : selectRow}
                    ref="stepObjectsTable"
                    remote
                    pagination
                    hover
                    fetchInfo={{dataTotalSize: pagination.totalCount}}
                    trStyle={(row) => {
                      if (!row) return

                      if (this.state.createdObjectsMap[row.id]) {
                        return {backgroundColor: "rgb(158 245 194)"};
                      }

                      if (this.state.selectedRows.includes(row.id)) {
                        return {backgroundColor: "#dce5ff"};
                      }
                    }}
                    striped
                    options={{
                      onPageChange: this.onPageChange,
                      onSortChange: this.onSortChange,
                      noDataText: isLoading ? __('loading_data') : __('NoDataFound'),
                      hideSizePerPage: false,
                      firstPage: 'First',
                      lastPage: 'Last',
                      sizePerPageList: config.pageSizeOptionsObjectList,
                      paginationShowsTotal: renderResultInfo.bind(this),
                      page: pagination.pageNumber,
                      sizePerPage: pagination.pageSize,
                    }}
                  >
                    <TableHeaderColumn
                      key={`table-column-id`}
                      isKey
                      dataField="id"
                      hidden={true}
                    >
                      {__("Id")}
                    </TableHeaderColumn>
                    {
                      configuration?.displayAttributes?.filter(da => da.summary).map((displayAttribute) => {
                        const attrId = displayAttribute.attributeId;
                        const attributeConfig = this.extractAttributeConfig(attrId);

                        if (!shouldShowColumn(attributeConfig) || !attributeConfig || !attributeConfig.displayable) {
                          return;
                        }

                        return (
                          <TableHeaderColumn
                            key={`table-column-${attrId}`}
                            dataField={attrId}
                            dataSort
                            dataFormat={(cell, row) => {
                              let value = row.displayData[attrId]?.value || row.displayData[attrId];
                              return (
                                <div>
                                  <EllipsisText>
                                    {(_.isArray(value) || _.isObject(value)) ? JSON.stringify(value) : value}
                                  </EllipsisText>
                                </div>
                              )
                            }
                            }
                          >
                            {__(attributeConfig.propertyLabel)}
                          </TableHeaderColumn>

                        );
                      })
                    }
                  </BootstrapTable>
                </div>
              }
            </Modal.Body>
            <Modal.Footer>
              <div className="group">
                <button className="btn btn-default pull-left" onClick={this.closeModal}>
                  {__('Cancel')}
                </button>

                <button
                  className="btn pull-right btn-primary"
                  disabled={this.state.isLoading || selectedRows.length === 0}
                  size="large"
                  onClick={this.handleSubmit}
                >
                  {__('Add Selected')}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        }
      </>
    );
  }
}

export default connect(
  state => {
    return {
      guiUser: state.user.data,
      formValues: getFormValues('reportSearchFieldsForm')(state),
    };
  },
  {updateRemoteObjectItem, createRemoteObjectItem}
)(ItemSelection);
