import React, {useEffect, useMemo, useState} from "react";
import {DeleteOutlined} from "@ant-design/icons";

import ContextProvider, {useObjectConfiguratorContext} from "./context";
import TableSection from "./components/TableSection";
import * as C from "./constants";
import {actionType} from "../../../../UI/Table/constants";

import {isEmpty} from 'lodash'
import {errorLevel} from "components/dist/FormBuilder/Components/UI/Grid/utils";
import {getAttributeFields} from "./utils";
import SelectedItems from "./components/SelectedItems";

const ObjectSelector = (props) => {
  const {state} = useObjectConfiguratorContext()

  const {label} = props

  const {
    value,
    attributesTree,
    attributesList,
  } = state || {}

  const {
    objectConfiguration,
  } = value || {}

  const [selectionModalOpen, setSelectionModalOpen] = useState(false)
  const attributeFields = useMemo(() => (getAttributeFields(objectConfiguration, attributesTree)), [objectConfiguration, attributesTree])

  const {manualSelection, addableEntries, deletableEntries, selectionMode} = objectConfiguration || {}

  const selectionModeOn = manualSelection || addableEntries

  return (objectConfiguration && attributesList?.length) ? (
    <div>
      {label}
      <SelectedItems
        toggleSelectionModal={(open) => setSelectionModalOpen(open)}
        attributeFields={attributeFields}
        rowActions={[
          actionType.VIEW_DETAILS,
          ...(deletableEntries ? [{
            value: 'DELETE',
            icon: <DeleteOutlined/>,
            onClick: () => console.log("delete unavailable"),
            danger: true,
            disabled: true,
            label: 'Delete',
          }] : []),
        ]}
      />
      <TableSection
        wrapperProps={{
          visible: selectionModalOpen,
          onCancel: () => setSelectionModalOpen(false),
          type: C.wrapperType.MODAL,
        }}
        wrapperType={selectionModeOn ? C.wrapperType.DRAWER : C.wrapperType.MODAL}
        selectionMode={selectionModeOn && C.selectionMode[selectionMode]}
        attributeFields={attributeFields}
        rowActions={[
          actionType.VIEW_DETAILS,
          ...(deletableEntries ? [{
            value: 'DELETE',
            icon: <DeleteOutlined/>,
            onClick: () => console.log("delete unavailable"),
            danger: true,
            disabled: true,
            label: 'Delete',
          }] : []),
        ]}
      />
    </div>
  ) : null
}

export const objectSelectorFieldValidation = (options) => ([
  {
    message: {
      value: 'This field is required',
      visible: errorLevel.BLUR
    },
    validator: (_, value) => {
      if (options?.required && (!value || !value.selectedItems || isEmpty(value.selectedItems)))
        return Promise.reject()
      return Promise.resolve()
    },
  }
])

export default (props) => {
  const {
    value = {},
    onChange,
    label,
    readOnly,
    readFrom,
    writeTo,
    missingConfigurationMessage,
    api,
    type
  } = props

  const [v, setV] = useState(value)

  useEffect(() => {
    setV(value)
  }, [value]);

  const handleChange = (val) => {
    setV(val)
    onChange?.(val)
  }
  const selectionMode = useMemo(() => (v?.objectConfiguration?.selectionMode || (type?.includes('Binder') ? 'SINGLE' : 'MULTI')), [])

  return (
    <ContextProvider
      api={api}
      readOnly={readOnly}
      value={{
        ...v,
        objectConfiguration: {
          ...v?.objectConfiguration,
          selectionMode
        }
      }}
      onChange={handleChange}
      missingConfigurationMessage={missingConfigurationMessage}
      readFrom={readFrom}
      writeTo={writeTo}
    >
      <ObjectSelector
        label={label}
      />
    </ContextProvider>
  )
}
