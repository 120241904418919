import React, {useEffect, useMemo, useState} from 'react'
import {find, isEmpty} from 'lodash'
import {Space, Tooltip as AntdTooltip} from "antd";

import {
  checkUserRights,
  convertArrayToObject,
  fetchEnumById,
  fetchRemoteObjectConfiguration,
  fetchRemoteObjectEntries,
  renderCAlgorithm,
} from '../../utils/appHelper'

import TableFilters from "../../components/UI/TableFilters";
import CreateInstance from "../../components/RemoteObjectInstances/CreateInstance";
import {ExportModal, RemoteObjectTable} from "../../components/RemoteObjectInstances";
import {ActionOptions, Progress} from "../../components/UI";
import {DownloadOutlined, RedoOutlined} from "@ant-design/icons";
import useProgress from "../../hooks/useProgress";
import {getColumnSort} from "../../utils/tableUtils";
import {ERROR} from "components/dist/Utils/LoggerUtils";
import {getSearchAttributes, getSearchFields} from "./utils";
import * as S from "../../providers/StyleProvider/styles";
import {IconButton, ScrollableListView, SettingsContainer} from "../../providers/StyleProvider/styles";
import * as config from "../../constants/globalConfiguration";
import {hashHistory} from "../../providers/HistoryProvider";
import {parse} from "query-string";
import {useSelector} from "react-redux";
import {availableExportOptions} from '../../components/RemoteObjectInstances/ExportModal/contants';
import {__} from '../../utils/translationUtils'
import {UseAttributesMapQuery, UseAttributesQuery} from "../../providers/QueryClientProvider/queries";
import {setLastRemoteObjSearchActionCreator} from "../../providers/ReduxProvider/actions/userActions";
import {lastSearchSelector} from "../../providers/ReduxProvider/reducers/uiReducer";
import useCache from "../../hooks/useCache";
import {
  countMissingRequiredSearchAttributes,
  SearchAttributeInverseMapping
} from "../../components/UI/TableFilters/utils";
import {getSummaryAttributesItems} from "../../utils/attributeUtils";
import ExportColumnsModal from "../../components/RemoteObjectInstances/ExportColumnsModal";

const {rightMappings} = global.constants

const RemoteObjectInstances = (props) => {
  const {data: attributeList} = UseAttributesQuery()
  const {data: attributesMap} = UseAttributesMapQuery()
  const {params} = props.match

  const progress = useProgress(21)
  const [isLoading, setIsLoading] = useState(true);
  const guiUser = useSelector((state) => state?.user.data)

  const [remoteObjectConfiguration, setRemoteObjectConfiguration] = useState(null);
  const [ROTableData, setROTableData] = useState(null);

  const [exportOption, setExportOption] = useState(undefined)
  const [exportColumns, setExportColumns] = useState(undefined)

  const [currentSearch, setCurrentSearch] = useState({});
  const [currentSort, setCurrentSort] = useState([]);

  const summaryDisplayAttributesItems = useMemo(() => getSummaryAttributesItems(remoteObjectConfiguration?.displayAttributes, attributesMap), [remoteObjectConfiguration, attributesMap])
  const [visibleColumns, setVisibleColumns] = useState([])

  const [currentSortCol, setCurrentSortCol] = useState(null);
  const [missingRequiredSearchAttributes, setMissingRequiredSearchAttributes] = useState(null);
  const [currentPagination, setCurrentPagination] = useState({
    pageNumber: 1,
    pageSize: 25,
    totalCount: 0
  });
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [sortModalOpen, setSortModalOpen] = useState(false)
  const [enumValues, setEnumValues] = useState([]);

  const {loading: cacheLoading, data: lastCached, saveCache} = useCache(params.id, lastSearchSelector)

  useEffect(() => {
    fetchRemoteObjectConfiguration(params.id)
      .then(({data: remoteObjConfiguration}) => {
        setRemoteObjectConfiguration(remoteObjConfiguration)
        const requiredSearchAttributes = countMissingRequiredSearchAttributes(
          getSearchFields(remoteObjConfiguration.searchAttributes), remoteObjConfiguration?.searchAttributes
        )
        setMissingRequiredSearchAttributes(requiredSearchAttributes)
        setSearchModalOpen(!!requiredSearchAttributes)
      })
      .catch(error => {
        if (error.request && error.request.status === 404) {
          const queryString = parse(props.location.search)
          hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
        }
      })
  }, [params.id])

  useEffect(() => {
    const fetchData = async () => {
      const enumsToGetValues = []
      if (!isEmpty(attributeList) && !isEmpty(remoteObjectConfiguration) && !cacheLoading) {

        let searchAttributes = remoteObjectConfiguration.searchAttributes
        let sortAttributes = remoteObjectConfiguration.sortAttributes
        let displayAttributes = remoteObjectConfiguration.displayAttributes
        let lastPagination

        if (lastCached?.payload) {
          try {
            const payloadObj = JSON.parse(lastCached.payload)
            const saMap = convertArrayToObject(payloadObj?.searchAttributes, 'attributeId')

            searchAttributes = searchAttributes.map((sa) => {
              const savedSA = saMap[sa.attributeId]
              return savedSA ? SearchAttributeInverseMapping(savedSA) : sa
            })

            sortAttributes = remoteObjectConfiguration.sortAttributes.map((sortAttr) => {
              const sa = payloadObj?.sortAttributes?.find(({attributeId}) => sortAttr?.attributeId === attributeId)
              return sa ? sortAttr : {...sortAttr, enabled: false}
            })

            displayAttributes = payloadObj.displayAttributes

            lastPagination = {
              pageSize: payloadObj.pageSize,
              pageNumber: payloadObj.pageNumber,
            }
          } catch (e) {
            ERROR(e)
          }
        }

        searchAttributes.forEach((attribute) => {
          const searchAttribute_attribute = find(attributeList, (c) => c.id === attribute.attributeId)
          if (attribute.mechanism === 'ENUMERATION' && searchAttribute_attribute.enumerationId) {
            const enumId = searchAttribute_attribute.enumerationId
            enumsToGetValues.push(enumId)
          }
        })

        if (enumsToGetValues.length > 0) {
          enumsToGetValues.forEach((enumId) => {
            fetchEnumById(enumId).then((enumerator) => {
              setEnumValues((prevValue) => [...prevValue, enumerator])
            })
          })
        }

        const search = getSearchFields(searchAttributes)
        const columns = getSummaryAttributesItems(displayAttributes, attributesMap).map((a) => (a.value))

        setCurrentSearch(search)
        setCurrentSort(sortAttributes)
        setVisibleColumns(columns)

        submitSearch({
          search,
          sort: sortAttributes,
          columns: columns,
          pagination: {
            ...currentPagination,
            pageSize: lastPagination?.pageSize || remoteObjectConfiguration?.pageSize || currentPagination.pageSize,
            pageNumber: lastPagination?.pageNumber || remoteObjectConfiguration?.pageNumber || currentPagination.pageNumber
          }
        })
      }
    }
    fetchData()
  }, [remoteObjectConfiguration, attributeList, cacheLoading])


  const submitSearch = ({search, sort, columns, pagination, updateSort = true, updateData = true}) => {
    if (!search || !sort || !pagination || !columns?.length) {
      ERROR("One of the following is null. Search: ", search, " sort: ", sort, " pagination: ", pagination)
      return
    }

    if (missingRequiredSearchAttributes > 0) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    const searchAttributes = getSearchAttributes(search, remoteObjectConfiguration, attributesMap)
    const payload = {
      id: remoteObjectConfiguration.id,
      name: remoteObjectConfiguration.name,
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber,
      onlySelfOrders: remoteObjectConfiguration.onlySelfOrders,
      displayAttributes: remoteObjectConfiguration.displayAttributes.filter(({attributeId}) => columns.includes(attributeId)),
      sortAttributes: sort.filter(({enabled}) => enabled == null || enabled),
      searchAttributes,
      settings: remoteObjectConfiguration?.settings,
      objectTypeId: remoteObjectConfiguration.objectTypeId,
      systemId: remoteObjectConfiguration.systemId,
    }
    if (!missingRequiredSearchAttributes) {
      fetchRemoteObjectEntries(payload)
        .then((response) => {
          saveCache(setLastRemoteObjSearchActionCreator({
            cacheId: response?.headers?.["x-saved-request-id"],
            id: remoteObjectConfiguration.id
          }))

          setVisibleColumns(columns)
          setCurrentSearch(search)
          setCurrentPagination({
            ...pagination,
            totalCount: parseInt(response.headers['x-total-count']),
            pageSize: parseInt(response.headers['x-page-size']),
            pageNumber: parseInt(response.headers['x-page'])
          })
          if (updateSort) {
            setCurrentSort(sort)
          }

          if (updateData) {
            const daMap = convertArrayToObject(remoteObjectConfiguration?.displayAttributes, 'attributeId')
            setROTableData(response.data.map((row) => {
              const {id, accessRightsInfo, displayData} = row
              return Object.entries(displayData).reduce((acc, [attributeId, value]) => {
                acc[attributeId] = renderCAlgorithm(value, row, attributesMap[attributeId], daMap[attributeId])
                return acc
              }, {id, accessRightsInfo})
            }))
          }
          setIsLoading(false)
        })
    } else
      setIsLoading(false)
  }

  const handleSubmitSearch = () => {
    submitSearch({
      search: currentSearch,
      sort: currentSort,
      columns: visibleColumns,
      pagination: currentPagination
    })
    setSortModalOpen(false)
  }

  const handlePaginationChange = ({pagination}) => {
    submitSearch({
        search: currentSearch,
        sort: currentSort,
        columns: visibleColumns,
        pagination: {...currentPagination, ...pagination},
      })
  }

  const onSortColumnChange = (sortName, sortOrder) => {
    const sort = getColumnSort(sortName, sortOrder, currentSortCol)

    submitSearch({
        search: currentSearch,
        sort,
        columns: visibleColumns,
        pagination: {...currentPagination},
        updateSort: false
      })
    setCurrentSortCol(sort)
  }

  const exportModalOpened = useMemo(() => {
    return exportOption &&
      (
        (exportOption !== availableExportOptions.onlyExports && !!exportColumns) ||
        exportOption === availableExportOptions.onlyExports
      )
  }, [exportOption, exportColumns])

  return (
    <ScrollableListView id="main-content">
      {!!isLoading && <S.OverlayFullSpaceSpin/>}
      <h1 className="sticky">
        <div className="container">
          {remoteObjectConfiguration ? __(remoteObjectConfiguration.name, "capitalise") : __('loading')}
        </div>
      </h1>
      {
        !!remoteObjectConfiguration && (
          <SettingsContainer>
            <TableFilters
              isLoading={isLoading}
              configuration={remoteObjectConfiguration}
              currentSearch={currentSearch}
              searchModalOpen={searchModalOpen}
              setSearchModalOpen={setSearchModalOpen}
              setMissingRequiredSearchAttributes={setMissingRequiredSearchAttributes}
              currentSort={currentSort}
              sortModalOpen={sortModalOpen}
              setSortModalOpen={setSortModalOpen}
              summaryDisplayAttributesItems={summaryDisplayAttributesItems}
              visibleColumns={visibleColumns}
              pagination={currentPagination}
              submitSearch={submitSearch}
              enumValues={enumValues}
              getAttribute={(id) => attributesMap[id]}
            />
            <Space className="right" style={{marginRight: "15px"}}>
              {
                !!remoteObjectConfiguration && checkUserRights(guiUser.rights, rightMappings.CAN_CREATE_REMOTEOBJECT_ITEM) &&
                (
                  <CreateInstance
                    configuration={remoteObjectConfiguration}
                    displayDataIds={Object.keys(ROTableData?.[0]?.displayData || {})}
                    onSubmit={handleSubmitSearch}
                  />
                )
              }
              {
                !!remoteObjectConfiguration && checkUserRights(guiUser.rights, rightMappings.CAN_SEE_ALL_ORDERS) &&
                (
                  <div style={{float: "right", display: "inline-flex"}}>
                    {
                      exportOption && !exportModalOpened && (
                        <ExportColumnsModal
                          submit={({ exportColumns, selected_columns }) => {
                            const { displayAttributes } = remoteObjectConfiguration

                            let exportColumnsValue
                            if(exportColumns === 'all') exportColumnsValue = displayAttributes
                            else if(exportColumns === 'selected') exportColumnsValue = displayAttributes.filter(({ attributeId }) => visibleColumns.includes(attributeId))
                            else exportColumnsValue = displayAttributes.filter(({ attributeId }) => selected_columns.includes(attributeId))

                            setExportColumns(exportColumnsValue)
                          }}
                          onClose={() => {
                            setExportColumns(undefined)
                            setExportOption(undefined)
                          }}
                          summaryDisplayAttributesItems={summaryDisplayAttributesItems}
                        />
                      )
                    }
                    {
                      exportModalOpened && (
                        <ExportModal
                          objectName={remoteObjectConfiguration?.name}
                          exportOption={exportOption}
                          onClose={() => {
                            setExportColumns(undefined)
                            setExportOption(undefined)
                          }}
                          payloadData={{
                            remoteObjectConfiguration,
                            currentPagination,
                            currentSearch,
                            currentSort,
                            visibleColumns: exportColumns
                          }}
                          commonProps={{attributesMap}}
                        />
                      )}
                    <Space>
                      <AntdTooltip title={__('refresh data')}>
                        <IconButton shape="circle" onClick={handleSubmitSearch} icon={<RedoOutlined/>}/>
                      </AntdTooltip>
                      <ActionOptions
                        disabled={progress?.state?.active}
                        options={[
                          {
                            value: 'OnlyVisible',
                            label: __('Only visible results'),
                            onClick: () => setExportOption(availableExportOptions.partial),
                          },
                          {
                            value: 'All',
                            label: __('All results'),
                            onClick: () => setExportOption(availableExportOptions.total)
                          },
                          {
                            value: 'Exports',
                            label: __('last_exports'),
                            onClick: () => setExportOption(availableExportOptions.onlyExports)
                          },
                        ]}
                      >
                        <AntdTooltip title={__('export')}>
                          <div style={{position: 'relative'}}>
                            <IconButton shape="circle" icon={<DownloadOutlined/>}/>
                            <Progress {...progress?.state} />
                          </div>
                        </AntdTooltip>
                      </ActionOptions>
                    </Space>
                  </div>
                )
              }
            </Space>
          </SettingsContainer>
        )
      }
      {
        ROTableData && (
          <div className="container-fluid report-view-page">
            <div className={"not-snapshots"}>
              <RemoteObjectTable
                {...props}
                data={ROTableData}
                attributesMap={attributesMap}
                remoteObjectConfiguration={remoteObjectConfiguration}
                pagination={currentPagination}
                isLoading={isLoading}
                onSortChange={onSortColumnChange}
                selectedColumns={visibleColumns}
                onPageChange={(pageNumber) => handlePaginationChange({pagination: {pageNumber}})}
                setPageSize={(pageSize) => handlePaginationChange({pagination: {pageSize}})}
              />
            </div>
          </div>
        )}
      {
        !ROTableData && !isLoading &&
        <div style={{textAlign: "center", color: '#76777A', fontSize: '1.25em'}}><i>Missing required filters.</i>
        </div>
      }
    </ScrollableListView>
  )
}


export default RemoteObjectInstances
