import React, {useContext} from "react"
import * as CONTEXT from "./../context"
import * as S from "./../style"

const ErrorPage = () => {
  const {state} = useContext(CONTEXT.MainContext)

  return <S.HeadingError>{state.error.message}</S.HeadingError>
}

export default ErrorPage
