import React from 'react'
import PropTypes from 'prop-types';
import MessageSingle from "./MessageSingle";

import { __ } from '../../../utils/translationUtils'

class MessageContainer extends React.Component{

  static propTypes = {
    messages: PropTypes.array
  }

  constructor(props){
    super(props);
  }

  render() {
    const {messages} = this.props;
    if (messages) {

      const sortedMessage = messages.slice().reverse();

      return (
        <div className={'chatroom'} id={'comment-list'}>
          <ul className={'chats'}>
            {[].concat(sortedMessage).map(message =>
              <MessageSingle message={message}
                             key={message.id}
                             {...this.props}
              />)}
          </ul>
        </div>
      )
    }else {
      return (
        <div className={'chatroom'} id={'comment-list'}>
          {__(`No ${this.props.panelTitle}`)}
        </div>
      )
    }
  }


}

export default MessageContainer
