import React, {useCallback} from "react";

import { message } from 'antd'

import Footer from '../../../components/ConfigurationConfiguratorBeta/Footer'
import {useConfigurationConfiguratorContext} from "../context";
import * as C from '../constants'
import { __, T } from '../../../utils/translationUtils'

export default ({ handleSubmit }) => {

  const { state, setActiveStep, setStepValidationVisibility } = useConfigurationConfiguratorContext()
  const { mode, validationState, activeStep } = state

  const handleNavigation = useCallback((steps) => {
    if (validationState[activeStep] != null && !validationState[activeStep]) {
      setStepValidationVisibility(C.validationActivatedAfter.CLOSE)
      message.error(
        __((
          activeStep === C.steps.OUTPUT_FIELDS && T.chooseFieldsValidationError)
          || (activeStep === C.steps.VIEW_PROPERTIES && T.viewPropertiesValidationError)
        )
      )
    }
    else {
      setActiveStep(activeStep + steps)
    }
  }, [activeStep, setActiveStep, setStepValidationVisibility, validationState])

  return (
    <Footer
      buttons={[
        {
          key: 'Back',
          disabled: !activeStep,
          title: __('Back'),
          onClick: () => handleNavigation(-1),
          borderColor: '#72777F'
        },
        {
          key: 'Next',
          disabled: activeStep === C.steps.VIEW_PROPERTIES,
          title: __('Next'),
          onClick: () => handleNavigation(1),
          borderColor: '#72777F'
        },
        {
          key: 'Submit',
          title: __(mode === C.mode.CREATE ? 'Create' : 'Save'),
          type: 'primary',
          onClick: handleSubmit,
          disabled: Object.keys(validationState).some((key) => !validationState[key])
        }
      ]}
    />
  )

}
