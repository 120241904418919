import React, {useEffect, useMemo, useRef, useState} from "react";
import {SignatureComponent} from '@syncfusion/ej2-react-inputs';
import {errorLevel} from "components/dist/FormBuilder/Components/UI/Grid/utils";
import FileUploaderService from "../../components/DooOrderPage/Step/customRender/FileUploader/services/fileUploaderService";
import {removeLastPipeOrSlash} from "../../components/DooOrderPage/Step/customRender/FileUploader/services/utils";
import * as config from "../../constants/globalConfiguration";

import {Button} from "components";
import {__} from "../../utils/translationUtils";
import {EditSignatureOverlay, SignatureWrapper} from "./styled";
import {initialize} from "./utils";
import SignatureModal from "./SignatureModal";
import {uploadFile} from "../../components/DooOrderPage/Step/customRender/FileUploader/utils/IO";

const apiUrl = config.getApiUrl()

export const SignatureFieldValidation = (options: { [key: string]: any }) => ([
  {
    message: {value: 'This field is required', visible: errorLevel.BLUR},
    validator: (_: any, value: any) => {
      return Promise.resolve()
    },
  }])

const dimensions = {width: 480, height: 240}

const Signature = (props: any) => {
  const {download, value, onChange, readOnly} = props
  const internalRef = useRef<SignatureComponent>(null);

  const [internalValue, setInternalValue] = useState<string | undefined>(undefined)
  const jsonPath = useMemo(() => props.value && FileUploaderService.formatInitialJsonPath(props.value), [])

  const [signatureModalOpen, setSignatureModalOpen] = useState(false)

  useEffect(() => {
    if (value?.files?.[0] && internalRef.current) {
      const fullPath = `${apiUrl}/files${removeLastPipeOrSlash(jsonPath)}/${value.files[0].name}`

      download(fullPath)
        .then((blob: Blob) => initialize(blob, dimensions.width, dimensions.height)
          .then((base64) => setInternalValue(base64)))
    }
  }, [jsonPath])

  useEffect(() => {
    internalValue && internalRef.current?.load(internalValue, dimensions.width, dimensions.height)
  }, [internalValue]);


  return (
    <div style={{width: '100%', display: "flex", justifyContent: 'center'}}>
      <SignatureWrapper width={`${dimensions.width}px`}>
        {
          !readOnly && (
            <EditSignatureOverlay
              className="edit-signature-overlay"
              onClick={() => setSignatureModalOpen(true)}
            >
              <Button
                title={__('edit_signature')}
                type='text'
                color='white'
              />
            </EditSignatureOverlay>
          )
        }
        <SignatureComponent
          ref={internalRef}
          id='signature-view'
          isReadOnly
          style={{maxWidth: '100%', width: `${dimensions.width}px`, borderRadius: '8px'}}
        />
      </SignatureWrapper>
      {
        signatureModalOpen && (
          <SignatureModal
            imgBase64={internalValue}
            dimensions={dimensions}
            onCancel={() => setSignatureModalOpen(false)}
            onSave={(blob) => {
              if (blob) {
                initialize(blob, dimensions.width, dimensions.height)
                  .then((base64) => {
                    setInternalValue(base64)
                    setSignatureModalOpen(false)
                  })

                const { fieldName } = value
                const file = new File([blob], `${fieldName}_1.jpg`, {type: 'image/jpeg'})
                uploadFile(file, { jsonPath, fieldName})
                  .then(async (res) => {
                    if (res.status === 200 && res.data) {
                      onChange?.({
                        ...value,
                        files: [{
                          name: `${fieldName}_1.jpg`,
                          type: "jpg",
                          crc: res.data,
                          length: file.size
                        }]
                      })
                    }
                  })
              }
            }}
          />
        )
      }
    </div>
  )
}

export default Signature
