import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  > :not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Title = styled.div`
  font-weight: 1000;
  color: #00629d;
  overflow-wrap: break-word;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const focusedAnchorItem = css`
  &, &:hover {
    color: #004671;
    border-color: #004671;
  }
`

export const AnchorItem = styled.div`
  padding: 12px;
  color: #ABABAE;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: 2px solid #C6C6C9;
  transition: .25s ease;
  &:hover {
    color: #72777F;
    padding-left: 14px;
    padding-right: 10px;
  }
  ${(props) => props?.focused && focusedAnchorItem}
`
