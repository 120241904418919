import React, {useMemo, useState} from 'react'
import {useROEConfiguratorContext} from "../../context";
import * as S from '../../styles'
import * as C from '../../constants'
import {Steps} from "antd";
import Step1 from "../../../../images/thumbnails/TableConfigurator.svg";
import Step2 from "../../../../images/thumbnails/LayoutConfigurator.svg";
import {validateViewProperties} from "../../utils/validation";
import { __ } from '../../../../utils/translationUtils'

const { Step } = Steps;

export default (props) => {

  const { state, setActiveStep } = useROEConfiguratorContext()
  const { activeStep, configurations, mode } = state || {}
  const [hoveredStep, setHoveredStep] = useState(null)

  const onStepClick = (step) => {
    if (!(step === C.steps.LISTING_PREVIEW || step === C.steps.CONTENT_PREVIEW)) return null
    setActiveStep(step + 1)
  }

  const onStepHover = (step) => {
    setHoveredStep(step)
  }

  const stepProps = useMemo(() => {
    const canAvoidFirstStep = validateViewProperties(configurations[C.steps.LISTING_VIEW_PROPERTIES])
    return {
      [C.steps.LISTING_PREVIEW]: {
        disabled: false,
        onClick: () => onStepClick(C.steps.LISTING_PREVIEW),
        onMouseEnter: () => onStepHover(C.steps.LISTING_PREVIEW),
        onMouseLeave: () => onStepHover(null),
      },
      [C.steps.CONTENT_PREVIEW]: {
        disabled: !canAvoidFirstStep,
        onClick: () => {if (canAvoidFirstStep) onStepClick(C.steps.CONTENT_PREVIEW)},
        onMouseEnter: () => {if (canAvoidFirstStep) onStepHover(C.steps.CONTENT_PREVIEW)},
        onMouseLeave: () => {if (canAvoidFirstStep) onStepHover(null)},
      },
    }
  }, [onStepClick, onStepHover])

  return (
    <S.PreviewContainer>
      <S.PreviewTitle>
        <S.StepItemContent size={'giga'} weight={'large'} expansion={'50%'}>
          {__(C.previewContent[mode][activeStep].title)}
        </S.StepItemContent>
        <S.StepItemContent size={'default'} weight={'large'} expansion={'40%'}>
          {__(C.previewContent[mode][activeStep].message)}
        </S.StepItemContent>
      </S.PreviewTitle>
      <S.JustifyCenter>
        <S.PreviewSteps current={activeStep === C.steps.LISTING_PREVIEW ? 0 : 1}>
          <Step/>
          <Step/>
        </S.PreviewSteps>
      </S.JustifyCenter>
      <S.StepRow>
        <S.StepCol
          span={12}
          {...stepProps[C.steps.LISTING_PREVIEW]}
        >
          <S.StepItemContent size={'large'} weight={'large'}>
            {__(C.previewContent[mode][C.steps.LISTING_PREVIEW].info.title)}
          </S.StepItemContent>
          <S.StepItemContent>
            {__(C.previewContent[mode][C.steps.LISTING_PREVIEW].info.message)}
          </S.StepItemContent>
        </S.StepCol>
        <S.StepCol
          span={12}
          {...stepProps[C.steps.CONTENT_PREVIEW]}
        >
          <S.StepItemContent size={'large'} weight={'large'}>
            {__(C.previewContent[mode][C.steps.CONTENT_PREVIEW].info.title)}
          </S.StepItemContent>
          <S.StepItemContent>
            {__(C.previewContent[mode][C.steps.CONTENT_PREVIEW].info.message)}
          </S.StepItemContent>
        </S.StepCol>
      </S.StepRow>
      <S.StepRow>
        <S.StepCol
          span={12}
          {...stepProps[C.steps.LISTING_PREVIEW]}
        >
          <S.StepItemImage
            src={Step1}
            preview={false}
            disabled={activeStep !== C.steps.LISTING_PREVIEW || hoveredStep === C.steps.CONTENT_PREVIEW }
            hovered={hoveredStep === C.steps.LISTING_PREVIEW}
          />
        </S.StepCol>
        <S.StepCol
          span={12}
          {...stepProps[C.steps.CONTENT_PREVIEW]}
        >
          <S.StepItemImage
            src={Step2}
            preview={false}
            disabled={activeStep !== C.steps.CONTENT_PREVIEW || hoveredStep === C.steps.LISTING_PREVIEW }
            hovered={hoveredStep === C.steps.CONTENT_PREVIEW}
          />
        </S.StepCol>
      </S.StepRow>

    </S.PreviewContainer>
  )
}
