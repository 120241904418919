import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import objectAssign from 'object-assign';
import _ from 'lodash';


import { createOrderGroup, updateOrderGroup } from '../providers/ReduxProvider/actions/orderGroupActions';
import { getGuiUser } from './../providers/ReduxProvider/actions/userActions';
import OrderGroupForm from '../components/OrderGroupForm';
import { api } from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'
const loadingBar = require('nprogress');

class OrderGroupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objectId: this.props.match.params.id,
      mode: this.props.mode,
      orderGroup: {
        name: '',
      },
      associatedViews: null,
      associatedReports: null,
      initialOrderGroupValues: null,
      initialAssociatedReports: null,
      initialAssociatedViews: null,
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addItems = this.addItems.bind(this);
    this.removeItems = this.removeItems.bind(this);
    this.getData = this.getData.bind(this);
    this.init = this.init.bind(this);
    this.getAssociatedData = this.getAssociatedData.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  init() {
    if (this.props.mode === 'create') {
      return;
    }

    this.getData();
  }

  addItems(device, items, type) {
    let _associatedItems;
    if (type === 'view') {
      _associatedItems = this.state.associatedViews;
    } else if (type === 'report') {
      _associatedItems = this.state.associatedReports;
    } else if (type === 'orderType') {
      _associatedItems = this.state.orderGroup.orderTypes;
    }

    let _availableItems;
    if (type === 'view') {
      _availableItems = this.props.availableViews;
    } else if (type === 'report') {
      _availableItems = this.props.availableReports;
    } else if (type === 'orderType') {
      _availableItems = this.props.availableOrderTypes;
    }

    let associatedItems = [].concat(_associatedItems);
    items.forEach((id) => {
      const found = _.find(_availableItems, (_v) => _v.id === id);
      if (typeof found === 'undefined') {
        return;
      }
      if (type === 'orderType') {
        associatedItems.push({
          // orderGroupId: this.state.objectId,
          ...found,
          // orderTypeId: found.id,
        });
      } else {
        associatedItems.push({
          configurationId: found.id,
          configuration: found,
          device,
          defaultPinned: false,
          groupId: this.state.objectId,
          position: 0,
        });
      }
    });

    let orderGroup = Object.assign({}, this.state.orderGroup);

    if (type === 'view') {
      this.setState({
        associatedViews: associatedItems,
      });
    } else if (type === 'report') {
      this.setState({
        associatedReports: associatedItems,
      });
    } else if (type === 'orderType') {
      orderGroup.orderTypes = associatedItems;
      this.setState({
        orderGroup,
      });
    }
  }

  removeItems(device, items, type) {
    let orderGroup = Object.assign({}, this.state.orderGroup);
    let _associatedItems;
    if (type === 'view') {
      _associatedItems = this.state.associatedViews;
    } else if (type === 'report') {
      _associatedItems = this.state.associatedReports;
    } else if (type === 'orderType') {
      _associatedItems = orderGroup.orderTypes;
    }

    let associatedItems = [].concat(_associatedItems);
    items.forEach((id) => {
      let idx;
      if (type === 'orderType') {
        idx = _.findIndex(associatedItems, (a) => {
          return a.id === id;
        });
      }
      else {
        idx = _.findIndex(associatedItems, (a) => {
          return a.configurationId === id && a.device === device;
        });
      }
      if (idx > -1) {
        associatedItems.splice(idx, 1);
      }
    });

    if (type === 'view') {
      this.setState({ associatedViews: associatedItems });
    } else if (type === 'report') {
      this.setState({ associatedReports: associatedItems });
    } else if (type === 'orderType') {
      orderGroup.orderTypes = associatedItems;
      this.setState({ orderGroup });
    }
  }

  getData() {
    this.setState({ isLoading: true });
    loadingBar.start();
    return api.get(`orderGroups/${this.state.objectId}`)
      .then(
        response => {
          // Update the state
          this.setState({
            orderGroup: response.data,
            initialOrderGroupValues: response.data,
            initialValues: { ...response.data },
            isLoading: false,
          }, () => {
            loadingBar.done();
            this.getAssociatedData();
          });
        }
      );
  }

  getAssociatedData() {
    this.setState({ isLoading: true });
    api.get(`orderGroups/${this.state.objectId}/views?sort=position&pageSize=200`)
      .then(
        response => {
          // Update the state
          this.setState({
            associatedViews: response.data,
            initialAssociatedViews: response.data,
          });
        }
      );

    api.get(`orderGroups/${this.state.objectId}/reports?sort=position&pageSize=200`)
      .then(
        response => {
          // Update the state
          this.setState({
            associatedReports: response.data,
            initialAssociatedReports: response.data,
          });
        }
      );
  }

  handleSubmit() {
    const { formValues, user } = this.props;

    const payload = objectAssign(this.state.orderGroup, {});
    payload.name = formValues.name;
    payload.modifierId = user.id;
    delete payload.lastUpdateDatetime;

    if (this.props.mode === 'edit') {
      this.props.updateOrderGroup(payload).then(() => {
        this.props.getGuiUser();
      });
    } else {
      payload.orderTypes = []
      formValues.orderTypes.forEach((orderType) => {
        payload.orderTypes.push(JSON.parse(orderType));
      })
      this.props.createOrderGroup(payload).then(() => {
        this.props.getGuiUser();
      });
    }
  }

  render() {
    const headerClass = (this.state.mode) ? this.state.mode : '';
    const { orderGroup, associatedViews, associatedReports } = this.state;
    return (
      <div id="main-content" className="orderGroup-page">
        <h1 className={'sticky ' + headerClass}>
          <div className="container">
            {
              this.state.mode === 'edit'
                ? __('Edit Order Group')
                : __('Create Order Group')
            }
          </div>
        </h1>

        <div className="container">
          <br />
          {this.state.orderGroup || this.state.mode !== 'edit' ?
            <OrderGroupForm
              addItems={this.addItems}
              removeItems={this.removeItems}
              getData={this.init}
              mode={this.state.mode}
              systemSettings={this.props.systemSettings}
              initialValues={(this.state.mode === 'create') ?
                {
                  type: 'SELF',
                } : this.state.initialValues
              }
              orderGroup={orderGroup}
              associatedViews={associatedViews}
              associatedReports={associatedReports}
              initialAssociatedViews={this.state.initialAssociatedViews}
              initialAssociatedReports={this.state.initialAssociatedReports}
              initialOrderGroupValues={this.state.initialOrderGroupValues}
              onSubmit={this.handleSubmit}
            />
            : <span>{__('loading')}</span>
          }
        </div>
      </div>
    );
  }
}

OrderGroupPage.propTypes = {
  mode: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
  getGuiUser: PropTypes.func,
  createOrderGroup: PropTypes.func,
  availableViews: PropTypes.array,
  availableReports: PropTypes.array,
  availableOrderTypes: PropTypes.array,
  updateOrderGroup: PropTypes.func,
  formValues: PropTypes.object,
};

export default connect(
  state => {
    return {
      user: state.user.data,
      availableReports: state.user.reports,
      availableViews: state.user.views,
      availableOrderTypes: state.user.orderTypes,
      systemSettings: state.ui.systemSettings,
      formValues: getFormValues('orderGroupForm')(state),
    };

  },
  { getGuiUser, createOrderGroup, updateOrderGroup }
)(OrderGroupPage);
