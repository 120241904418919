import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {bindActionCreators} from 'redux';
import {Panel,} from 'react-bootstrap';
import Glyphicon from '@strongdm/glyphicon'
import {hashHistory} from '../../providers/HistoryProvider';
import {isRequired, renderField} from '../../utils/fieldValidator';
import {Alert} from "antd";
import { __ } from '../../utils/translationUtils'

const validate = values => {
  const errors = {};
  errors.id = isRequired(values.id);

  return errors;
};

class RemoteObjectForm extends React.Component {

  static propTypes = {
    handleSubmit: PropTypes.func,
    change: PropTypes.func,
    getData: PropTypes.func,
    isLoading: PropTypes.bool,
    mode: PropTypes.string,
    remoteObject: PropTypes.object,
    errors: PropTypes.object
  };

  constructor(props){
    super(props);
    this.state={
      modal: {
        open: false,
        action: '',
        user: {
          name: '',
          id: 0
        }
      }
    };

  }

  handleSubmit(e){
    e.preventDefault();
    this.props.handleSubmit();
  }

  render() {

    const {isLoading, errors} = this.props;
    const renderPanelFooter = () => {
      return (
        <div className="group">

          <button className="btn btn-default pull-left" onClick={() => {
            hashHistory.goBack();
          }}>
            <Glyphicon glyph="chevron-left"/> {__('Back')}
          </button>

          <button className={'btn pull-right '.concat(this.props.mode === 'create' ? 'btn-success' : 'btn-primary')}
                  disabled={isLoading}
          size="large" onClick={this.handleSubmit.bind(this)}>
            <Glyphicon glyph={(this.props.mode==='edit')?'save':'plus-sign'} />
            { __( (this.props.mode==='edit')?__('Save Changes'):__('Create') )}
          </button>
        </div>
      );
    };

    return (
      <div>
        <Panel>
          <Panel.Heading>{__('Remote Object')}</Panel.Heading>
          <Panel.Body>
            <form className="form-horizontal remote-object-form">
              <div className="col-md-6">
                <Field
                  name="id"
                  component={renderField}
                  disabled={this.props.mode === "view"}
                  label={__('id') + '*'}
                  className="form-control"
                  type="text"/>
              </div>

            </form>

            <div className="col-sm-12">
              <div className="mandatory-text">
                <i>*{__('mandatory fields')}</i>
              </div>
            </div>

            <div className={"col-sm-12"}>
              {errors ?
                Object.entries(errors).map(([objectTypeId, errorDetails]) => (
                  <Alert
                    message={`${objectTypeId}: ${errorDetails}`}
                    type="error"
                  />
                ))
                :
                null}
            </div>

          </Panel.Body>
          <Panel.Footer>{renderPanelFooter()}</Panel.Footer>
        </Panel>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default reduxForm({
  form: 'remoteObjectForm',  // a unique identifier for this form
  validate,
}, mapDispatchToProps)(RemoteObjectForm);
