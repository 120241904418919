import React, {useContext} from "react"
import * as CONTEXT from "./../context"
import {DISPLAY_ATTRIBUTES, SEARCH_ATTRIBUTES, SORT_ATTRIBUTES} from "../utils/requiredFields"
import {Table, Transfer} from 'antd'
import AttributesTree from "./AttributesTree"
import * as ACTION_TYPE from "./../context/actionTypes"
import DraggableTableBodyRow from "./../../DraggableTableBodyRow"
import {HTML5Backend} from "react-dnd-html5-backend"
import {DndProvider} from "react-dnd"
import PropTypes from "prop-types"


AttributeTransfer.propTypes = {
  attributeType: PropTypes.oneOf([DISPLAY_ATTRIBUTES, SEARCH_ATTRIBUTES, SORT_ATTRIBUTES]).isRequired,
  renderCard: PropTypes.func.isRequired,
  onAttributeTransfer: PropTypes.func.isRequired,
  attributesInformation: PropTypes.shape({
    attributesTree: PropTypes.array.isRequired,
    attributesFlat: PropTypes.array.isRequired,
    targetKeys: PropTypes.array.isRequired,
    systemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objectTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  tablePagination: PropTypes.shape({
    showSizeChanger: PropTypes.bool,
    defaultCurrent: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
    defaultPageSize: PropTypes.number,
  }),
}

function AttributeTransfer({attributeType, renderCard, onAttributeTransfer, attributesInformation, tablePagination}) {
  const {dispatch} = useContext(CONTEXT.MainContext)

  const handleTransferChange = (attributeKeys) => {
    dispatch({
      type: ACTION_TYPE.UPDATE_ATTRIBUTES,
      payload: {
        attributeKeys: onAttributeTransfer(attributeKeys),
        attributeType: attributeType,
      },
    })
  }

  const handleSortingChange = (dragIndex, hoverIndex) => {
    const attributeKeys = attributesInformation?.targetKeys || []
    attributeKeys.splice(hoverIndex, 0, attributeKeys.splice(dragIndex, 1)[0])
    dispatch({
      type: ACTION_TYPE.UPDATE_ATTRIBUTES,
      payload: {
        attributeKeys: onAttributeTransfer(attributeKeys),
        attributeType: attributeType,
      },
    })
  }

  const tableComponents = {
    body: {
      row: DraggableTableBodyRow,
    },
  }

  const columns = [
    {
      dataIndex: "key",
      render: renderCard,
    },
  ]

  return (
    <Transfer
      dataSource={attributesInformation?.attributesFlat || []}
      targetKeys={attributesInformation?.targetKeys || []}
      onChange={handleTransferChange}
      render={(item) => item.propertyLabel}
      showSelectAll={false}
    >
      {({direction, onItemSelect, selectedKeys, disabled: listDisabled}) => {
        if (direction === "left") {
          const checkedKeys = [...selectedKeys, ...(attributesInformation?.targetKeys || [])]
          return (
            <AttributesTree
              checkedKeys={checkedKeys}
              attributes={attributesInformation?.targetKeys || []}
              dataSource={attributesInformation?.attributesTree || []}
              onItemSelect={onItemSelect}
            />
          )
        } else {
          const rowSelection = {
            getCheckboxProps: (item) => ({disabled: listDisabled || item.disabled}),

            onSelect({key}, selected) {
              onItemSelect(key, selected)
            },
            selectedRowKeys: selectedKeys,
          }

          return (
            <DndProvider backend={HTML5Backend}>
              <Table
                rowSelection={rowSelection}
                components={tableComponents}
                pagination={{
                  ...(tablePagination || {}),
                  ...{
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    pageSizeOptions: [10, 20, 30, 40, 50, attributesInformation?.targetKeys?.length || 0]
                      .sort()
                      .filter((s) => s <= (attributesInformation?.targetKeys?.length || 0) || s === 10)
                      .map((s) => s.toString()),
                  },
                }}
                columns={columns}
                showHeader={false}
                dataSource={(attributesInformation?.targetKeys || []).map((key) => ({key: key}))}
                size="small"
                style={{pointerEvents: listDisabled ? "none" : null}}
                onRow={(_, index) => ({
                  index,
                  moveRow: handleSortingChange,
                })}
              />
            </DndProvider>
          )
        }
      }}
    </Transfer>
  )
}

export default AttributeTransfer
