const process = {
  process_title: 'process_title',
  internal_process_status: 'internal_process_status',
  status: 'status',
  responsibleUser: 'responsibleUser', //todo no camelcase
  assignees: 'assignees',
  internal_order_status: 'internal_order_status',
  order_status: 'godoo_order_status',
  lastUpdateDatetime: 'lastUpdateDatetime'
}

export default process
