import React, {useEffect, useMemo} from 'react'
import {connect, useSelector} from 'react-redux'
import {getEntitySettingsActionCreator} from '../../providers/ReduxProvider/actions/userActions'

import {Authentication as Auth, Gutter} from 'components'
import {fetchEntityByName} from '../../providers/ApiProvider/authentication'
import useInit from '../../hooks/useInit'
import {TextCenter} from '../../components/UI/styles/text'

const SuccessMessagePage = (props) => {
  const {getEntitySettings, match, location} = props
  const {userRegistration} = useSelector((state) => state?.ui?.instanceSettings) || {}
  const {successfulSignUpPageConfiguration} = userRegistration || {}

  const entityName = useMemo(() => match.params.entityName, [match.params.entityName])

  const mounted = useInit()
  useEffect(() => {
    fetchEntityByName(entityName)
      .then((entity) => mounted.current && getEntitySettings(entity?.id))
  }, [entityName])

  return (
    <>
      <TextCenter>
        <Gutter bottom>
          <h3>
            {entityName || ''}
          </h3>
        </Gutter>
      </TextCenter>
      <Auth.SuccessMessage message={successfulSignUpPageConfiguration?.message} queryString={location.search}/>
    </>
  )
}

export default connect(
  null,
  {getEntitySettings: getEntitySettingsActionCreator}
)(SuccessMessagePage)
