import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import _ from 'lodash'
import {Button, Dropdown, Modal, Panel} from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {Divider} from 'antd'
import {hashHistory} from '../providers/HistoryProvider'
import {canUserAssociateEntityOfTypeToOrderGroup, checkUserRights, showMessage} from '../utils/appHelper'
import {isRequired, renderField} from '../utils/fieldValidator'
import * as config from '../constants/globalConfiguration'
import {api} from '../providers/ApiProvider'
import * as S from './Calendar/CalendarsDashboard/styles'
import {renderTreeSelect} from './OrderTypeForm'
import {__} from '../utils/translationUtils'

const loadingBar = require('nprogress')
const moment = require('moment')

const validate = (values) => {
  const errors = {}
  errors.name = isRequired(values.name)
  errors.orderTypes = isRequired(values.orderTypes)
  return errors
}

class OrderGroupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1,
      activeKey: 0,
      manageAssociatedItemsModal: {
        open: false,
        device: null,
        type: null
      },
      manageAssociatedOrderTypesModal: {
        open: false,
        device: null,
        type: null
      },
      managePinnedItemsModal: {
        open: false,
        device: null,
        type: null
      },
      selectedPinnedViews: [],
      selectedPinnedReports: [],
      selectedPinnedOrderTypes: [],
      selectedDefaultPinnedViews: [],
      selectedDefaultPinnedReports: [],
      selectedDefaultPinnedOrderTypes: []
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSetItems = this.handleSetItems.bind(this)
    this.handleSetPinnedItems = this.handleSetPinnedItems.bind(this)

    this.closeModal = this.closeModal.bind(this)

    this.showManageAssociatedItemsModal = this.showManageAssociatedItemsModal.bind(this)
    this.showManagePinnedItemsModal = this.showManagePinnedItemsModal.bind(this)

    this.toggleDefaultPinnedItem = this.toggleDefaultPinnedItem.bind(this)
    this.togglePinnedItem = this.togglePinnedItem.bind(this)

    this.getAssociatedReportsByDevice = this.getAssociatedReportsByDevice.bind(this)
    this.getAssociatedViewsByDevice = this.getAssociatedViewsByDevice.bind(this)
    this.getAssociatedOrderTypes = this.getAssociatedOrderTypes.bind(this)
    this.getAssociatedItemsByDeviceAndType = this.getAssociatedItemsByDeviceAndType.bind(this)
    this.getAvailableViews = this.getAvailableViews.bind(this)
    this.getAvailableReports = this.getAvailableReports.bind(this)
    this.getAvailableOrderTypes = this.getAvailableOrderTypes.bind(this)
    this.getAvailableItemsByType = this.getAvailableItemsByType.bind(this)
    this.getDefaultPinnedViewsByDevice = this.getDefaultPinnedViewsByDevice.bind(this)
    this.getDefaultPinnedReportsByDevice = this.getDefaultPinnedReportsByDevice.bind(this)
    this.getDefaultPinnedOrderTypesByDevice = this.getDefaultPinnedOrderTypesByDevice.bind(this)
    this.getSelectedDefaultPinnedItemsByType = this.getSelectedDefaultPinnedItemsByType.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }

  getSelectedDefaultPinnedItemsByType(type) {
    if (type === 'view') {
      return this.state.selectedDefaultPinnedViews
    }
    if (type === 'report') {
      return this.state.selectedDefaultPinnedReports
    }
    if (type === 'orderType') {
      return this.state.selectedDefaultPinnedOrderTypes
    }
  }

  getSelectedPinnedItemsByType(type) {
    if (type === 'view') {
      return this.state.selectedPinnedViews
    }
    if (type === 'report') {
      return this.state.selectedPinnedReports
    }
    if (type === 'orderType') {
      return this.state.selectedPinnedOrderTypes
    }
  }

  getAssociatedViewsByDevice(device) {
    const {associatedViews} = this.props
    const items = _.filter(associatedViews, (g) => g.device === device)
    return items
  }

  getAssociatedReportsByDevice(device) {
    const {associatedReports} = this.props
    return _.filter(associatedReports, (g) => g.device === device)
  }

  getAssociatedOrderTypes() {
    const {orderGroup} = this.props
    return orderGroup.orderTypes
  }

  getAssociatedItemsByDeviceAndType(device, type) {
    if (type === 'view') {
      return this.getAssociatedViewsByDevice(device)
    }
    if (type === 'report') {
      return this.getAssociatedReportsByDevice(device)
    }
    if (type === 'orderType') {
      return this.getAssociatedOrderTypes()
    }
  }

  getAvailableViews() {
    const {availableViews} = this.props
    return availableViews
  }

  getAvailableReports() {
    const {availableReports} = this.props
    return availableReports
  }

  getAvailableOrderTypes() {
    const {availableOrderTypes} = this.props
    return availableOrderTypes
  }

  getAvailableItemsByType(type) {
    if (type === 'view') {
      return this.getAvailableViews()
    }
    if (type === 'report') {
      return this.getAvailableReports()
    }
    if (type === 'orderType') {
      return this.getAvailableOrderTypes()
    }
  }

  getDefaultPinnedViewsByDevice(device) {
    const {associatedViews} = this.props
    const items = _.filter(associatedViews, (g) => g.device === device && g.defaultPinned)
    return items
  }

  getDefaultPinnedReportsByDevice(device) {
    const {associatedReports} = this.props
    const items = _.filter(associatedReports, (g) => g.device === device && g.defaultPinned)
    return items
  }

  getDefaultPinnedOrderTypesByDevice(device) {
    const {orderGroup} = this.props
    const items = _.filter(orderGroup.orderTypes, (g) => g.device === device && g.defaultPinned)
    return items
  }

  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({
      manageAssociatedItemsModal: {...this.state.manageAssociatedItemsModal, open: false},
      manageAssociatedOrderTypesModal: {...this.state.manageAssociatedOrderTypesModal, open: false},
      managePinnedItemsModal: {...this.state.managePinnedItemsModal, open: false}
    }, () => {
      this.props.getData()
    })
  }

  showManageAssociatedItemsModal(device, type) {
    if (type === 'orderType') {
      this.setState({
        selectedDefaultPinnedViews: this.getDefaultPinnedViewsByDevice(device),
        selectedDefaultPinnedReports: this.getDefaultPinnedReportsByDevice(device),
        selectedDefaultPinnedOrderTypes: this.getDefaultPinnedOrderTypesByDevice(device),
        manageAssociatedOrderTypesModal: {
          ...this.state.manageAssociatedOrderTypesModal,
          open: true,
          type
        }
      })
    } else {
      this.setState({
        selectedDefaultPinnedViews: this.getDefaultPinnedViewsByDevice(device),
        selectedDefaultPinnedReports: this.getDefaultPinnedReportsByDevice(device),
        selectedDefaultPinnedOrderTypes: this.getDefaultPinnedOrderTypesByDevice(device),
        manageAssociatedItemsModal: {
          ...this.state.manageAssociatedItemsModal,
          open: true,
          device,
          type
        }
      })
    }
  }

  showManagePinnedItemsModal(device, type) {
    const {orderGroup} = this.props
    const _pinnedViews = []
    orderGroup.pinnedViews.forEach((v) => {
      _pinnedViews.push(v.configuration)
    })
    const _pinnedReports = []
    orderGroup.pinnedReports.forEach((v) => {
      _pinnedReports.push(v.configuration)
    })

    this.setState({
      managePinnedItemsModal: {
        ...this.state.managePinnedItemsModal,
        open: true,
        device,
        type
      },
      selectedPinnedViews: _pinnedViews,
      selectedPinnedReports: _pinnedReports
    })
  }

  getMaxDefaultPinnedItems(type) {

    if (this.state.manageAssociatedItemsModal.device === config.DEVICE_TYPES.WEB) {
      if (type === 'view') {
        return this.props.systemSettings.webuiMaxDefaultPinnedViews
      } else if (type === 'report') {
        return this.props.systemSettings.webuiMaxDefaultPinnedReports
      }
      return config.TOTAL_PINNED_ITEMS_WEB
    }

    if (type === 'view') {
      return this.props.systemSettings.mobileMaxDefaultPinnedViews
    } else if (type === 'report') {
      return this.props.systemSettings.mobileMaxDefaultPinnedReports
    }
    return config.TOTAL_PINNED_ITEMS_MOBILE
  }


  toggleDefaultPinnedItem(item) {
    const {type} = this.state.manageAssociatedItemsModal

    // check if already pinned
    let items
    if (type === 'view') {
      items = this.state.selectedDefaultPinnedViews
    } else if (type === 'report') {
      items = this.state.selectedDefaultPinnedReports
    } else if (type === 'orderType') {
      items = this.state.selectedDefaultPinnedOrderTypes
    }
    const idx = _.findIndex(items, (i) => i.configurationId === item.id)
    const maxItems = this.getMaxDefaultPinnedItems(type)

    if (idx > -1) { // Pinned
      items.splice(idx, 1)
    } else { // Not pinned
      // check if maximum pinned items number has been reached.
      let message
      if (items.length >= maxItems) {
        if (type === 'view') {
          message = __('MaximumPinnedViewsReached')
        } else if (type === 'report') {
          message = __('MaximumPinnedReportsReached')
        } else if (type === 'orderType') {
          message = __('MaximumPinnedOrderTypesReached')
        }
        showMessage('info', message)
      } else {
        items.push({...item, configurationId: item.id})
      }
    }

    if (type === 'view') {
      this.setState({selectedDefaultPinnedViews: items})
    } else if (type === 'report') {
      this.setState({selectedDefaultPinnedReports: items})
    } else if (type === 'orderType') {
      this.setState({selectedDefaultPinnedOrderTypes: items})
    }
  }

  getMaxPinnedItems(type) {

    if (this.state.managePinnedItemsModal.device === config.DEVICE_TYPES.WEB) {
      if (type === 'view') {
        return this.props.systemSettings.webuiMaxPinnedViews
      } else if (type === 'report') {
        return this.props.systemSettings.webuiMaxPinnedReports
      }
      return config.TOTAL_PINNED_ITEMS_WEB
    }

    if (type === 'view') {
      return this.props.systemSettings.mobileMaxPinnedViews
    } else if (type === 'report') {
      return this.props.systemSettings.mobileMaxPinnedReports
    }
    return config.TOTAL_PINNED_ITEMS_MOBILE
  }

  togglePinnedItem(type, item) {
    // check if already pinned
    let items
    if (type === 'view') {
      items = this.state.selectedPinnedViews
    } else if (type === 'report') {
      items = this.state.selectedPinnedReports
    } else if (type === 'orderType') {
      items = this.state.selectedPinnedOrderTypes
    }
    const idx = _.findIndex(items, (i) => i.id === item.id)
    const maxItems = this.getMaxPinnedItems(type)

    if (idx > -1) { // Pinned
      items.splice(idx, 1)
    } else { // Not pinned
      // check if maximum pinned items number has been reached.
      if (items.length >= maxItems) {
        let message
        if (type === 'view') {
          message = __('MaximumPinnedViewsReached')
        } else if (type === 'report') {
          message = __('MaximumPinnedReportsReached')
        } else if (type === 'orderType') {
          message = __('MaximumPinnedOrderTypesReached')
        }
        showMessage('info', message)
      } else {
        items.push(item)
      }
    }

    if (type === 'view') {
      this.setState({selectedPinnedViews: items})
    } else if (type === 'report') {
      this.setState({selectedPinnedReports: items})
    } else if (type === 'orderType') {
      this.setState({selectedPinnedOrderTypes: items})
    }
  }

  handleSetPinnedItems(type, device) {
    const {orderGroup, guiUser} = this.props
    const {selectedPinnedViews, selectedPinnedReports, selectedPinnedOrderTypes} = this.state
    let pinnedItems
    if (type === 'view') {
      pinnedItems = selectedPinnedViews
    } else if (type === 'report') {
      pinnedItems = selectedPinnedReports
    } else if (type === 'orderType') {
      pinnedItems = selectedPinnedOrderTypes
    }

    const itemsIds = pinnedItems.map((i) => {
      // return e.id;
      const item = {
        groupId: orderGroup.id,
        userId: guiUser.id,
        configurationId: i.id,
        device
      }

      return item
    })
    this.setState({isLoading: true})
    loadingBar.start()
    return api.put(`/orderGroups/${orderGroup.id}/${type}s/pinned`, itemsIds)
      .then(
        () => {
          // Update the state
          this.setState({isLoading: false}, () => {
            loadingBar.done()
            this.closeModal()
            showMessage('success', __('PinnedItemsSaved'))
          })
        }
      ).catch((error) => {
        showMessage('error', `${__('ServerSideError')}. ${error}`)
      })
  }

  handleSetItems(type) {
    const {orderGroup} = this.props

    let selectedDefaultPinnedItems
    if (type === 'view') {
      selectedDefaultPinnedItems = this.state.selectedDefaultPinnedViews
    } else if (type === 'report') {
      selectedDefaultPinnedItems = this.state.selectedDefaultPinnedReports
    } else if (type === 'orderType') {
      selectedDefaultPinnedItems = this.state.selectedDefaultPinnedOrderTypes
    }

    const associatedItems = this.getAssociatedItemsByDeviceAndType(this.state.manageAssociatedItemsModal.device, type)

    if (type !== 'orderType') { // Views and Reports
      let patch = []
      const _items = type === 'view' ? this.props.initialAssociatedViews : this.props.initialAssociatedReports
      // Filter by device
      const initialItems = _.filter(_items, (f) => f.device === this.state.manageAssociatedItemsModal.device)

      // Remove
      initialItems.forEach((v) => {
        if (_.findIndex(associatedItems, (i) => i.configurationId === v.configurationId) === -1) {
          patch.push({
            op: 'test',
            path: `/${v.position}`,
            value: {
              groupId: orderGroup.id,
              configurationId: v.configurationId,
              defaultPinned: v.defaultPinned,
              device: this.state.manageAssociatedItemsModal.device,
              position: v.position
            }
          })
          patch.push({
            op: 'remove',
            path: `/${v.position}`
          })
        }
      })

      // Add
      associatedItems.forEach((v) => {
        const itemSetAsdefault = _.findIndex(selectedDefaultPinnedItems, (i) => i.id === v.configurationId)

        if (_.findIndex(initialItems, (i) => i.configurationId === v.configurationId) === -1) {
          patch.push({
            op: 'add',
            path: '/-',
            value: {
              groupId: orderGroup.id,
              configurationId: v.configurationId,
              defaultPinned: itemSetAsdefault > -1,
              position: v.position,
              device: this.state.manageAssociatedItemsModal.device
            }
          })
        }
      })

      // Update (opnly property to update currently is defaultPinned)
      initialItems.forEach((ii) => {
        const found = _.find(selectedDefaultPinnedItems, (i) => i.configurationId === ii.configurationId)
        const newValue = (typeof found !== 'undefined')

        if (
          newValue !== ii.defaultPinned
          // Check if also not going to be removed
          // && _.findIndex(associatedItems, (i) => i.configurationId === ii.configurationId) > -1
        ) {
          patch.push({
            op: 'test',
            path: `/${ii.position}`,
            value: {
              groupId: orderGroup.id,
              configurationId: ii.configurationId,
              defaultPinned: ii.defaultPinned,
              device: this.state.manageAssociatedItemsModal.device,
              position: ii.position
            }
          })

          patch.push({
            op: 'replace',
            path: `/${ii.position}`,
            value: {
              groupId: orderGroup.id,
              configurationId: ii.configurationId,
              defaultPinned: _.findIndex(selectedDefaultPinnedItems, (i) => i.id === ii.configurationId) > -1,
              device: this.state.manageAssociatedItemsModal.device,
              position: ii.position
            }
          })
        }
      })

      this.setState({isLoading: true})
      loadingBar.start()
      const apex = (type !== 'orderType') ? `${type}s` : ''

      // Tests should always go first, then replace
      // Also make sure higher position items comes first / per operation.
      patch = _.sortBy(patch, (item) => {
        if (item.op === 'test') {
          return `a_${100000 - parseInt(item.path.replace('/', ''))}`
        }
        if (item.op === 'replace') {
          return `b_${100000 - parseInt(item.path.replace('/', ''))}`
        }
        if (item.op === 'remove') {
          return `c_${100000 - parseInt(item.path.replace('/', ''))}`
        }

        return `d_${100000 - parseInt(item.path.replace('/', ''))}`
      })

      api.patch(`/orderGroups/${orderGroup.id}/${apex}?device=${this.state.manageAssociatedItemsModal.device}`, patch)
        .then(
          () => {
            // Update the state
            this.setState({isLoading: false}, () => {
              loadingBar.done()
              this.closeModal()
              showMessage('success', __('Order Group updated successfully'))
            })
          }
        ).catch((error) => {
        showMessage('error', `${__('ServerSideError')}. ${error}`)
      })
    } else { // Order Types
      const putData = [].concat(
        this.getAssociatedItemsByDeviceAndType(
          this.state.manageAssociatedOrderTypesModal.device,
          this.state.manageAssociatedOrderTypesModal.type
        )
      ).map((i) => ({
        orderGroupId: orderGroup.id,
        orderTypeId: i?.id
      }))

      this.setState({isLoading: true})
      loadingBar.start()
      api.put(`/orderGroups/${orderGroup.id}/orderTypes`, putData)
        .then(
          () => {
            // Update the state
            this.setState({isLoading: false}, () => {
              loadingBar.done()
              this.closeModal()
              showMessage('success', __('Order Group updated successfully'))
            })
          }
        ).catch((error) => {
        showMessage('error', `${__('ServerSideError')}. ${error}`)
      })
    }
  }

  render() {
    const {
      isLoading,
      mode,
      guiUser,
      orderGroup
    } = this.props

    const {rightMappings} = global.constants

    let userRights = []
    if (guiUser) {
      userRights = guiUser.rights
    }

    function getOrders(orders) {
      return orders?.map((order) => ({
        title: (
          <S.TreeNode>
            {order.name}
          </S.TreeNode>
        ),
        searchValue: order.name,
        value: order,
        key: JSON.stringify(order)
      }))
    }

    const renderPanelFooter = () => (
      <div className="group">
        <button className="btn btn-default pull-left" onClick={() => {
          hashHistory.goBack()
        }}>
          <Glyphicon glyph="chevron-left"/>{__('Back')}
        </button>

        {checkUserRights(userRights, rightMappings.CAN_ASSOCIATE_VIEWS_TO_ORDERGROUP)
          && (
            <button
              className={'btn pull-right '.concat(mode === 'create' ? 'btn-success' : 'btn-primary')}
              disabled={isLoading}
              size="large"
              onClick={this.handleSubmit}
            >
              <Glyphicon glyph={(mode === 'edit') ? 'save' : 'plus-sign'}/>
              {__((mode === 'edit') ? __('Save Changes') : __('Create'))}
            </button>
          )}
      </div>
    )

    return (
      <div>
        <Panel>
          <Panel.Heading>{__(`${mode.charAt(0).toUpperCase() + mode.slice(1)} Order Group`)}</Panel.Heading>
          <Panel.Body>
            <form className="form-horizontal report-form">
              <div className="row">
                <div className="col-xs-12 col-md-8 col-lg-7">
                  <Field
                    name="name"
                    component={renderField}
                    label={`${__('Order Group Name')}*`}
                    className="form-control"
                    type="text"
                    labelCol="col-md-5"
                    inputCol="col-md-7"
                  />
                  {
                    mode === 'create'
                    && (
                      <Field
                        name="orderTypes"
                        component={renderTreeSelect}
                        label={__('Order Types')}
                        treeData={getOrders(this.props?.availableOrderTypes)}
                        className="form-control"
                        labelCol="col-md-5"
                        inputCol="col-md-7"
                        required
                      />
                    )
                  }
                </div>
              </div>
              {
                mode === 'create' && <Divider/>
              }
              <div className="row">
                <div className="col-xs-12 col-md-8 col-lg-7">
                  {checkUserRights(userRights, rightMappings.CAN_ASSOCIATE_VIEWS_TO_ORDERGROUP)
                    && (
                      <div className="form-group">
                        <label className="col-sm-5 control-label">
                          {__('Associated Views')}
                        </label>
                        <div className="col-sm-7">
                          <Dropdown
                            id="manage-views-dropdown"
                            disabled={mode === 'create'}
                          >
                            <a
                              disabled={mode === 'create'}
                              className="btn btn-link btn-no-padding"
                              onClick={() => {
                                this.showManageAssociatedItemsModal(config.DEVICE_TYPES.WEB, 'view')
                              }}
                            >
                              {__('Manage associated Web App Views')}
                            </a>
                            <Dropdown.Toggle className="btn btn-default"/>
                            <Dropdown.Menu>
                              <a
                                disabled={mode === 'create'}
                                className="btn btn-link"
                                onClick={() => {
                                  this.showManageAssociatedItemsModal(config.DEVICE_TYPES.MOBILE, 'view')
                                }}
                              >
                                {__('Manage associated Mobile App Views')}
                              </a>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}

                  {checkUserRights(userRights, rightMappings.CAN_ASSOCIATE_REPORTS_TO_ORDERGROUP)
                    && (
                      <div className="form-group">
                        <label className="col-sm-5 control-label">
                          {__('Associated Reports')}
                        </label>
                        <div className="col-sm-7">
                          <Dropdown
                            id="manage-views-dropdown"
                            disabled={mode === 'create'}
                          >
                            <a
                              disabled={mode === 'create'}
                              className="btn btn-link btn-no-padding"
                              onClick={() => {
                                this.showManageAssociatedItemsModal(config.DEVICE_TYPES.WEB, 'report')
                              }}
                            >
                              {__(`Manage associated ${__(config.DEVICE_NAMES[config.DEVICE_TYPES.WEB])} Reports`)}
                            </a>
                            <Dropdown.Toggle className="btn btn-default"/>
                            <Dropdown.Menu>
                              <a
                                disabled={mode === 'create'}
                                className="btn btn-link"
                                onClick={() => {
                                  this.showManageAssociatedItemsModal(config.DEVICE_TYPES.MOBILE, 'report')
                                }}
                              >
                                {__(`Manage associated ${__(config.DEVICE_NAMES[config.DEVICE_TYPES.MOBILE])} Reports`)}
                              </a>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}

                  {checkUserRights(userRights, rightMappings.CAN_SET_PINNED_VIEWS_TO_ORDERGROUP)
                    && (
                      <div className="form-group">
                        <label className="col-sm-5 control-label">
                          {__('Pinned Views')}
                        </label>
                        <div className="col-sm-7">
                          <Dropdown
                            id="manage-views-dropdown"
                            disabled={mode === 'create'}
                          >
                            <a
                              disabled={mode === 'create'}
                              className="btn btn-link btn-no-padding"
                              onClick={() => {
                                this.showManagePinnedItemsModal(config.DEVICE_TYPES.WEB, 'view')
                              }}
                            >
                              {__('Manage Pinned Web App Views')}
                            </a>
                            <Dropdown.Toggle className="btn btn-default"/>
                            <Dropdown.Menu>
                              <a
                                disabled={mode === 'create'}
                                className="btn btn-link"
                                onClick={() => {
                                  this.showManagePinnedItemsModal(config.DEVICE_TYPES.MOBILE, 'view')
                                }}
                              >
                                {__('Manage Pinned Mobile App Views')}
                              </a>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}

                  {checkUserRights(userRights, rightMappings.CAN_SET_PINNED_REPORTS_TO_ORDERGROUP)
                    && (
                      <div className="form-group">
                        <label className="col-sm-5 control-label">
                          {__('Pinned Reports')}
                        </label>
                        <div className="col-sm-7">
                          <Dropdown
                            id="manage-views-dropdown"
                            disabled={mode === 'create'}
                          >
                            <a
                              disabled={mode === 'create'}
                              className="btn btn-link btn-no-padding"
                              onClick={() => {
                                this.showManagePinnedItemsModal(config.DEVICE_TYPES.WEB, 'report')
                              }}
                            >
                              {__('Manage Pinned Web App Reports')}
                            </a>
                            <Dropdown.Toggle className="btn btn-default"/>
                            <Dropdown.Menu>
                              <a
                                disabled={mode === 'create'}
                                className="btn btn-link"
                                onClick={() => {
                                  this.showManagePinnedItemsModal(config.DEVICE_TYPES.MOBILE, 'report')
                                }}
                              >
                                {__('Manage Pinned Mobile App Reports')}
                              </a>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}

                  {(checkUserRights(userRights, rightMappings.CAN_ASSOCIATE_ORDERTYPES_TO_ORDERGROUP) && mode !== 'create')
                    && (
                      <div className="form-group">
                        <label className="col-sm-5 control-label">
                          {__('Associated Order Types')}
                        </label>
                        <div className="col-sm-7">
                          <a
                            disabled={mode === 'create'}
                            className="btn btn-link btn-no-padding"
                            onClick={() => {
                              this.showManageAssociatedItemsModal(null, 'orderType')
                            }}
                          >
                            {__('Manage associated Order Types')}
                          </a>
                        </div>
                      </div>
                    )}
                </div>

                {mode === 'create'
                  && (
                    <div className="col-xs-12">
                      <div className="alert alert-warning gutter-top">
                        <strong>{__('Note')}</strong> {__('cannotAssociateBeforeCreation')}
                      </div>
                    </div>
                  )}

                {mode !== 'create'
                  && (
                    <div className="col-xs-12 col-md-4 col-lg-5">
                      <div className="form-group">
                        <label className="col-sm-5 control-label">{__('creationDateTime')}</label>
                        <div className="col-sm-7">
                          <p className="form-control-static">
                            {moment(orderGroup.creationDatetime).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                {mode !== 'create' && orderGroup && orderGroup.creator
                  && (
                    <div className="col-xs-12 col-md-4 col-lg-5">
                      <div className="form-group">
                        <label className="col-sm-5 control-label">{__('creator')}</label>
                        <div className="col-sm-7">
                          <p className="form-control-static">
                            {orderGroup.creator.username}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                {mode !== 'create'
                  && (
                    <div className="col-xs-12 col-md-4 col-lg-5">
                      <div className="form-group">
                        <label className="col-sm-5 control-label">{__('last_modified_datetime')}</label>
                        <div className="col-sm-7">
                          <p className="form-control-static">
                            {moment(orderGroup.lastUpdateDatetime).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                {mode !== 'create' && orderGroup && orderGroup.modifier
                  && (
                    <div className="col-xs-12 col-md-4 col-lg-5">
                      <div className="form-group">
                        <label className="col-sm-5 control-label">{__('LastModifiedBy')}</label>
                        <div className="col-sm-7">
                          <p className="form-control-static">
                            {orderGroup.modifier.username}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </form>
          </Panel.Body>
          <Panel.Footer>{renderPanelFooter()}</Panel.Footer>
        </Panel>

        <Modal
          backdrop="static"
          bsSize="lg"
          show={this.state.manageAssociatedItemsModal.open}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">
              {this.state.manageAssociatedItemsModal.device && __(`${this.state.manageAssociatedItemsModal.device} App`)}
              {this.state.manageAssociatedItemsModal.device && ' '}
              {__(`Associated ${this.state.manageAssociatedItemsModal.type}s`)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-5 col-md-offset-1">
                <h5>{__(`Available ${this.state.manageAssociatedItemsModal.type}s`)}</h5>

                {this.state.manageAssociatedItemsModal.open
                  && _.filter(this.getAvailableItemsByType(this.state.manageAssociatedItemsModal.type), (i) => {
                    const found = _.find(
                      this.getAssociatedItemsByDeviceAndType(
                        this.state.manageAssociatedItemsModal.device,
                        this.state.manageAssociatedItemsModal.type
                      ),
                      (y) => i.id === y.configurationId
                    )
                    return (typeof found === 'undefined')
                  }).length === 0
                  && <div className="text-info">{__('There are no available items')}</div>}

                <ul className="list-group">
                  {orderGroup
                    && _.filter(this.getAvailableItemsByType(this.state.manageAssociatedItemsModal.type), (i) => {
                      const found = _.find(
                        this.getAssociatedItemsByDeviceAndType(
                          this.state.manageAssociatedItemsModal.device,
                          this.state.manageAssociatedItemsModal.type
                        ),
                        (y) => i.id === y.configurationId
                      )
                      return (typeof found === 'undefined')
                    }).map((o) => (
                      <li key={`pinned-items-a-${o.id}`} className="list-group-item">
                        {canUserAssociateEntityOfTypeToOrderGroup({userRights, type: o.type})
                          && (
                            <a
                              className="pull-right"
                              onClick={() => {
                                this.props.addItems(this.state.manageAssociatedItemsModal.device, [o.id], this.state.manageAssociatedItemsModal.type)
                              }}
                            >
                              <Glyphicon glyph="plus"/>
                            </a>
                          )}
                        {o.name}
                      </li>
                    ))}
                </ul>
              </div>

              <div className="col-md-5">
                <h5>{__(`Selected ${this.state.manageAssociatedItemsModal.type}s`)}</h5>

                {orderGroup && this.state.manageAssociatedItemsModal.open
                  && this.getAssociatedItemsByDeviceAndType(
                    this.state.manageAssociatedItemsModal.device,
                    this.state.manageAssociatedItemsModal.type
                  ).length === 0
                  && <div className="text-info">{__('No items selected yet')}</div>}

                <ul className="list-group">
                  {orderGroup && this.getAssociatedItemsByDeviceAndType(
                      this.state.manageAssociatedItemsModal.device,
                      this.state.manageAssociatedItemsModal.type
                    ) && this.state.manageAssociatedItemsModal.open
                    && this.getAssociatedItemsByDeviceAndType(
                      this.state.manageAssociatedItemsModal.device,
                      this.state.manageAssociatedItemsModal.type
                    ).map((v) => (
                      <li key={`pinned-items-b-${v.configuration.id}`} className="list-group-item">
                        <a href={`/#/customViews/${v.configuration.id}/edit`}>{v.configuration.name}</a>
                        {canUserAssociateEntityOfTypeToOrderGroup({userRights, type: v.configuration.type})
                          && (
                            <a
                              className="pull-right"
                              onClick={() => {
                                this.props.removeItems(this.state.manageAssociatedItemsModal.device, [v.configurationId], this.state.manageAssociatedItemsModal.type)
                              }}
                            >
                              <Glyphicon glyph="remove"/>
                            </a>
                          )}

                        <div className="clearfix">
                          <div className="col-xs-12 no-padding"
                               style={{borderTop: '1px solid #eee', marginTop: '10px', paddingTop: '5px'}}>
                            <label>
                              <input
                                disabled={!checkUserRights(userRights, rightMappings.CAN_MARK_AS_DEFAULT_PINNED)}
                                type="checkbox"
                                checked={_.findIndex(this.getSelectedDefaultPinnedItemsByType(this.state.manageAssociatedItemsModal.type), (i) => i.configurationId === v.configurationId) > -1}
                                onClick={() => this.toggleDefaultPinnedItem(v.configuration)}
                              /> {__('Set as default Pinned item')}
                            </label>
                          </div>
                        </div>
                      </li>

                    ))}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <Button className="pull-left" onClick={this.closeModal}>
                {__('Cancel')}
              </Button>
              <button
                onClick={() => {
                  this.handleSetItems(this.state.manageAssociatedItemsModal.type)
                }}
                className="btn btn-primary pull-right"
              >
                {__('Save')}
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          backdrop="static"
          bsSize="lg"
          show={this.state.manageAssociatedOrderTypesModal.open}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">
              {__('Associated Order Types')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-5 col-md-offset-1">
                <h5>{__(`Available ${this.state.manageAssociatedOrderTypesModal.type}s`)}</h5>
                {this.state.manageAssociatedOrderTypesModal.open
                  && _.filter(this.getAvailableItemsByType(this.state.manageAssociatedOrderTypesModal.type), (i) => {
                    const found = _.find(
                      this.getAssociatedItemsByDeviceAndType(
                        this.state.manageAssociatedOrderTypesModal.device,
                        this.state.manageAssociatedOrderTypesModal.type
                      ),
                      (y) => i?.id === y?.configurationId
                    )
                    return (typeof found === 'undefined')
                  })?.length === 0
                  && <div className="text-info">{__('There are no available items')}</div>}

                <ul className="list-group">
                  {orderGroup
                    && _.filter(this.getAvailableItemsByType(this.state.manageAssociatedOrderTypesModal.type), (i) => {
                      const found = _.find(
                        this.getAssociatedItemsByDeviceAndType(
                          this.state.manageAssociatedOrderTypesModal.device,
                          this.state.manageAssociatedOrderTypesModal.type
                        ),
                        (y) => i?.id === y?.id
                      )
                      return (typeof found === 'undefined')
                    }).map((o) => (
                      <li key={`pinned-items-a-${o?.id}`} className="list-group-item">
                        <a
                          className="pull-right"
                          onClick={() => {
                            this.props.addItems(this.state.manageAssociatedOrderTypesModal.device, [o?.id], this.state.manageAssociatedOrderTypesModal.type)
                          }}
                        >
                          <Glyphicon glyph="plus"/>
                        </a>
                        {o?.name}
                      </li>
                    ))}
                </ul>
              </div>

              <div className="col-md-5">
                <h5>{__(`Selected ${this.state.manageAssociatedOrderTypesModal.type}s`)}</h5>

                {orderGroup && this.state.manageAssociatedOrderTypesModal.open
                  && this.getAssociatedItemsByDeviceAndType(
                    this.state.manageAssociatedOrderTypesModal.device,
                    this.state.manageAssociatedOrderTypesModal.type
                  )?.length === 0
                  && <div className="text-info">{__('No items selected yet')}</div>}

                <ul className="list-group">
                  {orderGroup && this.getAssociatedItemsByDeviceAndType(
                      this.state.manageAssociatedOrderTypesModal.device,
                      this.state.manageAssociatedOrderTypesModal.type
                    ) && this.state.manageAssociatedOrderTypesModal.open
                    && this.getAssociatedItemsByDeviceAndType(
                      this.state.manageAssociatedOrderTypesModal.device,
                      this.state.manageAssociatedOrderTypesModal.type
                    ).map((v) => (
                      <li key={`pinned-items-b-${v?.id}`} className="list-group-item">
                        {v?.name}
                        <a
                          className="pull-right"
                          onClick={() => {
                            this.props.removeItems(this.state.manageAssociatedOrderTypesModal.device, [v?.id], this.state.manageAssociatedOrderTypesModal.type)
                          }}
                        >
                          <Glyphicon glyph="remove"/>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <Button className="pull-left" onClick={this.closeModal}>
                {__('Cancel')}
              </Button>
              <button
                onClick={() => {
                  this.handleSetItems(this.state.manageAssociatedOrderTypesModal.type)
                }}
                className="btn btn-primary pull-right"
              >
                {__('Save')}
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          backdrop="static"
          show={this.state.managePinnedItemsModal.open}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">
              {__(config.DEVICE_NAMES[this.state.managePinnedItemsModal.device])} {__(`Pinned ${this.state.managePinnedItemsModal.type}s`)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-xs-12 gutter-bottom">
                <Glyphicon glyph="info-sign"/>{' '}
                <i>
                  {this.state.managePinnedItemsModal.type === 'view' && __('MaxPinnedViewsPerGroup')}
                  {this.state.managePinnedItemsModal.type === 'report' && __('MaxPinnedReportsPerGroup')}
                  {this.state.managePinnedItemsModal.type === 'orderType' && __('MaxPinnedOrderTypesPerGroup')}

                  : {this.getMaxPinnedItems(this.state.managePinnedItemsModal.type)}
                </i>
              </div>

              <div className="col-xs-12">
                <h5>Set Pinned items:</h5>

                {orderGroup && this.state.managePinnedItemsModal.open
                  && this.getAssociatedItemsByDeviceAndType(
                    this.state.managePinnedItemsModal.device,
                    this.state.managePinnedItemsModal.type
                  )?.length === 0
                  && <i className="text-info">{__('There are no associated items yet')}.</i>}

                {
                  <ul className="col-xs-12 list-group">
                    {orderGroup && this.state.managePinnedItemsModal.open
                      && this.getAssociatedItemsByDeviceAndType(
                        this.state.managePinnedItemsModal.device,
                        this.state.managePinnedItemsModal.type
                      ).map((v) => (
                        <li key={`pinned-items-c-${v.configuration.id}`} className="list-group-item">
                          <label>
                            <input
                              style={{marginRight: '10px'}}
                              type="checkbox"
                              checked={
                                _.findIndex(
                                  this.getSelectedPinnedItemsByType(this.state.managePinnedItemsModal.type),
                                  (i) => i.id === v.configuration.id
                                ) > -1
                              }
                              onClick={() => this.togglePinnedItem(this.state.managePinnedItemsModal.type, v.configuration)}
                            />
                            {v.configuration.name}
                          </label>
                        </li>
                      ))}
                  </ul>
                }
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <Button
                className="pull-left"
                onClick={this.closeModal}
              >
                {__('Cancel')}
              </Button>

              <button
                onClick={() => this.handleSetPinnedItems(this.state.managePinnedItemsModal.type, this.state.managePinnedItemsModal.device)}
                className="btn btn-primary pull-right"
              >
                {__('Save')}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

OrderGroupForm.propTypes = {
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  addItems: PropTypes.func,
  removeItems: PropTypes.func,
  isLoading: PropTypes.bool,
  mode: PropTypes.string,
  orderGroup: PropTypes.object,
  initialOrderGroupValues: PropTypes.object,
  getData: PropTypes.func,
  availableReports: PropTypes.array,
  availableViews: PropTypes.array,
  availableOrderTypes: PropTypes.array,
  associatedViews: PropTypes.array,
  associatedReports: PropTypes.array,
  initialAssociatedViews: PropTypes.array,
  initialAssociatedReports: PropTypes.array,
  guiUser: PropTypes.object
}

OrderGroupForm.defaultProps = {
  associatedViews: null,
  associatedReports: null
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const selector = formValueSelector('orderGroupForm')

const OrderGroupReduxForm = reduxForm({
  form: 'orderGroupForm',
  validate
}, mapDispatchToProps)(OrderGroupForm)

export default connect(
  (state) => ({
    guiUser: state.user.data,
    availableViews: state.user.views,
    availableReports: state.user.reports,
    availableOrderTypes: state.user.orderTypes,
    reportType: selector(state, 'type')
  }),
  {}
)(OrderGroupReduxForm)
