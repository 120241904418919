import moment from 'moment'
import { produceWorkingDayEvent } from '../../../../utils/calendarEvents'

const { WORKING_EVENT } = global.constants.eventTypes

export default ({ calendarListWithStepEvents, selectedDate, editable }) => calendarListWithStepEvents?.reduce((acc, val) => {
  if (!val?.events?.length) return acc
  const { user, events } = val

  return [
    ...acc,
    ...events.filter((e) => e).map((event) => {
      if (event.eventType === WORKING_EVENT) {
        return [-3, -2, -1, 0, 1, 2, 3].map((deltaWeek) => ({ // this is required in order to generate the necessary repeated working events throught the weeks,
          ...event,
          user,
          group: user.id,
          ...produceWorkingDayEvent({ ...event, selectedDate: moment(selectedDate).add(deltaWeek, 'week') }),
          ...(editable ? {} : { id: Math.random() }),
          lineHeight: 1
        }))
      }
      return {
        ...event,
        user,
        group: user.id,
        ...(editable ? {} : { id: Math.random() })
      }
    }) || []
  ].flat(1)
}, [])
