import _ from 'lodash'

export const consolidateSubObjectsData = (props) => {
  const { attributes, displayAttributes, action } = props || {}
  if (!attributes?.length || !displayAttributes?.length) return []
  const displayAttributesMap = _.mapValues(_.keyBy(displayAttributes, 'attributeId'))
  return attributes.reduce((prev, attribute) => {
    const displayAttribute = displayAttributesMap?.[attribute?.id]
    if (!attribute || !displayAttribute?.gridPosition) return prev
    prev.push({
      ...attribute,
      ...displayAttribute,
      currentPage: 1,
      pageSize: 5,
      editable: action === "edit" ? (displayAttribute.editable && attribute.editable) : action === "create",
      controllerType: displayAttribute.controllerType || displayAttribute.defaultControllerType || 'TextEntry',
      controllerOptions: displayAttribute.controllerOptions,
    })
    return prev
  }, [])
}

export const consolidateDeprecatedSubObjectsData = (props) => {
  const { attributes, displayAttributes, action } = props || {}
  if (!attributes?.length || !displayAttributes?.length) return []
  const displayAttributesMap = _.mapValues(_.keyBy(displayAttributes, 'attributeId'))
  return attributes.reduce((prev, attribute) => {
    const displayAttribute = displayAttributesMap?.[attribute?.id]
    if (!displayAttribute || attribute.type === 'IDS') return prev
    const index = prev.length
    prev.push({
      ...attribute,
      ...displayAttribute,
      currentPage: 1,
      pageSize: 5,
      editable: action === "edit" ? (displayAttribute.editable && attribute.editable) : action === "create",
      controllerType: displayAttribute.controllerType || displayAttribute.defaultControllerType || 'TextEntry',
      controllerOptions: displayAttribute.controllerOptions,
      gridPosition: {
        w: 3,
        h: 1,
        x: ((index % 4) * 3).toString(),
        y: Math.floor(index / 4).toString()
      }
    })
    return prev
  }, [])
}
