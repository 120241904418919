import React, { useContext, useEffect } from 'react'

import { useThunkReducer } from 'react-hook-thunk-reducer'

import * as A from "./actions"
import * as R from './reducer'
import useBoundActionCreators from '../../../../../../hooks/useBoundActionCreators'

export const ObjectSelectorContext = React.createContext(null)

export const useObjectConfiguratorContext = () => useContext(ObjectSelectorContext)

export default (props) => {
  const initialState = R.getInitialState()

  const { children, value } = props

  const [state, dispatch] = useThunkReducer(R.ObjectSelectorReducer, initialState, R.initState)
  const boundActions = useBoundActionCreators(A, dispatch)

  useEffect(() => {
    boundActions.initialize(props)
  }, [])

  useEffect(() => {
    boundActions.updateValue(value)
  }, [value])

  return (
    <ObjectSelectorContext.Provider
      value={{
        state,
        dispatch,
        boundActions
      }}
    >
      {children}
    </ObjectSelectorContext.Provider>
  )
}
