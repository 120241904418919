import {connect} from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import objectAssign from 'object-assign'
import {getFormValues} from 'redux-form'
import _ from 'lodash'
import {Button, Modal} from 'react-bootstrap'
import {hashHistory} from '../providers/HistoryProvider'
import {getGuiUser} from '../providers/ReduxProvider/actions/userActions'
import CustomReportForm from '../components/CustomReportForm'
import {createReport, updateReport} from '../providers/ReduxProvider/actions/reportActions'
import {createView, updateView} from '../providers/ReduxProvider/actions/viewActions'
import {createRemoteObject, updateRemoteObject} from '../providers/ReduxProvider/actions/remoteObjectActions'
import {createStepObject, updateStepObject} from '../providers/ReduxProvider/actions/stepObjectActions'
import {createOverviewObject, updateOverviewObject} from '../providers/ReduxProvider/actions/overviewObjectActions'
import {listingLayouts} from '../constants'
import {api} from '../providers/ApiProvider'
import {__} from '../utils/translationUtils'

const loadingBar = require('nprogress')

const regex = /[^@]+/g

class CustomReportPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      objectId: this.props.match.params.id,
      mode: this.props.mode,
      report: {
        name: '',
        type: 'SELF',
        searchAttributes: [],
        sortAttributes: [],
        displayAttributes: []
      },
      initialValues: null,
      attributesConfiguration: null,
      attributesConfigurationTree: null,
      isLoading: false,
      attributesNeedMapping: {},
      mappingDone: false,
      valueTypesMappings: null
    }
    this.getAttributesConfiguration = this.getAttributesConfiguration.bind(this)
    this.getAttributesConfigurationTree = this.getAttributesConfigurationTree.bind(this)
    this.getValueTypeMappings = this.getValueTypeMappings.bind(this)
    this.getData = this.getData.bind(this)
    this.getImportedData = this.getImportedData.bind(this)
    this.extractAttributeConfig = this.extractAttributeConfig.bind(this)
    this.setSearchAttrtibutes = this.setSearchAttrtibutes.bind(this)
    this.setDisplayAttrtibutes = this.setDisplayAttrtibutes.bind(this)
    this.setSortAttributes = this.setSortAttributes.bind(this)
    this.normalizeSearchAttributesSorting = this.normalizeSearchAttributesSorting.bind(this)
    this.normalizeSortAttributesSorting = this.normalizeSortAttributesSorting.bind(this)
    this.normalizeDisplayFiledsSorting = this.normalizeDisplayFiledsSorting.bind(this)
    this.renderMappingModal = this.renderMappingModal.bind(this)
    this.findAttributeId = this.findAttributeId.bind(this)
    this.showMappingModal = this.showMappingModal.bind(this)

    this.renderSystemOptions = this.renderSystemOptions.bind(this)
    this.renderDependenciesOptions = this.renderDependenciesOptions.bind(this)
    this.renderPropertiesOptions = this.renderPropertiesOptions.bind(this)
    this.submitMapping = this.submitMapping.bind(this)
  }

  componentDidMount() {
    this.init()
  }

  init() {
    this.getValueTypeMappings()

    if (this.props.mode === 'create') {
      this.getAttributesConfiguration()
        .then(() => {
          // Check if need to import file

          if (this.props.location && this.props.location.search && this.props.location.search === '?fromImport') {
            if (this.props.importData) {
              this.getImportedData()
            }
          } else {
            this.setState({
              mappingDone: true,
              initialValues: {
                settings: {
                  pageLayout: {
                    pageSize: 25,
                    listingLayout: {defaultSelected: listingLayouts.TABLE}
                  }
                }
              }
            })
          }
        })
      return
    }

    this.getData()
      .then(() => {
        this.getAttributesConfiguration()
      })
  }

  renderSystemOptions() {
    const {remoteSystems} = this.props
    return [
      <option key="dependency-select">{__('Select target System')}</option>,
      remoteSystems.map((system, i) => (
        <option key={`system-${i}`} value={system.id}>{__(system.name)}</option>
      ))
    ]
  }

  renderDependenciesOptions(attributeId) {
    const options = this.state.attributesNeedMapping[attributeId]

    if (options && options.system) {
      const filtered = _.filter(this.state.attributesConfiguration, (attr) => attr.system === options.system)
      const unique = _.uniqBy(_.map(filtered, (a) => ({
        dependencies: a.dependencies,
        dependenciesLabel: a.dependenciesLabel
      })), 'dependencies')
      return [
        <option key="dependency-select">{__('Select target Dependency')}</option>,
        unique.map((a, i) => (
          <option key={i} value={a.dependencies}>{a.dependenciesLabel}</option>
        ))
      ]
    }
    return null
  }

  renderPropertiesOptions(attributeId) {
    const options = this.state.attributesNeedMapping[attributeId]

    if (options && options.system && options.dependencies) {
      const filtered = _.filter(this.state.attributesConfiguration, (attr) => attr.system === options.system && attr.dependencies === options.dependencies)

      const unique = _.uniqBy(_.map(filtered, (a) => ({
        property: a.property,
        propertyLabel: a.propertyLabel
      })), 'property')
      return [
        <option key="property-select">{__('Select target Property')}</option>,
        unique.map((p, i) => (
          <option key={i} value={p.property}>{__(p.propertyLabel)}</option>
        ))
      ]
    }
    return null
  }

  submitMapping() {
    const {attributesNeedMapping, report} = this.state

    const _report = {...report}

    _report.searchAttributes.forEach((attr) => {
      if (attr.attributeId && attr.attributeId.substring(0, 2) === '@@') {
        const foundAttrId = attributesNeedMapping[attr.attributeId] && this.findAttributeId({
          system: attributesNeedMapping[attr.attributeId].system.toString(),
          dependencies: attributesNeedMapping[attr.attributeId].dependencies,
          property: attributesNeedMapping[attr.attributeId].property
        })
        if (foundAttrId) {
          attr.attributeId = foundAttrId
          // Remove from pedning mapping items
          // delete _attributesNeedMapping[_attributeId];
        }
      }
    })

    _report.displayAttributes.forEach((attr) => {
      if (attr.attributeId && attr.attributeId.substring(0, 2) === '@@') {
        const foundAttrId = attributesNeedMapping[attr.attributeId] && this.findAttributeId({
          system: attributesNeedMapping[attr.attributeId].system.toString(),
          dependencies: attributesNeedMapping[attr.attributeId].dependencies,
          property: attributesNeedMapping[attr.attributeId].property
        })
        if (foundAttrId) {
          attr.attributeId = foundAttrId
          // Remove from pedning mapping items
          // delete _attributesNeedMapping[_attributeId];
        }
      }
    })

    _report.sortAttributes.forEach((attr) => {
      if (attr.attributeId && attr.attributeId.substring(0, 2) === '@@') {
        const foundAttrId = attributesNeedMapping[attr.attributeId] && this.findAttributeId({
          system: attributesNeedMapping[attr.attributeId].system.toString(),
          dependencies: attributesNeedMapping[attr.attributeId].dependencies,
          property: attributesNeedMapping[attr.attributeId].property
        })
        if (foundAttrId) {
          attr.attributeId = foundAttrId
          // Remove from pedning mapping items
          // delete _attributesNeedMapping[_attributeId];
        }
      }
    })

    this.setState({report: _report}, () => {
      // Update pending mappings
      const _attributesNeedMapping = {...attributesNeedMapping}
      Object.keys(_attributesNeedMapping).forEach((am) => {
        // Check if is mapped in all cases
        const searchClear = _.find(_report.searchAttributes, (sa) => sa.attributeId === am)
        const displayClear = _.find(_report.displayAttributes, (da) => da.attributeId === am)
        const sortClear = _.find(_report.sortAttributes, (sa) => sa.attributeId === am)
        if (searchClear === undefined && displayClear === undefined && sortClear === undefined) {
          delete _attributesNeedMapping[am]
        }
      })
      this.setState({attributesNeedMapping: _attributesNeedMapping})
    })
  }

  getValueTypeMappings() {
    return api.get('/configurations/valueTypes/mappings')
      .then(
        (response) => {
          this.setState({valueTypesMappings: response.data})
        }
      )
  }

  getImportedData() {
    const {importData} = this.props
    const reportData = objectAssign({}, {...importData.configuration})
    const initialValues = {
      name: reportData.name,
      onlySelfOrders: false,
      type: 'SELF',
      entityIds: null
    }

    const _attributesNeedMapping = {}

    // Start Mapping:
    const mappedAttributes = []

    const _displayAttributes = []
    reportData.displayAttributes.forEach(
      (attr) => {
        let foundId = null
        const match = attr.attributeId.match(regex)

        if (match !== null && match.length > 0) {
          foundId = this.findAttributeId({
            system: match[0],
            dependencies: match[1],
            property: match[2]
          })
        }

        const _attr = objectAssign(attr)
        _displayAttributes.push(objectAssign({..._attr}, {attributeId: foundId || attr.attributeId}))

        if (foundId) { // Found
          mappedAttributes.push({
            source: attr.attributeId,
            destination: foundId
          })

          if (typeof attr.summary !== 'undefined') {
            initialValues[`${foundId}_summary`] = attr.summary
          }

          if (typeof attr.editable !== 'undefined') {
            initialValues[`${foundId}_editable`] = attr.editable
          }

          if (typeof attr.controllerType !== 'undefined') {
            initialValues[`${foundId}_controllerType`] = attr.controllerType
          }
        } else { // Cannot be mapped
          _attributesNeedMapping[attr.attributeId] = objectAssign(
            {..._attr},
            {
              system: match !== null && match[0] || null,
              dependencies: match !== null && match[1] || null,
              property: match !== null && match[2] || null
            }
          )
        }
      }
    )

    const _searchAttributes = []
    reportData.searchAttributes.forEach(
      (attr) => {
        let foundId = null
        const match = attr.attributeId.match(regex)

        if (match !== null && match.length > 0) {
          foundId = this.findAttributeId({
            system: match[0],
            dependencies: match[1],
            property: match[2]
          })
        }

        const _attr = objectAssign(attr)
        _searchAttributes.push(objectAssign({..._attr}, {attributeId: foundId || attr.attributeId}))

        if (foundId) { // Found
          mappedAttributes.push({
            source: attr.attributeId,
            destination: foundId
          })

          if (typeof _attr.multiSelect !== 'undefined') {
            initialValues[`${foundId}_multiSelect`] = _attr.multiSelect
          }

          if (typeof _attr.required !== 'undefined') {
            initialValues[`${foundId}_required`] = _attr.required
          }

          if (typeof _attr.hidden !== 'undefined') {
            initialValues[`${foundId}_hidden`] = _attr.hidden
          }

          if (typeof _attr.mechanism !== 'undefined') {
            initialValues[`${foundId}_mechanism`] = _attr.mechanism
          }

          if (typeof _attr.value !== 'undefined') {
            initialValues[`${foundId}_value`] = _attr.value
          }
        } else { // Cannot be mapped
          _attributesNeedMapping[attr.attributeId] = objectAssign(
            {..._attr},
            {
              system: match !== null && match[0] || null,
              dependencies: match !== null && match[1] || null,
              property: match !== null && match[2] || null
            }
          )
        }
      }
    )

    const _sortAttributes = []
    reportData.sortAttributes.forEach(
      (attr) => {
        let foundId = null
        const match = attr.attributeId.match(regex)

        if (match !== null && match.length > 0) {
          foundId = this.findAttributeId({
            system: match[0],
            dependencies: match[1],
            property: match[2]
          })
        }

        const _attr = objectAssign(attr)
        _sortAttributes.push(objectAssign({..._attr}, {attributeId: foundId || attr.attributeId}))

        if (foundId) { // Found
          mappedAttributes.push({
            source: attr.attributeId,
            destination: foundId
          })
        } else { // Cannot be mapped
          _attributesNeedMapping[attr.attributeId] = objectAssign(
            {..._attr},
            {
              system: match !== null && match[0] || null,
              dependencies: match !== null && match[1] || null,
              property: match !== null && match[2] || null
            }
          )
        }
      }
    )

    delete reportData.id
    delete reportData.lastUpdateDatetime
    delete reportData.modifierId
    delete reportData.creatorId
    delete reportData.entityIds

    // Update the state
    this.setState({
      report: objectAssign(reportData, {
        searchAttributes: _searchAttributes,
        displayAttributes: _displayAttributes,
        sortAttributes: _sortAttributes
      }),
      initialValues,
      attributesNeedMapping: _attributesNeedMapping,
      mappingDone: true
    })
  }

  getData() {
    const {type, mode} = this.props
    this.setState({isLoading: true})
    loadingBar.start()
    let url = (type.toLowerCase() === 'report') ? '/configurations/reports' : '/configurations/views'

    if (type.toLowerCase() === 'remoteobject') {
      url = '/configurations/remoteObjects'
    }

    if (type.toLowerCase() === 'overview_object') {
      url = '/configurations/overviewObjects'
    }

    if (type.toLowerCase() === 'stepobject') {
      url = '/configurations/stepObjects'
    }

    return api.get(`${url}/${this.state.objectId}`)
      .then(
        (response) => {
          const reportData = response.data
          const ids = reportData.entityIds.map((entity) => entity.id)
          const initialValues = {
            ...reportData,
            entityIds: ids
          }

          reportData.searchAttributes.forEach((attr) => {
            if (typeof attr.multiSelect !== 'undefined') {
              initialValues[`${attr.attributeId}_multiSelect`] = attr.multiSelect
            }

            if (typeof attr.required !== 'undefined') {
              initialValues[`${attr.attributeId}_required`] = attr.required
            }

            if (typeof attr.hidden !== 'undefined') {
              initialValues[`${attr.attributeId}_hidden`] = attr.hidden
            }

            if (typeof attr.mechanism !== 'undefined') {
              initialValues[`${attr.attributeId}_mechanism`] = attr.mechanism
            }

            if (typeof attr.value !== 'undefined') {
              initialValues[`${attr.attributeId}_value`] = attr.value
            }

            if (typeof attr.propertyLabel !== 'undefined') {
              initialValues[`${attr.attributeId}_search_propertyLabel`] = attr.propertyLabel
            }

            if (attr.mechanism === 'RANGE') {
              initialValues[`${attr.attributeId}_valueFrom`] = attr.from
              initialValues[`${attr.attributeId}_valueTo`] = attr.to
            }
          })

          reportData.displayAttributes.forEach((attr) => {
            if (typeof attr.summary !== 'undefined') {
              initialValues[`${attr.attributeId}_summary`] = attr.summary
            }

            if (typeof attr.editable !== 'undefined') {
              initialValues[`${attr.attributeId}_editable`] = attr.editable
            }
            if (typeof attr.controllerType !== 'undefined') {
              initialValues[`${attr.attributeId}_controllerType`] = attr.controllerType
            }
            if (typeof attr.propertyLabel !== 'undefined') {
              initialValues[`${attr.attributeId}_display_propertyLabel`] = attr.propertyLabel
            }

          })

          reportData.sortAttributes.forEach((attr) => {
            if (typeof attr.propertyLabel !== 'undefined') {
              initialValues[`${attr.attributeId}_sort_propertyLabel`] = attr.propertyLabel
            }
          })

          if (mode === 'clone') {
            delete reportData.id
            delete reportData.creatorId
            delete initialValues.name
          }

          if (!['report', 'remoteobject', 'overview_object', 'stepobject'].includes(type.toLowerCase())) {
            if (initialValues.settings == null || initialValues.settings.pageLayout == null || initialValues.settings.pageLayout.pageSize == null) {
              initialValues.settings = {
                ...(initialValues.settings || {}),
                pageLayout: {
                  ...(initialValues.settings && initialValues.settings.pageLayout || {}),
                  pageSize: 25
                }
              }
            }
            if (initialValues.settings == null || initialValues.settings.pageLayout == null || initialValues.settings.pageLayout.listingLayout == null || initialValues.settings.pageLayout.listingLayout.defaultSelected == null) {
              initialValues.settings = {
                ...(initialValues.settings || {}),
                pageLayout: {
                  ...(initialValues.settings && initialValues.settings.pageLayout || {}),
                  listingLayout: {
                    ...(initialValues.settings && initialValues.settings.pageLayout && initialValues.settings.pageLayout.listingLayout || {}),
                    defaultSelected: listingLayouts.TABLE
                  }
                }
              }
            }
          }

          // Update the state
          this.setState({
            report: reportData,
            initialValues,
            mappingDone: true
          })
        }
      )
  }

  findAttributeId({system, dependencies, property}) {
    const {attributesConfiguration} = this.state
    const found = _.find(attributesConfiguration, (c) => (
      c.system === system
      && c.dependencies === dependencies
      && c.property === property
    ))
    return found && found.id || null
  }

  handleSubmit({selectedObjectTypeId, selectedSystem}) {
    const {formValues, type, user} = this.props
    const payload = {...this.state.report}

    payload.name = formValues.name
    payload.type = formValues.type
    // TODO: remove.
    // payload.creatorId = '100_58be96d00e823f552aa9a721';
    payload.modifierId = user.id
    payload.onlySelfOrders = !!formValues.onlySelfOrders

    // TODO: use real values.
    if (type.toLowerCase() === 'remoteobject') {
      payload.objectTypeId = selectedObjectTypeId
      payload.systemId = selectedSystem
    } else if (type.toLowerCase() === 'stepobject') {
      payload.type = 'GLOBAL'
      payload.objectTypeId = selectedObjectTypeId
      payload.systemId = selectedSystem
      payload.removableEntries = formValues.removableEntries
      payload.editableEntries = formValues.editableEntries
      payload.addableEntries = formValues.addableEntries
    } else if (type.toLowerCase() === 'overview_object') {
      payload.flowId = formValues.flowId
      payload.flowVersion = formValues.flowVersion
      payload.objectTypeId = selectedObjectTypeId
      payload.systemId = selectedSystem
      payload.editableResults = formValues.editableResults
      payload.removableResults = formValues.removableResults
      payload.addableResults = formValues.addableResults
      payload.type = 'GLOBAL'
    } else if (type.toLowerCase() !== 'stepobject' && type.toLowerCase() !== 'overview_object') {
      payload.settings = formValues.settings || {}
      payload.settings.pageLayout.onlySelfOrders = !!formValues.onlySelfOrders
      if (!payload.settings.pageLayout.listingLayout) {
        payload.settings.pageLayout.listingLayout = listingLayouts.TABLE
      }
    }

    // Set entityIds
    const ids = []
    if (typeof formValues.entityIds !== 'undefined' && formValues.entityIds) {
      formValues.entityIds.forEach((entityId) => {
        ids.push({id: entityId})
      })
    }

    if (payload.type === 'ENTITY') {
      payload.entityIds = ids
    } else if (typeof payload.entityIds !== 'undefined') {
      delete payload.entityIds
    }

    payload.searchAttributes.forEach((a) => {
      a.mechanism = formValues[`${a.attributeId}_mechanism`]
      // obfuscationMethod: null,
      // obfuscationValue: null,
      a.value = formValues[`${a.attributeId}_value`] || null
      if (formValues[`${a.attributeId}_mechanism`] === 'RANGE') {
        a.from = formValues[`${a.attributeId}_valueFrom`] || null
        a.to = formValues[`${a.attributeId}_valueTo`] || null
        a.value = null
      }

      if (a.value !== null && a.value.constructor === Array) {
        a.value = a.value.join(',')
      }
      a.required = formValues[`${a.attributeId}_required`] || false
      a.hidden = formValues[`${a.attributeId}_hidden`] || false
      a.propertyLabel = formValues[`${a.attributeId}_search_propertyLabel`] || null
      if (`${typeof a.attributeId}_multiSelect` !== 'undefined') {
        a.multiSelect = formValues[`${a.attributeId}_multiSelect`] || false
      }
    })

    payload.displayAttributes.forEach((a) => {
      a.summary = formValues[`${a.attributeId}_summary`] || false
      a.controllerType = formValues[`${a.attributeId}_controllerType`] || null

      if (formValues[`${a.attributeId}_controllerOptions`] != null) {
        a.controllerOptions = {
          objectConfiguration: (formValues[`${a.attributeId}_controllerOptions`] || {}),
          type: (a.controllerType || a.defaultControllerType) + "ControllerOptions"
        }
      }

      a.propertyLabel = formValues[`${a.attributeId}_display_propertyLabel`] || null
      if (type.toLowerCase() === 'overview_object' || type.toLowerCase() === 'remoteobject') {
        a.editable = formValues[`${a.attributeId}_editable`] || null
      }
      if (a.controllerType === '') {
        a.controllerType = null
      }
    })

    payload.sortAttributes.forEach((a) => {
      a.propertyLabel = formValues[`${a.attributeId}_sort_propertyLabel`] || null
    })

    if (this.props.mode === 'edit') {
      if (type.toLowerCase() === 'report') {
        this.props.updateReport(payload).then(() => {
          this.props.getGuiUser()
        })
      } else if (type.toLowerCase() === 'view') {
        this.props.updateView(payload).then(() => {
          this.props.getGuiUser()
        })
      } else if (type.toLowerCase() === 'remoteobject') {
        this.props.updateRemoteObject(payload).then(() => {
          this.props.getGuiUser()
        })
      } else if (type.toLowerCase() === 'overview_object') {
        this.props.updateOverviewObject({id: this.state.objectId, params: payload}).then(() => {
          this.props.getGuiUser()
        })
      } else if (type.toLowerCase() === 'stepobject') {
        this.props.updateStepObject({id: this.state.objectId, payload}).then(() => {
          this.props.getGuiUser()
        })
      }
    } else if (type.toLowerCase() === 'report') {
      this.props.createReport(payload).then(() => {
        this.props.getGuiUser()
      })
    } else if (type.toLowerCase() === 'view') {
      this.props.createView(payload).then(() => {
        this.props.getGuiUser()
      })
    } else if (type.toLowerCase() === 'stepobject') {
      this.props.createStepObject(payload, this.props.onObjectCreate).then(() => {
        this.props.getGuiUser()
      })
    } else if (type.toLowerCase() === 'overview_object') {
      this.props.createOverviewObject(payload, this.props.onObjectCreate).then(() => {
        this.props.getGuiUser()
      })
    } else if (type.toLowerCase() === 'remoteobject') {
      this.props.createRemoteObject(payload, this.props.onObjectCreate, true).then(() => {
        this.props.getGuiUser()
      })
    }
  }

  getAttributesConfiguration() {
    return api.get('configurations/attributes')
      .then(
        (response) => {
          this.setState({
            attributesConfiguration: response.data,
            isLoading: false
          }, () => {
            this.getAttributesConfigurationTree()
          })
        }
      )
  }

  getAttributesConfigurationTree() {
    return api.get('configurations/attributes/tree')
      .then(
        (response) => {
          this.setState({
            attributesConfigurationTree: response.data,
            isLoading: false
          })
          loadingBar.done()
        }
      )
  }

  showMappingModal() {
    const {attributesNeedMapping} = this.state
    return (Object.keys(attributesNeedMapping).length > 0)
  }

  extractAttributeConfig(id) {
    return _.find(this.state.attributesConfiguration, (a) => a.id === id)
  }

  setSearchAttrtibutes(attributes) {
    this.setState({report: objectAssign(this.state.report, {searchAttributes: attributes})})
  }

  setDisplayAttrtibutes(attributes) {
    this.setState({report: objectAssign(this.state.report, {displayAttributes: attributes})})
  }

  setSortAttributes(attributes) {
    this.setState({report: objectAssign(this.state.report, {sortAttributes: attributes})})
  }

  handleAddSearchAttribute(attribute) {
    const attributes = [].concat(this.state.report.searchAttributes)
    attributes.push({
      attributeId: attribute.id,
      position: attributes.length,
      multiSelect: attributes.multiSelect
    })
    this.setState({report: objectAssign(this.state.report, {searchAttributes: attributes})}, () => {
      this.normalizeSearchAttributesSorting()
    })
  }

  handleRemoveSearchAttribute(attribute) {
    const attributes = [].concat(this.state.report.searchAttributes)
    const idx = _.findIndex(attributes, (a) => a.attributeId === attribute.attributeId)
    attributes.splice(idx, 1)
    this.setState({report: objectAssign(this.state.report, {searchAttributes: attributes})}, () => {
      this.normalizeSearchAttributesSorting()
    })
  }

  handleAddSortAttribute(attribute) {
    const attributes = [].concat(this.state.report.sortAttributes)
    attributes.push({
      attributeId: attribute.id,
      position: attributes.length,
      value: 'ASC'
    })
    this.setState({report: objectAssign(this.state.report, {sortAttributes: attributes})}, () => {
      this.normalizeSortAttributesSorting()
    })
  }

  handleRemoveSortAttribute(attribute) {
    const attributes = [].concat(this.state.report.sortAttributes)
    const idx = _.findIndex(attributes, (a) => a.attributeId === attribute.attributeId)
    attributes.splice(idx, 1)
    this.setState({report: objectAssign(this.state.report, {sortAttributes: attributes})}, () => {
      this.normalizeSortAttributesSorting()
    })
  }

  handleAddDisplayField(attribute) {
    const attributes = [].concat(this.state.report.displayAttributes)

    attributes.push({
      attributeId: attribute.id,
      position: attributes.length
    })
    this.setState({report: objectAssign(this.state.report, {displayAttributes: attributes})}, () => {
      this.normalizeDisplayFiledsSorting()
    })
  }

  handleRemoveDisplayField(attribute) {
    const attributes = [].concat(this.state.report.displayAttributes)
    const idx = _.findIndex(attributes, (a) => a.attributeId === attribute.attributeId)
    attributes.splice(idx, 1)
    this.setState({report: objectAssign(this.state.report, {displayAttributes: attributes})}, () => {
      this.normalizeDisplayFiledsSorting()
    })
  }

  handleResetAttributes() {
    this.setState({
      report: objectAssign(this.state.report, {
        displayAttributes: [],
        searchAttributes: [],
        sortAttributes: []
      })
    }, () => {
      this.normalizeDisplayFiledsSorting()
    })
  }

  handleAddSortField(attribute) {
    const attributes = [].concat(this.state.report.sortAttributes)
    attributes.push({
      attributeId: attribute.id,
      position: attributes.length
    })
    this.setState({report: objectAssign(this.state.report, {sortAttributes: attributes})}, () => {
      this.normalizeDisplayFiledsSorting()
    })
  }

  handleRemoveSortField(attribute) {
    const attributes = [].concat(this.state.report.sortAttributes)
    const idx = _.findIndex(attributes, (a) => a.attributeId === attribute.attributeId)
    attributes.splice(idx, 1)
    this.setState({report: objectAssign(this.state.report, {sortAttributes: attributes})}, () => {
      this.normalizeDisplayFiledsSorting()
    })
  }

  handleSetSortAttributeValue(attribute, value) {
    const attributes = [].concat(this.state.report.sortAttributes)
    const idx = _.findIndex(attributes, (a) => a.attributeId === attribute.attributeId)
    attributes[idx].value = value

    this.setState({report: objectAssign(this.state.report, {sortAttributes: attributes})}, () => {
      this.normalizeDisplayFiledsSorting()
    })
  }

  normalizeSearchAttributesSorting() {
    const attributes = this.state.report.searchAttributes
    attributes.forEach((a, idx) => {
      a.position = idx
    })

    this.setState({report: objectAssign(this.state.report, {searchAttributes: attributes})})
  }

  normalizeSortAttributesSorting() {
    const attributes = this.state.report.sortAttributes
    attributes.forEach((a, idx) => {
      a.position = idx
    })

    this.setState({report: objectAssign(this.state.report, {sortAttributes: attributes})})
  }

  normalizeDisplayFiledsSorting() {
    const attributes = _.sortBy(this.state.report.displayAttributes, 'position')
    attributes.forEach((a, idx) => {
      a.position = idx
    })

    this.setState({report: objectAssign(this.state.report, {displayAttributes: attributes})})
  }

  renderMappingModal() {
    const {attributesNeedMapping} = this.state
    const {importData} = this.props
    return (
      <div>
        Pending Mapping: {Object.keys(attributesNeedMapping).length} item(s).
        {attributesNeedMapping
          && Object.keys(attributesNeedMapping).map((attributeId, i) => {
            let match = importData.referenceLabels[attributeId].match(regex)
            if (match == null) {
              match = {0: 'NA', 1: 'NA', 2: 'NA'}
            }
            return (
              <div className="mapping-item" key={i}>
                <form className="">
                  <div className="form-group">
                    <label><strong>{__(match[0])}</strong> ({__('Origin System')})</label>
                    <select
                      value={this.state.attributesNeedMapping[attributeId] && this.state.attributesNeedMapping[attributeId].system}
                      className="form-control"
                      onChange={(e) => {
                        const mapping = _.find(this.state.attributesNeedMapping, (m) => m.attributeId === attributeId)
                        mapping.system = e.target.value
                        const _attributesNeedMapping = objectAssign({}, {...this.state.attributesNeedMapping})
                        _attributesNeedMapping[attributeId] = mapping

                        this.setState({attributesNeedMapping: _attributesNeedMapping})
                      }}
                    >
                      {this.renderSystemOptions()}
                    </select>
                  </div>
                  <div className="form-group">
                    <label><strong>{__(match[1])}</strong> ({__('Origin Dependencies')})</label>
                    <select
                      value={this.state.attributesNeedMapping[attributeId] && this.state.attributesNeedMapping[attributeId].dependencies}
                      className="form-control"
                      onChange={(e) => {
                        const _mapping = _.find(this.state.attributesNeedMapping, (m) => m.attributeId === attributeId)
                        _mapping.dependencies = e.target.value
                        const _attributesNeedMapping = objectAssign({}, {...this.state.attributesNeedMapping})
                        _attributesNeedMapping[attributeId] = _mapping

                        this.setState({attributesNeedMapping: _attributesNeedMapping})
                      }}
                    >
                      {this.renderDependenciesOptions(attributeId)}
                    </select>
                  </div>

                  <div className="form-group">
                    <label><strong>{__(match[2])}</strong> ({__('Origin Property')})</label>
                    <select
                      value={this.state.attributesNeedMapping[attributeId] && this.state.attributesNeedMapping[attributeId].property}
                      className="form-control"
                      onChange={(e) => {
                        const _mapping = _.find(this.state.attributesNeedMapping, (m) => m.attributeId === attributeId)
                        _mapping.property = e.target.value
                        const _attributesNeedMapping = objectAssign({}, {...this.state.attributesNeedMapping})
                        _attributesNeedMapping[attributeId] = _mapping

                        this.setState({attributesNeedMapping: _attributesNeedMapping})
                      }}
                    >
                      {this.renderPropertiesOptions(attributeId)}
                    </select>
                  </div>
                </form>
              </div>
            )
          })}
      </div>
    )
  }

  render() {
    const {type, location, match, hideCancel} = this.props

    const {params} = match
    const {
      valueTypesMappings,
      availableTemplates,
      report,
      mode,
      mappingDone,
      attributesConfiguration,
      initialValues
    } = this.state

    const headerClass = mode || ''

    return (
      <div id="main-content" className="report-configuration-page">
        <h1 className={`sticky ${headerClass}`}>
          <div className="container">
            {
              (mode !== 'edit')
                ? __(`Create ${type}`) : __(`Edit ${type}`)
            }
          </div>
        </h1>

        <div className="container">
          {attributesConfiguration
          && (report || mode !== 'edit')
          && !this.showMappingModal()
          && mappingDone
          && (initialValues || (mode === 'create' && location && location.search !== '?fromImport'))
            ? (
              <CustomReportForm
                mode={mode}
                type={type}
                availableTemplates={availableTemplates}
                reportId={(mode !== 'edit') ? null : params.id}
                getData={this.getData}
                hideCancel={hideCancel}
                valueTypesMappings={valueTypesMappings}
                initialValues={(mode === 'create' && location && location.search !== '?fromImport')
                  ? {
                    type: 'SELF',
                    ...initialValues

                  } : initialValues}
                report={report}
                onSubmit={this.handleSubmit.bind(this)}
                handleAddSearchAttribute={this.handleAddSearchAttribute.bind(this)}
                handleRemoveSearchAttribute={this.handleRemoveSearchAttribute.bind(this)}
                handleAddSortAttribute={this.handleAddSortAttribute.bind(this)}
                handleRemoveSortAttribute={this.handleRemoveSortAttribute.bind(this)}
                handleSetSortAttributeValue={this.handleSetSortAttributeValue.bind(this)}
                handleAddDisplayField={this.handleAddDisplayField.bind(this)}
                handleRemoveDisplayField={this.handleRemoveDisplayField.bind(this)}
                handleResetAttributes={this.handleResetAttributes.bind(this)}
                attributesConfiguration={this.state.attributesConfiguration}
                attributesConfigurationTree={this.state.attributesConfigurationTree}
                extractAttributeConfig={this.extractAttributeConfig}
                setSearchAttrtibutes={this.setSearchAttrtibutes}
                setDisplayAttrtibutes={this.setDisplayAttrtibutes}
                setSortAttributes={this.setSortAttributes}
              />
            )
            : <span>{__('loading')}</span>}
        </div>

        <Modal
          backdrop="static"
          show={this.showMappingModal()}
        >
          <Modal.Header>
            <Modal.Title>{__('User action required')}:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{padding: '15px'}} className="bg-info">
              {__('MappingNeddedText')}:
            </p>
            {this.renderMappingModal()}
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <Button
                className="pull-left"
                onClick={() => {
                  hashHistory.goBack()
                }}
              >
                {__('Cancel')}
              </Button>

              <Button
                className="pull-right"
                bsStyle="primary"
                onClick={this.submitMapping}
              >
                {__('Submit')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

CustomReportPage.propTypes = {
  mode: PropTypes.string,
  type: PropTypes.string,
  user: PropTypes.object,
  formValues: PropTypes.object,
  match: PropTypes.object,
  importData: PropTypes.object,
  location: PropTypes.object,
  getGuiUser: PropTypes.func,
  hideCancel: PropTypes.bool,
  createStepObject: PropTypes.func,
  createReport: PropTypes.func,
  updateReport: PropTypes.func,
  updateStepObject: PropTypes.func,
  createRemoteObject: PropTypes.func,
  updateRemoteObject: PropTypes.func,
  updateOverviewObject: PropTypes.func,
  createOverviewObject: PropTypes.func,
  createView: PropTypes.func,
  updateView: PropTypes.func,
  onObjectCreate: PropTypes.func,
  remoteSystems: PropTypes.arrayOf(Object)
}

CustomReportPage.defaultProps = {
  importData: null,
  hideCancel: false,
  onObjectCreate: null,
  remoteSystems: []
}

export default connect(
  (state) => ({
    remoteSystems: state.ui.remoteSystems,
    user: state.user.data,
    importData: state.import.reportData,
    formValues: getFormValues('customReportForm')(state)
  }),
  {
    createReport,
    updateReport,
    createView,
    updateView,
    updateRemoteObject,
    createRemoteObject,
    updateOverviewObject,
    createOverviewObject,
    createStepObject,
    updateStepObject,
    getGuiUser
  }
)(CustomReportPage)
