import React, {useState} from 'react'

import { Badge } from 'antd'

import {Button, Modal} from "components";


const BatModal = (props) => {
  const {
    fixedheight,
    bodyStyle,
    forceRender,
    title,
    children,
    buttonLabel,
    buttonProps,
    disabled,
    className,
    forceOpen,
    setForceOpen,
    footer,
    style,
    size,
    destroyOnClose,
    buttonBadgeCount,
    showBadgeCount = true,
    onSubmitCallback,
    onCancelCallback,
    useButtonStyle = true
  } = props || {}

  const [modalOpen, setModalOpen] = useState(false)
  const open = forceOpen !== undefined ? forceOpen : modalOpen
  const setOpen = setForceOpen || setModalOpen

  return (
    <>
      <Badge
        dot={!showBadgeCount && !!buttonBadgeCount}
        count={buttonBadgeCount}
        offset={[-10, 0]}
        size="small"
      >
        {useButtonStyle ?
          <Button
            backgroundColor={'#FFFFFF'}
            borderColor={'#72777F'}
            color={'#00629D'}
            onClick={() => !disabled && setOpen(true)}
            disabled={disabled}
            title={title}
            {...buttonProps}
          />
          :
          <div onClick={() => !disabled && setOpen(true)}>
            {buttonLabel || title}
          </div>
        }
      </Badge>
      <Modal
        destroyOnClose={destroyOnClose}
        bodyStyle={bodyStyle}
        className={className}
        style={style}
        size={size}
        centered
        fixedheight={fixedheight}
        forceRender={forceRender}
        footer={footer || false}
        visible={open}
        title={title}
        onOk={onSubmitCallback}
        onCancel={() => {
          setOpen(false)
          if (onCancelCallback?.()) onCancelCallback()
        }}
      >
        {children}
      </Modal>
    </>
  )
}

export default BatModal
