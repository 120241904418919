import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import {change} from 'redux-form';
import {Strong} from "../Label/styles";
import Label from "../Label";
import parse from 'html-react-parser'
import {NONE} from "react-big-calendar/lib/utils/Resources";
import { __ } from '../../utils/translationUtils'
import { cases } from '../../utils/stringUtils'

class HtmlEditorControl extends React.Component {

  static propTypes = {
    handleCompanySelect: PropTypes.func,
    change: PropTypes.func,
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);

    const valueToBeTranslated = this.props.controlData?.attributes?.extendedProperties?.valueToBeTranslated;
    this.state = {
      initialContent: this.props.controlData.source,
      valueToBeTranslated: valueToBeTranslated,
      content: valueToBeTranslated ? __(this.props.controlData.source, cases.NONE) : this.props.controlData.source,
    };
    this.selectedCompany = null;
  }

  handleHtmlChange(value) {
    this.setState({
        content: value
      },
      () => {
        // Transform to valid XHTML format.
        let doc = new DOMParser().parseFromString(value, 'text/html');
        value = new XMLSerializer().serializeToString(doc);
        value = value.match(/<body>(.*?)<\/body>/g)[0].replace(/<\/?body>/g, '');
        this.props.change('stepForm', this.props.controlData.id, value);
      });
  }

  render() {

    const {controlData} = this.props;


    const value = this.state.content || (this.state.valueToBeTranslated ? __(controlData?.defaultValue, cases.NONE) : controlData?.defaultValue)
    if (!controlData.readOnly) {
      return (
        <div className={'form-group enabled'}>
          <Label
            className="col-xs-12"
            label={__(controlData.title)}
            description={controlData.description && __(controlData.description)}
          />
          <div className="col-xs-12">
            <ReactQuill
              theme="snow"
              style={controlData?.readOnly?{}:{backgroundColor:'white'}}
              readOnly={controlData?.readOnly}
              value={value}
              onChange={this.handleHtmlChange.bind(this)}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={'form-group enabled'}>
        <Label
          className="col-sm-3 col-md-3 col-lg-2"
          label={__(controlData.title)}
          description={controlData.description && __(controlData.description)}
        />
        <div className="col-sm-9 col-md-6 col-lg-6">
          {parse(value)}
        </div>
      </div>
    )
  }

}

export default connect(
  null,
  {change}
)(HtmlEditorControl);
