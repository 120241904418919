import styled from 'styled-components'

import {Form} from "antd";

export const FormItem = styled(Form.Item)`
  ${(props) => props.hide && 'display: none'};
`
export const CollapseWrapper = styled.div`

  .ant-collapse, .ant-collapse-header{
    background-color: initial;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
    flex-direction: column;
    cursor: default;

    .ant-row {
      width: 100%;
    }
  }

  .ant-collapse-item, .ant-collapse-item {
    margin-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .ant-form-item-label {
    label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }

    label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
`

export const TreeNodeSelectorWrapper = styled.div`
  & > .ant-row {
      overflow: auto !important;
  }
`
