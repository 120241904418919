import React from 'react';
import { Button, Panel } from "react-bootstrap";
import ReactJson from 'react-json-view';
import getCurlFromRequest from "../../utils/curlFormatter";
import { connect } from "react-redux";
import { __ } from '../../utils/translationUtils'
const format = require('xml-formatter');
/**
 * This element use React Json in order to display object in a pretty way
 * **/
const JSONRequestProperty = props => {

  let { body, collapsed, collapseStringsAfterLength, indentWidth, title, titleh3 } = props;

  // Setting Default
  collapsed = collapsed || false;
  indentWidth = indentWidth || 2;
  title = title || titleh3 || ''

  if (body) {
    return (
      <div>
        <h3>{titleh3 || ''}</h3>
        <ReactJson src={body} name={title} indentWidth={indentWidth} style={{ whiteSpace: 'pre-wrap' }}
          collapseStringsAfterLength={collapseStringsAfterLength} collapsed={collapsed} />
      </div>
    );
  } else {
    return (
      <div />
    )
  }


};


/**
 * This component-react-panel display the request features
 * **/
class LogPageRequestPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openRequestInfoPanel: true,
      openStackTracePanel: true,
      openAdditionalOrderInfoPanel: true
    };

    this.handleToggleRequestInfo = this.handleToggleRequestInfo.bind(this);
    this.handleToggleStackTrace = this.handleToggleStackTrace.bind(this);
    this.handleToggleAdditionalInfo = this.handleToggleAdditionalInfo(this);
    this.copyCurlToClipBoard = this.copyCurlToClipBoard.bind(this);
  }

  handleToggleRequestInfo() {
    this.setState({ openPanel: !this.state.openPanel });
  }

  handleToggleStackTrace() {
    this.setState({ openStackTrace: !this.state.openStackTrace });
  }

  handleToggleAdditionalInfo() {
    this.setState({ openAdditionalOrderInfoPanel: !this.state.openAdditionalOrderInfoPanel })
  }

  copyCurlToClipBoard() {
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = getCurlFromRequest(this.props.log.request);
    // Set non-editable to avoid focus and move outside of view
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  }

  renderRequestInfoPanel() {
    const { log } = this.props;
    if (!log.request) {
      return null;
    }
    const { body, headers, ...generalProperties } = log.request;

    return (
      <Panel
        style={{ 'marginBottom': '40px' }}
        expanded={this.state.openRequestInfoPanel}
      >
        <Panel.Body collapsible>
          <JSONRequestProperty body={generalProperties} titleh3={__('Main Properties')} />
          {headers && <JSONRequestProperty body={headers} titleh3={__('Request Header')} collapsed={true} collapseStringsAfterLength={100} />}
          {body && <JSONRequestProperty body={JSON.parse(body)} titleh3={__('Request Body')} collapsed={true} collapseStringsAfterLength={100} />}
          <Button onClick={this.copyCurlToClipBoard} className={'pull-right btn-blue'}>{__('Copy Curl to ClipBoard')}</Button>
        </Panel.Body>
      </Panel>
    );

  }

  renderAdditionalInfoPanel() {

    const { log } = this.props;

    if (!log.additionalInformation) {
      return null;
    }

    return (
      <Panel
        style={{ 'marginBottom': '40px' }}
        expanded={this.state.openAdditionalOrderInfoPanel}
      >
        <Panel.Body collapsible>
          {
            Object.keys(log.additionalInformation).map((key, index) => {
              let jsonValue = null

              // Compute jsonValue
              if (key === 'xsd' || key === 'xml') {
                const options = { stripComments: true, collapseContent: true, indentation: '      ', lineSeparator: '\n', throwOnFailure: false };
                jsonValue = format(log.additionalInformation[key], options);
              } else {
                jsonValue = JSON.stringify(log.additionalInformation[key]);
              }

              const jsonXsd = {};
              jsonXsd[key] = jsonValue;

              let collapsed = jsonValue.length > 20;

              return (

                <JSONRequestProperty body={jsonXsd} collapsed={collapsed} title={key} key={index} />)
            })

          }
        </Panel.Body>
      </Panel>
    )
  }


  renderStackTracePanel() {
    const { log } = this.props

    return (
      <Panel
        style={{ 'marginBottom': '40px' }}
        expanded={this.state.openStackTracePanel}
      >
        <Panel.Body collapsible>
          <JSONRequestProperty body={log.stackTrace} title={__('Stack Trace')} collapseStringsAfterLength={100} />
        </Panel.Body>
      </Panel>
    )
  }

  render() {

    const { user, log } = this.props;

    // This panel should be visible only by ROOT user

    if (user.matchingTemplates && (user.matchingTemplates.includes('ROOT') || user.matchingTemplates.includes('PA'))) {

      return (
        <div>
          {log.additionalInformation && Object.keys(log.additionalInformation).length && <div className="fieldset style-1 blocks col-xs-12">
            <span className="collapsible-header no-selection" onClick={this.handleToggleAdditionalInfo}>
              {`${__('Additional Information')}`}
            </span>
            {this.renderAdditionalInfoPanel()}
          </div>}
          {log.request && <div className="fieldset style-1 blocks col-xs-12">
            <span className="collapsible-header no-selection" onClick={this.handleToggleRequestInfo}>
              {`${__('More request Information')}`}
            </span>
            {this.renderRequestInfoPanel()}
          </div>}
          <div className="fieldset style-1 blocks col-xs-12">
            <span className="collapsible-header no-selection" onClick={this.handleToggleStackTrace}>
              {`${__('Stack Trace')}`}
            </span>
            {this.renderStackTracePanel()}
          </div>
        </div>
      );

    } else {

      return (<div />);

    }
  }


}


export default connect(
  state => {
    return {
      user: state.user.data,
    };
  },
  {}
)(LogPageRequestPanel);

