import * as T from './types'
import * as C from '../constants'
import * as U from '../utils'
import {validateAllSteps} from "../utils/validation";
import {stepValidation} from "../utils/validation";

export const getInitialState = () => ({
  type: null,
  mode: null,
  creatorId: null,
  systems: null,
  attributesTree: null,
  attributesMap: null,
  flowOptions: null,
  valueTypesMap: null,
  entitiesTreeData: null,
  configurations: C.configuratorInitialState,
  activeStep: C.steps.OUTPUT_FIELDS,
  loading: true,
  validationState: C.validationInitialState,
  validationVisibility: {},
  enumerations: {},
  apis: {}
})

export const initState = (state) => ({ ...state })

export const ConfigurationConfiguratorReducer = (state, action) => {

  if (!action.type) return

  switch (action.type) {

    case T.INITIALIZED:
      const {
        tree,
        systems,
        valueTypesMap,
        configurations,
        entities,
        publishedFlowsLastVersion,
        apis,
      } = action.payload
      const attributesTree = U.sortAttributesTree(U.translateAllSystems(U.mapAttributesTree(tree), systems))
      const attributesMap = U.getAttributesMapFromTree(attributesTree)
      const flowOptions = U.getFlowOptions(publishedFlowsLastVersion)
      const additionalPayload = U.getAdditionalPayload(configurations, attributesMap)
      const entitiesTreeData = U.mapEntityTreeData(entities)
      return {
        ...state,
        systems,
        attributesTree,
        attributesMap,
        flowOptions,
        valueTypesMap,
        entitiesTreeData,
        apis,
        ...additionalPayload,
        loading: false
      }

    case T.NOT_INITIALIZED:
      return {
        ...state,
        loading: true
      }

    case T.INIT_CONFIGURATOR:
      return {
        ...state,
        type: action.payload.type,
        mode: action.payload.mode,
      }

    case T.UPDATE_ENUMS:
      return {
        ...state,
        enumerations: {
          ...state.enumerations,
          ...action.payload
        }
      }

    case T.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload
      }

    case T.SHIFT_ACTIVE_STEP:
      const steps = Object.keys(C.steps).map((stepKey) => (C.steps[stepKey]))
      const newActiveStep = state.activeStep + action.payload
      return {
        ...state,
        activeStep: steps.includes(newActiveStep) ? newActiveStep : state.activeStep
      }

    case T.SET_VALIDATION_VISIBILITY:
      return {
        ...state,
        validationVisibility: { ...state.validationVisibility, [state.activeStep]: action.payload}
      }

    case T.EDIT_STEP_CONFIGURATION: {
      const {stepValues} = action.payload
      const updatingStep = (action.payload.step == null) ? state.activeStep : action.payload.step
      const validationMethod = stepValidation[updatingStep]
      const isValid = validationMethod ? validationMethod(stepValues) : true
      return {
        ...state,
        validationState: {...state.validationState, [updatingStep]: isValid},
        validationVisibility: {...state.validationVisibility, [updatingStep]: C.validationActivatedAfter.EDIT},
        configurations: {
          ...state.configurations,
          [updatingStep]: {...stepValues}
        },
      }
    }

    case T.EDIT_STEP_CONFIGURATION_ATTRIBUTE: {
      const {attributeValues, attributeId, step} = action.payload
      const updatingStep = (step == null) ? state.activeStep : step
      const validationMethod = stepValidation[updatingStep]
      const updatedStep = {
        ...state.configurations[updatingStep],
        [attributeId]: {...attributeValues}
      }
      const isValid = validationMethod ? validationMethod(updatedStep) : true
      return {
        ...state,
        validationState: {...state.validationState, [updatingStep]: isValid},
        validationVisibility: {...state.validationVisibility, [updatingStep]: C.validationActivatedAfter.EDIT},
        configurations: {
          ...state.configurations || {},
          [updatingStep]: updatedStep
        },
      }
    }

    case T.EDIT_STEP_CONFIGURATION_ATTRIBUTES:
      const updatedStep = {
        ...state.configurations[state.activeStep],
        ...action.payload
      }
      const validationMethod = stepValidation[state.activeStep]
      const isValid = validationMethod ? validationMethod(updatedStep) : true
      return {
        ...state,
        validationState: {...state.validationState, [state.activeStep]: isValid},
        validationVisibility: {...state.validationVisibility, [state.activeStep]: C.validationActivatedAfter.EDIT},
        configurations: {
          ...state.configurations || {},
          [state.activeStep]: updatedStep
        },
      }

    default:
      return state
  }
}
