import React from 'react'
import PropTypes from 'prop-types'
import { api } from '../providers/ApiProvider'
import LogPageRequestPanel from '../components/LogPage/LogPageRequestPanel'

const loadingBar = require('nprogress')

class LogPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      objectId: props.match.params.logId,
      log: null,
      isLoading: false
    }
  }

  componentDidMount() {
    this.getLog()
  }

  getLog() {
    const { objectId } = this.state
    this.setState({ isLoading: true })
    loadingBar.start()
    return api.get(`log/${objectId}`)
      .then(
        (response) => {
          this.setState({ log: response.data, isLoading: false })
          loadingBar.done()
        }
      )
  }

  render() {
    const { log } = this.state
    return (

      <div id="main-content" style={{ wordBreak: 'break-word' }}>

        <h1 className="sticky">
          <div className="container">
            Log: {log && log._id}
          </div>
        </h1>
        {log
          && (
            <div className="container">

              <div className="col-sm-6">
                <h5>Log ID:</h5>
                {log._id}
                <br />
                <br />

                <h5>Other Info:</h5>
                Microservice Id: {log.microserviceId}<br />
                Microservice Type: {log.microserviceType}<br />
                Microservice URL: {log.microserviceURL}<br />
                <br />

              </div>

              <div className="col-sm-3">

                <h5>Level:</h5>
                {log.level}
                <br />
                <br />

              </div>

              <div className="col-sm-3">

                <h5>Date occured:</h5>
                {log.creationDate}
                <br />
                <br />

              </div>

              <div className="blocks col-xs-12 no-padding">

                <div className="col-sm-6">
                  <h5>Message:</h5>
                  {log.message}<br />
                  <br />
                </div>

                <div className="col-sm-3">
                  <h5>Exception Type:</h5>
                  {log.error && <div><div>{log.error.detailMessage}</div><div>{log.error.type}</div><br /></div>}
                  <br />
                </div>

                <div className="col-sm-3">
                  <h5>Class Name:</h5>
                  {log.className}<br />
                  <br />
                </div>

              </div>

              {log.exceptionMessage && (
                <div className="col-sm-12">
                  <h5>Exception Message:</h5>
                  {log.exceptionMessage}
                  <br />
                  <br />
                </div>
              )}

              {log && <LogPageRequestPanel log={log} /> }

            </div>
          )}

      </div>

    )
  }
}
LogPage.propTypes = { match: PropTypes.object };

export default LogPage
