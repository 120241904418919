import React from 'react'

import {Tooltip, Spin} from "antd";
import {Icon} from "@ant-design/compatible";

import * as S from './styles'

import { __ } from '../../../../utils/translationUtils'

export const LoadingDropdown = (props) => {
  const {title} = props || {}
  return (
    <S.DeprecatedCustomMenuNoData key="pinned">
      {title && <S.PinnedTitleNoData> {title} </S.PinnedTitleNoData>}
      <S.CustomLoading>
        <Spin/>
      </S.CustomLoading>
    </S.DeprecatedCustomMenuNoData>
  )
}

export const NoDataDropdown = (props) => {
  const {
    title,
    text = __("No Data"),
    redirect,
    redirectText,
    redirectIcon
  } = props || {}
  return (
    <S.DeprecatedCustomMenuNoData key="pinned">
      {title &&
        <S.PinnedTitleNoData>
          {title}
        </S.PinnedTitleNoData>
      }
      <S.Container position={'relative'}>
        <S.CustomEmpty description={ <span> {text} </span> }/>
        {redirect &&
          <S.NoDataAction href={redirect}>
            {redirectIcon}
            {redirectText &&
              <div>
                {redirectText}
              </div>
            }
          </S.NoDataAction>
        }
      </S.Container>
    </S.DeprecatedCustomMenuNoData>
  )
}

export default (props) => {
  const {data, title, noData} = props || {}
  if (!data) { return (
    <LoadingDropdown title={title}/>
  )}
  if (data.length === 0) { return (
    <NoDataDropdown
      title={title}
      {...noData}
    />
  )}
  return (
    <S.DeprecatedCustomMenu key="pinned">
      {title &&
        <S.PinnedTitle>
          {title}
        </S.PinnedTitle>
      }
      {data.map(group => {
        return (
          <S.PinnedGroup key={"pinned/" + group.groupLabel}>
            <S.GroupTitle>
              {group.groupLabel}
              {group.quickRedirect &&
                <a href={group.quickRedirect.redirect} className="group-action">
                  <Tooltip title={group.quickRedirect.text}>
                    <div className="btn-no-padding btn-std">
                      <Icon type={group.quickRedirect.icon} theme="outlined"/>
                    </div>
                  </Tooltip>
                </a>
              }
            </S.GroupTitle>
            {group.list?.map((item, key) => {
              return (
                  <S.PinnedItem href={item.link} key={`pinned-${group.groupLabel}-${item.label}-${key}`}>
                    {item.label}
                  </S.PinnedItem>
              )})}
          </S.PinnedGroup>
        )})}
    </S.DeprecatedCustomMenu>
  )
}
