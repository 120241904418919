import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
//import _ from 'lodash';

class RightsParam extends React.Component {

  static propTypes = {
    data: PropTypes.object,
    wildcards: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <FormGroup controlId="formHorizontalEmail">
          <Col componentClass={ControlLabel} sm={2}>
            {this.props.data.name}:
          </Col>
          <Col sm={5}>
            <select
              style={{height: '75px'}}
              className="form-control"
              multiple={true}
              >
              {
                this.props.data.values.map((o, k)=>{
                  return (
                    <option ref={(input) => { this.textInput = input; }} key={'rp_opt_'+k} value={o.id}>{o.value}</option>
                  );
                })
              }
            </select>
          </Col>
        </FormGroup>
    );
  }
}

export default connect(
  state => {
    return {
      wildcards: state.form.templateRightsForm && state.form.templateRightsForm.values
    };
  },
  {}
)(RightsParam);
