import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import SubMenuItem from './SubMenuItem';
import PinnedItems from "./customItems/PinnedItems";

const SubMenu = ({items, customItemsProps, className, handleClick}) => {
  const elementRef = useRef(null);


  return (
    <>
      <ul
        ref={elementRef}
        className="sub-menu group"
      >
        {customItemsProps && <PinnedItems {...customItemsProps} />}
        {items.map((menuItem, i) => {
          const classNames = [className];

          if (menuItem.separate) {
            classNames.push('separate');
          }

          return (
            <SubMenuItem
              handleClick={handleClick}
              key={`${i}-${menuItem.text}`}
              className={classNames.join(' ')}
              item={menuItem}
            />
          );
        })}
      </ul>
      <ul className="sub-menu group">
        {customItemsProps && <PinnedItems {...customItemsProps} />}
        {items.map((menuItem, i) => {
          const classNames = [className];

          if (menuItem.separate) {
            classNames.push('separate');
          }

          return (
            <SubMenuItem
              handleClick={handleClick}
              key={`${i}-${menuItem.text}`}
              className={classNames.join(' ')}
              item={menuItem}
            />
          );
        })}
      </ul>
    </>
  );
};

SubMenu.propTypes = {
  guiUser: PropTypes.object,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  location: PropTypes.object,
  isOpen: PropTypes.bool,
  items: PropTypes.array
};

SubMenu.defaultProps = {className: ''};

export default connect(
  (state) => ({menu: state.menu}),
  {}
)(SubMenu);
