import styled from "styled-components";

import {Empty} from "antd";

export const DeprecatedCustomMenu = styled.div`
  border-bottom: 1px solid #E0E0E0;
  background-color: #F9FAFC;
  height: 100% !important;
  position: relative;
`

export const DeprecatedCustomMenuNoData = styled(DeprecatedCustomMenu)`
  padding-bottom: 0;
`

export const NoDataAction = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition-duration: .25s;
  opacity: 0;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  text-align: center;
  background-color: #fffa;
  backdrop-filter: blur(1px);
  > div {
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    font-size: 10px;
    font-weight: 600;
    color: #00457088;
    opacity: 0;
    border-radius: 2px;
    padding: 0 10px 0 0;
    transition-delay: 0s !important;
    transition-duration: .25s !important;
  }
  > span {
    transition-duration: inherit;
    transform: scale(0);
  }
  &:hover {
    opacity: 1;
    & > span {
      transform: scale(1);
    }
    & > div {
      padding: 0 5px 0 5px;
      opacity: 1;
      transition-delay: .25s !important;
      transition-duration: .75s !important;
    }
  }
`

export const GroupTitle = styled.div`
  font-size: 13px;
  color: #00457088;
  padding: 0 10px;
  padding-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  > a {
    overflow: visible !important;
  }
`

export const PinnedGroup = styled.div`
  .group-action {
    transition-duration: .25s;
    transform: translateX(50%);
    opacity: 0;
    padding: 0 !important;
    .btn-no-padding.btn-std {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
  &:hover .group-action {
    padding: 0 0 0 5px;
    opacity: 1;
    transform: translateX(0);
  }
`

export const PinnedItem = styled.a`
  font-weight: 400 !important;
  transition-duration: .25s;
  padding: 8px 10px 8px 25px !important;
  border-radius: 0 !important;
  height: 100% !important;
  font-size: 13px !important;
  overflow-wrap: break-word;
  white-space: normal !important;
  &:hover {
    background-color: white;
  }
`

export const PinnedTitle = styled.div`
  font-size: 14px;
  color: #004570;
  font-weight: 600;
  padding: 11px 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E0E0E088;
`

export const PinnedTitleNoData = styled(PinnedTitle)`
  margin-bottom: 0;
`

export const CustomLoading = styled.div`
  width: 100%;
  align-content: center;
  padding: 40px 0;
  display: flex;
  justify-content: center;
`

export const CustomEmpty = styled(Empty)`
  padding-top: 20px;
  padding-bottom: 20px;
`

export const Container = styled.div`
  ${props => props.position && `position: ${props.position}`};
`
