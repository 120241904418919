import React from 'react'

import {Tooltip} from 'antd'
import {InfoCircleFilled} from '@ant-design/icons'
import * as S from './styles'

export default ({label, description, className, htmlFor}) => {
  
  const convert = (text)=>{
    return (
      <div className="Container" dangerouslySetInnerHTML={{__html: text}}/>
    )
  }
  
  return (
    <S.Label className={className} htmlFor={htmlFor}>
      {label}
      {description && (
        <Tooltip title={convert(description)} placement="right">
          <S.InfoBox><InfoCircleFilled /></S.InfoBox>
        </Tooltip>
      )}
    </S.Label>
  )
}
