import React, { useMemo, useState } from "react";

import { Input, Popover, Space } from "antd";
import NoData from 'components/dist/NoData'

import * as S from '../../styles'
import { getAttributePathParents } from "../../../ConfigurationConfiguratorBeta/utils";
import { Button } from "components";
import { __ } from '../../../../utils/translationUtils'
import { cases } from '../../../../utils/stringUtils'


const SearchSection = ({ setSearchedValue, searchedValue, placeAllFields, canPlaceAll }) => {

  return (
    <S.SearchSection>
      <Space
        direction={'vertical'}
        style={{ width: '100%' }}
        size={'small'}
      >
        <Input.Search
          allowClear
          defaultValue={searchedValue}
          style={{ width: '100%' }}
          onSearch={setSearchedValue}
        />
        {!searchedValue && (
          <Button
            type="text"
            onClick={placeAllFields}
            disabled={!canPlaceAll}
            title={__('Place all')}
            color={'#00629D'}
          />
        )}
      </Space>
    </S.SearchSection>
  )
}

const ListSection = ({ attributesList, systems }) => {
  if (!attributesList.length) return <NoData title={__('no_fields_to_place')} />
  return (
    <S.AttributesListingContainer>
      <S.ListSection>
        {
          attributesList.map((attribute) => {
            return (
              <S.DraggableItem
                key={attribute.id}
                draggable={true}
                unselectable="on"
                onDragStart={(e) => {
                  e.dataTransfer.setData("text/plain", JSON.stringify(attribute))
                }}
              >
                {__(attribute.propertyLabel, cases.UPPERCASE)}
                <Popover
                  content={
                    <S.Flex>
                      {getAttributePathParents(attribute, systems).join(" / ")}
                    </S.Flex>
                  }
                  placement={'right'}
                >
                  <S.ItemInfoIcon />
                </Popover>
              </S.DraggableItem>
            )
          })
        }
      </S.ListSection>
    </S.AttributesListingContainer>
  )
}

export default (props) => {

  const {
    attributesToSelect,
    placeAllFields,
    systems
  } = props || {}

  const [searchedValue, setSearchedValue] = useState('')

  const attributesToSelectFiltered = useMemo(() => {
    if (!searchedValue || !attributesToSelect.length) return attributesToSelect
    return attributesToSelect.filter((attribute) => (__(attribute.propertyLabel).toLowerCase().includes(searchedValue.toLowerCase())))
  }, [attributesToSelect, searchedValue])

  return (
    <>
      <SearchSection
        setSearchedValue={setSearchedValue}
        searchedValue={searchedValue}
        placeAllFields={placeAllFields}
        canPlaceAll={!!attributesToSelectFiltered?.length}
      />
      <ListSection
        attributesList={attributesToSelectFiltered}
        systems={systems}
      />
    </>
  )
}
