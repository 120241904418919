import React, {useCallback, useEffect, useState} from "react";

import {Form, TreeSelect} from "antd";
import { debounce } from 'lodash';

import {useConfigurationConfiguratorContext} from "../../context";
import ViewPropertiesForm from '../../../../components/ConfigurationConfiguratorBeta/ViewProperties'
import * as C from "../../constants";
import {useSelector} from "react-redux";
import {validationActivatedAfter} from "../../../RemoteObjectConfiguratorBeta/constants";
import * as configuratorUtils from "../../../../components/ConfigurationConfiguratorBeta/utils";
import {visibleFor} from "../../../../components/ConfigurationConfiguratorBeta/constants";
import * as S from "../../styles";
import {CustomText} from "../../../../providers/StyleProvider/styles";
import CheckboxImageGroup from "../../../../components/CheckboxImageGroup";
import Toggle from "../../../../components/Toggle";
import { __ } from '../../../../utils/translationUtils'
import {REGEX_TITLE} from "components/dist/Utils/regexUtils";

export default ({ viewPropertiesRef }) => {

  const { state, editStepConfiguration } = useConfigurationConfiguratorContext()
  const { configurations, activeStep, entitiesTreeData, validationVisibility } = state
  const formValues = configurations[activeStep]

  const [defaultLayoutOptions, setDefaultLayoutOptions] = useState(configuratorUtils.getLayoutItems().filter((option) => formValues?.layout?.includes(option?.value)))
  const [showEntitiesSelect, setShowEntitiesSelect] = useState(formValues?.visibleFor === visibleFor.SPECIFIC_ENTITIES)

  const [form] = Form.useForm();

  useEffect(() => {
    if (!viewPropertiesRef?.current) viewPropertiesRef.current = form
  }, [form, viewPropertiesRef])

  const debouncedSubmitValues = useCallback(
    debounce((step) => editStepConfiguration(form.getFieldsValue(), step), 300),
    [form],
  );

  useEffect(() => {
    if (validationVisibility[activeStep] === validationActivatedAfter.CLOSE) form.validateFields()
  }, [validationVisibility])

  const handleLayoutChange = (change) => {
    const defaultLayout = form.getFieldValue('defaultLayout')
    setDefaultLayoutOptions(configuratorUtils.getLayoutItems().filter((option) => change?.includes(option?.value)))
    if (!defaultLayout || !change?.includes(defaultLayout)) {
      form.setFieldsValue({defaultLayout: change?.[0]})
    }
  }

  const handleVisibleForChange = (change) => {
    if (change === visibleFor.SPECIFIC_ENTITIES) setShowEntitiesSelect(true)
    else if (showEntitiesSelect) setShowEntitiesSelect(false)
  }

  const onFormChange = (changes) => {
    if (changes.layout) handleLayoutChange(changes.layout)
    if (changes.visibleFor) handleVisibleForChange(changes.visibleFor)
    debouncedSubmitValues(activeStep)
  }

  return (
    <ViewPropertiesForm
      form={form}
      initialValues={formValues}
      fields={[
        {
          name: C.viewPropertiesFields.VIEW_NAME,
          label: <CustomText required>{__('view_name')}</CustomText>,
          rules: [
            {required: true, message: __('This field is required')},
            {pattern: REGEX_TITLE, message: "Wrong format!"}
          ],
          required: false,
          colProps: { span: 6 },
          itemProps: {
            type: 'input',
            placeholder: __('view_name'),
          }
        },
        {
          name: C.viewPropertiesFields.VISIBLE_FOR,
          label: <CustomText required>{__('visible_for')}</CustomText>,
          rules: [{required: true, message: __('required_field')}],
          required: false,
          colProps: { span: 6, style: { marginRight: !showEntitiesSelect? '50%' : 'unset' }},
          itemProps: {
            type: 'select',
            items: configuratorUtils.getVisibleForItems(),
            placeholder: __('select_one'),
            showSearch: true,
            allowClear: true,
          }
        },
        ...( !showEntitiesSelect ? [] : [{
          name: C.viewPropertiesFields.SELECT_ENTITIES,
          label: <CustomText required>{__('select_entities')}</CustomText>,
          rules: [{required: true, message: __('required_field')}],
          required: false,
          colProps: { span: 6, style: { marginRight: '25%'} },
          itemProps: {
            type: 'treeSelect',
            items: entitiesTreeData,
            treeCheckable: true,
            showSearch: true,
            allowClear: true,
            showCheckedStrategy: TreeSelect.SHOW_PARENT,
            placeholder: __('please_select'),
            dropdownMatchSelectWidth: false,
            maxTagCount: 'responsive',
          }
        }]),
        {
          name: C.viewPropertiesFields.SHOW_PHASES,
          label: <CustomText>{__('show')}</CustomText>,
          colProps: { span: 6 },
          itemProps: {
            type: 'select',
            mode: 'multiple',
            items: configuratorUtils.getShowPhasesItems(),
            showSearch: true,
            optionFilterProp: 'label',
            allowClear: true,
            placeholder: __('please_select'),
          }
        },
        {
          name: C.viewPropertiesFields.ONLY_MY_ORDERS,
          colProps: { span: 6 },
          itemProps: {
            CustomRender: Toggle,
            label: __('show_only_orders_assigned_to_me')
          },
        },
        {
          type: 'custom',
          colProps: { flex: '100%' },
          children: (
            <CustomText
              required
              color='0a4f7a'
              type='title'
              style={{ fontSize: '18px', fontWeight: '700', padding: '20px 0 10px'}}
            >
              {__('available_layouts')}
            </CustomText>
          ),
        },
        {
          name: C.viewPropertiesFields.LAYOUT,
          rules: [{required: true, message: <S.CustomText size={'inherit'} padding={'0 0 0 15px'}>{__('required_layout')}</S.CustomText>}],
          colProps: { flex: '60%'},
          itemProps: {
            CustomRender: CheckboxImageGroup,
            items: configuratorUtils.getLayoutItems(),
          }
        },
        {
          name: C.viewPropertiesFields.DEFAULT_LAYOUT,
          label: <CustomText>{__('default_layout')}</CustomText>,
          colProps: { flex: '20%' },
          itemProps: {
            type: 'select',
            items: defaultLayoutOptions,
            placeholder: __('missing_layouts'),
            showSearch: true,
            optionFilterProp: 'label',
            allowClear: true,
          },
        },
        {
          name: C.viewPropertiesFields.DEFAULT_PAGE_SIZE,
          label: <CustomText>{__('default_page_size')}</CustomText>,
          colProps: { flex: '20%' },
          itemProps: {
            type: 'select',
            items: configuratorUtils.getDefaultPageSizeItems(),
            showSearch: true,
            optionFilterProp: 'label',
            allowClear: true,
          }
        },
        {
          type: 'custom',
          colProps: { flex: '100%' },
          children: (
            <CustomText
              color='0a4f7a'
              type='title'
              style={{ fontSize: '18px', fontWeight: '700', padding: '20px 0 10px'}}
            >
              {__('additional_features')}
            </CustomText>
          ),
        },
        {
          name: C.viewPropertiesFields.FEATURES,
          colProps: { flex: '100%' },
          itemProps: {
            CustomRender: CheckboxImageGroup,
            items: configuratorUtils.getFeaturesItems(),
          }
        },
      ]}
      onFormChange={onFormChange}
    />
  )
}
