import {
  centerImage,
  getFileBase64,
  resizeImage
} from "../../components/DooOrderPage/Step/customRender/FileUploader/utils/file";

export const initialize = async (blob: Blob, width: number, height: number) =>
  resizeImage(blob, width, height, 1)
    .then((resizedImg) => centerImage(resizedImg, width, height)
      .then((centered) => getFileBase64(centered)))
