import React, {useMemo} from "react";
import {Button, Modal} from "react-bootstrap";
import {__, T} from "../../../utils/translationUtils";
import {cases} from "../../../utils/stringUtils";
import {Form} from "components";
import {changeDeputyInputs, OrgItem, UserItem} from "./config";
import {FormWrapper} from "./styles";
import * as yup from "yup";


type ChangeDeputyModalProps = {
  currentDeputy: UserOverview
  replaceWithDeputy?: boolean
  users: User[]
  organisations: Entity[]
  submit: (newUserId: string, replaceWithDeputy: boolean) => void
  close: () => void
}

const ChangeDeputy = ({
                        currentDeputy,
                        replaceWithDeputy,
                        organisations,
                        users,
                        submit,
                        close
                      }: ChangeDeputyModalProps) => {
  const initDeputyId = useMemo(() => ((currentDeputy && ["enabled", "reserved"].includes(currentDeputy.status)) ? currentDeputy.id : undefined), [currentDeputy])

  const orgItems: OrgItem[] | undefined = useMemo(() =>
      organisations.map(({id, name, shortName}) => ({value: id, label: `${name} (${shortName})`})),
    [organisations]);

  const userItems: UserItem[] = useMemo(() =>
      users.map(({id, firstName, lastName, username, belongsTo}) =>
        ({value: id, label: `${firstName} ${lastName} (${username})`, belongsTo: (belongsTo || []).map(({id}) => id)})),
    [users]);

  return (
    <Modal
      backdrop="static"
      show
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title className="capitalize">
          {__('choose_user_deputy', cases.CAPITALIZE_SENTENCE)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          orgItems && userItems && (
            <FormWrapper>
              <Form
                id="choose-deputy-form"
                key="choose-deputy-form"
                layout="horizontal"
                showSubmitButton={false}
                initialValues={{user: initDeputyId, organization: null, replaceWithDeputy: !!replaceWithDeputy}}
                validationSchema={yup.object().shape({
                  user: yup.string().required(__(T.requiredField, cases.CAPITALIZE_SENTENCE)).nullable()
                })}
                inputs={changeDeputyInputs({data: {orgItems, userItems}, __})}
                onSubmit={(formValues: {
                  user: string,
                  replaceWithDeputy: boolean
                }) => submit(formValues.user, formValues.replaceWithDeputy)}
              />
            </FormWrapper>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <div className="group">
          <Button onClick={close} className="pull-left">
            {__('close')}
          </Button>
          <button
            form={"choose-deputy-form"}
            className={'capitalize btn pull-right btn-primary'}
          >
            {__('continue')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeDeputy
