import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Glyphicon from '@strongdm/glyphicon'
import { __ } from '../utils/translationUtils'

class BasicSearchForm extends React.Component {

  static propTypes = {
    handleSubmit: PropTypes.func,
    placeholder: PropTypes.string,
    isLoading: PropTypes.bool,
    itemType: PropTypes.string
  };
  static defaultProps = {
    placeholder: ''
  };

  render(){
    const {handleSubmit, isLoading} = this.props;
    return (
      <div className="search-compbo gutter-bottom">
        <form className="" onSubmit={handleSubmit}>
          <Field
            name="q"
            className="form-control"
            type="text"
            component="input"
            label="Name"
            placeholder={(this.props.placeholder != '') ? this.props.placeholder : __(`Search ${this.props.itemType}`) + '...'}
          />

          <Field
            name="showDeleted"
            type="checkbox"
            style={{display: 'none'}}
            component="input"
          />

          <button title={__('Search')} disabled={isLoading} size="large" type="submit" >
            <Glyphicon glyph="search" />
          </button>

        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'basicSearchForm'  // a unique identifier for this form
})(BasicSearchForm);
