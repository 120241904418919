import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FileUploaderService from "../services/fileUploaderService";
import {
  ILLEGAL_ARGUMENT_EXCEPTION,
  IMAGE_MODAL_PREVIEW_TYPE
} from "../constant";
import UploadFileService from "../services/uploadFIleService";
import downloadjs from "downloadjs";
import { Skeleton, Upload } from "antd";
import { ERROR, INFO } from "components/dist/Utils/LoggerUtils";
import { getImageThumbnail } from "../../DooOrderPage/Step/customRender/FileUploader/utils/file";
import ImageCarouselModal from "../../DooOrderPage/Step/customRender/FileUploader/components/ImageCarouselModal";
import { fetchFileList } from "../../DooOrderPage/Step/customRender/FileUploader/utils/IO";
import { getFileResourceInfo } from "../../DooOrderPage/Step/customRender/FileUploader/utils/misc";
import { isImage } from "../../../utils/appHelper";
import pMap from "p-map";

/* I prefer to separate this component from Upload File since it has some important difference
 * and I think that if I added in the UploadFile, firstable the name of the compoent will be wront since the reduced
 * and then It could be really difficult to maintain
 */

function ReduceFileComponent(props) {
  const { authToken, filesCollected } = props

  const [previewModalVisible, setPreviewModalVisible] = useState(false)
  const [selectedImageName, setSelectedImageName] = useState(null)
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [initialised, setInitialised] = useState(false)

  const filesRef = useRef(undefined)

  const updateAllFiles = (newFileList) => {
    filesRef.current = newFileList
    setFileList(newFileList)
  }

  const updateFile = useCallback((file) => {
    if (!file) return

    const i = filesRef.current.findIndex(f => f.name === file.name)

    if (i > -1) {
      const newFileList = [...filesRef.current]
      newFileList[i] = file

      updateAllFiles(newFileList)
    } else {
      updateAllFiles([...filesRef.current, file])
    }
  }, [])

  const getFileListFromServer = useCallback(
    (filesToFetch, imageOptions) => fetchFileList(filesToFetch, imageOptions, authToken, updateFile),
    [authToken])

  const init = useCallback(async () => {
    setLoading(true)
    let files = filesCollected

    const initFiles = (files || []).map((file, index) => {
      const resInfo = getFileResourceInfo(file, { jsonPath: file.jsonPath })

      return {
        uid: `-${file.name}`,
        name: file.name,
        type: file.type,
        jsonPath: file.jsonPath,
        ...(isImage(file.type) ? { status: 'uploading' } : { status: 'done', ...resInfo })
      }
    })

    updateAllFiles(initFiles)

    pMap(initFiles, async (file) => {
      const resInfo = getFileResourceInfo(file, { jsonPath: file.jsonPath })
      getImageThumbnail(
        { ...file, ...resInfo },
        authToken,
        (thumbnail) => {
          const newFile = {
            ...file,
            status: 'done',
            jsonPath: file.jsonPath,
            ...resInfo,
            thumbUrl: thumbnail,
          }
          updateFile(newFile)
        })
    }, { concurrency: 10 }).then(() => INFO('Thumbnail files loaded'))

    setLoading(false)
  }, [])

  useEffect(() => {
    if (!initialised) {
      init()
      setInitialised(true)
    }
  }, [init, initialised])

  function handleCloseModal() {
    setPreviewModalVisible(false)
  }

  function handleOpenModal() {
    setPreviewModalVisible(true)
  }

  function handlePreview(file) {

    if (file == null) {
      ERROR(ILLEGAL_ARGUMENT_EXCEPTION)
      return
    }

    const images = UploadFileService.filterImage(fileList)

    // If is an image show preview else download the file
    if (images.find(i => file.name === i.name)) {
      handleOpenModal()
      setSelectedImageName(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    } else {
      downloadFile(file)
    }

  }

  async function downloadFile(file) {
    if (file == null) {
      console.error(ILLEGAL_ARGUMENT_EXCEPTION)
      return
    }

    const fileContent = await getFileListFromServer([file], null)
    const url = fileContent[file.name]

    if (url == null) {
      console.error('No content for this file ' + file.name)
      return
    }
    const fileType = FileUploaderService.getFileType(file)
    downloadjs(url, file.name, fileType)
  }

  return (
    <div className="clearfix">
      <Upload
        listType="picture"
        fileList={fileList}
        onPreview={handlePreview}
        disabled
      />
      {loading ? <Skeleton active row={30} /> : null}
      <ImageCarouselModal
        selectedImageName={selectedImageName}
        fileList={fileList}
        downloadFile={downloadFile}
        fileCollection={filesCollected}
        previewVisible={previewModalVisible}
        onCloseModal={handleCloseModal}
        type={IMAGE_MODAL_PREVIEW_TYPE.FROM_FILE_COLLECTION}
      />
    </div>

  )
}

ReduceFileComponent.propTypes = {
  authToken: PropTypes.string.isRequired,
  field: PropTypes.shape({
    id: PropTypes.string,
    readOnly: PropTypes.bool
  }).isRequired,
  filesCollected: PropTypes.arrayOf(PropTypes.shape({
    jsonPath: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired
}

const ReducedFile = connect(
  state => {
    return {
      authToken: state.user.authToken
    }
  },
  {}
)(ReduceFileComponent)

export default ReducedFile

