import React, {useCallback, useMemo} from "react";

import {
  EyeOutlined,
  TableOutlined,
  LayoutOutlined,
  ProfileOutlined,
  ControlOutlined,
  SortAscendingOutlined,
  SwapOutlined,
  PartitionOutlined
} from "@ant-design/icons";

import { useROEConfiguratorContext } from "../context";
import Header from '../../../components/ConfigurationConfiguratorBeta/Header'
import * as S from '../../ConfigurationConfiguratorBeta/styles'
import * as C from '../constants';
import {useHistory, useLocation} from "react-router-dom";
import {message, Popconfirm, Tooltip} from "antd";
import { __, T } from '../../../utils/translationUtils'

/* TODO: to delete on old configuration configurator deletion */
const GoToOldConfigurator = ({ mode }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <Popconfirm
      title={
        <>
          <S.CustomText weight={700} size={'large'}> {__('You are moving to the old configurator')} </S.CustomText>
          <S.CustomText> {__('Changes will be lost')} </S.CustomText>
          <S.CustomText> {__('Do you want to leave?')} </S.CustomText>
        </>
      }
      okText={__('Yes')}
      cancelText={__('No')}
      placement="bottomRight"
      onConfirm={() => {
        if (mode === C.mode.CREATE) history.push('/remoteObjects/deprecatedCreate')
        if (mode === C.mode.EDIT) history.push(location.pathname.split('/').slice(0,-1).join('/') + '/deprecatedEdit')
      }}
    >
      <Tooltip title={__('Move to the old configurator')} placement='left'>
        <SwapOutlined style={{marginLeft: '10px'}}/>
      </Tooltip>
    </Popconfirm>
  )
}

const SystemObject = ({ system, object, mode }) => {
  if (!system || !object) return null
  return (
    <>
      <S.FlexColumn>
        <S.Flex>
          <S.CustomText weight={700} display={'inline-flex'} padding={'0 5px'}>
            {__('System')}:
          </S.CustomText>
          <S.CustomText display={'inline-flex'}>
            {system}
          </S.CustomText>
        </S.Flex>
        <S.Flex>
          <S.CustomText weight={700} display={'inline-flex'} padding={'0 5px'}>
            {__('Object')}:
          </S.CustomText>
          <S.CustomText display={'inline-flex'}>
            {object}
          </S.CustomText>
        </S.Flex>
      </S.FlexColumn>
      <GoToOldConfigurator
        mode={mode}
      />
    </>
  )
}

export default () => {

  const { state, setActiveStep, setStepValidationVisibility} = useROEConfiguratorContext()
  const { activeStep, mode, system, object, validationState } = state

  const steps = useMemo(() => {
    switch (activeStep) {
      case C.steps.LISTING_OUTPUT_FIELDS:
      case C.steps.LISTING_FILTERS:
      case C.steps.LISTING_SORTING_PARAMETERS:
      case C.steps.LISTING_VIEW_PROPERTIES:
        return [
          {title: __("output_fields"), value: "OutputFields", icon: <TableOutlined />},
          {title: __("filters"), value: "Filters", icon: <ControlOutlined />},
          {title: __("sorting"), value: "Sorting", icon: <SortAscendingOutlined />},
          {title: __("view_properties"), value: "ViewProperties", icon: <EyeOutlined />},
        ]
      case C.steps.CONTENT_CHOOSE_FIELDS:
      case C.steps.CONTENT_ORGANISE_FIELDS:
      case C.steps.SELECT_RELATED_OBJECTS:
        return [
          {title: __("choose_fields"), value: "ChooseFields", icon: <ProfileOutlined />},
          {title: __("organise_fields"), value: "OrganiseFields", icon: <LayoutOutlined />},
          {title: __("select_related_content"), value: "SelectRelatedContent", icon: <PartitionOutlined />},
        ]
      default:
        return null
    }
  }, [activeStep])

  const onStepChange = useCallback((subCurrent) => {
    if (validationState[activeStep] != null && !validationState[activeStep]) {
      setStepValidationVisibility(C.validationActivatedAfter.CLOSE)
      message.error(
        __((
            [C.steps.LISTING_OUTPUT_FIELDS, C.steps.CONTENT_CHOOSE_FIELDS].includes(activeStep) && T.chooseFieldsValidationError)
          || (activeStep === C.steps.LISTING_VIEW_PROPERTIES && T.viewPropertiesValidationError)
        )
      )
      return
    }
    switch (activeStep) {
      case C.steps.LISTING_OUTPUT_FIELDS:
      case C.steps.LISTING_FILTERS:
      case C.steps.LISTING_SORTING_PARAMETERS:
      case C.steps.LISTING_VIEW_PROPERTIES:
        setActiveStep(subCurrent + C.steps.LISTING_OUTPUT_FIELDS)
        break
      case C.steps.CONTENT_CHOOSE_FIELDS:
      case C.steps.CONTENT_ORGANISE_FIELDS:
      case C.steps.SELECT_RELATED_OBJECTS:
        setActiveStep(subCurrent + C.steps.CONTENT_CHOOSE_FIELDS)
        break
      default:
        return
    }
  }, [activeStep, setActiveStep, validationState, setStepValidationVisibility])


  const { subActiveStep, title } = useMemo(() => {
    switch (activeStep) {
      case C.steps.LISTING_OUTPUT_FIELDS:
      case C.steps.LISTING_FILTERS:
      case C.steps.LISTING_SORTING_PARAMETERS:
      case C.steps.LISTING_VIEW_PROPERTIES:
        return {
          subActiveStep: activeStep - C.steps.LISTING_OUTPUT_FIELDS,
          title: __(`ro_listing_configuration`)
        }
      case C.steps.CONTENT_CHOOSE_FIELDS:
      case C.steps.CONTENT_ORGANISE_FIELDS:
      case C.steps.SELECT_RELATED_OBJECTS:
        return {
          subActiveStep: activeStep - C.steps.CONTENT_CHOOSE_FIELDS,
          title: __(`ro_content_configuration`)
        }
      default:
        return { title: __(`ro_configuration`) }
    }
  }, [activeStep, mode])

  return (
    <Header
      title={title}
      steps={steps}
      activeStep={subActiveStep}
      onStepChange={onStepChange}
      extra={
        <SystemObject
          system={system.label}
          object={object.label}
          mode={mode}
        />
      }
      ghostExtra={true}
    />
  )
}
