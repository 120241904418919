import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {change} from 'redux-form'
import * as CONST from '../constant'
import {ILLEGAL_ARGUMENT_EXCEPTION, UPLOAD_FILE_TYPE} from '../constant'
import {Collapse} from 'antd'
import FileUploaderService from '../services/fileUploaderService'
import UploadFile from "../components/UploadFile";
import * as S from '../styles'
import { __ } from '../../../utils/translationUtils'

const {Panel} = Collapse


FileUploader.propTypes = {
  change: PropTypes.func,
  formName: PropTypes.string,
  field: PropTypes.shape({
    id: PropTypes.string,
    source: PropTypes.string,
    controllerOptions: PropTypes.object.isRequired
  }).isRequired
}

function FileUploader({change, formName, field }) {

  const [filesTree, setFilesTree] = useState(null)
  const [fieldSource, setFieldSource] = useState(null) // I have to initialize this otherwise change of redux form  does not make me update the files

  useEffect(() => {
    // Need to check even field source because I need to get the xsdName, the primaryKey, the fieldName

    if (field?.controllerOptions != null) {
      let source = field?.source || field?.value
      try {
        source = JSON.parse(source)
      } catch (e) {
        console.warn(`Cannot parse the source for the file:\n ${source} \n Error: ${e}`)
        // Use controller Options when ready
        source = {}
      }
      source = fieldSource || source
      const treeSource = FileUploaderService.getFilesTree(source, field.controllerOptions)
      setFilesTree(treeSource)

    }
  }, [field, fieldSource])

  if (filesTree == null || filesTree[CONST.RootDirectory] == null) {
    return null
  }

  const handleUpload = async (jsonPath, file) => {
    if (file == null || jsonPath == null || jsonPath === '') {
      console.error(ILLEGAL_ARGUMENT_EXCEPTION)
      return null
    }

    const treeSource = JSON.parse(JSON.stringify(filesTree))

    await FileUploaderService.addFileToFilesTree(treeSource, jsonPath, file)

    const fieldSource = FileUploaderService.recomputeSource(treeSource)
    setFilesTree(treeSource)
    setFieldSource(fieldSource)

    change(FileUploaderService.retrieveFormName(formName), field.id, JSON.stringify(fieldSource))
  }

  const handleRemove = async (jsonPath, file) => {
    if (file == null || jsonPath == null || jsonPath === '') {
      console.error(ILLEGAL_ARGUMENT_EXCEPTION)
      return null
    }

    const treeSource = JSON.parse(JSON.stringify(filesTree))

    FileUploaderService.removeFileFromFilesTree(treeSource, jsonPath, file)
    const fieldSource = FileUploaderService.recomputeSource(treeSource)

    setFilesTree(treeSource)
    setFieldSource(fieldSource)

    change(FileUploaderService.retrieveFormName(formName), field.id, JSON.stringify(fieldSource))


  }

  function isDisabled(fileDefinition) {
    if (fileDefinition == null){
      console.error(ILLEGAL_ARGUMENT_EXCEPTION)
      return true
    }

    if (field?.readOnly) return true

    const fileSizes = (fileDefinition.files || []).reduce((totalSize, file) => {
      totalSize += file.size
      return totalSize
    }, 0)

    if (fileDefinition?.maxSize < fileSizes)
      return true

    if (fileDefinition?.maxAmount != null && (fileDefinition.files || []).length >= fileDefinition.maxAmount)
      return true

    return fileDefinition?.maxAmount === 0
  }

  const renderFilesDefinitions = (directory, depth, activeKeys) => {
    if (!Array.isArray(directory?.fileDefinitions)) return null

    return (
      <div>
        <div>
          <Collapse defaultActiveKey={activeKeys} ghost>
            {
              directory.fileDefinitions.map((fileDefinition, index) => {
                const readOnly = isDisabled(fileDefinition)
                
                return (
                  <Panel
                    className={(!fileDefinition || !fileDefinition.files || fileDefinition.files.length === 0) ? "emptyPanel" : null}
                    key={activeKeys[index]}
                    header={`${fileDefinition.name}`}>
                    <UploadFile
                      version={UPLOAD_FILE_TYPE.FILE_DEFINTION}
                      directory={directory}
                      field={field}
                      onUploadFile={handleUpload}
                      onRemoveFile={handleRemove}
                      fileDefinition={fileDefinition}
                      isReadOnly={readOnly}
                      context={{
                        changeFileName: true
                      }}
                    />
                  </Panel>)
                }
              )
            }
          </Collapse>
        </div>
      </div>
    )
  }


  const renderPanelDirectories = (directoryPath, depth = 0) => {
    if (directoryPath == null || (Array.isArray(directoryPath) && directoryPath.length === 0)) return null
    if (!Array.isArray(directoryPath)) directoryPath = [directoryPath]

    const activeKeys = directoryPath.map((directory, index) =>
      `directory_${directory.name}_depth_${depth}_index_${index}`)

    const nextDepth = depth + 1

    const invalid =  directoryPath.find(dP => (field.error || []).includes(dP.jsonPath))

    if (field?.controllerOptions?.rootDirectory?.allowExtraFiles === false && depth === 0) {
      return (
        directoryPath.map((directory, index) =>
          <React.Fragment>
            {renderFilesDefinitions(directory, nextDepth, activeKeys)}
            {renderPanelDirectories(directory.subDirectories, nextDepth)}
          </React.Fragment>
        )
      )
    }
    return (
      <S.Directory depth={depth}>
        <S.CustomCollapse defaultActiveKey={activeKeys} ghost error={!depth && invalid} depth={depth}>
          {
            directoryPath.map((directory, index) =>
              <Panel key={`directory_${directory.name}_depth_${depth}_index_${index}`}
                     header={depth === 0 && field.title ?
                       <span>{__(field.title || directory.name) + ((field?.required && !depth) ? ' *' : '')} </span>
                       :
                       <span>{__(directory.name) + ((field?.required && !depth) ? ' *' : '')}</span>
                     }
              >
                {renderFilesDefinitions(directory, nextDepth, activeKeys)}
                {directory.allowExtraFiles &&
                  <UploadFile
                    directory={directory}
                    field={{ ...directory, readOnly: field?.readOnly }}
                    isReadOnly={!!field?.readOnly}
                    onUploadFile={handleUpload}
                    onRemoveFile={handleRemove}
                    version={UPLOAD_FILE_TYPE.DIRECTORY_DEFINITION}
                  />}
                {renderPanelDirectories(directory.subDirectories, nextDepth)}
              </Panel>
            )
          }
        </S.CustomCollapse>
        { invalid && !depth && (
          <span className="text-danger">
            {__('This is a mandatory field')}
          </span>
        )}
      </S.Directory>
    )


  }


  return renderPanelDirectories(filesTree[CONST.RootDirectory], 0)

}


export default connect(
  () => ({}),
  {change}
)(FileUploader)
