import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {Button, message, Table} from 'antd'
import Glyphicon from '@strongdm/glyphicon'
import {api, fetchWidgets} from '../../providers/ApiProvider'
import * as config from '../../constants/globalConfiguration'
import {cloneWidget, getWidgetFastActionUrl} from '../../utils/appHelper'
import Loading from '../../components/Loading'
import NoDashboardSettings from "../../components/Dashboard/NoDashboardSettings";
import ActionOptions from "../../components/UI/ActionOptions";
import {CopyOutlined, DeleteOutlined, EditOutlined, MoreOutlined, DownloadOutlined} from "@ant-design/icons";
import {hashHistory} from "../../providers/HistoryProvider";
import * as S from "../../providers/StyleProvider/styles";
import { __ } from '../../utils/translationUtils'

const PAGINATION_INITIAL_STATE = {
  search: null,
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  sorter: null
}

const Widgets = () => {
  const [pagination, setPagination] = useState(PAGINATION_INITIAL_STATE)
  const [data, setData] = useState()
  const [loading, setLoading] = useState(null)
  const [hasConfigurations, setHasConfigurations] = useState(true)

  async function fetchData(e) {
    const { current, pageSize, sorter, search } = e
    setLoading(true)

    const widgetResponse = await fetchWidgets({
      pageSize,
      pageNumber: current,
      q: search,
      sort: sorter ? `${sorter?.order === 'ascend' ? '' : '-'}${sorter.field}` : undefined,
    })

    setLoading(false)
    setData(widgetResponse?.data)
    if (!widgetResponse?.data?.length) {
      const configurationsResponse = await api.get(`${config.prefix()?.REPORT || ''}/cube/configurations`).then((response) => response?.data?.[0]?.id)
      if (!configurationsResponse) setHasConfigurations(false)
    }
    setPagination((e) => ({
      ...e,
      current: Number(widgetResponse.headers?.['x-page']),
      pageSize: Number(widgetResponse.headers?.['x-page-size']),
      total: widgetResponse.headers?.['x-total-count']
    }))
  }

  useEffect(() => {
    if (!pagination?.current || !pagination?.pageSize) return
    fetchData(pagination)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.pageSize, pagination?.current, pagination?.sorter, pagination?.search])

  const handlePaginationChange = ({ current, pageSize }, _, sorter) => {
    setPagination((e) => ({ ...e, current, pageSize, sorter }))
  }

  const handleSearch = (value) => {
    setPagination((e) => ({...e, search: value}))
  }

  function handleDelete(id) {
    api.delete(getWidgetFastActionUrl(id, "delete"))
      .then(() => {
        message.success(__('The widget has been correctly removed'))
        fetchData(pagination)
      })
      .catch((err) => console.log(err))
  }

  async function handleClone(id) {
    cloneWidget(id).then(() => { fetchData(pagination) })
  }

  function handleExport(id) {
    api.get(getWidgetFastActionUrl(id, "export"), {responseType: 'arraybuffer'})
      .then((response) => {
        if (response.status === 200) {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          const blob = new Blob([response.data], { type: 'application/json' })
          const url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = `widget-${moment().format(config.appDefaults.dateFormatFiles)}.json`
          a.click()
          window.URL.revokeObjectURL(url)
        }
      }).catch((ex) => {
    })
  }

  return (
    <div id="main-content">
      <h1 className="sticky">
        <div className="container">
          { __('Widgets')}
        </div>
      </h1>
      <div className="container">
        {loading && <Loading/>}
        {data && (!hasConfigurations ? <NoDashboardSettings /> :
          <div>
            <S.FlexSpace>
              <S.FullWidthInputSearch
                allowClear
                placeholder={__('Widgets search')}
                onSearch={handleSearch}
              />
              <Link className="btn btn-primary col-xs-12 col-sm-3 gutter-bottom" to="/widgets/create">
                <Glyphicon glyph="plus"/>
                {__('Create widget')}
              </Link>
            </S.FlexSpace>
            {data && (
              <div>
                <Table
                  pagination={pagination}
                  dataSource={data}
                  columns={[
                    {
                      title: __('Widget name'),
                      dataIndex: 'name',
                      key: 'name',
                      sorter: () => 0,
                      render: (e, r) => (r.name)
                    },
                    {
                      title: __('Description'),
                      dataIndex: 'description',
                      sorter: () => 0,
                      key: 'description'
                    },
                    {
                      title: __('Last Update DateTime'),
                      dataIndex: 'lastUpdateDateTime',
                      key: 'lastUpdateDateTime',
                      sorter: () => 0,
                      render: (e) => (e ? moment(e).format(config.appDefaults.dateTimeFormat) : '')
                    },
                    {
                      dataIndex: 'id',
                      key: 'id',
                      width: 50,
                      render: (e) => (
                        <ActionOptions
                          options={[
                            {
                              value: 'edit',
                              label: __('Edit'),
                              icon: <EditOutlined />,
                              onClick: () => hashHistory.push(`/widgets/${e}`),
                            },
                            {
                              value: 'clone',
                              label: __('Clone'),
                              icon: <CopyOutlined />,
                              onClick: () => handleClone(e),
                            },
                            {
                              value: 'export',
                              label: __('Export'),
                              icon: <DownloadOutlined />,
                              onClick: () => handleExport(e),
                            },
                            {
                              value: 'delete',
                              label: __('Delete'),
                              icon: <DeleteOutlined />,
                              onClick: () => handleDelete(e),
                              danger: true,
                            }
                          ]}
                        >
                          <Button
                            icon={<MoreOutlined />}
                            size={'small'}
                            shape={'circle'}
                          />
                        </ActionOptions>
                      )
                    }
                  ]}
                  rowKey="id"
                  onChange={handlePaginationChange}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Widgets
