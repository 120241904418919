import {useEffect, useMemo, useState} from 'react'

import _ from 'lodash'

import {getAllTranslations} from "../utils/appHelper";

const getItemListFromObject = (object) => (
  _.isObject(object) ? Object.entries(object).map?.(([ value, label]) => ({ value, label })) : []
)

export default () => {
  const [translations, setTranslations] = useState(null)
  const translationList = useMemo(() => getItemListFromObject(translations), [translations])
  useEffect(() => {
    if (translations) return
    setTranslations(getAllTranslations())
  })
  return translationList
}
