import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import CreateOrderForm from '../components/CreateOrderForm';
import { createOrder } from '../providers/ReduxProvider/actions/orderActions';
import { getGuiUser } from '../providers/ReduxProvider/actions/userActions';
import { checkUserRights, fetchUserEntities, showMessage } from '../utils/appHelper';

import DATA_INTERFACE_DISCRIMINATOR from './../utils/dataInterfaceDiscriminator';
import moment from "moment";
import { hashHistory } from '../providers/HistoryProvider';
import { api } from '../providers/ApiProvider'
import { __ } from '../utils/translationUtils'

const loadingBar = require('nprogress');


class CreateOrderPage extends React.Component {
  static propTypes = {
    createOrder: PropTypes.func,
    user: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    getGuiUser: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      entities: [],
      deadlineOperations: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getGuiUser()
      .then(() => this.getData())

  }

  getData() {

    const { user } = this.props;
    this.setState({ isLoading: true });
    if (!user?.id) return
    fetchUserEntities(user.id).then((resp) => {
      this.setState({
        entities: resp?.data,
        isLoading: false
      });
    }).catch((e) => this.setState({ isLoading: false }))

  }

  handleSubmit(values) {
    loadingBar.start();
    this.setState({
      isLoading: true
    });

    if (values.processes != null) {
      values.processes = Object.entries(values.processes).map(([processId, { deadlineEndExpression: processDeadlineEndExpression, notBeforeEndExpression, startExpression, endExpression, location: coordinates }]) => {

        let result = { id: processId }

        if (processDeadlineEndExpression != null) {
          const endExpressionBase = {
            discriminator: DATA_INTERFACE_DISCRIMINATOR.STATIC_DATA,
            value: moment(processDeadlineEndExpression).toISOString()
          };

          result = {
            ...result,
            deadlineFormula: {
              endExpression: {
                base: endExpressionBase,
              }
            }
          }
        }

        if (startExpression != null || endExpression != null) {

          const startExpressionBase = {
            discriminator: DATA_INTERFACE_DISCRIMINATOR.STATIC_DATA,
            value: moment(startExpression).toISOString()
          };

          const endExpressionBase = {
            discriminator: DATA_INTERFACE_DISCRIMINATOR.STATIC_DATA,
            value: moment(endExpression).toISOString()
          };

          result = {
            ...result,
            betweenDatesFormula: {
              startExpression: {
                base: startExpressionBase,
              },
              endExpression: {
                base: endExpressionBase,
              }
            }
          }
        }


        if (typeof window !== "undefined" && window.location != null && coordinates != null) {
          result = {
            ...result,
            location: {
              type: "Point",
              coordinates: Object.values(coordinates) // [latitude, longitude]
            }
          };
        }

        return result


        // Maybe I have to add something like that in the payload in the future who knows
        /*        const startExpressionBase = {
                  discriminator: DATA_INTERFACE_DISCRIMINATOR.STATIC_DATA,
                  value: moment().toISOString()
                };*/

      })
    } else {
      delete values.processes;
    }

    if (values.deadlineFormula && values.deadlineFormula.endExpression) {

      const endExpressionBase = {
        discriminator: DATA_INTERFACE_DISCRIMINATOR.STATIC_DATA,
        value: moment(values.deadlineFormula.endExpression).toISOString()
      };

      // Maybe I have to add something like that in the payload in the future who knows
      /*const startExpressionBase = {
        discriminator: DATA_INTERFACE_DISCRIMINATOR.STATIC_DATA,
        value: moment().toISOString()
      };*/

      values.deadlineFormula = {
        endExpression: {
          base: endExpressionBase,
        }
      }

    } else {
      delete values.deadlineFormula
    }

    if (values['assignToMe'] && values['defaultAssignees']) {
      const { user } = this.props;
      values = {
        ...values,
        defaultAssignees: [{ userId: user.id, entityId: values['defaultAssignees'], type: 'USER' }]
      }

    } else {
      delete values['defaultAssignees']
    }

    delete values['assignToMe'];
    delete values['setExternalId'];

    if (values['orderTypeId'] == null) {
      showMessage('error', __("Please fill order information"));
    }

    const objKeys = Object.keys(values).filter(k => k.startsWith('objectTypeRequiredAtInitialization,'))
    const objectTypeRequiredAtInitialization = {}

    objKeys.forEach((k) => {
      objectTypeRequiredAtInitialization[k.replace('objectTypeRequiredAtInitialization,', '')] = values[k]?.selectedItems?.[0]
      delete values[k]
    })

    if (Object.keys(objectTypeRequiredAtInitialization).length) {
      values['objectTypeRequiredAtInitialization'] = objectTypeRequiredAtInitialization
    }


    api.post('/orders', values)
      .then(
        response => {

          if ([200, 201].includes(response.status)) {
            showMessage('success', __('Order created successfully'));
            loadingBar.done()
            hashHistory.push(`/orders/${response.data.id}/overview`)
          }
        }
      )
      .catch((error) => {
        showMessage('error', __('ServerSideError') + '. ' + error);
        this.setState({ isLoading: false })
        loadingBar.done();
      });
  }


  render() {
    const { isLoading } = this.state;
    const { user } = this.props;

    const { rightMappings } = global.constants

    return (
      <div id="main-content">
        <h1 className={'sticky create'}>
          <div className="container">
            {__("Create Order")}
          </div>
        </h1>

        <div className="container">
          <CreateOrderForm
            isLoading={isLoading}
            canCreate={checkUserRights(user && user.rights, rightMappings.CAN_CREATE_ORDERS)}
            onSubmit={this.handleSubmit.bind(this)}
            entities={this.state.entities}
            initialValues={{ priority: 'NORMAL' }}
          />
        </div>
      </div>
    );
  }
}
export default connect(
  state => {
    return {
      user: state.user.data,
    };
  },
  { createOrder, getGuiUser }
)(CreateOrderPage);
