import React, {useEffect, useState} from "react";
import {Panel} from "react-bootstrap";
import {__, T} from "../../utils/translationUtils";
import {fetchUsers} from "../../providers/ApiProvider/entity";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import moment from "moment/moment";
import {UsersField} from "../../utils/renderFieldUtils";
import {checkMatchingTemplates} from "../../utils/rightsUtils";


type ProcessesTableProps = {
  order: OrderNS.Order
  currentUser: User
}

type DataItemType = {
  id: string,
  title: string,
  internalProcessStatus: string,
  processStatus: string,
  assignees: UserOverview[] | undefined,
  responsibleUser: UserOverview[] | undefined,
  internalOrderStatus: string,
  orderStatus: string,
  lastUpdateDatetime: string
}

const ProcessesTable = ({currentUser, order}: ProcessesTableProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [userMap, setUserMap] = useState<Record<string, User> | undefined>(undefined)
  const [tableData, setTableData] = useState<Array<DataItemType> | undefined>(undefined)

  useEffect(() => {
    if (tableData || !open) return
    const processes = (order.processes || [])

    const allAssigneesId = [...new Set(processes
      ?.map(({ownership}) => ownership?.assignees
        ?.map(({userId}) => userId)).flat())].join()

    fetchUsers({ids: allAssigneesId, statuses: ["enabled", "reserved", "disabled", "deleted"]})
      .then((users) => {

        return users.reduce((userMap, user) => {
          userMap[user.id] = user
          return userMap
        }, {} as Record<string, User>)
      })
      .then((userMap) => setUserMap(userMap))
  }, [order, open])

  useEffect(() => {
    if (!userMap) return
    setTableData(
      (order.processes || [])
        ?.map(({id, title, internalProcessStatus, processStatus, lastUpdateDatetime, ownership}) => {
          const uniqueAssignees = new Set<string>();
          const filteredAssignees = ownership?.assignees
            ?.filter(assignee => {
              if (uniqueAssignees.has(assignee.userId)) {
                return false;
              }
              uniqueAssignees.add(assignee.userId);
              return true;
            });

          return {
            id,
            title: __(title),
            internalProcessStatus,
            processStatus: __(processStatus),
            assignees: ownership?.assignees?.map(({userId}) => userMap[userId] as UserOverview),
            responsibleUser: ownership?.master ? [ownership?.master] : filteredAssignees?.map(({userId}) => userMap[userId] as UserOverview),
            internalOrderStatus: order.internalOrderStatus,
            orderStatus: __(order.orderStatus),
            lastUpdateDatetime: moment(lastUpdateDatetime).startOf("minute").fromNow()
          }
        })
    )
  }, [userMap])

  const togglePanelOpen = () => {
    setOpen((open) => !open);
  }

  const isSuperUser = checkMatchingTemplates(currentUser.matchingTemplates, ['ROOT', 'PA'])

  return isSuperUser ? (
    <div className="fieldset style-1">
      <span
        className="collapsible-header no-selection"
        onClick={togglePanelOpen}
      >
        {`${__('processes_details', "capitalise")} - (${order.processes?.length || 0})`}
      </span>

      <Panel
        style={{marginBottom: '40px'}}
        expanded={open}
        onToggle={() => {
        }}
        bsStyle="info"
      >
        <Panel.Body collapsible>
          <div style={{maxHeight: '350px', overflowY: 'auto'}}>
            {
              tableData && <BootstrapTable
								data={tableData || []}
								remote
								pagination={false}
								hover
								striped
								options={{noDataText: __('NoDataFound')}}
							>
								<TableHeaderColumn
									isKey
									hidden
									dataField="id"
								/>

								<TableHeaderColumn dataField="title">
                  {__(T.process_title, "capitalise")}
								</TableHeaderColumn>

								<TableHeaderColumn dataField="internalProcessStatus">
                  {__(T.internal_process_status, "capitalise")}
								</TableHeaderColumn>

								<TableHeaderColumn dataField="processStatus">
                  {__(T.status, "capitalise")}
								</TableHeaderColumn>

								<TableHeaderColumn
									dataSort={false}
									dataField="responsibleUser"
									dataFormat={(cell, _) => <UsersField users={cell as Array<UserOverview>}/>}
								>
                  {__(T.responsibleUser, "capitalise")}
								</TableHeaderColumn>

								<TableHeaderColumn
									dataSort={false}
									dataField="assignees"
									dataFormat={(cell, _) => <UsersField users={cell as Array<UserOverview>}/>}
								>
                  {__(T.assignees, "capitalise")}
								</TableHeaderColumn>

								<TableHeaderColumn dataField="internalOrderStatus">
                  {__(T.internal_order_status, "capitalise")}
								</TableHeaderColumn>

								<TableHeaderColumn dataField="orderStatus">
                  {__(T.order_status, "capitalise")}
								</TableHeaderColumn>

								<TableHeaderColumn dataField="lastUpdateDatetime">
                  {__(T.lastUpdateDatetime, "capitalise")}
								</TableHeaderColumn>
							</BootstrapTable>}
          </div>
        </Panel.Body>
      </Panel>
    </div>
  ) : null
}

export default ProcessesTable
