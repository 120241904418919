export const getItemValues = (values, mainKey, attributesToBeDisplayed) => {
  if ( values && values[mainKey] && values[mainKey].displayData && attributesToBeDisplayed ) {
    const obj = values[mainKey].displayData;
    
    return attributesToBeDisplayed.map((da) => {
      return { id: da.id, value: (obj)[da?.id] } || { id: da.id, value: null };
    });
  }
  
  return [];
}

