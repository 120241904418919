import React, {useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {__} from "../../../utils/translationUtils";
import {cases} from "../../../utils/stringUtils";
import {Form} from "components";
import {OrgItem, reassignUserInputs, ResolutionType, UserItem} from "./config";
import {FormWrapper} from "./styles";
import {
  UseBlockedOrdersByUserIdQuery,
  UseEntitiesQuery,
  UseUsersQuery
} from "../../../providers/QueryClientProvider/queries";
import {LoadingOutlined} from "@ant-design/icons";


type ReassignModalProps = {
  user: User
  currentAssignee?: UserOverview
  newStatus: Statuses
  submit: (newUserId?: string) => void
  close: () => void
}

const ReassignModal = ({user, newStatus, currentAssignee, submit, close}: ReassignModalProps) => {
  const [resolution, setResolution] = useState<ResolutionType>("reassign")
  const [newAssignee, setNewAssignee] = useState<string | undefined>(undefined)

  const {data: blockedOrdersCount, isFetched,} = UseBlockedOrdersByUserIdQuery(user.id)
  const {data: organizations} = UseEntitiesQuery({ pageSize: 100, type: "organisation"})
  const {data: users} = UseUsersQuery()

  const orgItems: OrgItem[] | undefined = useMemo(() => {
    if (organizations && blockedOrdersCount) return organizations.map(({id, name, shortName}) => ({
      value: id,
      label: `${name} (${shortName})`
    }))
  }, [blockedOrdersCount, organizations]);

  const userItems: UserItem[] | undefined = useMemo(() => {
    if (users && blockedOrdersCount) return users.reduce((acc, {id, firstName, lastName, username, belongsTo}) => {
      if (id !== user.id) acc.push({
        value: id,
        label: `${firstName} ${lastName} (${username})`,
        belongsTo: (belongsTo || [])?.map(({id}) => id)
      })
      return acc
    }, [] as UserItem[])
  }, [blockedOrdersCount, users]);

  return (
    <Modal
      backdrop="static"
      show
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {__('confirm_user_status_change', cases.CAPITALIZE_SENTENCE)} {newStatus}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          !isFetched && <LoadingOutlined style={{display: 'block', fontSize: 24}} spin/>
        }
        {
          isFetched && !!blockedOrdersCount && orgItems && userItems && (
            <>
              <p>{__('for_user', cases.CAPITALIZE_SENTENCE)} <b>{user.firstName} {user.lastName} ({user.username})</b></p>
              <p>{__('reassign_header_description')}</p>
              <FormWrapper>
                <Form
                  id="choose-resolution-user-form"
                  key="choose-resolution-user-form"
                  layout="vertical"
                  showSubmitButton={false}
                  initialValues={{...{resolution, organization: null, user: currentAssignee?.id}}}
                  inputs={reassignUserInputs({data: {orgItems, userItems}, __})}
                  onValuesChange={(formValues: Record<string, any>) => {
                    setResolution(formValues.resolution)
                    setNewAssignee(formValues.user)
                  }}
                />
              </FormWrapper>
            </>
          )
        }
        {
          isFetched && !blockedOrdersCount && __('no_reassign_needed_message', cases.CAPITALIZE_SENTENCE)
        }

      </Modal.Body>
      <Modal.Footer>
        <div className="group">
          <Button onClick={close} className="pull-left">
            {__('Back')}
          </Button>
          <button
            onClick={() => submit(newAssignee)}
            disabled={!!blockedOrdersCount && resolution === "reassign" && !newAssignee}
            className={'capitalize btn pull-right btn-primary'}
          >
            {__('continue')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ReassignModal
