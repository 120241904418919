import * as ACTION_TYPE from "./actionTypes"
import * as ACTION from "./actions"

export default (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.INITIALIZE_CONFIGURATOR:
      return ACTION.initializeConfigurator(state, action.payload)

    case ACTION_TYPE.ERROR_FETCH:
      return ACTION.fetchError(state)

    case ACTION_TYPE.UPDATE_CONFIGURATION:
      return ACTION.updateConfiguration(state, action.payload)
    case ACTION_TYPE.UPDATE_ATTRIBUTES:
      return ACTION.updateAttributes(state, action.payload)
    default:
      return { ...state }
  }
}
