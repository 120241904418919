import styled from 'styled-components'

const LEFT_SIDEBAR_WIDTH = 300

export const CalendarsDashboard = styled.div`
  padding: 1rem;
  
  @media (min-width: 480px) {
    margin-top: 0;
    padding: 0 2rem;
  }

  @media print  {
    padding: 0;
    margin: 0;
  }

`

export const Top = styled.div`
  display: none;
  @media (min-width: 768px) {
    margin-left: ${(props) => (props.isSidebarOpen ? `calc(${LEFT_SIDEBAR_WIDTH}px + 3rem)` : 0)};
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
`

export const RadioGroupWrapper = styled.div`

  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.thirdary};
  }

  .ant-radio:hover .ant-radio-inner{
    border-color: ${(props) => props.theme.colors.thirdary};
  }


  .ant-radio-inner::after {
    background-color:  ${(props) => props.theme.colors.thirdary};
  }
 
`

export const TreeSelectWrapper = styled.div`
  position: relative;
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
    border-color: ${(props) => props.theme.colors.thirdary};
  }

  .ant-select-multiple .ant-select-selection-item-content{
    overflow: visible;
  }

`

export const TreeSelectSearch = styled.div`
  position: absolute;
  background: #0a4f7a;
  top: 0;
  right: 0;
  color: white;
  bottom: 0;


  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 .5rem;
  }
  &:hover{
    cursor: pointer;
  }
`

export const TreeNode = styled.div`
  font-size:1.25rem;
  display: flex;
  align-items: center;
  white-space: pre;
  svg {
    color: ${(props) => props.theme.colors.thirdary};
    font-size:1.5rem;
    margin-right: .5rem;
  }
  small {
    margin-left: .25rem;
    color: ${(props) => props.theme.colors.grey.primary};
  }
`

export const TopLeft = styled.div`
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.div`
  svg {
    &:hover{
      cursor: pointer;
      color: ${(props) => props.theme.colors.thirdary} !important;
    }
  }
`

export const SelectedDate = styled.div`
  font-size: 2rem;
  letter-spacing: 0.04rem;
  color: ${(props) => (props.isToday ? props.theme.colors.thirdary : 'auto')};
`

export const TopRight = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.div`
  text-align: center;
  text-transform: uppercase;
`

export const ButtonWrapper = styled.div`
  border-radius: 4px;

  ${(props) => props.isToday && `
    button {
      border-color: ${props.theme.colors.thirdary} !important;
      color: ${props.theme.colors.thirdary} !important;
    }
  `}
  ${(props) => props.isActive && `
    button {
      background-color: ${props.theme.colors.thirdary} !important;
      border-color: ${props.theme.colors.thirdary} !important;
      color: white !important;
      text-transform: capitalize;
      &:hover,
      &:hover,
      &:active,
      {
        background-color: ${props.theme.colors.thirdary} !important;
        border-color: ${props.theme.colors.thirdary} !important;
        color: white !important;
        text-transform: capitalize;
      }
    }
  `};
  ${(props) => !props.isActive && `
    button {
      text-transform: capitalize;
      &:hover,
      &:active,
      &:focus {
        text-transform: capitalize;
        border-color: ${props.theme.colors.thirdary} !important;
        color: ${props.theme.colors.thirdary} !important;
      }
    }
  `};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const ContentLeft = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  overflow: ${(props) => (props.isSidebarOpen ? 'inherit' : 'hidden')};
  @media (min-width: 768px) {
    width: ${(props) => (props.isSidebarOpen ? `${LEFT_SIDEBAR_WIDTH}px` : 0)};
    margin: 0;
    margin-top: 2rem;
    flex-shrink: 0;

    margin-right: ${(props) => (props.isSidebarOpen ? '3rem' : 0)};
  }

  .ant-checkbox-group-item {
    display: block;
    margin-bottom: 1rem;
    font-weight: 400;
    text-transform: capitalize;
  }
`

export const Center = styled.div`
  text-align: center;
`

export const SidebarToggler = styled.div`
  font-size: 2rem;
  cursor: pointer;
  display: none;
  padding: .25rem;
  transition: .2s all;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
  svg {
    transition: .2s all;

    &:hover{
      color: ${(props) => props.theme.colors.thirdary};
    }
  }

`

export const Label = styled.div`
  font-weight: 600;
  text-transform: capitalize;
`
export const Hint = styled.div`
  position: fixed;
  margin-top: 6px;
  margin-left: ${LEFT_SIDEBAR_WIDTH + 30}px;
  font-weight: 600;
  text-transform: capitalize;
  z-index: 89;
  -webkit-animation:bounce 2s 5;
  
  @-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateX(0); }
    40% {
      -webkit-transform: translateX(-30px); }
    60% {
      -webkit-transform: translateX(-15px); } }
  @-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -moz-transform: translateX(0); }
    40% {
      -moz-transform: translateX(-30px); }
    60% {
      -moz-transform: translateX(-15px); } }
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); }
    40% {
      -webkit-transform: translateX(-30px);
      -moz-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      -o-transform: translateX(-30px);
      transform: translateX(-30px); }
    60% {
      -webkit-transform: translateX(-15px);
      -moz-transform: translateX(-15px);
      -ms-transform: translateX(-15px);
      -o-transform: translateX(-15px);
      transform: translateX(-15px); } }
`

export const PrintWrapper = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid ${(props) => props.theme.colors.grey.primary};
`

export const ContentRight = styled.div`
  width: 100%;
  overflow: hidden;
`

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const CalendarsWrapper = styled.div`
  display: flex;
  overflow: auto hidden;
  scrollbar-color: rgba(198, 198, 201, 0.667) transparent;
  padding: 2rem;
  scroll-behavior: smooth;
  ${props => isFirefox && 'overflow: hidden !important;'}
  &::-webkit-scrollbar {
    height: 16px;
    width: 16px;
    transition: .2s ease;
  }
  &::-webkit-scrollbar-track {
    border-top: unset;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 4px;
    height: 5px;
  }
  &:hover {
    overflow: auto auto !important;
    &::-webkit-scrollbar-thumb {
      background-color: #E2E2E6;
    }
  }

  @media print {
    display: block;
    overflow-x: auto;
    overflow: hidden;
  }
`

export const CalendarWrapper = styled.div`
  margin-right: 2rem;
  flex-shrink: 0;
  flex-basis: 500px;
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(141, 149, 166, 0.1) 0px 2px 4px;
  padding: 1.5rem;

  @media print {
    background-color: ${(props) => props.theme.colors.gray.quaternary} !important;
    height: 100vh;
    width: 100%;
    margin: 0;
    display: block;
    padding: 0;
    flex-basis: 100vw;
  }


`

export const UserList = styled.div`
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
`

export const DatePickerWrapper = styled.div`


  .react-datepicker {
    font-size: 12px;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.gray.quintenary};

    .react-datepicker__day-name,
    .react-datepicker__day {
      line-height: 20px;
    }

    &__day--weekend {
      color: #666;
    }
    &__day--selected {
      color: #fff;
    }

    .react-datepicker__current-month {
      font-size: 13px;
      margin: 0px 0 5px;
    }

    .react-datepicker__header {
      background-color: #e4f7fe !important;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 12%;
    }
  }

  .react-datepicker__input-container {
    width: inherit;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__navigation {
    display: block;
  }
  .react-datepicker {
    border: 1px solid ${(props) => props.theme.colors.gray.quintenary};
    margin-top: 0;
  }

  .react-datepicker__navigation {
    height: 1rem;
    width: 1rem !important;
    border: .75rem solid transparent;
    
  }
  .react-datepicker__navigation--previous {
    border-right-color: black;
    &:hover{
      border-right-color: ${(props) => props.theme.colors.thirdary};
    }
  }
  .react-datepicker__navigation--next {
    border-left-color: black;
    &:hover{
      border-left-color: ${(props) => props.theme.colors.thirdary};
    }
  }
`

export const Dashboard = styled.div`
  position: relative;
  z-index: 1;
`

export const HandleDeleteStepEvents = styled.div`
    display: flex;
    padding: 1rem 0;
    justify-content: flex-start;
    margin-bottom: 1rem;

`

export const SubmitWrapper = styled.div`
  text-align: right;
`

export const Dot = styled.div`
  display: inline-block;
  margin-left: .5rem;
  border-radius: 50%;
  width: .75rem;
  height: .75rem;
  background-color: ${(props) => props.color};
`
