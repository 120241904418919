import {connect} from 'react-redux'
import React from 'react'

import PropTypes from 'prop-types'
import moment from 'moment'
import {formValueSelector} from 'redux-form'
import _ from 'lodash'
import {parse} from 'query-string'
import UserForm from './UserForm'
import * as config from '../../constants/globalConfiguration'
import {changePassword, createUser, updateUser} from '../../providers/ReduxProvider/actions/userActions'
import {checkUserRights, getUserLogoUrl} from '../../utils/appHelper'

import {api} from '../../providers/ApiProvider'
import {hashHistory} from '../../providers/HistoryProvider'
import {CUSTOM_PICTURE_AVATAR_TYPE} from "../../components/EntityAvatarForm";
import {__} from '../../utils/translationUtils'
import {fetchBlockedOrdersById, fetchEntities, fetchUsers} from "../../providers/ApiProvider/entity";

const loadingBar = require('nprogress')

class UserPage extends React.Component {
  static propTypes = {
    mode: PropTypes.string,
    user: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    modelValues: PropTypes.object,
    changePassword: PropTypes.func,
    createUser: PropTypes.func,
    updateUser: PropTypes.func,
    availableTemplates: PropTypes.array,
    entityType: PropTypes.string
  };

  constructor(props) {
    super(props)
    this.entityType = config.entityTypes[this.props.entityType]
    this.state = {
      objectId: this.props.match.params.userId,
      mode: this.props.mode,
      user: null,
      entities: null,
      userEntities: null,
      blockedOrders: null,
      users: null,
      organisations: null,
      isLoading: false,
      availableTemplates: []
    }

  }

  componentDidMount() {
    this.init()
  }

  init() {
    if (this.props.mode === 'create') {
      this.getAllEntities()
      this.getAvailableTemplates()
      this.getAvailableDeputies()

      return
    }
    this.getData()
      .then(() => {
        this.getAvailableTemplates()
        this.getAllEntities()
        this.getAvailableDeputies()
      })
  }

  getData() {
    const {location} = this.props
    this.setState({isLoading: true})
    loadingBar.start()

    fetchBlockedOrdersById(this.state.objectId).then((blockedOrders) => this.setState({ blockedOrders }))

    let url = `users/${this.state.objectId}`
    if (this.props.user && this.state.objectId === this.props.user.id) url = 'me'


    return api.get(url)
      .then(
        (response) => {
          const userData = response.data
          this.setState({user: userData, isLoading: false})
          api.get(`users/${this.state.objectId}/entities`).then((response) => {
            userData.entityIds = _.map(response.data, 'id')
            this.setState({user: userData, userEntities: response.data, isLoading: false})
          })
          loadingBar.done()
        }
      )
      .catch((error) => {
        if (error.request.status === 404) {
          const queryString = parse(location.search)
          hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
        }
      })
  }

  getAllEntities() {
    this.setState({isLoading: true})
    fetchEntities({ pageSize: 200 })
      .then(
        (entities) => { this.setState({ entities })},
        (error) => { console.log(error) }
      )
  }

  getAvailableTemplates() {
    this.setState({isLoading: true})
    loadingBar.start()
    api.get('rights/templates/')
      .then(
        (response) => {
          this.setState({availableTemplates: response.data, isLoading: false})
          loadingBar.done()
        },
        (error) => {
          console.log(error)
          this.setState({isLoading: false})
          loadingBar.done()
        }
      )
  }

  getAvailableDeputies() {
    this.setState({isLoading: true})
    fetchUsers({ pageSize: 500 }).then((users) => this.setState({users}))
    fetchEntities({ pageSize: 100, type: "organisation"}).then((organisations) => this.setState({organisations}))
  }

  handleSubmit() {
    const payload = {...this.props.modelValues}

    if (payload.avatar) {
      if (payload.avatar.avatarType !== CUSTOM_PICTURE_AVATAR_TYPE) {
        payload.avatar.avatarUrl = null;
      } else {
        payload.avatar.avatarUrl = getUserLogoUrl(payload.id)
      }
    }

    if (this.props.mode === 'edit') {
      this.props.updateUser(payload, null, true)
    } else if (this.props.mode === 'create') {
      this.props.createUser(payload, null, true)
    }
  }

  render() {
    const headerClass = (this.state.mode) ? this.state.mode : ''
    let userRights = []
    if (this.props.user) {
      userRights = this.props.user.rights
    }

    const {rightMappings} = global.constants

    if(this.state.user && this.state.user.status === 'deleted' && this.state.mode !== 'view')
      hashHistory.replace(`/users/${this.state.user.id}`)

    return (
      <div id="main-content">
        <h1 className={`sticky ${headerClass}`}>
          <div className="container">
            {
              (this.state.mode === 'create')
                ? __('Create User') : ((this.state.mode === 'view') ? __('user page', 'capitalise') : __('Edit User'))
            }
          </div>
        </h1>

        <div className="container">
          <br/>
          {(this.state.entities && this.state.user && this.state.availableTemplates && this.state.user.entityIds && (typeof this.state.user.entityIds !== 'undefined') && this.state.user.entityIds) || this.state.mode === 'create'
            ? (
              <>
                <UserForm
                  location={this.props.location}
                  mode={this.state.mode}
                  user={this.state.user}
                  userId={(this.state.mode === 'create') ? null : this.props.match.params.userId}
                  blockedOrders={this.state.blockedOrders}
                  entities={this.state.entities}
                  users={this.state.users}
                  organisations={this.state.organisations}
                  availableTemplates={this.state.availableTemplates}
                  userEntities={this.state.userEntities}
                  changePassword={this.props.changePassword}
                  getData={this.getData.bind(this)}
                  isOwnUser={(this.state.user && (this.props.user.id == this.state.user.id))}
                  onSubmit={this.handleSubmit.bind(this)}
                  canDelete={checkUserRights(userRights, rightMappings.CAN_DELETE_USER)}
                  canUpdate={checkUserRights(userRights, rightMappings.CAN_UPDATE_USER)}
                  initialValues={
                    (this.state.mode === 'create') ?
                      {
                        status: 'enabled',
                        entityIds: [],
                        userRights: {},
                        templates: [],
                      } :
                      {
                        id: this.state.user.id || null,
                        ...(this.state.user.status !== 'deleted' ?
                          {
                            username: this.state.user.username,
                            firstName: this.state.user.firstName,
                            lastName: this.state.user.lastName,
                            phone: this.state.user.phone,
                            email: this.state.user.email,
                          } :
                          {
                            username: '--',
                            firstName: '--',
                            lastName: '--',
                            phone: '--',
                          }),
                        password: this.state.user.password,
                        validFrom: this.state.user.validFrom ? moment(this.state.user.validFrom).toDate() : null,
                        validTo: this.state.user.validTo ? moment(this.state.user.validTo).toDate() : null,
                        entityIds: this.state.user.entityIds,
                        userRights: {},
                        templates: (this.state.user.matchingTemplates) ? this.state.user.matchingTemplates.split(', ') : [],
                        language: this.state.user.language || 'en_GB',
                        avatar: this.state.user.avatar,
                      }
                  }
                />
              </>
            )

            : <span>{__('loading')}</span>}

        </div>
      </div>
    )
  }
}

const selector = formValueSelector('userForm')
export default connect(
  (state) => {
    const values = selector(
      state,
      'id',
      'firstName',
      'lastName',
      'username',
      'password',
      'phone',
      'email',
      'status',
      'validFrom',
      'validTo',
      'entityIds',
      'userRights',
      'language',
      'templates',
      'avatar'
    )
    return {
      user: state.user.data,
      ui: state.ui,
      modelValues: {...values}
    }
  },
  {changePassword, updateUser, createUser}
)(UserPage)
