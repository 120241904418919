import React, { useEffect, useState } from "react"
import { Form, Select, Switch, TreeSelect } from "antd"
import { incrementalSelect as IncrementalSelect, radio as Radio } from "components/dist/Form/Inputs"
import { itemOrientation } from "components/dist/Form/Inputs/Select/constants"
import SortableTable from "../UI/SortableTable"
import { getUserRegistrationPayload, signUpFieldsColumns } from "./utils"
import { getRegistrationFields, successfulSignUpPageConfig } from "./config"
import useInit from "../../hooks/useInit"
import { TreeNode } from "../Calendar/CalendarsDashboard/styles"
import { Icon } from "@ant-design/compatible"
import { fetchAllEntities, fetchUserRoles, } from "../../utils/appHelper"
import * as S from "../../pages/EntitySettingsPage/styles"
import * as C from "./constants"
import { __, T } from '../../utils/translationUtils'
import { availableBehaviours } from "./constants";
import { cases } from '../../utils/stringUtils'

const { SHOW_PARENT } = TreeSelect

export default (props) => {
  const { entitySettings, settingsForm, handleSubmit } = props

  const [fields, setFields] = useState()
  const [selfRegistrationDisabled, setSelfRegistrationDisabled] = useState(undefined)
  const [successMessageDisabled, setSuccessMessageDisabled] = useState(null)
  const [emailVerificationDisabled, setEmailVerificationDisabled] = useState(null)
  const [userRoles, setUserRoles] = useState()
  const [companiesTree, setCompaniesTree] = useState([])

  const mounted = useInit()

  useEffect(() => {
    fetchUserRoles().then((userRoles) => mounted.current && setUserRoles(mapUserRoles(userRoles)))
    fetchAllEntities().then((entities) => mounted.current && setCompaniesTree(getCompaniesTree({ entities })))
  }, [])

  useEffect(() => {
    if (!entitySettings) return
    setFields(getRegistrationFields(entitySettings))

    const { selfRegistrationEnabled, onSuccessfulSignUp, emailVerification } = entitySettings.userRegistration
    setSelfRegistrationDisabled(!selfRegistrationEnabled)
    setSuccessMessageDisabled(onSuccessfulSignUp !== C.availableBehaviours.REDIRECT_TO_SUCCESSFUL_SIGN_UP_PAGE)
    setEmailVerificationDisabled(!emailVerification)
  }, [entitySettings])

  const mapUserRoles = (roles) => roles.map((role) => ({
    ...role,
    value: role['shortName'],
    label: role['name']
  }))

  function getCompaniesTree({ entities, showCount }) {
    return entities?.map((company) => ({
      title: (
        <TreeNode>
          <Icon type="cluster" /> {company.name} - {company.shortName}
          {showCount ? <small> ({company?.children?.length})</small> : ''}
        </TreeNode>
      ),
      searchValue: `${company.name} (${company.shortName})`,
      key: company.id,
    }))
  }


  function onFinish() {
    handleSubmit()
  }

  function handleSettingsFormChange(value, values) {
    settingsForm.setFieldsValue({
      [C.settingsFields.SIGN_UP_FIELDS]: values.signUpFields?.map((field) => {
        if (field?.name === "password") {
          return {
            ...field,
            enabled: values[C.settingsFields.EMAIL_VERIFICATION] ? {
              checked: false,
              editable: false
            } : { checked: true, editable: false },
            required: values[C.settingsFields.EMAIL_VERIFICATION] ? {
              checked: false,
              editable: false
            } : { checked: true, editable: false },
          }
        }

        return field
      })
    })
  }

  const onEmailVerificationSwitchChange = (value) => {
    if (value && settingsForm.getFieldValue(C.settingsFields.ON_SUCCESSFUL_SIGNUP) === availableBehaviours.AUTOMATIC_LOGIN)
      settingsForm.setFieldsValue({ [C.settingsFields.ON_SUCCESSFUL_SIGNUP]: availableBehaviours.REDIRECT_TO_LOGIN_PAGE })
    setEmailVerificationDisabled(!value)
  }

  const onSelfRegistrationSwitchChange = (value) => {
    settingsForm.validateFields([C.settingsFields.USER_GROUPS, C.settingsFields.USER_ROLES])
    setSelfRegistrationDisabled(!value);
  }

  const handleChangeSignUpFields = (field, fields, callback) => {
    const newFields = [...fields]

    const { value, row, key } = field

    newFields[row] = {
      ...newFields[row],
      [key]: {
        ...newFields[row][key],
        checked: value
      },
      ...(key === "enabled" && !value ? {
        required: {
          ...newFields[row]["required"],
          checked: false
        }
      } : {}),
      ...(key === "required" && value ? {
        enabled: {
          ...newFields[row]["enabled"],
          checked: true
        }
      } : {})
    }

    callback(newFields)
  }

  const renderRows = (props) => {
    const row = props["data-row-key"]

    return (
      <tr key={row}>
        {
          props.children.map((child) => {
            const { props: childProps } = child

            const renderProps = {
              value: childProps.record?.[childProps.dataIndex],
              onChange: (value) => handleChangeSignUpFields({
                value,
                row,
                key: childProps.dataIndex
              }, props.fields, props?.onChange)
            }
            return <td key={childProps.dataIndex}>{childProps.render?.(renderProps) || null}</td>
          })
        }
      </tr>
    )
  }

  const required = {
    required: true,
    message: __(T.requiredField),
  }

  return (
    <Form
      layout="vertical"
      form={settingsForm}
      fields={fields}
      name="entity-settings"
      onValuesChange={handleSettingsFormChange}
      onFinish={onFinish}
    >
      <Form.Item
        label={__(T.selfRegistration)}
        name={C.settingsFields.SELF_REGISTRATION}
        valuePropName="checked"
      >
        <Switch
          onChange={onSelfRegistrationSwitchChange}
        />
      </Form.Item>
      <Form.Item
        label={__(T.targetUserGroups)}
        name={C.settingsFields.USER_GROUPS}
        rules={[...(!selfRegistrationDisabled ? [required] : [])]}
      >
        <TreeSelect
          dropdownStyle={{ maxHeight: 500, overflowY: 'scroll' }}
          treeData={companiesTree}
          fieldNames={{ label: 'title', value: 'key', children: 'children' }}
          treeCheckable
          multiple
          treeNodeFilterProp="searchValue"
          showCheckedStrategy={SHOW_PARENT}
          placeholder={__(T.selectTargetGroups)}
          maxTagCount={3}
          disabled={selfRegistrationDisabled}
        />
      </Form.Item>
      <Form.Item
        label={__(T.targetUserRoles)}
        name={C.settingsFields.USER_ROLES}
        rules={[...(!selfRegistrationDisabled ? [required] : [])]}
      >
        <Select
          mode="multiple"
          options={userRoles}
          allowClear
          style={{ width: '100%' }}
          placeholder={__(T.selectTargetGroupRoles)}
          disabled={selfRegistrationDisabled}
        />
      </Form.Item>
      <Form.Item
        label={__(T.allowedDomains)}
        name={C.settingsFields.ALLOWED_EMAIL_DOMAINS}
      >
        <IncrementalSelect
          name='domainList'
          label='Domains'
          allowClear={true}
          showSearch={true}
          customItemProps={{
            type: 'input',
            name: 'domain',
            label: 'domain',
            placeholder: __('e.g. gmail.com')
          }}
          customItem={(customValue) => ({
            value: customValue,
            label: customValue
          })}
          customItemOrientation={itemOrientation.HORIZONTAL}
          customItemError={(customValue) => !!customValue && customValue?.match(/^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/)}
          CustomItemRender={(props) => <div>{props.label}</div>}
          notFoundContent={(<i>{__(T.noEntryAddedYet, cases.CAPITALIZE_SENTENCE)}</i>)}
          disabled={selfRegistrationDisabled}
        />
      </Form.Item>
      <Form.Item
        label={__(T.emailVerification)}
        name={C.settingsFields.EMAIL_VERIFICATION}
        valuePropName="checked"
      >
        <Switch
          onChange={(value) => onEmailVerificationSwitchChange(value)}
          disabled={selfRegistrationDisabled}
        />
      </Form.Item>
      <Form.Item
        label={__(T.registrationBehaviour)}
        name={C.settingsFields.ON_SUCCESSFUL_SIGNUP}
      >
        <Radio
          onChange={(value) => setSuccessMessageDisabled(value !== C.availableBehaviours.REDIRECT_TO_SUCCESSFUL_SIGN_UP_PAGE)}
          items={
            C.registrationBehaviour
              .map(({ value, label, message }) => ({
                value,
                label: __(label),
                disabled: value === availableBehaviours.AUTOMATIC_LOGIN && !emailVerificationDisabled
              }))}
          defaultValue={C.registrationBehaviour[0].value}
          disabled={selfRegistrationDisabled}
          isButton
        />
      </Form.Item>
      <Form.Item
        label={__(T.successMessage)}
        name={C.settingsFields.SUCCESSFUL_SIGNUP_CONFIGURATION}
      >
        <Radio
          layout='vertical'
          items={Object.values(successfulSignUpPageConfig)
            .map(({ type, message }) => ({ value: type, label: __(message) }))}
          defaultValue={C.availableMessageTypes.GREETINGS}
          disabled={selfRegistrationDisabled || successMessageDisabled}
        />
      </Form.Item>
      <S.FormItem
        label={__(T.registrationFormFields, cases.CAPITALIZE_SENTENCE)}
        name={C.settingsFields.SIGN_UP_FIELDS}
        hide={selfRegistrationDisabled}
      >
        <SortableTable
          columns={signUpFieldsColumns}
          renderRows={renderRows}
          disabled={selfRegistrationDisabled}
        />
      </S.FormItem>
      <button
        className='btn btn-primary'
        size="large"
        htmlType="submit"
        style={{ float: "right", marginRight: "-6px" }}
      >
        {__(T.save)}
      </button>
    </Form>
  )
}
