import {connect} from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import objectAssign from 'object-assign'
import {formValueSelector} from 'redux-form'
import {parse} from 'query-string'
import EntityForm from '../components/EntityForm'
import {checkUserRights, getCompanyLogoUrl} from '../utils/appHelper'

import * as config from '../constants/globalConfiguration'
import {createEntity, deleteEntity, reopenEntity, updateEntity} from '../providers/ReduxProvider/actions/entityActions'
import {api} from '../providers/ApiProvider'
import {hashHistory} from '../providers/HistoryProvider'
import {CUSTOM_PICTURE_AVATAR_TYPE} from "../components/EntityAvatarForm";
import { __ } from '../utils/translationUtils'

const loadingBar = require('nprogress')

class EntityPage extends React.Component {
  static propTypes = {
    mode: PropTypes.string,
    user: PropTypes.object,
    modelValues: PropTypes.object,
    updateEntity: PropTypes.func,
    createEntity: PropTypes.func,
    deleteEntity: PropTypes.func,
    reopenEntity: PropTypes.func,
    match: PropTypes.object,
    entityType: PropTypes.string
  };

  constructor(props) {
    super(props)
    this.entityType = config.entityTypes[this.props.entityType]
    this.isUserGroup = this.props.entityType === 'user_group'
    this.state = {
      objectId: this.props.match.params.entityId,
      mode: this.props.mode,
      entity: null,
      entities: null,
      users: null,
      nestedUsers: null,
      isLoading: false
    }
    this.getUsers = this.getUsers.bind(this)
    this.getNestedUsers = this.getNestedUsers.bind(this)
  }

  componentDidMount() {
    if (this.props.mode === 'create') {
      this.getAllEntities()
      this.getUsers()
      return
    }
    this.getData().then(() => {
      this.getAllEntities()
      this.getUsers()
      this.getNestedUsers()
    })
  }

  getData() {
    const {location} = this.props
    this.setState({isLoading: true})
    loadingBar.start()
    return api.get(`/entities/${this.state.objectId}`)
      .then(
        (response) => {
          this.setState({entity: response.data, isLoading: false})
          loadingBar.done()
        },
        (error) => {
          console.log(error)
          this.setState({isLoading: false})
          loadingBar.done()
        }
      ).catch((error) => {
        if (error.request.status === 404) {
          const queryString = parse(location.search)
          hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
        }
      })
  }

  getAllEntities() {
    return api.get('/entities?pageSize=200&status=enabled,reserved')
      .then(
        (response) => {
          // remove current entity from list
          const entitiesList = _.filter(response.data, (e) => e.id !== this.state.objectId)
          this.setState({entities: entitiesList})
        },
        (error) => {
          console.log(error)
        }
      )
  }

  getUsers() {
    if (!this.state.objectId) return
    return api.get(`/entities/${this.state.objectId}/users`)
      .then(
        (response) => {
          this.setState({users: response.data})
        },
        (error) => {
          console.log(error)
        }
      )
  }

  getNestedUsers() {
    return api.get(`/entities/${this.state.objectId}/hierarchy`)
      .then(
        (response) => {
          this.setState({nestedUsers: response.data.users})
        },
        (error) => {
          console.log(error)
        }
      )
  }

  checkIfCanSee(entityId) {
    const index = _.findIndex(this.state.entities, (entity) => entity.id == entityId)

    // If the mode is view I have to return the name not the id, because I don't need to select an option
    // I just need to show the name in the UI.
    // I did this because the editForm container is triggered only in this point of the code.
    if (index > -1 && this.state.mode !== 'view') {
      return entityId
    }
    if (index > -1 && this.state.mode === 'view') {
      const entity = this.state.entities[index]
      return entity.name
    }
    return null
  }

  handleSubmit() {

    const payload = {...this.props.modelValues}

    if (payload.avatar) {
      if (payload.avatar.avatarType !== CUSTOM_PICTURE_AVATAR_TYPE) {
        payload.avatar.avatarUrl = null
      } else {
        payload.avatar.avatarUrl = getCompanyLogoUrl(payload.id)
      }
    }

    if (this.props.mode === 'edit') this.props.updateEntity(payload)
    else if (this.props.mode === 'create') this.props.createEntity(payload)
  }

  render() {
    const headerClass = (this.state.mode) ? this.state.mode : ''

    let userRights = []
    if (this.props.user) {
      userRights = this.props.user.rights
    }

    function getStatusState() {
      return this.state.entity.status
    }

    const {rightMappings} = global.constants

    return (
      <div id="main-content">

        <h1 className={`sticky ${headerClass}`}>
          <div className="container">
            {__(`${this.state.mode} ${this.entityType.name}`)}
          </div>
        </h1>

        <div className="container">
          <br/>
          {(this.state.entity || this.state.mode === 'create') && this.state.entities
          && (
            <EntityForm
              mode={this.state.mode}
              isUserGroup={this.isUserGroup}
              initialValues={(this.state.mode === 'create')
                ? {type: this.props.entityType, status: true}
                : objectAssign({}, {
                  id: this.state.entity.id || null,
                  name: this.state.entity.name,
                  shortName: this.state.entity.shortName,
                  parentId: (typeof this.state.entity.parent !== 'undefined') ? this.checkIfCanSee(this.state.entity.parent.id) : null,
                  streetName: this.state.entity.streetName,
                  streetNumber: this.state.entity.streetNumber,
                  suffix: this.state.entity.suffix,
                  zip: this.state.entity.zip,
                  type: this.state.entity.type,
                  poBox: this.state.entity.poBox,
                  city: this.state.entity.city,
                  contactName: this.state.entity.contactName,
                  contactEmail: this.state.entity.contactEmail,
                  contactPhone: this.state.entity.contactPhone,
                  avatar: this.state.entity.avatar,
                  status: !!(getStatusState.bind(this)() === 'enabled' || getStatusState.bind(this)() === true)
                })}
              entity={this.state.entity}
              entityType={this.props.entityType}
              onSubmit={this.handleSubmit.bind(this)}
              reopenEntity={this.props.reopenEntity.bind(this)}
              deleteEntity={this.props.deleteEntity.bind(this)}
              getData={this.getData.bind(this)}
              canManageEntitySettings={checkUserRights(userRights, rightMappings.CAN_MANAGE_ENTITY_SETTINGS)}
              canDelete={checkUserRights(userRights, rightMappings.CAN_DELETE_USER)}
              canUpdate={checkUserRights(userRights, rightMappings.CAN_UPDATE_USER)}
              entities={this.state.entities}
              users={this.state.users}
              nestedUsers={this.state.nestedUsers}
            />
          )}
        </div>

      </div>
    )
  }
}

const selector = formValueSelector('entityForm')
export default connect(
  (state) => {
    const values = selector(
      state,
      'id',
      'name',
      'type',
      'parent',
      'status',
      'shortName',
      'parentId',
      'streetName',
      'streetNumber',
      'suffix',
      'zip',
      'poBox',
      'city',
      'contactName',
      'contactEmail',
      'contactPhone',
      'avatar'
    )

    return {
      user: state.user.data,
      modelValues: {...values, status: selector(state, 'status') ? 'enabled' : 'disabled'}
    }
  },
  {updateEntity, createEntity, reopenEntity, deleteEntity}
)(EntityPage)
