import React, {useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from 'prop-types'
import {change, Field} from "redux-form";
import {useDispatch} from "react-redux";
import {renderField} from "../../../utils/fieldValidator";
import AddMainKeyModal from "./AddMainKeyModal";
import StepObjectNode from "../../StepObjectNode";
import {api} from "../../../providers/ApiProvider";
import moment from "moment";
import * as config from "../../../constants/globalConfiguration";
import EditStepObjectButton from "../../EditStepObjectButton";
import {Tooltip} from "antd";
import {Icon} from "@ant-design/compatible";
import {OBJECT_BINDER_CONTROLLER_TYPE, OBJECT_PICKER_CONTROLLER_TYPE} from "./utils";
import { __ } from '../../../utils/translationUtils'


function MainKeysControl(props) {
  
  const {controlData: field, formName, objectConfiguration, controllerType, apis} = props

  const [mainKeysSelected, setMainKeysSelected] = useState([])
  const [attributeTree, setAttributeTree] = useState(null)
  const [attributeList, setAttributeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [fetchInfoAtStart, setFetchInfoAtStart] = useState(true)
  const dispatch = useDispatch()

  const fetchAttributesTree = useCallback(async () => {
    setIsLoading(true)
    const attributeTreeResponse = await api.get('configurations/attributes/tree').catch(() => setIsLoading(false))
    if (attributeTreeResponse) setAttributeTree(attributeTreeResponse.data)
    setIsLoading(false)
  }, [])

  const getAttributeList = useCallback(async () => {
    setIsLoading(true)
    const attributeList = await api.get('configurations/attributes')
    if (Array.isArray(attributeList?.data)) setAttributeList(attributeList?.data)
    setIsLoading(false)
  }, [])

  const fetchMainKeysSelectedData = useCallback(async (mainKeysIds, optionalProps) => {
    if (objectConfiguration == null) return []
    setIsLoading(true)

    const defaultFetchRemoteItems = (ids) => {
      const promises = []
      ids.forEach((mainKeyId) => {
        promises.push(
          new Promise(resolve => api.get(`configurations/remoteObjects/${objectConfiguration.objectTypeId}/${mainKeyId.trim()}`)
            .then(res => {
              resolve(res.data);
            })
          ));
      });
      return Promise.all(promises)
    }
    const fetchRemoteItems = apis?.fetchRemoteItems || defaultFetchRemoteItems
    
    fetchRemoteItems(mainKeysIds.filter(id => id && id!=='0'), optionalProps).then((mainKeys) => {
        const oldMainKeysToKeep = (mainKeysSelected || []).filter(mk => !mainKeysIds.includes(mk.id))
        setMainKeysSelected([...oldMainKeysToKeep, ...(mainKeys || []).map(mk => mk[0])]);
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false));

  }, [mainKeysSelected, objectConfiguration])

  useEffect(() => {

    if (!fetchInfoAtStart) return;

    if (typeof field?.source === "string" && !(field?.source === '')) {
      const mainKeysIds = field?.source.split(',')
      fetchMainKeysSelectedData(mainKeysIds, { objectTypeId: objectConfiguration.objectTypeId })
    }
    fetchAttributesTree()
    getAttributeList()
    setFetchInfoAtStart(false)

  }, [fetchAttributesTree, fetchInfoAtStart, fetchMainKeysSelectedData, field, getAttributeList, objectConfiguration])


  const stepValidation = useMemo(() => {

    const valueRequired = v => v != null && v !== '' ? null : __('This is a mandatory field');
    const valueRegexMatch = v => {
      if (typeof field?.regex?.js !== 'string') return null
      const pattern = new RegExp(field?.regex?.js)
      return v != null && v !== '' && pattern.test(v) ? null : __('valueFormatError');
    }

    let validation = [];
    if (field == null) return undefined;
    if (field.required) validation.push(valueRequired)
    if (typeof field?.regex?.js === 'string') validation.push(valueRegexMatch)

    // Remove null
    validation = validation.filter(e => e);
    return validation.length ? validation : undefined

  }, [field])

  const updateReduxForm = (mainKeysToSave) => {
    if (typeof formName !== 'string' || field?.id == null) {
      return
    }
    dispatch(change(formName, field.id, mainKeysToSave.map(mk => mk.id).join(',')))

  }

  const handleAddMainKey = (mainKeysToAdd) => {
    const mainKeysToAddIds = (mainKeysToAdd || []).map(mk => mk.id)
    const newMainKeysSelected = [...mainKeysSelected.filter(mk => !mainKeysToAddIds.includes(mk.id)), ...mainKeysToAdd]
    setMainKeysSelected(newMainKeysSelected)
    updateReduxForm(newMainKeysSelected)
  }

  const handleRemoveKey = mainKeyToRemove => {
    if (mainKeyToRemove == null) {
      return
    }

    const newMainKeysSelected = mainKeysSelected.filter(mk => !(mk.id === mainKeyToRemove.id))
    setMainKeysSelected([...newMainKeysSelected])
    updateReduxForm(newMainKeysSelected)
  }

  const attributesUsedInDisplay = useMemo(() =>
      ((objectConfiguration && objectConfiguration?.displayAttributes) || []).map(dAttribute =>
        (attributeList || []).find(a => a.id === dAttribute.attributeId)
      ).filter(a => a),
    [attributeList, objectConfiguration])

  const summaryDisplayAttributes = useMemo(() =>
      ((objectConfiguration && objectConfiguration?.displayAttributes) || []).filter(a => a.summary),
    [objectConfiguration])

  const objectTypeConfiguration = useMemo(() => {
    if (attributeTree == null || objectConfiguration == null) return null
    const systemConfiguration = attributeTree[objectConfiguration.systemId]
    if (systemConfiguration == null) {
      console.error('Cannot find configuration for system id: ' + objectConfiguration.systemId);
      return null
    }
    const objectTypeConfiguration = systemConfiguration.find(oTC => oTC.objectTypeId === objectConfiguration.objectTypeId);
    if (objectTypeConfiguration == null) {
      console.error('Cannot find configuration for system id: ' + objectConfiguration.systemId + ". Object Type id: " + objectTypeConfiguration?.objectTypeId);
      return null;
    }
    return objectTypeConfiguration;

  }, [attributeTree, objectConfiguration])

  const renderMainKey = (label, type, _, attributeId, mainKey) => {

    let value = (mainKey.displayData || {})[attributeId]

    if (value || value === false) {
      if (type === "YESNO" && (value === true || value.toLowerCase() === "yes" || value.toLowerCase() === "true")) {
        value = __('val_yes');
      }
      if (type === "YESNO" && (value === false || value.toLowerCase() === "no" || value.toLowerCase() === "false")) {
        value = __('val_no');
      }
      // Check if date
      if (type === "DATE") {
        value = moment(value).format(config.appDefaults.dateTimeFormat);
      }
      // Check if ListBox
      if (type === "PopupListBox") {
        value = __(value);
      }
    }
    let input = {}
    input.value = value
    input.name = attributeId
    input.container = 'ObjectListing';
    return (
      <Field
        input={input}
        component={renderField}
        label={label}
        className="form-control"
        type={type}
        name={`StepObj-${mainKey}-${attributeId}`}
        key={`StepObj-${mainKey}-${attributeId}`}
        disabled={true}
      />
    );
  }

  const getValues = useCallback((mainKey) => {

    if (mainKey.displayData == null) {
      console.error('Found a mainKey without display Data: ' + mainKey.id)
      return []
    }

    return summaryDisplayAttributes.map((da) => mainKey.displayData[da.attributeId]).filter(a => a);

  }, [summaryDisplayAttributes])

  const getFullValues = useCallback((mainKey) => {


    if (mainKey.displayData == null) {
      console.error('Found a mainKey without display Data: ' + mainKey.id)
      return []
    }

    return (attributesUsedInDisplay || []).map((da) => mainKey.displayData[da.attributeId]).filter(a => a);
  }, [attributesUsedInDisplay])



  if (objectConfiguration == null || field == null || attributeTree == null || objectTypeConfiguration == null) return null;
  
  return (
    <div className='form-group label-control object-listing'>
      <div className="col-sm-12 object-listing__list top-bar">
        <div className="col-xs-12">

          {/* Hidden field of main keys */}
          <Field
            name={field.id || Math.floor(Math.random() * 1000).toString()}
            component={renderField}
            label={field.title ? __(field.title) + (field.required ? '*' : '') : ''}
            className="form-control"
            labelCol={' '}
            inputCol={' '}
            type="hidden"
            items={mainKeysSelected}
            validate={stepValidation}
          />

          {/* Add Main Key */}
          {objectConfiguration.addableEntries && !field.readOnly && (
            controllerType === OBJECT_PICKER_CONTROLLER_TYPE ||
            (controllerType === OBJECT_BINDER_CONTROLLER_TYPE && Array.isArray(mainKeysSelected) && mainKeysSelected.length < 1)
          ) &&
          <div className="col-xs-8 pull-right text-right">
            <AddMainKeyModal
              objectConfiguration={objectConfiguration}
              field={field}
              onAddMainKey={handleAddMainKey}
              attributeList={attributeList}
              mainKeysSelected={mainKeysSelected}
              controllerType={controllerType}
              apis={apis}
            />
          </div>
          }

          {/* mainKeysSelected is Empty */}
          <div className="col-sm-12 object-listing__list">
            <div>
              {(mainKeysSelected.length === 0 || isLoading) &&
              <div className="discreet">
                <i>{__('objectListEmpty')}</i>
              </div>}
            </div>
          </div>

          {/* mainKeysSelected is not empty */}
          <div className="col-sm-12 object-listing__list">
            <div key={objectConfiguration.objectTypeId}>
              {
                mainKeysSelected.filter(m => m).map(mainKey => (
                  <StepObjectNode
                    key={`object-${mainKey.id}`}
                    mainKey={mainKey}
                    node={objectTypeConfiguration}
                    renderStepObject={renderMainKey}
                    attributes={attributesUsedInDisplay}
                    displayAttributes={summaryDisplayAttributes}
                    maxColumns={2}
                    values={getValues(mainKey)}
                    buttons={
                      <span style={{display: 'flex', float: 'right'}}>
                        {!field.readOnly && objectConfiguration.editableEntries &&
                          <EditStepObjectButton
                            key={`edit-button-${mainKey.id}`}
                            onUpdate={() => fetchMainKeysSelectedData([mainKey.id], { objectTypeId: objectConfiguration.objectTypeId })}
                            configuration={objectConfiguration}
                            node={objectTypeConfiguration}
                            overviewObjectId={mainKey.id}
                            resultId={mainKey.id}
                            initialValues={mainKey.displayData}
                            system={objectConfiguration.systemId}
                            dependencyId={objectConfiguration.objectTypeId}
                            attributes={attributesUsedInDisplay}
                            values={getFullValues(mainKey)}
                            apis={apis}
                          />
                        }
                        {' '}
                        {!field.readOnly && objectConfiguration.removableEntries &&
                        <Tooltip title={__('remove')}>
                          <div className="btn-no-padding btn-std" onClick={(e) => {
                            e.preventDefault();
                            handleRemoveKey(mainKey);
                          }}><Icon className="icon-delete" type="close-circle" theme="outlined"/></div>
                        </Tooltip>

                        }
                  </span>}
                  />


                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MainKeysControl.propTypes = {
  controlData: PropTypes.object.isRequired,
  formName: PropTypes.string,
  controllerType: PropTypes.oneOf([OBJECT_PICKER_CONTROLLER_TYPE, OBJECT_BINDER_CONTROLLER_TYPE]).isRequired,
  objectConfiguration: PropTypes.shape({
    searchAttributes: PropTypes.array,
    sortAttributes: PropTypes.array,
    displayAttributes: PropTypes.array,
    objectTypeId: PropTypes.number,
    systemId: PropTypes.number,
    addableEntries: PropTypes.bool
  }).isRequired
}

export default MainKeysControl

