import React from 'react'
import PropTypes from 'prop-types'
import objectAssign from 'object-assign'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import _ from 'lodash'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import {Draggable, Droppable} from 'react-drag-and-drop'
import {Button, Modal, OverlayTrigger, Panel, PanelGroup, Tooltip} from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import {Field, getFormValues, reduxForm} from 'redux-form'
import {Button as AntButton, TreeSelect} from 'antd'
import {ClusterOutlined, RedoOutlined, TeamOutlined} from '@ant-design/icons'
import styled from 'styled-components'
import {hashHistory} from '../providers/HistoryProvider'
import {checkUserRights, showMessage} from '../utils/appHelper'
import {isRequired, renderField} from '../utils/fieldValidator'
import OrderDetailsAttributeCard from './OrderDetailsAttributeCard'
import * as config from '../constants/globalConfiguration'
import {deadlineThresholdColor} from '../pages/OrderTypePage'
import {api} from '../providers/ApiProvider'
import * as S from './Calendar/CalendarsDashboard/styles'
import Label from './Label'
import {__} from '../utils/translationUtils'
import CollapsiblePanelDeprecated from "./UI/CollapsiblePanelDeprecated";

const moment = require('moment')

const {SHOW_PARENT, SHOW_CHILD} = TreeSelect

const verticalCenterStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
  float: 'none',
  marginTop: '35px'
}

const validate = (values) => {
  const validateDateFormat = ({dateFormat, code, size, offset}) => {
    if (
      (offset && !(offset === '')
        || code && !(code === '')
        || size && !(size === '')
      ) && (!dateFormat || dateFormat === '')
    ) {
      return __('Date Format is a mandatory field')
    }
  }

  const validateCode = ({dateFormat, code, size, offset}) => {
    if (
      (offset && (!(offset === '')
        || (dateFormat && !(dateFormat === ''))
        || (size && !(size === ''))
      ) && (!code || code === ''))
    ) {
      return __('Short Name is a mandatory field')
    }

    if (code && code.length > 4) {
      return __('Short Name length should be no greater than 4 chars long')
    }
  }

  const validateSize = ({dateFormat, code, size, offset}) => {
    if (
      (offset && (!(offset === '')
        || (code && !(code === ''))
        || (dateFormat && !(dateFormat === ''))
      ) && (!size || size === ''))
    ) {
      return __('Counter Size is a mandatory field')
    }

    if (size && size > 6) {
      return __('Counter Size should be no greater than 6')
    }
  }

  const validateOffset = ({dateFormat, code, size, offset}) => {
    if (
      (dateFormat && (!(dateFormat === '')
        || (code && !(code === ''))
        || (size && !(size === ''))
      ) && (offset == null || offset === ''))
    ) {
      return __('Counter Starting Offset is a mandatory field')
    }

    if (offset && size && (offset.length > size)) {
      return __(`Counter Starting Offset length should be no greater than ${size} chars long`)
    }

    if (offset && (offset.length > 6)) {
      return __(`Counter Starting Offset length should be no greater than ${6} chars long`)
    }
  }

  const errors = {}
  errors.name = isRequired(values.name)
  errors.flowId = isRequired(values.flowId)
  errors.flowVersion = isRequired(values.flowVersion)
  errors.entities = isRequired(values.entities)
  errors.code = validateCode(values)
  errors.size = validateSize(values)
  errors.offset = validateOffset(values)
  errors.dateFormat = validateDateFormat(values)
  return errors
}

const SortableItem = SortableElement(({value}) => <div style={{zIndex: 99999999}}>{value}</div>)

const SortableList = SortableContainer(({items}) => (
  <div className="configuration-list">
    {items.map((value, index) => (
      <SortableItem key={`item-${index}`} index={index} value={value}/>
    ))}
  </div>
))

export const renderTreeSelect = (props) => {
  const {input, treeData, label, required, labelCol, description, inputCol, meta: {touched, error}} = props
  const req = (required) ? '*' : ''
  const labelClass = labelCol || 'col-sm-5'
  const inputClass = inputCol || 'col-sm-7'
  return (
    <div className={'form-group '.concat(touched && error ? 'has-error ' : '').concat('clearfix')}>
      {typeof label !== 'undefined' && label !== ''
        && <Label htmlFor="inputName" className={`${labelClass} control-label`} label={label + req}
                  description={description && __(description)}/>}
      <S.TreeSelectWrapper className={`${inputClass} text-danger-wrapper`} style={{position: 'relative'}}>
        <TreeSelect
          {...input}
          value={(input.value) ? input.value : []}
          onChange={(value) => {
            input.onChange(value)
          }}
          onBlur={() => input.onBlur(input.value)}
          dropdownStyle={{maxHeight: 500, overflowY: 'scroll'}}
          treeData={treeData}
          fieldNames={{label: 'title', value: 'key', children: 'children'}}
          treeCheckable
          multiple
          treeNodeFilterProp="searchValue"
          showCheckedStrategy={SHOW_PARENT}
          placeholder={__('Select related entities / user groups')}
          maxTagCount={3}
        />
        {touched && <span className="text-danger">{error}</span>}
      </S.TreeSelectWrapper>
    </div>
  )
}

class OrderType extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderDetailsModal: {
        data: [],
        open: false,
        loading: false
      },
      myOrdersModal: {
        data: [],
        open: false,
        loading: false
      },
      deadlineThresholdsModal: {open: false}
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.showOrderDetailsModal = this.showOrderDetailsModal.bind(this)
    this.showMyOrdersModal = this.showMyOrdersModal.bind(this)
    this.detailsAttributesList = this.detailsAttributesList.bind(this)
    this.myOrdersAttributesList = this.myOrdersAttributesList.bind(this)
    this.addAttribute = this.addAttribute.bind(this)
    this.addMyOrdersAttribute = this.addMyOrdersAttribute.bind(this)
    this.removeAttribute = this.removeAttribute.bind(this)
    this.removeMyOrdersAttribute = this.removeMyOrdersAttribute.bind(this)
    this.closeDetailsModal = this.closeDetailsModal.bind(this)
    this.closeMyOrdersModal = this.closeMyOrdersModal.bind(this)
    this.onAttributesSortEnd = this.onAttributesSortEnd.bind(this)
    this.setOrderDetails = this.setOrderDetails.bind(this)
    this.setMyOrders = this.setMyOrders.bind(this)
    this.clearAttributesSorting = this.clearAttributesSorting.bind(this)
    this.clearMyOrdersAttributesSorting = this.clearMyOrdersAttributesSorting.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
    this.openDeadlineThresholdsModal = this.openDeadlineThresholdsModal.bind(this)
    this.closeDeadlineThresholdsModal = this.closeDeadlineThresholdsModal.bind(this)
    this.setControllerTypeToMyOrdersAttribute = this.setControllerTypeToMyOrdersAttribute.bind(this)
  }

  handleColorChange(color) {
    this.props.change('color', color)
  }

  openDeadlineThresholdsModal() {
    this.setState({deadlineThresholdsModal: {...this.state.deadlineThresholdsModal, open: true}})
  }

  closeDeadlineThresholdsModal() {
    this.setState({deadlineThresholdsModal: {...this.state.deadlineThresholdsModal, open: false}})
  }

  onAttributesSortEnd(ordersModal) {
    const attributes = [].concat(this.state[ordersModal].data)
    return ({oldIndex, newIndex}) => {
      const item1 = _.find(attributes, (attr) => attr.position === oldIndex)
      if (newIndex > oldIndex) {
        // Update the position of all the previews items
        const prevItems = _.filter(attributes, (attr) => attr.position >= oldIndex && attr.position <= newIndex)
        prevItems.forEach((item) => {
          item.position -= 1
        })
      } else {
        // Update the position of all the previews items
        const prevItems = _.filter(attributes, (attr) => attr.position >= newIndex && attr.position <= oldIndex)
        prevItems.forEach((item) => {
          item.position += 1
        })
      }
      // const item2 = _.find(attributes, (attr) => attr.position === newIndex);
      item1.position = newIndex
      this.setState({
        orderDetailsModal: {
          ...this.state.orderDetailsModal,
          data: attributes
        }
      })
    }
  }

  getOrderDetails() {
    const {orderType} = this.props
    this.setState({
      orderDetailsModal: {
        ...this.state.orderDetailsModal,
        loading: true
      }
    }, () => {
      api.get(`orderTypes/${orderType.id}/orderDetails`)
        .then(
          (response) => {
            this.setState({
              orderDetailsModal: {
                ...this.state.orderDetailsModal,
                data: response.data,
                loading: false
              }
            })
          },
          (error) => {
            console.log(error)
          }
        )
    })
  }

  getMyOrders() {
    const {orderType} = this.props

    this.setState({
      myOrdersModal: {
        ...this.state.myOrdersModal,
        loading: true
      }
    }, () => {
      api.get(`orderTypes/${orderType.id}/defaultAttributes`)
        .then(
          (response) => {
            this.setState({
              myOrdersModal: {
                ...this.state.myOrdersModal,
                data: response.data,
                loading: false
              }
            })
          },
          (error) => {
            console.log(error)
          }
        )
    })
  }

  setOrderDetails() {
    const {orderType} = this.props

    this.setState({
      orderDetailsModal: {
        ...this.state.orderDetailsModal,
        loading: true
      }
    }, () => {
      const payload = this.state.orderDetailsModal.data

      api.put(`orderTypes/${orderType.id}/orderDetails`, payload)
        .then(
          (response) => {
            this.setState({
              orderDetailsModal: {
                ...this.state.orderDetailsModal,
                data: response.data,
                loading: false
              }
            }, () => {
              this.closeDetailsModal()
              showMessage('success', __('Order Details Attributes updated successfully'))
            })
          },
          (error) => {
            console.log(error)
            showMessage('error', error)
          }
        )
    })
  }

  setMyOrders() {
    const {orderType} = this.props
    this.setState({
      myOrdersModal: {
        ...this.state.myOrdersModal,
        loading: true
      }
    }, () => {
      const payload = this.state.myOrdersModal.data
      api.put(`orderTypes/${orderType.id}/defaultAttributes`, payload)
        .then(
          (response) => {
            this.setState({
              myOrdersModal: {
                ...this.state.myOrdersModal,
                data: response.data,
                loading: false
              }
            }, () => {
              this.closeMyOrdersModal()
              showMessage('success', __('Order Details Attributes updated successfully'))
            })
          },
          (error) => {
            console.log(error)
            showMessage('error', error)
          }
        )
    })
  }

  addAttribute(attribute) {
    const _attr = JSON.parse(attribute.attribute)
    const attributes = [].concat(this.state.orderDetailsModal.data)
    attributes.push({
      attributeId: _attr.id,
      position: attributes.length,
      orderTypeId: this.props.orderType.id
    })
    this.setState({orderDetailsModal: objectAssign(this.state.orderDetailsModal, {data: attributes})}, () => {
      this.clearAttributesSorting()
    })
  }

  setControllerTypeToAttribute = (id, controllerType) => {
    const attrIndex = this.state.orderDetailsModal?.data.findIndex(el => el.attributeId === id);
    let attributes = this.state.orderDetailsModal?.data;
    attributes[attrIndex] = {...attributes[attrIndex], controllerType: `${controllerType}`}
    this.setState({
      orderDetailsModal: {
        ...this.state.orderDetailsModal,
        data: [...attributes]
      }
    })
  };

  removeAttribute(attribute) {
    const attributes = [].concat(this.state.orderDetailsModal.data)
    const idx = _.findIndex(attributes, (a) => a.attributeId === attribute.attributeId);
    attributes.splice(idx, 1)
    this.setState({orderDetailsModal: objectAssign(this.state.orderDetailsModal, {data: attributes})}, () => {
      this.clearAttributesSorting()
    })
  }

  addMyOrdersAttribute(attribute) {
    const _attr = JSON.parse(attribute.attribute)
    const attributes = [...this.state.myOrdersModal?.data || []];
    attributes.push({
      attributeId: _attr.id,
      position: attributes.length,
      orderTypeId: this.props.orderType.id
    })
    this.setState({myOrdersModal: objectAssign(this.state.myOrdersModal, {data: attributes || []})}, () => {
      this.clearMyOrdersAttributesSorting()
    })
  }

  setControllerTypeToMyOrdersAttribute = (id, controllerType) => {
    const attrIndex = this.state.myOrdersModal?.data.findIndex(el => el.attributeId === id);
    let attributes = [...this.state.myOrdersModal?.data || []];
    attributes[attrIndex] = {...attributes[attrIndex], controllerType: `${controllerType}`};
    this.setState({
      myOrdersModal: {
        ...this.state.myOrdersModal,
        data: [...attributes]
      }
    })
  };

  removeMyOrdersAttribute(attribute) {
    const attributes = [].concat(this.state.myOrdersModal.data)
    const idx = _.findIndex(attributes, (a) => a.attributeId === attribute.attributeId)
    attributes.splice(idx, 1)
    this.setState({myOrdersModal: objectAssign(this.state.myOrdersModal, {data: attributes})}, () => {
      this.clearMyOrdersAttributesSorting()
    })
  }

  clearAttributesSorting() {
    const attributes = this.state.orderDetailsModal.data
    attributes.forEach((a, idx) => {
      a.position = idx
    })

    this.setState({orderDetailsModal: objectAssign(this.state.orderDetailsModal, {data: attributes})})
  }

  clearMyOrdersAttributesSorting() {
    const attributes = this.state.myOrdersModal.data
    attributes.forEach((a, idx) => {
      a.position = idx
    })

    this.setState({myOrdersModal: objectAssign(this.state.myOrdersModal, {data: attributes})})
  }

  detailsAttributesList() {
    const sorted = _.sortBy(this.state.orderDetailsModal.data, 'position')
    return sorted.map(
      (attr) => (
        <OrderDetailsAttributeCard
          key={attr.attributeId}
          attribute={attr}
          valueTypesMappings={this.props.valueTypesMappings}
          extractAttributeConfig={this.props.extractAttributeConfig}
          remove={this.removeAttribute}
          setControllerType={this.setControllerTypeToAttribute}
        />
      )
    )
  }

  myOrdersAttributesList() {
    const sorted = _.sortBy(this.state.myOrdersModal.data, 'position')
    return sorted.map(
      (attr) => (
        <OrderDetailsAttributeCard
          key={attr.attributeId}
          attribute={attr}
          valueTypesMappings={this.props.valueTypesMappings}
          extractAttributeConfig={this.props.extractAttributeConfig}
          remove={this.removeMyOrdersAttribute}
          setControllerType={this.setControllerTypeToMyOrdersAttribute}
        />
      )
    )
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }

  showOrderDetailsModal(e) {
    e.preventDefault()
    this.setState({orderDetailsModal: {open: true}}, () => {
      this.getOrderDetails()
    })
  }

  showMyOrdersModal(e) {
    e.preventDefault()
    this.setState({myOrdersModal: {open: true}}, () => {
      this.getMyOrders()
    })
  }

  closeDetailsModal() {
    this.setState({orderDetailsModal: {...this.state.orderDetailsModal, open: false}})
  }

  closeMyOrdersModal() {
    this.setState({myOrdersModal: {...this.state.myOrdersModal, open: false}})
  }

  stringComparator = (aLabel, bLabel) => {
    if (aLabel < bLabel) {
      return -1
    }
    if (aLabel > bLabel) {
      return 1
    }
    return 0
  }

  renderAttributeData(system, node, modal, addAttribute) {
    const {currentStep} = this.state

    return (
      <ul className="attributes-list">
        {
          node.data.sort((a, b) => this.stringComparator(__(a.propertyLabel), __(b.propertyLabel))).map((a) => {
            if (currentStep === 1 && !a.searchable) {
              return null
            }

            if (_.findIndex(modal.data, (i) => i.attributeId === a.id) === -1) {
              return (

                <li
                  className="attributes-list-item"
                  key={`data-${system}-${a.id}`}
                >
                  <Draggable
                    type="item"
                    data={JSON.stringify(a)}
                  >
                    {a && __(a.propertyLabel)}
                    <a
                      onClick={() => addAttribute({attribute: JSON.stringify(a)})}
                    >
                      <Glyphicon className="pull-right" glyph="plus-sign"/>
                    </a>
                  </Draggable>
                </li>
              )
            }
            return null
          })

        }
      </ul>
    )
  }

  renderAttributeChildren(system, node, level, modal, addAttribute) {
    return (node.children.sort((a, b) => this.stringComparator(__(a.label), __(b.label))).map((child) => (
        <div
          className="gutter-top"
          style={{paddingLeft: `${level * 8}px`}}
          key={`child-${system}-${node.label}-${child.label}`}
        >
          <h5>{__(child.label)}</h5>

          {child.data.length > 0
            && (
              <div>
                {this.renderAttributeData(system, child, modal, addAttribute)}
              </div>
            )}

          {child.children
            && (
              <div>
                {this.renderAttributeChildren(system, child, level + 1, modal, addAttribute)}
              </div>
            )}
        </div>
      ))
    )
  }

  renderAvailableAttributesList(modal, addAttribute) {
    if (!this.props.attributesConfigurationTree) {
      return null
    }

    const {remoteSystemsMap} = this.props

    let systems = Object.keys(this.props.attributesConfigurationTree).filter((system) => system !== 'godoo')
      .sort((a, b) => this.stringComparator(remoteSystemsMap[a], remoteSystemsMap[b]))

    return (
      <>
        <CollapsiblePanelDeprecated
          panelKey={'godoo'}
          eventKey={`${0} - ${remoteSystemsMap['godoo']}`}
          title={remoteSystemsMap['godoo']}
          isParent={true}
          body={
            <div style={{paddingLeft: '10px'}}>
              {
                this.props.attributesConfigurationTree['godoo'].map((node, idx) => {
                  return (
                    <CollapsiblePanelDeprecated
                      panelKey={node.label}
                      eventKey={`${idx + 1} - ${node.label}`}
                      title={node.label}
                      body={
                        <div style={{paddingLeft: '10px'}}>
                          {node.data.length && this.renderAttributeData('godoo', node, modal, addAttribute) || <div/>}
                          {node.children.length && this.renderAttributeChildren('godoo', node, 1, modal, addAttribute) ||
                            <div/>}
                        </div>
                      }
                    />
                  )
                })
              }
            </div>
          }
        />
        {
          systems.map((system, index) => (
            <CollapsiblePanelDeprecated
              panelKey={system}
              eventKey={`${index + 1} - ${remoteSystemsMap[system]}`}
              title={remoteSystemsMap[system]}
              isParent={true}
              body={
                <div style={{paddingLeft: '10px'}}>
                  {
                    this.props.attributesConfigurationTree[system].sort((a, b) => this.stringComparator(a.label, b.label)).map((node, idx) => {
                      return (
                        <CollapsiblePanelDeprecated
                          panelKey={node.label}
                          eventKey={`${idx + 1} - ${node.label}`}
                          title={node.label}
                          body={
                            <div style={{paddingLeft: '10px'}}>
                              {node.data.length && this.renderAttributeData(system, node, modal, addAttribute) ||
                                <div/>}
                              {node.children.length && this.renderAttributeChildren(system, node, 1, modal, addAttribute) ||
                                <div/>}
                            </div>
                          }

                        />
                      )
                    })
                  }
                </div>
              }
            />
          ))
        }
      </>)
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  async handleRefreshFlow() {
    const {getPublishedFlows} = this.props
    try {
      await api.post('/system/discoverNewFlows')
      showMessage('success', 'Flows are now updated')
    } catch (e) {
      showMessage('error', 'Flows cannot be updated')
    }
    try {
      getPublishedFlows()
    } catch (e) {
      //Ignored
    }
  }

  render() {
    const {
      isLoading,
      mode,
      orderType,
      formValues
    } = this.props

    const renderPanelFooter = () => (
      <div className="group">
        <button
          className="btn btn-default pull-left"
          onClick={() => {
            hashHistory.goBack()
          }}
        >
          <Glyphicon glyph="chevron-left"/>{__('Back')}
        </button>

        <button
          className={'btn pull-right '.concat(mode === 'create' ? 'btn-success' : 'btn-primary')}
          disabled={isLoading}
          size="large"
          onClick={this.handleSubmit}
        >
          <Glyphicon glyph={(mode === 'edit') ? 'save' : 'plus-sign'}/>
          {__((mode === 'edit') ? __('Save Changes') : __('Create'))}
        </button>
      </div>
    )

    const listEntities = (entities) => {
      if (!entities) {
        return []
      }
      const options = entities.map((entity) => <option key={entity.id} value={entity.id}>{entity.name}</option>)
      return [<option key="000" value="">{__('Select one')}..</option>].concat(options)
    }

    const listFlows = (flows) => {
      if (!flows) {
        return []
      }
      // Order by version
      let options = _.orderBy(flows, (f) => -f.flowVersion)

      // uniqu flowId
      options = _.uniqBy(options, 'flowId').map((flow) => {
        const showId = _.filter(flows, (f) => f.title === flow.title).length > 1
        return (
          <option key={`flow-${flow.flowId}`} value={flow.flowId}>
            {flow.title} {showId && `(${flow.flowId})`}
          </option>
        )
      })
      return [<option key="000" value="">{__('Select one')}..</option>].concat(options)
    }

    const listFlowVersions = (flows, flowId) => {
      if (!flowId) {
        return []
      }
      const options = _.filter(flows, (f) => f.flowId === flowId).map((flow) => (
        <option key={`ver-${flow.flowId}-${flow.flowVersion}`} value={flow.flowVersion}>
          {flow.flowVersion}
        </option>
      ))
      return [<option key="verion-000" value="">{__('Select one')}..</option>].concat(options)
    }

    const listDateFormats = () => {
      const now = new moment()
      const options = this.props.dateFormats.map((df) => (
        <option key={`date-format-${df.id}`} value={df.id}>
          {df.id} (ie: {now.format(df.id.replace('dd', 'DD').replace('mm', 'MM'))})
        </option>
      ))
      return [<option key="verion-000" value="">{__('Select one')}..</option>].concat(options)
    }

    const disabledModalTooltip = (
      <Tooltip id="disabled-modal">
        {__('createOrderTypeFirst')}
      </Tooltip>
    )

    const {orderDetailsModal, myOrdersModal} = this.state

    const {rightMappings} = global.constants

    function getCompaniesTree(companies, config = {}) {

      const {rootCheckable = true} = config

      return companies?.map((company) => ({
        title: (
          <S.TreeNode> <ClusterOutlined/> {company.name} - {company.shortName}
            <small> ({company?.children?.length}) </small>
          </S.TreeNode>
        ),
        disableCheckbox: !rootCheckable,
        searchValue: `${company.name} (${company.shortName})`,
        key: JSON.stringify({
          type: 'company',
          id: company.id,
          key: company.id
        }),
        children: company.children?.map((entity) => ({
          title: <S.TreeNode>{entity.type === 'organisation' ? <ClusterOutlined/> :
            <TeamOutlined/>}{entity.name}</S.TreeNode>,
          searchValue: entity.name,
          key: JSON.stringify({
            type: 'entity',
            id: entity.id,
            key: entity.id
          })
        }))
      }))
    }

    return (
      <div>
        <Panel>
          <Panel.Heading>
            {__(`${mode.charAt(0).toUpperCase() + mode.slice(1)} Order Type`)}
          </Panel.Heading>
          <Panel.Body>
            <form className="form-horizontal report-form">
              {formValues && (
                <>
                  <div className="row">
                    <div className="col-xs-12 col-md-8">
                      <Field
                        name="name"
                        component={renderField}
                        label={__('Order Type Name')}
                        className="form-control"
                        type="text"
                        labelCol="col-md-4"
                        inputCol="col-md-8"
                        required
                      />

                      <Field
                        name="code"
                        component={renderField}
                        label={__('Short Name')}
                        className="form-control"
                        labelCol="col-md-4"
                        inputCol="col-md-8"
                        type="text"
                        required
                      />

                      <Field
                        name="description"
                        component={renderField}
                        label={__('Description')}
                        className="form-control"
                        type="textarea"
                        labelCol="col-md-4"
                        inputCol="col-md-8"
                      />

                      <Field
                        name="creatableByUser"
                        component={renderField}
                        label={__('Manual Orders')}
                        className="form-control"
                        type="checkbox"
                        labelCol="col-md-4"
                        inputCol="col-md-8"
                      />
                    </div>
                    {mode !== 'create'
                      && (
                        <div className="col-xs-12 col-md-4">
                          <div className="form-group">
                            <label className="col-sm-5 control-label">{__('creationDateTime')}</label>
                            <div className="col-sm-7">
                              <p className="form-control-static">
                                {moment(orderType.creationDatetime).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                              </p>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-5 control-label">{__('Last modified date/time')}</label>
                            <div className="col-sm-7">
                              <p className="form-control-static">
                                {moment(orderType.lastUpdateDatetime).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="row">
                    <div className="col-xs-10 col-md-8">
                      <Field
                        name="flowId"
                        component={renderField}
                        className="form-control"
                        label={__('Flow')}
                        items={listFlows(this.props.publishedFlows)}
                        onChange={() => {
                          this.props.resetVersion()
                        }}
                        labelCol="col-md-4"
                        inputCol="col-md-8"
                        type="select"
                        required
                      />
                    </div>
                    <div className="col-xs-2 col-md-4">
                      {this.props.guiUser && checkUserRights(this.props.guiUser.rights, rightMappings.CAN_REFRESH_FLOWS)
                        ? (
                          <div className="col-xs-2">
                            <RefreshFlowButton type="primary"
                                               onClick={this.handleRefreshFlow.bind(this)}><RedoOutlined/></RefreshFlowButton>
                          </div>
                        )
                        : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-8">
                      <Field
                        name="flowVersion"
                        component={renderField}
                        label={__('Flow Version')}
                        className="form-control"
                        items={listFlowVersions(this.props.publishedFlows, formValues.flowId)}
                        labelCol="col-md-4"
                        inputCol="col-md-8"
                        type="select"
                        required
                      />
                      <Field
                        name="defaultEntityIds"
                        component={renderTreeSelect}
                        label={__('Default Entities')}
                        className="form-control"
                        treeData={getCompaniesTree(this.props.entitiesRelatedToUser, {rootCheckable: false})}
                        labelCol="col-md-4"
                        inputCol="col-md-8"
                        type="select"
                      />
                      <Field
                        name="entities"
                        component={renderTreeSelect}
                        label={__('Entities')}
                        treeData={getCompaniesTree(this.props.entitiesRelatedToUser)}
                        className="form-control"
                        labelCol="col-md-4"
                        inputCol="col-md-8"
                        required
                      />
                      <Field
                        name="color"
                        component={renderField}
                        label={this.capitalize(__('Color'))}
                        className="form-control color_input"
                        labelCol="col-md-4"
                        inputCol="col-md-8"
                        items={{onColorChange: this.handleColorChange}}
                        type="colorPicker"
                      />

                      {formValues && formValues.creatableByUser

                        && (
                          <div className="row">
                            <div style={{marginBottom: 0}} className="form-group">
                              <label className="col-sm-4 control-label">
                                <br/>
                                <br/>
                                {__('Automatic External Order Id Creation Pattern')}:
                              </label>
                              <div className="col-sm-8">
                                <div className="col-sm-6 no-padding external-order-format">
                                  <Field
                                    name="dateFormat"
                                    component={renderField}
                                    label={__('Date Format')}
                                    className="form-control"
                                    items={listDateFormats()}
                                    labelCol="col-xs-12"
                                    inputCol="col-xs-12"
                                    type="select"
                                  />
                                </div>

                                <div className="col-sm-2 no-padding external-order-format">
                                  <Field
                                    name="size"
                                    component={renderField}
                                    label={__('Size')}
                                    className="form-control"
                                    labelCol="col-xs-12"
                                    inputCol="col-xs-12"
                                    type="text"
                                  />
                                </div>

                                <div className="col-sm-4 no-padding external-order-format">
                                  <Field
                                    name="offset"
                                    component={renderField}
                                    label={__('Starting Offset')}
                                    className="form-control"
                                    labelCol="col-xs-12"
                                    inputCol="col-xs-12"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>

                          </div>
                        )}
                      {formValues && formValues.creatableByUser && formValues.code && formValues.dateFormat && formValues.size && formValues.offset >= 0
                        && (
                          <div className="form-group">
                            <label className="col-sm-4 control-label">{__('Preview')}</label>
                            <div className="col-sm-8">
                              <p>
                                <div className="live-order-id-example discreet">
                                  {moment().format(formValues.dateFormat.replace('mm', 'MM').replace('dd', 'DD'))}
                                  {formValues.code}{(parseInt(formValues.offset) + 1).toString().padStart(formValues.size, '0')}
                                </div>
                              </p>
                            </div>
                          </div>
                        )}

                      <div className="form-group gutter-top">
                        <label className="col-sm-4 control-label">
                          {__('Order Details Attributes')}
                        </label>

                        <div className="col-sm-8">
                          {mode !== 'create'
                            && (
                              <button
                                onClick={this.showOrderDetailsModal}
                                className="btn btn-link"
                                disabled={mode === 'create'}
                              >
                                {__('Manage Attributes')}
                              </button>
                            )}
                          {mode === 'create'
                            && (
                              <OverlayTrigger placement="top" overlay={disabledModalTooltip}>
                                <button
                                  onClick={(e) => e.preventDefault()}
                                  className="btn btn-link disabled"
                                >
                                  {__('Manage Attributes')}
                                </button>
                              </OverlayTrigger>
                            )}
                        </div>
                      </div>

                      <div className="form-group gutter-top">
                        <label className="col-sm-4 control-label">
                          {__('My Orders Attributes')}
                        </label>

                        <div className="col-sm-8">
                          {mode !== 'create'
                            && (
                              <button
                                onClick={this.showMyOrdersModal}
                                className="btn btn-link"
                                disabled={mode === 'create'}
                              >
                                {__('Manage Attributes')}
                              </button>
                            )}
                          {mode === 'create'
                            && (
                              <OverlayTrigger placement="top" overlay={disabledModalTooltip}>
                                <button
                                  onClick={(e) => e.preventDefault()}
                                  className="btn btn-link disabled"
                                >
                                  {__('Manage Attributes')}
                                </button>
                              </OverlayTrigger>
                            )}
                        </div>
                      </div>

                      <div className="form-group gutter-top">
                        <label className="col-sm-4 control-label">
                          {__('Thresholds Deadline')}
                        </label>

                        <div className="col-sm-8">
                          {mode !== 'create'
                            && (
                              <button
                                onClick={this.openDeadlineThresholdsModal}
                                className="btn btn-link"
                                disabled={mode === 'create'}
                              >
                                {__('Thresholds Deadline')}
                              </button>
                            )}
                          {mode === 'create'
                            && (
                              <OverlayTrigger placement="top" overlay={disabledModalTooltip}>
                                <button
                                  onClick={(e) => e.preventDefault()}
                                  className="btn btn-link disabled"
                                >
                                  {this.capitalize(__('Thresholds Deadline'))}
                                </button>
                              </OverlayTrigger>
                            )}
                        </div>
                      </div>

                    </div>

                  </div>
                </>
              )}

            </form>
          </Panel.Body>
          <Panel.Footer>{renderPanelFooter()}</Panel.Footer>
        </Panel>

        <Modal
          bsSize="large"
          backdrop="static"
          show={this.state.orderDetailsModal.open}
          onHide={this.closeDetailsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">{__('Order Details Attributes')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{height: 610, overflowY: 'scroll'}}>
            {orderDetailsModal.loading
              && <span>{__('loading')}</span>}
            {!orderDetailsModal.loading && orderDetailsModal.data
              && (
                <div className="row">
                  <div className="col-md-5 attributes-groups">
                    <PanelGroup
                      accordion
                      // activeKey={this.state.activeKey}
                      id="accordion-attributes"
                    >
                      {this.renderAvailableAttributesList(this.state.orderDetailsModal, this.addAttribute)}
                    </PanelGroup>
                  </div>

                  <div className="col-md-7">
                    <Droppable
                      className="dnd-drop-area"
                      types={['attribute']} // <= allowed drop types
                      onDrop={this.addAttribute}
                    >
                      <SortableList
                        lockAxis="y"
                        lockToContainerEdges
                        items={this.detailsAttributesList()}
                        useDragHandle
                        onSortEnd={this.onAttributesSortEnd('orderDetailsModal')}
                      />
                      <div className="discreet text-center gutter-top gutter-bottom">
                        {this.state.orderDetailsModal.data.length === 0
                          && <span>{__('dragAttributesToStartText')}</span>}
                        {this.state.orderDetailsModal.data.length > 0
                          && <span>{__('dragAttributesHereText')}</span>}
                      </div>
                    </Droppable>
                  </div>
                </div>
              )}
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <Button
                className="pull-left"
                onClick={this.closeDetailsModal}
              >
                {__('Close')}
              </Button>
              <Button
                className="btn-primary pull-right"
                onClick={this.setOrderDetails}
              >
                <Glyphicon glyph="save"/> {__('Save Changes')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          bsSize="large"
          backdrop="static"
          show={this.state.myOrdersModal.open}
          onHide={this.closeMyOrdersModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">{__('Order Details Attributes')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {myOrdersModal.loading
              && <span>{__('loading')}</span>}
            {!myOrdersModal.loading && myOrdersModal.data
              && (
                <div className="row">
                  <div className="col-md-5 attributes-groups">
                    <PanelGroup
                      accordion
                      id="accordion-attributes"
                    >
                      {this.renderAvailableAttributesList(this.state.myOrdersModal, this.addMyOrdersAttribute)}
                    </PanelGroup>
                  </div>

                  <div className="col-md-7">
                    <Droppable
                      className="dnd-drop-area"
                      types={['attribute']} // <= allowed drop types
                      onDrop={this.addMyOrdersAttribute}
                    >
                      <SortableList
                        lockAxis="y"
                        lockToContainerEdges
                        items={this.myOrdersAttributesList()}
                        useDragHandle
                        onSortEnd={this.onAttributesSortEnd('myOrdersModal')}
                      />
                      <div className="discreet text-center gutter-top gutter-bottom">
                        {this.state.myOrdersModal.data.length === 0
                          && <span>{__('dragAttributesToStartText')}</span>}
                        {this.state.myOrdersModal.data.length > 0
                          && <span>{__('dragAttributesHereText')}</span>}
                      </div>
                    </Droppable>
                  </div>
                </div>
              )}
          </Modal.Body>
          <Modal.Footer>
            <div className="group">
              <Button
                className="pull-left"
                onClick={this.closeMyOrdersModal}
              >
                {__('Close')}
              </Button>
              <Button
                className="btn-primary pull-right"
                onClick={this.setMyOrders}
              >
                <Glyphicon glyph="save"/> {__('Save Changes')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsSize="large"
          backdrop="static"
          show={this.state.deadlineThresholdsModal.open}
          onHide={this.closeDeadlineThresholdsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">{__('Order deadline thresholds Management')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <div className="col-md-8">
                <Field
                  name="deadlineThresholds.4.valueEnd"
                  component={renderField}
                  className="form-control"
                  label={__('from @last to')}
                  labelCol="col-xs-12"
                  inputCol="col-xs-12"
                  type="number"
                  disabled
                />
              </div>

              <div className="col-md-4">
                <div
                  className="color-picker__color"
                  style={{...verticalCenterStyle, backgroundColor: deadlineThresholdColor[4]}}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <Field
                  name="deadlineThresholds.3.valueEnd"
                  component={renderField}
                  className="form-control"
                  label={__('from @last to')}
                  labelCol="col-xs-12"
                  inputCol="col-xs-12"
                  type="number"
                />
              </div>

              <div className="col-md-4">
                <div
                  className="color-picker__color"
                  style={{...verticalCenterStyle, backgroundColor: deadlineThresholdColor[3]}}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <Field
                  name="deadlineThresholds.2.valueEnd"
                  component={renderField}
                  label={__('from @last to')}
                  className="form-control"
                  labelCol="col-xs-12"
                  inputCol="col-xs-12"
                  type="number"
                />
              </div>

              <div className="col-md-4">
                <div
                  className="color-picker__color"
                  style={{...verticalCenterStyle, backgroundColor: deadlineThresholdColor[2]}}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <Field
                  name="deadlineThresholds.1.valueEnd"
                  component={renderField}
                  className="form-control"
                  label={__('from @last to')}
                  labelCol="col-xs-12"
                  inputCol="col-xs-12"
                  type="number"
                />
              </div>

              <div className="col-md-4">
                <div
                  className="color-picker__color"
                  style={{...verticalCenterStyle, backgroundColor: deadlineThresholdColor[1]}}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <Field
                  name="deadlineThresholds.0.valueEnd"
                  component={renderField}
                  className="form-control"
                  label={__('From 0% To')}
                  labelCol="col-xs-12"
                  inputCol="col-xs-12"
                  type="number"
                />
              </div>

              <div className="col-md-4">
                <div
                  className="color-picker__color"
                  style={{...verticalCenterStyle, backgroundColor: deadlineThresholdColor[0]}}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              onClick={this.closeDeadlineThresholdsModal}
            >
              {__('OK')}
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}

OrderType.propTypes = {
  handleSubmit: PropTypes.func,
  resetVersion: PropTypes.func,
  addItems: PropTypes.func,
  extractAttributeConfig: PropTypes.func,
  dispatch: PropTypes.func,
  removeItems: PropTypes.func,
  isLoading: PropTypes.bool,
  formValues: PropTypes.object,
  mode: PropTypes.string,
  entities: PropTypes.array,
  entitiesRelatedToUser: PropTypes.array,
  publishedFlows: PropTypes.array,
  dateFormats: PropTypes.array,
  attributesConfiguration: PropTypes.array,
  attributesConfigurationTree: PropTypes.object,
  orderType: PropTypes.object,
  initialOrderTypeValues: PropTypes.object,
  getData: PropTypes.func,
  getPublishedFlows: PropTypes.func,
  guiUser: PropTypes.object,
  valueTypesMappings: PropTypes.object
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const OrderTypeForm = reduxForm({
  form: 'orderTypeForm',
  validate
}, mapDispatchToProps)(OrderType)

export default connect(
  (state) => ({
    formValues: getFormValues('orderTypeForm')(state),
    remoteSystemsMap: state.ui.remoteSystems?.reduce((acc, system) => {
      acc[system.id] = system.name
      return acc
    }, {}),
    guiUser: state.user.data
  }),
  {}
)(OrderTypeForm)

export const RefreshFlowButton = styled(AntButton)`
  background-color: rgb(0, 141, 176);
  border-color: rgb(0, 141, 176);
  border-radius: 3px;

  :hover {
    background-color: #086391;
  }

`
