import { ERROR } from "components/dist/Utils/LoggerUtils"
import { api } from "./index";


export const initialiseMultipartUpload = async (fileName) => {
  return api.post(`/files/multipart-upload/${fileName}/initialise`)
    .then((res) => res?.data?.uploadId)
    .catch((error) => ERROR(error))
}

export const uploadFullFile = async (url, payload) => {
  const formData = new FormData()
  formData.append('file', payload)

  return api.post(url, formData).then((res) => res).catch((error) => ERROR(error))
}

export const uploadPart = async (data, formData) => {
  return api.put(`/files/multipart-upload/${data.fileName}?uploadId=${data.uploadId}&partNumber=${data.partNumber}`, formData)
    .then((res) => res)
}

// Helper function to wait for a specified time
export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const completeMultipartUpload = async (name, uploadId, payload) => {
  return api.post(`/files/multipart-upload/${name}/complete?uploadId=${uploadId}`, payload)
    .then((res) => {
      return res
    })
    .catch((error) => {
      ERROR(error)
    })
}

export const uploadProgress = async (props) => {
  const { uploadId, xsdName, primaryKey, fieldName, fileName } = props || {}

  return api.get(`/files/${xsdName}/${primaryKey}/${fieldName}/${fileName}/progress?uploadId=${uploadId}`)
    .then((res) => res)
    .catch((error) => {
      ERROR(error)
    })
}

export const handleTimeout = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), timeout)
  })
}

export const getFileInfo = async (url) => {
  return api.head(url).catch((error) => ERROR(error))
}

export const getFileCrc = async (url) => {
  return api.get(`${url}/crc`).catch((error) => ERROR(error))
}

export const singlePartDownload = (url, authToken) => {
  return new Promise(async (resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.onload = () => resolve(xhr.response)
    xhr.onerror = error => reject(error)

    xhr.open('GET', url.replaceAll('|', '%7C'))
    xhr.responseType = 'blob'
    xhr.setRequestHeader('x-auth-token', authToken)
    xhr.send()
  })
}

export const downloadPart = (url, { start, end }) =>
  api.get(url, {
    headers: {
      Range: `bytes=${start}-${end}`
    },
    responseType: "arraybuffer"
  })
    .then((res) => res.data)
    .catch((error) => ERROR(error))
