/*eslint no-unused-vars: "off"*/
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { reduxForm } from 'redux-form';

import StepComments from '../../StepComments';
import SimpleLabelControl from '../../controls/SimpleLabelControl';
import TextEntryControl from '../../controls/TextEntryControl';
import MultiLineTextEntryControl from '../../controls/MultiLineTextEntryControl';
import DateControl from '../../controls/DateControl';
import DocumentViewerControl from '../../controls/DocumentViewerControl';
import DocumentEditorControl from '../../controls/DocumentEditorControl';
import DateTimeControl from '../../controls/DateTimeControl';
import AssignmentControl from '../../controls/AssignmentControl';
import CalendarsDashboard from '../../Calendar/CalendarsDashboard';
import SimpleCheckBoxControl from '../../controls/SimpleCheckBoxControl';
import RadioButtonControl from '../../controls/RadioButtonControl';
import PriceEntryControl from '../../controls/PriceEntryControl';
import UrlLinkControl from '../../controls/UrlLinkControl';
import ObjectListingControl from '../../controls/ObjectListingControl';
import HtmlEditorControl from '../../controls/HtmlEditorControl';
import BillingController from '../../controls/BillingController';
import PopupListBoxControl from '../../controls/PopupListBoxControl';
import * as config from '../../../constants/globalConfiguration';
import {
  createStepRemoteItem,
  editStepRemoteItem,
  fetchStepRemoteItemsFromIds,
  fetchStepRemoteTableData
} from "../../../utils/appHelper";
import FileUploader from "../../fileUploader";
import MainKeysControl from "../../controls/mainKeyControl/MainKeysControl";
import { OBJECT_BINDER_CONTROLLER_TYPE, OBJECT_PICKER_CONTROLLER_TYPE } from "../../controls/mainKeyControl/utils";
import MultipleChoiceControl from "../../controls/MultipleChoiceControl";
import { ImageViewer } from "../../controls/ImageViewer";
import ObjectListingControlAdapted from '../../controls/ObjectListingControlAdapted';
import { __ } from '../../../utils/translationUtils'

class StepLegacy extends React.Component {

  static propTypes = {
    handleDocumentEditCompanySelect: PropTypes.func,
    setAppointmentDetails: PropTypes.func,
    step: PropTypes.object,
    order: PropTypes.object,
    process: PropTypes.object,
    guiUserId: PropTypes.string,
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { step } = this.props;

    // Sort fields by position.
    const sorted = _.sortBy(step.fields, 'position');
    const splitColumns = sorted.length > 22;
    const fieldGroups = (splitColumns) ? _.chunk(sorted, sorted.length / 2) : [sorted];
    return (

      <div className={
        'step-container'
          .concat((step.discriminator != config.APPONINTMENT_STEP_DISCRIMINATOR) ? ' col-sm-12' : '')
      }>

        <form className="form-horizontal">

          {(step.discriminator == config.ASSIGNMENT_STEP_DISCRIMINATOR || step.discriminator == config.REASSIGNMENT_STEP_DISCRIMINATOR) &&
            <AssignmentControl
              process={this.props.process}
              controlData={step.attributes}
              guiUserId={this.props.guiUserId}
            />
          }

          {(step.discriminator == config.APPONINTMENT_STEP_DISCRIMINATOR) &&
            <CalendarsDashboard
              selectable
              calendarControlProps={{
                controlData: step.attributes,
                process: this.props.propcess,
                order: this.props.order,
                setAppointmentDetails: this.props.setAppointmentDetails
              }}
            />
          }
          <div className="row">
            {fieldGroups.map((group, k) => {
              return (

                <div key={'fg-' + k} style={{ padding: '0 30px' }} className={'fieldset ' + (splitColumns ? 'col-sm-6' : 'col-sm-12')}>
                  {group.map((field, key) => {
                    if (!field.visible) {
                      return null;
                    }
                    let control;

                    const objectSelectorsAPIs = {
                      fetchTableData: (payload, optionalProps) => fetchStepRemoteTableData({ fieldId: field.id, ...optionalProps, payload }),
                      createRemoteItem: (payload, optionalProps) => createStepRemoteItem({ fieldId: field.id, ...optionalProps, payload }),
                      editRemoteItem: ({ mainKey, payload }, optionalProps) => editStepRemoteItem({ fieldId: field.id, ...optionalProps, payload, mainKey }),
                      fetchRemoteItems: (mainKeys, optionalProps) => fetchStepRemoteItemsFromIds({ fieldId: field.id, ...optionalProps, mainKeys }),
                    }

                    switch (field.controllerType) {
                      case 'SimpleLabel':
                        control = <SimpleLabelControl key={field.id} controlData={field} />;
                        break;

                      case 'TextEntry':
                      case 'ColorPicker':
                      case 'ShortText':
                      case 'Rating':
                        control = <TextEntryControl key={field.id} controlData={field} />;
                        break;
                      case 'Number':
                        control = <TextEntryControl type={'number'} key={field.id} controlData={field} />; // TODO: controlData.valueType [NUMBER, INTEGER]
                        break;

                      case 'TranslatedLabel':
                        control = <SimpleLabelControl key={field.id} value={__((field.source) ? field.source : field.defaultValue)} controlData={field} />;
                        break;

                      case 'UrlLink':
                      case 'Url':
                        if (field.readOnly)
                          control = <UrlLinkControl key={field.id} controlData={field} />;
                        else
                          control = <TextEntryControl type={"url"} key={field.id} controlData={field} />;
                        break;

                      case 'PriceEntry':
                        control = <PriceEntryControl key={field.id} controlData={field} />;
                        break;

                      case 'MultiLineTextEntry':
                      case 'LongText':
                        control = <MultiLineTextEntryControl key={field.id} controlData={field} />;
                        break;
                      case 'Paragraph':
                        control = <MultiLineTextEntryControl key={field.id} controlData={{ ...field, readOnly: true }} />;
                        break;
                      case 'DateInput':
                      case 'Date':
                        control = <DateControl key={field.id} controlData={field} />;
                        break;

                      case 'DateTimeInput':
                      case 'Time':
                        control = <DateTimeControl key={field.id} controlData={field} />;
                        break;

                      case 'DocumentEditor':
                        control = <DocumentEditorControl key={field.id} controlData={field}
                          handleCompanySelect={this.props.handleDocumentEditCompanySelect.bind(this)} />;
                        break;

                      case 'DocumentViewer':
                        control = <DocumentViewerControl key={field.id} controlData={field} />;
                        break;

                      case 'PhotoCollection':
                      case 'MediaViewer':
                        control = <FileUploader key={field.id} controlData={field} order={this.props.order}
                          withPreview={true} readOnly={field.readOnly} />;
                        break;

                      case 'CustomPhotoCollection':
                        control = <FileUploader key={field.id} controlData={field} order={this.props.order}
                          withPreview={false} readOnly={field.readOnly} />;
                        break;

                      case 'SimpleCheckBox':
                        if (field.readOnly)
                          control = <SimpleLabelControl key={field.id} controlData={field} />; //TODO: check if value is compatible
                        else
                          control = <SimpleCheckBoxControl key={field.id} controlData={field} />;
                        break;

                      case 'MultipleChoice':
                        control = <MultipleChoiceControl key={field.id} controlData={field} />
                        break;

                      case 'RadioButton':
                      case 'SingleChoice':
                        if (field.readOnly)
                          control = <SimpleLabelControl key={field.id} controlData={field} />; //TODO: check if value is compatible
                        else
                          control = <RadioButtonControl inputCol={"col-sm-12"} key={field.id} controlData={field} />;
                        break;
                      case 'Toggle':
                        if (field.readOnly)
                          control = <SimpleLabelControl key={field.id} controlData={field} />; //TODO: check if value is compatible
                        else
                          control = <RadioButtonControl inputCol={"col-sm-12"} key={field.id}
                            controlData={{ ...field, valueType: 'BOOLEAN' }} />;
                        break;
                      case 'SimpleSignature':
                      case 'Signature':
                        control = <FileUploader key={field.id} controlData={field} order={this.props.order}
                          withPreview={true} readOnly={field.readOnly} />;
                        break;

                      case 'RepresentativeSignature':
                        control = <FileUploader key={field.id} controlData={field} order={this.props.order}
                          withPreview={true} readOnly={field.readOnly} />;
                        break;

                      case 'PopupListBox':
                      case 'Dropdown':
                        control = <PopupListBoxControl key={field.id} controlData={field} />;
                        break;

                      case 'BillingController':
                      case 'Billing':
                        control = <BillingController form={field.id} key={field.id} controlData={field} />;
                        break;

                      case 'FileUpload':
                      case 'FileUploader':
                        control = <FileUploader key={field.id} controlData={field}
                          order={this.props.order}
                          withPreview={false} />;
                        break;

                      case 'HtmlEditor':
                        control = <HtmlEditorControl key={field.id} controlData={field} />;
                        break;

                      case 'ObjectListing':
                        return (
                          <ObjectListingControlAdapted
                            order={this.props.order}
                            key={field.id}
                            controlData={field}
                            apis={objectSelectorsAPIs}
                          />
                        )
                        break;
                      case 'Phone':
                        control = <TextEntryControl type={"phone"} key={field.id} controlData={field} />;
                        break;
                      case 'Email':
                        control = <TextEntryControl type={"email"} key={field.id} controlData={field} />;
                        break;
                      case 'ObjectPicker':
                        control = (
                          <MainKeysControl
                            key={field.id}
                            controlData={field}
                            objectConfiguration={field?.attributes?.objectPickerConfiguration}
                            formName={'stepForm'} controllerType={OBJECT_PICKER_CONTROLLER_TYPE}
                            apis={objectSelectorsAPIs}
                          />
                        )
                        break;
                      case 'ObjectBinder':
                        control = (
                          <MainKeysControl
                            controlData={field}
                            objectConfiguration={field?.attributes?.objectBinderConfiguration}
                            formName={'stepForm'} controllerType={OBJECT_BINDER_CONTROLLER_TYPE}
                            apis={objectSelectorsAPIs}
                          />
                        )
                        break;
                      case 'ImageViewer':
                        control =
                          <ImageViewer key={field.id} field={field} order={this.props.order} formName={'stepForm'} />
                        break;

                      default:
                        control = <div className="text-warning" key={key}>{field.controllerType} Control not yet implemented.<br /><br /></div>;
                    }
                    return control;
                  })}
                </div>

              );
            })}
          </div>
          {//TODO: Remove this shitty code, implemented in a rush only for Claudia and Be Advanced. To be replaced with an UI/UX solution as soon as possible.
            !window.location.hostname?.toLowerCase()?.replace('-', '')?.includes('beadvanced') &&
            <>
              <hr />
              <div className="row">
                <div className="col-xs-12">
                  <StepComments step={step} />
                </div>
              </div>
            </>
          }
        </form>

      </div>
    );
  }

}

export default reduxForm({
  form: 'stepForm',  // a unique identifier for this form
})(StepLegacy);
