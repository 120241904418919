import React, {createRef, useEffect} from 'react'

import {Redirect, useHistory,} from 'react-router-dom'
import {urlAny, urlGodoo, urlLocalhost} from "../constants/regexes";

export let hashHistory = createRef()


export default ({children}) => {
  let history = useHistory()
  useEffect(() => {
    if (!history) return
    hashHistory = history
    hashHistory.listen(() => {
      document.documentElement.scrollTop = 0;
    })
  }, [history])

  return <>{children}</>
}

export const HistoryNext = ({nextUrl = '/'}) => {

  if (nextUrl.match(urlAny)) {
    if (nextUrl.match(urlGodoo) || nextUrl.match(urlLocalhost)) {
      const url = new URL(nextUrl)
      window.location = url.toString()
    } else {
      window.location = nextUrl
    }
  }

  return (
    <Redirect
      to={{
        pathname: nextUrl
      }}
    />
  )
}
