import React from 'react'

import { CloseOutlined, InfoOutlined, CheckOutlined } from '@ant-design/icons';

import * as S from "./styles";

export const getProgressColor = (state) => {
  const { error, warning, completed } = state || {}
  if (error) return '#FF897A'
  if (warning) return '#00629D'
  if (completed) return '#85BB35'
  return '#00629D'
}

export default (props) => {
  const {
    active,
    error,
    warning,
    percent,
    precision,
  } = props

  const completed = error || warning || percent >= 100
  const color = getProgressColor({ error, warning, completed })
  const format = () => {
    if (!completed) return null
    if (error) return <CloseOutlined style={{ color }} />
    if (warning) return <InfoOutlined style={{ color }} />
    return <CheckOutlined style={{ color }} />
  }

  return !active ? null : (
    <S.CustomProgress
      type="circle"
      isButton={true}
      status="normal"
      width="100%"
      strokeWidth={10}
      format={format}
      percent={percent}
      gapDuration={precision}
      showInfo={completed}
      strokeColor={color}
    />
  )
}
