export default ({ calendarList, stepEvents }) => {
  if (!calendarList?.length) return []
  return calendarList.map((calendar) => {
    const withEvents = stepEvents.filter((e) => e.userId === calendar.user.id) || []

    return {
      ...calendar,
      events: [...calendar.events, ...withEvents]
    }
  })
}
