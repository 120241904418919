import React from 'react'
import PropTypes from 'prop-types'
import {change, Field, reduxForm} from 'redux-form'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {Button, Modal, Panel} from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'

import {getCompanyLogoUrl} from '../utils/appHelper'
import {isEmail, isRequired, renderField} from '../utils/fieldValidator'
import {hashHistory} from '../providers/HistoryProvider'
import * as config from '../constants/globalConfiguration'
import {message, Space, Tooltip, Upload} from "antd";
import {Icon} from "@ant-design/compatible";
import {deleteEntity, reopenEntity} from '../providers/ReduxProvider/actions/entityActions'
import {UploadOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import {api} from '../providers/ApiProvider'
import {getBase64} from "./EntityAvatar/utils";
import OrderTypesROField from "./formFields/OrderTypesROField";
import {ENTITY_FORM_NAME} from "./EntityFormNames";
import EntityAvatarForm, {
  AVATAR_TYPE_FIELD_NAME,
  AVATAR_URL_FIELD_NAME,
  CUSTOM_PICTURE_AVATAR_TYPE,
  GRAVATAR_AVATAR_TYPE,
  INITIAL_AVATAR_TYPE
} from "./EntityAvatarForm";
import {__} from '../utils/translationUtils'

const moment = require('moment')

const validate = values => {
  const errors = {};

  errors.name = isRequired(values.name);
  errors.shortName = isRequired(values.shortName);
  errors.parentId = isRequired(values.parentId);
  errors.contactPhone = isRequired(values.contactPhone);
  errors.contactEmail = isEmail(values.contactEmail);

  return errors;
};


class EntityForm extends React.Component {

  static propTypes = {
    getData: PropTypes.func,
    handleSubmit: PropTypes.func,
    deleteEntity: PropTypes.func,
    reopenEntity: PropTypes.func,
    updateEntity: PropTypes.func,
    canDelete: PropTypes.bool,
    canUpdate: PropTypes.bool,
    entity: PropTypes.object,
    isLoading: PropTypes.bool,
    isUserGroup: PropTypes.bool,
    mode: PropTypes.string,
    entityType: PropTypes.string,
    entities: PropTypes.array,
    users: PropTypes.array,
    nestedUsers: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.isUserGroup = this.props.entityType === 'user_group';
    this.entityType = config.entityTypes[this.props.entityType];
    this.state = {
      companyLogo: {
        previewVisible: false,
        previewImage: null,
        previewTitle: null,
        fileList: []
      },
      modal: {
        open: false,
        action: '',
        entity: {
          name: '',
          id: 0
        }
      },
      usersModal: {
        open: false,
      },
      userAvatarSrc: null
    };
    this.showUsers = this.showUsers.bind(this);
    this.listUsers = this.listUsers.bind(this);
  }

  componentDidMount() {
    this.getEntityCustomAvatar()
  }

  handleSubmit() {
    this.props.handleSubmit();
  }

  confirmAction(action) {
    this.setState({modal: {action: action, open: true, entity: this.props.entity}});
  }


  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({modal: {...this.state.modal, open: false}});
    this.setState({usersModal: {...this.state.usersModal, open: false}});
  }

  handleConfirmation() {
    if (this.state.modal.action === 'reopen') {
      this.handlereopen();
    } else if (this.state.modal.action === 'delete') {
      this.handledelete();
    }
  }

  handledelete() {
    this.props.deleteEntity(this.state.modal.entity).then(() => {
      this.props.getData();
    });
    // Close modal:
    this.closeModal();
  }

  handlereopen() {
    this.props.reopenEntity(this.state.modal.entity).then(() => {
      this.props.getData();
    });
    // Close modal:
    this.closeModal();
  }

  showUsers(e) {
    e.preventDefault();
    this.setState({usersModal: {open: true}});
  }

  listUsers() {
    return (
      <div className="clearfix">
        <ul className="list-group directly-attached col-xs-12">
          <li key="entity-users" className="list-group-item capitalize">
            <h4>{__(this.entityType.name)} {__('Users')}:</h4>
          </li>
          {this.props.users && this.props.users.map(user => (
            <li key={user.id} className="list-group-item">
              <Link to={`/users/${user.id}`}>
                {user.username}
              </Link><br/><i>({user.firstName} {user.lastName})</i>
            </li>
          ))}
        </ul>
        <ul className="with-children col-xs-12 list-group">
          <li key="nested-entity-users" className="list-group-item">
            <h4>{__('Nested Users')}:</h4>
          </li>
          {this.props.nestedUsers && this.props.nestedUsers.map(user => (
            <li key={user.id} className="list-group-item">
              <Link to={`/users/${user.id}`}>
                {user.username}
              </Link><br/>
              <i>
                ({user.firstName} {user.lastName})<br/>
                {__('Belongs to')}: {
                user.belongsTo.map((entity, i) => {
                  return (
                    <span key={entity.id}>
                  <Link to={`/redirectToEntity/${entity.id}`}>{entity.name}</Link>
                      {((i + 1) < user.belongsTo.length) ? ', ' : ''}
                  </span>);
                })}
              </i>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  handleCompanyLogoModalClose = () => this.setState({companyLogo: {...this.state.companyLogo, previewVisible: false}});

  handleCompanyLogoPreview = async (file, openModal = false) => {

    if (!file.url && !file.preview && file.originFileObj) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      companyLogo: {
        ...this.state.companyLogo,
        previewImage: file.url || file.preview,
        previewVisible: openModal,
        previewTitle: this.props.initialValues.name || 'Entity',
        fileList: [{
          uid: '-1',
          name: this.props.initialValues.name || 'Entity',
          status: 'done',
          url: file.url || file.preview
        }]
      }
    });

  }


  isEditMode = () => ['edit'].includes(this.props.mode)
  isCreateMode = () => ['create'].includes(this.props.mode)


  async getEntityCustomAvatar() {
    const {initialValues} = this.props
    const blobFile = await api.get(getCompanyLogoUrl(initialValues.id), {responseType: 'arraybuffer'})
      .then(response => new Blob([response.data], {type: "image/jpeg;"}))
      .catch(() => null)

    if (blobFile == null)
      return

    this.setState({userAvatarSrc: await getBase64(blobFile, {type: "image/jpeg;"})})

  }

  render() {
    const {isLoading, entity} = this.props;

    const renderCompanyLogoModalFooter = () => {
      if (this.isEditMode() && this.props.initialValues?.id) {

        const uploadProps = {
          name: 'file',
          action: getCompanyLogoUrl(this.props.initialValues?.id),
          headers: {'x-auth-token': this.props.authToken},
          accept: "image/*",
          beforeUpload: (file) => {
            if (!file) return false
            const fileSizeMB = ((file.size / 1024) / 1024).toFixed(4)
            if (fileSizeMB > 2) {
              message.error('Cannot update image bigger then 2MB');
              return false;
            }
          },
          fileList: [],
          onChange: (info) => {

            if (!info.file.status) return

            if (info.file.status === 'error') {
              message.error(`${info.file.name} file upload failed.`);
              return
            }

            this.handleCompanyLogoPreview(info.file)
            message.success(`File uploaded successfully`);

          }
        }

        return <Upload {...uploadProps}>
          <button className={'btn btn-primary'}><UploadOutlined/></button>
        </Upload>
      } else if (this.isCreateMode()) {
        return <div>{__('createEntityBeforeLogo')}</div>
      } else {
        return null;
      }


    }

    const renderPanelFooter = () => {
      return (
        <div className="group">

          <button className="btn btn-default pull-left" onClick={() => {
            hashHistory.goBack();
          }}>
            <Glyphicon glyph="chevron-left"/>{__('Back')}
          </button>

          {(this.props.mode !== 'create' && entity.status !== 'deleted') && this.props.canDelete &&
            <button className="btn btn-danger"
                    disabled={entity.status === 'disabled'}
                    onClick={() => this.confirmAction.bind(this)('delete')}>
              {__('Delete')}
            </button>
          }
          {(this.props.mode !== 'create' && entity.status === 'deleted') && this.props.canDelete &&
            <button className="btn btn-success" disabled={entity.status === 'enabled'}
                    onClick={() => this.confirmAction.bind(this)('reopen')}>
              {__('Reopen')}
            </button>
          }

          {this.props.mode !== 'view' && this.props.canUpdate &&
            <button className={'btn pull-right '.concat(this.props.mode === 'create' ? 'btn-success' : 'btn-primary')}
                    disabled={isLoading}
                    size="large" onClick={this.handleSubmit.bind(this)}>
              <Glyphicon glyph={(this.props.mode === 'edit') ? 'save' : 'plus-sign'}/>
              {(this.props.mode === 'edit') ? __('Save Changes') : __('Create')}
            </button>
          }

          {this.props.mode == 'view' && this.props.canUpdate &&
            <Link
              to={`/${this.entityType.name === 'User Group' ? 'userGroup' : this.entityType.name.toLowerCase()}s/${entity.id}/edit`}
              className={'btn pull-right '.concat(this.props.mode === 'create' ? 'btn-success' : 'btn-primary')}
              size="large"
            >
              <Glyphicon glyph="pencil"/> Edit {this.entityType.name}
            </Link>
          }

        </div>

      );
    };

    const listEntities = (entities) => {
      let options = entities.map((entity) => {
        return <option key={entity.id} value={entity.id}>{entity.name}</option>;
      });

      //if(this.props.mode === 'create')
      return [<option key={'000'} value="">{__('Select one')}</option>].concat(options);

      //return options;

    };

    const listAvatarTypes = () => {

      const {formValues} = this.props

      const options = []
      options.push(<option key={INITIAL_AVATAR_TYPE} value={INITIAL_AVATAR_TYPE}>Initials</option>)
      options.push(<option key={CUSTOM_PICTURE_AVATAR_TYPE} value={CUSTOM_PICTURE_AVATAR_TYPE}>Upload Picture </option>)
      if (formValues.contactEmail != null) {
        options.push(<option key={GRAVATAR_AVATAR_TYPE} value={GRAVATAR_AVATAR_TYPE}>Gravatar</option>)
      }


      return options

    }


    const uploadAvatarUrlProps = {
      name: 'file',
      action: getCompanyLogoUrl(this.props.initialValues.id),
      headers: {'x-auth-token': this.props.authToken},
      accept: "image/*",
      fileList: [],
      beforeUpload: async (file) => {
        if (!file) return false
        const maxFileSizeMB = 2
        const fileSizeMB = ((file.size / 1024) / 1024).toFixed(4)
        if (fileSizeMB > maxFileSizeMB) {
          message.error('Cannot update image bigger then 2MB');
          return false;
        }
      },
      onChange: async (info) => {

        if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
          return
        }

        this.setState({userAvatarSrc: await getBase64(new Blob([info.file.originFileObj || info.file], {type: "image/jpeg;"}))})

        this.props.change(ENTITY_FORM_NAME, AVATAR_URL_FIELD_NAME, getCompanyLogoUrl(this.props.initialValues.id))
        this.props.change(ENTITY_FORM_NAME, AVATAR_TYPE_FIELD_NAME, CUSTOM_PICTURE_AVATAR_TYPE)

      }
    }

    return (
      <div>
        <Panel>
          <Panel.Heading>
            <PanelHeadingInner>{__(this.entityType.name + ' Details')}
              {this.props.entity &&
                <Space>
                  <Link
                    to={`/${this.props.entityType === 'organisation' ? 'organisations' : 'userGroups'}/${this.props.entity.id}/calendar`}>
                    <Tooltip title={__('calendar')}>
                      <div className="btn-no-padding btn-std">
                        <Icon className="icon-std" type="calendar" theme="outlined"/>
                      </div>
                    </Tooltip>
                  </Link>
                  {this.props.canManageEntitySettings && this.props.entity?.accessRightsInfo?.edit &&
                    <div>
                      <Link
                        to={`/${this.props.entityType === 'organisation' ? 'organisations' : 'userGroups'}/${this.props.entity.id}/settings`}>
                        <Tooltip title={__('settings')}>
                          <div className="btn-no-padding btn-std">
                            <Icon className="icon-std" type="setting" theme="outlined"/>
                          </div>
                        </Tooltip>
                      </Link>
                    </div>
                  }
                </Space>
              }
            </PanelHeadingInner>
          </Panel.Heading>
          <Panel.Body>
            <form className="form-horizontal user-form">

              <div className="col-md-1">

                <EntityAvatarForm customPictureSrcImage={this.state.userAvatarSrc}
                                  gravatarMail={this.props.formValues.contactEmail}
                                  formName={ENTITY_FORM_NAME}
                                  avatarName={this.props.initialValues?.shortName?.charAt(0)}/>

              </div>
              <div className="col-md-5">

                <Field
                  name="shortName"
                  disabled={this.props.mode === 'view'}
                  component={renderField}
                  label={__('Short Name')}
                  required
                  className="form-control"
                  type="text"
                  inputCol="col-sm-9"
                  labelCol="col-sm-3"
                />

                <Field
                  name="parentId"
                  component={renderField}
                  disabled={this.props.mode === "view" || (this.props.entity && (typeof this.props.entity.parent === 'undefined'))}
                  label={__('Parent')}
                  required
                  className="form-control"
                  items={listEntities.bind(this)(this.props.entities)}
                  type="select"
                  inputCol="col-sm-9"
                  labelCol="col-sm-3"
                />

                <Field
                  name="name"
                  disabled={this.props.mode === 'view'}
                  component={renderField}
                  label={`${__(this.entityType.name + ' Name')}`}
                  required
                  className="form-control"
                  type="text"
                  inputCol="col-sm-9"
                  labelCol="col-sm-3"
                />


                {
                  this.props.mode === 'edit' &&
                  <div>
                    <Field
                      name={AVATAR_TYPE_FIELD_NAME}
                      component={renderField}
                      label={`${__('Avatar Type')}`}
                      className="form-control"
                      items={listAvatarTypes()}
                      disabled={(this.props.mode === 'view')}
                      type="select"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3">
                    </Field>
                    {this.props.mode === 'edit' && this.props.formValues?.avatar?.avatarType === CUSTOM_PICTURE_AVATAR_TYPE ?
                      <div className={"form-group clearfix"}>
                        <label className={'col-sm-3 control-label'}/>
                        <div className={'col-sm-9 text-danger-wrapper'}>
                          <Tooltip title={__("Upload")}>
                            <Upload {...uploadAvatarUrlProps}>
                              <div className={'btn btn-primary'}><UploadOutlined/></div>
                            </Upload>
                          </Tooltip>
                        </div>
                      </div> : null}
                  </div>
                }

                {
                  this.props.mode === 'view'
                  && (
                    <div className="form-group">
                      <label className="col-sm-3 control-label">{`${__("Order types")}`}</label>
                      <div className="col-sm-9">
                        <OrderTypesROField orderTypes={this.props.entity.orderTypes}/>
                      </div>
                    </div>
                  )
                }

                {!this.isUserGroup &&
                  <div>
                    <Field
                      name="streetName"
                      disabled={this.props.mode === 'view'}
                      component={renderField}
                      label={__('Street Name')}
                      className="form-control"
                      type="text"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3"
                    />

                    <Field
                      name="streetNumber"
                      disabled={this.props.mode === 'view'}
                      component={renderField}
                      label={__('Street No')}
                      className="form-control"
                      type="text"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3"
                    />

                    <Field
                      name="suffix"
                      disabled={this.props.mode === 'view'}
                      component={renderField}
                      label={__('Suffix')}
                      className="form-control"
                      type="text"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3"
                    />

                    <Field
                      name="poBox"
                      disabled={this.props.mode === 'view'}
                      component={renderField}
                      label={__('PO-Box')}
                      className="form-control"
                      type="text"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3"
                    />

                    <Field
                      name="zip"
                      disabled={this.props.mode === 'view'}
                      component={renderField}
                      label={__('ZIP')}
                      className="form-control"
                      type="text"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3"
                    />

                    <Field
                      name="city"
                      disabled={this.props.mode === 'view'}
                      component={renderField}
                      label={__('City')}
                      className="form-control"
                      type="text"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3"
                    />

                  </div>
                }

                <div className="form-group">
                  <label htmlFor="inputName" className="col-sm-3 control-label">{__('Enabled')}</label>
                  <div className="col-sm-9 checkbox">
                    <Field disabled={this.props.mode === 'view'} name="status" component="input" type="checkbox"
                           inputCol="col-sm-9"
                           labelCol="col-sm-3"/>
                  </div>
                </div>

                {this.props.mode != 'create' && <div className="form-group">
                  <label className="col-sm-3 control-label">
                    {__('Users')}
                  </label>
                  <div className="col-sm-9">
                    <button onClick={this.showUsers} className="btn btn-link">
                      {__(`Show ${this.entityType.name} Users`)}
                    </button>
                  </div>
                </div>}
              </div>

              <div className="col-md-6">

                {!this.isUserGroup &&
                  <div>
                    <Field
                      name="contactName"
                      disabled={this.props.mode === 'view'}
                      component={renderField}
                      label={__('Contact Name')}
                      className="form-control"
                      type="text"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3"
                    />

                    <Field
                      name="contactEmail"
                      disabled={this.props.mode === 'view'}
                      component={renderField}
                      label={__('Contact eMail')}
                      className="form-control"
                      type="text"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3"
                    />

                    <Field
                      name="contactPhone"
                      disabled={this.props.mode === 'view'}
                      component={renderField}
                      label={__('Contact Phone') + '*'}
                      className="form-control"
                      type="text"
                      inputCol="col-sm-9"
                      labelCol="col-sm-3"
                    />

                  </div>
                }

                {this.props.mode != 'create' &&
                  <div>

                    <div className="form-group">
                      <label htmlFor="inputName" className="col-sm-3 control-label">{__('Created by')}</label>
                      <div className="col-sm-9">
                        <p className="form-control-static">
                          <Link to={'/users/' + this.props.entity.creatorId}>
                            {this.props.entity.creator.firstName + ' ' + this.props.entity.creator.lastName}
                          </Link>
                        </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputName" className="col-sm-3 control-label">{__('creationDateTime')}</label>
                      <div className="col-sm-9">
                        <p
                          className="form-control-static">{moment(this.props.entity.creationDatetime).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat)}</p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputName" className="col-sm-3 control-label">{__('LastModifiedDatetime')}</label>
                      <div className="col-sm-9">
                        <p
                          className="form-control-static">{this.props.entity.lastUpdateDatetime && moment(this.props.entity.lastUpdateDatetime).format(config.appDefaults.dateFormat + ' ' + config.appDefaults.timeFormat)}</p>
                      </div>
                    </div>

                    {entity.nestedParams &&
                      <div className="form-group">
                        <label htmlFor="inputName" className="col-sm-3 control-label">{__('Summary')}</label>
                        <div className="col-sm-9">
                          <p className="form-control-static">
                            {__('Users')} {entity.nestedParams.users}<br/>
                            {__('User Groups')} {entity.nestedParams.user_groups}<br/>
                            {__('Organisations')} {entity.nestedParams.organisations}<br/>
                          </p>
                        </div>
                      </div>
                    }

                  </div>
                }

              </div>

            </form>
            <div className="col-sm-12">
              <div className="mandatory-text">
                <i>*{__('mandatory fields')}</i>
              </div>
            </div>


          </Panel.Body>
          <Panel.Footer>{renderPanelFooter()}</Panel.Footer>
        </Panel>

        <Modal
          backdrop="static"
          show={this.state.modal.open}
          onHide={this.closeModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">
              {__(this.state.modal.action)} {this.entityType.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{__('Are you sure you want to')} {__(this.state.modal.action)} "<strong>{this.state.modal.entity.name}</strong>"?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="group ">
              <button onClick={this.handleConfirmation.bind(this)}
                      className={'capitalize btn pull-left'.concat((this.state.modal.action === 'delete') ? 'btn-danger' : 'btn-success dark')}
              >
                {__(this.state.modal.action)}
              </button>
              <button className="btn pull-right btn-default" onClick={this.closeModal.bind(this)}>
                {__('Back')}
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          backdrop="static"
          show={this.state.usersModal.open}
          onHide={this.closeModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">{__(`${this.entityType.name} Users`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.listUsers()}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModal.bind(this)}>
              {__('Close')}
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }


}

const PanelHeadingInner = styled.div`
  display: flex;
  justify-content: space-between;
`

const EntityFormConnected = connect(
  (state) => {
    return {
      authToken: state.user.authToken,
      formValues: state.form.entityForm.values
    }
  },
  {change}
)(EntityForm)

export default reduxForm({
  form: ENTITY_FORM_NAME,  // a unique identifier for this form
  validate,
  deleteEntity,
  reopenEntity
})(EntityFormConnected);
