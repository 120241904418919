import React, {useCallback, useContext, useEffect, useReducer} from 'react'

import * as A from "./actions";
import {
  initState,
  getInitialState,
  ConfigurationConfiguratorReducer
} from './reducer'

export const ConfigurationConfiguratorContext = React.createContext(null)

export const useConfigurationConfiguratorContext = () => useContext(ConfigurationConfiguratorContext);

export default ( props) => {

  const initialState = getInitialState()
  const {
    children,
    type,
    mode,
    id,
    apis,
  } = props
  const [state, dispatch] = useReducer(ConfigurationConfiguratorReducer, initialState, initState)

  useEffect(() => {
    const initAttributes = async () => {
      return {
        apis,
        mode: mode,
        configurations: id && await apis.fetchConfiguration(id),
        tree: await apis.fetchAttributeTree(),
        systems: await apis.fetchRemoteSystems(),
        valueTypesMap: await apis.fetchValueTypesMappings(),
        entities: await apis.fetchEntities(),
        publishedFlowsLastVersion: await apis.fetchPublishedFlowsLastVersion(),
      }
    }
    A.initConfigurator({ mode, type }, dispatch)
    initAttributes().then((data) => A.initializeAction(data, dispatch))
  }, [])

  const updateEnumerations = useCallback((ids) => {
    const idsToFetch = ids?.filter((id) => id && !state.enumerations?.[id])
    if (!idsToFetch?.length) return
    apis?.fetchEnumValuesByIds?.(ids).then((resp) => {
      A.updateEnumerations(resp, dispatch)
    })
  }, [apis, state])

  const editStepConfigurationAttributes = (attributesValues) => A.editStepConfigurationAttributesAction(attributesValues, dispatch)
  const editStepConfigurationAttribute = (attributeId, attributeValues, step) => A.editStepConfigurationAttributeAction({ attributeValues, attributeId, step }, dispatch)
  const editStepConfiguration = (stepValues, step) => A.editStepConfigurationAction({ stepValues, step }, dispatch)
  const setActiveStep = (step) => A.setActiveStepAction(step, dispatch)
  const shiftActiveStep = (jump) => A.shiftActiveStepAction(jump, dispatch)
  const setStepValidationVisibility = (visible) => A.setValidationVisibilityAction(visible, dispatch)

  return (
    <ConfigurationConfiguratorContext.Provider
      value={{
        state,
        dispatch,
        setActiveStep,
        shiftActiveStep,
        editStepConfiguration,
        editStepConfigurationAttribute,
        editStepConfigurationAttributes,
        setStepValidationVisibility,
        updateEnumerations
      }}
    >
      {children}
    </ConfigurationConfiguratorContext.Provider>

  )
}
