import * as config from "../constants/globalConfiguration";

const os = require('os');

const apiUrl = config.getApiUrl();
const API_VERSION = config.API_VERSION;

function escapeStringWindows(str) {
  return "\"" + str.replace(/"/g, "\"\"")
    .replace(/%/g, "\"%\"")
    .replace(/\\/g, "\\\\")
    .replace(/[\r\n]+/g, "\"^$&\"") + "\"";
}

function escapeStringPosix(str) {
  function escapeCharacter(x) {
    let code = x.charCodeAt(0);
    if ( code < 256 ) {
      // Add leading zero when needed to not care about the next character.
      return code < 16 ? "\\x0" + code.toString(16) : "\\x" + code.toString(16);
    }
    code = code.toString(16);
    return "\\u" + ("0000" + code).substr(code.length, 4);
  }
  
  if ( /[^\x20-\x7E]|'/.test(str) ) {
    // Use ANSI-C quoting syntax.
    return "$'" + str.replace(/\\/g, "\\\\")
      .replace(/'/g, "\\'")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[^\x20-\x7E]/g, escapeCharacter) + "'";
  } else {
    // Use single quote syntax.
    return "'" + str + "'";
  }
}


function getCurlFromRequest(request) {
  
  const platform = os.platform().startsWith('win') ? 'win' : 'posix';
  const escapeString = platform === 'win' ? escapeStringWindows : escapeStringPosix;
  
  let command = ['curl'];
  const ignoredHeaders = [];
  const data = [];
  const requestHeaders = request.headers || {};
  const requestBody = request.body && (JSON.stringify(JSON.parse(request.body)) || '');
  const contentType = requestHeaders['content-type'];
  
  const url = apiUrl + request.url.replace(`/${API_VERSION}`, '')
  
  if ( requestBody !== '' ) {
    ignoredHeaders.push('content-length');
    
    if ( contentType && contentType.startsWith('application/x-www-form-urlencoded') ) {
      data.push('--data');
    } else {
      data.push('--data-binary');
    }
    
    data.push(escapeString(requestBody));
  }
  
  command.push('-X');
  command.push(request.method);
  command.push(url);
  
  Object.keys(requestHeaders)
    .filter(name => ignoredHeaders.indexOf(name) === -1)
    .forEach(function (name) {
      command.push('-H');
      command.push(escapeString(name.replace(/^:/, '') + ': ' + requestHeaders[name]));
    });
  
  
  command = command.concat(data);
  
  return command.join(' ');
}

export default getCurlFromRequest;
