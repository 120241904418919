import * as VIEW_TYPE from "./viewType"
import * as UT from "./../utils"
import * as MAPPER from "./../mapper"
import {DISPLAY_ATTRIBUTES, SEARCH_ATTRIBUTES, SORT_ATTRIBUTES} from "../utils/requiredFields"

// Action Fetch Data and save the result in an object in initial state
export const initializeConfigurator = (state, actionPayload) => {
  if (!actionPayload.configurationType) {
    throw Error("Cannot initialize Configuration Configuration without a configuration type, Please contact administrator")
  }


  const configurationToEdit = actionPayload.configurationToEdit || {}

  const configurationFormFields = MAPPER.mapConfigurationToFormFields(configurationToEdit)
  const stateConfiguration = UT.manageStateConfigurationOnType(actionPayload.configurationType, configurationFormFields)

  return {
    ...state,
    stateConfiguration: {
      configurationType: actionPayload.configurationType,
      ...stateConfiguration,
    },
    editingForms: {
      configurationFormFields: configurationFormFields,
    },
    needFetch: false,
    viewType: VIEW_TYPE.DISPLAY,
  }
}

export const fetchError = (state) => {
  return {
    ...state,
    needFetch: false,
    viewType: VIEW_TYPE.ERROR,
    error: {
      exist: true,
      message: "Cannot connect to server specified in configuration, Please contact Administrator",
    },
  }
}

export const updateConfiguration = (state, actionPayload) => {
  if (!state.editingForms.configurationFormFields) {
    throw Error("An Order configurator MUST exist in order to be updated")
  }

  if (!actionPayload.changedFields) {
    throw Error("Cannot Update configuration without fields to change")
  }

  UT.manageConditionalFormFields(state.editingForms.configurationFormFields, actionPayload.changedFields)
  const configurationFormFields = {...state.editingForms.configurationFormFields, ...MAPPER.updateFormFields(actionPayload.changedFields)}
  const stateConfiguration = UT.manageStateConfigurationOnType(state.stateConfiguration.configurationType, configurationFormFields)

  return {
    ...state,
    editingForms: {
      configurationFormFields: configurationFormFields,
    },
    stateConfiguration: {
      ...state.stateConfiguration,
      ...stateConfiguration,
    },
  }
}

export function updateAttributes(state, actionPayload) {
  if (!state.editingForms.configurationFormFields) {
    throw Error("An Order configurator MUST exist in order to be updated")
  }

  if (
    actionPayload.attributeType == null ||
    ![DISPLAY_ATTRIBUTES, SEARCH_ATTRIBUTES, SORT_ATTRIBUTES].includes(actionPayload.attributeType)
  ) {
    throw Error("Cannot update this kind of attributes. Please contact administrator")
  }

  if (!actionPayload.attributeKeys) {
    throw Error("Cannot Update attributes without fields to change")
  }

  const configurationFormFields = {
    ...UT.filerObjectByKey(state.editingForms.configurationFormFields, (key) => !key.startsWith(actionPayload.attributeType)),
    ...MAPPER.updateFormFields(actionPayload.attributeKeys),
  }

  return {
    ...state,
    editingForms: {
      configurationFormFields: configurationFormFields,
    },
  }
}
