import styled from "styled-components";

import { Col, Row } from "antd";

export const RelatedContentContainer = styled(Row)`
  flex: 1;
  overflow: hidden;
`

export const Column = styled(Col)`
  height: 100%;
`

export const RelatedItemsCol = styled(Column)`
  padding: 2rem;
  overflow: hidden scroll;
  &>div:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const AnchorsContainer = styled.div`
  padding: 2rem;
  height: inherit;
  overflow: scroll;
  background-color: #fcfcff;
`
