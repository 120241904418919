import { Icon } from "@ant-design/compatible";
import { Button, Modal } from "antd";
import React, { useCallback, useState } from "react";

import { __ } from '../../../utils/translationUtils'


export default (props) => {

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(props.isDeleteModalVisible)
  const [isExitEditModalVisible, setIsExitEditModalVisible] = useState(props.isExitEditModalVisible)
  const setIsMainObjectOpen = props.setIsMainObjectOpen
  const { isLoading, cancelEdit, deleteObject, editObject, updateObject, action, scrollToRelatedOrders } = props

  const showDeleteModal = useCallback(() => {
    setIsDeleteModalVisible(true);
  }, [])

  const showExitEditModal = useCallback(() => {
    setIsExitEditModalVisible(true);
  }, [])

  const handleOkDelete = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDeleteModalVisible(false);
    deleteObject();
  }, [deleteObject])

  const handleOkExitEdit = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsExitEditModalVisible(false);
    cancelEdit();
  }, [cancelEdit])

  const handleEditButton = useCallback((event) => {
    editObject();
    event.stopPropagation()
  }, [editObject])

  const handleCancelDelete = useCallback(() => {
    setIsDeleteModalVisible(false);
  }, [])

  const closeCancelEditModal = useCallback(() => {
    setIsExitEditModalVisible(false);
  }, [])

  const handleGoToRelatedOrders = useCallback((event) => {
    scrollToRelatedOrders();
    setIsMainObjectOpen(false);
    event.stopPropagation()
  }, [scrollToRelatedOrders, setIsMainObjectOpen])

  // todo: HAS_ROE_READ_ACCES is not used
  const {
    HAS_ROE_EDIT_ACCESS = false,
    CAN_UPDATE_REMOTE_OBJECT_ITEM = false,
    CAN_DELETE_REMOTE_OBJECT_ITEM = false,
  } = props.permissions || {};

  return (
    <div className="action-buttons-group">
      {action === "view" && (
        <Button
          ghost
          className="go-to-related-orders"
          disabled={isLoading}
          onClick={handleGoToRelatedOrders}
        >
          {__("Go To Related Orders")}
        </Button>
      )}

      <div className="right-action-buttons-group">
        {action === "view" && HAS_ROE_EDIT_ACCESS && CAN_DELETE_REMOTE_OBJECT_ITEM && (
          <>
            <Button
              ghost
              className="right-button-outline"
              disabled={isLoading}
              onClick={(event) => { showDeleteModal(); event.stopPropagation(); }}>
              <Icon
                className="icon-std right-button-icon"
                type="delete"
                theme="outlined" />
            </Button>
            <Modal
              title="Delete Object"
              visible={isDeleteModalVisible}
              className="default-modal"
              onOk={handleOkDelete}
              onCancel={handleCancelDelete}>
              {__("Are you sure you want to delete this object?")}
            </Modal>
          </>
        )}

        {action === "view" && HAS_ROE_EDIT_ACCESS && (
          <Button
            ghost
            className="right-button-outline"
            disabled={isLoading}
            onClick={handleEditButton}>
            <Icon
              className="icon-std right-button-icon"
              type="edit"
              theme="outlined"
            />
          </Button>
        )}

        {action === "edit" && CAN_UPDATE_REMOTE_OBJECT_ITEM && (
          <Button
            className="btn save-changes-button right-btn"
            disabled={isLoading}
            onClick={(event) => { updateObject(); event.stopPropagation() }}>
            {__("Save Changes")}
          </Button>
        )}
        {action === "edit" && (
          <>
            <Button
              className="btn right-btn cancel-button"
              disabled={isLoading}
              onClick={(event) => { showExitEditModal(); event.stopPropagation(); }}>
              {__("Cancel")}
            </Button>
            <Modal
              title="Exit Edit Mode"
              visible={isExitEditModalVisible}
              onOk={handleOkExitEdit}
              onCancel={closeCancelEditModal}>
              {__("Are you sure you want to exit edit mode?")}
            </Modal>
          </>
        )}
      </div>

    </div>
  )
}
