import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { bindActionCreators } from "redux";

import { renderField } from "../../../../utils/fieldValidator";
import {
  checkUserRightsAll,
} from "../../../../utils/appHelper";
import ROEFormGrid from './ResponsiveGrid'
import { appDefaults } from "../../../../constants/globalConfiguration";
import { getEnumValues } from "../../../../providers/ReduxProvider/actions/reportActions";

import EllipsisText from "../../../Ellipsis";
import { consolidateDeprecatedSubObjectsData, consolidateSubObjectsData } from "../../utlis";
import RelatedContent from "./RelatedContent";
import { MainObjectContainer, ROEItemContainer, ROEItemTitle } from "./styles";
import { __ } from '../../../../utils/translationUtils'

const { CAN_ADD_RO_RELATION, CAN_REMOVE_RO_RELATION } = global.constants.rightMappings

export const formName = "editRemoteObjectItemForm";

const RemoteObjectItemForm = (props) => {
  const {
    viewDisplayAttributes,
    attributesConfigurationTree,
    updateObject,
    attributes,
    enumValues,
    isMainObjectOpen,
    system,
    dependencyId,
    action: prevAction,
    isCreatingObject = false,
    remoteObjectConfiguration,
    remoteObjectInstance
  } = props

  const dispatch = useDispatch()
  const { rights } = useSelector((state) => state?.user?.data || {})
  const [action, setAction] = useState(props?.action)
  const [fullSelectedAttributes, setFullSelectedAttributes] = useState(null)
  const [remoteObjectDefinition, setRemoteObjectDefinition] = useState(null)
  const [highlightedSubObjectId, setHighlightedSubObjectId] = useState(null)

  const [permissions, setPermissions] = useState(null)

  useEffect(() => {
    if (rights) setPermissions(checkUserRightsAll(rights, { ADDABLE: CAN_ADD_RO_RELATION, REMOVABLE: CAN_REMOVE_RO_RELATION }))
  }, [rights])

  useEffect(() => {
    let definition = _.find(attributesConfigurationTree[system], (item) => item?.objectTypeId === parseInt(dependencyId))
    if (definition) {
      setRemoteObjectDefinition(definition)
      const selectedAttributes = remoteObjectConfiguration?.content?.displayAttributes || viewDisplayAttributes
      const validAttributeIds = selectedAttributes?.map((a) => a.attributeId) || []
      const td = {
        ...(definition || {}),
        data: [...(definition?.data.filter((a) => validAttributeIds.includes(a.id)))],
      };
      setFullSelectedAttributes(td);
    }
  }, [attributesConfigurationTree, dependencyId, remoteObjectDefinition, system, viewDisplayAttributes, remoteObjectConfiguration])

  useEffect(() => {
    if (prevAction !== action) {
      setAction(prevAction)
    }
  }, [action, prevAction]);

  const renderLine = useCallback((attr) => {
    if ((prevAction === "create" && attr.type === "BINARY") || attr.type === "IDS") {
      return (
        <ROEItemContainer>
          <ROEItemTitle>
            <EllipsisText>
              {__(attr.propertyLabel)}
            </EllipsisText>
          </ROEItemTitle>
          <div>
            <label style={{ color: '#00000040', fontStyle: 'italic' }}>{__('Unsupported field')}</label>
          </div>
        </ROEItemContainer>
      )
    }

    if (attr.enumerationId && enumValues && !enumValues[attr.enumerationId]) {
      //TODO check if this is necessary
      dispatch(getEnumValues(attr.enumerationId));
    }

    return (
      <ROEItemContainer>
        <ROEItemTitle>
          <EllipsisText>
            {__(attr.propertyLabel)}
          </EllipsisText>
        </ROEItemTitle>
        <Field
          key={`remoteobject-property-${attr.id}`}
          formName={formName}
          name={attr.id}
          id={attr.id}
          updateObject={updateObject}
          component={renderField}
          disabled={!attr.editable}
          type={attr.controllerType}
          placeholder={"-"}
          dateFormat={appDefaults.dateFormat}
          items={
            enumValues && attr.enumerationId ? renderSelectOptions(enumValues[attr.enumerationId], !attr.editable) : []
          }
        />
      </ROEItemContainer>
    );
  }, [action, attributes, dispatch, enumValues, prevAction, updateObject])

  const renderSelectOptions = (options, readOnly) => {
    const _opts = options &&
      options.map((opt) => (
        <option key={`date-format-${opt.translationKey}`} value={opt.value}>
          {__(opt.translationKey) || __(opt.value)}
        </option>
      ));
    return [
      <option key="verion-000" value="">
        {readOnly ? "" : __("Select one")}
      </option>,
    ].concat(_opts);
  }

  const onSubObjectHighlight = (id) => id && setHighlightedSubObjectId(id)

  const targetData = useMemo(() => {
    if (!fullSelectedAttributes) return
    const displayAttributes = remoteObjectConfiguration?.content?.displayAttributes ||
      remoteObjectConfiguration?.displayAttributes || [] // TODO remove this when all the objects are migrated!

    return {
      ...fullSelectedAttributes,
      data: displayAttributes?.some((item) => item?.gridPosition) ?
        consolidateSubObjectsData({
          attributes: fullSelectedAttributes?.data,
          displayAttributes: displayAttributes,
          action
        }) :
        consolidateDeprecatedSubObjectsData({
          attributes: fullSelectedAttributes?.data,
          displayAttributes: displayAttributes,
          action
        }),
      children: fullSelectedAttributes.children.filter((item) => (item.data?.length))
    }
  }, [action, fullSelectedAttributes, remoteObjectConfiguration])


  const gridItems = useMemo(() => (
    targetData?.data?.reduce((prev, item) => {
      const { x, y, w, h } = item.gridPosition || {}
      if (x && y && w && h) {
        prev.push({
          id: `cell-${item.id}`,
          type: item.type,
          cellProperties: {
            ...item.gridPosition,
            x: parseInt(x),
            y: parseInt(y),
            w: parseInt(w),
            h: parseInt(h),
            i: item.attributeId
          },
          children: renderLine?.(item) || item.value,
        })
      }
      return prev
    }, []) || []
  ), [targetData, renderLine])

  const subObjectsData = useMemo(() => {
    const { relations } = remoteObjectConfiguration?.content || {}

    return relations?.map((r) => ({ attr: { ...attributes?.[r.attributeId], elementId: `related-element-${r.attributeId}` }, relation: r })) || []
  }, [remoteObjectConfiguration])

  return (
    <form className="remote-object-form">
      <MainObjectContainer
        activeKey={isMainObjectOpen ? ['ROEEditNode'] : []}
        ghost
      >
        <Collapse.Panel key="ROEEditNode">
          {
            !(remoteObjectDefinition && gridItems) ? "Node not found" : (
              <ROEFormGrid items={gridItems} />)
          }
        </Collapse.Panel>
      </MainObjectContainer>
      <RelatedContent
        subObjectsData={subObjectsData}
        targetData={targetData}
        attributes={attributes}
        updateObject={updateObject}
        remoteObjectDefinition={remoteObjectDefinition}
        remoteObjectInstance={remoteObjectInstance}
        isCreatingObject={isCreatingObject}
        highlightedSubObjectId={highlightedSubObjectId}
        onSubObjectHighlight={onSubObjectHighlight}
        permissions={permissions}
      />
    </form>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
}

export default reduxForm(
  {
    form: formName
  },
  mapDispatchToProps
)(RemoteObjectItemForm);
