import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getFormValues, change } from 'redux-form'

import BillingStepForm from '../BillingStepForm'
import {debounce} from "lodash";
export const DISCOUNT_TYPES = {
  Percentage: 'Percentage',
  Quantity: 'Quantity',
  Price: 'Price',
  None: 'None'
}
class BillingController extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    controlData: PropTypes.object,
    change: PropTypes.func,
    formValues: PropTypes.object
  };
  static defaultProps = { onChange: null };

  constructor(props) {
    super(props)
    const source = JSON.parse(this.props.controlData?.source)

    const adaptedSource = this.adaptValuesToObject(source)
    this.state = {
      source: (this.props.controlData?.source) ? adaptedSource : [],
      readOnly: (this.props.controlData?.readOnly) ? this.props.controlData?.readOnly : false
    }

    this.initialValues = this.generateInitialValues(adaptedSource instanceof Array)
    this.calculateSummary = this.calculateSummary.bind(this)
    this.calculateRecap = this.calculateRecap.bind(this)
  }

  updateFieldValue() {
    this.props.change('stepForm', this.props.controlData?.id, JSON.stringify(this.state.source))
  }

  generateInitialValues(isCompatibilityMode) {
    const { source } = this.state
    const values = {}

    if (!(source?.priceCategories instanceof Array)) {
      return undefined
    }
    if (!isCompatibilityMode) {
      if (!source?.additionalDiscountType) {
        source.additionalDiscountType = DISCOUNT_TYPES.Percentage
      }

      values[`_discount`] = parseFloat(source.discount || 0).toFixed(2)
      values['_additionalDiscountEnabled'] = parseFloat(source.discount || 0).toFixed(2) !== "0.00"
      values['_additionalDiscountType'] = source.additionalDiscountType || DISCOUNT_TYPES.Percentage
      values[`_totalBeforeDiscount`] = parseFloat(source.totalBeforeDiscount || 0).toFixed(2)
      values[`_totalAfterDiscount`] = parseFloat(source.totalAfterDiscount || 0).toFixed(2)
      values[`_totalIncludingVat`] = parseFloat(source.totalIncludingVat || 0).toFixed(2)
    }
    source.priceCategories.forEach((category) => {
      category.billingItems.forEach((item) => {
        values[`${item.priceElementID}_quantity`] = item.quantity || 0
        values[`${item.priceElementID}_unitPrice`] = parseFloat(item.unitPrice || 0).toFixed(2)
        values[`${item.priceElementID}_comment`] = item.comment || ''
        if (!isCompatibilityMode) {
          values[`_currency`] = item.priceElementCurrency
          values[`${item.priceElementID}_discountType`] = item.discountType === DISCOUNT_TYPES.None ? DISCOUNT_TYPES.Percentage : item.discountType || DISCOUNT_TYPES.Percentage
          values[`${item.priceElementID}_discount`] = item.discount || 0
          values[`${item.priceElementID}_VAT`] = item.vat || 0
        }
      })

      category.subCategories.forEach((cat) => {
        cat.billingItems.forEach((item) => {
          values[`${item.priceElementID}_quantity`] = item.quantity || 0
          values[`${item.priceElementID}_unitPrice`] = parseFloat(item.unitPrice || 0).toFixed(2)
          values[`${item.priceElementID}_comment`] = item.comment || ''
          if (!isCompatibilityMode) {
            values[`_currency`] = item.priceElementCurrency
            values[`${item.priceElementID}_discount`] = item.discount || 0
            values[`${item.priceElementID}_VAT`] = item.vat || 0
            values[`${item.priceElementID}_discountType`] = item.discountType === DISCOUNT_TYPES.None ? DISCOUNT_TYPES.Percentage : item.discountType || DISCOUNT_TYPES.Percentage
          }
        })

        cat.subCategories.forEach((cat) => {
          cat.billingItems.forEach((item) => {
            values[`${item.priceElementID}_quantity`] = item.quantity || 0
            values[`${item.priceElementID}_unitPrice`] = parseFloat(item.unitPrice || 0).toFixed(2)
            values[`${item.priceElementID}_comment`] = item.comment || ''
            if (!isCompatibilityMode) {
              values[`_currency`] = item.priceElementCurrency
              values[`${item.priceElementID}_discount`] = item.discount || 0
              values[`${item.priceElementID}_VAT`] = item.vat || 0
              values[`${item.priceElementID}_discountType`] = item.discountType === DISCOUNT_TYPES.None ? DISCOUNT_TYPES.Percentage : item.discountType || DISCOUNT_TYPES.Percentage
            }
          })

          cat.subCategories.forEach((cat) => {
            cat.billingItems.forEach((item) => {
              values[`${item.priceElementID}_quantity`] = item.quantity || 0
              values[`${item.priceElementID}_unitPrice`] = parseFloat(item.unitPrice || 0).toFixed(2)
              values[`${item.priceElementID}_comment`] = item.comment || ''
              values[`_currency`] = item.priceElementCurrency
              if (!isCompatibilityMode) {
                values[`${item.priceElementID}_discount`] = item.discount || 0
                values[`${item.priceElementID}_VAT`] = item.vat || 0
                values[`${item.priceElementID}_discountType`] = item.discountType === DISCOUNT_TYPES.None ? DISCOUNT_TYPES.Percentage : item.discountType || DISCOUNT_TYPES.Percentage
              }
            })
          })
        })
      })
    })

    return values
  }
  calculateRecap() {
    const { source } = this.state
    const { formValues } = this.props
    let recap = {};
    if (source && !source.isCompatibilityMode) {
      let vatSummary = this.calculateSummary()[2]
      let totalBeforeDiscount = this.calculateSummary()[0]
      if (formValues && formValues["_discount"] >= 0) {

        recap.discount = formValues["_discount"]
        recap.additionalDiscountType = formValues["_additionalDiscountType"]
        switch (formValues["_additionalDiscountType"]) {
          case DISCOUNT_TYPES.Percentage: {
            recap.totalAfterDiscount = totalBeforeDiscount - (totalBeforeDiscount * recap.discount / 100) || 0
            recap.vatTotal = vatSummary - (vatSummary * recap.discount / 100) || 0
            break;
          }
          case DISCOUNT_TYPES.Price: {
            recap.totalAfterDiscount = totalBeforeDiscount - recap.discount || 0
            recap.vatTotal = vatSummary || 0
            break;
          }
          default: {
            recap.totalAfterDiscount = totalBeforeDiscount || 0
            recap.vatTotal = vatSummary || 0
            break;
          }
        }
      }
      recap.totalIncludingVat = recap.totalAfterDiscount + recap.vatTotal || 0
    }
    return recap
  }
  calculateSummary() {
    const { source } = this.state
    const { formValues } = this.props
    let summary = 0
    let vatSummary = 0
    const subSummaries = []

    if (!formValues) {
      return 0
    }
    const calculateItemSummary = (item, category) => {
      const { formValues } = this.props
      switch (formValues[`${item.priceElementID}_discountType`]) {
        case DISCOUNT_TYPES.Percentage: {
          if (!item.priceElementDetailSignum || item.priceElementDetailSignum === 'DEBIT') {
            summary += (formValues[`${item.priceElementID}_quantity`] * item.unitPrice) - (formValues[`${item.priceElementID}_quantity`] * formValues[`${item.priceElementID}_discount`] / 100 * item.unitPrice)
            vatSummary += (formValues[`${item.priceElementID}_quantity`] * item.unitPrice * item.vat / 100) - (formValues[`${item.priceElementID}_quantity`] * item.unitPrice * item.vat / 100 * formValues[`${item.priceElementID}_discount`] / 100)
            subSummaries[category.categoryName] = (subSummaries[category.categoryName] || 0) + ((formValues[`${item.priceElementID}_quantity`] * item.unitPrice) - (formValues[`${item.priceElementID}_quantity`] * formValues[`${item.priceElementID}_discount`] / 100 * item.unitPrice))
          }
          if (item.priceElementDetailSignum === 'CREDIT') {
            summary = summary - (formValues[`${item.priceElementID}_quantity`] * item.unitPrice) - (formValues[`${item.priceElementID}_quantity`] * formValues[`${item.priceElementID}_discount`] / 100 * item.unitPrice)
            vatSummary -= (formValues[`${item.priceElementID}_quantity`] * item.unitPrice * item.vat / 100) - (formValues[`${item.priceElementID}_quantity`] * formValues[`${item.priceElementID}_discount`] / 100 * item.unitPrice)
            subSummaries[category.categoryName] = (subSummaries[category.categoryName] || 0) - ((formValues[`${item.priceElementID}_quantity`] * item.unitPrice) - (formValues[`${item.priceElementID}_quantity`] * item.unitPrice / 100 * formValues[`${item.priceElementID}_discount`]))
          }
          break;
        }
        case DISCOUNT_TYPES.Price: {
          if (!item.priceElementDetailSignum || item.priceElementDetailSignum === 'DEBIT') {
            summary += formValues[`${item.priceElementID}_quantity`] * (item.unitPrice) - formValues[`${item.priceElementID}_discount`]
            vatSummary += (formValues[`${item.priceElementID}_quantity`] * (item.unitPrice - formValues[`${item.priceElementID}_discount`]) * item.vat / 100)
            subSummaries[category.categoryName] = (subSummaries[category.categoryName] || 0) + (formValues[`${item.priceElementID}_quantity`] * item.unitPrice - formValues[`${item.priceElementID}_discount`])
          }
          if (item.priceElementDetailSignum === 'CREDIT') {
            summary = summary - (formValues[`${item.priceElementID}_quantity`] * item.unitPrice) - formValues[`${item.priceElementID}_discount`]
            vatSummary -= (formValues[`${item.priceElementID}_quantity`] * item.unitPrice * item.vat / 100) - (formValues[`${item.priceElementID}_quantity`] - formValues[`${item.priceElementID}_discount`] * item.unitPrice)
            subSummaries[category.categoryName] = subSummaries[category.categoryName] || 0 - (formValues[`${item.priceElementID}_quantity`] * item.unitPrice) - formValues[`${item.priceElementID}_discount`]
          }
          break;
        }
        case DISCOUNT_TYPES.Quantity: {
          if (!item.priceElementDetailSignum || item.priceElementDetailSignum === 'DEBIT') {
            summary += ((formValues[`${item.priceElementID}_quantity`] - formValues[`${item.priceElementID}_discount`]) * item.unitPrice)
            vatSummary += ((formValues[`${item.priceElementID}_quantity`] - formValues[`${item.priceElementID}_discount`]) * item.unitPrice * item.vat / 100)
            subSummaries[category.categoryName] = (subSummaries[category.categoryName] || 0) + (((formValues[`${item.priceElementID}_quantity`] - formValues[`${item.priceElementID}_discount`]) * item.unitPrice))
          }
          if (item.priceElementDetailSignum === 'CREDIT') {
            summary = summary - ((formValues[`${item.priceElementID}_quantity`] - formValues[`${item.priceElementID}_discount`]) * item.unitPrice)
            vatSummary -= ((formValues[`${item.priceElementID}_quantity`] - formValues[`${item.priceElementID}_discount`]) * item.unitPrice * item.vat / 100) - ((formValues[`${item.priceElementID}_quantity`] - formValues[`${item.priceElementID}_discount`]) * item.unitPrice)
            subSummaries[category.categoryName] = (subSummaries[category.categoryName] || 0) - ((formValues[`${item.priceElementID}_quantity`] * item.unitPrice) - ((formValues[`${item.priceElementID}_quantity`] - formValues[`${item.priceElementID}_discount`]) * item.unitPrice))
          }
          break;
        }
        default: {
          if (!item.priceElementDetailSignum || item.priceElementDetailSignum === 'DEBIT') {
            summary += (formValues[`${item.priceElementID}_quantity`] * item.unitPrice)
            vatSummary += (formValues[`${item.priceElementID}_quantity`] * item.unitPrice * item.vat / 100)
            subSummaries[category.categoryName] = (subSummaries[category.categoryName] || 0) + (formValues[`${item.priceElementID}_quantity`] * item.unitPrice)
          }
          if (item.priceElementDetailSignum === 'CREDIT') {
            summary = summary - (formValues[`${item.priceElementID}_quantity`] * item.unitPrice)
            vatSummary -= (formValues[`${item.priceElementID}_quantity`] * item.unitPrice * item.vat / 100)
            subSummaries[category.categoryName] = (subSummaries[category.categoryName] || 0) - (formValues[`${item.priceElementID}_quantity`] * item.unitPrice)
          }
          break;
        }
      }
    }
    const calculateCategorySummary = (parent, child) => {
      subSummaries[parent.categoryName] = (subSummaries[parent.categoryName] || 0) + (subSummaries[child.categoryName] || 0)
    }

    // TODO: Add debt/credit check.
    source.priceCategories.forEach((category) => {
      category.billingItems.forEach((item) => {
        calculateItemSummary(item, category)
      })
      category.subCategories.forEach((cat1) => {
        cat1.billingItems.forEach((item) => {
          calculateItemSummary(item, cat1)
        })
        cat1.subCategories.forEach((cat2) => {
          cat2.billingItems.forEach((item) => {
            calculateItemSummary(item, cat2)
          })
          cat2.subCategories.forEach((cat3) => {
            cat3.billingItems.forEach((item) => {
              calculateItemSummary(item, cat3)
            })
            cat3.subCategories.forEach((cat4) => {
              cat4.billingItems.forEach((item) => {
                calculateItemSummary(item, cat4)
              })
              calculateCategorySummary(cat3, cat4)
            })
            calculateCategorySummary(cat2, cat3)
          })
          calculateCategorySummary(cat1, cat2)
        })
        calculateCategorySummary(category, cat1)
      })
    })
    return [summary, subSummaries, vatSummary]
  }
  updateRecapForm() {
    const { source } = this.state
    const recap = this.calculateRecap();
    this.setState({
      source: {
        ...source || {},
        discount: recap.discount,
        totalBeforeDiscount: this.calculateSummary()[0],
        totalAfterDiscount: recap.totalAfterDiscount,
        vatTotal: recap.vatTotal,
        totalIncludingVat: recap.totalIncludingVat,
        additionalDiscountType: recap.additionalDiscountType
      }
    })
  }
  adaptValuesToObject(source) {
    let sourceObj = {}
    if (Array.isArray(source)) {
      sourceObj = {};
      sourceObj.priceCategories = source;
      sourceObj.discount = 0;
      sourceObj.totalBeforeDiscount = 0
      sourceObj.totalAfterDiscount = 0
      sourceObj.totalIncludingVat = 0
      sourceObj.totalAfterVat = 0
      sourceObj.vatTotal = 0
      sourceObj.isCompatibilityMode = true
    } else {
      sourceObj = { ...source }
      sourceObj.isCompatibilityMode = false
    }
    return sourceObj
  }

  debouncedHandleChange = debounce(() => {
    const { formValues, onChange } = this.props

    let { source, readOnly } = this.state
    const _source = { ...source || {} }
    const priceCategories = [].concat(_source.priceCategories)
    priceCategories.forEach((category) => {
      category.billingItems.forEach((item) => {
        item.quantity = formValues[`${item.priceElementID}_quantity`]
        item.comment = formValues[`${item.priceElementID}_comment`]
        item.unitPrice = formValues[`${item.priceElementID}_unitPrice`]
        if (!source.isCompatibilityMode) {
          item.discountType = formValues[`${item.priceElementID}_discountType`]
          item.discount = formValues[`${item.priceElementID}_discount`]
          item.vat = formValues[`${item.priceElementID}_VAT`]
          item.vatTotal = item.unitPrice * formValues[`${item.priceElementID}_quantity`] * item.vat / 100
        }
      })

      category.subCategories.forEach((cat) => {
        cat.billingItems.forEach((item) => {
          item.quantity = formValues[`${item.priceElementID}_quantity`]
          item.comment = formValues[`${item.priceElementID}_comment`]
          item.unitPrice = formValues[`${item.priceElementID}_unitPrice`]
          if (!source.isCompatibilityMode) {
            item.discount = formValues[`${item.priceElementID}_discount`]
            item.discountType = formValues[`${item.priceElementID}_discountType`]
            item.vat = formValues[`${item.priceElementID}_VAT`]
            item.vatTotal = item.unitPrice * formValues[`${item.priceElementID}_quantity`] * item.vat / 100
          }
        })

        cat.subCategories.forEach((cat) => {
          cat.billingItems.forEach((item) => {
            item.quantity = formValues[`${item.priceElementID}_quantity`]
            item.comment = formValues[`${item.priceElementID}_comment`]
            item.unitPrice = formValues[`${item.priceElementID}_unitPrice`]
            if (!source.isCompatibilityMode) {
              item.discountType = formValues[`${item.priceElementID}_discountType`]
              item.discount = formValues[`${item.priceElementID}_discount`]
              item.vat = formValues[`${item.priceElementID}_VAT`]
              item.vatTotal = item.unitPrice * formValues[`${item.priceElementID}_quantity`] * item.vat / 100
            }
          })
          cat.subCategories.forEach((cat) => {
            cat.billingItems.forEach((item) => {
              item.quantity = formValues[`${item.priceElementID}_quantity`]
              item.comment = formValues[`${item.priceElementID}_comment`]
              item.unitPrice = formValues[`${item.priceElementID}_unitPrice`]
              if (!source.isCompatibilityMode) {
                item.discountType = formValues[`${item.priceElementID}_discountType`]
                item.discount = formValues[`${item.priceElementID}_discount`]
                item.vat = formValues[`${item.priceElementID}_VAT`]
                item.vatTotal = item.unitPrice * formValues[`${item.priceElementID}_quantity`] * item.vat / 100
              }
            })

            cat.subCategories.forEach((cat) => {
              cat.billingItems.forEach((item) => {
                item.quantity = formValues[`${item.priceElementID}_quantity`]
                item.comment = formValues[`${item.priceElementID}_comment`]
                item.unitPrice = formValues[`${item.priceElementID}_unitPrice`]
                if (!source.isCompatibilityMode) {
                  item.discountType = formValues[`${item.priceElementID}_discountType`]
                  item.discount = formValues[`${item.priceElementID}_discount`]
                  item.vat = formValues[`${item.priceElementID}_VAT`]
                  item.vatTotal = item.unitPrice * formValues[`${item.priceElementID}_quantity`] * item.vat / 100
                }
              })
            })
          })
        })
      })
    })

    onChange?.(_source)

    this.updateRecapForm()
    this.setState({ readOnly }, () => { this.updateFieldValue() })
  }, 1000);

  handleChange = () => {
    const { formValues } = this.props;
    if (!formValues) return;
    this.debouncedHandleChange();
  };


  render() {
    let { source, readOnly } = this.state
    const { controlData } = this.props
    return (
      <div className="form-group col-xs-12" style={{ float: 'none' }}>
        <BillingStepForm
          form={this.props.form}
          extendedProperties={controlData.attributes && controlData.attributes.extendedProperties ? controlData.attributes.extendedProperties : []}
          onChange={this.handleChange.bind(this)}
          readOnly={readOnly}
          source={source}
          summary={this.calculateSummary()[0]}
          recap={this.calculateRecap()}
          subSummaries={this.calculateSummary()[1] || []}
          initialValues={this.initialValues}
        />
      </div>
    )
  }
}

export default connect((state, { form }) => ({ formValues: getFormValues(form)(state) }),
  { change }
)(BillingController)
