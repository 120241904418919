import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { change, Field } from 'redux-form';

import StepObjectNode from '../StepObjectNode';
import AddStepObjectButton from '../../components/AddStepObjectButton';
import EditStepObjectButton from '../../components/EditStepObjectButton';
import { showMessage } from '../../utils/appHelper';
import * as config from '../../constants/globalConfiguration';
import { renderField, stepFieldRequired } from "../../utils/fieldValidator";
import { api } from '../../providers/ApiProvider'
import { Dropdown, Tooltip, Menu, Button } from 'antd'
import { Icon } from '@ant-design/compatible'
import EyeOutlined from "@ant-design/icons/es/icons/EyeOutlined";
import { hashHistory } from "../../providers/HistoryProvider";
import { __ } from '../../utils/translationUtils'

class ObjectListingControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      error: false,
      selectedObjectIds: props.controlData.source && props.controlData.source.split(',') || [],
      configuration: null,
      attributesConfiguration: [],
      attributesConfigurationTree: null,
    };
    this.getAttributesConfigurationTree = this.getAttributesConfigurationTree.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.handleAddItems = this.handleAddItems.bind(this);
    this.renderStepObject = this.renderStepObject.bind(this);
    this.updateReduxForm = this.updateReduxForm.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.init = this.init.bind(this);
    this.getValues = this.getValues.bind(this);
    this.stepFieldValidator = this.stepFieldValidator.bind(this);
  }

  componentDidMount() {
    this.getAttributesConfigurationTree();
    this.init();
  }

  getViewName() {
    const { objectTypeId, controlData: { attributes: { customOptions } }, type } = this.props;
    const url = type && !['ROE', 'CreateOrder'].includes(type) ? 'configurations/stepObjects/' : 'configurations/remoteObjects/'
    if (!customOptions) {
      return new Promise(resolve => {
        resolve(api.get(`${url}?sort=name&pageNumber=1&pageSize=200&status=disabled,enabled,reserved`)
          .then(res => {
            const objectType_Id = objectTypeId || this.props.controlData?.attributes?.objectBinderConfiguration?.objectTypeId
            if (Array.isArray(res.data) && res.data.length) {
              const found = _.find(res.data, (c) => c.objectTypeId == objectType_Id);
              if (found) {
                return found.name;
              }
              console.error("View for " + objectType_Id + " not found.")
            }
          }));
      });
    } else {
      return new Promise((resolve) => {
        return resolve(customOptions[0]);
      });
    }
  }

  init() {
    const { objectTypeId, controlData: { source, controllerOptions }, type } = this.props;
    //TODO: Fix this. Configuration should come from the controller options only.
    if (this.props.controlData?.attributes?.objectBinderConfiguration || this.props.controlData?.attributes?.remoteObjectBinderConfiguration) {
      this.setState({
        configuration: {
          addableEntries: true,
          editableEntries: true,
          removableEntries: true,
          ...(this.props.controlData?.attributes?.objectBinderConfiguration || this.props.controlData?.attributes?.remoteObjectBinderConfiguration)
        }
      }, () => {
        if (source && source !== '') {
          this.fetchData(source.split(','));
        }
      })

      return
    }
    if (controllerOptions?.objectConfiguration) {
      this.setState({
        configuration: controllerOptions.objectConfiguration,
      });

      // get remote object data for existing primaryKeys found on source.
      if (source && source !== '') {
        this.fetchData(source.split(','), controllerOptions.objectConfiguration);
      }
      return
    }
    this.getViewName().then(
      viewName => {
        if (!viewName) {
          console.error("Unable to find view for object type id " + objectTypeId)
          return;
        }

        const url = type && !['ROE'].includes(type) ? 'configurations/stepObjects/' : 'configurations/remoteObjects/'
        // get configuration
        api.get(`${url}?q=${viewName}`)
          .then(
            response => {
              if (response.data.length === 0) {
                showMessage('error', `Configuration for '${viewName}' not found.`);
                this.setState({
                  error: true,
                });
              }

              // get displayAttributes
              api.get(`${url}${response.data[0].id}`)
                .then(
                  res => {
                    // Save configuration
                    this.setState({
                      configuration: res.data,
                    });

                    // get remote object data for existing primaryKeys found on source.
                    if (source && source !== '') {
                      this.fetchData(source.split(','));
                    }
                  }
                );
            }
          )
      }
    )
  }

  getValues(value, attributesToBeDisplayed) {
    if (value?.displayData && attributesToBeDisplayed) {
      const obj = value.displayData;

      return attributesToBeDisplayed.map((da) => {
        return (obj)[da?.id] || null;
      });
    }

    return [];
  }

  fetchData(ids, config) {
    const promises = [];
    const { configuration } = this.state;
    const { type, controlData } = this.props;

    const url = type && !['ROE'].includes(type) ? 'configurations/stepObjects/' : 'configurations/remoteObjects/'

    let overviewConfiguration = configuration || controlData?.attributes?.objectBinderConfiguration || config

    if (!overviewConfiguration) return

    const defaultFetchRemoteItems = (ids) => {
      ids.forEach(primaryKey => {
        promises.push(
          new Promise(resolve => api.get(`${url}${overviewConfiguration.objectTypeId}/${primaryKey.trim()}`)
            .then(res => {
              resolve(res.data);
            })
          ));
      })
      return Promise.all(promises)
    }

    const fetchRemoteItems = this.props.apis?.fetchRemoteItems || defaultFetchRemoteItems
    fetchRemoteItems(ids.filter(id => id), { objectTypeId: configuration.objectTypeId }).then(results => {
      let values = { ...this.state.values };
      results.forEach((result) => {
        values[result[0].id] = result[0];
      });
      this.setState({ values });
    });
  }

  onUpdate(mainKey) {
    this.fetchData([mainKey]);
  }

  updateReduxForm(_selectedObjectIds) {
    const { type, overviewTabEditField, change, controlData } = this.props
    if (overviewTabEditField) {
      change('overviewTabEditForm', controlData.id, _selectedObjectIds.join(','))
    }
    if (type === 'ROE') {
      change('editRemoteObjectItemForm', controlData.id, _selectedObjectIds.join(','))
      return
    }

    if (type === 'CreateOrder') {
      change('createOrderForm', controlData.id, _selectedObjectIds[0])
      return;
    }

    change('stepForm', controlData.id, _selectedObjectIds.join(','))
  }

  handleAddItems(mainKeys) {
    let _selectedObjectIds;
    const { singleRow } = this.props
    if (singleRow) {
      _selectedObjectIds = mainKeys
    } else {
      _selectedObjectIds = mainKeys.concat(this.state.selectedObjectIds)
    }

    this.setState({
      selectedObjectIds: _selectedObjectIds,
    }, () => {
      this.updateReduxForm(_selectedObjectIds);
      if (mainKeys) {
        this.fetchData(mainKeys);
      }
    });
  }

  removeItem(mainKey) {
    let _selectedObjectIds = [].concat(this.state.selectedObjectIds);
    _.remove(_selectedObjectIds, (mk) => mk === mainKey);
    this.setState({
      selectedObjectIds: _selectedObjectIds,
    }, this.updateReduxForm(_selectedObjectIds));
  }

  renderStepObject(label, type, value, key, mainKey) {
    //console.log("V: "+ value + " --> "+ type);
    if (value || value === false) {
      if (type === "YESNO" && (value === true || value.toLowerCase() === "yes" || value.toLowerCase() === "true")) {
        value = __('val_yes');
      }
      if (type === "YESNO" && (value === false || value.toLowerCase() === "no" || value.toLowerCase() === "false")) {
        value = __('val_no');
      }
      // Check if date
      if (type === "DATE") {
        value = moment(value).format(config.appDefaults.dateTimeFormat);
      }
      // Check if ListBox
      if (type === "PopupListBox") {
        value = __(value);
      }
    }
    let input = {}
    input.value = value
    input.name = key
    input.container = 'ObjectListing';
    return (<Field
      input={input}
      component={renderField}
      label={label}
      className="form-control"
      labelCol={'col-sm-9 col-md-6 col-lg-6'}
      type={type}
      name={`StepObj-${mainKey}-${key}`}
      key={`StepObj-${mainKey}-${key}`}
      disabled={true}
    />);
  }


  getAttributesConfigurationTree() {
    return api.get('configurations/attributes/tree')
      .then(
        response => {
          this.setState({
            attributesConfigurationTree: response.data,
          });
        }
      );
  }


  stepFieldValidator(value) {

    const { controlData } = this.props;

    let patt = new RegExp(controlData.regex.js);

    // Validate value
    if (value && !patt.test(value)) { // Vaule is valid.
      return __('valueFormatError');
    } else { // Value is not valid.
      return undefined;
    }

  }

  stepValidation() {
    const { controlData } = this.props;
    let validation = [];

    if (controlData.required) {
      validation.push(stepFieldRequired);
    }

    // Check if field needs to be validated (check if regex exist)
    if (typeof controlData.regex !== 'undefined'
      && typeof controlData.regex.js !== 'undefined'
      && controlData.regex.js !== '') {
      validation.push(this.stepFieldValidator);
    }

    if (validation.length == 0) {
      return undefined;
    }

    return validation;
  }


  render() {
    const { controlData, enumValues, additionalStyle, type, views, singleRow, apis } = this.props;
    let { readOnly, attributes } = this.props;
    const objectConfiguration = controlData.objectConfiguration
    const {
      values,
      configuration,
      attributesConfigurationTree,
      selectedObjectIds,
      error,
    } = this.state;
    // In Object Binder the configuration is in the controlData
    let overviewConfiguration = configuration || controlData?.attributes?.objectBinderConfiguration

    if (readOnly === undefined && controlData.readOnly !== undefined) {
      readOnly = controlData.readOnly
      // TODO: Remove this ugly WDC5 fix
      readOnly = false
    }
    const req = controlData.required ? '*' : '';
    const system = attributesConfigurationTree && overviewConfiguration && attributesConfigurationTree[overviewConfiguration.systemId];
    const node = _.find(system, (nodeItem) => {
      return nodeItem.objectTypeId === overviewConfiguration.objectTypeId;
    });

    let summaryDisplayAttributes = [];
    let summaryAttributes = [];
    let attributesUsedInDisplay = [];
    if (configuration && configuration.displayAttributes) {

      summaryAttributes = configuration.displayAttributes
        .filter(displayAttribute => displayAttribute.summary)
        .map(att => att.attributeId).map(id => attributes[id]);

      summaryDisplayAttributes = overviewConfiguration.displayAttributes
        .filter(displayAttribute => displayAttribute.summary);

      attributesUsedInDisplay = configuration.displayAttributes
        .map(att => att.attributeId).map(id => attributes[id]);
      if (!summaryAttributes || summaryAttributes.length < 1) {
        return (<div>No summary values configured. Please check the configuration or ask your administrator.</div>)
      }
    }

    const externalObjectViewsMenu = (mainKey) => (
      <Menu>
        {
          views?.map(view => (
            <Menu.Item>
              <a onClick={() => hashHistory.push(`/remoteObjects/${view.id}/${this.state?.values[mainKey]?.id}`)}>
                {__(`${view.name}`)}
              </a>
            </Menu.Item>
          ))
        }
      </Menu>
    );

    return (
      <div className={`form-group label-control object-listing ${additionalStyle || ''}`}>
        <div className={"col-sm-12 object-listing__list  top-bar"}>
          <Field
            name={controlData.id || Math.floor(Math.random() * 1000).toString()}
            component={renderField}
            inputCol={type == "ROE" ? "col-sm-12 col-md-6 col-lg-12" : null}
            label={controlData.title ? __(controlData.title) + req : ''}
            description={controlData.description}
            className="form-control"
            labelCol={' '}
            type="hidden"
            items={[selectedObjectIds]}
            validate={this.stepValidation.bind(this)()}
          />
        </div>
        <div>
          {(type === 'ROE' || type === 'CreateOrder' ? !readOnly : objectConfiguration ? objectConfiguration.addableResults : !readOnly && overviewConfiguration && overviewConfiguration.addableEntries) &&
            <div className="col-sm-12">
              <AddStepObjectButton
                configuration={overviewConfiguration}
                handleAddItems={this.handleAddItems}
                selectedObjectIds={selectedObjectIds}
                initialValues={{}}
                attributes={attributesUsedInDisplay}
                values={[]}
                type={type}
                singleRow={singleRow}
                apis={apis}
              />
            </div>
          }
        </div>
        <div className="col-sm-12 object-listing__list">
          {(configuration && attributes)
            ?
            <div key={overviewConfiguration.id}>
              {
                selectedObjectIds.length === 0 && (
                  <div className="discreet">
                    <i>{__('objectListEmpty')}</i>
                  </div>
                )
              }
              {
                selectedObjectIds.filter(mk => mk).map((mainKey) => {
                  const current = values?.[mainKey]
                  const summaryValues = this.getValues(current, summaryAttributes);

                  let fullValues = [];
                  if (!readOnly && configuration.editableEntries) {
                    fullValues = this.getValues(current, attributesUsedInDisplay);
                  }

                  return (
                    <StepObjectNode
                      key={`object-${mainKey}`}
                      mainKey={mainKey}
                      node={node}
                      renderStepObject={this.renderStepObject}
                      attributes={summaryAttributes}
                      displayAttributes={summaryDisplayAttributes}
                      values={summaryValues}
                      maxColumns={(type !== 'ROE' && type !== 'CreateOrder') ? 2 : 3}
                      buttons={
                        <span style={{ display: 'flex', float: 'right' }}>
                          {(type === 'ROE' || type === 'CreateOrder' ? !readOnly : objectConfiguration ? objectConfiguration.editableResults : !readOnly && overviewConfiguration.editableEntries) &&
                            <EditStepObjectButton
                              key={`edit-button-${mainKey}`}
                              onUpdate={this.onUpdate}
                              configuration={overviewConfiguration}
                              node={node}
                              overviewObjectId={mainKey}
                              resultId={mainKey} //{values && values[mainKey] && values[mainKey].id}
                              enumValues={enumValues}
                              initialValues={values && values[mainKey] && values[mainKey].displayData || {}}
                              system={configuration.systemId}
                              dependencyId={configuration.objectTypeId}
                              attributes={attributesUsedInDisplay}
                              values={fullValues}
                              apis={apis}
                            />
                          }
                          {' '}
                          {(type === 'ROE' || type === 'CreateOrder' ? !readOnly : objectConfiguration ? objectConfiguration.removableResults : !readOnly && overviewConfiguration.removableEntries) &&
                            <Tooltip title={__('remove')}>
                              <div className="btn-no-padding btn-std" onClick={(e) => {
                                e.preventDefault();
                                this.removeItem(mainKey);
                              }}><Icon className="icon-delete" type="close-circle" theme="outlined" /></div>
                            </Tooltip>

                          }
                          {' '}
                          {(type === 'ROE' && Array.isArray(views) && views?.length > 0 && readOnly) &&
                            <Tooltip title={__('See views')}>
                              <Dropdown overlay={externalObjectViewsMenu(mainKey)} placement="bottomLeft">
                                <Button shape="circle" icon={<EyeOutlined />} />
                              </Dropdown>
                            </Tooltip>

                          }
                        </span>
                      }
                    />
                  );
                })
              }
            </div>
            : <div>{!error ? __('loading') : __('Could not load configuration')}</div>
          }
        </div>
      </div>
    );
  }

}
ObjectListingControl.propTypes = {
  controlData: PropTypes.object.isRequired,
  change: PropTypes.func,
  readOnly: PropTypes.bool,
  additionalStyle: PropTypes.string,
  enumValues: PropTypes.object.isRequired,
  objectTypeId: PropTypes.string.isRequired,
  overviewTabEditField: PropTypes.bool,
  type: PropTypes.string,
  views: PropTypes.array,
  apis: PropTypes.object
}
ObjectListingControl.defaultProps = {
  type: 'StepObject'
}
export default connect(
  state => {
    return {
      enumValues: state.enumValues,
      attributes: state.ui.attributes,
    };
  },
  { change }
)(ObjectListingControl);
