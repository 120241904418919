import React from 'react';
import MessageAttachment from "./MessageAttachment";
import { showMessage} from "../../../utils/appHelper";
import { api } from '../../../providers/ApiProvider'

const MessageAttachmentContainer = props => {

  const { attachmentPaths, editModeOn, authToken, objectCaller, onChangeAttachmentPaths } = props;

  const handleChangeAttachmentPath = attachment => {

    const onChange = value => {

      const pathList = attachmentPaths.slice()

      // if no attachment it means  one you add
      if (!attachment && value) {
        pathList.push(value);
        onChangeAttachmentPaths(pathList);
      }

      // If there is an attachment
      if (attachment && !value) {

        // first delete the image from static files then delete it from the state
        api.delete(attachment)
          .then( () => {
            const idx = pathList.findIndex( path => path === attachment)

            if ( idx > -1 ){
              pathList.splice(idx, 1);
            }

            onChangeAttachmentPaths(pathList);

          })
          .catch( () => {

            showMessage('error','Impossible to delete message for static file');

          });

      }

    }

    return onChange;

  }

  return(
    <ul className={'message-attachment-list'}>

      {attachmentPaths.map( ( attachment, index ) => {

        return(
          <MessageAttachment key={`${index} - ${attachment}`}
                             attachmentPath={attachment} onChangeAttachmentPath={handleChangeAttachmentPath(attachment)}
                             editModeOn={editModeOn} authToken={authToken} objectCaller={objectCaller}
          />
        )

      } )}
      <MessageAttachment attachmentPath={''} onChangeAttachmentPath={handleChangeAttachmentPath()}
                         editModeOn={editModeOn} authToken={authToken} objectCaller={objectCaller}
                         multiple={true}
      />
    </ul>
  )


}



export default MessageAttachmentContainer;
