import * as C from "./constants";
import { availableMessageTypes } from "./constants";
import { signUpFieldsDefaultData } from "./utils";
import { __, T } from '../../utils/translationUtils'

export const getRegistrationFields = ({ userRegistration }) => {
  const {
    selfRegistrationEnabled,
    defaultEntityIds,
    defaultRoles,
    allowedEmailDomains,
    emailVerification,
    onSuccessfulSignUp,
    successfulSignUpPageConfiguration,
    fields
  } = userRegistration || {}

  return [
    {
      name: [C.settingsFields.SELF_REGISTRATION],
      value: selfRegistrationEnabled,
    },
    {
      name: [C.settingsFields.USER_GROUPS],
      value: defaultEntityIds,
    },
    {
      name: [C.settingsFields.USER_ROLES],
      value: defaultRoles,
    },
    {
      name: [C.settingsFields.ALLOWED_EMAIL_DOMAINS],
      value: allowedEmailDomains?.map((v) => ({label:v, value: v})),
    },
    {
      name: [C.settingsFields.EMAIL_VERIFICATION],
      value: emailVerification,
    },
    {
      name: [C.settingsFields.ON_SUCCESSFUL_SIGNUP],
      value: onSuccessfulSignUp,
    },
    {
      name: [C.settingsFields.SUCCESSFUL_SIGNUP_CONFIGURATION],
      value: successfulSignUpPageConfiguration?.type
    },
    {
      name: [C.settingsFields.SIGN_UP_FIELDS],
      value: fields?.map((field) => {
        const defaultFields = signUpFieldsDefaultData[field.destination] || {}
        return {
          name: field.destination,
          label: field.label,
          index: field.position,
          required: {
            ...( defaultFields?.required ? { editable: defaultFields?.required?.editable } : { editable: true }),
            checked: field.required
          },
          enabled: {
            ...( defaultFields?.required ? { editable: defaultFields?.required?.editable } : { editable: true }),
            checked: field.enabled
          },
          field
        }
      })
    },
  ]
}

export const getSettingsPanels = () => ({
  userSelfRegistration: {
    panelKey: '1',
    title: __(T.userSelfRegistration),
    subtitle: __(T.userSelfRegistrationSub)
  },
  widgetSettings: {
    panelKey: '2',
    title: __(T.widgetSettings),
    subtitle: __(T.widgetSettingsSub)
  },
  dashboardsSettings: {
    panelKey: '3',
    title: __(T.dashboardsConfiguration),
    subtitle: __(T.dashboardsConfigurationSub),
  },
  instanceSettings: {
    panelKey: '4',
    title: __(T.instanceConfigurations),
    subtitle: __(T.instanceConfigurationsSub)
  }
})

export const successfulSignUpPageConfig = {
  [availableMessageTypes.CHECK_YOUR_EMAIL]: {
    type: availableMessageTypes.CHECK_YOUR_EMAIL,
    message: T.checkYourEmailMessage
  },
  [availableMessageTypes.GREETINGS]: {
    type: availableMessageTypes.GREETINGS,
    message: T.greetingsMessage
  }
}
