import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import FileUploaderService from '../services/fileUploaderService'
import ReducedFile from "../components/ReduceFile";
import {ERROR} from "components/dist/Utils/LoggerUtils";



FileListReduced.propTypes = {
  change: PropTypes.func,
  formName: PropTypes.string,
  field: PropTypes.shape({
    id: PropTypes.string,
    source: PropTypes.string,
    value: PropTypes.string
  }).isRequired

}

function FileListReduced({field, authToken}) {

  const [fileCollected, setFileCollected] = useState([])

  useEffect(() => {

    if (field?.source != null || field?.value != null) {
      let source = field?.source || field?.value
      try {
        source = JSON.parse(source)
      } catch (e) {
        ERROR('Cannot parse the source for the file' + e)
        return
      }

      const initialJsonPath = FileUploaderService.formatInitialJsonPath(source)
      setFileCollected(FileUploaderService.collectAllFiles(source, initialJsonPath))

    }
  }, [authToken, field])

  if (!fileCollected.length){
    return null
  }

  return (
    <ReducedFile field={field} filesCollected={fileCollected}/>
  )

}


export default FileListReduced
