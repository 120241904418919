import React from "react";
import { Button, Col, Row, Typography } from "antd";

import { __ } from '../../utils/translationUtils'

const { Title, Paragraph } = Typography;

export default (props) => {
  const { panelKey, panelOpen, setPanelOpen, title, subtitle } = props
  
  const openOrExpand = () => {
    if ( panelOpen?.includes(panelKey) ) {
      setPanelOpen(panelOpen.filter(e => e !== panelKey))
    } else {
      setPanelOpen(panelOpen.concat(panelKey))
    }
  }
  
  return (
    <React.Fragment>
      <Row
        onClick={event => event.stopPropagation()}
        justify="space-between"
        style={{ cursor: "default" }}
      >
        <Col span={22}>
          <Title level={4}>{title}</Title>
        </Col>
        <Col span={2} style={{}}>
          <Row justify="end" style={{ marginTop: 10 }}>
            <Button onClick={openOrExpand}>
              {panelOpen.includes(panelKey) ? __("Collapse") : __("Expand")}
            </Button>
          </Row>
        </Col>
      </Row>
      {
        (subtitle) &&
        <Row>
          <Col span={22}>
            <Paragraph ellipsis={{ rows: 2 }}>
              {subtitle}
            </Paragraph>
          </Col>
        </Row>
      }
    </React.Fragment>
  )
}
