import * as T from './types'
import * as U from "../utils"
import { defaultPagination } from "../../../../../UI/SmartTable/constants"

// ACTIONS

const errorAction = () => ({ type: T.ERROR })
const noOperationAction = () => ({ type: T.NO_OP })
const fetchingDataAction = (payload) => ({ type: T.FETCHING_DATA, payload })
const initializedAction = (payload) => ({ type: T.INITIALIZED, payload })
const updateEnumsAction = (payload) => ({ type: T.UPDATE_ENUMS, payload })
const updateRemoteObjectsAction = (payload) => ({ type: T.UPDATE_REMOTE_OBJECT_ITEMS, payload })
const updateValueAction = (payload) => ({ type: T.UPDATE_VALUE, payload })


// ASYNC ACTIONS

const fetchInitData = (payload) => (dispatch, getState) => {
  const { api, readOnly, onChange, missingConfigurationMessage, readFrom, writeTo } = payload || {}
  const fetch = async () => ({
    api,
    readOnly,
    onChange,
    missingConfigurationMessage: missingConfigurationMessage,
    attributesTree: await api.fetchAttributesTree(),
    attributesList: await api.fetchAttributesList(),
    readFrom,
    writeTo,
  })

  fetch()
    .then((data) => dispatch(initializedAction(data)))
    .catch(() => dispatch(errorAction()))
}

const fetchRemoteObjectsAsyncAction = (pagination) => (dispatch, getState) => {
  const { value, api, attributesList } = getState()
  const { selectedItems, objectConfiguration } = value || {}
  const { showOnlySelected } = objectConfiguration || {}

  dispatch(fetchingDataAction(true))

  const payload = U.getPayloadForDataFetch({
    searchValues: {},
    sortValues: [],
    currentPagination: pagination,
    dataStructures: { objectConfiguration, attributesList },
  })

  if (!selectedItems?.length && showOnlySelected)
    return dispatch(updateRemoteObjectsAction([]))

  api?.fetchTableData?.(payload, showOnlySelected ? selectedItems : []).then((res) => {
    dispatch(updateRemoteObjectsAction(res?.data))
  }).catch((err) => {
    console.log('err', err)
    dispatch(errorAction())
  }).finally(() => {
    dispatch(fetchingDataAction(false))
  })
}

const fetchEnumerationsAsyncAction = (ids) => (dispatch, getState) => {
  const { api, enumerations } = getState()
  const idsToFetch = ids?.filter((id) => !enumerations?.[id])
  if (!idsToFetch?.length) return dispatch(noOperationAction())

  api.fetchEnumValuesByIds(idsToFetch).then((payload) => {
    dispatch(updateEnumsAction(payload))
  })
}


// ACTION CREATORS

export const initialize = (payload) => {
  return fetchInitData(payload)
}

export const updateValue = (payload) => {
  if (!payload?.objectConfiguration) {
    return errorAction()
  }
  return updateValueAction(payload)
}

export const updateEnumerations = (ids) => {
  return fetchEnumerationsAsyncAction(ids)
}

export const getRemoteObjects = (pagination = defaultPagination) => {
  return fetchRemoteObjectsAsyncAction(pagination)
}
