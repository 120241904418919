import React from "react";
import {HashRouter, Link} from "react-router-dom";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {__} from "./translationUtils";


type UsersFieldProps = {
  users: UserOverview[] | undefined
}

export const UsersField = ({users}: UsersFieldProps) => {
  if (!users || !users?.length) return '---'

  const visibleUsers = users.filter(u => u)
  const unknownUsers = users.filter(u => !u)

  if (users.length === 1) {
    return (
      <div>
        {
          !!visibleUsers.length && (
            <Link style={{fontWeight: 'normal'}} to={`/users/${users[0].id}`}>
              { users[0].status === 'deleted' ? 'deleted_user' : users[0].username }
            </Link>
          )
        }
        {
          !!unknownUsers.length && (`${unknownUsers.length} ${__('unknown_users')}`)
        }
      </div>
    )
  }

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="top"
      overlay={
        <Popover id={`popover-trigger`}>
          <HashRouter>
            {
              !!visibleUsers.length && (
                <div>
                  {
                    visibleUsers.map((user, i) => {
                      return (
                        <span key={`${user.id}_${i}`}>
                    <Link to={`/users/${user.id}`}>
                      {
                        user?.status === 'deleted' ? __('deleted_user', 'capitalise') : user.username
                      }
                      {
                        i === visibleUsers.length - 1 ? '' : ', '
                      }
                  </Link>
                </span>
                      )
                    })
                  }
                </div>
              )
            }

              {
                !!unknownUsers.length && (
                  <div>
                    <hr/>
                    {`${unknownUsers.length} ${__('unknown_users')}`}
                  </div>
                )
              }
          </HashRouter>
        </Popover>
      }
    >
      <span className="user-popover-trigger">
        {users.length} {__('Users')}
      </span>
    </OverlayTrigger>
  )
}
