import React from 'react'

import { Icon } from '@ant-design/compatible'
import theme from '../../../providers/StyleProvider/theme'
import * as S from './styles'

export default ({ onClose, onExpand, size }) => {
  return (
    <S.CalendarMacbookStyleHandler>
      {onClose && (
        <S.IconWrapper color={theme.colors.red.primary} onClick={onClose} size={size}>
          <Icon type="close" />
        </S.IconWrapper>
      )}
      <S.IconWrapper color={theme.colors.secondary} onClick={onExpand} size={size}>
        <Icon type="arrows-alt" />
      </S.IconWrapper>
    </S.CalendarMacbookStyleHandler>
  )
}
