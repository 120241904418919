import React, {useState, useMemo, useCallback} from "react";

import ColumnsLayout from 'components/dist/ColumnsLayout'

import {useROEConfiguratorContext} from "../../context";
import Selector from './Selector'
import Configurator from './Configurator'
import * as U from '../../utils'
import * as C from '../../constants'
import {Select} from "antd";
import { __ } from '../../../../utils/translationUtils'

const getObjectAttributes = (object) => {
  if (!object) return []
  return [
    ...object.data,
    ...object.children.reduce((prev, subObject) => {
      return [...prev, ...getObjectAttributes(subObject)]
    }, [])
  ]
}

const getGridItemsInitialState = (configurations, activeStep, attributesMap) => {
  return Object.keys(configurations[activeStep]).reduce((prev, attributeId) => {
    const attribute = attributesMap[attributeId]
    if (attribute?.type !== 'IDS') prev.push({
      ...attribute,
      cellProperties: configurations[activeStep][attributeId]
    })
    return prev
  }, [])
}

export default () => {

  const { state } = useROEConfiguratorContext()
  const {
    configurations,
    activeStep,
    attributesMap,
    attributesTree,
    systems,
    system,
    object
  } = state

  const attributes = useMemo(() => {
    const objectData = attributesTree.find((s) => s.name === system.id).data.find((o) => o.label === object.label)
    const objectAttributes = getObjectAttributes(objectData)
    const prevSelectedAttributes = Object.keys(configurations[C.steps.CONTENT_CHOOSE_FIELDS])
    return objectAttributes.filter((attribute) => prevSelectedAttributes.includes(attribute.id))
  }, [attributesTree, system, object, configurations])

  const [gridItems, setGridItems] = useState(getGridItemsInitialState(configurations, activeStep, attributesMap))
  const [attributesToSelect, setAttributesToSelect] = useState(attributes.filter((attribute) => (!Object.keys(configurations[activeStep]).includes(attribute.id)) ))
  const [defaultCellSize, setDefaultCellSize] = useState(3)

  const placeAllFields = () => {
    const occupiedCells = U.getGridOccupiedCells(gridItems)
    const filledGrid = [
      ...gridItems,
      ...U.getGridItemsFromFilledGrid(attributesToSelect, occupiedCells, { w: defaultCellSize, h:1}, C.gridConfiguration.cols),
    ]
    setGridItems(filledGrid)
    setAttributesToSelect([])
  }

  const onRemoveAll = useCallback(() => {
    setAttributesToSelect(attributes)
    setGridItems([])
  }, [attributes])

  return (
    <ColumnsLayout
      title={__('grid_drag_and_drop_info')}
      extra={(
        <div style={{ lineHeight: '30px', fontSize: '14px'}}>
          {`${__('default_size')}:`}
          <Select
            style={{ minWidth: '30px'}}
            bordered={false}
            onChange={setDefaultCellSize}
            value={defaultCellSize}
            options={C.defaultCellSizeOptions}
          />
        </div>
      )}
      widths={[1, 3]}
      left={{
        span: 6,
        children: (
          <Selector
            attributesToSelect={attributesToSelect}
            placeAllFields={placeAllFields}
            systems={systems}
          />
        )
      }}
      right={{
        span: 18,
        children: (
          <Configurator
            attributes={attributes}
            setAttributesToSelect={setAttributesToSelect}
            gridItems={gridItems}
            onRemoveAllCallback={onRemoveAll}
            setGridItems={setGridItems}
            cellDimension={{ w: defaultCellSize, h: 1, }}
            gridCols={C.gridConfiguration.cols}
          />
        )
      }}
    />
  )
}
