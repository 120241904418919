import React from "react";
import { Link } from "react-router-dom";

import {
  createStepRemoteItem,
  editStepRemoteItem,
  fetchEnumValuesByIds,
  fetchStepRemoteObject,
  fetchStepRemoteItemsFromIds,
  fetchStepRemoteTableData,
  fetchStepDefaultConfiguration,
} from "../../../../../../utils/appHelper";
import NoData from "../../../../../UI/NoData";
import { __ } from '../../../../../../utils/translationUtils'
import { addRelations, removeRelations, fetchAttributes, fetchAttributesTree} from "../../../../../../providers/ApiProvider";
import {defaultAvailableControllers} from "components/dist/FormBuilder/Config/defaults";
import {downloadFile} from "../../../customRender/FileUploader/utils/IO";


export const getFieldsCustomProps = (fields, commonProps) => {
  if (!fields) return {}
  return fields.reduce((prev, field) => {
    prev[field.id] = getFieldCustomProps(field, commonProps)
    return prev
  }, {})
}

const getFieldCustomProps = (field, commonProps) => {
  if (field?.controllerOptions?.objectConfiguration) {
    return objectSelectorCustomProps(field, commonProps)
  }

  if (field?.controllerType === defaultAvailableControllers.Signature.controllerType) {
    return {
      download: (url) => downloadFile(url, commonProps.token)
    }
  }
  return null
}

const MissingConfigurationMessage = () => (
  <NoData
    title={__("You have not configured any views for this object")}
    subTitle={<Link to="/remoteObjects/create">{__('create')}</Link>}
    size={'large'}
    centered
    subTitleWrap={{
      before: __('To see results here'),
      after: __('a Remote Object Configuration or talk with your admin.')
    }}
    image={false}
  />
)

export const objectSelectorCustomProps = (field, commonProps) => {
  if (!field) return null

  const { id: fieldId, controllerOptions, orderId, position, overviewObjectConfigurationId } = field
  const { objectConfiguration, readFrom, writeTo } = controllerOptions
  const { objectTypeId } = objectConfiguration

  const { attributes, attributesTree } = commonProps || {}

  const orderProps = {
    objectTypeId,
    fieldId,
    orderId,
    overviewObjectConfigurationId,
    position,
  }

  return {
    readFrom,
    writeTo,
    missingConfigurationMessage: <MissingConfigurationMessage />,
    api: {
      createItem: ({ payload }) => createStepRemoteItem({ ...orderProps, payload }),
      editItem: ({ payload, mainKey }) => editStepRemoteItem({ ...orderProps, payload, mainKey }),
      fetchAttributesList: attributes ? async () => (attributes) : fetchAttributes,
      fetchAttributesTree: attributesTree ? async () => (attributesTree) : fetchAttributesTree,
      fetchEnumValuesByIds: fetchEnumValuesByIds,
      fetchRemoteObject: (mainKey) => fetchStepRemoteObject({ ...orderProps, mainKey }),
      fetchItemsFromIds: (mainKeys) => fetchStepRemoteItemsFromIds({ ...orderProps, mainKeys }),
      fetchTableData: (payload, ids) => fetchStepRemoteTableData({ ...orderProps, ids, payload }),
      fetchRemoteTableData: ({ objectTypeId, payload, optionalProps }) => fetchStepRemoteTableData({ ...orderProps, ...optionalProps, objectTypeId, payload }),
      fetchDefaultConfiguration: (objectTypeId) => fetchStepDefaultConfiguration({ ...orderProps, objectTypeId }),
      addRelations: (parentObject, payload, optionalprops) => addRelations({ ...orderProps, parentObject, payload, ...optionalprops }),
      removeRelations: (parentObject, payload, optionalprops) => removeRelations({ ...orderProps, parentObject, payload, ...optionalprops }),
    }
  }
}
