import React, { useMemo } from 'react'

import FileUploader from './FileUploader'

import { __ } from '../../../../../utils/translationUtils'
import {errorLevel} from "components/dist/FormBuilder/Components/UI/Grid/utils";


const parse = (value) => {
  try {
    return JSON.parse(value);
  } catch(e) {
    return value
  }
}

// This is needed for the inception
export const FileUploaderAdapted = (props) => {
  const consolidatedProps = useMemo(() => adaptFileUploaderProps(props), [props])

  return (
    <FileUploaderComponent
      {...consolidatedProps}
      error={__('This option is available only in edit mode.')}
    />
  )
}

// ℹ️ This may be useful whenever the form item is not configured by the user (e.g. from the flow config) but it is created from attribute + value.
const adaptFileUploaderProps = (props) => {
  if (!props?.value) return null
  const { attribute } = props || {}
  const value = typeof props?.value === 'string' ? JSON.parse(props.value) : props?.value
  const source = JSON.stringify({
    fieldName: value.fieldName,
    primaryKey: value.primaryKey,
    xsdName: value.xsdName,
    files: value.files,
    groups: value.groups
  })

  return {
    ...props,
    source,
    id: attribute.id,
    name: attribute.id,
    readOnly: props.readOnly !== undefined ? props.readOnly : !attribute.editable,
    allowFilePreview: true,
    type: 'FileUploaderControllerOptions',
    rootDirectory: { allowExtraFiles: true },
  }
}

export const fileUploaderFieldValidation = (options) => ([
  {
    message: { value: 'This field is required', visible: errorLevel.BLUR },
    validator: (_, value) => {
      if (options?.required) {
        if (!value || !(parse(value)?.files?.length > 0)) {
          return Promise.reject()
        }
      }

      return Promise.resolve()
    },
  }])

const FileUploaderComponent = (props) => {
  const {
    onChange,
    error,
    ...field
  } = props

  const {
    value,
    type,
    rootDirectory,
    allowFilePreview,
  } = field

  return (
    <div className='file-uploader-container'>
      {!value ? (error || __('You cannot currently upload files')) : (
        <FileUploader
          onChange={onChange}
          field={{
            ...field,
            controllerOptions: {
              type,
              rootDirectory,
              allowFilePreview,
            },
          }}
        />
      )}
    </div>
  )
}


export default FileUploaderComponent
