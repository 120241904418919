import styled from "styled-components";

export const FormWrapper = styled.div`
  * > .ant-radio-group {
    width: 100%;
    .ant-radio-wrapper {
      display: flex !important;
      flex-direction: row-reverse !important;
      align-items: center;
      // label should be full width
      & > span:nth-child(2) {
        width: 100%;
        padding-right: 4rem;
        padding-left: 0;
      }
    }
  }
  
  #form-content > div {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }
`
