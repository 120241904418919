import React from 'react';
import PropTypes from 'prop-types';
import { Field} from 'redux-form';
import { __ } from '../../utils/translationUtils'

class SimpleCheckBoxControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { id, title, required, description} = this.props.controlData;
    return (
      <div className="form-group">
        <label className="col-sm-3 col-md-3 col-lg-2 control-label">{__(title)}{required?'*':''}</label>
        <div className="col-sm-9 col-md-6 col-lg-6">
          <div>
            <label  style={{marginRight: '20px'}}>
              <Field
                name={id}
                description={description}
                component="input"
                type="radio"
                value={'true'}
              /> {' '} {__('val_yes')}
            </label>

            <br/>

            <label  style={{marginRight: '20px'}}>
              <Field
                name={id}
                description={description}
                component="input"
                type="radio"
                value={'false'} />
              {' '} {__('val_no')}
            </label><br/>
          </div>
        </div>
      </div>
    );

  }
}
export default SimpleCheckBoxControl;
