import React, {useCallback, useMemo, useState} from "react";

import _ from "lodash";
import { MinusCircleOutlined } from "@ant-design/icons"

import * as S from '../../styles'
import * as C from '../../constants'
import {useROEConfiguratorContext} from "../../context";
import {Tooltip} from "antd";
import { __ } from '../../../../utils/translationUtils'
import { cases } from '../../../../utils/stringUtils'

const DraggableGrid = (props) => {

  const {
    cols=4,
    rowHeight=120,
    margin=[10, 10],
    onDropCallback,
    onRemoveCallback,
    gridItems,
    onLayoutChangeCallback,
    cellDimension = C.defaultCellDimension
  } = props
  const layout = useMemo(() => gridItems.map((item) => (item.cellProperties)),  [gridItems])
  const [draggedItem, setDraggedItem] = useState({})
  const [hoveredItem, setHoveredItem] = useState({})

  const onDrop = useCallback((items, item, e) => {
    const dataString = e.dataTransfer.getData('text/plain')
    if (item === undefined || dataString === undefined) return
    const newItems = _.cloneDeep(items)
    const newGridItem = { ...JSON.parse(dataString), cellProperties: _.cloneDeep(item) }
    newItems.find((item) => item.i === '__dropping-elem__').i = newGridItem.id.toString()
    newGridItem.cellProperties.i = newGridItem.id.toString()
    onDropCallback?.(newGridItem)
  }, [])

  const onRemove = useCallback((gridItem) => {
    onRemoveCallback?.(gridItem)
  }, [])

  const onDrag = (layout, cell) => {
    if (!cell || !gridItems) return
    setDraggedItem(gridItems.find((item) => (item.id.toString() === cell.i)) || {})
  }

  const onDragStop = (layout, cell) => {
    if (!Object.keys(draggedItem).length) return
    setDraggedItem({})
  }

  const onMouseEnter = (item) => {
    if (!item) return
    setHoveredItem(item)
  }

  const onMouseLeave = (item) => {
    if (!item || !Object.keys(hoveredItem).length) return
    setHoveredItem({})
  }

  const onLayoutChange = (layout) => {
    onLayoutChangeCallback?.(layout)
  }

  const generateDOM = () => {
    return gridItems.map((item) => {
      const isDragging = item.id === draggedItem.id
      const isHovering = item.id === hoveredItem.id
      return (
        <S.GridItem
          key={item.id}
          id={item.id}
          dragging={isDragging}
          hovering={isHovering}
          onMouseEnter={() => onMouseEnter(item)}
          onMouseLeave={() => onMouseLeave(item)}
        >
          <S.GridItemHeader>
            <S.GridItemTitle>
              {__(item.propertyLabel, cases.UPPERCASE)}
            </S.GridItemTitle>
            <S.RemoveButton
              onClick={() => onRemove(item)}
              displaying={isHovering}
            />
          </S.GridItemHeader>
          <S.GridItemBody>
            <S.CustomSkeleton
              title={false}
              paragraph={{
                rows: 3,
                width: ['80%', '100%', '50%']
              }}
              size={'small'}
            />
          </S.GridItemBody>
        </S.GridItem>
      );
    });
  }

  return (
    <S.GridWrapper
      layout={layout}
      rowHeight={rowHeight}
      resizeHandles={['se']}
      onDrop={onDrop}
      isDroppable={true}
      cols={cols}
      margin={margin}
      onDrag={onDrag}
      onDragStop={onDragStop}
      onResizeStart={() => null}
      onResize={() => null}
      onResizeStop={() => null}
      onLayoutChange={onLayoutChange}
      measureBeforeMount={false}
      droppingItem={{
        i: '__dropping-elem__',
        ...cellDimension
      }}
      useCSSTransforms
    >
      {generateDOM()}
    </S.GridWrapper>
  )
}

export default (props) => {

  const {
    setAttributesToSelect,
    attributes,
    gridItems,
    setGridItems,
    gridCols,
    onRemoveAllCallback,
    cellDimension,
  } = props || {}

  const { state, editStepConfiguration, editStepConfigurationAttribute } = useROEConfiguratorContext()
  const { activeStep } = state

  const onDropCallback = (toAddGridItem) => {
    setGridItems((prev) => [...prev, toAddGridItem])
    editStepConfigurationAttribute(toAddGridItem.id, toAddGridItem.cellProperties, activeStep)
    setAttributesToSelect((prev) => {
      return prev.filter((attribute) => (attribute.id !== toAddGridItem.id))
    })
  }

  const onRemoveCallback = (toRemoveGridItem) => {
    setGridItems((prev) => {
      return prev.filter((gridItem) => gridItem.id !== toRemoveGridItem.id)
    })
    setAttributesToSelect((prev) => {
      return [ ...prev, attributes.find((attribute) => attribute.id === toRemoveGridItem.id)]
    })
  }

  const onLayoutChangeCallback = (layout) => {
    setGridItems(gridItems.map((item) => (
      {...item, cellProperties: layout.find((cell) => (cell.i === item.id.toString()))}
    )))
    editStepConfiguration(layout.reduce((prev, cellProperty) => {
      prev[cellProperty.i] = cellProperty
      return prev
    }, {}), activeStep)
  }

  return (
    <>
      <Tooltip title={__('remove all')}>
        <S.ShortcutButton
          type={'link'}
          icon={<MinusCircleOutlined />}
          onClick={onRemoveAllCallback}
        />
      </Tooltip>
      <DraggableGrid
        onDropCallback={onDropCallback}
        onRemoveCallback={onRemoveCallback}
        onLayoutChangeCallback={onLayoutChangeCallback}
        gridItems={gridItems}
        cols={gridCols}
        cellDimension={cellDimension}
      />
    </>
  )
}
