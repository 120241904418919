import * as types from '../types';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function reportUpdate(state = initialState.import, { type, payload }) {
  switch (type) {
    case types.REPORT_IMPORTED:
      return objectAssign({}, state, {'reportData': payload});

    default:
      return state;
  }
  
}
