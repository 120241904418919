import * as types from '../types';
import objectAssign from 'object-assign';
import { hashHistory } from '../../HistoryProvider';

import { showMessage, fetchEnumById } from '../../../utils/appHelper';
import { api } from '../../ApiProvider'
import { __ } from '../../../utils/translationUtils'

export function createReport(params) {
  return function () {
    return api.post('/configurations/reports/',{ ...params })
    .then(
      response => {
      if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Report created successfully'));
            },
            200
          );

          hashHistory.push('/customReports');
        }
      }
    )
    .catch( (error) => {
      showMessage('error', __('ServerSideError') + '. ' + error);
    });
  };
}

export function updateReport(params) {
  let payload = objectAssign({}, {...params});

  return function(){
    return api.put('/configurations/reports/'+params.id,{...payload})
      .then(
        response => {
          if(response.status === 200){
            setTimeout(()=>{
              showMessage('success', __('Report updated successfully'));
              },
              200
            );
            hashHistory.push('/customReports');
          }
        }
      )
      .catch( (error) => {
        showMessage('error', __('ServerSideError') + '. ' + error);
      });
  }

}

export function deleteReport(id) {
  return function (dispatch) {
    return api.delete('/configurations/reports/'+id, {
      status: 'deleted'
    })
    .then(
      response => {
        if(response.status === 200){
          setTimeout(()=>{
            showMessage('success', __('Report deleted successfully'));
            },
            200
          );
        }
        dispatch({
          type: types.USER_DELETED,
          payload: response.data
        });
      }
    );
  };
}

export function importReport(data) {
  return function (dispatch) {
    return new Promise(function(resolve) {
      resolve(
        dispatch({
            type: types.REPORT_IMPORTED,
            payload: data,
          })
        );
      }
    );
  };
}

export function importReportReset() {
  return function (dispatch) {
    return new Promise(function(resolve) {
      resolve(
        dispatch({type: types.REPORT_RESET_IMPORT})
      );
    });
  };
}

export function getReport(key) {
  return function (dispatch) {
    return api.get('/reports/'+key)
    .then(
      response => {
        dispatch({
          type: types.REPORTS_GOT_DATA,
          payload: response.data
        });
      },
      error => {
        dispatch({
          type: types.REPORTS_GET_DATA_ERROR,
          payload: error.response.data.message
        });
      },
    );
  };
}

export function getEnumValues(id) {
  return function (dispatch) {
     return fetchEnumById(id)
    .then(
      response => {
        if (!response) return
        dispatch({
          type: types.ENUMVALUES_GOT_DATA,
          payload: response
        });
      },
    );
  };
}
