import * as yup from 'yup'
import V from '../../utils/validation'

export const signupValidationSchema = (fields, allowedEmailDomains) => {
  const formValidationSchema = {}
  fields.forEach(({ destination, required }) => {
    const schema = getSignupInputValidationSchema({ name: destination, required, allowedEmailDomains })
    if (schema) formValidationSchema[destination] = schema
  })

  return yup.object().shape(formValidationSchema)
}

const getSignupInputValidationSchema = ({ name, required, allowedEmailDomains: domains }) => {
  const baseSchema = yup.string().concat(required && V.isRequiredSchema())
  switch (name) {
    case 'firstName':
    case 'lastName':
      return baseSchema.concat(V.minSchema()).concat(V.maxSchema()).concat(V.illegalCharsName()).nullable()
    case 'username':
      return baseSchema.concat(V.minSchema()).concat(V.maxSchema()).concat(V.illegalCharsUsername()).nullable()
    case 'password':
      return baseSchema.concat(V.minSchema(5)).nullable()
    case 'phone':
      return baseSchema.concat(V.phoneSchema()).concat(V.prefixSchema()).nullable()
    case 'email':
      return baseSchema.concat(V.emailSchema()).concat(domains && V.domainSchema(domains)).nullable()
    default:
      return baseSchema.nullable()
  }
}
