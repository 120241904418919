import styled from 'styled-components'

export const Cards = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (min-width: 1152px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const Card = styled.div`
  box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  padding: 2rem;
  position: relative;
`

export const SimpleLabel = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  overflow-wrap: break-word;
  word-break: break-word;
  ${(props) => props.hoverable && `
    &:hover {
      cursor: pointer;
      color: ${props.theme.colors.thirdary};
    }
  `}
`

export const AddWrapper = styled.div`
  box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  padding: 2rem;
  min-height: 150px;
  height: 100%;
  & > span {
    width: 100%;
    height: 100%;
    border: 1px dashed #3232324a;
    button {
      height: 100%;
    }
  }
`

export const DeleteWrapper = styled.div`
  position: absolute;
  top: .5rem;
  right: 1rem;
  color: red;
  cursor: pointer;
`

export const Info = styled.div`
  background-color: #fafafa;
  padding: 1rem;
  margin-bottom: 1rem;
`

export const ErrorsModalBody = styled.div`
 
 a {
   text-decoration: none;
    color: inherit;
 }
`

export const AlertWrapper = styled.div`
  margin-top: 1rem;
`

export const ErrorCard = styled.div`
  background: #fafafa;
  padding: 1rem;
  margin-bottom: 1rem;
  &:hover{
    box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  }
`
export const ErrorCardRow = styled.div`
  display: flex;

  strong {
    flex-basis: 140px;
    flex-shrink: 0;
  }

`
