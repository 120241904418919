import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const MainContainer = styled.div`
  margin-bottom: 16px;
  form,
  button,
  .ant-picker {
    width: 100%;
  }

  .ant-row {
    height: 100%;
  }

  .ant-form-item-control-input, 
  .ant-form-item-control-input-content {
    height: 100%;
  }

  @media (min-width: 992px) {
    display: flex;
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`
export const Containter = styled.div`
  box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
`

export const GridLayoutWrapper = styled.div`
  width: 100%;
  box-shadow: rgb(0 0 0 / 10%) -2px 0px 8px;
  min-height: 250px;
  position: relative;
  .react-grid-layout {
    width: 100%;
  }
  .ant-form-item-control-input, 
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }


  .react-resizable-handle {
    ${(props) => props.mode === 'view' && `
      display: none;
    `}
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    height: 2rem;
    width: 2rem;
    border-right: 2px solid ${(props) => props.theme.colors.thirdary};
    border-bottom: 2px solid ${(props) => props.theme.colors.thirdary};
    cursor: se-resize;
  }
`

export const Card = styled.div`
  margin-bottom: 1rem;
  background-color: white;
  padding: 1rem;
  cursor: pointer;
  position: relative;

  box-shadow: rgb(0 0 0 / 10%) -2px 0px 18px;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
  .ant-form-item {
    margin: 0;
    margin-bottom: 1rem;
  }
  .ant-form-item-label {
    padding: 0;
  }
`

export const AddWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`
export const HandlerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`
export const ChartHandlerWrapper = styled.div`
  position: absolute;
  top: 0rem;
  right: 0rem;
  cursor: pointer;
`

export const IconDanger = styled.div`
  color: red;
  display: flex;
  align-items: center;
  svg {
    margin-right: .5rem;
  }
`

export const Label = styled.div`
  margin-bottom: .75rem;
`

export const TabsWrapper = styled.div`
  width: 100%;
`

export const ChartPlaceholder = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12rem;

  svg {
    color: #0a4f7a;
  }
`

export const ChartListPlaceholder = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10rem;

  svg {
    color: #0a4f7a;
  }
`

export const Icons = styled.div`
  position: absolute;
  top: .5rem;
  right: .5rem;

  
  svg {
    margin: 0 .5rem;
  }
`

export const PanelHeadingInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ChartWrapper = styled.div`
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(141, 149, 166, 0.1) 0px 2px 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 1rem;
  ${(props) => props.pointer && `
    cursor: all-scroll;
    &:hover {
      box-shadow: rgb(0 0 0 / 10%) -2px 0px 16px;
    }
  `}
  padding: 2rem;
`

export const ChartTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: .04rem;
  color: ${(props) => props.theme.colors.thirdary};
`

export const SelectWrapper = styled.div`
  padding: 2rem;
`

export const SimpleLabel = styled.div`
  height: 3.5rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  background-color: #fafafa;
  border: 1px solid ${(props) => props.theme.colors.gray.primary};
  color: ${(props) => props.theme.colors.text.secondary};
`

export const IconDeleteWrapper = styled.div`
  color: red
`

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`

export const ModalStickyContainer = styled(StickyContainer)`
  top: -24px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  background-color: white;
  padding: 24px;
  border-bottom: 1px solid #f0f0f0;
`

export const TitleAction = styled(Link)`
  font-size: 16px;
  align-self: flex-end;
  white-space: nowrap;
`
