import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {change, Field, reduxForm} from 'redux-form';
import {find} from 'lodash';
import {bindActionCreators} from 'redux';

import {renderField} from '../utils/fieldValidator';
import AttributeNodeEdit from '../components/AttributeNodeEdit';
import {getEnumValues} from '../providers/ReduxProvider/actions/reportActions';
import {appDefaults} from "../constants/globalConfiguration";
import BillingController from "../components/controls/BillingController";
import {OBJECT_BINDER_CONTROLLER_TYPE, OBJECT_PICKER_CONTROLLER_TYPE} from "./DooOrderPage/Step/customRender/ObjectSelector/utils";
import MainKeysControl from './controls/mainKeyControl/MainKeysControl'
import { __ } from '../utils/translationUtils'


class OverviewTabEditForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    getEnumValues: PropTypes.func,
    change: PropTypes.func,
    system: PropTypes.string,
    node: PropTypes.object,
    configuration: PropTypes.object,
    filterByDisplayAttributes: PropTypes.bool,
    initialValues: PropTypes.object,
    dependencyId: PropTypes.string,
    enumValues: PropTypes.object.isRequired,
    objectConfiguration: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    attributesConfiguration: PropTypes.array,
  };

  static defaultProps = {
    filterByDisplayAttributes: false,
  }

  constructor(props){
    super(props);
    this.state = {
      activeBillingItem: null,
      loadingItem: null,
    };

    this.selectedCompany = null;
  }

  render() {
    const {
      attributesConfiguration,
      node,
      configuration,
      objectConfiguration,
      filterByDisplayAttributes,
      enumValues,
      attributesConfigurationTree
    } = this.props;

    const extractConfiguration = id => {
      const found = find(attributesConfiguration, (c) =>  c.id === id );
      return found;
    };

    const shouldShow = (attr) => {
      // ✅ to do. cosa voleva dire sta roba?
      // attr.displayable;
      if (filterByDisplayAttributes) {
        const found = find(configuration.displayAttributes, (a) => a.attributeId === attr.id);
        return (found && attr.displayable);
      }
      return attr.displayable;
    };

    const getConfigController = (attr) => {
      if (objectConfiguration && configuration && configuration.configuration && configuration.configuration.id && objectConfiguration[configuration.configuration.id] && objectConfiguration[configuration.configuration.id].displayAttributes) {
        const found = find(objectConfiguration[configuration.configuration.id].displayAttributes, (a) => a.attributeId === attr.id);
        return (found && (found.controllerType || found.defaultControllerType) );
      }
      return attr.type;
    };

    const renderLine = (attr) => {
      const { initialValues, change, dispatch } = this.props;
      const { activeBillingItem, loadingItem } = this.state;
      const attrConfiguration = extractConfiguration(attr.id);

      if(attrConfiguration.enumerationId && attrConfiguration.enumerationId != '') {
        if (attrConfiguration) {
          // dispatch
          //
          // (getEnumValues(attrConfiguration.enumerationId));
        }
      }

      if(attrConfiguration.enumerationId && !enumValues[attrConfiguration.enumerationId])
      {
        dispatch(getEnumValues(attrConfiguration.enumerationId));
      }

      if (typeof initialValues[attr.id] === 'undefined') {
        return;
      }

      if (shouldShow(attrConfiguration)) {

        // Is price list
        if (attrConfiguration && attrConfiguration.type === 'BILLING') {
          return (
            <div
              className="col-xs-12"
              style={{
                borderTop: '1px solid #ccc',
                marginTop: '5px',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}>
              <div className="form-group">
                <label className="control-label">
                  {__(attrConfiguration.propertyLabel)}:
                </label>
                { activeBillingItem === attr.id && (
                  <BillingController
                    form={attr.id}
                    key={attr.id}
                    onChange={(newSource)=> {
                      dispatch(change(attr.id, JSON.stringify(newSource)));
                    }}
                    controlData={{
                      source: initialValues[attr.id],
                      title: __(attrConfiguration.propertyLabel),
                    }}
                  />
                )}

                {loadingItem === attr.id && (
                  <span> {__('loading')}</span>
                )}

                {' '}
                { loadingItem !== attr.id && activeBillingItem !== attr.id && (
                  <a
                    onClick={() => {
                      this.setState({
                        activeBillingItem: null,
                        loadingItem: attr.id,
                      }, () => {
                        setTimeout(() => {
                          this.setState({
                            activeBillingItem: attr.id,
                            loadingItem: null
                          });
                        }, 200);
                      });
                    }}
                  >
                    {__('Show')}
                  </a>
                )}
                { !loadingItem && activeBillingItem === attr.id && (
                  <a
                    onClick={() => {
                      this.setState({
                        activeBillingItem: null,
                        loadingItem: null
                      });
                    }}
                  >
                    {__('Hide')}
                  </a>
                )}
              </div>
            </div>
          );
        }


        const displayAttributeList = objectConfiguration["undefined"] && objectConfiguration["undefined"].displayAttributes;
        const displayAttribute = displayAttributeList.find(a => a.attributeId === attr.id);

        if ([OBJECT_PICKER_CONTROLLER_TYPE, OBJECT_BINDER_CONTROLLER_TYPE].includes(displayAttribute.controllerType) && getConfigController(attrConfiguration) === 'OBJECT_LIST_IDS') {
          const objectConfiguration = (configuration.configuration) ? configuration.configuration : configuration
          const attributes = OBJECT_BINDER_CONTROLLER_TYPE === displayAttribute.controllerType ? {objectBinderConfiguration: objectConfiguration} : {objectPickerConfiguration: objectConfiguration}
          const value = initialValues[displayAttribute.attributeId]
          const mainKeyControllerProps = {
            formName: 'overviewTabEditForm',
            controllerType: displayAttribute.controllerType,
            objectConfiguration: {
              ...objectConfiguration
            },
            controlData: {
              attributes: attributes,
              controllerType: displayAttribute.controllerType,
              readOnly: true,
              id: `${objectConfiguration.objectTypeId}`,
              source: value
            }
          }
          return <MainKeysControl {...mainKeyControllerProps}/>
        }


        return attrConfiguration && (
          <Field
            key={`remoteobject-property-${attr.id}`}
            name={attr.id}
            attribute={attr}
            component={renderField}
            disabled={(displayAttribute && !displayAttribute.editable) || attrConfiguration.propertyLabel === 'id' || !attrConfiguration.editable}
            label={getConfigController(attrConfiguration) === 'OBJECT_LIST_IDS' ? '' : __(attrConfiguration.propertyLabel)}
            type={getConfigController(attrConfiguration)}
            dateFormat={appDefaults.dateFormat}
            attributesConfigurationTree={attributesConfigurationTree}
            inputCol={getConfigController(attrConfiguration) === 'OBJECT_LIST_IDS' ? "col-sm-12" : null}
            overviewTabEditField={(displayAttribute && displayAttribute.editable)}
            formName={'overviewTabEditForm'}
            objectConfiguration={objectConfiguration["undefined"]}
            labelCol={'col-sm-3'}
            inputClass={'col-sm-9'}
            items={enumValues && attrConfiguration.enumerationId
              ? renderSelectOptions(enumValues[attrConfiguration.enumerationId],(displayAttribute && !displayAttribute.editable) || attrConfiguration.propertyLabel === 'id' || !attrConfiguration.editable)
              : []}
          />
        );
      }
    };


    const renderSelectOptions = (options,readOnly) => {
      const _opts = options && options.map((opt) => {
        return (
          <option key={`date-format-${opt.id}`} value={opt.id} >
            {/* In the case of this that is broken check this commit: 8880cec143924f623cce69676f6a2d77267106ec */}
            {__(opt.translationKey) || __(opt.value)}
          </option>);
       });
      return [<option key="verion-000" value="">{readOnly?"":__('Select one')}</option>].concat(_opts)
    };

    return (
      <form className="form-horizontal remote-object-form">
        <div className="row">
          <div className="col-md-12">
            { node
              ? <AttributeNodeEdit
                node={node}
                checkVisibility
                renderLine={renderLine}
              />
              : 'Node not found'
            }
          </div>
        </div>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const OverviewTabEditFormConnected = connect(
  () => {
    return {
    };
  },
  dispatch => {
    return {
      change,
      dispatch,
      getEnumValues,
    };
  },
)(OverviewTabEditForm);

export default reduxForm({
  form: 'overviewTabEditForm',  // a unique identifier for this form
}, mapDispatchToProps)(OverviewTabEditFormConnected);
