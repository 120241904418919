import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getFormValues } from "redux-form";
import { MainButtons, ROInfo, MostRecentItems } from "../components/RemoteObjectDetails";
import { getRemoteObjectInstance, getRemoteObjectConfiguration, getRemoteObjectResults } from "../components/RemoteObjectDetails/ApiHelper";
import { showMessage, checkUserRightsAll } from "../utils/appHelper";
import { api } from "../providers/ApiProvider";
import { Col, Row } from "antd";
import Loading from "../components/Loading";
import useDynamicRefs from "use-dynamic-refs";
import { hashHistory } from "../providers/HistoryProvider";
import { useSelector } from "react-redux";

import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { __ } from '../utils/translationUtils'
import {UseCombinedQueries} from "../providers/QueryClientProvider/queries";
import styled from "styled-components";

const RemoteObjectDetailsPage = (props) => {
  const enumValues = useSelector((state) => state?.enumValues || [])

  const { attributesMap, attributesTree } = UseCombinedQueries(["attributesMap", "attributesTree"])

  const { rights } = useSelector((state) => state?.user?.data || {})

  const formValues = useSelector((state) => getFormValues("editRemoteObjectItemForm")(state))

	const [getRef] = useDynamicRefs();

	const formRef = useRef(undefined)

	const scrollToRelatedOrders = () => getRef('godoo-orders-ref')?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

  const params = useMemo(() => props?.match?.params, [props.match.params])

  const [init, setInit] = useState(false)

  const [system, setSystem] = useState(null)
  const [dependencyId, setDependencyId] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isMainObjectOpen, setIsMainObjectOpen] = useState(true)
  const [action, setAction] = useState('view')
  const [remoteObject, setRemoteObject] = useState({ ...props.remoteObject, id: params.entryId })
  const [remoteObjectConfiguration, setRemoteObjectConfiguration] = useState(props.remoteObjectsSpec)
  const [viewDisplayAttributes, setViewDisplayAttributes] = useState(props.viewDisplayAttributes)
  const [remoteObjectInstance, setRemoteObjectInstance] = useState(null)
  const [permissions, setPermissions] = useState(null)

  const isDeleteModalVisible = props.isDeleteModalVisible
  const isExitEditModalVisible = props.isExitEditModalVisible

  const FlexDiv = styled.div`display: flex;`

  useEffect(() => {
    if (!init && attributesMap && attributesTree) {
      const primaryKeyId = remoteObject?.id

      getRemoteObjectConfiguration(params?.configurationId).then((roConfiguration) => {
        const payload = {
          ...roConfiguration,
          displayAttributes: roConfiguration?.displayAttributes,
          searchAttributes: [],
          sortAttributes: []
        }

        if (roConfiguration) {
          getRemoteObjectResults(payload, primaryKeyId).then((response) => {

            if (response && response?.length > 0) {
              setViewDisplayAttributes(roConfiguration.displayAttributes)
              const attrId = Object.keys(response[0].displayData)[0];

              const attributeConfiguration = attributesMap[attrId];
              if (!attributeConfiguration) return
              getRemoteObjectInstance(primaryKeyId, attributeConfiguration).then((roInstance) => {
                setRemoteObjectInstance(roInstance)
              })
              setRemoteObjectConfiguration(roConfiguration);
              addToRecentItems(roConfiguration.name, roConfiguration.id, primaryKeyId, attributeConfiguration.dependencies)
              setDependencyId(attributeConfiguration.dependencies)
              setSystem(attributeConfiguration.system)
            } else {
              showMessage('error', "No Results Found")
              setLoading(false)
            }
          })
        }
      })

      setInit(true)
    }
  }, [attributesMap, attributesTree])

  useEffect(() => {
    const { HAS_ROE_READ_ACCESS, HAS_ROE_EDIT_ACCESS, CAN_UPDATE_REMOTE_OBJECT_ITEM, CAN_DELETE_REMOTE_OBJECT_ITEM } = global.constants?.rightMappings
    if (rights)
      setPermissions(checkUserRightsAll(rights, {
        HAS_ROE_READ_ACCESS,
        HAS_ROE_EDIT_ACCESS,
        CAN_UPDATE_REMOTE_OBJECT_ITEM,
        CAN_DELETE_REMOTE_OBJECT_ITEM
      }))
  }, [rights])

  const updateObject = useCallback((changes) => {
    const newFormValues = { ...formValues, ...changes }
    setLoading(true)
    setRemoteObject({ ...remoteObject, displayData: newFormValues })
    let payload = { ...remoteObject, displayData: newFormValues }
    return api.put('/configurations/remoteObjects/update', payload)
      .then(
        response => {
          if (response.status === 200) {
            setTimeout(() => {
              showMessage('success', __('Remote Object updated successfully'));
              setLoading(false)
            },
              200
            );
            setAction("view")

            return response
          }
        }
      )
      .catch((error) => {
        setLoading(false)
        return error
      });
  }, [formValues, remoteObject])

  const cancelEdit = useCallback(() => {
    setAction('view')
    formRef.current.reset()

  }, [formRef?.current])

  const editObject = useCallback(() => {
    setAction('edit')
    setIsMainObjectOpen(true)
  }, [])

  const addToRecentItems = useCallback((itemName, remoteObjectId, objectId, dependencies) => {
    let item = { name: itemName, id: remoteObjectId, objectId: objectId, dependencies: dependencies }
    let mostRecentItems = JSON.parse(localStorage.getItem('mostRecentItems')) || []
    if (JSON.stringify(mostRecentItems[mostRecentItems.length - 1]) !== JSON.stringify(item)) {
      if (mostRecentItems.length > 4) {
        mostRecentItems.shift()
      }
      mostRecentItems.push(item)
      localStorage.setItem('mostRecentItems', JSON.stringify(mostRecentItems))
    }
  }, [])

  const deleteObject = useCallback(() => {
    const dependencyId = remoteObjectConfiguration.objectTypeId;
    setLoading(true)
    api.delete(`/configurations/remoteObjects/${system}/${dependencyId}/${remoteObject.id}`)
      .then(
        response => {
          if (response.status === 200) {
            setTimeout(() => {
              showMessage('success', __('Remote Object deleted successfully'));
            },
              200
            );
            setAction("view")
            setLoading(false);
            hashHistory.goBack()
          }
        }
      )
      .catch((error) => {
        showMessage('error', __('ServerSideError') + '. ' + error);
        setLoading(false);
      });
  }, [system, remoteObject, remoteObjectConfiguration])

  const toggleCollapsibleSection = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    setIsMainObjectOpen(!isMainObjectOpen)
  }, [isMainObjectOpen])

  return (
    <>
      <FlexDiv />
      {
        <div id="main-content" className="remote-object-page">
          <div id="remote-object-title" onClick={toggleCollapsibleSection}>
            <Row align="middle" className="header-row-style">
              <Col span={12}>
                {
                  !!remoteObjectConfiguration
                  && (
                    <span className="capitalize remote-object-details-title">
                      {isMainObjectOpen ? <CaretDownOutlined /> : <CaretRightOutlined />}
                      {remoteObjectConfiguration.name}
                    </span>
                  )
                }
              </Col>
              <Col span={12}>
                <MainButtons
                  action={action}
                  setIsMainObjectOpen={setIsMainObjectOpen}
                  scrollToRelatedOrders={scrollToRelatedOrders}
                  isDeleteModalVisible={isDeleteModalVisible}
                  isExitEditModalVisible={isExitEditModalVisible}
                  isMainObjectOpen={isMainObjectOpen}
                  isLoading={isLoading}
                  cancelEdit={cancelEdit}
                  deleteObject={deleteObject}
                  editObject={editObject}
                  updateObject={updateObject}
                  permissions={permissions}
                />
              </Col>
            </Row>
          </div>
          {remoteObjectInstance && attributesMap && attributesTree && dependencyId && system ? (
            <ROInfo
              ref={formRef}
              action={action}
              enumValues={enumValues}
              dependencyId={dependencyId}
              system={system}
              isMainObjectOpen={isMainObjectOpen}
              viewDisplayAttributes={viewDisplayAttributes}
              remoteObjectInstance={remoteObjectInstance}
              attributesConfigurationTree={attributesTree}
              attributes={attributesMap}
              initialValues={remoteObjectInstance.displayData}
              remoteObject={remoteObject}
              remoteObjectConfiguration={remoteObjectConfiguration}
              updateObject={updateObject}
            />
          ) : (
            <Loading />
          )}
          {remoteObjectInstance && attributesTree && (<MostRecentItems props={props} />)}
        </div>
      }
    </>
  );
}


export default RemoteObjectDetailsPage;
