import React, {useContext, useEffect, useState} from "react"
import * as CONTEXT from "../../context"
import * as UT from "../utils"
import {SEARCH_ATTRIBUTES} from "../../utils/requiredFields"
import * as S from "../../style"
import AttributeTransfer from "../AttributeTransfer"
import FormContext from "../../factories/FormContext"
import * as MECHANISM_TYPE from "./mechanismType"
import ApiManagement from "./../../api"
import * as ACTION_TYPE from "../../context/actionTypes"
import {Form, Icon} from '@ant-design/compatible';
import {Collapse, Descriptions, Input, Select, Switch} from 'antd'

const {Option} = Select
const {Panel} = Collapse

function ObjectListing() {
  const {state, dispatch} = useContext(CONTEXT.MainContext)
  const {form} = useContext(FormContext)

  const [attributeInformation, setAttributeInformation] = useState(null)
  const [enumerationValues, setEnumerationValues] = useState([])

  useEffect(() => {
    if (state.editingForms.configurationFormFields) {
      setAttributeInformation(UT.retrieveAttributesInformation(state.editingForms.configurationFormFields, SEARCH_ATTRIBUTES))
    }
  }, [state.editingForms.configurationFormFields])

  const handleMechanismChange = (value, key, enumerationId) => {
    form.resetFields([`${SEARCH_ATTRIBUTES},${key},value`, `${SEARCH_ATTRIBUTES},${key},from`, `${SEARCH_ATTRIBUTES},${key},to`])
    if (value === MECHANISM_TYPE.ENUMERATION && enumerationId) {
      ApiManagement.fetchEnumeration(enumerationId).then((res) => {
        if (res && Array.isArray(res.enumerationValues)) {
          setEnumerationValues(res.enumerationValues)
        } else {
          dispatch({
            type: ACTION_TYPE.ERROR_FETCH,
          })
        }
      })
    }
  }

  const renderCard = (key) => {
    const attributeProperty = (attributeInformation?.attributesFlat || []).find((attribute) => attribute.key === key)
    //const formFields = state.editingForms.configurationFormFields || {}
    return (
      <S.SortableContainer>
        <S.FormDescription>
          <Descriptions title={attributeProperty?.property || ""}/>
        </S.FormDescription>

        <Collapse defaultActiveKey={[]}>
          <Panel
            header={
              <S.PanelHeader>
                <Icon type="more"/>
                Show More
              </S.PanelHeader>
            }
          >
            {/* MECHANISM */}
            <S.FormField>
              <Form.Item label={"Mechanism"}>
                {form.getFieldDecorator(`${SEARCH_ATTRIBUTES},${key},mechanism`, {
                  rules: [{required: true, message: "Please input the Mechanism of this Search field!"}],
                  initialValue: MECHANISM_TYPE.retrieveMechanismInitialValue(attributeProperty),
                })(
                  <Select onChange={(value) => handleMechanismChange(value, key, attributeProperty.enumerationId)}>
                    {attributeProperty && attributeProperty.specificValueMechanismAllowed ? (
                      <Option key={MECHANISM_TYPE.SPECIFIC_VALUE} value={MECHANISM_TYPE.SPECIFIC_VALUE}>
                        {MECHANISM_TYPE.SPECIFIC_VALUE}
                      </Option>
                    ) : null}
                    {attributeProperty && attributeProperty.rangeMechanismAllowed ? (
                      <Option key={MECHANISM_TYPE.RANGE} value={MECHANISM_TYPE.RANGE}>
                        {MECHANISM_TYPE.RANGE}
                      </Option>
                    ) : null}
                    {attributeProperty && attributeProperty.enumMechanismAllowed && attributeProperty.enumerationId ? (
                      <Option key={MECHANISM_TYPE.ENUMERATION} value={MECHANISM_TYPE.ENUMERATION}>
                        {MECHANISM_TYPE.ENUMERATION}
                      </Option>
                    ) : null}
                  </Select>
                )}
              </Form.Item>
            </S.FormField>

            {/* DEFAULT VALUE */}
            <S.FormField>
              {form.getFieldValue(`${SEARCH_ATTRIBUTES},${key},mechanism`) === MECHANISM_TYPE.SPECIFIC_VALUE ? (
                <Form.Item label={"Default Value"}>{form.getFieldDecorator(`${SEARCH_ATTRIBUTES},${key},value`)(
                  <Input/>)}</Form.Item>
              ) : null}
              {form.getFieldValue(`${SEARCH_ATTRIBUTES},${key},mechanism`) === MECHANISM_TYPE.RANGE ? (
                <React.Fragment>
                  <Form.Item label={"Default From"}>{form.getFieldDecorator(`${SEARCH_ATTRIBUTES},${key},from`)(
                    <Input/>)}</Form.Item>
                  <Form.Item label={"Default To"}>{form.getFieldDecorator(`${SEARCH_ATTRIBUTES},${key},to`)(
                    <Input/>)}</Form.Item>
                </React.Fragment>
              ) : null}
              {form.getFieldValue(`${SEARCH_ATTRIBUTES},${key},mechanism`) === MECHANISM_TYPE.ENUMERATION && enumerationValues ? (
                <Form.Item label={"Default Value"}>
                  {form.getFieldDecorator(`${SEARCH_ATTRIBUTES},${key},value`)(
                    <Select>
                      {enumerationValues.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)).map((option, index) => {
                        let value = option.value
                        if(option.toBeTranslated && option.translationKey){
                          value = option.translationKey
                        }
                        return(
                          <Option key={`option-${option.id}-${index}`} value={option.id}>
                            {value}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
            </S.FormField>

            {/* MULTI SELECT ONLY IF MECHANISM IS ENUMERATION */}
            {form.getFieldValue(`${SEARCH_ATTRIBUTES},${key},mechanism`) === MECHANISM_TYPE.ENUMERATION ? (
              <S.FormField>
                <Form.Item label={"MultiSelect"}>
                  {form.getFieldDecorator(`${SEARCH_ATTRIBUTES},${key},multiSelect`, {
                    valuePropName: "checked",
                  })(<Switch/>)}
                </Form.Item>
              </S.FormField>
            ) : null}
          </Panel>
        </Collapse>
      </S.SortableContainer>
    )
  }

  const createSearchAttributes = (attributeKeys) => {
    const res = {}
    attributeKeys.forEach((attributeId, index) => {
      const attributeProperty = (attributeInformation?.attributesFlat || []).find((attribute) => attribute.key === attributeId)
      Object.assign(res, {
        [`${SEARCH_ATTRIBUTES},${attributeId},attributeId`]: {
          name: `${SEARCH_ATTRIBUTES},${attributeId},attributeId`,
          value: attributeId,
        },
        [`${SEARCH_ATTRIBUTES},${attributeId},position`]: {
          name: `${SEARCH_ATTRIBUTES},${attributeId},position`,
          value: index,
        },
        [`${SEARCH_ATTRIBUTES},${attributeId},mechanism`]: {
          name: `${SEARCH_ATTRIBUTES},${attributeId},mechanism`,
          value:
            form.getFieldValue(`${SEARCH_ATTRIBUTES},${attributeId},mechanism`) ||
            MECHANISM_TYPE.retrieveMechanismInitialValue(attributeProperty),
        },
        [`${SEARCH_ATTRIBUTES},${attributeId},value`]: {
          name: `${SEARCH_ATTRIBUTES},${attributeId},value`,
          value: form.getFieldValue(`${SEARCH_ATTRIBUTES},${attributeId},value`) || null,
        },
        [`${SEARCH_ATTRIBUTES},${attributeId},from`]: {
          name: `${SEARCH_ATTRIBUTES},${attributeId},from`,
          value: form.getFieldValue(`${SEARCH_ATTRIBUTES},${attributeId},from`) || null,
        },
        [`${SEARCH_ATTRIBUTES},${attributeId},to`]: {
          name: `${SEARCH_ATTRIBUTES},${attributeId},to`,
          value: form.getFieldValue(`${SEARCH_ATTRIBUTES},${attributeId},to`) || null,
        },
        [`${SEARCH_ATTRIBUTES},${attributeId},multiSelect`]: {
          name: `${SEARCH_ATTRIBUTES},${attributeId},multiSelect`,
          value: form.getFieldValue(`${SEARCH_ATTRIBUTES},${attributeId},multiSelect`) || false,
        },
      })
    })
    return res
  }

  if (!attributeInformation) {
    return <S.Loading>...Loading</S.Loading>
  }
  return (
    <AttributeTransfer
      attributesInformation={attributeInformation}
      renderCard={renderCard}
      attributeType={SEARCH_ATTRIBUTES}
      onAttributeTransfer={createSearchAttributes}
    />
  )
}

export default ObjectListing
