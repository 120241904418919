export const RootDirectory = 'rootDirectory'
export const SubDirectories = 'directories'
export const DirectoryName = 'name'
export const Files = 'files'
export const PATCH_ADD = 'add'
export const PATCH_REMOVE = 'remove'
export const STANDARD_PHOTOS = 'StandardPhotos'

export const FILE_VERSIONS_ENUM = {
  OLD_FILE_VERSION: 'oldFileVersion',
  NEW_FILE_VERSION: 'newFileVersion',
  REDUCED_VERSION: 'reducedVersion'
}

export const UPLOAD_FILE_TYPE = {
  DIRECTORY_DEFINITION: "directoryDefinition",
  FILE_DEFINTION: "fileDefintion"
}

export const IMAGE_MODAL_PREVIEW_TYPE = {
  FROM_DIRECTORY: "fromDirectory",
  FROM_FILE_COLLECTION: "fromFileCollection"
}

export const IMAGE_DEFAULT_OPTIONS = {
  HEIGHT: 100
}

export const ILLEGAL_ARGUMENT_EXCEPTION = "Illegal Argument Exception"
export const REQUIRED_FILE_EXCEPTION = 'This file contains a mandatory field'
