import styled from "styled-components"

export const HeadingError = styled.h1`
  color: red;
`

export const SortableContainer = styled.div``

export const FormField = styled.div``

export const FormDescription = styled.div``

export const Loading = styled.div`
  color: blue;
`

export const PanelHeader = styled.div`
  text-align: center;
`
