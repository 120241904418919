import React from 'react';
import PropTypes from 'prop-types';
import { Field} from 'redux-form';
import { renderField, stepFieldRequired} from '../../utils/fieldValidator';
import { __ } from '../../utils/translationUtils'


class TextEntryControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object,
    type: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.stepFieldValidator = this.stepFieldValidator.bind(this)
  }

  stepFieldValidator(value){

    const { controlData } = this.props;

    let patt = new RegExp(controlData.regex.js);

    // Validate value
    if(value && !patt.test(value) ){ // Vaule is valid.
      return __('valueFormatError');
    }else{ // Value is not valid.
      return undefined;
    }

  }

  stepValidation(){

    const { controlData } = this.props;
    let validation = [];

    if(controlData.required){
      validation.push(stepFieldRequired);
    }

    // Check if field needs to be validated (check if regex exist)
    if( typeof controlData.regex !== 'undefined'
        && typeof controlData.regex.js !== 'undefined'
        && controlData.regex.js !== ''){
      validation.push(this.stepFieldValidator);
    }

    if(validation.length == 0){
      return undefined;
    }


    return validation;
  }

  render() {
    const { controlData, type } = this.props;
    const req = controlData.required?'*':'';

    return (
      <Field
        name={controlData.id}
        description={controlData.description}
        disabled={controlData.readOnly}
        component={renderField}
        label={__(controlData.title)+req}
        className="form-control"
        type= {type || "text"}
        validate={this.stepValidation.bind(this)()}
        />
    );

  }

}

export default TextEntryControl;
