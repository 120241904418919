import React from 'react'
import PropTypes from 'prop-types'
import {Field, reduxForm} from 'redux-form'
import {filter, find, forEach, groupBy} from 'lodash'

import {getFieldValidation, renderField} from '../utils/fieldValidator'
import {Col, Row} from "antd";
import { __ } from '../utils/translationUtils'

class ReportSearchFieldsForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.renderFieldItem = this.renderFieldItem.bind(this)
    this.renderFields = this.renderFields.bind(this)
    this.enumOptions = this.enumOptions.bind(this)
  }

  componentDidMount() {
    if (this.props.componentRef) this.props.componentRef.current = this
  }

  handleReset(e) {
    e.preventDefault()
    this.props.reset()
  }

  handleFormValuesInject(values) {
    Object.keys(values).forEach((key) => {
      this.props.change(key, values[key])
    })
  }

  handleSubmit(e) {
    e?.preventDefault()
    if (typeof this.props.onPageChange !== 'undefined') {
      this.props.onPageChange(1)
    } else {
      this.props.handleSubmit()
    }
  }

  enumOptions(enumValues, enumerationId, requiresobfuscation, field) {
    const optionValues = []

    if (!field.multiSelect) {
      optionValues.push(
        <option key={`select-default-option${enumerationId}`} value="">
          {__('Select one')}
        </option>
      )
    }
    if (enumValues && enumValues[enumerationId]) {
      forEach(enumValues[enumerationId], (o) => {
        if (o.enumerationId && o.value && enumerationId) {
          if (requiresobfuscation && o.type === field.obfuscationValue) {
            optionValues.push(
              <option key={`${o.enumerationId}-${o.value}`} value={o.value}>{(o.toBeTranslated) ? __(o.value) :  o.value}</option>
            )
          } else {
            optionValues.push(
              <option key={`${o.enumerationId}-${o.value}`} value={o.value}>{(o.toBeTranslated) ? __(o.value) :  o.value}</option>
            )
          }
        }
      })
    }
    return optionValues
  }

  renderFieldItem(field, i) {
    const {
      attributesConfiguration,
      enumValues,
      extractConfiguration,
      buttonSize
    } = this.props

    const item = find(attributesConfiguration, (c) => c.id === field.attributeId)
    let type = 'text'

    // Check if item is searchable
    if (!item.searchable) {
      return
    }

    const propertyLabel = field.propertyLabel || item.propertyLabel

    if (item.type && item.type.toLowerCase() === 'boolean') {
      type = 'BOOLEAN'
    } else if (item.type === 'YESNO' || item.type === 'Yesno') {
      type = 'YESNO'
    } else if (item.type === 'DATE') {
      type = 'datetime'
    } else if (item.type === 'DATETIME') {
      type = 'datetime'
    } else {
      type = 'text'
    }
    // RANGE
    if (field.mechanism === 'RANGE') {
      return (
        <Col
          key={`search-mask-${item.propertyLabel}`}
          span={12}
          style={{ display: field.hidden ? 'none' : '' }}
        >
          <div className="col-xs-8">
            <Field
              name={`${item.id}From`}
              className={`form-control`}
              type={type}
              component={renderField}
              label={__(propertyLabel)}
              placeholder={__('From')}
              labelCol="col-md-5"
              inputCol="col-md-7"
              required={field.required}
              validate={getFieldValidation(type, field.required)}
            />
          </div>

          <div className="col-xs-4">
            <div className="row">
              <Field
                name={`${item.id}To`}
                className={`form-control`}
                type={type}
                component={renderField}
                label=""
                inputCol="col-md-12"
                placeholder={__('To')}
                required={field.required}
                validate={getFieldValidation(type, field.required)}
              />
            </div>
          </div>
        </Col>
      )

      // SPECIFIC_VALUE
    } if (field.mechanism === 'SPECIFIC_VALUE') {
      return (
        <Col
          key={`search-mask-${item.propertyLabel}-container-${i}`}
          span={12}
          style={{ display: field.hidden ? 'none' : '' }}
        >
          <div className={`${buttonSize === 'md' ? 'col-xs-8' : 'col-xs-12'}`}>
            {(type && type.toLowerCase() === 'checkbox')
            && (
              <div className="form-group clearfix" key={`search-mask-${item.propertyLabel}`}>
                <label className="col-sm-5 control-label">{__(propertyLabel)}{field.required ? '*' : ''}:</label>
                <div className="col-xs-7">
                  <Field
                    name={item.id}
                    className="form-control"
                    type="select"
                    component={renderField}
                    labelCol="col-md-5"
                    inputCol="col-md-7"
                    required={field.required}
                    validate={getFieldValidation(type, field.required)}
                  >
                    <option value="">{__('Select one')}</option>
                    <option value="true">{__('val_true')}</option>
                    <option value="false">{__('val_false')}</option>
                  </Field>
                </div>
              </div>
            )}
            {(type && type.toLowerCase() === 'yesno')
            && (
              <div className="form-group clearfix" key={`search-mask-${item.propertyLabel}`}>
                <label className="col-sm-5 control-label">{__(propertyLabel)}{field.required ? '*' : ''}:</label>
                <div className="col-xs-7">
                  <Field
                    name={item.id}
                    className="form-control"
                    type="select"
                    component={renderField}
                    labelCol="col-md-5"
                    inputCol="col-md-7"
                    required={field.required}
                    validate={getFieldValidation(type, field.required)}
                  >
                    <option value="">{__('Select one')}</option>
                    <option value="true">{__('val_yes')}</option>
                    <option value="false">{__('val_no')}</option>
                  </Field>
                </div>
              </div>
            )}
            {(type !== 'checkbox') && type !== 'Yesno'
            && (
              <Field
                key={`search-mask-${item.propertyLabel}`}
                name={item.id}
                className={type !== 'checkbox' ? 'form-control' : ''}
                type={type}
                labelCol="col-md-5"
                inputCol="col-md-7"
                component={renderField}
                label={__(propertyLabel)}
                placeholder={__(propertyLabel)}
                required={field.required}
                validate={getFieldValidation(type, field.required)}
              />
            )}
          </div>
        </Col>
      )

      // ENUMERATION
    } if (field.mechanism === 'ENUMERATION') {
      const enumId = extractConfiguration(field.attributeId).enumerationId
      const requiresobfuscation = (field.obfuscationMethod != null && field.obfuscationValue != null)
      const label = __(propertyLabel) + (field.required ? '*' : '')
      if (item.type === 'ENTITY') {
        return (
          <Col
            span={12}
            style={{ display: field.hidden ? 'none' : '' }}
            key={`search-mask-${item.propertyLabel}`}
          >
            <div className="col-xs-8">
              <div
                className="form-group clearfix"
                key={`search-mask-${item.name}`}
              >

                <Field
                  key={item.id}
                  name={item.id}
                  component={renderField}
                  multiple={field.multiSelect}
                  enumId={enumId}
                  label={label}
                  className="form-control"
                  items={this.enumOptions(enumValues, enumId, requiresobfuscation, field)}
                  type="dropdownType"
                  required={field.required}
                  attributesConfiguration={attributesConfiguration}
                  validate={getFieldValidation(type, field.required)}
                  labelCol="col-md-5"
                  inputCol="col-md-7"
                />
              </div>
            </div>
          </Col>
        )
      }
      return (
        <div
          className="col-xs-6"
          style={{ display: field.hidden ? 'none' : '' }}
          key={`search-mask-${item.propertyLabel}`}
        >
          <div className="col-xs-8">
            <div
              className="form-group clearfix"
              key={`search-mask-${item.name}`}
            >

              <Field
                key={item.id}
                name={item.id}
                component={renderField}
                multiple={field.multiSelect}
                label={label}
                className="form-control"
                items={this.enumOptions(enumValues, enumId, requiresobfuscation, field)}
                type="select"
                required={field.required}
                validate={getFieldValidation(type, field.required)}
                labelCol="col-md-5"
                inputCol="col-md-7"
              />
            </div>
          </div>
        </div>
      )
    }
  }

  renderFields() {
    const {
      attributesConfiguration,
      extractConfiguration,
      fields
    } = this.props

    const _fields = [].concat(fields)
    const groups = groupBy(_fields, (f) => (extractConfiguration(f.attributeId)
      ? extractConfiguration(f.attributeId).dependenciesLabel : ''))
    const groupKeys = Object.keys(groups)

    if (attributesConfiguration?.length === 0) {
      return
    }

    return groupKeys.map((groupKey, idx) => {
      // if there is no visible field for this group, hide titke
      const visibleFieldsCount = filter(groups[groupKey], (f) => !f.hidden).length

      return (
        <div key={`search-group-${idx}`} className="col-xs-12">
          {visibleFieldsCount > 0 && groupKey !== 'undefined' && groupKey != ''
          && <h5>{__(groupKey)}</h5>}
          <Row>
            {groups[groupKey].map(this.renderFieldItem)}
          </Row>
        </div>
      )
    })
  }

  render() {
    const {
      isLoading,
      fields,
      buttonSize,
      componentRef
    } = this.props

    return (
      <form className="report-search-masks">
        <div className="row">
          {this.renderFields()}

          {filter(fields, (f) => !f.hidden).length === 0
          && (
            <div className="text-cetner">
              {__('There are no Search Fields available')}
            </div>
          )}
        </div>

        {filter(fields, (f) => !f.hidden).length > 0 && !componentRef
          && (
            <div className="row sticky">
              <div className="col-xs-12">
                <div className="col-sm-2 col-xs-12 gutter-top pull-left text-center">
                  <a onClick={(e) => this.handleReset(e)}>{__('Clear All')}</a>
                </div>
                <div className="col-sm-3 col-xs-12 gutter-top pull-right text-center">
                  <button
                    onClick={this.handleSubmit}
                    disabled={isLoading}
                    className={`btn btn-${buttonSize} btn-primary ${buttonSize === 'md' ? 'btn-block' : 'pull-right'} btn-submit`}
                  >
                    {__('Search')}
                  </button>
                </div>
              </div>
            </div>
          )}

      </form>
    )
  }
}

ReportSearchFieldsForm.propTypes = {
  enumValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  guiUser: PropTypes.object,
  buttonSize: PropTypes.string,
  extractConfiguration: PropTypes.func,
  reportSpec: PropTypes.object,
  setShowAllOrders: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  fields: PropTypes.array.isRequired,
  sortAttributes: PropTypes.array.isRequired,
  attributesConfiguration: PropTypes.array.isRequired
}

ReportSearchFieldsForm.defaultProps = { buttonSize: 'md' }

export default reduxForm({ form: 'reportSearchFieldsForm', initialValues: this?.props?.initialValues })(ReportSearchFieldsForm)
