import { BUILD_VERSION as _buildVersion } from './server-variables'

export const E_GLOBE = 'https://www.e-globe-solutions.com'
export const GODOO = 'https://www.godoo.ch/'
export const API_VERSION = 'v1.0'
export const BUILD_VERSION = _buildVersion
export const CLIENT_VERSION = `3.0.0 b:#${BUILD_VERSION}`

export function setApiRelease(headers) {
  API_BUILD = headers['x-api-build']
  API_BRANCH = headers['x-api-branch']
  API_COMMIT = headers['x-api-commit']
  if (headers['x-api-host']) {
    GODOO_HOST = headers['x-api-host'].split('/')[0]
  }
  API_RELEASE_STRING = `#${API_BUILD
    } Branch: ${API_BRANCH
    } Commit:${API_COMMIT}`
}
export let GODOO_HOST = ''
export let API_RELEASE_STRING = ''
export let API_BUILD = ''
export let API_BRANCH = ''
export let API_COMMIT = ''

export const getEnvConfig = () => {
  const customEnv = localStorage.getItem('env')
  return customEnv && JSON.parse(customEnv) || window.__env__
}

export const getApiUrl = () => {
  const env = getEnvConfig()
  const baseUrl = env?.API_URL || `${window.location.protocol}//${window.location.hostname}`
  return `${baseUrl}/${API_VERSION}`
}

export const prefix = () => {
  const env = getEnvConfig()

  return {
    CALENDAR: env?.API_CALENDAR_PREFIX || '',
    TRANSLATION: env?.API_TRANSLATION_PREFIX || '',
    REPORT: env?.API_REPORT_PREFIX || ''
  }
}

export const ADD_ASTERISKS_TO_SEARCH_VALUES = true
export const TYPES_NO_ASTERISKS_ALLOWED = ['IDS', 'DATE', 'BOOLEAN', 'YESNO']
// export  const ordersDefaultUrl = '/orders?pageNumber=1&pageSize=50&showAllOrders=true&showOnlyActiveOrders=true&sort=nextActionDatetime';
export const ordersDefaultUrl = '/'
export const HOME_URL = '/'

export const BILLING_CUSTOM_CATEGORY = 'Travail sur devis'
export const NORMAL_STEP_DISCRIMINATOR = 0
export const ASSIGNMENT_STEP_DISCRIMINATOR = 1
export const APPONINTMENT_STEP_DISCRIMINATOR = 2
export const REASSIGNMENT_STEP_DISCRIMINATOR = 3

export const TOTAL_PINNED_ITEMS_WEB = 5
export const TOTAL_PINNED_ITEMS_MOBILE = 4

export const DEFAULT_PAGE_SIZE = 25
export const MAX_REPORT_EXPORT_SIZE = 2000
export const pageSizeOptions = [10, 25, 50, 100]
export const DEFAULT_PAGE_SIZE_OBJECTLIST = 25
export const pageSizeOptionsObjectList = [10, 25, 50, 100]
export const MAX_PAGE_SIZE = 10000

export const CUSTOM_LOGO = 'customLogo';
export const CUSTOM_LOGO_NAME = 'logo-full.png';

export const entityTypes = {
  organisation: {
    name: 'Organisation',
    alias: 'organisation'
  },
  user_group: {
    name: 'User Group',
    alias: 'userGroup'
  }
}

export const DEVICE_TYPES = {
  MOBILE: 'mobile',
  WEB: 'webui',
  ADAPTER: 'adapter'
}

export const DEVICE_NAMES = {
  [DEVICE_TYPES.MOBILE]: 'Mobile App',
  [DEVICE_TYPES.WEB]: 'Web App',
  [DEVICE_TYPES.ADAPTER]: 'Adapter'
}

export const appDefaults = {
  dateFormat: 'DD.MM.YYYY',
  dateFormatFiles: 'YYYYMMDD-HHmmss',
  dateTimeFormat: 'DD.MM.YYYY HH:mm',
  timeFormat: ' HH:mm',
  hourFormat: 'HH:mm'
}
export const remoteObjectDetailsUrl = "remoteObjects/details"

export const discriminators = {
  ACTION: "0",
  ASSIGN_TO: "1",
  GO_TO: "2",
  LOCAL_FIELD_DATA: "3",
  LOCAL_BUTTON_ACTION_DATA: "5",
  LOCAL_ORDER_DATA: "7",
  LOCAL_PROCESS_DATA: "8",
  LOCAL_STEP_DATA: "9",
  LOCAL_USER_DATA: "10",
  SOUTH_BRIDGE_DATA: "11",
  STATIC_DATA: "12",
  GO_TO_IF_GREATER: "13",
  GO_TO_IF_GREATER_EQUALS: "14",
  GO_TO_IF_EQUALS: "15",
  GO_TO_IF_TRUE: "16",
  NEGATE: "17",
  CANCEL: "18",
  REJECT: "19",
  COMPLETE: "20",
  MESSAGE: "21",
  ALERT: "22",
  SET_BILLABLE: "23",
  SET_INTERNAL_ORDER_STATUS: "24",
  SET_INTERNAL_PROCESS_STATUS: "25",
  SET_ORDER_STATUS: "26",
  SET_PROCESS_STATUS: "27",
  SET_TOKENS: "28",
  SET_ON_HOLD: "29",
  SEND_EMAIL: "30",
  SETTINGS_DATA: "31",
  FORMAT_STRING: "32",
  AND: "33",
  EQUALS: "34",
  GREATER: "35",
  GREATER_EQUALS: "36",
  OR: "37",
  XOR: "38",
  SKIP_SAVE: "39",
  SKIP_SAVE_IF_TRUE: "40",
  BACK: "41",
  SET_PARENT_OWNERSHIP: "42",
  RESTART_PARENT_PROCESS: "43",
  RETURN: "44",
  SET_EXTERNAL_ORDER_STATUS: "45",
  CURRENT_DATETIME_DATA: "46",
  POP_PROCESS_STACK: "47",
  LOCAL_ENTITY_ORDER_DATA: "48",
  SET_PROCESS_NEXT_ACTION_DATE: "49",
  RESET_APPOINTMENT: "50",
  SET_EDITABLE: "51",
  REASSIGN_PROCESS: "52",
  GO_TO_NEXT_PROCESS: "53",
  BINARY_DATA: "54",
  DOCUMENT_GENERATOR_DATA: "55",
  SET_REMOTE_VALUE: "56",
  FIRE_BILLING_EVENT: "57",
  CREATE_REMOTE_OBJECT_FROM_WORKSHEET: "58",
  IF: "59",
  CREATE_WORKSHEET_FROM_REMOTE_OBJECT: "60",
  ASSIGN: "61",
  REMOTE_OBJECT_BINDING: "62",
  RESET: "63",
  SET_MASTER: "64",
  LOGOUT: "65",
  STRING_UTILS_JOIN: "80",
}
