import {connect} from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import {Button, DropdownButton, MenuItem, Modal} from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import objectAssign from 'object-assign'
import {change, formValueSelector} from 'redux-form'
import {stringify} from 'query-string'
import {Tooltip} from 'antd'
import {Icon} from '@ant-design/compatible'
import {SettingOutlined} from '@ant-design/icons';
import {api} from '../providers/ApiProvider'
import {
  addAsterisks,
  checkUserRights,
  getInitialPageSize,
  renderResultInfo,
  searchToObject,
  setInitialPageSize,
  showMessage
} from '../utils/appHelper'
import {deleteEntity, reopenEntity} from '../providers/ReduxProvider/actions/entityActions'
import * as config from '../constants/globalConfiguration'
import {hashHistory} from '../providers/HistoryProvider'
import {__} from '../utils/translationUtils'
import {ScrollableListView, StickyScrollableTable} from "../providers/StyleProvider/styles";

const loadingBar = require('nprogress')
const moment = require('moment')
const BasicSearchForm = require('../components/BasicSearchForm').default

class EntitiesPage extends React.Component {
  static propTypes = {
    deleteEntity: PropTypes.func,
    reopenEntity: PropTypes.func,
    change: PropTypes.func,
    q: PropTypes.string,
    entityType: PropTypes.string,
    location: PropTypes.object,
    user: PropTypes.object,
    showDeleted: PropTypes.bool
  };

  constructor(props) {
    super(props)
    this.entityType = config.entityTypes[this.props.entityType]
    this.isUserGroup = this.props.entityType === 'user_group'
    this.state = {
      deleteModal: {
        open: false,
        entity: {
          name: '',
          id: 0
        }
      },
      restoreModal: {
        open: false,
        entity: {
          name: '',
          id: 0
        }
      },
      data: [],
      searchOpen: true,
      isLoading: false,
      pagination: {
        pageNumber: 1,
        pageSize: getInitialPageSize('entitiesPage'),
        totalCount: 0
      },
      sort: 'shortName'
    }
  }

  componentDidMount() {
    // initial results
    this.hashHistoryListener = hashHistory.listen((location) => {
      const searchParams = searchToObject(this.props.location.search)
      this.props.change('basicSearchForm', 'q', searchParams.q || '')
      if (location.action === 'POP' && (location.pathname == '/organisations' || location.pathname == '/userGroups')) {
        this.getData()
      }
    })

    // get initial data
    this.getData()
  }

  componentWillUnmount() {
    this.hashHistoryListener()
  }

  getParams = () => {
    const params = {...searchToObject(this.props.location.search)}
    return objectAssign({}, {
      type: this.props.entityType,
      sort: this.state.sort || params.sort,
      pageNumber: (parseInt(this.state.pagination.pageNumber) > -1) ? parseInt(this.state.pagination.pageNumber) : params.pageNumber,
      pageSize: this.state.pagination.pageSize || params.pageSize,
      q: this.props.q ? addAsterisks(this.props.q) : undefined
    })
  }

  updateHash = () => {
    // update url params
    const params = this.getParams()

    hashHistory.push({
      ...this.props.location,
      search: stringify(params)
    }, this.getData())
  }

  search = () => {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: this.state.pagination.pageSize,
          pageNumber: 1
        }
      )
    })
    this.getData()
  }

  getData = () => {
    this.setState({isLoading: true})
    loadingBar.start()

    const params = {...this.getParams.bind(this)(), pageNumber: this.getParams().pageNumber}

    // Add status depending on the "showDeleted" checkbox.
    params.status = this.props.showDeleted ? 'disabled,deleted,enabled,reserved' : 'disabled,enabled,reserved'

    return api.get('/entities/', {params})
      .then(
        (response) => {
          this.setState({
            data: response.data,
            isLoading: false,
            pagination: objectAssign(
              this.state.pagination, {
                totalCount: parseInt(response.headers['x-total-count']),
                pageSize: parseInt(response.headers['x-page-size']),
                pageNumber: parseInt(response.headers['x-page'])
              }
            )
          })
          loadingBar.done()
        }
      )
  }

  onSortChange(sortName, sortOrder) {
    this.setState({sort: ((sortOrder === 'asc') ? '' : '-') + sortName}, function () {
      this.updateHash()
    })
  }

  onPageChange(page) {
    this.setState({
        pagination: objectAssign(
          this.state.pagination, {pageNumber: page}
        )
      },
      () => {
        this.getData()
      })
  }

  confirmRestore(entity) {
    this.setState({restoreModal: {open: true, entity}})
  }

  handleRestore() {
    this.props.reopenEntity(this.state.restoreModal.entity).then(() => {
      this.getData()
    })
    // Close modal:
    this.closeRestoreModal()
  }

  closeRestoreModal() {
    this.setState({restoreModal: {...this.state.restoreModal, open: false}})
  }

  confirmDelete(entity) {
    this.setState({deleteModal: {open: true, entity}})
  }

  handleDelete() {
    this.props.deleteEntity(this.state.deleteModal.entity).then(() => {
      this.getData()
    })
    // Close modal:
    this.closeDeleteModal()
  }

  closeDeleteModal() {
    this.setState({deleteModal: {...this.state.deleteModal, open: false}})
  }

  setPageSize(val) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
          totalCount: 0,
          pageSize: val,
          pageNumber: 1
        }
      )
    }, () => {
      setInitialPageSize('entitiesPage', val)
      this.getData()
    })
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.setPageSize.bind(this)}
        bsStyle="link"
        title={this.state.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config?.pageSizeOptions?.map((val, i) => <MenuItem key={i} eventKey={val}>{val}</MenuItem>)}
      </DropdownButton>
    )
  }

  trClassFormat(row) {
    return row.status // return class name.
  }

  render() {
    const {rightMappings} = global.constants

    // Check user rights
    if (this.props.user && this.props.user.rights) {
      if (!checkUserRights(this.props.user.rights, rightMappings.CAN_CREATE_ENTITY)) {
        hashHistory.push('/')
        showMessage('error', __('NoAccessForThisPage'))
      }
    }

    let userRights = []
    if (this.props.user) {
      userRights = this.props.user.rights
    }

    function parentFormater(cell, row) {
      return <span>{(typeof row.parent !== 'undefined') && row.parent.name}</span>
    }

    function linkFormater(cell, row) {
      return <Link className="stronger" to={`/${this.entityType.alias}s/${row.id}`}>{row.shortName}</Link>
    }

    function actionsFormater(cell, row) {
      return (
        <div className="actions">
          {row.status !== 'deleted'
            && (
              <div style={{display: 'flex', justifyContent: "center"}}>
                <Tooltip title={__('calendar')}><Link
                  to={`/${this.props.entityType === 'organisation' ? 'organisations' : 'userGroups'}/${row.id}/calendar`}>
                  <div className="btn-no-padding btn-std"><Icon className="icon-std" type="calendar" theme="outlined"/>
                  </div>
                </Link></Tooltip>
                {checkUserRights(userRights, rightMappings.CAN_MANAGE_ENTITY_SETTINGS) && row.accessRightsInfo?.edit &&
                  <Tooltip title={__('settings')}><Link
                    to={`/${this.props.entityType === 'organisation' ? 'organisations' : 'userGroups'}/${row.id}/settings`}>
                    <div className="btn-no-padding btn-std"><SettingOutlined/></div>
                  </Link></Tooltip>
                }
                {row.accessRightsInfo?.delete &&
                  <Tooltip title={__('delete')}>
                    <div className="btn-no-padding btn-std" onClick={() => this.confirmDelete.bind(this)(row)}><Icon
                      className="icon-delete" type="delete" theme="outlined"/></div>
                  </Tooltip>
                }
              </div>
            )}
          {row.status === 'deleted'
            && row.accessRightsInfo?.edit && (
              <Tooltip title={__('Restore')}>
                <div className="btn-std btn-no-padding"
                     disabled={typeof row.parent === 'undefined' || row.parent.id === ''}
                     onClick={() => this.confirmRestore.bind(this)(row)}><Icon className="icon-std" type="retweet"
                                                                               theme="outlined"/></div>
              </Tooltip>
            )}
        </div>
      )
    }

    return (
      <ScrollableListView id="main-content">

        <h1 className="sticky">
          <div className="container">
            {__(`${this.entityType.alias}ManagementTitle`)}
          </div>
        </h1>

        <div className="container">

          <div className="row">

            <div className="col-lg-10 col-lg-offset-1 col-sm-12">
              {checkUserRights(userRights, rightMappings.CAN_CREATE_ENTITY)
                && (
                  <Link className="btn btn-success col-xs-12 col-sm-3 gutter-bottom"
                        to={`/${this.entityType.alias}s/create`}>
                    <Glyphicon glyph="plus"/>
                    {__(`create ${this.entityType.name}`)}
                  </Link>
                )}

              <div className="col-xs-12 col-sm-6 col-lg-4">
                <BasicSearchForm
                  isLoading={this.state.isLoading}
                  itemType={this.entityType.alias}
                  initialValues={{
                    q: searchToObject(this.props.location.search).q,
                    showDeleted: false
                  }}
                  onSubmit={this.search.bind(this)}
                />
              </div>

              <div className=" col-xs-12 col-sm-3 col-lg-4  pull-right text-right">
                {checkUserRights(userRights, rightMappings.CAN_DELETE_ENTITY)
                  && (
                    <label>
                      <input
                        style={{marginRight: '8px'}}
                        type="checkbox"
                        onChange={(event) => {
                          this.props.change(
                            'basicSearchForm',
                            'showDeleted',
                            event.target.checked
                          )
                          setTimeout(() => {
                              this.getData()
                            },
                            500)
                        }}
                      />
                      {__(`Show deleted ${this.entityType.alias}s`)}
                    </label>
                  )}
              </div>

            </div>

          </div>

          {this.state.data
            && (
              <StickyScrollableTable
                data={this.state.data}
                remote
                pagination
                /* hover */
                striped
                trClassName={this.trClassFormat}
                fetchInfo={{dataTotalSize: this.state.pagination.totalCount}}
                options={{
                  onPageChange: this.onPageChange.bind(this),
                  onSortChange: this.onSortChange.bind(this),
                  noDataText: this.state.isLoading ? __('loading_data') : __('NoDataFound'),
                  hideSizePerPage: false,
                  firstPage: 'First',
                  lastPage: 'Last',
                  sizePerPageList: config.pageSizeOptions,
                  paginationShowsTotal: renderResultInfo.bind(this),
                  page: this.state.pagination.pageNumber,
                  sizePerPage: this.state.pagination.pageSize
                }}
              >

                <TableHeaderColumn
                  hidden
                  isKey
                  dataField="id"
                  dataSort
                >
                  {__('Id')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  // hidden={this.isUserGroup}
                  dataFormat={linkFormater.bind(this)}
                  dataSort
                  dataField="shortName"
                >
                  {__('Short Name')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="name"
                  dataSort
                >
                  {__(`${this.entityType.name} Name`)}
                </TableHeaderColumn>

                <TableHeaderColumn
                  hidden={this.isUserGroup}
                  dataFormat={(cell) => config.entityTypes[cell].name}
                  dataField="type"
                  dataSort
                >
                  {__('Type')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="belongsTo.name"
                  dataFormat={parentFormater.bind(this)}
                  dataSort
                >
                  {__('Parent')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="lastUpdateDatetime"
                  dataSort
                  // width=""
                  dataFormat={(val) => moment(val).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                >
                  {__('LastModifiedDatetime')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  width="150"
                  hidden={!checkUserRights(userRights, rightMappings.CAN_DELETE_ENTITY)}
                  dataFormat={actionsFormater.bind(this)}
                >
                </TableHeaderColumn>

              </StickyScrollableTable>
            )}

          <Modal
            backdrop="static"
            show={this.state.deleteModal.open}
            onHide={this.closeDeleteModal.bind(this)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{__('Confirm Deletion')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{__('Are you sure you want to delete')} "<strong>{this.state.deleteModal.entity.name}</strong>"?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeDeleteModal.bind(this)}>
                {__('Back')}
              </Button>
              <Button onClick={this.handleDelete.bind(this)} bsStyle="danger">
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            backdrop="static"
            show={this.state.restoreModal.open}
            onHide={this.closeRestoreModal.bind(this)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{__('Confirm Restore')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{__('Are you sure you want to restore')} "<strong>{this.state.deleteModal.entity.name}</strong>"?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeRestoreModal.bind(this)}>
                {__('Back')}
              </Button>
              <Button onClick={this.handleRestore.bind(this)} bsStyle="success">
                {__('Restore')}
              </Button>
            </Modal.Footer>
          </Modal>

        </div>

      </ScrollableListView>
    )
  }
}

const selector = formValueSelector('basicSearchForm')
export default connect(
  (state) => ({
    user: state.user.data,
    q: selector(state, 'q'),
    showDeleted: selector(state, 'showDeleted')
  }),
  {deleteEntity, reopenEntity, change}
)(EntitiesPage)
