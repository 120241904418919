import React, {useEffect, useState} from 'react'

import {Button, Card, Collapse, Descriptions, Empty, Radio, Timeline, Tooltip, Typography} from 'antd'
import {ControlOutlined, UserAddOutlined,} from '@ant-design/icons';
import moment from "moment";

import * as S from "./styles"
import * as U from "./utils";
import {getUserHistory} from "../../utils/appHelper";
import {api} from "../../providers/ApiProvider";
import ActionDescription from "./components/ActionDescription";
import AuthorDetails from "./components/AuthorDetails";
import ActionsTags from "./components/ActionsTags";
import FiltersDrawer from "./components/FiltersDrawer"
import {appDefaults} from "../../constants/globalConfiguration";
import {__} from '../../utils/translationUtils'

const {Text} = Typography;
const {Panel} = Collapse;


const TimelineHeader = ({history, setOpenFilters, setExpandView}) => (
  <S.TimelineHeader>
    <Button
      icon={<ControlOutlined/>}
      type="primary"
      onClick={() => setOpenFilters(true)}
      disabled={!history || history.length === 0}
    >
      {__('Filters')}
    </Button>
    <Tooltip title={__('Listing View Mode')}>
      <Radio.Group
        size="small"
        defaultValue='compressed'
        disabled={!history || history.length === 0}
        onChange={(e) => {
          setExpandView(e.target.value === 'expanded')
        }}
      >
        {
          [{value: 'compressed', label: 'Compressed'}, {value: 'expanded', label: 'Expanded'}].map((option) => (
            <Radio.Button value={option.value} key={option.value}>{__(option.label)}</Radio.Button>
          ))
        }
      </Radio.Group>
    </Tooltip>
  </S.TimelineHeader>
)

const TimelineAuthor = ({author}) => (
  <div>
    <Text type="secondary">{__("Author")}:</Text>
    <AuthorDetails
      author={author}
      label={
        <Text type="primary" style={{paddingLeft: "5px", cursor: "default"}} strong>
          {author?.username}
        </Text>
      }
    />
  </div>
)

const TimelineActionsDescription = ({actions, expanded, status}) => (
  <Descriptions column={1} bordered size={!expanded && "small"}>
    {actions.map((action) => {
        return (
          <Descriptions.Item key={action.name} label={__(action.name)}>
            <ActionDescription action={action} status={status}/>
          </Descriptions.Item>
        )
      }
    )}
  </Descriptions>
)

const TimelineItemContent = ({actions, expandedView, status}) => (
  <>
    <TimelineAuthor author={actions.creator}/><br/>
    <Card title={!expandedView && <ActionsTags actions={actions.updatedFields} status={status}/>}>
      {!expandedView ?
        <S.GhostCollapse ghost expandIconPosition="right">
          <Panel header="Details" key="1">
            <TimelineActionsDescription
              actions={actions.updatedFields}
              expanded={expandedView}
              status={status}
            />
          </Panel>
        </S.GhostCollapse>
        :
        <TimelineActionsDescription
          actions={actions.updatedFields}
          expanded={expandedView}
          status={status}
        />
      }
    </Card>
  </>
)


const TimelineDate = ({date}) => {
  if (!date) return null
  return (
    <>
      <div> {moment(date).format(appDefaults.dateTimeFormat)} </div>
      <div><Text type="secondary"> {moment(date).fromNow()} </Text></div>
    </>
  )
}

const UserHistory = ({userId, status}) => {

  const [history, setHistory] = useState(undefined)
  const [filteredHistory, setFilteredHistory] = useState(undefined)
  const [expandedView, setExpandView] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchHistory = async () => {
      await api.get(getUserHistory(userId)).then((resp) => {
        const userHistory = U.getMappedUserHistory(resp.data)
        setHistory(userHistory)
        setFilteredHistory(userHistory)
      }).catch((err) => console.log(err))
      setLoading(false)
    }
    setLoading(true)
    fetchHistory()
  }, [userId])

  return (
    <>
      <FiltersDrawer
        history={history}
        setFilteredHistory={setFilteredHistory}
        open={openFilters}
        setOpen={setOpenFilters}
      />
      {loading ? <S.FullSpaceSpin/> :
        <S.Relative>
          <TimelineHeader
            history={history}
            setOpenFilters={setOpenFilters}
            setExpandView={setExpandView}
          />
          {!history ? <Empty description={__("Error")}/> :
            (filteredHistory?.length === 0 ? <Empty/> :
                <S.CustomTimeline mode="left" className="left-timeline">
                  {filteredHistory?.map((actions) => (
                    <Timeline.Item
                      dot={actions.revision === 0 && <UserAddOutlined/>}
                      key={actions.creationDatetime + actions.creatorId}
                      label={<TimelineDate date={actions.creationDatetime}/>}
                    >
                      <TimelineItemContent
                        actions={actions}
                        expandedView={expandedView}
                        status={status}
                      />
                    </Timeline.Item>
                  ))}
                  {history[history.length - 1].revision > 0 &&
                    <Timeline.Item color="grey">
                      <Text type="secondary">{__("Some old user changes are not available")}</Text>
                    </Timeline.Item>
                  }
                </S.CustomTimeline>
            )
          }
        </S.Relative>
      }
    </>
  )
}

export default UserHistory
