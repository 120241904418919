import {connect} from 'react-redux';
import React from 'react';
import Glyphicon from '@strongdm/glyphicon'
import PropTypes from 'prop-types';
import {change, getFormValues} from 'redux-form';
import _ from 'lodash';

import {showMessage} from '../utils/appHelper';
import {updateProcessOverviewObjects} from '../providers/ReduxProvider/actions/processOverviewObjectActions';
import { api } from '../providers/ApiProvider'
import {getGuiUser} from './../providers/ReduxProvider/actions/userActions';
import { __ } from '../utils/translationUtils'

const loadingBar = require('nprogress');

class ProcessOverviewObjectsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributesConfiguration: null,
      associations: null,
      objectId: this.props.match.params.id,
      flowVersion: this.props.match.params.flowVersion,
      newTabName: '',
      selectedObject: '',
      selectedProcess: '',
      entities: null,
      dateFormats: [],
      overviewTabs: null,
      tabNames: {},
      processObjectPairs: [],
      flow: {
        name: '',
      },
      initialOrderTypeValues: null,
      isLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getPublishedFlows = this.getPublishedFlows.bind(this);
    this.getPairs = this.getPairs.bind(this);
    this.getOverviewObjects = this.getOverviewObjects.bind(this);
    this.addObjectToProcess = this.addObjectToProcess.bind(this);
    this.getCurrentAssociations = this.getCurrentAssociations.bind(this);
    this.removeObjectFromProcess = this.removeObjectFromProcess.bind(this);
    this.renderProcessObjects = this.renderProcessObjects.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  removeObjectFromProcess(objectId, processId) {
    const { processObjectPairs } = this.state;
    let _processObjectPairs = [].concat(processObjectPairs);

    _.remove(_processObjectPairs, (obj) =>
      obj.processId === processId && obj.overviewObjectId === objectId
    );
    this.setState({ processObjectPairs: _processObjectPairs });
  }

  addObjectToProcess(e) {
    e.preventDefault();
    const {
      selectedProcess,
      selectedObject,
      processObjectPairs,
      overviewObjects,
      flow,
    } = this.state;

    if (!selectedObject || !selectedProcess || selectedProcess === '' || selectedObject === '') {
      showMessage('error',  'Please select both an object and a target process.');
      return;
    }

    let _processObjectPairs = [].concat(processObjectPairs);
    const _obj = _.find(overviewObjects, (obj) => obj.id === selectedObject);
    // Add object to the process
    _processObjectPairs.push({
      flowId: flow.flowId,
      flowVersion: flow.flowVersion,
      processId: selectedProcess,
      overviewObjectId: selectedObject,
      configuration: _obj,
    });
    this.setState({ processObjectPairs: _processObjectPairs });
  }

  init(){
    this.getPublishedFlows().then(() => {
      this.getPairs();
      this.getOverviewObjects();
      this.getCurrentAssociations();
    });
  }

  getPairs() {
    const { flow, flowVersion } = this.state;
    return api.get(`publishedFlows/${flow.flowId}/${flowVersion}/processOverviewObjects`)
    .then(
      response => {
        this.setState({
          processObjectPairs: response.data,
          isLoading: false,
        });
        loadingBar.done();
      }
    );
  }

  getOverviewObjects() {
    const { flow } = this.state;
    return api.get(`/publishedFlows/${flow.flowId}/${flow.flowVersion}/configurations/overviewObjects?sort=name&pageNumber=1&pageSize=200`)
    .then(
      response => {
        this.setState({
          overviewObjects: response.data,
          isLoading: false,
        });
        loadingBar.done();
      }
    );
  }

  getCurrentAssociations() {
    const { flow } = this.state;
    return api.get(`/publishedFlows/${flow.flowId}/${flow.flowVersion}/processOverviewObjects`)
    .then(
      response => {
        this.setState({
          associations: response.data,
          isLoading: false,
        });
        loadingBar.done();
      }
    );
  }

  getPublishedFlows(){
    return api.get('/publishedFlows?pageSize=300&sort=-creationDatetime')
    .then(
      response => {
        this.setState({
          publishedFlows: response.data,
          flow: _.find(response.data, (flow) => flow.flowId === this.props.match.params.id && flow.flowVersion.toString() === this.state.flowVersion),
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  handleSubmit(processId) {
    const { flow, processObjectPairs } = this.state;

    const payload = _.filter(processObjectPairs, (obj) => {
      return obj.processId === processId;
    });

    this.props.updateProcessOverviewObjects({
      payload,
      flowId: flow.flowId,
      flowVersion: flow.flowVersion,
      processId: processId,
    });
  }

  renderProcessObjects(processId) {
    const { processObjectPairs } = this.state;
    const found = _.filter(processObjectPairs, (obj) => {
      return obj.processId === processId;
    });

    if (found.length === 0) {
      return (
        <i className="discreet">
          No objects added yet.
        </i>
      );
    }

    return found && found.map((obj) => {
      return (
        <div
          className="tab-configuration-item"
          key={obj.configuration.id}
        >
          <Glyphicon
            onClick={() => {
              this.removeObjectFromProcess(obj.configuration.id, processId);
            }}
            className="remove-icon" glyph="remove"
          />
          {__(obj.configuration.name)}
        </div>
      );
    });
  }

  render() {
    const { flow, associations, overviewObjects } = this.state;

    if (!flow || !associations) {
      return null;
    }

    return (
      <div id="main-content" className="process-object-pairs-management-page">
        <h1 className="sticky edit">
          <div className="container">
            {flow.title}: { __('Process Overview Object Visibility') }
          </div>
        </h1>

        <div className="container">
          <br/>
          { this.state.flow ?
            <div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="panel">
                    <div className="panel-body add-object-to-tab-panel" >
                      <form className="form-inline">
                        <div className="form-group">
                          <label>Add&ensp;&ensp;</label>
                          <select
                            onChange={(e) => {
                              this.setState({ selectedObject: e.target.value });
                            }}
                            className="form-control"
                          >
                            <option value="">Select an Object...</option>
                            {
                              overviewObjects && overviewObjects.map((object) => {
                                return <option key={object.id} value={object.id}>{__(object.name)}</option>;
                              })
                            }
                          </select>
                        </div>
                        <div className="form-group">
                          <label>&ensp;&ensp;To&ensp;&ensp;</label>
                          <select
                            onChange={(e) => {
                              this.setState({ selectedProcess: e.target.value });
                            }}
                            className="form-control"
                          >
                            <option value="">Select a Tab...</option>
                            {
                              flow && flow.processes && flow.processes.map((process) => {
                                return (
                                  <option
                                    key={process.processId}
                                    value={process.processId}
                                  >
                                    {__(process.processTitle)}
                                  </option>
                                );
                              })
                            }
                          </select>
                        </div>
                        &ensp;&ensp;
                        <button
                          onClick={this.addObjectToProcess}
                          type="submit"
                          className="btn btn-default"
                        >
                          Add Object
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="flex-items">
                  {
                    flow && flow.processes.map((process) => {
                      return (
                        <div
                          className="col-sm-6 flex-box-item"
                          key={process.processId}
                          value={process.processId}
                        >

                          <div className="panel panel-default">
                            <div className="panel-heading" >
                              {__(process.processTitle)}
                            </div>
                            <div className="panel-body" >
                              {this.renderProcessObjects(process.processId)}
                            </div>
                            <div className="panel-footer text-right" >
                              <button
                                onClick={() => this.handleSubmit(process.processId)}
                                className="btn btn-primary"
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
            : <span>{__('loading')}</span>
          }

        </div>
      </div>
    );
  }
}

ProcessOverviewObjectsPage.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  getGuiUser: PropTypes.func,
  availableViews: PropTypes.array,
  availableReports: PropTypes.array,
  updateProcessOverviewObjects: PropTypes.func,
  change: PropTypes.func,
  formValues: PropTypes.object,
};

export default connect(
  state => {
    return {
      user: state.user.data,
      availableReports: state.user.reports,
      availableViews: state.user.views,
      formValues: getFormValues('orderTypeForm')(state),
    };

  },
  { getGuiUser, updateProcessOverviewObjects, change }
)(ProcessOverviewObjectsPage);
