export default {
  colors: {
    primary: '#eb008b',
    secondary: '#D3E0E8',
    thirdary: '#0a4f7a',
    quaternary: '#a8d15d',
    quintenary: '#0a4f7a',
    black: {
      primary: '#080808',
      secondary: '#314659'
    },
    gray: {
      primary: '#d8d8d8',
      secondary: '#e8e8e8',
      thirdary: '#f0f0f0',
      quaternary: '#f3f3f3',
      quintenary: '#d9d9d9'
    },
    text: {
      primary: '#4d4d4d',
      secondary: '#808080'
    },
    grey: {
      primary: '#d8d8d8',
      secondary: '#e8e8e8',
      thirdary: '#f0f0f0',
      quaternary: '#f3f3f3',
      quintenary: '#d9d9d9'
    },
    lightGray: {
      primary: '#f2f4f5',
      secondary: '#fafafa'
    },
    lightGrey: {
      primary: '#f2f4f5',
      secondary: '#fafafa'
    },
    blue: {
      primary: '#1890ff',
      secondary: '#40a9ff'
    },
    red: {
      primary: '#ea4335',
      secondary: '#ea4335'
    }
  }
}
