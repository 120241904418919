import React from 'react'
import { Space, TreeSelect } from 'antd'
import * as S from './Calendar/CalendarsDashboard/styles'

import { __ } from '../utils/translationUtils'

const { SHOW_PARENT } = TreeSelect

const UsersTreeSelect = (props) => {
  const { selectedCompanyUsers, setSelectedCompanyUsers, companiesTree } = props

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="small">
      <S.Label>{__('Users')}</S.Label>
      <S.TreeSelectWrapper style={{ position: 'relative' }}>
        <TreeSelect
          style={{ width: '100%' }}
          value={selectedCompanyUsers}
          onChange={setSelectedCompanyUsers}
          dropdownStyle={{ maxHeight: 500, overflowY: 'scroll', zIndex: 2001 }}
          treeData={companiesTree}
          fieldNames={{ label: 'title', value: 'key', children: 'children' }}
          treeCheckable
          multiple
          treeNodeFilterProp="searchValue"
          showCheckedStrategy={SHOW_PARENT}
          placeholder={__('Search user(s)')}
          maxTagCount={false}
        />
      </S.TreeSelectWrapper>
    </Space>
  )
}

export default UsersTreeSelect
