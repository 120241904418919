import React from "react";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";

import { Button, Form } from "react-bootstrap";

import { renderField, isRequired } from "../utils/fieldValidator";
import { Field, reduxForm } from "redux-form";
import { __ } from '../utils/translationUtils'

// const loadingBar = require("nprogress");


const validate = values => {
  const errors = {};
  errors.from = isRequired(values.from);
  errors.to = isRequired(values.to);

  return errors;
};

class EgsBillingView extends React.Component {

  static propTypes = {
    handleSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      from: "",
      to: ""
    };
    this.isLoading = false;

  }

  componentDidMount() {}


  render() {
    const {handleSubmit} = this.props;

    return (
      <div className="blocks row">
        <div className="col-lg-3 col-xs-12 gutter-bottom">

         <Form onSubmit={handleSubmit} >
              <div className="form-horizontal" style={{display:"table", width:"100%", padding:"10px 0 20px"}}>
                <Field
                  type="datetime"
                  name="from"
                  component={renderField}
                  label={__("All Orders After") + "*"}
                />
                <Field
                  type="datetime"
                  name="to"
                  component={renderField}
                  label={__("All Orders Before") + "*"}
                />


                <div className="col-sm-12">
                  <div className="mandatory-text">
                    <i>*{__('mandatory fields')}</i>
                  </div>
                </div>
              </div>

              <div className="modal-footer">


          <Button
            className={'btn btn-default'}
            type="submit"
            >
             {__('Download')}
          </Button>

          <Button
             className={'btn btn-primary'}
            >
            {__('Draw Graphs')}
          </Button>



            </div>
          </Form>

        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default reduxForm(
  {
    form: "egsBillingView", // a unique identifier for this form
    validate
  },
  mapDispatchToProps
)(EgsBillingView);
