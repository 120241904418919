import {
  mapAttributesTree,
  translateAllSystems,
  getAttributesMapFromTree,
  mapEntityTreeData,
} from '../../ConfigurationConfiguratorBeta/utils'
import * as C from '../constants'
import * as T from './types'
import * as U from '../utils'
import {stepValidation} from "../utils/validation";

export const getInitialState = () => ({
  apis: {},
  mode: null,
  system: {
    id: null,
    label: null
  },
  object: {
    id: null,
    label: null
  },
  systems: null,
  creatorId: null,
  attributes: null,
  attributesTree: null,
  attributesMap: null,
  valueTypesMap: null,
  entitiesTreeData: null,
  validationReference: {},
  configurations: C.configuratorInitialState,
  activeStep: C.steps.SYSTEM_OBJECT_SELECTION,
  validationState: C.validationInitialState,
  validationVisibility: {},
  defaultConfiguration: {
    initialValue: false,
    exists: false,
    editable: true,
  },
  loading: true,
  enumerations: {},
})

export const initState = (state) => ({ ...state })

export const ROEConfiguratorReducer = (state, action) => {

  if (!action.type) return

  switch (action.type) {

    case T.INITIALIZED:
      const {
        apis,
        tree,
        systems,
        valueTypesMap,
        configuration,
        entities,
      } = action.payload
      const attributesTree = translateAllSystems(mapAttributesTree(tree), systems)
      const attributesMap = getAttributesMapFromTree(attributesTree)
      const additionalPayload = U.getAdditionalPayload(configuration, attributesMap, attributesTree)
      const entitiesTreeData = mapEntityTreeData(entities)
      return {
        ...state,
        apis,
        systems,
        attributesTree,
        attributesMap,
        valueTypesMap,
        entitiesTreeData,
        ...additionalPayload,
        loading: false
      }

    case T.NOT_INITIALIZED:
      return {
        ...state,
        loading: true
      }

    case T.UPDATE_ENUMS:
      return {
        ...state,
        enumerations: {
          ...state.enumerations,
          ...action.payload
        }
      }

    case T.SET_MODE:
      return {
        ...state,
        mode: action.payload,
        activeStep: action.payload === C.mode.CREATE ? C.steps.SYSTEM_OBJECT_SELECTION : C.steps.LISTING_PREVIEW
      }

    case T.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload
      }

    case T.SHIFT_ACTIVE_STEP: {
      const steps = Object.keys(C.steps).map((stepKey) => (C.steps[stepKey]))
      const newActiveStep = state.activeStep + action.payload
      return {
        ...state,
        activeStep: steps.includes(newActiveStep) ? newActiveStep : state.activeStep
      }
    }

    case T.EDIT_STEP_CONFIGURATION: {
      const {stepValues} = action.payload
      const updatingStep = (action.payload.step == null) ? state.activeStep : action.payload.step
      const validationMethod = stepValidation[updatingStep]
      const isValid = validationMethod ? validationMethod(stepValues) : true
      if (!action.payload.step && updatingStep === C.steps.CONTENT_CHOOSE_FIELDS) {
        const config = state.configurations
        if (Object.keys(config[C.steps.CONTENT_ORGANISE_FIELDS]).some((gridItem) => !Object.keys(stepValues).includes(gridItem))) {
          return {
            ...state,
            validationState: {...state.validationState, [updatingStep]: isValid},
            validationVisibility: {...state.validationVisibility, [updatingStep]: C.validationActivatedAfter.EDIT},
            configurations: {
              ...state.configurations,
              [C.steps.CONTENT_ORGANISE_FIELDS]: U.getAllowedGridItems(config[C.steps.CONTENT_ORGANISE_FIELDS], Object.keys(stepValues)),
              [updatingStep]: {...stepValues}
            },
          }
        }
      }
      return {
        ...state,
        validationState: {...state.validationState, [updatingStep]: isValid},
        validationVisibility: {...state.validationVisibility, [updatingStep]: C.validationActivatedAfter.EDIT},
        configurations: {
          ...state.configurations,
          [updatingStep]: {...stepValues}
        },
      }
    }

    case T.EDIT_STEP_CONFIGURATION_ATTRIBUTE: {
      const {attributeValues, attributeId, step} = action.payload
      const updatingStep = (step == null) ? state.activeStep : step
      const updatedStep = {
        ...state.configurations[updatingStep],
        [attributeId]: {...attributeValues}
      }
      const validationMethod = stepValidation[updatingStep]
      const isValid = validationMethod ? validationMethod(updatedStep) : true
      return {
        ...state,
        validationState: {...state.validationState, [updatingStep]: isValid},
        validationVisibility: {...state.validationVisibility, [updatingStep]: C.validationActivatedAfter.EDIT},
        configurations: {
          ...state.configurations || {},
          [updatingStep]: updatedStep
        },
      }
    }

    case T.EDIT_STEP_CONFIGURATION_ATTRIBUTES:
      const updatedStep = {
        ...state.configurations[state.activeStep],
        ...action.payload
      }
      const validationMethod = stepValidation[state.activeStep]
      const isValid = validationMethod ? validationMethod(updatedStep) : true
      return {
        ...state,
        validationState: {...state.validationState, [state.activeStep]: isValid},
        validationVisibility: {...state.validationVisibility, [state.activeStep]: C.validationActivatedAfter.EDIT},
        configurations: {
          ...state.configurations || {},
          [state.activeStep]: updatedStep,
        },
      }

    case T.SET_VALIDATION_VISIBILITY:
      return {
        ...state,
        validationVisibility: { ...state.validationVisibility, [state.activeStep]: action.payload}
      }

    case T.CHANGE_SYSTEM:
      return {
        ...state,
        system: {
          id: action.payload.value,
          label: action.payload.label
        },
        object: {
          id: null,
          label: null
        },
        configurations: C.configuratorInitialState
      }

    case T.CHANGE_OBJECT:
      return {
        ...state,
        object: {
          id: action.payload.value,
          label: action.payload.label
        },
        configurations: C.configuratorInitialState
      }

    case T.CONNECT_VALIDATION_REFERENCE:
      return {
        ...state,
        validationReference: {
          ...state.validationReference,
          ...action.payload
        }
      }

    case T.UPDATE_DEFAULT_CONFIGURATION_INFO:
      return {
        ...state,
        defaultConfiguration: {
          ...state.defaultConfiguration,
          ...action.payload
        }
      }

    default:
      return state
  }
}
