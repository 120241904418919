import React, { useMemo } from 'react'
import { MinusOutlined } from "@ant-design/icons";
import Table from "../../../../../../UI/Table";
import { getItemValues } from "./utils";
import InputSwitch from "./InputSwitch";
import { hashHistory } from "../../../../../../../providers/HistoryProvider";
import * as config from '../../../../../../../constants/globalConfiguration';
import { __ } from '../../../../../../../utils/translationUtils'


export default (props) => {
  const { idsToRender, values, attributes, displayAttributes, permissions, onRemoveItem, pagination, type, overviewConfiguration } = props

  const handleSelection = (subObjectInfos, type = '') => {
    if (type === 'ROE') {
      if (window.getSelection().toString() === null || window.getSelection().toString() === '') {
        hashHistory.push(`/${config.remoteObjectDetailsUrl}/${subObjectInfos.configurationId}/${subObjectInfos.objectId}/${subObjectInfos.entryId}`);
      }
    }
  }

  const tableData = useMemo(() => idsToRender.map((itemId) => {
    let summaryValues = getItemValues(values, itemId, attributes);
    const item = {
      key: itemId
    }
    summaryValues.forEach((i) => {
      const displayAttribute = displayAttributes.filter(a => a.attributeId === i.id)
      item[i.id] = { type: displayAttribute[0]?.defaultControllerType, value: i.value, mainKey: itemId, key: i.id }
    })
    return item
  }
  ), [idsToRender, values, attributes])

  const tableColumns = useMemo(() => attributes.map((col) => ({
    title: __(col.propertyLabel),
    dataIndex: col.id,
    render: (props) => {
      const { type: colType, value, key, mainKey } = props || {}
      return <InputSwitch
        type={colType ?? 'textarea'}
        value={value}
        key={key}
        mainKey={mainKey}
      />
    }
  })), [attributes])


  return (
    <Table
      data={tableData}
      columns={tableColumns}
      scroll={{ x: true }}
      onRow={(record, rowIndex) => {
        return {
          onClick: event => handleSelection({
            objectId: overviewConfiguration.id,
            configurationId: overviewConfiguration.objectTypeId,
            entryId: record.key
          }, type)
        }
      }
      }
      pagination={pagination}
      actions={permissions.REMOVABLE ? [
        {
          value: 'UNLINK',
          icon: <MinusOutlined />,
          popconfirm: {
            title: __("Are you sure you want to remove this item relation?"),
            onConfirm: (row) => onRemoveItem(row.key),
            okText: __("Yes"),
            cancelText: __("No")
          },
          label: 'Unlink',
        }

      ] : []}
    />)
}
