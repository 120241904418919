const equals = {
  label: 'Equals',
  value: 'equals'
}

const notEquals = {
  label: 'Not equals',
  value: 'notEquals'
}
const set = {
  label: 'Is set',
  value: 'set'
}
const notSet = {
  label: 'Is not set',
  value: 'notSet'
}

const greatherThan = {
  label: 'gt',
  value: 'gt'
}
const contains = {
  label: 'Contains',
  value: 'contains'
}
const notContains = {
  label: 'notContains',
  value: 'notContains'
}

const greatherEqualThan = {
  label: 'gte',
  value: 'gte'
}

const lessThan = {
  label: 'lt',
  value: 'lt'
}

const lessEqualThan = {
  label: 'lte',
  value: 'lte'
}
const beforeDate = {
  label: 'Before Date',
  value: 'beforeDate'
}
const afterDate = {
  label: 'After Date',
  value: 'afterDate'
}
const inDateRange = {
  label: 'In Date Range',
  value: 'inDateRange'
}
const notInDateRange = {
  label: 'Not In Date Range',
  value: 'notInDateRange'
}

export default {
  string: [equals, notEquals, contains, notContains, set, notSet],
  number: [equals, notEquals, greatherThan, greatherEqualThan, lessThan, lessEqualThan, set, notSet],
  boolean: [equals, notEquals, set, notSet],
  time: [equals, notEquals, beforeDate, afterDate, inDateRange, notInDateRange, set, notSet]
}
