import React, {useEffect, useState} from "react";
import {fetchWidgets} from "../../providers/ApiProvider";
import {Button, Input, List, message, Pagination, Tooltip, Typography} from "antd";
import {Icon} from '@ant-design/compatible'
import SearchOutlined from "@ant-design/icons/es/icons/SearchOutlined";
import getIconType from "../../pages/Dashboard/utility/getIconType";
import * as GlobalS from '../../providers/StyleProvider/styles'
import {ResponsiveModal} from '../../providers/StyleProvider/styles'
import * as S from "../../pages/Dashboard/styles";
import {hashHistory} from "../../providers/HistoryProvider";
import moment from "moment";
import {useDebounce} from "../../hooks";
import getWidgetDimensions from "../../pages/Dashboard/utility/getWidgetDimensions";
import {__} from '../../utils/translationUtils'


const {Title, Text} = Typography;

const PAGINATION_INITIAL_STATE = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  sorter: null
}

const WidgetSelectModal = ({onSelect, modalOpen, setModalOpen, ...props}) => {
  const [pagination, setPagination] = useState(PAGINATION_INITIAL_STATE)
  const [searchValue, setSearchValue] = useState('');
  const [widgetList, setWidgetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  async function fetchData(value, current, pageSize) {
    if (!current || !pageSize) return;
    setLoading(true);
    if (!value) {
      value = '';
    }
    fetchWidgets({pageSize: pagination?.pageSize, pageNumber: pagination?.current, q: value})
      .then((result) => {
        setWidgetList(result?.data);
        setPagination({
          ...pagination,
          current: Number(result.headers?.['x-page']),
          pageSize: Number(result.headers?.['x-page-size']),
          total: Number(result.headers?.['x-total-count'])
        });
        setLoading(false);
      })
      .catch((err) => message.error("Error retrieving widgets"))
  }

  useEffect(() => {
    fetchData(debouncedSearchValue, 1, pagination.pageSize);
  }, [debouncedSearchValue])

  useEffect(() => {
    fetchData(debouncedSearchValue, pagination?.current, pagination.pageSize);
  }, [pagination?.current, pagination?.pageSize])


  const handlePaginationChange = (current, pageSize) => {
    setPagination({...pagination, current, pageSize})
  }

  const handleWidgetSelection = (item) => {
    const dimensions = getWidgetDimensions(item?.presentation);
    onSelect(item, dimensions);
    setModalOpen(false);
  }

  return (
    <>
      <ResponsiveModal
        title={__("Add a widget")}
        size={'medium'}
        centered
        visible={modalOpen}
        footer={
          <Pagination
            defaultCurrent={1}
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePaginationChange}
          />
        }
        onCancel={() => setModalOpen(false)}
        afterClose={() => {
          document.body.removeAttribute('style')
        }} //for bug described here: https://github.com/ant-design/ant-design/issues/21539
      >
        <S.ModalStickyContainer>
          <Input placeholder="Search" prefix={<SearchOutlined/>} onChange={(e) => setSearchValue(e.target.value)}/>
        </S.ModalStickyContainer>
        <div>
          {
            loading && <GlobalS.OverlayFullSpaceSpin/>
          }
          {
            !!widgetList?.length && (
              <List
                itemLayout="horizontal"
                dataSource={widgetList}
                renderItem={item => (
                  <List.Item
                    actions={[<Button onClick={() => handleWidgetSelection(item)}>{__('Add widget')}</Button>]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Tooltip placement="bottom"
                                 title={item?.presentation}>
                          <S.ChartListPlaceholder>
                            <Icon type={getIconType(item?.presentation)}/>
                          </S.ChartListPlaceholder>
                        </Tooltip>
                      }
                      title={
                        <div>
                          <p style={{marginTop: 0, marginBottom: 0}}>
                            <Title style={{marginTop: 0, marginBottom: 0}} level={4}
                                   onClick={() => hashHistory.push(`/widgets/${item?.id}`)}>
                              {__(item?.name)}
                            </Title>
                          </p>
                          <p>
                            <Tooltip placement="bottom"
                                     title={moment(new Date(item?.lastUpdateDateTime)).format("DD/MM/YYYY HH:mm")}>
                              <Text type="secondary">
                                {`Last update: ${moment(new Date(item?.lastUpdateDateTime)).fromNow()}`}
                              </Text>
                            </Tooltip>
                          </p>
                        </div>
                      }
                      description={<Text>{__(item?.description)}</Text>}
                    />
                  </List.Item>
                )}
              />
            )
          }
        </div>
      </ResponsiveModal>
    </>
  )
}

export default WidgetSelectModal;
