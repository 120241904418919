import React from 'react'
import {__} from "../../../../../utils/translationUtils";
import { TreeSelect } from "components";
const { SHOW_PARENT } = TreeSelect

const UserPicker = (props) => {
  const { companiesTree } = props

  return (
    <TreeSelect
      dropdownStyle={{ maxHeight: 500, overflowY: 'scroll' }}
      items={companiesTree}
      treeCheckable
      multiple
      treeNodeFilterProp="searchValue"
      showCheckedStrategy={SHOW_PARENT}
      placeholder={__('Search user(s)')}
      maxTagCount={15}
      { ...props }
    />
  )
}

export default UserPicker
