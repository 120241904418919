import {
  initialiseMultipartUpload,
  uploadPart,
  completeMultipartUpload,
  getFileInfo,
  singlePartDownload,
  downloadPart,
  uploadFullFile,
  wait,
  uploadProgress,
} from "../../../../../../providers/ApiProvider/filesIO";
import * as config from "../../../../../../constants/globalConfiguration";
import { removeLastPipeOrSlash } from "../services/utils";
import pMap from "p-map";
import UploadFileService from "../../../../../fileUploader/services/uploadFIleService";
import FileUploaderService from "../../../../../fileUploader/services/fileUploaderService";

const apiUrl = config.getApiUrl()

const SLICE_SIZE = 5 * 1024 * 1024 // 10MB

/* UPLOAD */
export const uploadFile = async (file, info, updateFile) => {
  if (!file) return null;

  const totalParts = Math.ceil(file.size / SLICE_SIZE)

  if (totalParts === 1) {
    return singlePartUpload(file, info)
  } else {
    return multiPartUpload(file, { ...info, totalParts }, updateFile)
  }
}

const singlePartUpload = async (file, info, basePath = "files") => {
  const url = `${apiUrl}/${basePath}${removeLastPipeOrSlash(info.jsonPath)}/${file.name}`

  return uploadFullFile(url, file)
}

const multiPartUpload = async (file, info, updateFile) => {
  const uploadId = await initialiseMultipartUpload(file.name)
  if (!uploadId) return Promise.reject('Failed to initialise multipart upload')

  const newFile = {
    uid: `-${file.name}`,
    name: file.name,
    status: 'uploading',
    percent: 0,
    url: 'data:application/pdf'
  }

  updateFile?.(newFile)

  return sendChunkedFile(file, { ...info, uploadId }, 0, 1, (percent) => updateFile?.({ ...newFile, percent }))
    .then(async (parts) => {
      const { xsdName, primaryKey, fieldName, fileName, group } = info
      const xsdFileDirectoryPath = { xsdName, primaryKey, fieldName, fileName, group }

      let progress = 80
      completeMultipartUpload(fileName, uploadId, { parts, xsdFileDirectoryPath }).then(() => {
        progress < 100 && updateFile?.({ ...newFile, percent: 100 })
        progress = 100
      })

      while (progress < 100) {
        const temporaryProgress = (await uploadProgress({ ...xsdFileDirectoryPath, uploadId }))?.data
        progress += (temporaryProgress || 0) / 100 * 20

        updateFile?.({ ...newFile, percent: progress })
        await wait(temporaryProgress > 95 ? 250 : 2000); // 2 seconds
      }
      return true
    })
}

const sendChunkedFile = async (file, info, start, partNumber, progress) => {
  const end = Math.min(file.size, start + SLICE_SIZE)
  const formData = chunkFile(file, { start, end })
  const { fileName, uploadId } = info

  return uploadPart({ fileName, partNumber, uploadId }, formData)
    .then(res => {
      progress?.(Math.round((end / file.size) * 80))

      const part = { partNumber, etag: res.data.etag }

      if (end < file.size) {
        return sendChunkedFile(file, info, end, partNumber + 1, progress)
          .then(prev => ([...prev, part]))
      } else
        return [part]
    })
}

const chunkFile = (file, { start, end }) => {
  const chunk = file.slice(start, end)
  const newFile = new File([chunk], file.name, { type: file.type })

  const formData = new FormData()
  formData.append('file', newFile)

  return formData
}

/* DOWNLOAD */

export const fetchFileList = async (filesToFetch, imageOptions = false, authToken, updateFile) => {
  const files = {}

  await pMap(filesToFetch, async file => {
    let uri = file.url
    if (imageOptions != null) {
      uri += UploadFileService.applyImageOptions(imageOptions)
    }

    const fileBlob = await FileUploaderService.getUrlFile(uri, authToken, (changes) => updateFile({ ...file, ...changes }))
    files[file.name] = fileBlob
  }, { concurrency: 5 })
  return files
}

export const downloadFile = async (url, authToken, progress) => {
  const info = await getFileInfo(url)

  if (!info) return null

  const contentLength = info?.headers?.["content-length"]
  const contentType = info?.headers?.["content-type"]

  if (!contentLength || contentLength < SLICE_SIZE) {
    return singlePartDownload(url, authToken)
  } else {
    return multiPartDownload({ url, contentType, contentLength, chunkSize: SLICE_SIZE }, progress)
  }
}

async function multiPartDownload({ url, contentType, contentLength, chunkSize, poolLimit = 5 }, progress) {

  let completed = 0
  progress?.({ status: 'uploading', percent: 0 })

  const fileSize = parseInt(contentLength, 10)
  const chunks = Math.ceil(fileSize / chunkSize)
  const chunksData = []

  await pMap(Array.from({ length: chunks }, (_, i) => i), async chunkIndex => {
    const start = chunkIndex * chunkSize
    const end = start + chunkSize - 1

    chunksData[chunkIndex] = await downloadPart(url, { start, end })

    completed++
    const percent = (completed / chunks) * 100
    progress?.({ status: 'uploading', percent: Math.round(percent) })

  }, { concurrency: poolLimit })

  progress?.({})

  return new Blob(chunksData, { type: contentType })
}
