import React, {useState} from 'react'
import md5 from 'md5'
import {parse} from 'query-string'
import PropTypes from 'prop-types'
import {Icon} from '@ant-design/compatible'
import {connect} from 'react-redux'
import {change, Field, reduxForm} from 'redux-form'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {message, Space, Tag, Tooltip, Upload} from 'antd'
import {Button, Modal, Panel} from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import _ from 'lodash'
import {isEmail, isRequired, renderField} from '../../utils/fieldValidator'
import * as config from '../../constants/globalConfiguration'
import {changePassword} from '../../providers/ReduxProvider/actions/userActions'
import {getUserLogoUrl} from '../../utils/appHelper'
import {hashHistory} from '../../providers/HistoryProvider'
import OrderTypesROField from '../../components/formFields/OrderTypesROField'
import {USER_FORM_NAME} from "../../components/EntityFormNames";
import EntityAvatarForm, {
  AVATAR_TYPE_FIELD_NAME,
  AVATAR_URL_FIELD_NAME,
  CUSTOM_PICTURE_AVATAR_TYPE,
  GRAVATAR_AVATAR_TYPE,
  INITIAL_AVATAR_TYPE
} from "../../components/EntityAvatarForm";
import {InfoCircleFilled, InfoCircleOutlined, UploadOutlined, WarningOutlined} from "@ant-design/icons";
import {getBase64} from "../../components/EntityAvatar/utils";
import {api} from "../../providers/ApiProvider";
import BatModal from "../../components/UI/BatModal";
import UserHistory from "../../components/UserHistory";
import {LabelInfo, PanelHeadingInner} from "../../providers/StyleProvider/styles";
import {__} from '../../utils/translationUtils'
import StatusChange, {statusToAction} from "./StatusChange";
import {cases} from "../../utils/stringUtils";
import {changeDeputy, setUserRights, updateUserStatus} from "../../providers/ApiProvider/entity";
import ChangeDeputy from "./StatusChange/ChangeDeputy";
import * as S from "../../components/Label/styles";

const validate = (values, props) => {
  const errors = {}
  errors.firstName = isRequired(values.firstName)
  errors.lastName = isRequired(values.lastName)
  errors.email = isRequired(values.email) || isEmail(values.email)
  errors.username = props?.mode === 'create' ? false : isRequired(values.username)
  errors.password = isRequired(values.password)
  errors.confirmPassword = isRequired(values.confirmPassword)
  errors.phone = isRequired(values.phone)
  errors.entityIds = isRequired(values.entityIds)
  errors.language = isRequired(values.language)

  return errors
}

const validateConfirmPassword = (confirmPassword, allValues) => {
  if (confirmPassword && confirmPassword !== allValues?.password) {
    return __('PasswordDoesntMatch')
  }
  return undefined;
}

class UserForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    changePassword: PropTypes.func,
    guiUser: PropTypes.object,
    userRegistration: PropTypes.object,
    change: PropTypes.func,
    getData: PropTypes.func,
    isLoading: PropTypes.bool,
    canDelete: PropTypes.bool,
    canUpdate: PropTypes.bool,
    isOwnUser: PropTypes.bool,
    mode: PropTypes.string,
    user: PropTypes.object,
    userId: PropTypes.string,
    entities: PropTypes.array,
    availableTemplates: PropTypes.array,
    location: PropTypes.object,
    formValues: PropTypes.object
  };

  constructor(props) {
    super(props)
    this.passwordInput = null
    this.selectedCompany = null
    this.selectedRoles = null
    this.queryString = parse(this.props.location.search)

    this.state = {
      selectedRoles: props.user ? props.user.matchingTemplates.split(',').map(s => s.trim()) : null,
      statusChange: props.mode === 'delete' && props.user,
      showiCalLink: false,
      passwordModal: {open: false},
      gravatarModal: {open: false},
      historyModal: {open: false},
      userRightsModal: {open: false},
      manageRolesModal: {open: false},
      entityIds: [],
      userAvatarSrc: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.showChangePasswordPopup = this.showChangePasswordPopup.bind(this)
    this.showGravatarPopup = this.showGravatarPopup.bind(this)
    this.showManageRolesModal = this.showManageRolesModal.bind(this)
    this.handleUserStatusChange = this.handleUserStatusChange.bind(this)

    this.showiCal = this.showiCal.bind(this)
  }

  componentDidMount() {
    this.getUserCustomAvatar()

    if (this.queryString.showRolesModal) {
      setTimeout(() => {
        this.showManageRolesModal()
      }, 200)
    }
  }

  handleSubmit() {
    this.props.handleSubmit()
  }

  handleUserStatusChange(user, status, newUserId) {
    if (user.status !== status) {
      updateUserStatus(user.id, statusToAction(status), newUserId)
        .then(() => {
          hashHistory.replace(`/users/${this.props.userId}`)
        })
    } else {
      hashHistory.replace(`/users/${this.props.userId}`)
    }
  }

  closeModal(e) {
    if (e?.stopPropagation) e.stopPropagation()
    this.setState({
      passwordModal: {...this.state.passwordModal, open: false},
      gravatarModal: {...this.state.gravatarModal, open: false},
      manageRolesModal: {...this.state.manageRolesModal, open: false}
    })
  }

  showiCal() {
    if (this.state.showiCalLink) {
      this.setState({showiCalLink: false})
    } else {
      this.setState({showiCalLink: true})
    }
  }

  showManageRolesModal(e) {
    if (typeof e !== 'undefined') {
      e.preventDefault()
    }
    this.setState({manageRolesModal: {...this.state.manageRolesModal, open: true}})
  }

  showChangePasswordPopup(e) {
    e.preventDefault()
    this.setState({passwordModal: {...this.state.passwordModal, open: true}})
  }

  showGravatarPopup(e) {
    e.preventDefault()
    this.setState({gravatarModal: {...this.state.gravatarModal, open: true}})
  }

  handleChangePassword() {
    const {user, changePassword, guiUser} = this.props
    const forceLogout = user.id === guiUser.id
    changePassword(user.id, this.passwordInput.value, forceLogout).then(() => {
      this.closeModal()
    })
  }

  handleChangeRoles() {
    setUserRights(this.props.user.id, this.state.selectedRoles)
      .then(() => {
        this.closeModal()
        hashHistory.replace(`/users/${this.props.userId}/edit`)
        this.props.getData()
      })
  }

  async getUserCustomAvatar() {
    const {initialValues} = this.props
    if (!initialValues.id) return
    const blobFile = await api.get(getUserLogoUrl(initialValues.id), {responseType: 'arraybuffer'})
      .then(response => new Blob([response.data], {type: "image/jpeg;"}))
      .catch(() => null)

    if (blobFile == null)
      return

    this.setState({userAvatarSrc: await getBase64(blobFile, {type: "image/jpeg;"})})

  }

  componentWillReceiveProps = (nextProps) => {
    const next = nextProps.location?.pathname
    if (next === `/users/${this.props.user?.id}/history` && !this.state.historyModal.open) {
      this.setState({historyModal: {open: true}})
    }
  }

  render() {
    const {
      isLoading,
      mode,
      user,
      canUpdate,
      canDelete,
      availableTemplates,
      initialValues,
      isOwnUser,
      guiUser,
      formValues
    } = this.props

    const renderPanelFooter = () => (
      <div className="group">

        <button
          className="btn btn-default pull-left"
          onClick={() => {
            hashHistory.push('/users')
          }}
        >
          <Glyphicon glyph="chevron-left"/> {__('Back')}
        </button>
        {
          (mode === 'edit' || mode === 'create') && canUpdate
          && (
            <button
              className={'btn pull-right '.concat(mode === 'create' ? 'btn-success' : 'btn-primary')}
              disabled={isLoading}
              size="large"
              onClick={this.handleSubmit}
            >
              <Glyphicon glyph={(mode === 'edit') ? 'save' : 'plus-sign'}/>
              {__((mode === 'edit') ? __('Save Changes') : __('Create'))}
            </button>
          )
        }
        {
          (mode === 'view' || mode === 'delete') && canUpdate && user && user.status !== 'deleted'
          && (
            <Link
              to={`/users/${user.id}/edit`}
              className={'btn pull-right '.concat(mode === 'create' ? 'btn-success' : 'btn-primary')}
              disabled={isLoading}
              size="large"
            >
              <Glyphicon glyph="pencil"/>
              {__('Edit User')}
            </Link>
          )
        }
      </div>
    )

    const listTemplates = () => availableTemplates.map((template) => (
      <option
        key={template.shortName}
        value={template.shortName}
      >{template.name}
      </option>
    ))

    const listLanguages = () => {
      const {availableLanguages} = this.props
      const items = []
      items.push(
        <option key="asd" selected>{__('Select one')}</option>
      )
      if (availableLanguages) {
        availableLanguages.map((lang) => {
          items.push(<option key={lang.languageCode} value={lang.languageCode}>{lang.language}</option>)
        })
      }
      return items
    }

    const listStatuses = () => {
      return ["enabled", "disabled"].map((status) => <option key={status} value={status}>{status}</option>)
    }

    const listAvatarTypes = () => {

      const {formValues} = this.props

      const options = []
      options.push(<option key={INITIAL_AVATAR_TYPE} value={INITIAL_AVATAR_TYPE}>Initials</option>)
      options.push(<option key={CUSTOM_PICTURE_AVATAR_TYPE} value={CUSTOM_PICTURE_AVATAR_TYPE}>Upload Picture </option>)
      if (formValues.email != null) {
        options.push(<option key={GRAVATAR_AVATAR_TYPE} value={GRAVATAR_AVATAR_TYPE}>Gravatar</option>)
      }


      return options

    }

    const listEntities = () => {
      const {entities} = this.props
      const entityGroups = _.groupBy(entities, (e) => e.belongsTo.shortName)
      const optionGroups = []
      for (const entityGroup in entityGroups) {
        optionGroups.push(
          <optgroup key={entityGroup} label={entityGroup}>
            {entityGroups[entityGroup].map((entity) => (
              <option
                key={entity.id}
                value={entity.id}
              >{entity.name}
              </option>
            ))}
          </optgroup>
        )
      }
      return optionGroups
    }

    function extractEntities() {
      const userEntities = user.belongsTo

      return userEntities.map((en) => en.name).join(', ') + (userEntities?.length ? '.' : 'Not belonging to any entity.')
    }

    const uploadAvatarUrlProps = {
      name: 'file',
      action: getUserLogoUrl(this.props.initialValues.id),
      headers: {'x-auth-token': this.props.guiUser?.authToken},
      accept: "image/*",
      fileList: [],
      beforeUpload: async (file) => {
        if (!file) return false
        const maxFileSizeMB = 2
        const fileSizeMB = ((file.size / 1024) / 1024).toFixed(4)
        if (fileSizeMB > maxFileSizeMB) {
          message.error('Cannot update image bigger then 2MB');
          return false;
        }
      },
      onChange: async (info) => {

        if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
          return
        }


        this.setState({userAvatarSrc: await getBase64(new Blob([info.file.originFileObj || info.file], {type: "image/jpeg;"}))})

        this.props.change(USER_FORM_NAME, AVATAR_URL_FIELD_NAME, getUserLogoUrl(this.props.initialValues.id))
        this.props.change(USER_FORM_NAME, AVATAR_TYPE_FIELD_NAME, CUSTOM_PICTURE_AVATAR_TYPE)

      }
    }

    return (

      <div>
        <Panel>
          <Panel.Heading>
            <PanelHeadingInner>
              <div style={{ display: "flex", gap: '10px', alignItems: 'center' }}>
                {__('User Details')}
                {user && user.status !== 'enabled' && <Tag color={'error'}>{__(user.status, 'uppercase')}</Tag>}
              </div>
              {
                user && (
                  <Space>
                    <BatModal
                      title={`${user.status === 'deleted' ? __('deleted_user') : formValues?.username} ${__("history")}`}
                      className="default-height no-body-padding"
                      useButtonStyle={false}
                      onCancelCallback={() => {
                        hashHistory.push(`/users/${user.id}`)
                      }}
                      forceOpen={this.state.historyModal.open}
                      setForceOpen={(val) => {
                        this.setState({historyModal: {open: val}}
                        )
                      }}
                      buttonLabel={
                        <Tooltip title={__('User History')}>
                          <div className="btn-no-padding btn-std" onClick={() => {
                            hashHistory.push(`/users/${user.id}/history`)
                          }}>
                            <Icon className="icon-std" type="history" theme="outlined"/>
                          </div>
                        </Tooltip>
                      }
                    >
                      <UserHistory userId={initialValues.id} status={user.status}/>
                    </BatModal>
                    <Link to={`/users/${user.id}/calendar`}>
                      <Tooltip title={__('calendar')}>
                        <div className="btn-no-padding btn-std">
                          <Icon className="icon-std" type="calendar" theme="outlined"/>
                        </div>
                      </Tooltip>
                    </Link>
                    {this.props.isOwnUser && (
                      <Link to={`/users/${user.id}/settings`}>
                        <Tooltip title={__('settings')}>
                          <div className="btn-no-padding btn-std">
                            <Icon className="icon-std" type="setting" theme="outlined"/>
                          </div>
                        </Tooltip>
                      </Link>
                    )}
                  </Space>
                )
              }
            </PanelHeadingInner>
          </Panel.Heading>
          <Panel.Body>
            <form className="form-horizontal user-form" style={{display: 'flex'}}>
              {mode !== 'create' &&
                <EntityAvatarForm formName={USER_FORM_NAME} gravatarMail={this.props.initialValues.email}
                                  customPictureSrcImage={this.state.userAvatarSrc}
                                  avatarName={this.props.initialValues?.firstName && this.props.initialValues?.lastName ? this.props.initialValues.firstName.charAt(0) + this.props.initialValues.lastName.charAt(0) : null}
                />
              }
              <div style={{width: '100%'}}>
                <div className="col-md-7">
                  <Field
                    name="firstName"
                    component={renderField}
                    disabled={mode === 'view' || mode === 'delete'}
                    label={`${__('First Name')}`}
                    required
                    className="form-control"
                    type="text"
                    inputCol="col-sm-9"
                    labelCol="col-sm-3"
                  />

                  <Field
                    name="lastName"
                    component={renderField}
                    disabled={mode === 'view' || mode === 'delete'}
                    label={`${__('Last Name')}`}
                    required
                    className="form-control"
                    type="text"
                    inputCol="col-sm-9"
                    labelCol="col-sm-3"
                  />

                  <Field
                    name="email"
                    component={renderField}
                    disabled={mode === 'view' || mode === 'delete'}
                    label={`${__('eMail')}`}
                    required
                    className="form-control"
                    type="email"
                    placeholder="eMail"
                    inputCol="col-sm-9"
                    labelCol="col-sm-3"
                  />

                  <Field
                    name="username"
                    component={renderField}
                    disabled={mode === 'view' || mode === 'delete'}
                    required={mode !== 'create'}
                    className="form-control"
                    label={mode !== 'create' ? __('Username') :
                      <div>
                        {__('Username')}
                        <FieldDetails>
                          {__('UserCreationUsernameInfoMessage')}
                        </FieldDetails>
                      </div>
                    }
                    type="text"
                    placeholder="User name"
                    inputCol="col-sm-9"
                    labelCol="col-sm-3"
                  />

                  {(mode === 'create' && this.props.userRegistration?.passwordOnSignUp) &&
                    <>
                      <Field
                        name="password"
                        type="password"
                        component={renderField}
                        label={`${__('Password')}`}
                        required
                        placeholder={__('Password')}
                        className="form-control"
                        inputCol="col-sm-9"
                        labelCol="col-sm-3"
                        autoFocus=""
                      />
                      <Field
                        name="passwordConfirmation"
                        type="password"
                        component={renderField}
                        validate={[validateConfirmPassword]}
                        label={`${__('Confirm Password')}`}
                        required
                        placeholder={__('Confirm Password')}
                        className="form-control"
                        inputCol="col-sm-9"
                        labelCol="col-sm-3"
                        autoFocus=""
                      />
                    </>
                  }

                  {
                    mode === 'edit' &&
                    <>
                      <Field
                        name={AVATAR_TYPE_FIELD_NAME}
                        component={renderField}
                        label={`${__('Avatar Type')}`}
                        className="form-control"
                        items={listAvatarTypes()}
                        disabled={mode === 'view' || mode === 'delete'}
                        inputCol="col-sm-9"
                        labelCol="col-sm-3"
                        type="select"
                      />
                      {this.props.mode === 'edit' && this.props.formValues?.avatar?.avatarType === CUSTOM_PICTURE_AVATAR_TYPE ?
                        <div className={"form-group clearfix"}>
                          <label className={'col-sm-3 control-label'}/>
                          <div className={'col-sm-9 text-danger-wrapper'}>
                            <Tooltip title={__("Upload")}>
                              <Upload {...uploadAvatarUrlProps}>
                                <div className={'btn btn-primary'}><UploadOutlined/></div>
                              </Upload>
                            </Tooltip>
                          </div>
                        </div> : null}
                    </>
                  }

                  {
                    mode === 'edit' && (isOwnUser || canUpdate)
                    && (
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          {__('Password')}:
                        </label>
                        <div className="col-sm-9">
                          <button onClick={this.showChangePasswordPopup} className="btn btn-link">
                            {__('Change Password')}
                          </button>
                        </div>
                      </div>
                    )
                  }

                  <Field
                    name="phone"
                    component={renderField}
                    disabled={mode === 'view' || mode === 'delete'}
                    label={`${__('Phone Number')}`}
                    required
                    className="form-control"
                    type="text"
                    inputCol="col-sm-9"
                    labelCol="col-sm-3"
                  />

                  <Field
                    name="language"
                    component={renderField}
                    label={`${__('language', cases.CAPITALIZE_SENTENCE)}`}
                    required
                    className=""
                    items={listLanguages()}
                    disabled={(mode === 'view' || mode === 'delete')}
                    type="select"
                    inputCol="col-sm-9"
                    labelCol="col-sm-3"
                  />

                  <Field
                    type="datetime"
                    name="validFrom"
                    component={renderField}
                    disabled={mode === 'view' || mode === 'delete'}
                    label={__('Valid From')}
                    inputCol="col-sm-9"
                    labelCol="col-sm-3"
                  />

                  <Field
                    type="datetime"
                    name="validTo"
                    component={renderField}
                    disabled={mode === 'view' || mode === 'delete'}
                    label={__('Valid To')}
                    inputCol="col-sm-9"
                    labelCol="col-sm-3"
                  />

                  {
                    (mode !== 'view' && mode !== 'delete')
                    && (
                      <Field
                        name="entityIds"
                        component={renderField}
                        multiple
                        label={`${__('Attach this user to')}`}
                        required
                        items={listEntities()}
                        disabled={mode === 'view' || mode === 'delete'}
                        type="select"
                        inputCol="col-sm-9"
                        labelCol="col-sm-3"
                      />
                    )
                  }

                  {
                    (mode === 'view' || mode === 'delete')
                    && (
                      <div className="form-group">
                        <label className="col-sm-3 control-label">Company</label>
                        <div className="col-sm-9">
                          <p className="form-control-static">{extractEntities.bind(this)()}</p>
                        </div>
                      </div>
                    )
                  }

                  {
                    (mode === 'view' || mode === 'delete')
                    && (
                      <div className="form-group">
                        <label className="col-sm-3 control-label">{`${__('Order types')}`}</label>
                        <div className="col-sm-9">
                          <OrderTypesROField orderTypes={user.orderTypes}/>
                        </div>
                      </div>
                    )
                  }
                  {
                    mode !== 'create' && user.status !== 'deleted' && (
                      <TagField
                        tag={user.matchingTemplates?.split(', ')}
                        label={`${__('roles', cases.CAPITALIZE)}`}
                        multiple
                        button={
                          <button
                            onClick={this.showManageRolesModal}
                            className="btn btn-link"
                            disabled={mode === 'create'}
                          >
                            {__('Manage Roles')}
                          </button>
                        }
                      />)
                  }
                  {
                    mode === 'create' && (
                      <Field
                        name="status"
                        component={renderField}
                        label={`${__('status', cases.CAPITALIZE_SENTENCE)}`}
                        className=""
                        items={listStatuses()}
                        type="select"
                        inputCol="col-sm-9"
                        labelCol="col-sm-3"
                      />

                    )
                  }
                  {
                    mode !== 'create' && (
                      <>
                        {
                          user.status !== 'deleted' && (
                            <>
                              <TagField
                                tag={user.status}
                                label={`${__('status', cases.CAPITALIZE)}`}
                                color={user.status === 'enabled' ? 'success' : 'error'}
                                button={
                                  <button
                                    className="btn btn-link"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      hashHistory.push(`/users/${this.props.userId}/changeStatus`)
                                    }}
                                  >
                                    {__('manage_user_status', cases.CAPITALIZE_SENTENCE)}
                                  </button>
                                }
                              />
                              {
                                this.state.statusChange && (
                                  <StatusChange
                                    user={this.state.statusChange}
                                    isOwnUser={this.props.isOwnUser}
                                    submit={(userid, status, newUserId) => {
                                      this.handleUserStatusChange(userid, status, newUserId)
                                    }}
                                    close={() => hashHistory.push(`/users/${this.props.userId}`)}
                                  />
                                )
                              }
                            </>
                          )
                        }
                        <DeputyField
                          user={user}
                          deputy={user.deputy}
                          users={this.props.users}
                          organisations={this.props.organisations}
                          refreshUserPage={this.props.getData}
                        />
                      </>
                    )
                  }
                  {(mode !== 'create' && user.icalUrl && user.status !== 'deleted')
                    && (
                      <div className="form-group">
                        <label htmlFor="status" className="col-sm-3 control-label">{__('iCal Link')}</label>
                        <div className={'col-sm-9'}>
                          <Button onClick={this.showiCal.bind()}>
                            <Glyphicon glyph="calendar"/>
                            {__('Show Private iCal Link')}
                          </Button>
                        </div>
                        {this.state && this.state.showiCalLink
                          && (
                            <div>
                              <br/>
                              <br/>
                              <a className="btn btn-link btn-block" href={user.icalUrl}>Get the calendar on
                                your
                                device
                              </a>
                            </div>
                          )}
                      </div>
                    )}
                </div>
                <div className="col-md-5">
                  {mode !== 'create'
                    && (
                      <div>
                        {
                          (typeof user.creator !== 'undefined')
                          && (
                            <div className="form-group">
                              <label className="col-sm-3 control-label">{__('Created by')}</label>
                              <div className="col-sm-9">
                                <p className="form-control-static">
                                  <Link to={`/users/${user.creator.id}`}>
                                    {`${user.creator.firstName} ${user.creator.lastName}`}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          )
                        }
                        {
                          (typeof user.modifier !== 'undefined')
                          && (
                            <div className="form-group">
                              <label className="col-sm-3 control-label">{__('Modified by')}</label>
                              <div className="col-sm-9">
                                <p className="form-control-static">
                                  <Link to={`/users/${user.modifier.id}`}>
                                    {`${user.modifier.firstName} ${user.modifier.lastName}`}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          )
                        }
                        {
                          (!!this.props.blockedOrders && user.status !== "enabled")
                          && (
                            <div className="form-group">
                              <label className="col-sm-3 control-label">
                                <>
                                  {__('blocked_orders', cases.CAPITALIZE_SENTENCE)}
                                  <Tooltip title={__('resolve_blocked_orders_description', cases.CAPITALIZE_SENTENCE)}
                                           placement="right">
                                    <S.InfoBox><InfoCircleFilled/></S.InfoBox>
                                  </Tooltip>
                                </>
                              </label>
                              <div className="col-sm-9">
                                <p className="form-control-static">
                                  <Tag icon={<WarningOutlined/>} color={'error'}>{this.props.blockedOrders}
                                  </Tag>
                                </p>
                              </div>
                            </div>
                          )
                        }

                        <div className="form-group">
                          <label className="col-sm-3 control-label">{__('creationDateTime')}</label>
                          <div className="col-sm-9">
                            <p
                              className="form-control-static"
                            >{moment(user.creationDatetime).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                            </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">{__('last_modified_datetime')}</label>
                          <div className="col-sm-9">
                            <p
                              className="form-control-static"
                            >{user.lastUpdateDatetime && moment(user.lastUpdateDatetime).format(`${config.appDefaults.dateFormat} ${config.appDefaults.timeFormat}`)}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </form>
            {
              (mode === 'create' || mode === 'edit') &&
              (
                <div className="col-sm-12">
                  <div className="mandatory-text">
                    <i>*{__('mandatory fields')}</i>
                  </div>
                </div>
              )
            }
          </Panel.Body>
          <Panel.Footer>{renderPanelFooter()}</Panel.Footer>
        </Panel>

        <Modal
          backdrop="static"
          show={this.state.passwordModal.open}
          onHide={this.closeModal.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">{__('Change Password')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal">
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('New Password')}:</label>
                <div className="col-sm-9">
                  <input
                    type="password"
                    className="form-control"
                    ref={(input) => {
                      this.passwordInput = input
                    }}
                  />
                </div>
              </div>
            </form>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModal.bind(this)}>
              {__('Back')}
            </Button>
            <button
              onClick={this.handleChangePassword.bind(this)}
              className="btn btn-primary"
            >
              {__('Set Password')}
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          backdrop="static"
          show={this.state.gravatarModal.open}
          onHide={this.closeModal.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title
              className="capitalize"
            >{guiUser && user && guiUser.id === user.id && mode === 'edit' ? __('Change Avatar') : __('Current Avatar')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              style={{width: '100%'}}
              src={`https://www.gravatar.com/avatar/${this.props.initialValues.email && md5(this.props.initialValues.email)}?s=1500&d=https://www.godoo.ch/en/icons/icon-512x512.png?v=d57b514fa910e3ff9d98c1ae52bfb7d7`}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeModal.bind(this)}>
              {__('Back')}
            </Button>
            {guiUser && user && guiUser.id === user.id && mode === 'edit'
              && (
                <button
                  onClick={() => {
                    this.closeModal()
                    window.open('https://www.gravatar.com/', '_blank')
                  }}
                  className="btn btn-primary"
                >
                  {__('Edit on UserAvatar.com')}
                </button>
              )}
          </Modal.Footer>
        </Modal>

        <Modal
          backdrop="static"
          show={this.state.manageRolesModal.open}
          onHide={this.closeModal.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="capitalize">{__('Manage Roles')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              this.queryString.showRolesModal
              && <div className="alert-warning gutter-bottom">{__('SetUserRolesWarning')}</div>
            }
            <form className="form-horizontal">
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('Roles')}:</label>
                <div className="col-sm-9">
                  {user && user.matchingTemplates
                    && (
                      <select
                        multiple
                        value={this.state.selectedRoles}
                        onChange={(e) => {
                          const {options} = e.target
                          const value = []
                          for (let i = 0, l = options.length; i < l; i++) {
                            if (options[i].selected) {
                              value.push(options[i].value)
                            }
                          }
                          this.setState({selectedRoles: value})
                        }}
                        className="form-control"
                        ref={(input) => {
                          this.selectedRoles = input
                        }}
                      >
                        {listTemplates()}
                      </select>
                    )}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button className="pull-left" onClick={this.closeModal.bind(this)}>
              {__('Back')}
            </Button>
            <button
              onClick={this.handleChangeRoles.bind(this)}
              className="btn pull-right btn-primary"
            >
              {__('Save Changes')}
            </button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}

const TagField = ({tag, label, color, button = null, multiple}) => (
  <div className="form-group" style={{display: 'flex', alignItems: "baseline"}}>
    <label className="col-sm-3 control-label">{label}</label>
    <div className="col-sm-9">
      <p className="form-control-static"
         style={{display: 'flex', alignItems: "center", gap: "20px", justifyContent: "space-between"}}>
        {
          multiple && (
            <div className="form-control-static">
              {tag.map((status) => (
                <Tag color={color}>{status?.toUpperCase?.()}</Tag>
              ))}
            </div>
          )
        }
        {
          !multiple && (
            <div className="form-control-static">
              <Tag color={color}>{tag?.toUpperCase?.()}</Tag>
            </div>
          )
        }
        {
          button
        }
      </p>
    </div>

  </div>
)


const DeputyField = ({user, deputy, users, organisations, disabled, refreshUserPage}) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className="form-group" style={{display: 'flex', alignItems: "baseline"}}>
      <label className="col-sm-3 control-label">{__('deputy', cases.CAPITALIZE)}</label>
      <div className="col-sm-9">
        <p className="form-control-static"
           style={{display: 'flex', alignItems: "center", gap: "20px", justifyContent: "space-between"}}>
          {
            user.deputy ? (
              <Link to={`/users/${user.deputy.id}`}>
                {
                  user.deputy.status === 'deleted' ?
                    __('deleted_user', cases.CAPITALIZE_SENTENCE) :
                    `${user.deputy.firstName} ${user.deputy.lastName}`
                }
              </Link>
            ) : "--"
          }
          <button
            onClick={(e) => {
              e.preventDefault()
              setOpenModal(true)
            }}
            className="btn btn-link"
            disabled={disabled}
          >
            {__('manage_deputy', cases.CAPITALIZE_SENTENCE)}
          </button>
          {
            openModal && (
              <ChangeDeputy
                currentDeputy={deputy}
                users={users}
                replaceWithDeputy={user.replaceWithDeputy}
                organisations={organisations}
                submit={(deputyId, replaceWithDeputy) => {
                  changeDeputy(user.id, deputyId, replaceWithDeputy).then(() => {
                    setOpenModal(false)
                    refreshUserPage()
                  })
                }}
                close={() => setOpenModal(false)}
              />
            )
          }
        </p>
      </div>

    </div>
  )
}

const FieldDetails = ({children}) => (
  <LabelInfo>
    <Tooltip title={children}>
      <InfoCircleOutlined/>
    </Tooltip>
  </LabelInfo>
)

const UserFormConnected = connect(
  (state) => (
    {
      guiUser: state.user.data,
      formValues: state.form.userForm.values,
      availableLanguages: state.ui.availableLanguages,
      userRegistration: state.ui.instanceSettings?.userRegistration
    }
  ),
  {change}
)(UserForm)


export default reduxForm(
  {
    form: USER_FORM_NAME, // a unique identifier for this form
    validate,
    changePassword,
  },
  {}
)(UserFormConnected)
