import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { change } from 'redux-form';
import { api } from '../../providers/ApiProvider'
import { __ } from '../../utils/translationUtils'

class DocumentEditorControl extends React.Component {

  static propTypes = {
    handleCompanySelect: PropTypes.func,
    change: PropTypes.func,
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state={
      initialContent: this.props.controlData.source,
      content: this.props.controlData.source,
      selectedCompany: '',
    };
  }

  componentDidMount() {
    const fakeEvent = {
      target: {
        value: this.props.controlData.attributes.documentEditorSettings.companies[0][0],
      },
    };
    this.handleCompanySelect(fakeEvent);
  }

  handleHtmlChange(value){
    this.setState({
      content: value
    },
    ()=>{
      let doc = new DOMParser().parseFromString(value, 'text/html');
      value = new XMLSerializer().serializeToString(doc);
      value = value.match(/<body>(.*?)<\/body>/g)[0].replace(/<\/?body>/g,'');
      this.props.change('stepForm', this.props.controlData.id, value);
    });
  }

  handleCompanySelect(e) {
    if(e.target.value == '' || e.target.value == null){
      return;
    }
    const companyId = e.target.value;
    api.get('/entities/'+companyId)
    .then(
      response => {

        const entity = response.data;
        // placeholders mapping
        let address = `${entity.streetName} ${entity.streetNumber} ${entity.suffix}, ${entity.zip} - ${entity.city}`; // +  Name Street Number Street Suffix,
        const placeholders = [
          {
            key: "[company:phone]",
            value: entity.contactPhone || '[..company:phone..]'
          },
          {
            key: "[company:name]",
            value: entity.name || '[..company:name..]' ,
          },
          {
            key: "[company:address]",
            value: address || '[..company:address..]'
          }
        ];

        let _content = this.state.initialContent;

        placeholders.map((pl)=>{
          _content = _content.replace(pl.key, pl.value);
        });

        this.setState({
          content: _content,
          selectedCompany: companyId,
        },()=>{
          this.props.handleCompanySelect(companyId);
        });
      },
      error => {
        console.log(error);
        this.setState({isLoading: false});
      }
    );

  }


  render() {

    const { controlData } = this.props;

    const editorState = function(){
      return (this.state.selectedCompany  && this.state.selectedCompany != '')?'enabled':'disabled';
    };

    return (
      <div>
        <div className={`form-group ${(controlData.attributes.documentEditorSettings.companies.length == 1)?'hidden':''}`}>
          <label htmlFor="inputName" className="col-sm-3 col-md-3 col-lg-2 control-label">
            {__('Company')}*:
          </label>
          <div className="col-sm-9 col-md-6 col-lg-6">
            <select
              className="form-control"
              value={this.state.selectedCompany}
              onChange={this.handleCompanySelect.bind(this)}
              >
              {controlData.attributes.documentEditorSettings.companies.map((company,i)=>{
                return (
                  <option defaultChecked={i === 1} key={company[0]} value={company[0]}>{company[1]}</option>
                );
              })}
            </select>
          </div>
        </div>

        <div className={'form-group ' + editorState.bind(this)()}>
          <label className="col-xs-12">{__(controlData.title)}</label>

          <div className="col-xs-12">
            <ReactQuill
              theme="snow"
              style={controlData?.readOnly?{}:{backgroundColor:'white'}}
              value={this.state.content}
              onChange={this.handleHtmlChange.bind(this)}
              />
          </div>

        </div>
      </div>
    );
  }

}

export default connect(
  null,
  { change }
)(DocumentEditorControl);
