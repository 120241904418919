import React from 'react'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Glyphicon from '@strongdm/glyphicon'
import {isImage} from "../../../utils/appHelper";
import ImagePreviewPopup from "../../ImagePreviewPopup";
import DeleteFileConfirmationModal from "../../DeleteFileConfirmationModal";
import UploadFileModal from "../../UploadFileModal";

import PropTypes from 'prop-types';
import mime from 'mime-types';
import path from 'path';
import * as config from "../../../constants/globalConfiguration";
import { __ } from '../../../utils/translationUtils'
const apiUrl = config.getApiUrl();

class MessageAttachment  extends React.Component {

  static propTypes = {
    attachmentPath: PropTypes.string,
    onChangeAttachmentPath: PropTypes.func,
    editModeOn: PropTypes.bool,
    authToken: PropTypes.string,
    objectCaller: PropTypes.object,
    multiple: PropTypes.bool
  };

  constructor(props){
    super(props)
    this.state = {
      previewModal: {open: false, file: null},
      uploadModal: {open: false},
      deleteModal: {open: false, file: null}
    };

    this.fileUpLoadId = null;
  }

  handleAttachmentPathChange(attachmentPath){
    this.props.onChangeAttachmentPath(attachmentPath);
  }

  openUploadModal(e) {
    const {objectCaller} = this.props;
    e.preventDefault();
    e.stopPropagation();


    const today = new Date();
    this.fileUpLoadId = `${objectCaller.id}/${today.getTime()}`;
    this.setState({uploadModal: {open: true}});
  }

  closeUpLoadModal() {
    this.setState({uploadModal: {open: false}});
  }

  closePreviewModal() {
    this.setState({previewModal: {open: false}});
  }

  closeDeleteModal() {
    this.setState({deleteModal: {open: false}});
  }

  showImage(file) {
    this.setState({previewModal: {open: true, file: file}});
  }

  getFileObject(attachmentPath) {

    let file = null;

    if (attachmentPath && attachmentPath !== '') {
      const url = path.dirname(attachmentPath);
      const type = mime.lookup(attachmentPath);
      const filename = attachmentPath.replace(/^.*[\\\/]/, '');

      file = {};
      Object.assign(file, {
        data: {name: filename, type: type},
        path: url + '/',
        groupName: '',
        xsdName: ''
      });

    }

    return file;

  }

  deleteFile() {
    this.handleAttachmentPathChange('');
    this.closeDeleteModal();
  }

  addFile(file) {
    const attachmentPath = `${apiUrl}/staticFiles/${this.fileUpLoadId}/${file.name}`
    this.handleAttachmentPathChange(attachmentPath);

  }

  handleAttachmentUploadError(){
    this.handleAttachmentPathChange(null);
  }

  renderRemovingFile(file){

    const deleteTooltip = (<Tooltip id="delete-file">{__('DeleteFile')}</ Tooltip>);
    return(
      <OverlayTrigger placement="top" overlay={deleteTooltip}>
        <Glyphicon
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
              deleteModal: {
                open: true,
                file: file,
              }
            });
          }}
          className="delete-icon"
          title={__('DeleteFile')}
          glyph="remove"
        />
      </OverlayTrigger>
    );
  }

  renderFileBody() {
    const file = this.getFileObject(this.props.attachmentPath);
    const pattern = /\.[0-9a-z]+$/i;

    if (!file) {
      return (
        <div/>
      );
    } else {
      const url = this.props.attachmentPath;
      return (

        <div
          className={`${isImage(file.data.type) ? 'image-item' : 'file-item'} message-attachment`}>
          <a target="_blank" onClick={(e) => {
            if (isImage(file.data.type)) {
              e.preventDefault();
              e.stopPropagation();
              this.showImage(file);
            }
          }}
             className="text-center"
             href={url} rel="noreferrer"
          >
            {isImage(file.data.type) && <img src={url} alt={file.data.name}/>}
            {!isImage(file.data.type) &&
            <span style={{display: 'inline-block', position: 'relative'}}>
                          <img src={require('../../../images/file-icon.png')} alt={''}/>
                          <span className="file-type">{pattern.exec(file.data.name)[0]}</span>
                        </span>
            }
            <div>{file.data.name}</div>

            {this.props.editModeOn && this.renderRemovingFile(file)}
          </a>
        </div>

      );
    }
  }

  renderFileHeading(){
    const tooltip = (<Tooltip id="upload-file">{__('UploadFile')}</Tooltip>);
    let divStyle = {display: 'inline'};

    if (!this.state.file) {
      return (
        <div className="panel-heading">
          <span>{__('Add Attachment')+': '}</span>

          <div style={divStyle}>
            <OverlayTrigger placement="left" overlay={tooltip}>
              <button
                className="comment-upload-file pull-right btn btn-success btn-no-padding btn-sm"
                onClick={this.openUploadModal.bind(this)}
              >
                <Glyphicon glyph="plus"/>
              </button>
            </OverlayTrigger>
          </div>
        </div>
      );
    } else {
      return (
        <div className="panel-heading">
          {__('Attachment: ')}
        </div>
      );
    }

  }

  renderFileUpload(){
    return (
      <div>
        {this.state.previewModal.file &&
        <ImagePreviewPopup
          open={this.state.previewModal.open}
          file={this.state.previewModal.file}
          closeModal={this.closePreviewModal.bind(this)}
        />
        }

        {this.state.deleteModal.file &&
        <DeleteFileConfirmationModal
          open={this.state.deleteModal.open}
          file={this.state.deleteModal.file}
          closeModal={this.closeDeleteModal.bind(this)}
          deleteFile={this.deleteFile.bind(this)}
        />
        }

        {
          <UploadFileModal
            open={this.state.uploadModal.open}
            path={`${apiUrl}/staticFiles/${this.fileUpLoadId}`}
            closeModal={this.closeUpLoadModal.bind(this)}
            authToken={this.props.authToken}
            onComplete={this.addFile.bind(this)}
            onError={this.handleAttachmentUploadError.bind(this)}
            multiple={this.props.multiple}
          />
        }
      </div>
    );
  }

  render() {
    const {editModeOn, attachmentPath} = this.props;
    const style = {margin: '0 auto', 'backgroundColor': 'initial', border: 'none', 'boxShadow': 'none'};
    return(
      <div className={'image-container'}>
        <div className="group panel panel-info panel-message-attachment" key={'fg-ungrouped'} style={style}>
          {editModeOn && !attachmentPath && this.renderFileHeading()}
          {this.renderFileBody()}
          {this.renderFileUpload()}
        </div>
      </div>
    )
  }
}

export default MessageAttachment;
