import styled from 'styled-components'
import {Panel as RBPanel} from "react-bootstrap";

export const Panel = styled(RBPanel)`
	cursor: ${props => props.collapsible ? 'pointer' : 'default'};
	
	&>.panel-heading {
    text-overflow: ellipsis;
    overflow: hidden;
    ${props => props.isParent === true ? `
      background-color: rgb(61, 114, 156);
      color: white;
    ` : ''}
  }

  .panel-body {
    padding: 10px;
  }
`
