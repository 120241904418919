import React from 'react'
import * as S from './styles'


/**
 * @props {value: string}
 * @props {options: [{label: '', value: ''},]}
 * @props {size: 	'large' | 'middle' | 'small'}
 * @props {onChange: callback}
 * @props {className: string}
 */

const ButtonRadio = (props) => (
  <S.RadioGroup
    {...props}
    optionType="button"
    buttonStyle="solid"
  />
)

ButtonRadio.defaultProps = {
  size: 'medium',
};

export default ButtonRadio
