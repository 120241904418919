import React from 'react'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';

import rootReducer from './reducers/'
import initialState from './reducers/initialState'


const isDev = () => process.env.NODE_ENV === 'development'

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(thunk),
    (isDev() && window.__REDUX_DEVTOOLS_EXTENSION__) ? window.__REDUX_DEVTOOLS_EXTENSION__() : args => args)
)


export default ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
)








