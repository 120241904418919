import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Glyphicon from '@strongdm/glyphicon'
import { checkUserRights } from '../utils/appHelper';
import { SortableHandle} from 'react-sortable-hoc';
import { __ } from '../utils/translationUtils'
const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

class TabConfigurationCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      configuration,
      tab,
      removeObjectFromTab,
      user
    } = this.props;
  
    const {CAN_MANAGE_OVERVIEW_OBJECTS }  = global.constants.rightMappings

    return (
      <div
        className="tab-configuration-item"
        key={configuration.configuration.id}
      >
        <DragHandle />{' '}
        <Glyphicon
          onClick={() => removeObjectFromTab(configuration.configuration.id, tab.id)}
          className="remove-icon"
          glyph="remove"
        />
        { !(checkUserRights(user.rights, CAN_MANAGE_OVERVIEW_OBJECTS)) &&
          <span>{__(configuration.configuration.name)}</span>
        }
        { (checkUserRights(user.rights, CAN_MANAGE_OVERVIEW_OBJECTS)) &&
          <Link to={`/overviewObjects/${configuration.configuration.id}/edit`}>{__(configuration.configuration.name)}</Link>
        }
      </div>
    );
  }
}

TabConfigurationCard.propTypes = {
  tab: PropTypes.object,
  user: PropTypes.object,
  configuration: PropTypes.object,
  removeObjectFromTab: PropTypes.func,
};

export default connect(
  state => {
    return {
      user: state.user.data,
    };
  },
  {}
)(TabConfigurationCard);
