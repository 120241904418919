import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Field} from 'redux-form';

import { api } from '../../providers/ApiProvider'
import AssignmentField from '../formFields/AssignmentField';
import { __ } from '../../utils/translationUtils'
const loadingBar = require('nprogress');



class AssignmentControl extends React.Component {
  static propTypes = {
    guiUser: PropTypes.object,
    attributes: PropTypes.object,
    process: PropTypes.object,
    guiUserId: PropTypes.string,
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      userGroups: [],
      pairsLoading: true,
      pairs: [],
      selectedItemsLoading: true,
      selectedItems: [],
    };

    this.getAssigneeOptions = this.getAssigneeOptions.bind(this);
    this.getOwnerShip = this.getOwnerShip.bind(this);
  }

  componentDidMount() {
    this.getPairs();
    this.getOwnerShip();
  }

  getPairs(){
    const { process/*, guiUserId*/ } = this.props;
    if (!process){
      this.setState({
        pairsLoading: false
      });
      return;
    }
    // fetch... => setState pairs
  }

  getOwnerShip(){

    // TODO setting comment
    const {process} = this.props;

    loadingBar.start();

    if (this.props.controlData && !this.props.controlData.showCurrentAssignees){
      loadingBar.done();
      this.setState( () => ({
        selectedItemsLoading: false,
        selectedItems: [],
      }));
      return;
    }

    const req = `/processes/${process.id}/ownership`;
    api.get(req).then( res => {
        this.setState( () => ({
          selectedItemsLoading: false,
          selectedItems: res.data.assignees,
        }));
      loadingBar.done();
      });
  }

  getAssigneeOptions(){
    let pairs = (
      this.state.pairs.map((pair)=>{
        return <option value={JSON.stringify(pair)} key={`pair-${pair.userId}-${pair.entityId}`}>{`${pair.user.username} - ${pair.entity.shortName}`}</option>;
      }));
    return pairs;
  }

  render() {
    const { guiUser, process, controlData } = this.props;
    const { selectedItems, selectedItemsLoading } = this.state;

    if (selectedItemsLoading /*|| pairsLoading*/ ){
      return (<div>LOADING...</div>);
    }
    return (
      <div className="col-sm-12">
        <div className="form-group">
          <Field
            name="assignees"
            component={AssignmentField}
            label={__('Assign To')+'*'}
            className="form-control"
            multiple
            withFilter
            type="select"
            items={this.getAssigneeOptions()}
            guiUserId={guiUser.id}
            processId={process.id}
            selectedItems = {selectedItems}
            controlData = {controlData}
          />
        </div>
      </div>
    );

  }

}
export default connect(
  state => {
    return {
      guiUser: state.user.data
    };

  },
  {}
)(AssignmentControl);
