import React from "react"
import * as TEMPLATES from "./../templates/displayAttributes"
import * as CONFIG_TYPE from "../context/configurationType"
import PropTypes from "prop-types"

DisplayAttributeFactory.propTypes = {
  configurationType: PropTypes.oneOf([CONFIG_TYPE.VIEW, CONFIG_TYPE.REPORT, CONFIG_TYPE.OBJECT_LIST, CONFIG_TYPE.OBJECT_BINDER]).isRequired,
}

function DisplayAttributeFactory({configurationType}) {
  switch (configurationType) {
    case CONFIG_TYPE.OBJECT_LIST:
    case CONFIG_TYPE.OBJECT_BINDER:
    case CONFIG_TYPE.OBJECT_PICKER:
      return <TEMPLATES.ObjectListing/>
    case CONFIG_TYPE.VIEW:
    case CONFIG_TYPE.REPORT:
      return <TEMPLATES.View/>
    default:
      return <div>...loading</div>
  }
}

export default DisplayAttributeFactory
