import React from 'react';
import PropTypes from 'prop-types';
import { renderField, stepFieldRequired} from '../../utils/fieldValidator';
import { Field} from 'redux-form';
import { __ } from '../../utils/translationUtils'

class DateTimeControl extends React.Component {

  static propTypes = {
    controlData: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {

    const { controlData } = this.props;
    const req = (controlData.required)?'*':'';
    return (
      <Field
        minValue={controlData && controlData.attributes.extendedProperties && controlData.attributes.extendedProperties.minValue ? controlData.attributes.extendedProperties.minValue:null}
        maxValue={controlData && controlData.attributes.extendedProperties && controlData.attributes.extendedProperties.maxValue ? controlData.attributes.extendedProperties.maxValue:null}
        disabled={controlData.readOnly}
        name={controlData.id}
        description={controlData.description}
        component={renderField}
        label={__(controlData.title)+req}
        className="form-control"
        containerClassName="label-control"
        type="date"
        validate={controlData.required?[stepFieldRequired]:undefined}
      />
    );
  }

}

export default DateTimeControl;
